export const JOB_TITLES3 = [
    {
        "value": 15000,
        "NOCCode": "7445",
        "JobTitle": "gas meter servicer"
    },
    {
        "value": 15001,
        "NOCCode": "7445",
        "JobTitle": "gas meter tester"
    },
    {
        "value": 15002,
        "NOCCode": "7445",
        "JobTitle": "golf club head adjuster"
    },
    {
        "value": 15003,
        "NOCCode": "7445",
        "JobTitle": "golf club repairer"
    },
    {
        "value": 15004,
        "NOCCode": "7445",
        "JobTitle": "guitar repairer"
    },
    {
        "value": 15005,
        "NOCCode": "7445",
        "JobTitle": "gyroscope repairer"
    },
    {
        "value": 15006,
        "NOCCode": "7445",
        "JobTitle": "gyroscope repairman/woman"
    },
    {
        "value": 15007,
        "NOCCode": "7445",
        "JobTitle": "hand tool repairman/woman"
    },
    {
        "value": 15008,
        "NOCCode": "7445",
        "JobTitle": "hydraulic unit repairer"
    },
    {
        "value": 15009,
        "NOCCode": "7445",
        "JobTitle": "industrial belt repairer"
    },
    {
        "value": 15010,
        "NOCCode": "7445",
        "JobTitle": "installer-repairer, billiard and bowling equipment"
    },
    {
        "value": 15011,
        "NOCCode": "7445",
        "JobTitle": "juke box mechanic"
    },
    {
        "value": 15012,
        "NOCCode": "7445",
        "JobTitle": "mechanic, bicycles"
    },
    {
        "value": 15013,
        "NOCCode": "7445",
        "JobTitle": "mechanic, domestic sewing machine"
    },
    {
        "value": 15014,
        "NOCCode": "7445",
        "JobTitle": "mechanic, juke boxes"
    },
    {
        "value": 15015,
        "NOCCode": "7445",
        "JobTitle": "mechanic, scales"
    },
    {
        "value": 15016,
        "NOCCode": "7445",
        "JobTitle": "mechanic, vending machines"
    },
    {
        "value": 15017,
        "NOCCode": "7445",
        "JobTitle": "mechanical meter repairer"
    },
    {
        "value": 15018,
        "NOCCode": "7445",
        "JobTitle": "meter repairer"
    },
    {
        "value": 15019,
        "NOCCode": "7445",
        "JobTitle": "meter servicer"
    },
    {
        "value": 15020,
        "NOCCode": "7445",
        "JobTitle": "musical instrument repairer"
    },
    {
        "value": 15021,
        "NOCCode": "7445",
        "JobTitle": "musical instrument tester"
    },
    {
        "value": 15022,
        "NOCCode": "7445",
        "JobTitle": "musical instrument tuner"
    },
    {
        "value": 15023,
        "NOCCode": "7445",
        "JobTitle": "neon sign repairer"
    },
    {
        "value": 15024,
        "NOCCode": "7445",
        "JobTitle": "net mender"
    },
    {
        "value": 15025,
        "NOCCode": "7445",
        "JobTitle": "net repairer"
    },
    {
        "value": 15026,
        "NOCCode": "7445",
        "JobTitle": "organ tuner"
    },
    {
        "value": 15027,
        "NOCCode": "7445",
        "JobTitle": "Oriental rug repairer"
    },
    {
        "value": 15028,
        "NOCCode": "7445",
        "JobTitle": "parking meter repairer"
    },
    {
        "value": 15029,
        "NOCCode": "7445",
        "JobTitle": "parking meter servicer"
    },
    {
        "value": 15030,
        "NOCCode": "7445",
        "JobTitle": "percussion instrument repairer"
    },
    {
        "value": 15031,
        "NOCCode": "7445",
        "JobTitle": "photofinishing equipment repairer"
    },
    {
        "value": 15032,
        "NOCCode": "7445",
        "JobTitle": "piano action adjuster"
    },
    {
        "value": 15033,
        "NOCCode": "7445",
        "JobTitle": "piano action regulator"
    },
    {
        "value": 15034,
        "NOCCode": "7445",
        "JobTitle": "piano repairer"
    },
    {
        "value": 15035,
        "NOCCode": "7445",
        "JobTitle": "piano stringer"
    },
    {
        "value": 15036,
        "NOCCode": "7445",
        "JobTitle": "piano technician"
    },
    {
        "value": 15037,
        "NOCCode": "7445",
        "JobTitle": "piano tone regulator"
    },
    {
        "value": 15038,
        "NOCCode": "7445",
        "JobTitle": "piano tuner"
    },
    {
        "value": 15039,
        "NOCCode": "7445",
        "JobTitle": "piano tuner-technician"
    },
    {
        "value": 15040,
        "NOCCode": "7445",
        "JobTitle": "pipe organ tuner and repairer"
    },
    {
        "value": 15041,
        "NOCCode": "7445",
        "JobTitle": "pipeline valve repairer"
    },
    {
        "value": 15042,
        "NOCCode": "7445",
        "JobTitle": "pneumatic tool repairer"
    },
    {
        "value": 15043,
        "NOCCode": "7445",
        "JobTitle": "pneumatic tube repairer"
    },
    {
        "value": 15044,
        "NOCCode": "7445",
        "JobTitle": "pneumatic unit tester and repairer"
    },
    {
        "value": 15045,
        "NOCCode": "7445",
        "JobTitle": "pressure control calibrator"
    },
    {
        "value": 15046,
        "NOCCode": "7445",
        "JobTitle": "repairer, archery bows – sporting goods manufacturing"
    },
    {
        "value": 15047,
        "NOCCode": "7445",
        "JobTitle": "repairer, awnings and tents"
    },
    {
        "value": 15048,
        "NOCCode": "7445",
        "JobTitle": "repairer, beer-dispensing equipment"
    },
    {
        "value": 15049,
        "NOCCode": "7445",
        "JobTitle": "repairer, bicycle"
    },
    {
        "value": 15050,
        "NOCCode": "7445",
        "JobTitle": "repairer, bows – stringed instruments"
    },
    {
        "value": 15051,
        "NOCCode": "7445",
        "JobTitle": "repairer, camera"
    },
    {
        "value": 15052,
        "NOCCode": "7445",
        "JobTitle": "repairer, dictaphones"
    },
    {
        "value": 15053,
        "NOCCode": "7445",
        "JobTitle": "repairer, domestic sewing machines"
    },
    {
        "value": 15054,
        "NOCCode": "7445",
        "JobTitle": "repairer, electric sign"
    },
    {
        "value": 15055,
        "NOCCode": "7445",
        "JobTitle": "repairer, fire extinguishers"
    },
    {
        "value": 15056,
        "NOCCode": "7445",
        "JobTitle": "repairer, golf clubs"
    },
    {
        "value": 15057,
        "NOCCode": "7445",
        "JobTitle": "repairer, mechanical meters"
    },
    {
        "value": 15058,
        "NOCCode": "7445",
        "JobTitle": "repairer, musical instruments"
    },
    {
        "value": 15059,
        "NOCCode": "7445",
        "JobTitle": "repairer, neon signs"
    },
    {
        "value": 15060,
        "NOCCode": "7445",
        "JobTitle": "repairer, Oriental rugs"
    },
    {
        "value": 15061,
        "NOCCode": "7445",
        "JobTitle": "repairer, percussion instruments"
    },
    {
        "value": 15062,
        "NOCCode": "7445",
        "JobTitle": "repairer, piano"
    },
    {
        "value": 15063,
        "NOCCode": "7445",
        "JobTitle": "repairer, scaffolding"
    },
    {
        "value": 15064,
        "NOCCode": "7445",
        "JobTitle": "repairer, scales"
    },
    {
        "value": 15065,
        "NOCCode": "7445",
        "JobTitle": "repairer, sporting goods"
    },
    {
        "value": 15066,
        "NOCCode": "7445",
        "JobTitle": "repairer, sports equipment"
    },
    {
        "value": 15067,
        "NOCCode": "7445",
        "JobTitle": "repairer, stringed instruments"
    },
    {
        "value": 15068,
        "NOCCode": "7445",
        "JobTitle": "repairer, surveying and optical instruments"
    },
    {
        "value": 15069,
        "NOCCode": "7445",
        "JobTitle": "repairer, survival equipment"
    },
    {
        "value": 15070,
        "NOCCode": "7445",
        "JobTitle": "repairer, thermostats"
    },
    {
        "value": 15071,
        "NOCCode": "7445",
        "JobTitle": "repairer, vending machine"
    },
    {
        "value": 15072,
        "NOCCode": "7445",
        "JobTitle": "repairman/woman, hand tools"
    },
    {
        "value": 15073,
        "NOCCode": "7445",
        "JobTitle": "respirator servicer and fitter"
    },
    {
        "value": 15074,
        "NOCCode": "7445",
        "JobTitle": "safety valve repairer, pipelines"
    },
    {
        "value": 15075,
        "NOCCode": "7445",
        "JobTitle": "salvage inspector, instruments"
    },
    {
        "value": 15076,
        "NOCCode": "7445",
        "JobTitle": "salvager, machinery"
    },
    {
        "value": 15077,
        "NOCCode": "7445",
        "JobTitle": "scaffold repairer"
    },
    {
        "value": 15078,
        "NOCCode": "7445",
        "JobTitle": "scale mechanic"
    },
    {
        "value": 15079,
        "NOCCode": "7445",
        "JobTitle": "scale repairer"
    },
    {
        "value": 15080,
        "NOCCode": "7445",
        "JobTitle": "security systems technician"
    },
    {
        "value": 15081,
        "NOCCode": "7445",
        "JobTitle": "servicer, automotive maintenance equipment"
    },
    {
        "value": 15082,
        "NOCCode": "7445",
        "JobTitle": "servicer, dictaphones"
    },
    {
        "value": 15083,
        "NOCCode": "7445",
        "JobTitle": "servicer, fire equipment"
    },
    {
        "value": 15084,
        "NOCCode": "7445",
        "JobTitle": "servicer, gas meters"
    },
    {
        "value": 15085,
        "NOCCode": "7445",
        "JobTitle": "servicer, meters"
    },
    {
        "value": 15086,
        "NOCCode": "7445",
        "JobTitle": "servicer, parking meters"
    },
    {
        "value": 15087,
        "NOCCode": "7445",
        "JobTitle": "sewing machine mechanic – domestic"
    },
    {
        "value": 15088,
        "NOCCode": "7445",
        "JobTitle": "sewing machine servicer – domestic"
    },
    {
        "value": 15089,
        "NOCCode": "7445",
        "JobTitle": "shaker repairer"
    },
    {
        "value": 15090,
        "NOCCode": "7445",
        "JobTitle": "sieve repairer"
    },
    {
        "value": 15091,
        "NOCCode": "7445",
        "JobTitle": "ski equipment repairer"
    },
    {
        "value": 15092,
        "NOCCode": "7445",
        "JobTitle": "speedometer repairer"
    },
    {
        "value": 15093,
        "NOCCode": "7445",
        "JobTitle": "sporting goods repairer"
    },
    {
        "value": 15094,
        "NOCCode": "7445",
        "JobTitle": "sports equipment repairer"
    },
    {
        "value": 15095,
        "NOCCode": "7445",
        "JobTitle": "spray gun repairer"
    },
    {
        "value": 15096,
        "NOCCode": "7445",
        "JobTitle": "steam meter repairer"
    },
    {
        "value": 15097,
        "NOCCode": "7445",
        "JobTitle": "stone saw setter"
    },
    {
        "value": 15098,
        "NOCCode": "7445",
        "JobTitle": "stringed instrument repairer"
    },
    {
        "value": 15099,
        "NOCCode": "7445",
        "JobTitle": "surveying and optical instrument repairer"
    },
    {
        "value": 15100,
        "NOCCode": "7445",
        "JobTitle": "surveying and optical instrument technician"
    },
    {
        "value": 15101,
        "NOCCode": "7445",
        "JobTitle": "survival equipment repairer"
    },
    {
        "value": 15102,
        "NOCCode": "7445",
        "JobTitle": "taximeter repairer"
    },
    {
        "value": 15103,
        "NOCCode": "7445",
        "JobTitle": "technician, surveying and optical instruments"
    },
    {
        "value": 15104,
        "NOCCode": "7445",
        "JobTitle": "tester, gas meters"
    },
    {
        "value": 15105,
        "NOCCode": "7445",
        "JobTitle": "tester, musical instruments"
    },
    {
        "value": 15106,
        "NOCCode": "7445",
        "JobTitle": "thermostat repairer"
    },
    {
        "value": 15107,
        "NOCCode": "7445",
        "JobTitle": "torque wrench calibrator"
    },
    {
        "value": 15108,
        "NOCCode": "7445",
        "JobTitle": "transcribing machine servicer"
    },
    {
        "value": 15109,
        "NOCCode": "7445",
        "JobTitle": "tuner-technician, piano"
    },
    {
        "value": 15110,
        "NOCCode": "7445",
        "JobTitle": "valve repairer, pipelines"
    },
    {
        "value": 15111,
        "NOCCode": "7445",
        "JobTitle": "vending machine mechanic"
    },
    {
        "value": 15112,
        "NOCCode": "7445",
        "JobTitle": "vending machine repairer"
    },
    {
        "value": 15113,
        "NOCCode": "7445",
        "JobTitle": "violin repairer"
    },
    {
        "value": 15114,
        "NOCCode": "7445",
        "JobTitle": "water meter repairer"
    },
    {
        "value": 15115,
        "NOCCode": "7445",
        "JobTitle": "wind instrument repairer"
    },
    {
        "value": 15116,
        "NOCCode": "7445",
        "JobTitle": "wrecker, automobile"
    },
    {
        "value": 15117,
        "NOCCode": "7451",
        "JobTitle": "barge loader"
    },
    {
        "value": 15118,
        "NOCCode": "7451",
        "JobTitle": "boat loader"
    },
    {
        "value": 15119,
        "NOCCode": "7451",
        "JobTitle": "dock hand"
    },
    {
        "value": 15120,
        "NOCCode": "7451",
        "JobTitle": "dockerman/woman – marine cargo handling"
    },
    {
        "value": 15121,
        "NOCCode": "7451",
        "JobTitle": "dockworker"
    },
    {
        "value": 15122,
        "NOCCode": "7451",
        "JobTitle": "dockworker – marine cargo handling"
    },
    {
        "value": 15123,
        "NOCCode": "7451",
        "JobTitle": "longshore worker"
    },
    {
        "value": 15124,
        "NOCCode": "7451",
        "JobTitle": "longshore worker and tower-loader operator"
    },
    {
        "value": 15125,
        "NOCCode": "7451",
        "JobTitle": "longshoreman/woman"
    },
    {
        "value": 15126,
        "NOCCode": "7451",
        "JobTitle": "lumper – marine cargo handling"
    },
    {
        "value": 15127,
        "NOCCode": "7451",
        "JobTitle": "ship loader operator"
    },
    {
        "value": 15128,
        "NOCCode": "7451",
        "JobTitle": "shiploader operator"
    },
    {
        "value": 15129,
        "NOCCode": "7451",
        "JobTitle": "stevedore"
    },
    {
        "value": 15130,
        "NOCCode": "7451",
        "JobTitle": "stower"
    },
    {
        "value": 15131,
        "NOCCode": "7451",
        "JobTitle": "tanker loader"
    },
    {
        "value": 15132,
        "NOCCode": "7451",
        "JobTitle": "tanker loader – marine cargo handling"
    },
    {
        "value": 15133,
        "NOCCode": "7451",
        "JobTitle": "tankerman/woman – marine cargo handling"
    },
    {
        "value": 15134,
        "NOCCode": "7451",
        "JobTitle": "tower-loader operator – marine cargo handling"
    },
    {
        "value": 15135,
        "NOCCode": "7451",
        "JobTitle": "wharfman/woman – marine cargo handling"
    },
    {
        "value": 15136,
        "NOCCode": "7452",
        "JobTitle": "ash handler"
    },
    {
        "value": 15137,
        "NOCCode": "7452",
        "JobTitle": "attendant, used building materials yard"
    },
    {
        "value": 15138,
        "NOCCode": "7452",
        "JobTitle": "automated storage and retrieval system operator"
    },
    {
        "value": 15139,
        "NOCCode": "7452",
        "JobTitle": "automatic pallet equipment operator"
    },
    {
        "value": 15140,
        "NOCCode": "7452",
        "JobTitle": "bandoleer packer – explosives manufacturing"
    },
    {
        "value": 15141,
        "NOCCode": "7452",
        "JobTitle": "belt conveyor attendant"
    },
    {
        "value": 15142,
        "NOCCode": "7452",
        "JobTitle": "beltman/woman"
    },
    {
        "value": 15143,
        "NOCCode": "7452",
        "JobTitle": "bin attendant"
    },
    {
        "value": 15144,
        "NOCCode": "7452",
        "JobTitle": "bin filler"
    },
    {
        "value": 15145,
        "NOCCode": "7452",
        "JobTitle": "binman/woman"
    },
    {
        "value": 15146,
        "NOCCode": "7452",
        "JobTitle": "box bander"
    },
    {
        "value": 15147,
        "NOCCode": "7452",
        "JobTitle": "box filler"
    },
    {
        "value": 15148,
        "NOCCode": "7452",
        "JobTitle": "boxcar loader"
    },
    {
        "value": 15149,
        "NOCCode": "7452",
        "JobTitle": "bucket elevator operator"
    },
    {
        "value": 15150,
        "NOCCode": "7452",
        "JobTitle": "bulk materials carrier operator"
    },
    {
        "value": 15151,
        "NOCCode": "7452",
        "JobTitle": "bulk materials loader operator"
    },
    {
        "value": 15152,
        "NOCCode": "7452",
        "JobTitle": "bulk materials unloader"
    },
    {
        "value": 15153,
        "NOCCode": "7452",
        "JobTitle": "bundler, hand"
    },
    {
        "value": 15154,
        "NOCCode": "7452",
        "JobTitle": "car loader, railway"
    },
    {
        "value": 15155,
        "NOCCode": "7452",
        "JobTitle": "carrier operator, bulk materials"
    },
    {
        "value": 15156,
        "NOCCode": "7452",
        "JobTitle": "cement loader and sacker"
    },
    {
        "value": 15157,
        "NOCCode": "7452",
        "JobTitle": "coal conveyor operator"
    },
    {
        "value": 15158,
        "NOCCode": "7452",
        "JobTitle": "coal conveyor operator – surface mining"
    },
    {
        "value": 15159,
        "NOCCode": "7452",
        "JobTitle": "coal dumper"
    },
    {
        "value": 15160,
        "NOCCode": "7452",
        "JobTitle": "coal handler"
    },
    {
        "value": 15161,
        "NOCCode": "7452",
        "JobTitle": "coal loader-unloader"
    },
    {
        "value": 15162,
        "NOCCode": "7452",
        "JobTitle": "coal wheeler"
    },
    {
        "value": 15163,
        "NOCCode": "7452",
        "JobTitle": "coal-moving equipment operator"
    },
    {
        "value": 15164,
        "NOCCode": "7452",
        "JobTitle": "conveyor belt attendant"
    },
    {
        "value": 15165,
        "NOCCode": "7452",
        "JobTitle": "conveyor belt operator (except underground mines)"
    },
    {
        "value": 15166,
        "NOCCode": "7452",
        "JobTitle": "conveyor belt patroller-controller"
    },
    {
        "value": 15167,
        "NOCCode": "7452",
        "JobTitle": "conveyor console operator"
    },
    {
        "value": 15168,
        "NOCCode": "7452",
        "JobTitle": "conveyor operator – material handling (except underground mines)"
    },
    {
        "value": 15169,
        "NOCCode": "7452",
        "JobTitle": "conveyor operator, grain"
    },
    {
        "value": 15170,
        "NOCCode": "7452",
        "JobTitle": "conveyor tender – material handling (except underground mines)"
    },
    {
        "value": 15171,
        "NOCCode": "7452",
        "JobTitle": "cribber – grain elevator"
    },
    {
        "value": 15172,
        "NOCCode": "7452",
        "JobTitle": "disposal worker – warehouse"
    },
    {
        "value": 15173,
        "NOCCode": "7452",
        "JobTitle": "dockman/woman – truck transport"
    },
    {
        "value": 15174,
        "NOCCode": "7452",
        "JobTitle": "dumper – material handling"
    },
    {
        "value": 15175,
        "NOCCode": "7452",
        "JobTitle": "dumper – surface coal mining"
    },
    {
        "value": 15176,
        "NOCCode": "7452",
        "JobTitle": "electric dolly operator"
    },
    {
        "value": 15177,
        "NOCCode": "7452",
        "JobTitle": "electric mule operator"
    },
    {
        "value": 15178,
        "NOCCode": "7452",
        "JobTitle": "electric truck operator"
    },
    {
        "value": 15179,
        "NOCCode": "7452",
        "JobTitle": "explosives handler"
    },
    {
        "value": 15180,
        "NOCCode": "7452",
        "JobTitle": "explosives packer"
    },
    {
        "value": 15181,
        "NOCCode": "7452",
        "JobTitle": "express service handler (except air transport)"
    },
    {
        "value": 15182,
        "NOCCode": "7452",
        "JobTitle": "fats and oils loader"
    },
    {
        "value": 15183,
        "NOCCode": "7452",
        "JobTitle": "feed mill loader"
    },
    {
        "value": 15184,
        "NOCCode": "7452",
        "JobTitle": "forklift operator"
    },
    {
        "value": 15185,
        "NOCCode": "7452",
        "JobTitle": "forklift truck operator"
    },
    {
        "value": 15186,
        "NOCCode": "7452",
        "JobTitle": "freight car loader"
    },
    {
        "value": 15187,
        "NOCCode": "7452",
        "JobTitle": "freight car unloader"
    },
    {
        "value": 15188,
        "NOCCode": "7452",
        "JobTitle": "freight handler (except air transport)"
    },
    {
        "value": 15189,
        "NOCCode": "7452",
        "JobTitle": "freight loader (except air transport)"
    },
    {
        "value": 15190,
        "NOCCode": "7452",
        "JobTitle": "freight unloader (except air transport)"
    },
    {
        "value": 15191,
        "NOCCode": "7452",
        "JobTitle": "furniture loader-unloader"
    },
    {
        "value": 15192,
        "NOCCode": "7452",
        "JobTitle": "furniture mover"
    },
    {
        "value": 15193,
        "NOCCode": "7452",
        "JobTitle": "glass cut-off machine unloader"
    },
    {
        "value": 15194,
        "NOCCode": "7452",
        "JobTitle": "grain conveyor operator"
    },
    {
        "value": 15195,
        "NOCCode": "7452",
        "JobTitle": "grain handler"
    },
    {
        "value": 15196,
        "NOCCode": "7452",
        "JobTitle": "grain weigher"
    },
    {
        "value": 15197,
        "NOCCode": "7452",
        "JobTitle": "hand bagger – material handling"
    },
    {
        "value": 15198,
        "NOCCode": "7452",
        "JobTitle": "hand bundler"
    },
    {
        "value": 15199,
        "NOCCode": "7452",
        "JobTitle": "hand packager"
    },
    {
        "value": 15200,
        "NOCCode": "7452",
        "JobTitle": "hand packer, sacks – material handling"
    },
    {
        "value": 15201,
        "NOCCode": "7452",
        "JobTitle": "hand sacker – material handling"
    },
    {
        "value": 15202,
        "NOCCode": "7452",
        "JobTitle": "hand truck operator"
    },
    {
        "value": 15203,
        "NOCCode": "7452",
        "JobTitle": "handler, explosives"
    },
    {
        "value": 15204,
        "NOCCode": "7452",
        "JobTitle": "handler, freight (except air transport)"
    },
    {
        "value": 15205,
        "NOCCode": "7452",
        "JobTitle": "handler, grain"
    },
    {
        "value": 15206,
        "NOCCode": "7452",
        "JobTitle": "handler, heavy materials"
    },
    {
        "value": 15207,
        "NOCCode": "7452",
        "JobTitle": "handler, light materials"
    },
    {
        "value": 15208,
        "NOCCode": "7452",
        "JobTitle": "handler, materials"
    },
    {
        "value": 15209,
        "NOCCode": "7452",
        "JobTitle": "handler, materials – manufacturing and warehousing"
    },
    {
        "value": 15210,
        "NOCCode": "7452",
        "JobTitle": "handler, recycled paper"
    },
    {
        "value": 15211,
        "NOCCode": "7452",
        "JobTitle": "heavy materials handler"
    },
    {
        "value": 15212,
        "NOCCode": "7452",
        "JobTitle": "helper, stock"
    },
    {
        "value": 15213,
        "NOCCode": "7452",
        "JobTitle": "helper, stock-keeper"
    },
    {
        "value": 15214,
        "NOCCode": "7452",
        "JobTitle": "helper, warehouse keeper"
    },
    {
        "value": 15215,
        "NOCCode": "7452",
        "JobTitle": "household goods mover"
    },
    {
        "value": 15216,
        "NOCCode": "7452",
        "JobTitle": "industrial truck operator"
    },
    {
        "value": 15217,
        "NOCCode": "7452",
        "JobTitle": "jack lift operator"
    },
    {
        "value": 15218,
        "NOCCode": "7452",
        "JobTitle": "jigger operator, supermarket"
    },
    {
        "value": 15219,
        "NOCCode": "7452",
        "JobTitle": "jitney driver – material handling"
    },
    {
        "value": 15220,
        "NOCCode": "7452",
        "JobTitle": "labourer – material handling"
    },
    {
        "value": 15221,
        "NOCCode": "7452",
        "JobTitle": "labourer – warehousing and storage"
    },
    {
        "value": 15222,
        "NOCCode": "7452",
        "JobTitle": "lift driver"
    },
    {
        "value": 15223,
        "NOCCode": "7452",
        "JobTitle": "lift jack operator"
    },
    {
        "value": 15224,
        "NOCCode": "7452",
        "JobTitle": "lift scoop operator"
    },
    {
        "value": 15225,
        "NOCCode": "7452",
        "JobTitle": "lift truck operator"
    },
    {
        "value": 15226,
        "NOCCode": "7452",
        "JobTitle": "light materials handler"
    },
    {
        "value": 15227,
        "NOCCode": "7452",
        "JobTitle": "liquids loader-unloader"
    },
    {
        "value": 15228,
        "NOCCode": "7452",
        "JobTitle": "loader operator, bulk materials"
    },
    {
        "value": 15229,
        "NOCCode": "7452",
        "JobTitle": "loader, fats and oils"
    },
    {
        "value": 15230,
        "NOCCode": "7452",
        "JobTitle": "loader, freight (except air transport)"
    },
    {
        "value": 15231,
        "NOCCode": "7452",
        "JobTitle": "loader, railway car"
    },
    {
        "value": 15232,
        "NOCCode": "7452",
        "JobTitle": "loader, ready-mix trucks"
    },
    {
        "value": 15233,
        "NOCCode": "7452",
        "JobTitle": "loader, tank trucks"
    },
    {
        "value": 15234,
        "NOCCode": "7452",
        "JobTitle": "loader, trucks"
    },
    {
        "value": 15235,
        "NOCCode": "7452",
        "JobTitle": "loader-unloader – material handling"
    },
    {
        "value": 15236,
        "NOCCode": "7452",
        "JobTitle": "loader-unloader, furniture"
    },
    {
        "value": 15237,
        "NOCCode": "7452",
        "JobTitle": "loader-unloader, liquids"
    },
    {
        "value": 15238,
        "NOCCode": "7452",
        "JobTitle": "lumber handler – building supplies"
    },
    {
        "value": 15239,
        "NOCCode": "7452",
        "JobTitle": "lumber piler – building supplies"
    },
    {
        "value": 15240,
        "NOCCode": "7452",
        "JobTitle": "lumber stocker – material handling"
    },
    {
        "value": 15241,
        "NOCCode": "7452",
        "JobTitle": "lumber unloader – material handling"
    },
    {
        "value": 15242,
        "NOCCode": "7452",
        "JobTitle": "malthouse worker"
    },
    {
        "value": 15243,
        "NOCCode": "7452",
        "JobTitle": "material handler"
    },
    {
        "value": 15244,
        "NOCCode": "7452",
        "JobTitle": "material handler – manufacturing and warehousing"
    },
    {
        "value": 15245,
        "NOCCode": "7452",
        "JobTitle": "Mobilift operator"
    },
    {
        "value": 15246,
        "NOCCode": "7452",
        "JobTitle": "munitions handler"
    },
    {
        "value": 15247,
        "NOCCode": "7452",
        "JobTitle": "operator, coal-moving equipment"
    },
    {
        "value": 15248,
        "NOCCode": "7452",
        "JobTitle": "operator, conveyor – material handling (except underground mines)"
    },
    {
        "value": 15249,
        "NOCCode": "7452",
        "JobTitle": "operator, conveyor belt (except underground mines)"
    },
    {
        "value": 15250,
        "NOCCode": "7452",
        "JobTitle": "operator, forklift"
    },
    {
        "value": 15251,
        "NOCCode": "7452",
        "JobTitle": "operator, forklift truck"
    },
    {
        "value": 15252,
        "NOCCode": "7452",
        "JobTitle": "operator, grain conveyor"
    },
    {
        "value": 15253,
        "NOCCode": "7452",
        "JobTitle": "operator, hand truck"
    },
    {
        "value": 15254,
        "NOCCode": "7452",
        "JobTitle": "operator, lift truck"
    },
    {
        "value": 15255,
        "NOCCode": "7452",
        "JobTitle": "operator, pallet lift"
    },
    {
        "value": 15256,
        "NOCCode": "7452",
        "JobTitle": "operator, pallet truck"
    },
    {
        "value": 15257,
        "NOCCode": "7452",
        "JobTitle": "operator, reach truck"
    },
    {
        "value": 15258,
        "NOCCode": "7452",
        "JobTitle": "operator, stacker – material handling"
    },
    {
        "value": 15259,
        "NOCCode": "7452",
        "JobTitle": "operator, straddle carrier"
    },
    {
        "value": 15260,
        "NOCCode": "7452",
        "JobTitle": "operator, straddle truck"
    },
    {
        "value": 15261,
        "NOCCode": "7452",
        "JobTitle": "operator, tier lift truck"
    },
    {
        "value": 15262,
        "NOCCode": "7452",
        "JobTitle": "package lift operator"
    },
    {
        "value": 15263,
        "NOCCode": "7452",
        "JobTitle": "packer – material handling"
    },
    {
        "value": 15264,
        "NOCCode": "7452",
        "JobTitle": "pallet lift operator"
    },
    {
        "value": 15265,
        "NOCCode": "7452",
        "JobTitle": "pallet loader operator"
    },
    {
        "value": 15266,
        "NOCCode": "7452",
        "JobTitle": "pallet truck operator"
    },
    {
        "value": 15267,
        "NOCCode": "7452",
        "JobTitle": "palletizer operator"
    },
    {
        "value": 15268,
        "NOCCode": "7452",
        "JobTitle": "paper wrapper – material handling"
    },
    {
        "value": 15269,
        "NOCCode": "7452",
        "JobTitle": "pick-up machine operator"
    },
    {
        "value": 15270,
        "NOCCode": "7452",
        "JobTitle": "piler – material handling"
    },
    {
        "value": 15271,
        "NOCCode": "7452",
        "JobTitle": "piler (except processing)"
    },
    {
        "value": 15272,
        "NOCCode": "7452",
        "JobTitle": "pole distributor operator"
    },
    {
        "value": 15273,
        "NOCCode": "7452",
        "JobTitle": "pole stacker operator"
    },
    {
        "value": 15274,
        "NOCCode": "7452",
        "JobTitle": "power truck driver – material handling"
    },
    {
        "value": 15275,
        "NOCCode": "7452",
        "JobTitle": "railway car loader"
    },
    {
        "value": 15276,
        "NOCCode": "7452",
        "JobTitle": "reach truck operator"
    },
    {
        "value": 15277,
        "NOCCode": "7452",
        "JobTitle": "ready-mix truck loader"
    },
    {
        "value": 15278,
        "NOCCode": "7452",
        "JobTitle": "recycled paper handler"
    },
    {
        "value": 15279,
        "NOCCode": "7452",
        "JobTitle": "sack packer, hand – material handling"
    },
    {
        "value": 15280,
        "NOCCode": "7452",
        "JobTitle": "shingle piler – material handling"
    },
    {
        "value": 15281,
        "NOCCode": "7452",
        "JobTitle": "shipment assembler"
    },
    {
        "value": 15282,
        "NOCCode": "7452",
        "JobTitle": "skidder – fruit packing"
    },
    {
        "value": 15283,
        "NOCCode": "7452",
        "JobTitle": "stacker – material handling"
    },
    {
        "value": 15284,
        "NOCCode": "7452",
        "JobTitle": "stacker operator – material handling"
    },
    {
        "value": 15285,
        "NOCCode": "7452",
        "JobTitle": "stacking machine operator – material handling"
    },
    {
        "value": 15286,
        "NOCCode": "7452",
        "JobTitle": "stacking machine tender – material handling"
    },
    {
        "value": 15287,
        "NOCCode": "7452",
        "JobTitle": "stock helper"
    },
    {
        "value": 15288,
        "NOCCode": "7452",
        "JobTitle": "stock-keeper helper"
    },
    {
        "value": 15289,
        "NOCCode": "7452",
        "JobTitle": "stockpiler"
    },
    {
        "value": 15290,
        "NOCCode": "7452",
        "JobTitle": "storage man/woman"
    },
    {
        "value": 15291,
        "NOCCode": "7452",
        "JobTitle": "storage worker – material handling"
    },
    {
        "value": 15292,
        "NOCCode": "7452",
        "JobTitle": "straddle carrier operator"
    },
    {
        "value": 15293,
        "NOCCode": "7452",
        "JobTitle": "straddle truck operator"
    },
    {
        "value": 15294,
        "NOCCode": "7452",
        "JobTitle": "supermarket jigger operator"
    },
    {
        "value": 15295,
        "NOCCode": "7452",
        "JobTitle": "tank truck loader"
    },
    {
        "value": 15296,
        "NOCCode": "7452",
        "JobTitle": "tender, conveyor – material handling (except underground mines)"
    },
    {
        "value": 15297,
        "NOCCode": "7452",
        "JobTitle": "tender, stacking machine – material handling"
    },
    {
        "value": 15298,
        "NOCCode": "7452",
        "JobTitle": "tier lift truck operator"
    },
    {
        "value": 15299,
        "NOCCode": "7452",
        "JobTitle": "tipman/woman – surface coal mining"
    },
    {
        "value": 15300,
        "NOCCode": "7452",
        "JobTitle": "tow motor driver (except air transport)"
    },
    {
        "value": 15301,
        "NOCCode": "7452",
        "JobTitle": "truck load weigher"
    },
    {
        "value": 15302,
        "NOCCode": "7452",
        "JobTitle": "truck loader"
    },
    {
        "value": 15303,
        "NOCCode": "7452",
        "JobTitle": "unloader, glass cut-off machine"
    },
    {
        "value": 15304,
        "NOCCode": "7452",
        "JobTitle": "used building materials yard attendant"
    },
    {
        "value": 15305,
        "NOCCode": "7452",
        "JobTitle": "van loader"
    },
    {
        "value": 15306,
        "NOCCode": "7452",
        "JobTitle": "warehouse keeper helper"
    },
    {
        "value": 15307,
        "NOCCode": "7452",
        "JobTitle": "warehouse worker – material handling"
    },
    {
        "value": 15308,
        "NOCCode": "7452",
        "JobTitle": "warehouseman/woman"
    },
    {
        "value": 15309,
        "NOCCode": "7452",
        "JobTitle": "warehouseperson"
    },
    {
        "value": 15310,
        "NOCCode": "7452",
        "JobTitle": "weigher, truck loads"
    },
    {
        "value": 15311,
        "NOCCode": "7452",
        "JobTitle": "wrapper, paper – material handling"
    },
    {
        "value": 15312,
        "NOCCode": "7452",
        "JobTitle": "yard attendant, used building materials"
    },
    {
        "value": 15313,
        "NOCCode": "7511",
        "JobTitle": "automobile carrier driver"
    },
    {
        "value": 15314,
        "NOCCode": "7511",
        "JobTitle": "automobile transport driver"
    },
    {
        "value": 15315,
        "NOCCode": "7511",
        "JobTitle": "bulk goods truck driver"
    },
    {
        "value": 15316,
        "NOCCode": "7511",
        "JobTitle": "bulk milk truck driver"
    },
    {
        "value": 15317,
        "NOCCode": "7511",
        "JobTitle": "cement truck driver – construction"
    },
    {
        "value": 15318,
        "NOCCode": "7511",
        "JobTitle": "coal hauler (except underground mining)"
    },
    {
        "value": 15319,
        "NOCCode": "7511",
        "JobTitle": "driver, bulk milk truck"
    },
    {
        "value": 15320,
        "NOCCode": "7511",
        "JobTitle": "driver, dump truck"
    },
    {
        "value": 15321,
        "NOCCode": "7511",
        "JobTitle": "driver, explosives truck"
    },
    {
        "value": 15322,
        "NOCCode": "7511",
        "JobTitle": "driver, gravel truck"
    },
    {
        "value": 15323,
        "NOCCode": "7511",
        "JobTitle": "driver, heavy truck"
    },
    {
        "value": 15324,
        "NOCCode": "7511",
        "JobTitle": "driver, line-haul"
    },
    {
        "value": 15325,
        "NOCCode": "7511",
        "JobTitle": "driver, logging truck"
    },
    {
        "value": 15326,
        "NOCCode": "7511",
        "JobTitle": "driver, long haul"
    },
    {
        "value": 15327,
        "NOCCode": "7511",
        "JobTitle": "driver, low-bed semi-trailer"
    },
    {
        "value": 15328,
        "NOCCode": "7511",
        "JobTitle": "driver, mobile concrete mixer"
    },
    {
        "value": 15329,
        "NOCCode": "7511",
        "JobTitle": "driver, ready-mix – construction"
    },
    {
        "value": 15330,
        "NOCCode": "7511",
        "JobTitle": "driver, ready-mix truck"
    },
    {
        "value": 15331,
        "NOCCode": "7511",
        "JobTitle": "driver, tow truck"
    },
    {
        "value": 15332,
        "NOCCode": "7511",
        "JobTitle": "driver, transport"
    },
    {
        "value": 15333,
        "NOCCode": "7511",
        "JobTitle": "driver, truck"
    },
    {
        "value": 15334,
        "NOCCode": "7511",
        "JobTitle": "driver, truck-trailer"
    },
    {
        "value": 15335,
        "NOCCode": "7511",
        "JobTitle": "dump truck driver"
    },
    {
        "value": 15336,
        "NOCCode": "7511",
        "JobTitle": "dumpster truck driver"
    },
    {
        "value": 15337,
        "NOCCode": "7511",
        "JobTitle": "explosives truck driver"
    },
    {
        "value": 15338,
        "NOCCode": "7511",
        "JobTitle": "flatbed truck driver"
    },
    {
        "value": 15339,
        "NOCCode": "7511",
        "JobTitle": "freight truck driver"
    },
    {
        "value": 15340,
        "NOCCode": "7511",
        "JobTitle": "fuel oil truck driver"
    },
    {
        "value": 15341,
        "NOCCode": "7511",
        "JobTitle": "gasoline truck operator"
    },
    {
        "value": 15342,
        "NOCCode": "7511",
        "JobTitle": "gravel hauler"
    },
    {
        "value": 15343,
        "NOCCode": "7511",
        "JobTitle": "gravel truck driver"
    },
    {
        "value": 15344,
        "NOCCode": "7511",
        "JobTitle": "hauler, gravel"
    },
    {
        "value": 15345,
        "NOCCode": "7511",
        "JobTitle": "hydrovac truck operator"
    },
    {
        "value": 15346,
        "NOCCode": "7511",
        "JobTitle": "LCV (long combination vehicule) driver"
    },
    {
        "value": 15347,
        "NOCCode": "7511",
        "JobTitle": "line-haul driver"
    },
    {
        "value": 15348,
        "NOCCode": "7511",
        "JobTitle": "liquid fertilizer truck driver"
    },
    {
        "value": 15349,
        "NOCCode": "7511",
        "JobTitle": "log hauler"
    },
    {
        "value": 15350,
        "NOCCode": "7511",
        "JobTitle": "log truck driver"
    },
    {
        "value": 15351,
        "NOCCode": "7511",
        "JobTitle": "logging truck driver"
    },
    {
        "value": 15352,
        "NOCCode": "7511",
        "JobTitle": "long combination vehicule (LCV) driver"
    },
    {
        "value": 15353,
        "NOCCode": "7511",
        "JobTitle": "long distance truck driver"
    },
    {
        "value": 15354,
        "NOCCode": "7511",
        "JobTitle": "long haul driver"
    },
    {
        "value": 15355,
        "NOCCode": "7511",
        "JobTitle": "long haul driver, groceries"
    },
    {
        "value": 15356,
        "NOCCode": "7511",
        "JobTitle": "long haul tractor-trailer driver"
    },
    {
        "value": 15357,
        "NOCCode": "7511",
        "JobTitle": "long haul truck driver"
    },
    {
        "value": 15358,
        "NOCCode": "7511",
        "JobTitle": "low-bed semi-trailer driver"
    },
    {
        "value": 15359,
        "NOCCode": "7511",
        "JobTitle": "mobile cement mixer driver"
    },
    {
        "value": 15360,
        "NOCCode": "7511",
        "JobTitle": "mobile concrete mixer driver"
    },
    {
        "value": 15361,
        "NOCCode": "7511",
        "JobTitle": "moving truck driver"
    },
    {
        "value": 15362,
        "NOCCode": "7511",
        "JobTitle": "moving van driver"
    },
    {
        "value": 15363,
        "NOCCode": "7511",
        "JobTitle": "muskeg tractor operator"
    },
    {
        "value": 15364,
        "NOCCode": "7511",
        "JobTitle": "oil transport driver"
    },
    {
        "value": 15365,
        "NOCCode": "7511",
        "JobTitle": "operator, gasoline truck"
    },
    {
        "value": 15366,
        "NOCCode": "7511",
        "JobTitle": "operator, truck"
    },
    {
        "value": 15367,
        "NOCCode": "7511",
        "JobTitle": "ready-mix driver – construction"
    },
    {
        "value": 15368,
        "NOCCode": "7511",
        "JobTitle": "ready-mix truck driver"
    },
    {
        "value": 15369,
        "NOCCode": "7511",
        "JobTitle": "short haul truck driver"
    },
    {
        "value": 15370,
        "NOCCode": "7511",
        "JobTitle": "shunt truck driver"
    },
    {
        "value": 15371,
        "NOCCode": "7511",
        "JobTitle": "shunter – trucking"
    },
    {
        "value": 15372,
        "NOCCode": "7511",
        "JobTitle": "shunter truck driver"
    },
    {
        "value": 15373,
        "NOCCode": "7511",
        "JobTitle": "tank truck driver"
    },
    {
        "value": 15374,
        "NOCCode": "7511",
        "JobTitle": "tow truck driver"
    },
    {
        "value": 15375,
        "NOCCode": "7511",
        "JobTitle": "tractor-trailer driver, long haul"
    },
    {
        "value": 15376,
        "NOCCode": "7511",
        "JobTitle": "transport driver"
    },
    {
        "value": 15377,
        "NOCCode": "7511",
        "JobTitle": "truck driver"
    },
    {
        "value": 15378,
        "NOCCode": "7511",
        "JobTitle": "truck driver, bulk milk"
    },
    {
        "value": 15379,
        "NOCCode": "7511",
        "JobTitle": "truck driver, cement – construction"
    },
    {
        "value": 15380,
        "NOCCode": "7511",
        "JobTitle": "truck driver, dangerous goods"
    },
    {
        "value": 15381,
        "NOCCode": "7511",
        "JobTitle": "truck driver, explosives"
    },
    {
        "value": 15382,
        "NOCCode": "7511",
        "JobTitle": "truck driver, flatbed"
    },
    {
        "value": 15383,
        "NOCCode": "7511",
        "JobTitle": "truck driver, freight"
    },
    {
        "value": 15384,
        "NOCCode": "7511",
        "JobTitle": "truck driver, heavy truck"
    },
    {
        "value": 15385,
        "NOCCode": "7511",
        "JobTitle": "truck driver, liquid fertilizer"
    },
    {
        "value": 15386,
        "NOCCode": "7511",
        "JobTitle": "truck driver, long distance"
    },
    {
        "value": 15387,
        "NOCCode": "7511",
        "JobTitle": "truck driver, long haul"
    },
    {
        "value": 15388,
        "NOCCode": "7511",
        "JobTitle": "truck driver, ready-mix"
    },
    {
        "value": 15389,
        "NOCCode": "7511",
        "JobTitle": "truck driver, tractor-trailer"
    },
    {
        "value": 15390,
        "NOCCode": "7511",
        "JobTitle": "truck driver, truck-trailer"
    },
    {
        "value": 15391,
        "NOCCode": "7511",
        "JobTitle": "truck mixer driver – construction"
    },
    {
        "value": 15392,
        "NOCCode": "7511",
        "JobTitle": "truck operator"
    },
    {
        "value": 15393,
        "NOCCode": "7511",
        "JobTitle": "truck owner operator"
    },
    {
        "value": 15394,
        "NOCCode": "7511",
        "JobTitle": "trucker"
    },
    {
        "value": 15395,
        "NOCCode": "7511",
        "JobTitle": "truck-trailer driver"
    },
    {
        "value": 15396,
        "NOCCode": "7512",
        "JobTitle": "articulated bus operator"
    },
    {
        "value": 15397,
        "NOCCode": "7512",
        "JobTitle": "bus driver"
    },
    {
        "value": 15398,
        "NOCCode": "7512",
        "JobTitle": "bus driver – special needs passengers"
    },
    {
        "value": 15399,
        "NOCCode": "7512",
        "JobTitle": "bus operator"
    },
    {
        "value": 15400,
        "NOCCode": "7512",
        "JobTitle": "bus operator – accessible services"
    },
    {
        "value": 15401,
        "NOCCode": "7512",
        "JobTitle": "charter bus driver"
    },
    {
        "value": 15402,
        "NOCCode": "7512",
        "JobTitle": "driver, bus"
    },
    {
        "value": 15403,
        "NOCCode": "7512",
        "JobTitle": "driver, motor coach"
    },
    {
        "value": 15404,
        "NOCCode": "7512",
        "JobTitle": "driver, public passenger transit"
    },
    {
        "value": 15405,
        "NOCCode": "7512",
        "JobTitle": "driver, school bus"
    },
    {
        "value": 15406,
        "NOCCode": "7512",
        "JobTitle": "intercity bus driver"
    },
    {
        "value": 15407,
        "NOCCode": "7512",
        "JobTitle": "light rail transit operator"
    },
    {
        "value": 15408,
        "NOCCode": "7512",
        "JobTitle": "long distance bus driver"
    },
    {
        "value": 15409,
        "NOCCode": "7512",
        "JobTitle": "motor coach driver"
    },
    {
        "value": 15410,
        "NOCCode": "7512",
        "JobTitle": "operator, bus"
    },
    {
        "value": 15411,
        "NOCCode": "7512",
        "JobTitle": "operator, streetcar"
    },
    {
        "value": 15412,
        "NOCCode": "7512",
        "JobTitle": "operator, subway"
    },
    {
        "value": 15413,
        "NOCCode": "7512",
        "JobTitle": "operator, urban transit"
    },
    {
        "value": 15414,
        "NOCCode": "7512",
        "JobTitle": "public passenger transit driver"
    },
    {
        "value": 15415,
        "NOCCode": "7512",
        "JobTitle": "school bus driver"
    },
    {
        "value": 15416,
        "NOCCode": "7512",
        "JobTitle": "school bus driver – special needs passengers"
    },
    {
        "value": 15417,
        "NOCCode": "7512",
        "JobTitle": "shuttle bus driver"
    },
    {
        "value": 15418,
        "NOCCode": "7512",
        "JobTitle": "shuttle operator"
    },
    {
        "value": 15419,
        "NOCCode": "7512",
        "JobTitle": "sightseeing bus driver"
    },
    {
        "value": 15420,
        "NOCCode": "7512",
        "JobTitle": "sightseeing tour driver"
    },
    {
        "value": 15421,
        "NOCCode": "7512",
        "JobTitle": "streetcar conductor"
    },
    {
        "value": 15422,
        "NOCCode": "7512",
        "JobTitle": "streetcar operator"
    },
    {
        "value": 15423,
        "NOCCode": "7512",
        "JobTitle": "subway train operator"
    },
    {
        "value": 15424,
        "NOCCode": "7512",
        "JobTitle": "tour bus operator"
    },
    {
        "value": 15425,
        "NOCCode": "7512",
        "JobTitle": "transit operator – transportation"
    },
    {
        "value": 15426,
        "NOCCode": "7512",
        "JobTitle": "trolley bus operator"
    },
    {
        "value": 15427,
        "NOCCode": "7512",
        "JobTitle": "trolley coach driver"
    },
    {
        "value": 15428,
        "NOCCode": "7512",
        "JobTitle": "urban transit operator"
    },
    {
        "value": 15429,
        "NOCCode": "7513",
        "JobTitle": "airport limousine driver"
    },
    {
        "value": 15430,
        "NOCCode": "7513",
        "JobTitle": "chauffeur, private household"
    },
    {
        "value": 15431,
        "NOCCode": "7513",
        "JobTitle": "company chauffeur"
    },
    {
        "value": 15432,
        "NOCCode": "7513",
        "JobTitle": "driver, funeral services"
    },
    {
        "value": 15433,
        "NOCCode": "7513",
        "JobTitle": "driver, hearse"
    },
    {
        "value": 15434,
        "NOCCode": "7513",
        "JobTitle": "driver, taxi"
    },
    {
        "value": 15435,
        "NOCCode": "7513",
        "JobTitle": "driver, taxicab"
    },
    {
        "value": 15436,
        "NOCCode": "7513",
        "JobTitle": "funeral chauffeur"
    },
    {
        "value": 15437,
        "NOCCode": "7513",
        "JobTitle": "funeral driver"
    },
    {
        "value": 15438,
        "NOCCode": "7513",
        "JobTitle": "hearse driver"
    },
    {
        "value": 15439,
        "NOCCode": "7513",
        "JobTitle": "limousine driver"
    },
    {
        "value": 15440,
        "NOCCode": "7513",
        "JobTitle": "private chauffeur"
    },
    {
        "value": 15441,
        "NOCCode": "7513",
        "JobTitle": "taxi driver"
    },
    {
        "value": 15442,
        "NOCCode": "7513",
        "JobTitle": "taxi owner-operator"
    },
    {
        "value": 15443,
        "NOCCode": "7513",
        "JobTitle": "taxicab driver"
    },
    {
        "value": 15444,
        "NOCCode": "7514",
        "JobTitle": "amusement machine route agent"
    },
    {
        "value": 15445,
        "NOCCode": "7514",
        "JobTitle": "bakery deliveryman/woman"
    },
    {
        "value": 15446,
        "NOCCode": "7514",
        "JobTitle": "bakery route salesman/woman"
    },
    {
        "value": 15447,
        "NOCCode": "7514",
        "JobTitle": "bookmobile driver"
    },
    {
        "value": 15448,
        "NOCCode": "7514",
        "JobTitle": "bread deliverer"
    },
    {
        "value": 15449,
        "NOCCode": "7514",
        "JobTitle": "bread route driver"
    },
    {
        "value": 15450,
        "NOCCode": "7514",
        "JobTitle": "canteen driver"
    },
    {
        "value": 15451,
        "NOCCode": "7514",
        "JobTitle": "courier driver"
    },
    {
        "value": 15452,
        "NOCCode": "7514",
        "JobTitle": "courier service driver"
    },
    {
        "value": 15453,
        "NOCCode": "7514",
        "JobTitle": "courier van driver"
    },
    {
        "value": 15454,
        "NOCCode": "7514",
        "JobTitle": "dairy route salesman/woman"
    },
    {
        "value": 15455,
        "NOCCode": "7514",
        "JobTitle": "delivery driver"
    },
    {
        "value": 15456,
        "NOCCode": "7514",
        "JobTitle": "delivery driver – parcels"
    },
    {
        "value": 15457,
        "NOCCode": "7514",
        "JobTitle": "delivery driver, fast food"
    },
    {
        "value": 15458,
        "NOCCode": "7514",
        "JobTitle": "delivery salesperson"
    },
    {
        "value": 15459,
        "NOCCode": "7514",
        "JobTitle": "delivery truck driver"
    },
    {
        "value": 15460,
        "NOCCode": "7514",
        "JobTitle": "deliveryman/woman, bakery"
    },
    {
        "value": 15461,
        "NOCCode": "7514",
        "JobTitle": "driver salesman/woman"
    },
    {
        "value": 15462,
        "NOCCode": "7514",
        "JobTitle": "driver salesperson"
    },
    {
        "value": 15463,
        "NOCCode": "7514",
        "JobTitle": "driver, courier service"
    },
    {
        "value": 15464,
        "NOCCode": "7514",
        "JobTitle": "driver, fast food service"
    },
    {
        "value": 15465,
        "NOCCode": "7514",
        "JobTitle": "driver, small vans"
    },
    {
        "value": 15466,
        "NOCCode": "7514",
        "JobTitle": "drug store delivery driver"
    },
    {
        "value": 15467,
        "NOCCode": "7514",
        "JobTitle": "dry cleaning driver"
    },
    {
        "value": 15468,
        "NOCCode": "7514",
        "JobTitle": "expedite driver"
    },
    {
        "value": 15469,
        "NOCCode": "7514",
        "JobTitle": "fast food delivery driver"
    },
    {
        "value": 15470,
        "NOCCode": "7514",
        "JobTitle": "food delivery driver"
    },
    {
        "value": 15471,
        "NOCCode": "7514",
        "JobTitle": "food service driver"
    },
    {
        "value": 15472,
        "NOCCode": "7514",
        "JobTitle": "groceries deliverer"
    },
    {
        "value": 15473,
        "NOCCode": "7514",
        "JobTitle": "laundry route driver"
    },
    {
        "value": 15474,
        "NOCCode": "7514",
        "JobTitle": "mail service courier"
    },
    {
        "value": 15475,
        "NOCCode": "7514",
        "JobTitle": "milk deliverer"
    },
    {
        "value": 15476,
        "NOCCode": "7514",
        "JobTitle": "milkman/woman"
    },
    {
        "value": 15477,
        "NOCCode": "7514",
        "JobTitle": "mobile canteen driver"
    },
    {
        "value": 15478,
        "NOCCode": "7514",
        "JobTitle": "mobile caterer"
    },
    {
        "value": 15479,
        "NOCCode": "7514",
        "JobTitle": "motorized mail courier"
    },
    {
        "value": 15480,
        "NOCCode": "7514",
        "JobTitle": "newspaper delivery driver"
    },
    {
        "value": 15481,
        "NOCCode": "7514",
        "JobTitle": "parts delivery driver"
    },
    {
        "value": 15482,
        "NOCCode": "7514",
        "JobTitle": "pizza delivery driver"
    },
    {
        "value": 15483,
        "NOCCode": "7514",
        "JobTitle": "route driver"
    },
    {
        "value": 15484,
        "NOCCode": "7514",
        "JobTitle": "soft drink driver-salesman/woman"
    },
    {
        "value": 15485,
        "NOCCode": "7514",
        "JobTitle": "soft drinks driver"
    },
    {
        "value": 15486,
        "NOCCode": "7514",
        "JobTitle": "takeout pizza driver"
    },
    {
        "value": 15487,
        "NOCCode": "7514",
        "JobTitle": "telephone directory deliverer – courier service"
    },
    {
        "value": 15488,
        "NOCCode": "7514",
        "JobTitle": "town messenger driver"
    },
    {
        "value": 15489,
        "NOCCode": "7514",
        "JobTitle": "vending machine driver-supplier"
    },
    {
        "value": 15490,
        "NOCCode": "7521",
        "JobTitle": "aerial tram operator"
    },
    {
        "value": 15491,
        "NOCCode": "7521",
        "JobTitle": "apprentice heavy equipment operator"
    },
    {
        "value": 15492,
        "NOCCode": "7521",
        "JobTitle": "asphalt batch plant operator"
    },
    {
        "value": 15493,
        "NOCCode": "7521",
        "JobTitle": "asphalt plant operator"
    },
    {
        "value": 15494,
        "NOCCode": "7521",
        "JobTitle": "asphalt roller operator"
    },
    {
        "value": 15495,
        "NOCCode": "7521",
        "JobTitle": "asphalt spreader operator"
    },
    {
        "value": 15496,
        "NOCCode": "7521",
        "JobTitle": "asphalt street paver operator"
    },
    {
        "value": 15497,
        "NOCCode": "7521",
        "JobTitle": "asphalt-grooving machine operator"
    },
    {
        "value": 15498,
        "NOCCode": "7521",
        "JobTitle": "asphalt-paving machine operator"
    },
    {
        "value": 15499,
        "NOCCode": "7521",
        "JobTitle": "asphalt-paving screed operator"
    },
    {
        "value": 15500,
        "NOCCode": "7521",
        "JobTitle": "backhoe operator"
    },
    {
        "value": 15501,
        "NOCCode": "7521",
        "JobTitle": "bituminous paver operator – heavy equipment"
    },
    {
        "value": 15502,
        "NOCCode": "7521",
        "JobTitle": "bobcat operator"
    },
    {
        "value": 15503,
        "NOCCode": "7521",
        "JobTitle": "boom-conveyor operator"
    },
    {
        "value": 15504,
        "NOCCode": "7521",
        "JobTitle": "bucketwheel operator"
    },
    {
        "value": 15505,
        "NOCCode": "7521",
        "JobTitle": "bucketwheel-excavator operator"
    },
    {
        "value": 15506,
        "NOCCode": "7521",
        "JobTitle": "buggy-operator skinner"
    },
    {
        "value": 15507,
        "NOCCode": "7521",
        "JobTitle": "buggy-scraper operator"
    },
    {
        "value": 15508,
        "NOCCode": "7521",
        "JobTitle": "bulldozer driver"
    },
    {
        "value": 15509,
        "NOCCode": "7521",
        "JobTitle": "bulldozer operator"
    },
    {
        "value": 15510,
        "NOCCode": "7521",
        "JobTitle": "cat operator"
    },
    {
        "value": 15511,
        "NOCCode": "7521",
        "JobTitle": "cat skinner"
    },
    {
        "value": 15512,
        "NOCCode": "7521",
        "JobTitle": "caterpillar car operator"
    },
    {
        "value": 15513,
        "NOCCode": "7521",
        "JobTitle": "caterpillar operator"
    },
    {
        "value": 15514,
        "NOCCode": "7521",
        "JobTitle": "concrete spreader operator – heavy equipment"
    },
    {
        "value": 15515,
        "NOCCode": "7521",
        "JobTitle": "concrete-grooving machine operator"
    },
    {
        "value": 15516,
        "NOCCode": "7521",
        "JobTitle": "concrete-paving machine operator"
    },
    {
        "value": 15517,
        "NOCCode": "7521",
        "JobTitle": "construction equipment operator"
    },
    {
        "value": 15518,
        "NOCCode": "7521",
        "JobTitle": "continuous bucket excavator operator"
    },
    {
        "value": 15519,
        "NOCCode": "7521",
        "JobTitle": "crawler tractor operator"
    },
    {
        "value": 15520,
        "NOCCode": "7521",
        "JobTitle": "ditcher operator – construction"
    },
    {
        "value": 15521,
        "NOCCode": "7521",
        "JobTitle": "ditching machine operator"
    },
    {
        "value": 15522,
        "NOCCode": "7521",
        "JobTitle": "dozer operator"
    },
    {
        "value": 15523,
        "NOCCode": "7521",
        "JobTitle": "dredge operator"
    },
    {
        "value": 15524,
        "NOCCode": "7521",
        "JobTitle": "dredge runner"
    },
    {
        "value": 15525,
        "NOCCode": "7521",
        "JobTitle": "earth-moving equipment operator"
    },
    {
        "value": 15526,
        "NOCCode": "7521",
        "JobTitle": "electrical equipment operator – shovel"
    },
    {
        "value": 15527,
        "NOCCode": "7521",
        "JobTitle": "elevating grader operator"
    },
    {
        "value": 15528,
        "NOCCode": "7521",
        "JobTitle": "elevating scraper operator"
    },
    {
        "value": 15529,
        "NOCCode": "7521",
        "JobTitle": "equipment operator, heavy-duty"
    },
    {
        "value": 15530,
        "NOCCode": "7521",
        "JobTitle": "excavating machine operator"
    },
    {
        "value": 15531,
        "NOCCode": "7521",
        "JobTitle": "excavator operator"
    },
    {
        "value": 15532,
        "NOCCode": "7521",
        "JobTitle": "front end loader operator"
    },
    {
        "value": 15533,
        "NOCCode": "7521",
        "JobTitle": "gradall operator"
    },
    {
        "value": 15534,
        "NOCCode": "7521",
        "JobTitle": "grader operator"
    },
    {
        "value": 15535,
        "NOCCode": "7521",
        "JobTitle": "groove-cutting machine operator"
    },
    {
        "value": 15536,
        "NOCCode": "7521",
        "JobTitle": "grooving machine operator – heavy equipment"
    },
    {
        "value": 15537,
        "NOCCode": "7521",
        "JobTitle": "hammer operator, pile driving"
    },
    {
        "value": 15538,
        "NOCCode": "7521",
        "JobTitle": "heater-planer operator"
    },
    {
        "value": 15539,
        "NOCCode": "7521",
        "JobTitle": "heavy construction-equipment operator"
    },
    {
        "value": 15540,
        "NOCCode": "7521",
        "JobTitle": "heavy equipment operator"
    },
    {
        "value": 15541,
        "NOCCode": "7521",
        "JobTitle": "heavy equipment operator apprentice"
    },
    {
        "value": 15542,
        "NOCCode": "7521",
        "JobTitle": "heavy-duty equipment operator"
    },
    {
        "value": 15543,
        "NOCCode": "7521",
        "JobTitle": "heavy-duty equipment operator apprentice"
    },
    {
        "value": 15544,
        "NOCCode": "7521",
        "JobTitle": "ho-ram operator"
    },
    {
        "value": 15545,
        "NOCCode": "7521",
        "JobTitle": "horizontal earth-boring machine operator"
    },
    {
        "value": 15546,
        "NOCCode": "7521",
        "JobTitle": "hydraulic excavator operator"
    },
    {
        "value": 15547,
        "NOCCode": "7521",
        "JobTitle": "industrial backhoe operator"
    },
    {
        "value": 15548,
        "NOCCode": "7521",
        "JobTitle": "loader operator – construction"
    },
    {
        "value": 15549,
        "NOCCode": "7521",
        "JobTitle": "mining shovel operator (except underground)"
    },
    {
        "value": 15550,
        "NOCCode": "7521",
        "JobTitle": "mole operator"
    },
    {
        "value": 15551,
        "NOCCode": "7521",
        "JobTitle": "mucker operator – construction"
    },
    {
        "value": 15552,
        "NOCCode": "7521",
        "JobTitle": "mucking machine operator – construction"
    },
    {
        "value": 15553,
        "NOCCode": "7521",
        "JobTitle": "operating engineer, heavy equipment"
    },
    {
        "value": 15554,
        "NOCCode": "7521",
        "JobTitle": "operator, asphalt plant"
    },
    {
        "value": 15555,
        "NOCCode": "7521",
        "JobTitle": "operator, heavy construction-equipment"
    },
    {
        "value": 15556,
        "NOCCode": "7521",
        "JobTitle": "operator, pile-driving hammer"
    },
    {
        "value": 15557,
        "NOCCode": "7521",
        "JobTitle": "operator, side boom tractor"
    },
    {
        "value": 15558,
        "NOCCode": "7521",
        "JobTitle": "paver operator"
    },
    {
        "value": 15559,
        "NOCCode": "7521",
        "JobTitle": "payloader operator"
    },
    {
        "value": 15560,
        "NOCCode": "7521",
        "JobTitle": "peat-moss-cutting machine operator"
    },
    {
        "value": 15561,
        "NOCCode": "7521",
        "JobTitle": "peat-moss-gathering machine operator"
    },
    {
        "value": 15562,
        "NOCCode": "7521",
        "JobTitle": "pile driver winch operator"
    },
    {
        "value": 15563,
        "NOCCode": "7521",
        "JobTitle": "pile-driving hammer operator"
    },
    {
        "value": 15564,
        "NOCCode": "7521",
        "JobTitle": "pipelayer operator"
    },
    {
        "value": 15565,
        "NOCCode": "7521",
        "JobTitle": "power grader operator"
    },
    {
        "value": 15566,
        "NOCCode": "7521",
        "JobTitle": "power shovel operator"
    },
    {
        "value": 15567,
        "NOCCode": "7521",
        "JobTitle": "pulvimixer operator"
    },
    {
        "value": 15568,
        "NOCCode": "7521",
        "JobTitle": "ripper operator – heavy equipment"
    },
    {
        "value": 15569,
        "NOCCode": "7521",
        "JobTitle": "road grader operator"
    },
    {
        "value": 15570,
        "NOCCode": "7521",
        "JobTitle": "road mixer operator"
    },
    {
        "value": 15571,
        "NOCCode": "7521",
        "JobTitle": "road planer operator"
    },
    {
        "value": 15572,
        "NOCCode": "7521",
        "JobTitle": "road roller operator"
    },
    {
        "value": 15573,
        "NOCCode": "7521",
        "JobTitle": "roller operator"
    },
    {
        "value": 15574,
        "NOCCode": "7521",
        "JobTitle": "rotary trencher operator"
    },
    {
        "value": 15575,
        "NOCCode": "7521",
        "JobTitle": "scarifier operator"
    },
    {
        "value": 15576,
        "NOCCode": "7521",
        "JobTitle": "scraper operator"
    },
    {
        "value": 15577,
        "NOCCode": "7521",
        "JobTitle": "screed operator, asphalt paving"
    },
    {
        "value": 15578,
        "NOCCode": "7521",
        "JobTitle": "shield operator"
    },
    {
        "value": 15579,
        "NOCCode": "7521",
        "JobTitle": "shovel operator – heavy equipment"
    },
    {
        "value": 15580,
        "NOCCode": "7521",
        "JobTitle": "side boom operator – pipeline construction"
    },
    {
        "value": 15581,
        "NOCCode": "7521",
        "JobTitle": "side boom tractor operator"
    },
    {
        "value": 15582,
        "NOCCode": "7521",
        "JobTitle": "snow-grooming machine operator"
    },
    {
        "value": 15583,
        "NOCCode": "7521",
        "JobTitle": "spreader operator – heavy equipment"
    },
    {
        "value": 15584,
        "NOCCode": "7521",
        "JobTitle": "stacker operator – heavy equipment"
    },
    {
        "value": 15585,
        "NOCCode": "7521",
        "JobTitle": "street paver operator"
    },
    {
        "value": 15586,
        "NOCCode": "7521",
        "JobTitle": "street paver, asphalt operator"
    },
    {
        "value": 15587,
        "NOCCode": "7521",
        "JobTitle": "stripping shovel operator"
    },
    {
        "value": 15588,
        "NOCCode": "7521",
        "JobTitle": "structural hydraulic jack erector"
    },
    {
        "value": 15589,
        "NOCCode": "7521",
        "JobTitle": "surface mining equipment operator"
    },
    {
        "value": 15590,
        "NOCCode": "7521",
        "JobTitle": "track-loader operator"
    },
    {
        "value": 15591,
        "NOCCode": "7521",
        "JobTitle": "tractor operator – construction"
    },
    {
        "value": 15592,
        "NOCCode": "7521",
        "JobTitle": "tractor-excavator operator"
    },
    {
        "value": 15593,
        "NOCCode": "7521",
        "JobTitle": "trench-digging machine operator"
    },
    {
        "value": 15594,
        "NOCCode": "7521",
        "JobTitle": "trenching machine operator"
    },
    {
        "value": 15595,
        "NOCCode": "7521",
        "JobTitle": "tunnelling machine operator"
    },
    {
        "value": 15596,
        "NOCCode": "7521",
        "JobTitle": "vibratory road roller operator"
    },
    {
        "value": 15597,
        "NOCCode": "7521",
        "JobTitle": "vibratory roller operator"
    },
    {
        "value": 15598,
        "NOCCode": "7522",
        "JobTitle": "collection truck driver – public works"
    },
    {
        "value": 15599,
        "NOCCode": "7522",
        "JobTitle": "drain roto servicer – public works"
    },
    {
        "value": 15600,
        "NOCCode": "7522",
        "JobTitle": "driver, sprinkling truck – public works"
    },
    {
        "value": 15601,
        "NOCCode": "7522",
        "JobTitle": "electrical forester"
    },
    {
        "value": 15602,
        "NOCCode": "7522",
        "JobTitle": "equipment operator – municipal public work"
    },
    {
        "value": 15603,
        "NOCCode": "7522",
        "JobTitle": "garbage collector – driver"
    },
    {
        "value": 15604,
        "NOCCode": "7522",
        "JobTitle": "garbage truck driver"
    },
    {
        "value": 15605,
        "NOCCode": "7522",
        "JobTitle": "garbage truck driver – public works"
    },
    {
        "value": 15606,
        "NOCCode": "7522",
        "JobTitle": "highway snow removal equipment operator"
    },
    {
        "value": 15607,
        "NOCCode": "7522",
        "JobTitle": "highway snowplough operator"
    },
    {
        "value": 15608,
        "NOCCode": "7522",
        "JobTitle": "maintenance equipment operator – public works"
    },
    {
        "value": 15609,
        "NOCCode": "7522",
        "JobTitle": "mechanical sweeper operator – public works"
    },
    {
        "value": 15610,
        "NOCCode": "7522",
        "JobTitle": "municipal maintenance equipment operator"
    },
    {
        "value": 15611,
        "NOCCode": "7522",
        "JobTitle": "municipal servicer drain roto"
    },
    {
        "value": 15612,
        "NOCCode": "7522",
        "JobTitle": "power pole inspector"
    },
    {
        "value": 15613,
        "NOCCode": "7522",
        "JobTitle": "power sweeper operator – public works"
    },
    {
        "value": 15614,
        "NOCCode": "7522",
        "JobTitle": "public works maintenance equipment operator"
    },
    {
        "value": 15615,
        "NOCCode": "7522",
        "JobTitle": "recycling truck driver"
    },
    {
        "value": 15616,
        "NOCCode": "7522",
        "JobTitle": "recycling truck driver – public works"
    },
    {
        "value": 15617,
        "NOCCode": "7522",
        "JobTitle": "refuse truck driver – public works"
    },
    {
        "value": 15618,
        "NOCCode": "7522",
        "JobTitle": "road snowplough operator – public works"
    },
    {
        "value": 15619,
        "NOCCode": "7522",
        "JobTitle": "salt truck operator"
    },
    {
        "value": 15620,
        "NOCCode": "7522",
        "JobTitle": "salt truck operator – public works"
    },
    {
        "value": 15621,
        "NOCCode": "7522",
        "JobTitle": "sand spreader operator"
    },
    {
        "value": 15622,
        "NOCCode": "7522",
        "JobTitle": "sand spreader operator – public works"
    },
    {
        "value": 15623,
        "NOCCode": "7522",
        "JobTitle": "sanding truck operator – public works"
    },
    {
        "value": 15624,
        "NOCCode": "7522",
        "JobTitle": "sanitation truck driver"
    },
    {
        "value": 15625,
        "NOCCode": "7522",
        "JobTitle": "sewer flusher operator-driver – public works"
    },
    {
        "value": 15626,
        "NOCCode": "7522",
        "JobTitle": "sewer jet cleaner operator – public works"
    },
    {
        "value": 15627,
        "NOCCode": "7522",
        "JobTitle": "sewer jet machine operator – public works"
    },
    {
        "value": 15628,
        "NOCCode": "7522",
        "JobTitle": "sewer-flushing truck operator"
    },
    {
        "value": 15629,
        "NOCCode": "7522",
        "JobTitle": "sewer-flushing truck operator – public works"
    },
    {
        "value": 15630,
        "NOCCode": "7522",
        "JobTitle": "sewer-pipe-cleaning machine operator – public works"
    },
    {
        "value": 15631,
        "NOCCode": "7522",
        "JobTitle": "snow removal equipment operator – public works"
    },
    {
        "value": 15632,
        "NOCCode": "7522",
        "JobTitle": "snowplough operator, highway"
    },
    {
        "value": 15633,
        "NOCCode": "7522",
        "JobTitle": "snowplough operator, road – public works"
    },
    {
        "value": 15634,
        "NOCCode": "7522",
        "JobTitle": "sprinkling truck driver – public works"
    },
    {
        "value": 15635,
        "NOCCode": "7522",
        "JobTitle": "street flusher operator"
    },
    {
        "value": 15636,
        "NOCCode": "7522",
        "JobTitle": "street flusher operator – public works"
    },
    {
        "value": 15637,
        "NOCCode": "7522",
        "JobTitle": "street sprinkler operator – public works"
    },
    {
        "value": 15638,
        "NOCCode": "7522",
        "JobTitle": "street sweeper operator"
    },
    {
        "value": 15639,
        "NOCCode": "7522",
        "JobTitle": "street sweeper operator – public works"
    },
    {
        "value": 15640,
        "NOCCode": "7522",
        "JobTitle": "urban snow removal equipment operator – public works"
    },
    {
        "value": 15641,
        "NOCCode": "7522",
        "JobTitle": "utility arborist"
    },
    {
        "value": 15642,
        "NOCCode": "7522",
        "JobTitle": "utility forester"
    },
    {
        "value": 15643,
        "NOCCode": "7522",
        "JobTitle": "utility locator"
    },
    {
        "value": 15644,
        "NOCCode": "7522",
        "JobTitle": "utility pole inspector"
    },
    {
        "value": 15645,
        "NOCCode": "7522",
        "JobTitle": "utility pole tester"
    },
    {
        "value": 15646,
        "NOCCode": "7522",
        "JobTitle": "utility tree trimmer"
    },
    {
        "value": 15647,
        "NOCCode": "7522",
        "JobTitle": "utility tree worker"
    },
    {
        "value": 15648,
        "NOCCode": "7531",
        "JobTitle": "attendant, locomotives – railway yard"
    },
    {
        "value": 15649,
        "NOCCode": "7531",
        "JobTitle": "car controller – railway"
    },
    {
        "value": 15650,
        "NOCCode": "7531",
        "JobTitle": "car retarder operator – railway yard"
    },
    {
        "value": 15651,
        "NOCCode": "7531",
        "JobTitle": "control tower operator – railway"
    },
    {
        "value": 15652,
        "NOCCode": "7531",
        "JobTitle": "controller, cars – railway"
    },
    {
        "value": 15653,
        "NOCCode": "7531",
        "JobTitle": "controller, railway cars – railway yard"
    },
    {
        "value": 15654,
        "NOCCode": "7531",
        "JobTitle": "controller, tank cars – railway yard"
    },
    {
        "value": 15655,
        "NOCCode": "7531",
        "JobTitle": "coupler – railway yard"
    },
    {
        "value": 15656,
        "NOCCode": "7531",
        "JobTitle": "distributor, freight cars – railway yard"
    },
    {
        "value": 15657,
        "NOCCode": "7531",
        "JobTitle": "distributor, railway cars"
    },
    {
        "value": 15658,
        "NOCCode": "7531",
        "JobTitle": "freight car distributor – railway yard"
    },
    {
        "value": 15659,
        "NOCCode": "7531",
        "JobTitle": "greaser, journal boxes – railway yard"
    },
    {
        "value": 15660,
        "NOCCode": "7531",
        "JobTitle": "greaser, railway cars"
    },
    {
        "value": 15661,
        "NOCCode": "7531",
        "JobTitle": "interlocking cabin switcher – railway yard"
    },
    {
        "value": 15662,
        "NOCCode": "7531",
        "JobTitle": "interlocking signal box switcher – railway yard"
    },
    {
        "value": 15663,
        "NOCCode": "7531",
        "JobTitle": "journal box greaser – railway yard"
    },
    {
        "value": 15664,
        "NOCCode": "7531",
        "JobTitle": "locomotive attendant – railway yard"
    },
    {
        "value": 15665,
        "NOCCode": "7531",
        "JobTitle": "locomotive distributor – railway yard"
    },
    {
        "value": 15666,
        "NOCCode": "7531",
        "JobTitle": "locomotive engine servicer"
    },
    {
        "value": 15667,
        "NOCCode": "7531",
        "JobTitle": "locomotive maintenance attendant"
    },
    {
        "value": 15668,
        "NOCCode": "7531",
        "JobTitle": "locomotive servicer"
    },
    {
        "value": 15669,
        "NOCCode": "7531",
        "JobTitle": "lubricator, railway cars"
    },
    {
        "value": 15670,
        "NOCCode": "7531",
        "JobTitle": "oiler, railway cars"
    },
    {
        "value": 15671,
        "NOCCode": "7531",
        "JobTitle": "operator, retarder – railway yard"
    },
    {
        "value": 15672,
        "NOCCode": "7531",
        "JobTitle": "operator, turntable – railway yard"
    },
    {
        "value": 15673,
        "NOCCode": "7531",
        "JobTitle": "railway car controller – railway yard"
    },
    {
        "value": 15674,
        "NOCCode": "7531",
        "JobTitle": "railway car distributor"
    },
    {
        "value": 15675,
        "NOCCode": "7531",
        "JobTitle": "railway car greaser"
    },
    {
        "value": 15676,
        "NOCCode": "7531",
        "JobTitle": "railway car lubricating specialist"
    },
    {
        "value": 15677,
        "NOCCode": "7531",
        "JobTitle": "railway car lubricator"
    },
    {
        "value": 15678,
        "NOCCode": "7531",
        "JobTitle": "railway car oiler"
    },
    {
        "value": 15679,
        "NOCCode": "7531",
        "JobTitle": "railway signalman/woman"
    },
    {
        "value": 15680,
        "NOCCode": "7531",
        "JobTitle": "railway tank car servicer"
    },
    {
        "value": 15681,
        "NOCCode": "7531",
        "JobTitle": "railway train engine maintenance worker"
    },
    {
        "value": 15682,
        "NOCCode": "7531",
        "JobTitle": "retarder operator – railway yard"
    },
    {
        "value": 15683,
        "NOCCode": "7531",
        "JobTitle": "servicer, locomotives"
    },
    {
        "value": 15684,
        "NOCCode": "7531",
        "JobTitle": "signal tower operator – railway"
    },
    {
        "value": 15685,
        "NOCCode": "7531",
        "JobTitle": "signalman/woman – railway transport"
    },
    {
        "value": 15686,
        "NOCCode": "7531",
        "JobTitle": "signalman/woman, railway"
    },
    {
        "value": 15687,
        "NOCCode": "7531",
        "JobTitle": "switch tender – railway yard"
    },
    {
        "value": 15688,
        "NOCCode": "7531",
        "JobTitle": "switcher – railway yard"
    },
    {
        "value": 15689,
        "NOCCode": "7531",
        "JobTitle": "switcher, interlocking cabins – railway yard"
    },
    {
        "value": 15690,
        "NOCCode": "7531",
        "JobTitle": "switcher, interlocking signal boxes – railway yard"
    },
    {
        "value": 15691,
        "NOCCode": "7531",
        "JobTitle": "switcher, switching station – railway yard"
    },
    {
        "value": 15692,
        "NOCCode": "7531",
        "JobTitle": "switching station switcher – railway yard"
    },
    {
        "value": 15693,
        "NOCCode": "7531",
        "JobTitle": "switchman/woman – railway"
    },
    {
        "value": 15694,
        "NOCCode": "7531",
        "JobTitle": "switchman/woman, yard – railway transport"
    },
    {
        "value": 15695,
        "NOCCode": "7531",
        "JobTitle": "tank car controller – railway yard"
    },
    {
        "value": 15696,
        "NOCCode": "7531",
        "JobTitle": "tower operator – railway transport"
    },
    {
        "value": 15697,
        "NOCCode": "7531",
        "JobTitle": "towerman/woman – railway transport"
    },
    {
        "value": 15698,
        "NOCCode": "7531",
        "JobTitle": "trackman/woman – railway yard"
    },
    {
        "value": 15699,
        "NOCCode": "7531",
        "JobTitle": "trainman/woman – railway yard"
    },
    {
        "value": 15700,
        "NOCCode": "7531",
        "JobTitle": "trainman/woman, yard"
    },
    {
        "value": 15701,
        "NOCCode": "7531",
        "JobTitle": "transfer table operator – railway yard"
    },
    {
        "value": 15702,
        "NOCCode": "7531",
        "JobTitle": "turntable operator – railway yard"
    },
    {
        "value": 15703,
        "NOCCode": "7531",
        "JobTitle": "yard coupler – railway"
    },
    {
        "value": 15704,
        "NOCCode": "7531",
        "JobTitle": "yard switchman/woman – railway transport"
    },
    {
        "value": 15705,
        "NOCCode": "7531",
        "JobTitle": "yard trainman/woman"
    },
    {
        "value": 15706,
        "NOCCode": "7531",
        "JobTitle": "yard worker – railway"
    },
    {
        "value": 15707,
        "NOCCode": "7531",
        "JobTitle": "yardman/woman – railway"
    },
    {
        "value": 15708,
        "NOCCode": "7532",
        "JobTitle": "able seaman/woman – except armed forces"
    },
    {
        "value": 15709,
        "NOCCode": "7532",
        "JobTitle": "barge deckhand"
    },
    {
        "value": 15710,
        "NOCCode": "7532",
        "JobTitle": "boatswain"
    },
    {
        "value": 15711,
        "NOCCode": "7532",
        "JobTitle": "boatswain – water transport"
    },
    {
        "value": 15712,
        "NOCCode": "7532",
        "JobTitle": "boiler tender – ships"
    },
    {
        "value": 15713,
        "NOCCode": "7532",
        "JobTitle": "deckhand"
    },
    {
        "value": 15714,
        "NOCCode": "7532",
        "JobTitle": "deckhand – ships"
    },
    {
        "value": 15715,
        "NOCCode": "7532",
        "JobTitle": "deckhand, dredge"
    },
    {
        "value": 15716,
        "NOCCode": "7532",
        "JobTitle": "dredge deckhand"
    },
    {
        "value": 15717,
        "NOCCode": "7532",
        "JobTitle": "engine and boiler room crew member – ships"
    },
    {
        "value": 15718,
        "NOCCode": "7532",
        "JobTitle": "engine room crew member – ship"
    },
    {
        "value": 15719,
        "NOCCode": "7532",
        "JobTitle": "engine room crew member, ship"
    },
    {
        "value": 15720,
        "NOCCode": "7532",
        "JobTitle": "engine room greaser – marine"
    },
    {
        "value": 15721,
        "NOCCode": "7532",
        "JobTitle": "engine room greaser – water transport"
    },
    {
        "value": 15722,
        "NOCCode": "7532",
        "JobTitle": "engine room mechanical assistant – ships"
    },
    {
        "value": 15723,
        "NOCCode": "7532",
        "JobTitle": "engine room mechanical assistant, ship"
    },
    {
        "value": 15724,
        "NOCCode": "7532",
        "JobTitle": "ferryboat deckhand"
    },
    {
        "value": 15725,
        "NOCCode": "7532",
        "JobTitle": "greaser, engine room"
    },
    {
        "value": 15726,
        "NOCCode": "7532",
        "JobTitle": "greaser, engine room – marine"
    },
    {
        "value": 15727,
        "NOCCode": "7532",
        "JobTitle": "helmsman/woman – water transport"
    },
    {
        "value": 15728,
        "NOCCode": "7532",
        "JobTitle": "log boom tugboat deckhand"
    },
    {
        "value": 15729,
        "NOCCode": "7532",
        "JobTitle": "marine engine oiler"
    },
    {
        "value": 15730,
        "NOCCode": "7532",
        "JobTitle": "marine engineering mechanic"
    },
    {
        "value": 15731,
        "NOCCode": "7532",
        "JobTitle": "marine engineering technician"
    },
    {
        "value": 15732,
        "NOCCode": "7532",
        "JobTitle": "marine oiler"
    },
    {
        "value": 15733,
        "NOCCode": "7532",
        "JobTitle": "mechanical assistant – water transport"
    },
    {
        "value": 15734,
        "NOCCode": "7532",
        "JobTitle": "mechanical assistant, engine room – ships"
    },
    {
        "value": 15735,
        "NOCCode": "7532",
        "JobTitle": "mechanical assistant, ship's engine room"
    },
    {
        "value": 15736,
        "NOCCode": "7532",
        "JobTitle": "oiler, marine"
    },
    {
        "value": 15737,
        "NOCCode": "7532",
        "JobTitle": "ordinary seaman/woman – except armed forces"
    },
    {
        "value": 15738,
        "NOCCode": "7532",
        "JobTitle": "pump seaman/woman"
    },
    {
        "value": 15739,
        "NOCCode": "7532",
        "JobTitle": "pumpman/woman – ships"
    },
    {
        "value": 15740,
        "NOCCode": "7532",
        "JobTitle": "quartermaster"
    },
    {
        "value": 15741,
        "NOCCode": "7532",
        "JobTitle": "seaman/woman"
    },
    {
        "value": 15742,
        "NOCCode": "7532",
        "JobTitle": "ship boiler tender"
    },
    {
        "value": 15743,
        "NOCCode": "7532",
        "JobTitle": "ship engine room greaser"
    },
    {
        "value": 15744,
        "NOCCode": "7532",
        "JobTitle": "ship engine room mechanical assistant"
    },
    {
        "value": 15745,
        "NOCCode": "7532",
        "JobTitle": "ship stoker"
    },
    {
        "value": 15746,
        "NOCCode": "7532",
        "JobTitle": "ship tunnelman/woman"
    },
    {
        "value": 15747,
        "NOCCode": "7532",
        "JobTitle": "ship watchman/woman"
    },
    {
        "value": 15748,
        "NOCCode": "7532",
        "JobTitle": "ship's carpenter"
    },
    {
        "value": 15749,
        "NOCCode": "7532",
        "JobTitle": "stoker – ships"
    },
    {
        "value": 15750,
        "NOCCode": "7532",
        "JobTitle": "tanker pumper – ship"
    },
    {
        "value": 15751,
        "NOCCode": "7532",
        "JobTitle": "tugboat deckhand"
    },
    {
        "value": 15752,
        "NOCCode": "7532",
        "JobTitle": "tunnel operator, ship"
    },
    {
        "value": 15753,
        "NOCCode": "7532",
        "JobTitle": "tunnelman/woman, ship"
    },
    {
        "value": 15754,
        "NOCCode": "7532",
        "JobTitle": "watchman/woman, ship"
    },
    {
        "value": 15755,
        "NOCCode": "7532",
        "JobTitle": "wheelsman/woman"
    },
    {
        "value": 15756,
        "NOCCode": "7533",
        "JobTitle": "attendant, ferry terminal"
    },
    {
        "value": 15757,
        "NOCCode": "7533",
        "JobTitle": "barge captain"
    },
    {
        "value": 15758,
        "NOCCode": "7533",
        "JobTitle": "barge operator"
    },
    {
        "value": 15759,
        "NOCCode": "7533",
        "JobTitle": "beachcomber"
    },
    {
        "value": 15760,
        "NOCCode": "7533",
        "JobTitle": "bridge attendant – canal lock systems"
    },
    {
        "value": 15761,
        "NOCCode": "7533",
        "JobTitle": "bridge keeper – lock system"
    },
    {
        "value": 15762,
        "NOCCode": "7533",
        "JobTitle": "bridgemaster"
    },
    {
        "value": 15763,
        "NOCCode": "7533",
        "JobTitle": "bridgemaster – lock systems"
    },
    {
        "value": 15764,
        "NOCCode": "7533",
        "JobTitle": "cable ferry deckhand"
    },
    {
        "value": 15765,
        "NOCCode": "7533",
        "JobTitle": "cable ferry operator"
    },
    {
        "value": 15766,
        "NOCCode": "7533",
        "JobTitle": "cable ferry operator, manual"
    },
    {
        "value": 15767,
        "NOCCode": "7533",
        "JobTitle": "canal lock operator"
    },
    {
        "value": 15768,
        "NOCCode": "7533",
        "JobTitle": "canal lock tender"
    },
    {
        "value": 15769,
        "NOCCode": "7533",
        "JobTitle": "canal lockman/woman"
    },
    {
        "value": 15770,
        "NOCCode": "7533",
        "JobTitle": "charter boat captain"
    },
    {
        "value": 15771,
        "NOCCode": "7533",
        "JobTitle": "charter boat master"
    },
    {
        "value": 15772,
        "NOCCode": "7533",
        "JobTitle": "charter boat operator"
    },
    {
        "value": 15773,
        "NOCCode": "7533",
        "JobTitle": "dry dock worker – shipbuilding"
    },
    {
        "value": 15774,
        "NOCCode": "7533",
        "JobTitle": "ferry operator"
    },
    {
        "value": 15775,
        "NOCCode": "7533",
        "JobTitle": "ferry terminal attendant"
    },
    {
        "value": 15776,
        "NOCCode": "7533",
        "JobTitle": "ferry terminal worker"
    },
    {
        "value": 15777,
        "NOCCode": "7533",
        "JobTitle": "launch captain"
    },
    {
        "value": 15778,
        "NOCCode": "7533",
        "JobTitle": "launch master"
    },
    {
        "value": 15779,
        "NOCCode": "7533",
        "JobTitle": "launch operator"
    },
    {
        "value": 15780,
        "NOCCode": "7533",
        "JobTitle": "launch owner-operator"
    },
    {
        "value": 15781,
        "NOCCode": "7533",
        "JobTitle": "lighthouse keeper"
    },
    {
        "value": 15782,
        "NOCCode": "7533",
        "JobTitle": "lineshandler – canal lock system"
    },
    {
        "value": 15783,
        "NOCCode": "7533",
        "JobTitle": "linesman/woman – canal lock system"
    },
    {
        "value": 15784,
        "NOCCode": "7533",
        "JobTitle": "loading bridge operator"
    },
    {
        "value": 15785,
        "NOCCode": "7533",
        "JobTitle": "loading bridge operator – ferry"
    },
    {
        "value": 15786,
        "NOCCode": "7533",
        "JobTitle": "lock operator"
    },
    {
        "value": 15787,
        "NOCCode": "7533",
        "JobTitle": "lockmaster"
    },
    {
        "value": 15788,
        "NOCCode": "7533",
        "JobTitle": "motor boat operator"
    },
    {
        "value": 15789,
        "NOCCode": "7533",
        "JobTitle": "motor launch operator"
    },
    {
        "value": 15790,
        "NOCCode": "7533",
        "JobTitle": "motorboat captain"
    },
    {
        "value": 15791,
        "NOCCode": "7533",
        "JobTitle": "motorboat operator"
    },
    {
        "value": 15792,
        "NOCCode": "7533",
        "JobTitle": "power cable ferry operator"
    },
    {
        "value": 15793,
        "NOCCode": "7533",
        "JobTitle": "reaction ferry operator"
    },
    {
        "value": 15794,
        "NOCCode": "7533",
        "JobTitle": "repair ship owner-operator"
    },
    {
        "value": 15795,
        "NOCCode": "7533",
        "JobTitle": "scow captain"
    },
    {
        "value": 15796,
        "NOCCode": "7533",
        "JobTitle": "scow operator"
    },
    {
        "value": 15797,
        "NOCCode": "7533",
        "JobTitle": "sightseeing boat captain"
    },
    {
        "value": 15798,
        "NOCCode": "7533",
        "JobTitle": "sightseeing boat master"
    },
    {
        "value": 15799,
        "NOCCode": "7533",
        "JobTitle": "sightseeing boat operator"
    },
    {
        "value": 15800,
        "NOCCode": "7533",
        "JobTitle": "small craft operator"
    },
    {
        "value": 15801,
        "NOCCode": "7533",
        "JobTitle": "water bus captain"
    },
    {
        "value": 15802,
        "NOCCode": "7533",
        "JobTitle": "water bus master"
    },
    {
        "value": 15803,
        "NOCCode": "7533",
        "JobTitle": "water bus operator"
    },
    {
        "value": 15804,
        "NOCCode": "7533",
        "JobTitle": "water taxi captain"
    },
    {
        "value": 15805,
        "NOCCode": "7533",
        "JobTitle": "water taxi master"
    },
    {
        "value": 15806,
        "NOCCode": "7533",
        "JobTitle": "water taxi operator"
    },
    {
        "value": 15807,
        "NOCCode": "7533",
        "JobTitle": "workboat operator"
    },
    {
        "value": 15808,
        "NOCCode": "7534",
        "JobTitle": "aircraft cleaner"
    },
    {
        "value": 15809,
        "NOCCode": "7534",
        "JobTitle": "aircraft groomer"
    },
    {
        "value": 15810,
        "NOCCode": "7534",
        "JobTitle": "aircraft refueler"
    },
    {
        "value": 15811,
        "NOCCode": "7534",
        "JobTitle": "aircraft towing equipment operator"
    },
    {
        "value": 15812,
        "NOCCode": "7534",
        "JobTitle": "airport ramp attendant"
    },
    {
        "value": 15813,
        "NOCCode": "7534",
        "JobTitle": "baggage loader – air transport"
    },
    {
        "value": 15814,
        "NOCCode": "7534",
        "JobTitle": "cargo attendant, air transport"
    },
    {
        "value": 15815,
        "NOCCode": "7534",
        "JobTitle": "cargo handler – air transport"
    },
    {
        "value": 15816,
        "NOCCode": "7534",
        "JobTitle": "cargo loader – air transport"
    },
    {
        "value": 15817,
        "NOCCode": "7534",
        "JobTitle": "freight attendant – air transport"
    },
    {
        "value": 15818,
        "NOCCode": "7534",
        "JobTitle": "groomer, aircraft"
    },
    {
        "value": 15819,
        "NOCCode": "7534",
        "JobTitle": "ramp agent, air transport"
    },
    {
        "value": 15820,
        "NOCCode": "7534",
        "JobTitle": "ramp attendant, air transport"
    },
    {
        "value": 15821,
        "NOCCode": "7534",
        "JobTitle": "ramp lead hand – air transport"
    },
    {
        "value": 15822,
        "NOCCode": "7534",
        "JobTitle": "ramp station attendant – air transport"
    },
    {
        "value": 15823,
        "NOCCode": "7534",
        "JobTitle": "station attendant, air transport"
    },
    {
        "value": 15824,
        "NOCCode": "7535",
        "JobTitle": "automobile lubricator"
    },
    {
        "value": 15825,
        "NOCCode": "7535",
        "JobTitle": "automotive muffler installer"
    },
    {
        "value": 15826,
        "NOCCode": "7535",
        "JobTitle": "automotive radiator installer"
    },
    {
        "value": 15827,
        "NOCCode": "7535",
        "JobTitle": "automotive radiator installer – automotive repair"
    },
    {
        "value": 15828,
        "NOCCode": "7535",
        "JobTitle": "automotive shock absorber installer"
    },
    {
        "value": 15829,
        "NOCCode": "7535",
        "JobTitle": "automotive spring installer"
    },
    {
        "value": 15830,
        "NOCCode": "7535",
        "JobTitle": "caterpillar servicer (except mechanic)"
    },
    {
        "value": 15831,
        "NOCCode": "7535",
        "JobTitle": "crane greaser"
    },
    {
        "value": 15832,
        "NOCCode": "7535",
        "JobTitle": "crane oiler"
    },
    {
        "value": 15833,
        "NOCCode": "7535",
        "JobTitle": "crawler track servicer"
    },
    {
        "value": 15834,
        "NOCCode": "7535",
        "JobTitle": "dragline oiler"
    },
    {
        "value": 15835,
        "NOCCode": "7535",
        "JobTitle": "electric golf cart servicer (except mechanic)"
    },
    {
        "value": 15836,
        "NOCCode": "7535",
        "JobTitle": "electric truck servicer (except mechanic)"
    },
    {
        "value": 15837,
        "NOCCode": "7535",
        "JobTitle": "emergency road servicer, motor vehicle"
    },
    {
        "value": 15838,
        "NOCCode": "7535",
        "JobTitle": "excavator oiler"
    },
    {
        "value": 15839,
        "NOCCode": "7535",
        "JobTitle": "greaser"
    },
    {
        "value": 15840,
        "NOCCode": "7535",
        "JobTitle": "greaser, heavy truck"
    },
    {
        "value": 15841,
        "NOCCode": "7535",
        "JobTitle": "heavy equipment greaser"
    },
    {
        "value": 15842,
        "NOCCode": "7535",
        "JobTitle": "heavy equipment servicer"
    },
    {
        "value": 15843,
        "NOCCode": "7535",
        "JobTitle": "heavy-duty equipment servicer"
    },
    {
        "value": 15844,
        "NOCCode": "7535",
        "JobTitle": "hostler – transit system"
    },
    {
        "value": 15845,
        "NOCCode": "7535",
        "JobTitle": "installer, automotive shock absorbers"
    },
    {
        "value": 15846,
        "NOCCode": "7535",
        "JobTitle": "installer, automotive springs"
    },
    {
        "value": 15847,
        "NOCCode": "7535",
        "JobTitle": "installer, muffler"
    },
    {
        "value": 15848,
        "NOCCode": "7535",
        "JobTitle": "installer, radiators – automotive service"
    },
    {
        "value": 15849,
        "NOCCode": "7535",
        "JobTitle": "loading shovel oiler"
    },
    {
        "value": 15850,
        "NOCCode": "7535",
        "JobTitle": "lubrication technician – automotive service and repair"
    },
    {
        "value": 15851,
        "NOCCode": "7535",
        "JobTitle": "muffler installer"
    },
    {
        "value": 15852,
        "NOCCode": "7535",
        "JobTitle": "oil changer – motor vehicle service"
    },
    {
        "value": 15853,
        "NOCCode": "7535",
        "JobTitle": "oiler – automotive service"
    },
    {
        "value": 15854,
        "NOCCode": "7535",
        "JobTitle": "oiler and greaser, crane and dragline"
    },
    {
        "value": 15855,
        "NOCCode": "7535",
        "JobTitle": "oiler, heavy truck"
    },
    {
        "value": 15856,
        "NOCCode": "7535",
        "JobTitle": "pipeline service truck driver"
    },
    {
        "value": 15857,
        "NOCCode": "7535",
        "JobTitle": "radiator installer – auto repair service"
    },
    {
        "value": 15858,
        "NOCCode": "7535",
        "JobTitle": "radiator installer – automotive service"
    },
    {
        "value": 15859,
        "NOCCode": "7535",
        "JobTitle": "service truck driver, heavy equipment"
    },
    {
        "value": 15860,
        "NOCCode": "7535",
        "JobTitle": "servicer, heavy equipment"
    },
    {
        "value": 15861,
        "NOCCode": "7535",
        "JobTitle": "servicer, pipeline equipment"
    },
    {
        "value": 15862,
        "NOCCode": "7535",
        "JobTitle": "shock absorber installer"
    },
    {
        "value": 15863,
        "NOCCode": "7535",
        "JobTitle": "shovel oiler"
    },
    {
        "value": 15864,
        "NOCCode": "7535",
        "JobTitle": "spring installer"
    },
    {
        "value": 15865,
        "NOCCode": "7535",
        "JobTitle": "stripping shovel oiler"
    },
    {
        "value": 15866,
        "NOCCode": "7535",
        "JobTitle": "tire changer – automotive service"
    },
    {
        "value": 15867,
        "NOCCode": "7535",
        "JobTitle": "tire repairer"
    },
    {
        "value": 15868,
        "NOCCode": "7535",
        "JobTitle": "tire repairer – automotive service"
    },
    {
        "value": 15869,
        "NOCCode": "7611",
        "JobTitle": "access floor installer helper"
    },
    {
        "value": 15870,
        "NOCCode": "7611",
        "JobTitle": "air breaker operator"
    },
    {
        "value": 15871,
        "NOCCode": "7611",
        "JobTitle": "air gun operator"
    },
    {
        "value": 15872,
        "NOCCode": "7611",
        "JobTitle": "air hammer operator – construction"
    },
    {
        "value": 15873,
        "NOCCode": "7611",
        "JobTitle": "air lock tender"
    },
    {
        "value": 15874,
        "NOCCode": "7611",
        "JobTitle": "air tool operator"
    },
    {
        "value": 15875,
        "NOCCode": "7611",
        "JobTitle": "air track drill operator helper"
    },
    {
        "value": 15876,
        "NOCCode": "7611",
        "JobTitle": "asbestos fibre preparer"
    },
    {
        "value": 15877,
        "NOCCode": "7611",
        "JobTitle": "asbestos remover"
    },
    {
        "value": 15878,
        "NOCCode": "7611",
        "JobTitle": "asbestos worker – construction"
    },
    {
        "value": 15879,
        "NOCCode": "7611",
        "JobTitle": "asphalt cold patcher"
    },
    {
        "value": 15880,
        "NOCCode": "7611",
        "JobTitle": "asphalt mixer"
    },
    {
        "value": 15881,
        "NOCCode": "7611",
        "JobTitle": "asphalt plant labourer – construction"
    },
    {
        "value": 15882,
        "NOCCode": "7611",
        "JobTitle": "asphalt raker"
    },
    {
        "value": 15883,
        "NOCCode": "7611",
        "JobTitle": "asphalt roofer helper"
    },
    {
        "value": 15884,
        "NOCCode": "7611",
        "JobTitle": "asphalt spreader"
    },
    {
        "value": 15885,
        "NOCCode": "7611",
        "JobTitle": "asphalt worker – road"
    },
    {
        "value": 15886,
        "NOCCode": "7611",
        "JobTitle": "awning installer helper"
    },
    {
        "value": 15887,
        "NOCCode": "7611",
        "JobTitle": "batterboard setter"
    },
    {
        "value": 15888,
        "NOCCode": "7611",
        "JobTitle": "bending machine operator – pipelines"
    },
    {
        "value": 15889,
        "NOCCode": "7611",
        "JobTitle": "bill poster"
    },
    {
        "value": 15890,
        "NOCCode": "7611",
        "JobTitle": "bituminous paving labourer"
    },
    {
        "value": 15891,
        "NOCCode": "7611",
        "JobTitle": "bituminous paving machine operator"
    },
    {
        "value": 15892,
        "NOCCode": "7611",
        "JobTitle": "blacktop raker"
    },
    {
        "value": 15893,
        "NOCCode": "7611",
        "JobTitle": "blacktop spreader"
    },
    {
        "value": 15894,
        "NOCCode": "7611",
        "JobTitle": "blower operator, insulation"
    },
    {
        "value": 15895,
        "NOCCode": "7611",
        "JobTitle": "brick cleaner"
    },
    {
        "value": 15896,
        "NOCCode": "7611",
        "JobTitle": "bricklayer helper"
    },
    {
        "value": 15897,
        "NOCCode": "7611",
        "JobTitle": "brickmason's helper"
    },
    {
        "value": 15898,
        "NOCCode": "7611",
        "JobTitle": "bridge gang worker – construction"
    },
    {
        "value": 15899,
        "NOCCode": "7611",
        "JobTitle": "building and house wrecker"
    },
    {
        "value": 15900,
        "NOCCode": "7611",
        "JobTitle": "building painter helper"
    },
    {
        "value": 15901,
        "NOCCode": "7611",
        "JobTitle": "building wrecker"
    },
    {
        "value": 15902,
        "NOCCode": "7611",
        "JobTitle": "cabinetmaker's helper"
    },
    {
        "value": 15903,
        "NOCCode": "7611",
        "JobTitle": "carpenter helper"
    },
    {
        "value": 15904,
        "NOCCode": "7611",
        "JobTitle": "carpet layer helper"
    },
    {
        "value": 15905,
        "NOCCode": "7611",
        "JobTitle": "carpet mechanics helper"
    },
    {
        "value": 15906,
        "NOCCode": "7611",
        "JobTitle": "caulker – construction"
    },
    {
        "value": 15907,
        "NOCCode": "7611",
        "JobTitle": "caulker, precast concrete"
    },
    {
        "value": 15908,
        "NOCCode": "7611",
        "JobTitle": "cement and concrete pump tender"
    },
    {
        "value": 15909,
        "NOCCode": "7611",
        "JobTitle": "cement finisher helper"
    },
    {
        "value": 15910,
        "NOCCode": "7611",
        "JobTitle": "cement gun nozzle operator"
    },
    {
        "value": 15911,
        "NOCCode": "7611",
        "JobTitle": "cement gun operator"
    },
    {
        "value": 15912,
        "NOCCode": "7611",
        "JobTitle": "cement mixer operator – construction"
    },
    {
        "value": 15913,
        "NOCCode": "7611",
        "JobTitle": "cement pump operator"
    },
    {
        "value": 15914,
        "NOCCode": "7611",
        "JobTitle": "chainman/woman, pile driving"
    },
    {
        "value": 15915,
        "NOCCode": "7611",
        "JobTitle": "churn drill operator helper – water well"
    },
    {
        "value": 15916,
        "NOCCode": "7611",
        "JobTitle": "clamper – construction"
    },
    {
        "value": 15917,
        "NOCCode": "7611",
        "JobTitle": "cold patcher, asphalt"
    },
    {
        "value": 15918,
        "NOCCode": "7611",
        "JobTitle": "concrete block pourer"
    },
    {
        "value": 15919,
        "NOCCode": "7611",
        "JobTitle": "concrete breaker"
    },
    {
        "value": 15920,
        "NOCCode": "7611",
        "JobTitle": "concrete finisher helper"
    },
    {
        "value": 15921,
        "NOCCode": "7611",
        "JobTitle": "concrete float pourer"
    },
    {
        "value": 15922,
        "NOCCode": "7611",
        "JobTitle": "concrete former helper"
    },
    {
        "value": 15923,
        "NOCCode": "7611",
        "JobTitle": "concrete gun operator"
    },
    {
        "value": 15924,
        "NOCCode": "7611",
        "JobTitle": "concrete mixer helper"
    },
    {
        "value": 15925,
        "NOCCode": "7611",
        "JobTitle": "concrete mixer operator – construction"
    },
    {
        "value": 15926,
        "NOCCode": "7611",
        "JobTitle": "concrete mixer operator helper – highway construction"
    },
    {
        "value": 15927,
        "NOCCode": "7611",
        "JobTitle": "concrete paver operator helper"
    },
    {
        "value": 15928,
        "NOCCode": "7611",
        "JobTitle": "concrete pourer – construction"
    },
    {
        "value": 15929,
        "NOCCode": "7611",
        "JobTitle": "concrete pump operator"
    },
    {
        "value": 15930,
        "NOCCode": "7611",
        "JobTitle": "concrete spreader operator"
    },
    {
        "value": 15931,
        "NOCCode": "7611",
        "JobTitle": "concrete vibrator operator, manual"
    },
    {
        "value": 15932,
        "NOCCode": "7611",
        "JobTitle": "concrete waterproofer – construction"
    },
    {
        "value": 15933,
        "NOCCode": "7611",
        "JobTitle": "construction craft labourer"
    },
    {
        "value": 15934,
        "NOCCode": "7611",
        "JobTitle": "construction electrician helper"
    },
    {
        "value": 15935,
        "NOCCode": "7611",
        "JobTitle": "construction helper"
    },
    {
        "value": 15936,
        "NOCCode": "7611",
        "JobTitle": "construction labourer"
    },
    {
        "value": 15937,
        "NOCCode": "7611",
        "JobTitle": "construction labourer and helper"
    },
    {
        "value": 15938,
        "NOCCode": "7611",
        "JobTitle": "construction trades helper"
    },
    {
        "value": 15939,
        "NOCCode": "7611",
        "JobTitle": "construction worker"
    },
    {
        "value": 15940,
        "NOCCode": "7611",
        "JobTitle": "crane chaser"
    },
    {
        "value": 15941,
        "NOCCode": "7611",
        "JobTitle": "crane hooker"
    },
    {
        "value": 15942,
        "NOCCode": "7611",
        "JobTitle": "cupola spray reliner"
    },
    {
        "value": 15943,
        "NOCCode": "7611",
        "JobTitle": "curb builder, manual"
    },
    {
        "value": 15944,
        "NOCCode": "7611",
        "JobTitle": "dampproofer helper"
    },
    {
        "value": 15945,
        "NOCCode": "7611",
        "JobTitle": "deck worker"
    },
    {
        "value": 15946,
        "NOCCode": "7611",
        "JobTitle": "demolition worker"
    },
    {
        "value": 15947,
        "NOCCode": "7611",
        "JobTitle": "diamond driller helper – surface mining"
    },
    {
        "value": 15948,
        "NOCCode": "7611",
        "JobTitle": "digger – construction"
    },
    {
        "value": 15949,
        "NOCCode": "7611",
        "JobTitle": "digger, sewers"
    },
    {
        "value": 15950,
        "NOCCode": "7611",
        "JobTitle": "ditch cleaner – construction"
    },
    {
        "value": 15951,
        "NOCCode": "7611",
        "JobTitle": "door frame installer"
    },
    {
        "value": 15952,
        "NOCCode": "7611",
        "JobTitle": "dope machine operator – pipelines"
    },
    {
        "value": 15953,
        "NOCCode": "7611",
        "JobTitle": "dragline operator helper"
    },
    {
        "value": 15954,
        "NOCCode": "7611",
        "JobTitle": "dredge worker"
    },
    {
        "value": 15955,
        "NOCCode": "7611",
        "JobTitle": "driller helper – surface mining"
    },
    {
        "value": 15956,
        "NOCCode": "7611",
        "JobTitle": "drywall and acoustical installer helper"
    },
    {
        "value": 15957,
        "NOCCode": "7611",
        "JobTitle": "drywall sander"
    },
    {
        "value": 15958,
        "NOCCode": "7611",
        "JobTitle": "earthworks labourer – construction"
    },
    {
        "value": 15959,
        "NOCCode": "7611",
        "JobTitle": "erector, highway signs"
    },
    {
        "value": 15960,
        "NOCCode": "7611",
        "JobTitle": "erector, scaffold"
    },
    {
        "value": 15961,
        "NOCCode": "7611",
        "JobTitle": "excavation worker"
    },
    {
        "value": 15962,
        "NOCCode": "7611",
        "JobTitle": "exterior cladder helper"
    },
    {
        "value": 15963,
        "NOCCode": "7611",
        "JobTitle": "finisher helper, terrazzo"
    },
    {
        "value": 15964,
        "NOCCode": "7611",
        "JobTitle": "firebrick layer helper"
    },
    {
        "value": 15965,
        "NOCCode": "7611",
        "JobTitle": "fitter helper – construction"
    },
    {
        "value": 15966,
        "NOCCode": "7611",
        "JobTitle": "flagman/woman"
    },
    {
        "value": 15967,
        "NOCCode": "7611",
        "JobTitle": "flagman/woman – construction"
    },
    {
        "value": 15968,
        "NOCCode": "7611",
        "JobTitle": "floor coverer helper"
    },
    {
        "value": 15969,
        "NOCCode": "7611",
        "JobTitle": "floor layer's helper"
    },
    {
        "value": 15970,
        "NOCCode": "7611",
        "JobTitle": "floor sander and finisher helper"
    },
    {
        "value": 15971,
        "NOCCode": "7611",
        "JobTitle": "floorcovering installer helper"
    },
    {
        "value": 15972,
        "NOCCode": "7611",
        "JobTitle": "floorcovering mechanic helper"
    },
    {
        "value": 15973,
        "NOCCode": "7611",
        "JobTitle": "form remover – construction"
    },
    {
        "value": 15974,
        "NOCCode": "7611",
        "JobTitle": "form setter, metal – construction"
    },
    {
        "value": 15975,
        "NOCCode": "7611",
        "JobTitle": "form stripper – construction"
    },
    {
        "value": 15976,
        "NOCCode": "7611",
        "JobTitle": "foundation dampproofer helper"
    },
    {
        "value": 15977,
        "NOCCode": "7611",
        "JobTitle": "foundation drill operator helper"
    },
    {
        "value": 15978,
        "NOCCode": "7611",
        "JobTitle": "foundation form worker"
    },
    {
        "value": 15979,
        "NOCCode": "7611",
        "JobTitle": "foundation waterproofer, construction"
    },
    {
        "value": 15980,
        "NOCCode": "7611",
        "JobTitle": "framer helper – construction"
    },
    {
        "value": 15981,
        "NOCCode": "7611",
        "JobTitle": "gas pipelayer – construction"
    },
    {
        "value": 15982,
        "NOCCode": "7611",
        "JobTitle": "general construction labourer"
    },
    {
        "value": 15983,
        "NOCCode": "7611",
        "JobTitle": "glass setter helper"
    },
    {
        "value": 15984,
        "NOCCode": "7611",
        "JobTitle": "glass worker helper – construction"
    },
    {
        "value": 15985,
        "NOCCode": "7611",
        "JobTitle": "glazier helper"
    },
    {
        "value": 15986,
        "NOCCode": "7611",
        "JobTitle": "grade tamper – construction"
    },
    {
        "value": 15987,
        "NOCCode": "7611",
        "JobTitle": "ground worker, pile driving"
    },
    {
        "value": 15988,
        "NOCCode": "7611",
        "JobTitle": "grout machine operator"
    },
    {
        "value": 15989,
        "NOCCode": "7611",
        "JobTitle": "grout pump operator"
    },
    {
        "value": 15990,
        "NOCCode": "7611",
        "JobTitle": "grouter"
    },
    {
        "value": 15991,
        "NOCCode": "7611",
        "JobTitle": "gunite mixer – construction"
    },
    {
        "value": 15992,
        "NOCCode": "7611",
        "JobTitle": "guniter – construction"
    },
    {
        "value": 15993,
        "NOCCode": "7611",
        "JobTitle": "helper, air track drill operator"
    },
    {
        "value": 15994,
        "NOCCode": "7611",
        "JobTitle": "helper, asphalt roofer"
    },
    {
        "value": 15995,
        "NOCCode": "7611",
        "JobTitle": "helper, awning installer"
    },
    {
        "value": 15996,
        "NOCCode": "7611",
        "JobTitle": "helper, bricklayer"
    },
    {
        "value": 15997,
        "NOCCode": "7611",
        "JobTitle": "helper, brickmason"
    },
    {
        "value": 15998,
        "NOCCode": "7611",
        "JobTitle": "helper, building painter"
    },
    {
        "value": 15999,
        "NOCCode": "7611",
        "JobTitle": "helper, cabinetmaker"
    },
    {
        "value": 16000,
        "NOCCode": "7611",
        "JobTitle": "helper, carpenter"
    },
    {
        "value": 16001,
        "NOCCode": "7611",
        "JobTitle": "helper, carpet layer"
    },
    {
        "value": 16002,
        "NOCCode": "7611",
        "JobTitle": "helper, carpet mechanic"
    },
    {
        "value": 16003,
        "NOCCode": "7611",
        "JobTitle": "helper, cement finisher"
    },
    {
        "value": 16004,
        "NOCCode": "7611",
        "JobTitle": "helper, churn drill operator – water well"
    },
    {
        "value": 16005,
        "NOCCode": "7611",
        "JobTitle": "helper, concrete finisher"
    },
    {
        "value": 16006,
        "NOCCode": "7611",
        "JobTitle": "helper, concrete former"
    },
    {
        "value": 16007,
        "NOCCode": "7611",
        "JobTitle": "helper, concrete mixer operator"
    },
    {
        "value": 16008,
        "NOCCode": "7611",
        "JobTitle": "helper, concrete paver operator"
    },
    {
        "value": 16009,
        "NOCCode": "7611",
        "JobTitle": "helper, construction"
    },
    {
        "value": 16010,
        "NOCCode": "7611",
        "JobTitle": "helper, construction electrician"
    },
    {
        "value": 16011,
        "NOCCode": "7611",
        "JobTitle": "helper, construction trades"
    },
    {
        "value": 16012,
        "NOCCode": "7611",
        "JobTitle": "helper, dragline operator – crane operation"
    },
    {
        "value": 16013,
        "NOCCode": "7611",
        "JobTitle": "helper, drywall and acoustical board installer"
    },
    {
        "value": 16014,
        "NOCCode": "7611",
        "JobTitle": "helper, fitter – construction"
    },
    {
        "value": 16015,
        "NOCCode": "7611",
        "JobTitle": "helper, floor coverer"
    },
    {
        "value": 16016,
        "NOCCode": "7611",
        "JobTitle": "helper, floor sander and finisher"
    },
    {
        "value": 16017,
        "NOCCode": "7611",
        "JobTitle": "helper, floorcovering installer"
    },
    {
        "value": 16018,
        "NOCCode": "7611",
        "JobTitle": "helper, floorcovering mechanic"
    },
    {
        "value": 16019,
        "NOCCode": "7611",
        "JobTitle": "helper, glass setter"
    },
    {
        "value": 16020,
        "NOCCode": "7611",
        "JobTitle": "helper, glass worker – construction"
    },
    {
        "value": 16021,
        "NOCCode": "7611",
        "JobTitle": "helper, glazier"
    },
    {
        "value": 16022,
        "NOCCode": "7611",
        "JobTitle": "helper, highway concrete mixer operator"
    },
    {
        "value": 16023,
        "NOCCode": "7611",
        "JobTitle": "helper, insulation hoseman"
    },
    {
        "value": 16024,
        "NOCCode": "7611",
        "JobTitle": "helper, insulator"
    },
    {
        "value": 16025,
        "NOCCode": "7611",
        "JobTitle": "helper, interior and exterior wall erector"
    },
    {
        "value": 16026,
        "NOCCode": "7611",
        "JobTitle": "helper, interior wall finisher"
    },
    {
        "value": 16027,
        "NOCCode": "7611",
        "JobTitle": "helper, lightning rod erector"
    },
    {
        "value": 16028,
        "NOCCode": "7611",
        "JobTitle": "helper, marble setter"
    },
    {
        "value": 16029,
        "NOCCode": "7611",
        "JobTitle": "helper, mason"
    },
    {
        "value": 16030,
        "NOCCode": "7611",
        "JobTitle": "helper, metal roofer – construction"
    },
    {
        "value": 16031,
        "NOCCode": "7611",
        "JobTitle": "helper, metal trim erector"
    },
    {
        "value": 16032,
        "NOCCode": "7611",
        "JobTitle": "helper, mole operator – construction"
    },
    {
        "value": 16033,
        "NOCCode": "7611",
        "JobTitle": "helper, oil burner installer"
    },
    {
        "value": 16034,
        "NOCCode": "7611",
        "JobTitle": "helper, ornamental iron erector"
    },
    {
        "value": 16035,
        "NOCCode": "7611",
        "JobTitle": "helper, ornamental metal worker"
    },
    {
        "value": 16036,
        "NOCCode": "7611",
        "JobTitle": "helper, painter – construction"
    },
    {
        "value": 16037,
        "NOCCode": "7611",
        "JobTitle": "helper, painter and decorator"
    },
    {
        "value": 16038,
        "NOCCode": "7611",
        "JobTitle": "helper, paperhanger – construction"
    },
    {
        "value": 16039,
        "NOCCode": "7611",
        "JobTitle": "helper, pavement line painter"
    },
    {
        "value": 16040,
        "NOCCode": "7611",
        "JobTitle": "helper, pile driver operator – crane operation"
    },
    {
        "value": 16041,
        "NOCCode": "7611",
        "JobTitle": "helper, pile-driving lead placer"
    },
    {
        "value": 16042,
        "NOCCode": "7611",
        "JobTitle": "helper, plasterer"
    },
    {
        "value": 16043,
        "NOCCode": "7611",
        "JobTitle": "helper, plate fitter – construction"
    },
    {
        "value": 16044,
        "NOCCode": "7611",
        "JobTitle": "helper, plumber"
    },
    {
        "value": 16045,
        "NOCCode": "7611",
        "JobTitle": "helper, power shovel operator"
    },
    {
        "value": 16046,
        "NOCCode": "7611",
        "JobTitle": "helper, rigger – construction"
    },
    {
        "value": 16047,
        "NOCCode": "7611",
        "JobTitle": "helper, road mixer operator"
    },
    {
        "value": 16048,
        "NOCCode": "7611",
        "JobTitle": "helper, roofer"
    },
    {
        "value": 16049,
        "NOCCode": "7611",
        "JobTitle": "helper, sheet metal worker – construction"
    },
    {
        "value": 16050,
        "NOCCode": "7611",
        "JobTitle": "helper, sider"
    },
    {
        "value": 16051,
        "NOCCode": "7611",
        "JobTitle": "helper, spray truck"
    },
    {
        "value": 16052,
        "NOCCode": "7611",
        "JobTitle": "helper, steam fitter – construction"
    },
    {
        "value": 16053,
        "NOCCode": "7611",
        "JobTitle": "helper, steel erector"
    },
    {
        "value": 16054,
        "NOCCode": "7611",
        "JobTitle": "helper, steel form setter"
    },
    {
        "value": 16055,
        "NOCCode": "7611",
        "JobTitle": "helper, structural plate fitter – construction"
    },
    {
        "value": 16056,
        "NOCCode": "7611",
        "JobTitle": "helper, terrazzo finisher"
    },
    {
        "value": 16057,
        "NOCCode": "7611",
        "JobTitle": "helper, terrazzo layer"
    },
    {
        "value": 16058,
        "NOCCode": "7611",
        "JobTitle": "helper, terrazzo mechanic"
    },
    {
        "value": 16059,
        "NOCCode": "7611",
        "JobTitle": "helper, terrazzo polisher"
    },
    {
        "value": 16060,
        "NOCCode": "7611",
        "JobTitle": "helper, tile setter"
    },
    {
        "value": 16061,
        "NOCCode": "7611",
        "JobTitle": "helper, trades – construction"
    },
    {
        "value": 16062,
        "NOCCode": "7611",
        "JobTitle": "helper, tunnelling machine operator – construction"
    },
    {
        "value": 16063,
        "NOCCode": "7611",
        "JobTitle": "helper, waterproofer"
    },
    {
        "value": 16064,
        "NOCCode": "7611",
        "JobTitle": "helper, welder-fitter – construction"
    },
    {
        "value": 16065,
        "NOCCode": "7611",
        "JobTitle": "highway concrete mixer operator helper"
    },
    {
        "value": 16066,
        "NOCCode": "7611",
        "JobTitle": "highway maintenance worker"
    },
    {
        "value": 16067,
        "NOCCode": "7611",
        "JobTitle": "highway sign erector"
    },
    {
        "value": 16068,
        "NOCCode": "7611",
        "JobTitle": "horizontal earth-boring machine helper"
    },
    {
        "value": 16069,
        "NOCCode": "7611",
        "JobTitle": "hoseman/woman insulation helper"
    },
    {
        "value": 16070,
        "NOCCode": "7611",
        "JobTitle": "hoseman/woman, insulation"
    },
    {
        "value": 16071,
        "NOCCode": "7611",
        "JobTitle": "industrial chimney repairer-mason helper"
    },
    {
        "value": 16072,
        "NOCCode": "7611",
        "JobTitle": "installer helper, awnings"
    },
    {
        "value": 16073,
        "NOCCode": "7611",
        "JobTitle": "installer helper, vanities and kitchen cupboards"
    },
    {
        "value": 16074,
        "NOCCode": "7611",
        "JobTitle": "installer, seats – construction"
    },
    {
        "value": 16075,
        "NOCCode": "7611",
        "JobTitle": "insulation blower"
    },
    {
        "value": 16076,
        "NOCCode": "7611",
        "JobTitle": "insulation blower helper"
    },
    {
        "value": 16077,
        "NOCCode": "7611",
        "JobTitle": "insulation blower operator"
    },
    {
        "value": 16078,
        "NOCCode": "7611",
        "JobTitle": "insulator helper"
    },
    {
        "value": 16079,
        "NOCCode": "7611",
        "JobTitle": "interior and exterior wall erector helper"
    },
    {
        "value": 16080,
        "NOCCode": "7611",
        "JobTitle": "interior finisher helper"
    },
    {
        "value": 16081,
        "NOCCode": "7611",
        "JobTitle": "ironworker helper"
    },
    {
        "value": 16082,
        "NOCCode": "7611",
        "JobTitle": "jackhammer operator – construction"
    },
    {
        "value": 16083,
        "NOCCode": "7611",
        "JobTitle": "joiner helper – construction"
    },
    {
        "value": 16084,
        "NOCCode": "7611",
        "JobTitle": "labourer, asphalt plant – construction"
    },
    {
        "value": 16085,
        "NOCCode": "7611",
        "JobTitle": "labourer, bituminous paving"
    },
    {
        "value": 16086,
        "NOCCode": "7611",
        "JobTitle": "labourer, concrete paving"
    },
    {
        "value": 16087,
        "NOCCode": "7611",
        "JobTitle": "labourer, construction"
    },
    {
        "value": 16088,
        "NOCCode": "7611",
        "JobTitle": "labourer, earthworks – construction"
    },
    {
        "value": 16089,
        "NOCCode": "7611",
        "JobTitle": "labourer, excavation"
    },
    {
        "value": 16090,
        "NOCCode": "7611",
        "JobTitle": "labourer, paving operations"
    },
    {
        "value": 16091,
        "NOCCode": "7611",
        "JobTitle": "labourer, pipeline"
    },
    {
        "value": 16092,
        "NOCCode": "7611",
        "JobTitle": "labourer, quarry"
    },
    {
        "value": 16093,
        "NOCCode": "7611",
        "JobTitle": "labourer, shore dredging"
    },
    {
        "value": 16094,
        "NOCCode": "7611",
        "JobTitle": "lightning rod erector helper"
    },
    {
        "value": 16095,
        "NOCCode": "7611",
        "JobTitle": "liner installer, pipelines"
    },
    {
        "value": 16096,
        "NOCCode": "7611",
        "JobTitle": "maintenance carpenter helper"
    },
    {
        "value": 16097,
        "NOCCode": "7611",
        "JobTitle": "manual concrete vibrator operator"
    },
    {
        "value": 16098,
        "NOCCode": "7611",
        "JobTitle": "marble setter helper"
    },
    {
        "value": 16099,
        "NOCCode": "7611",
        "JobTitle": "masonry pointer and caulker"
    },
    {
        "value": 16100,
        "NOCCode": "7611",
        "JobTitle": "mason's helper"
    },
    {
        "value": 16101,
        "NOCCode": "7611",
        "JobTitle": "measurer"
    },
    {
        "value": 16102,
        "NOCCode": "7611",
        "JobTitle": "metal form setter – construction"
    },
    {
        "value": 16103,
        "NOCCode": "7611",
        "JobTitle": "metal roofer helper – construction"
    },
    {
        "value": 16104,
        "NOCCode": "7611",
        "JobTitle": "metal trim erector helper"
    },
    {
        "value": 16105,
        "NOCCode": "7611",
        "JobTitle": "mixer, gunite – construction"
    },
    {
        "value": 16106,
        "NOCCode": "7611",
        "JobTitle": "mole operator helper – construction"
    },
    {
        "value": 16107,
        "NOCCode": "7611",
        "JobTitle": "monument erector"
    },
    {
        "value": 16108,
        "NOCCode": "7611",
        "JobTitle": "monument setter"
    },
    {
        "value": 16109,
        "NOCCode": "7611",
        "JobTitle": "mud jack operator"
    },
    {
        "value": 16110,
        "NOCCode": "7611",
        "JobTitle": "nozzle worker – pile driving"
    },
    {
        "value": 16111,
        "NOCCode": "7611",
        "JobTitle": "operator, air breaker"
    },
    {
        "value": 16112,
        "NOCCode": "7611",
        "JobTitle": "operator, cement gun"
    },
    {
        "value": 16113,
        "NOCCode": "7611",
        "JobTitle": "operator, jackhammer – construction"
    },
    {
        "value": 16114,
        "NOCCode": "7611",
        "JobTitle": "operator, pipeline mandrel"
    },
    {
        "value": 16115,
        "NOCCode": "7611",
        "JobTitle": "operator, tamper"
    },
    {
        "value": 16116,
        "NOCCode": "7611",
        "JobTitle": "ornamental iron erector helper"
    },
    {
        "value": 16117,
        "NOCCode": "7611",
        "JobTitle": "ornamental metal worker helper"
    },
    {
        "value": 16118,
        "NOCCode": "7611",
        "JobTitle": "painter and decorator helper"
    },
    {
        "value": 16119,
        "NOCCode": "7611",
        "JobTitle": "painter helper – construction"
    },
    {
        "value": 16120,
        "NOCCode": "7611",
        "JobTitle": "painter, pavement lines"
    },
    {
        "value": 16121,
        "NOCCode": "7611",
        "JobTitle": "paper latcher – construction"
    },
    {
        "value": 16122,
        "NOCCode": "7611",
        "JobTitle": "paperhanger helper"
    },
    {
        "value": 16123,
        "NOCCode": "7611",
        "JobTitle": "pavement line painter"
    },
    {
        "value": 16124,
        "NOCCode": "7611",
        "JobTitle": "pavement line painter helper"
    },
    {
        "value": 16125,
        "NOCCode": "7611",
        "JobTitle": "pavement striper"
    },
    {
        "value": 16126,
        "NOCCode": "7611",
        "JobTitle": "paving operations labourer"
    },
    {
        "value": 16127,
        "NOCCode": "7611",
        "JobTitle": "pile driver operator's helper"
    },
    {
        "value": 16128,
        "NOCCode": "7611",
        "JobTitle": "pile driving chainman/woman"
    },
    {
        "value": 16129,
        "NOCCode": "7611",
        "JobTitle": "pile driving ground worker"
    },
    {
        "value": 16130,
        "NOCCode": "7611",
        "JobTitle": "pipe aligner – pipeline construction"
    },
    {
        "value": 16131,
        "NOCCode": "7611",
        "JobTitle": "pipe doper – construction"
    },
    {
        "value": 16132,
        "NOCCode": "7611",
        "JobTitle": "pipe-cleaning and covering machine operator"
    },
    {
        "value": 16133,
        "NOCCode": "7611",
        "JobTitle": "pipefitter helper – construction"
    },
    {
        "value": 16134,
        "NOCCode": "7611",
        "JobTitle": "pipelayer"
    },
    {
        "value": 16135,
        "NOCCode": "7611",
        "JobTitle": "pipelayer – construction"
    },
    {
        "value": 16136,
        "NOCCode": "7611",
        "JobTitle": "pipelayer – drainage systems"
    },
    {
        "value": 16137,
        "NOCCode": "7611",
        "JobTitle": "pipelayer – irrigation"
    },
    {
        "value": 16138,
        "NOCCode": "7611",
        "JobTitle": "pipelayer, gas – construction"
    },
    {
        "value": 16139,
        "NOCCode": "7611",
        "JobTitle": "pipeline caulker"
    },
    {
        "value": 16140,
        "NOCCode": "7611",
        "JobTitle": "pipeline labourer"
    },
    {
        "value": 16141,
        "NOCCode": "7611",
        "JobTitle": "pipeline mandrel operator"
    },
    {
        "value": 16142,
        "NOCCode": "7611",
        "JobTitle": "pipeline welder helper"
    },
    {
        "value": 16143,
        "NOCCode": "7611",
        "JobTitle": "pipeline worker – construction"
    },
    {
        "value": 16144,
        "NOCCode": "7611",
        "JobTitle": "pipeline-liner installer"
    },
    {
        "value": 16145,
        "NOCCode": "7611",
        "JobTitle": "pit bottom worker"
    },
    {
        "value": 16146,
        "NOCCode": "7611",
        "JobTitle": "plasterer helper"
    },
    {
        "value": 16147,
        "NOCCode": "7611",
        "JobTitle": "plate fitter helper – construction"
    },
    {
        "value": 16148,
        "NOCCode": "7611",
        "JobTitle": "plumber helper"
    },
    {
        "value": 16149,
        "NOCCode": "7611",
        "JobTitle": "pneumatic drill operator – construction"
    },
    {
        "value": 16150,
        "NOCCode": "7611",
        "JobTitle": "pneumatic hammer operator – construction"
    },
    {
        "value": 16151,
        "NOCCode": "7611",
        "JobTitle": "post hole driller"
    },
    {
        "value": 16152,
        "NOCCode": "7611",
        "JobTitle": "power curber tender"
    },
    {
        "value": 16153,
        "NOCCode": "7611",
        "JobTitle": "power shovel operator helper"
    },
    {
        "value": 16154,
        "NOCCode": "7611",
        "JobTitle": "precast concrete caulker"
    },
    {
        "value": 16155,
        "NOCCode": "7611",
        "JobTitle": "preheater tender – pipelines"
    },
    {
        "value": 16156,
        "NOCCode": "7611",
        "JobTitle": "pump tender, cement and concrete"
    },
    {
        "value": 16157,
        "NOCCode": "7611",
        "JobTitle": "quarry dump attendant"
    },
    {
        "value": 16158,
        "NOCCode": "7611",
        "JobTitle": "quarry labourer"
    },
    {
        "value": 16159,
        "NOCCode": "7611",
        "JobTitle": "raker, asphalt"
    },
    {
        "value": 16160,
        "NOCCode": "7611",
        "JobTitle": "refractory bricklayer helper"
    },
    {
        "value": 16161,
        "NOCCode": "7611",
        "JobTitle": "reinforcing wire mesh worker"
    },
    {
        "value": 16162,
        "NOCCode": "7611",
        "JobTitle": "renovation carpenter helper"
    },
    {
        "value": 16163,
        "NOCCode": "7611",
        "JobTitle": "rigger's helper – construction"
    },
    {
        "value": 16164,
        "NOCCode": "7611",
        "JobTitle": "rip-rap placer"
    },
    {
        "value": 16165,
        "NOCCode": "7611",
        "JobTitle": "road form setter"
    },
    {
        "value": 16166,
        "NOCCode": "7611",
        "JobTitle": "road mixer operator helper"
    },
    {
        "value": 16167,
        "NOCCode": "7611",
        "JobTitle": "road spray truck helper"
    },
    {
        "value": 16168,
        "NOCCode": "7611",
        "JobTitle": "roofer helper"
    },
    {
        "value": 16169,
        "NOCCode": "7611",
        "JobTitle": "roofer helper, asphalt"
    },
    {
        "value": 16170,
        "NOCCode": "7611",
        "JobTitle": "rotary driller helper – water well"
    },
    {
        "value": 16171,
        "NOCCode": "7611",
        "JobTitle": "sander, drywall"
    },
    {
        "value": 16172,
        "NOCCode": "7611",
        "JobTitle": "sander, wallboards"
    },
    {
        "value": 16173,
        "NOCCode": "7611",
        "JobTitle": "scaffold erector"
    },
    {
        "value": 16174,
        "NOCCode": "7611",
        "JobTitle": "seam caulker – construction"
    },
    {
        "value": 16175,
        "NOCCode": "7611",
        "JobTitle": "seat installer – construction"
    },
    {
        "value": 16176,
        "NOCCode": "7611",
        "JobTitle": "setter, monuments"
    },
    {
        "value": 16177,
        "NOCCode": "7611",
        "JobTitle": "setter, skids"
    },
    {
        "value": 16178,
        "NOCCode": "7611",
        "JobTitle": "sewage pipelayer"
    },
    {
        "value": 16179,
        "NOCCode": "7611",
        "JobTitle": "sewer digger"
    },
    {
        "value": 16180,
        "NOCCode": "7611",
        "JobTitle": "sheather helper"
    },
    {
        "value": 16181,
        "NOCCode": "7611",
        "JobTitle": "sheet metal worker helper – construction"
    },
    {
        "value": 16182,
        "NOCCode": "7611",
        "JobTitle": "shore dredging labourer"
    },
    {
        "value": 16183,
        "NOCCode": "7611",
        "JobTitle": "sider helper"
    },
    {
        "value": 16184,
        "NOCCode": "7611",
        "JobTitle": "sign erector, highways"
    },
    {
        "value": 16185,
        "NOCCode": "7611",
        "JobTitle": "skid setter – pipeline construction"
    },
    {
        "value": 16186,
        "NOCCode": "7611",
        "JobTitle": "slinger – construction"
    },
    {
        "value": 16187,
        "NOCCode": "7611",
        "JobTitle": "slip form curb machine tender"
    },
    {
        "value": 16188,
        "NOCCode": "7611",
        "JobTitle": "smelter liner"
    },
    {
        "value": 16189,
        "NOCCode": "7611",
        "JobTitle": "spacer – pipeline construction"
    },
    {
        "value": 16190,
        "NOCCode": "7611",
        "JobTitle": "spray truck helper"
    },
    {
        "value": 16191,
        "NOCCode": "7611",
        "JobTitle": "spreader box tender"
    },
    {
        "value": 16192,
        "NOCCode": "7611",
        "JobTitle": "stabber"
    },
    {
        "value": 16193,
        "NOCCode": "7611",
        "JobTitle": "stabber – pipeline construction"
    },
    {
        "value": 16194,
        "NOCCode": "7611",
        "JobTitle": "steam fitter's helper – construction"
    },
    {
        "value": 16195,
        "NOCCode": "7611",
        "JobTitle": "steel erector helper"
    },
    {
        "value": 16196,
        "NOCCode": "7611",
        "JobTitle": "steel form setting worker"
    },
    {
        "value": 16197,
        "NOCCode": "7611",
        "JobTitle": "steel forms setter helper"
    },
    {
        "value": 16198,
        "NOCCode": "7611",
        "JobTitle": "stone spreader operator, manual"
    },
    {
        "value": 16199,
        "NOCCode": "7611",
        "JobTitle": "structural plate fitter helper – construction"
    },
    {
        "value": 16200,
        "NOCCode": "7611",
        "JobTitle": "structural steel erector helper"
    },
    {
        "value": 16201,
        "NOCCode": "7611",
        "JobTitle": "tamper operator – construction"
    },
    {
        "value": 16202,
        "NOCCode": "7611",
        "JobTitle": "tank builder helper"
    },
    {
        "value": 16203,
        "NOCCode": "7611",
        "JobTitle": "tender, air lock"
    },
    {
        "value": 16204,
        "NOCCode": "7611",
        "JobTitle": "tender, cement and concrete pump"
    },
    {
        "value": 16205,
        "NOCCode": "7611",
        "JobTitle": "tender, power curber"
    },
    {
        "value": 16206,
        "NOCCode": "7611",
        "JobTitle": "tender, spreader box – construction"
    },
    {
        "value": 16207,
        "NOCCode": "7611",
        "JobTitle": "terrazzo finisher helper"
    },
    {
        "value": 16208,
        "NOCCode": "7611",
        "JobTitle": "terrazzo grinder, manual"
    },
    {
        "value": 16209,
        "NOCCode": "7611",
        "JobTitle": "terrazzo layer helper"
    },
    {
        "value": 16210,
        "NOCCode": "7611",
        "JobTitle": "terrazzo machine grinder"
    },
    {
        "value": 16211,
        "NOCCode": "7611",
        "JobTitle": "terrazzo mechanic helper"
    },
    {
        "value": 16212,
        "NOCCode": "7611",
        "JobTitle": "terrazzo polisher helper"
    },
    {
        "value": 16213,
        "NOCCode": "7611",
        "JobTitle": "tile layer helper"
    },
    {
        "value": 16214,
        "NOCCode": "7611",
        "JobTitle": "tile setter helper"
    },
    {
        "value": 16215,
        "NOCCode": "7611",
        "JobTitle": "tinsmith helper – construction"
    },
    {
        "value": 16216,
        "NOCCode": "7611",
        "JobTitle": "tombstone erector"
    },
    {
        "value": 16217,
        "NOCCode": "7611",
        "JobTitle": "tombstone setter"
    },
    {
        "value": 16218,
        "NOCCode": "7611",
        "JobTitle": "trades helper – construction"
    },
    {
        "value": 16219,
        "NOCCode": "7611",
        "JobTitle": "trench digger"
    },
    {
        "value": 16220,
        "NOCCode": "7611",
        "JobTitle": "tunnelling machine operator helper – construction"
    },
    {
        "value": 16221,
        "NOCCode": "7611",
        "JobTitle": "underground labourer – construction"
    },
    {
        "value": 16222,
        "NOCCode": "7611",
        "JobTitle": "vanity and kitchen cupboard installer helper"
    },
    {
        "value": 16223,
        "NOCCode": "7611",
        "JobTitle": "vibrator roller operator"
    },
    {
        "value": 16224,
        "NOCCode": "7611",
        "JobTitle": "vibratory compactor operator"
    },
    {
        "value": 16225,
        "NOCCode": "7611",
        "JobTitle": "wallboard sander"
    },
    {
        "value": 16226,
        "NOCCode": "7611",
        "JobTitle": "water pipe installer"
    },
    {
        "value": 16227,
        "NOCCode": "7611",
        "JobTitle": "water well driller helper"
    },
    {
        "value": 16228,
        "NOCCode": "7611",
        "JobTitle": "waterproofer – construction"
    },
    {
        "value": 16229,
        "NOCCode": "7611",
        "JobTitle": "waterproofer helper"
    },
    {
        "value": 16230,
        "NOCCode": "7611",
        "JobTitle": "window caulker"
    },
    {
        "value": 16231,
        "NOCCode": "7611",
        "JobTitle": "wire mesh setter"
    },
    {
        "value": 16232,
        "NOCCode": "7611",
        "JobTitle": "wrecker, buildings"
    },
    {
        "value": 16233,
        "NOCCode": "7611",
        "JobTitle": "wrecker, buildings and houses"
    },
    {
        "value": 16234,
        "NOCCode": "7612",
        "JobTitle": "aerial spraying assistant"
    },
    {
        "value": 16235,
        "NOCCode": "7612",
        "JobTitle": "aerial spraying lineman/woman"
    },
    {
        "value": 16236,
        "NOCCode": "7612",
        "JobTitle": "air conditioning mechanic helper"
    },
    {
        "value": 16237,
        "NOCCode": "7612",
        "JobTitle": "aircraft instrument mechanic helper"
    },
    {
        "value": 16238,
        "NOCCode": "7612",
        "JobTitle": "aircraft mechanic helper"
    },
    {
        "value": 16239,
        "NOCCode": "7612",
        "JobTitle": "automobile body repairer helper"
    },
    {
        "value": 16240,
        "NOCCode": "7612",
        "JobTitle": "automobile bumper straightener"
    },
    {
        "value": 16241,
        "NOCCode": "7612",
        "JobTitle": "automotive electrician helper"
    },
    {
        "value": 16242,
        "NOCCode": "7612",
        "JobTitle": "automotive mechanic helper"
    },
    {
        "value": 16243,
        "NOCCode": "7612",
        "JobTitle": "body repairer helper, automobile"
    },
    {
        "value": 16244,
        "NOCCode": "7612",
        "JobTitle": "cable installation and repair helper – telecommunications"
    },
    {
        "value": 16245,
        "NOCCode": "7612",
        "JobTitle": "cable installer helper"
    },
    {
        "value": 16246,
        "NOCCode": "7612",
        "JobTitle": "cable repairer helper"
    },
    {
        "value": 16247,
        "NOCCode": "7612",
        "JobTitle": "cable repairer helper – telecommunications"
    },
    {
        "value": 16248,
        "NOCCode": "7612",
        "JobTitle": "cable splicer helper"
    },
    {
        "value": 16249,
        "NOCCode": "7612",
        "JobTitle": "cableman/woman helper"
    },
    {
        "value": 16250,
        "NOCCode": "7612",
        "JobTitle": "chainman/woman – land surveying"
    },
    {
        "value": 16251,
        "NOCCode": "7612",
        "JobTitle": "chemical process equipment mechanic helper"
    },
    {
        "value": 16252,
        "NOCCode": "7612",
        "JobTitle": "commercial air conditioning mechanic helper"
    },
    {
        "value": 16253,
        "NOCCode": "7612",
        "JobTitle": "compassman/woman – land surveying"
    },
    {
        "value": 16254,
        "NOCCode": "7612",
        "JobTitle": "construction equipment mechanic helper"
    },
    {
        "value": 16255,
        "NOCCode": "7612",
        "JobTitle": "crawler track repairer helper"
    },
    {
        "value": 16256,
        "NOCCode": "7612",
        "JobTitle": "diesel mechanic helper"
    },
    {
        "value": 16257,
        "NOCCode": "7612",
        "JobTitle": "diver helper"
    },
    {
        "value": 16258,
        "NOCCode": "7612",
        "JobTitle": "electric cable network installer helper"
    },
    {
        "value": 16259,
        "NOCCode": "7612",
        "JobTitle": "electric cable splicer helper"
    },
    {
        "value": 16260,
        "NOCCode": "7612",
        "JobTitle": "electric motor repairer helper"
    },
    {
        "value": 16261,
        "NOCCode": "7612",
        "JobTitle": "electric power and wire communications labourer"
    },
    {
        "value": 16262,
        "NOCCode": "7612",
        "JobTitle": "electrical mechanic helper"
    },
    {
        "value": 16263,
        "NOCCode": "7612",
        "JobTitle": "electrician helper, automotive"
    },
    {
        "value": 16264,
        "NOCCode": "7612",
        "JobTitle": "electrician helper, powerhouse"
    },
    {
        "value": 16265,
        "NOCCode": "7612",
        "JobTitle": "elevator constructor helper"
    },
    {
        "value": 16266,
        "NOCCode": "7612",
        "JobTitle": "elevator mechanic helper"
    },
    {
        "value": 16267,
        "NOCCode": "7612",
        "JobTitle": "engine fitter helper"
    },
    {
        "value": 16268,
        "NOCCode": "7612",
        "JobTitle": "factory maintenance mechanic helper"
    },
    {
        "value": 16269,
        "NOCCode": "7612",
        "JobTitle": "field mechanic helper"
    },
    {
        "value": 16270,
        "NOCCode": "7612",
        "JobTitle": "fire equipment servicer helper"
    },
    {
        "value": 16271,
        "NOCCode": "7612",
        "JobTitle": "furnace repairer helper"
    },
    {
        "value": 16272,
        "NOCCode": "7612",
        "JobTitle": "gas meter repairer helper"
    },
    {
        "value": 16273,
        "NOCCode": "7612",
        "JobTitle": "ground hand – telecommunications"
    },
    {
        "value": 16274,
        "NOCCode": "7612",
        "JobTitle": "ground worker – power lines"
    },
    {
        "value": 16275,
        "NOCCode": "7612",
        "JobTitle": "ground worker – telecommunications"
    },
    {
        "value": 16276,
        "NOCCode": "7612",
        "JobTitle": "hand cutter, peat moss"
    },
    {
        "value": 16277,
        "NOCCode": "7612",
        "JobTitle": "hand peat moss cutter"
    },
    {
        "value": 16278,
        "NOCCode": "7612",
        "JobTitle": "heating and air conditioning mechanic helper"
    },
    {
        "value": 16279,
        "NOCCode": "7612",
        "JobTitle": "heating and cooling mechanic helper"
    },
    {
        "value": 16280,
        "NOCCode": "7612",
        "JobTitle": "heavy equipment mechanic helper"
    },
    {
        "value": 16281,
        "NOCCode": "7612",
        "JobTitle": "helper, automotive electrician"
    },
    {
        "value": 16282,
        "NOCCode": "7612",
        "JobTitle": "helper, automotive mechanic"
    },
    {
        "value": 16283,
        "NOCCode": "7612",
        "JobTitle": "helper, cable installation and repair – telecommunications"
    },
    {
        "value": 16284,
        "NOCCode": "7612",
        "JobTitle": "helper, cable splicer"
    },
    {
        "value": 16285,
        "NOCCode": "7612",
        "JobTitle": "helper, commercial air conditioning mechanic"
    },
    {
        "value": 16286,
        "NOCCode": "7612",
        "JobTitle": "helper, diver"
    },
    {
        "value": 16287,
        "NOCCode": "7612",
        "JobTitle": "helper, elevator mechanic"
    },
    {
        "value": 16288,
        "NOCCode": "7612",
        "JobTitle": "helper, engine fitter"
    },
    {
        "value": 16289,
        "NOCCode": "7612",
        "JobTitle": "helper, furnace repairer"
    },
    {
        "value": 16290,
        "NOCCode": "7612",
        "JobTitle": "helper, heating and cooling mechanic"
    },
    {
        "value": 16291,
        "NOCCode": "7612",
        "JobTitle": "helper, industrial mechanic"
    },
    {
        "value": 16292,
        "NOCCode": "7612",
        "JobTitle": "helper, mechanic"
    },
    {
        "value": 16293,
        "NOCCode": "7612",
        "JobTitle": "helper, millwright"
    },
    {
        "value": 16294,
        "NOCCode": "7612",
        "JobTitle": "helper, motor vehicle body repairer"
    },
    {
        "value": 16295,
        "NOCCode": "7612",
        "JobTitle": "helper, motor vehicle mechanic"
    },
    {
        "value": 16296,
        "NOCCode": "7612",
        "JobTitle": "helper, oil burner mechanic"
    },
    {
        "value": 16297,
        "NOCCode": "7612",
        "JobTitle": "helper, oil burner technician"
    },
    {
        "value": 16298,
        "NOCCode": "7612",
        "JobTitle": "helper, powerhouse electrician"
    },
    {
        "value": 16299,
        "NOCCode": "7612",
        "JobTitle": "helper, small-engine mechanic"
    },
    {
        "value": 16300,
        "NOCCode": "7612",
        "JobTitle": "industrial instrument repairer helper"
    },
    {
        "value": 16301,
        "NOCCode": "7612",
        "JobTitle": "industrial machinery oiler"
    },
    {
        "value": 16302,
        "NOCCode": "7612",
        "JobTitle": "industrial mechanic helper"
    },
    {
        "value": 16303,
        "NOCCode": "7612",
        "JobTitle": "installer helper – telecommunications"
    },
    {
        "value": 16304,
        "NOCCode": "7612",
        "JobTitle": "installer helper, power cable systems"
    },
    {
        "value": 16305,
        "NOCCode": "7612",
        "JobTitle": "labourer, electric power and wire communications"
    },
    {
        "value": 16306,
        "NOCCode": "7612",
        "JobTitle": "lineman/woman helper"
    },
    {
        "value": 16307,
        "NOCCode": "7612",
        "JobTitle": "lineman/woman helper – power and communication lines"
    },
    {
        "value": 16308,
        "NOCCode": "7612",
        "JobTitle": "liner replacer – ore-processing equipment"
    },
    {
        "value": 16309,
        "NOCCode": "7612",
        "JobTitle": "lineworker helper"
    },
    {
        "value": 16310,
        "NOCCode": "7612",
        "JobTitle": "machine mechanic-fitter helper"
    },
    {
        "value": 16311,
        "NOCCode": "7612",
        "JobTitle": "maintenance mechanic helper, factory or mill"
    },
    {
        "value": 16312,
        "NOCCode": "7612",
        "JobTitle": "marine electrician helper"
    },
    {
        "value": 16313,
        "NOCCode": "7612",
        "JobTitle": "mechanic helper"
    },
    {
        "value": 16314,
        "NOCCode": "7612",
        "JobTitle": "mechanic helper, air conditioning"
    },
    {
        "value": 16315,
        "NOCCode": "7612",
        "JobTitle": "mechanic helper, heavy equipment"
    },
    {
        "value": 16316,
        "NOCCode": "7612",
        "JobTitle": "mechanic helper, utilities instruments"
    },
    {
        "value": 16317,
        "NOCCode": "7612",
        "JobTitle": "mechanic-fitter helper"
    },
    {
        "value": 16318,
        "NOCCode": "7612",
        "JobTitle": "mechanic's helper – automotive"
    },
    {
        "value": 16319,
        "NOCCode": "7612",
        "JobTitle": "mill mechanic helper"
    },
    {
        "value": 16320,
        "NOCCode": "7612",
        "JobTitle": "millwright helper"
    },
    {
        "value": 16321,
        "NOCCode": "7612",
        "JobTitle": "mobile equipment repairer helper"
    },
    {
        "value": 16322,
        "NOCCode": "7612",
        "JobTitle": "mobile mechanic helper"
    },
    {
        "value": 16323,
        "NOCCode": "7612",
        "JobTitle": "motor vehicle body repairer helper"
    },
    {
        "value": 16324,
        "NOCCode": "7612",
        "JobTitle": "motor vehicle mechanic helper"
    },
    {
        "value": 16325,
        "NOCCode": "7612",
        "JobTitle": "motorman/woman – offshore drilling rig"
    },
    {
        "value": 16326,
        "NOCCode": "7612",
        "JobTitle": "oil burner maintainer helper"
    },
    {
        "value": 16327,
        "NOCCode": "7612",
        "JobTitle": "oil burner mechanic helper"
    },
    {
        "value": 16328,
        "NOCCode": "7612",
        "JobTitle": "oil burner technician helper"
    },
    {
        "value": 16329,
        "NOCCode": "7612",
        "JobTitle": "oiler, industrial machinery"
    },
    {
        "value": 16330,
        "NOCCode": "7612",
        "JobTitle": "oiler, stationary engines"
    },
    {
        "value": 16331,
        "NOCCode": "7612",
        "JobTitle": "ore-processing equipment repairer helper"
    },
    {
        "value": 16332,
        "NOCCode": "7612",
        "JobTitle": "peat digger"
    },
    {
        "value": 16333,
        "NOCCode": "7612",
        "JobTitle": "pipefitter helper – shipbuilding"
    },
    {
        "value": 16334,
        "NOCCode": "7612",
        "JobTitle": "power cable system installer helper"
    },
    {
        "value": 16335,
        "NOCCode": "7612",
        "JobTitle": "powered-machine or towed-machine repairer helper – construction"
    },
    {
        "value": 16336,
        "NOCCode": "7612",
        "JobTitle": "powerhouse electrician helper"
    },
    {
        "value": 16337,
        "NOCCode": "7612",
        "JobTitle": "powerhouse mechanical repairer helper"
    },
    {
        "value": 16338,
        "NOCCode": "7612",
        "JobTitle": "powerhouse oiler"
    },
    {
        "value": 16339,
        "NOCCode": "7612",
        "JobTitle": "protective signal servicer helper"
    },
    {
        "value": 16340,
        "NOCCode": "7612",
        "JobTitle": "pump-installer and repairer helper"
    },
    {
        "value": 16341,
        "NOCCode": "7612",
        "JobTitle": "railway car repairer helper"
    },
    {
        "value": 16342,
        "NOCCode": "7612",
        "JobTitle": "refrigeration mechanic helper"
    },
    {
        "value": 16343,
        "NOCCode": "7612",
        "JobTitle": "repairer helper, railway cars"
    },
    {
        "value": 16344,
        "NOCCode": "7612",
        "JobTitle": "rig mechanic helper"
    },
    {
        "value": 16345,
        "NOCCode": "7612",
        "JobTitle": "rodman/woman – land surveying"
    },
    {
        "value": 16346,
        "NOCCode": "7612",
        "JobTitle": "sewing machine mechanic helper"
    },
    {
        "value": 16347,
        "NOCCode": "7612",
        "JobTitle": "sewing machine repairer helper"
    },
    {
        "value": 16348,
        "NOCCode": "7612",
        "JobTitle": "ship machinery installer helper"
    },
    {
        "value": 16349,
        "NOCCode": "7612",
        "JobTitle": "shipbuilding electrician helper"
    },
    {
        "value": 16350,
        "NOCCode": "7612",
        "JobTitle": "shipyard electrician helper"
    },
    {
        "value": 16351,
        "NOCCode": "7612",
        "JobTitle": "small-engine mechanic helper"
    },
    {
        "value": 16352,
        "NOCCode": "7612",
        "JobTitle": "splicer helper – telecommunications"
    },
    {
        "value": 16353,
        "NOCCode": "7612",
        "JobTitle": "squeak, rattle and leak locator"
    },
    {
        "value": 16354,
        "NOCCode": "7612",
        "JobTitle": "stakeman/woman – land surveying"
    },
    {
        "value": 16355,
        "NOCCode": "7612",
        "JobTitle": "staker – detailer"
    },
    {
        "value": 16356,
        "NOCCode": "7612",
        "JobTitle": "stationary engine oiler"
    },
    {
        "value": 16357,
        "NOCCode": "7612",
        "JobTitle": "surveying and optical instruments repairer helper"
    },
    {
        "value": 16358,
        "NOCCode": "7612",
        "JobTitle": "surveyor helper"
    },
    {
        "value": 16359,
        "NOCCode": "7612",
        "JobTitle": "switchman/woman helper – telecommunications"
    },
    {
        "value": 16360,
        "NOCCode": "7612",
        "JobTitle": "telephone cable splicer helper"
    },
    {
        "value": 16361,
        "NOCCode": "7612",
        "JobTitle": "underground cable helper"
    },
    {
        "value": 16362,
        "NOCCode": "7612",
        "JobTitle": "underground cable splicer helper"
    },
    {
        "value": 16363,
        "NOCCode": "7612",
        "JobTitle": "utilities instrument mechanic helper"
    },
    {
        "value": 16364,
        "NOCCode": "7612",
        "JobTitle": "utility pole installer"
    },
    {
        "value": 16365,
        "NOCCode": "7612",
        "JobTitle": "window air conditioner repairer helper"
    },
    {
        "value": 16366,
        "NOCCode": "7621",
        "JobTitle": "casual worker – public works"
    },
    {
        "value": 16367,
        "NOCCode": "7621",
        "JobTitle": "dumpman/woman"
    },
    {
        "value": 16368,
        "NOCCode": "7621",
        "JobTitle": "garbage receptacle maintainer"
    },
    {
        "value": 16369,
        "NOCCode": "7621",
        "JobTitle": "garbage truck loader"
    },
    {
        "value": 16370,
        "NOCCode": "7621",
        "JobTitle": "general labourer – public works"
    },
    {
        "value": 16371,
        "NOCCode": "7621",
        "JobTitle": "helper – garbage collection"
    },
    {
        "value": 16372,
        "NOCCode": "7621",
        "JobTitle": "highway line painter"
    },
    {
        "value": 16373,
        "NOCCode": "7621",
        "JobTitle": "labourer – sanitary service"
    },
    {
        "value": 16374,
        "NOCCode": "7621",
        "JobTitle": "labourer crew leader – public works"
    },
    {
        "value": 16375,
        "NOCCode": "7621",
        "JobTitle": "line-painting machine operator – highways and roads"
    },
    {
        "value": 16376,
        "NOCCode": "7621",
        "JobTitle": "maintenance labourer – public works"
    },
    {
        "value": 16377,
        "NOCCode": "7621",
        "JobTitle": "manual cleaner, sewer pipes"
    },
    {
        "value": 16378,
        "NOCCode": "7621",
        "JobTitle": "manual cleaner, sidewalks"
    },
    {
        "value": 16379,
        "NOCCode": "7621",
        "JobTitle": "manual sewer pipe cleaner"
    },
    {
        "value": 16380,
        "NOCCode": "7621",
        "JobTitle": "manual sidewalk cleaner"
    },
    {
        "value": 16381,
        "NOCCode": "7621",
        "JobTitle": "manual street cleaner"
    },
    {
        "value": 16382,
        "NOCCode": "7621",
        "JobTitle": "manual street sweeper"
    },
    {
        "value": 16383,
        "NOCCode": "7621",
        "JobTitle": "municipal elemental worker"
    },
    {
        "value": 16384,
        "NOCCode": "7621",
        "JobTitle": "municipal general labourer"
    },
    {
        "value": 16385,
        "NOCCode": "7621",
        "JobTitle": "municipal labourer"
    },
    {
        "value": 16386,
        "NOCCode": "7621",
        "JobTitle": "parking meter collector"
    },
    {
        "value": 16387,
        "NOCCode": "7621",
        "JobTitle": "playground maintenance man/woman"
    },
    {
        "value": 16388,
        "NOCCode": "7621",
        "JobTitle": "public works labourer"
    },
    {
        "value": 16389,
        "NOCCode": "7621",
        "JobTitle": "road maintenance worker"
    },
    {
        "value": 16390,
        "NOCCode": "7621",
        "JobTitle": "road maintenance worker – public works"
    },
    {
        "value": 16391,
        "NOCCode": "7621",
        "JobTitle": "sanitation man/woman"
    },
    {
        "value": 16392,
        "NOCCode": "7621",
        "JobTitle": "sanitation worker"
    },
    {
        "value": 16393,
        "NOCCode": "7621",
        "JobTitle": "seasonal worker – public works"
    },
    {
        "value": 16394,
        "NOCCode": "7621",
        "JobTitle": "sewer maintenance worker"
    },
    {
        "value": 16395,
        "NOCCode": "7621",
        "JobTitle": "sewer system maintenance worker"
    },
    {
        "value": 16396,
        "NOCCode": "7621",
        "JobTitle": "sidewalk cleaner"
    },
    {
        "value": 16397,
        "NOCCode": "7621",
        "JobTitle": "sidewalk snowplough operator"
    },
    {
        "value": 16398,
        "NOCCode": "7621",
        "JobTitle": "sidewalk-cleaning equipment operator"
    },
    {
        "value": 16399,
        "NOCCode": "7621",
        "JobTitle": "snowplough operator, sidewalk"
    },
    {
        "value": 16400,
        "NOCCode": "7622",
        "JobTitle": "car checker – railway transport"
    },
    {
        "value": 16401,
        "NOCCode": "7622",
        "JobTitle": "car checker, railway"
    },
    {
        "value": 16402,
        "NOCCode": "7622",
        "JobTitle": "car weigher – railway"
    },
    {
        "value": 16403,
        "NOCCode": "7622",
        "JobTitle": "carman/woman helper – railway"
    },
    {
        "value": 16404,
        "NOCCode": "7622",
        "JobTitle": "cold-storage checker – transport"
    },
    {
        "value": 16405,
        "NOCCode": "7622",
        "JobTitle": "crossing attendant – railway"
    },
    {
        "value": 16406,
        "NOCCode": "7622",
        "JobTitle": "crossing gateman/woman – railway transport"
    },
    {
        "value": 16407,
        "NOCCode": "7622",
        "JobTitle": "deliverer helper"
    },
    {
        "value": 16408,
        "NOCCode": "7622",
        "JobTitle": "delivery truck driver helper"
    },
    {
        "value": 16409,
        "NOCCode": "7622",
        "JobTitle": "delivery truck helper"
    },
    {
        "value": 16410,
        "NOCCode": "7622",
        "JobTitle": "driver helper"
    },
    {
        "value": 16411,
        "NOCCode": "7622",
        "JobTitle": "engineer's assistant"
    },
    {
        "value": 16412,
        "NOCCode": "7622",
        "JobTitle": "freight car checker – railway"
    },
    {
        "value": 16413,
        "NOCCode": "7622",
        "JobTitle": "freight car weigher"
    },
    {
        "value": 16414,
        "NOCCode": "7622",
        "JobTitle": "fuel and sand operator – railway transport"
    },
    {
        "value": 16415,
        "NOCCode": "7622",
        "JobTitle": "fuel truck driver helper"
    },
    {
        "value": 16416,
        "NOCCode": "7622",
        "JobTitle": "furniture mover helper"
    },
    {
        "value": 16417,
        "NOCCode": "7622",
        "JobTitle": "gateman/woman – railway transport"
    },
    {
        "value": 16418,
        "NOCCode": "7622",
        "JobTitle": "helper, carman/woman"
    },
    {
        "value": 16419,
        "NOCCode": "7622",
        "JobTitle": "helper, delivery truck"
    },
    {
        "value": 16420,
        "NOCCode": "7622",
        "JobTitle": "labourer, section – railway"
    },
    {
        "value": 16421,
        "NOCCode": "7622",
        "JobTitle": "labourer, yard – railway transport"
    },
    {
        "value": 16422,
        "NOCCode": "7622",
        "JobTitle": "motor transport labourer"
    },
    {
        "value": 16423,
        "NOCCode": "7622",
        "JobTitle": "mover helper"
    },
    {
        "value": 16424,
        "NOCCode": "7622",
        "JobTitle": "moving van helper"
    },
    {
        "value": 16425,
        "NOCCode": "7622",
        "JobTitle": "rail lubricator"
    },
    {
        "value": 16426,
        "NOCCode": "7622",
        "JobTitle": "railway car decal applier"
    },
    {
        "value": 16427,
        "NOCCode": "7622",
        "JobTitle": "railway car weigher"
    },
    {
        "value": 16428,
        "NOCCode": "7622",
        "JobTitle": "railway labourer"
    },
    {
        "value": 16429,
        "NOCCode": "7622",
        "JobTitle": "railway track labourer"
    },
    {
        "value": 16430,
        "NOCCode": "7622",
        "JobTitle": "railway track maintenance labourer"
    },
    {
        "value": 16431,
        "NOCCode": "7622",
        "JobTitle": "railway yard labourer"
    },
    {
        "value": 16432,
        "NOCCode": "7622",
        "JobTitle": "route driver helper"
    },
    {
        "value": 16433,
        "NOCCode": "7622",
        "JobTitle": "section labourer – railway"
    },
    {
        "value": 16434,
        "NOCCode": "7622",
        "JobTitle": "siding checker-messenger – railway transport"
    },
    {
        "value": 16435,
        "NOCCode": "7622",
        "JobTitle": "signal gang helper"
    },
    {
        "value": 16436,
        "NOCCode": "7622",
        "JobTitle": "swamper – truck transport"
    },
    {
        "value": 16437,
        "NOCCode": "7622",
        "JobTitle": "track greaser – railway"
    },
    {
        "value": 16438,
        "NOCCode": "7622",
        "JobTitle": "track labourer, railway"
    },
    {
        "value": 16439,
        "NOCCode": "7622",
        "JobTitle": "track sweeper – railway"
    },
    {
        "value": 16440,
        "NOCCode": "7622",
        "JobTitle": "truck driver helper"
    },
    {
        "value": 16441,
        "NOCCode": "7622",
        "JobTitle": "weigher, freight cars"
    },
    {
        "value": 16442,
        "NOCCode": "7622",
        "JobTitle": "weigher, railway car"
    },
    {
        "value": 16443,
        "NOCCode": "8211",
        "JobTitle": "boom master – logging"
    },
    {
        "value": 16444,
        "NOCCode": "8211",
        "JobTitle": "buckers supervisor"
    },
    {
        "value": 16445,
        "NOCCode": "8211",
        "JobTitle": "contractor, forest conservation"
    },
    {
        "value": 16446,
        "NOCCode": "8211",
        "JobTitle": "contractor, logging"
    },
    {
        "value": 16447,
        "NOCCode": "8211",
        "JobTitle": "contractor, silviculture"
    },
    {
        "value": 16448,
        "NOCCode": "8211",
        "JobTitle": "fire suppression crew foreman/woman – forestry"
    },
    {
        "value": 16449,
        "NOCCode": "8211",
        "JobTitle": "foreman/woman, forestry"
    },
    {
        "value": 16450,
        "NOCCode": "8211",
        "JobTitle": "foreman/woman, logging"
    },
    {
        "value": 16451,
        "NOCCode": "8211",
        "JobTitle": "foreman/woman, planting crew"
    },
    {
        "value": 16452,
        "NOCCode": "8211",
        "JobTitle": "foreman/woman, pond"
    },
    {
        "value": 16453,
        "NOCCode": "8211",
        "JobTitle": "forest operations supervisor"
    },
    {
        "value": 16454,
        "NOCCode": "8211",
        "JobTitle": "forestry conservation contractor"
    },
    {
        "value": 16455,
        "NOCCode": "8211",
        "JobTitle": "forestry crew supervisor"
    },
    {
        "value": 16456,
        "NOCCode": "8211",
        "JobTitle": "forestry foreman/woman"
    },
    {
        "value": 16457,
        "NOCCode": "8211",
        "JobTitle": "forestry supervisor"
    },
    {
        "value": 16458,
        "NOCCode": "8211",
        "JobTitle": "grapple yarder hook tender"
    },
    {
        "value": 16459,
        "NOCCode": "8211",
        "JobTitle": "hook and rig supervisor – logging"
    },
    {
        "value": 16460,
        "NOCCode": "8211",
        "JobTitle": "hook tender – logging"
    },
    {
        "value": 16461,
        "NOCCode": "8211",
        "JobTitle": "loading foreman/woman – logging"
    },
    {
        "value": 16462,
        "NOCCode": "8211",
        "JobTitle": "log yarding boss"
    },
    {
        "value": 16463,
        "NOCCode": "8211",
        "JobTitle": "loggers supervisor – forestry"
    },
    {
        "value": 16464,
        "NOCCode": "8211",
        "JobTitle": "logging contractor"
    },
    {
        "value": 16465,
        "NOCCode": "8211",
        "JobTitle": "logging crew supervisor – forestry"
    },
    {
        "value": 16466,
        "NOCCode": "8211",
        "JobTitle": "logging foreman/woman"
    },
    {
        "value": 16467,
        "NOCCode": "8211",
        "JobTitle": "logging supervisor"
    },
    {
        "value": 16468,
        "NOCCode": "8211",
        "JobTitle": "planting crew foreman/woman"
    },
    {
        "value": 16469,
        "NOCCode": "8211",
        "JobTitle": "pond foreman/woman"
    },
    {
        "value": 16470,
        "NOCCode": "8211",
        "JobTitle": "production supervisor – logging"
    },
    {
        "value": 16471,
        "NOCCode": "8211",
        "JobTitle": "setting foreman/woman logging"
    },
    {
        "value": 16472,
        "NOCCode": "8211",
        "JobTitle": "silviculture contractor"
    },
    {
        "value": 16473,
        "NOCCode": "8211",
        "JobTitle": "silviculture supervisor"
    },
    {
        "value": 16474,
        "NOCCode": "8211",
        "JobTitle": "skidders foreman/woman – logging"
    },
    {
        "value": 16475,
        "NOCCode": "8211",
        "JobTitle": "supervisor, forest operations"
    },
    {
        "value": 16476,
        "NOCCode": "8211",
        "JobTitle": "supervisor, forestry"
    },
    {
        "value": 16477,
        "NOCCode": "8211",
        "JobTitle": "supervisor, forestry crew"
    },
    {
        "value": 16478,
        "NOCCode": "8211",
        "JobTitle": "supervisor, logging"
    },
    {
        "value": 16479,
        "NOCCode": "8211",
        "JobTitle": "woods foreman/woman"
    },
    {
        "value": 16480,
        "NOCCode": "8221",
        "JobTitle": "blasters foreman/woman – mining and quarrying"
    },
    {
        "value": 16481,
        "NOCCode": "8221",
        "JobTitle": "blasters foreman/woman – quarrying"
    },
    {
        "value": 16482,
        "NOCCode": "8221",
        "JobTitle": "blasters foreman/woman – surface mining"
    },
    {
        "value": 16483,
        "NOCCode": "8221",
        "JobTitle": "boss, level – underground mining"
    },
    {
        "value": 16484,
        "NOCCode": "8221",
        "JobTitle": "boss, sand fill – underground mining"
    },
    {
        "value": 16485,
        "NOCCode": "8221",
        "JobTitle": "boss, shift – mining and quarrying"
    },
    {
        "value": 16486,
        "NOCCode": "8221",
        "JobTitle": "boss, track layers – underground mining"
    },
    {
        "value": 16487,
        "NOCCode": "8221",
        "JobTitle": "boss, yard – mining and quarrying"
    },
    {
        "value": 16488,
        "NOCCode": "8221",
        "JobTitle": "coal mine foreman/woman"
    },
    {
        "value": 16489,
        "NOCCode": "8221",
        "JobTitle": "construction supervisor – underground mining and quarrying"
    },
    {
        "value": 16490,
        "NOCCode": "8221",
        "JobTitle": "diamond drilling foreman/woman"
    },
    {
        "value": 16491,
        "NOCCode": "8221",
        "JobTitle": "drill fitter boss – mining"
    },
    {
        "value": 16492,
        "NOCCode": "8221",
        "JobTitle": "drill fitter foreman/woman – mining"
    },
    {
        "value": 16493,
        "NOCCode": "8221",
        "JobTitle": "drilling foreman/woman – mining and quarrying"
    },
    {
        "value": 16494,
        "NOCCode": "8221",
        "JobTitle": "dynamiters foreman/woman – quarrying"
    },
    {
        "value": 16495,
        "NOCCode": "8221",
        "JobTitle": "face boss – underground mining"
    },
    {
        "value": 16496,
        "NOCCode": "8221",
        "JobTitle": "fill foreman/woman"
    },
    {
        "value": 16497,
        "NOCCode": "8221",
        "JobTitle": "fill foreman/woman – underground mining"
    },
    {
        "value": 16498,
        "NOCCode": "8221",
        "JobTitle": "fire boss – mining"
    },
    {
        "value": 16499,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, blasters – quarrying"
    },
    {
        "value": 16500,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, blasters – surface mining"
    },
    {
        "value": 16501,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, blasting – mining and quarrying"
    },
    {
        "value": 16502,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, coal mine"
    },
    {
        "value": 16503,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, diamond drilling"
    },
    {
        "value": 16504,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, drilling – mining and quarrying"
    },
    {
        "value": 16505,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, fill – underground mining"
    },
    {
        "value": 16506,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, mine"
    },
    {
        "value": 16507,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, motormen/women and dinkeymen/women (except rail transport)"
    },
    {
        "value": 16508,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, production – mining and quarrying"
    },
    {
        "value": 16509,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, quarry"
    },
    {
        "value": 16510,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, shift – mining and quarrying"
    },
    {
        "value": 16511,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, shot – mining and quarrying"
    },
    {
        "value": 16512,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, stope – mining"
    },
    {
        "value": 16513,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, surface mine"
    },
    {
        "value": 16514,
        "NOCCode": "8221",
        "JobTitle": "foreman/woman, underground mine"
    },
    {
        "value": 16515,
        "NOCCode": "8221",
        "JobTitle": "general foreman/woman – mining"
    },
    {
        "value": 16516,
        "NOCCode": "8221",
        "JobTitle": "junior foreman/woman – mining"
    },
    {
        "value": 16517,
        "NOCCode": "8221",
        "JobTitle": "leader, shift – mining and quarrying"
    },
    {
        "value": 16518,
        "NOCCode": "8221",
        "JobTitle": "level boss – underground mining"
    },
    {
        "value": 16519,
        "NOCCode": "8221",
        "JobTitle": "mine captain"
    },
    {
        "value": 16520,
        "NOCCode": "8221",
        "JobTitle": "mine foreman/woman"
    },
    {
        "value": 16521,
        "NOCCode": "8221",
        "JobTitle": "motormen/women and dinkeymen/women foreman/woman (except rail transport)"
    },
    {
        "value": 16522,
        "NOCCode": "8221",
        "JobTitle": "open pit mine supervisor"
    },
    {
        "value": 16523,
        "NOCCode": "8221",
        "JobTitle": "production foreman/woman – mining and quarrying"
    },
    {
        "value": 16524,
        "NOCCode": "8221",
        "JobTitle": "quarry foreman/woman"
    },
    {
        "value": 16525,
        "NOCCode": "8221",
        "JobTitle": "relieving surface foreman/woman – mining"
    },
    {
        "value": 16526,
        "NOCCode": "8221",
        "JobTitle": "salvage foreman/woman – mining"
    },
    {
        "value": 16527,
        "NOCCode": "8221",
        "JobTitle": "sand fill boss – underground mining"
    },
    {
        "value": 16528,
        "NOCCode": "8221",
        "JobTitle": "senior foreman/woman – mining"
    },
    {
        "value": 16529,
        "NOCCode": "8221",
        "JobTitle": "shift boss – mining and quarrying"
    },
    {
        "value": 16530,
        "NOCCode": "8221",
        "JobTitle": "shift foreman/woman – mining and quarrying"
    },
    {
        "value": 16531,
        "NOCCode": "8221",
        "JobTitle": "shift leader – mining and quarrying"
    },
    {
        "value": 16532,
        "NOCCode": "8221",
        "JobTitle": "shot foreman/woman – mining and quarrying"
    },
    {
        "value": 16533,
        "NOCCode": "8221",
        "JobTitle": "stope foreman/woman – mining"
    },
    {
        "value": 16534,
        "NOCCode": "8221",
        "JobTitle": "supervisor, construction – underground mining and quarrying"
    },
    {
        "value": 16535,
        "NOCCode": "8221",
        "JobTitle": "supervisor, mine"
    },
    {
        "value": 16536,
        "NOCCode": "8221",
        "JobTitle": "supervisor, open pit mine"
    },
    {
        "value": 16537,
        "NOCCode": "8221",
        "JobTitle": "supervisor, quarry"
    },
    {
        "value": 16538,
        "NOCCode": "8221",
        "JobTitle": "supervisor, surface mine"
    },
    {
        "value": 16539,
        "NOCCode": "8221",
        "JobTitle": "supervisor, yard – mining and quarrying"
    },
    {
        "value": 16540,
        "NOCCode": "8221",
        "JobTitle": "supply boss – mining"
    },
    {
        "value": 16541,
        "NOCCode": "8221",
        "JobTitle": "supply foreman/woman – mining"
    },
    {
        "value": 16542,
        "NOCCode": "8221",
        "JobTitle": "surface foreman/woman – mining"
    },
    {
        "value": 16543,
        "NOCCode": "8221",
        "JobTitle": "surface mine foreman/woman"
    },
    {
        "value": 16544,
        "NOCCode": "8221",
        "JobTitle": "track boss – underground mining"
    },
    {
        "value": 16545,
        "NOCCode": "8221",
        "JobTitle": "yard boss – mining and quarrying"
    },
    {
        "value": 16546,
        "NOCCode": "8221",
        "JobTitle": "yard supervisor – mining and quarrying"
    },
    {
        "value": 16547,
        "NOCCode": "8222",
        "JobTitle": "assistant offshore drilling rig superintendent"
    },
    {
        "value": 16548,
        "NOCCode": "8222",
        "JobTitle": "assistant platform superintendent"
    },
    {
        "value": 16549,
        "NOCCode": "8222",
        "JobTitle": "assistant superintendent, offshore drilling rig"
    },
    {
        "value": 16550,
        "NOCCode": "8222",
        "JobTitle": "assistant toolpusher – offshore drilling rig"
    },
    {
        "value": 16551,
        "NOCCode": "8222",
        "JobTitle": "consultant – gas well drilling"
    },
    {
        "value": 16552,
        "NOCCode": "8222",
        "JobTitle": "consultant – oil drilling"
    },
    {
        "value": 16553,
        "NOCCode": "8222",
        "JobTitle": "directional drilling consultant – oil and gas"
    },
    {
        "value": 16554,
        "NOCCode": "8222",
        "JobTitle": "field supervisor, oil well servicing"
    },
    {
        "value": 16555,
        "NOCCode": "8222",
        "JobTitle": "foreman/woman, oil and gas well drilling"
    },
    {
        "value": 16556,
        "NOCCode": "8222",
        "JobTitle": "foreman/woman, oil and natural gas well servicing"
    },
    {
        "value": 16557,
        "NOCCode": "8222",
        "JobTitle": "fracturing supervisor"
    },
    {
        "value": 16558,
        "NOCCode": "8222",
        "JobTitle": "fracturing supervisor – oil field services"
    },
    {
        "value": 16559,
        "NOCCode": "8222",
        "JobTitle": "multi-service operator – oil field services"
    },
    {
        "value": 16560,
        "NOCCode": "8222",
        "JobTitle": "offshore drilling rig superintendent"
    },
    {
        "value": 16561,
        "NOCCode": "8222",
        "JobTitle": "oil and gas contractor"
    },
    {
        "value": 16562,
        "NOCCode": "8222",
        "JobTitle": "oil and gas drilling foreman/woman"
    },
    {
        "value": 16563,
        "NOCCode": "8222",
        "JobTitle": "oil and natural gas well servicing foreman/woman"
    },
    {
        "value": 16564,
        "NOCCode": "8222",
        "JobTitle": "oil well services field supervisor"
    },
    {
        "value": 16565,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant"
    },
    {
        "value": 16566,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant – construction and reclamation"
    },
    {
        "value": 16567,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant – rig moving"
    },
    {
        "value": 16568,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant – well completions"
    },
    {
        "value": 16569,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant – well servicing"
    },
    {
        "value": 16570,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant – well testing"
    },
    {
        "value": 16571,
        "NOCCode": "8222",
        "JobTitle": "oilfield consultant supervisor"
    },
    {
        "value": 16572,
        "NOCCode": "8222",
        "JobTitle": "oilfield contractor"
    },
    {
        "value": 16573,
        "NOCCode": "8222",
        "JobTitle": "platform superintendent – oil and gas drilling"
    },
    {
        "value": 16574,
        "NOCCode": "8222",
        "JobTitle": "rig manager"
    },
    {
        "value": 16575,
        "NOCCode": "8222",
        "JobTitle": "rig manager – oil and gas drilling"
    },
    {
        "value": 16576,
        "NOCCode": "8222",
        "JobTitle": "supervisor, well services crew – oil field services"
    },
    {
        "value": 16577,
        "NOCCode": "8222",
        "JobTitle": "toolpusher – offshore drilling rig"
    },
    {
        "value": 16578,
        "NOCCode": "8222",
        "JobTitle": "toolpusher – oil and gas well"
    },
    {
        "value": 16579,
        "NOCCode": "8222",
        "JobTitle": "well services crew supervisor"
    },
    {
        "value": 16580,
        "NOCCode": "8222",
        "JobTitle": "well services crew supervisor – oil and gas drilling"
    },
    {
        "value": 16581,
        "NOCCode": "8222",
        "JobTitle": "well services crew supervisor – oil field services"
    },
    {
        "value": 16582,
        "NOCCode": "8222",
        "JobTitle": "well site consultant – oil and gas"
    },
    {
        "value": 16583,
        "NOCCode": "8222",
        "JobTitle": "wireline field supervisor – oil field services"
    },
    {
        "value": 16584,
        "NOCCode": "8231",
        "JobTitle": "apprentice miner"
    },
    {
        "value": 16585,
        "NOCCode": "8231",
        "JobTitle": "bench blaster – underground mining"
    },
    {
        "value": 16586,
        "NOCCode": "8231",
        "JobTitle": "blast hole driller – underground mining"
    },
    {
        "value": 16587,
        "NOCCode": "8231",
        "JobTitle": "blaster – underground mining"
    },
    {
        "value": 16588,
        "NOCCode": "8231",
        "JobTitle": "blockholer – underground mining"
    },
    {
        "value": 16589,
        "NOCCode": "8231",
        "JobTitle": "bolter operator – underground mining"
    },
    {
        "value": 16590,
        "NOCCode": "8231",
        "JobTitle": "brine well tender"
    },
    {
        "value": 16591,
        "NOCCode": "8231",
        "JobTitle": "chute blaster"
    },
    {
        "value": 16592,
        "NOCCode": "8231",
        "JobTitle": "chute blaster – underground mining"
    },
    {
        "value": 16593,
        "NOCCode": "8231",
        "JobTitle": "coal miner"
    },
    {
        "value": 16594,
        "NOCCode": "8231",
        "JobTitle": "continuous mining machine operator"
    },
    {
        "value": 16595,
        "NOCCode": "8231",
        "JobTitle": "core driller"
    },
    {
        "value": 16596,
        "NOCCode": "8231",
        "JobTitle": "core driller – underground mining"
    },
    {
        "value": 16597,
        "NOCCode": "8231",
        "JobTitle": "core-drill operator"
    },
    {
        "value": 16598,
        "NOCCode": "8231",
        "JobTitle": "core-drill operator – underground mining"
    },
    {
        "value": 16599,
        "NOCCode": "8231",
        "JobTitle": "cutting machine operator – underground mining"
    },
    {
        "value": 16600,
        "NOCCode": "8231",
        "JobTitle": "development miner"
    },
    {
        "value": 16601,
        "NOCCode": "8231",
        "JobTitle": "diamond driller – underground mining"
    },
    {
        "value": 16602,
        "NOCCode": "8231",
        "JobTitle": "diamond-drill operator"
    },
    {
        "value": 16603,
        "NOCCode": "8231",
        "JobTitle": "diamond-drill runner – underground mining"
    },
    {
        "value": 16604,
        "NOCCode": "8231",
        "JobTitle": "diamond-point drill operator"
    },
    {
        "value": 16605,
        "NOCCode": "8231",
        "JobTitle": "diesel loaderman/woman – underground mining"
    },
    {
        "value": 16606,
        "NOCCode": "8231",
        "JobTitle": "downhole loader – underground mining"
    },
    {
        "value": 16607,
        "NOCCode": "8231",
        "JobTitle": "drift driller – underground mining"
    },
    {
        "value": 16608,
        "NOCCode": "8231",
        "JobTitle": "drift miner"
    },
    {
        "value": 16609,
        "NOCCode": "8231",
        "JobTitle": "driller – underground mining"
    },
    {
        "value": 16610,
        "NOCCode": "8231",
        "JobTitle": "driller, drift – underground mining"
    },
    {
        "value": 16611,
        "NOCCode": "8231",
        "JobTitle": "driller, long-hole – underground mining"
    },
    {
        "value": 16612,
        "NOCCode": "8231",
        "JobTitle": "driller, rotary raise – underground mining"
    },
    {
        "value": 16613,
        "NOCCode": "8231",
        "JobTitle": "drilling machine operator – underground mining"
    },
    {
        "value": 16614,
        "NOCCode": "8231",
        "JobTitle": "faceman/woman – coal mine"
    },
    {
        "value": 16615,
        "NOCCode": "8231",
        "JobTitle": "hang-up blaster – underground mining"
    },
    {
        "value": 16616,
        "NOCCode": "8231",
        "JobTitle": "hardrock miner"
    },
    {
        "value": 16617,
        "NOCCode": "8231",
        "JobTitle": "hardrock miner apprentice"
    },
    {
        "value": 16618,
        "NOCCode": "8231",
        "JobTitle": "high-raise blaster"
    },
    {
        "value": 16619,
        "NOCCode": "8231",
        "JobTitle": "hoist operator – underground mining"
    },
    {
        "value": 16620,
        "NOCCode": "8231",
        "JobTitle": "hoistman/woman underground mining"
    },
    {
        "value": 16621,
        "NOCCode": "8231",
        "JobTitle": "hydraulic coal monitor operator"
    },
    {
        "value": 16622,
        "NOCCode": "8231",
        "JobTitle": "in-hole drill operator – underground mining"
    },
    {
        "value": 16623,
        "NOCCode": "8231",
        "JobTitle": "ITH (in-the-hole) driller"
    },
    {
        "value": 16624,
        "NOCCode": "8231",
        "JobTitle": "jack leg drill operator – underground mining"
    },
    {
        "value": 16625,
        "NOCCode": "8231",
        "JobTitle": "jumbo drill operator"
    },
    {
        "value": 16626,
        "NOCCode": "8231",
        "JobTitle": "jumbo drill operator – underground mining"
    },
    {
        "value": 16627,
        "NOCCode": "8231",
        "JobTitle": "LHD (load-haul-dump) operator – underground mining"
    },
    {
        "value": 16628,
        "NOCCode": "8231",
        "JobTitle": "loaderman/woman, diesel – underground mining"
    },
    {
        "value": 16629,
        "NOCCode": "8231",
        "JobTitle": "load-haul-dump (LHD) operator – underground mining"
    },
    {
        "value": 16630,
        "NOCCode": "8231",
        "JobTitle": "loading machine operator – underground mining"
    },
    {
        "value": 16631,
        "NOCCode": "8231",
        "JobTitle": "long-hole blaster – underground mining"
    },
    {
        "value": 16632,
        "NOCCode": "8231",
        "JobTitle": "long-hole driller – underground mining"
    },
    {
        "value": 16633,
        "NOCCode": "8231",
        "JobTitle": "longwall coal shearer operator"
    },
    {
        "value": 16634,
        "NOCCode": "8231",
        "JobTitle": "miner"
    },
    {
        "value": 16635,
        "NOCCode": "8231",
        "JobTitle": "mining machine operator"
    },
    {
        "value": 16636,
        "NOCCode": "8231",
        "JobTitle": "mucker – underground mining"
    },
    {
        "value": 16637,
        "NOCCode": "8231",
        "JobTitle": "mucking machine operator"
    },
    {
        "value": 16638,
        "NOCCode": "8231",
        "JobTitle": "mucking machine operator – underground mining"
    },
    {
        "value": 16639,
        "NOCCode": "8231",
        "JobTitle": "potash miner"
    },
    {
        "value": 16640,
        "NOCCode": "8231",
        "JobTitle": "powderman/woman – underground mining"
    },
    {
        "value": 16641,
        "NOCCode": "8231",
        "JobTitle": "production driller"
    },
    {
        "value": 16642,
        "NOCCode": "8231",
        "JobTitle": "production loader operator"
    },
    {
        "value": 16643,
        "NOCCode": "8231",
        "JobTitle": "production miner"
    },
    {
        "value": 16644,
        "NOCCode": "8231",
        "JobTitle": "raise boring machine operator – underground mining"
    },
    {
        "value": 16645,
        "NOCCode": "8231",
        "JobTitle": "raise driller – underground mining"
    },
    {
        "value": 16646,
        "NOCCode": "8231",
        "JobTitle": "raise miner"
    },
    {
        "value": 16647,
        "NOCCode": "8231",
        "JobTitle": "raiseman/woman"
    },
    {
        "value": 16648,
        "NOCCode": "8231",
        "JobTitle": "ring drill operator"
    },
    {
        "value": 16649,
        "NOCCode": "8231",
        "JobTitle": "roadheader operator"
    },
    {
        "value": 16650,
        "NOCCode": "8231",
        "JobTitle": "rockbolter"
    },
    {
        "value": 16651,
        "NOCCode": "8231",
        "JobTitle": "roof bolter"
    },
    {
        "value": 16652,
        "NOCCode": "8231",
        "JobTitle": "rotary raise driller – underground mining"
    },
    {
        "value": 16653,
        "NOCCode": "8231",
        "JobTitle": "scoop operator – underground mining"
    },
    {
        "value": 16654,
        "NOCCode": "8231",
        "JobTitle": "scooptram operator"
    },
    {
        "value": 16655,
        "NOCCode": "8231",
        "JobTitle": "scooptram operator – underground mining"
    },
    {
        "value": 16656,
        "NOCCode": "8231",
        "JobTitle": "shaft driller"
    },
    {
        "value": 16657,
        "NOCCode": "8231",
        "JobTitle": "shaft inspector"
    },
    {
        "value": 16658,
        "NOCCode": "8231",
        "JobTitle": "shaft miner"
    },
    {
        "value": 16659,
        "NOCCode": "8231",
        "JobTitle": "shearer operator – underground mining"
    },
    {
        "value": 16660,
        "NOCCode": "8231",
        "JobTitle": "shearing machine operator – underground mining"
    },
    {
        "value": 16661,
        "NOCCode": "8231",
        "JobTitle": "shot firer"
    },
    {
        "value": 16662,
        "NOCCode": "8231",
        "JobTitle": "shot firer – underground mining"
    },
    {
        "value": 16663,
        "NOCCode": "8231",
        "JobTitle": "shotman/woman – underground mining"
    },
    {
        "value": 16664,
        "NOCCode": "8231",
        "JobTitle": "slusher operator – underground mining"
    },
    {
        "value": 16665,
        "NOCCode": "8231",
        "JobTitle": "soft rock miner"
    },
    {
        "value": 16666,
        "NOCCode": "8231",
        "JobTitle": "stope driller – underground mining"
    },
    {
        "value": 16667,
        "NOCCode": "8231",
        "JobTitle": "stope miner"
    },
    {
        "value": 16668,
        "NOCCode": "8231",
        "JobTitle": "undercutter operator – underground mining"
    },
    {
        "value": 16669,
        "NOCCode": "8231",
        "JobTitle": "wagon drill operator – underground mining"
    },
    {
        "value": 16670,
        "NOCCode": "8232",
        "JobTitle": "assistant driller – offshore drilling rig"
    },
    {
        "value": 16671,
        "NOCCode": "8232",
        "JobTitle": "assistant driller – oil and gas well drilling"
    },
    {
        "value": 16672,
        "NOCCode": "8232",
        "JobTitle": "coring operator – oil field services"
    },
    {
        "value": 16673,
        "NOCCode": "8232",
        "JobTitle": "directional driller – oil and gas drilling"
    },
    {
        "value": 16674,
        "NOCCode": "8232",
        "JobTitle": "directional drilling operator"
    },
    {
        "value": 16675,
        "NOCCode": "8232",
        "JobTitle": "directional drilling operator – oil and gas drilling"
    },
    {
        "value": 16676,
        "NOCCode": "8232",
        "JobTitle": "downhole tool operator"
    },
    {
        "value": 16677,
        "NOCCode": "8232",
        "JobTitle": "downhole tool operator – oil and gas drilling"
    },
    {
        "value": 16678,
        "NOCCode": "8232",
        "JobTitle": "drill stem tester"
    },
    {
        "value": 16679,
        "NOCCode": "8232",
        "JobTitle": "drill stem tester – oil and gas drilling"
    },
    {
        "value": 16680,
        "NOCCode": "8232",
        "JobTitle": "driller – offshore drilling rig"
    },
    {
        "value": 16681,
        "NOCCode": "8232",
        "JobTitle": "driller – oil and gas drilling"
    },
    {
        "value": 16682,
        "NOCCode": "8232",
        "JobTitle": "electric line operator"
    },
    {
        "value": 16683,
        "NOCCode": "8232",
        "JobTitle": "electric line operator – oil field services"
    },
    {
        "value": 16684,
        "NOCCode": "8232",
        "JobTitle": "e-line operator – oil field services"
    },
    {
        "value": 16685,
        "NOCCode": "8232",
        "JobTitle": "fishing tool operator – oil field services"
    },
    {
        "value": 16686,
        "NOCCode": "8232",
        "JobTitle": "gas tester – oil field services"
    },
    {
        "value": 16687,
        "NOCCode": "8232",
        "JobTitle": "gun perforator – oil field services"
    },
    {
        "value": 16688,
        "NOCCode": "8232",
        "JobTitle": "hydrocarbon mud logger – oil field services"
    },
    {
        "value": 16689,
        "NOCCode": "8232",
        "JobTitle": "logging and perforating operator"
    },
    {
        "value": 16690,
        "NOCCode": "8232",
        "JobTitle": "logging and perforating operator – oil field services"
    },
    {
        "value": 16691,
        "NOCCode": "8232",
        "JobTitle": "offshore driller – oil and gas"
    },
    {
        "value": 16692,
        "NOCCode": "8232",
        "JobTitle": "oil well perforator"
    },
    {
        "value": 16693,
        "NOCCode": "8232",
        "JobTitle": "perforator, oil wells"
    },
    {
        "value": 16694,
        "NOCCode": "8232",
        "JobTitle": "production testing operator – oil and gas well drilling"
    },
    {
        "value": 16695,
        "NOCCode": "8232",
        "JobTitle": "rig technician – driller"
    },
    {
        "value": 16696,
        "NOCCode": "8232",
        "JobTitle": "service rig operator"
    },
    {
        "value": 16697,
        "NOCCode": "8232",
        "JobTitle": "service rig operator – oil field services"
    },
    {
        "value": 16698,
        "NOCCode": "8232",
        "JobTitle": "sidewall core sampler – oil field services"
    },
    {
        "value": 16699,
        "NOCCode": "8232",
        "JobTitle": "slickline operator"
    },
    {
        "value": 16700,
        "NOCCode": "8232",
        "JobTitle": "slickline operator – oil field services"
    },
    {
        "value": 16701,
        "NOCCode": "8232",
        "JobTitle": "well puller – oil field services"
    },
    {
        "value": 16702,
        "NOCCode": "8232",
        "JobTitle": "well testing operator"
    },
    {
        "value": 16703,
        "NOCCode": "8232",
        "JobTitle": "well testing operator – oil and gas drilling"
    },
    {
        "value": 16704,
        "NOCCode": "8232",
        "JobTitle": "wireline operator"
    },
    {
        "value": 16705,
        "NOCCode": "8232",
        "JobTitle": "wireline operator – oil field services"
    },
    {
        "value": 16706,
        "NOCCode": "8232",
        "JobTitle": "wireline operator trainee"
    },
    {
        "value": 16707,
        "NOCCode": "8232",
        "JobTitle": "workover rig operator – oil field services"
    },
    {
        "value": 16708,
        "NOCCode": "8241",
        "JobTitle": "bunk skidder operator"
    },
    {
        "value": 16709,
        "NOCCode": "8241",
        "JobTitle": "cable yarding operator"
    },
    {
        "value": 16710,
        "NOCCode": "8241",
        "JobTitle": "cable yarding system operator"
    },
    {
        "value": 16711,
        "NOCCode": "8241",
        "JobTitle": "chipping machine operator"
    },
    {
        "value": 16712,
        "NOCCode": "8241",
        "JobTitle": "chipping machine operator – logging"
    },
    {
        "value": 16713,
        "NOCCode": "8241",
        "JobTitle": "crane and claw operator – logging"
    },
    {
        "value": 16714,
        "NOCCode": "8241",
        "JobTitle": "delimber and buncher operator"
    },
    {
        "value": 16715,
        "NOCCode": "8241",
        "JobTitle": "delimber operator"
    },
    {
        "value": 16716,
        "NOCCode": "8241",
        "JobTitle": "feller buncher operator"
    },
    {
        "value": 16717,
        "NOCCode": "8241",
        "JobTitle": "feller delimber operator"
    },
    {
        "value": 16718,
        "NOCCode": "8241",
        "JobTitle": "feller forwarder operator"
    },
    {
        "value": 16719,
        "NOCCode": "8241",
        "JobTitle": "forwarder operator – logging"
    },
    {
        "value": 16720,
        "NOCCode": "8241",
        "JobTitle": "grapple operator – logging"
    },
    {
        "value": 16721,
        "NOCCode": "8241",
        "JobTitle": "grapple yarder operator – logging"
    },
    {
        "value": 16722,
        "NOCCode": "8241",
        "JobTitle": "linehorse operator"
    },
    {
        "value": 16723,
        "NOCCode": "8241",
        "JobTitle": "loader operator – logging"
    },
    {
        "value": 16724,
        "NOCCode": "8241",
        "JobTitle": "loader, logging truck"
    },
    {
        "value": 16725,
        "NOCCode": "8241",
        "JobTitle": "log loader operator"
    },
    {
        "value": 16726,
        "NOCCode": "8241",
        "JobTitle": "log loading machine operator"
    },
    {
        "value": 16727,
        "NOCCode": "8241",
        "JobTitle": "log process operator"
    },
    {
        "value": 16728,
        "NOCCode": "8241",
        "JobTitle": "log processor operator"
    },
    {
        "value": 16729,
        "NOCCode": "8241",
        "JobTitle": "logging crane operator"
    },
    {
        "value": 16730,
        "NOCCode": "8241",
        "JobTitle": "logging machinery operator"
    },
    {
        "value": 16731,
        "NOCCode": "8241",
        "JobTitle": "logging truck loader"
    },
    {
        "value": 16732,
        "NOCCode": "8241",
        "JobTitle": "mechanical harvester and forwarder operator"
    },
    {
        "value": 16733,
        "NOCCode": "8241",
        "JobTitle": "mechanical harvester operator – logging"
    },
    {
        "value": 16734,
        "NOCCode": "8241",
        "JobTitle": "mechanical tree processor and loader operator"
    },
    {
        "value": 16735,
        "NOCCode": "8241",
        "JobTitle": "mechanical tree processor operator – logging"
    },
    {
        "value": 16736,
        "NOCCode": "8241",
        "JobTitle": "mobile chipper operator – logging"
    },
    {
        "value": 16737,
        "NOCCode": "8241",
        "JobTitle": "mobile whole tree chipper – logging"
    },
    {
        "value": 16738,
        "NOCCode": "8241",
        "JobTitle": "operator, cable yarding system"
    },
    {
        "value": 16739,
        "NOCCode": "8241",
        "JobTitle": "operator, chipping machine – logging"
    },
    {
        "value": 16740,
        "NOCCode": "8241",
        "JobTitle": "operator, crane and claw – logging"
    },
    {
        "value": 16741,
        "NOCCode": "8241",
        "JobTitle": "operator, delimber"
    },
    {
        "value": 16742,
        "NOCCode": "8241",
        "JobTitle": "operator, feller buncher"
    },
    {
        "value": 16743,
        "NOCCode": "8241",
        "JobTitle": "operator, forwarder – logging"
    },
    {
        "value": 16744,
        "NOCCode": "8241",
        "JobTitle": "operator, grapple loader – logging"
    },
    {
        "value": 16745,
        "NOCCode": "8241",
        "JobTitle": "operator, grapple yarder – logging"
    },
    {
        "value": 16746,
        "NOCCode": "8241",
        "JobTitle": "operator, linehorse"
    },
    {
        "value": 16747,
        "NOCCode": "8241",
        "JobTitle": "operator, log loader"
    },
    {
        "value": 16748,
        "NOCCode": "8241",
        "JobTitle": "operator, log loading machine"
    },
    {
        "value": 16749,
        "NOCCode": "8241",
        "JobTitle": "operator, logging crane"
    },
    {
        "value": 16750,
        "NOCCode": "8241",
        "JobTitle": "operator, logging machinery"
    },
    {
        "value": 16751,
        "NOCCode": "8241",
        "JobTitle": "operator, mechanical harvester – logging"
    },
    {
        "value": 16752,
        "NOCCode": "8241",
        "JobTitle": "operator, mechanical tree processor – logging"
    },
    {
        "value": 16753,
        "NOCCode": "8241",
        "JobTitle": "operator, mobile chipper – logging"
    },
    {
        "value": 16754,
        "NOCCode": "8241",
        "JobTitle": "operator, picker truck"
    },
    {
        "value": 16755,
        "NOCCode": "8241",
        "JobTitle": "operator, pulpwood harvester"
    },
    {
        "value": 16756,
        "NOCCode": "8241",
        "JobTitle": "operator, shortwood harvester"
    },
    {
        "value": 16757,
        "NOCCode": "8241",
        "JobTitle": "operator, slasher – logging"
    },
    {
        "value": 16758,
        "NOCCode": "8241",
        "JobTitle": "operator, slasher sawyer – logging"
    },
    {
        "value": 16759,
        "NOCCode": "8241",
        "JobTitle": "operator, tree processor – logging"
    },
    {
        "value": 16760,
        "NOCCode": "8241",
        "JobTitle": "picker truck operator"
    },
    {
        "value": 16761,
        "NOCCode": "8241",
        "JobTitle": "pulpwood harvester operator"
    },
    {
        "value": 16762,
        "NOCCode": "8241",
        "JobTitle": "rigging slinger – logging"
    },
    {
        "value": 16763,
        "NOCCode": "8241",
        "JobTitle": "shortwood harvester operator"
    },
    {
        "value": 16764,
        "NOCCode": "8241",
        "JobTitle": "slasher loader operator"
    },
    {
        "value": 16765,
        "NOCCode": "8241",
        "JobTitle": "slasher operator – logging"
    },
    {
        "value": 16766,
        "NOCCode": "8241",
        "JobTitle": "slasher saw operator – logging"
    },
    {
        "value": 16767,
        "NOCCode": "8241",
        "JobTitle": "slasher sawyer operator – logging"
    },
    {
        "value": 16768,
        "NOCCode": "8241",
        "JobTitle": "slasher-brusher"
    },
    {
        "value": 16769,
        "NOCCode": "8241",
        "JobTitle": "steel spar operator – logging"
    },
    {
        "value": 16770,
        "NOCCode": "8241",
        "JobTitle": "tree harvester operator"
    },
    {
        "value": 16771,
        "NOCCode": "8241",
        "JobTitle": "tree processor operator – logging"
    },
    {
        "value": 16772,
        "NOCCode": "8241",
        "JobTitle": "treelength forwarder operator"
    },
    {
        "value": 16773,
        "NOCCode": "8241",
        "JobTitle": "tree-length harvester operator"
    },
    {
        "value": 16774,
        "NOCCode": "8241",
        "JobTitle": "yarder operator – logging"
    },
    {
        "value": 16775,
        "NOCCode": "8241",
        "JobTitle": "yarding engineer – logging"
    },
    {
        "value": 16776,
        "NOCCode": "8252",
        "JobTitle": "aerial crop dusting service contractor"
    },
    {
        "value": 16777,
        "NOCCode": "8252",
        "JobTitle": "agriculture foreman/woman"
    },
    {
        "value": 16778,
        "NOCCode": "8252",
        "JobTitle": "animal farm foreman/woman"
    },
    {
        "value": 16779,
        "NOCCode": "8252",
        "JobTitle": "animal husbandry foreman/woman"
    },
    {
        "value": 16780,
        "NOCCode": "8252",
        "JobTitle": "apple orchard foreman/woman"
    },
    {
        "value": 16781,
        "NOCCode": "8252",
        "JobTitle": "artificial inseminator"
    },
    {
        "value": 16782,
        "NOCCode": "8252",
        "JobTitle": "beef cattle herdsperson"
    },
    {
        "value": 16783,
        "NOCCode": "8252",
        "JobTitle": "caponizing service contractor"
    },
    {
        "value": 16784,
        "NOCCode": "8252",
        "JobTitle": "cattle feedlot foreman/woman"
    },
    {
        "value": 16785,
        "NOCCode": "8252",
        "JobTitle": "cattle herdsperson"
    },
    {
        "value": 16786,
        "NOCCode": "8252",
        "JobTitle": "cattle ranch foreman/woman"
    },
    {
        "value": 16787,
        "NOCCode": "8252",
        "JobTitle": "contractor – agricultural service"
    },
    {
        "value": 16788,
        "NOCCode": "8252",
        "JobTitle": "contractor, artificial insemination service"
    },
    {
        "value": 16789,
        "NOCCode": "8252",
        "JobTitle": "contractor, crop harvesting service"
    },
    {
        "value": 16790,
        "NOCCode": "8252",
        "JobTitle": "contractor, farm produce packing service"
    },
    {
        "value": 16791,
        "NOCCode": "8252",
        "JobTitle": "contractor, fertilization service – agriculture"
    },
    {
        "value": 16792,
        "NOCCode": "8252",
        "JobTitle": "contractor, weed control service – agriculture"
    },
    {
        "value": 16793,
        "NOCCode": "8252",
        "JobTitle": "corral boss"
    },
    {
        "value": 16794,
        "NOCCode": "8252",
        "JobTitle": "crop dusting contractor"
    },
    {
        "value": 16795,
        "NOCCode": "8252",
        "JobTitle": "crop dusting service contractor"
    },
    {
        "value": 16796,
        "NOCCode": "8252",
        "JobTitle": "crop harvesting contractor"
    },
    {
        "value": 16797,
        "NOCCode": "8252",
        "JobTitle": "custom combine contractor"
    },
    {
        "value": 16798,
        "NOCCode": "8252",
        "JobTitle": "custom combine operator"
    },
    {
        "value": 16799,
        "NOCCode": "8252",
        "JobTitle": "custom harvester"
    },
    {
        "value": 16800,
        "NOCCode": "8252",
        "JobTitle": "custom operator, farm machinery"
    },
    {
        "value": 16801,
        "NOCCode": "8252",
        "JobTitle": "dairy farm foreman/woman"
    },
    {
        "value": 16802,
        "NOCCode": "8252",
        "JobTitle": "dairy farm workers foreman/woman"
    },
    {
        "value": 16803,
        "NOCCode": "8252",
        "JobTitle": "dairy herdsperson"
    },
    {
        "value": 16804,
        "NOCCode": "8252",
        "JobTitle": "dehorning service contractor"
    },
    {
        "value": 16805,
        "NOCCode": "8252",
        "JobTitle": "dressage and stunt horse trainer"
    },
    {
        "value": 16806,
        "NOCCode": "8252",
        "JobTitle": "egg grading station manager"
    },
    {
        "value": 16807,
        "NOCCode": "8252",
        "JobTitle": "farm boss"
    },
    {
        "value": 16808,
        "NOCCode": "8252",
        "JobTitle": "farm business foreman/woman"
    },
    {
        "value": 16809,
        "NOCCode": "8252",
        "JobTitle": "farm foreman/woman"
    },
    {
        "value": 16810,
        "NOCCode": "8252",
        "JobTitle": "farm irrigating system contractor"
    },
    {
        "value": 16811,
        "NOCCode": "8252",
        "JobTitle": "farm operation foreman/woman"
    },
    {
        "value": 16812,
        "NOCCode": "8252",
        "JobTitle": "farm operation supervisor"
    },
    {
        "value": 16813,
        "NOCCode": "8252",
        "JobTitle": "farm produce grading service contractor"
    },
    {
        "value": 16814,
        "NOCCode": "8252",
        "JobTitle": "farm produce packing service contractor"
    },
    {
        "value": 16815,
        "NOCCode": "8252",
        "JobTitle": "farm supervisor"
    },
    {
        "value": 16816,
        "NOCCode": "8252",
        "JobTitle": "farm workers supervisor"
    },
    {
        "value": 16817,
        "NOCCode": "8252",
        "JobTitle": "feedlot foreman/woman"
    },
    {
        "value": 16818,
        "NOCCode": "8252",
        "JobTitle": "feedlot herd attendant"
    },
    {
        "value": 16819,
        "NOCCode": "8252",
        "JobTitle": "fertilization service contractor – agriculture"
    },
    {
        "value": 16820,
        "NOCCode": "8252",
        "JobTitle": "field crop and vegetable workers foreman/woman"
    },
    {
        "value": 16821,
        "NOCCode": "8252",
        "JobTitle": "field crop foreman/woman"
    },
    {
        "value": 16822,
        "NOCCode": "8252",
        "JobTitle": "field-grown crop foreman/woman"
    },
    {
        "value": 16823,
        "NOCCode": "8252",
        "JobTitle": "field-grown crop workers foreman/woman"
    },
    {
        "value": 16824,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, apple orchard"
    },
    {
        "value": 16825,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, dairy farm workers"
    },
    {
        "value": 16826,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, farm"
    },
    {
        "value": 16827,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, feedlot"
    },
    {
        "value": 16828,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, field crop and vegetable workers"
    },
    {
        "value": 16829,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, fruit farm"
    },
    {
        "value": 16830,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, fruit farm workers"
    },
    {
        "value": 16831,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, hatchery workers"
    },
    {
        "value": 16832,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, livestock farm workers"
    },
    {
        "value": 16833,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, orchard"
    },
    {
        "value": 16834,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, poultry farm workers"
    },
    {
        "value": 16835,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, ranch"
    },
    {
        "value": 16836,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, vegetable farm workers"
    },
    {
        "value": 16837,
        "NOCCode": "8252",
        "JobTitle": "foreman/woman, vineyard"
    },
    {
        "value": 16838,
        "NOCCode": "8252",
        "JobTitle": "fruit farm foreman/woman"
    },
    {
        "value": 16839,
        "NOCCode": "8252",
        "JobTitle": "fruit farm workers foreman/woman"
    },
    {
        "value": 16840,
        "NOCCode": "8252",
        "JobTitle": "grain crop foreman/woman"
    },
    {
        "value": 16841,
        "NOCCode": "8252",
        "JobTitle": "grain custom harvester"
    },
    {
        "value": 16842,
        "NOCCode": "8252",
        "JobTitle": "hatchery foreman/woman"
    },
    {
        "value": 16843,
        "NOCCode": "8252",
        "JobTitle": "hatchery workers foreman/woman"
    },
    {
        "value": 16844,
        "NOCCode": "8252",
        "JobTitle": "herd attendant"
    },
    {
        "value": 16845,
        "NOCCode": "8252",
        "JobTitle": "herd attendant, feedlot"
    },
    {
        "value": 16846,
        "NOCCode": "8252",
        "JobTitle": "herd superintendent"
    },
    {
        "value": 16847,
        "NOCCode": "8252",
        "JobTitle": "herdsman/woman"
    },
    {
        "value": 16848,
        "NOCCode": "8252",
        "JobTitle": "herdsperson"
    },
    {
        "value": 16849,
        "NOCCode": "8252",
        "JobTitle": "hog farm foreman/woman"
    },
    {
        "value": 16850,
        "NOCCode": "8252",
        "JobTitle": "hog farm supervisor"
    },
    {
        "value": 16851,
        "NOCCode": "8252",
        "JobTitle": "hog farm workers supervisor"
    },
    {
        "value": 16852,
        "NOCCode": "8252",
        "JobTitle": "hog operation supervisor"
    },
    {
        "value": 16853,
        "NOCCode": "8252",
        "JobTitle": "hop farm foreman/woman"
    },
    {
        "value": 16854,
        "NOCCode": "8252",
        "JobTitle": "horse stable foreman/woman"
    },
    {
        "value": 16855,
        "NOCCode": "8252",
        "JobTitle": "horse trainer"
    },
    {
        "value": 16856,
        "NOCCode": "8252",
        "JobTitle": "irrigation supervisor – agriculture"
    },
    {
        "value": 16857,
        "NOCCode": "8252",
        "JobTitle": "livery stable foreman/woman"
    },
    {
        "value": 16858,
        "NOCCode": "8252",
        "JobTitle": "livestock attendant"
    },
    {
        "value": 16859,
        "NOCCode": "8252",
        "JobTitle": "livestock breeding service contractor"
    },
    {
        "value": 16860,
        "NOCCode": "8252",
        "JobTitle": "livestock exchange contractor"
    },
    {
        "value": 16861,
        "NOCCode": "8252",
        "JobTitle": "livestock farm workers foreman/woman"
    },
    {
        "value": 16862,
        "NOCCode": "8252",
        "JobTitle": "livestock foreman/woman"
    },
    {
        "value": 16863,
        "NOCCode": "8252",
        "JobTitle": "livestock raising foreman/woman"
    },
    {
        "value": 16864,
        "NOCCode": "8252",
        "JobTitle": "maple products foreman/woman – agriculture"
    },
    {
        "value": 16865,
        "NOCCode": "8252",
        "JobTitle": "milking services contractor"
    },
    {
        "value": 16866,
        "NOCCode": "8252",
        "JobTitle": "mink farm supervisor"
    },
    {
        "value": 16867,
        "NOCCode": "8252",
        "JobTitle": "mink ranch supervisor"
    },
    {
        "value": 16868,
        "NOCCode": "8252",
        "JobTitle": "orchard foreman/woman"
    },
    {
        "value": 16869,
        "NOCCode": "8252",
        "JobTitle": "pasture-managing supervisor"
    },
    {
        "value": 16870,
        "NOCCode": "8252",
        "JobTitle": "planting and cultivation contractor"
    },
    {
        "value": 16871,
        "NOCCode": "8252",
        "JobTitle": "pork production technician"
    },
    {
        "value": 16872,
        "NOCCode": "8252",
        "JobTitle": "pork production technician apprentice"
    },
    {
        "value": 16873,
        "NOCCode": "8252",
        "JobTitle": "poultry farm foreman/woman"
    },
    {
        "value": 16874,
        "NOCCode": "8252",
        "JobTitle": "poultry farm workers foreman/woman"
    },
    {
        "value": 16875,
        "NOCCode": "8252",
        "JobTitle": "poultry operation supervisor"
    },
    {
        "value": 16876,
        "NOCCode": "8252",
        "JobTitle": "poultry-hatching services contractor"
    },
    {
        "value": 16877,
        "NOCCode": "8252",
        "JobTitle": "racehorse trainer"
    },
    {
        "value": 16878,
        "NOCCode": "8252",
        "JobTitle": "ranch foreman/woman"
    },
    {
        "value": 16879,
        "NOCCode": "8252",
        "JobTitle": "seed cleaning service contractor"
    },
    {
        "value": 16880,
        "NOCCode": "8252",
        "JobTitle": "seeding contractor"
    },
    {
        "value": 16881,
        "NOCCode": "8252",
        "JobTitle": "sheep handler"
    },
    {
        "value": 16882,
        "NOCCode": "8252",
        "JobTitle": "sheep herdsperson"
    },
    {
        "value": 16883,
        "NOCCode": "8252",
        "JobTitle": "sheep shearing contractor"
    },
    {
        "value": 16884,
        "NOCCode": "8252",
        "JobTitle": "shepherd"
    },
    {
        "value": 16885,
        "NOCCode": "8252",
        "JobTitle": "soil preparation contractor"
    },
    {
        "value": 16886,
        "NOCCode": "8252",
        "JobTitle": "soil-testing service contractor"
    },
    {
        "value": 16887,
        "NOCCode": "8252",
        "JobTitle": "stable boss"
    },
    {
        "value": 16888,
        "NOCCode": "8252",
        "JobTitle": "station manager, egg grading"
    },
    {
        "value": 16889,
        "NOCCode": "8252",
        "JobTitle": "stockyard and herdsmen foreman/woman"
    },
    {
        "value": 16890,
        "NOCCode": "8252",
        "JobTitle": "sugarbush operation foreman/woman"
    },
    {
        "value": 16891,
        "NOCCode": "8252",
        "JobTitle": "supervisor, hog farm workers"
    },
    {
        "value": 16892,
        "NOCCode": "8252",
        "JobTitle": "swine herdsperson"
    },
    {
        "value": 16893,
        "NOCCode": "8252",
        "JobTitle": "tobacco farm foreman/woman"
    },
    {
        "value": 16894,
        "NOCCode": "8252",
        "JobTitle": "tobacco farming workers foreman/woman"
    },
    {
        "value": 16895,
        "NOCCode": "8252",
        "JobTitle": "trainer, horse"
    },
    {
        "value": 16896,
        "NOCCode": "8252",
        "JobTitle": "trainer, racehorses"
    },
    {
        "value": 16897,
        "NOCCode": "8252",
        "JobTitle": "turkey farm foreman/woman"
    },
    {
        "value": 16898,
        "NOCCode": "8252",
        "JobTitle": "turkey farming workers foreman/woman"
    },
    {
        "value": 16899,
        "NOCCode": "8252",
        "JobTitle": "vegetable farm foreman/woman"
    },
    {
        "value": 16900,
        "NOCCode": "8252",
        "JobTitle": "vegetable farm workers foreman/woman"
    },
    {
        "value": 16901,
        "NOCCode": "8252",
        "JobTitle": "vineyard foreman/woman"
    },
    {
        "value": 16902,
        "NOCCode": "8252",
        "JobTitle": "vineyard workers foreman/woman"
    },
    {
        "value": 16903,
        "NOCCode": "8252",
        "JobTitle": "weed control service contractor – agriculture"
    },
    {
        "value": 16904,
        "NOCCode": "8252",
        "JobTitle": "wool shearing contractor"
    },
    {
        "value": 16905,
        "NOCCode": "8255",
        "JobTitle": "caretaker, park"
    },
    {
        "value": 16906,
        "NOCCode": "8255",
        "JobTitle": "cemetery foreman/woman"
    },
    {
        "value": 16907,
        "NOCCode": "8255",
        "JobTitle": "contractor, lawn care service"
    },
    {
        "value": 16908,
        "NOCCode": "8255",
        "JobTitle": "country club grounds supervisor"
    },
    {
        "value": 16909,
        "NOCCode": "8255",
        "JobTitle": "crew chief, greenhouse workers"
    },
    {
        "value": 16910,
        "NOCCode": "8255",
        "JobTitle": "crew chief, nursery workers"
    },
    {
        "value": 16911,
        "NOCCode": "8255",
        "JobTitle": "foreman/woman, cemetery"
    },
    {
        "value": 16912,
        "NOCCode": "8255",
        "JobTitle": "foreman/woman, groundskeeping"
    },
    {
        "value": 16913,
        "NOCCode": "8255",
        "JobTitle": "foreman/woman, landscaping"
    },
    {
        "value": 16914,
        "NOCCode": "8255",
        "JobTitle": "foreman/woman, nursery"
    },
    {
        "value": 16915,
        "NOCCode": "8255",
        "JobTitle": "foreman/woman, weed control"
    },
    {
        "value": 16916,
        "NOCCode": "8255",
        "JobTitle": "greenhouse foreman/woman"
    },
    {
        "value": 16917,
        "NOCCode": "8255",
        "JobTitle": "greenhouse supervisor"
    },
    {
        "value": 16918,
        "NOCCode": "8255",
        "JobTitle": "greenhouse worker crew chief"
    },
    {
        "value": 16919,
        "NOCCode": "8255",
        "JobTitle": "grounds maintenance contractor"
    },
    {
        "value": 16920,
        "NOCCode": "8255",
        "JobTitle": "grounds maintenance supervisor"
    },
    {
        "value": 16921,
        "NOCCode": "8255",
        "JobTitle": "groundskeeper supervisor"
    },
    {
        "value": 16922,
        "NOCCode": "8255",
        "JobTitle": "groundskeeping foreman/woman"
    },
    {
        "value": 16923,
        "NOCCode": "8255",
        "JobTitle": "groundskeeping supervisor"
    },
    {
        "value": 16924,
        "NOCCode": "8255",
        "JobTitle": "groundskeeping supervisor – cemetery"
    },
    {
        "value": 16925,
        "NOCCode": "8255",
        "JobTitle": "head, park maintenance"
    },
    {
        "value": 16926,
        "NOCCode": "8255",
        "JobTitle": "horticultural contractor"
    },
    {
        "value": 16927,
        "NOCCode": "8255",
        "JobTitle": "horticulture workers supervisor"
    },
    {
        "value": 16928,
        "NOCCode": "8255",
        "JobTitle": "interior plantscaping contractor"
    },
    {
        "value": 16929,
        "NOCCode": "8255",
        "JobTitle": "landscape design contractor"
    },
    {
        "value": 16930,
        "NOCCode": "8255",
        "JobTitle": "landscape maintenance contractor"
    },
    {
        "value": 16931,
        "NOCCode": "8255",
        "JobTitle": "landscape service contractor"
    },
    {
        "value": 16932,
        "NOCCode": "8255",
        "JobTitle": "landscaping contractor"
    },
    {
        "value": 16933,
        "NOCCode": "8255",
        "JobTitle": "landscaping foreman/woman"
    },
    {
        "value": 16934,
        "NOCCode": "8255",
        "JobTitle": "landscaping supervisor"
    },
    {
        "value": 16935,
        "NOCCode": "8255",
        "JobTitle": "lawn care contractor"
    },
    {
        "value": 16936,
        "NOCCode": "8255",
        "JobTitle": "lawn care service contractor"
    },
    {
        "value": 16937,
        "NOCCode": "8255",
        "JobTitle": "lawn care supervisor"
    },
    {
        "value": 16938,
        "NOCCode": "8255",
        "JobTitle": "lawn maintenance contractor"
    },
    {
        "value": 16939,
        "NOCCode": "8255",
        "JobTitle": "nursery foreman/woman"
    },
    {
        "value": 16940,
        "NOCCode": "8255",
        "JobTitle": "nursery supervisor"
    },
    {
        "value": 16941,
        "NOCCode": "8255",
        "JobTitle": "nursery worker crew chief"
    },
    {
        "value": 16942,
        "NOCCode": "8255",
        "JobTitle": "nursery worker foreman/woman"
    },
    {
        "value": 16943,
        "NOCCode": "8255",
        "JobTitle": "nursery workers supervisor"
    },
    {
        "value": 16944,
        "NOCCode": "8255",
        "JobTitle": "park caretaker"
    },
    {
        "value": 16945,
        "NOCCode": "8255",
        "JobTitle": "park labourers supervisor"
    },
    {
        "value": 16946,
        "NOCCode": "8255",
        "JobTitle": "park maintenance head"
    },
    {
        "value": 16947,
        "NOCCode": "8255",
        "JobTitle": "park maintenance supervisor"
    },
    {
        "value": 16948,
        "NOCCode": "8255",
        "JobTitle": "park section head – arboriculture"
    },
    {
        "value": 16949,
        "NOCCode": "8255",
        "JobTitle": "park supervisor, grounds maintenance"
    },
    {
        "value": 16950,
        "NOCCode": "8255",
        "JobTitle": "plantscape maintenance contractor"
    },
    {
        "value": 16951,
        "NOCCode": "8255",
        "JobTitle": "supervisor, groundsman/woman"
    },
    {
        "value": 16952,
        "NOCCode": "8255",
        "JobTitle": "supervisor, groundsperson – campground"
    },
    {
        "value": 16953,
        "NOCCode": "8255",
        "JobTitle": "supervisor, horticulture workers"
    },
    {
        "value": 16954,
        "NOCCode": "8255",
        "JobTitle": "supervisor, landscaping"
    },
    {
        "value": 16955,
        "NOCCode": "8255",
        "JobTitle": "supervisor, maintenance worker – campground"
    },
    {
        "value": 16956,
        "NOCCode": "8255",
        "JobTitle": "supervisor, nursery workers"
    },
    {
        "value": 16957,
        "NOCCode": "8255",
        "JobTitle": "supervisor, park labourers"
    },
    {
        "value": 16958,
        "NOCCode": "8255",
        "JobTitle": "supervisor, public works grounds maintenance labourers"
    },
    {
        "value": 16959,
        "NOCCode": "8255",
        "JobTitle": "supervisor, public works grounds maintenance workers"
    },
    {
        "value": 16960,
        "NOCCode": "8255",
        "JobTitle": "supervisor, tree service"
    },
    {
        "value": 16961,
        "NOCCode": "8255",
        "JobTitle": "tree removal contractor"
    },
    {
        "value": 16962,
        "NOCCode": "8255",
        "JobTitle": "tree service contractor"
    },
    {
        "value": 16963,
        "NOCCode": "8255",
        "JobTitle": "tree service supervisor"
    },
    {
        "value": 16964,
        "NOCCode": "8255",
        "JobTitle": "weed control foreman/woman"
    },
    {
        "value": 16965,
        "NOCCode": "8261",
        "JobTitle": "boatswain, fishing vessel"
    },
    {
        "value": 16966,
        "NOCCode": "8261",
        "JobTitle": "bo'sun, fishing vessel"
    },
    {
        "value": 16967,
        "NOCCode": "8261",
        "JobTitle": "captain, factory freezer trawler"
    },
    {
        "value": 16968,
        "NOCCode": "8261",
        "JobTitle": "captain, fishing vessel"
    },
    {
        "value": 16969,
        "NOCCode": "8261",
        "JobTitle": "captain, offshore fishing vessel"
    },
    {
        "value": 16970,
        "NOCCode": "8261",
        "JobTitle": "deck officer, fishing vessel"
    },
    {
        "value": 16971,
        "NOCCode": "8261",
        "JobTitle": "factory freezer trawler captain"
    },
    {
        "value": 16972,
        "NOCCode": "8261",
        "JobTitle": "first mate, fishing vessel"
    },
    {
        "value": 16973,
        "NOCCode": "8261",
        "JobTitle": "fishing master"
    },
    {
        "value": 16974,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel boatswain"
    },
    {
        "value": 16975,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel bo'sun"
    },
    {
        "value": 16976,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel captain"
    },
    {
        "value": 16977,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel master"
    },
    {
        "value": 16978,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel mate"
    },
    {
        "value": 16979,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel navigation officer"
    },
    {
        "value": 16980,
        "NOCCode": "8261",
        "JobTitle": "fishing vessel second mate"
    },
    {
        "value": 16981,
        "NOCCode": "8261",
        "JobTitle": "master, fishing vessel"
    },
    {
        "value": 16982,
        "NOCCode": "8261",
        "JobTitle": "mate, fishing vessel"
    },
    {
        "value": 16983,
        "NOCCode": "8261",
        "JobTitle": "navigation officer, fishing vessel"
    },
    {
        "value": 16984,
        "NOCCode": "8261",
        "JobTitle": "second mate, fishing vessel"
    },
    {
        "value": 16985,
        "NOCCode": "8261",
        "JobTitle": "trawler captain"
    },
    {
        "value": 16986,
        "NOCCode": "8262",
        "JobTitle": "beam trawl fisherman/woman"
    },
    {
        "value": 16987,
        "NOCCode": "8262",
        "JobTitle": "clam dredge operator"
    },
    {
        "value": 16988,
        "NOCCode": "8262",
        "JobTitle": "clam fisherman/woman"
    },
    {
        "value": 16989,
        "NOCCode": "8262",
        "JobTitle": "crab fisherman/woman"
    },
    {
        "value": 16990,
        "NOCCode": "8262",
        "JobTitle": "Danish seine fisherman/woman"
    },
    {
        "value": 16991,
        "NOCCode": "8262",
        "JobTitle": "fisherman/woman"
    },
    {
        "value": 16992,
        "NOCCode": "8262",
        "JobTitle": "fishing vessel skipper"
    },
    {
        "value": 16993,
        "NOCCode": "8262",
        "JobTitle": "gill net fisherman/woman"
    },
    {
        "value": 16994,
        "NOCCode": "8262",
        "JobTitle": "gillnetter skipper"
    },
    {
        "value": 16995,
        "NOCCode": "8262",
        "JobTitle": "handliner fisherman/woman"
    },
    {
        "value": 16996,
        "NOCCode": "8262",
        "JobTitle": "harpoon fisherman/woman"
    },
    {
        "value": 16997,
        "NOCCode": "8262",
        "JobTitle": "hoop net fisherman/woman"
    },
    {
        "value": 16998,
        "NOCCode": "8262",
        "JobTitle": "inshore fisherman/woman"
    },
    {
        "value": 16999,
        "NOCCode": "8262",
        "JobTitle": "line fisherman/woman"
    },
    {
        "value": 17000,
        "NOCCode": "8262",
        "JobTitle": "lobster fisherman/woman"
    },
    {
        "value": 17001,
        "NOCCode": "8262",
        "JobTitle": "longline fisherman/woman"
    },
    {
        "value": 17002,
        "NOCCode": "8262",
        "JobTitle": "longliner fisherman/woman"
    },
    {
        "value": 17003,
        "NOCCode": "8262",
        "JobTitle": "otter trawl fisherman/woman"
    },
    {
        "value": 17004,
        "NOCCode": "8262",
        "JobTitle": "pot fisherman/woman"
    },
    {
        "value": 17005,
        "NOCCode": "8262",
        "JobTitle": "pound net fisherman/woman"
    },
    {
        "value": 17006,
        "NOCCode": "8262",
        "JobTitle": "prawn fisherman/woman"
    },
    {
        "value": 17007,
        "NOCCode": "8262",
        "JobTitle": "purse seine fisherman/woman"
    },
    {
        "value": 17008,
        "NOCCode": "8262",
        "JobTitle": "scallop dredger"
    },
    {
        "value": 17009,
        "NOCCode": "8262",
        "JobTitle": "seaweed harvesting machine operator"
    },
    {
        "value": 17010,
        "NOCCode": "8262",
        "JobTitle": "seiner fisherman/woman"
    },
    {
        "value": 17011,
        "NOCCode": "8262",
        "JobTitle": "shellfish fisherman/woman"
    },
    {
        "value": 17012,
        "NOCCode": "8262",
        "JobTitle": "shore fisherman/woman"
    },
    {
        "value": 17013,
        "NOCCode": "8262",
        "JobTitle": "skipper, fishing vessel"
    },
    {
        "value": 17014,
        "NOCCode": "8262",
        "JobTitle": "skipper, gillnetter"
    },
    {
        "value": 17015,
        "NOCCode": "8262",
        "JobTitle": "swordfish fisherman/woman"
    },
    {
        "value": 17016,
        "NOCCode": "8262",
        "JobTitle": "trap boss – fishing"
    },
    {
        "value": 17017,
        "NOCCode": "8262",
        "JobTitle": "trap fisherman/woman"
    },
    {
        "value": 17018,
        "NOCCode": "8262",
        "JobTitle": "troller fisherman/woman"
    },
    {
        "value": 17019,
        "NOCCode": "8262",
        "JobTitle": "weir fisherman/woman"
    },
    {
        "value": 17020,
        "NOCCode": "8411",
        "JobTitle": "assembler, extensible conveyor belt – underground mining"
    },
    {
        "value": 17021,
        "NOCCode": "8411",
        "JobTitle": "backfill plant operator – underground mining"
    },
    {
        "value": 17022,
        "NOCCode": "8411",
        "JobTitle": "backfiller – underground mining"
    },
    {
        "value": 17023,
        "NOCCode": "8411",
        "JobTitle": "blaster helper – underground mining"
    },
    {
        "value": 17024,
        "NOCCode": "8411",
        "JobTitle": "blaster, line crew – underground mining"
    },
    {
        "value": 17025,
        "NOCCode": "8411",
        "JobTitle": "bottom cager – underground mining"
    },
    {
        "value": 17026,
        "NOCCode": "8411",
        "JobTitle": "brattice builder – underground mining"
    },
    {
        "value": 17027,
        "NOCCode": "8411",
        "JobTitle": "bridge operator – underground mining"
    },
    {
        "value": 17028,
        "NOCCode": "8411",
        "JobTitle": "brusher – underground mining"
    },
    {
        "value": 17029,
        "NOCCode": "8411",
        "JobTitle": "builder, brattice – underground mining"
    },
    {
        "value": 17030,
        "NOCCode": "8411",
        "JobTitle": "cage tender"
    },
    {
        "value": 17031,
        "NOCCode": "8411",
        "JobTitle": "cage tender – underground mining"
    },
    {
        "value": 17032,
        "NOCCode": "8411",
        "JobTitle": "chute loader – underground mining"
    },
    {
        "value": 17033,
        "NOCCode": "8411",
        "JobTitle": "chute operator – underground mining"
    },
    {
        "value": 17034,
        "NOCCode": "8411",
        "JobTitle": "coal hauler – underground mining"
    },
    {
        "value": 17035,
        "NOCCode": "8411",
        "JobTitle": "construction worker – mines"
    },
    {
        "value": 17036,
        "NOCCode": "8411",
        "JobTitle": "conveyor operator – underground mining"
    },
    {
        "value": 17037,
        "NOCCode": "8411",
        "JobTitle": "conveyorman/woman – underground mining"
    },
    {
        "value": 17038,
        "NOCCode": "8411",
        "JobTitle": "crusher operator – underground mining"
    },
    {
        "value": 17039,
        "NOCCode": "8411",
        "JobTitle": "crusherman/woman – underground mining"
    },
    {
        "value": 17040,
        "NOCCode": "8411",
        "JobTitle": "deckman/woman – underground mining"
    },
    {
        "value": 17041,
        "NOCCode": "8411",
        "JobTitle": "diamond driller helper – underground mining"
    },
    {
        "value": 17042,
        "NOCCode": "8411",
        "JobTitle": "drill carriage operator helper – underground mining"
    },
    {
        "value": 17043,
        "NOCCode": "8411",
        "JobTitle": "driller helper – underground mining"
    },
    {
        "value": 17044,
        "NOCCode": "8411",
        "JobTitle": "dryman/woman – underground mining"
    },
    {
        "value": 17045,
        "NOCCode": "8411",
        "JobTitle": "erector, steelwork – underground mining"
    },
    {
        "value": 17046,
        "NOCCode": "8411",
        "JobTitle": "extensible conveyor belt assembler – underground mining"
    },
    {
        "value": 17047,
        "NOCCode": "8411",
        "JobTitle": "footman/woman – underground mining"
    },
    {
        "value": 17048,
        "NOCCode": "8411",
        "JobTitle": "haulage truck driver – underground mining"
    },
    {
        "value": 17049,
        "NOCCode": "8411",
        "JobTitle": "haulageman/woman – underground mining"
    },
    {
        "value": 17050,
        "NOCCode": "8411",
        "JobTitle": "hauler, coal – underground mining"
    },
    {
        "value": 17051,
        "NOCCode": "8411",
        "JobTitle": "head operator, loading – underground mining"
    },
    {
        "value": 17052,
        "NOCCode": "8411",
        "JobTitle": "headman/woman – underground mining"
    },
    {
        "value": 17053,
        "NOCCode": "8411",
        "JobTitle": "helper, blaster – underground mining"
    },
    {
        "value": 17054,
        "NOCCode": "8411",
        "JobTitle": "helper, borer – underground raise mining"
    },
    {
        "value": 17055,
        "NOCCode": "8411",
        "JobTitle": "helper, diamond driller – underground mining"
    },
    {
        "value": 17056,
        "NOCCode": "8411",
        "JobTitle": "helper, drill carriage operator – underground mining"
    },
    {
        "value": 17057,
        "NOCCode": "8411",
        "JobTitle": "helper, driller – underground mining"
    },
    {
        "value": 17058,
        "NOCCode": "8411",
        "JobTitle": "helper, jumbo drill operator – underground mining"
    },
    {
        "value": 17059,
        "NOCCode": "8411",
        "JobTitle": "helper, jumbo operator – underground mining"
    },
    {
        "value": 17060,
        "NOCCode": "8411",
        "JobTitle": "helper, raise miner"
    },
    {
        "value": 17061,
        "NOCCode": "8411",
        "JobTitle": "helper, rotary driller – raise mining"
    },
    {
        "value": 17062,
        "NOCCode": "8411",
        "JobTitle": "in-hole driller helper – underground mining"
    },
    {
        "value": 17063,
        "NOCCode": "8411",
        "JobTitle": "jack operator – underground mining"
    },
    {
        "value": 17064,
        "NOCCode": "8411",
        "JobTitle": "jumbo drill operator helper – underground mining"
    },
    {
        "value": 17065,
        "NOCCode": "8411",
        "JobTitle": "jumbo operator helper – underground mining"
    },
    {
        "value": 17066,
        "NOCCode": "8411",
        "JobTitle": "lamp keeper – underground mining"
    },
    {
        "value": 17067,
        "NOCCode": "8411",
        "JobTitle": "line crew blaster – underground mining"
    },
    {
        "value": 17068,
        "NOCCode": "8411",
        "JobTitle": "loader, chute – underground mining"
    },
    {
        "value": 17069,
        "NOCCode": "8411",
        "JobTitle": "loader, skip – underground mining"
    },
    {
        "value": 17070,
        "NOCCode": "8411",
        "JobTitle": "loading head operator – underground mining"
    },
    {
        "value": 17071,
        "NOCCode": "8411",
        "JobTitle": "loading pocket operator – underground mining"
    },
    {
        "value": 17072,
        "NOCCode": "8411",
        "JobTitle": "man/woman, ventilation – underground mining"
    },
    {
        "value": 17073,
        "NOCCode": "8411",
        "JobTitle": "materialman/woman – underground mining"
    },
    {
        "value": 17074,
        "NOCCode": "8411",
        "JobTitle": "mine attendant"
    },
    {
        "value": 17075,
        "NOCCode": "8411",
        "JobTitle": "mine construction worker"
    },
    {
        "value": 17076,
        "NOCCode": "8411",
        "JobTitle": "mine motorman/woman"
    },
    {
        "value": 17077,
        "NOCCode": "8411",
        "JobTitle": "mine timberman/woman"
    },
    {
        "value": 17078,
        "NOCCode": "8411",
        "JobTitle": "miner helper – raise mining"
    },
    {
        "value": 17079,
        "NOCCode": "8411",
        "JobTitle": "motorman/woman – mines"
    },
    {
        "value": 17080,
        "NOCCode": "8411",
        "JobTitle": "motorman/woman – underground mining"
    },
    {
        "value": 17081,
        "NOCCode": "8411",
        "JobTitle": "nipper – underground mining"
    },
    {
        "value": 17082,
        "NOCCode": "8411",
        "JobTitle": "operator, chute gate – underground mining"
    },
    {
        "value": 17083,
        "NOCCode": "8411",
        "JobTitle": "operator, conveyor – underground mining"
    },
    {
        "value": 17084,
        "NOCCode": "8411",
        "JobTitle": "operator, crusher – underground mining"
    },
    {
        "value": 17085,
        "NOCCode": "8411",
        "JobTitle": "operator, loading pocket – underground mining"
    },
    {
        "value": 17086,
        "NOCCode": "8411",
        "JobTitle": "operator, shuttle car"
    },
    {
        "value": 17087,
        "NOCCode": "8411",
        "JobTitle": "operator, transfer hoist"
    },
    {
        "value": 17088,
        "NOCCode": "8411",
        "JobTitle": "ore handling operator – underground mining"
    },
    {
        "value": 17089,
        "NOCCode": "8411",
        "JobTitle": "orepass tender"
    },
    {
        "value": 17090,
        "NOCCode": "8411",
        "JobTitle": "orepass tender – underground mining"
    },
    {
        "value": 17091,
        "NOCCode": "8411",
        "JobTitle": "pipe turner – trackman/woman"
    },
    {
        "value": 17092,
        "NOCCode": "8411",
        "JobTitle": "pipeworker – underground mining"
    },
    {
        "value": 17093,
        "NOCCode": "8411",
        "JobTitle": "raise borer helper – underground mining"
    },
    {
        "value": 17094,
        "NOCCode": "8411",
        "JobTitle": "raise miner helper"
    },
    {
        "value": 17095,
        "NOCCode": "8411",
        "JobTitle": "roadmaker – underground mining"
    },
    {
        "value": 17096,
        "NOCCode": "8411",
        "JobTitle": "rotary raise driller helper"
    },
    {
        "value": 17097,
        "NOCCode": "8411",
        "JobTitle": "sand fill man/woman"
    },
    {
        "value": 17098,
        "NOCCode": "8411",
        "JobTitle": "sand fill man/woman – hydraulic stowing"
    },
    {
        "value": 17099,
        "NOCCode": "8411",
        "JobTitle": "service crew worker – underground mining"
    },
    {
        "value": 17100,
        "NOCCode": "8411",
        "JobTitle": "setter, steel forms – underground mining"
    },
    {
        "value": 17101,
        "NOCCode": "8411",
        "JobTitle": "shaft conveyance operator"
    },
    {
        "value": 17102,
        "NOCCode": "8411",
        "JobTitle": "shuttle car operator"
    },
    {
        "value": 17103,
        "NOCCode": "8411",
        "JobTitle": "skip loader – underground mining"
    },
    {
        "value": 17104,
        "NOCCode": "8411",
        "JobTitle": "skip tender"
    },
    {
        "value": 17105,
        "NOCCode": "8411",
        "JobTitle": "skip tender – underground mining"
    },
    {
        "value": 17106,
        "NOCCode": "8411",
        "JobTitle": "sludge pump operator – underground mining"
    },
    {
        "value": 17107,
        "NOCCode": "8411",
        "JobTitle": "steel form setter – underground mining"
    },
    {
        "value": 17108,
        "NOCCode": "8411",
        "JobTitle": "steelwork erector – underground mining"
    },
    {
        "value": 17109,
        "NOCCode": "8411",
        "JobTitle": "support miner"
    },
    {
        "value": 17110,
        "NOCCode": "8411",
        "JobTitle": "tender, cage – underground mining"
    },
    {
        "value": 17111,
        "NOCCode": "8411",
        "JobTitle": "tender, orepass – underground mining"
    },
    {
        "value": 17112,
        "NOCCode": "8411",
        "JobTitle": "tender, skip"
    },
    {
        "value": 17113,
        "NOCCode": "8411",
        "JobTitle": "tender, skip – underground mining"
    },
    {
        "value": 17114,
        "NOCCode": "8411",
        "JobTitle": "timberman/woman – mines"
    },
    {
        "value": 17115,
        "NOCCode": "8411",
        "JobTitle": "timberman/woman – underground mining"
    },
    {
        "value": 17116,
        "NOCCode": "8411",
        "JobTitle": "top cager – underground mining"
    },
    {
        "value": 17117,
        "NOCCode": "8411",
        "JobTitle": "track equipment operator – underground mining"
    },
    {
        "value": 17118,
        "NOCCode": "8411",
        "JobTitle": "trackman/woman – underground mining"
    },
    {
        "value": 17119,
        "NOCCode": "8411",
        "JobTitle": "trackperson – underground mining"
    },
    {
        "value": 17120,
        "NOCCode": "8411",
        "JobTitle": "trainman/woman – underground mining"
    },
    {
        "value": 17121,
        "NOCCode": "8411",
        "JobTitle": "trammerman/woman"
    },
    {
        "value": 17122,
        "NOCCode": "8411",
        "JobTitle": "transfer hoist operator"
    },
    {
        "value": 17123,
        "NOCCode": "8411",
        "JobTitle": "ventilation man/woman – underground mining"
    },
    {
        "value": 17124,
        "NOCCode": "8412",
        "JobTitle": "acid treater – oil field services"
    },
    {
        "value": 17125,
        "NOCCode": "8412",
        "JobTitle": "acid truck driver – oil field services"
    },
    {
        "value": 17126,
        "NOCCode": "8412",
        "JobTitle": "acidizer, oil field services"
    },
    {
        "value": 17127,
        "NOCCode": "8412",
        "JobTitle": "ballast control operator – offshore drilling rig"
    },
    {
        "value": 17128,
        "NOCCode": "8412",
        "JobTitle": "blender operator – oil field services"
    },
    {
        "value": 17129,
        "NOCCode": "8412",
        "JobTitle": "blending machine operator – oil field services"
    },
    {
        "value": 17130,
        "NOCCode": "8412",
        "JobTitle": "carbon dioxide truck operator – oil field services"
    },
    {
        "value": 17131,
        "NOCCode": "8412",
        "JobTitle": "cement truck driver – oil field services"
    },
    {
        "value": 17132,
        "NOCCode": "8412",
        "JobTitle": "cementer – oil field services"
    },
    {
        "value": 17133,
        "NOCCode": "8412",
        "JobTitle": "cementer, oil field services"
    },
    {
        "value": 17134,
        "NOCCode": "8412",
        "JobTitle": "cementing equipment operator – oil field services"
    },
    {
        "value": 17135,
        "NOCCode": "8412",
        "JobTitle": "chemical services operator – oil field services"
    },
    {
        "value": 17136,
        "NOCCode": "8412",
        "JobTitle": "chemical truck operator – oil field services"
    },
    {
        "value": 17137,
        "NOCCode": "8412",
        "JobTitle": "coiled tubing operator – oil field services"
    },
    {
        "value": 17138,
        "NOCCode": "8412",
        "JobTitle": "control room operator – offshore drilling"
    },
    {
        "value": 17139,
        "NOCCode": "8412",
        "JobTitle": "derrick worker – oil and gas drilling"
    },
    {
        "value": 17140,
        "NOCCode": "8412",
        "JobTitle": "derrickhand – offshore drilling"
    },
    {
        "value": 17141,
        "NOCCode": "8412",
        "JobTitle": "derrickhand – oil and gas drilling"
    },
    {
        "value": 17142,
        "NOCCode": "8412",
        "JobTitle": "derrickman/woman"
    },
    {
        "value": 17143,
        "NOCCode": "8412",
        "JobTitle": "derrickman/woman – oil and gas drilling"
    },
    {
        "value": 17144,
        "NOCCode": "8412",
        "JobTitle": "dynamic positioning operator – offshore drilling"
    },
    {
        "value": 17145,
        "NOCCode": "8412",
        "JobTitle": "formation fracturing operator"
    },
    {
        "value": 17146,
        "NOCCode": "8412",
        "JobTitle": "formation fracturing operator – oil field services"
    },
    {
        "value": 17147,
        "NOCCode": "8412",
        "JobTitle": "frac driver – oil field services"
    },
    {
        "value": 17148,
        "NOCCode": "8412",
        "JobTitle": "motorhand – offshore drilling"
    },
    {
        "value": 17149,
        "NOCCode": "8412",
        "JobTitle": "motorhand – oil and gas drilling"
    },
    {
        "value": 17150,
        "NOCCode": "8412",
        "JobTitle": "motorman/woman – oil and gas drilling"
    },
    {
        "value": 17151,
        "NOCCode": "8412",
        "JobTitle": "nitrogen operator"
    },
    {
        "value": 17152,
        "NOCCode": "8412",
        "JobTitle": "nitrogen operator – oil field services"
    },
    {
        "value": 17153,
        "NOCCode": "8412",
        "JobTitle": "nitrogen truck operator – oil field services"
    },
    {
        "value": 17154,
        "NOCCode": "8412",
        "JobTitle": "oil well acidizer"
    },
    {
        "value": 17155,
        "NOCCode": "8412",
        "JobTitle": "oil well cementer, oil field services"
    },
    {
        "value": 17156,
        "NOCCode": "8412",
        "JobTitle": "pipeline locator – oil and gas"
    },
    {
        "value": 17157,
        "NOCCode": "8412",
        "JobTitle": "power-tong operator"
    },
    {
        "value": 17158,
        "NOCCode": "8412",
        "JobTitle": "production tubing operator – oil field services"
    },
    {
        "value": 17159,
        "NOCCode": "8412",
        "JobTitle": "pump operator, well treatment – oil field services"
    },
    {
        "value": 17160,
        "NOCCode": "8412",
        "JobTitle": "pumper operator – oil field services"
    },
    {
        "value": 17161,
        "NOCCode": "8412",
        "JobTitle": "pumpman/woman – oil field services"
    },
    {
        "value": 17162,
        "NOCCode": "8412",
        "JobTitle": "reeled tubing operator – oil field services"
    },
    {
        "value": 17163,
        "NOCCode": "8412",
        "JobTitle": "rig technician – derrickhand"
    },
    {
        "value": 17164,
        "NOCCode": "8412",
        "JobTitle": "rig technician – motorhand"
    },
    {
        "value": 17165,
        "NOCCode": "8412",
        "JobTitle": "snubbing-unit operator"
    },
    {
        "value": 17166,
        "NOCCode": "8412",
        "JobTitle": "well treatment blender operator – oil field services"
    },
    {
        "value": 17167,
        "NOCCode": "8412",
        "JobTitle": "well treatment pump operator – oil field services"
    },
    {
        "value": 17168,
        "NOCCode": "8421",
        "JobTitle": "bucker"
    },
    {
        "value": 17169,
        "NOCCode": "8421",
        "JobTitle": "bucker – logging"
    },
    {
        "value": 17170,
        "NOCCode": "8421",
        "JobTitle": "cable skidder operator"
    },
    {
        "value": 17171,
        "NOCCode": "8421",
        "JobTitle": "chain saw operator – logging"
    },
    {
        "value": 17172,
        "NOCCode": "8421",
        "JobTitle": "chaser – logging"
    },
    {
        "value": 17173,
        "NOCCode": "8421",
        "JobTitle": "cordwood cutter"
    },
    {
        "value": 17174,
        "NOCCode": "8421",
        "JobTitle": "cutter – logging"
    },
    {
        "value": 17175,
        "NOCCode": "8421",
        "JobTitle": "cutter, cordwood"
    },
    {
        "value": 17176,
        "NOCCode": "8421",
        "JobTitle": "cutter, logs"
    },
    {
        "value": 17177,
        "NOCCode": "8421",
        "JobTitle": "cutter, poles"
    },
    {
        "value": 17178,
        "NOCCode": "8421",
        "JobTitle": "cutter, posts"
    },
    {
        "value": 17179,
        "NOCCode": "8421",
        "JobTitle": "cutter, pulpwood"
    },
    {
        "value": 17180,
        "NOCCode": "8421",
        "JobTitle": "cutter, wood – logging"
    },
    {
        "value": 17181,
        "NOCCode": "8421",
        "JobTitle": "faller"
    },
    {
        "value": 17182,
        "NOCCode": "8421",
        "JobTitle": "faller – logging"
    },
    {
        "value": 17183,
        "NOCCode": "8421",
        "JobTitle": "faller and bucker – logging"
    },
    {
        "value": 17184,
        "NOCCode": "8421",
        "JobTitle": "faller and bucker, hardwood"
    },
    {
        "value": 17185,
        "NOCCode": "8421",
        "JobTitle": "feller"
    },
    {
        "value": 17186,
        "NOCCode": "8421",
        "JobTitle": "feller – logging"
    },
    {
        "value": 17187,
        "NOCCode": "8421",
        "JobTitle": "forest worker – logging"
    },
    {
        "value": 17188,
        "NOCCode": "8421",
        "JobTitle": "grapple skidder operator"
    },
    {
        "value": 17189,
        "NOCCode": "8421",
        "JobTitle": "hardwood faller and bucker"
    },
    {
        "value": 17190,
        "NOCCode": "8421",
        "JobTitle": "horse logger"
    },
    {
        "value": 17191,
        "NOCCode": "8421",
        "JobTitle": "landing bucker – logging"
    },
    {
        "value": 17192,
        "NOCCode": "8421",
        "JobTitle": "landingman/woman"
    },
    {
        "value": 17193,
        "NOCCode": "8421",
        "JobTitle": "landingman/woman – logging"
    },
    {
        "value": 17194,
        "NOCCode": "8421",
        "JobTitle": "log cutter"
    },
    {
        "value": 17195,
        "NOCCode": "8421",
        "JobTitle": "logger"
    },
    {
        "value": 17196,
        "NOCCode": "8421",
        "JobTitle": "logging tractor operator"
    },
    {
        "value": 17197,
        "NOCCode": "8421",
        "JobTitle": "lumberjack"
    },
    {
        "value": 17198,
        "NOCCode": "8421",
        "JobTitle": "operator, cable skidder"
    },
    {
        "value": 17199,
        "NOCCode": "8421",
        "JobTitle": "operator, chain saw – logging"
    },
    {
        "value": 17200,
        "NOCCode": "8421",
        "JobTitle": "operator, grapple skidder"
    },
    {
        "value": 17201,
        "NOCCode": "8421",
        "JobTitle": "operator, logging tractor"
    },
    {
        "value": 17202,
        "NOCCode": "8421",
        "JobTitle": "operator, skidder – logging"
    },
    {
        "value": 17203,
        "NOCCode": "8421",
        "JobTitle": "pieceworker – logging"
    },
    {
        "value": 17204,
        "NOCCode": "8421",
        "JobTitle": "pole cutter"
    },
    {
        "value": 17205,
        "NOCCode": "8421",
        "JobTitle": "post cutter"
    },
    {
        "value": 17206,
        "NOCCode": "8421",
        "JobTitle": "pulpwood cutter"
    },
    {
        "value": 17207,
        "NOCCode": "8421",
        "JobTitle": "skidder operator"
    },
    {
        "value": 17208,
        "NOCCode": "8421",
        "JobTitle": "skidder operator – logging"
    },
    {
        "value": 17209,
        "NOCCode": "8421",
        "JobTitle": "wood cutter – logging"
    },
    {
        "value": 17210,
        "NOCCode": "8422",
        "JobTitle": "brush cutter – forestry"
    },
    {
        "value": 17211,
        "NOCCode": "8422",
        "JobTitle": "clearing saw operator – forestry"
    },
    {
        "value": 17212,
        "NOCCode": "8422",
        "JobTitle": "collector, seed cones"
    },
    {
        "value": 17213,
        "NOCCode": "8422",
        "JobTitle": "crew worker, forestry"
    },
    {
        "value": 17214,
        "NOCCode": "8422",
        "JobTitle": "crewman/woman, forest fire suppression"
    },
    {
        "value": 17215,
        "NOCCode": "8422",
        "JobTitle": "crewman/woman, forestry"
    },
    {
        "value": 17216,
        "NOCCode": "8422",
        "JobTitle": "fire crewman/woman"
    },
    {
        "value": 17217,
        "NOCCode": "8422",
        "JobTitle": "fire lookout"
    },
    {
        "value": 17218,
        "NOCCode": "8422",
        "JobTitle": "fire suppression crewman/woman"
    },
    {
        "value": 17219,
        "NOCCode": "8422",
        "JobTitle": "firefighter, forest"
    },
    {
        "value": 17220,
        "NOCCode": "8422",
        "JobTitle": "forest firefighter"
    },
    {
        "value": 17221,
        "NOCCode": "8422",
        "JobTitle": "forestry conservation worker"
    },
    {
        "value": 17222,
        "NOCCode": "8422",
        "JobTitle": "forestry crew worker"
    },
    {
        "value": 17223,
        "NOCCode": "8422",
        "JobTitle": "forestry crewman/woman"
    },
    {
        "value": 17224,
        "NOCCode": "8422",
        "JobTitle": "forestry worker"
    },
    {
        "value": 17225,
        "NOCCode": "8422",
        "JobTitle": "forestry worker – logging"
    },
    {
        "value": 17226,
        "NOCCode": "8422",
        "JobTitle": "lookout – forestry"
    },
    {
        "value": 17227,
        "NOCCode": "8422",
        "JobTitle": "operator, clearing saw – forestry"
    },
    {
        "value": 17228,
        "NOCCode": "8422",
        "JobTitle": "operator, scarification equipment – forestry"
    },
    {
        "value": 17229,
        "NOCCode": "8422",
        "JobTitle": "operator, scarifier – silviculture"
    },
    {
        "value": 17230,
        "NOCCode": "8422",
        "JobTitle": "operator, spacing saw – forestry"
    },
    {
        "value": 17231,
        "NOCCode": "8422",
        "JobTitle": "picker, seed cones"
    },
    {
        "value": 17232,
        "NOCCode": "8422",
        "JobTitle": "pieceworker – silviculture"
    },
    {
        "value": 17233,
        "NOCCode": "8422",
        "JobTitle": "precommercial tree thinner"
    },
    {
        "value": 17234,
        "NOCCode": "8422",
        "JobTitle": "scarification equipment operator"
    },
    {
        "value": 17235,
        "NOCCode": "8422",
        "JobTitle": "scarification equipment operator – forestry"
    },
    {
        "value": 17236,
        "NOCCode": "8422",
        "JobTitle": "scarifier operator – silviculture"
    },
    {
        "value": 17237,
        "NOCCode": "8422",
        "JobTitle": "seed cone collector"
    },
    {
        "value": 17238,
        "NOCCode": "8422",
        "JobTitle": "seed cone picker"
    },
    {
        "value": 17239,
        "NOCCode": "8422",
        "JobTitle": "silviculture worker"
    },
    {
        "value": 17240,
        "NOCCode": "8422",
        "JobTitle": "spacing saw operator"
    },
    {
        "value": 17241,
        "NOCCode": "8422",
        "JobTitle": "spacing saw operator – forestry"
    },
    {
        "value": 17242,
        "NOCCode": "8422",
        "JobTitle": "thinning saw operator"
    },
    {
        "value": 17243,
        "NOCCode": "8422",
        "JobTitle": "thinning saw operator – forestry"
    },
    {
        "value": 17244,
        "NOCCode": "8422",
        "JobTitle": "tree pruner – forestry"
    },
    {
        "value": 17245,
        "NOCCode": "8422",
        "JobTitle": "tree spacer – forestry"
    },
    {
        "value": 17246,
        "NOCCode": "8431",
        "JobTitle": "apiary technician"
    },
    {
        "value": 17247,
        "NOCCode": "8431",
        "JobTitle": "apiary worker"
    },
    {
        "value": 17248,
        "NOCCode": "8431",
        "JobTitle": "artificial breeding worker"
    },
    {
        "value": 17249,
        "NOCCode": "8431",
        "JobTitle": "attendant, livestock yard"
    },
    {
        "value": 17250,
        "NOCCode": "8431",
        "JobTitle": "attendant, stockyard"
    },
    {
        "value": 17251,
        "NOCCode": "8431",
        "JobTitle": "baler – agriculture"
    },
    {
        "value": 17252,
        "NOCCode": "8431",
        "JobTitle": "baling machine operator – farm"
    },
    {
        "value": 17253,
        "NOCCode": "8431",
        "JobTitle": "barn worker"
    },
    {
        "value": 17254,
        "NOCCode": "8431",
        "JobTitle": "bee farm worker"
    },
    {
        "value": 17255,
        "NOCCode": "8431",
        "JobTitle": "beef cattle farm worker"
    },
    {
        "value": 17256,
        "NOCCode": "8431",
        "JobTitle": "beekeeping technician"
    },
    {
        "value": 17257,
        "NOCCode": "8431",
        "JobTitle": "binder operator – agriculture"
    },
    {
        "value": 17258,
        "NOCCode": "8431",
        "JobTitle": "cattle ranch labourer"
    },
    {
        "value": 17259,
        "NOCCode": "8431",
        "JobTitle": "cattle ranch worker"
    },
    {
        "value": 17260,
        "NOCCode": "8431",
        "JobTitle": "chick sexer"
    },
    {
        "value": 17261,
        "NOCCode": "8431",
        "JobTitle": "chicken catcher"
    },
    {
        "value": 17262,
        "NOCCode": "8431",
        "JobTitle": "chore hand – agriculture"
    },
    {
        "value": 17263,
        "NOCCode": "8431",
        "JobTitle": "cleaner, poultry yard"
    },
    {
        "value": 17264,
        "NOCCode": "8431",
        "JobTitle": "combine operator"
    },
    {
        "value": 17265,
        "NOCCode": "8431",
        "JobTitle": "cowboy/cowgirl"
    },
    {
        "value": 17266,
        "NOCCode": "8431",
        "JobTitle": "cowhand"
    },
    {
        "value": 17267,
        "NOCCode": "8431",
        "JobTitle": "cowpuncher"
    },
    {
        "value": 17268,
        "NOCCode": "8431",
        "JobTitle": "crewperson, custom harvest"
    },
    {
        "value": 17269,
        "NOCCode": "8431",
        "JobTitle": "cropsprayer machine operator"
    },
    {
        "value": 17270,
        "NOCCode": "8431",
        "JobTitle": "cultivator operator"
    },
    {
        "value": 17271,
        "NOCCode": "8431",
        "JobTitle": "curer, tobacco"
    },
    {
        "value": 17272,
        "NOCCode": "8431",
        "JobTitle": "custom harvest crewperson"
    },
    {
        "value": 17273,
        "NOCCode": "8431",
        "JobTitle": "dairy farm labourer"
    },
    {
        "value": 17274,
        "NOCCode": "8431",
        "JobTitle": "dairy farm worker"
    },
    {
        "value": 17275,
        "NOCCode": "8431",
        "JobTitle": "dairy hand"
    },
    {
        "value": 17276,
        "NOCCode": "8431",
        "JobTitle": "driver, livestock"
    },
    {
        "value": 17277,
        "NOCCode": "8431",
        "JobTitle": "egg candler"
    },
    {
        "value": 17278,
        "NOCCode": "8431",
        "JobTitle": "egg collector"
    },
    {
        "value": 17279,
        "NOCCode": "8431",
        "JobTitle": "egg gatherer"
    },
    {
        "value": 17280,
        "NOCCode": "8431",
        "JobTitle": "egg grader"
    },
    {
        "value": 17281,
        "NOCCode": "8431",
        "JobTitle": "egg grading machine tender"
    },
    {
        "value": 17282,
        "NOCCode": "8431",
        "JobTitle": "egg grading station labourer"
    },
    {
        "value": 17283,
        "NOCCode": "8431",
        "JobTitle": "egg packer – farm"
    },
    {
        "value": 17284,
        "NOCCode": "8431",
        "JobTitle": "egg picker – poultry"
    },
    {
        "value": 17285,
        "NOCCode": "8431",
        "JobTitle": "egg processing machine tender"
    },
    {
        "value": 17286,
        "NOCCode": "8431",
        "JobTitle": "examiner, fruit – agriculture"
    },
    {
        "value": 17287,
        "NOCCode": "8431",
        "JobTitle": "exerciser, racehorse"
    },
    {
        "value": 17288,
        "NOCCode": "8431",
        "JobTitle": "farm equipment operator"
    },
    {
        "value": 17289,
        "NOCCode": "8431",
        "JobTitle": "farm hand"
    },
    {
        "value": 17290,
        "NOCCode": "8431",
        "JobTitle": "farm labourer"
    },
    {
        "value": 17291,
        "NOCCode": "8431",
        "JobTitle": "farm labourer, grain"
    },
    {
        "value": 17292,
        "NOCCode": "8431",
        "JobTitle": "farm machinery operator"
    },
    {
        "value": 17293,
        "NOCCode": "8431",
        "JobTitle": "farm worker"
    },
    {
        "value": 17294,
        "NOCCode": "8431",
        "JobTitle": "farm worker, fruit"
    },
    {
        "value": 17295,
        "NOCCode": "8431",
        "JobTitle": "farm worker, general"
    },
    {
        "value": 17296,
        "NOCCode": "8431",
        "JobTitle": "farm worker, grain"
    },
    {
        "value": 17297,
        "NOCCode": "8431",
        "JobTitle": "farm worker, hogs"
    },
    {
        "value": 17298,
        "NOCCode": "8431",
        "JobTitle": "farm worker, mushrooms"
    },
    {
        "value": 17299,
        "NOCCode": "8431",
        "JobTitle": "farm worker, pheasants"
    },
    {
        "value": 17300,
        "NOCCode": "8431",
        "JobTitle": "farm worker, poultry"
    },
    {
        "value": 17301,
        "NOCCode": "8431",
        "JobTitle": "farm worker, sugar-beets"
    },
    {
        "value": 17302,
        "NOCCode": "8431",
        "JobTitle": "farm worker, tobacco"
    },
    {
        "value": 17303,
        "NOCCode": "8431",
        "JobTitle": "farm worker, vegetables"
    },
    {
        "value": 17304,
        "NOCCode": "8431",
        "JobTitle": "farrowing technician"
    },
    {
        "value": 17305,
        "NOCCode": "8431",
        "JobTitle": "feedlot assistant"
    },
    {
        "value": 17306,
        "NOCCode": "8431",
        "JobTitle": "feedlot worker"
    },
    {
        "value": 17307,
        "NOCCode": "8431",
        "JobTitle": "feedyard assistant"
    },
    {
        "value": 17308,
        "NOCCode": "8431",
        "JobTitle": "feedyard worker"
    },
    {
        "value": 17309,
        "NOCCode": "8431",
        "JobTitle": "field and vegetable crop labourer"
    },
    {
        "value": 17310,
        "NOCCode": "8431",
        "JobTitle": "field crop and vegetable-growing worker"
    },
    {
        "value": 17311,
        "NOCCode": "8431",
        "JobTitle": "fruit examiner – agriculture"
    },
    {
        "value": 17312,
        "NOCCode": "8431",
        "JobTitle": "fruit farm labourer"
    },
    {
        "value": 17313,
        "NOCCode": "8431",
        "JobTitle": "fruit farm worker"
    },
    {
        "value": 17314,
        "NOCCode": "8431",
        "JobTitle": "fruit farming labourer"
    },
    {
        "value": 17315,
        "NOCCode": "8431",
        "JobTitle": "fruit tree pruner"
    },
    {
        "value": 17316,
        "NOCCode": "8431",
        "JobTitle": "fruit tree thinner"
    },
    {
        "value": 17317,
        "NOCCode": "8431",
        "JobTitle": "fur farm worker"
    },
    {
        "value": 17318,
        "NOCCode": "8431",
        "JobTitle": "fur farming worker"
    },
    {
        "value": 17319,
        "NOCCode": "8431",
        "JobTitle": "fur ranch labourer"
    },
    {
        "value": 17320,
        "NOCCode": "8431",
        "JobTitle": "general farm worker"
    },
    {
        "value": 17321,
        "NOCCode": "8431",
        "JobTitle": "grafter – orchard"
    },
    {
        "value": 17322,
        "NOCCode": "8431",
        "JobTitle": "grain farm labourer"
    },
    {
        "value": 17323,
        "NOCCode": "8431",
        "JobTitle": "grain farm worker"
    },
    {
        "value": 17324,
        "NOCCode": "8431",
        "JobTitle": "groom – farm"
    },
    {
        "value": 17325,
        "NOCCode": "8431",
        "JobTitle": "harvester machine operator"
    },
    {
        "value": 17326,
        "NOCCode": "8431",
        "JobTitle": "hatchery worker"
    },
    {
        "value": 17327,
        "NOCCode": "8431",
        "JobTitle": "hay baler operator – agriculture"
    },
    {
        "value": 17328,
        "NOCCode": "8431",
        "JobTitle": "herd hand"
    },
    {
        "value": 17329,
        "NOCCode": "8431",
        "JobTitle": "herder – stockyards"
    },
    {
        "value": 17330,
        "NOCCode": "8431",
        "JobTitle": "hog farm worker"
    },
    {
        "value": 17331,
        "NOCCode": "8431",
        "JobTitle": "honey farm worker"
    },
    {
        "value": 17332,
        "NOCCode": "8431",
        "JobTitle": "hop-picking machine operator"
    },
    {
        "value": 17333,
        "NOCCode": "8431",
        "JobTitle": "horse breaker"
    },
    {
        "value": 17334,
        "NOCCode": "8431",
        "JobTitle": "incubator tender – agriculture"
    },
    {
        "value": 17335,
        "NOCCode": "8431",
        "JobTitle": "irrigation worker – agriculture"
    },
    {
        "value": 17336,
        "NOCCode": "8431",
        "JobTitle": "irrigator – agriculture"
    },
    {
        "value": 17337,
        "NOCCode": "8431",
        "JobTitle": "labourer, cattle ranch"
    },
    {
        "value": 17338,
        "NOCCode": "8431",
        "JobTitle": "labourer, dairy farm"
    },
    {
        "value": 17339,
        "NOCCode": "8431",
        "JobTitle": "labourer, egg grading station"
    },
    {
        "value": 17340,
        "NOCCode": "8431",
        "JobTitle": "labourer, farm"
    },
    {
        "value": 17341,
        "NOCCode": "8431",
        "JobTitle": "labourer, field and vegetable crops"
    },
    {
        "value": 17342,
        "NOCCode": "8431",
        "JobTitle": "labourer, fruit farming"
    },
    {
        "value": 17343,
        "NOCCode": "8431",
        "JobTitle": "labourer, fur ranch"
    },
    {
        "value": 17344,
        "NOCCode": "8431",
        "JobTitle": "labourer, grain farm"
    },
    {
        "value": 17345,
        "NOCCode": "8431",
        "JobTitle": "labourer, livestock"
    },
    {
        "value": 17346,
        "NOCCode": "8431",
        "JobTitle": "labourer, poultry farming"
    },
    {
        "value": 17347,
        "NOCCode": "8431",
        "JobTitle": "lamb feedlot worker"
    },
    {
        "value": 17348,
        "NOCCode": "8431",
        "JobTitle": "livestock driver"
    },
    {
        "value": 17349,
        "NOCCode": "8431",
        "JobTitle": "livestock labourer"
    },
    {
        "value": 17350,
        "NOCCode": "8431",
        "JobTitle": "livestock weigher"
    },
    {
        "value": 17351,
        "NOCCode": "8431",
        "JobTitle": "livestock yard attendant"
    },
    {
        "value": 17352,
        "NOCCode": "8431",
        "JobTitle": "livestock yardman/woman"
    },
    {
        "value": 17353,
        "NOCCode": "8431",
        "JobTitle": "maple syrup maker"
    },
    {
        "value": 17354,
        "NOCCode": "8431",
        "JobTitle": "maple tapping worker"
    },
    {
        "value": 17355,
        "NOCCode": "8431",
        "JobTitle": "market garden worker"
    },
    {
        "value": 17356,
        "NOCCode": "8431",
        "JobTitle": "milk production labourer"
    },
    {
        "value": 17357,
        "NOCCode": "8431",
        "JobTitle": "milking machine tender"
    },
    {
        "value": 17358,
        "NOCCode": "8431",
        "JobTitle": "mushroom farm worker"
    },
    {
        "value": 17359,
        "NOCCode": "8431",
        "JobTitle": "oilseed crop worker"
    },
    {
        "value": 17360,
        "NOCCode": "8431",
        "JobTitle": "operator, baling machine – agriculture"
    },
    {
        "value": 17361,
        "NOCCode": "8431",
        "JobTitle": "operator, farm machinery"
    },
    {
        "value": 17362,
        "NOCCode": "8431",
        "JobTitle": "operator, harvester machine"
    },
    {
        "value": 17363,
        "NOCCode": "8431",
        "JobTitle": "operator, hop-picking machine"
    },
    {
        "value": 17364,
        "NOCCode": "8431",
        "JobTitle": "operator, threshing machine"
    },
    {
        "value": 17365,
        "NOCCode": "8431",
        "JobTitle": "operator, tobacco harvesting machine"
    },
    {
        "value": 17366,
        "NOCCode": "8431",
        "JobTitle": "orchard worker"
    },
    {
        "value": 17367,
        "NOCCode": "8431",
        "JobTitle": "organic farm worker"
    },
    {
        "value": 17368,
        "NOCCode": "8431",
        "JobTitle": "pasture rider"
    },
    {
        "value": 17369,
        "NOCCode": "8431",
        "JobTitle": "penrider"
    },
    {
        "value": 17370,
        "NOCCode": "8431",
        "JobTitle": "pheasant farm worker"
    },
    {
        "value": 17371,
        "NOCCode": "8431",
        "JobTitle": "poultry farm labourer"
    },
    {
        "value": 17372,
        "NOCCode": "8431",
        "JobTitle": "poultry farm worker"
    },
    {
        "value": 17373,
        "NOCCode": "8431",
        "JobTitle": "poultry yard cleaner"
    },
    {
        "value": 17374,
        "NOCCode": "8431",
        "JobTitle": "primer, tobacco"
    },
    {
        "value": 17375,
        "NOCCode": "8431",
        "JobTitle": "pruner, vineyard"
    },
    {
        "value": 17376,
        "NOCCode": "8431",
        "JobTitle": "racehorse exerciser"
    },
    {
        "value": 17377,
        "NOCCode": "8431",
        "JobTitle": "ranch hand"
    },
    {
        "value": 17378,
        "NOCCode": "8431",
        "JobTitle": "ranch labourer, fur"
    },
    {
        "value": 17379,
        "NOCCode": "8431",
        "JobTitle": "ranch worker"
    },
    {
        "value": 17380,
        "NOCCode": "8431",
        "JobTitle": "sexer, chicks"
    },
    {
        "value": 17381,
        "NOCCode": "8431",
        "JobTitle": "sheep feedlot worker"
    },
    {
        "value": 17382,
        "NOCCode": "8431",
        "JobTitle": "sheep shearer"
    },
    {
        "value": 17383,
        "NOCCode": "8431",
        "JobTitle": "soya bean crop worker"
    },
    {
        "value": 17384,
        "NOCCode": "8431",
        "JobTitle": "stable assistant"
    },
    {
        "value": 17385,
        "NOCCode": "8431",
        "JobTitle": "stable hand"
    },
    {
        "value": 17386,
        "NOCCode": "8431",
        "JobTitle": "stable worker"
    },
    {
        "value": 17387,
        "NOCCode": "8431",
        "JobTitle": "stableman/woman"
    },
    {
        "value": 17388,
        "NOCCode": "8431",
        "JobTitle": "stock attendant"
    },
    {
        "value": 17389,
        "NOCCode": "8431",
        "JobTitle": "stockyard attendant"
    },
    {
        "value": 17390,
        "NOCCode": "8431",
        "JobTitle": "sugar bush worker"
    },
    {
        "value": 17391,
        "NOCCode": "8431",
        "JobTitle": "sugar-beet farm worker"
    },
    {
        "value": 17392,
        "NOCCode": "8431",
        "JobTitle": "swine technician"
    },
    {
        "value": 17393,
        "NOCCode": "8431",
        "JobTitle": "tender, egg processing machine"
    },
    {
        "value": 17394,
        "NOCCode": "8431",
        "JobTitle": "tender, incubator – agriculture"
    },
    {
        "value": 17395,
        "NOCCode": "8431",
        "JobTitle": "tender, milking machine"
    },
    {
        "value": 17396,
        "NOCCode": "8431",
        "JobTitle": "threshing machine operator"
    },
    {
        "value": 17397,
        "NOCCode": "8431",
        "JobTitle": "tobacco curer"
    },
    {
        "value": 17398,
        "NOCCode": "8431",
        "JobTitle": "tobacco farm worker"
    },
    {
        "value": 17399,
        "NOCCode": "8431",
        "JobTitle": "tobacco harvesting machine operator"
    },
    {
        "value": 17400,
        "NOCCode": "8431",
        "JobTitle": "tobacco primer"
    },
    {
        "value": 17401,
        "NOCCode": "8431",
        "JobTitle": "tractor operator – farm"
    },
    {
        "value": 17402,
        "NOCCode": "8431",
        "JobTitle": "vegetable farm worker"
    },
    {
        "value": 17403,
        "NOCCode": "8431",
        "JobTitle": "vegetable thinner"
    },
    {
        "value": 17404,
        "NOCCode": "8431",
        "JobTitle": "vineyard pruner"
    },
    {
        "value": 17405,
        "NOCCode": "8431",
        "JobTitle": "vineyard worker"
    },
    {
        "value": 17406,
        "NOCCode": "8431",
        "JobTitle": "weigher, livestock"
    },
    {
        "value": 17407,
        "NOCCode": "8431",
        "JobTitle": "worker, artificial insemination"
    },
    {
        "value": 17408,
        "NOCCode": "8431",
        "JobTitle": "worker, farm"
    },
    {
        "value": 17409,
        "NOCCode": "8431",
        "JobTitle": "yard attendant, livestock"
    },
    {
        "value": 17410,
        "NOCCode": "8431",
        "JobTitle": "yard cleaner, poultry"
    },
    {
        "value": 17411,
        "NOCCode": "8431",
        "JobTitle": "yardman/woman, livestock"
    },
    {
        "value": 17412,
        "NOCCode": "8432",
        "JobTitle": "Christmas tree shearer"
    },
    {
        "value": 17413,
        "NOCCode": "8432",
        "JobTitle": "Christmas tree trimmer"
    },
    {
        "value": 17414,
        "NOCCode": "8432",
        "JobTitle": "forest assistant nursery"
    },
    {
        "value": 17415,
        "NOCCode": "8432",
        "JobTitle": "forest nursery worker"
    },
    {
        "value": 17416,
        "NOCCode": "8432",
        "JobTitle": "greenhouse worker"
    },
    {
        "value": 17417,
        "NOCCode": "8432",
        "JobTitle": "hand sprayer – greenhouse"
    },
    {
        "value": 17418,
        "NOCCode": "8432",
        "JobTitle": "horticulture worker"
    },
    {
        "value": 17419,
        "NOCCode": "8432",
        "JobTitle": "hothouse worker"
    },
    {
        "value": 17420,
        "NOCCode": "8432",
        "JobTitle": "hydroponics worker"
    },
    {
        "value": 17421,
        "NOCCode": "8432",
        "JobTitle": "interior plantscaper"
    },
    {
        "value": 17422,
        "NOCCode": "8432",
        "JobTitle": "nursery worker"
    },
    {
        "value": 17423,
        "NOCCode": "8432",
        "JobTitle": "plant propagator – greenhouse or nursery"
    },
    {
        "value": 17424,
        "NOCCode": "8432",
        "JobTitle": "sprayer, hand – greenhouse"
    },
    {
        "value": 17425,
        "NOCCode": "8432",
        "JobTitle": "tree grafter – nursery"
    },
    {
        "value": 17426,
        "NOCCode": "8441",
        "JobTitle": "checkerman/woman, fishing vessel"
    },
    {
        "value": 17427,
        "NOCCode": "8441",
        "JobTitle": "crewman/woman, fishing vessel"
    },
    {
        "value": 17428,
        "NOCCode": "8441",
        "JobTitle": "deckhand, fishing vessel"
    },
    {
        "value": 17429,
        "NOCCode": "8441",
        "JobTitle": "deckhand, gillnetter"
    },
    {
        "value": 17430,
        "NOCCode": "8441",
        "JobTitle": "deckhand, offshore trawler"
    },
    {
        "value": 17431,
        "NOCCode": "8441",
        "JobTitle": "deckhand, seiner"
    },
    {
        "value": 17432,
        "NOCCode": "8441",
        "JobTitle": "deckhand, shellfish harvester"
    },
    {
        "value": 17433,
        "NOCCode": "8441",
        "JobTitle": "fishboat deckhand"
    },
    {
        "value": 17434,
        "NOCCode": "8441",
        "JobTitle": "fishing vessel checkerman/woman"
    },
    {
        "value": 17435,
        "NOCCode": "8441",
        "JobTitle": "fishing vessel deckhand"
    },
    {
        "value": 17436,
        "NOCCode": "8441",
        "JobTitle": "fishing vessel rollerman/woman"
    },
    {
        "value": 17437,
        "NOCCode": "8441",
        "JobTitle": "fishing vessel skiffman/woman"
    },
    {
        "value": 17438,
        "NOCCode": "8441",
        "JobTitle": "gillnetter deckhand"
    },
    {
        "value": 17439,
        "NOCCode": "8441",
        "JobTitle": "icer, fishing vessel"
    },
    {
        "value": 17440,
        "NOCCode": "8441",
        "JobTitle": "netmender, fishing vessel"
    },
    {
        "value": 17441,
        "NOCCode": "8441",
        "JobTitle": "offshore trawler deckhand"
    },
    {
        "value": 17442,
        "NOCCode": "8441",
        "JobTitle": "rollerman/woman, fishing vessel"
    },
    {
        "value": 17443,
        "NOCCode": "8441",
        "JobTitle": "shellfish harvester deckhand"
    },
    {
        "value": 17444,
        "NOCCode": "8441",
        "JobTitle": "skiffman/woman, fishing vessel"
    },
    {
        "value": 17445,
        "NOCCode": "8441",
        "JobTitle": "trawlerman/woman"
    },
    {
        "value": 17446,
        "NOCCode": "8442",
        "JobTitle": "beaver trapper"
    },
    {
        "value": 17447,
        "NOCCode": "8442",
        "JobTitle": "fur trapper"
    },
    {
        "value": 17448,
        "NOCCode": "8442",
        "JobTitle": "game trapper"
    },
    {
        "value": 17449,
        "NOCCode": "8442",
        "JobTitle": "hunter"
    },
    {
        "value": 17450,
        "NOCCode": "8442",
        "JobTitle": "land-based seal hunter"
    },
    {
        "value": 17451,
        "NOCCode": "8442",
        "JobTitle": "land-based sealer"
    },
    {
        "value": 17452,
        "NOCCode": "8442",
        "JobTitle": "muskrat trapper"
    },
    {
        "value": 17453,
        "NOCCode": "8442",
        "JobTitle": "seal hunter – hunting and trapping"
    },
    {
        "value": 17454,
        "NOCCode": "8442",
        "JobTitle": "sealer"
    },
    {
        "value": 17455,
        "NOCCode": "8442",
        "JobTitle": "sealer – hunting and trapping"
    },
    {
        "value": 17456,
        "NOCCode": "8442",
        "JobTitle": "trapper"
    },
    {
        "value": 17457,
        "NOCCode": "8611",
        "JobTitle": "apple picker"
    },
    {
        "value": 17458,
        "NOCCode": "8611",
        "JobTitle": "berry picker"
    },
    {
        "value": 17459,
        "NOCCode": "8611",
        "JobTitle": "crop farm labourer – harvesting"
    },
    {
        "value": 17460,
        "NOCCode": "8611",
        "JobTitle": "cucumber picker"
    },
    {
        "value": 17461,
        "NOCCode": "8611",
        "JobTitle": "field-grown crop farm labourer – harvesting"
    },
    {
        "value": 17462,
        "NOCCode": "8611",
        "JobTitle": "fruit harvest worker"
    },
    {
        "value": 17463,
        "NOCCode": "8611",
        "JobTitle": "fruit harvester"
    },
    {
        "value": 17464,
        "NOCCode": "8611",
        "JobTitle": "fruit harvesting labourer"
    },
    {
        "value": 17465,
        "NOCCode": "8611",
        "JobTitle": "fruit or vegetable picker"
    },
    {
        "value": 17466,
        "NOCCode": "8611",
        "JobTitle": "fruit packer – farm"
    },
    {
        "value": 17467,
        "NOCCode": "8611",
        "JobTitle": "fruit picker"
    },
    {
        "value": 17468,
        "NOCCode": "8611",
        "JobTitle": "fruit sorter – farm"
    },
    {
        "value": 17469,
        "NOCCode": "8611",
        "JobTitle": "harvest hand"
    },
    {
        "value": 17470,
        "NOCCode": "8611",
        "JobTitle": "harvest worker"
    },
    {
        "value": 17471,
        "NOCCode": "8611",
        "JobTitle": "harvesting labourer"
    },
    {
        "value": 17472,
        "NOCCode": "8611",
        "JobTitle": "mushroom picker"
    },
    {
        "value": 17473,
        "NOCCode": "8611",
        "JobTitle": "packer, fruit – farm"
    },
    {
        "value": 17474,
        "NOCCode": "8611",
        "JobTitle": "packer, vegetables – farm"
    },
    {
        "value": 17475,
        "NOCCode": "8611",
        "JobTitle": "picker, fruit or vegetable"
    },
    {
        "value": 17476,
        "NOCCode": "8611",
        "JobTitle": "small fruit picker"
    },
    {
        "value": 17477,
        "NOCCode": "8611",
        "JobTitle": "sorter, fruit – farm"
    },
    {
        "value": 17478,
        "NOCCode": "8611",
        "JobTitle": "sorter, vegetable – farm"
    },
    {
        "value": 17479,
        "NOCCode": "8611",
        "JobTitle": "tobacco cutter – tobacco harvesting"
    },
    {
        "value": 17480,
        "NOCCode": "8611",
        "JobTitle": "tobacco picker"
    },
    {
        "value": 17481,
        "NOCCode": "8611",
        "JobTitle": "tobacco stripper – tobacco harvesting"
    },
    {
        "value": 17482,
        "NOCCode": "8611",
        "JobTitle": "tomato picker"
    },
    {
        "value": 17483,
        "NOCCode": "8611",
        "JobTitle": "vegetable packer – farm"
    },
    {
        "value": 17484,
        "NOCCode": "8611",
        "JobTitle": "vegetable picker"
    },
    {
        "value": 17485,
        "NOCCode": "8611",
        "JobTitle": "vegetable sorter – farm"
    },
    {
        "value": 17486,
        "NOCCode": "8611",
        "JobTitle": "worm harvester"
    },
    {
        "value": 17487,
        "NOCCode": "8611",
        "JobTitle": "worm packer"
    },
    {
        "value": 17488,
        "NOCCode": "8611",
        "JobTitle": "worm picker"
    },
    {
        "value": 17489,
        "NOCCode": "8611",
        "JobTitle": "worm picker-packer"
    },
    {
        "value": 17490,
        "NOCCode": "8612",
        "JobTitle": "applicator, chemicals – lawn care"
    },
    {
        "value": 17491,
        "NOCCode": "8612",
        "JobTitle": "bulb planter – landscaping"
    },
    {
        "value": 17492,
        "NOCCode": "8612",
        "JobTitle": "campground maintenance worker"
    },
    {
        "value": 17493,
        "NOCCode": "8612",
        "JobTitle": "cemetery labourer"
    },
    {
        "value": 17494,
        "NOCCode": "8612",
        "JobTitle": "cemetery worker"
    },
    {
        "value": 17495,
        "NOCCode": "8612",
        "JobTitle": "chemical applicator – lawn care"
    },
    {
        "value": 17496,
        "NOCCode": "8612",
        "JobTitle": "cutter, grass"
    },
    {
        "value": 17497,
        "NOCCode": "8612",
        "JobTitle": "digger, graves"
    },
    {
        "value": 17498,
        "NOCCode": "8612",
        "JobTitle": "gardener helper"
    },
    {
        "value": 17499,
        "NOCCode": "8612",
        "JobTitle": "gardening helper"
    },
    {
        "value": 17500,
        "NOCCode": "8612",
        "JobTitle": "gardening helper, landscape"
    },
    {
        "value": 17501,
        "NOCCode": "8612",
        "JobTitle": "golf course labourer"
    },
    {
        "value": 17502,
        "NOCCode": "8612",
        "JobTitle": "golf course worker"
    },
    {
        "value": 17503,
        "NOCCode": "8612",
        "JobTitle": "grass cutter"
    },
    {
        "value": 17504,
        "NOCCode": "8612",
        "JobTitle": "grave digger"
    },
    {
        "value": 17505,
        "NOCCode": "8612",
        "JobTitle": "greens worker"
    },
    {
        "value": 17506,
        "NOCCode": "8612",
        "JobTitle": "greenskeeper helper"
    },
    {
        "value": 17507,
        "NOCCode": "8612",
        "JobTitle": "grounds maintainer"
    },
    {
        "value": 17508,
        "NOCCode": "8612",
        "JobTitle": "grounds maintenance labourer"
    },
    {
        "value": 17509,
        "NOCCode": "8612",
        "JobTitle": "grounds maintenance worker"
    },
    {
        "value": 17510,
        "NOCCode": "8612",
        "JobTitle": "groundskeeper"
    },
    {
        "value": 17511,
        "NOCCode": "8612",
        "JobTitle": "groundsman/woman"
    },
    {
        "value": 17512,
        "NOCCode": "8612",
        "JobTitle": "groundsperson – campground"
    },
    {
        "value": 17513,
        "NOCCode": "8612",
        "JobTitle": "helper, gardener"
    },
    {
        "value": 17514,
        "NOCCode": "8612",
        "JobTitle": "helper, gardening"
    },
    {
        "value": 17515,
        "NOCCode": "8612",
        "JobTitle": "helper, greenskeeper"
    },
    {
        "value": 17516,
        "NOCCode": "8612",
        "JobTitle": "helper, landscape gardener"
    },
    {
        "value": 17517,
        "NOCCode": "8612",
        "JobTitle": "helper, landscape gardening"
    },
    {
        "value": 17518,
        "NOCCode": "8612",
        "JobTitle": "helper, plant doctor"
    },
    {
        "value": 17519,
        "NOCCode": "8612",
        "JobTitle": "helper, tree surgeon"
    },
    {
        "value": 17520,
        "NOCCode": "8612",
        "JobTitle": "keeper, grounds"
    },
    {
        "value": 17521,
        "NOCCode": "8612",
        "JobTitle": "labourer, cemetery"
    },
    {
        "value": 17522,
        "NOCCode": "8612",
        "JobTitle": "labourer, golf course"
    },
    {
        "value": 17523,
        "NOCCode": "8612",
        "JobTitle": "labourer, grounds maintenance"
    },
    {
        "value": 17524,
        "NOCCode": "8612",
        "JobTitle": "labourer, landscape"
    },
    {
        "value": 17525,
        "NOCCode": "8612",
        "JobTitle": "labourer, park maintenance"
    },
    {
        "value": 17526,
        "NOCCode": "8612",
        "JobTitle": "labourer, sod laying"
    },
    {
        "value": 17527,
        "NOCCode": "8612",
        "JobTitle": "landscape gardener helper"
    },
    {
        "value": 17528,
        "NOCCode": "8612",
        "JobTitle": "landscape gardening helper"
    },
    {
        "value": 17529,
        "NOCCode": "8612",
        "JobTitle": "landscape labourer"
    },
    {
        "value": 17530,
        "NOCCode": "8612",
        "JobTitle": "landscape worker"
    },
    {
        "value": 17531,
        "NOCCode": "8612",
        "JobTitle": "lawn care labourer"
    },
    {
        "value": 17532,
        "NOCCode": "8612",
        "JobTitle": "lawn care worker"
    },
    {
        "value": 17533,
        "NOCCode": "8612",
        "JobTitle": "lawn mower"
    },
    {
        "value": 17534,
        "NOCCode": "8612",
        "JobTitle": "lawn-mowing worker"
    },
    {
        "value": 17535,
        "NOCCode": "8612",
        "JobTitle": "maintainer, grounds"
    },
    {
        "value": 17536,
        "NOCCode": "8612",
        "JobTitle": "maintenance labourer, parks"
    },
    {
        "value": 17537,
        "NOCCode": "8612",
        "JobTitle": "maintenance worker, campground"
    },
    {
        "value": 17538,
        "NOCCode": "8612",
        "JobTitle": "maintenance worker, grounds"
    },
    {
        "value": 17539,
        "NOCCode": "8612",
        "JobTitle": "maintenance worker, parks"
    },
    {
        "value": 17540,
        "NOCCode": "8612",
        "JobTitle": "mower, lawns"
    },
    {
        "value": 17541,
        "NOCCode": "8612",
        "JobTitle": "park maintenance labourer"
    },
    {
        "value": 17542,
        "NOCCode": "8612",
        "JobTitle": "plant care worker"
    },
    {
        "value": 17543,
        "NOCCode": "8612",
        "JobTitle": "plant doctor helper"
    },
    {
        "value": 17544,
        "NOCCode": "8612",
        "JobTitle": "snow shoveller – grounds"
    },
    {
        "value": 17545,
        "NOCCode": "8612",
        "JobTitle": "sod layer"
    },
    {
        "value": 17546,
        "NOCCode": "8612",
        "JobTitle": "sod laying labourer"
    },
    {
        "value": 17547,
        "NOCCode": "8612",
        "JobTitle": "sprayer, trees"
    },
    {
        "value": 17548,
        "NOCCode": "8612",
        "JobTitle": "stadium groundskeeper"
    },
    {
        "value": 17549,
        "NOCCode": "8612",
        "JobTitle": "transplanter – landscape maintenance"
    },
    {
        "value": 17550,
        "NOCCode": "8612",
        "JobTitle": "tree pruner – landscaping"
    },
    {
        "value": 17551,
        "NOCCode": "8612",
        "JobTitle": "tree sprayer"
    },
    {
        "value": 17552,
        "NOCCode": "8612",
        "JobTitle": "tree surgeon helper"
    },
    {
        "value": 17553,
        "NOCCode": "8612",
        "JobTitle": "tree trimmer – landscaping"
    },
    {
        "value": 17554,
        "NOCCode": "8612",
        "JobTitle": "trimmer, trees – landscaping"
    },
    {
        "value": 17555,
        "NOCCode": "8612",
        "JobTitle": "worker, cemetery"
    },
    {
        "value": 17556,
        "NOCCode": "8612",
        "JobTitle": "worker, golf course"
    },
    {
        "value": 17557,
        "NOCCode": "8612",
        "JobTitle": "worker, greens"
    },
    {
        "value": 17558,
        "NOCCode": "8612",
        "JobTitle": "worker, grounds maintenance"
    },
    {
        "value": 17559,
        "NOCCode": "8612",
        "JobTitle": "worker, landscape"
    },
    {
        "value": 17560,
        "NOCCode": "8612",
        "JobTitle": "worker, lawn mowing"
    },
    {
        "value": 17561,
        "NOCCode": "8612",
        "JobTitle": "worker, plant care"
    },
    {
        "value": 17562,
        "NOCCode": "8613",
        "JobTitle": "aquaculture support worker"
    },
    {
        "value": 17563,
        "NOCCode": "8613",
        "JobTitle": "aquaculture worker"
    },
    {
        "value": 17564,
        "NOCCode": "8613",
        "JobTitle": "attendant, lobster pound"
    },
    {
        "value": 17565,
        "NOCCode": "8613",
        "JobTitle": "clam digger"
    },
    {
        "value": 17566,
        "NOCCode": "8613",
        "JobTitle": "dulse gatherer"
    },
    {
        "value": 17567,
        "NOCCode": "8613",
        "JobTitle": "fish farm helper"
    },
    {
        "value": 17568,
        "NOCCode": "8613",
        "JobTitle": "fish farm worker"
    },
    {
        "value": 17569,
        "NOCCode": "8613",
        "JobTitle": "fish hatchery attendant"
    },
    {
        "value": 17570,
        "NOCCode": "8613",
        "JobTitle": "fish hatchery tagger"
    },
    {
        "value": 17571,
        "NOCCode": "8613",
        "JobTitle": "fish hatchery worker – aquaculture"
    },
    {
        "value": 17572,
        "NOCCode": "8613",
        "JobTitle": "fish tagger"
    },
    {
        "value": 17573,
        "NOCCode": "8613",
        "JobTitle": "fry marker"
    },
    {
        "value": 17574,
        "NOCCode": "8613",
        "JobTitle": "fry marker – fishing"
    },
    {
        "value": 17575,
        "NOCCode": "8613",
        "JobTitle": "fry tagger"
    },
    {
        "value": 17576,
        "NOCCode": "8613",
        "JobTitle": "hatchery helper"
    },
    {
        "value": 17577,
        "NOCCode": "8613",
        "JobTitle": "hatchery worker – aquaculture"
    },
    {
        "value": 17578,
        "NOCCode": "8613",
        "JobTitle": "Irish moss gatherer"
    },
    {
        "value": 17579,
        "NOCCode": "8613",
        "JobTitle": "lobster pound attendant"
    },
    {
        "value": 17580,
        "NOCCode": "8613",
        "JobTitle": "marine plant gatherer"
    },
    {
        "value": 17581,
        "NOCCode": "8613",
        "JobTitle": "mussel farm labourer"
    },
    {
        "value": 17582,
        "NOCCode": "8613",
        "JobTitle": "mussel harvester"
    },
    {
        "value": 17583,
        "NOCCode": "8613",
        "JobTitle": "oyster harvester"
    },
    {
        "value": 17584,
        "NOCCode": "8613",
        "JobTitle": "oyster picker"
    },
    {
        "value": 17585,
        "NOCCode": "8613",
        "JobTitle": "oyster picker-shucker"
    },
    {
        "value": 17586,
        "NOCCode": "8613",
        "JobTitle": "quahog digger"
    },
    {
        "value": 17587,
        "NOCCode": "8613",
        "JobTitle": "sea farm attendant"
    },
    {
        "value": 17588,
        "NOCCode": "8613",
        "JobTitle": "sea farm worker"
    },
    {
        "value": 17589,
        "NOCCode": "8613",
        "JobTitle": "seaweed gatherer"
    },
    {
        "value": 17590,
        "NOCCode": "8613",
        "JobTitle": "shellfish bed attendant"
    },
    {
        "value": 17591,
        "NOCCode": "8613",
        "JobTitle": "shellfish harvester"
    },
    {
        "value": 17592,
        "NOCCode": "8614",
        "JobTitle": "check weigher, mine"
    },
    {
        "value": 17593,
        "NOCCode": "8614",
        "JobTitle": "chute puller"
    },
    {
        "value": 17594,
        "NOCCode": "8614",
        "JobTitle": "coal sampler"
    },
    {
        "value": 17595,
        "NOCCode": "8614",
        "JobTitle": "core splitter"
    },
    {
        "value": 17596,
        "NOCCode": "8614",
        "JobTitle": "grizzly worker – underground mining"
    },
    {
        "value": 17597,
        "NOCCode": "8614",
        "JobTitle": "helper, miner – underground mining"
    },
    {
        "value": 17598,
        "NOCCode": "8614",
        "JobTitle": "helper, pipeworker – underground mining"
    },
    {
        "value": 17599,
        "NOCCode": "8614",
        "JobTitle": "helper, timber and steel prop setter"
    },
    {
        "value": 17600,
        "NOCCode": "8614",
        "JobTitle": "labourer, mine"
    },
    {
        "value": 17601,
        "NOCCode": "8614",
        "JobTitle": "limestone spreader – underground mining"
    },
    {
        "value": 17602,
        "NOCCode": "8614",
        "JobTitle": "mine check weigher"
    },
    {
        "value": 17603,
        "NOCCode": "8614",
        "JobTitle": "mine helper"
    },
    {
        "value": 17604,
        "NOCCode": "8614",
        "JobTitle": "mine helper – underground mining"
    },
    {
        "value": 17605,
        "NOCCode": "8614",
        "JobTitle": "mine labourer"
    },
    {
        "value": 17606,
        "NOCCode": "8614",
        "JobTitle": "miner helper – underground mining"
    },
    {
        "value": 17607,
        "NOCCode": "8614",
        "JobTitle": "pipeworker helper – underground mining"
    },
    {
        "value": 17608,
        "NOCCode": "8614",
        "JobTitle": "pit scaler – underground mining"
    },
    {
        "value": 17609,
        "NOCCode": "8614",
        "JobTitle": "pit-scaler helper"
    },
    {
        "value": 17610,
        "NOCCode": "8614",
        "JobTitle": "powder carrier – underground mining"
    },
    {
        "value": 17611,
        "NOCCode": "8614",
        "JobTitle": "rock duster – underground mining"
    },
    {
        "value": 17612,
        "NOCCode": "8614",
        "JobTitle": "salvager, timber and steel props"
    },
    {
        "value": 17613,
        "NOCCode": "8614",
        "JobTitle": "sample processor"
    },
    {
        "value": 17614,
        "NOCCode": "8614",
        "JobTitle": "setter helper, timber and steel prop"
    },
    {
        "value": 17615,
        "NOCCode": "8614",
        "JobTitle": "shoveller – underground mining"
    },
    {
        "value": 17616,
        "NOCCode": "8614",
        "JobTitle": "slate picker"
    },
    {
        "value": 17617,
        "NOCCode": "8614",
        "JobTitle": "stone duster – underground mining"
    },
    {
        "value": 17618,
        "NOCCode": "8614",
        "JobTitle": "timber and steel prop salvager"
    },
    {
        "value": 17619,
        "NOCCode": "8614",
        "JobTitle": "timber and steel prop setter helper"
    },
    {
        "value": 17620,
        "NOCCode": "8614",
        "JobTitle": "timbering and steel prop setting helper"
    },
    {
        "value": 17621,
        "NOCCode": "8614",
        "JobTitle": "timberman/woman helper – underground mining"
    },
    {
        "value": 17622,
        "NOCCode": "8614",
        "JobTitle": "underground helper – mining"
    },
    {
        "value": 17623,
        "NOCCode": "8614",
        "JobTitle": "underground labourer – mining"
    },
    {
        "value": 17624,
        "NOCCode": "8614",
        "JobTitle": "underground mine labourer"
    },
    {
        "value": 17625,
        "NOCCode": "8615",
        "JobTitle": "cementer helper, oil well"
    },
    {
        "value": 17626,
        "NOCCode": "8615",
        "JobTitle": "floorhand"
    },
    {
        "value": 17627,
        "NOCCode": "8615",
        "JobTitle": "floorhand – offshore drilling rig"
    },
    {
        "value": 17628,
        "NOCCode": "8615",
        "JobTitle": "floorhand – oil and gas drilling"
    },
    {
        "value": 17629,
        "NOCCode": "8615",
        "JobTitle": "floorhand – service rig"
    },
    {
        "value": 17630,
        "NOCCode": "8615",
        "JobTitle": "floorman/woman – offshore drilling rig"
    },
    {
        "value": 17631,
        "NOCCode": "8615",
        "JobTitle": "floorman/woman – oil and gas drilling"
    },
    {
        "value": 17632,
        "NOCCode": "8615",
        "JobTitle": "gravity prospecting observer helper"
    },
    {
        "value": 17633,
        "NOCCode": "8615",
        "JobTitle": "helper, oil well cementer"
    },
    {
        "value": 17634,
        "NOCCode": "8615",
        "JobTitle": "helper, perforator loader – oil field services"
    },
    {
        "value": 17635,
        "NOCCode": "8615",
        "JobTitle": "helper, service rig – oil field services"
    },
    {
        "value": 17636,
        "NOCCode": "8615",
        "JobTitle": "helper, tubing – oil field services"
    },
    {
        "value": 17637,
        "NOCCode": "8615",
        "JobTitle": "helper, well puller – oil field services"
    },
    {
        "value": 17638,
        "NOCCode": "8615",
        "JobTitle": "helper, well treatment – oil field services"
    },
    {
        "value": 17639,
        "NOCCode": "8615",
        "JobTitle": "helper, wireline"
    },
    {
        "value": 17640,
        "NOCCode": "8615",
        "JobTitle": "helper, wireline – oil field services"
    },
    {
        "value": 17641,
        "NOCCode": "8615",
        "JobTitle": "labourer, oil field"
    },
    {
        "value": 17642,
        "NOCCode": "8615",
        "JobTitle": "leasehand"
    },
    {
        "value": 17643,
        "NOCCode": "8615",
        "JobTitle": "leasehand – oil and gas drilling"
    },
    {
        "value": 17644,
        "NOCCode": "8615",
        "JobTitle": "observer helper – gravity prospecting"
    },
    {
        "value": 17645,
        "NOCCode": "8615",
        "JobTitle": "observer helper – seismic prospecting"
    },
    {
        "value": 17646,
        "NOCCode": "8615",
        "JobTitle": "oil well cementer helper"
    },
    {
        "value": 17647,
        "NOCCode": "8615",
        "JobTitle": "operator helper, service rig"
    },
    {
        "value": 17648,
        "NOCCode": "8615",
        "JobTitle": "operator, vibrator – seismic prospecting"
    },
    {
        "value": 17649,
        "NOCCode": "8615",
        "JobTitle": "perforator helper – oil field services"
    },
    {
        "value": 17650,
        "NOCCode": "8615",
        "JobTitle": "reeled tubing helper – oil field services"
    },
    {
        "value": 17651,
        "NOCCode": "8615",
        "JobTitle": "roughneck"
    },
    {
        "value": 17652,
        "NOCCode": "8615",
        "JobTitle": "roughneck – offshore drilling rig"
    },
    {
        "value": 17653,
        "NOCCode": "8615",
        "JobTitle": "roughneck – oil and gas drilling"
    },
    {
        "value": 17654,
        "NOCCode": "8615",
        "JobTitle": "roughneck, service rig – oil field services"
    },
    {
        "value": 17655,
        "NOCCode": "8615",
        "JobTitle": "roustabout"
    },
    {
        "value": 17656,
        "NOCCode": "8615",
        "JobTitle": "roustabout – offshore drilling rig"
    },
    {
        "value": 17657,
        "NOCCode": "8615",
        "JobTitle": "roustabout – oil and gas drilling"
    },
    {
        "value": 17658,
        "NOCCode": "8615",
        "JobTitle": "seismic GPS rover"
    },
    {
        "value": 17659,
        "NOCCode": "8615",
        "JobTitle": "seismic line cutter"
    },
    {
        "value": 17660,
        "NOCCode": "8615",
        "JobTitle": "seismic prospecting observer helper"
    },
    {
        "value": 17661,
        "NOCCode": "8615",
        "JobTitle": "service rig helper"
    },
    {
        "value": 17662,
        "NOCCode": "8615",
        "JobTitle": "service rig helper – oil field services"
    },
    {
        "value": 17663,
        "NOCCode": "8615",
        "JobTitle": "service rig roughneck – oil field services"
    },
    {
        "value": 17664,
        "NOCCode": "8615",
        "JobTitle": "shakerhand"
    },
    {
        "value": 17665,
        "NOCCode": "8615",
        "JobTitle": "shakerhand – oil and gas drilling"
    },
    {
        "value": 17666,
        "NOCCode": "8615",
        "JobTitle": "shooter – seismograph"
    },
    {
        "value": 17667,
        "NOCCode": "8615",
        "JobTitle": "shooter helper – seismic prospecting"
    },
    {
        "value": 17668,
        "NOCCode": "8615",
        "JobTitle": "slasher – oil and gas"
    },
    {
        "value": 17669,
        "NOCCode": "8615",
        "JobTitle": "slasher – oil field services"
    },
    {
        "value": 17670,
        "NOCCode": "8615",
        "JobTitle": "swamper – oil and gas"
    },
    {
        "value": 17671,
        "NOCCode": "8615",
        "JobTitle": "swamper – oil field services"
    },
    {
        "value": 17672,
        "NOCCode": "8615",
        "JobTitle": "tubing helper – oil field services"
    },
    {
        "value": 17673,
        "NOCCode": "8615",
        "JobTitle": "vibrator operator – seismic prospecting"
    },
    {
        "value": 17674,
        "NOCCode": "8615",
        "JobTitle": "well puller helper – oil field services"
    },
    {
        "value": 17675,
        "NOCCode": "8615",
        "JobTitle": "well treatment helper"
    },
    {
        "value": 17676,
        "NOCCode": "8615",
        "JobTitle": "well treatment helper – oil field services"
    },
    {
        "value": 17677,
        "NOCCode": "8615",
        "JobTitle": "wireline helper – oil field services"
    },
    {
        "value": 17678,
        "NOCCode": "8616",
        "JobTitle": "boom man/woman"
    },
    {
        "value": 17679,
        "NOCCode": "8616",
        "JobTitle": "boom worker"
    },
    {
        "value": 17680,
        "NOCCode": "8616",
        "JobTitle": "boom worker – logging"
    },
    {
        "value": 17681,
        "NOCCode": "8616",
        "JobTitle": "boom worker – mill pond"
    },
    {
        "value": 17682,
        "NOCCode": "8616",
        "JobTitle": "cable hooker – logging"
    },
    {
        "value": 17683,
        "NOCCode": "8616",
        "JobTitle": "choke setter – logging"
    },
    {
        "value": 17684,
        "NOCCode": "8616",
        "JobTitle": "chokerman/woman"
    },
    {
        "value": 17685,
        "NOCCode": "8616",
        "JobTitle": "chokerman/woman – logging"
    },
    {
        "value": 17686,
        "NOCCode": "8616",
        "JobTitle": "forestry labourer"
    },
    {
        "value": 17687,
        "NOCCode": "8616",
        "JobTitle": "labourer, forestry"
    },
    {
        "value": 17688,
        "NOCCode": "8616",
        "JobTitle": "labourer, logging"
    },
    {
        "value": 17689,
        "NOCCode": "8616",
        "JobTitle": "labourer, logging and forestry"
    },
    {
        "value": 17690,
        "NOCCode": "8616",
        "JobTitle": "log driver"
    },
    {
        "value": 17691,
        "NOCCode": "8616",
        "JobTitle": "log rider"
    },
    {
        "value": 17692,
        "NOCCode": "8616",
        "JobTitle": "logging and forestry labourer"
    },
    {
        "value": 17693,
        "NOCCode": "8616",
        "JobTitle": "logging labourer"
    },
    {
        "value": 17694,
        "NOCCode": "8616",
        "JobTitle": "piler, pulpwood"
    },
    {
        "value": 17695,
        "NOCCode": "8616",
        "JobTitle": "pondman/woman"
    },
    {
        "value": 17696,
        "NOCCode": "8616",
        "JobTitle": "pulpwood piler"
    },
    {
        "value": 17697,
        "NOCCode": "8616",
        "JobTitle": "river driver"
    },
    {
        "value": 17698,
        "NOCCode": "8616",
        "JobTitle": "seasonal swamper – logging forestry"
    },
    {
        "value": 17699,
        "NOCCode": "8616",
        "JobTitle": "sprayer – logging and forestry"
    },
    {
        "value": 17700,
        "NOCCode": "8616",
        "JobTitle": "swamper – logging and forestry"
    },
    {
        "value": 17701,
        "NOCCode": "8616",
        "JobTitle": "swamper, seasonal – logging"
    },
    {
        "value": 17702,
        "NOCCode": "8616",
        "JobTitle": "swamper, seasonal – logging and forestry"
    },
    {
        "value": 17703,
        "NOCCode": "8616",
        "JobTitle": "tree planter, seasonal"
    },
    {
        "value": 17704,
        "NOCCode": "9211",
        "JobTitle": "alumina plant foreman/woman"
    },
    {
        "value": 17705,
        "NOCCode": "9211",
        "JobTitle": "annealing foreman/woman"
    },
    {
        "value": 17706,
        "NOCCode": "9211",
        "JobTitle": "annealing foreman/woman – primary metal processing"
    },
    {
        "value": 17707,
        "NOCCode": "9211",
        "JobTitle": "anodizing foreman/woman – primary metal processing"
    },
    {
        "value": 17708,
        "NOCCode": "9211",
        "JobTitle": "asbestos manufacturing foreman/woman"
    },
    {
        "value": 17709,
        "NOCCode": "9211",
        "JobTitle": "ball mill foreman/woman – mineral products processing"
    },
    {
        "value": 17710,
        "NOCCode": "9211",
        "JobTitle": "blast furnace foreman/woman"
    },
    {
        "value": 17711,
        "NOCCode": "9211",
        "JobTitle": "brick and tile foreman/woman"
    },
    {
        "value": 17712,
        "NOCCode": "9211",
        "JobTitle": "casting operations foreman/woman"
    },
    {
        "value": 17713,
        "NOCCode": "9211",
        "JobTitle": "casting room foreman/woman"
    },
    {
        "value": 17714,
        "NOCCode": "9211",
        "JobTitle": "cement manufacturing supervisor"
    },
    {
        "value": 17715,
        "NOCCode": "9211",
        "JobTitle": "cement processing foreman/woman"
    },
    {
        "value": 17716,
        "NOCCode": "9211",
        "JobTitle": "cement processing supervisor"
    },
    {
        "value": 17717,
        "NOCCode": "9211",
        "JobTitle": "clay preparation foreman/woman"
    },
    {
        "value": 17718,
        "NOCCode": "9211",
        "JobTitle": "clay products manufacturing foreman/woman"
    },
    {
        "value": 17719,
        "NOCCode": "9211",
        "JobTitle": "clay shop foreman/woman"
    },
    {
        "value": 17720,
        "NOCCode": "9211",
        "JobTitle": "clay, glass and stone furnace and kiln operations foreman/woman"
    },
    {
        "value": 17721,
        "NOCCode": "9211",
        "JobTitle": "clay, glass and stone furnace and kiln operators supervisor"
    },
    {
        "value": 17722,
        "NOCCode": "9211",
        "JobTitle": "coal-and-coke crushing and grinding foreman/woman"
    },
    {
        "value": 17723,
        "NOCCode": "9211",
        "JobTitle": "coke ovens foreman/woman"
    },
    {
        "value": 17724,
        "NOCCode": "9211",
        "JobTitle": "coke screening foreman/woman"
    },
    {
        "value": 17725,
        "NOCCode": "9211",
        "JobTitle": "coke screening foreman/woman – mineral and metal processing"
    },
    {
        "value": 17726,
        "NOCCode": "9211",
        "JobTitle": "concentrator foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17727,
        "NOCCode": "9211",
        "JobTitle": "concrete products foreman/woman"
    },
    {
        "value": 17728,
        "NOCCode": "9211",
        "JobTitle": "converter foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17729,
        "NOCCode": "9211",
        "JobTitle": "coremaking foreman/woman – foundry"
    },
    {
        "value": 17730,
        "NOCCode": "9211",
        "JobTitle": "coremaking foreman/woman – mineral processing"
    },
    {
        "value": 17731,
        "NOCCode": "9211",
        "JobTitle": "crushing and grinding operations foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17732,
        "NOCCode": "9211",
        "JobTitle": "crushing and grinding supervisor – primary metal and mineral products"
    },
    {
        "value": 17733,
        "NOCCode": "9211",
        "JobTitle": "die casting foreman/woman – foundry"
    },
    {
        "value": 17734,
        "NOCCode": "9211",
        "JobTitle": "die casting supervisor"
    },
    {
        "value": 17735,
        "NOCCode": "9211",
        "JobTitle": "electrolytic cell operations foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17736,
        "NOCCode": "9211",
        "JobTitle": "extruding foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17737,
        "NOCCode": "9211",
        "JobTitle": "extruding foreman/woman – primary metal processing"
    },
    {
        "value": 17738,
        "NOCCode": "9211",
        "JobTitle": "filter plant foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17739,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, annealing"
    },
    {
        "value": 17740,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, annealing – primary metal processing"
    },
    {
        "value": 17741,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, anodizing – primary metal processing"
    },
    {
        "value": 17742,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, casting operations"
    },
    {
        "value": 17743,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, casting room"
    },
    {
        "value": 17744,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, cement processing"
    },
    {
        "value": 17745,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, clay products manufacturing"
    },
    {
        "value": 17746,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, clay shop"
    },
    {
        "value": 17747,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, clay, glass and stone furnace and kiln operations"
    },
    {
        "value": 17748,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, coal and coke crushing and grinding"
    },
    {
        "value": 17749,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, coke screening – mineral and metal processing"
    },
    {
        "value": 17750,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, concentrator – primary metal and mineral products processing"
    },
    {
        "value": 17751,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, concrete products"
    },
    {
        "value": 17752,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, converter – primary metal and mineral products processing"
    },
    {
        "value": 17753,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, coremaking – foundry"
    },
    {
        "value": 17754,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, coremaking – mineral processing"
    },
    {
        "value": 17755,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, crushing and grinding operations – primary metal and mineral products processing"
    },
    {
        "value": 17756,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, die casting – foundry"
    },
    {
        "value": 17757,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, extruding – primary metal and mineral products processing"
    },
    {
        "value": 17758,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, extruding – primary metal processing"
    },
    {
        "value": 17759,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, filter plant – primary metal and mineral products processing"
    },
    {
        "value": 17760,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, foundry"
    },
    {
        "value": 17761,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, furnace operations – primary metal and mineral products processing"
    },
    {
        "value": 17762,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, galvanizing – metal and mineral products processing"
    },
    {
        "value": 17763,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass cutting"
    },
    {
        "value": 17764,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass engraving"
    },
    {
        "value": 17765,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass forming"
    },
    {
        "value": 17766,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass furnace"
    },
    {
        "value": 17767,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass grinding and polishing"
    },
    {
        "value": 17768,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass processing"
    },
    {
        "value": 17769,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, glass products manufacturing"
    },
    {
        "value": 17770,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, inspecting, testing and grading – primary metal and mineral products processing"
    },
    {
        "value": 17771,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, kiln workers – clay products"
    },
    {
        "value": 17772,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, labourers – primary metal and mineral products processing"
    },
    {
        "value": 17773,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, lead refining"
    },
    {
        "value": 17774,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, lime preparation"
    },
    {
        "value": 17775,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, melting and roasting – primary metal and mineral products processing"
    },
    {
        "value": 17776,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, melting and roasting furnace – primary metal and mineral products processing"
    },
    {
        "value": 17777,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, metal casting, moulding and coremaking"
    },
    {
        "value": 17778,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, metal extruding and drawing"
    },
    {
        "value": 17779,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, metal heat-treating – primary metal processing"
    },
    {
        "value": 17780,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, metal rolling"
    },
    {
        "value": 17781,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, metal smelting, converting and refining"
    },
    {
        "value": 17782,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, mica processing"
    },
    {
        "value": 17783,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, milling plant – primary metal and mineral products processing"
    },
    {
        "value": 17784,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, mixing and separating operations – primary metal and mineral products processing"
    },
    {
        "value": 17785,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, mixing plant – primary metal and mineral products processing"
    },
    {
        "value": 17786,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, moulding – foundry"
    },
    {
        "value": 17787,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, ore milling"
    },
    {
        "value": 17788,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, oxygen furnace – primary metal and mineral products processing"
    },
    {
        "value": 17789,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, pot room – primary metal and mineral products processing"
    },
    {
        "value": 17790,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, pot room – primary metal processing"
    },
    {
        "value": 17791,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, primary metal and mineral products processing"
    },
    {
        "value": 17792,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, quenching – mineral and metal processing"
    },
    {
        "value": 17793,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, quenching – primary metal processing"
    },
    {
        "value": 17794,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, reclamation – primary metal and mineral products processing"
    },
    {
        "value": 17795,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, reduction plant – primary metal and mineral products processing"
    },
    {
        "value": 17796,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, rock crusher – primary metal and mineral products processing"
    },
    {
        "value": 17797,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, rolling mill – primary metal processing"
    },
    {
        "value": 17798,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, scrap preparation – primary metal and mineral products processing"
    },
    {
        "value": 17799,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, scrap preparation – primary metal processing"
    },
    {
        "value": 17800,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, sintering plant – mineral and metal processing"
    },
    {
        "value": 17801,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, sintering plant – primary metal and mineral products processing"
    },
    {
        "value": 17802,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, smelter – primary metal and mineral products processing"
    },
    {
        "value": 17803,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, stone processing"
    },
    {
        "value": 17804,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, stone products manufacturing"
    },
    {
        "value": 17805,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, tankhouse – primary metal and mineral products processing"
    },
    {
        "value": 17806,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, tapper operations – mineral and metal processing"
    },
    {
        "value": 17807,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, tempering – primary metal processing"
    },
    {
        "value": 17808,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, uranium processing"
    },
    {
        "value": 17809,
        "NOCCode": "9211",
        "JobTitle": "foreman/woman, wire drawing – primary metal processing"
    },
    {
        "value": 17810,
        "NOCCode": "9211",
        "JobTitle": "foundry foreman/woman"
    },
    {
        "value": 17811,
        "NOCCode": "9211",
        "JobTitle": "foundry supervisor"
    },
    {
        "value": 17812,
        "NOCCode": "9211",
        "JobTitle": "furnace operations foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17813,
        "NOCCode": "9211",
        "JobTitle": "galvanizing foreman/woman – metal and mineral products processing"
    },
    {
        "value": 17814,
        "NOCCode": "9211",
        "JobTitle": "glass cutting foreman/woman"
    },
    {
        "value": 17815,
        "NOCCode": "9211",
        "JobTitle": "glass engraving foreman/woman"
    },
    {
        "value": 17816,
        "NOCCode": "9211",
        "JobTitle": "glass forming foreman/woman"
    },
    {
        "value": 17817,
        "NOCCode": "9211",
        "JobTitle": "glass furnace foreman/woman"
    },
    {
        "value": 17818,
        "NOCCode": "9211",
        "JobTitle": "glass processing foreman/woman"
    },
    {
        "value": 17819,
        "NOCCode": "9211",
        "JobTitle": "glass products manufacturing foreman/woman"
    },
    {
        "value": 17820,
        "NOCCode": "9211",
        "JobTitle": "glass-grinders and glass-polishers foreman/woman"
    },
    {
        "value": 17821,
        "NOCCode": "9211",
        "JobTitle": "inspecting, testing and grading foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17822,
        "NOCCode": "9211",
        "JobTitle": "kiln workers foreman/woman – clay products"
    },
    {
        "value": 17823,
        "NOCCode": "9211",
        "JobTitle": "labourers foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17824,
        "NOCCode": "9211",
        "JobTitle": "lime preparation foreman/woman"
    },
    {
        "value": 17825,
        "NOCCode": "9211",
        "JobTitle": "melting and roasting department supervisor"
    },
    {
        "value": 17826,
        "NOCCode": "9211",
        "JobTitle": "melting and roasting foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17827,
        "NOCCode": "9211",
        "JobTitle": "melting and roasting operations foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17828,
        "NOCCode": "9211",
        "JobTitle": "melting-furnace and roasting-furnace foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17829,
        "NOCCode": "9211",
        "JobTitle": "metal casters, moulders and coremakers foreman/woman"
    },
    {
        "value": 17830,
        "NOCCode": "9211",
        "JobTitle": "metal casting, moulding and coremaking foreman/woman"
    },
    {
        "value": 17831,
        "NOCCode": "9211",
        "JobTitle": "metal heat-treating foreman/woman – primary metal processing"
    },
    {
        "value": 17832,
        "NOCCode": "9211",
        "JobTitle": "metal rolling foreman/woman"
    },
    {
        "value": 17833,
        "NOCCode": "9211",
        "JobTitle": "metal smelting, converting and refining foreman/woman"
    },
    {
        "value": 17834,
        "NOCCode": "9211",
        "JobTitle": "metal-extruding and metal-drawing foreman/woman"
    },
    {
        "value": 17835,
        "NOCCode": "9211",
        "JobTitle": "mica processing foreman/woman"
    },
    {
        "value": 17836,
        "NOCCode": "9211",
        "JobTitle": "milling plant foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17837,
        "NOCCode": "9211",
        "JobTitle": "mixing and separating operations foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17838,
        "NOCCode": "9211",
        "JobTitle": "mixing plant foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17839,
        "NOCCode": "9211",
        "JobTitle": "moulding foreman/woman – foundry"
    },
    {
        "value": 17840,
        "NOCCode": "9211",
        "JobTitle": "ore milling foreman/woman"
    },
    {
        "value": 17841,
        "NOCCode": "9211",
        "JobTitle": "ore milling supervisor"
    },
    {
        "value": 17842,
        "NOCCode": "9211",
        "JobTitle": "oxygen furnace foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17843,
        "NOCCode": "9211",
        "JobTitle": "pot room foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17844,
        "NOCCode": "9211",
        "JobTitle": "pot room foreman/woman – primary metal processing"
    },
    {
        "value": 17845,
        "NOCCode": "9211",
        "JobTitle": "precast concrete working foreman/woman"
    },
    {
        "value": 17846,
        "NOCCode": "9211",
        "JobTitle": "primary metal and mineral products processing foreman/woman"
    },
    {
        "value": 17847,
        "NOCCode": "9211",
        "JobTitle": "primary metal and mineral products processing supervisor"
    },
    {
        "value": 17848,
        "NOCCode": "9211",
        "JobTitle": "quenching foreman/woman – mineral and metal processing"
    },
    {
        "value": 17849,
        "NOCCode": "9211",
        "JobTitle": "quenching foreman/woman – primary metal processing"
    },
    {
        "value": 17850,
        "NOCCode": "9211",
        "JobTitle": "reclamation foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17851,
        "NOCCode": "9211",
        "JobTitle": "reduction plant foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17852,
        "NOCCode": "9211",
        "JobTitle": "rock crusher foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17853,
        "NOCCode": "9211",
        "JobTitle": "rock crushing foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17854,
        "NOCCode": "9211",
        "JobTitle": "roll shop operations coach – steel mill"
    },
    {
        "value": 17855,
        "NOCCode": "9211",
        "JobTitle": "rolling foreman/woman – primary metal processing"
    },
    {
        "value": 17856,
        "NOCCode": "9211",
        "JobTitle": "rolling mill foreman/woman – primary metal processing"
    },
    {
        "value": 17857,
        "NOCCode": "9211",
        "JobTitle": "salt milling supervisor"
    },
    {
        "value": 17858,
        "NOCCode": "9211",
        "JobTitle": "scrap preparation foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17859,
        "NOCCode": "9211",
        "JobTitle": "scrap preparation foreman/woman – primary metal processing"
    },
    {
        "value": 17860,
        "NOCCode": "9211",
        "JobTitle": "shift co-ordinator, steel mill"
    },
    {
        "value": 17861,
        "NOCCode": "9211",
        "JobTitle": "sintering plant foreman/woman – mineral and metal processing"
    },
    {
        "value": 17862,
        "NOCCode": "9211",
        "JobTitle": "sintering plant foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17863,
        "NOCCode": "9211",
        "JobTitle": "smelter foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17864,
        "NOCCode": "9211",
        "JobTitle": "stone processing foreman/woman"
    },
    {
        "value": 17865,
        "NOCCode": "9211",
        "JobTitle": "stone products manufacturing foreman/woman"
    },
    {
        "value": 17866,
        "NOCCode": "9211",
        "JobTitle": "supervisor, cement manufacturing"
    },
    {
        "value": 17867,
        "NOCCode": "9211",
        "JobTitle": "supervisor, cement processing"
    },
    {
        "value": 17868,
        "NOCCode": "9211",
        "JobTitle": "supervisor, clay, glass and stone furnace and kiln operators"
    },
    {
        "value": 17869,
        "NOCCode": "9211",
        "JobTitle": "supervisor, crushing and grinding – primary metal and mineral products"
    },
    {
        "value": 17870,
        "NOCCode": "9211",
        "JobTitle": "supervisor, die casting"
    },
    {
        "value": 17871,
        "NOCCode": "9211",
        "JobTitle": "supervisor, foundry"
    },
    {
        "value": 17872,
        "NOCCode": "9211",
        "JobTitle": "supervisor, glass cutters"
    },
    {
        "value": 17873,
        "NOCCode": "9211",
        "JobTitle": "supervisor, melting and roasting department"
    },
    {
        "value": 17874,
        "NOCCode": "9211",
        "JobTitle": "supervisor, ore milling"
    },
    {
        "value": 17875,
        "NOCCode": "9211",
        "JobTitle": "supervisor, primary metal and mineral products processing"
    },
    {
        "value": 17876,
        "NOCCode": "9211",
        "JobTitle": "supervisor, salt milling"
    },
    {
        "value": 17877,
        "NOCCode": "9211",
        "JobTitle": "tankhouse foreman/woman – primary metal and mineral products processing"
    },
    {
        "value": 17878,
        "NOCCode": "9211",
        "JobTitle": "tapper operations foreman/woman – mineral and metal processing"
    },
    {
        "value": 17879,
        "NOCCode": "9211",
        "JobTitle": "tempering foreman/woman – primary metal processing"
    },
    {
        "value": 17880,
        "NOCCode": "9211",
        "JobTitle": "uranium processing foreman/woman"
    },
    {
        "value": 17881,
        "NOCCode": "9211",
        "JobTitle": "wire drawing foreman/woman – primary metal processing"
    },
    {
        "value": 17882,
        "NOCCode": "9211",
        "JobTitle": "yard foreman/woman, scrap metal"
    },
    {
        "value": 17883,
        "NOCCode": "9212",
        "JobTitle": "by-products extraction foreman/woman – chemical processing"
    },
    {
        "value": 17884,
        "NOCCode": "9212",
        "JobTitle": "chemical processing plant foreman/woman"
    },
    {
        "value": 17885,
        "NOCCode": "9212",
        "JobTitle": "chemical processing supervisor"
    },
    {
        "value": 17886,
        "NOCCode": "9212",
        "JobTitle": "chief operator, hydro-electric station"
    },
    {
        "value": 17887,
        "NOCCode": "9212",
        "JobTitle": "chief operator, water treatment"
    },
    {
        "value": 17888,
        "NOCCode": "9212",
        "JobTitle": "chief stationary engineer"
    },
    {
        "value": 17889,
        "NOCCode": "9212",
        "JobTitle": "chief substation operator"
    },
    {
        "value": 17890,
        "NOCCode": "9212",
        "JobTitle": "chlorine plant foreman/woman"
    },
    {
        "value": 17891,
        "NOCCode": "9212",
        "JobTitle": "compost facility supervisor"
    },
    {
        "value": 17892,
        "NOCCode": "9212",
        "JobTitle": "compressor station foreman/woman"
    },
    {
        "value": 17893,
        "NOCCode": "9212",
        "JobTitle": "continuous process foreman/woman – chemical processing"
    },
    {
        "value": 17894,
        "NOCCode": "9212",
        "JobTitle": "distillation foreman/woman – chemical processing"
    },
    {
        "value": 17895,
        "NOCCode": "9212",
        "JobTitle": "evaporator foreman/woman – chemical processing"
    },
    {
        "value": 17896,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman – petroleum, gas and chemical processing"
    },
    {
        "value": 17897,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, chemical"
    },
    {
        "value": 17898,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, chemical processing plant"
    },
    {
        "value": 17899,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, coating and calendering workers – chemical processing"
    },
    {
        "value": 17900,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, cosmetics processing"
    },
    {
        "value": 17901,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, crushing and grinding workers – chemicals and related materials"
    },
    {
        "value": 17902,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, detergents and removers manufacturing"
    },
    {
        "value": 17903,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, evaporator – chemical processing"
    },
    {
        "value": 17904,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, gas distribution"
    },
    {
        "value": 17905,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, gas plant"
    },
    {
        "value": 17906,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, grinding and packing – chemical processing"
    },
    {
        "value": 17907,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, heating plant"
    },
    {
        "value": 17908,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, incinerator"
    },
    {
        "value": 17909,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, ink preparation – chemical processing"
    },
    {
        "value": 17910,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, natural gas plant"
    },
    {
        "value": 17911,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, oven – chemical processing"
    },
    {
        "value": 17912,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, petroleum processing"
    },
    {
        "value": 17913,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, pharmaceuticals compounding"
    },
    {
        "value": 17914,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, pilot plant"
    },
    {
        "value": 17915,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, pipeline operations"
    },
    {
        "value": 17916,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, power engineers"
    },
    {
        "value": 17917,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, power plant"
    },
    {
        "value": 17918,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, power station operators"
    },
    {
        "value": 17919,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, pumping and blending – chemical processing"
    },
    {
        "value": 17920,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, pumping and pipeline equipment operators"
    },
    {
        "value": 17921,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, refinery unit"
    },
    {
        "value": 17922,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, sanitary service"
    },
    {
        "value": 17923,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, sewage disposal"
    },
    {
        "value": 17924,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, sewer services"
    },
    {
        "value": 17925,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, steam plant"
    },
    {
        "value": 17926,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, viscose filtration – chemical processing"
    },
    {
        "value": 17927,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, water and sanitation-utilities equipment operating occupations"
    },
    {
        "value": 17928,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, water purification plant"
    },
    {
        "value": 17929,
        "NOCCode": "9212",
        "JobTitle": "foreman/woman, water treatment plant"
    },
    {
        "value": 17930,
        "NOCCode": "9212",
        "JobTitle": "gas distribution foreman/woman"
    },
    {
        "value": 17931,
        "NOCCode": "9212",
        "JobTitle": "gas field supervisor"
    },
    {
        "value": 17932,
        "NOCCode": "9212",
        "JobTitle": "gas plant foreman/woman"
    },
    {
        "value": 17933,
        "NOCCode": "9212",
        "JobTitle": "gas transmission supervisor"
    },
    {
        "value": 17934,
        "NOCCode": "9212",
        "JobTitle": "grinding and packing foreman/woman – chemical processing"
    },
    {
        "value": 17935,
        "NOCCode": "9212",
        "JobTitle": "head refrigerating engineer"
    },
    {
        "value": 17936,
        "NOCCode": "9212",
        "JobTitle": "hydro-electric station chief operator"
    },
    {
        "value": 17937,
        "NOCCode": "9212",
        "JobTitle": "incinerator foreman/woman"
    },
    {
        "value": 17938,
        "NOCCode": "9212",
        "JobTitle": "landfill supervisor"
    },
    {
        "value": 17939,
        "NOCCode": "9212",
        "JobTitle": "lead collections operator – water treatment"
    },
    {
        "value": 17940,
        "NOCCode": "9212",
        "JobTitle": "lead operator, collections – water treatment"
    },
    {
        "value": 17941,
        "NOCCode": "9212",
        "JobTitle": "lead operator, water treatment"
    },
    {
        "value": 17942,
        "NOCCode": "9212",
        "JobTitle": "oven foreman/woman – chemical processing"
    },
    {
        "value": 17943,
        "NOCCode": "9212",
        "JobTitle": "paint making foreman/woman"
    },
    {
        "value": 17944,
        "NOCCode": "9212",
        "JobTitle": "paint processing foreman/woman"
    },
    {
        "value": 17945,
        "NOCCode": "9212",
        "JobTitle": "petroleum processing foreman/woman"
    },
    {
        "value": 17946,
        "NOCCode": "9212",
        "JobTitle": "petroleum processing shift supervisor"
    },
    {
        "value": 17947,
        "NOCCode": "9212",
        "JobTitle": "pharmaceutical compounding foreman/woman"
    },
    {
        "value": 17948,
        "NOCCode": "9212",
        "JobTitle": "pilot plant foreman/woman – chemical processing"
    },
    {
        "value": 17949,
        "NOCCode": "9212",
        "JobTitle": "pipeline foreman/woman"
    },
    {
        "value": 17950,
        "NOCCode": "9212",
        "JobTitle": "pipeline operations supervisor"
    },
    {
        "value": 17951,
        "NOCCode": "9212",
        "JobTitle": "plant foreman/woman, chemical processing"
    },
    {
        "value": 17952,
        "NOCCode": "9212",
        "JobTitle": "plant superintendent, water treatment"
    },
    {
        "value": 17953,
        "NOCCode": "9212",
        "JobTitle": "plant supervisor, water treatment"
    },
    {
        "value": 17954,
        "NOCCode": "9212",
        "JobTitle": "power engineer foreman/woman"
    },
    {
        "value": 17955,
        "NOCCode": "9212",
        "JobTitle": "power plant chief substation operator"
    },
    {
        "value": 17956,
        "NOCCode": "9212",
        "JobTitle": "power plant foreman/woman"
    },
    {
        "value": 17957,
        "NOCCode": "9212",
        "JobTitle": "power station operators foreman/woman"
    },
    {
        "value": 17958,
        "NOCCode": "9212",
        "JobTitle": "power station shift supervisor"
    },
    {
        "value": 17959,
        "NOCCode": "9212",
        "JobTitle": "production supervisor, pharmaceuticals"
    },
    {
        "value": 17960,
        "NOCCode": "9212",
        "JobTitle": "production supervisor, specialty chemicals"
    },
    {
        "value": 17961,
        "NOCCode": "9212",
        "JobTitle": "pumping and blending foreman/woman – chemical processing"
    },
    {
        "value": 17962,
        "NOCCode": "9212",
        "JobTitle": "pumping and pipeline equipment operators foreman/woman"
    },
    {
        "value": 17963,
        "NOCCode": "9212",
        "JobTitle": "pumping station foreman/woman"
    },
    {
        "value": 17964,
        "NOCCode": "9212",
        "JobTitle": "refinery unit foreman/woman"
    },
    {
        "value": 17965,
        "NOCCode": "9212",
        "JobTitle": "refrigeration plant foreman/woman"
    },
    {
        "value": 17966,
        "NOCCode": "9212",
        "JobTitle": "sewage disposal foreman/woman"
    },
    {
        "value": 17967,
        "NOCCode": "9212",
        "JobTitle": "sewer services foreman/woman"
    },
    {
        "value": 17968,
        "NOCCode": "9212",
        "JobTitle": "shift supervisor, petroleum processing"
    },
    {
        "value": 17969,
        "NOCCode": "9212",
        "JobTitle": "shift supervisor, power station"
    },
    {
        "value": 17970,
        "NOCCode": "9212",
        "JobTitle": "stationary engineer foreman/woman"
    },
    {
        "value": 17971,
        "NOCCode": "9212",
        "JobTitle": "stationary engineers supervisor"
    },
    {
        "value": 17972,
        "NOCCode": "9212",
        "JobTitle": "steam engineer leader"
    },
    {
        "value": 17973,
        "NOCCode": "9212",
        "JobTitle": "steam engineers foreman/woman"
    },
    {
        "value": 17974,
        "NOCCode": "9212",
        "JobTitle": "steam plant engineers foreman/woman"
    },
    {
        "value": 17975,
        "NOCCode": "9212",
        "JobTitle": "steam plant foreman/woman"
    },
    {
        "value": 17976,
        "NOCCode": "9212",
        "JobTitle": "superintendent, water treatment plant"
    },
    {
        "value": 17977,
        "NOCCode": "9212",
        "JobTitle": "supervisor, chemical processing"
    },
    {
        "value": 17978,
        "NOCCode": "9212",
        "JobTitle": "supervisor, gas field"
    },
    {
        "value": 17979,
        "NOCCode": "9212",
        "JobTitle": "supervisor, gas transmission"
    },
    {
        "value": 17980,
        "NOCCode": "9212",
        "JobTitle": "supervisor, petroleum refining"
    },
    {
        "value": 17981,
        "NOCCode": "9212",
        "JobTitle": "supervisor, pharmaceuticals production"
    },
    {
        "value": 17982,
        "NOCCode": "9212",
        "JobTitle": "supervisor, pipeline operation"
    },
    {
        "value": 17983,
        "NOCCode": "9212",
        "JobTitle": "supervisor, power station"
    },
    {
        "value": 17984,
        "NOCCode": "9212",
        "JobTitle": "supervisor, sewage treatment plant"
    },
    {
        "value": 17985,
        "NOCCode": "9212",
        "JobTitle": "supervisor, specialty chemicals production"
    },
    {
        "value": 17986,
        "NOCCode": "9212",
        "JobTitle": "supervisor, stationary engineers"
    },
    {
        "value": 17987,
        "NOCCode": "9212",
        "JobTitle": "supervisor, water treatment plant"
    },
    {
        "value": 17988,
        "NOCCode": "9212",
        "JobTitle": "viscose filtration foreman/woman – chemical processing"
    },
    {
        "value": 17989,
        "NOCCode": "9212",
        "JobTitle": "waste treatment supervisor"
    },
    {
        "value": 17990,
        "NOCCode": "9212",
        "JobTitle": "water and sewer foreman/woman"
    },
    {
        "value": 17991,
        "NOCCode": "9212",
        "JobTitle": "water filtration plant supervisor"
    },
    {
        "value": 17992,
        "NOCCode": "9212",
        "JobTitle": "water pollution control foreman/woman"
    },
    {
        "value": 17993,
        "NOCCode": "9212",
        "JobTitle": "water treatment chief operator"
    },
    {
        "value": 17994,
        "NOCCode": "9212",
        "JobTitle": "water treatment lead operator"
    },
    {
        "value": 17995,
        "NOCCode": "9212",
        "JobTitle": "water treatment operations supervisor"
    },
    {
        "value": 17996,
        "NOCCode": "9212",
        "JobTitle": "water treatment plant foreman/woman"
    },
    {
        "value": 17997,
        "NOCCode": "9212",
        "JobTitle": "water treatment plant superintendent"
    },
    {
        "value": 17998,
        "NOCCode": "9212",
        "JobTitle": "water treatment plant supervisor"
    },
    {
        "value": 17999,
        "NOCCode": "9213",
        "JobTitle": "abattoir supervisor"
    },
    {
        "value": 18000,
        "NOCCode": "9213",
        "JobTitle": "bakery foreman/woman – food and beverage processing"
    },
    {
        "value": 18001,
        "NOCCode": "9213",
        "JobTitle": "bakery plant supervisor"
    },
    {
        "value": 18002,
        "NOCCode": "9213",
        "JobTitle": "baking and confectionery making foreman/woman"
    },
    {
        "value": 18003,
        "NOCCode": "9213",
        "JobTitle": "baking foreman/woman – food and beverage processing"
    },
    {
        "value": 18004,
        "NOCCode": "9213",
        "JobTitle": "beef dressing foreman/woman"
    },
    {
        "value": 18005,
        "NOCCode": "9213",
        "JobTitle": "bottling foreman/woman"
    },
    {
        "value": 18006,
        "NOCCode": "9213",
        "JobTitle": "bottling supervisor – food and beverage processing"
    },
    {
        "value": 18007,
        "NOCCode": "9213",
        "JobTitle": "brewer supervisor"
    },
    {
        "value": 18008,
        "NOCCode": "9213",
        "JobTitle": "brewery supervisor"
    },
    {
        "value": 18009,
        "NOCCode": "9213",
        "JobTitle": "cannery foreman/woman – food processing"
    },
    {
        "value": 18010,
        "NOCCode": "9213",
        "JobTitle": "cellar master"
    },
    {
        "value": 18011,
        "NOCCode": "9213",
        "JobTitle": "chocolate factory foreman/woman"
    },
    {
        "value": 18012,
        "NOCCode": "9213",
        "JobTitle": "chocolate refining foreman/woman"
    },
    {
        "value": 18013,
        "NOCCode": "9213",
        "JobTitle": "coffee roasting foreman/woman"
    },
    {
        "value": 18014,
        "NOCCode": "9213",
        "JobTitle": "confectionery foreman/woman"
    },
    {
        "value": 18015,
        "NOCCode": "9213",
        "JobTitle": "curing room foreman/woman – tobacco processing"
    },
    {
        "value": 18016,
        "NOCCode": "9213",
        "JobTitle": "dairy plant foreman/woman"
    },
    {
        "value": 18017,
        "NOCCode": "9213",
        "JobTitle": "dairy products processing foreman/woman"
    },
    {
        "value": 18018,
        "NOCCode": "9213",
        "JobTitle": "distillery foreman/woman"
    },
    {
        "value": 18019,
        "NOCCode": "9213",
        "JobTitle": "dry-starch foreman/woman – food processing"
    },
    {
        "value": 18020,
        "NOCCode": "9213",
        "JobTitle": "feed milling foreman/woman"
    },
    {
        "value": 18021,
        "NOCCode": "9213",
        "JobTitle": "fish canning, curing and packing workers foreman/woman"
    },
    {
        "value": 18022,
        "NOCCode": "9213",
        "JobTitle": "fish curing and canning foreman/woman"
    },
    {
        "value": 18023,
        "NOCCode": "9213",
        "JobTitle": "fish freezing foreman/woman"
    },
    {
        "value": 18024,
        "NOCCode": "9213",
        "JobTitle": "fish freezing supervisor"
    },
    {
        "value": 18025,
        "NOCCode": "9213",
        "JobTitle": "fish preparation foreman/woman"
    },
    {
        "value": 18026,
        "NOCCode": "9213",
        "JobTitle": "fish reduction foreman/woman"
    },
    {
        "value": 18027,
        "NOCCode": "9213",
        "JobTitle": "fish-freezing and storage foreman/woman"
    },
    {
        "value": 18028,
        "NOCCode": "9213",
        "JobTitle": "flour mill foreman/woman"
    },
    {
        "value": 18029,
        "NOCCode": "9213",
        "JobTitle": "flour-and-grain mill foreman/woman"
    },
    {
        "value": 18030,
        "NOCCode": "9213",
        "JobTitle": "food and beverage processing foreman/woman"
    },
    {
        "value": 18031,
        "NOCCode": "9213",
        "JobTitle": "food and beverage processing supervisor"
    },
    {
        "value": 18032,
        "NOCCode": "9213",
        "JobTitle": "food testing foreman/woman – food and beverage processing"
    },
    {
        "value": 18033,
        "NOCCode": "9213",
        "JobTitle": "food testing supervisor"
    },
    {
        "value": 18034,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, abattoir"
    },
    {
        "value": 18035,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, bakery – food and beverage processing"
    },
    {
        "value": 18036,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, baking – food and beverage processing"
    },
    {
        "value": 18037,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, baking and confectionery making"
    },
    {
        "value": 18038,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, beef boning and cutting"
    },
    {
        "value": 18039,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, beef dressing"
    },
    {
        "value": 18040,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, boiling house – food and beverage processing"
    },
    {
        "value": 18041,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, bottling – food and beverage processing"
    },
    {
        "value": 18042,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, cannery – food processing"
    },
    {
        "value": 18043,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, char house – food and beverage processing"
    },
    {
        "value": 18044,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, chocolate candies processing"
    },
    {
        "value": 18045,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, chocolate refining"
    },
    {
        "value": 18046,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, cigarette inspectors"
    },
    {
        "value": 18047,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, cigarettes graders"
    },
    {
        "value": 18048,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, coffee roasting"
    },
    {
        "value": 18049,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, confectionery"
    },
    {
        "value": 18050,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, curing room – tobacco processing"
    },
    {
        "value": 18051,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, dairy products processing"
    },
    {
        "value": 18052,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, distilling"
    },
    {
        "value": 18053,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, dry starch – food processing"
    },
    {
        "value": 18054,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, feed milling"
    },
    {
        "value": 18055,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, fish canning, curing and packing workers"
    },
    {
        "value": 18056,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, fish freezing"
    },
    {
        "value": 18057,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, fish freezing and storage"
    },
    {
        "value": 18058,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, flour mill"
    },
    {
        "value": 18059,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, food and beverage processing"
    },
    {
        "value": 18060,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, fruit grading"
    },
    {
        "value": 18061,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, fruit-and-vegetable canning, preserving and packing workers"
    },
    {
        "value": 18062,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, ham curing"
    },
    {
        "value": 18063,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, hide curing – food and beverage processing"
    },
    {
        "value": 18064,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, ice cream making"
    },
    {
        "value": 18065,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, jam and jelly processing"
    },
    {
        "value": 18066,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, meat cutting plant"
    },
    {
        "value": 18067,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, milk production"
    },
    {
        "value": 18068,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, pan house – food and beverage processing"
    },
    {
        "value": 18069,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, poultry eviscerating"
    },
    {
        "value": 18070,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, poultry graders"
    },
    {
        "value": 18071,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, poultry preparers"
    },
    {
        "value": 18072,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, rendering plant workers"
    },
    {
        "value": 18073,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, slaughtering and meat packing workers"
    },
    {
        "value": 18074,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, sugar filter house"
    },
    {
        "value": 18075,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, sugar processing workers"
    },
    {
        "value": 18076,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, sugar refining"
    },
    {
        "value": 18077,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, tea blending plant"
    },
    {
        "value": 18078,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, testing and grading – food, beverage and tobacco processing"
    },
    {
        "value": 18079,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, testing, grading and sampling – food and beverage processing"
    },
    {
        "value": 18080,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, threshing department – tobacco processing"
    },
    {
        "value": 18081,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, tipping department – tobacco processing"
    },
    {
        "value": 18082,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, tobacco curing room"
    },
    {
        "value": 18083,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, tobacco processing"
    },
    {
        "value": 18084,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, tobacco-processing occupations"
    },
    {
        "value": 18085,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, wash house – food and beverage processing"
    },
    {
        "value": 18086,
        "NOCCode": "9213",
        "JobTitle": "foreman/woman, wet starch – food and beverage processing"
    },
    {
        "value": 18087,
        "NOCCode": "9213",
        "JobTitle": "fruit grading foreman/woman"
    },
    {
        "value": 18088,
        "NOCCode": "9213",
        "JobTitle": "fruit-and-vegetable canning, preserving and packing workers foreman/woman"
    },
    {
        "value": 18089,
        "NOCCode": "9213",
        "JobTitle": "grain-milling team supervisor"
    },
    {
        "value": 18090,
        "NOCCode": "9213",
        "JobTitle": "HACCP (hazard analysis critical control point) co-ordinator – food processing"
    },
    {
        "value": 18091,
        "NOCCode": "9213",
        "JobTitle": "HACCP co-ordinator"
    },
    {
        "value": 18092,
        "NOCCode": "9213",
        "JobTitle": "HACCP co-ordinator – food processing"
    },
    {
        "value": 18093,
        "NOCCode": "9213",
        "JobTitle": "ham curing foreman/woman"
    },
    {
        "value": 18094,
        "NOCCode": "9213",
        "JobTitle": "hazard analysis critical control point (HACCP) co-ordinator"
    },
    {
        "value": 18095,
        "NOCCode": "9213",
        "JobTitle": "head miller, feed-and-flour mill"
    },
    {
        "value": 18096,
        "NOCCode": "9213",
        "JobTitle": "head mixer – bakery"
    },
    {
        "value": 18097,
        "NOCCode": "9213",
        "JobTitle": "hide curing foreman/woman – food and beverage processing"
    },
    {
        "value": 18098,
        "NOCCode": "9213",
        "JobTitle": "ice cream making foreman/woman"
    },
    {
        "value": 18099,
        "NOCCode": "9213",
        "JobTitle": "ice cream processing assistant supervisor"
    },
    {
        "value": 18100,
        "NOCCode": "9213",
        "JobTitle": "jam and jelly processing foreman/woman"
    },
    {
        "value": 18101,
        "NOCCode": "9213",
        "JobTitle": "meat cutting plant foreman/woman"
    },
    {
        "value": 18102,
        "NOCCode": "9213",
        "JobTitle": "milk processing foreman/woman"
    },
    {
        "value": 18103,
        "NOCCode": "9213",
        "JobTitle": "milk processing plant foreman/woman"
    },
    {
        "value": 18104,
        "NOCCode": "9213",
        "JobTitle": "packaging supervisor – food and beverage processing"
    },
    {
        "value": 18105,
        "NOCCode": "9213",
        "JobTitle": "poultry eviscerating foreman/woman"
    },
    {
        "value": 18106,
        "NOCCode": "9213",
        "JobTitle": "poultry graders supervisor"
    },
    {
        "value": 18107,
        "NOCCode": "9213",
        "JobTitle": "poultry preparers foreman/woman"
    },
    {
        "value": 18108,
        "NOCCode": "9213",
        "JobTitle": "production supervisor – food and beverage processing"
    },
    {
        "value": 18109,
        "NOCCode": "9213",
        "JobTitle": "rendering plant workers foreman/woman – meat packing"
    },
    {
        "value": 18110,
        "NOCCode": "9213",
        "JobTitle": "slaughterhouse foreman/woman"
    },
    {
        "value": 18111,
        "NOCCode": "9213",
        "JobTitle": "slaughtering and meat packing workers foreman/woman"
    },
    {
        "value": 18112,
        "NOCCode": "9213",
        "JobTitle": "sugar filter house foreman/woman"
    },
    {
        "value": 18113,
        "NOCCode": "9213",
        "JobTitle": "sugar processing workers foreman/woman"
    },
    {
        "value": 18114,
        "NOCCode": "9213",
        "JobTitle": "sugar refining foreman/woman"
    },
    {
        "value": 18115,
        "NOCCode": "9213",
        "JobTitle": "supervisor – meat packing"
    },
    {
        "value": 18116,
        "NOCCode": "9213",
        "JobTitle": "supervisor – vegetable packing"
    },
    {
        "value": 18117,
        "NOCCode": "9213",
        "JobTitle": "supervisor, abattoir"
    },
    {
        "value": 18118,
        "NOCCode": "9213",
        "JobTitle": "supervisor, bakery plant"
    },
    {
        "value": 18119,
        "NOCCode": "9213",
        "JobTitle": "supervisor, bottling"
    },
    {
        "value": 18120,
        "NOCCode": "9213",
        "JobTitle": "supervisor, brewery"
    },
    {
        "value": 18121,
        "NOCCode": "9213",
        "JobTitle": "supervisor, fish freezing"
    },
    {
        "value": 18122,
        "NOCCode": "9213",
        "JobTitle": "supervisor, fish processing"
    },
    {
        "value": 18123,
        "NOCCode": "9213",
        "JobTitle": "supervisor, food and beverage processing"
    },
    {
        "value": 18124,
        "NOCCode": "9213",
        "JobTitle": "supervisor, food product graders"
    },
    {
        "value": 18125,
        "NOCCode": "9213",
        "JobTitle": "supervisor, food product testers"
    },
    {
        "value": 18126,
        "NOCCode": "9213",
        "JobTitle": "supervisor, food testing"
    },
    {
        "value": 18127,
        "NOCCode": "9213",
        "JobTitle": "supervisor, meat packing"
    },
    {
        "value": 18128,
        "NOCCode": "9213",
        "JobTitle": "supervisor, packaging – food and beverage processing"
    },
    {
        "value": 18129,
        "NOCCode": "9213",
        "JobTitle": "supervisor, poultry graders"
    },
    {
        "value": 18130,
        "NOCCode": "9213",
        "JobTitle": "supervisor, production – food and beverage processing"
    },
    {
        "value": 18131,
        "NOCCode": "9213",
        "JobTitle": "supervisor, slaughterhouse"
    },
    {
        "value": 18132,
        "NOCCode": "9213",
        "JobTitle": "supervisor, tobacco processing"
    },
    {
        "value": 18133,
        "NOCCode": "9213",
        "JobTitle": "supervisor, tobacco-processing machine operators"
    },
    {
        "value": 18134,
        "NOCCode": "9213",
        "JobTitle": "supervisor, vegetable canning"
    },
    {
        "value": 18135,
        "NOCCode": "9213",
        "JobTitle": "supervisor, vegetable packing"
    },
    {
        "value": 18136,
        "NOCCode": "9213",
        "JobTitle": "tea blending plant foreman/woman"
    },
    {
        "value": 18137,
        "NOCCode": "9213",
        "JobTitle": "team supervisor, flour milling"
    },
    {
        "value": 18138,
        "NOCCode": "9213",
        "JobTitle": "testing and grading foreman/woman – food, beverage and tobacco processing"
    },
    {
        "value": 18139,
        "NOCCode": "9213",
        "JobTitle": "threshing department foreman/woman – tobacco processing"
    },
    {
        "value": 18140,
        "NOCCode": "9213",
        "JobTitle": "tipping department foreman/woman – tobacco processing"
    },
    {
        "value": 18141,
        "NOCCode": "9213",
        "JobTitle": "tobacco curing room foreman/woman"
    },
    {
        "value": 18142,
        "NOCCode": "9213",
        "JobTitle": "tobacco processing foreman/woman"
    },
    {
        "value": 18143,
        "NOCCode": "9213",
        "JobTitle": "tobacco-processing workers foreman/woman"
    },
    {
        "value": 18144,
        "NOCCode": "9213",
        "JobTitle": "tobacco-stemming room foreman/woman"
    },
    {
        "value": 18145,
        "NOCCode": "9213",
        "JobTitle": "wet-starch foreman/woman – food and beverage processing"
    },
    {
        "value": 18146,
        "NOCCode": "9213",
        "JobTitle": "winery cellar master"
    },
    {
        "value": 18147,
        "NOCCode": "9214",
        "JobTitle": "assemblers foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18148,
        "NOCCode": "9214",
        "JobTitle": "assemblers supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18149,
        "NOCCode": "9214",
        "JobTitle": "automobile tire builders foreman/woman"
    },
    {
        "value": 18150,
        "NOCCode": "9214",
        "JobTitle": "belt building foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18151,
        "NOCCode": "9214",
        "JobTitle": "blow moulding foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18152,
        "NOCCode": "9214",
        "JobTitle": "calendering foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18153,
        "NOCCode": "9214",
        "JobTitle": "compounding foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18154,
        "NOCCode": "9214",
        "JobTitle": "compression moulding foreman/woman – rubber and products manufacturing"
    },
    {
        "value": 18155,
        "NOCCode": "9214",
        "JobTitle": "conveyor belt foreman/woman – rubber manufacturing"
    },
    {
        "value": 18156,
        "NOCCode": "9214",
        "JobTitle": "curing foreman/woman – rubber manufacturing"
    },
    {
        "value": 18157,
        "NOCCode": "9214",
        "JobTitle": "curing supervisor – rubber products manufacturing"
    },
    {
        "value": 18158,
        "NOCCode": "9214",
        "JobTitle": "extruding supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18159,
        "NOCCode": "9214",
        "JobTitle": "extrusion supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18160,
        "NOCCode": "9214",
        "JobTitle": "fibreglass plastics foreman/woman"
    },
    {
        "value": 18161,
        "NOCCode": "9214",
        "JobTitle": "fibreglass plastics processing supervisor"
    },
    {
        "value": 18162,
        "NOCCode": "9214",
        "JobTitle": "finishing and trimming foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18163,
        "NOCCode": "9214",
        "JobTitle": "finishing and trimming supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18164,
        "NOCCode": "9214",
        "JobTitle": "finishing supervisor – plastic products manufacturing"
    },
    {
        "value": 18165,
        "NOCCode": "9214",
        "JobTitle": "finishing supervisor – rubber products manufacturing"
    },
    {
        "value": 18166,
        "NOCCode": "9214",
        "JobTitle": "foam products inspection foreman/woman – rubber manufacturing"
    },
    {
        "value": 18167,
        "NOCCode": "9214",
        "JobTitle": "foam products inspection supervisor – rubber products manufacturing"
    },
    {
        "value": 18168,
        "NOCCode": "9214",
        "JobTitle": "footwear inspection foreman/woman – rubber manufacturing"
    },
    {
        "value": 18169,
        "NOCCode": "9214",
        "JobTitle": "footwear inspection supervisor – rubber products manufacturing"
    },
    {
        "value": 18170,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, assemblers – rubber and plastic manufacturing"
    },
    {
        "value": 18171,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, belt building – rubber manufacturing"
    },
    {
        "value": 18172,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, blow moulding"
    },
    {
        "value": 18173,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, blow moulding – rubber and plastic manufacturing"
    },
    {
        "value": 18174,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, calendering – rubber and plastic manufacturing"
    },
    {
        "value": 18175,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, conveyor belt building – rubber manufacturing"
    },
    {
        "value": 18176,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, curing – rubber manufacturing"
    },
    {
        "value": 18177,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, extruding – rubber and plastic manufacturing"
    },
    {
        "value": 18178,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, extrusion – rubber and plastic manufacturing"
    },
    {
        "value": 18179,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, fibreglass plastics"
    },
    {
        "value": 18180,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, finishing – plastic manufacturing"
    },
    {
        "value": 18181,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, finishing – rubber manufacturing"
    },
    {
        "value": 18182,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, finishing and trimming – rubber and plastic manufacturing"
    },
    {
        "value": 18183,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, foam products inspection – rubber manufacturing"
    },
    {
        "value": 18184,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, footwear inspection – rubber manufacturing"
    },
    {
        "value": 18185,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, injection moulding – rubber and plastic manufacturing"
    },
    {
        "value": 18186,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, matting inspection – rubber manufacturing"
    },
    {
        "value": 18187,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, mixing – rubber and plastic manufacturing"
    },
    {
        "value": 18188,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, moulded rubber footwear"
    },
    {
        "value": 18189,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, moulding – rubber and plastic manufacturing"
    },
    {
        "value": 18190,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, paint department – plastic products"
    },
    {
        "value": 18191,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, paint department – rubber products"
    },
    {
        "value": 18192,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, plastic inspectors"
    },
    {
        "value": 18193,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, plastic products manufacturing"
    },
    {
        "value": 18194,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, plastics-processing shop"
    },
    {
        "value": 18195,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, retread tire inspection"
    },
    {
        "value": 18196,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, roll building – rubber manufacturing"
    },
    {
        "value": 18197,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, rubber goods dipping"
    },
    {
        "value": 18198,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, rubber hose building"
    },
    {
        "value": 18199,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, rubber hose inspection and testing"
    },
    {
        "value": 18200,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, rubber products inspectors"
    },
    {
        "value": 18201,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, rubber products manufacturing"
    },
    {
        "value": 18202,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, snowtrack – rubber manufacturing"
    },
    {
        "value": 18203,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, stock preparation – rubber manufacturing"
    },
    {
        "value": 18204,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, tire building"
    },
    {
        "value": 18205,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, tire finishing"
    },
    {
        "value": 18206,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, tire inspection"
    },
    {
        "value": 18207,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, tire retread"
    },
    {
        "value": 18208,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, tire stock preparation"
    },
    {
        "value": 18209,
        "NOCCode": "9214",
        "JobTitle": "foreman/woman, vulcanizing – rubber manufacturing"
    },
    {
        "value": 18210,
        "NOCCode": "9214",
        "JobTitle": "hose building foreman/woman – rubber manufacturing"
    },
    {
        "value": 18211,
        "NOCCode": "9214",
        "JobTitle": "injection moulding supervisor – plastic products manufacturing"
    },
    {
        "value": 18212,
        "NOCCode": "9214",
        "JobTitle": "inner tube building foreman/woman"
    },
    {
        "value": 18213,
        "NOCCode": "9214",
        "JobTitle": "matting inspection foreman/woman – rubber manufacturing"
    },
    {
        "value": 18214,
        "NOCCode": "9214",
        "JobTitle": "matting inspection supervisor – rubber products manufacturing"
    },
    {
        "value": 18215,
        "NOCCode": "9214",
        "JobTitle": "millroom foreman/woman – rubber products manufacturing"
    },
    {
        "value": 18216,
        "NOCCode": "9214",
        "JobTitle": "millroom supervisor – rubber products manufacturing"
    },
    {
        "value": 18217,
        "NOCCode": "9214",
        "JobTitle": "mixing supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18218,
        "NOCCode": "9214",
        "JobTitle": "moulded footwear inspection supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18219,
        "NOCCode": "9214",
        "JobTitle": "moulded rubber footwear foreman/woman"
    },
    {
        "value": 18220,
        "NOCCode": "9214",
        "JobTitle": "moulding foreman/woman – rubber and plastic manufacturing"
    },
    {
        "value": 18221,
        "NOCCode": "9214",
        "JobTitle": "moulding supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18222,
        "NOCCode": "9214",
        "JobTitle": "paint department supervisor – plastic products manufacturing"
    },
    {
        "value": 18223,
        "NOCCode": "9214",
        "JobTitle": "paint department supervisor – rubber products manufacturing"
    },
    {
        "value": 18224,
        "NOCCode": "9214",
        "JobTitle": "plastic products inspection supervisor"
    },
    {
        "value": 18225,
        "NOCCode": "9214",
        "JobTitle": "plastic products inspectors supervisor"
    },
    {
        "value": 18226,
        "NOCCode": "9214",
        "JobTitle": "plastics shop foreman/woman"
    },
    {
        "value": 18227,
        "NOCCode": "9214",
        "JobTitle": "plastics-processing shop supervisor"
    },
    {
        "value": 18228,
        "NOCCode": "9214",
        "JobTitle": "retread tire inspection foreman/woman"
    },
    {
        "value": 18229,
        "NOCCode": "9214",
        "JobTitle": "retread tire inspection supervisor – rubber products manufacturing"
    },
    {
        "value": 18230,
        "NOCCode": "9214",
        "JobTitle": "roll building foreman/woman – rubber manufacturing"
    },
    {
        "value": 18231,
        "NOCCode": "9214",
        "JobTitle": "roll building supervisor – rubber products manufacturing"
    },
    {
        "value": 18232,
        "NOCCode": "9214",
        "JobTitle": "rubber footwear moulding supervisor"
    },
    {
        "value": 18233,
        "NOCCode": "9214",
        "JobTitle": "rubber fuel tank foreman/woman"
    },
    {
        "value": 18234,
        "NOCCode": "9214",
        "JobTitle": "rubber gasket making foreman/woman"
    },
    {
        "value": 18235,
        "NOCCode": "9214",
        "JobTitle": "rubber goods dipping foreman/woman"
    },
    {
        "value": 18236,
        "NOCCode": "9214",
        "JobTitle": "rubber goods dipping supervisor"
    },
    {
        "value": 18237,
        "NOCCode": "9214",
        "JobTitle": "rubber hose building supervisor"
    },
    {
        "value": 18238,
        "NOCCode": "9214",
        "JobTitle": "rubber hose foreman/woman"
    },
    {
        "value": 18239,
        "NOCCode": "9214",
        "JobTitle": "rubber hose inspection and testing foreman/woman"
    },
    {
        "value": 18240,
        "NOCCode": "9214",
        "JobTitle": "rubber products inspection supervisor"
    },
    {
        "value": 18241,
        "NOCCode": "9214",
        "JobTitle": "rubber products inspectors supervisor"
    },
    {
        "value": 18242,
        "NOCCode": "9214",
        "JobTitle": "rubber products manufacturing supervisor"
    },
    {
        "value": 18243,
        "NOCCode": "9214",
        "JobTitle": "sampling supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18244,
        "NOCCode": "9214",
        "JobTitle": "snowtrack foreman/woman – rubber manufacturing"
    },
    {
        "value": 18245,
        "NOCCode": "9214",
        "JobTitle": "spray chamber supervisor – rubber and plastic products manufacturing"
    },
    {
        "value": 18246,
        "NOCCode": "9214",
        "JobTitle": "stock preparation foreman/woman – rubber manufacturing"
    },
    {
        "value": 18247,
        "NOCCode": "9214",
        "JobTitle": "supervisor, assemblers – rubber and plastic products manufacturing"
    },
    {
        "value": 18248,
        "NOCCode": "9214",
        "JobTitle": "supervisor, curing – rubber products manufacturing"
    },
    {
        "value": 18249,
        "NOCCode": "9214",
        "JobTitle": "supervisor, extruding – rubber and plastic products manufacturing"
    },
    {
        "value": 18250,
        "NOCCode": "9214",
        "JobTitle": "supervisor, extrusion – rubber and plastic products manufacturing"
    },
    {
        "value": 18251,
        "NOCCode": "9214",
        "JobTitle": "supervisor, finishing – plastic products manufacturing"
    },
    {
        "value": 18252,
        "NOCCode": "9214",
        "JobTitle": "supervisor, finishing – rubber products manufacturing"
    },
    {
        "value": 18253,
        "NOCCode": "9214",
        "JobTitle": "supervisor, finishing and trimming – rubber and plastic products manufacturing"
    },
    {
        "value": 18254,
        "NOCCode": "9214",
        "JobTitle": "supervisor, footwear inspection – rubber products manufacturing"
    },
    {
        "value": 18255,
        "NOCCode": "9214",
        "JobTitle": "supervisor, hose building – rubber products manufacturing"
    },
    {
        "value": 18256,
        "NOCCode": "9214",
        "JobTitle": "supervisor, injection moulding – plastic products manufacturing"
    },
    {
        "value": 18257,
        "NOCCode": "9214",
        "JobTitle": "supervisor, inner tube building"
    },
    {
        "value": 18258,
        "NOCCode": "9214",
        "JobTitle": "supervisor, matting inspection – rubber products manufacturing"
    },
    {
        "value": 18259,
        "NOCCode": "9214",
        "JobTitle": "supervisor, millroom – rubber products manufacturing"
    },
    {
        "value": 18260,
        "NOCCode": "9214",
        "JobTitle": "supervisor, mixing – rubber and plastic products manufacturing"
    },
    {
        "value": 18261,
        "NOCCode": "9214",
        "JobTitle": "supervisor, moulding – rubber and plastic products manufacturing"
    },
    {
        "value": 18262,
        "NOCCode": "9214",
        "JobTitle": "supervisor, paint department – plastic products manufacturing"
    },
    {
        "value": 18263,
        "NOCCode": "9214",
        "JobTitle": "supervisor, paint department – rubber products manufacturing"
    },
    {
        "value": 18264,
        "NOCCode": "9214",
        "JobTitle": "supervisor, plastic products inspection"
    },
    {
        "value": 18265,
        "NOCCode": "9214",
        "JobTitle": "supervisor, plastic products inspectors"
    },
    {
        "value": 18266,
        "NOCCode": "9214",
        "JobTitle": "supervisor, plastic products manufacturing"
    },
    {
        "value": 18267,
        "NOCCode": "9214",
        "JobTitle": "supervisor, plastics-processing shop"
    },
    {
        "value": 18268,
        "NOCCode": "9214",
        "JobTitle": "supervisor, quality control – rubber and plastic products manufacturing"
    },
    {
        "value": 18269,
        "NOCCode": "9214",
        "JobTitle": "supervisor, retread tire inspection – rubber products manufacturing"
    },
    {
        "value": 18270,
        "NOCCode": "9214",
        "JobTitle": "supervisor, roll building – rubber products manufacturing"
    },
    {
        "value": 18271,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber footwear moulding"
    },
    {
        "value": 18272,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber goods dipping"
    },
    {
        "value": 18273,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber hose building"
    },
    {
        "value": 18274,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber products inspection"
    },
    {
        "value": 18275,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber products inspectors"
    },
    {
        "value": 18276,
        "NOCCode": "9214",
        "JobTitle": "supervisor, rubber products manufacturing"
    },
    {
        "value": 18277,
        "NOCCode": "9214",
        "JobTitle": "supervisor, sampling – rubber and plastic products manufacturing"
    },
    {
        "value": 18278,
        "NOCCode": "9214",
        "JobTitle": "supervisor, spray chamber – rubber and plastic products manufacturing"
    },
    {
        "value": 18279,
        "NOCCode": "9214",
        "JobTitle": "supervisor, thermoforming – plastics products manufacturing"
    },
    {
        "value": 18280,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire building"
    },
    {
        "value": 18281,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire finishing"
    },
    {
        "value": 18282,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire inspection"
    },
    {
        "value": 18283,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire retreading"
    },
    {
        "value": 18284,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire stock preparation"
    },
    {
        "value": 18285,
        "NOCCode": "9214",
        "JobTitle": "supervisor, tire tube building"
    },
    {
        "value": 18286,
        "NOCCode": "9214",
        "JobTitle": "supervisor, V-belt building – rubber products manufacturing"
    },
    {
        "value": 18287,
        "NOCCode": "9214",
        "JobTitle": "supervisor, vulcanization – rubber products manufacturing"
    },
    {
        "value": 18288,
        "NOCCode": "9214",
        "JobTitle": "thermoforming foreman/woman – plastic manufacturing"
    },
    {
        "value": 18289,
        "NOCCode": "9214",
        "JobTitle": "thermoforming supervisor – plastic products manufacturing"
    },
    {
        "value": 18290,
        "NOCCode": "9214",
        "JobTitle": "tire building foreman/woman"
    },
    {
        "value": 18291,
        "NOCCode": "9214",
        "JobTitle": "tire building supervisor"
    },
    {
        "value": 18292,
        "NOCCode": "9214",
        "JobTitle": "tire finishing foreman/woman"
    },
    {
        "value": 18293,
        "NOCCode": "9214",
        "JobTitle": "tire finishing supervisor"
    },
    {
        "value": 18294,
        "NOCCode": "9214",
        "JobTitle": "tire inspection supervisor"
    },
    {
        "value": 18295,
        "NOCCode": "9214",
        "JobTitle": "tire retread foreman/woman"
    },
    {
        "value": 18296,
        "NOCCode": "9214",
        "JobTitle": "tire retreading supervisor"
    },
    {
        "value": 18297,
        "NOCCode": "9214",
        "JobTitle": "tire stock preparation foreman/woman"
    },
    {
        "value": 18298,
        "NOCCode": "9214",
        "JobTitle": "V-belt foreman/woman – rubber manufacturing"
    },
    {
        "value": 18299,
        "NOCCode": "9214",
        "JobTitle": "vulcanization supervisor – rubber products manufacturing"
    },
    {
        "value": 18300,
        "NOCCode": "9215",
        "JobTitle": "beater room foreman/woman – pulp and paper"
    },
    {
        "value": 18301,
        "NOCCode": "9215",
        "JobTitle": "bleach plant foreman/woman – pulp and paper"
    },
    {
        "value": 18302,
        "NOCCode": "9215",
        "JobTitle": "box making foreman/woman – paper converting"
    },
    {
        "value": 18303,
        "NOCCode": "9215",
        "JobTitle": "coating room foreman/woman – pulp and paper"
    },
    {
        "value": 18304,
        "NOCCode": "9215",
        "JobTitle": "core room foreman/woman – pulp and paper"
    },
    {
        "value": 18305,
        "NOCCode": "9215",
        "JobTitle": "coremaking foreman/woman – paper converting"
    },
    {
        "value": 18306,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, beater room – pulp and paper"
    },
    {
        "value": 18307,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, bleach plant – pulp and paper"
    },
    {
        "value": 18308,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, box making – paper converting"
    },
    {
        "value": 18309,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, coating room – pulp and paper"
    },
    {
        "value": 18310,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, core room – pulp and paper"
    },
    {
        "value": 18311,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, coremaking – paper converting"
    },
    {
        "value": 18312,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, lumber grading – wood processing"
    },
    {
        "value": 18313,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper – pulp and paper"
    },
    {
        "value": 18314,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper inspectors – pulp and paper"
    },
    {
        "value": 18315,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper machine – pulp and paper"
    },
    {
        "value": 18316,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper mill"
    },
    {
        "value": 18317,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper mill workers"
    },
    {
        "value": 18318,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, paper product fabricating and assembling"
    },
    {
        "value": 18319,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, papermaking and finishing"
    },
    {
        "value": 18320,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, particleboard making"
    },
    {
        "value": 18321,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, plywood inspection – wood processing"
    },
    {
        "value": 18322,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, plywood making"
    },
    {
        "value": 18323,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, pulp and paper mill"
    },
    {
        "value": 18324,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, pulp mill"
    },
    {
        "value": 18325,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, pulp processing – pulp and paper"
    },
    {
        "value": 18326,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, sawmill"
    },
    {
        "value": 18327,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, screen room – pulp and paper"
    },
    {
        "value": 18328,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, shingle mill"
    },
    {
        "value": 18329,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, shingle mill – wood processing"
    },
    {
        "value": 18330,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, tour – pulp and paper"
    },
    {
        "value": 18331,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, treating plant – wood processing"
    },
    {
        "value": 18332,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, veneer – wood processing"
    },
    {
        "value": 18333,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, veneer inspection – wood processing"
    },
    {
        "value": 18334,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, waferboard"
    },
    {
        "value": 18335,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, waferboard making – wood processing"
    },
    {
        "value": 18336,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, winding room – pulp and paper"
    },
    {
        "value": 18337,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, wood treating plant"
    },
    {
        "value": 18338,
        "NOCCode": "9215",
        "JobTitle": "foreman/woman, yard – pulp and paper"
    },
    {
        "value": 18339,
        "NOCCode": "9215",
        "JobTitle": "forest products processing supervisor"
    },
    {
        "value": 18340,
        "NOCCode": "9215",
        "JobTitle": "lumber grading foreman/woman – wood processing"
    },
    {
        "value": 18341,
        "NOCCode": "9215",
        "JobTitle": "moulded pulp products foreman/woman"
    },
    {
        "value": 18342,
        "NOCCode": "9215",
        "JobTitle": "operating team supervisor – pulp and paper"
    },
    {
        "value": 18343,
        "NOCCode": "9215",
        "JobTitle": "paper coating supervisor – pulp and paper"
    },
    {
        "value": 18344,
        "NOCCode": "9215",
        "JobTitle": "paper foreman/woman – pulp and paper"
    },
    {
        "value": 18345,
        "NOCCode": "9215",
        "JobTitle": "paper inspectors foreman/woman – pulp and paper"
    },
    {
        "value": 18346,
        "NOCCode": "9215",
        "JobTitle": "paper machine foreman/woman"
    },
    {
        "value": 18347,
        "NOCCode": "9215",
        "JobTitle": "paper machine foreman/woman – pulp and paper"
    },
    {
        "value": 18348,
        "NOCCode": "9215",
        "JobTitle": "paper mill foreman/woman"
    },
    {
        "value": 18349,
        "NOCCode": "9215",
        "JobTitle": "paper mill supervisor"
    },
    {
        "value": 18350,
        "NOCCode": "9215",
        "JobTitle": "paper mill workers foreman/woman"
    },
    {
        "value": 18351,
        "NOCCode": "9215",
        "JobTitle": "paper products makers foreman/woman – paper converting"
    },
    {
        "value": 18352,
        "NOCCode": "9215",
        "JobTitle": "particleboard foreman/woman"
    },
    {
        "value": 18353,
        "NOCCode": "9215",
        "JobTitle": "pulp and paper mill foreman/woman"
    },
    {
        "value": 18354,
        "NOCCode": "9215",
        "JobTitle": "pulp mill foreman/woman"
    },
    {
        "value": 18355,
        "NOCCode": "9215",
        "JobTitle": "pulp processing foreman/woman – pulp and paper"
    },
    {
        "value": 18356,
        "NOCCode": "9215",
        "JobTitle": "pulp testers foreman/woman"
    },
    {
        "value": 18357,
        "NOCCode": "9215",
        "JobTitle": "pulping foreman/woman – pulp and paper"
    },
    {
        "value": 18358,
        "NOCCode": "9215",
        "JobTitle": "sawmill foreman/woman"
    },
    {
        "value": 18359,
        "NOCCode": "9215",
        "JobTitle": "sawmill supervisor"
    },
    {
        "value": 18360,
        "NOCCode": "9215",
        "JobTitle": "screen room foreman/woman – pulp and paper"
    },
    {
        "value": 18361,
        "NOCCode": "9215",
        "JobTitle": "shake and shingle machine foreman/woman – sawmill"
    },
    {
        "value": 18362,
        "NOCCode": "9215",
        "JobTitle": "shift operating supervisor – pulp and paper"
    },
    {
        "value": 18363,
        "NOCCode": "9215",
        "JobTitle": "shingle mill supervisor – wood processing"
    },
    {
        "value": 18364,
        "NOCCode": "9215",
        "JobTitle": "supervisor, forest products processing"
    },
    {
        "value": 18365,
        "NOCCode": "9215",
        "JobTitle": "supervisor, paper converting"
    },
    {
        "value": 18366,
        "NOCCode": "9215",
        "JobTitle": "supervisor, paper mill"
    },
    {
        "value": 18367,
        "NOCCode": "9215",
        "JobTitle": "supervisor, sawmill"
    },
    {
        "value": 18368,
        "NOCCode": "9215",
        "JobTitle": "supervisor, shingle mill – wood processing"
    },
    {
        "value": 18369,
        "NOCCode": "9215",
        "JobTitle": "tour foreman/woman – pulp and paper"
    },
    {
        "value": 18370,
        "NOCCode": "9215",
        "JobTitle": "veneer foreman/woman – wood processing"
    },
    {
        "value": 18371,
        "NOCCode": "9215",
        "JobTitle": "waferboard making foreman/woman – wood processing"
    },
    {
        "value": 18372,
        "NOCCode": "9215",
        "JobTitle": "wood-treating machine foreman/woman"
    },
    {
        "value": 18373,
        "NOCCode": "9215",
        "JobTitle": "wood-treating plant foreman/woman"
    },
    {
        "value": 18374,
        "NOCCode": "9215",
        "JobTitle": "yard foreman/woman – pulp and paper"
    },
    {
        "value": 18375,
        "NOCCode": "9217",
        "JobTitle": "alterations supervisor – clothing manufacturing"
    },
    {
        "value": 18376,
        "NOCCode": "9217",
        "JobTitle": "assemblers supervisor – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18377,
        "NOCCode": "9217",
        "JobTitle": "beam department foreman/woman – hide and pelt processing"
    },
    {
        "value": 18378,
        "NOCCode": "9217",
        "JobTitle": "bleaching foreman/woman – textiles"
    },
    {
        "value": 18379,
        "NOCCode": "9217",
        "JobTitle": "boot and shoe foreman/woman"
    },
    {
        "value": 18380,
        "NOCCode": "9217",
        "JobTitle": "bootmakers and shoemakers foreman/woman"
    },
    {
        "value": 18381,
        "NOCCode": "9217",
        "JobTitle": "bootmakers foreman/woman"
    },
    {
        "value": 18382,
        "NOCCode": "9217",
        "JobTitle": "canvas products manufacturing foreman/woman"
    },
    {
        "value": 18383,
        "NOCCode": "9217",
        "JobTitle": "card room supervisor – textiles"
    },
    {
        "value": 18384,
        "NOCCode": "9217",
        "JobTitle": "clothing manufacturing foreman/woman"
    },
    {
        "value": 18385,
        "NOCCode": "9217",
        "JobTitle": "cutting department foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18386,
        "NOCCode": "9217",
        "JobTitle": "cutting department foreman/woman, fabric"
    },
    {
        "value": 18387,
        "NOCCode": "9217",
        "JobTitle": "cutting room foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18388,
        "NOCCode": "9217",
        "JobTitle": "dress repairs foreman/woman – clothing manufacturing"
    },
    {
        "value": 18389,
        "NOCCode": "9217",
        "JobTitle": "dressmakers supervisor"
    },
    {
        "value": 18390,
        "NOCCode": "9217",
        "JobTitle": "dressmaking department foreman/woman"
    },
    {
        "value": 18391,
        "NOCCode": "9217",
        "JobTitle": "dye foreman/woman – textiles"
    },
    {
        "value": 18392,
        "NOCCode": "9217",
        "JobTitle": "dye room supervisor"
    },
    {
        "value": 18393,
        "NOCCode": "9217",
        "JobTitle": "dye room supervisor – textiles"
    },
    {
        "value": 18394,
        "NOCCode": "9217",
        "JobTitle": "dyehouse foreman/woman – hide and pelt processing"
    },
    {
        "value": 18395,
        "NOCCode": "9217",
        "JobTitle": "dyehouse foreman/woman – textiles"
    },
    {
        "value": 18396,
        "NOCCode": "9217",
        "JobTitle": "dyehouse supervisor – textile processing"
    },
    {
        "value": 18397,
        "NOCCode": "9217",
        "JobTitle": "dyeing supervisor – textiles"
    },
    {
        "value": 18398,
        "NOCCode": "9217",
        "JobTitle": "embroidery supervisor – fabric products"
    },
    {
        "value": 18399,
        "NOCCode": "9217",
        "JobTitle": "embroidery supervisor, fabric products"
    },
    {
        "value": 18400,
        "NOCCode": "9217",
        "JobTitle": "fabric products embroidery supervisor"
    },
    {
        "value": 18401,
        "NOCCode": "9217",
        "JobTitle": "felt hat finishing room foreman/woman"
    },
    {
        "value": 18402,
        "NOCCode": "9217",
        "JobTitle": "finishing department foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18403,
        "NOCCode": "9217",
        "JobTitle": "finishing department foreman/woman – textile processing"
    },
    {
        "value": 18404,
        "NOCCode": "9217",
        "JobTitle": "finishing department supervisor – textile processing"
    },
    {
        "value": 18405,
        "NOCCode": "9217",
        "JobTitle": "finishing supervisor – textiles"
    },
    {
        "value": 18406,
        "NOCCode": "9217",
        "JobTitle": "footwear finishing foreman/woman"
    },
    {
        "value": 18407,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman – textile processing"
    },
    {
        "value": 18408,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, beam department – hide and pelt processing"
    },
    {
        "value": 18409,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, bleaching – textiles"
    },
    {
        "value": 18410,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, boot and shoe"
    },
    {
        "value": 18411,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, canvas products manufacturing"
    },
    {
        "value": 18412,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, clothing manufacturing"
    },
    {
        "value": 18413,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, dressmaking department"
    },
    {
        "value": 18414,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, dye department – textiles"
    },
    {
        "value": 18415,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, dye room – textile processing"
    },
    {
        "value": 18416,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, fabric-cutting department"
    },
    {
        "value": 18417,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, finishing department – textiles"
    },
    {
        "value": 18418,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, footwear manufacturing"
    },
    {
        "value": 18419,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, fur dressing – hide and pelt processing"
    },
    {
        "value": 18420,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, fur hatmaking department"
    },
    {
        "value": 18421,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, fur products manufacturing"
    },
    {
        "value": 18422,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, furriers"
    },
    {
        "value": 18423,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, garment manufacturing"
    },
    {
        "value": 18424,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, glove manufacturing"
    },
    {
        "value": 18425,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, handbag manufacturing"
    },
    {
        "value": 18426,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, hat and cap makers"
    },
    {
        "value": 18427,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, hat trimming department"
    },
    {
        "value": 18428,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, hide-house – hide and pelt processing"
    },
    {
        "value": 18429,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, hosiery"
    },
    {
        "value": 18430,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, inspection – textiles"
    },
    {
        "value": 18431,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, inspectors, graders, samplers and testers – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18432,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, knitting – textile processing"
    },
    {
        "value": 18433,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, layout and marking – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18434,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, leather finishing – hide and pelt processing"
    },
    {
        "value": 18435,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, leather products manufacturing"
    },
    {
        "value": 18436,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, luggage assembly – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18437,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, millinery – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18438,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, preparation department – textile processing"
    },
    {
        "value": 18439,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, production – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18440,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, production – fur products manufacturing"
    },
    {
        "value": 18441,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, quality control – textile processing"
    },
    {
        "value": 18442,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, sewing machine operators – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18443,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, spinning room – textile processing"
    },
    {
        "value": 18444,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, spun yarn preparation"
    },
    {
        "value": 18445,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, stitching department – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18446,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, suitmaking"
    },
    {
        "value": 18447,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, tannery – hide and pelt processing"
    },
    {
        "value": 18448,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, textile processing"
    },
    {
        "value": 18449,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, upholsterers – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18450,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, weaving"
    },
    {
        "value": 18451,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, weaving inspectors – textile processing"
    },
    {
        "value": 18452,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, weaving room – textile processing"
    },
    {
        "value": 18453,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, wool sorters and graders – textile processing"
    },
    {
        "value": 18454,
        "NOCCode": "9217",
        "JobTitle": "foreman/woman, wool sorting and grading"
    },
    {
        "value": 18455,
        "NOCCode": "9217",
        "JobTitle": "fur dressing foreman/woman"
    },
    {
        "value": 18456,
        "NOCCode": "9217",
        "JobTitle": "fur dressing foreman/woman – hide and pelt processing"
    },
    {
        "value": 18457,
        "NOCCode": "9217",
        "JobTitle": "fur hatmaking department foreman/woman"
    },
    {
        "value": 18458,
        "NOCCode": "9217",
        "JobTitle": "fur products manufacturing foreman/woman"
    },
    {
        "value": 18459,
        "NOCCode": "9217",
        "JobTitle": "furriers foreman/woman"
    },
    {
        "value": 18460,
        "NOCCode": "9217",
        "JobTitle": "furriers supervisor"
    },
    {
        "value": 18461,
        "NOCCode": "9217",
        "JobTitle": "garment manufacturing foreman/woman"
    },
    {
        "value": 18462,
        "NOCCode": "9217",
        "JobTitle": "glove makers foreman/woman"
    },
    {
        "value": 18463,
        "NOCCode": "9217",
        "JobTitle": "handbag manufacturing foreman/woman"
    },
    {
        "value": 18464,
        "NOCCode": "9217",
        "JobTitle": "hat and cap makers foreman/woman"
    },
    {
        "value": 18465,
        "NOCCode": "9217",
        "JobTitle": "hat trimming department foreman/woman"
    },
    {
        "value": 18466,
        "NOCCode": "9217",
        "JobTitle": "hide tanning supervisor"
    },
    {
        "value": 18467,
        "NOCCode": "9217",
        "JobTitle": "hosiery foreman/woman"
    },
    {
        "value": 18468,
        "NOCCode": "9217",
        "JobTitle": "inspection foreman/woman – textile processing"
    },
    {
        "value": 18469,
        "NOCCode": "9217",
        "JobTitle": "knitting foreman/woman"
    },
    {
        "value": 18470,
        "NOCCode": "9217",
        "JobTitle": "latex carpet-backing department foreman/woman"
    },
    {
        "value": 18471,
        "NOCCode": "9217",
        "JobTitle": "leather finishing foreman/woman – hide and pelt processing"
    },
    {
        "value": 18472,
        "NOCCode": "9217",
        "JobTitle": "leather products manufacturing foreman/woman"
    },
    {
        "value": 18473,
        "NOCCode": "9217",
        "JobTitle": "luggage assembly foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18474,
        "NOCCode": "9217",
        "JobTitle": "millinery foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18475,
        "NOCCode": "9217",
        "JobTitle": "preparation department foreman/woman – textiles"
    },
    {
        "value": 18476,
        "NOCCode": "9217",
        "JobTitle": "production foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18477,
        "NOCCode": "9217",
        "JobTitle": "production foreman/woman – fur products manufacturing"
    },
    {
        "value": 18478,
        "NOCCode": "9217",
        "JobTitle": "quality control foreman/woman – textiles"
    },
    {
        "value": 18479,
        "NOCCode": "9217",
        "JobTitle": "quilting foreman/woman – textile processing"
    },
    {
        "value": 18480,
        "NOCCode": "9217",
        "JobTitle": "sample room foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18481,
        "NOCCode": "9217",
        "JobTitle": "sample room foreman/woman, leather products"
    },
    {
        "value": 18482,
        "NOCCode": "9217",
        "JobTitle": "sewing machine operators foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18483,
        "NOCCode": "9217",
        "JobTitle": "sewing machine operators supervisor"
    },
    {
        "value": 18484,
        "NOCCode": "9217",
        "JobTitle": "spinning room foreman/woman – textiles"
    },
    {
        "value": 18485,
        "NOCCode": "9217",
        "JobTitle": "spinning supervisor – textiles"
    },
    {
        "value": 18486,
        "NOCCode": "9217",
        "JobTitle": "spun yarn preparation supervisor – textile processing"
    },
    {
        "value": 18487,
        "NOCCode": "9217",
        "JobTitle": "stitchers foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18488,
        "NOCCode": "9217",
        "JobTitle": "stitching department foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18489,
        "NOCCode": "9217",
        "JobTitle": "stitching department supervisor"
    },
    {
        "value": 18490,
        "NOCCode": "9217",
        "JobTitle": "supervisor, alterations – clothing manufacturing"
    },
    {
        "value": 18491,
        "NOCCode": "9217",
        "JobTitle": "supervisor, assemblers – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18492,
        "NOCCode": "9217",
        "JobTitle": "supervisor, card room – textiles"
    },
    {
        "value": 18493,
        "NOCCode": "9217",
        "JobTitle": "supervisor, cutters – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18494,
        "NOCCode": "9217",
        "JobTitle": "supervisor, dressmakers"
    },
    {
        "value": 18495,
        "NOCCode": "9217",
        "JobTitle": "supervisor, dressmaking department"
    },
    {
        "value": 18496,
        "NOCCode": "9217",
        "JobTitle": "supervisor, dye room – textiles processing"
    },
    {
        "value": 18497,
        "NOCCode": "9217",
        "JobTitle": "supervisor, dyehouse – textile processing"
    },
    {
        "value": 18498,
        "NOCCode": "9217",
        "JobTitle": "supervisor, dyeing – textiles"
    },
    {
        "value": 18499,
        "NOCCode": "9217",
        "JobTitle": "supervisor, fabric products embroidery"
    },
    {
        "value": 18500,
        "NOCCode": "9217",
        "JobTitle": "supervisor, finishing – textiles"
    },
    {
        "value": 18501,
        "NOCCode": "9217",
        "JobTitle": "supervisor, finishing department – textile processing"
    },
    {
        "value": 18502,
        "NOCCode": "9217",
        "JobTitle": "supervisor, fur dressing"
    },
    {
        "value": 18503,
        "NOCCode": "9217",
        "JobTitle": "supervisor, furriers"
    },
    {
        "value": 18504,
        "NOCCode": "9217",
        "JobTitle": "supervisor, hat dyers"
    },
    {
        "value": 18505,
        "NOCCode": "9217",
        "JobTitle": "supervisor, knitting"
    },
    {
        "value": 18506,
        "NOCCode": "9217",
        "JobTitle": "supervisor, knitting – textile processing"
    },
    {
        "value": 18507,
        "NOCCode": "9217",
        "JobTitle": "supervisor, sewing machine operators – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18508,
        "NOCCode": "9217",
        "JobTitle": "supervisor, spinning – textile processing"
    },
    {
        "value": 18509,
        "NOCCode": "9217",
        "JobTitle": "supervisor, stitching department – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18510,
        "NOCCode": "9217",
        "JobTitle": "supervisor, tannery – hide and pelt processing"
    },
    {
        "value": 18511,
        "NOCCode": "9217",
        "JobTitle": "supervisor, textile processing"
    },
    {
        "value": 18512,
        "NOCCode": "9217",
        "JobTitle": "supervisor, tufted-carpet weavers"
    },
    {
        "value": 18513,
        "NOCCode": "9217",
        "JobTitle": "supervisor, tufting – textile processing"
    },
    {
        "value": 18514,
        "NOCCode": "9217",
        "JobTitle": "supervisor, weaving"
    },
    {
        "value": 18515,
        "NOCCode": "9217",
        "JobTitle": "supervisor, yarn preparation – textiles"
    },
    {
        "value": 18516,
        "NOCCode": "9217",
        "JobTitle": "tannery foreman/woman"
    },
    {
        "value": 18517,
        "NOCCode": "9217",
        "JobTitle": "tannery foreman/woman – hide and pelt processing"
    },
    {
        "value": 18518,
        "NOCCode": "9217",
        "JobTitle": "tanyard foreman/woman – hide and pelt processing"
    },
    {
        "value": 18519,
        "NOCCode": "9217",
        "JobTitle": "textile processing foreman/woman"
    },
    {
        "value": 18520,
        "NOCCode": "9217",
        "JobTitle": "textile processing supervisor"
    },
    {
        "value": 18521,
        "NOCCode": "9217",
        "JobTitle": "tufting supervisor – textiles"
    },
    {
        "value": 18522,
        "NOCCode": "9217",
        "JobTitle": "twisting foreman/woman – textiles"
    },
    {
        "value": 18523,
        "NOCCode": "9217",
        "JobTitle": "upholsterers foreman/woman – fabric, fur and leather products manufacturing"
    },
    {
        "value": 18524,
        "NOCCode": "9217",
        "JobTitle": "upholstery-cutting and sewing foreman/woman"
    },
    {
        "value": 18525,
        "NOCCode": "9217",
        "JobTitle": "weave room foreman/woman – textile processing"
    },
    {
        "value": 18526,
        "NOCCode": "9217",
        "JobTitle": "weave room supervisor"
    },
    {
        "value": 18527,
        "NOCCode": "9217",
        "JobTitle": "weave room supervisor – textile processing"
    },
    {
        "value": 18528,
        "NOCCode": "9217",
        "JobTitle": "weaving foreman/woman"
    },
    {
        "value": 18529,
        "NOCCode": "9217",
        "JobTitle": "weaving room supervisor – textile processing"
    },
    {
        "value": 18530,
        "NOCCode": "9217",
        "JobTitle": "weaving room supervisor – textiles"
    },
    {
        "value": 18531,
        "NOCCode": "9217",
        "JobTitle": "weaving supervisor"
    },
    {
        "value": 18532,
        "NOCCode": "9217",
        "JobTitle": "woven fabric inspection foreman/woman – textile processing"
    },
    {
        "value": 18533,
        "NOCCode": "9221",
        "JobTitle": "area co-ordinator – motor vehicle assembly"
    },
    {
        "value": 18534,
        "NOCCode": "9221",
        "JobTitle": "area co-ordinator – motor vehicle manufacturing"
    },
    {
        "value": 18535,
        "NOCCode": "9221",
        "JobTitle": "assembly co-ordinator – motor vehicle manufacturing"
    },
    {
        "value": 18536,
        "NOCCode": "9221",
        "JobTitle": "assembly co-ordinator, motor vehicles"
    },
    {
        "value": 18537,
        "NOCCode": "9221",
        "JobTitle": "assembly foreman, automobiles"
    },
    {
        "value": 18538,
        "NOCCode": "9221",
        "JobTitle": "assembly foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18539,
        "NOCCode": "9221",
        "JobTitle": "assembly foreman/woman, motor vehicles"
    },
    {
        "value": 18540,
        "NOCCode": "9221",
        "JobTitle": "assembly supervisor, motor vehicles"
    },
    {
        "value": 18541,
        "NOCCode": "9221",
        "JobTitle": "assembly zone supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18542,
        "NOCCode": "9221",
        "JobTitle": "automobile assembly foreman/woman"
    },
    {
        "value": 18543,
        "NOCCode": "9221",
        "JobTitle": "body shop supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18544,
        "NOCCode": "9221",
        "JobTitle": "chassis assembly supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18545,
        "NOCCode": "9221",
        "JobTitle": "co-ordinator, assembly – motor vehicle manufacturing"
    },
    {
        "value": 18546,
        "NOCCode": "9221",
        "JobTitle": "engine dress supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18547,
        "NOCCode": "9221",
        "JobTitle": "final-assembly supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18548,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18549,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, assembly – motor vehicle manufacturing"
    },
    {
        "value": 18550,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, automobile assembly"
    },
    {
        "value": 18551,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, motor vehicle inspection and testing – motor vehicle manufacturing"
    },
    {
        "value": 18552,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, motor vehicle manufacturing"
    },
    {
        "value": 18553,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, paint department – motor vehicle manufacturing"
    },
    {
        "value": 18554,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, paint shop – motor vehicle manufacturing"
    },
    {
        "value": 18555,
        "NOCCode": "9221",
        "JobTitle": "foreman/woman, production line – motor vehicle manufacturing"
    },
    {
        "value": 18556,
        "NOCCode": "9221",
        "JobTitle": "general supervisor, assembly – motor vehicle manufacturing"
    },
    {
        "value": 18557,
        "NOCCode": "9221",
        "JobTitle": "general supervisor, motor vehicle assembly"
    },
    {
        "value": 18558,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle assembly co-ordinator"
    },
    {
        "value": 18559,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle assembly foreman/woman"
    },
    {
        "value": 18560,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle assembly general supervisor"
    },
    {
        "value": 18561,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle assembly supervisor"
    },
    {
        "value": 18562,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle inspection and testing foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18563,
        "NOCCode": "9221",
        "JobTitle": "motor vehicle manufacturing foreman/woman"
    },
    {
        "value": 18564,
        "NOCCode": "9221",
        "JobTitle": "paint department foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18565,
        "NOCCode": "9221",
        "JobTitle": "paint shop foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18566,
        "NOCCode": "9221",
        "JobTitle": "paint shop supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18567,
        "NOCCode": "9221",
        "JobTitle": "production line foreman/woman – motor vehicle manufacturing"
    },
    {
        "value": 18568,
        "NOCCode": "9221",
        "JobTitle": "supervisor, assembly – motor vehicle manufacturing"
    },
    {
        "value": 18569,
        "NOCCode": "9221",
        "JobTitle": "supervisor, body shop – motor vehicle manufacturing"
    },
    {
        "value": 18570,
        "NOCCode": "9221",
        "JobTitle": "supervisor, chassis assembly – motor vehicle manufacturing"
    },
    {
        "value": 18571,
        "NOCCode": "9221",
        "JobTitle": "supervisor, engine dress – motor vehicle manufacturing"
    },
    {
        "value": 18572,
        "NOCCode": "9221",
        "JobTitle": "supervisor, final assembly – motor vehicle manufacturing"
    },
    {
        "value": 18573,
        "NOCCode": "9221",
        "JobTitle": "supervisor, motor vehicle assembly"
    },
    {
        "value": 18574,
        "NOCCode": "9221",
        "JobTitle": "supervisor, paint shop – motor vehicle manufacturing"
    },
    {
        "value": 18575,
        "NOCCode": "9221",
        "JobTitle": "supervisor, trim and hardware – motor vehicle manufacturing"
    },
    {
        "value": 18576,
        "NOCCode": "9221",
        "JobTitle": "trim and hardware supervisor – motor vehicle manufacturing"
    },
    {
        "value": 18577,
        "NOCCode": "9221",
        "JobTitle": "zone supervisor, assembly – motor vehicle manufacturing"
    },
    {
        "value": 18578,
        "NOCCode": "9221",
        "JobTitle": "zone supervisor, motor vehicle assembly"
    },
    {
        "value": 18579,
        "NOCCode": "9222",
        "JobTitle": "assembly and test foreman/woman – electronics manufacturing"
    },
    {
        "value": 18580,
        "NOCCode": "9222",
        "JobTitle": "assembly and testing supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18581,
        "NOCCode": "9222",
        "JobTitle": "assembly foreman/woman, semiconductors"
    },
    {
        "value": 18582,
        "NOCCode": "9222",
        "JobTitle": "assembly supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18583,
        "NOCCode": "9222",
        "JobTitle": "business and commercial machine assembly supervisor"
    },
    {
        "value": 18584,
        "NOCCode": "9222",
        "JobTitle": "business and commercial machines assembly foreman/woman"
    },
    {
        "value": 18585,
        "NOCCode": "9222",
        "JobTitle": "coil winding foreman/woman – electronics manufacturing"
    },
    {
        "value": 18586,
        "NOCCode": "9222",
        "JobTitle": "communications equipment assembly foreman/woman – electronics manufacturing"
    },
    {
        "value": 18587,
        "NOCCode": "9222",
        "JobTitle": "communications equipment testing supervisor – electronics manufacturing"
    },
    {
        "value": 18588,
        "NOCCode": "9222",
        "JobTitle": "crystal processing foreman/woman – electronics manufacturing"
    },
    {
        "value": 18589,
        "NOCCode": "9222",
        "JobTitle": "electronic assembly supervisor"
    },
    {
        "value": 18590,
        "NOCCode": "9222",
        "JobTitle": "electronic equipment fabricating and assembling foreman/woman"
    },
    {
        "value": 18591,
        "NOCCode": "9222",
        "JobTitle": "electronic equipment fabricating and assembling supervisor"
    },
    {
        "value": 18592,
        "NOCCode": "9222",
        "JobTitle": "electronic equipment fabricating and assembling workers foreman/woman"
    },
    {
        "value": 18593,
        "NOCCode": "9222",
        "JobTitle": "electronic inspection foreman/woman – electronics manufacturing"
    },
    {
        "value": 18594,
        "NOCCode": "9222",
        "JobTitle": "electronics manufacturing foreman/woman"
    },
    {
        "value": 18595,
        "NOCCode": "9222",
        "JobTitle": "electronics manufacturing supervisor"
    },
    {
        "value": 18596,
        "NOCCode": "9222",
        "JobTitle": "final assembly and testing foreman/woman – electronic equipment manufacturing"
    },
    {
        "value": 18597,
        "NOCCode": "9222",
        "JobTitle": "final assembly and testing supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18598,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman – electronic equipment manufacturing"
    },
    {
        "value": 18599,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, business and commercial machines assembly"
    },
    {
        "value": 18600,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, electronic assembly"
    },
    {
        "value": 18601,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, electronic equipment fabricating and assembling"
    },
    {
        "value": 18602,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, electronic equipment fabricating and assembling workers"
    },
    {
        "value": 18603,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, electronic inspection – electronics manufacturing"
    },
    {
        "value": 18604,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, electronics manufacturing"
    },
    {
        "value": 18605,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, final assembly and test – electronics manufacturing"
    },
    {
        "value": 18606,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, inspectors and testers – electronic equipment fabrication, assembly, installation and repair"
    },
    {
        "value": 18607,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, PCB (printed circuit board) fabrication"
    },
    {
        "value": 18608,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, printed circuit board (PCB) fabrication – electronic equipment manufacturing"
    },
    {
        "value": 18609,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, printed circuit board fabrication"
    },
    {
        "value": 18610,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, production – electronic equipment manufacturing"
    },
    {
        "value": 18611,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, semiconductor assembly"
    },
    {
        "value": 18612,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, systems test – electronics manufacturing"
    },
    {
        "value": 18613,
        "NOCCode": "9222",
        "JobTitle": "foreman/woman, testing – electronic equipment manufacturing"
    },
    {
        "value": 18614,
        "NOCCode": "9222",
        "JobTitle": "IC (integrated circuit) fabrication supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18615,
        "NOCCode": "9222",
        "JobTitle": "inspectors and testers foreman/woman – electronic equipment fabrication, assembly, installation and repair"
    },
    {
        "value": 18616,
        "NOCCode": "9222",
        "JobTitle": "integrated circuit (IC) fabrication supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18617,
        "NOCCode": "9222",
        "JobTitle": "PCB (printed circuit board) fabrication foreman/woman – electronic equipment manufacturing"
    },
    {
        "value": 18618,
        "NOCCode": "9222",
        "JobTitle": "PCB (printed circuit board) fabrication supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18619,
        "NOCCode": "9222",
        "JobTitle": "printed circuit board (PCB) fabrication supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18620,
        "NOCCode": "9222",
        "JobTitle": "production foreman/woman – electronics manufacturing"
    },
    {
        "value": 18621,
        "NOCCode": "9222",
        "JobTitle": "production supervisor – electronics manufacturing"
    },
    {
        "value": 18622,
        "NOCCode": "9222",
        "JobTitle": "semiconductor assembly foreman/woman"
    },
    {
        "value": 18623,
        "NOCCode": "9222",
        "JobTitle": "supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18624,
        "NOCCode": "9222",
        "JobTitle": "supervisor, assembly and test"
    },
    {
        "value": 18625,
        "NOCCode": "9222",
        "JobTitle": "supervisor, assembly and test – electronics manufacturing"
    },
    {
        "value": 18626,
        "NOCCode": "9222",
        "JobTitle": "supervisor, assembly and test electronic equipment manufacturing"
    },
    {
        "value": 18627,
        "NOCCode": "9222",
        "JobTitle": "supervisor, communications equipment assembly – electronic equipment manufacturing"
    },
    {
        "value": 18628,
        "NOCCode": "9222",
        "JobTitle": "supervisor, communications equipment testing – electronic equipment manufacturing"
    },
    {
        "value": 18629,
        "NOCCode": "9222",
        "JobTitle": "supervisor, communications equipment testing – electronics manufacturing"
    },
    {
        "value": 18630,
        "NOCCode": "9222",
        "JobTitle": "supervisor, electronic assembly"
    },
    {
        "value": 18631,
        "NOCCode": "9222",
        "JobTitle": "supervisor, electronic equipment fabricating and assembling"
    },
    {
        "value": 18632,
        "NOCCode": "9222",
        "JobTitle": "supervisor, electronics manufacturing"
    },
    {
        "value": 18633,
        "NOCCode": "9222",
        "JobTitle": "supervisor, final assembly and testing – electronic equipment manufacturing"
    },
    {
        "value": 18634,
        "NOCCode": "9222",
        "JobTitle": "supervisor, integrated-circuit-board fabrication – electronic equipment manufacturing"
    },
    {
        "value": 18635,
        "NOCCode": "9222",
        "JobTitle": "supervisor, production – electronic equipment manufacturing"
    },
    {
        "value": 18636,
        "NOCCode": "9222",
        "JobTitle": "supervisor, systems testing – electronic equipment manufacturing"
    },
    {
        "value": 18637,
        "NOCCode": "9222",
        "JobTitle": "supervisor, testing – electronic equipment manufacturing"
    },
    {
        "value": 18638,
        "NOCCode": "9222",
        "JobTitle": "systems test foreman/woman"
    },
    {
        "value": 18639,
        "NOCCode": "9222",
        "JobTitle": "systems test foreman/woman – electronic equipment manufacturing"
    },
    {
        "value": 18640,
        "NOCCode": "9222",
        "JobTitle": "test supervisor – electronic equipment manufacturing"
    },
    {
        "value": 18641,
        "NOCCode": "9222",
        "JobTitle": "test supervisor – electronics manufacturing"
    },
    {
        "value": 18642,
        "NOCCode": "9222",
        "JobTitle": "testing foreman/woman – electronic equipment manufacturing"
    },
    {
        "value": 18643,
        "NOCCode": "9222",
        "JobTitle": "testing supervisor, communications equipment – electronic equipment manufacturing"
    },
    {
        "value": 18644,
        "NOCCode": "9223",
        "JobTitle": "dry cell assemblers supervisors"
    },
    {
        "value": 18645,
        "NOCCode": "9223",
        "JobTitle": "electric cable manufacturing foreman/woman"
    },
    {
        "value": 18646,
        "NOCCode": "9223",
        "JobTitle": "electric motor and generator assembly foreman/woman"
    },
    {
        "value": 18647,
        "NOCCode": "9223",
        "JobTitle": "electric motor testing foreman/woman"
    },
    {
        "value": 18648,
        "NOCCode": "9223",
        "JobTitle": "electrical appliance assemblers supervisor"
    },
    {
        "value": 18649,
        "NOCCode": "9223",
        "JobTitle": "electrical appliance manufacturing supervisor"
    },
    {
        "value": 18650,
        "NOCCode": "9223",
        "JobTitle": "electrical dry cell assembly foreman/woman"
    },
    {
        "value": 18651,
        "NOCCode": "9223",
        "JobTitle": "electrical equipment manufacturing foreman/woman"
    },
    {
        "value": 18652,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electric dry cell assembly"
    },
    {
        "value": 18653,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electric motor and generator assembly"
    },
    {
        "value": 18654,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electric motor testing"
    },
    {
        "value": 18655,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electrical appliance assembly"
    },
    {
        "value": 18656,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electrical equipment manufacturing"
    },
    {
        "value": 18657,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electrical motor assembly"
    },
    {
        "value": 18658,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electrical switchgear assembly"
    },
    {
        "value": 18659,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, electrical transformer assembly"
    },
    {
        "value": 18660,
        "NOCCode": "9223",
        "JobTitle": "foreman/woman, switchgear assembly"
    },
    {
        "value": 18661,
        "NOCCode": "9223",
        "JobTitle": "supervisor, dry cell assemblers"
    },
    {
        "value": 18662,
        "NOCCode": "9223",
        "JobTitle": "supervisor, electrical appliance assemblers"
    },
    {
        "value": 18663,
        "NOCCode": "9223",
        "JobTitle": "supervisor, electrical appliance assembly"
    },
    {
        "value": 18664,
        "NOCCode": "9223",
        "JobTitle": "supervisor, electrical appliance manufacturing"
    },
    {
        "value": 18665,
        "NOCCode": "9223",
        "JobTitle": "supervisor, electrical equipment manufacturing"
    },
    {
        "value": 18666,
        "NOCCode": "9224",
        "JobTitle": "buffing and lacquering foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18667,
        "NOCCode": "9224",
        "JobTitle": "chair assembly foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18668,
        "NOCCode": "9224",
        "JobTitle": "finishing room foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18669,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, buffing and lacquering – furniture and fixtures manufacturing"
    },
    {
        "value": 18670,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, cabinet assemblers and wood furniture makers"
    },
    {
        "value": 18671,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, chair assembly – furniture and fixtures manufacturing"
    },
    {
        "value": 18672,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, decorating – furniture and fixtures manufacturing"
    },
    {
        "value": 18673,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, desk assembly"
    },
    {
        "value": 18674,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, desk assembly – furniture and fixtures manufacturing"
    },
    {
        "value": 18675,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture assembly"
    },
    {
        "value": 18676,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture assembly – furniture and fixtures manufacturing"
    },
    {
        "value": 18677,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture components sanding department"
    },
    {
        "value": 18678,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture finishers – furniture and fixtures manufacturing"
    },
    {
        "value": 18679,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture finishing – furniture and fixtures manufacturing"
    },
    {
        "value": 18680,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture gluers – furniture and fixtures manufacturing"
    },
    {
        "value": 18681,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, furniture inspectors – furniture and fixtures manufacturing"
    },
    {
        "value": 18682,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, gluing – furniture and fixtures manufacturing"
    },
    {
        "value": 18683,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, laminating – furniture and fixtures manufacturing"
    },
    {
        "value": 18684,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, machining – furniture and fixtures manufacturing"
    },
    {
        "value": 18685,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, mattress manufacturing"
    },
    {
        "value": 18686,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, metal furniture assembly"
    },
    {
        "value": 18687,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, paint department – furniture and fixtures manufacturing"
    },
    {
        "value": 18688,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, planers, turners, shapers and related wood-machining workers – furniture and fixtures manufacturing"
    },
    {
        "value": 18689,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, veneering – furniture and fixtures manufacturing"
    },
    {
        "value": 18690,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, veneering shop – furniture and fixtures manufacturing"
    },
    {
        "value": 18691,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, wood machining workers – furniture and fixtures manufacturing"
    },
    {
        "value": 18692,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, wood sanders – furniture and fixtures manufacturing"
    },
    {
        "value": 18693,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, wood sawing (except sawmill)"
    },
    {
        "value": 18694,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, wood-machining quality-control inspectors – furniture and fixtures manufacturing"
    },
    {
        "value": 18695,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, woodworking machine department"
    },
    {
        "value": 18696,
        "NOCCode": "9224",
        "JobTitle": "foreman/woman, woodworking machine operators – furniture and fixtures manufacturing"
    },
    {
        "value": 18697,
        "NOCCode": "9224",
        "JobTitle": "furniture and fixtures manufacturing supervisor"
    },
    {
        "value": 18698,
        "NOCCode": "9224",
        "JobTitle": "furniture assembly foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18699,
        "NOCCode": "9224",
        "JobTitle": "furniture components sanding department foreman/woman"
    },
    {
        "value": 18700,
        "NOCCode": "9224",
        "JobTitle": "furniture finishers foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18701,
        "NOCCode": "9224",
        "JobTitle": "furniture finishing foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18702,
        "NOCCode": "9224",
        "JobTitle": "furniture gluers foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18703,
        "NOCCode": "9224",
        "JobTitle": "furniture inspectors foreman/woman"
    },
    {
        "value": 18704,
        "NOCCode": "9224",
        "JobTitle": "gluing foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18705,
        "NOCCode": "9224",
        "JobTitle": "laminating foreman/woman"
    },
    {
        "value": 18706,
        "NOCCode": "9224",
        "JobTitle": "laminating foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18707,
        "NOCCode": "9224",
        "JobTitle": "machining foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18708,
        "NOCCode": "9224",
        "JobTitle": "mattress manufacturing foreman/woman"
    },
    {
        "value": 18709,
        "NOCCode": "9224",
        "JobTitle": "metal furniture assembly foreman/woman"
    },
    {
        "value": 18710,
        "NOCCode": "9224",
        "JobTitle": "paint department foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18711,
        "NOCCode": "9224",
        "JobTitle": "planers, turners, shapers and related wood-machining workers foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18712,
        "NOCCode": "9224",
        "JobTitle": "shop foreman/woman, veneering – furniture and fixtures manufacturing"
    },
    {
        "value": 18713,
        "NOCCode": "9224",
        "JobTitle": "supervisor, furniture and fixture manufacturing"
    },
    {
        "value": 18714,
        "NOCCode": "9224",
        "JobTitle": "supervisor, furniture finishers"
    },
    {
        "value": 18715,
        "NOCCode": "9224",
        "JobTitle": "supervisor, woodworking machine operators"
    },
    {
        "value": 18716,
        "NOCCode": "9224",
        "JobTitle": "supervisor, woodworking machine operators – furniture and fixtures manufacturing"
    },
    {
        "value": 18717,
        "NOCCode": "9224",
        "JobTitle": "veneering foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18718,
        "NOCCode": "9224",
        "JobTitle": "veneering shop foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18719,
        "NOCCode": "9224",
        "JobTitle": "wood machining workers foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18720,
        "NOCCode": "9224",
        "JobTitle": "wood sanders foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18721,
        "NOCCode": "9224",
        "JobTitle": "wood sawing foreman/woman (except sawmill)"
    },
    {
        "value": 18722,
        "NOCCode": "9224",
        "JobTitle": "wood-machining quality-control inspectors foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18723,
        "NOCCode": "9224",
        "JobTitle": "woodworking machine department foreman/woman – furniture and fixtures manufacturing"
    },
    {
        "value": 18724,
        "NOCCode": "9224",
        "JobTitle": "woodworking machine operators supervisor – furniture and fixtures manufacturing"
    },
    {
        "value": 18725,
        "NOCCode": "9226",
        "JobTitle": "aircraft assembly foreman/woman"
    },
    {
        "value": 18726,
        "NOCCode": "9226",
        "JobTitle": "aircraft assembly inspection foreman/woman"
    },
    {
        "value": 18727,
        "NOCCode": "9226",
        "JobTitle": "aircraft assembly supervisor"
    },
    {
        "value": 18728,
        "NOCCode": "9226",
        "JobTitle": "aircraft hydraulic equipment installation foreman/woman"
    },
    {
        "value": 18729,
        "NOCCode": "9226",
        "JobTitle": "aircraft parts etching foreman/woman"
    },
    {
        "value": 18730,
        "NOCCode": "9226",
        "JobTitle": "aircraft rigging foreman/woman"
    },
    {
        "value": 18731,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, agricultural equipment"
    },
    {
        "value": 18732,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, engine accessories (except aircraft)"
    },
    {
        "value": 18733,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, engines (except aircraft)"
    },
    {
        "value": 18734,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, farm implements"
    },
    {
        "value": 18735,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, helicopters"
    },
    {
        "value": 18736,
        "NOCCode": "9226",
        "JobTitle": "assembly foreman/woman, tractor-trailers"
    },
    {
        "value": 18737,
        "NOCCode": "9226",
        "JobTitle": "assembly inspection foreman/woman, aircraft"
    },
    {
        "value": 18738,
        "NOCCode": "9226",
        "JobTitle": "assembly supervisor, engines (except aircraft)"
    },
    {
        "value": 18739,
        "NOCCode": "9226",
        "JobTitle": "assembly supervisor, helicopters"
    },
    {
        "value": 18740,
        "NOCCode": "9226",
        "JobTitle": "assembly supervisor, mobile homes"
    },
    {
        "value": 18741,
        "NOCCode": "9226",
        "JobTitle": "assembly supervisor, small engines"
    },
    {
        "value": 18742,
        "NOCCode": "9226",
        "JobTitle": "assembly supervisor, tractor-trailers"
    },
    {
        "value": 18743,
        "NOCCode": "9226",
        "JobTitle": "ball and roller-bearings assembly foreman/woman"
    },
    {
        "value": 18744,
        "NOCCode": "9226",
        "JobTitle": "construction machinery assembly foreman/woman"
    },
    {
        "value": 18745,
        "NOCCode": "9226",
        "JobTitle": "diesel engine assembly foreman/woman"
    },
    {
        "value": 18746,
        "NOCCode": "9226",
        "JobTitle": "electroplating foreman/woman"
    },
    {
        "value": 18747,
        "NOCCode": "9226",
        "JobTitle": "engine accessories assembly foreman/woman (except aircraft)"
    },
    {
        "value": 18748,
        "NOCCode": "9226",
        "JobTitle": "engine assembly foreman/woman (except aircraft)"
    },
    {
        "value": 18749,
        "NOCCode": "9226",
        "JobTitle": "engine assembly supervisor (except aircraft)"
    },
    {
        "value": 18750,
        "NOCCode": "9226",
        "JobTitle": "engine rebuilding shop foreman/woman (except aircraft)"
    },
    {
        "value": 18751,
        "NOCCode": "9226",
        "JobTitle": "engine testing foreman/woman (except aircraft)"
    },
    {
        "value": 18752,
        "NOCCode": "9226",
        "JobTitle": "farm machinery inspection foreman/woman"
    },
    {
        "value": 18753,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, agricultural equipment assembly"
    },
    {
        "value": 18754,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, aircraft assembly"
    },
    {
        "value": 18755,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, aircraft fuselage assembly"
    },
    {
        "value": 18756,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, aircraft painters"
    },
    {
        "value": 18757,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, ball and roller-bearings assembly"
    },
    {
        "value": 18758,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, construction machinery assembly"
    },
    {
        "value": 18759,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, electroplating"
    },
    {
        "value": 18760,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, engine assembly (except aircraft)"
    },
    {
        "value": 18761,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, engine guard assembly (except aircraft)"
    },
    {
        "value": 18762,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, engine rebuilding shop (except aircraft)"
    },
    {
        "value": 18763,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, engine testing (except aircraft)"
    },
    {
        "value": 18764,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, forge shop – metal products manufacturing"
    },
    {
        "value": 18765,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, forging-machine operators"
    },
    {
        "value": 18766,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, galvanizing"
    },
    {
        "value": 18767,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, garden tractor assembly"
    },
    {
        "value": 18768,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, helicopter assembly"
    },
    {
        "value": 18769,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, industrial truck assembly"
    },
    {
        "value": 18770,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, light agricultural and farm equipment assembly"
    },
    {
        "value": 18771,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, light construction machinery assembly"
    },
    {
        "value": 18772,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, light industrial, farm and construction machinery assembly"
    },
    {
        "value": 18773,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, light material-handling equipment assembly"
    },
    {
        "value": 18774,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, metal buffers"
    },
    {
        "value": 18775,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, metal buffing"
    },
    {
        "value": 18776,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, metal products inspection and testing"
    },
    {
        "value": 18777,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, metalworking machine operators"
    },
    {
        "value": 18778,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, mobile home assembly"
    },
    {
        "value": 18779,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – aircraft manufacturing"
    },
    {
        "value": 18780,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – heavy equipment manufacturing"
    },
    {
        "value": 18781,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – heavy truck, bus and trailer manufacturing"
    },
    {
        "value": 18782,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – machinery manufacturing"
    },
    {
        "value": 18783,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – metal products manufacturing"
    },
    {
        "value": 18784,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – railway rolling stock"
    },
    {
        "value": 18785,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – shipbuilding"
    },
    {
        "value": 18786,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, paint department – transport equipment manufacturing (except motor vehicles)"
    },
    {
        "value": 18787,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, painters – metal products"
    },
    {
        "value": 18788,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, power transmission assembly"
    },
    {
        "value": 18789,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, rototiller assembly"
    },
    {
        "value": 18790,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, shipyard painters"
    },
    {
        "value": 18791,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, small engine assembly"
    },
    {
        "value": 18792,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, snowblower tractor assembly"
    },
    {
        "value": 18793,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, snowmobile assembly"
    },
    {
        "value": 18794,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, tractor-trailer assembly"
    },
    {
        "value": 18795,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, truck assembly"
    },
    {
        "value": 18796,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, truck trailer assembly"
    },
    {
        "value": 18797,
        "NOCCode": "9226",
        "JobTitle": "foreman/woman, vending machine assembly"
    },
    {
        "value": 18798,
        "NOCCode": "9226",
        "JobTitle": "forge shop foreman/woman – metal products manufacturing"
    },
    {
        "value": 18799,
        "NOCCode": "9226",
        "JobTitle": "forgers foreman/woman – metal products manufacturing"
    },
    {
        "value": 18800,
        "NOCCode": "9226",
        "JobTitle": "galvanizing foreman/woman"
    },
    {
        "value": 18801,
        "NOCCode": "9226",
        "JobTitle": "helicopter assembly supervisor"
    },
    {
        "value": 18802,
        "NOCCode": "9226",
        "JobTitle": "hoisting-and-lifting equipment assembly foreman/woman"
    },
    {
        "value": 18803,
        "NOCCode": "9226",
        "JobTitle": "house trailer assembly foreman/woman"
    },
    {
        "value": 18804,
        "NOCCode": "9226",
        "JobTitle": "inspection foreman/woman, farm machinery"
    },
    {
        "value": 18805,
        "NOCCode": "9226",
        "JobTitle": "light agricultural and farm equipment assembly foreman/woman"
    },
    {
        "value": 18806,
        "NOCCode": "9226",
        "JobTitle": "light agricultural machinery assembly foreman/woman"
    },
    {
        "value": 18807,
        "NOCCode": "9226",
        "JobTitle": "light construction machinery assembly foreman/woman"
    },
    {
        "value": 18808,
        "NOCCode": "9226",
        "JobTitle": "material-handling equipment assembly foreman/woman"
    },
    {
        "value": 18809,
        "NOCCode": "9226",
        "JobTitle": "metal buffers foreman/woman"
    },
    {
        "value": 18810,
        "NOCCode": "9226",
        "JobTitle": "metal buffing foreman/woman"
    },
    {
        "value": 18811,
        "NOCCode": "9226",
        "JobTitle": "metal products inspection and testing foreman/woman"
    },
    {
        "value": 18812,
        "NOCCode": "9226",
        "JobTitle": "mobile home assembly supervisor"
    },
    {
        "value": 18813,
        "NOCCode": "9226",
        "JobTitle": "motor home assembly foreman/woman"
    },
    {
        "value": 18814,
        "NOCCode": "9226",
        "JobTitle": "outboard motor assembly foreman/woman"
    },
    {
        "value": 18815,
        "NOCCode": "9226",
        "JobTitle": "paint department foreman/woman – railway rolling stock manufacturing"
    },
    {
        "value": 18816,
        "NOCCode": "9226",
        "JobTitle": "paint department foreman/woman – shipbuilding"
    },
    {
        "value": 18817,
        "NOCCode": "9226",
        "JobTitle": "painter foreman/woman, shipyard"
    },
    {
        "value": 18818,
        "NOCCode": "9226",
        "JobTitle": "painters foreman/woman – metal products manufacturing"
    },
    {
        "value": 18819,
        "NOCCode": "9226",
        "JobTitle": "power transmission assembly foreman/woman"
    },
    {
        "value": 18820,
        "NOCCode": "9226",
        "JobTitle": "printing equipment assembly foreman/woman"
    },
    {
        "value": 18821,
        "NOCCode": "9226",
        "JobTitle": "shipyard painter foreman/woman"
    },
    {
        "value": 18822,
        "NOCCode": "9226",
        "JobTitle": "shop foreman/woman, engine rebuilding (except aircraft)"
    },
    {
        "value": 18823,
        "NOCCode": "9226",
        "JobTitle": "small engine assembly foreman/woman"
    },
    {
        "value": 18824,
        "NOCCode": "9226",
        "JobTitle": "small engine assembly supervisor"
    },
    {
        "value": 18825,
        "NOCCode": "9226",
        "JobTitle": "snowmobile assembly foreman/woman"
    },
    {
        "value": 18826,
        "NOCCode": "9226",
        "JobTitle": "spacecraft subassembly foreman/woman"
    },
    {
        "value": 18827,
        "NOCCode": "9226",
        "JobTitle": "supervisor, aircraft assembly"
    },
    {
        "value": 18828,
        "NOCCode": "9226",
        "JobTitle": "supervisor, electroplating"
    },
    {
        "value": 18829,
        "NOCCode": "9226",
        "JobTitle": "supervisor, engine assembly (except aircraft)"
    },
    {
        "value": 18830,
        "NOCCode": "9226",
        "JobTitle": "supervisor, helicopter assembly"
    },
    {
        "value": 18831,
        "NOCCode": "9226",
        "JobTitle": "supervisor, mobile home assembly"
    },
    {
        "value": 18832,
        "NOCCode": "9226",
        "JobTitle": "supervisor, shipbuilding"
    },
    {
        "value": 18833,
        "NOCCode": "9226",
        "JobTitle": "supervisor, small engine assembly"
    },
    {
        "value": 18834,
        "NOCCode": "9226",
        "JobTitle": "supervisor, snowmobile assembly"
    },
    {
        "value": 18835,
        "NOCCode": "9226",
        "JobTitle": "supervisor, tractor-trailer assembly"
    },
    {
        "value": 18836,
        "NOCCode": "9226",
        "JobTitle": "supervisor, truck trailer assembly"
    },
    {
        "value": 18837,
        "NOCCode": "9226",
        "JobTitle": "tractor-trailer assembly foreman/woman"
    },
    {
        "value": 18838,
        "NOCCode": "9226",
        "JobTitle": "tractor-trailer assembly supervisor"
    },
    {
        "value": 18839,
        "NOCCode": "9226",
        "JobTitle": "trailer assembly foreman/woman"
    },
    {
        "value": 18840,
        "NOCCode": "9226",
        "JobTitle": "transmission-rebuilding shop foreman/woman"
    },
    {
        "value": 18841,
        "NOCCode": "9226",
        "JobTitle": "truck assembly foreman/woman"
    },
    {
        "value": 18842,
        "NOCCode": "9226",
        "JobTitle": "truck trailer assembly foreman/woman"
    },
    {
        "value": 18843,
        "NOCCode": "9226",
        "JobTitle": "truck trailer assembly supervisor"
    },
    {
        "value": 18844,
        "NOCCode": "9227",
        "JobTitle": "assembly foreman/woman, clocks and watches"
    },
    {
        "value": 18845,
        "NOCCode": "9227",
        "JobTitle": "assembly foreman/woman, fibreglass boats"
    },
    {
        "value": 18846,
        "NOCCode": "9227",
        "JobTitle": "baby carriage assembly foreman/woman"
    },
    {
        "value": 18847,
        "NOCCode": "9227",
        "JobTitle": "bench assemblers foreman/woman – wood products manufacturing"
    },
    {
        "value": 18848,
        "NOCCode": "9227",
        "JobTitle": "brush making foreman/woman"
    },
    {
        "value": 18849,
        "NOCCode": "9227",
        "JobTitle": "candle making foreman/woman"
    },
    {
        "value": 18850,
        "NOCCode": "9227",
        "JobTitle": "clock and watch assembly repairers foreman/woman"
    },
    {
        "value": 18851,
        "NOCCode": "9227",
        "JobTitle": "clock and watch assembly repairers supervisor"
    },
    {
        "value": 18852,
        "NOCCode": "9227",
        "JobTitle": "clock and watch assembly supervisor"
    },
    {
        "value": 18853,
        "NOCCode": "9227",
        "JobTitle": "clock and watch inspection foreman/woman"
    },
    {
        "value": 18854,
        "NOCCode": "9227",
        "JobTitle": "controlling and recording instruments assembly foreman/woman"
    },
    {
        "value": 18855,
        "NOCCode": "9227",
        "JobTitle": "cooper foreman/woman"
    },
    {
        "value": 18856,
        "NOCCode": "9227",
        "JobTitle": "cutlery manufacturing foreman/woman"
    },
    {
        "value": 18857,
        "NOCCode": "9227",
        "JobTitle": "door and sash assembly foreman/woman"
    },
    {
        "value": 18858,
        "NOCCode": "9227",
        "JobTitle": "fibreglass boat foreman/woman"
    },
    {
        "value": 18859,
        "NOCCode": "9227",
        "JobTitle": "firearms assembly foreman/woman"
    },
    {
        "value": 18860,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, baby carriage assembly"
    },
    {
        "value": 18861,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, bench assemblers – wood products"
    },
    {
        "value": 18862,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, bicycle assembly"
    },
    {
        "value": 18863,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, brush making"
    },
    {
        "value": 18864,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, candle making"
    },
    {
        "value": 18865,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, clock and watch assembly"
    },
    {
        "value": 18866,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, clock and watch assembly repairers"
    },
    {
        "value": 18867,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, clock and watch inspection"
    },
    {
        "value": 18868,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, controlling and recording instruments assembly"
    },
    {
        "value": 18869,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, cutlery manufacturing"
    },
    {
        "value": 18870,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, door and sash assembly"
    },
    {
        "value": 18871,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, fibreglass boat assembly"
    },
    {
        "value": 18872,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, firearms assembly"
    },
    {
        "value": 18873,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, hand tool manufacturing"
    },
    {
        "value": 18874,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, jewellery and silverware-manufacturing workers"
    },
    {
        "value": 18875,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, jewellery manufacturing"
    },
    {
        "value": 18876,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, jewellery repairing – manufacturing"
    },
    {
        "value": 18877,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, lamp shade fabrication"
    },
    {
        "value": 18878,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, lens grinders and polishers"
    },
    {
        "value": 18879,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, millwork assemblers – wood manufacturing"
    },
    {
        "value": 18880,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, millwork assembly"
    },
    {
        "value": 18881,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, millwork assembly – wood products manufacturing"
    },
    {
        "value": 18882,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, mirror silvering"
    },
    {
        "value": 18883,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, musical instrument manufacturing"
    },
    {
        "value": 18884,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, optical instrument assembly"
    },
    {
        "value": 18885,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, optical instrument manufacturing"
    },
    {
        "value": 18886,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, photographic equipment assembly"
    },
    {
        "value": 18887,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, precision instruments and related equipment assembly"
    },
    {
        "value": 18888,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, prefab house assembly"
    },
    {
        "value": 18889,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, prefab housing assemblers"
    },
    {
        "value": 18890,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, prefab housing assembly"
    },
    {
        "value": 18891,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, prefabricated joinery assembly – wood products manufacturing"
    },
    {
        "value": 18892,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, sash and door assembly"
    },
    {
        "value": 18893,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, sash and door shop"
    },
    {
        "value": 18894,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, scale assembly"
    },
    {
        "value": 18895,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, spikemaking"
    },
    {
        "value": 18896,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, sporting equipment manufacturing"
    },
    {
        "value": 18897,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, thermostat assembly"
    },
    {
        "value": 18898,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, toy assembly"
    },
    {
        "value": 18899,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, toy manufacturing"
    },
    {
        "value": 18900,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, wooden barrel makers"
    },
    {
        "value": 18901,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, wooden box maker"
    },
    {
        "value": 18902,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, wooden truss makers – manufacturing"
    },
    {
        "value": 18903,
        "NOCCode": "9227",
        "JobTitle": "foreman/woman, woodenware assemblers"
    },
    {
        "value": 18904,
        "NOCCode": "9227",
        "JobTitle": "hand tools manufacturing foreman/woman"
    },
    {
        "value": 18905,
        "NOCCode": "9227",
        "JobTitle": "jewellery manufacturing foreman/woman"
    },
    {
        "value": 18906,
        "NOCCode": "9227",
        "JobTitle": "jewellery repairing foreman/woman – manufacturing"
    },
    {
        "value": 18907,
        "NOCCode": "9227",
        "JobTitle": "millwork assemblers foreman/woman – wood products manufacturing"
    },
    {
        "value": 18908,
        "NOCCode": "9227",
        "JobTitle": "millwork assembly supervisor"
    },
    {
        "value": 18909,
        "NOCCode": "9227",
        "JobTitle": "mirror silvering foreman/woman"
    },
    {
        "value": 18910,
        "NOCCode": "9227",
        "JobTitle": "optical instrument assembly foreman/woman"
    },
    {
        "value": 18911,
        "NOCCode": "9227",
        "JobTitle": "optical instrument manufacturing foreman/woman"
    },
    {
        "value": 18912,
        "NOCCode": "9227",
        "JobTitle": "prefabricated joinery assemblers foreman/woman – wood products manufacturing"
    },
    {
        "value": 18913,
        "NOCCode": "9227",
        "JobTitle": "prefabricated joinery assembly supervisor"
    },
    {
        "value": 18914,
        "NOCCode": "9227",
        "JobTitle": "silverware manufacturing supervisor"
    },
    {
        "value": 18915,
        "NOCCode": "9227",
        "JobTitle": "sporting goods manufacturing supervisor"
    },
    {
        "value": 18916,
        "NOCCode": "9227",
        "JobTitle": "supervisor, bicycle assembly"
    },
    {
        "value": 18917,
        "NOCCode": "9227",
        "JobTitle": "supervisor, clock and watch assembly"
    },
    {
        "value": 18918,
        "NOCCode": "9227",
        "JobTitle": "supervisor, clock and watch assembly repairers"
    },
    {
        "value": 18919,
        "NOCCode": "9227",
        "JobTitle": "supervisor, jewellery assembly"
    },
    {
        "value": 18920,
        "NOCCode": "9227",
        "JobTitle": "supervisor, lens manufacturing (non-prescription)"
    },
    {
        "value": 18921,
        "NOCCode": "9227",
        "JobTitle": "supervisor, millwork assembly"
    },
    {
        "value": 18922,
        "NOCCode": "9227",
        "JobTitle": "supervisor, silverware manufacturing"
    },
    {
        "value": 18923,
        "NOCCode": "9227",
        "JobTitle": "supervisor, sports equipment assembly"
    },
    {
        "value": 18924,
        "NOCCode": "9227",
        "JobTitle": "supervisor, sports equipment manufacturing"
    },
    {
        "value": 18925,
        "NOCCode": "9227",
        "JobTitle": "supervisor, toy manufacturing"
    },
    {
        "value": 18926,
        "NOCCode": "9227",
        "JobTitle": "toy assembly foreman/woman"
    },
    {
        "value": 18927,
        "NOCCode": "9227",
        "JobTitle": "toy manufacturing foreman/woman"
    },
    {
        "value": 18928,
        "NOCCode": "9227",
        "JobTitle": "toy manufacturing supervisor"
    },
    {
        "value": 18929,
        "NOCCode": "9227",
        "JobTitle": "wood products foreman/woman"
    },
    {
        "value": 18930,
        "NOCCode": "9227",
        "JobTitle": "wooden box maker foreman/woman"
    },
    {
        "value": 18931,
        "NOCCode": "9227",
        "JobTitle": "wooden truss making foreman/woman – manufacturing"
    },
    {
        "value": 18932,
        "NOCCode": "9231",
        "JobTitle": "alloy control operator – zinc casting"
    },
    {
        "value": 18933,
        "NOCCode": "9231",
        "JobTitle": "bar mill finisher – primary metal processing"
    },
    {
        "value": 18934,
        "NOCCode": "9231",
        "JobTitle": "billet mill roller – primary metal processing"
    },
    {
        "value": 18935,
        "NOCCode": "9231",
        "JobTitle": "blast furnace operator"
    },
    {
        "value": 18936,
        "NOCCode": "9231",
        "JobTitle": "blast furnaceman/woman"
    },
    {
        "value": 18937,
        "NOCCode": "9231",
        "JobTitle": "blooming mill roller"
    },
    {
        "value": 18938,
        "NOCCode": "9231",
        "JobTitle": "breakdown mill operator"
    },
    {
        "value": 18939,
        "NOCCode": "9231",
        "JobTitle": "central control caster"
    },
    {
        "value": 18940,
        "NOCCode": "9231",
        "JobTitle": "central control caster – primary metal processing"
    },
    {
        "value": 18941,
        "NOCCode": "9231",
        "JobTitle": "central control operator – cement manufacturing"
    },
    {
        "value": 18942,
        "NOCCode": "9231",
        "JobTitle": "central control room operator – primary metal and mineral products processing"
    },
    {
        "value": 18943,
        "NOCCode": "9231",
        "JobTitle": "central control room operator – primary metal processing"
    },
    {
        "value": 18944,
        "NOCCode": "9231",
        "JobTitle": "chief flotation operator"
    },
    {
        "value": 18945,
        "NOCCode": "9231",
        "JobTitle": "chief operator – zinc pressure leach"
    },
    {
        "value": 18946,
        "NOCCode": "9231",
        "JobTitle": "coke plant by-products operator"
    },
    {
        "value": 18947,
        "NOCCode": "9231",
        "JobTitle": "cold mill operator – primary metal processing"
    },
    {
        "value": 18948,
        "NOCCode": "9231",
        "JobTitle": "concentrator control room operator"
    },
    {
        "value": 18949,
        "NOCCode": "9231",
        "JobTitle": "console operator – cement manufacturing"
    },
    {
        "value": 18950,
        "NOCCode": "9231",
        "JobTitle": "continuous casting operator – primary metal processing"
    },
    {
        "value": 18951,
        "NOCCode": "9231",
        "JobTitle": "control operator, rolling mill – primary metal processing"
    },
    {
        "value": 18952,
        "NOCCode": "9231",
        "JobTitle": "control room furnace operator – smelter"
    },
    {
        "value": 18953,
        "NOCCode": "9231",
        "JobTitle": "control room operator – primary metal processing"
    },
    {
        "value": 18954,
        "NOCCode": "9231",
        "JobTitle": "copper melter"
    },
    {
        "value": 18955,
        "NOCCode": "9231",
        "JobTitle": "copper refinery operator"
    },
    {
        "value": 18956,
        "NOCCode": "9231",
        "JobTitle": "crucible furnace operator"
    },
    {
        "value": 18957,
        "NOCCode": "9231",
        "JobTitle": "crusher control room operator"
    },
    {
        "value": 18958,
        "NOCCode": "9231",
        "JobTitle": "cupola furnace operator – primary metal processing"
    },
    {
        "value": 18959,
        "NOCCode": "9231",
        "JobTitle": "dross furnace operator"
    },
    {
        "value": 18960,
        "NOCCode": "9231",
        "JobTitle": "electric arc furnace operator – primary metal processing"
    },
    {
        "value": 18961,
        "NOCCode": "9231",
        "JobTitle": "electric furnace operator – primary metal and mineral products processing"
    },
    {
        "value": 18962,
        "NOCCode": "9231",
        "JobTitle": "electrolytic refinery process operator"
    },
    {
        "value": 18963,
        "NOCCode": "9231",
        "JobTitle": "furnace operator – primary metal and mineral products processing"
    },
    {
        "value": 18964,
        "NOCCode": "9231",
        "JobTitle": "head charger"
    },
    {
        "value": 18965,
        "NOCCode": "9231",
        "JobTitle": "heater – primary metal processing"
    },
    {
        "value": 18966,
        "NOCCode": "9231",
        "JobTitle": "hot mill roller – primary metal processing"
    },
    {
        "value": 18967,
        "NOCCode": "9231",
        "JobTitle": "induction furnace operator – mineral and metal processing"
    },
    {
        "value": 18968,
        "NOCCode": "9231",
        "JobTitle": "ion exchange operator"
    },
    {
        "value": 18969,
        "NOCCode": "9231",
        "JobTitle": "lead-refining furnace operator"
    },
    {
        "value": 18970,
        "NOCCode": "9231",
        "JobTitle": "melter – primary metal processing"
    },
    {
        "value": 18971,
        "NOCCode": "9231",
        "JobTitle": "metal heater – primary metal processing"
    },
    {
        "value": 18972,
        "NOCCode": "9231",
        "JobTitle": "metal heater operator"
    },
    {
        "value": 18973,
        "NOCCode": "9231",
        "JobTitle": "metal melter"
    },
    {
        "value": 18974,
        "NOCCode": "9231",
        "JobTitle": "metal roaster – primary metal processing"
    },
    {
        "value": 18975,
        "NOCCode": "9231",
        "JobTitle": "metal smelter"
    },
    {
        "value": 18976,
        "NOCCode": "9231",
        "JobTitle": "molybdenum roaster operator"
    },
    {
        "value": 18977,
        "NOCCode": "9231",
        "JobTitle": "nuclear fuel processor"
    },
    {
        "value": 18978,
        "NOCCode": "9231",
        "JobTitle": "open-flame furnace operator – primary metal processing"
    },
    {
        "value": 18979,
        "NOCCode": "9231",
        "JobTitle": "operator, central control – cement manufacturing"
    },
    {
        "value": 18980,
        "NOCCode": "9231",
        "JobTitle": "operator, central control room – primary metal and mineral products processing"
    },
    {
        "value": 18981,
        "NOCCode": "9231",
        "JobTitle": "operator, cold mill – primary metal processing"
    },
    {
        "value": 18982,
        "NOCCode": "9231",
        "JobTitle": "operator, continuous casting machine – primary metal processing"
    },
    {
        "value": 18983,
        "NOCCode": "9231",
        "JobTitle": "operator, roaster – primary metal processing"
    },
    {
        "value": 18984,
        "NOCCode": "9231",
        "JobTitle": "operator, rolling mill control – primary metal processing"
    },
    {
        "value": 18985,
        "NOCCode": "9231",
        "JobTitle": "ore roaster"
    },
    {
        "value": 18986,
        "NOCCode": "9231",
        "JobTitle": "oxygen furnace operator – primary metal processing"
    },
    {
        "value": 18987,
        "NOCCode": "9231",
        "JobTitle": "panel board operator – primary metal and mineral products processing"
    },
    {
        "value": 18988,
        "NOCCode": "9231",
        "JobTitle": "pelletizing control operator"
    },
    {
        "value": 18989,
        "NOCCode": "9231",
        "JobTitle": "perlite expander control panel operator – mineral products processing"
    },
    {
        "value": 18990,
        "NOCCode": "9231",
        "JobTitle": "process operator – primary metal and mineral products processing"
    },
    {
        "value": 18991,
        "NOCCode": "9231",
        "JobTitle": "process operator – zinc pressure leach"
    },
    {
        "value": 18992,
        "NOCCode": "9231",
        "JobTitle": "purity control operator – zinc leaching"
    },
    {
        "value": 18993,
        "NOCCode": "9231",
        "JobTitle": "refinery process operator – primary metal processing"
    },
    {
        "value": 18994,
        "NOCCode": "9231",
        "JobTitle": "reverberatory furnace operator – primary metal processing"
    },
    {
        "value": 18995,
        "NOCCode": "9231",
        "JobTitle": "roaster operator – primary metal processing"
    },
    {
        "value": 18996,
        "NOCCode": "9231",
        "JobTitle": "rolling mill control operator"
    },
    {
        "value": 18997,
        "NOCCode": "9231",
        "JobTitle": "rolling mill control operator – primary metal processing"
    },
    {
        "value": 18998,
        "NOCCode": "9231",
        "JobTitle": "slabbing mill roller – primary metal processing"
    },
    {
        "value": 18999,
        "NOCCode": "9231",
        "JobTitle": "slurry controller – primary metal processing"
    },
    {
        "value": 19000,
        "NOCCode": "9231",
        "JobTitle": "smelting furnace operator – primary metal processing"
    },
    {
        "value": 19001,
        "NOCCode": "9231",
        "JobTitle": "steel heater operator"
    },
    {
        "value": 19002,
        "NOCCode": "9231",
        "JobTitle": "steel-degasser process operator"
    },
    {
        "value": 19003,
        "NOCCode": "9231",
        "JobTitle": "vacuum furnace operator – primary metal processing"
    },
    {
        "value": 19004,
        "NOCCode": "9231",
        "JobTitle": "vacuum-degasser process operator – primary metal processing"
    },
    {
        "value": 19005,
        "NOCCode": "9232",
        "JobTitle": "absorption operator"
    },
    {
        "value": 19006,
        "NOCCode": "9232",
        "JobTitle": "absorption plant operator"
    },
    {
        "value": 19007,
        "NOCCode": "9232",
        "JobTitle": "acid mixer operator"
    },
    {
        "value": 19008,
        "NOCCode": "9232",
        "JobTitle": "acid plant operator"
    },
    {
        "value": 19009,
        "NOCCode": "9232",
        "JobTitle": "alkylation chief operator"
    },
    {
        "value": 19010,
        "NOCCode": "9232",
        "JobTitle": "alkylation operator"
    },
    {
        "value": 19011,
        "NOCCode": "9232",
        "JobTitle": "alkylation tower tender"
    },
    {
        "value": 19012,
        "NOCCode": "9232",
        "JobTitle": "alum-liquor plant operator"
    },
    {
        "value": 19013,
        "NOCCode": "9232",
        "JobTitle": "ammonia plant operator"
    },
    {
        "value": 19014,
        "NOCCode": "9232",
        "JobTitle": "antibiotics fermenter"
    },
    {
        "value": 19015,
        "NOCCode": "9232",
        "JobTitle": "assistant operator – chemical processing"
    },
    {
        "value": 19016,
        "NOCCode": "9232",
        "JobTitle": "batching chemical process operator"
    },
    {
        "value": 19017,
        "NOCCode": "9232",
        "JobTitle": "benzol agitator operator"
    },
    {
        "value": 19018,
        "NOCCode": "9232",
        "JobTitle": "bitumen extraction plant operator"
    },
    {
        "value": 19019,
        "NOCCode": "9232",
        "JobTitle": "bitumen upgrading plant operator"
    },
    {
        "value": 19020,
        "NOCCode": "9232",
        "JobTitle": "blender, petroleum"
    },
    {
        "value": 19021,
        "NOCCode": "9232",
        "JobTitle": "blender, propellants"
    },
    {
        "value": 19022,
        "NOCCode": "9232",
        "JobTitle": "boardman/woman – chemical processing"
    },
    {
        "value": 19023,
        "NOCCode": "9232",
        "JobTitle": "brine equipment tender"
    },
    {
        "value": 19024,
        "NOCCode": "9232",
        "JobTitle": "brine purification operator"
    },
    {
        "value": 19025,
        "NOCCode": "9232",
        "JobTitle": "burner operator – petroleum"
    },
    {
        "value": 19026,
        "NOCCode": "9232",
        "JobTitle": "catalyst operator, gasoline"
    },
    {
        "value": 19027,
        "NOCCode": "9232",
        "JobTitle": "catalytic cracking unit operator"
    },
    {
        "value": 19028,
        "NOCCode": "9232",
        "JobTitle": "caustic cell operator"
    },
    {
        "value": 19029,
        "NOCCode": "9232",
        "JobTitle": "cell room operator, chlor-alkali plant"
    },
    {
        "value": 19030,
        "NOCCode": "9232",
        "JobTitle": "chemical process operator"
    },
    {
        "value": 19031,
        "NOCCode": "9232",
        "JobTitle": "chemical process unit operator"
    },
    {
        "value": 19032,
        "NOCCode": "9232",
        "JobTitle": "chemical roaster"
    },
    {
        "value": 19033,
        "NOCCode": "9232",
        "JobTitle": "chief operator – chemical processing"
    },
    {
        "value": 19034,
        "NOCCode": "9232",
        "JobTitle": "chief operator, batching process"
    },
    {
        "value": 19035,
        "NOCCode": "9232",
        "JobTitle": "chief operator, continuous process"
    },
    {
        "value": 19036,
        "NOCCode": "9232",
        "JobTitle": "chief operator, synthesis"
    },
    {
        "value": 19037,
        "NOCCode": "9232",
        "JobTitle": "chief technician, chemical processing"
    },
    {
        "value": 19038,
        "NOCCode": "9232",
        "JobTitle": "chlorination plant operator"
    },
    {
        "value": 19039,
        "NOCCode": "9232",
        "JobTitle": "chlorine plant operator"
    },
    {
        "value": 19040,
        "NOCCode": "9232",
        "JobTitle": "coal gas exhaust operator"
    },
    {
        "value": 19041,
        "NOCCode": "9232",
        "JobTitle": "coal tar distiller"
    },
    {
        "value": 19042,
        "NOCCode": "9232",
        "JobTitle": "column operator – chemical processing"
    },
    {
        "value": 19043,
        "NOCCode": "9232",
        "JobTitle": "compressor operator, gas processing"
    },
    {
        "value": 19044,
        "NOCCode": "9232",
        "JobTitle": "compressor operator, natural gas"
    },
    {
        "value": 19045,
        "NOCCode": "9232",
        "JobTitle": "compressor station operator – pipelines"
    },
    {
        "value": 19046,
        "NOCCode": "9232",
        "JobTitle": "control panel operator – chemical processing"
    },
    {
        "value": 19047,
        "NOCCode": "9232",
        "JobTitle": "controller – oil"
    },
    {
        "value": 19048,
        "NOCCode": "9232",
        "JobTitle": "controller, oil movement"
    },
    {
        "value": 19049,
        "NOCCode": "9232",
        "JobTitle": "cracking plant operator"
    },
    {
        "value": 19050,
        "NOCCode": "9232",
        "JobTitle": "desulphurizer operator"
    },
    {
        "value": 19051,
        "NOCCode": "9232",
        "JobTitle": "dispatcher, gas"
    },
    {
        "value": 19052,
        "NOCCode": "9232",
        "JobTitle": "dispatcher, oil"
    },
    {
        "value": 19053,
        "NOCCode": "9232",
        "JobTitle": "dissolver-precipitator"
    },
    {
        "value": 19054,
        "NOCCode": "9232",
        "JobTitle": "distillation operator"
    },
    {
        "value": 19055,
        "NOCCode": "9232",
        "JobTitle": "distillation operator, petroleum"
    },
    {
        "value": 19056,
        "NOCCode": "9232",
        "JobTitle": "explosives senior operator"
    },
    {
        "value": 19057,
        "NOCCode": "9232",
        "JobTitle": "fertilizer maker, phosphate process"
    },
    {
        "value": 19058,
        "NOCCode": "9232",
        "JobTitle": "filter operator – petroleum, gas and chemical processing"
    },
    {
        "value": 19059,
        "NOCCode": "9232",
        "JobTitle": "fractionator operator"
    },
    {
        "value": 19060,
        "NOCCode": "9232",
        "JobTitle": "fuel gas treater"
    },
    {
        "value": 19061,
        "NOCCode": "9232",
        "JobTitle": "gas control coordinator"
    },
    {
        "value": 19062,
        "NOCCode": "9232",
        "JobTitle": "gas control operator"
    },
    {
        "value": 19063,
        "NOCCode": "9232",
        "JobTitle": "gas controller"
    },
    {
        "value": 19064,
        "NOCCode": "9232",
        "JobTitle": "gas dispatcher"
    },
    {
        "value": 19065,
        "NOCCode": "9232",
        "JobTitle": "gas field operator"
    },
    {
        "value": 19066,
        "NOCCode": "9232",
        "JobTitle": "gas field production operator"
    },
    {
        "value": 19067,
        "NOCCode": "9232",
        "JobTitle": "gas liquefaction and regasification plant operator"
    },
    {
        "value": 19068,
        "NOCCode": "9232",
        "JobTitle": "gas pipeline operator"
    },
    {
        "value": 19069,
        "NOCCode": "9232",
        "JobTitle": "gas plant operator"
    },
    {
        "value": 19070,
        "NOCCode": "9232",
        "JobTitle": "gas pressure regulator"
    },
    {
        "value": 19071,
        "NOCCode": "9232",
        "JobTitle": "gas processing controller"
    },
    {
        "value": 19072,
        "NOCCode": "9232",
        "JobTitle": "gas recovery operator"
    },
    {
        "value": 19073,
        "NOCCode": "9232",
        "JobTitle": "gasoline catalyst operator"
    },
    {
        "value": 19074,
        "NOCCode": "9232",
        "JobTitle": "gas-treating unit operator"
    },
    {
        "value": 19075,
        "NOCCode": "9232",
        "JobTitle": "gauger, oil"
    },
    {
        "value": 19076,
        "NOCCode": "9232",
        "JobTitle": "gauger, pipelines"
    },
    {
        "value": 19077,
        "NOCCode": "9232",
        "JobTitle": "glycerine refiner"
    },
    {
        "value": 19078,
        "NOCCode": "9232",
        "JobTitle": "glycerine refinery operator"
    },
    {
        "value": 19079,
        "NOCCode": "9232",
        "JobTitle": "grease maker"
    },
    {
        "value": 19080,
        "NOCCode": "9232",
        "JobTitle": "heavy water chemical process operator"
    },
    {
        "value": 19081,
        "NOCCode": "9232",
        "JobTitle": "hydrogen process plant operator"
    },
    {
        "value": 19082,
        "NOCCode": "9232",
        "JobTitle": "lead operator – chemical processing"
    },
    {
        "value": 19083,
        "NOCCode": "9232",
        "JobTitle": "liquefaction plant operator"
    },
    {
        "value": 19084,
        "NOCCode": "9232",
        "JobTitle": "master operator – chemical processing"
    },
    {
        "value": 19085,
        "NOCCode": "9232",
        "JobTitle": "mixer, wet explosives"
    },
    {
        "value": 19086,
        "NOCCode": "9232",
        "JobTitle": "naphthalene clarifier"
    },
    {
        "value": 19087,
        "NOCCode": "9232",
        "JobTitle": "nitric acid oxidation plant operator"
    },
    {
        "value": 19088,
        "NOCCode": "9232",
        "JobTitle": "nitrocellulose maker"
    },
    {
        "value": 19089,
        "NOCCode": "9232",
        "JobTitle": "nitroglycerine maker"
    },
    {
        "value": 19090,
        "NOCCode": "9232",
        "JobTitle": "nitroglycerine nitrator operator"
    },
    {
        "value": 19091,
        "NOCCode": "9232",
        "JobTitle": "nitroglycerine separator"
    },
    {
        "value": 19092,
        "NOCCode": "9232",
        "JobTitle": "nitroglycerine separator operator"
    },
    {
        "value": 19093,
        "NOCCode": "9232",
        "JobTitle": "oil blender"
    },
    {
        "value": 19094,
        "NOCCode": "9232",
        "JobTitle": "oil controller"
    },
    {
        "value": 19095,
        "NOCCode": "9232",
        "JobTitle": "oil dispatcher"
    },
    {
        "value": 19096,
        "NOCCode": "9232",
        "JobTitle": "oil field production operator"
    },
    {
        "value": 19097,
        "NOCCode": "9232",
        "JobTitle": "oil field pumper"
    },
    {
        "value": 19098,
        "NOCCode": "9232",
        "JobTitle": "oil movement controller"
    },
    {
        "value": 19099,
        "NOCCode": "9232",
        "JobTitle": "oil movement coordinator"
    },
    {
        "value": 19100,
        "NOCCode": "9232",
        "JobTitle": "oil pipeline operator"
    },
    {
        "value": 19101,
        "NOCCode": "9232",
        "JobTitle": "oil refiner"
    },
    {
        "value": 19102,
        "NOCCode": "9232",
        "JobTitle": "oil refinery process operator"
    },
    {
        "value": 19103,
        "NOCCode": "9232",
        "JobTitle": "operating technician – chemical processing"
    },
    {
        "value": 19104,
        "NOCCode": "9232",
        "JobTitle": "operations technician – chemical processing"
    },
    {
        "value": 19105,
        "NOCCode": "9232",
        "JobTitle": "operator, catalytic conversion"
    },
    {
        "value": 19106,
        "NOCCode": "9232",
        "JobTitle": "operator, chemical process"
    },
    {
        "value": 19107,
        "NOCCode": "9232",
        "JobTitle": "operator, chemical process unit"
    },
    {
        "value": 19108,
        "NOCCode": "9232",
        "JobTitle": "operator, chlorination plant"
    },
    {
        "value": 19109,
        "NOCCode": "9232",
        "JobTitle": "operator, compressor station – pipelines"
    },
    {
        "value": 19110,
        "NOCCode": "9232",
        "JobTitle": "operator, control panel – chemical processing"
    },
    {
        "value": 19111,
        "NOCCode": "9232",
        "JobTitle": "operator, desulphurizing unit"
    },
    {
        "value": 19112,
        "NOCCode": "9232",
        "JobTitle": "operator, distillation equipment"
    },
    {
        "value": 19113,
        "NOCCode": "9232",
        "JobTitle": "operator, filter – petroleum, gas and chemical processing"
    },
    {
        "value": 19114,
        "NOCCode": "9232",
        "JobTitle": "operator, fractionator – petroleum"
    },
    {
        "value": 19115,
        "NOCCode": "9232",
        "JobTitle": "operator, heavy water chemical process"
    },
    {
        "value": 19116,
        "NOCCode": "9232",
        "JobTitle": "operator, liquefaction plant"
    },
    {
        "value": 19117,
        "NOCCode": "9232",
        "JobTitle": "operator, nitric acid oxidation plant"
    },
    {
        "value": 19118,
        "NOCCode": "9232",
        "JobTitle": "operator, oxygen recovery plant"
    },
    {
        "value": 19119,
        "NOCCode": "9232",
        "JobTitle": "operator, panel – chemical processing"
    },
    {
        "value": 19120,
        "NOCCode": "9232",
        "JobTitle": "operator, petroleum process"
    },
    {
        "value": 19121,
        "NOCCode": "9232",
        "JobTitle": "operator, petroleum refinery"
    },
    {
        "value": 19122,
        "NOCCode": "9232",
        "JobTitle": "operator, pharmaceutical processing"
    },
    {
        "value": 19123,
        "NOCCode": "9232",
        "JobTitle": "operator, polymerization unit"
    },
    {
        "value": 19124,
        "NOCCode": "9232",
        "JobTitle": "operator, power switchboard – chemical processing"
    },
    {
        "value": 19125,
        "NOCCode": "9232",
        "JobTitle": "operator, production – chemical processing"
    },
    {
        "value": 19126,
        "NOCCode": "9232",
        "JobTitle": "operator, purification – chemical processing"
    },
    {
        "value": 19127,
        "NOCCode": "9232",
        "JobTitle": "operator, refinery"
    },
    {
        "value": 19128,
        "NOCCode": "9232",
        "JobTitle": "oxygen recovery plant operator"
    },
    {
        "value": 19129,
        "NOCCode": "9232",
        "JobTitle": "panel operator – chemical processing"
    },
    {
        "value": 19130,
        "NOCCode": "9232",
        "JobTitle": "petroleum process operator"
    },
    {
        "value": 19131,
        "NOCCode": "9232",
        "JobTitle": "petroleum refiner"
    },
    {
        "value": 19132,
        "NOCCode": "9232",
        "JobTitle": "petroleum refinery operator"
    },
    {
        "value": 19133,
        "NOCCode": "9232",
        "JobTitle": "petroleum unit operator"
    },
    {
        "value": 19134,
        "NOCCode": "9232",
        "JobTitle": "pharmaceutical processing operator"
    },
    {
        "value": 19135,
        "NOCCode": "9232",
        "JobTitle": "pilot plant tender"
    },
    {
        "value": 19136,
        "NOCCode": "9232",
        "JobTitle": "pipeline compressor station operator"
    },
    {
        "value": 19137,
        "NOCCode": "9232",
        "JobTitle": "pipeline gauger"
    },
    {
        "value": 19138,
        "NOCCode": "9232",
        "JobTitle": "polymerization kettle operator"
    },
    {
        "value": 19139,
        "NOCCode": "9232",
        "JobTitle": "polymerization unit operator"
    },
    {
        "value": 19140,
        "NOCCode": "9232",
        "JobTitle": "power switchboard operator – chemical"
    },
    {
        "value": 19141,
        "NOCCode": "9232",
        "JobTitle": "process control system operator"
    },
    {
        "value": 19142,
        "NOCCode": "9232",
        "JobTitle": "process technician – chemical processing"
    },
    {
        "value": 19143,
        "NOCCode": "9232",
        "JobTitle": "processing laboratory operator"
    },
    {
        "value": 19144,
        "NOCCode": "9232",
        "JobTitle": "processing operator – chemical processing"
    },
    {
        "value": 19145,
        "NOCCode": "9232",
        "JobTitle": "processor, sterile products – chemical processing"
    },
    {
        "value": 19146,
        "NOCCode": "9232",
        "JobTitle": "production operator – chemical processing"
    },
    {
        "value": 19147,
        "NOCCode": "9232",
        "JobTitle": "propellant blender"
    },
    {
        "value": 19148,
        "NOCCode": "9232",
        "JobTitle": "pump station operator – pipelines"
    },
    {
        "value": 19149,
        "NOCCode": "9232",
        "JobTitle": "pumping station operator"
    },
    {
        "value": 19150,
        "NOCCode": "9232",
        "JobTitle": "purification operator – chemical processing"
    },
    {
        "value": 19151,
        "NOCCode": "9232",
        "JobTitle": "refinery operator"
    },
    {
        "value": 19152,
        "NOCCode": "9232",
        "JobTitle": "refinery process technician"
    },
    {
        "value": 19153,
        "NOCCode": "9232",
        "JobTitle": "rotary furnace tender – chemical processing"
    },
    {
        "value": 19154,
        "NOCCode": "9232",
        "JobTitle": "senior operating technician"
    },
    {
        "value": 19155,
        "NOCCode": "9232",
        "JobTitle": "senior operator – chemical processing"
    },
    {
        "value": 19156,
        "NOCCode": "9232",
        "JobTitle": "sterile products processor – chemical processing"
    },
    {
        "value": 19157,
        "NOCCode": "9232",
        "JobTitle": "sulphuric acid plant operator"
    },
    {
        "value": 19158,
        "NOCCode": "9232",
        "JobTitle": "tank farm operator"
    },
    {
        "value": 19159,
        "NOCCode": "9232",
        "JobTitle": "technician, process – chemical processing"
    },
    {
        "value": 19160,
        "NOCCode": "9232",
        "JobTitle": "technician, refinery process"
    },
    {
        "value": 19161,
        "NOCCode": "9232",
        "JobTitle": "tender, pilot plant"
    },
    {
        "value": 19162,
        "NOCCode": "9232",
        "JobTitle": "thermal acid equipment operator"
    },
    {
        "value": 19163,
        "NOCCode": "9232",
        "JobTitle": "treater, fuel gas"
    },
    {
        "value": 19164,
        "NOCCode": "9232",
        "JobTitle": "treater, petroleum"
    },
    {
        "value": 19165,
        "NOCCode": "9232",
        "JobTitle": "upgrader process operator – petroleum"
    },
    {
        "value": 19166,
        "NOCCode": "9232",
        "JobTitle": "wet explosives mixer"
    },
    {
        "value": 19167,
        "NOCCode": "9235",
        "JobTitle": "batch operator – pulp and paper"
    },
    {
        "value": 19168,
        "NOCCode": "9235",
        "JobTitle": "beater engineer – pulp and paper"
    },
    {
        "value": 19169,
        "NOCCode": "9235",
        "JobTitle": "beater room lead hand – pulp and paper"
    },
    {
        "value": 19170,
        "NOCCode": "9235",
        "JobTitle": "bleach plant operator – pulp and paper"
    },
    {
        "value": 19171,
        "NOCCode": "9235",
        "JobTitle": "control operator, paper machine"
    },
    {
        "value": 19172,
        "NOCCode": "9235",
        "JobTitle": "control operator, pulping – pulp and paper"
    },
    {
        "value": 19173,
        "NOCCode": "9235",
        "JobTitle": "control room operator – pulp and paper"
    },
    {
        "value": 19174,
        "NOCCode": "9235",
        "JobTitle": "cooking systems operator – pulp and paper"
    },
    {
        "value": 19175,
        "NOCCode": "9235",
        "JobTitle": "cylinder machine operator – pulp and paper"
    },
    {
        "value": 19176,
        "NOCCode": "9235",
        "JobTitle": "operator, bleach plant – pulp and paper"
    },
    {
        "value": 19177,
        "NOCCode": "9235",
        "JobTitle": "operator, control room – pulp and paper"
    },
    {
        "value": 19178,
        "NOCCode": "9235",
        "JobTitle": "operator, panelboard – pulp and paper"
    },
    {
        "value": 19179,
        "NOCCode": "9235",
        "JobTitle": "operator, pulping group – pulp and paper"
    },
    {
        "value": 19180,
        "NOCCode": "9235",
        "JobTitle": "panel operator – pulp and paper"
    },
    {
        "value": 19181,
        "NOCCode": "9235",
        "JobTitle": "panelboard operator – pulp and paper"
    },
    {
        "value": 19182,
        "NOCCode": "9235",
        "JobTitle": "paper maker"
    },
    {
        "value": 19183,
        "NOCCode": "9235",
        "JobTitle": "peroxide bleach plant operator – pulp and paper"
    },
    {
        "value": 19184,
        "NOCCode": "9235",
        "JobTitle": "pulping control operator"
    },
    {
        "value": 19185,
        "NOCCode": "9235",
        "JobTitle": "pulping group operator – pulp and paper"
    },
    {
        "value": 19186,
        "NOCCode": "9235",
        "JobTitle": "pulping technician"
    },
    {
        "value": 19187,
        "NOCCode": "9235",
        "JobTitle": "recovery plant operator – pulp and paper"
    },
    {
        "value": 19188,
        "NOCCode": "9241",
        "JobTitle": "air compressor operator"
    },
    {
        "value": 19189,
        "NOCCode": "9241",
        "JobTitle": "air conditioning system operator"
    },
    {
        "value": 19190,
        "NOCCode": "9241",
        "JobTitle": "apprentice power dispatcher"
    },
    {
        "value": 19191,
        "NOCCode": "9241",
        "JobTitle": "apprentice power dispatcher – electrical power systems"
    },
    {
        "value": 19192,
        "NOCCode": "9241",
        "JobTitle": "apprentice power engineer"
    },
    {
        "value": 19193,
        "NOCCode": "9241",
        "JobTitle": "apprentice stationary engineer"
    },
    {
        "value": 19194,
        "NOCCode": "9241",
        "JobTitle": "area dispatch chief operator"
    },
    {
        "value": 19195,
        "NOCCode": "9241",
        "JobTitle": "area load centre dispatcher"
    },
    {
        "value": 19196,
        "NOCCode": "9241",
        "JobTitle": "automated substation operator – electrical power systems"
    },
    {
        "value": 19197,
        "NOCCode": "9241",
        "JobTitle": "auxiliary equipment operator"
    },
    {
        "value": 19198,
        "NOCCode": "9241",
        "JobTitle": "auxiliary plant operator"
    },
    {
        "value": 19199,
        "NOCCode": "9241",
        "JobTitle": "boiler operator"
    },
    {
        "value": 19200,
        "NOCCode": "9241",
        "JobTitle": "boilerhouse operator"
    },
    {
        "value": 19201,
        "NOCCode": "9241",
        "JobTitle": "building systems operator"
    },
    {
        "value": 19202,
        "NOCCode": "9241",
        "JobTitle": "building systems technician"
    },
    {
        "value": 19203,
        "NOCCode": "9241",
        "JobTitle": "central control room operator – electrical power systems"
    },
    {
        "value": 19204,
        "NOCCode": "9241",
        "JobTitle": "central-office power control room operator – electrical power systems"
    },
    {
        "value": 19205,
        "NOCCode": "9241",
        "JobTitle": "chief operator, area dispatch"
    },
    {
        "value": 19206,
        "NOCCode": "9241",
        "JobTitle": "chief operator, area dispatch – electrical power systems"
    },
    {
        "value": 19207,
        "NOCCode": "9241",
        "JobTitle": "control room operator – electrical power systems"
    },
    {
        "value": 19208,
        "NOCCode": "9241",
        "JobTitle": "control room operator, trainee – electrical power systems"
    },
    {
        "value": 19209,
        "NOCCode": "9241",
        "JobTitle": "conversion substation operator – electrical power systems"
    },
    {
        "value": 19210,
        "NOCCode": "9241",
        "JobTitle": "cooling system operator"
    },
    {
        "value": 19211,
        "NOCCode": "9241",
        "JobTitle": "diesel engine operator, stationary"
    },
    {
        "value": 19212,
        "NOCCode": "9241",
        "JobTitle": "diesel generating station operator – electrical power systems"
    },
    {
        "value": 19213,
        "NOCCode": "9241",
        "JobTitle": "diesel plant operator – electrical power systems"
    },
    {
        "value": 19214,
        "NOCCode": "9241",
        "JobTitle": "diesel station operator"
    },
    {
        "value": 19215,
        "NOCCode": "9241",
        "JobTitle": "distribution control operator"
    },
    {
        "value": 19216,
        "NOCCode": "9241",
        "JobTitle": "distribution control operator – electrical power systems"
    },
    {
        "value": 19217,
        "NOCCode": "9241",
        "JobTitle": "distribution system dispatcher apprentice – electrical power systems"
    },
    {
        "value": 19218,
        "NOCCode": "9241",
        "JobTitle": "distribution system operator"
    },
    {
        "value": 19219,
        "NOCCode": "9241",
        "JobTitle": "electrical power systems operator"
    },
    {
        "value": 19220,
        "NOCCode": "9241",
        "JobTitle": "electrical station operator"
    },
    {
        "value": 19221,
        "NOCCode": "9241",
        "JobTitle": "electrical station operator – electrical power systems"
    },
    {
        "value": 19222,
        "NOCCode": "9241",
        "JobTitle": "electrical station power distribution inspector"
    },
    {
        "value": 19223,
        "NOCCode": "9241",
        "JobTitle": "energy from waste plant operator"
    },
    {
        "value": 19224,
        "NOCCode": "9241",
        "JobTitle": "energy recovery incinerator plant operator"
    },
    {
        "value": 19225,
        "NOCCode": "9241",
        "JobTitle": "feeder switchboard apprentice operator – electrical power systems"
    },
    {
        "value": 19226,
        "NOCCode": "9241",
        "JobTitle": "feeder switchboard operator – electrical power systems"
    },
    {
        "value": 19227,
        "NOCCode": "9241",
        "JobTitle": "fifth-class stationary engineer"
    },
    {
        "value": 19228,
        "NOCCode": "9241",
        "JobTitle": "first-class power engineer"
    },
    {
        "value": 19229,
        "NOCCode": "9241",
        "JobTitle": "first-class stationary engineer"
    },
    {
        "value": 19230,
        "NOCCode": "9241",
        "JobTitle": "fourth-class power engineer"
    },
    {
        "value": 19231,
        "NOCCode": "9241",
        "JobTitle": "fourth-class stationary engineer"
    },
    {
        "value": 19232,
        "NOCCode": "9241",
        "JobTitle": "furnace boiler operator"
    },
    {
        "value": 19233,
        "NOCCode": "9241",
        "JobTitle": "generating station main operator"
    },
    {
        "value": 19234,
        "NOCCode": "9241",
        "JobTitle": "generating station operator"
    },
    {
        "value": 19235,
        "NOCCode": "9241",
        "JobTitle": "generating station operator – electrical power systems"
    },
    {
        "value": 19236,
        "NOCCode": "9241",
        "JobTitle": "generating station switchboard operator"
    },
    {
        "value": 19237,
        "NOCCode": "9241",
        "JobTitle": "generation substation operator – electrical power systems"
    },
    {
        "value": 19238,
        "NOCCode": "9241",
        "JobTitle": "heating and ventilation equipment tender"
    },
    {
        "value": 19239,
        "NOCCode": "9241",
        "JobTitle": "heating stationary engineer"
    },
    {
        "value": 19240,
        "NOCCode": "9241",
        "JobTitle": "heavy water steam plant operator"
    },
    {
        "value": 19241,
        "NOCCode": "9241",
        "JobTitle": "humidifying systems operator"
    },
    {
        "value": 19242,
        "NOCCode": "9241",
        "JobTitle": "hydro-electric generating station operator"
    },
    {
        "value": 19243,
        "NOCCode": "9241",
        "JobTitle": "hydro-electric power plant operator"
    },
    {
        "value": 19244,
        "NOCCode": "9241",
        "JobTitle": "load dispatcher – electrical power systems"
    },
    {
        "value": 19245,
        "NOCCode": "9241",
        "JobTitle": "load dispatcher apprentice – electrical power systems"
    },
    {
        "value": 19246,
        "NOCCode": "9241",
        "JobTitle": "mobile generator operator"
    },
    {
        "value": 19247,
        "NOCCode": "9241",
        "JobTitle": "nuclear generating station field operator"
    },
    {
        "value": 19248,
        "NOCCode": "9241",
        "JobTitle": "nuclear generating station operator"
    },
    {
        "value": 19249,
        "NOCCode": "9241",
        "JobTitle": "nuclear generating station operator – electrical power systems"
    },
    {
        "value": 19250,
        "NOCCode": "9241",
        "JobTitle": "nuclear reactor control room operator – electrical power systems"
    },
    {
        "value": 19251,
        "NOCCode": "9241",
        "JobTitle": "nuclear reactor operator"
    },
    {
        "value": 19252,
        "NOCCode": "9241",
        "JobTitle": "nuclear reactor operator – electrical power systems"
    },
    {
        "value": 19253,
        "NOCCode": "9241",
        "JobTitle": "nuclear station control room operator"
    },
    {
        "value": 19254,
        "NOCCode": "9241",
        "JobTitle": "operator, air conditioning systems"
    },
    {
        "value": 19255,
        "NOCCode": "9241",
        "JobTitle": "operator, boiler"
    },
    {
        "value": 19256,
        "NOCCode": "9241",
        "JobTitle": "operator, steam plant"
    },
    {
        "value": 19257,
        "NOCCode": "9241",
        "JobTitle": "plant maintenance engineer, stationary"
    },
    {
        "value": 19258,
        "NOCCode": "9241",
        "JobTitle": "plant maintenance stationary engineer"
    },
    {
        "value": 19259,
        "NOCCode": "9241",
        "JobTitle": "power control room operator – electrical power systems"
    },
    {
        "value": 19260,
        "NOCCode": "9241",
        "JobTitle": "power dispatcher"
    },
    {
        "value": 19261,
        "NOCCode": "9241",
        "JobTitle": "power dispatcher – electrical power systems"
    },
    {
        "value": 19262,
        "NOCCode": "9241",
        "JobTitle": "power dispatcher – generating station"
    },
    {
        "value": 19263,
        "NOCCode": "9241",
        "JobTitle": "power engineer"
    },
    {
        "value": 19264,
        "NOCCode": "9241",
        "JobTitle": "power engineer apprentice"
    },
    {
        "value": 19265,
        "NOCCode": "9241",
        "JobTitle": "power engineer, first class"
    },
    {
        "value": 19266,
        "NOCCode": "9241",
        "JobTitle": "power engineer, fourth class"
    },
    {
        "value": 19267,
        "NOCCode": "9241",
        "JobTitle": "power engineer, second class"
    },
    {
        "value": 19268,
        "NOCCode": "9241",
        "JobTitle": "power engineer, third class"
    },
    {
        "value": 19269,
        "NOCCode": "9241",
        "JobTitle": "power plant operator"
    },
    {
        "value": 19270,
        "NOCCode": "9241",
        "JobTitle": "power plant operator – electrical power systems"
    },
    {
        "value": 19271,
        "NOCCode": "9241",
        "JobTitle": "power plant stationary engineer"
    },
    {
        "value": 19272,
        "NOCCode": "9241",
        "JobTitle": "power reactor operator – electrical power systems"
    },
    {
        "value": 19273,
        "NOCCode": "9241",
        "JobTitle": "power station operator – electrical power systems"
    },
    {
        "value": 19274,
        "NOCCode": "9241",
        "JobTitle": "power substation operator"
    },
    {
        "value": 19275,
        "NOCCode": "9241",
        "JobTitle": "power switchboard operator – electrical power systems"
    },
    {
        "value": 19276,
        "NOCCode": "9241",
        "JobTitle": "power switchboard operator apprentice – electrical power systems"
    },
    {
        "value": 19277,
        "NOCCode": "9241",
        "JobTitle": "power system operator"
    },
    {
        "value": 19278,
        "NOCCode": "9241",
        "JobTitle": "power system operator – offshore drilling"
    },
    {
        "value": 19279,
        "NOCCode": "9241",
        "JobTitle": "powerhouse operator"
    },
    {
        "value": 19280,
        "NOCCode": "9241",
        "JobTitle": "rectifier operator – electrical power systems"
    },
    {
        "value": 19281,
        "NOCCode": "9241",
        "JobTitle": "refrigeration plant operator"
    },
    {
        "value": 19282,
        "NOCCode": "9241",
        "JobTitle": "second-class stationary engineer"
    },
    {
        "value": 19283,
        "NOCCode": "9241",
        "JobTitle": "second-lass power engineer"
    },
    {
        "value": 19284,
        "NOCCode": "9241",
        "JobTitle": "station operator – electrical power systems"
    },
    {
        "value": 19285,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer"
    },
    {
        "value": 19286,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer \"A\" and refrigeration plant operator"
    },
    {
        "value": 19287,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer \"B\" and refrigeration plant operator"
    },
    {
        "value": 19288,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer apprentice"
    },
    {
        "value": 19289,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, fifth class"
    },
    {
        "value": 19290,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, first class"
    },
    {
        "value": 19291,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, fourth class"
    },
    {
        "value": 19292,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, hospital"
    },
    {
        "value": 19293,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, plant maintenance"
    },
    {
        "value": 19294,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, second class"
    },
    {
        "value": 19295,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, steam power plant"
    },
    {
        "value": 19296,
        "NOCCode": "9241",
        "JobTitle": "stationary engineer, third class"
    },
    {
        "value": 19297,
        "NOCCode": "9241",
        "JobTitle": "stationary operating engineer"
    },
    {
        "value": 19298,
        "NOCCode": "9241",
        "JobTitle": "stationary power engineer"
    },
    {
        "value": 19299,
        "NOCCode": "9241",
        "JobTitle": "steam operator"
    },
    {
        "value": 19300,
        "NOCCode": "9241",
        "JobTitle": "steam plant operator"
    },
    {
        "value": 19301,
        "NOCCode": "9241",
        "JobTitle": "steam power plant stationary engineer"
    },
    {
        "value": 19302,
        "NOCCode": "9241",
        "JobTitle": "steam turbine operator – electrical power systems"
    },
    {
        "value": 19303,
        "NOCCode": "9241",
        "JobTitle": "substation inspector – electrical power systems"
    },
    {
        "value": 19304,
        "NOCCode": "9241",
        "JobTitle": "substation operator"
    },
    {
        "value": 19305,
        "NOCCode": "9241",
        "JobTitle": "substation operator – electrical power systems"
    },
    {
        "value": 19306,
        "NOCCode": "9241",
        "JobTitle": "system control centre operator – electrical power systems"
    },
    {
        "value": 19307,
        "NOCCode": "9241",
        "JobTitle": "system controller – electrical power systems"
    },
    {
        "value": 19308,
        "NOCCode": "9241",
        "JobTitle": "system operator – electrical power systems"
    },
    {
        "value": 19309,
        "NOCCode": "9241",
        "JobTitle": "technician, building systems"
    },
    {
        "value": 19310,
        "NOCCode": "9241",
        "JobTitle": "tender, heating and ventilation equipment"
    },
    {
        "value": 19311,
        "NOCCode": "9241",
        "JobTitle": "thermal plant operator"
    },
    {
        "value": 19312,
        "NOCCode": "9241",
        "JobTitle": "third-class power engineer"
    },
    {
        "value": 19313,
        "NOCCode": "9241",
        "JobTitle": "third-class stationary engineer"
    },
    {
        "value": 19314,
        "NOCCode": "9241",
        "JobTitle": "turbine operator – stationary engines"
    },
    {
        "value": 19315,
        "NOCCode": "9243",
        "JobTitle": "environmental systems operator – water treatment"
    },
    {
        "value": 19316,
        "NOCCode": "9243",
        "JobTitle": "filter plant operator – water treatment"
    },
    {
        "value": 19317,
        "NOCCode": "9243",
        "JobTitle": "filtration plant controller – water treatment"
    },
    {
        "value": 19318,
        "NOCCode": "9243",
        "JobTitle": "industrial waste treatment plant operator"
    },
    {
        "value": 19319,
        "NOCCode": "9243",
        "JobTitle": "liquid waste-processing plant, operator"
    },
    {
        "value": 19320,
        "NOCCode": "9243",
        "JobTitle": "operator, liquid waste treatment plant"
    },
    {
        "value": 19321,
        "NOCCode": "9243",
        "JobTitle": "operator, pumphouse – water treatment"
    },
    {
        "value": 19322,
        "NOCCode": "9243",
        "JobTitle": "operator, pumping station – water treatment"
    },
    {
        "value": 19323,
        "NOCCode": "9243",
        "JobTitle": "operator, sewage plant"
    },
    {
        "value": 19324,
        "NOCCode": "9243",
        "JobTitle": "operator, wastewater treatment plant"
    },
    {
        "value": 19325,
        "NOCCode": "9243",
        "JobTitle": "operator, water filtration plant"
    },
    {
        "value": 19326,
        "NOCCode": "9243",
        "JobTitle": "operator, water plant pump"
    },
    {
        "value": 19327,
        "NOCCode": "9243",
        "JobTitle": "operator, water purification plant"
    },
    {
        "value": 19328,
        "NOCCode": "9243",
        "JobTitle": "operator, water treatment plant"
    },
    {
        "value": 19329,
        "NOCCode": "9243",
        "JobTitle": "operator, waterworks pumping station"
    },
    {
        "value": 19330,
        "NOCCode": "9243",
        "JobTitle": "process operator, liquid waste"
    },
    {
        "value": 19331,
        "NOCCode": "9243",
        "JobTitle": "process technician, water treatment plant"
    },
    {
        "value": 19332,
        "NOCCode": "9243",
        "JobTitle": "processing plant operator, liquid waste"
    },
    {
        "value": 19333,
        "NOCCode": "9243",
        "JobTitle": "pump operator, water plant"
    },
    {
        "value": 19334,
        "NOCCode": "9243",
        "JobTitle": "pump station operator – water treatment"
    },
    {
        "value": 19335,
        "NOCCode": "9243",
        "JobTitle": "pumphouse operator – water treatment"
    },
    {
        "value": 19336,
        "NOCCode": "9243",
        "JobTitle": "sewage plant operator"
    },
    {
        "value": 19337,
        "NOCCode": "9243",
        "JobTitle": "sewage-processing equipment tender"
    },
    {
        "value": 19338,
        "NOCCode": "9243",
        "JobTitle": "systems operator, water treatment"
    },
    {
        "value": 19339,
        "NOCCode": "9243",
        "JobTitle": "tender, sewage-processing equipment"
    },
    {
        "value": 19340,
        "NOCCode": "9243",
        "JobTitle": "waste treatment plant operator"
    },
    {
        "value": 19341,
        "NOCCode": "9243",
        "JobTitle": "wastewater collection operator"
    },
    {
        "value": 19342,
        "NOCCode": "9243",
        "JobTitle": "wastewater collection systems operator"
    },
    {
        "value": 19343,
        "NOCCode": "9243",
        "JobTitle": "wastewater treatment operator"
    },
    {
        "value": 19344,
        "NOCCode": "9243",
        "JobTitle": "wastewater treatment plant operator"
    },
    {
        "value": 19345,
        "NOCCode": "9243",
        "JobTitle": "wastewater treatment plant worker"
    },
    {
        "value": 19346,
        "NOCCode": "9243",
        "JobTitle": "water and wastewater technician"
    },
    {
        "value": 19347,
        "NOCCode": "9243",
        "JobTitle": "water filtration plant operator"
    },
    {
        "value": 19348,
        "NOCCode": "9243",
        "JobTitle": "water gate operator"
    },
    {
        "value": 19349,
        "NOCCode": "9243",
        "JobTitle": "water plant pump operator"
    },
    {
        "value": 19350,
        "NOCCode": "9243",
        "JobTitle": "water treatment operator"
    },
    {
        "value": 19351,
        "NOCCode": "9243",
        "JobTitle": "water treatment plant operator"
    },
    {
        "value": 19352,
        "NOCCode": "9243",
        "JobTitle": "water treatment plant process technician"
    },
    {
        "value": 19353,
        "NOCCode": "9243",
        "JobTitle": "water treatment systems operator"
    },
    {
        "value": 19354,
        "NOCCode": "9243",
        "JobTitle": "water utility operator"
    },
    {
        "value": 19355,
        "NOCCode": "9243",
        "JobTitle": "waterworks pump house operator"
    },
    {
        "value": 19356,
        "NOCCode": "9243",
        "JobTitle": "well-point pump operator"
    },
    {
        "value": 19357,
        "NOCCode": "9411",
        "JobTitle": "agglomerating attendant, iron ore pellets"
    },
    {
        "value": 19358,
        "NOCCode": "9411",
        "JobTitle": "alumina clarifier operator"
    },
    {
        "value": 19359,
        "NOCCode": "9411",
        "JobTitle": "alumina recovery operator"
    },
    {
        "value": 19360,
        "NOCCode": "9411",
        "JobTitle": "aluminum classifier"
    },
    {
        "value": 19361,
        "NOCCode": "9411",
        "JobTitle": "aluminum electrolytic tank reconditioner"
    },
    {
        "value": 19362,
        "NOCCode": "9411",
        "JobTitle": "aluminum electrolytic tank tender"
    },
    {
        "value": 19363,
        "NOCCode": "9411",
        "JobTitle": "aluminum oxide controller"
    },
    {
        "value": 19364,
        "NOCCode": "9411",
        "JobTitle": "aluminum pourer – primary metal processing"
    },
    {
        "value": 19365,
        "NOCCode": "9411",
        "JobTitle": "aluminum powder maker"
    },
    {
        "value": 19366,
        "NOCCode": "9411",
        "JobTitle": "aluminum slurry pump tender"
    },
    {
        "value": 19367,
        "NOCCode": "9411",
        "JobTitle": "aluminum stretcher-leveller operator"
    },
    {
        "value": 19368,
        "NOCCode": "9411",
        "JobTitle": "aluminum-briquetting equipment operator"
    },
    {
        "value": 19369,
        "NOCCode": "9411",
        "JobTitle": "aluminum-casting machine operator – primary metal processing"
    },
    {
        "value": 19370,
        "NOCCode": "9411",
        "JobTitle": "amalgamation process tender"
    },
    {
        "value": 19371,
        "NOCCode": "9411",
        "JobTitle": "annealer – primary metal processing"
    },
    {
        "value": 19372,
        "NOCCode": "9411",
        "JobTitle": "anode caster"
    },
    {
        "value": 19373,
        "NOCCode": "9411",
        "JobTitle": "anode caster – primary metal processing"
    },
    {
        "value": 19374,
        "NOCCode": "9411",
        "JobTitle": "anode operator"
    },
    {
        "value": 19375,
        "NOCCode": "9411",
        "JobTitle": "anode press operator"
    },
    {
        "value": 19376,
        "NOCCode": "9411",
        "JobTitle": "arsenic recovery operator"
    },
    {
        "value": 19377,
        "NOCCode": "9411",
        "JobTitle": "asbestos dryer"
    },
    {
        "value": 19378,
        "NOCCode": "9411",
        "JobTitle": "asbestos screen tender"
    },
    {
        "value": 19379,
        "NOCCode": "9411",
        "JobTitle": "auxiliary equipment operator – primary metal processing"
    },
    {
        "value": 19380,
        "NOCCode": "9411",
        "JobTitle": "baghouse operator"
    },
    {
        "value": 19381,
        "NOCCode": "9411",
        "JobTitle": "ball mill operator"
    },
    {
        "value": 19382,
        "NOCCode": "9411",
        "JobTitle": "bar-straightening machine operator – metal processing"
    },
    {
        "value": 19383,
        "NOCCode": "9411",
        "JobTitle": "billet caster, zinc"
    },
    {
        "value": 19384,
        "NOCCode": "9411",
        "JobTitle": "billet heater"
    },
    {
        "value": 19385,
        "NOCCode": "9411",
        "JobTitle": "blast furnace tapper"
    },
    {
        "value": 19386,
        "NOCCode": "9411",
        "JobTitle": "blender tender – primary metal and mineral products processing"
    },
    {
        "value": 19387,
        "NOCCode": "9411",
        "JobTitle": "brick and tile crusher operator"
    },
    {
        "value": 19388,
        "NOCCode": "9411",
        "JobTitle": "brine maker – mineral products processing"
    },
    {
        "value": 19389,
        "NOCCode": "9411",
        "JobTitle": "briquetting machine operator"
    },
    {
        "value": 19390,
        "NOCCode": "9411",
        "JobTitle": "bulk cement-blender tender"
    },
    {
        "value": 19391,
        "NOCCode": "9411",
        "JobTitle": "cadmium control operator – zinc leaching"
    },
    {
        "value": 19392,
        "NOCCode": "9411",
        "JobTitle": "cadmium plant operator"
    },
    {
        "value": 19393,
        "NOCCode": "9411",
        "JobTitle": "cadmium recoverer"
    },
    {
        "value": 19394,
        "NOCCode": "9411",
        "JobTitle": "cadmium zinc leacher"
    },
    {
        "value": 19395,
        "NOCCode": "9411",
        "JobTitle": "calciner operator – mineral products processing"
    },
    {
        "value": 19396,
        "NOCCode": "9411",
        "JobTitle": "case hardener – primary metal processing"
    },
    {
        "value": 19397,
        "NOCCode": "9411",
        "JobTitle": "casting machine operator – primary metal processing"
    },
    {
        "value": 19398,
        "NOCCode": "9411",
        "JobTitle": "cathode-stripping machine operator – zinc electrolysis"
    },
    {
        "value": 19399,
        "NOCCode": "9411",
        "JobTitle": "cell operator – primary metal and mineral processing"
    },
    {
        "value": 19400,
        "NOCCode": "9411",
        "JobTitle": "cement miller"
    },
    {
        "value": 19401,
        "NOCCode": "9411",
        "JobTitle": "cement mixer – primary metal and mineral products processing"
    },
    {
        "value": 19402,
        "NOCCode": "9411",
        "JobTitle": "cement pumping tender"
    },
    {
        "value": 19403,
        "NOCCode": "9411",
        "JobTitle": "cementation equipment operator"
    },
    {
        "value": 19404,
        "NOCCode": "9411",
        "JobTitle": "charge control operator"
    },
    {
        "value": 19405,
        "NOCCode": "9411",
        "JobTitle": "charging car operator"
    },
    {
        "value": 19406,
        "NOCCode": "9411",
        "JobTitle": "charging machine operator – mineral and metal processing"
    },
    {
        "value": 19407,
        "NOCCode": "9411",
        "JobTitle": "classifier operator"
    },
    {
        "value": 19408,
        "NOCCode": "9411",
        "JobTitle": "coal breaker"
    },
    {
        "value": 19409,
        "NOCCode": "9411",
        "JobTitle": "coal screener"
    },
    {
        "value": 19410,
        "NOCCode": "9411",
        "JobTitle": "coal separator"
    },
    {
        "value": 19411,
        "NOCCode": "9411",
        "JobTitle": "coal washer"
    },
    {
        "value": 19412,
        "NOCCode": "9411",
        "JobTitle": "coarse salt equipment operator"
    },
    {
        "value": 19413,
        "NOCCode": "9411",
        "JobTitle": "coiler operator – primary metal processing"
    },
    {
        "value": 19414,
        "NOCCode": "9411",
        "JobTitle": "coke by-products pump operator – primary metal processing"
    },
    {
        "value": 19415,
        "NOCCode": "9411",
        "JobTitle": "coke grinder"
    },
    {
        "value": 19416,
        "NOCCode": "9411",
        "JobTitle": "coke oven door operator"
    },
    {
        "value": 19417,
        "NOCCode": "9411",
        "JobTitle": "coke oven operator"
    },
    {
        "value": 19418,
        "NOCCode": "9411",
        "JobTitle": "coke screen tender"
    },
    {
        "value": 19419,
        "NOCCode": "9411",
        "JobTitle": "coke-screen and coke-blender tender"
    },
    {
        "value": 19420,
        "NOCCode": "9411",
        "JobTitle": "cold steel roller – steel production"
    },
    {
        "value": 19421,
        "NOCCode": "9411",
        "JobTitle": "cold steel rolling mill operator"
    },
    {
        "value": 19422,
        "NOCCode": "9411",
        "JobTitle": "concrete batch plant operator – cement manufacturing"
    },
    {
        "value": 19423,
        "NOCCode": "9411",
        "JobTitle": "condenser setter – primary metal processing"
    },
    {
        "value": 19424,
        "NOCCode": "9411",
        "JobTitle": "cone tender"
    },
    {
        "value": 19425,
        "NOCCode": "9411",
        "JobTitle": "continuous drossing operator"
    },
    {
        "value": 19426,
        "NOCCode": "9411",
        "JobTitle": "cooling bed operator – steel mill"
    },
    {
        "value": 19427,
        "NOCCode": "9411",
        "JobTitle": "copper billet piercer"
    },
    {
        "value": 19428,
        "NOCCode": "9411",
        "JobTitle": "copper billet piercing mill operator"
    },
    {
        "value": 19429,
        "NOCCode": "9411",
        "JobTitle": "copper flotation operator"
    },
    {
        "value": 19430,
        "NOCCode": "9411",
        "JobTitle": "copper ore slime treater"
    },
    {
        "value": 19431,
        "NOCCode": "9411",
        "JobTitle": "copper-briquetting machine operator"
    },
    {
        "value": 19432,
        "NOCCode": "9411",
        "JobTitle": "Cottrell tender"
    },
    {
        "value": 19433,
        "NOCCode": "9411",
        "JobTitle": "crane ladle pourer"
    },
    {
        "value": 19434,
        "NOCCode": "9411",
        "JobTitle": "crucible pourer"
    },
    {
        "value": 19435,
        "NOCCode": "9411",
        "JobTitle": "crusher and blender operator"
    },
    {
        "value": 19436,
        "NOCCode": "9411",
        "JobTitle": "crusher setter"
    },
    {
        "value": 19437,
        "NOCCode": "9411",
        "JobTitle": "crushing equipment operator"
    },
    {
        "value": 19438,
        "NOCCode": "9411",
        "JobTitle": "cryolite recovery equipment operator"
    },
    {
        "value": 19439,
        "NOCCode": "9411",
        "JobTitle": "dental amalgam processor"
    },
    {
        "value": 19440,
        "NOCCode": "9411",
        "JobTitle": "dewaterer tender – primary metal processing"
    },
    {
        "value": 19441,
        "NOCCode": "9411",
        "JobTitle": "dewatering equipment operator"
    },
    {
        "value": 19442,
        "NOCCode": "9411",
        "JobTitle": "diamond powder blender"
    },
    {
        "value": 19443,
        "NOCCode": "9411",
        "JobTitle": "digester operator – primary metal and mineral processing"
    },
    {
        "value": 19444,
        "NOCCode": "9411",
        "JobTitle": "discharge door attendant"
    },
    {
        "value": 19445,
        "NOCCode": "9411",
        "JobTitle": "dross treatment operator – zinc melting"
    },
    {
        "value": 19446,
        "NOCCode": "9411",
        "JobTitle": "dryer operator – mineral and metal processing"
    },
    {
        "value": 19447,
        "NOCCode": "9411",
        "JobTitle": "dust operator"
    },
    {
        "value": 19448,
        "NOCCode": "9411",
        "JobTitle": "dust pump operator – slag furnace"
    },
    {
        "value": 19449,
        "NOCCode": "9411",
        "JobTitle": "electrolytic cleaner operator – primary metal processing"
    },
    {
        "value": 19450,
        "NOCCode": "9411",
        "JobTitle": "electrolytic descaler – primary metal processing"
    },
    {
        "value": 19451,
        "NOCCode": "9411",
        "JobTitle": "electrolytic tank maintainer – primary metal and mineral products processing"
    },
    {
        "value": 19452,
        "NOCCode": "9411",
        "JobTitle": "electrostatic separator tender – primary metal processing"
    },
    {
        "value": 19453,
        "NOCCode": "9411",
        "JobTitle": "extrusion press operator"
    },
    {
        "value": 19454,
        "NOCCode": "9411",
        "JobTitle": "finisher – primary metal processing"
    },
    {
        "value": 19455,
        "NOCCode": "9411",
        "JobTitle": "flame annealing tender – primary metal processing"
    },
    {
        "value": 19456,
        "NOCCode": "9411",
        "JobTitle": "flotation cell tender – primary metal processing"
    },
    {
        "value": 19457,
        "NOCCode": "9411",
        "JobTitle": "fluorspar recovery tender"
    },
    {
        "value": 19458,
        "NOCCode": "9411",
        "JobTitle": "flux-mixing machine tender – metal processing"
    },
    {
        "value": 19459,
        "NOCCode": "9411",
        "JobTitle": "foil-winding machine operator"
    },
    {
        "value": 19460,
        "NOCCode": "9411",
        "JobTitle": "foil-winding machine operator – primary metal processing"
    },
    {
        "value": 19461,
        "NOCCode": "9411",
        "JobTitle": "fume scrubber operator"
    },
    {
        "value": 19462,
        "NOCCode": "9411",
        "JobTitle": "furnace charger – mineral and metal processing"
    },
    {
        "value": 19463,
        "NOCCode": "9411",
        "JobTitle": "gold recoverer"
    },
    {
        "value": 19464,
        "NOCCode": "9411",
        "JobTitle": "granulator operator – phosphate"
    },
    {
        "value": 19465,
        "NOCCode": "9411",
        "JobTitle": "grinder and classifier tender"
    },
    {
        "value": 19466,
        "NOCCode": "9411",
        "JobTitle": "grinding and flotation tender"
    },
    {
        "value": 19467,
        "NOCCode": "9411",
        "JobTitle": "gypsum calciner"
    },
    {
        "value": 19468,
        "NOCCode": "9411",
        "JobTitle": "hardener – primary metal processing"
    },
    {
        "value": 19469,
        "NOCCode": "9411",
        "JobTitle": "heater, coke oven"
    },
    {
        "value": 19470,
        "NOCCode": "9411",
        "JobTitle": "heat-treat furnace operator – primary metal processing"
    },
    {
        "value": 19471,
        "NOCCode": "9411",
        "JobTitle": "heavy media separation tender – mineral and metal processing"
    },
    {
        "value": 19472,
        "NOCCode": "9411",
        "JobTitle": "hot strip finishing mill tender – primary metal processing"
    },
    {
        "value": 19473,
        "NOCCode": "9411",
        "JobTitle": "hot strip roughing mill tender – primary metal processing"
    },
    {
        "value": 19474,
        "NOCCode": "9411",
        "JobTitle": "hydrate thickener operator"
    },
    {
        "value": 19475,
        "NOCCode": "9411",
        "JobTitle": "indium refiner"
    },
    {
        "value": 19476,
        "NOCCode": "9411",
        "JobTitle": "induction machine setter – primary metal processing"
    },
    {
        "value": 19477,
        "NOCCode": "9411",
        "JobTitle": "induction machine tender – primary metal processing"
    },
    {
        "value": 19478,
        "NOCCode": "9411",
        "JobTitle": "induration attendant – iron ore"
    },
    {
        "value": 19479,
        "NOCCode": "9411",
        "JobTitle": "ingot buggy operator"
    },
    {
        "value": 19480,
        "NOCCode": "9411",
        "JobTitle": "ingot finishing operator"
    },
    {
        "value": 19481,
        "NOCCode": "9411",
        "JobTitle": "ingot header"
    },
    {
        "value": 19482,
        "NOCCode": "9411",
        "JobTitle": "jumbo casting operator – zinc melting"
    },
    {
        "value": 19483,
        "NOCCode": "9411",
        "JobTitle": "kettle charger"
    },
    {
        "value": 19484,
        "NOCCode": "9411",
        "JobTitle": "kettle tender – primary metal processing"
    },
    {
        "value": 19485,
        "NOCCode": "9411",
        "JobTitle": "ladle operator – primary metal processing"
    },
    {
        "value": 19486,
        "NOCCode": "9411",
        "JobTitle": "ladle pourer"
    },
    {
        "value": 19487,
        "NOCCode": "9411",
        "JobTitle": "ladle pourer – primary metal processing"
    },
    {
        "value": 19488,
        "NOCCode": "9411",
        "JobTitle": "larry car operator"
    },
    {
        "value": 19489,
        "NOCCode": "9411",
        "JobTitle": "leaching operator – primary metal processing"
    },
    {
        "value": 19490,
        "NOCCode": "9411",
        "JobTitle": "lead and copper refiner"
    },
    {
        "value": 19491,
        "NOCCode": "9411",
        "JobTitle": "lead caster – primary metal processing"
    },
    {
        "value": 19492,
        "NOCCode": "9411",
        "JobTitle": "lead dust treater"
    },
    {
        "value": 19493,
        "NOCCode": "9411",
        "JobTitle": "lead extruder operator – primary metal processing"
    },
    {
        "value": 19494,
        "NOCCode": "9411",
        "JobTitle": "lead refiner"
    },
    {
        "value": 19495,
        "NOCCode": "9411",
        "JobTitle": "lead wheel operator"
    },
    {
        "value": 19496,
        "NOCCode": "9411",
        "JobTitle": "lead-casting machine operator – primary metal processing"
    },
    {
        "value": 19497,
        "NOCCode": "9411",
        "JobTitle": "lead-melting furnace tender – metal processing"
    },
    {
        "value": 19498,
        "NOCCode": "9411",
        "JobTitle": "lead-melting kettle tender – metal processing"
    },
    {
        "value": 19499,
        "NOCCode": "9411",
        "JobTitle": "lead-smelting furnace tender – metal processing"
    },
    {
        "value": 19500,
        "NOCCode": "9411",
        "JobTitle": "lead-smelting kettle tender – metal processing"
    },
    {
        "value": 19501,
        "NOCCode": "9411",
        "JobTitle": "lime kiln operator"
    },
    {
        "value": 19502,
        "NOCCode": "9411",
        "JobTitle": "lime kiln operator – mineral and metal processing"
    },
    {
        "value": 19503,
        "NOCCode": "9411",
        "JobTitle": "machine drawer, wires"
    },
    {
        "value": 19504,
        "NOCCode": "9411",
        "JobTitle": "machine operator – mineral and metal processing"
    },
    {
        "value": 19505,
        "NOCCode": "9411",
        "JobTitle": "machine wire drawer"
    },
    {
        "value": 19506,
        "NOCCode": "9411",
        "JobTitle": "magnetic separator operator – primary metal processing"
    },
    {
        "value": 19507,
        "NOCCode": "9411",
        "JobTitle": "manipulator machine operator – primary metal processing"
    },
    {
        "value": 19508,
        "NOCCode": "9411",
        "JobTitle": "metal annealing tender"
    },
    {
        "value": 19509,
        "NOCCode": "9411",
        "JobTitle": "metal extrusion press operator – primary metal processing"
    },
    {
        "value": 19510,
        "NOCCode": "9411",
        "JobTitle": "metal hardener – primary metal processing"
    },
    {
        "value": 19511,
        "NOCCode": "9411",
        "JobTitle": "metal mixer – primary metal processing"
    },
    {
        "value": 19512,
        "NOCCode": "9411",
        "JobTitle": "metal mixer operator – primary metal processing"
    },
    {
        "value": 19513,
        "NOCCode": "9411",
        "JobTitle": "metal pointer operator – primary metal processing"
    },
    {
        "value": 19514,
        "NOCCode": "9411",
        "JobTitle": "metal pourer – metal processing"
    },
    {
        "value": 19515,
        "NOCCode": "9411",
        "JobTitle": "metal reclaimer"
    },
    {
        "value": 19516,
        "NOCCode": "9411",
        "JobTitle": "mica crusher tender"
    },
    {
        "value": 19517,
        "NOCCode": "9411",
        "JobTitle": "mica plate tender"
    },
    {
        "value": 19518,
        "NOCCode": "9411",
        "JobTitle": "mica press tender"
    },
    {
        "value": 19519,
        "NOCCode": "9411",
        "JobTitle": "mica sheet laminator"
    },
    {
        "value": 19520,
        "NOCCode": "9411",
        "JobTitle": "mica sheet salvager"
    },
    {
        "value": 19521,
        "NOCCode": "9411",
        "JobTitle": "mica splitter"
    },
    {
        "value": 19522,
        "NOCCode": "9411",
        "JobTitle": "mica trimmer"
    },
    {
        "value": 19523,
        "NOCCode": "9411",
        "JobTitle": "milling machine operator – mineral and metal processing"
    },
    {
        "value": 19524,
        "NOCCode": "9411",
        "JobTitle": "mint precious metal refiner – primary metal processing"
    },
    {
        "value": 19525,
        "NOCCode": "9411",
        "JobTitle": "mixer operator, molten metal"
    },
    {
        "value": 19526,
        "NOCCode": "9411",
        "JobTitle": "molten metal mixer operator"
    },
    {
        "value": 19527,
        "NOCCode": "9411",
        "JobTitle": "molybdenum flotation operator"
    },
    {
        "value": 19528,
        "NOCCode": "9411",
        "JobTitle": "molybdenum leaching plant operator"
    },
    {
        "value": 19529,
        "NOCCode": "9411",
        "JobTitle": "operator, anode paste plant"
    },
    {
        "value": 19530,
        "NOCCode": "9411",
        "JobTitle": "operator, anode press"
    },
    {
        "value": 19531,
        "NOCCode": "9411",
        "JobTitle": "operator, coke calciner"
    },
    {
        "value": 19532,
        "NOCCode": "9411",
        "JobTitle": "operator, coke oven door"
    },
    {
        "value": 19533,
        "NOCCode": "9411",
        "JobTitle": "operator, scrubber"
    },
    {
        "value": 19534,
        "NOCCode": "9411",
        "JobTitle": "ore crushing worker"
    },
    {
        "value": 19535,
        "NOCCode": "9411",
        "JobTitle": "ore dryer operator"
    },
    {
        "value": 19536,
        "NOCCode": "9411",
        "JobTitle": "ore grinding worker"
    },
    {
        "value": 19537,
        "NOCCode": "9411",
        "JobTitle": "ore milling worker"
    },
    {
        "value": 19538,
        "NOCCode": "9411",
        "JobTitle": "ore screen tender"
    },
    {
        "value": 19539,
        "NOCCode": "9411",
        "JobTitle": "ore screener"
    },
    {
        "value": 19540,
        "NOCCode": "9411",
        "JobTitle": "ore separator tender – primary metal and mineral products processing"
    },
    {
        "value": 19541,
        "NOCCode": "9411",
        "JobTitle": "pan tender"
    },
    {
        "value": 19542,
        "NOCCode": "9411",
        "JobTitle": "peat moss dehydrator tender"
    },
    {
        "value": 19543,
        "NOCCode": "9411",
        "JobTitle": "pellet press tender – primary metal processing"
    },
    {
        "value": 19544,
        "NOCCode": "9411",
        "JobTitle": "pellet segregator – primary metal processing"
    },
    {
        "value": 19545,
        "NOCCode": "9411",
        "JobTitle": "pelletizer, slag – primary metal processing"
    },
    {
        "value": 19546,
        "NOCCode": "9411",
        "JobTitle": "pickle line operator – primary metal processing"
    },
    {
        "value": 19547,
        "NOCCode": "9411",
        "JobTitle": "pickler operator – primary metal processing"
    },
    {
        "value": 19548,
        "NOCCode": "9411",
        "JobTitle": "piercing mill operator – primary metal processing"
    },
    {
        "value": 19549,
        "NOCCode": "9411",
        "JobTitle": "pig caster – primary metal processing"
    },
    {
        "value": 19550,
        "NOCCode": "9411",
        "JobTitle": "pig iron pourer"
    },
    {
        "value": 19551,
        "NOCCode": "9411",
        "JobTitle": "pipe and tube roll setter – primary metal processing"
    },
    {
        "value": 19552,
        "NOCCode": "9411",
        "JobTitle": "plate roller – primary metal processing"
    },
    {
        "value": 19553,
        "NOCCode": "9411",
        "JobTitle": "plate rolling mill operator – primary metal processing"
    },
    {
        "value": 19554,
        "NOCCode": "9411",
        "JobTitle": "pointer operator – primary metal processing"
    },
    {
        "value": 19555,
        "NOCCode": "9411",
        "JobTitle": "pot liner – metal processing"
    },
    {
        "value": 19556,
        "NOCCode": "9411",
        "JobTitle": "pot tender – primary metal processing"
    },
    {
        "value": 19557,
        "NOCCode": "9411",
        "JobTitle": "pourer – metal processing"
    },
    {
        "value": 19558,
        "NOCCode": "9411",
        "JobTitle": "precious metal recoverer"
    },
    {
        "value": 19559,
        "NOCCode": "9411",
        "JobTitle": "precipitator tender – primary metal processing"
    },
    {
        "value": 19560,
        "NOCCode": "9411",
        "JobTitle": "press operator, anodes"
    },
    {
        "value": 19561,
        "NOCCode": "9411",
        "JobTitle": "process assistant – zinc pressure leach"
    },
    {
        "value": 19562,
        "NOCCode": "9411",
        "JobTitle": "purification operator – zinc oxide leach"
    },
    {
        "value": 19563,
        "NOCCode": "9411",
        "JobTitle": "quenching car operator"
    },
    {
        "value": 19564,
        "NOCCode": "9411",
        "JobTitle": "reagent tender – primary metal processing"
    },
    {
        "value": 19565,
        "NOCCode": "9411",
        "JobTitle": "recovery equipment operator – primary metal and mineral processing"
    },
    {
        "value": 19566,
        "NOCCode": "9411",
        "JobTitle": "red mud thickener tender"
    },
    {
        "value": 19567,
        "NOCCode": "9411",
        "JobTitle": "reeling machine operator – primary metal processing"
    },
    {
        "value": 19568,
        "NOCCode": "9411",
        "JobTitle": "residual metal recoverer"
    },
    {
        "value": 19569,
        "NOCCode": "9411",
        "JobTitle": "residue filter operator – zinc oxide leach"
    },
    {
        "value": 19570,
        "NOCCode": "9411",
        "JobTitle": "reversing mill operator – primary metal processing"
    },
    {
        "value": 19571,
        "NOCCode": "9411",
        "JobTitle": "reversing rolling mill operator – primary metal processing"
    },
    {
        "value": 19572,
        "NOCCode": "9411",
        "JobTitle": "rivet heater – primary metal processing"
    },
    {
        "value": 19573,
        "NOCCode": "9411",
        "JobTitle": "rock crusher tender – primary metal and mineral products processing"
    },
    {
        "value": 19574,
        "NOCCode": "9411",
        "JobTitle": "rod drawer – primary metal processing"
    },
    {
        "value": 19575,
        "NOCCode": "9411",
        "JobTitle": "rod mill operator – primary metal processing"
    },
    {
        "value": 19576,
        "NOCCode": "9411",
        "JobTitle": "roll setter – primary metal processing"
    },
    {
        "value": 19577,
        "NOCCode": "9411",
        "JobTitle": "roll table operator – primary metal processing"
    },
    {
        "value": 19578,
        "NOCCode": "9411",
        "JobTitle": "roller – primary metal processing"
    },
    {
        "value": 19579,
        "NOCCode": "9411",
        "JobTitle": "roller plate mill – primary metal processing"
    },
    {
        "value": 19580,
        "NOCCode": "9411",
        "JobTitle": "roller-leveller operator"
    },
    {
        "value": 19581,
        "NOCCode": "9411",
        "JobTitle": "rolling mill guide setter – metal processing"
    },
    {
        "value": 19582,
        "NOCCode": "9411",
        "JobTitle": "rolling mill operator – mineral and metal processing"
    },
    {
        "value": 19583,
        "NOCCode": "9411",
        "JobTitle": "rolling mill preparer – primary metal processing"
    },
    {
        "value": 19584,
        "NOCCode": "9411",
        "JobTitle": "roll-straightener operator – primary metal processing"
    },
    {
        "value": 19585,
        "NOCCode": "9411",
        "JobTitle": "rotary kiln operator – primary metal and mineral products processing"
    },
    {
        "value": 19586,
        "NOCCode": "9411",
        "JobTitle": "rotary kiln tender – primary metal processing"
    },
    {
        "value": 19587,
        "NOCCode": "9411",
        "JobTitle": "roughing mill operator – metal processing"
    },
    {
        "value": 19588,
        "NOCCode": "9411",
        "JobTitle": "salt compactor operator"
    },
    {
        "value": 19589,
        "NOCCode": "9411",
        "JobTitle": "salt compressor operator"
    },
    {
        "value": 19590,
        "NOCCode": "9411",
        "JobTitle": "salt crusher"
    },
    {
        "value": 19591,
        "NOCCode": "9411",
        "JobTitle": "salt processor"
    },
    {
        "value": 19592,
        "NOCCode": "9411",
        "JobTitle": "salt refiner"
    },
    {
        "value": 19593,
        "NOCCode": "9411",
        "JobTitle": "scalehouse operator – zinc melting"
    },
    {
        "value": 19594,
        "NOCCode": "9411",
        "JobTitle": "scrap metal remelter – primary metal processing"
    },
    {
        "value": 19595,
        "NOCCode": "9411",
        "JobTitle": "scrap metal shredder"
    },
    {
        "value": 19596,
        "NOCCode": "9411",
        "JobTitle": "scrap metal shredder operator – primary metal processing"
    },
    {
        "value": 19597,
        "NOCCode": "9411",
        "JobTitle": "screen tender, coke"
    },
    {
        "value": 19598,
        "NOCCode": "9411",
        "JobTitle": "seamless steel billet piercer – primary metal processing"
    },
    {
        "value": 19599,
        "NOCCode": "9411",
        "JobTitle": "seamless tube roller – primary metal processing"
    },
    {
        "value": 19600,
        "NOCCode": "9411",
        "JobTitle": "sheet roller – primary metal processing"
    },
    {
        "value": 19601,
        "NOCCode": "9411",
        "JobTitle": "sheet rolling mill operator – primary metal processing"
    },
    {
        "value": 19602,
        "NOCCode": "9411",
        "JobTitle": "sheet rougher – primary metal processing"
    },
    {
        "value": 19603,
        "NOCCode": "9411",
        "JobTitle": "sink-float equipment operator – metal processing"
    },
    {
        "value": 19604,
        "NOCCode": "9411",
        "JobTitle": "sintering machine operator"
    },
    {
        "value": 19605,
        "NOCCode": "9411",
        "JobTitle": "slab caster – steel rolling mill"
    },
    {
        "value": 19606,
        "NOCCode": "9411",
        "JobTitle": "slag pelletizer – primary metal processing"
    },
    {
        "value": 19607,
        "NOCCode": "9411",
        "JobTitle": "slime recoverer – primary metal processing"
    },
    {
        "value": 19608,
        "NOCCode": "9411",
        "JobTitle": "slime recovery operator – primary metal processing"
    },
    {
        "value": 19609,
        "NOCCode": "9411",
        "JobTitle": "slime treater – primary metal processing"
    },
    {
        "value": 19610,
        "NOCCode": "9411",
        "JobTitle": "slurry equipment operator"
    },
    {
        "value": 19611,
        "NOCCode": "9411",
        "JobTitle": "slurry equipment operator – primary metal and mineral products processing"
    },
    {
        "value": 19612,
        "NOCCode": "9411",
        "JobTitle": "solution operator – primary metal and mineral processing"
    },
    {
        "value": 19613,
        "NOCCode": "9411",
        "JobTitle": "spiral conveyor attendant"
    },
    {
        "value": 19614,
        "NOCCode": "9411",
        "JobTitle": "stack dust leach operator – zinc casting"
    },
    {
        "value": 19615,
        "NOCCode": "9411",
        "JobTitle": "steel pourer"
    },
    {
        "value": 19616,
        "NOCCode": "9411",
        "JobTitle": "steel roller"
    },
    {
        "value": 19617,
        "NOCCode": "9411",
        "JobTitle": "steel rougher"
    },
    {
        "value": 19618,
        "NOCCode": "9411",
        "JobTitle": "steel straightener operator"
    },
    {
        "value": 19619,
        "NOCCode": "9411",
        "JobTitle": "steel treater – primary metal processing"
    },
    {
        "value": 19620,
        "NOCCode": "9411",
        "JobTitle": "straightening feeder operator"
    },
    {
        "value": 19621,
        "NOCCode": "9411",
        "JobTitle": "straightening machine operator"
    },
    {
        "value": 19622,
        "NOCCode": "9411",
        "JobTitle": "stretcher-leveller operator – primary metal processing"
    },
    {
        "value": 19623,
        "NOCCode": "9411",
        "JobTitle": "sulphur control operator – zinc pressure leach"
    },
    {
        "value": 19624,
        "NOCCode": "9411",
        "JobTitle": "tailings thickener tender – primary metal processing"
    },
    {
        "value": 19625,
        "NOCCode": "9411",
        "JobTitle": "tandem roller – primary metal processing"
    },
    {
        "value": 19626,
        "NOCCode": "9411",
        "JobTitle": "tank tender"
    },
    {
        "value": 19627,
        "NOCCode": "9411",
        "JobTitle": "tank tender – primary metal processing"
    },
    {
        "value": 19628,
        "NOCCode": "9411",
        "JobTitle": "tankhouse equipment operator – primary metal processing"
    },
    {
        "value": 19629,
        "NOCCode": "9411",
        "JobTitle": "temper mill roller – primary metal processing"
    },
    {
        "value": 19630,
        "NOCCode": "9411",
        "JobTitle": "tender, coke screen and coke blender"
    },
    {
        "value": 19631,
        "NOCCode": "9411",
        "JobTitle": "thallium recoverer"
    },
    {
        "value": 19632,
        "NOCCode": "9411",
        "JobTitle": "thickener equipment tender – primary metal processing"
    },
    {
        "value": 19633,
        "NOCCode": "9411",
        "JobTitle": "tilting table tender – metal processing"
    },
    {
        "value": 19634,
        "NOCCode": "9411",
        "JobTitle": "transfer bed operator – primary metal processing"
    },
    {
        "value": 19635,
        "NOCCode": "9411",
        "JobTitle": "tube drawer – primary metal processing"
    },
    {
        "value": 19636,
        "NOCCode": "9411",
        "JobTitle": "tube rolling mill operator – primary metal processing"
    },
    {
        "value": 19637,
        "NOCCode": "9411",
        "JobTitle": "tube straightener operator – primary metal processing"
    },
    {
        "value": 19638,
        "NOCCode": "9411",
        "JobTitle": "tubing machine tender – primary metal processing"
    },
    {
        "value": 19639,
        "NOCCode": "9411",
        "JobTitle": "uranium classifier operator"
    },
    {
        "value": 19640,
        "NOCCode": "9411",
        "JobTitle": "waste treatment operator – metal processing"
    },
    {
        "value": 19641,
        "NOCCode": "9411",
        "JobTitle": "water treatment plant operator – primary metal processing"
    },
    {
        "value": 19642,
        "NOCCode": "9411",
        "JobTitle": "wet plant recovery equipment tender – primary metal processing"
    },
    {
        "value": 19643,
        "NOCCode": "9411",
        "JobTitle": "wire drawer"
    },
    {
        "value": 19644,
        "NOCCode": "9411",
        "JobTitle": "wire drawer operator"
    },
    {
        "value": 19645,
        "NOCCode": "9411",
        "JobTitle": "wire drawer, machine"
    },
    {
        "value": 19646,
        "NOCCode": "9411",
        "JobTitle": "wire drawing machine operator"
    },
    {
        "value": 19647,
        "NOCCode": "9411",
        "JobTitle": "wire roller operator – primary metal processing"
    },
    {
        "value": 19648,
        "NOCCode": "9411",
        "JobTitle": "wire sample former"
    },
    {
        "value": 19649,
        "NOCCode": "9411",
        "JobTitle": "wire sheather – primary metal processing"
    },
    {
        "value": 19650,
        "NOCCode": "9411",
        "JobTitle": "zinc cell operator"
    },
    {
        "value": 19651,
        "NOCCode": "9411",
        "JobTitle": "zinc chloride separator tender"
    },
    {
        "value": 19652,
        "NOCCode": "9411",
        "JobTitle": "zinc pressure leach operator"
    },
    {
        "value": 19653,
        "NOCCode": "9411",
        "JobTitle": "zinc vapour condenser"
    },
    {
        "value": 19654,
        "NOCCode": "9412",
        "JobTitle": "ammunition caster – foundry"
    },
    {
        "value": 19655,
        "NOCCode": "9412",
        "JobTitle": "apprentice moulder – foundry"
    },
    {
        "value": 19656,
        "NOCCode": "9412",
        "JobTitle": "automatic casting machine operator – foundry"
    },
    {
        "value": 19657,
        "NOCCode": "9412",
        "JobTitle": "automatic moulding machine operator – foundry"
    },
    {
        "value": 19658,
        "NOCCode": "9412",
        "JobTitle": "bench coremaker – foundry"
    },
    {
        "value": 19659,
        "NOCCode": "9412",
        "JobTitle": "bench moulder"
    },
    {
        "value": 19660,
        "NOCCode": "9412",
        "JobTitle": "bench moulder – foundry"
    },
    {
        "value": 19661,
        "NOCCode": "9412",
        "JobTitle": "brass caster"
    },
    {
        "value": 19662,
        "NOCCode": "9412",
        "JobTitle": "bullet moulder – foundry"
    },
    {
        "value": 19663,
        "NOCCode": "9412",
        "JobTitle": "caster, metal – foundry"
    },
    {
        "value": 19664,
        "NOCCode": "9412",
        "JobTitle": "casting machine operator"
    },
    {
        "value": 19665,
        "NOCCode": "9412",
        "JobTitle": "casting machine operator – foundry"
    },
    {
        "value": 19666,
        "NOCCode": "9412",
        "JobTitle": "casting machine operator, lead"
    },
    {
        "value": 19667,
        "NOCCode": "9412",
        "JobTitle": "centrifugal casting machine operator"
    },
    {
        "value": 19668,
        "NOCCode": "9412",
        "JobTitle": "centrifugal casting machine tender – foundry"
    },
    {
        "value": 19669,
        "NOCCode": "9412",
        "JobTitle": "ceramic mouldmaker"
    },
    {
        "value": 19670,
        "NOCCode": "9412",
        "JobTitle": "ceramic mouldmaker – foundry"
    },
    {
        "value": 19671,
        "NOCCode": "9412",
        "JobTitle": "channel furnace operator – foundry"
    },
    {
        "value": 19672,
        "NOCCode": "9412",
        "JobTitle": "cope and drag pinlift operator – foundry"
    },
    {
        "value": 19673,
        "NOCCode": "9412",
        "JobTitle": "copper caster – foundry"
    },
    {
        "value": 19674,
        "NOCCode": "9412",
        "JobTitle": "core extruder – foundry"
    },
    {
        "value": 19675,
        "NOCCode": "9412",
        "JobTitle": "core machine operator – foundry"
    },
    {
        "value": 19676,
        "NOCCode": "9412",
        "JobTitle": "core oven operator – foundry"
    },
    {
        "value": 19677,
        "NOCCode": "9412",
        "JobTitle": "core oven tender – foundry"
    },
    {
        "value": 19678,
        "NOCCode": "9412",
        "JobTitle": "core room set-up man/woman – foundry"
    },
    {
        "value": 19679,
        "NOCCode": "9412",
        "JobTitle": "core setter – foundry"
    },
    {
        "value": 19680,
        "NOCCode": "9412",
        "JobTitle": "core-blower machine operator – foundry"
    },
    {
        "value": 19681,
        "NOCCode": "9412",
        "JobTitle": "coremaker – foundry"
    },
    {
        "value": 19682,
        "NOCCode": "9412",
        "JobTitle": "coremaker, machine – foundry"
    },
    {
        "value": 19683,
        "NOCCode": "9412",
        "JobTitle": "coremaking machine operator – foundry"
    },
    {
        "value": 19684,
        "NOCCode": "9412",
        "JobTitle": "core-sand-mixer – foundry"
    },
    {
        "value": 19685,
        "NOCCode": "9412",
        "JobTitle": "cupola furnace operator – foundry"
    },
    {
        "value": 19686,
        "NOCCode": "9412",
        "JobTitle": "custom moulder – foundry"
    },
    {
        "value": 19687,
        "NOCCode": "9412",
        "JobTitle": "die-casting machine operator"
    },
    {
        "value": 19688,
        "NOCCode": "9412",
        "JobTitle": "die-casting machine operator – foundry"
    },
    {
        "value": 19689,
        "NOCCode": "9412",
        "JobTitle": "die-casting machine setter – foundry"
    },
    {
        "value": 19690,
        "NOCCode": "9412",
        "JobTitle": "die-casting machine set-up man/woman – foundry"
    },
    {
        "value": 19691,
        "NOCCode": "9412",
        "JobTitle": "die-casting machine tender – foundry"
    },
    {
        "value": 19692,
        "NOCCode": "9412",
        "JobTitle": "dry-sand moulder – foundry"
    },
    {
        "value": 19693,
        "NOCCode": "9412",
        "JobTitle": "electric furnace operator – foundry"
    },
    {
        "value": 19694,
        "NOCCode": "9412",
        "JobTitle": "floor moulder – foundry"
    },
    {
        "value": 19695,
        "NOCCode": "9412",
        "JobTitle": "floor moulder, sand – foundry"
    },
    {
        "value": 19696,
        "NOCCode": "9412",
        "JobTitle": "foundry furnace operator"
    },
    {
        "value": 19697,
        "NOCCode": "9412",
        "JobTitle": "foundry mouldmaker"
    },
    {
        "value": 19698,
        "NOCCode": "9412",
        "JobTitle": "foundry pourer"
    },
    {
        "value": 19699,
        "NOCCode": "9412",
        "JobTitle": "foundry sand mixer"
    },
    {
        "value": 19700,
        "NOCCode": "9412",
        "JobTitle": "foundry worker"
    },
    {
        "value": 19701,
        "NOCCode": "9412",
        "JobTitle": "furnace operator – foundry"
    },
    {
        "value": 19702,
        "NOCCode": "9412",
        "JobTitle": "granular aluminum caster – foundry"
    },
    {
        "value": 19703,
        "NOCCode": "9412",
        "JobTitle": "grid caster – foundry"
    },
    {
        "value": 19704,
        "NOCCode": "9412",
        "JobTitle": "grid-caster operator – foundry"
    },
    {
        "value": 19705,
        "NOCCode": "9412",
        "JobTitle": "hand lead caster – foundry"
    },
    {
        "value": 19706,
        "NOCCode": "9412",
        "JobTitle": "hand mouldmaker – foundry"
    },
    {
        "value": 19707,
        "NOCCode": "9412",
        "JobTitle": "hand sand-core maker – foundry"
    },
    {
        "value": 19708,
        "NOCCode": "9412",
        "JobTitle": "hot-box moulder – foundry"
    },
    {
        "value": 19709,
        "NOCCode": "9412",
        "JobTitle": "hunter machine operator – foundry"
    },
    {
        "value": 19710,
        "NOCCode": "9412",
        "JobTitle": "induction furnace operator – foundry"
    },
    {
        "value": 19711,
        "NOCCode": "9412",
        "JobTitle": "investment caster – foundry"
    },
    {
        "value": 19712,
        "NOCCode": "9412",
        "JobTitle": "iron caster – foundry"
    },
    {
        "value": 19713,
        "NOCCode": "9412",
        "JobTitle": "iron moulder – foundry"
    },
    {
        "value": 19714,
        "NOCCode": "9412",
        "JobTitle": "job moulder – foundry"
    },
    {
        "value": 19715,
        "NOCCode": "9412",
        "JobTitle": "jobbing coremaker"
    },
    {
        "value": 19716,
        "NOCCode": "9412",
        "JobTitle": "jobbing moulder"
    },
    {
        "value": 19717,
        "NOCCode": "9412",
        "JobTitle": "lead caster, hand – foundry"
    },
    {
        "value": 19718,
        "NOCCode": "9412",
        "JobTitle": "lead casting machine operator"
    },
    {
        "value": 19719,
        "NOCCode": "9412",
        "JobTitle": "lead shot caster – foundry"
    },
    {
        "value": 19720,
        "NOCCode": "9412",
        "JobTitle": "lost pattern caster – foundry"
    },
    {
        "value": 19721,
        "NOCCode": "9412",
        "JobTitle": "lost wax caster – foundry"
    },
    {
        "value": 19722,
        "NOCCode": "9412",
        "JobTitle": "machine coremaker"
    },
    {
        "value": 19723,
        "NOCCode": "9412",
        "JobTitle": "machine coremaker – foundry"
    },
    {
        "value": 19724,
        "NOCCode": "9412",
        "JobTitle": "machine moulder – foundry"
    },
    {
        "value": 19725,
        "NOCCode": "9412",
        "JobTitle": "machine-line moulder – foundry"
    },
    {
        "value": 19726,
        "NOCCode": "9412",
        "JobTitle": "manual moulder"
    },
    {
        "value": 19727,
        "NOCCode": "9412",
        "JobTitle": "melter – foundry"
    },
    {
        "value": 19728,
        "NOCCode": "9412",
        "JobTitle": "metal caster"
    },
    {
        "value": 19729,
        "NOCCode": "9412",
        "JobTitle": "metal caster – foundry"
    },
    {
        "value": 19730,
        "NOCCode": "9412",
        "JobTitle": "metal frame moulder – foundry"
    },
    {
        "value": 19731,
        "NOCCode": "9412",
        "JobTitle": "mould caster – foundry"
    },
    {
        "value": 19732,
        "NOCCode": "9412",
        "JobTitle": "mould setter – foundry"
    },
    {
        "value": 19733,
        "NOCCode": "9412",
        "JobTitle": "moulder, bench – foundry"
    },
    {
        "value": 19734,
        "NOCCode": "9412",
        "JobTitle": "moulder, floor – foundry"
    },
    {
        "value": 19735,
        "NOCCode": "9412",
        "JobTitle": "moulder, squeezer – foundry"
    },
    {
        "value": 19736,
        "NOCCode": "9412",
        "JobTitle": "moulding and coremaking machine setter – foundry"
    },
    {
        "value": 19737,
        "NOCCode": "9412",
        "JobTitle": "moulding and coremaking machine set-up man/woman – foundry"
    },
    {
        "value": 19738,
        "NOCCode": "9412",
        "JobTitle": "moulding sand mixer – foundry"
    },
    {
        "value": 19739,
        "NOCCode": "9412",
        "JobTitle": "mouldmaker, hand – foundry"
    },
    {
        "value": 19740,
        "NOCCode": "9412",
        "JobTitle": "ornamental caster – foundry"
    },
    {
        "value": 19741,
        "NOCCode": "9412",
        "JobTitle": "pattern gater"
    },
    {
        "value": 19742,
        "NOCCode": "9412",
        "JobTitle": "pattern moulder – foundry"
    },
    {
        "value": 19743,
        "NOCCode": "9412",
        "JobTitle": "pellet maker – foundry"
    },
    {
        "value": 19744,
        "NOCCode": "9412",
        "JobTitle": "permanent mould operator – foundry"
    },
    {
        "value": 19745,
        "NOCCode": "9412",
        "JobTitle": "pipe coremaker – foundry"
    },
    {
        "value": 19746,
        "NOCCode": "9412",
        "JobTitle": "pit moulder"
    },
    {
        "value": 19747,
        "NOCCode": "9412",
        "JobTitle": "pit moulder – foundry"
    },
    {
        "value": 19748,
        "NOCCode": "9412",
        "JobTitle": "Roto-lift operator – foundry"
    },
    {
        "value": 19749,
        "NOCCode": "9412",
        "JobTitle": "sand control man/woman – foundry"
    },
    {
        "value": 19750,
        "NOCCode": "9412",
        "JobTitle": "sand control operator – foundry"
    },
    {
        "value": 19751,
        "NOCCode": "9412",
        "JobTitle": "sand coremaker"
    },
    {
        "value": 19752,
        "NOCCode": "9412",
        "JobTitle": "sand floor moulder – foundry"
    },
    {
        "value": 19753,
        "NOCCode": "9412",
        "JobTitle": "sand mill operator – foundry"
    },
    {
        "value": 19754,
        "NOCCode": "9412",
        "JobTitle": "sand moulder"
    },
    {
        "value": 19755,
        "NOCCode": "9412",
        "JobTitle": "sand moulder – foundry"
    },
    {
        "value": 19756,
        "NOCCode": "9412",
        "JobTitle": "sand processing operator – foundry"
    },
    {
        "value": 19757,
        "NOCCode": "9412",
        "JobTitle": "sand system operator – foundry"
    },
    {
        "value": 19758,
        "NOCCode": "9412",
        "JobTitle": "sand-core maker, hand – foundry"
    },
    {
        "value": 19759,
        "NOCCode": "9412",
        "JobTitle": "sand-slinger operator – foundry"
    },
    {
        "value": 19760,
        "NOCCode": "9412",
        "JobTitle": "set-up man/woman, core room – foundry"
    },
    {
        "value": 19761,
        "NOCCode": "9412",
        "JobTitle": "shell core maker – foundry"
    },
    {
        "value": 19762,
        "NOCCode": "9412",
        "JobTitle": "shell mould assembler – foundry"
    },
    {
        "value": 19763,
        "NOCCode": "9412",
        "JobTitle": "shell moulder – foundry"
    },
    {
        "value": 19764,
        "NOCCode": "9412",
        "JobTitle": "shot maker – foundry"
    },
    {
        "value": 19765,
        "NOCCode": "9412",
        "JobTitle": "sintering press tender – foundry"
    },
    {
        "value": 19766,
        "NOCCode": "9412",
        "JobTitle": "squeezer machine operator – foundry"
    },
    {
        "value": 19767,
        "NOCCode": "9412",
        "JobTitle": "squeezer moulder – foundry"
    },
    {
        "value": 19768,
        "NOCCode": "9412",
        "JobTitle": "strand operator – foundry"
    },
    {
        "value": 19769,
        "NOCCode": "9412",
        "JobTitle": "vessel operator – foundry"
    },
    {
        "value": 19770,
        "NOCCode": "9412",
        "JobTitle": "wax-pattern assembler and repairer"
    },
    {
        "value": 19771,
        "NOCCode": "9412",
        "JobTitle": "wax-pattern coater"
    },
    {
        "value": 19772,
        "NOCCode": "9412",
        "JobTitle": "wax-pattern moulder"
    },
    {
        "value": 19773,
        "NOCCode": "9412",
        "JobTitle": "wax-pattern mouldmaker"
    },
    {
        "value": 19774,
        "NOCCode": "9412",
        "JobTitle": "white-metal caster – foundry"
    },
    {
        "value": 19775,
        "NOCCode": "9413",
        "JobTitle": "annealer – glass products manufacturing"
    },
    {
        "value": 19776,
        "NOCCode": "9413",
        "JobTitle": "automatic glass-cutting table operator"
    },
    {
        "value": 19777,
        "NOCCode": "9413",
        "JobTitle": "automatic sealing gun operator – glass"
    },
    {
        "value": 19778,
        "NOCCode": "9413",
        "JobTitle": "banding machine tender, glassware"
    },
    {
        "value": 19779,
        "NOCCode": "9413",
        "JobTitle": "batch plant tender – glass"
    },
    {
        "value": 19780,
        "NOCCode": "9413",
        "JobTitle": "beaker tester"
    },
    {
        "value": 19781,
        "NOCCode": "9413",
        "JobTitle": "beveller, glass"
    },
    {
        "value": 19782,
        "NOCCode": "9413",
        "JobTitle": "bonding machine operator, glass"
    },
    {
        "value": 19783,
        "NOCCode": "9413",
        "JobTitle": "bottle inspector – glass products manufacturing"
    },
    {
        "value": 19784,
        "NOCCode": "9413",
        "JobTitle": "bottle machine operator – glass products manufacturing"
    },
    {
        "value": 19785,
        "NOCCode": "9413",
        "JobTitle": "bottle maker operator"
    },
    {
        "value": 19786,
        "NOCCode": "9413",
        "JobTitle": "bottle maker operator – glass and glass products"
    },
    {
        "value": 19787,
        "NOCCode": "9413",
        "JobTitle": "bottle tester"
    },
    {
        "value": 19788,
        "NOCCode": "9413",
        "JobTitle": "diamond grinder operator – glass finishing"
    },
    {
        "value": 19789,
        "NOCCode": "9413",
        "JobTitle": "diamond wheel edger – glass finishing"
    },
    {
        "value": 19790,
        "NOCCode": "9413",
        "JobTitle": "drawing kiln operator – glass forming"
    },
    {
        "value": 19791,
        "NOCCode": "9413",
        "JobTitle": "drill operator, glass"
    },
    {
        "value": 19792,
        "NOCCode": "9413",
        "JobTitle": "edger, glass"
    },
    {
        "value": 19793,
        "NOCCode": "9413",
        "JobTitle": "enamel burner – glass products manufacturing"
    },
    {
        "value": 19794,
        "NOCCode": "9413",
        "JobTitle": "enamelling furnace operator – glass products manufacturing"
    },
    {
        "value": 19795,
        "NOCCode": "9413",
        "JobTitle": "enamelware paint baker – glass products manufacturing"
    },
    {
        "value": 19796,
        "NOCCode": "9413",
        "JobTitle": "float chief operator – glass forming"
    },
    {
        "value": 19797,
        "NOCCode": "9413",
        "JobTitle": "float operator – glass forming"
    },
    {
        "value": 19798,
        "NOCCode": "9413",
        "JobTitle": "forming-cutting operator – glass products"
    },
    {
        "value": 19799,
        "NOCCode": "9413",
        "JobTitle": "gatherer – glass products manufacturing"
    },
    {
        "value": 19800,
        "NOCCode": "9413",
        "JobTitle": "glass bender"
    },
    {
        "value": 19801,
        "NOCCode": "9413",
        "JobTitle": "glass beveller"
    },
    {
        "value": 19802,
        "NOCCode": "9413",
        "JobTitle": "glass beveller operator"
    },
    {
        "value": 19803,
        "NOCCode": "9413",
        "JobTitle": "glass beveller tender"
    },
    {
        "value": 19804,
        "NOCCode": "9413",
        "JobTitle": "glass breaker"
    },
    {
        "value": 19805,
        "NOCCode": "9413",
        "JobTitle": "glass buffer"
    },
    {
        "value": 19806,
        "NOCCode": "9413",
        "JobTitle": "glass chipper"
    },
    {
        "value": 19807,
        "NOCCode": "9413",
        "JobTitle": "glass cut-off machine operator"
    },
    {
        "value": 19808,
        "NOCCode": "9413",
        "JobTitle": "glass cutter"
    },
    {
        "value": 19809,
        "NOCCode": "9413",
        "JobTitle": "glass cutter operator"
    },
    {
        "value": 19810,
        "NOCCode": "9413",
        "JobTitle": "glass cutter, hand"
    },
    {
        "value": 19811,
        "NOCCode": "9413",
        "JobTitle": "glass cutter-framer"
    },
    {
        "value": 19812,
        "NOCCode": "9413",
        "JobTitle": "glass drill tender"
    },
    {
        "value": 19813,
        "NOCCode": "9413",
        "JobTitle": "glass edge finisher, hand"
    },
    {
        "value": 19814,
        "NOCCode": "9413",
        "JobTitle": "glass edger"
    },
    {
        "value": 19815,
        "NOCCode": "9413",
        "JobTitle": "glass edger operator"
    },
    {
        "value": 19816,
        "NOCCode": "9413",
        "JobTitle": "glass edger tender"
    },
    {
        "value": 19817,
        "NOCCode": "9413",
        "JobTitle": "glass etcher"
    },
    {
        "value": 19818,
        "NOCCode": "9413",
        "JobTitle": "glass finisher"
    },
    {
        "value": 19819,
        "NOCCode": "9413",
        "JobTitle": "glass forming operator"
    },
    {
        "value": 19820,
        "NOCCode": "9413",
        "JobTitle": "glass forming specialist"
    },
    {
        "value": 19821,
        "NOCCode": "9413",
        "JobTitle": "glass furnace operator"
    },
    {
        "value": 19822,
        "NOCCode": "9413",
        "JobTitle": "glass furnace setter-operator"
    },
    {
        "value": 19823,
        "NOCCode": "9413",
        "JobTitle": "glass furnace set-up operator"
    },
    {
        "value": 19824,
        "NOCCode": "9413",
        "JobTitle": "glass grinder"
    },
    {
        "value": 19825,
        "NOCCode": "9413",
        "JobTitle": "glass heater"
    },
    {
        "value": 19826,
        "NOCCode": "9413",
        "JobTitle": "glass laminator"
    },
    {
        "value": 19827,
        "NOCCode": "9413",
        "JobTitle": "glass lathe operator"
    },
    {
        "value": 19828,
        "NOCCode": "9413",
        "JobTitle": "glass lehr attendant"
    },
    {
        "value": 19829,
        "NOCCode": "9413",
        "JobTitle": "glass lehr operator"
    },
    {
        "value": 19830,
        "NOCCode": "9413",
        "JobTitle": "glass lehr tender"
    },
    {
        "value": 19831,
        "NOCCode": "9413",
        "JobTitle": "glass marker"
    },
    {
        "value": 19832,
        "NOCCode": "9413",
        "JobTitle": "glass melter"
    },
    {
        "value": 19833,
        "NOCCode": "9413",
        "JobTitle": "glass mixer"
    },
    {
        "value": 19834,
        "NOCCode": "9413",
        "JobTitle": "glass mould cleaner"
    },
    {
        "value": 19835,
        "NOCCode": "9413",
        "JobTitle": "glass mould polisher"
    },
    {
        "value": 19836,
        "NOCCode": "9413",
        "JobTitle": "glass moulder"
    },
    {
        "value": 19837,
        "NOCCode": "9413",
        "JobTitle": "glass polisher"
    },
    {
        "value": 19838,
        "NOCCode": "9413",
        "JobTitle": "glass polisher tender"
    },
    {
        "value": 19839,
        "NOCCode": "9413",
        "JobTitle": "glass presser"
    },
    {
        "value": 19840,
        "NOCCode": "9413",
        "JobTitle": "glass spin mould machine set-up operator"
    },
    {
        "value": 19841,
        "NOCCode": "9413",
        "JobTitle": "glass tank furnace operator"
    },
    {
        "value": 19842,
        "NOCCode": "9413",
        "JobTitle": "glass temperer"
    },
    {
        "value": 19843,
        "NOCCode": "9413",
        "JobTitle": "glass windshield autoclave operator"
    },
    {
        "value": 19844,
        "NOCCode": "9413",
        "JobTitle": "glass worker – glass products manufacturing"
    },
    {
        "value": 19845,
        "NOCCode": "9413",
        "JobTitle": "glass-annealing furnace tender"
    },
    {
        "value": 19846,
        "NOCCode": "9413",
        "JobTitle": "glass-blowing lathe operator"
    },
    {
        "value": 19847,
        "NOCCode": "9413",
        "JobTitle": "glass-blowing machine operator"
    },
    {
        "value": 19848,
        "NOCCode": "9413",
        "JobTitle": "glass-blowing machine setter"
    },
    {
        "value": 19849,
        "NOCCode": "9413",
        "JobTitle": "glass-coating line operator"
    },
    {
        "value": 19850,
        "NOCCode": "9413",
        "JobTitle": "glass-decorating machine operator"
    },
    {
        "value": 19851,
        "NOCCode": "9413",
        "JobTitle": "glass-drawing machine operator"
    },
    {
        "value": 19852,
        "NOCCode": "9413",
        "JobTitle": "glass-edging machine feeder"
    },
    {
        "value": 19853,
        "NOCCode": "9413",
        "JobTitle": "glass-finishing machine operator"
    },
    {
        "value": 19854,
        "NOCCode": "9413",
        "JobTitle": "glass-forming machine operator"
    },
    {
        "value": 19855,
        "NOCCode": "9413",
        "JobTitle": "glass-forming machine setter"
    },
    {
        "value": 19856,
        "NOCCode": "9413",
        "JobTitle": "glass-forming machine set-up person"
    },
    {
        "value": 19857,
        "NOCCode": "9413",
        "JobTitle": "glass-grinding machine feeder"
    },
    {
        "value": 19858,
        "NOCCode": "9413",
        "JobTitle": "glass-pressing and glass-blowing machine operator"
    },
    {
        "value": 19859,
        "NOCCode": "9413",
        "JobTitle": "glass-pressing machine operator"
    },
    {
        "value": 19860,
        "NOCCode": "9413",
        "JobTitle": "glass-silvering machine operator"
    },
    {
        "value": 19861,
        "NOCCode": "9413",
        "JobTitle": "glass-stopper grinder"
    },
    {
        "value": 19862,
        "NOCCode": "9413",
        "JobTitle": "glass-tempering furnace operator"
    },
    {
        "value": 19863,
        "NOCCode": "9413",
        "JobTitle": "glass-tube-expander operator"
    },
    {
        "value": 19864,
        "NOCCode": "9413",
        "JobTitle": "glassware grinder"
    },
    {
        "value": 19865,
        "NOCCode": "9413",
        "JobTitle": "glassware-banding machine tender"
    },
    {
        "value": 19866,
        "NOCCode": "9413",
        "JobTitle": "glassware-decorating machine operator"
    },
    {
        "value": 19867,
        "NOCCode": "9413",
        "JobTitle": "groove grinder – glass"
    },
    {
        "value": 19868,
        "NOCCode": "9413",
        "JobTitle": "hand finisher, glass edges"
    },
    {
        "value": 19869,
        "NOCCode": "9413",
        "JobTitle": "hand glass edge finisher"
    },
    {
        "value": 19870,
        "NOCCode": "9413",
        "JobTitle": "heater, glass"
    },
    {
        "value": 19871,
        "NOCCode": "9413",
        "JobTitle": "machine operator – glass forming and finishing"
    },
    {
        "value": 19872,
        "NOCCode": "9413",
        "JobTitle": "mirror maker"
    },
    {
        "value": 19873,
        "NOCCode": "9413",
        "JobTitle": "mirror silverer"
    },
    {
        "value": 19874,
        "NOCCode": "9413",
        "JobTitle": "mirror-silvering equipment operator"
    },
    {
        "value": 19875,
        "NOCCode": "9413",
        "JobTitle": "neon sign pumper"
    },
    {
        "value": 19876,
        "NOCCode": "9413",
        "JobTitle": "neon sign tube bender"
    },
    {
        "value": 19877,
        "NOCCode": "9413",
        "JobTitle": "neon tube bender"
    },
    {
        "value": 19878,
        "NOCCode": "9413",
        "JobTitle": "neon tube pumper"
    },
    {
        "value": 19879,
        "NOCCode": "9413",
        "JobTitle": "ornamental glass production worker"
    },
    {
        "value": 19880,
        "NOCCode": "9413",
        "JobTitle": "paint baker, enamelware – glass products manufacturing"
    },
    {
        "value": 19881,
        "NOCCode": "9413",
        "JobTitle": "silverer, mirrors"
    },
    {
        "value": 19882,
        "NOCCode": "9413",
        "JobTitle": "special glass cutter"
    },
    {
        "value": 19883,
        "NOCCode": "9413",
        "JobTitle": "stained glass production worker"
    },
    {
        "value": 19884,
        "NOCCode": "9413",
        "JobTitle": "staple cutter – glass products manufacturing"
    },
    {
        "value": 19885,
        "NOCCode": "9413",
        "JobTitle": "stopper grinder, glass"
    },
    {
        "value": 19886,
        "NOCCode": "9413",
        "JobTitle": "strapping machine operator – glass manufacturing"
    },
    {
        "value": 19887,
        "NOCCode": "9413",
        "JobTitle": "tinted glassware maker"
    },
    {
        "value": 19888,
        "NOCCode": "9413",
        "JobTitle": "yardage controller – glass products manufacturing"
    },
    {
        "value": 19889,
        "NOCCode": "9414",
        "JobTitle": "abrasive mixer – stone products"
    },
    {
        "value": 19890,
        "NOCCode": "9414",
        "JobTitle": "abrasive products kiln fireman/woman – non-metallic mineral products"
    },
    {
        "value": 19891,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel grinder"
    },
    {
        "value": 19892,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel grinder, rubber"
    },
    {
        "value": 19893,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel maker"
    },
    {
        "value": 19894,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel maker, diamond"
    },
    {
        "value": 19895,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel maker, rubber"
    },
    {
        "value": 19896,
        "NOCCode": "9414",
        "JobTitle": "abrasive wheel moulder"
    },
    {
        "value": 19897,
        "NOCCode": "9414",
        "JobTitle": "abrasives furnace preparer – stone products"
    },
    {
        "value": 19898,
        "NOCCode": "9414",
        "JobTitle": "asbestos board curer"
    },
    {
        "value": 19899,
        "NOCCode": "9414",
        "JobTitle": "asbestos board machine operator"
    },
    {
        "value": 19900,
        "NOCCode": "9414",
        "JobTitle": "asbestos board machinery setter"
    },
    {
        "value": 19901,
        "NOCCode": "9414",
        "JobTitle": "asbestos mixer tender"
    },
    {
        "value": 19902,
        "NOCCode": "9414",
        "JobTitle": "asbestos shingle assembler"
    },
    {
        "value": 19903,
        "NOCCode": "9414",
        "JobTitle": "asbestos shingle cutter"
    },
    {
        "value": 19904,
        "NOCCode": "9414",
        "JobTitle": "asbestos shingle maker"
    },
    {
        "value": 19905,
        "NOCCode": "9414",
        "JobTitle": "asbestos shingle presser"
    },
    {
        "value": 19906,
        "NOCCode": "9414",
        "JobTitle": "assembler, asbestos shingles"
    },
    {
        "value": 19907,
        "NOCCode": "9414",
        "JobTitle": "auger machine operator, drain tiles – clay products"
    },
    {
        "value": 19908,
        "NOCCode": "9414",
        "JobTitle": "automatic jigger man/woman – clay products"
    },
    {
        "value": 19909,
        "NOCCode": "9414",
        "JobTitle": "automatic jigger operator – clay products"
    },
    {
        "value": 19910,
        "NOCCode": "9414",
        "JobTitle": "baker, bricks – clay products"
    },
    {
        "value": 19911,
        "NOCCode": "9414",
        "JobTitle": "bender, clay pipes"
    },
    {
        "value": 19912,
        "NOCCode": "9414",
        "JobTitle": "beveller, stones"
    },
    {
        "value": 19913,
        "NOCCode": "9414",
        "JobTitle": "bisque kiln operator – clay products"
    },
    {
        "value": 19914,
        "NOCCode": "9414",
        "JobTitle": "block cuber – concrete, clay and stone products"
    },
    {
        "value": 19915,
        "NOCCode": "9414",
        "JobTitle": "block machine operator – concrete, clay and stone products"
    },
    {
        "value": 19916,
        "NOCCode": "9414",
        "JobTitle": "block maker, cement – concrete products"
    },
    {
        "value": 19917,
        "NOCCode": "9414",
        "JobTitle": "block maker, concrete"
    },
    {
        "value": 19918,
        "NOCCode": "9414",
        "JobTitle": "block maker, stonework"
    },
    {
        "value": 19919,
        "NOCCode": "9414",
        "JobTitle": "block moulder, cement – concrete products"
    },
    {
        "value": 19920,
        "NOCCode": "9414",
        "JobTitle": "block-making machine operator – concrete, clay and stone products"
    },
    {
        "value": 19921,
        "NOCCode": "9414",
        "JobTitle": "block-making machine tender – concrete, clay and stone products"
    },
    {
        "value": 19922,
        "NOCCode": "9414",
        "JobTitle": "boat builder, quartz – stone products"
    },
    {
        "value": 19923,
        "NOCCode": "9414",
        "JobTitle": "boring mill operator – concrete products"
    },
    {
        "value": 19924,
        "NOCCode": "9414",
        "JobTitle": "brake lining finisher, asbestos"
    },
    {
        "value": 19925,
        "NOCCode": "9414",
        "JobTitle": "brick and tile caster – clay products"
    },
    {
        "value": 19926,
        "NOCCode": "9414",
        "JobTitle": "brick and tile-making machine operator – clay products"
    },
    {
        "value": 19927,
        "NOCCode": "9414",
        "JobTitle": "brick baker – clay products"
    },
    {
        "value": 19928,
        "NOCCode": "9414",
        "JobTitle": "brick burner – clay products"
    },
    {
        "value": 19929,
        "NOCCode": "9414",
        "JobTitle": "brick cutter – clay products"
    },
    {
        "value": 19930,
        "NOCCode": "9414",
        "JobTitle": "brick kiln operator – clay products"
    },
    {
        "value": 19931,
        "NOCCode": "9414",
        "JobTitle": "brick kilnman/woman – clay products"
    },
    {
        "value": 19932,
        "NOCCode": "9414",
        "JobTitle": "brick machine set-up operator – clay products"
    },
    {
        "value": 19933,
        "NOCCode": "9414",
        "JobTitle": "brick maker – clay products"
    },
    {
        "value": 19934,
        "NOCCode": "9414",
        "JobTitle": "brick moulder – clay products"
    },
    {
        "value": 19935,
        "NOCCode": "9414",
        "JobTitle": "brick moulder, hand – clay products"
    },
    {
        "value": 19936,
        "NOCCode": "9414",
        "JobTitle": "brick presser – clay products"
    },
    {
        "value": 19937,
        "NOCCode": "9414",
        "JobTitle": "brick presser operator"
    },
    {
        "value": 19938,
        "NOCCode": "9414",
        "JobTitle": "brick-cutting machine operator – clay products"
    },
    {
        "value": 19939,
        "NOCCode": "9414",
        "JobTitle": "brick-moulding machine operator – clay products"
    },
    {
        "value": 19940,
        "NOCCode": "9414",
        "JobTitle": "brush coater operator – concrete products"
    },
    {
        "value": 19941,
        "NOCCode": "9414",
        "JobTitle": "buffer, stone products"
    },
    {
        "value": 19942,
        "NOCCode": "9414",
        "JobTitle": "builder, insulators – clay products"
    },
    {
        "value": 19943,
        "NOCCode": "9414",
        "JobTitle": "burial vault maker, concrete"
    },
    {
        "value": 19944,
        "NOCCode": "9414",
        "JobTitle": "burner, bricks – clay products"
    },
    {
        "value": 19945,
        "NOCCode": "9414",
        "JobTitle": "carbon planer tender – stone products"
    },
    {
        "value": 19946,
        "NOCCode": "9414",
        "JobTitle": "carver, granite"
    },
    {
        "value": 19947,
        "NOCCode": "9414",
        "JobTitle": "carver, marble – concrete, clay and stone forming"
    },
    {
        "value": 19948,
        "NOCCode": "9414",
        "JobTitle": "carver, monuments – stone products"
    },
    {
        "value": 19949,
        "NOCCode": "9414",
        "JobTitle": "caster – concrete products"
    },
    {
        "value": 19950,
        "NOCCode": "9414",
        "JobTitle": "caster, bricks and tiles – clay products"
    },
    {
        "value": 19951,
        "NOCCode": "9414",
        "JobTitle": "caster, concrete mixer"
    },
    {
        "value": 19952,
        "NOCCode": "9414",
        "JobTitle": "caster, pottery and porcelain ware"
    },
    {
        "value": 19953,
        "NOCCode": "9414",
        "JobTitle": "caster, scagliola – stone products"
    },
    {
        "value": 19954,
        "NOCCode": "9414",
        "JobTitle": "cement block maker – concrete products"
    },
    {
        "value": 19955,
        "NOCCode": "9414",
        "JobTitle": "cement block moulder – concrete products"
    },
    {
        "value": 19956,
        "NOCCode": "9414",
        "JobTitle": "cement pipe shaper operator – concrete products"
    },
    {
        "value": 19957,
        "NOCCode": "9414",
        "JobTitle": "cement pipe shaper, machine – clay products"
    },
    {
        "value": 19958,
        "NOCCode": "9414",
        "JobTitle": "cement polisher"
    },
    {
        "value": 19959,
        "NOCCode": "9414",
        "JobTitle": "ceramic tile press operator – concrete products"
    },
    {
        "value": 19960,
        "NOCCode": "9414",
        "JobTitle": "chipper, pottery and porcelain"
    },
    {
        "value": 19961,
        "NOCCode": "9414",
        "JobTitle": "cinder block machine operator – concrete products"
    },
    {
        "value": 19962,
        "NOCCode": "9414",
        "JobTitle": "circular saw operator – concrete, clay and stone products"
    },
    {
        "value": 19963,
        "NOCCode": "9414",
        "JobTitle": "clay dry press operator"
    },
    {
        "value": 19964,
        "NOCCode": "9414",
        "JobTitle": "clay insulator builder – clay products"
    },
    {
        "value": 19965,
        "NOCCode": "9414",
        "JobTitle": "clay pipe bender"
    },
    {
        "value": 19966,
        "NOCCode": "9414",
        "JobTitle": "clay press operator"
    },
    {
        "value": 19967,
        "NOCCode": "9414",
        "JobTitle": "clay products machine operator"
    },
    {
        "value": 19968,
        "NOCCode": "9414",
        "JobTitle": "clay products moulder, hand"
    },
    {
        "value": 19969,
        "NOCCode": "9414",
        "JobTitle": "clay products polisher"
    },
    {
        "value": 19970,
        "NOCCode": "9414",
        "JobTitle": "clay structure moulder and installer"
    },
    {
        "value": 19971,
        "NOCCode": "9414",
        "JobTitle": "clay temperer – clay products"
    },
    {
        "value": 19972,
        "NOCCode": "9414",
        "JobTitle": "clayware driller"
    },
    {
        "value": 19973,
        "NOCCode": "9414",
        "JobTitle": "clayware turner"
    },
    {
        "value": 19974,
        "NOCCode": "9414",
        "JobTitle": "claywork moulder"
    },
    {
        "value": 19975,
        "NOCCode": "9414",
        "JobTitle": "concrete block machine operator"
    },
    {
        "value": 19976,
        "NOCCode": "9414",
        "JobTitle": "concrete block maker"
    },
    {
        "value": 19977,
        "NOCCode": "9414",
        "JobTitle": "concrete burial vault maker"
    },
    {
        "value": 19978,
        "NOCCode": "9414",
        "JobTitle": "concrete fittings maker"
    },
    {
        "value": 19979,
        "NOCCode": "9414",
        "JobTitle": "concrete hydraulic press operator"
    },
    {
        "value": 19980,
        "NOCCode": "9414",
        "JobTitle": "concrete mixer caster"
    },
    {
        "value": 19981,
        "NOCCode": "9414",
        "JobTitle": "concrete mixer operator – concrete manufacturing"
    },
    {
        "value": 19982,
        "NOCCode": "9414",
        "JobTitle": "concrete mixer-caster"
    },
    {
        "value": 19983,
        "NOCCode": "9414",
        "JobTitle": "concrete moulder"
    },
    {
        "value": 19984,
        "NOCCode": "9414",
        "JobTitle": "concrete operator – dry cast"
    },
    {
        "value": 19985,
        "NOCCode": "9414",
        "JobTitle": "concrete operator – wet cast"
    },
    {
        "value": 19986,
        "NOCCode": "9414",
        "JobTitle": "concrete pipe machine tender"
    },
    {
        "value": 19987,
        "NOCCode": "9414",
        "JobTitle": "concrete plant operator"
    },
    {
        "value": 19988,
        "NOCCode": "9414",
        "JobTitle": "concrete pole maker – concrete products manufacturing"
    },
    {
        "value": 19989,
        "NOCCode": "9414",
        "JobTitle": "concrete pole moulder – concrete products manufacturing"
    },
    {
        "value": 19990,
        "NOCCode": "9414",
        "JobTitle": "concrete products machine operator"
    },
    {
        "value": 19991,
        "NOCCode": "9414",
        "JobTitle": "concrete products moulder"
    },
    {
        "value": 19992,
        "NOCCode": "9414",
        "JobTitle": "concrete saw tender"
    },
    {
        "value": 19993,
        "NOCCode": "9414",
        "JobTitle": "concrete sawyer"
    },
    {
        "value": 19994,
        "NOCCode": "9414",
        "JobTitle": "concrete spin caster"
    },
    {
        "value": 19995,
        "NOCCode": "9414",
        "JobTitle": "concrete vault maker"
    },
    {
        "value": 19996,
        "NOCCode": "9414",
        "JobTitle": "concrete-spinning machine operator"
    },
    {
        "value": 19997,
        "NOCCode": "9414",
        "JobTitle": "contour grinder, machine – concrete, clay and stone products"
    },
    {
        "value": 19998,
        "NOCCode": "9414",
        "JobTitle": "contour-grinding machine operator – concrete, clay and stone products"
    },
    {
        "value": 19999,
        "NOCCode": "9414",
        "JobTitle": "corrugated asbestos board former"
    },
    {
        "value": 20000,
        "NOCCode": "9414",
        "JobTitle": "crusher operator – stone products"
    },
    {
        "value": 20001,
        "NOCCode": "9414",
        "JobTitle": "cupola operator – concrete, clay and stone products"
    },
    {
        "value": 20002,
        "NOCCode": "9414",
        "JobTitle": "cutter operator – concrete, clay and stone products"
    },
    {
        "value": 20003,
        "NOCCode": "9414",
        "JobTitle": "cutter, asbestos shingles"
    },
    {
        "value": 20004,
        "NOCCode": "9414",
        "JobTitle": "cutter, bricks – clay products"
    },
    {
        "value": 20005,
        "NOCCode": "9414",
        "JobTitle": "cutter, granite"
    },
    {
        "value": 20006,
        "NOCCode": "9414",
        "JobTitle": "cutter, grave markers – stone products"
    },
    {
        "value": 20007,
        "NOCCode": "9414",
        "JobTitle": "cutter, marble"
    },
    {
        "value": 20008,
        "NOCCode": "9414",
        "JobTitle": "cutter, millstones – stone products"
    },
    {
        "value": 20009,
        "NOCCode": "9414",
        "JobTitle": "cutter, slate"
    },
    {
        "value": 20010,
        "NOCCode": "9414",
        "JobTitle": "cutter, stencils – stone products"
    },
    {
        "value": 20011,
        "NOCCode": "9414",
        "JobTitle": "cutter, stonework stencils"
    },
    {
        "value": 20012,
        "NOCCode": "9414",
        "JobTitle": "decorated products kilnman/woman – clay products"
    },
    {
        "value": 20013,
        "NOCCode": "9414",
        "JobTitle": "decorating kiln operator – clay products"
    },
    {
        "value": 20014,
        "NOCCode": "9414",
        "JobTitle": "decorating machine tender – clay products"
    },
    {
        "value": 20015,
        "NOCCode": "9414",
        "JobTitle": "decorator, machine – clay products"
    },
    {
        "value": 20016,
        "NOCCode": "9414",
        "JobTitle": "diamond abrasive wheel maker"
    },
    {
        "value": 20017,
        "NOCCode": "9414",
        "JobTitle": "diamond powder mixer – stone products"
    },
    {
        "value": 20018,
        "NOCCode": "9414",
        "JobTitle": "diamond wheel moulder and finisher"
    },
    {
        "value": 20019,
        "NOCCode": "9414",
        "JobTitle": "die maker, plaster"
    },
    {
        "value": 20020,
        "NOCCode": "9414",
        "JobTitle": "dish maker – clay products"
    },
    {
        "value": 20021,
        "NOCCode": "9414",
        "JobTitle": "drain tile auger machine operator – clay products"
    },
    {
        "value": 20022,
        "NOCCode": "9414",
        "JobTitle": "dresser, grinding wheels"
    },
    {
        "value": 20023,
        "NOCCode": "9414",
        "JobTitle": "dresser, ware – concrete, clay and stone products"
    },
    {
        "value": 20024,
        "NOCCode": "9414",
        "JobTitle": "drill press operator – stone products"
    },
    {
        "value": 20025,
        "NOCCode": "9414",
        "JobTitle": "drill press operator, stonework"
    },
    {
        "value": 20026,
        "NOCCode": "9414",
        "JobTitle": "driller, clayware"
    },
    {
        "value": 20027,
        "NOCCode": "9414",
        "JobTitle": "driller, greenware – clay products"
    },
    {
        "value": 20028,
        "NOCCode": "9414",
        "JobTitle": "driller, stones – stone products"
    },
    {
        "value": 20029,
        "NOCCode": "9414",
        "JobTitle": "dry pan operator – stone products"
    },
    {
        "value": 20030,
        "NOCCode": "9414",
        "JobTitle": "dry plaster mixer"
    },
    {
        "value": 20031,
        "NOCCode": "9414",
        "JobTitle": "dry press operator – clay products"
    },
    {
        "value": 20032,
        "NOCCode": "9414",
        "JobTitle": "dry products mixer – stone products"
    },
    {
        "value": 20033,
        "NOCCode": "9414",
        "JobTitle": "dryer, pottery – clay products"
    },
    {
        "value": 20034,
        "NOCCode": "9414",
        "JobTitle": "drying machine tender – clay products"
    },
    {
        "value": 20035,
        "NOCCode": "9414",
        "JobTitle": "electrolytic cell maker – concrete products"
    },
    {
        "value": 20036,
        "NOCCode": "9414",
        "JobTitle": "engraver, monuments – stone products"
    },
    {
        "value": 20037,
        "NOCCode": "9414",
        "JobTitle": "engraver, stonework – stone products"
    },
    {
        "value": 20038,
        "NOCCode": "9414",
        "JobTitle": "extruder operator – concrete, clay and stone products"
    },
    {
        "value": 20039,
        "NOCCode": "9414",
        "JobTitle": "fabricator, quartzware – stone products"
    },
    {
        "value": 20040,
        "NOCCode": "9414",
        "JobTitle": "filler, moulds – clay products"
    },
    {
        "value": 20041,
        "NOCCode": "9414",
        "JobTitle": "finisher – concrete, clay and stone products"
    },
    {
        "value": 20042,
        "NOCCode": "9414",
        "JobTitle": "finisher, greenware – clay products"
    },
    {
        "value": 20043,
        "NOCCode": "9414",
        "JobTitle": "finisher, pipes – clay products"
    },
    {
        "value": 20044,
        "NOCCode": "9414",
        "JobTitle": "finisher, surface plates – concrete, clay and stone products"
    },
    {
        "value": 20045,
        "NOCCode": "9414",
        "JobTitle": "fitting lathe operator – concrete products"
    },
    {
        "value": 20046,
        "NOCCode": "9414",
        "JobTitle": "flowerpot pressman/woman – clay products"
    },
    {
        "value": 20047,
        "NOCCode": "9414",
        "JobTitle": "former, corrugated asbestos board"
    },
    {
        "value": 20048,
        "NOCCode": "9414",
        "JobTitle": "former, pottery and porcelain – clay products"
    },
    {
        "value": 20049,
        "NOCCode": "9414",
        "JobTitle": "forming machine operator – concrete, clay and stone products"
    },
    {
        "value": 20050,
        "NOCCode": "9414",
        "JobTitle": "gang sawyer – stone products manufacturing"
    },
    {
        "value": 20051,
        "NOCCode": "9414",
        "JobTitle": "glaze maker – clay products"
    },
    {
        "value": 20052,
        "NOCCode": "9414",
        "JobTitle": "glazed products kilnman/woman – clay products"
    },
    {
        "value": 20053,
        "NOCCode": "9414",
        "JobTitle": "glost kiln operator – clay products"
    },
    {
        "value": 20054,
        "NOCCode": "9414",
        "JobTitle": "granite carver"
    },
    {
        "value": 20055,
        "NOCCode": "9414",
        "JobTitle": "granite cutter"
    },
    {
        "value": 20056,
        "NOCCode": "9414",
        "JobTitle": "granite polisher, hand"
    },
    {
        "value": 20057,
        "NOCCode": "9414",
        "JobTitle": "granite polisher, machine"
    },
    {
        "value": 20058,
        "NOCCode": "9414",
        "JobTitle": "granite products polisher"
    },
    {
        "value": 20059,
        "NOCCode": "9414",
        "JobTitle": "grave marker cutter – stone products"
    },
    {
        "value": 20060,
        "NOCCode": "9414",
        "JobTitle": "greenware driller – clay products"
    },
    {
        "value": 20061,
        "NOCCode": "9414",
        "JobTitle": "greenware finisher – clay products"
    },
    {
        "value": 20062,
        "NOCCode": "9414",
        "JobTitle": "greenware turner operator – clay products"
    },
    {
        "value": 20063,
        "NOCCode": "9414",
        "JobTitle": "greenware turner, machine – clay products"
    },
    {
        "value": 20064,
        "NOCCode": "9414",
        "JobTitle": "grinder – concrete, clay and stone products"
    },
    {
        "value": 20065,
        "NOCCode": "9414",
        "JobTitle": "grinder tender, stones"
    },
    {
        "value": 20066,
        "NOCCode": "9414",
        "JobTitle": "grinder, pottery and porcelain"
    },
    {
        "value": 20067,
        "NOCCode": "9414",
        "JobTitle": "grinder, rubber abrasive wheels"
    },
    {
        "value": 20068,
        "NOCCode": "9414",
        "JobTitle": "grinding stone sharpener"
    },
    {
        "value": 20069,
        "NOCCode": "9414",
        "JobTitle": "grinding wheel dresser"
    },
    {
        "value": 20070,
        "NOCCode": "9414",
        "JobTitle": "gypsum plasterboard maker"
    },
    {
        "value": 20071,
        "NOCCode": "9414",
        "JobTitle": "hammer mill tender – concrete, clay and stone products"
    },
    {
        "value": 20072,
        "NOCCode": "9414",
        "JobTitle": "hand brick moulder – clay products"
    },
    {
        "value": 20073,
        "NOCCode": "9414",
        "JobTitle": "hand clay products moulder"
    },
    {
        "value": 20074,
        "NOCCode": "9414",
        "JobTitle": "hand marble polisher"
    },
    {
        "value": 20075,
        "NOCCode": "9414",
        "JobTitle": "hand moulder – clay products"
    },
    {
        "value": 20076,
        "NOCCode": "9414",
        "JobTitle": "hand moulder, bricks – clay products"
    },
    {
        "value": 20077,
        "NOCCode": "9414",
        "JobTitle": "hand moulder, clay products"
    },
    {
        "value": 20078,
        "NOCCode": "9414",
        "JobTitle": "hand polisher, granite"
    },
    {
        "value": 20079,
        "NOCCode": "9414",
        "JobTitle": "hand polisher, marble"
    },
    {
        "value": 20080,
        "NOCCode": "9414",
        "JobTitle": "hand polisher, stones"
    },
    {
        "value": 20081,
        "NOCCode": "9414",
        "JobTitle": "hand porcelain and earthenware presser – clay products"
    },
    {
        "value": 20082,
        "NOCCode": "9414",
        "JobTitle": "hand presser – clay products"
    },
    {
        "value": 20083,
        "NOCCode": "9414",
        "JobTitle": "hand presser, porcelain and earthenware – clay products"
    },
    {
        "value": 20084,
        "NOCCode": "9414",
        "JobTitle": "hand stone polisher"
    },
    {
        "value": 20085,
        "NOCCode": "9414",
        "JobTitle": "hand stonecutter"
    },
    {
        "value": 20086,
        "NOCCode": "9414",
        "JobTitle": "hot press operator – clay products"
    },
    {
        "value": 20087,
        "NOCCode": "9414",
        "JobTitle": "hydraulic press operator, concrete"
    },
    {
        "value": 20088,
        "NOCCode": "9414",
        "JobTitle": "hydrogen furnace tender – clay products"
    },
    {
        "value": 20089,
        "NOCCode": "9414",
        "JobTitle": "insulation mat machine operator – stone products"
    },
    {
        "value": 20090,
        "NOCCode": "9414",
        "JobTitle": "insulator builder – clay products"
    },
    {
        "value": 20091,
        "NOCCode": "9414",
        "JobTitle": "insulator builder, clay – clay products"
    },
    {
        "value": 20092,
        "NOCCode": "9414",
        "JobTitle": "jigger operator – clay products"
    },
    {
        "value": 20093,
        "NOCCode": "9414",
        "JobTitle": "jigger operator, automatic – clay products"
    },
    {
        "value": 20094,
        "NOCCode": "9414",
        "JobTitle": "jiggerman/woman – clay products"
    },
    {
        "value": 20095,
        "NOCCode": "9414",
        "JobTitle": "joint sawyer – concrete, clay and stone products"
    },
    {
        "value": 20096,
        "NOCCode": "9414",
        "JobTitle": "jollier – clay products"
    },
    {
        "value": 20097,
        "NOCCode": "9414",
        "JobTitle": "junction maker – concrete, clay and stone products"
    },
    {
        "value": 20098,
        "NOCCode": "9414",
        "JobTitle": "kiln fireman/woman – clay products"
    },
    {
        "value": 20099,
        "NOCCode": "9414",
        "JobTitle": "kiln operator, bricks – clay products"
    },
    {
        "value": 20100,
        "NOCCode": "9414",
        "JobTitle": "kiln operator, decorating – clay products"
    },
    {
        "value": 20101,
        "NOCCode": "9414",
        "JobTitle": "kiln operator, glost – clay products"
    },
    {
        "value": 20102,
        "NOCCode": "9414",
        "JobTitle": "kiln operator, periodic – clay products"
    },
    {
        "value": 20103,
        "NOCCode": "9414",
        "JobTitle": "kiln operator, tunnel – clay products"
    },
    {
        "value": 20104,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman – clay products"
    },
    {
        "value": 20105,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman, bricks – clay products"
    },
    {
        "value": 20106,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman, decorated products – clay products"
    },
    {
        "value": 20107,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman, glazed products – clay products"
    },
    {
        "value": 20108,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman, periodic – clay products"
    },
    {
        "value": 20109,
        "NOCCode": "9414",
        "JobTitle": "kilnman/woman, tunnel – clay products"
    },
    {
        "value": 20110,
        "NOCCode": "9414",
        "JobTitle": "lathe operator – concrete products"
    },
    {
        "value": 20111,
        "NOCCode": "9414",
        "JobTitle": "laundry tub moulder – concrete products"
    },
    {
        "value": 20112,
        "NOCCode": "9414",
        "JobTitle": "layout marker, stones"
    },
    {
        "value": 20113,
        "NOCCode": "9414",
        "JobTitle": "letterer, monuments – stone products"
    },
    {
        "value": 20114,
        "NOCCode": "9414",
        "JobTitle": "lime mixer operator"
    },
    {
        "value": 20115,
        "NOCCode": "9414",
        "JobTitle": "lime mixer tender"
    },
    {
        "value": 20116,
        "NOCCode": "9414",
        "JobTitle": "machine buffer, stones"
    },
    {
        "value": 20117,
        "NOCCode": "9414",
        "JobTitle": "machine cement pipe shaper – clay products"
    },
    {
        "value": 20118,
        "NOCCode": "9414",
        "JobTitle": "machine contour grinder – concrete, clay and stone products"
    },
    {
        "value": 20119,
        "NOCCode": "9414",
        "JobTitle": "machine decorator – clay products"
    },
    {
        "value": 20120,
        "NOCCode": "9414",
        "JobTitle": "machine greenware turner – clay products"
    },
    {
        "value": 20121,
        "NOCCode": "9414",
        "JobTitle": "machine marble polisher – stone products"
    },
    {
        "value": 20122,
        "NOCCode": "9414",
        "JobTitle": "machine operator, brick cutting – clay products"
    },
    {
        "value": 20123,
        "NOCCode": "9414",
        "JobTitle": "machine operator, brick moulding – clay products"
    },
    {
        "value": 20124,
        "NOCCode": "9414",
        "JobTitle": "machine operator, clay products"
    },
    {
        "value": 20125,
        "NOCCode": "9414",
        "JobTitle": "machine operator, concrete blocks"
    },
    {
        "value": 20126,
        "NOCCode": "9414",
        "JobTitle": "machine operator, concrete spinning"
    },
    {
        "value": 20127,
        "NOCCode": "9414",
        "JobTitle": "machine operator, plasterboard"
    },
    {
        "value": 20128,
        "NOCCode": "9414",
        "JobTitle": "machine polisher, granite"
    },
    {
        "value": 20129,
        "NOCCode": "9414",
        "JobTitle": "machine polisher, marble – stone products"
    },
    {
        "value": 20130,
        "NOCCode": "9414",
        "JobTitle": "machine shaper, cement pipes – clay products"
    },
    {
        "value": 20131,
        "NOCCode": "9414",
        "JobTitle": "machine splitter, stones"
    },
    {
        "value": 20132,
        "NOCCode": "9414",
        "JobTitle": "machine stone buffer"
    },
    {
        "value": 20133,
        "NOCCode": "9414",
        "JobTitle": "machine stone splitter"
    },
    {
        "value": 20134,
        "NOCCode": "9414",
        "JobTitle": "machine turner, greenware – clay products"
    },
    {
        "value": 20135,
        "NOCCode": "9414",
        "JobTitle": "marble carver – concrete, clay and stone forming"
    },
    {
        "value": 20136,
        "NOCCode": "9414",
        "JobTitle": "marble cutter"
    },
    {
        "value": 20137,
        "NOCCode": "9414",
        "JobTitle": "marble polisher, hand"
    },
    {
        "value": 20138,
        "NOCCode": "9414",
        "JobTitle": "marble polisher, machine – stone products"
    },
    {
        "value": 20139,
        "NOCCode": "9414",
        "JobTitle": "marker, stone layouts"
    },
    {
        "value": 20140,
        "NOCCode": "9414",
        "JobTitle": "mill tender – clay products"
    },
    {
        "value": 20141,
        "NOCCode": "9414",
        "JobTitle": "millstone cutter – stone products"
    },
    {
        "value": 20142,
        "NOCCode": "9414",
        "JobTitle": "mixer – concrete, clay and stone products"
    },
    {
        "value": 20143,
        "NOCCode": "9414",
        "JobTitle": "mixer and extruder, modelling clay"
    },
    {
        "value": 20144,
        "NOCCode": "9414",
        "JobTitle": "mixer caster, concrete"
    },
    {
        "value": 20145,
        "NOCCode": "9414",
        "JobTitle": "mixer operator – concrete products"
    },
    {
        "value": 20146,
        "NOCCode": "9414",
        "JobTitle": "mixer operator, lime"
    },
    {
        "value": 20147,
        "NOCCode": "9414",
        "JobTitle": "mixer tender, lime"
    },
    {
        "value": 20148,
        "NOCCode": "9414",
        "JobTitle": "mixer, dry plaster"
    },
    {
        "value": 20149,
        "NOCCode": "9414",
        "JobTitle": "mixer, dry products – stone products"
    },
    {
        "value": 20150,
        "NOCCode": "9414",
        "JobTitle": "mixer, mortar"
    },
    {
        "value": 20151,
        "NOCCode": "9414",
        "JobTitle": "mixer, refractory mortar"
    },
    {
        "value": 20152,
        "NOCCode": "9414",
        "JobTitle": "mixer, slate"
    },
    {
        "value": 20153,
        "NOCCode": "9414",
        "JobTitle": "mixer-caster, concrete"
    },
    {
        "value": 20154,
        "NOCCode": "9414",
        "JobTitle": "mixing machine tender, plasterboard"
    },
    {
        "value": 20155,
        "NOCCode": "9414",
        "JobTitle": "model maker, pottery"
    },
    {
        "value": 20156,
        "NOCCode": "9414",
        "JobTitle": "modelling clay mixer and extruder"
    },
    {
        "value": 20157,
        "NOCCode": "9414",
        "JobTitle": "monument carver – stone products"
    },
    {
        "value": 20158,
        "NOCCode": "9414",
        "JobTitle": "monument engraver – stone products"
    },
    {
        "value": 20159,
        "NOCCode": "9414",
        "JobTitle": "monument letterer – stone products"
    },
    {
        "value": 20160,
        "NOCCode": "9414",
        "JobTitle": "monument stonecutter – stone products"
    },
    {
        "value": 20161,
        "NOCCode": "9414",
        "JobTitle": "mortar mixer"
    },
    {
        "value": 20162,
        "NOCCode": "9414",
        "JobTitle": "mould filler – clay products"
    },
    {
        "value": 20163,
        "NOCCode": "9414",
        "JobTitle": "moulder and finisher, diamond wheels"
    },
    {
        "value": 20164,
        "NOCCode": "9414",
        "JobTitle": "moulder and installer, clay structures"
    },
    {
        "value": 20165,
        "NOCCode": "9414",
        "JobTitle": "moulder, bricks – clay products"
    },
    {
        "value": 20166,
        "NOCCode": "9414",
        "JobTitle": "moulder, cement blocks – concrete products"
    },
    {
        "value": 20167,
        "NOCCode": "9414",
        "JobTitle": "moulder, clay products"
    },
    {
        "value": 20168,
        "NOCCode": "9414",
        "JobTitle": "moulder, claywork"
    },
    {
        "value": 20169,
        "NOCCode": "9414",
        "JobTitle": "moulder, concrete"
    },
    {
        "value": 20170,
        "NOCCode": "9414",
        "JobTitle": "moulder, concrete poles – concrete products manufacturing"
    },
    {
        "value": 20171,
        "NOCCode": "9414",
        "JobTitle": "moulder, concrete products"
    },
    {
        "value": 20172,
        "NOCCode": "9414",
        "JobTitle": "moulder, hand – clay products"
    },
    {
        "value": 20173,
        "NOCCode": "9414",
        "JobTitle": "moulder, pipe fittings – concrete products"
    },
    {
        "value": 20174,
        "NOCCode": "9414",
        "JobTitle": "moulder, precast concrete"
    },
    {
        "value": 20175,
        "NOCCode": "9414",
        "JobTitle": "moulder, refractory tamp – clay products"
    },
    {
        "value": 20176,
        "NOCCode": "9414",
        "JobTitle": "moulder, saggars – clay products"
    },
    {
        "value": 20177,
        "NOCCode": "9414",
        "JobTitle": "moulder, stone products"
    },
    {
        "value": 20178,
        "NOCCode": "9414",
        "JobTitle": "moulder, stonework"
    },
    {
        "value": 20179,
        "NOCCode": "9414",
        "JobTitle": "mouldmaker – clay products"
    },
    {
        "value": 20180,
        "NOCCode": "9414",
        "JobTitle": "mouldmaker, pottery"
    },
    {
        "value": 20181,
        "NOCCode": "9414",
        "JobTitle": "operator, asbestos board machine"
    },
    {
        "value": 20182,
        "NOCCode": "9414",
        "JobTitle": "operator, automatic jigger – clay products"
    },
    {
        "value": 20183,
        "NOCCode": "9414",
        "JobTitle": "operator, boring mill – concrete products"
    },
    {
        "value": 20184,
        "NOCCode": "9414",
        "JobTitle": "operator, brick kiln – clay products"
    },
    {
        "value": 20185,
        "NOCCode": "9414",
        "JobTitle": "operator, brick presser – clay products"
    },
    {
        "value": 20186,
        "NOCCode": "9414",
        "JobTitle": "operator, brick-cutting machine – clay products"
    },
    {
        "value": 20187,
        "NOCCode": "9414",
        "JobTitle": "operator, brick-moulding machine – clay products"
    },
    {
        "value": 20188,
        "NOCCode": "9414",
        "JobTitle": "operator, brush coater – concrete products"
    },
    {
        "value": 20189,
        "NOCCode": "9414",
        "JobTitle": "operator, cement pipe shaper – concrete products"
    },
    {
        "value": 20190,
        "NOCCode": "9414",
        "JobTitle": "operator, ceramic tile press – concrete products"
    },
    {
        "value": 20191,
        "NOCCode": "9414",
        "JobTitle": "operator, cinder block machine – concrete products"
    },
    {
        "value": 20192,
        "NOCCode": "9414",
        "JobTitle": "operator, clay dry press"
    },
    {
        "value": 20193,
        "NOCCode": "9414",
        "JobTitle": "operator, clay press"
    },
    {
        "value": 20194,
        "NOCCode": "9414",
        "JobTitle": "operator, clay products machine"
    },
    {
        "value": 20195,
        "NOCCode": "9414",
        "JobTitle": "operator, concrete block machine"
    },
    {
        "value": 20196,
        "NOCCode": "9414",
        "JobTitle": "operator, concrete hydraulic press"
    },
    {
        "value": 20197,
        "NOCCode": "9414",
        "JobTitle": "operator, concrete products machine"
    },
    {
        "value": 20198,
        "NOCCode": "9414",
        "JobTitle": "operator, concrete-spinning machine"
    },
    {
        "value": 20199,
        "NOCCode": "9414",
        "JobTitle": "operator, contour-grinding machine – concrete, clay and stone products"
    },
    {
        "value": 20200,
        "NOCCode": "9414",
        "JobTitle": "operator, cupola – concrete, clay and stone products"
    },
    {
        "value": 20201,
        "NOCCode": "9414",
        "JobTitle": "operator, cutter – concrete, clay and stone products"
    },
    {
        "value": 20202,
        "NOCCode": "9414",
        "JobTitle": "operator, decorating kiln – clay products"
    },
    {
        "value": 20203,
        "NOCCode": "9414",
        "JobTitle": "operator, drain tile auger machine – clay products"
    },
    {
        "value": 20204,
        "NOCCode": "9414",
        "JobTitle": "operator, drill press – stone products"
    },
    {
        "value": 20205,
        "NOCCode": "9414",
        "JobTitle": "operator, dry pan – stone products"
    },
    {
        "value": 20206,
        "NOCCode": "9414",
        "JobTitle": "operator, dry press – clay products"
    },
    {
        "value": 20207,
        "NOCCode": "9414",
        "JobTitle": "operator, extruder – concrete, clay and stone products"
    },
    {
        "value": 20208,
        "NOCCode": "9414",
        "JobTitle": "operator, forming machine – concrete, clay and stone products"
    },
    {
        "value": 20209,
        "NOCCode": "9414",
        "JobTitle": "operator, glost kiln – clay products"
    },
    {
        "value": 20210,
        "NOCCode": "9414",
        "JobTitle": "operator, greenware turner – clay products"
    },
    {
        "value": 20211,
        "NOCCode": "9414",
        "JobTitle": "operator, hot press – clay products"
    },
    {
        "value": 20212,
        "NOCCode": "9414",
        "JobTitle": "operator, insulation mat machine – stone products"
    },
    {
        "value": 20213,
        "NOCCode": "9414",
        "JobTitle": "operator, jigger – clay products"
    },
    {
        "value": 20214,
        "NOCCode": "9414",
        "JobTitle": "operator, lathe – concrete products"
    },
    {
        "value": 20215,
        "NOCCode": "9414",
        "JobTitle": "operator, lime mixer"
    },
    {
        "value": 20216,
        "NOCCode": "9414",
        "JobTitle": "operator, pebble mill – stone products"
    },
    {
        "value": 20217,
        "NOCCode": "9414",
        "JobTitle": "operator, periodic kiln – clay products"
    },
    {
        "value": 20218,
        "NOCCode": "9414",
        "JobTitle": "operator, planer – stone products"
    },
    {
        "value": 20219,
        "NOCCode": "9414",
        "JobTitle": "operator, plasterboard machine"
    },
    {
        "value": 20220,
        "NOCCode": "9414",
        "JobTitle": "operator, portable diamond drill and saw"
    },
    {
        "value": 20221,
        "NOCCode": "9414",
        "JobTitle": "operator, pug mill – clay products"
    },
    {
        "value": 20222,
        "NOCCode": "9414",
        "JobTitle": "operator, ram press – clay products"
    },
    {
        "value": 20223,
        "NOCCode": "9414",
        "JobTitle": "operator, ripsaw – stone products"
    },
    {
        "value": 20224,
        "NOCCode": "9414",
        "JobTitle": "operator, rock wool – stone products"
    },
    {
        "value": 20225,
        "NOCCode": "9414",
        "JobTitle": "operator, sandblast – stone products"
    },
    {
        "value": 20226,
        "NOCCode": "9414",
        "JobTitle": "operator, steam tank – stone products"
    },
    {
        "value": 20227,
        "NOCCode": "9414",
        "JobTitle": "operator, stone products machine"
    },
    {
        "value": 20228,
        "NOCCode": "9414",
        "JobTitle": "operator, stonework drill press"
    },
    {
        "value": 20229,
        "NOCCode": "9414",
        "JobTitle": "operator, stonework sandblast"
    },
    {
        "value": 20230,
        "NOCCode": "9414",
        "JobTitle": "operator, stonework saw"
    },
    {
        "value": 20231,
        "NOCCode": "9414",
        "JobTitle": "operator, tunnel kiln – clay products"
    },
    {
        "value": 20232,
        "NOCCode": "9414",
        "JobTitle": "operator, wire saw – concrete, clay and stone products"
    },
    {
        "value": 20233,
        "NOCCode": "9414",
        "JobTitle": "paving and stone block maker – concrete and stone products"
    },
    {
        "value": 20234,
        "NOCCode": "9414",
        "JobTitle": "pebble mill operator – stone products"
    },
    {
        "value": 20235,
        "NOCCode": "9414",
        "JobTitle": "periodic kiln operator – clay products"
    },
    {
        "value": 20236,
        "NOCCode": "9414",
        "JobTitle": "periodic kilnman/woman – clay products"
    },
    {
        "value": 20237,
        "NOCCode": "9414",
        "JobTitle": "pipe finisher – clay products"
    },
    {
        "value": 20238,
        "NOCCode": "9414",
        "JobTitle": "pipe fittings moulder – concrete products"
    },
    {
        "value": 20239,
        "NOCCode": "9414",
        "JobTitle": "pipe junction maker – concrete products"
    },
    {
        "value": 20240,
        "NOCCode": "9414",
        "JobTitle": "pipe tester – concrete products"
    },
    {
        "value": 20241,
        "NOCCode": "9414",
        "JobTitle": "planer operator – stone products"
    },
    {
        "value": 20242,
        "NOCCode": "9414",
        "JobTitle": "planer, stones"
    },
    {
        "value": 20243,
        "NOCCode": "9414",
        "JobTitle": "plaster die maker"
    },
    {
        "value": 20244,
        "NOCCode": "9414",
        "JobTitle": "plasterboard machine operator"
    },
    {
        "value": 20245,
        "NOCCode": "9414",
        "JobTitle": "plasterboard maker, gypsum"
    },
    {
        "value": 20246,
        "NOCCode": "9414",
        "JobTitle": "plasterboard-mixing machine tender"
    },
    {
        "value": 20247,
        "NOCCode": "9414",
        "JobTitle": "pole maker, concrete – concrete products manufacturing"
    },
    {
        "value": 20248,
        "NOCCode": "9414",
        "JobTitle": "polisher – concrete, clay and stone products"
    },
    {
        "value": 20249,
        "NOCCode": "9414",
        "JobTitle": "polisher, cement"
    },
    {
        "value": 20250,
        "NOCCode": "9414",
        "JobTitle": "polisher, clay products"
    },
    {
        "value": 20251,
        "NOCCode": "9414",
        "JobTitle": "polisher, granite products"
    },
    {
        "value": 20252,
        "NOCCode": "9414",
        "JobTitle": "polisher, pottery and porcelain"
    },
    {
        "value": 20253,
        "NOCCode": "9414",
        "JobTitle": "polisher, stone products"
    },
    {
        "value": 20254,
        "NOCCode": "9414",
        "JobTitle": "porcelain and earthenware presser, hand – clay products"
    },
    {
        "value": 20255,
        "NOCCode": "9414",
        "JobTitle": "portable diamond drill and saw operator"
    },
    {
        "value": 20256,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain chipper"
    },
    {
        "value": 20257,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain former – clay products"
    },
    {
        "value": 20258,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain grinder"
    },
    {
        "value": 20259,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain polisher"
    },
    {
        "value": 20260,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain presser"
    },
    {
        "value": 20261,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain sagger maker"
    },
    {
        "value": 20262,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain sander"
    },
    {
        "value": 20263,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain turner – clay products"
    },
    {
        "value": 20264,
        "NOCCode": "9414",
        "JobTitle": "pottery and porcelain ware caster"
    },
    {
        "value": 20265,
        "NOCCode": "9414",
        "JobTitle": "pottery dryer – clay products"
    },
    {
        "value": 20266,
        "NOCCode": "9414",
        "JobTitle": "pottery model maker"
    },
    {
        "value": 20267,
        "NOCCode": "9414",
        "JobTitle": "pottery mould maker"
    },
    {
        "value": 20268,
        "NOCCode": "9414",
        "JobTitle": "pottery trimmer"
    },
    {
        "value": 20269,
        "NOCCode": "9414",
        "JobTitle": "precast concrete moulder"
    },
    {
        "value": 20270,
        "NOCCode": "9414",
        "JobTitle": "precast concrete slab maker"
    },
    {
        "value": 20271,
        "NOCCode": "9414",
        "JobTitle": "precast terrazzo maker"
    },
    {
        "value": 20272,
        "NOCCode": "9414",
        "JobTitle": "press operator, ceramic tiles – concrete products"
    },
    {
        "value": 20273,
        "NOCCode": "9414",
        "JobTitle": "press operator, clay"
    },
    {
        "value": 20274,
        "NOCCode": "9414",
        "JobTitle": "presser, bricks – clay products"
    },
    {
        "value": 20275,
        "NOCCode": "9414",
        "JobTitle": "presser, pottery and porcelain"
    },
    {
        "value": 20276,
        "NOCCode": "9414",
        "JobTitle": "pressman/woman, flowerpots – clay products"
    },
    {
        "value": 20277,
        "NOCCode": "9414",
        "JobTitle": "pug mill operator – clay products"
    },
    {
        "value": 20278,
        "NOCCode": "9414",
        "JobTitle": "pulpstone builder"
    },
    {
        "value": 20279,
        "NOCCode": "9414",
        "JobTitle": "quartzware fabricator – stone products"
    },
    {
        "value": 20280,
        "NOCCode": "9414",
        "JobTitle": "ram press operator – clay products"
    },
    {
        "value": 20281,
        "NOCCode": "9414",
        "JobTitle": "refractory mortar mixer"
    },
    {
        "value": 20282,
        "NOCCode": "9414",
        "JobTitle": "refractory tamp moulder – clay products"
    },
    {
        "value": 20283,
        "NOCCode": "9414",
        "JobTitle": "retort tender – clay products"
    },
    {
        "value": 20284,
        "NOCCode": "9414",
        "JobTitle": "ripsaw operator – stone products"
    },
    {
        "value": 20285,
        "NOCCode": "9414",
        "JobTitle": "ripsawyer – stone products"
    },
    {
        "value": 20286,
        "NOCCode": "9414",
        "JobTitle": "rock wool operator – stone products"
    },
    {
        "value": 20287,
        "NOCCode": "9414",
        "JobTitle": "rubber abrasive wheel grinder"
    },
    {
        "value": 20288,
        "NOCCode": "9414",
        "JobTitle": "saggar moulder – clay products"
    },
    {
        "value": 20289,
        "NOCCode": "9414",
        "JobTitle": "sagger maker, pottery and porcelain"
    },
    {
        "value": 20290,
        "NOCCode": "9414",
        "JobTitle": "sandblast operator – stone products"
    },
    {
        "value": 20291,
        "NOCCode": "9414",
        "JobTitle": "sandblast operator, stonework"
    },
    {
        "value": 20292,
        "NOCCode": "9414",
        "JobTitle": "sandblaster, stones"
    },
    {
        "value": 20293,
        "NOCCode": "9414",
        "JobTitle": "sander, pottery and porcelain"
    },
    {
        "value": 20294,
        "NOCCode": "9414",
        "JobTitle": "saw operator, stonework"
    },
    {
        "value": 20295,
        "NOCCode": "9414",
        "JobTitle": "saw operator, wire – concrete, clay and stone products"
    },
    {
        "value": 20296,
        "NOCCode": "9414",
        "JobTitle": "saw tender, concrete"
    },
    {
        "value": 20297,
        "NOCCode": "9414",
        "JobTitle": "sawyer, concrete"
    },
    {
        "value": 20298,
        "NOCCode": "9414",
        "JobTitle": "sawyer, joints – concrete, clay and stone products"
    },
    {
        "value": 20299,
        "NOCCode": "9414",
        "JobTitle": "sawyer, stones – stone products"
    },
    {
        "value": 20300,
        "NOCCode": "9414",
        "JobTitle": "scagliola caster – stone products"
    },
    {
        "value": 20301,
        "NOCCode": "9414",
        "JobTitle": "set-up operator, brick machine – clay products"
    },
    {
        "value": 20302,
        "NOCCode": "9414",
        "JobTitle": "shaper operator, cement pipes – concrete products"
    },
    {
        "value": 20303,
        "NOCCode": "9414",
        "JobTitle": "sharpener, grinding stones"
    },
    {
        "value": 20304,
        "NOCCode": "9414",
        "JobTitle": "slab maker, precast concrete"
    },
    {
        "value": 20305,
        "NOCCode": "9414",
        "JobTitle": "slate bed maker – clay products"
    },
    {
        "value": 20306,
        "NOCCode": "9414",
        "JobTitle": "slate cutter"
    },
    {
        "value": 20307,
        "NOCCode": "9414",
        "JobTitle": "slate mixer"
    },
    {
        "value": 20308,
        "NOCCode": "9414",
        "JobTitle": "slate-cutting and slate-grinding machine tender"
    },
    {
        "value": 20309,
        "NOCCode": "9414",
        "JobTitle": "slip mixer tender"
    },
    {
        "value": 20310,
        "NOCCode": "9414",
        "JobTitle": "splitter tender, stone slabs"
    },
    {
        "value": 20311,
        "NOCCode": "9414",
        "JobTitle": "steam tank operator – stone products"
    },
    {
        "value": 20312,
        "NOCCode": "9414",
        "JobTitle": "steam-pressure cylinder tender – clay products"
    },
    {
        "value": 20313,
        "NOCCode": "9414",
        "JobTitle": "stencil cutter – stone products"
    },
    {
        "value": 20314,
        "NOCCode": "9414",
        "JobTitle": "stone beveller"
    },
    {
        "value": 20315,
        "NOCCode": "9414",
        "JobTitle": "stone buffer, machine"
    },
    {
        "value": 20316,
        "NOCCode": "9414",
        "JobTitle": "stone driller"
    },
    {
        "value": 20317,
        "NOCCode": "9414",
        "JobTitle": "stone driller – stone products"
    },
    {
        "value": 20318,
        "NOCCode": "9414",
        "JobTitle": "stone grinder tender"
    },
    {
        "value": 20319,
        "NOCCode": "9414",
        "JobTitle": "stone layout marker"
    },
    {
        "value": 20320,
        "NOCCode": "9414",
        "JobTitle": "stone planer"
    },
    {
        "value": 20321,
        "NOCCode": "9414",
        "JobTitle": "stone polisher, hand"
    },
    {
        "value": 20322,
        "NOCCode": "9414",
        "JobTitle": "stone polisher, machine"
    },
    {
        "value": 20323,
        "NOCCode": "9414",
        "JobTitle": "stone products buffer"
    },
    {
        "value": 20324,
        "NOCCode": "9414",
        "JobTitle": "stone products machine operator"
    },
    {
        "value": 20325,
        "NOCCode": "9414",
        "JobTitle": "stone products moulder"
    },
    {
        "value": 20326,
        "NOCCode": "9414",
        "JobTitle": "stone products polisher"
    },
    {
        "value": 20327,
        "NOCCode": "9414",
        "JobTitle": "stone sandblaster"
    },
    {
        "value": 20328,
        "NOCCode": "9414",
        "JobTitle": "stone saw operator"
    },
    {
        "value": 20329,
        "NOCCode": "9414",
        "JobTitle": "stone sawyer – stone products"
    },
    {
        "value": 20330,
        "NOCCode": "9414",
        "JobTitle": "stone slab splitter tender"
    },
    {
        "value": 20331,
        "NOCCode": "9414",
        "JobTitle": "stone splitter, machine"
    },
    {
        "value": 20332,
        "NOCCode": "9414",
        "JobTitle": "stonecutter – stone products"
    },
    {
        "value": 20333,
        "NOCCode": "9414",
        "JobTitle": "stonecutter, hand"
    },
    {
        "value": 20334,
        "NOCCode": "9414",
        "JobTitle": "stonecutter, monuments – stone products"
    },
    {
        "value": 20335,
        "NOCCode": "9414",
        "JobTitle": "stonework block maker"
    },
    {
        "value": 20336,
        "NOCCode": "9414",
        "JobTitle": "stonework drill press operator"
    },
    {
        "value": 20337,
        "NOCCode": "9414",
        "JobTitle": "stonework engraver – stone products"
    },
    {
        "value": 20338,
        "NOCCode": "9414",
        "JobTitle": "stonework moulder"
    },
    {
        "value": 20339,
        "NOCCode": "9414",
        "JobTitle": "stonework sandblast operator"
    },
    {
        "value": 20340,
        "NOCCode": "9414",
        "JobTitle": "stonework saw operator"
    },
    {
        "value": 20341,
        "NOCCode": "9414",
        "JobTitle": "stonework stencil cutter"
    },
    {
        "value": 20342,
        "NOCCode": "9414",
        "JobTitle": "surface plate finisher – concrete, clay and stone products"
    },
    {
        "value": 20343,
        "NOCCode": "9414",
        "JobTitle": "tender, concrete pipe machine"
    },
    {
        "value": 20344,
        "NOCCode": "9414",
        "JobTitle": "tender, concrete saw"
    },
    {
        "value": 20345,
        "NOCCode": "9414",
        "JobTitle": "tender, decorating machine – clay products"
    },
    {
        "value": 20346,
        "NOCCode": "9414",
        "JobTitle": "tender, drying machine – clay products"
    },
    {
        "value": 20347,
        "NOCCode": "9414",
        "JobTitle": "tender, hammer mill – concrete, clay and stone products"
    },
    {
        "value": 20348,
        "NOCCode": "9414",
        "JobTitle": "tender, hydrogen furnace – clay products"
    },
    {
        "value": 20349,
        "NOCCode": "9414",
        "JobTitle": "tender, lime mixer"
    },
    {
        "value": 20350,
        "NOCCode": "9414",
        "JobTitle": "tender, mill – clay products"
    },
    {
        "value": 20351,
        "NOCCode": "9414",
        "JobTitle": "tender, retort – clay products"
    },
    {
        "value": 20352,
        "NOCCode": "9414",
        "JobTitle": "tender, slate-cutting and slate-grinding machine"
    },
    {
        "value": 20353,
        "NOCCode": "9414",
        "JobTitle": "tender, slip mixer"
    },
    {
        "value": 20354,
        "NOCCode": "9414",
        "JobTitle": "tender, steam-pressure cylinder – clay products"
    },
    {
        "value": 20355,
        "NOCCode": "9414",
        "JobTitle": "tender, stone grinder"
    },
    {
        "value": 20356,
        "NOCCode": "9414",
        "JobTitle": "tender, stone slab splitter"
    },
    {
        "value": 20357,
        "NOCCode": "9414",
        "JobTitle": "terrazzo maker, precast"
    },
    {
        "value": 20358,
        "NOCCode": "9414",
        "JobTitle": "tester, pipes – concrete products"
    },
    {
        "value": 20359,
        "NOCCode": "9414",
        "JobTitle": "tile burner – clay products"
    },
    {
        "value": 20360,
        "NOCCode": "9414",
        "JobTitle": "tile kilnman/woman – clay products"
    },
    {
        "value": 20361,
        "NOCCode": "9414",
        "JobTitle": "tile maker – clay products"
    },
    {
        "value": 20362,
        "NOCCode": "9414",
        "JobTitle": "trimmer, pottery"
    },
    {
        "value": 20363,
        "NOCCode": "9414",
        "JobTitle": "tunnel kiln operator – clay products"
    },
    {
        "value": 20364,
        "NOCCode": "9414",
        "JobTitle": "tunnel kilnman/woman – clay products"
    },
    {
        "value": 20365,
        "NOCCode": "9414",
        "JobTitle": "turner operator, greenware – clay products"
    },
    {
        "value": 20366,
        "NOCCode": "9414",
        "JobTitle": "turner, clayware"
    },
    {
        "value": 20367,
        "NOCCode": "9414",
        "JobTitle": "turner, pottery and porcelain – clay products"
    },
    {
        "value": 20368,
        "NOCCode": "9414",
        "JobTitle": "vault maker, concrete"
    },
    {
        "value": 20369,
        "NOCCode": "9414",
        "JobTitle": "ware dresser – concrete, clay and stone products"
    },
    {
        "value": 20370,
        "NOCCode": "9414",
        "JobTitle": "wheel maker, abrasive"
    },
    {
        "value": 20371,
        "NOCCode": "9414",
        "JobTitle": "wire saw operator – concrete, clay and stone products"
    },
    {
        "value": 20372,
        "NOCCode": "9415",
        "JobTitle": "abrasive and clay products grader"
    },
    {
        "value": 20373,
        "NOCCode": "9415",
        "JobTitle": "abrasive wheel tester"
    },
    {
        "value": 20374,
        "NOCCode": "9415",
        "JobTitle": "anode adjuster – primary metal processing"
    },
    {
        "value": 20375,
        "NOCCode": "9415",
        "JobTitle": "asbestos classifier"
    },
    {
        "value": 20376,
        "NOCCode": "9415",
        "JobTitle": "asbestos fibre inspector and tester"
    },
    {
        "value": 20377,
        "NOCCode": "9415",
        "JobTitle": "asbestos grader"
    },
    {
        "value": 20378,
        "NOCCode": "9415",
        "JobTitle": "asbestos shingle inspector"
    },
    {
        "value": 20379,
        "NOCCode": "9415",
        "JobTitle": "brick and tile inspector"
    },
    {
        "value": 20380,
        "NOCCode": "9415",
        "JobTitle": "brick and tile tester"
    },
    {
        "value": 20381,
        "NOCCode": "9415",
        "JobTitle": "brick grader"
    },
    {
        "value": 20382,
        "NOCCode": "9415",
        "JobTitle": "castings inspector – foundry"
    },
    {
        "value": 20383,
        "NOCCode": "9415",
        "JobTitle": "castings tester – primary metal and mineral products processing"
    },
    {
        "value": 20384,
        "NOCCode": "9415",
        "JobTitle": "cell tester – primary metal processing"
    },
    {
        "value": 20385,
        "NOCCode": "9415",
        "JobTitle": "cement plant inspector"
    },
    {
        "value": 20386,
        "NOCCode": "9415",
        "JobTitle": "clay products grader"
    },
    {
        "value": 20387,
        "NOCCode": "9415",
        "JobTitle": "clay tile grader – mineral products processing"
    },
    {
        "value": 20388,
        "NOCCode": "9415",
        "JobTitle": "coke inspector – primary metal processing"
    },
    {
        "value": 20389,
        "NOCCode": "9415",
        "JobTitle": "coke sampler and tester – primary metal processing"
    },
    {
        "value": 20390,
        "NOCCode": "9415",
        "JobTitle": "combustion tester, furnace – primary metal processing"
    },
    {
        "value": 20391,
        "NOCCode": "9415",
        "JobTitle": "concrete products inspector"
    },
    {
        "value": 20392,
        "NOCCode": "9415",
        "JobTitle": "concrete products tester"
    },
    {
        "value": 20393,
        "NOCCode": "9415",
        "JobTitle": "container tester, glass"
    },
    {
        "value": 20394,
        "NOCCode": "9415",
        "JobTitle": "core inspector – foundry"
    },
    {
        "value": 20395,
        "NOCCode": "9415",
        "JobTitle": "core tester – foundry"
    },
    {
        "value": 20396,
        "NOCCode": "9415",
        "JobTitle": "electrolytic cell tester – primary metal processing"
    },
    {
        "value": 20397,
        "NOCCode": "9415",
        "JobTitle": "evaluator, uranium ore"
    },
    {
        "value": 20398,
        "NOCCode": "9415",
        "JobTitle": "fibreglass tester"
    },
    {
        "value": 20399,
        "NOCCode": "9415",
        "JobTitle": "finishing inspector – primary metal and mineral products processing"
    },
    {
        "value": 20400,
        "NOCCode": "9415",
        "JobTitle": "foundry sand tester"
    },
    {
        "value": 20401,
        "NOCCode": "9415",
        "JobTitle": "glass container tester"
    },
    {
        "value": 20402,
        "NOCCode": "9415",
        "JobTitle": "glass inspector"
    },
    {
        "value": 20403,
        "NOCCode": "9415",
        "JobTitle": "glass quality control inspector"
    },
    {
        "value": 20404,
        "NOCCode": "9415",
        "JobTitle": "glass tester"
    },
    {
        "value": 20405,
        "NOCCode": "9415",
        "JobTitle": "grader, abrasive and clay products"
    },
    {
        "value": 20406,
        "NOCCode": "9415",
        "JobTitle": "grader, asbestos"
    },
    {
        "value": 20407,
        "NOCCode": "9415",
        "JobTitle": "grader, bricks"
    },
    {
        "value": 20408,
        "NOCCode": "9415",
        "JobTitle": "grader, clay products"
    },
    {
        "value": 20409,
        "NOCCode": "9415",
        "JobTitle": "grader, clay tiles – mineral products processing"
    },
    {
        "value": 20410,
        "NOCCode": "9415",
        "JobTitle": "grader, stonework"
    },
    {
        "value": 20411,
        "NOCCode": "9415",
        "JobTitle": "gravel grader – mineral products processing"
    },
    {
        "value": 20412,
        "NOCCode": "9415",
        "JobTitle": "hardness tester – primary metal processing"
    },
    {
        "value": 20413,
        "NOCCode": "9415",
        "JobTitle": "heat treating inspector – metal processing"
    },
    {
        "value": 20414,
        "NOCCode": "9415",
        "JobTitle": "ingots inspector – primary metal processing"
    },
    {
        "value": 20415,
        "NOCCode": "9415",
        "JobTitle": "inspector – metal processing"
    },
    {
        "value": 20416,
        "NOCCode": "9415",
        "JobTitle": "inspector – primary metal and mineral products processing"
    },
    {
        "value": 20417,
        "NOCCode": "9415",
        "JobTitle": "inspector and grader, tin plates"
    },
    {
        "value": 20418,
        "NOCCode": "9415",
        "JobTitle": "inspector, asbestos shingles"
    },
    {
        "value": 20419,
        "NOCCode": "9415",
        "JobTitle": "inspector, bricks and tiles"
    },
    {
        "value": 20420,
        "NOCCode": "9415",
        "JobTitle": "inspector, castings – foundry"
    },
    {
        "value": 20421,
        "NOCCode": "9415",
        "JobTitle": "inspector, glass"
    },
    {
        "value": 20422,
        "NOCCode": "9415",
        "JobTitle": "inspector, heat treating – metal processing"
    },
    {
        "value": 20423,
        "NOCCode": "9415",
        "JobTitle": "inspector, ingots – primary metal processing"
    },
    {
        "value": 20424,
        "NOCCode": "9415",
        "JobTitle": "inspector, metal processing"
    },
    {
        "value": 20425,
        "NOCCode": "9415",
        "JobTitle": "inspector, pottery and porcelain ware"
    },
    {
        "value": 20426,
        "NOCCode": "9415",
        "JobTitle": "inspector, sheet steel – primary metal processing"
    },
    {
        "value": 20427,
        "NOCCode": "9415",
        "JobTitle": "inspector, steel – primary metal processing"
    },
    {
        "value": 20428,
        "NOCCode": "9415",
        "JobTitle": "inspector, steel sheets – primary metal processing"
    },
    {
        "value": 20429,
        "NOCCode": "9415",
        "JobTitle": "inspector, stones – mineral products processing"
    },
    {
        "value": 20430,
        "NOCCode": "9415",
        "JobTitle": "inspectors, wire products"
    },
    {
        "value": 20431,
        "NOCCode": "9415",
        "JobTitle": "iron pellet hardness tester – metal processing"
    },
    {
        "value": 20432,
        "NOCCode": "9415",
        "JobTitle": "laminated asbestos products inspector"
    },
    {
        "value": 20433,
        "NOCCode": "9415",
        "JobTitle": "metal processing inspector"
    },
    {
        "value": 20434,
        "NOCCode": "9415",
        "JobTitle": "metal strength tester – primary metal processing"
    },
    {
        "value": 20435,
        "NOCCode": "9415",
        "JobTitle": "metal tensile strength tester – primary metal processing"
    },
    {
        "value": 20436,
        "NOCCode": "9415",
        "JobTitle": "mineral sampler – mineral products processing"
    },
    {
        "value": 20437,
        "NOCCode": "9415",
        "JobTitle": "molten metal sampler – primary metal processing"
    },
    {
        "value": 20438,
        "NOCCode": "9415",
        "JobTitle": "monument inspector – mineral products processing"
    },
    {
        "value": 20439,
        "NOCCode": "9415",
        "JobTitle": "ore sample tester – mineral products processing"
    },
    {
        "value": 20440,
        "NOCCode": "9415",
        "JobTitle": "ore sampler – mineral products processing"
    },
    {
        "value": 20441,
        "NOCCode": "9415",
        "JobTitle": "physical tester – steel mill"
    },
    {
        "value": 20442,
        "NOCCode": "9415",
        "JobTitle": "plasterboard inspector – mineral products processing"
    },
    {
        "value": 20443,
        "NOCCode": "9415",
        "JobTitle": "pottery and porcelain ware inspector"
    },
    {
        "value": 20444,
        "NOCCode": "9415",
        "JobTitle": "quality assurance inspector – steel mill"
    },
    {
        "value": 20445,
        "NOCCode": "9415",
        "JobTitle": "quality control inspector, glass"
    },
    {
        "value": 20446,
        "NOCCode": "9415",
        "JobTitle": "salvage glass inspector"
    },
    {
        "value": 20447,
        "NOCCode": "9415",
        "JobTitle": "sample tester – primary metal and mineral products processing"
    },
    {
        "value": 20448,
        "NOCCode": "9415",
        "JobTitle": "sampler – primary metal and mineral products processing"
    },
    {
        "value": 20449,
        "NOCCode": "9415",
        "JobTitle": "sampler and tester, coke – primary metal processing"
    },
    {
        "value": 20450,
        "NOCCode": "9415",
        "JobTitle": "sampler, minerals – mineral products processing"
    },
    {
        "value": 20451,
        "NOCCode": "9415",
        "JobTitle": "sampler, molten metal – primary metal processing"
    },
    {
        "value": 20452,
        "NOCCode": "9415",
        "JobTitle": "sand tester, foundry"
    },
    {
        "value": 20453,
        "NOCCode": "9415",
        "JobTitle": "sheet and plate inspector – steel mill"
    },
    {
        "value": 20454,
        "NOCCode": "9415",
        "JobTitle": "sheet steel inspector – primary metal processing"
    },
    {
        "value": 20455,
        "NOCCode": "9415",
        "JobTitle": "steel inspector – primary metal processing"
    },
    {
        "value": 20456,
        "NOCCode": "9415",
        "JobTitle": "steel sheet inspector – primary metal processing"
    },
    {
        "value": 20457,
        "NOCCode": "9415",
        "JobTitle": "steel tester – primary metal processing"
    },
    {
        "value": 20458,
        "NOCCode": "9415",
        "JobTitle": "stone inspector – mineral products processing"
    },
    {
        "value": 20459,
        "NOCCode": "9415",
        "JobTitle": "tensile strength tester, metal – primary metal processing"
    },
    {
        "value": 20460,
        "NOCCode": "9415",
        "JobTitle": "tester and inspector – primary metal and mineral products processing"
    },
    {
        "value": 20461,
        "NOCCode": "9415",
        "JobTitle": "tester and inspector, wires – primary metal processing"
    },
    {
        "value": 20462,
        "NOCCode": "9415",
        "JobTitle": "tester, bricks and tiles"
    },
    {
        "value": 20463,
        "NOCCode": "9415",
        "JobTitle": "tester, castings – primary metal and mineral products processing"
    },
    {
        "value": 20464,
        "NOCCode": "9415",
        "JobTitle": "tester, cells – primary metal processing"
    },
    {
        "value": 20465,
        "NOCCode": "9415",
        "JobTitle": "tester, cores – foundry"
    },
    {
        "value": 20466,
        "NOCCode": "9415",
        "JobTitle": "tester, glass"
    },
    {
        "value": 20467,
        "NOCCode": "9415",
        "JobTitle": "tester, glass containers"
    },
    {
        "value": 20468,
        "NOCCode": "9415",
        "JobTitle": "tester, metal strength – primary metal processing"
    },
    {
        "value": 20469,
        "NOCCode": "9415",
        "JobTitle": "tester, metal tensile strength – primary metal processing"
    },
    {
        "value": 20470,
        "NOCCode": "9415",
        "JobTitle": "tester, steel – primary metal processing"
    },
    {
        "value": 20471,
        "NOCCode": "9415",
        "JobTitle": "tile grader, clay – mineral products processing"
    },
    {
        "value": 20472,
        "NOCCode": "9415",
        "JobTitle": "tin plate inspector and grader"
    },
    {
        "value": 20473,
        "NOCCode": "9415",
        "JobTitle": "uranium ore evaluator"
    },
    {
        "value": 20474,
        "NOCCode": "9415",
        "JobTitle": "wire products inspector"
    },
    {
        "value": 20475,
        "NOCCode": "9415",
        "JobTitle": "wire tester and inspector – primary metal processing"
    },
    {
        "value": 20476,
        "NOCCode": "9416",
        "JobTitle": "acetylene torch operator – metal fabrication"
    },
    {
        "value": 20477,
        "NOCCode": "9416",
        "JobTitle": "air hammer operator – metal forging"
    },
    {
        "value": 20478,
        "NOCCode": "9416",
        "JobTitle": "aluminum sheet cutter – ductwork"
    },
    {
        "value": 20479,
        "NOCCode": "9416",
        "JobTitle": "aluminum sheet cutter – metal fabrication"
    },
    {
        "value": 20480,
        "NOCCode": "9416",
        "JobTitle": "aluminum sheet trimmer – ductwork"
    },
    {
        "value": 20481,
        "NOCCode": "9416",
        "JobTitle": "angle shear operator – metal fabrication"
    },
    {
        "value": 20482,
        "NOCCode": "9416",
        "JobTitle": "arc cutter – metal fabrication"
    },
    {
        "value": 20483,
        "NOCCode": "9416",
        "JobTitle": "automatic cut-off operator – metal fabrication"
    },
    {
        "value": 20484,
        "NOCCode": "9416",
        "JobTitle": "automatic cut-off saw operator – metal products manufacturing"
    },
    {
        "value": 20485,
        "NOCCode": "9416",
        "JobTitle": "bender set-up operator – metal products manufacturing"
    },
    {
        "value": 20486,
        "NOCCode": "9416",
        "JobTitle": "bending machine operator – metal forging"
    },
    {
        "value": 20487,
        "NOCCode": "9416",
        "JobTitle": "bending machine set-up operator – metal fabrication"
    },
    {
        "value": 20488,
        "NOCCode": "9416",
        "JobTitle": "bending machine tender – metal fabrication"
    },
    {
        "value": 20489,
        "NOCCode": "9416",
        "JobTitle": "bending machine tender – metal products manufacturing"
    },
    {
        "value": 20490,
        "NOCCode": "9416",
        "JobTitle": "bending press operator – metal fabrication"
    },
    {
        "value": 20491,
        "NOCCode": "9416",
        "JobTitle": "bending roll operator – metal fabrication"
    },
    {
        "value": 20492,
        "NOCCode": "9416",
        "JobTitle": "board drop hammer operator"
    },
    {
        "value": 20493,
        "NOCCode": "9416",
        "JobTitle": "board hammer operator – metal forging"
    },
    {
        "value": 20494,
        "NOCCode": "9416",
        "JobTitle": "brake setter – metalworking"
    },
    {
        "value": 20495,
        "NOCCode": "9416",
        "JobTitle": "buffing line setter"
    },
    {
        "value": 20496,
        "NOCCode": "9416",
        "JobTitle": "buffing machine set-up operator – metal fabrication"
    },
    {
        "value": 20497,
        "NOCCode": "9416",
        "JobTitle": "burning machine operator – metal fabrication"
    },
    {
        "value": 20498,
        "NOCCode": "9416",
        "JobTitle": "burning table operator – metal fabrication"
    },
    {
        "value": 20499,
        "NOCCode": "9416",
        "JobTitle": "cage builder – metal fabrication"
    },
    {
        "value": 20500,
        "NOCCode": "9416",
        "JobTitle": "CNC (computer numerically controlled) brake press operator"
    },
    {
        "value": 20501,
        "NOCCode": "9416",
        "JobTitle": "CNC (computer numerically controlled) brake press set-up operator"
    },
    {
        "value": 20502,
        "NOCCode": "9416",
        "JobTitle": "cold drawn operator – metal forging"
    },
    {
        "value": 20503,
        "NOCCode": "9416",
        "JobTitle": "cold press operator – metal forging"
    },
    {
        "value": 20504,
        "NOCCode": "9416",
        "JobTitle": "corrugation machine operator, sheet metal"
    },
    {
        "value": 20505,
        "NOCCode": "9416",
        "JobTitle": "corrugator sheet metal operator"
    },
    {
        "value": 20506,
        "NOCCode": "9416",
        "JobTitle": "corrugator, sheet metal"
    },
    {
        "value": 20507,
        "NOCCode": "9416",
        "JobTitle": "cutter, aluminum sheet – ductwork"
    },
    {
        "value": 20508,
        "NOCCode": "9416",
        "JobTitle": "disc flanging operator – metal fabrication"
    },
    {
        "value": 20509,
        "NOCCode": "9416",
        "JobTitle": "disk flange operator"
    },
    {
        "value": 20510,
        "NOCCode": "9416",
        "JobTitle": "disk flange operator – metal fabrication"
    },
    {
        "value": 20511,
        "NOCCode": "9416",
        "JobTitle": "drop forge hand"
    },
    {
        "value": 20512,
        "NOCCode": "9416",
        "JobTitle": "drop forge operator"
    },
    {
        "value": 20513,
        "NOCCode": "9416",
        "JobTitle": "drop forge operator – metal forging"
    },
    {
        "value": 20514,
        "NOCCode": "9416",
        "JobTitle": "drop forger"
    },
    {
        "value": 20515,
        "NOCCode": "9416",
        "JobTitle": "drop hammer forger"
    },
    {
        "value": 20516,
        "NOCCode": "9416",
        "JobTitle": "drop hammer operator – metal forging"
    },
    {
        "value": 20517,
        "NOCCode": "9416",
        "JobTitle": "dual action hammer operator – metal forging"
    },
    {
        "value": 20518,
        "NOCCode": "9416",
        "JobTitle": "duplicator punch operator – metal fabrication"
    },
    {
        "value": 20519,
        "NOCCode": "9416",
        "JobTitle": "electric arc cutter – metal products manufacturing"
    },
    {
        "value": 20520,
        "NOCCode": "9416",
        "JobTitle": "expanding machine operator – metal products manufacturing"
    },
    {
        "value": 20521,
        "NOCCode": "9416",
        "JobTitle": "flame cutting machine operator – metal fabrication"
    },
    {
        "value": 20522,
        "NOCCode": "9416",
        "JobTitle": "flame cutting tracer machine operator – metal fabrication"
    },
    {
        "value": 20523,
        "NOCCode": "9416",
        "JobTitle": "flame hardening machine setter"
    },
    {
        "value": 20524,
        "NOCCode": "9416",
        "JobTitle": "flying shear operator – metal fabrication"
    },
    {
        "value": 20525,
        "NOCCode": "9416",
        "JobTitle": "forge manipulator operator"
    },
    {
        "value": 20526,
        "NOCCode": "9416",
        "JobTitle": "forging air hammer operator"
    },
    {
        "value": 20527,
        "NOCCode": "9416",
        "JobTitle": "forging machine operator"
    },
    {
        "value": 20528,
        "NOCCode": "9416",
        "JobTitle": "forging machine setter"
    },
    {
        "value": 20529,
        "NOCCode": "9416",
        "JobTitle": "forging machine set-up man/woman"
    },
    {
        "value": 20530,
        "NOCCode": "9416",
        "JobTitle": "forging press operator"
    },
    {
        "value": 20531,
        "NOCCode": "9416",
        "JobTitle": "forging press tender"
    },
    {
        "value": 20532,
        "NOCCode": "9416",
        "JobTitle": "forging roll operator"
    },
    {
        "value": 20533,
        "NOCCode": "9416",
        "JobTitle": "forming machine operator – metal fabrication"
    },
    {
        "value": 20534,
        "NOCCode": "9416",
        "JobTitle": "forming press operator – metal fabrication"
    },
    {
        "value": 20535,
        "NOCCode": "9416",
        "JobTitle": "forming roll set-up operator – metal fabrication"
    },
    {
        "value": 20536,
        "NOCCode": "9416",
        "JobTitle": "forming roll tender – metal fabrication"
    },
    {
        "value": 20537,
        "NOCCode": "9416",
        "JobTitle": "group leader, forging machine operator"
    },
    {
        "value": 20538,
        "NOCCode": "9416",
        "JobTitle": "hammer driver – forging"
    },
    {
        "value": 20539,
        "NOCCode": "9416",
        "JobTitle": "hammer operator – metal forging"
    },
    {
        "value": 20540,
        "NOCCode": "9416",
        "JobTitle": "heater operator – metal forging"
    },
    {
        "value": 20541,
        "NOCCode": "9416",
        "JobTitle": "heavy forger"
    },
    {
        "value": 20542,
        "NOCCode": "9416",
        "JobTitle": "heavy-duty press set-up operator – metal fabrication"
    },
    {
        "value": 20543,
        "NOCCode": "9416",
        "JobTitle": "hoop bender"
    },
    {
        "value": 20544,
        "NOCCode": "9416",
        "JobTitle": "hoop-maker machine operator – metal fabrication"
    },
    {
        "value": 20545,
        "NOCCode": "9416",
        "JobTitle": "hot press operator – metal forging"
    },
    {
        "value": 20546,
        "NOCCode": "9416",
        "JobTitle": "hydraulic press operator – metal fabrication"
    },
    {
        "value": 20547,
        "NOCCode": "9416",
        "JobTitle": "impact hammer operator – metal forging"
    },
    {
        "value": 20548,
        "NOCCode": "9416",
        "JobTitle": "impression die press operator – metal fabrication"
    },
    {
        "value": 20549,
        "NOCCode": "9416",
        "JobTitle": "inspector, leaf springs"
    },
    {
        "value": 20550,
        "NOCCode": "9416",
        "JobTitle": "inspector, metal parts"
    },
    {
        "value": 20551,
        "NOCCode": "9416",
        "JobTitle": "inspector, sheet fabricating"
    },
    {
        "value": 20552,
        "NOCCode": "9416",
        "JobTitle": "laser cutter – metal fabrication"
    },
    {
        "value": 20553,
        "NOCCode": "9416",
        "JobTitle": "lay-out marker – metal products manufacturing"
    },
    {
        "value": 20554,
        "NOCCode": "9416",
        "JobTitle": "lead fabricator"
    },
    {
        "value": 20555,
        "NOCCode": "9416",
        "JobTitle": "lead hand, forging machine operators"
    },
    {
        "value": 20556,
        "NOCCode": "9416",
        "JobTitle": "leaf spring inspector"
    },
    {
        "value": 20557,
        "NOCCode": "9416",
        "JobTitle": "leaf spring maker – metal fabrication"
    },
    {
        "value": 20558,
        "NOCCode": "9416",
        "JobTitle": "machine operator, metalworking"
    },
    {
        "value": 20559,
        "NOCCode": "9416",
        "JobTitle": "machine power saw operator"
    },
    {
        "value": 20560,
        "NOCCode": "9416",
        "JobTitle": "machine setter, metalworking"
    },
    {
        "value": 20561,
        "NOCCode": "9416",
        "JobTitle": "magnetic flame cutting machine operator – metal fabrication"
    },
    {
        "value": 20562,
        "NOCCode": "9416",
        "JobTitle": "manipulator operator – metal forging"
    },
    {
        "value": 20563,
        "NOCCode": "9416",
        "JobTitle": "mechanical press operator – metal products manufacturing"
    },
    {
        "value": 20564,
        "NOCCode": "9416",
        "JobTitle": "metal cut-off saw operator"
    },
    {
        "value": 20565,
        "NOCCode": "9416",
        "JobTitle": "metal fabricating machine operator"
    },
    {
        "value": 20566,
        "NOCCode": "9416",
        "JobTitle": "metal parts inspector"
    },
    {
        "value": 20567,
        "NOCCode": "9416",
        "JobTitle": "metal power saw operator"
    },
    {
        "value": 20568,
        "NOCCode": "9416",
        "JobTitle": "metal sawing machine operator"
    },
    {
        "value": 20569,
        "NOCCode": "9416",
        "JobTitle": "metal spinner"
    },
    {
        "value": 20570,
        "NOCCode": "9416",
        "JobTitle": "metal table saw operator"
    },
    {
        "value": 20571,
        "NOCCode": "9416",
        "JobTitle": "metal-forming machine set-up operator"
    },
    {
        "value": 20572,
        "NOCCode": "9416",
        "JobTitle": "metal-forming machine tender"
    },
    {
        "value": 20573,
        "NOCCode": "9416",
        "JobTitle": "metal-stamping press operator"
    },
    {
        "value": 20574,
        "NOCCode": "9416",
        "JobTitle": "metalworking machine operator"
    },
    {
        "value": 20575,
        "NOCCode": "9416",
        "JobTitle": "metalworking machine setter"
    },
    {
        "value": 20576,
        "NOCCode": "9416",
        "JobTitle": "metalworking machine set-up man/woman"
    },
    {
        "value": 20577,
        "NOCCode": "9416",
        "JobTitle": "metalworking machine set-up operator"
    },
    {
        "value": 20578,
        "NOCCode": "9416",
        "JobTitle": "multi-operation forming machine tender"
    },
    {
        "value": 20579,
        "NOCCode": "9416",
        "JobTitle": "multiple punch press operator – metal fabrication"
    },
    {
        "value": 20580,
        "NOCCode": "9416",
        "JobTitle": "musical instrument tube bender"
    },
    {
        "value": 20581,
        "NOCCode": "9416",
        "JobTitle": "nibbling machine operator – metal fabrication"
    },
    {
        "value": 20582,
        "NOCCode": "9416",
        "JobTitle": "ornamental metal worker – metal fabrication"
    },
    {
        "value": 20583,
        "NOCCode": "9416",
        "JobTitle": "pantograph burner – metal fabrication"
    },
    {
        "value": 20584,
        "NOCCode": "9416",
        "JobTitle": "pantograph flame cutter – metal fabrication"
    },
    {
        "value": 20585,
        "NOCCode": "9416",
        "JobTitle": "pipe-bending machine operator – metal fabrication"
    },
    {
        "value": 20586,
        "NOCCode": "9416",
        "JobTitle": "plate burner – metal fabrication"
    },
    {
        "value": 20587,
        "NOCCode": "9416",
        "JobTitle": "plate shear operator – metal fabrication"
    },
    {
        "value": 20588,
        "NOCCode": "9416",
        "JobTitle": "power brake operator – metal fabrication"
    },
    {
        "value": 20589,
        "NOCCode": "9416",
        "JobTitle": "power hammer operator – metal forging"
    },
    {
        "value": 20590,
        "NOCCode": "9416",
        "JobTitle": "power press operator"
    },
    {
        "value": 20591,
        "NOCCode": "9416",
        "JobTitle": "power press operator – metal fabrication"
    },
    {
        "value": 20592,
        "NOCCode": "9416",
        "JobTitle": "power press operator – metal products manufacturing"
    },
    {
        "value": 20593,
        "NOCCode": "9416",
        "JobTitle": "power press set-up operator – metal fabrication"
    },
    {
        "value": 20594,
        "NOCCode": "9416",
        "JobTitle": "power shear operator – metal fabrication"
    },
    {
        "value": 20595,
        "NOCCode": "9416",
        "JobTitle": "power shear tender – metal fabrication"
    },
    {
        "value": 20596,
        "NOCCode": "9416",
        "JobTitle": "press brake operator"
    },
    {
        "value": 20597,
        "NOCCode": "9416",
        "JobTitle": "press brake operator – metal products manufacturing"
    },
    {
        "value": 20598,
        "NOCCode": "9416",
        "JobTitle": "press tender – pierce and shave"
    },
    {
        "value": 20599,
        "NOCCode": "9416",
        "JobTitle": "punch and shear machine operator – metal fabrication"
    },
    {
        "value": 20600,
        "NOCCode": "9416",
        "JobTitle": "punch press operator"
    },
    {
        "value": 20601,
        "NOCCode": "9416",
        "JobTitle": "punch press operator – metal fabrication"
    },
    {
        "value": 20602,
        "NOCCode": "9416",
        "JobTitle": "punch press setter – metalworking"
    },
    {
        "value": 20603,
        "NOCCode": "9416",
        "JobTitle": "puncher and shearer – metal products manufacturing"
    },
    {
        "value": 20604,
        "NOCCode": "9416",
        "JobTitle": "restrike hammer operator – metal forging"
    },
    {
        "value": 20605,
        "NOCCode": "9416",
        "JobTitle": "ring mill operator – metal forging"
    },
    {
        "value": 20606,
        "NOCCode": "9416",
        "JobTitle": "rod-bending machine operator – metal fabrication"
    },
    {
        "value": 20607,
        "NOCCode": "9416",
        "JobTitle": "roll bender operator – metal products manufacturing"
    },
    {
        "value": 20608,
        "NOCCode": "9416",
        "JobTitle": "roll forger"
    },
    {
        "value": 20609,
        "NOCCode": "9416",
        "JobTitle": "roll operator – metal fabrication"
    },
    {
        "value": 20610,
        "NOCCode": "9416",
        "JobTitle": "roll-forming machine setter – metalworking"
    },
    {
        "value": 20611,
        "NOCCode": "9416",
        "JobTitle": "rolling machine operator – metal forging"
    },
    {
        "value": 20612,
        "NOCCode": "9416",
        "JobTitle": "rotary shear operator – metal fabrication"
    },
    {
        "value": 20613,
        "NOCCode": "9416",
        "JobTitle": "saw operator – metal fabrication"
    },
    {
        "value": 20614,
        "NOCCode": "9416",
        "JobTitle": "shear operator"
    },
    {
        "value": 20615,
        "NOCCode": "9416",
        "JobTitle": "shear operator – metal fabrication"
    },
    {
        "value": 20616,
        "NOCCode": "9416",
        "JobTitle": "shear setter – metal fabrication"
    },
    {
        "value": 20617,
        "NOCCode": "9416",
        "JobTitle": "shearer – metal working"
    },
    {
        "value": 20618,
        "NOCCode": "9416",
        "JobTitle": "sheet metal brake tender"
    },
    {
        "value": 20619,
        "NOCCode": "9416",
        "JobTitle": "sheet metal corrugator"
    },
    {
        "value": 20620,
        "NOCCode": "9416",
        "JobTitle": "sheet metal cutter – metal fabrication"
    },
    {
        "value": 20621,
        "NOCCode": "9416",
        "JobTitle": "sheet metal fabrication inspector"
    },
    {
        "value": 20622,
        "NOCCode": "9416",
        "JobTitle": "sheet metal roll operator – metal fabrication"
    },
    {
        "value": 20623,
        "NOCCode": "9416",
        "JobTitle": "sheet metal spinner – metal fabrication"
    },
    {
        "value": 20624,
        "NOCCode": "9416",
        "JobTitle": "sheet-metal-embossing machine operator"
    },
    {
        "value": 20625,
        "NOCCode": "9416",
        "JobTitle": "sheet-metal-working machine operator – metal products manufacturing"
    },
    {
        "value": 20626,
        "NOCCode": "9416",
        "JobTitle": "sheet-metal-working machine set-up operator"
    },
    {
        "value": 20627,
        "NOCCode": "9416",
        "JobTitle": "sheet-metal-working machine tender – metal fabrication"
    },
    {
        "value": 20628,
        "NOCCode": "9416",
        "JobTitle": "slide forming machine setter – metal fabrication"
    },
    {
        "value": 20629,
        "NOCCode": "9416",
        "JobTitle": "slide forming machine set-up operator"
    },
    {
        "value": 20630,
        "NOCCode": "9416",
        "JobTitle": "slide forming machine tender – metal fabrication"
    },
    {
        "value": 20631,
        "NOCCode": "9416",
        "JobTitle": "spinner, metal"
    },
    {
        "value": 20632,
        "NOCCode": "9416",
        "JobTitle": "steam hammer operator – metal forging"
    },
    {
        "value": 20633,
        "NOCCode": "9416",
        "JobTitle": "steel shot machine operator"
    },
    {
        "value": 20634,
        "NOCCode": "9416",
        "JobTitle": "straight line press setter – metal fabrication"
    },
    {
        "value": 20635,
        "NOCCode": "9416",
        "JobTitle": "straightener hand – metal fabrication"
    },
    {
        "value": 20636,
        "NOCCode": "9416",
        "JobTitle": "straightening press operator – metal fabrication"
    },
    {
        "value": 20637,
        "NOCCode": "9416",
        "JobTitle": "strip-roll-forming machine operator – metal fabrication"
    },
    {
        "value": 20638,
        "NOCCode": "9416",
        "JobTitle": "strip-roll-forming machine tender – metal fabrication"
    },
    {
        "value": 20639,
        "NOCCode": "9416",
        "JobTitle": "threader – metal products manufacturing"
    },
    {
        "value": 20640,
        "NOCCode": "9416",
        "JobTitle": "trip hammer operator – metal forging"
    },
    {
        "value": 20641,
        "NOCCode": "9416",
        "JobTitle": "tube-bending machine operator – metal fabrication"
    },
    {
        "value": 20642,
        "NOCCode": "9416",
        "JobTitle": "tubular metal products fabricator"
    },
    {
        "value": 20643,
        "NOCCode": "9416",
        "JobTitle": "turret press operator"
    },
    {
        "value": 20644,
        "NOCCode": "9416",
        "JobTitle": "turret press set-up operator"
    },
    {
        "value": 20645,
        "NOCCode": "9416",
        "JobTitle": "turret punch press operator – metal fabrication"
    },
    {
        "value": 20646,
        "NOCCode": "9416",
        "JobTitle": "upsetter operator – metal forging"
    },
    {
        "value": 20647,
        "NOCCode": "9416",
        "JobTitle": "upsetting machine operator – metal forging"
    },
    {
        "value": 20648,
        "NOCCode": "9416",
        "JobTitle": "vehicle spring repairer – metal forging"
    },
    {
        "value": 20649,
        "NOCCode": "9416",
        "JobTitle": "vertical press operator"
    },
    {
        "value": 20650,
        "NOCCode": "9416",
        "JobTitle": "vertical press operator – metal fabrication"
    },
    {
        "value": 20651,
        "NOCCode": "9416",
        "JobTitle": "vertical roll operator – metal fabrication"
    },
    {
        "value": 20652,
        "NOCCode": "9417",
        "JobTitle": "aircraft engine parts machine tool set-up operator"
    },
    {
        "value": 20653,
        "NOCCode": "9417",
        "JobTitle": "aircraft parts etcher"
    },
    {
        "value": 20654,
        "NOCCode": "9417",
        "JobTitle": "automated machine tool set-up operator"
    },
    {
        "value": 20655,
        "NOCCode": "9417",
        "JobTitle": "automatic screw machine operator"
    },
    {
        "value": 20656,
        "NOCCode": "9417",
        "JobTitle": "automatic transfer machine operator – metal machining"
    },
    {
        "value": 20657,
        "NOCCode": "9417",
        "JobTitle": "axle lathe operator"
    },
    {
        "value": 20658,
        "NOCCode": "9417",
        "JobTitle": "barrel rifler"
    },
    {
        "value": 20659,
        "NOCCode": "9417",
        "JobTitle": "barrel threader, small arms"
    },
    {
        "value": 20660,
        "NOCCode": "9417",
        "JobTitle": "bearing borer"
    },
    {
        "value": 20661,
        "NOCCode": "9417",
        "JobTitle": "bearing grinder"
    },
    {
        "value": 20662,
        "NOCCode": "9417",
        "JobTitle": "bench set-up operator, machine tool"
    },
    {
        "value": 20663,
        "NOCCode": "9417",
        "JobTitle": "bolt threader"
    },
    {
        "value": 20664,
        "NOCCode": "9417",
        "JobTitle": "bolt-threading machine operator"
    },
    {
        "value": 20665,
        "NOCCode": "9417",
        "JobTitle": "borer – metal machining"
    },
    {
        "value": 20666,
        "NOCCode": "9417",
        "JobTitle": "boring machine operator – metal machining"
    },
    {
        "value": 20667,
        "NOCCode": "9417",
        "JobTitle": "boring mill operator – metal machining"
    },
    {
        "value": 20668,
        "NOCCode": "9417",
        "JobTitle": "boring mill set-up operator – metal machining"
    },
    {
        "value": 20669,
        "NOCCode": "9417",
        "JobTitle": "brake drum lathe operator"
    },
    {
        "value": 20670,
        "NOCCode": "9417",
        "JobTitle": "broacher, production – metal machining"
    },
    {
        "value": 20671,
        "NOCCode": "9417",
        "JobTitle": "broaching machine operator – metal machining"
    },
    {
        "value": 20672,
        "NOCCode": "9417",
        "JobTitle": "broaching machine set-up operator – metal machining"
    },
    {
        "value": 20673,
        "NOCCode": "9417",
        "JobTitle": "Bullard multiple-spindle lathe operator"
    },
    {
        "value": 20674,
        "NOCCode": "9417",
        "JobTitle": "camming machine operator – metal machining"
    },
    {
        "value": 20675,
        "NOCCode": "9417",
        "JobTitle": "centreless cylindrical grinder set-up operator"
    },
    {
        "value": 20676,
        "NOCCode": "9417",
        "JobTitle": "centreless cylindrical-grinder operator"
    },
    {
        "value": 20677,
        "NOCCode": "9417",
        "JobTitle": "centreless grinder operator"
    },
    {
        "value": 20678,
        "NOCCode": "9417",
        "JobTitle": "centreless grinder set-up operator"
    },
    {
        "value": 20679,
        "NOCCode": "9417",
        "JobTitle": "chucking machine set-up operator"
    },
    {
        "value": 20680,
        "NOCCode": "9417",
        "JobTitle": "CNC (computer numerical control) lathe operator – metal machining"
    },
    {
        "value": 20681,
        "NOCCode": "9417",
        "JobTitle": "CNC (computer numerical control) machining tool operator"
    },
    {
        "value": 20682,
        "NOCCode": "9417",
        "JobTitle": "CNC (computer numerical control) profile mill operator"
    },
    {
        "value": 20683,
        "NOCCode": "9417",
        "JobTitle": "CNC (computer numerically controlled) machine set-up operator – metal machining"
    },
    {
        "value": 20684,
        "NOCCode": "9417",
        "JobTitle": "CNC (computer numerically controlled) machining centre operator"
    },
    {
        "value": 20685,
        "NOCCode": "9417",
        "JobTitle": "CNC machining tool operator"
    },
    {
        "value": 20686,
        "NOCCode": "9417",
        "JobTitle": "computer numerically controlled (CNC) machine set-up operator – metal machining"
    },
    {
        "value": 20687,
        "NOCCode": "9417",
        "JobTitle": "computer numerically controlled (CNC) machining centre operator"
    },
    {
        "value": 20688,
        "NOCCode": "9417",
        "JobTitle": "connecting rod borer"
    },
    {
        "value": 20689,
        "NOCCode": "9417",
        "JobTitle": "countersinker – metal machining"
    },
    {
        "value": 20690,
        "NOCCode": "9417",
        "JobTitle": "custom screw machine operator"
    },
    {
        "value": 20691,
        "NOCCode": "9417",
        "JobTitle": "cutlery and tool etcher"
    },
    {
        "value": 20692,
        "NOCCode": "9417",
        "JobTitle": "cylinder grinder – metal machining"
    },
    {
        "value": 20693,
        "NOCCode": "9417",
        "JobTitle": "cylinder honer – metal machining"
    },
    {
        "value": 20694,
        "NOCCode": "9417",
        "JobTitle": "diesinking machine set-up operator"
    },
    {
        "value": 20695,
        "NOCCode": "9417",
        "JobTitle": "drill press operator – metal machining"
    },
    {
        "value": 20696,
        "NOCCode": "9417",
        "JobTitle": "drill press set-up operator – metal machining"
    },
    {
        "value": 20697,
        "NOCCode": "9417",
        "JobTitle": "drilling and milling machine operator – metal machining"
    },
    {
        "value": 20698,
        "NOCCode": "9417",
        "JobTitle": "drilling machine operator – metal machining"
    },
    {
        "value": 20699,
        "NOCCode": "9417",
        "JobTitle": "EDM (electrical discharge machine) operator"
    },
    {
        "value": 20700,
        "NOCCode": "9417",
        "JobTitle": "EDM (electrical discharge machine) set-up operator"
    },
    {
        "value": 20701,
        "NOCCode": "9417",
        "JobTitle": "EDM (electrical discharge machine) tender"
    },
    {
        "value": 20702,
        "NOCCode": "9417",
        "JobTitle": "electrical discharge machine (EDM) operator"
    },
    {
        "value": 20703,
        "NOCCode": "9417",
        "JobTitle": "electrical discharge machine (EDM) tender"
    },
    {
        "value": 20704,
        "NOCCode": "9417",
        "JobTitle": "electrochemical machining tool operator"
    },
    {
        "value": 20705,
        "NOCCode": "9417",
        "JobTitle": "electrolytic etcher – metal machining"
    },
    {
        "value": 20706,
        "NOCCode": "9417",
        "JobTitle": "engine lathe set-up operator"
    },
    {
        "value": 20707,
        "NOCCode": "9417",
        "JobTitle": "etcher, aircraft parts"
    },
    {
        "value": 20708,
        "NOCCode": "9417",
        "JobTitle": "etcher, electrolytic – metal machining"
    },
    {
        "value": 20709,
        "NOCCode": "9417",
        "JobTitle": "etcher, firearms"
    },
    {
        "value": 20710,
        "NOCCode": "9417",
        "JobTitle": "external cylindrical grinder set-up operator"
    },
    {
        "value": 20711,
        "NOCCode": "9417",
        "JobTitle": "external cylindrical-grinder operator"
    },
    {
        "value": 20712,
        "NOCCode": "9417",
        "JobTitle": "file cutter – metal machining"
    },
    {
        "value": 20713,
        "NOCCode": "9417",
        "JobTitle": "file maker – metal machining"
    },
    {
        "value": 20714,
        "NOCCode": "9417",
        "JobTitle": "firearms etcher"
    },
    {
        "value": 20715,
        "NOCCode": "9417",
        "JobTitle": "gear cutter"
    },
    {
        "value": 20716,
        "NOCCode": "9417",
        "JobTitle": "gear grinder"
    },
    {
        "value": 20717,
        "NOCCode": "9417",
        "JobTitle": "gear hobber set-up operator"
    },
    {
        "value": 20718,
        "NOCCode": "9417",
        "JobTitle": "gear roller grinder"
    },
    {
        "value": 20719,
        "NOCCode": "9417",
        "JobTitle": "gear-cutting machine set-up operator"
    },
    {
        "value": 20720,
        "NOCCode": "9417",
        "JobTitle": "gear-cutting machine tender"
    },
    {
        "value": 20721,
        "NOCCode": "9417",
        "JobTitle": "gear-grinding machine set-up operator"
    },
    {
        "value": 20722,
        "NOCCode": "9417",
        "JobTitle": "gear-hobbing machine set-up operator"
    },
    {
        "value": 20723,
        "NOCCode": "9417",
        "JobTitle": "gear-hob-milling machine set-up operator"
    },
    {
        "value": 20724,
        "NOCCode": "9417",
        "JobTitle": "gear-lapping machine operator"
    },
    {
        "value": 20725,
        "NOCCode": "9417",
        "JobTitle": "gear-lapping machine set-up operator"
    },
    {
        "value": 20726,
        "NOCCode": "9417",
        "JobTitle": "gear-milling machine operator"
    },
    {
        "value": 20727,
        "NOCCode": "9417",
        "JobTitle": "gear-milling machine set-up operator"
    },
    {
        "value": 20728,
        "NOCCode": "9417",
        "JobTitle": "gear-rolling machine operator"
    },
    {
        "value": 20729,
        "NOCCode": "9417",
        "JobTitle": "gear-shaper operator"
    },
    {
        "value": 20730,
        "NOCCode": "9417",
        "JobTitle": "gear-shaving machine set-up operator"
    },
    {
        "value": 20731,
        "NOCCode": "9417",
        "JobTitle": "general machine tool set-up operator"
    },
    {
        "value": 20732,
        "NOCCode": "9417",
        "JobTitle": "grinder set-up operator – metal machining"
    },
    {
        "value": 20733,
        "NOCCode": "9417",
        "JobTitle": "grinder, cylinders – metal machining"
    },
    {
        "value": 20734,
        "NOCCode": "9417",
        "JobTitle": "grinder, metal saw teeth"
    },
    {
        "value": 20735,
        "NOCCode": "9417",
        "JobTitle": "grinder, printing rollers"
    },
    {
        "value": 20736,
        "NOCCode": "9417",
        "JobTitle": "grooving machine set-up operator – metal machining"
    },
    {
        "value": 20737,
        "NOCCode": "9417",
        "JobTitle": "hand filer, tools – metal machining"
    },
    {
        "value": 20738,
        "NOCCode": "9417",
        "JobTitle": "hand lapper – metal machining"
    },
    {
        "value": 20739,
        "NOCCode": "9417",
        "JobTitle": "heavy chucking lathe operator – metal machining"
    },
    {
        "value": 20740,
        "NOCCode": "9417",
        "JobTitle": "honing machine operator – metal machining"
    },
    {
        "value": 20741,
        "NOCCode": "9417",
        "JobTitle": "honing machine set-up operator – metal machining"
    },
    {
        "value": 20742,
        "NOCCode": "9417",
        "JobTitle": "horizontal boring and milling machine set-up operator"
    },
    {
        "value": 20743,
        "NOCCode": "9417",
        "JobTitle": "impressing machine tender – metal machining"
    },
    {
        "value": 20744,
        "NOCCode": "9417",
        "JobTitle": "jig grinder set-up operator"
    },
    {
        "value": 20745,
        "NOCCode": "9417",
        "JobTitle": "keyseater operator"
    },
    {
        "value": 20746,
        "NOCCode": "9417",
        "JobTitle": "keyseater set-up operator"
    },
    {
        "value": 20747,
        "NOCCode": "9417",
        "JobTitle": "keyseating machine operator"
    },
    {
        "value": 20748,
        "NOCCode": "9417",
        "JobTitle": "lapper, hand – metal machining"
    },
    {
        "value": 20749,
        "NOCCode": "9417",
        "JobTitle": "lapping machine operator"
    },
    {
        "value": 20750,
        "NOCCode": "9417",
        "JobTitle": "lapping machine set-up operator"
    },
    {
        "value": 20751,
        "NOCCode": "9417",
        "JobTitle": "lapping machine tender"
    },
    {
        "value": 20752,
        "NOCCode": "9417",
        "JobTitle": "lathe hand – metal machining"
    },
    {
        "value": 20753,
        "NOCCode": "9417",
        "JobTitle": "lathe machining operator"
    },
    {
        "value": 20754,
        "NOCCode": "9417",
        "JobTitle": "lathe operator – metal machining"
    },
    {
        "value": 20755,
        "NOCCode": "9417",
        "JobTitle": "lathe operator, CNC (computer numerical control) – metal machining"
    },
    {
        "value": 20756,
        "NOCCode": "9417",
        "JobTitle": "layer-out, machine shop"
    },
    {
        "value": 20757,
        "NOCCode": "9417",
        "JobTitle": "layout marker, machine shop"
    },
    {
        "value": 20758,
        "NOCCode": "9417",
        "JobTitle": "machine operator – metal machining"
    },
    {
        "value": 20759,
        "NOCCode": "9417",
        "JobTitle": "machine router – metal machining"
    },
    {
        "value": 20760,
        "NOCCode": "9417",
        "JobTitle": "machine shop layer-out"
    },
    {
        "value": 20761,
        "NOCCode": "9417",
        "JobTitle": "machine tool operator – metal machining"
    },
    {
        "value": 20762,
        "NOCCode": "9417",
        "JobTitle": "machine tool setter"
    },
    {
        "value": 20763,
        "NOCCode": "9417",
        "JobTitle": "machine tool set-up man/woman"
    },
    {
        "value": 20764,
        "NOCCode": "9417",
        "JobTitle": "machine tool set-up operator"
    },
    {
        "value": 20765,
        "NOCCode": "9417",
        "JobTitle": "machine tool set-up operator, aircraft engine parts"
    },
    {
        "value": 20766,
        "NOCCode": "9417",
        "JobTitle": "machine tool set-up operator, bench"
    },
    {
        "value": 20767,
        "NOCCode": "9417",
        "JobTitle": "machine-tool setter – metal machining"
    },
    {
        "value": 20768,
        "NOCCode": "9417",
        "JobTitle": "machining equipment set-up operator"
    },
    {
        "value": 20769,
        "NOCCode": "9417",
        "JobTitle": "machining tool operator"
    },
    {
        "value": 20770,
        "NOCCode": "9417",
        "JobTitle": "machining tool operator, CNC (computerized numerical control)"
    },
    {
        "value": 20771,
        "NOCCode": "9417",
        "JobTitle": "main bearing borer – metal machining"
    },
    {
        "value": 20772,
        "NOCCode": "9417",
        "JobTitle": "metal band saw operator"
    },
    {
        "value": 20773,
        "NOCCode": "9417",
        "JobTitle": "metal saw tooth grinder"
    },
    {
        "value": 20774,
        "NOCCode": "9417",
        "JobTitle": "milling machine operator – metal machining"
    },
    {
        "value": 20775,
        "NOCCode": "9417",
        "JobTitle": "milling machine setter"
    },
    {
        "value": 20776,
        "NOCCode": "9417",
        "JobTitle": "milling machine set-up operator"
    },
    {
        "value": 20777,
        "NOCCode": "9417",
        "JobTitle": "milling machine set-up operator – metal machining"
    },
    {
        "value": 20778,
        "NOCCode": "9417",
        "JobTitle": "motor vehicle cylinder grinder"
    },
    {
        "value": 20779,
        "NOCCode": "9417",
        "JobTitle": "multi-function metal-cutting tool operator"
    },
    {
        "value": 20780,
        "NOCCode": "9417",
        "JobTitle": "multi-function metal-cutting tool operator trainee"
    },
    {
        "value": 20781,
        "NOCCode": "9417",
        "JobTitle": "multiple-spindle drill press operator – metal machining"
    },
    {
        "value": 20782,
        "NOCCode": "9417",
        "JobTitle": "multiple-spindle drill press set-up operator – metal machining"
    },
    {
        "value": 20783,
        "NOCCode": "9417",
        "JobTitle": "multiple-spindle screw machine operator"
    },
    {
        "value": 20784,
        "NOCCode": "9417",
        "JobTitle": "multiple-spindle vertical boring mill set-up operator"
    },
    {
        "value": 20785,
        "NOCCode": "9417",
        "JobTitle": "multiple-spindle vertical turret lathe set-up operator"
    },
    {
        "value": 20786,
        "NOCCode": "9417",
        "JobTitle": "name plate stamper"
    },
    {
        "value": 20787,
        "NOCCode": "9417",
        "JobTitle": "NC (numerical control) drilling machine operator – metal machining"
    },
    {
        "value": 20788,
        "NOCCode": "9417",
        "JobTitle": "NC (numerically controlled) machine operator – metal machining"
    },
    {
        "value": 20789,
        "NOCCode": "9417",
        "JobTitle": "NC (numerically controlled) machine tool operator"
    },
    {
        "value": 20790,
        "NOCCode": "9417",
        "JobTitle": "NC (numerically controlled) machine tool set-up operator"
    },
    {
        "value": 20791,
        "NOCCode": "9417",
        "JobTitle": "NC (numerically controlled) machining tool operator"
    },
    {
        "value": 20792,
        "NOCCode": "9417",
        "JobTitle": "NC (numerically controlled) tracer operator – metal machining"
    },
    {
        "value": 20793,
        "NOCCode": "9417",
        "JobTitle": "NC/CNC (numerical control/computer numerical control) machining tool operator"
    },
    {
        "value": 20794,
        "NOCCode": "9417",
        "JobTitle": "numerical control (NC) machining tool operator"
    },
    {
        "value": 20795,
        "NOCCode": "9417",
        "JobTitle": "numerically controlled (NC) machine operator – metal machining"
    },
    {
        "value": 20796,
        "NOCCode": "9417",
        "JobTitle": "numerically controlled (NC) machine tool set-up operator"
    },
    {
        "value": 20797,
        "NOCCode": "9417",
        "JobTitle": "operator trainee, multi-function metal-cutting tool"
    },
    {
        "value": 20798,
        "NOCCode": "9417",
        "JobTitle": "operator, boring machine – metal machining"
    },
    {
        "value": 20799,
        "NOCCode": "9417",
        "JobTitle": "operator, boring mill – metal machining"
    },
    {
        "value": 20800,
        "NOCCode": "9417",
        "JobTitle": "operator, drill press – metal machining"
    },
    {
        "value": 20801,
        "NOCCode": "9417",
        "JobTitle": "operator, EDM (electrical discharge machine)"
    },
    {
        "value": 20802,
        "NOCCode": "9417",
        "JobTitle": "operator, electrical discharge machine (EDM)"
    },
    {
        "value": 20803,
        "NOCCode": "9417",
        "JobTitle": "operator, lathe – metal machining"
    },
    {
        "value": 20804,
        "NOCCode": "9417",
        "JobTitle": "operator, lathe machining"
    },
    {
        "value": 20805,
        "NOCCode": "9417",
        "JobTitle": "operator, machine tool – metal machining"
    },
    {
        "value": 20806,
        "NOCCode": "9417",
        "JobTitle": "pantograph milling machine set-up operator"
    },
    {
        "value": 20807,
        "NOCCode": "9417",
        "JobTitle": "pedestal drill operator – metal machining"
    },
    {
        "value": 20808,
        "NOCCode": "9417",
        "JobTitle": "pipe threader"
    },
    {
        "value": 20809,
        "NOCCode": "9417",
        "JobTitle": "pipe-threading machine operator"
    },
    {
        "value": 20810,
        "NOCCode": "9417",
        "JobTitle": "planer operator – metal machining"
    },
    {
        "value": 20811,
        "NOCCode": "9417",
        "JobTitle": "planer set-up operator – metal machining"
    },
    {
        "value": 20812,
        "NOCCode": "9417",
        "JobTitle": "precision grinder operator – metal machining"
    },
    {
        "value": 20813,
        "NOCCode": "9417",
        "JobTitle": "precision grinder set-up operator"
    },
    {
        "value": 20814,
        "NOCCode": "9417",
        "JobTitle": "precision grinder set-up operator – metal machining"
    },
    {
        "value": 20815,
        "NOCCode": "9417",
        "JobTitle": "precision honing machine operator – metal machining"
    },
    {
        "value": 20816,
        "NOCCode": "9417",
        "JobTitle": "precision lapper – metal machining"
    },
    {
        "value": 20817,
        "NOCCode": "9417",
        "JobTitle": "precision lathe operator – metal machining"
    },
    {
        "value": 20818,
        "NOCCode": "9417",
        "JobTitle": "printing-roller finisher"
    },
    {
        "value": 20819,
        "NOCCode": "9417",
        "JobTitle": "printing-roller grinder"
    },
    {
        "value": 20820,
        "NOCCode": "9417",
        "JobTitle": "printing-roller polisher"
    },
    {
        "value": 20821,
        "NOCCode": "9417",
        "JobTitle": "production borer – metal machining"
    },
    {
        "value": 20822,
        "NOCCode": "9417",
        "JobTitle": "production boring machine operator – metal machining"
    },
    {
        "value": 20823,
        "NOCCode": "9417",
        "JobTitle": "production broaching machine operator – metal machining"
    },
    {
        "value": 20824,
        "NOCCode": "9417",
        "JobTitle": "production broaching machine tender – metal machining"
    },
    {
        "value": 20825,
        "NOCCode": "9417",
        "JobTitle": "production gear cutter"
    },
    {
        "value": 20826,
        "NOCCode": "9417",
        "JobTitle": "production grinder – metal machining"
    },
    {
        "value": 20827,
        "NOCCode": "9417",
        "JobTitle": "production grinder operator – metal machining"
    },
    {
        "value": 20828,
        "NOCCode": "9417",
        "JobTitle": "production lapping machine operator"
    },
    {
        "value": 20829,
        "NOCCode": "9417",
        "JobTitle": "production lapping machine tender"
    },
    {
        "value": 20830,
        "NOCCode": "9417",
        "JobTitle": "production lathe operator – metal machining"
    },
    {
        "value": 20831,
        "NOCCode": "9417",
        "JobTitle": "production machine tool operator"
    },
    {
        "value": 20832,
        "NOCCode": "9417",
        "JobTitle": "production metal grinder operator"
    },
    {
        "value": 20833,
        "NOCCode": "9417",
        "JobTitle": "production milling machine operator – metal machining"
    },
    {
        "value": 20834,
        "NOCCode": "9417",
        "JobTitle": "production multiple-spindle screw machine set-up operator"
    },
    {
        "value": 20835,
        "NOCCode": "9417",
        "JobTitle": "profile mill operator, CNC (computer numerical control)"
    },
    {
        "value": 20836,
        "NOCCode": "9417",
        "JobTitle": "profile mill operator, CNC (computerized numerical control)"
    },
    {
        "value": 20837,
        "NOCCode": "9417",
        "JobTitle": "profile milling machine operator – metal machining"
    },
    {
        "value": 20838,
        "NOCCode": "9417",
        "JobTitle": "profile milling machine set-up operator"
    },
    {
        "value": 20839,
        "NOCCode": "9417",
        "JobTitle": "profiling machine operator – metal machining"
    },
    {
        "value": 20840,
        "NOCCode": "9417",
        "JobTitle": "profiling machine set-up operator"
    },
    {
        "value": 20841,
        "NOCCode": "9417",
        "JobTitle": "radial drill operator – metal machining"
    },
    {
        "value": 20842,
        "NOCCode": "9417",
        "JobTitle": "radial drill press operator – metal machining"
    },
    {
        "value": 20843,
        "NOCCode": "9417",
        "JobTitle": "radial drill press set-up operator – metal machining"
    },
    {
        "value": 20844,
        "NOCCode": "9417",
        "JobTitle": "radial drill set-up operator – metal machining"
    },
    {
        "value": 20845,
        "NOCCode": "9417",
        "JobTitle": "rotary-head milling machine set-up operator"
    },
    {
        "value": 20846,
        "NOCCode": "9417",
        "JobTitle": "router operator – metal machining"
    },
    {
        "value": 20847,
        "NOCCode": "9417",
        "JobTitle": "screw machine operator – metal machining"
    },
    {
        "value": 20848,
        "NOCCode": "9417",
        "JobTitle": "screw machine operator, custom"
    },
    {
        "value": 20849,
        "NOCCode": "9417",
        "JobTitle": "screw machine set-up operator"
    },
    {
        "value": 20850,
        "NOCCode": "9417",
        "JobTitle": "screw threader"
    },
    {
        "value": 20851,
        "NOCCode": "9417",
        "JobTitle": "setter, machine tools"
    },
    {
        "value": 20852,
        "NOCCode": "9417",
        "JobTitle": "setter, milling machines"
    },
    {
        "value": 20853,
        "NOCCode": "9417",
        "JobTitle": "set-up man/woman, machine tools"
    },
    {
        "value": 20854,
        "NOCCode": "9417",
        "JobTitle": "set-up operator – metal machining"
    },
    {
        "value": 20855,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, aircraft engine parts machine tool"
    },
    {
        "value": 20856,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, automated machine tool"
    },
    {
        "value": 20857,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, boring mill – metal machining"
    },
    {
        "value": 20858,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, diesinking machine"
    },
    {
        "value": 20859,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, drill press – metal machining"
    },
    {
        "value": 20860,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, EDM (electrical discharge machine)"
    },
    {
        "value": 20861,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, engine lathe"
    },
    {
        "value": 20862,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, gear-shaving machine"
    },
    {
        "value": 20863,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, grinder – metal machining"
    },
    {
        "value": 20864,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, horizontal boring mill"
    },
    {
        "value": 20865,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, jig grinder"
    },
    {
        "value": 20866,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, keyseater"
    },
    {
        "value": 20867,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, machine tool"
    },
    {
        "value": 20868,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, machining equipment"
    },
    {
        "value": 20869,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, numerically controlled (NC) machine tool"
    },
    {
        "value": 20870,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, precision grinder"
    },
    {
        "value": 20871,
        "NOCCode": "9417",
        "JobTitle": "set-up operator, rotary-head milling machine"
    },
    {
        "value": 20872,
        "NOCCode": "9417",
        "JobTitle": "shaper set-up operator"
    },
    {
        "value": 20873,
        "NOCCode": "9417",
        "JobTitle": "single-spindle drill press set-up operator – metal machining"
    },
    {
        "value": 20874,
        "NOCCode": "9417",
        "JobTitle": "spiral bevel-gear cutting machine set-up operator"
    },
    {
        "value": 20875,
        "NOCCode": "9417",
        "JobTitle": "spiral bevel-gear generator set-up operator"
    },
    {
        "value": 20876,
        "NOCCode": "9417",
        "JobTitle": "spiral gear-generator operator"
    },
    {
        "value": 20877,
        "NOCCode": "9417",
        "JobTitle": "straight bevel-gear cutting machine set-up operator"
    },
    {
        "value": 20878,
        "NOCCode": "9417",
        "JobTitle": "straight bevel-gear generator set-up operator"
    },
    {
        "value": 20879,
        "NOCCode": "9417",
        "JobTitle": "surface grinder set-up operator"
    },
    {
        "value": 20880,
        "NOCCode": "9417",
        "JobTitle": "surface-grinder operator, production"
    },
    {
        "value": 20881,
        "NOCCode": "9417",
        "JobTitle": "thread cutter – metal machining"
    },
    {
        "value": 20882,
        "NOCCode": "9417",
        "JobTitle": "thread grinder set-up operator"
    },
    {
        "value": 20883,
        "NOCCode": "9417",
        "JobTitle": "threader – metal machining"
    },
    {
        "value": 20884,
        "NOCCode": "9417",
        "JobTitle": "thread-grinder operator"
    },
    {
        "value": 20885,
        "NOCCode": "9417",
        "JobTitle": "threading machine operator"
    },
    {
        "value": 20886,
        "NOCCode": "9417",
        "JobTitle": "thread-milling machine set-up operator"
    },
    {
        "value": 20887,
        "NOCCode": "9417",
        "JobTitle": "tool filer, hand – metal machining"
    },
    {
        "value": 20888,
        "NOCCode": "9417",
        "JobTitle": "tool grinder – metal machining"
    },
    {
        "value": 20889,
        "NOCCode": "9417",
        "JobTitle": "tool grinder set-up operator"
    },
    {
        "value": 20890,
        "NOCCode": "9417",
        "JobTitle": "tool lapper – metal machining"
    },
    {
        "value": 20891,
        "NOCCode": "9417",
        "JobTitle": "tool-dresser operator"
    },
    {
        "value": 20892,
        "NOCCode": "9417",
        "JobTitle": "transfer machine tender – metal machining"
    },
    {
        "value": 20893,
        "NOCCode": "9417",
        "JobTitle": "turret lathe operator – metal machining"
    },
    {
        "value": 20894,
        "NOCCode": "9417",
        "JobTitle": "turret lathe set-up operator"
    },
    {
        "value": 20895,
        "NOCCode": "9417",
        "JobTitle": "universal grinder set-up operator"
    },
    {
        "value": 20896,
        "NOCCode": "9417",
        "JobTitle": "vertical boring machine operator"
    },
    {
        "value": 20897,
        "NOCCode": "9417",
        "JobTitle": "vertical boring mill operator"
    },
    {
        "value": 20898,
        "NOCCode": "9417",
        "JobTitle": "vertical boring mill set-up operator – metal machining"
    },
    {
        "value": 20899,
        "NOCCode": "9417",
        "JobTitle": "vertical machining centre operator"
    },
    {
        "value": 20900,
        "NOCCode": "9417",
        "JobTitle": "vertical milling machine set-up operator – metal machining"
    },
    {
        "value": 20901,
        "NOCCode": "9417",
        "JobTitle": "vertical-spindle milling machine set-up operator – metal machining"
    },
    {
        "value": 20902,
        "NOCCode": "9418",
        "JobTitle": "asbestos wire finisher"
    },
    {
        "value": 20903,
        "NOCCode": "9418",
        "JobTitle": "assembler and tester, wire cables"
    },
    {
        "value": 20904,
        "NOCCode": "9418",
        "JobTitle": "assembler, wire mesh gates"
    },
    {
        "value": 20905,
        "NOCCode": "9418",
        "JobTitle": "automatic bolt machine operator"
    },
    {
        "value": 20906,
        "NOCCode": "9418",
        "JobTitle": "automatic coil machine operator"
    },
    {
        "value": 20907,
        "NOCCode": "9418",
        "JobTitle": "automatic coiling machine operator – metal products manufacturing"
    },
    {
        "value": 20908,
        "NOCCode": "9418",
        "JobTitle": "automatic machine polisher, metal"
    },
    {
        "value": 20909,
        "NOCCode": "9418",
        "JobTitle": "axle cutter"
    },
    {
        "value": 20910,
        "NOCCode": "9418",
        "JobTitle": "barbed wire machine tender"
    },
    {
        "value": 20911,
        "NOCCode": "9418",
        "JobTitle": "basket maker, wire"
    },
    {
        "value": 20912,
        "NOCCode": "9418",
        "JobTitle": "beading machine operator – metal products manufacturing"
    },
    {
        "value": 20913,
        "NOCCode": "9418",
        "JobTitle": "bench loom wire weaver"
    },
    {
        "value": 20914,
        "NOCCode": "9418",
        "JobTitle": "bolt header operator"
    },
    {
        "value": 20915,
        "NOCCode": "9418",
        "JobTitle": "bolt machine operator"
    },
    {
        "value": 20916,
        "NOCCode": "9418",
        "JobTitle": "bolt maker"
    },
    {
        "value": 20917,
        "NOCCode": "9418",
        "JobTitle": "braider tender"
    },
    {
        "value": 20918,
        "NOCCode": "9418",
        "JobTitle": "braiding machine tender – wire products manufacturing"
    },
    {
        "value": 20919,
        "NOCCode": "9418",
        "JobTitle": "buckshot swage operator"
    },
    {
        "value": 20920,
        "NOCCode": "9418",
        "JobTitle": "bullet cutter and former"
    },
    {
        "value": 20921,
        "NOCCode": "9418",
        "JobTitle": "bullet maker"
    },
    {
        "value": 20922,
        "NOCCode": "9418",
        "JobTitle": "bullet shaper, machine – metal products manufacturing"
    },
    {
        "value": 20923,
        "NOCCode": "9418",
        "JobTitle": "bullet-shaping machine tender"
    },
    {
        "value": 20924,
        "NOCCode": "9418",
        "JobTitle": "bullet-swaging machine adjuster"
    },
    {
        "value": 20925,
        "NOCCode": "9418",
        "JobTitle": "bullet-swaging machine setter – metal products manufacturing"
    },
    {
        "value": 20926,
        "NOCCode": "9418",
        "JobTitle": "bunching machine operator"
    },
    {
        "value": 20927,
        "NOCCode": "9418",
        "JobTitle": "cable armourer"
    },
    {
        "value": 20928,
        "NOCCode": "9418",
        "JobTitle": "cable insulator"
    },
    {
        "value": 20929,
        "NOCCode": "9418",
        "JobTitle": "cable maker, metal"
    },
    {
        "value": 20930,
        "NOCCode": "9418",
        "JobTitle": "cable-braiding machine operator"
    },
    {
        "value": 20931,
        "NOCCode": "9418",
        "JobTitle": "cage maker – wire products manufacturing"
    },
    {
        "value": 20932,
        "NOCCode": "9418",
        "JobTitle": "can inspector – metal products manufacturing"
    },
    {
        "value": 20933,
        "NOCCode": "9418",
        "JobTitle": "can machine operator"
    },
    {
        "value": 20934,
        "NOCCode": "9418",
        "JobTitle": "can machine setter"
    },
    {
        "value": 20935,
        "NOCCode": "9418",
        "JobTitle": "can-forming machine operator"
    },
    {
        "value": 20936,
        "NOCCode": "9418",
        "JobTitle": "can-making machine operator"
    },
    {
        "value": 20937,
        "NOCCode": "9418",
        "JobTitle": "can-making machine setter"
    },
    {
        "value": 20938,
        "NOCCode": "9418",
        "JobTitle": "carbide press tender"
    },
    {
        "value": 20939,
        "NOCCode": "9418",
        "JobTitle": "cartridge primers drop tester"
    },
    {
        "value": 20940,
        "NOCCode": "9418",
        "JobTitle": "chain inspector"
    },
    {
        "value": 20941,
        "NOCCode": "9418",
        "JobTitle": "chain maker, machine"
    },
    {
        "value": 20942,
        "NOCCode": "9418",
        "JobTitle": "chain tester"
    },
    {
        "value": 20943,
        "NOCCode": "9418",
        "JobTitle": "chain tester, machine"
    },
    {
        "value": 20944,
        "NOCCode": "9418",
        "JobTitle": "chain-link fence machine operator"
    },
    {
        "value": 20945,
        "NOCCode": "9418",
        "JobTitle": "chain-making machine feeder"
    },
    {
        "value": 20946,
        "NOCCode": "9418",
        "JobTitle": "chain-making machine operator"
    },
    {
        "value": 20947,
        "NOCCode": "9418",
        "JobTitle": "chain-making machine tender"
    },
    {
        "value": 20948,
        "NOCCode": "9418",
        "JobTitle": "chain-testing machine tender"
    },
    {
        "value": 20949,
        "NOCCode": "9418",
        "JobTitle": "chamfering and boring machine operator – metal products manufacturing"
    },
    {
        "value": 20950,
        "NOCCode": "9418",
        "JobTitle": "chamfering machine tender"
    },
    {
        "value": 20951,
        "NOCCode": "9418",
        "JobTitle": "chandelier maker"
    },
    {
        "value": 20952,
        "NOCCode": "9418",
        "JobTitle": "coil machine operator"
    },
    {
        "value": 20953,
        "NOCCode": "9418",
        "JobTitle": "coil spring machine tender"
    },
    {
        "value": 20954,
        "NOCCode": "9418",
        "JobTitle": "coiler – metal products manufacturing"
    },
    {
        "value": 20955,
        "NOCCode": "9418",
        "JobTitle": "coiler, hot-wound springs"
    },
    {
        "value": 20956,
        "NOCCode": "9418",
        "JobTitle": "coiler, springs"
    },
    {
        "value": 20957,
        "NOCCode": "9418",
        "JobTitle": "coiler, valve springs"
    },
    {
        "value": 20958,
        "NOCCode": "9418",
        "JobTitle": "coiling machine operator – metal products manufacturing"
    },
    {
        "value": 20959,
        "NOCCode": "9418",
        "JobTitle": "coil-rewind machine tender"
    },
    {
        "value": 20960,
        "NOCCode": "9418",
        "JobTitle": "crimping machine feeder – wire products manufacturing"
    },
    {
        "value": 20961,
        "NOCCode": "9418",
        "JobTitle": "crimping machine operator, wires"
    },
    {
        "value": 20962,
        "NOCCode": "9418",
        "JobTitle": "cutter and former, bullets – metal products manufacturing"
    },
    {
        "value": 20963,
        "NOCCode": "9418",
        "JobTitle": "cutter, nut disks"
    },
    {
        "value": 20964,
        "NOCCode": "9418",
        "JobTitle": "cylinder roll fabricator"
    },
    {
        "value": 20965,
        "NOCCode": "9418",
        "JobTitle": "drop tester, cartridge primers"
    },
    {
        "value": 20966,
        "NOCCode": "9418",
        "JobTitle": "embossing machine tender – explosives manufacturing"
    },
    {
        "value": 20967,
        "NOCCode": "9418",
        "JobTitle": "extruder operator, welding rods"
    },
    {
        "value": 20968,
        "NOCCode": "9418",
        "JobTitle": "extruder tender, welding rods"
    },
    {
        "value": 20969,
        "NOCCode": "9418",
        "JobTitle": "feeder, knurling machine"
    },
    {
        "value": 20970,
        "NOCCode": "9418",
        "JobTitle": "fence-making machine operator, wire mesh"
    },
    {
        "value": 20971,
        "NOCCode": "9418",
        "JobTitle": "finisher, asbestos-covered wires"
    },
    {
        "value": 20972,
        "NOCCode": "9418",
        "JobTitle": "fixture maker, plating equipment"
    },
    {
        "value": 20973,
        "NOCCode": "9418",
        "JobTitle": "foil rewinder"
    },
    {
        "value": 20974,
        "NOCCode": "9418",
        "JobTitle": "former, metal nuts"
    },
    {
        "value": 20975,
        "NOCCode": "9418",
        "JobTitle": "former, wire screens"
    },
    {
        "value": 20976,
        "NOCCode": "9418",
        "JobTitle": "frame machine operator, tents"
    },
    {
        "value": 20977,
        "NOCCode": "9418",
        "JobTitle": "grid machine job setter"
    },
    {
        "value": 20978,
        "NOCCode": "9418",
        "JobTitle": "header machine operator – metal"
    },
    {
        "value": 20979,
        "NOCCode": "9418",
        "JobTitle": "hoop-making machine operator"
    },
    {
        "value": 20980,
        "NOCCode": "9418",
        "JobTitle": "hoop-punching and hoop-coiling machine operator – metal products manufacturing"
    },
    {
        "value": 20981,
        "NOCCode": "9418",
        "JobTitle": "hoop-riveting machine operator"
    },
    {
        "value": 20982,
        "NOCCode": "9418",
        "JobTitle": "hot-wound spring coiler"
    },
    {
        "value": 20983,
        "NOCCode": "9418",
        "JobTitle": "industrial knitting needle machine feeder"
    },
    {
        "value": 20984,
        "NOCCode": "9418",
        "JobTitle": "industrial needle machine setter-operator"
    },
    {
        "value": 20985,
        "NOCCode": "9418",
        "JobTitle": "industrial needle machine set-up operator"
    },
    {
        "value": 20986,
        "NOCCode": "9418",
        "JobTitle": "industrial needle maker"
    },
    {
        "value": 20987,
        "NOCCode": "9418",
        "JobTitle": "inspector, cans – metal products manufacturing"
    },
    {
        "value": 20988,
        "NOCCode": "9418",
        "JobTitle": "inspector, chains"
    },
    {
        "value": 20989,
        "NOCCode": "9418",
        "JobTitle": "inspector, springs"
    },
    {
        "value": 20990,
        "NOCCode": "9418",
        "JobTitle": "inspector, tin can"
    },
    {
        "value": 20991,
        "NOCCode": "9418",
        "JobTitle": "insulation machine operator"
    },
    {
        "value": 20992,
        "NOCCode": "9418",
        "JobTitle": "insulator, cables"
    },
    {
        "value": 20993,
        "NOCCode": "9418",
        "JobTitle": "knife blade polisher"
    },
    {
        "value": 20994,
        "NOCCode": "9418",
        "JobTitle": "knitter, wire mesh"
    },
    {
        "value": 20995,
        "NOCCode": "9418",
        "JobTitle": "knurling machine feeder"
    },
    {
        "value": 20996,
        "NOCCode": "9418",
        "JobTitle": "lampshade frame maker, wire"
    },
    {
        "value": 20997,
        "NOCCode": "9418",
        "JobTitle": "lattice weaver, wire"
    },
    {
        "value": 20998,
        "NOCCode": "9418",
        "JobTitle": "link wire machine set-up operator"
    },
    {
        "value": 20999,
        "NOCCode": "9418",
        "JobTitle": "link wire machine tender"
    },
    {
        "value": 21000,
        "NOCCode": "9418",
        "JobTitle": "loom setter, wire weaving"
    },
    {
        "value": 21001,
        "NOCCode": "9418",
        "JobTitle": "loom setter, wires"
    },
    {
        "value": 21002,
        "NOCCode": "9418",
        "JobTitle": "machine assembler, springs"
    },
    {
        "value": 21003,
        "NOCCode": "9418",
        "JobTitle": "machine chain maker"
    },
    {
        "value": 21004,
        "NOCCode": "9418",
        "JobTitle": "machine cutter, metal pipes"
    },
    {
        "value": 21005,
        "NOCCode": "9418",
        "JobTitle": "machine tender, nuts and bolts"
    },
    {
        "value": 21006,
        "NOCCode": "9418",
        "JobTitle": "metal awning machine operator"
    },
    {
        "value": 21007,
        "NOCCode": "9418",
        "JobTitle": "metal blade sharpener operator"
    },
    {
        "value": 21008,
        "NOCCode": "9418",
        "JobTitle": "metal cable machine operator"
    },
    {
        "value": 21009,
        "NOCCode": "9418",
        "JobTitle": "metal cable maker"
    },
    {
        "value": 21010,
        "NOCCode": "9418",
        "JobTitle": "metal cable maker operator"
    },
    {
        "value": 21011,
        "NOCCode": "9418",
        "JobTitle": "metal cable stretcher and tester"
    },
    {
        "value": 21012,
        "NOCCode": "9418",
        "JobTitle": "metal floor lamp maker"
    },
    {
        "value": 21013,
        "NOCCode": "9418",
        "JobTitle": "metal nut former"
    },
    {
        "value": 21014,
        "NOCCode": "9418",
        "JobTitle": "metal nut maker"
    },
    {
        "value": 21015,
        "NOCCode": "9418",
        "JobTitle": "metal pin maker"
    },
    {
        "value": 21016,
        "NOCCode": "9418",
        "JobTitle": "metal pipe cutter machine operator"
    },
    {
        "value": 21017,
        "NOCCode": "9418",
        "JobTitle": "metal pipe cutter operator"
    },
    {
        "value": 21018,
        "NOCCode": "9418",
        "JobTitle": "metal pipe cutter, machine"
    },
    {
        "value": 21019,
        "NOCCode": "9418",
        "JobTitle": "metal polisher, automatic machine"
    },
    {
        "value": 21020,
        "NOCCode": "9418",
        "JobTitle": "metal products manufacturing machine operator"
    },
    {
        "value": 21021,
        "NOCCode": "9418",
        "JobTitle": "metal screened door and window maker"
    },
    {
        "value": 21022,
        "NOCCode": "9418",
        "JobTitle": "metal sponge machine tender"
    },
    {
        "value": 21023,
        "NOCCode": "9418",
        "JobTitle": "metal sponge maker"
    },
    {
        "value": 21024,
        "NOCCode": "9418",
        "JobTitle": "metal tack maker"
    },
    {
        "value": 21025,
        "NOCCode": "9418",
        "JobTitle": "metal-bonding equipment operator"
    },
    {
        "value": 21026,
        "NOCCode": "9418",
        "JobTitle": "metal-twisting machine tender"
    },
    {
        "value": 21027,
        "NOCCode": "9418",
        "JobTitle": "metalworking weaver – metal products manufacturing"
    },
    {
        "value": 21028,
        "NOCCode": "9418",
        "JobTitle": "miller, saw blades"
    },
    {
        "value": 21029,
        "NOCCode": "9418",
        "JobTitle": "nail maker"
    },
    {
        "value": 21030,
        "NOCCode": "9418",
        "JobTitle": "nail making machine set-up operator"
    },
    {
        "value": 21031,
        "NOCCode": "9418",
        "JobTitle": "nail making machine tender"
    },
    {
        "value": 21032,
        "NOCCode": "9418",
        "JobTitle": "NC (numerical control) riveting machine operator – metal products manufacturing"
    },
    {
        "value": 21033,
        "NOCCode": "9418",
        "JobTitle": "NC (numerically controlled) riveting machine operator – metal products manufacturing"
    },
    {
        "value": 21034,
        "NOCCode": "9418",
        "JobTitle": "nut and bolt machine tender"
    },
    {
        "value": 21035,
        "NOCCode": "9418",
        "JobTitle": "nut disk cutter"
    },
    {
        "value": 21036,
        "NOCCode": "9418",
        "JobTitle": "nut former, metal"
    },
    {
        "value": 21037,
        "NOCCode": "9418",
        "JobTitle": "nut maker, metal"
    },
    {
        "value": 21038,
        "NOCCode": "9418",
        "JobTitle": "operator, beading machine – metal products manufacturing"
    },
    {
        "value": 21039,
        "NOCCode": "9418",
        "JobTitle": "operator, bolt machine"
    },
    {
        "value": 21040,
        "NOCCode": "9418",
        "JobTitle": "operator, cable-braiding machine"
    },
    {
        "value": 21041,
        "NOCCode": "9418",
        "JobTitle": "operator, can machine"
    },
    {
        "value": 21042,
        "NOCCode": "9418",
        "JobTitle": "operator, can-making machine"
    },
    {
        "value": 21043,
        "NOCCode": "9418",
        "JobTitle": "operator, chain-link fence machine"
    },
    {
        "value": 21044,
        "NOCCode": "9418",
        "JobTitle": "operator, hoop-punching and hoop-coiling machine – metal products manufacturing"
    },
    {
        "value": 21045,
        "NOCCode": "9418",
        "JobTitle": "operator, insulation machine – metal products manufacturing"
    },
    {
        "value": 21046,
        "NOCCode": "9418",
        "JobTitle": "operator, metal pipe cutter"
    },
    {
        "value": 21047,
        "NOCCode": "9418",
        "JobTitle": "operator, metal pipe cutting machine"
    },
    {
        "value": 21048,
        "NOCCode": "9418",
        "JobTitle": "operator, metal products manufacturing machine"
    },
    {
        "value": 21049,
        "NOCCode": "9418",
        "JobTitle": "operator, polishing machine – metal products manufacturing"
    },
    {
        "value": 21050,
        "NOCCode": "9418",
        "JobTitle": "operator, spiral weaving machine – metal products manufacturing"
    },
    {
        "value": 21051,
        "NOCCode": "9418",
        "JobTitle": "operator, spring assembly machine"
    },
    {
        "value": 21052,
        "NOCCode": "9418",
        "JobTitle": "operator, spring machine"
    },
    {
        "value": 21053,
        "NOCCode": "9418",
        "JobTitle": "operator, spring-coiling machine"
    },
    {
        "value": 21054,
        "NOCCode": "9418",
        "JobTitle": "operator, spring-making machine"
    },
    {
        "value": 21055,
        "NOCCode": "9418",
        "JobTitle": "operator, spring-winding machine"
    },
    {
        "value": 21056,
        "NOCCode": "9418",
        "JobTitle": "operator, steel wool machine"
    },
    {
        "value": 21057,
        "NOCCode": "9418",
        "JobTitle": "operator, tent frame machine"
    },
    {
        "value": 21058,
        "NOCCode": "9418",
        "JobTitle": "operator, welding rod extruder"
    },
    {
        "value": 21059,
        "NOCCode": "9418",
        "JobTitle": "operator, wire bundle machine"
    },
    {
        "value": 21060,
        "NOCCode": "9418",
        "JobTitle": "operator, wire coiner"
    },
    {
        "value": 21061,
        "NOCCode": "9418",
        "JobTitle": "operator, wire loom"
    },
    {
        "value": 21062,
        "NOCCode": "9418",
        "JobTitle": "operator, wire loop machine"
    },
    {
        "value": 21063,
        "NOCCode": "9418",
        "JobTitle": "operator, wire-crimping machine"
    },
    {
        "value": 21064,
        "NOCCode": "9418",
        "JobTitle": "operator, wire-netting machine"
    },
    {
        "value": 21065,
        "NOCCode": "9418",
        "JobTitle": "pin maker – metal products"
    },
    {
        "value": 21066,
        "NOCCode": "9418",
        "JobTitle": "pin maker, metal"
    },
    {
        "value": 21067,
        "NOCCode": "9418",
        "JobTitle": "pocketed spring machine tender"
    },
    {
        "value": 21068,
        "NOCCode": "9418",
        "JobTitle": "polishing machine operator – metal products"
    },
    {
        "value": 21069,
        "NOCCode": "9418",
        "JobTitle": "reinforcing cage maker"
    },
    {
        "value": 21070,
        "NOCCode": "9418",
        "JobTitle": "reinforcing cage winding machine operator"
    },
    {
        "value": 21071,
        "NOCCode": "9418",
        "JobTitle": "rimfire cartridge-priming machine tender"
    },
    {
        "value": 21072,
        "NOCCode": "9418",
        "JobTitle": "rivet maker"
    },
    {
        "value": 21073,
        "NOCCode": "9418",
        "JobTitle": "rivet shaper"
    },
    {
        "value": 21074,
        "NOCCode": "9418",
        "JobTitle": "roll threader operator"
    },
    {
        "value": 21075,
        "NOCCode": "9418",
        "JobTitle": "rope maker, wire"
    },
    {
        "value": 21076,
        "NOCCode": "9418",
        "JobTitle": "safety pin machine set-up operator"
    },
    {
        "value": 21077,
        "NOCCode": "9418",
        "JobTitle": "saw knife miller"
    },
    {
        "value": 21078,
        "NOCCode": "9418",
        "JobTitle": "scarfer-borer – metal products manufacturing"
    },
    {
        "value": 21079,
        "NOCCode": "9418",
        "JobTitle": "scarfing and boring machine operator"
    },
    {
        "value": 21080,
        "NOCCode": "9418",
        "JobTitle": "screen maker, wire"
    },
    {
        "value": 21081,
        "NOCCode": "9418",
        "JobTitle": "scribing machine tender"
    },
    {
        "value": 21082,
        "NOCCode": "9418",
        "JobTitle": "scroll machine tender"
    },
    {
        "value": 21083,
        "NOCCode": "9418",
        "JobTitle": "serrating machine operator"
    },
    {
        "value": 21084,
        "NOCCode": "9418",
        "JobTitle": "serrating machine tender – metal products manufacturing"
    },
    {
        "value": 21085,
        "NOCCode": "9418",
        "JobTitle": "setter, can machine"
    },
    {
        "value": 21086,
        "NOCCode": "9418",
        "JobTitle": "setter, can-making machine"
    },
    {
        "value": 21087,
        "NOCCode": "9418",
        "JobTitle": "setter, grid machine"
    },
    {
        "value": 21088,
        "NOCCode": "9418",
        "JobTitle": "setter, wire loom"
    },
    {
        "value": 21089,
        "NOCCode": "9418",
        "JobTitle": "setter, wire-weaving loom"
    },
    {
        "value": 21090,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, industrial needle machine"
    },
    {
        "value": 21091,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, link wire machine"
    },
    {
        "value": 21092,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, nail making machine"
    },
    {
        "value": 21093,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, safety pin machine"
    },
    {
        "value": 21094,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, spring machine"
    },
    {
        "value": 21095,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, spring-making machine"
    },
    {
        "value": 21096,
        "NOCCode": "9418",
        "JobTitle": "set-up operator, staple machine"
    },
    {
        "value": 21097,
        "NOCCode": "9418",
        "JobTitle": "shadow mask shaper"
    },
    {
        "value": 21098,
        "NOCCode": "9418",
        "JobTitle": "shaker-plate tender"
    },
    {
        "value": 21099,
        "NOCCode": "9418",
        "JobTitle": "shaper, rivets"
    },
    {
        "value": 21100,
        "NOCCode": "9418",
        "JobTitle": "sharpener operator, metal blades"
    },
    {
        "value": 21101,
        "NOCCode": "9418",
        "JobTitle": "shell-case chamfering machine tender"
    },
    {
        "value": 21102,
        "NOCCode": "9418",
        "JobTitle": "sizing and trimming machine adjuster – explosives manufacturing"
    },
    {
        "value": 21103,
        "NOCCode": "9418",
        "JobTitle": "slide fastener links machine tender"
    },
    {
        "value": 21104,
        "NOCCode": "9418",
        "JobTitle": "slide fastener links maker"
    },
    {
        "value": 21105,
        "NOCCode": "9418",
        "JobTitle": "spiral weaving machine operator"
    },
    {
        "value": 21106,
        "NOCCode": "9418",
        "JobTitle": "spool winder"
    },
    {
        "value": 21107,
        "NOCCode": "9418",
        "JobTitle": "spooler – metal products manufacturing"
    },
    {
        "value": 21108,
        "NOCCode": "9418",
        "JobTitle": "spring assembler, machine"
    },
    {
        "value": 21109,
        "NOCCode": "9418",
        "JobTitle": "spring assembly machine operator"
    },
    {
        "value": 21110,
        "NOCCode": "9418",
        "JobTitle": "spring coiler"
    },
    {
        "value": 21111,
        "NOCCode": "9418",
        "JobTitle": "spring inspector"
    },
    {
        "value": 21112,
        "NOCCode": "9418",
        "JobTitle": "spring machine operator"
    },
    {
        "value": 21113,
        "NOCCode": "9418",
        "JobTitle": "spring machine set-up operator"
    },
    {
        "value": 21114,
        "NOCCode": "9418",
        "JobTitle": "spring tester"
    },
    {
        "value": 21115,
        "NOCCode": "9418",
        "JobTitle": "spring winder"
    },
    {
        "value": 21116,
        "NOCCode": "9418",
        "JobTitle": "spring-coiling machine operator"
    },
    {
        "value": 21117,
        "NOCCode": "9418",
        "JobTitle": "spring-making machine operator"
    },
    {
        "value": 21118,
        "NOCCode": "9418",
        "JobTitle": "spring-making machine set-up operator"
    },
    {
        "value": 21119,
        "NOCCode": "9418",
        "JobTitle": "spring-winding machine operator"
    },
    {
        "value": 21120,
        "NOCCode": "9418",
        "JobTitle": "staple machine set-up operator"
    },
    {
        "value": 21121,
        "NOCCode": "9418",
        "JobTitle": "steel wool machine operator"
    },
    {
        "value": 21122,
        "NOCCode": "9418",
        "JobTitle": "stranding machine operator"
    },
    {
        "value": 21123,
        "NOCCode": "9418",
        "JobTitle": "stretcher and tester, metal cables"
    },
    {
        "value": 21124,
        "NOCCode": "9418",
        "JobTitle": "stretcher and tester, wire cables"
    },
    {
        "value": 21125,
        "NOCCode": "9418",
        "JobTitle": "tack maker"
    },
    {
        "value": 21126,
        "NOCCode": "9418",
        "JobTitle": "tapping machine tender"
    },
    {
        "value": 21127,
        "NOCCode": "9418",
        "JobTitle": "tender, embossing machine – explosives manufacturing"
    },
    {
        "value": 21128,
        "NOCCode": "9418",
        "JobTitle": "tender, metal-twisting machine"
    },
    {
        "value": 21129,
        "NOCCode": "9418",
        "JobTitle": "tender, tapping machine"
    },
    {
        "value": 21130,
        "NOCCode": "9418",
        "JobTitle": "tender, welding rod extruder"
    },
    {
        "value": 21131,
        "NOCCode": "9418",
        "JobTitle": "tender, wire-pairing machine"
    },
    {
        "value": 21132,
        "NOCCode": "9418",
        "JobTitle": "tender, wire-rewinding machine"
    },
    {
        "value": 21133,
        "NOCCode": "9418",
        "JobTitle": "tent frame machine operator"
    },
    {
        "value": 21134,
        "NOCCode": "9418",
        "JobTitle": "tester, chains"
    },
    {
        "value": 21135,
        "NOCCode": "9418",
        "JobTitle": "tester, springs"
    },
    {
        "value": 21136,
        "NOCCode": "9418",
        "JobTitle": "tin can inspector"
    },
    {
        "value": 21137,
        "NOCCode": "9418",
        "JobTitle": "tinware beader"
    },
    {
        "value": 21138,
        "NOCCode": "9418",
        "JobTitle": "trim machine adjuster"
    },
    {
        "value": 21139,
        "NOCCode": "9418",
        "JobTitle": "valve spring coiler"
    },
    {
        "value": 21140,
        "NOCCode": "9418",
        "JobTitle": "valve spring winder"
    },
    {
        "value": 21141,
        "NOCCode": "9418",
        "JobTitle": "weaver – metal products manufacturing"
    },
    {
        "value": 21142,
        "NOCCode": "9418",
        "JobTitle": "weaver, wire lattices"
    },
    {
        "value": 21143,
        "NOCCode": "9418",
        "JobTitle": "weaver, wire lattices and wire meshes"
    },
    {
        "value": 21144,
        "NOCCode": "9418",
        "JobTitle": "weaver, wire netting"
    },
    {
        "value": 21145,
        "NOCCode": "9418",
        "JobTitle": "weaver, wire screens"
    },
    {
        "value": 21146,
        "NOCCode": "9418",
        "JobTitle": "weaver, wires"
    },
    {
        "value": 21147,
        "NOCCode": "9418",
        "JobTitle": "welding rod-extruder operator"
    },
    {
        "value": 21148,
        "NOCCode": "9418",
        "JobTitle": "welding rod-extruder tender"
    },
    {
        "value": 21149,
        "NOCCode": "9418",
        "JobTitle": "winder, springs"
    },
    {
        "value": 21150,
        "NOCCode": "9418",
        "JobTitle": "winder, valve springs"
    },
    {
        "value": 21151,
        "NOCCode": "9418",
        "JobTitle": "wire basket maker"
    },
    {
        "value": 21152,
        "NOCCode": "9418",
        "JobTitle": "wire bundle machine operator"
    },
    {
        "value": 21153,
        "NOCCode": "9418",
        "JobTitle": "wire cable assembler and tester"
    },
    {
        "value": 21154,
        "NOCCode": "9418",
        "JobTitle": "wire cable stretcher and tester"
    },
    {
        "value": 21155,
        "NOCCode": "9418",
        "JobTitle": "wire lampshade frame maker"
    },
    {
        "value": 21156,
        "NOCCode": "9418",
        "JobTitle": "wire lattice and wire mesh weaver"
    },
    {
        "value": 21157,
        "NOCCode": "9418",
        "JobTitle": "wire lattice weaver"
    },
    {
        "value": 21158,
        "NOCCode": "9418",
        "JobTitle": "wire loom operator"
    },
    {
        "value": 21159,
        "NOCCode": "9418",
        "JobTitle": "wire loom setter"
    },
    {
        "value": 21160,
        "NOCCode": "9418",
        "JobTitle": "wire loop machine operator"
    },
    {
        "value": 21161,
        "NOCCode": "9418",
        "JobTitle": "wire mesh fence-making machine operator"
    },
    {
        "value": 21162,
        "NOCCode": "9418",
        "JobTitle": "wire mesh gate assembler"
    },
    {
        "value": 21163,
        "NOCCode": "9418",
        "JobTitle": "wire mesh knitter"
    },
    {
        "value": 21164,
        "NOCCode": "9418",
        "JobTitle": "wire netting weaver"
    },
    {
        "value": 21165,
        "NOCCode": "9418",
        "JobTitle": "wire rewinder, machine"
    },
    {
        "value": 21166,
        "NOCCode": "9418",
        "JobTitle": "wire rope maker"
    },
    {
        "value": 21167,
        "NOCCode": "9418",
        "JobTitle": "wire rope sling and cable splicer"
    },
    {
        "value": 21168,
        "NOCCode": "9418",
        "JobTitle": "wire screen cutter and trimmer"
    },
    {
        "value": 21169,
        "NOCCode": "9418",
        "JobTitle": "wire screen former"
    },
    {
        "value": 21170,
        "NOCCode": "9418",
        "JobTitle": "wire screen maker"
    },
    {
        "value": 21171,
        "NOCCode": "9418",
        "JobTitle": "wire screen weaver"
    },
    {
        "value": 21172,
        "NOCCode": "9418",
        "JobTitle": "wire weaver"
    },
    {
        "value": 21173,
        "NOCCode": "9418",
        "JobTitle": "wire weaver, bench loom"
    },
    {
        "value": 21174,
        "NOCCode": "9418",
        "JobTitle": "wire-coiner operator"
    },
    {
        "value": 21175,
        "NOCCode": "9418",
        "JobTitle": "wire-crimping machine operator"
    },
    {
        "value": 21176,
        "NOCCode": "9418",
        "JobTitle": "wire-netting machine operator"
    },
    {
        "value": 21177,
        "NOCCode": "9418",
        "JobTitle": "wire-pairing machine tender"
    },
    {
        "value": 21178,
        "NOCCode": "9418",
        "JobTitle": "wire-rewinding machine tender"
    },
    {
        "value": 21179,
        "NOCCode": "9418",
        "JobTitle": "wire-weaving loom setter"
    },
    {
        "value": 21180,
        "NOCCode": "9418",
        "JobTitle": "wireworks weaver – metal products manufacturing"
    },
    {
        "value": 21181,
        "NOCCode": "9418",
        "JobTitle": "zipper links machine tender"
    },
    {
        "value": 21182,
        "NOCCode": "9418",
        "JobTitle": "zipper links maker"
    },
    {
        "value": 21183,
        "NOCCode": "9421",
        "JobTitle": "acetylene plant attendant"
    },
    {
        "value": 21184,
        "NOCCode": "9421",
        "JobTitle": "acid pumper – chemical processing"
    },
    {
        "value": 21185,
        "NOCCode": "9421",
        "JobTitle": "acidulation plant tender"
    },
    {
        "value": 21186,
        "NOCCode": "9421",
        "JobTitle": "asphalt coater – chemical processing"
    },
    {
        "value": 21187,
        "NOCCode": "9421",
        "JobTitle": "asphalt heater tender"
    },
    {
        "value": 21188,
        "NOCCode": "9421",
        "JobTitle": "asphalt plant dryer tender"
    },
    {
        "value": 21189,
        "NOCCode": "9421",
        "JobTitle": "asphalt roofing material mixer"
    },
    {
        "value": 21190,
        "NOCCode": "9421",
        "JobTitle": "asphalt roofing materials inspector"
    },
    {
        "value": 21191,
        "NOCCode": "9421",
        "JobTitle": "autoclave operator – chemical processing"
    },
    {
        "value": 21192,
        "NOCCode": "9421",
        "JobTitle": "backing-in machine tender"
    },
    {
        "value": 21193,
        "NOCCode": "9421",
        "JobTitle": "batch mixer – chemical processing"
    },
    {
        "value": 21194,
        "NOCCode": "9421",
        "JobTitle": "batch still operator"
    },
    {
        "value": 21195,
        "NOCCode": "9421",
        "JobTitle": "battery acid adjuster"
    },
    {
        "value": 21196,
        "NOCCode": "9421",
        "JobTitle": "battery paste maker"
    },
    {
        "value": 21197,
        "NOCCode": "9421",
        "JobTitle": "battery paste mixer"
    },
    {
        "value": 21198,
        "NOCCode": "9421",
        "JobTitle": "black powder grainer and glazier tender"
    },
    {
        "value": 21199,
        "NOCCode": "9421",
        "JobTitle": "black powder press tender"
    },
    {
        "value": 21200,
        "NOCCode": "9421",
        "JobTitle": "bleacher, oils"
    },
    {
        "value": 21201,
        "NOCCode": "9421",
        "JobTitle": "bleaching solution maker"
    },
    {
        "value": 21202,
        "NOCCode": "9421",
        "JobTitle": "blender – chemical processing"
    },
    {
        "value": 21203,
        "NOCCode": "9421",
        "JobTitle": "blender operator – chemical processing"
    },
    {
        "value": 21204,
        "NOCCode": "9421",
        "JobTitle": "blender, glues – chemical processing"
    },
    {
        "value": 21205,
        "NOCCode": "9421",
        "JobTitle": "cadmium liquor maker"
    },
    {
        "value": 21206,
        "NOCCode": "9421",
        "JobTitle": "calciner tender"
    },
    {
        "value": 21207,
        "NOCCode": "9421",
        "JobTitle": "capsule filler – chemical processing"
    },
    {
        "value": 21208,
        "NOCCode": "9421",
        "JobTitle": "capsule machine operator"
    },
    {
        "value": 21209,
        "NOCCode": "9421",
        "JobTitle": "capsule machine operator – chemical processing"
    },
    {
        "value": 21210,
        "NOCCode": "9421",
        "JobTitle": "carbon black pelletizer"
    },
    {
        "value": 21211,
        "NOCCode": "9421",
        "JobTitle": "carbon paste mixer operator"
    },
    {
        "value": 21212,
        "NOCCode": "9421",
        "JobTitle": "cellulose film caster – chemical processing"
    },
    {
        "value": 21213,
        "NOCCode": "9421",
        "JobTitle": "cellulose film dry-end tender"
    },
    {
        "value": 21214,
        "NOCCode": "9421",
        "JobTitle": "cellulose film rewinder"
    },
    {
        "value": 21215,
        "NOCCode": "9421",
        "JobTitle": "cellulose film wet-end tender"
    },
    {
        "value": 21216,
        "NOCCode": "9421",
        "JobTitle": "centrifugal separator tender – chemical processing"
    },
    {
        "value": 21217,
        "NOCCode": "9421",
        "JobTitle": "centrifuge tender – chemical processing"
    },
    {
        "value": 21218,
        "NOCCode": "9421",
        "JobTitle": "charcoal burner"
    },
    {
        "value": 21219,
        "NOCCode": "9421",
        "JobTitle": "chemical operator, chemical-processing"
    },
    {
        "value": 21220,
        "NOCCode": "9421",
        "JobTitle": "chemical plant machine operator"
    },
    {
        "value": 21221,
        "NOCCode": "9421",
        "JobTitle": "chiller operator – chemical processing"
    },
    {
        "value": 21222,
        "NOCCode": "9421",
        "JobTitle": "chiller tender – chemical processing"
    },
    {
        "value": 21223,
        "NOCCode": "9421",
        "JobTitle": "cleaning compounds mixer"
    },
    {
        "value": 21224,
        "NOCCode": "9421",
        "JobTitle": "coater, asphalt"
    },
    {
        "value": 21225,
        "NOCCode": "9421",
        "JobTitle": "coating compounds mixer – chemical processing"
    },
    {
        "value": 21226,
        "NOCCode": "9421",
        "JobTitle": "coating machine operator – chemical processing"
    },
    {
        "value": 21227,
        "NOCCode": "9421",
        "JobTitle": "coating machine tender – chemical processing"
    },
    {
        "value": 21228,
        "NOCCode": "9421",
        "JobTitle": "coatings mixer – chemical processing"
    },
    {
        "value": 21229,
        "NOCCode": "9421",
        "JobTitle": "coke by-products pump operator – chemical processing"
    },
    {
        "value": 21230,
        "NOCCode": "9421",
        "JobTitle": "colour grinder tender – chemical processing"
    },
    {
        "value": 21231,
        "NOCCode": "9421",
        "JobTitle": "colour matcher – chemical processing"
    },
    {
        "value": 21232,
        "NOCCode": "9421",
        "JobTitle": "colour mixer, prints – chemical processing"
    },
    {
        "value": 21233,
        "NOCCode": "9421",
        "JobTitle": "colour weigher and mixer"
    },
    {
        "value": 21234,
        "NOCCode": "9421",
        "JobTitle": "composition mixer – chemical processing"
    },
    {
        "value": 21235,
        "NOCCode": "9421",
        "JobTitle": "compounder – chemical processing"
    },
    {
        "value": 21236,
        "NOCCode": "9421",
        "JobTitle": "compounder, latex"
    },
    {
        "value": 21237,
        "NOCCode": "9421",
        "JobTitle": "cooker – chemical processing"
    },
    {
        "value": 21238,
        "NOCCode": "9421",
        "JobTitle": "cosmetics and perfume maker"
    },
    {
        "value": 21239,
        "NOCCode": "9421",
        "JobTitle": "crusher tender – chemical processing"
    },
    {
        "value": 21240,
        "NOCCode": "9421",
        "JobTitle": "crutcher operator – chemical processing"
    },
    {
        "value": 21241,
        "NOCCode": "9421",
        "JobTitle": "crystallizer tender – chemical processing"
    },
    {
        "value": 21242,
        "NOCCode": "9421",
        "JobTitle": "dehydrating press tender – chemical processing"
    },
    {
        "value": 21243,
        "NOCCode": "9421",
        "JobTitle": "detergent and powdered soap screener-perfumer"
    },
    {
        "value": 21244,
        "NOCCode": "9421",
        "JobTitle": "devulcanizer tender"
    },
    {
        "value": 21245,
        "NOCCode": "9421",
        "JobTitle": "digester operator – chemical processing"
    },
    {
        "value": 21246,
        "NOCCode": "9421",
        "JobTitle": "distiller operator – soda ash processing"
    },
    {
        "value": 21247,
        "NOCCode": "9421",
        "JobTitle": "distiller, wood distillation and charcoal"
    },
    {
        "value": 21248,
        "NOCCode": "9421",
        "JobTitle": "drug and toilet goods weigher"
    },
    {
        "value": 21249,
        "NOCCode": "9421",
        "JobTitle": "drum filler, liquefied gases"
    },
    {
        "value": 21250,
        "NOCCode": "9421",
        "JobTitle": "dry explosive mixer"
    },
    {
        "value": 21251,
        "NOCCode": "9421",
        "JobTitle": "Dry Ice maker"
    },
    {
        "value": 21252,
        "NOCCode": "9421",
        "JobTitle": "dry-end tender, cellulose films"
    },
    {
        "value": 21253,
        "NOCCode": "9421",
        "JobTitle": "dryer operator – chemical processing"
    },
    {
        "value": 21254,
        "NOCCode": "9421",
        "JobTitle": "dryer operator, soap flakes"
    },
    {
        "value": 21255,
        "NOCCode": "9421",
        "JobTitle": "dryer tender – chemical processing"
    },
    {
        "value": 21256,
        "NOCCode": "9421",
        "JobTitle": "dryer tender, asphalt plant"
    },
    {
        "value": 21257,
        "NOCCode": "9421",
        "JobTitle": "dryer tender, nitrocellulose"
    },
    {
        "value": 21258,
        "NOCCode": "9421",
        "JobTitle": "dryhouse attendant – chemical processing"
    },
    {
        "value": 21259,
        "NOCCode": "9421",
        "JobTitle": "dryhouse tender, explosive primers – chemical processing"
    },
    {
        "value": 21260,
        "NOCCode": "9421",
        "JobTitle": "dye maker"
    },
    {
        "value": 21261,
        "NOCCode": "9421",
        "JobTitle": "electrode oven operator"
    },
    {
        "value": 21262,
        "NOCCode": "9421",
        "JobTitle": "evaporator operator – chemical processing"
    },
    {
        "value": 21263,
        "NOCCode": "9421",
        "JobTitle": "explosive primers dryhouse attendant – chemical processing"
    },
    {
        "value": 21264,
        "NOCCode": "9421",
        "JobTitle": "explosive primers dryhouse tender – chemical processing"
    },
    {
        "value": 21265,
        "NOCCode": "9421",
        "JobTitle": "explosives mixer"
    },
    {
        "value": 21266,
        "NOCCode": "9421",
        "JobTitle": "explosives mixer tender"
    },
    {
        "value": 21267,
        "NOCCode": "9421",
        "JobTitle": "extractor operator, phenol"
    },
    {
        "value": 21268,
        "NOCCode": "9421",
        "JobTitle": "extractor operator, phosphoric acid"
    },
    {
        "value": 21269,
        "NOCCode": "9421",
        "JobTitle": "fertilizer mixer"
    },
    {
        "value": 21270,
        "NOCCode": "9421",
        "JobTitle": "fibreglass binder mixer"
    },
    {
        "value": 21271,
        "NOCCode": "9421",
        "JobTitle": "filter press tender – chemical processing"
    },
    {
        "value": 21272,
        "NOCCode": "9421",
        "JobTitle": "flaker tender – chemical processing"
    },
    {
        "value": 21273,
        "NOCCode": "9421",
        "JobTitle": "formulation blender operator, pesticides"
    },
    {
        "value": 21274,
        "NOCCode": "9421",
        "JobTitle": "formulations blender operator"
    },
    {
        "value": 21275,
        "NOCCode": "9421",
        "JobTitle": "formulations blender operator – chemical processing"
    },
    {
        "value": 21276,
        "NOCCode": "9421",
        "JobTitle": "furnace cooler"
    },
    {
        "value": 21277,
        "NOCCode": "9421",
        "JobTitle": "fuse maker – chemical processing"
    },
    {
        "value": 21278,
        "NOCCode": "9421",
        "JobTitle": "glue blender"
    },
    {
        "value": 21279,
        "NOCCode": "9421",
        "JobTitle": "graining press operator – chemical processing"
    },
    {
        "value": 21280,
        "NOCCode": "9421",
        "JobTitle": "granulator machine operator"
    },
    {
        "value": 21281,
        "NOCCode": "9421",
        "JobTitle": "granulator machine operator – chemical processing"
    },
    {
        "value": 21282,
        "NOCCode": "9421",
        "JobTitle": "grinder tender – chemical processing"
    },
    {
        "value": 21283,
        "NOCCode": "9421",
        "JobTitle": "hydrator operator"
    },
    {
        "value": 21284,
        "NOCCode": "9421",
        "JobTitle": "ink matcher"
    },
    {
        "value": 21285,
        "NOCCode": "9421",
        "JobTitle": "ink purifier"
    },
    {
        "value": 21286,
        "NOCCode": "9421",
        "JobTitle": "insecticide mixer"
    },
    {
        "value": 21287,
        "NOCCode": "9421",
        "JobTitle": "inspector, asphalt roofing materials"
    },
    {
        "value": 21288,
        "NOCCode": "9421",
        "JobTitle": "inspector, vinyl fabrics"
    },
    {
        "value": 21289,
        "NOCCode": "9421",
        "JobTitle": "kettle operator – chemical processing"
    },
    {
        "value": 21290,
        "NOCCode": "9421",
        "JobTitle": "kettle tender – chemical processing"
    },
    {
        "value": 21291,
        "NOCCode": "9421",
        "JobTitle": "lacquer maker"
    },
    {
        "value": 21292,
        "NOCCode": "9421",
        "JobTitle": "latex coagulation operator"
    },
    {
        "value": 21293,
        "NOCCode": "9421",
        "JobTitle": "latex compounder"
    },
    {
        "value": 21294,
        "NOCCode": "9421",
        "JobTitle": "lead oxide maker – chemical processing"
    },
    {
        "value": 21295,
        "NOCCode": "9421",
        "JobTitle": "lead oxide mill tender – chemical processing"
    },
    {
        "value": 21296,
        "NOCCode": "9421",
        "JobTitle": "light-oil extractor operator"
    },
    {
        "value": 21297,
        "NOCCode": "9421",
        "JobTitle": "linoleum oil oxidizer"
    },
    {
        "value": 21298,
        "NOCCode": "9421",
        "JobTitle": "liquefied gas drum filler"
    },
    {
        "value": 21299,
        "NOCCode": "9421",
        "JobTitle": "liquid mixer, photographic chemicals"
    },
    {
        "value": 21300,
        "NOCCode": "9421",
        "JobTitle": "loading inspector – chemical processing"
    },
    {
        "value": 21301,
        "NOCCode": "9421",
        "JobTitle": "match head maker"
    },
    {
        "value": 21302,
        "NOCCode": "9421",
        "JobTitle": "mercury recoverer – chemical processing"
    },
    {
        "value": 21303,
        "NOCCode": "9421",
        "JobTitle": "mill roll tester – chemical processing"
    },
    {
        "value": 21304,
        "NOCCode": "9421",
        "JobTitle": "mill tender – chemical processing"
    },
    {
        "value": 21305,
        "NOCCode": "9421",
        "JobTitle": "mixer – chemical processing"
    },
    {
        "value": 21306,
        "NOCCode": "9421",
        "JobTitle": "mixer helper, wallpaper colours"
    },
    {
        "value": 21307,
        "NOCCode": "9421",
        "JobTitle": "mixer tender – chemical processing"
    },
    {
        "value": 21308,
        "NOCCode": "9421",
        "JobTitle": "mixer, asphalt roofing materials"
    },
    {
        "value": 21309,
        "NOCCode": "9421",
        "JobTitle": "mixer, battery paste"
    },
    {
        "value": 21310,
        "NOCCode": "9421",
        "JobTitle": "mixer, coating compounds – chemical processing"
    },
    {
        "value": 21311,
        "NOCCode": "9421",
        "JobTitle": "mixer, coatings – chemical processing"
    },
    {
        "value": 21312,
        "NOCCode": "9421",
        "JobTitle": "mixer, dry explosives"
    },
    {
        "value": 21313,
        "NOCCode": "9421",
        "JobTitle": "mixer, explosives"
    },
    {
        "value": 21314,
        "NOCCode": "9421",
        "JobTitle": "mixer, paints – chemical processing"
    },
    {
        "value": 21315,
        "NOCCode": "9421",
        "JobTitle": "mixer, soda solutions"
    },
    {
        "value": 21316,
        "NOCCode": "9421",
        "JobTitle": "mixer, viscose"
    },
    {
        "value": 21317,
        "NOCCode": "9421",
        "JobTitle": "moulder – chemical processing"
    },
    {
        "value": 21318,
        "NOCCode": "9421",
        "JobTitle": "moulder and compressor, toiletries – chemical processing"
    },
    {
        "value": 21319,
        "NOCCode": "9421",
        "JobTitle": "neutralizer"
    },
    {
        "value": 21320,
        "NOCCode": "9421",
        "JobTitle": "neutralizer, nitroglycerine"
    },
    {
        "value": 21321,
        "NOCCode": "9421",
        "JobTitle": "nitrocellulose dryer tender"
    },
    {
        "value": 21322,
        "NOCCode": "9421",
        "JobTitle": "nitrocellulose finisher"
    },
    {
        "value": 21323,
        "NOCCode": "9421",
        "JobTitle": "nitrocellulose screener and weigher"
    },
    {
        "value": 21324,
        "NOCCode": "9421",
        "JobTitle": "nitroglycerine neutralizer"
    },
    {
        "value": 21325,
        "NOCCode": "9421",
        "JobTitle": "odorization technician – chemical processing"
    },
    {
        "value": 21326,
        "NOCCode": "9421",
        "JobTitle": "operator, batch still"
    },
    {
        "value": 21327,
        "NOCCode": "9421",
        "JobTitle": "operator, chemical plant"
    },
    {
        "value": 21328,
        "NOCCode": "9421",
        "JobTitle": "operator, chemical processing machine"
    },
    {
        "value": 21329,
        "NOCCode": "9421",
        "JobTitle": "operator, grinder – chemical processing"
    },
    {
        "value": 21330,
        "NOCCode": "9421",
        "JobTitle": "operator, mixer – chemical processing"
    },
    {
        "value": 21331,
        "NOCCode": "9421",
        "JobTitle": "operator, orthophosphate wet mix"
    },
    {
        "value": 21332,
        "NOCCode": "9421",
        "JobTitle": "operator, packaging machine – chemical processing"
    },
    {
        "value": 21333,
        "NOCCode": "9421",
        "JobTitle": "operator, soap flake dryer"
    },
    {
        "value": 21334,
        "NOCCode": "9421",
        "JobTitle": "operator, sulphonator – chemical processing"
    },
    {
        "value": 21335,
        "NOCCode": "9421",
        "JobTitle": "operator, tital liquor plant"
    },
    {
        "value": 21336,
        "NOCCode": "9421",
        "JobTitle": "operator, wash-house and recovery equipment – chemical processing"
    },
    {
        "value": 21337,
        "NOCCode": "9421",
        "JobTitle": "orthophosphate wet mix operator"
    },
    {
        "value": 21338,
        "NOCCode": "9421",
        "JobTitle": "oven operator, electrodes"
    },
    {
        "value": 21339,
        "NOCCode": "9421",
        "JobTitle": "packaging machine operator – chemical processing"
    },
    {
        "value": 21340,
        "NOCCode": "9421",
        "JobTitle": "paint maker"
    },
    {
        "value": 21341,
        "NOCCode": "9421",
        "JobTitle": "paint mixer"
    },
    {
        "value": 21342,
        "NOCCode": "9421",
        "JobTitle": "paradichlorobenzene equipment tender"
    },
    {
        "value": 21343,
        "NOCCode": "9421",
        "JobTitle": "pelletizer, plastics"
    },
    {
        "value": 21344,
        "NOCCode": "9421",
        "JobTitle": "perfume maker"
    },
    {
        "value": 21345,
        "NOCCode": "9421",
        "JobTitle": "pesticide mixer"
    },
    {
        "value": 21346,
        "NOCCode": "9421",
        "JobTitle": "phenol plant extractor operator"
    },
    {
        "value": 21347,
        "NOCCode": "9421",
        "JobTitle": "phosphoric acid extractor"
    },
    {
        "value": 21348,
        "NOCCode": "9421",
        "JobTitle": "plastics pelletizer"
    },
    {
        "value": 21349,
        "NOCCode": "9421",
        "JobTitle": "plodder operator"
    },
    {
        "value": 21350,
        "NOCCode": "9421",
        "JobTitle": "powder blender"
    },
    {
        "value": 21351,
        "NOCCode": "9421",
        "JobTitle": "precipitator operator – chemical processing"
    },
    {
        "value": 21352,
        "NOCCode": "9421",
        "JobTitle": "press tender, dehydrating – chemical processing"
    },
    {
        "value": 21353,
        "NOCCode": "9421",
        "JobTitle": "print colour mixer"
    },
    {
        "value": 21354,
        "NOCCode": "9421",
        "JobTitle": "pulverizer and sifter tender – chemical processing"
    },
    {
        "value": 21355,
        "NOCCode": "9421",
        "JobTitle": "pulverizing and sifting equipment tender – chemical processing"
    },
    {
        "value": 21356,
        "NOCCode": "9421",
        "JobTitle": "pump operator, coke by-products – chemical processing"
    },
    {
        "value": 21357,
        "NOCCode": "9421",
        "JobTitle": "reagent mixer – cellulose film manufacturing"
    },
    {
        "value": 21358,
        "NOCCode": "9421",
        "JobTitle": "recausticizer"
    },
    {
        "value": 21359,
        "NOCCode": "9421",
        "JobTitle": "recovery equipment operator – chemical processing"
    },
    {
        "value": 21360,
        "NOCCode": "9421",
        "JobTitle": "recovery vat tender"
    },
    {
        "value": 21361,
        "NOCCode": "9421",
        "JobTitle": "ring furnace operator"
    },
    {
        "value": 21362,
        "NOCCode": "9421",
        "JobTitle": "ring furnace operator helper"
    },
    {
        "value": 21363,
        "NOCCode": "9421",
        "JobTitle": "saturating machine tender – chemical processing"
    },
    {
        "value": 21364,
        "NOCCode": "9421",
        "JobTitle": "saturator operator"
    },
    {
        "value": 21365,
        "NOCCode": "9421",
        "JobTitle": "scratcher tender"
    },
    {
        "value": 21366,
        "NOCCode": "9421",
        "JobTitle": "screener – chemical processing"
    },
    {
        "value": 21367,
        "NOCCode": "9421",
        "JobTitle": "screener and weigher, nitrocellulose"
    },
    {
        "value": 21368,
        "NOCCode": "9421",
        "JobTitle": "screener-perfumer, detergents and powdered soaps"
    },
    {
        "value": 21369,
        "NOCCode": "9421",
        "JobTitle": "sealant operator – chemical processing"
    },
    {
        "value": 21370,
        "NOCCode": "9421",
        "JobTitle": "separation press tender"
    },
    {
        "value": 21371,
        "NOCCode": "9421",
        "JobTitle": "settler-filterer – chemical processing"
    },
    {
        "value": 21372,
        "NOCCode": "9421",
        "JobTitle": "shredding machine tender – chemical processing"
    },
    {
        "value": 21373,
        "NOCCode": "9421",
        "JobTitle": "slabber"
    },
    {
        "value": 21374,
        "NOCCode": "9421",
        "JobTitle": "soap boiler"
    },
    {
        "value": 21375,
        "NOCCode": "9421",
        "JobTitle": "soap chipper"
    },
    {
        "value": 21376,
        "NOCCode": "9421",
        "JobTitle": "soap cutter and stamper"
    },
    {
        "value": 21377,
        "NOCCode": "9421",
        "JobTitle": "soap flake dryer operator"
    },
    {
        "value": 21378,
        "NOCCode": "9421",
        "JobTitle": "soap maker"
    },
    {
        "value": 21379,
        "NOCCode": "9421",
        "JobTitle": "soda dialyzer"
    },
    {
        "value": 21380,
        "NOCCode": "9421",
        "JobTitle": "soda solution maker"
    },
    {
        "value": 21381,
        "NOCCode": "9421",
        "JobTitle": "soda solution mixer"
    },
    {
        "value": 21382,
        "NOCCode": "9421",
        "JobTitle": "solution maker, soda"
    },
    {
        "value": 21383,
        "NOCCode": "9421",
        "JobTitle": "solution mixer – chemical processing"
    },
    {
        "value": 21384,
        "NOCCode": "9421",
        "JobTitle": "solvent recovery tender – chemical processing"
    },
    {
        "value": 21385,
        "NOCCode": "9421",
        "JobTitle": "spinneret pack preparer – chemical processing"
    },
    {
        "value": 21386,
        "NOCCode": "9421",
        "JobTitle": "splash line tender"
    },
    {
        "value": 21387,
        "NOCCode": "9421",
        "JobTitle": "spray dryer tender – chemical processing"
    },
    {
        "value": 21388,
        "NOCCode": "9421",
        "JobTitle": "still tender"
    },
    {
        "value": 21389,
        "NOCCode": "9421",
        "JobTitle": "sulphonator"
    },
    {
        "value": 21390,
        "NOCCode": "9421",
        "JobTitle": "tender, nitrocellulose dryer"
    },
    {
        "value": 21391,
        "NOCCode": "9421",
        "JobTitle": "tender, recausticizer"
    },
    {
        "value": 21392,
        "NOCCode": "9421",
        "JobTitle": "tender, saturating machine – chemical processing"
    },
    {
        "value": 21393,
        "NOCCode": "9421",
        "JobTitle": "textile treatment solution mixer"
    },
    {
        "value": 21394,
        "NOCCode": "9421",
        "JobTitle": "tinter – chemical processing"
    },
    {
        "value": 21395,
        "NOCCode": "9421",
        "JobTitle": "toiletries moulder and compressor"
    },
    {
        "value": 21396,
        "NOCCode": "9421",
        "JobTitle": "tower operator – soap"
    },
    {
        "value": 21397,
        "NOCCode": "9421",
        "JobTitle": "tracer powder blender"
    },
    {
        "value": 21398,
        "NOCCode": "9421",
        "JobTitle": "varnish maker"
    },
    {
        "value": 21399,
        "NOCCode": "9421",
        "JobTitle": "vinyl and asbestos-blending machine tender"
    },
    {
        "value": 21400,
        "NOCCode": "9421",
        "JobTitle": "vinyl fabrics inspector"
    },
    {
        "value": 21401,
        "NOCCode": "9421",
        "JobTitle": "viscose filter operator"
    },
    {
        "value": 21402,
        "NOCCode": "9421",
        "JobTitle": "viscose maker"
    },
    {
        "value": 21403,
        "NOCCode": "9421",
        "JobTitle": "viscose solution mixer"
    },
    {
        "value": 21404,
        "NOCCode": "9421",
        "JobTitle": "washer and dryer – chemical processing"
    },
    {
        "value": 21405,
        "NOCCode": "9421",
        "JobTitle": "wash-house and recovery operator"
    },
    {
        "value": 21406,
        "NOCCode": "9421",
        "JobTitle": "wax bleacher"
    },
    {
        "value": 21407,
        "NOCCode": "9421",
        "JobTitle": "weigher – chemical processing"
    },
    {
        "value": 21408,
        "NOCCode": "9421",
        "JobTitle": "weigher and mixer, colorants – chemical processing"
    },
    {
        "value": 21409,
        "NOCCode": "9421",
        "JobTitle": "wet mix operator, orthophosphate"
    },
    {
        "value": 21410,
        "NOCCode": "9421",
        "JobTitle": "wet-end tender, cellulose films"
    },
    {
        "value": 21411,
        "NOCCode": "9421",
        "JobTitle": "white lead mixer"
    },
    {
        "value": 21412,
        "NOCCode": "9422",
        "JobTitle": "arch cushion press operator – plastic manufacturing"
    },
    {
        "value": 21413,
        "NOCCode": "9422",
        "JobTitle": "bag machine operator"
    },
    {
        "value": 21414,
        "NOCCode": "9422",
        "JobTitle": "bag-making machine operator – plastic manufacturing"
    },
    {
        "value": 21415,
        "NOCCode": "9422",
        "JobTitle": "bakelite mixer operator – plastic manufacturing"
    },
    {
        "value": 21416,
        "NOCCode": "9422",
        "JobTitle": "banbury mixer operator – plastic manufacturing"
    },
    {
        "value": 21417,
        "NOCCode": "9422",
        "JobTitle": "banbury operator – plastic manufacturing"
    },
    {
        "value": 21418,
        "NOCCode": "9422",
        "JobTitle": "blender operator – plastic manufacturing"
    },
    {
        "value": 21419,
        "NOCCode": "9422",
        "JobTitle": "blow moulder operator – plastic manufacturing"
    },
    {
        "value": 21420,
        "NOCCode": "9422",
        "JobTitle": "blow moulding machine operator – plastic manufacturing"
    },
    {
        "value": 21421,
        "NOCCode": "9422",
        "JobTitle": "blow-moulding process operator – plastic manufacturing"
    },
    {
        "value": 21422,
        "NOCCode": "9422",
        "JobTitle": "cake press operator – plastic manufacturing"
    },
    {
        "value": 21423,
        "NOCCode": "9422",
        "JobTitle": "calender operator – plastic manufacturing"
    },
    {
        "value": 21424,
        "NOCCode": "9422",
        "JobTitle": "calender operator, multi-roll – plastic manufacturing"
    },
    {
        "value": 21425,
        "NOCCode": "9422",
        "JobTitle": "calender wind-up tender – plastic manufacturing"
    },
    {
        "value": 21426,
        "NOCCode": "9422",
        "JobTitle": "calendering machine tender – plastic manufacturing"
    },
    {
        "value": 21427,
        "NOCCode": "9422",
        "JobTitle": "caster – plastic manufacturing"
    },
    {
        "value": 21428,
        "NOCCode": "9422",
        "JobTitle": "casting room operator – plastic manufacturing"
    },
    {
        "value": 21429,
        "NOCCode": "9422",
        "JobTitle": "cellophane bag machine operator – plastic manufacturing"
    },
    {
        "value": 21430,
        "NOCCode": "9422",
        "JobTitle": "cellophane bag maker – plastic manufacturing"
    },
    {
        "value": 21431,
        "NOCCode": "9422",
        "JobTitle": "cellophane making worker – plastic manufacturing"
    },
    {
        "value": 21432,
        "NOCCode": "9422",
        "JobTitle": "coagulator, plastics"
    },
    {
        "value": 21433,
        "NOCCode": "9422",
        "JobTitle": "compounder, plastics"
    },
    {
        "value": 21434,
        "NOCCode": "9422",
        "JobTitle": "compounder, plastics and plastic products"
    },
    {
        "value": 21435,
        "NOCCode": "9422",
        "JobTitle": "compression moulder – plastic manufacturing"
    },
    {
        "value": 21436,
        "NOCCode": "9422",
        "JobTitle": "encapsulator – plastic manufacturing"
    },
    {
        "value": 21437,
        "NOCCode": "9422",
        "JobTitle": "extruder operator – plastic manufacturing"
    },
    {
        "value": 21438,
        "NOCCode": "9422",
        "JobTitle": "extruder operator, plastic pipes"
    },
    {
        "value": 21439,
        "NOCCode": "9422",
        "JobTitle": "extruding machine operator – plastic manufacturing"
    },
    {
        "value": 21440,
        "NOCCode": "9422",
        "JobTitle": "extrusion blow moulding operator"
    },
    {
        "value": 21441,
        "NOCCode": "9422",
        "JobTitle": "extrusion machine operator – plastic manufacturing"
    },
    {
        "value": 21442,
        "NOCCode": "9422",
        "JobTitle": "fibreglass machine operator – plastic manufacturing"
    },
    {
        "value": 21443,
        "NOCCode": "9422",
        "JobTitle": "fibreglass moulder"
    },
    {
        "value": 21444,
        "NOCCode": "9422",
        "JobTitle": "fibreglass-spraying machine operator"
    },
    {
        "value": 21445,
        "NOCCode": "9422",
        "JobTitle": "flatbed press operator – plastic manufacturing"
    },
    {
        "value": 21446,
        "NOCCode": "9422",
        "JobTitle": "foam plastic-producing machine operator"
    },
    {
        "value": 21447,
        "NOCCode": "9422",
        "JobTitle": "foam plastic-spraying machine operator"
    },
    {
        "value": 21448,
        "NOCCode": "9422",
        "JobTitle": "footwear presser – plastic manufacturing"
    },
    {
        "value": 21449,
        "NOCCode": "9422",
        "JobTitle": "gel coat sprayer operator – plastic manufacturing"
    },
    {
        "value": 21450,
        "NOCCode": "9422",
        "JobTitle": "grinder operator – plastic manufacturing"
    },
    {
        "value": 21451,
        "NOCCode": "9422",
        "JobTitle": "heat-sealing machine tender – plastic manufacturing"
    },
    {
        "value": 21452,
        "NOCCode": "9422",
        "JobTitle": "imprinting machine tender, plastics"
    },
    {
        "value": 21453,
        "NOCCode": "9422",
        "JobTitle": "injection blow moulding processing operator"
    },
    {
        "value": 21454,
        "NOCCode": "9422",
        "JobTitle": "injection molding set-up operator"
    },
    {
        "value": 21455,
        "NOCCode": "9422",
        "JobTitle": "injection moulder – plastic manufacturing"
    },
    {
        "value": 21456,
        "NOCCode": "9422",
        "JobTitle": "injection moulder operator – plastic manufacturing"
    },
    {
        "value": 21457,
        "NOCCode": "9422",
        "JobTitle": "injection moulding operator – plastic manufacturing"
    },
    {
        "value": 21458,
        "NOCCode": "9422",
        "JobTitle": "injection moulding processing operator"
    },
    {
        "value": 21459,
        "NOCCode": "9422",
        "JobTitle": "injection operator – plastic manufacturing"
    },
    {
        "value": 21460,
        "NOCCode": "9422",
        "JobTitle": "injection plastic moulder"
    },
    {
        "value": 21461,
        "NOCCode": "9422",
        "JobTitle": "injection-moulding machine operator – plastic manufacturing"
    },
    {
        "value": 21462,
        "NOCCode": "9422",
        "JobTitle": "laminating machine tender – plastic manufacturing"
    },
    {
        "value": 21463,
        "NOCCode": "9422",
        "JobTitle": "lead operator, blow-moulding machine – plastic manufacturing"
    },
    {
        "value": 21464,
        "NOCCode": "9422",
        "JobTitle": "lead operator, injection-moulding machine – plastic manufacturing"
    },
    {
        "value": 21465,
        "NOCCode": "9422",
        "JobTitle": "linoleum floor tile backer"
    },
    {
        "value": 21466,
        "NOCCode": "9422",
        "JobTitle": "linoleum floor tile calender operator"
    },
    {
        "value": 21467,
        "NOCCode": "9422",
        "JobTitle": "machine operator – plastic manufacturing"
    },
    {
        "value": 21468,
        "NOCCode": "9422",
        "JobTitle": "machine operator – plastics processing"
    },
    {
        "value": 21469,
        "NOCCode": "9422",
        "JobTitle": "machine set-up operator – plastic manufacturing"
    },
    {
        "value": 21470,
        "NOCCode": "9422",
        "JobTitle": "mannequin moulder – plastic manufacturing"
    },
    {
        "value": 21471,
        "NOCCode": "9422",
        "JobTitle": "mixer operator, solutions – plastic manufacturing"
    },
    {
        "value": 21472,
        "NOCCode": "9422",
        "JobTitle": "mould setter, plastic"
    },
    {
        "value": 21473,
        "NOCCode": "9422",
        "JobTitle": "moulder operator – plastic manufacturing"
    },
    {
        "value": 21474,
        "NOCCode": "9422",
        "JobTitle": "moulder, compression – plastic manufacturing"
    },
    {
        "value": 21475,
        "NOCCode": "9422",
        "JobTitle": "moulder, fibreglass"
    },
    {
        "value": 21476,
        "NOCCode": "9422",
        "JobTitle": "moulder, plastic mannequins – plastic manufacturing"
    },
    {
        "value": 21477,
        "NOCCode": "9422",
        "JobTitle": "moulder, printing rollers – plastic manufacturing"
    },
    {
        "value": 21478,
        "NOCCode": "9422",
        "JobTitle": "moulding machine operator, injection – plastic manufacturing"
    },
    {
        "value": 21479,
        "NOCCode": "9422",
        "JobTitle": "moulding operator, injection – plastic manufacturing"
    },
    {
        "value": 21480,
        "NOCCode": "9422",
        "JobTitle": "multiple extruder operator – plastic manufacturing"
    },
    {
        "value": 21481,
        "NOCCode": "9422",
        "JobTitle": "neoprene sprayer operator – plastic manufacturing"
    },
    {
        "value": 21482,
        "NOCCode": "9422",
        "JobTitle": "operator, blender – plastic manufacturing"
    },
    {
        "value": 21483,
        "NOCCode": "9422",
        "JobTitle": "operator, fibreglass-spinning machine – plastic manufacturing"
    },
    {
        "value": 21484,
        "NOCCode": "9422",
        "JobTitle": "operator, fibreglass-spraying machine"
    },
    {
        "value": 21485,
        "NOCCode": "9422",
        "JobTitle": "operator, flatbed press – plastic manufacturing"
    },
    {
        "value": 21486,
        "NOCCode": "9422",
        "JobTitle": "operator, foamed-plastic-producing machine"
    },
    {
        "value": 21487,
        "NOCCode": "9422",
        "JobTitle": "operator, gel coat sprayer – plastic manufacturing"
    },
    {
        "value": 21488,
        "NOCCode": "9422",
        "JobTitle": "operator, injection moulding – plastic manufacturing"
    },
    {
        "value": 21489,
        "NOCCode": "9422",
        "JobTitle": "operator, moulder – plastic manufacturing"
    },
    {
        "value": 21490,
        "NOCCode": "9422",
        "JobTitle": "operator, pipe extrusion machine – plastic manufacturing"
    },
    {
        "value": 21491,
        "NOCCode": "9422",
        "JobTitle": "operator, plastic footwear moulder"
    },
    {
        "value": 21492,
        "NOCCode": "9422",
        "JobTitle": "pipe extrusion machine operator – plastic manufacturing"
    },
    {
        "value": 21493,
        "NOCCode": "9422",
        "JobTitle": "plastic bag machine operator"
    },
    {
        "value": 21494,
        "NOCCode": "9422",
        "JobTitle": "plastic bag maker operator"
    },
    {
        "value": 21495,
        "NOCCode": "9422",
        "JobTitle": "plastic centrifugal caster"
    },
    {
        "value": 21496,
        "NOCCode": "9422",
        "JobTitle": "plastic coagulation operator"
    },
    {
        "value": 21497,
        "NOCCode": "9422",
        "JobTitle": "plastic coagulator"
    },
    {
        "value": 21498,
        "NOCCode": "9422",
        "JobTitle": "plastic compounder"
    },
    {
        "value": 21499,
        "NOCCode": "9422",
        "JobTitle": "plastic extruder operator"
    },
    {
        "value": 21500,
        "NOCCode": "9422",
        "JobTitle": "plastic film roll inspector"
    },
    {
        "value": 21501,
        "NOCCode": "9422",
        "JobTitle": "plastic footwear moulder operator"
    },
    {
        "value": 21502,
        "NOCCode": "9422",
        "JobTitle": "plastic mannequin moulder – plastic manufacturing"
    },
    {
        "value": 21503,
        "NOCCode": "9422",
        "JobTitle": "plastic mixer operator"
    },
    {
        "value": 21504,
        "NOCCode": "9422",
        "JobTitle": "plastic mould setter"
    },
    {
        "value": 21505,
        "NOCCode": "9422",
        "JobTitle": "plastic moulder, compression"
    },
    {
        "value": 21506,
        "NOCCode": "9422",
        "JobTitle": "plastic moulder, injection"
    },
    {
        "value": 21507,
        "NOCCode": "9422",
        "JobTitle": "plastic pipe production worker"
    },
    {
        "value": 21508,
        "NOCCode": "9422",
        "JobTitle": "plastic windshield processor"
    },
    {
        "value": 21509,
        "NOCCode": "9422",
        "JobTitle": "plasticator operator"
    },
    {
        "value": 21510,
        "NOCCode": "9422",
        "JobTitle": "plastic-extrusion operator"
    },
    {
        "value": 21511,
        "NOCCode": "9422",
        "JobTitle": "plastic-fibre-extruding machine tender"
    },
    {
        "value": 21512,
        "NOCCode": "9422",
        "JobTitle": "plastic-film extrusion machine operator"
    },
    {
        "value": 21513,
        "NOCCode": "9422",
        "JobTitle": "plastic-pipe production machine operator"
    },
    {
        "value": 21514,
        "NOCCode": "9422",
        "JobTitle": "plastic-pipe-extruder operator"
    },
    {
        "value": 21515,
        "NOCCode": "9422",
        "JobTitle": "plastics and plastic products compounder"
    },
    {
        "value": 21516,
        "NOCCode": "9422",
        "JobTitle": "plastics machine operator"
    },
    {
        "value": 21517,
        "NOCCode": "9422",
        "JobTitle": "plastics moulder"
    },
    {
        "value": 21518,
        "NOCCode": "9422",
        "JobTitle": "plastics press operator"
    },
    {
        "value": 21519,
        "NOCCode": "9422",
        "JobTitle": "plastics processing compounder"
    },
    {
        "value": 21520,
        "NOCCode": "9422",
        "JobTitle": "plastics strainer operator"
    },
    {
        "value": 21521,
        "NOCCode": "9422",
        "JobTitle": "plastics-blending machine tender"
    },
    {
        "value": 21522,
        "NOCCode": "9422",
        "JobTitle": "plastics-moulding machine operator"
    },
    {
        "value": 21523,
        "NOCCode": "9422",
        "JobTitle": "plastics-moulding machine setter"
    },
    {
        "value": 21524,
        "NOCCode": "9422",
        "JobTitle": "plastic-spreading machine operator"
    },
    {
        "value": 21525,
        "NOCCode": "9422",
        "JobTitle": "plastics-processing machine setter"
    },
    {
        "value": 21526,
        "NOCCode": "9422",
        "JobTitle": "preform machine tender – plastic manufacturing"
    },
    {
        "value": 21527,
        "NOCCode": "9422",
        "JobTitle": "press operator – plastic manufacturing"
    },
    {
        "value": 21528,
        "NOCCode": "9422",
        "JobTitle": "press operator, plastics"
    },
    {
        "value": 21529,
        "NOCCode": "9422",
        "JobTitle": "pressure-sensitive coater operator – plastic manufacturing"
    },
    {
        "value": 21530,
        "NOCCode": "9422",
        "JobTitle": "printing roller moulder – plastic manufacturing"
    },
    {
        "value": 21531,
        "NOCCode": "9422",
        "JobTitle": "production machine operator, plastic bags"
    },
    {
        "value": 21532,
        "NOCCode": "9422",
        "JobTitle": "production machine operator, plastic pipes"
    },
    {
        "value": 21533,
        "NOCCode": "9422",
        "JobTitle": "production worker, plastic pipes"
    },
    {
        "value": 21534,
        "NOCCode": "9422",
        "JobTitle": "profile extrusion operator"
    },
    {
        "value": 21535,
        "NOCCode": "9422",
        "JobTitle": "profile extrusion processing operator"
    },
    {
        "value": 21536,
        "NOCCode": "9422",
        "JobTitle": "profile extrusion set-up operator"
    },
    {
        "value": 21537,
        "NOCCode": "9422",
        "JobTitle": "roll operator – plastic manufacturing"
    },
    {
        "value": 21538,
        "NOCCode": "9422",
        "JobTitle": "rotational moulding operator"
    },
    {
        "value": 21539,
        "NOCCode": "9422",
        "JobTitle": "setter, plastics-moulding machine"
    },
    {
        "value": 21540,
        "NOCCode": "9422",
        "JobTitle": "setter, plastics-processing machine"
    },
    {
        "value": 21541,
        "NOCCode": "9422",
        "JobTitle": "set-up operator, machine – plastic manufacturing"
    },
    {
        "value": 21542,
        "NOCCode": "9422",
        "JobTitle": "sheeter operator – plastic manufacturing"
    },
    {
        "value": 21543,
        "NOCCode": "9422",
        "JobTitle": "shredder operator – plastic recycling"
    },
    {
        "value": 21544,
        "NOCCode": "9422",
        "JobTitle": "shredding machine operator – plastic recycling"
    },
    {
        "value": 21545,
        "NOCCode": "9422",
        "JobTitle": "skin-former machine tender – plastic manufacturing"
    },
    {
        "value": 21546,
        "NOCCode": "9422",
        "JobTitle": "slitter operator – plastic manufacturing"
    },
    {
        "value": 21547,
        "NOCCode": "9422",
        "JobTitle": "solution mixer operator – plastic manufacturing"
    },
    {
        "value": 21548,
        "NOCCode": "9422",
        "JobTitle": "solvent mixer operator – plastic manufacturing"
    },
    {
        "value": 21549,
        "NOCCode": "9422",
        "JobTitle": "sprayer, gel coat operator – plastic manufacturing"
    },
    {
        "value": 21550,
        "NOCCode": "9422",
        "JobTitle": "synthetic marble production worker"
    },
    {
        "value": 21551,
        "NOCCode": "9422",
        "JobTitle": "tender, heat-sealing machine – plastic manufacturing"
    },
    {
        "value": 21552,
        "NOCCode": "9422",
        "JobTitle": "tender, laminating machine – plastic manufacturing"
    },
    {
        "value": 21553,
        "NOCCode": "9422",
        "JobTitle": "tender, plastic coagulating tank"
    },
    {
        "value": 21554,
        "NOCCode": "9422",
        "JobTitle": "tender, preform machine – plastic manufacturing"
    },
    {
        "value": 21555,
        "NOCCode": "9422",
        "JobTitle": "tester, plastic film rolls"
    },
    {
        "value": 21556,
        "NOCCode": "9422",
        "JobTitle": "thermoforming operator"
    },
    {
        "value": 21557,
        "NOCCode": "9422",
        "JobTitle": "vaporizer – plastic manufacturing"
    },
    {
        "value": 21558,
        "NOCCode": "9422",
        "JobTitle": "wash line operator – plastic recycling"
    },
    {
        "value": 21559,
        "NOCCode": "9423",
        "JobTitle": "air brake hose builder – rubber products manufacturing"
    },
    {
        "value": 21560,
        "NOCCode": "9423",
        "JobTitle": "airbag and tube curer – rubber products manufacturing"
    },
    {
        "value": 21561,
        "NOCCode": "9423",
        "JobTitle": "airbag builder – rubber products manufacturing"
    },
    {
        "value": 21562,
        "NOCCode": "9423",
        "JobTitle": "aircraft tire tube builder – rubber products manufacturing"
    },
    {
        "value": 21563,
        "NOCCode": "9423",
        "JobTitle": "arch-cushion-skiving machine operator"
    },
    {
        "value": 21564,
        "NOCCode": "9423",
        "JobTitle": "assembler, rubber goods"
    },
    {
        "value": 21565,
        "NOCCode": "9423",
        "JobTitle": "assembler, rubber sponge gaskets"
    },
    {
        "value": 21566,
        "NOCCode": "9423",
        "JobTitle": "assembly line tire repairer"
    },
    {
        "value": 21567,
        "NOCCode": "9423",
        "JobTitle": "automotive rubber parts cutter"
    },
    {
        "value": 21568,
        "NOCCode": "9423",
        "JobTitle": "back strip machine tender – rubber products manufacturing"
    },
    {
        "value": 21569,
        "NOCCode": "9423",
        "JobTitle": "banbury machine operator – rubber products manufacturing"
    },
    {
        "value": 21570,
        "NOCCode": "9423",
        "JobTitle": "banbury mixer operator – rubber products manufacturing"
    },
    {
        "value": 21571,
        "NOCCode": "9423",
        "JobTitle": "banbury operator – rubber products manufacturing"
    },
    {
        "value": 21572,
        "NOCCode": "9423",
        "JobTitle": "band assembler – rubber products manufacturing"
    },
    {
        "value": 21573,
        "NOCCode": "9423",
        "JobTitle": "band stock preparer – rubber products manufacturing"
    },
    {
        "value": 21574,
        "NOCCode": "9423",
        "JobTitle": "bead-forming machine operator – rubber products manufacturing"
    },
    {
        "value": 21575,
        "NOCCode": "9423",
        "JobTitle": "beading machine operator – rubber products manufacturing"
    },
    {
        "value": 21576,
        "NOCCode": "9423",
        "JobTitle": "belt brander – rubber products manufacturing"
    },
    {
        "value": 21577,
        "NOCCode": "9423",
        "JobTitle": "belt buffer, rubber"
    },
    {
        "value": 21578,
        "NOCCode": "9423",
        "JobTitle": "belt builder, rubber"
    },
    {
        "value": 21579,
        "NOCCode": "9423",
        "JobTitle": "belt maker, rubber"
    },
    {
        "value": 21580,
        "NOCCode": "9423",
        "JobTitle": "bias cutter – rubber products manufacturing"
    },
    {
        "value": 21581,
        "NOCCode": "9423",
        "JobTitle": "bias cutting operator – rubber products manufacturing"
    },
    {
        "value": 21582,
        "NOCCode": "9423",
        "JobTitle": "bladder moulder – rubber products manufacturing"
    },
    {
        "value": 21583,
        "NOCCode": "9423",
        "JobTitle": "blow-out and test operator – rubber products"
    },
    {
        "value": 21584,
        "NOCCode": "9423",
        "JobTitle": "bonder – rubber products manufacturing"
    },
    {
        "value": 21585,
        "NOCCode": "9423",
        "JobTitle": "bonding press tender – rubber products manufacturing"
    },
    {
        "value": 21586,
        "NOCCode": "9423",
        "JobTitle": "boot cutter, rubber"
    },
    {
        "value": 21587,
        "NOCCode": "9423",
        "JobTitle": "boot maker, rubber"
    },
    {
        "value": 21588,
        "NOCCode": "9423",
        "JobTitle": "braider operator – rubber products manufacturing"
    },
    {
        "value": 21589,
        "NOCCode": "9423",
        "JobTitle": "braiding machine tender – rubber products manufacturing"
    },
    {
        "value": 21590,
        "NOCCode": "9423",
        "JobTitle": "buffer, rubber belts"
    },
    {
        "value": 21591,
        "NOCCode": "9423",
        "JobTitle": "builder, rubber drums"
    },
    {
        "value": 21592,
        "NOCCode": "9423",
        "JobTitle": "builder, rubber V-belts"
    },
    {
        "value": 21593,
        "NOCCode": "9423",
        "JobTitle": "calender let-off operator – rubber products manufacturing"
    },
    {
        "value": 21594,
        "NOCCode": "9423",
        "JobTitle": "calender machine operator – rubber products manufacturing"
    },
    {
        "value": 21595,
        "NOCCode": "9423",
        "JobTitle": "calender operator – rubber products manufacturing"
    },
    {
        "value": 21596,
        "NOCCode": "9423",
        "JobTitle": "calender roll warmer – rubber products manufacturing"
    },
    {
        "value": 21597,
        "NOCCode": "9423",
        "JobTitle": "calender wind-up tender – rubber products manufacturing"
    },
    {
        "value": 21598,
        "NOCCode": "9423",
        "JobTitle": "cementer – rubber products manufacturing"
    },
    {
        "value": 21599,
        "NOCCode": "9423",
        "JobTitle": "cementer operator – rubber products manufacturing"
    },
    {
        "value": 21600,
        "NOCCode": "9423",
        "JobTitle": "chafer builder"
    },
    {
        "value": 21601,
        "NOCCode": "9423",
        "JobTitle": "cogger, rubber belts"
    },
    {
        "value": 21602,
        "NOCCode": "9423",
        "JobTitle": "continuous mixer operator – rubber products manufacturing"
    },
    {
        "value": 21603,
        "NOCCode": "9423",
        "JobTitle": "conveyor belt splicer – rubber products manufacturing"
    },
    {
        "value": 21604,
        "NOCCode": "9423",
        "JobTitle": "coverer, rubber V-belts"
    },
    {
        "value": 21605,
        "NOCCode": "9423",
        "JobTitle": "cracker operator – rubber products manufacturing"
    },
    {
        "value": 21606,
        "NOCCode": "9423",
        "JobTitle": "cross head operator – rubber products manufacturing"
    },
    {
        "value": 21607,
        "NOCCode": "9423",
        "JobTitle": "cure operator – rubber products manufacturing"
    },
    {
        "value": 21608,
        "NOCCode": "9423",
        "JobTitle": "cured tire repairer"
    },
    {
        "value": 21609,
        "NOCCode": "9423",
        "JobTitle": "curer, rubber"
    },
    {
        "value": 21610,
        "NOCCode": "9423",
        "JobTitle": "curer, rubber flaps"
    },
    {
        "value": 21611,
        "NOCCode": "9423",
        "JobTitle": "curer, rubber V-belts"
    },
    {
        "value": 21612,
        "NOCCode": "9423",
        "JobTitle": "curing machine operator – rubber products manufacturing"
    },
    {
        "value": 21613,
        "NOCCode": "9423",
        "JobTitle": "curing man/woman – rubber products manufacturing"
    },
    {
        "value": 21614,
        "NOCCode": "9423",
        "JobTitle": "curing press set-up operator – rubber products manufacturing"
    },
    {
        "value": 21615,
        "NOCCode": "9423",
        "JobTitle": "curing press tender – rubber products manufacturing"
    },
    {
        "value": 21616,
        "NOCCode": "9423",
        "JobTitle": "cutter, heels – rubber footwear manufacturing"
    },
    {
        "value": 21617,
        "NOCCode": "9423",
        "JobTitle": "cutter, rubber"
    },
    {
        "value": 21618,
        "NOCCode": "9423",
        "JobTitle": "cutter, rubber boots"
    },
    {
        "value": 21619,
        "NOCCode": "9423",
        "JobTitle": "cutting machine operator, rubber"
    },
    {
        "value": 21620,
        "NOCCode": "9423",
        "JobTitle": "dipping machine tender – rubber products manufacturing"
    },
    {
        "value": 21621,
        "NOCCode": "9423",
        "JobTitle": "drum builder, rubber"
    },
    {
        "value": 21622,
        "NOCCode": "9423",
        "JobTitle": "earth mover tire builder"
    },
    {
        "value": 21623,
        "NOCCode": "9423",
        "JobTitle": "electrical rubber goods manufacturing tester"
    },
    {
        "value": 21624,
        "NOCCode": "9423",
        "JobTitle": "expansion joint builder – rubber products manufacturing"
    },
    {
        "value": 21625,
        "NOCCode": "9423",
        "JobTitle": "expansion joint finisher – rubber products manufacturing"
    },
    {
        "value": 21626,
        "NOCCode": "9423",
        "JobTitle": "experimental tire manufacturing inspector"
    },
    {
        "value": 21627,
        "NOCCode": "9423",
        "JobTitle": "extruder operator – rubber products manufacturing"
    },
    {
        "value": 21628,
        "NOCCode": "9423",
        "JobTitle": "extruding machine operator – rubber products manufacturing"
    },
    {
        "value": 21629,
        "NOCCode": "9423",
        "JobTitle": "extrusion finisher – rubber products manufacturing"
    },
    {
        "value": 21630,
        "NOCCode": "9423",
        "JobTitle": "feeder mill operator – rubber products manufacturing"
    },
    {
        "value": 21631,
        "NOCCode": "9423",
        "JobTitle": "finisher, rubber V-belts"
    },
    {
        "value": 21632,
        "NOCCode": "9423",
        "JobTitle": "fire hose curer rubber"
    },
    {
        "value": 21633,
        "NOCCode": "9423",
        "JobTitle": "fire hose maker, rubber"
    },
    {
        "value": 21634,
        "NOCCode": "9423",
        "JobTitle": "flap curer, rubber"
    },
    {
        "value": 21635,
        "NOCCode": "9423",
        "JobTitle": "flap presser, rubber"
    },
    {
        "value": 21636,
        "NOCCode": "9423",
        "JobTitle": "floor mat press tender – rubber products manufacturing"
    },
    {
        "value": 21637,
        "NOCCode": "9423",
        "JobTitle": "foam rubber goods inspector"
    },
    {
        "value": 21638,
        "NOCCode": "9423",
        "JobTitle": "foam rubber machine operator"
    },
    {
        "value": 21639,
        "NOCCode": "9423",
        "JobTitle": "foam rubber maker"
    },
    {
        "value": 21640,
        "NOCCode": "9423",
        "JobTitle": "foam rubber mattress inspector"
    },
    {
        "value": 21641,
        "NOCCode": "9423",
        "JobTitle": "foam rubber mixer"
    },
    {
        "value": 21642,
        "NOCCode": "9423",
        "JobTitle": "foam rubber press operator"
    },
    {
        "value": 21643,
        "NOCCode": "9423",
        "JobTitle": "folded gasket maker – rubber products manufacturing"
    },
    {
        "value": 21644,
        "NOCCode": "9423",
        "JobTitle": "foxer – rubber goods"
    },
    {
        "value": 21645,
        "NOCCode": "9423",
        "JobTitle": "freeze tumbler tender – rubber products manufacturing"
    },
    {
        "value": 21646,
        "NOCCode": "9423",
        "JobTitle": "fuel tank builder, rubber"
    },
    {
        "value": 21647,
        "NOCCode": "9423",
        "JobTitle": "fuel tank finisher and repairer, rubber"
    },
    {
        "value": 21648,
        "NOCCode": "9423",
        "JobTitle": "fuel tank form builder"
    },
    {
        "value": 21649,
        "NOCCode": "9423",
        "JobTitle": "gang press operator – rubber products manufacturing"
    },
    {
        "value": 21650,
        "NOCCode": "9423",
        "JobTitle": "gasket cutter, rubber"
    },
    {
        "value": 21651,
        "NOCCode": "9423",
        "JobTitle": "gasket finisher, rubber"
    },
    {
        "value": 21652,
        "NOCCode": "9423",
        "JobTitle": "gasket maker, rubber"
    },
    {
        "value": 21653,
        "NOCCode": "9423",
        "JobTitle": "gaskets gluer, rubber"
    },
    {
        "value": 21654,
        "NOCCode": "9423",
        "JobTitle": "glove maker, rubber"
    },
    {
        "value": 21655,
        "NOCCode": "9423",
        "JobTitle": "gluer – rubber products manufacturing"
    },
    {
        "value": 21656,
        "NOCCode": "9423",
        "JobTitle": "gluing machine operator – rubber products manufacturing"
    },
    {
        "value": 21657,
        "NOCCode": "9423",
        "JobTitle": "golf ball assembler"
    },
    {
        "value": 21658,
        "NOCCode": "9423",
        "JobTitle": "golf ball maker"
    },
    {
        "value": 21659,
        "NOCCode": "9423",
        "JobTitle": "golf ball vulcanizer"
    },
    {
        "value": 21660,
        "NOCCode": "9423",
        "JobTitle": "green tire inspector"
    },
    {
        "value": 21661,
        "NOCCode": "9423",
        "JobTitle": "green tire lubricator"
    },
    {
        "value": 21662,
        "NOCCode": "9423",
        "JobTitle": "green tire repairer"
    },
    {
        "value": 21663,
        "NOCCode": "9423",
        "JobTitle": "grinder operator – rubber products manufacturing"
    },
    {
        "value": 21664,
        "NOCCode": "9423",
        "JobTitle": "gum cutter – rubber footwear"
    },
    {
        "value": 21665,
        "NOCCode": "9423",
        "JobTitle": "hand bonder – rubber footwear manufacturing"
    },
    {
        "value": 21666,
        "NOCCode": "9423",
        "JobTitle": "hand hose maker – rubber products manufacturing"
    },
    {
        "value": 21667,
        "NOCCode": "9423",
        "JobTitle": "hand rubber footwear maker"
    },
    {
        "value": 21668,
        "NOCCode": "9423",
        "JobTitle": "heel cutter – rubber footwear"
    },
    {
        "value": 21669,
        "NOCCode": "9423",
        "JobTitle": "heel maker – rubber footwear"
    },
    {
        "value": 21670,
        "NOCCode": "9423",
        "JobTitle": "hooker-laster – rubber products manufacturing"
    },
    {
        "value": 21671,
        "NOCCode": "9423",
        "JobTitle": "hose builder, rubber"
    },
    {
        "value": 21672,
        "NOCCode": "9423",
        "JobTitle": "hose curer – rubber products manufacturing"
    },
    {
        "value": 21673,
        "NOCCode": "9423",
        "JobTitle": "hose cutter and brander operator – rubber products manufacturing"
    },
    {
        "value": 21674,
        "NOCCode": "9423",
        "JobTitle": "hose maker machine operator – rubber products manufacturing"
    },
    {
        "value": 21675,
        "NOCCode": "9423",
        "JobTitle": "hose maker, hand – rubber products manufacturing"
    },
    {
        "value": 21676,
        "NOCCode": "9423",
        "JobTitle": "hose maker, machine – rubber products manufacturing"
    },
    {
        "value": 21677,
        "NOCCode": "9423",
        "JobTitle": "inner tube finisher – rubber products manufacturing"
    },
    {
        "value": 21678,
        "NOCCode": "9423",
        "JobTitle": "inner tube skiver – rubber products manufacturing"
    },
    {
        "value": 21679,
        "NOCCode": "9423",
        "JobTitle": "inner tube sorter – rubber reclaiming"
    },
    {
        "value": 21680,
        "NOCCode": "9423",
        "JobTitle": "inner tube valve builder"
    },
    {
        "value": 21681,
        "NOCCode": "9423",
        "JobTitle": "inner tube vulcanizer tender – rubber products manufacturing"
    },
    {
        "value": 21682,
        "NOCCode": "9423",
        "JobTitle": "inner-tube-processing equipment tender – rubber products manufacturing"
    },
    {
        "value": 21683,
        "NOCCode": "9423",
        "JobTitle": "inspector, rubber products manufacturing"
    },
    {
        "value": 21684,
        "NOCCode": "9423",
        "JobTitle": "inspector-trimmer, moulded rubber products"
    },
    {
        "value": 21685,
        "NOCCode": "9423",
        "JobTitle": "laminating machine operator – rubber products manufacturing"
    },
    {
        "value": 21686,
        "NOCCode": "9423",
        "JobTitle": "laminating machine tender – rubber products manufacturing"
    },
    {
        "value": 21687,
        "NOCCode": "9423",
        "JobTitle": "laster – rubber footwear manufacturing"
    },
    {
        "value": 21688,
        "NOCCode": "9423",
        "JobTitle": "latex ribbon machine operator – rubber products manufacturing"
    },
    {
        "value": 21689,
        "NOCCode": "9423",
        "JobTitle": "latex thread machine operator – rubber products manufacturing"
    },
    {
        "value": 21690,
        "NOCCode": "9423",
        "JobTitle": "liner reroller – rubber products manufacturing"
    },
    {
        "value": 21691,
        "NOCCode": "9423",
        "JobTitle": "loom tender – rubber products manufacturing"
    },
    {
        "value": 21692,
        "NOCCode": "9423",
        "JobTitle": "lube and splice operator – rubber products"
    },
    {
        "value": 21693,
        "NOCCode": "9423",
        "JobTitle": "machine hose cutter and brander – rubber products manufacturing"
    },
    {
        "value": 21694,
        "NOCCode": "9423",
        "JobTitle": "machine hose maker – rubber products manufacturing"
    },
    {
        "value": 21695,
        "NOCCode": "9423",
        "JobTitle": "machine trimmer – rubber products manufacturing"
    },
    {
        "value": 21696,
        "NOCCode": "9423",
        "JobTitle": "mandrel builder – rubber products manufacturing"
    },
    {
        "value": 21697,
        "NOCCode": "9423",
        "JobTitle": "mill operator – rubber processing"
    },
    {
        "value": 21698,
        "NOCCode": "9423",
        "JobTitle": "mill operator – rubber products manufacturing"
    },
    {
        "value": 21699,
        "NOCCode": "9423",
        "JobTitle": "mill tender – rubber products manufacturing"
    },
    {
        "value": 21700,
        "NOCCode": "9423",
        "JobTitle": "millroom operator – rubber products manufacturing"
    },
    {
        "value": 21701,
        "NOCCode": "9423",
        "JobTitle": "mixer operator – rubber processing"
    },
    {
        "value": 21702,
        "NOCCode": "9423",
        "JobTitle": "mixer operator – rubber products manufacturing"
    },
    {
        "value": 21703,
        "NOCCode": "9423",
        "JobTitle": "mixer, foam rubber"
    },
    {
        "value": 21704,
        "NOCCode": "9423",
        "JobTitle": "mixing and grinding machine tender – rubber products manufacturing"
    },
    {
        "value": 21705,
        "NOCCode": "9423",
        "JobTitle": "mould machine operator – rubber products manufacturing"
    },
    {
        "value": 21706,
        "NOCCode": "9423",
        "JobTitle": "moulder – rubber products manufacturing"
    },
    {
        "value": 21707,
        "NOCCode": "9423",
        "JobTitle": "moulding press tender – rubber products manufacturing"
    },
    {
        "value": 21708,
        "NOCCode": "9423",
        "JobTitle": "mouldman/woman – rubber products manufacturing"
    },
    {
        "value": 21709,
        "NOCCode": "9423",
        "JobTitle": "multiroll calender operator – rubber products manufacturing"
    },
    {
        "value": 21710,
        "NOCCode": "9423",
        "JobTitle": "operator, rubber-cutting machine"
    },
    {
        "value": 21711,
        "NOCCode": "9423",
        "JobTitle": "padded products repairer"
    },
    {
        "value": 21712,
        "NOCCode": "9423",
        "JobTitle": "ply assembler – rubber"
    },
    {
        "value": 21713,
        "NOCCode": "9423",
        "JobTitle": "ply splicer – rubber products manufacturing"
    },
    {
        "value": 21714,
        "NOCCode": "9423",
        "JobTitle": "polisher, rubber products"
    },
    {
        "value": 21715,
        "NOCCode": "9423",
        "JobTitle": "press line operator – rubber products manufacturing"
    },
    {
        "value": 21716,
        "NOCCode": "9423",
        "JobTitle": "press operator – rubber products manufacturing"
    },
    {
        "value": 21717,
        "NOCCode": "9423",
        "JobTitle": "presser – rubber products manufacturing"
    },
    {
        "value": 21718,
        "NOCCode": "9423",
        "JobTitle": "presser, rubber flaps"
    },
    {
        "value": 21719,
        "NOCCode": "9423",
        "JobTitle": "pressman/woman, moulding – rubber products manufacturing"
    },
    {
        "value": 21720,
        "NOCCode": "9423",
        "JobTitle": "production operator – rubber products manufacturing"
    },
    {
        "value": 21721,
        "NOCCode": "9423",
        "JobTitle": "puller-laster – rubber products manufacturing"
    },
    {
        "value": 21722,
        "NOCCode": "9423",
        "JobTitle": "punch press operator – rubber products manufacturing"
    },
    {
        "value": 21723,
        "NOCCode": "9423",
        "JobTitle": "radial tire builder"
    },
    {
        "value": 21724,
        "NOCCode": "9423",
        "JobTitle": "reclaim millman/woman – rubber products manufacturing"
    },
    {
        "value": 21725,
        "NOCCode": "9423",
        "JobTitle": "reclaimed rubber inspector"
    },
    {
        "value": 21726,
        "NOCCode": "9423",
        "JobTitle": "refiner mill tender – rubber products manufacturing"
    },
    {
        "value": 21727,
        "NOCCode": "9423",
        "JobTitle": "roll builder – rubber products manufacturing"
    },
    {
        "value": 21728,
        "NOCCode": "9423",
        "JobTitle": "roll coverer – rubber products manufacturing"
    },
    {
        "value": 21729,
        "NOCCode": "9423",
        "JobTitle": "roll grinder – rubber products manufacturing"
    },
    {
        "value": 21730,
        "NOCCode": "9423",
        "JobTitle": "roll operator – rubber products manufacturing"
    },
    {
        "value": 21731,
        "NOCCode": "9423",
        "JobTitle": "roller repairer – rubber products manufacturing"
    },
    {
        "value": 21732,
        "NOCCode": "9423",
        "JobTitle": "roller repairman/woman – rubber products manufacturing"
    },
    {
        "value": 21733,
        "NOCCode": "9423",
        "JobTitle": "rubber band cutter"
    },
    {
        "value": 21734,
        "NOCCode": "9423",
        "JobTitle": "rubber belt buffer"
    },
    {
        "value": 21735,
        "NOCCode": "9423",
        "JobTitle": "rubber belt maker"
    },
    {
        "value": 21736,
        "NOCCode": "9423",
        "JobTitle": "rubber boot cutter"
    },
    {
        "value": 21737,
        "NOCCode": "9423",
        "JobTitle": "rubber boot maker"
    },
    {
        "value": 21738,
        "NOCCode": "9423",
        "JobTitle": "rubber boot maker, moulded"
    },
    {
        "value": 21739,
        "NOCCode": "9423",
        "JobTitle": "rubber cement mixer"
    },
    {
        "value": 21740,
        "NOCCode": "9423",
        "JobTitle": "rubber closure installer"
    },
    {
        "value": 21741,
        "NOCCode": "9423",
        "JobTitle": "rubber coater"
    },
    {
        "value": 21742,
        "NOCCode": "9423",
        "JobTitle": "rubber cord assembler"
    },
    {
        "value": 21743,
        "NOCCode": "9423",
        "JobTitle": "rubber cord cutter"
    },
    {
        "value": 21744,
        "NOCCode": "9423",
        "JobTitle": "rubber cord splicer"
    },
    {
        "value": 21745,
        "NOCCode": "9423",
        "JobTitle": "rubber curer – rubber products manufacturing"
    },
    {
        "value": 21746,
        "NOCCode": "9423",
        "JobTitle": "rubber cushion inspector"
    },
    {
        "value": 21747,
        "NOCCode": "9423",
        "JobTitle": "rubber cutter"
    },
    {
        "value": 21748,
        "NOCCode": "9423",
        "JobTitle": "rubber foam cushion fabricator"
    },
    {
        "value": 21749,
        "NOCCode": "9423",
        "JobTitle": "rubber footwear maker"
    },
    {
        "value": 21750,
        "NOCCode": "9423",
        "JobTitle": "rubber footwear maker, hand"
    },
    {
        "value": 21751,
        "NOCCode": "9423",
        "JobTitle": "rubber footwear parts buffer"
    },
    {
        "value": 21752,
        "NOCCode": "9423",
        "JobTitle": "rubber footwear repairer"
    },
    {
        "value": 21753,
        "NOCCode": "9423",
        "JobTitle": "rubber footwear tread machine tender"
    },
    {
        "value": 21754,
        "NOCCode": "9423",
        "JobTitle": "rubber gasket gluer"
    },
    {
        "value": 21755,
        "NOCCode": "9423",
        "JobTitle": "rubber gear moulder"
    },
    {
        "value": 21756,
        "NOCCode": "9423",
        "JobTitle": "rubber glove maker"
    },
    {
        "value": 21757,
        "NOCCode": "9423",
        "JobTitle": "rubber golf ball cover treater"
    },
    {
        "value": 21758,
        "NOCCode": "9423",
        "JobTitle": "rubber goods assembler"
    },
    {
        "value": 21759,
        "NOCCode": "9423",
        "JobTitle": "rubber goods cutter-finisher"
    },
    {
        "value": 21760,
        "NOCCode": "9423",
        "JobTitle": "rubber goods inspector"
    },
    {
        "value": 21761,
        "NOCCode": "9423",
        "JobTitle": "rubber goods machine operator"
    },
    {
        "value": 21762,
        "NOCCode": "9423",
        "JobTitle": "rubber goods press operator"
    },
    {
        "value": 21763,
        "NOCCode": "9423",
        "JobTitle": "rubber goods repairer"
    },
    {
        "value": 21764,
        "NOCCode": "9423",
        "JobTitle": "rubber grinder"
    },
    {
        "value": 21765,
        "NOCCode": "9423",
        "JobTitle": "rubber hose builder"
    },
    {
        "value": 21766,
        "NOCCode": "9423",
        "JobTitle": "rubber lining installer"
    },
    {
        "value": 21767,
        "NOCCode": "9423",
        "JobTitle": "rubber mill operator"
    },
    {
        "value": 21768,
        "NOCCode": "9423",
        "JobTitle": "rubber moulder operator – rubber products manufacturing"
    },
    {
        "value": 21769,
        "NOCCode": "9423",
        "JobTitle": "rubber press operator"
    },
    {
        "value": 21770,
        "NOCCode": "9423",
        "JobTitle": "rubber processing machine operator"
    },
    {
        "value": 21771,
        "NOCCode": "9423",
        "JobTitle": "rubber products assembler"
    },
    {
        "value": 21772,
        "NOCCode": "9423",
        "JobTitle": "rubber products inspector"
    },
    {
        "value": 21773,
        "NOCCode": "9423",
        "JobTitle": "rubber products polisher"
    },
    {
        "value": 21774,
        "NOCCode": "9423",
        "JobTitle": "rubber products tester"
    },
    {
        "value": 21775,
        "NOCCode": "9423",
        "JobTitle": "rubber products washer-dryer"
    },
    {
        "value": 21776,
        "NOCCode": "9423",
        "JobTitle": "rubber roll grinding inspector"
    },
    {
        "value": 21777,
        "NOCCode": "9423",
        "JobTitle": "rubber shoe cementer"
    },
    {
        "value": 21778,
        "NOCCode": "9423",
        "JobTitle": "rubber shoe strap maker"
    },
    {
        "value": 21779,
        "NOCCode": "9423",
        "JobTitle": "rubber slab inspector, rubber"
    },
    {
        "value": 21780,
        "NOCCode": "9423",
        "JobTitle": "rubber snowtrack builder"
    },
    {
        "value": 21781,
        "NOCCode": "9423",
        "JobTitle": "rubber splitter – rubber products manufacturing"
    },
    {
        "value": 21782,
        "NOCCode": "9423",
        "JobTitle": "rubber squeegee splicer"
    },
    {
        "value": 21783,
        "NOCCode": "9423",
        "JobTitle": "rubber stamp maker"
    },
    {
        "value": 21784,
        "NOCCode": "9423",
        "JobTitle": "rubber stripper and applier"
    },
    {
        "value": 21785,
        "NOCCode": "9423",
        "JobTitle": "rubber trimmer"
    },
    {
        "value": 21786,
        "NOCCode": "9423",
        "JobTitle": "rubber tube vulcanizer tender"
    },
    {
        "value": 21787,
        "NOCCode": "9423",
        "JobTitle": "rubber tubing splicer"
    },
    {
        "value": 21788,
        "NOCCode": "9423",
        "JobTitle": "rubber-strap-folding machine tender"
    },
    {
        "value": 21789,
        "NOCCode": "9423",
        "JobTitle": "rug underlay machine operator – rubber products manufacturing"
    },
    {
        "value": 21790,
        "NOCCode": "9423",
        "JobTitle": "scrap rubber grinder – recycling"
    },
    {
        "value": 21791,
        "NOCCode": "9423",
        "JobTitle": "sheet rubber inspector"
    },
    {
        "value": 21792,
        "NOCCode": "9423",
        "JobTitle": "sheeter – rubber products manufacturing"
    },
    {
        "value": 21793,
        "NOCCode": "9423",
        "JobTitle": "skiver – rubber products manufacturing"
    },
    {
        "value": 21794,
        "NOCCode": "9423",
        "JobTitle": "skiver, rubber V-belts"
    },
    {
        "value": 21795,
        "NOCCode": "9423",
        "JobTitle": "slab machine operator – rubber products manufacturing"
    },
    {
        "value": 21796,
        "NOCCode": "9423",
        "JobTitle": "slitter operator – rubber products manufacturing"
    },
    {
        "value": 21797,
        "NOCCode": "9423",
        "JobTitle": "softball centre moulder"
    },
    {
        "value": 21798,
        "NOCCode": "9423",
        "JobTitle": "sole maker, rubber"
    },
    {
        "value": 21799,
        "NOCCode": "9423",
        "JobTitle": "sole-treading machine tender – rubber footwear manufacturing"
    },
    {
        "value": 21800,
        "NOCCode": "9423",
        "JobTitle": "spiral wound gasket maker – rubber products manufacturing"
    },
    {
        "value": 21801,
        "NOCCode": "9423",
        "JobTitle": "splicer – rubber products manufacturing"
    },
    {
        "value": 21802,
        "NOCCode": "9423",
        "JobTitle": "splicer, rubber"
    },
    {
        "value": 21803,
        "NOCCode": "9423",
        "JobTitle": "splicing machine tender – rubber products manufacturing"
    },
    {
        "value": 21804,
        "NOCCode": "9423",
        "JobTitle": "splitting machine tender – rubber products manufacturing"
    },
    {
        "value": 21805,
        "NOCCode": "9423",
        "JobTitle": "sponge rubber goods inspector"
    },
    {
        "value": 21806,
        "NOCCode": "9423",
        "JobTitle": "spooler-twister operator – rubber products manufacturing"
    },
    {
        "value": 21807,
        "NOCCode": "9423",
        "JobTitle": "spreader machine tender – rubber products manufacturing"
    },
    {
        "value": 21808,
        "NOCCode": "9423",
        "JobTitle": "spreader operator – rubber products manufacturing"
    },
    {
        "value": 21809,
        "NOCCode": "9423",
        "JobTitle": "spreader tender – rubber products manufacturing"
    },
    {
        "value": 21810,
        "NOCCode": "9423",
        "JobTitle": "squeegee splicer, rubber"
    },
    {
        "value": 21811,
        "NOCCode": "9423",
        "JobTitle": "strainer tender – rubber products manufacturing"
    },
    {
        "value": 21812,
        "NOCCode": "9423",
        "JobTitle": "strap machine tender – rubber products manufacturing"
    },
    {
        "value": 21813,
        "NOCCode": "9423",
        "JobTitle": "strap maker, rubber shoes"
    },
    {
        "value": 21814,
        "NOCCode": "9423",
        "JobTitle": "strip machine operator – rubber products manufacturing"
    },
    {
        "value": 21815,
        "NOCCode": "9423",
        "JobTitle": "tank liner – rubber products manufacturing"
    },
    {
        "value": 21816,
        "NOCCode": "9423",
        "JobTitle": "tire assembler"
    },
    {
        "value": 21817,
        "NOCCode": "9423",
        "JobTitle": "tire bagger"
    },
    {
        "value": 21818,
        "NOCCode": "9423",
        "JobTitle": "tire breaker operator"
    },
    {
        "value": 21819,
        "NOCCode": "9423",
        "JobTitle": "tire buffer"
    },
    {
        "value": 21820,
        "NOCCode": "9423",
        "JobTitle": "tire builder"
    },
    {
        "value": 21821,
        "NOCCode": "9423",
        "JobTitle": "tire classifier"
    },
    {
        "value": 21822,
        "NOCCode": "9423",
        "JobTitle": "tire coverer"
    },
    {
        "value": 21823,
        "NOCCode": "9423",
        "JobTitle": "tire curer"
    },
    {
        "value": 21824,
        "NOCCode": "9423",
        "JobTitle": "tire finisher"
    },
    {
        "value": 21825,
        "NOCCode": "9423",
        "JobTitle": "tire inspector"
    },
    {
        "value": 21826,
        "NOCCode": "9423",
        "JobTitle": "tire maker"
    },
    {
        "value": 21827,
        "NOCCode": "9423",
        "JobTitle": "tire rebuilder"
    },
    {
        "value": 21828,
        "NOCCode": "9423",
        "JobTitle": "tire recapper"
    },
    {
        "value": 21829,
        "NOCCode": "9423",
        "JobTitle": "tire repairer, assembly line"
    },
    {
        "value": 21830,
        "NOCCode": "9423",
        "JobTitle": "tire repairer-vulcanizer"
    },
    {
        "value": 21831,
        "NOCCode": "9423",
        "JobTitle": "tire retreader"
    },
    {
        "value": 21832,
        "NOCCode": "9423",
        "JobTitle": "tire retreading inspector"
    },
    {
        "value": 21833,
        "NOCCode": "9423",
        "JobTitle": "tire touch-up and buff worker"
    },
    {
        "value": 21834,
        "NOCCode": "9423",
        "JobTitle": "tire tread moulder"
    },
    {
        "value": 21835,
        "NOCCode": "9423",
        "JobTitle": "tire trimmer, hand"
    },
    {
        "value": 21836,
        "NOCCode": "9423",
        "JobTitle": "tire valve worker"
    },
    {
        "value": 21837,
        "NOCCode": "9423",
        "JobTitle": "tire vulcanizer"
    },
    {
        "value": 21838,
        "NOCCode": "9423",
        "JobTitle": "tire-bagging machine tender"
    },
    {
        "value": 21839,
        "NOCCode": "9423",
        "JobTitle": "tire-retreading machine tender"
    },
    {
        "value": 21840,
        "NOCCode": "9423",
        "JobTitle": "traction belt builder – rubber products manufacturing"
    },
    {
        "value": 21841,
        "NOCCode": "9423",
        "JobTitle": "Transmix operator – rubber products manufacturing"
    },
    {
        "value": 21842,
        "NOCCode": "9423",
        "JobTitle": "tread cutter machine operator – rubber products manufacturing"
    },
    {
        "value": 21843,
        "NOCCode": "9423",
        "JobTitle": "tread cutter, truck tires"
    },
    {
        "value": 21844,
        "NOCCode": "9423",
        "JobTitle": "tread-cutting machine operator – rubber products manufacturing"
    },
    {
        "value": 21845,
        "NOCCode": "9423",
        "JobTitle": "trim lathe operator – rubber products manufacturing"
    },
    {
        "value": 21846,
        "NOCCode": "9423",
        "JobTitle": "trimmer operator – rubber products manufacturing"
    },
    {
        "value": 21847,
        "NOCCode": "9423",
        "JobTitle": "trimmer, rubber products"
    },
    {
        "value": 21848,
        "NOCCode": "9423",
        "JobTitle": "truck tire builder"
    },
    {
        "value": 21849,
        "NOCCode": "9423",
        "JobTitle": "tube finisher – rubber products manufacturing"
    },
    {
        "value": 21850,
        "NOCCode": "9423",
        "JobTitle": "tube skiver – rubber"
    },
    {
        "value": 21851,
        "NOCCode": "9423",
        "JobTitle": "tube sorter – rubber reclaiming"
    },
    {
        "value": 21852,
        "NOCCode": "9423",
        "JobTitle": "tube vulcanizer tender – rubber products manufacturing"
    },
    {
        "value": 21853,
        "NOCCode": "9423",
        "JobTitle": "tuber operator – rubber products manufacturing"
    },
    {
        "value": 21854,
        "NOCCode": "9423",
        "JobTitle": "tumbler operator – rubber products manufacturing"
    },
    {
        "value": 21855,
        "NOCCode": "9423",
        "JobTitle": "tumbler tender – rubber products manufacturing"
    },
    {
        "value": 21856,
        "NOCCode": "9423",
        "JobTitle": "uncured tire inspector"
    },
    {
        "value": 21857,
        "NOCCode": "9423",
        "JobTitle": "uncured tire lubricator"
    },
    {
        "value": 21858,
        "NOCCode": "9423",
        "JobTitle": "uncured tire repairer"
    },
    {
        "value": 21859,
        "NOCCode": "9423",
        "JobTitle": "valve buffer – rubber products manufacturing"
    },
    {
        "value": 21860,
        "NOCCode": "9423",
        "JobTitle": "V-belt builder, rubber"
    },
    {
        "value": 21861,
        "NOCCode": "9423",
        "JobTitle": "V-belt coverer, rubber"
    },
    {
        "value": 21862,
        "NOCCode": "9423",
        "JobTitle": "V-belt curer, rubber"
    },
    {
        "value": 21863,
        "NOCCode": "9423",
        "JobTitle": "V-belt finisher, rubber"
    },
    {
        "value": 21864,
        "NOCCode": "9423",
        "JobTitle": "V-belt skiver, rubber"
    },
    {
        "value": 21865,
        "NOCCode": "9423",
        "JobTitle": "vulcanizer – rubber products manufacturing"
    },
    {
        "value": 21866,
        "NOCCode": "9423",
        "JobTitle": "vulcanizer tender, rubber tube"
    },
    {
        "value": 21867,
        "NOCCode": "9423",
        "JobTitle": "washer-dryer, rubber products"
    },
    {
        "value": 21868,
        "NOCCode": "9423",
        "JobTitle": "wrapper operator – rubber products"
    },
    {
        "value": 21869,
        "NOCCode": "9431",
        "JobTitle": "block splitter – sawmill"
    },
    {
        "value": 21870,
        "NOCCode": "9431",
        "JobTitle": "board sawyer – sawmill"
    },
    {
        "value": 21871,
        "NOCCode": "9431",
        "JobTitle": "bolt sawyer – sawmill"
    },
    {
        "value": 21872,
        "NOCCode": "9431",
        "JobTitle": "bolterman/woman – sawmill"
    },
    {
        "value": 21873,
        "NOCCode": "9431",
        "JobTitle": "canter operator – sawmill"
    },
    {
        "value": 21874,
        "NOCCode": "9431",
        "JobTitle": "chip reclaim operator – sawmill"
    },
    {
        "value": 21875,
        "NOCCode": "9431",
        "JobTitle": "Chip-N-Saw operator – sawmill"
    },
    {
        "value": 21876,
        "NOCCode": "9431",
        "JobTitle": "circular saw operator"
    },
    {
        "value": 21877,
        "NOCCode": "9431",
        "JobTitle": "circular saw operator – sawmill"
    },
    {
        "value": 21878,
        "NOCCode": "9431",
        "JobTitle": "cut-off saw operator – sawmill"
    },
    {
        "value": 21879,
        "NOCCode": "9431",
        "JobTitle": "cut-off sawyer, timber – sawmill"
    },
    {
        "value": 21880,
        "NOCCode": "9431",
        "JobTitle": "cutter, shingles – sawmill"
    },
    {
        "value": 21881,
        "NOCCode": "9431",
        "JobTitle": "drag saw operator – sawmill"
    },
    {
        "value": 21882,
        "NOCCode": "9431",
        "JobTitle": "edger – sawmill"
    },
    {
        "value": 21883,
        "NOCCode": "9431",
        "JobTitle": "edger operator, automatic – sawmill"
    },
    {
        "value": 21884,
        "NOCCode": "9431",
        "JobTitle": "edgerman/woman"
    },
    {
        "value": 21885,
        "NOCCode": "9431",
        "JobTitle": "edgerman/woman – sawmill"
    },
    {
        "value": 21886,
        "NOCCode": "9431",
        "JobTitle": "gang sawyer – sawmill"
    },
    {
        "value": 21887,
        "NOCCode": "9431",
        "JobTitle": "gang sawyer, logs – sawmill"
    },
    {
        "value": 21888,
        "NOCCode": "9431",
        "JobTitle": "head sawyer"
    },
    {
        "value": 21889,
        "NOCCode": "9431",
        "JobTitle": "head sawyer – sawmill"
    },
    {
        "value": 21890,
        "NOCCode": "9431",
        "JobTitle": "headrig operator – sawmill"
    },
    {
        "value": 21891,
        "NOCCode": "9431",
        "JobTitle": "head-saw operator – sawmill"
    },
    {
        "value": 21892,
        "NOCCode": "9431",
        "JobTitle": "high-speed stud operator – sawmill"
    },
    {
        "value": 21893,
        "NOCCode": "9431",
        "JobTitle": "jumbo operator – sawmill"
    },
    {
        "value": 21894,
        "NOCCode": "9431",
        "JobTitle": "log cut-off operator"
    },
    {
        "value": 21895,
        "NOCCode": "9431",
        "JobTitle": "log cut-off operator – sawmill"
    },
    {
        "value": 21896,
        "NOCCode": "9431",
        "JobTitle": "log cut-off sawyer – sawmill"
    },
    {
        "value": 21897,
        "NOCCode": "9431",
        "JobTitle": "log gang saw operator – sawmill"
    },
    {
        "value": 21898,
        "NOCCode": "9431",
        "JobTitle": "log gang sawyer – sawmill"
    },
    {
        "value": 21899,
        "NOCCode": "9431",
        "JobTitle": "log haul cut-off saw operator – sawmill"
    },
    {
        "value": 21900,
        "NOCCode": "9431",
        "JobTitle": "log hoist operator – sawmill"
    },
    {
        "value": 21901,
        "NOCCode": "9431",
        "JobTitle": "lumber sizer operator – sawmill"
    },
    {
        "value": 21902,
        "NOCCode": "9431",
        "JobTitle": "machine splitter – sawmill"
    },
    {
        "value": 21903,
        "NOCCode": "9431",
        "JobTitle": "operator, automatic edger – sawmill"
    },
    {
        "value": 21904,
        "NOCCode": "9431",
        "JobTitle": "operator, head saw – sawmill"
    },
    {
        "value": 21905,
        "NOCCode": "9431",
        "JobTitle": "operator, log cut-off – sawmill"
    },
    {
        "value": 21906,
        "NOCCode": "9431",
        "JobTitle": "operator, resaw – sawmill"
    },
    {
        "value": 21907,
        "NOCCode": "9431",
        "JobTitle": "operator, splitter – sawmill"
    },
    {
        "value": 21908,
        "NOCCode": "9431",
        "JobTitle": "operator, trimmer – sawmill"
    },
    {
        "value": 21909,
        "NOCCode": "9431",
        "JobTitle": "planer – sawmill"
    },
    {
        "value": 21910,
        "NOCCode": "9431",
        "JobTitle": "planer mill machine operator"
    },
    {
        "value": 21911,
        "NOCCode": "9431",
        "JobTitle": "planer operator"
    },
    {
        "value": 21912,
        "NOCCode": "9431",
        "JobTitle": "planer operator – sawmill"
    },
    {
        "value": 21913,
        "NOCCode": "9431",
        "JobTitle": "planerman/woman – sawmill"
    },
    {
        "value": 21914,
        "NOCCode": "9431",
        "JobTitle": "resaw operator"
    },
    {
        "value": 21915,
        "NOCCode": "9431",
        "JobTitle": "resaw operator – sawmill"
    },
    {
        "value": 21916,
        "NOCCode": "9431",
        "JobTitle": "resawyer – sawmill"
    },
    {
        "value": 21917,
        "NOCCode": "9431",
        "JobTitle": "ripsaw operator – sawmill"
    },
    {
        "value": 21918,
        "NOCCode": "9431",
        "JobTitle": "saw operator – sawmill"
    },
    {
        "value": 21919,
        "NOCCode": "9431",
        "JobTitle": "sawdust reclaim operator – sawmill"
    },
    {
        "value": 21920,
        "NOCCode": "9431",
        "JobTitle": "sawing machine operator – sawmill"
    },
    {
        "value": 21921,
        "NOCCode": "9431",
        "JobTitle": "sawmill machine operator"
    },
    {
        "value": 21922,
        "NOCCode": "9431",
        "JobTitle": "sawyer – sawmill"
    },
    {
        "value": 21923,
        "NOCCode": "9431",
        "JobTitle": "sawyer, boards – sawmill"
    },
    {
        "value": 21924,
        "NOCCode": "9431",
        "JobTitle": "sawyer, shakes – sawmill"
    },
    {
        "value": 21925,
        "NOCCode": "9431",
        "JobTitle": "sawyer, stave bolts – sawmill"
    },
    {
        "value": 21926,
        "NOCCode": "9431",
        "JobTitle": "scrag-saw operator – sawmill"
    },
    {
        "value": 21927,
        "NOCCode": "9431",
        "JobTitle": "shake maker"
    },
    {
        "value": 21928,
        "NOCCode": "9431",
        "JobTitle": "shake sawyer"
    },
    {
        "value": 21929,
        "NOCCode": "9431",
        "JobTitle": "shake splitter"
    },
    {
        "value": 21930,
        "NOCCode": "9431",
        "JobTitle": "shingle cutter"
    },
    {
        "value": 21931,
        "NOCCode": "9431",
        "JobTitle": "shingle maker"
    },
    {
        "value": 21932,
        "NOCCode": "9431",
        "JobTitle": "shingle sawyer"
    },
    {
        "value": 21933,
        "NOCCode": "9431",
        "JobTitle": "slab saw operator"
    },
    {
        "value": 21934,
        "NOCCode": "9431",
        "JobTitle": "slasher operator – sawmill"
    },
    {
        "value": 21935,
        "NOCCode": "9431",
        "JobTitle": "slasher saw operator – sawmill"
    },
    {
        "value": 21936,
        "NOCCode": "9431",
        "JobTitle": "slasher trim operator – sawmill"
    },
    {
        "value": 21937,
        "NOCCode": "9431",
        "JobTitle": "splitter – sawmill"
    },
    {
        "value": 21938,
        "NOCCode": "9431",
        "JobTitle": "splitter operator – sawmill"
    },
    {
        "value": 21939,
        "NOCCode": "9431",
        "JobTitle": "splitter, machine – sawmill"
    },
    {
        "value": 21940,
        "NOCCode": "9431",
        "JobTitle": "stave bolt saw operator"
    },
    {
        "value": 21941,
        "NOCCode": "9431",
        "JobTitle": "stave bolt sawyer – sawmill"
    },
    {
        "value": 21942,
        "NOCCode": "9431",
        "JobTitle": "tender, trimmer – sawmill"
    },
    {
        "value": 21943,
        "NOCCode": "9431",
        "JobTitle": "tender, trimming machine – sawmill"
    },
    {
        "value": 21944,
        "NOCCode": "9431",
        "JobTitle": "tilt-hoist operator – sawmill"
    },
    {
        "value": 21945,
        "NOCCode": "9431",
        "JobTitle": "timber cut-off sawyer – sawmill"
    },
    {
        "value": 21946,
        "NOCCode": "9431",
        "JobTitle": "transfer operator – sawmill"
    },
    {
        "value": 21947,
        "NOCCode": "9431",
        "JobTitle": "trim saw operator – sawmill"
    },
    {
        "value": 21948,
        "NOCCode": "9431",
        "JobTitle": "trim sawyer – sawmill"
    },
    {
        "value": 21949,
        "NOCCode": "9431",
        "JobTitle": "trimmer operator – sawmill"
    },
    {
        "value": 21950,
        "NOCCode": "9431",
        "JobTitle": "trimmer tender – sawmill"
    },
    {
        "value": 21951,
        "NOCCode": "9431",
        "JobTitle": "trimmerman/woman"
    },
    {
        "value": 21952,
        "NOCCode": "9431",
        "JobTitle": "trimmerman/woman – sawmill"
    },
    {
        "value": 21953,
        "NOCCode": "9431",
        "JobTitle": "trimming machine operator – sawmill"
    },
    {
        "value": 21954,
        "NOCCode": "9431",
        "JobTitle": "trimming machine tender – sawmill"
    },
    {
        "value": 21955,
        "NOCCode": "9432",
        "JobTitle": "acid maker – pulp and paper"
    },
    {
        "value": 21956,
        "NOCCode": "9432",
        "JobTitle": "assistant bleacher operator – pulp and paper"
    },
    {
        "value": 21957,
        "NOCCode": "9432",
        "JobTitle": "assistant digester operator"
    },
    {
        "value": 21958,
        "NOCCode": "9432",
        "JobTitle": "assistant digester operator – pulp and paper"
    },
    {
        "value": 21959,
        "NOCCode": "9432",
        "JobTitle": "baler – pulp and paper"
    },
    {
        "value": 21960,
        "NOCCode": "9432",
        "JobTitle": "batch digester operator"
    },
    {
        "value": 21961,
        "NOCCode": "9432",
        "JobTitle": "batch process cook – pulp and paper"
    },
    {
        "value": 21962,
        "NOCCode": "9432",
        "JobTitle": "beater operator – pulp and paper"
    },
    {
        "value": 21963,
        "NOCCode": "9432",
        "JobTitle": "bleach boiler tender"
    },
    {
        "value": 21964,
        "NOCCode": "9432",
        "JobTitle": "bleach field operator – pulp and paper"
    },
    {
        "value": 21965,
        "NOCCode": "9432",
        "JobTitle": "bleach liquor maker, pulp"
    },
    {
        "value": 21966,
        "NOCCode": "9432",
        "JobTitle": "bleach stock preparation operator – pulp and paper"
    },
    {
        "value": 21967,
        "NOCCode": "9432",
        "JobTitle": "bleacher operator – pulp and paper"
    },
    {
        "value": 21968,
        "NOCCode": "9432",
        "JobTitle": "bleacher tender – pulp and paper"
    },
    {
        "value": 21969,
        "NOCCode": "9432",
        "JobTitle": "bleacher, rag stock"
    },
    {
        "value": 21970,
        "NOCCode": "9432",
        "JobTitle": "blow pit tender – pulp and paper"
    },
    {
        "value": 21971,
        "NOCCode": "9432",
        "JobTitle": "breaker – pulp and paper"
    },
    {
        "value": 21972,
        "NOCCode": "9432",
        "JobTitle": "breaker tender – pulp and paper"
    },
    {
        "value": 21973,
        "NOCCode": "9432",
        "JobTitle": "breakerman/woman – pulp and paper"
    },
    {
        "value": 21974,
        "NOCCode": "9432",
        "JobTitle": "broke beater tender – pulp and paper"
    },
    {
        "value": 21975,
        "NOCCode": "9432",
        "JobTitle": "brown stock operator – pulp and paper"
    },
    {
        "value": 21976,
        "NOCCode": "9432",
        "JobTitle": "brown stock washer – pulp and paper"
    },
    {
        "value": 21977,
        "NOCCode": "9432",
        "JobTitle": "caustic plant operator"
    },
    {
        "value": 21978,
        "NOCCode": "9432",
        "JobTitle": "causticizer operator"
    },
    {
        "value": 21979,
        "NOCCode": "9432",
        "JobTitle": "causticizing operator – pulp and paper"
    },
    {
        "value": 21980,
        "NOCCode": "9432",
        "JobTitle": "centri-cleaner tender – pulp and paper"
    },
    {
        "value": 21981,
        "NOCCode": "9432",
        "JobTitle": "chemical liquor preparation operator – pulp and paper"
    },
    {
        "value": 21982,
        "NOCCode": "9432",
        "JobTitle": "chemical pulping plant operator – pulp and paper"
    },
    {
        "value": 21983,
        "NOCCode": "9432",
        "JobTitle": "chemical solutions operator – pulp and paper"
    },
    {
        "value": 21984,
        "NOCCode": "9432",
        "JobTitle": "chemi-thermomechanical pulp (CTMP) machine assistant operator"
    },
    {
        "value": 21985,
        "NOCCode": "9432",
        "JobTitle": "chemi-thermomechanical pulp (CTMP) operator"
    },
    {
        "value": 21986,
        "NOCCode": "9432",
        "JobTitle": "chemi-wash operator – pulp and paper"
    },
    {
        "value": 21987,
        "NOCCode": "9432",
        "JobTitle": "continuous digester operator – pulp and paper"
    },
    {
        "value": 21988,
        "NOCCode": "9432",
        "JobTitle": "continuous process cook – pulp and paper"
    },
    {
        "value": 21989,
        "NOCCode": "9432",
        "JobTitle": "cook – pulp and paper"
    },
    {
        "value": 21990,
        "NOCCode": "9432",
        "JobTitle": "cook's first helper – pulp and paper"
    },
    {
        "value": 21991,
        "NOCCode": "9432",
        "JobTitle": "CTMP (chemi-thermomechanical pulp) machine assistant operator"
    },
    {
        "value": 21992,
        "NOCCode": "9432",
        "JobTitle": "CTMP (chemi-thermomechanical pulp) operator"
    },
    {
        "value": 21993,
        "NOCCode": "9432",
        "JobTitle": "decker tender – pulp and paper"
    },
    {
        "value": 21994,
        "NOCCode": "9432",
        "JobTitle": "deckerman/woman – pulp and paper"
    },
    {
        "value": 21995,
        "NOCCode": "9432",
        "JobTitle": "digester cook – pulp and paper"
    },
    {
        "value": 21996,
        "NOCCode": "9432",
        "JobTitle": "digester field operator"
    },
    {
        "value": 21997,
        "NOCCode": "9432",
        "JobTitle": "digester operator – pulp and paper"
    },
    {
        "value": 21998,
        "NOCCode": "9432",
        "JobTitle": "digester tender – pulp and paper"
    },
    {
        "value": 21999,
        "NOCCode": "9432",
        "JobTitle": "dryer tender, insulation boards"
    },
    {
        "value": 22000,
        "NOCCode": "9432",
        "JobTitle": "evaporator tender – pulp and paper"
    },
    {
        "value": 22001,
        "NOCCode": "9432",
        "JobTitle": "field operator – pulp and paper"
    },
    {
        "value": 22002,
        "NOCCode": "9432",
        "JobTitle": "filterman/woman – pulp and paper"
    },
    {
        "value": 22003,
        "NOCCode": "9432",
        "JobTitle": "grinder loader – pulp and paper"
    },
    {
        "value": 22004,
        "NOCCode": "9432",
        "JobTitle": "grinder operator – pulp and paper"
    },
    {
        "value": 22005,
        "NOCCode": "9432",
        "JobTitle": "grinderman/woman – pulp and paper"
    },
    {
        "value": 22006,
        "NOCCode": "9432",
        "JobTitle": "kiln-recaust operator – pulp and paper"
    },
    {
        "value": 22007,
        "NOCCode": "9432",
        "JobTitle": "lime kiln operator – pulp and paper"
    },
    {
        "value": 22008,
        "NOCCode": "9432",
        "JobTitle": "lime sludge mixer"
    },
    {
        "value": 22009,
        "NOCCode": "9432",
        "JobTitle": "liquor maker – pulp and paper"
    },
    {
        "value": 22010,
        "NOCCode": "9432",
        "JobTitle": "mixing operator – pulp and paper"
    },
    {
        "value": 22011,
        "NOCCode": "9432",
        "JobTitle": "operator, bleacher – pulp and paper"
    },
    {
        "value": 22012,
        "NOCCode": "9432",
        "JobTitle": "operator, digester – pulp and paper"
    },
    {
        "value": 22013,
        "NOCCode": "9432",
        "JobTitle": "operator, pulp refiner – pulp and paper"
    },
    {
        "value": 22014,
        "NOCCode": "9432",
        "JobTitle": "operator, pulp washer – pulp and paper"
    },
    {
        "value": 22015,
        "NOCCode": "9432",
        "JobTitle": "operator, thermomechanical pulp"
    },
    {
        "value": 22016,
        "NOCCode": "9432",
        "JobTitle": "paper pulp tester"
    },
    {
        "value": 22017,
        "NOCCode": "9432",
        "JobTitle": "pulp and paper size maker"
    },
    {
        "value": 22018,
        "NOCCode": "9432",
        "JobTitle": "pulp baler – pulp and paper"
    },
    {
        "value": 22019,
        "NOCCode": "9432",
        "JobTitle": "pulp bleach liquor maker"
    },
    {
        "value": 22020,
        "NOCCode": "9432",
        "JobTitle": "pulp cook"
    },
    {
        "value": 22021,
        "NOCCode": "9432",
        "JobTitle": "pulp dryer operator"
    },
    {
        "value": 22022,
        "NOCCode": "9432",
        "JobTitle": "pulp grader"
    },
    {
        "value": 22023,
        "NOCCode": "9432",
        "JobTitle": "pulp maker"
    },
    {
        "value": 22024,
        "NOCCode": "9432",
        "JobTitle": "pulp mill equipment operator"
    },
    {
        "value": 22025,
        "NOCCode": "9432",
        "JobTitle": "pulp mill machine operator"
    },
    {
        "value": 22026,
        "NOCCode": "9432",
        "JobTitle": "pulp refiner operator – pulp and paper"
    },
    {
        "value": 22027,
        "NOCCode": "9432",
        "JobTitle": "pulp tester – pulp and paper"
    },
    {
        "value": 22028,
        "NOCCode": "9432",
        "JobTitle": "pulp washer"
    },
    {
        "value": 22029,
        "NOCCode": "9432",
        "JobTitle": "pulp washer operator – pulp and paper"
    },
    {
        "value": 22030,
        "NOCCode": "9432",
        "JobTitle": "pulpwood products tester"
    },
    {
        "value": 22031,
        "NOCCode": "9432",
        "JobTitle": "recovery operator – pulp and paper"
    },
    {
        "value": 22032,
        "NOCCode": "9432",
        "JobTitle": "refiner – pulp and paper"
    },
    {
        "value": 22033,
        "NOCCode": "9432",
        "JobTitle": "refiner man/woman – pulp and paper"
    },
    {
        "value": 22034,
        "NOCCode": "9432",
        "JobTitle": "refiner operator – pulp and paper"
    },
    {
        "value": 22035,
        "NOCCode": "9432",
        "JobTitle": "repulper operator"
    },
    {
        "value": 22036,
        "NOCCode": "9432",
        "JobTitle": "screen room operator – pulp and paper"
    },
    {
        "value": 22037,
        "NOCCode": "9432",
        "JobTitle": "screen tender – pulp and paper"
    },
    {
        "value": 22038,
        "NOCCode": "9432",
        "JobTitle": "screenman/woman – pulp and paper"
    },
    {
        "value": 22039,
        "NOCCode": "9432",
        "JobTitle": "size maker, pulp and paper"
    },
    {
        "value": 22040,
        "NOCCode": "9432",
        "JobTitle": "stock tester – pulp and paper"
    },
    {
        "value": 22041,
        "NOCCode": "9432",
        "JobTitle": "stock washer – pulp and paper"
    },
    {
        "value": 22042,
        "NOCCode": "9432",
        "JobTitle": "tall oil operator"
    },
    {
        "value": 22043,
        "NOCCode": "9432",
        "JobTitle": "tender, broke beater – pulp and paper"
    },
    {
        "value": 22044,
        "NOCCode": "9432",
        "JobTitle": "thermomechanical pulp assistant operator"
    },
    {
        "value": 22045,
        "NOCCode": "9432",
        "JobTitle": "thermomechanical pulp assistant operator – pulp and paper"
    },
    {
        "value": 22046,
        "NOCCode": "9432",
        "JobTitle": "thermomechanical pulp operator"
    },
    {
        "value": 22047,
        "NOCCode": "9432",
        "JobTitle": "unbleached pulp washer – pulp and paper"
    },
    {
        "value": 22048,
        "NOCCode": "9432",
        "JobTitle": "washer operator, pulp"
    },
    {
        "value": 22049,
        "NOCCode": "9432",
        "JobTitle": "washerman/woman – pulp and paper"
    },
    {
        "value": 22050,
        "NOCCode": "9432",
        "JobTitle": "washing-screening operator – pulp and paper"
    },
    {
        "value": 22051,
        "NOCCode": "9432",
        "JobTitle": "wood pulp caustic liquor maker"
    },
    {
        "value": 22052,
        "NOCCode": "9433",
        "JobTitle": "assistant coating operator – pulp and paper"
    },
    {
        "value": 22053,
        "NOCCode": "9433",
        "JobTitle": "back tender, paper machine"
    },
    {
        "value": 22054,
        "NOCCode": "9433",
        "JobTitle": "balerman/woman – pulp and paper"
    },
    {
        "value": 22055,
        "NOCCode": "9433",
        "JobTitle": "baling press tender – pulp and paper"
    },
    {
        "value": 22056,
        "NOCCode": "9433",
        "JobTitle": "calender man/woman – pulp and paper"
    },
    {
        "value": 22057,
        "NOCCode": "9433",
        "JobTitle": "calender operator – pulp and paper"
    },
    {
        "value": 22058,
        "NOCCode": "9433",
        "JobTitle": "calenderer – pulp and paper"
    },
    {
        "value": 22059,
        "NOCCode": "9433",
        "JobTitle": "combiner machine operator – paper mill"
    },
    {
        "value": 22060,
        "NOCCode": "9433",
        "JobTitle": "core cutter and reamer"
    },
    {
        "value": 22061,
        "NOCCode": "9433",
        "JobTitle": "crimping head operator – papermaking"
    },
    {
        "value": 22062,
        "NOCCode": "9433",
        "JobTitle": "cutter and reamer, cores – pulp and paper"
    },
    {
        "value": 22063,
        "NOCCode": "9433",
        "JobTitle": "embossing calender operator – pulp and paper"
    },
    {
        "value": 22064,
        "NOCCode": "9433",
        "JobTitle": "finisher – pulp and paper"
    },
    {
        "value": 22065,
        "NOCCode": "9433",
        "JobTitle": "finisher, paper rolls – pulp and paper"
    },
    {
        "value": 22066,
        "NOCCode": "9433",
        "JobTitle": "fourdrinier machine operator – pulp and paper"
    },
    {
        "value": 22067,
        "NOCCode": "9433",
        "JobTitle": "fourth hand – pulp and paper"
    },
    {
        "value": 22068,
        "NOCCode": "9433",
        "JobTitle": "grader – pulp and paper"
    },
    {
        "value": 22069,
        "NOCCode": "9433",
        "JobTitle": "graderman/woman – pulp and paper"
    },
    {
        "value": 22070,
        "NOCCode": "9433",
        "JobTitle": "inspector, sheet paper"
    },
    {
        "value": 22071,
        "NOCCode": "9433",
        "JobTitle": "label operator – papermaking"
    },
    {
        "value": 22072,
        "NOCCode": "9433",
        "JobTitle": "laminating machine operator – paper mill"
    },
    {
        "value": 22073,
        "NOCCode": "9433",
        "JobTitle": "operator, calender – pulp and paper"
    },
    {
        "value": 22074,
        "NOCCode": "9433",
        "JobTitle": "operator, coating machine – pulp and paper"
    },
    {
        "value": 22075,
        "NOCCode": "9433",
        "JobTitle": "operator, fourdrinier machine – pulp and paper"
    },
    {
        "value": 22076,
        "NOCCode": "9433",
        "JobTitle": "operator, paper machine"
    },
    {
        "value": 22077,
        "NOCCode": "9433",
        "JobTitle": "operator, sheeter – pulp and paper"
    },
    {
        "value": 22078,
        "NOCCode": "9433",
        "JobTitle": "packer-wrapper attendant – papermaking"
    },
    {
        "value": 22079,
        "NOCCode": "9433",
        "JobTitle": "paper grader"
    },
    {
        "value": 22080,
        "NOCCode": "9433",
        "JobTitle": "paper jogger operation – pulp and paper"
    },
    {
        "value": 22081,
        "NOCCode": "9433",
        "JobTitle": "paper machine hand"
    },
    {
        "value": 22082,
        "NOCCode": "9433",
        "JobTitle": "paper machine operator"
    },
    {
        "value": 22083,
        "NOCCode": "9433",
        "JobTitle": "paper machine winderman/woman"
    },
    {
        "value": 22084,
        "NOCCode": "9433",
        "JobTitle": "paper roll finisher – pulp and paper"
    },
    {
        "value": 22085,
        "NOCCode": "9433",
        "JobTitle": "paper roll wrapper – pulp and paper"
    },
    {
        "value": 22086,
        "NOCCode": "9433",
        "JobTitle": "paper-coating machine operator"
    },
    {
        "value": 22087,
        "NOCCode": "9433",
        "JobTitle": "paper-finishing machine operator"
    },
    {
        "value": 22088,
        "NOCCode": "9433",
        "JobTitle": "papermaking and finishing machine operator"
    },
    {
        "value": 22089,
        "NOCCode": "9433",
        "JobTitle": "pressman/woman, pulp – pulp and paper"
    },
    {
        "value": 22090,
        "NOCCode": "9433",
        "JobTitle": "processed paper rewinder"
    },
    {
        "value": 22091,
        "NOCCode": "9433",
        "JobTitle": "pulp press tender"
    },
    {
        "value": 22092,
        "NOCCode": "9433",
        "JobTitle": "rewinder – pulp and paper"
    },
    {
        "value": 22093,
        "NOCCode": "9433",
        "JobTitle": "rewinder tender – pulp and paper"
    },
    {
        "value": 22094,
        "NOCCode": "9433",
        "JobTitle": "rewinder, processed paper"
    },
    {
        "value": 22095,
        "NOCCode": "9433",
        "JobTitle": "roll finisher – paper"
    },
    {
        "value": 22096,
        "NOCCode": "9433",
        "JobTitle": "roll wrapper – paper"
    },
    {
        "value": 22097,
        "NOCCode": "9433",
        "JobTitle": "salvage winder operator – papermaking"
    },
    {
        "value": 22098,
        "NOCCode": "9433",
        "JobTitle": "sampler, sheet paper"
    },
    {
        "value": 22099,
        "NOCCode": "9433",
        "JobTitle": "second hand, paper machine"
    },
    {
        "value": 22100,
        "NOCCode": "9433",
        "JobTitle": "sheet paper inspector"
    },
    {
        "value": 22101,
        "NOCCode": "9433",
        "JobTitle": "sheet paper sampler"
    },
    {
        "value": 22102,
        "NOCCode": "9433",
        "JobTitle": "sheeter operator – pulp and paper"
    },
    {
        "value": 22103,
        "NOCCode": "9433",
        "JobTitle": "specialty rewinder operator – papermaking"
    },
    {
        "value": 22104,
        "NOCCode": "9433",
        "JobTitle": "supercalender operator – pulp and paper"
    },
    {
        "value": 22105,
        "NOCCode": "9433",
        "JobTitle": "tender, rewinder – pulp and paper"
    },
    {
        "value": 22106,
        "NOCCode": "9433",
        "JobTitle": "third hand – pulp and paper"
    },
    {
        "value": 22107,
        "NOCCode": "9433",
        "JobTitle": "winder operator – pulp and paper"
    },
    {
        "value": 22108,
        "NOCCode": "9433",
        "JobTitle": "winderman/woman – pulp and paper"
    },
    {
        "value": 22109,
        "NOCCode": "9433",
        "JobTitle": "wrapline operator – papermaking"
    },
    {
        "value": 22110,
        "NOCCode": "9433",
        "JobTitle": "wrapper, paper rolls – pulp and paper"
    },
    {
        "value": 22111,
        "NOCCode": "9433",
        "JobTitle": "wrapper, rolls – pulp and paper"
    },
    {
        "value": 22112,
        "NOCCode": "9434",
        "JobTitle": "automatic veneer clipper tender – wood processing"
    },
    {
        "value": 22113,
        "NOCCode": "9434",
        "JobTitle": "barker operator"
    },
    {
        "value": 22114,
        "NOCCode": "9434",
        "JobTitle": "barker operator – wood processing"
    },
    {
        "value": 22115,
        "NOCCode": "9434",
        "JobTitle": "barking machine operator – wood processing"
    },
    {
        "value": 22116,
        "NOCCode": "9434",
        "JobTitle": "chip screen tender – wood processing"
    },
    {
        "value": 22117,
        "NOCCode": "9434",
        "JobTitle": "chip-mixing machine tender – wood processing"
    },
    {
        "value": 22118,
        "NOCCode": "9434",
        "JobTitle": "chipper operator"
    },
    {
        "value": 22119,
        "NOCCode": "9434",
        "JobTitle": "chipper operator – wood processing"
    },
    {
        "value": 22120,
        "NOCCode": "9434",
        "JobTitle": "clipper operator – wood processing"
    },
    {
        "value": 22121,
        "NOCCode": "9434",
        "JobTitle": "core layer – wood processing"
    },
    {
        "value": 22122,
        "NOCCode": "9434",
        "JobTitle": "core-laying machine tender – wood processing"
    },
    {
        "value": 22123,
        "NOCCode": "9434",
        "JobTitle": "cut-off sawyer, veneer strips – wood processing"
    },
    {
        "value": 22124,
        "NOCCode": "9434",
        "JobTitle": "debarker operator – wood processing"
    },
    {
        "value": 22125,
        "NOCCode": "9434",
        "JobTitle": "dip tank tender – wood processing"
    },
    {
        "value": 22126,
        "NOCCode": "9434",
        "JobTitle": "drop sorter operator – wood processing"
    },
    {
        "value": 22127,
        "NOCCode": "9434",
        "JobTitle": "drum barker tender – wood processing"
    },
    {
        "value": 22128,
        "NOCCode": "9434",
        "JobTitle": "dryer tender, wood particles"
    },
    {
        "value": 22129,
        "NOCCode": "9434",
        "JobTitle": "drying kiln operator – wood processing"
    },
    {
        "value": 22130,
        "NOCCode": "9434",
        "JobTitle": "edge gluer – wood processing"
    },
    {
        "value": 22131,
        "NOCCode": "9434",
        "JobTitle": "excelsior machine tender"
    },
    {
        "value": 22132,
        "NOCCode": "9434",
        "JobTitle": "flaker operator – wood processing"
    },
    {
        "value": 22133,
        "NOCCode": "9434",
        "JobTitle": "forming line operator, hardboard"
    },
    {
        "value": 22134,
        "NOCCode": "9434",
        "JobTitle": "forming machine operator, particleboard"
    },
    {
        "value": 22135,
        "NOCCode": "9434",
        "JobTitle": "fuel log maker – wood processing"
    },
    {
        "value": 22136,
        "NOCCode": "9434",
        "JobTitle": "grinding machine operator, hardboard"
    },
    {
        "value": 22137,
        "NOCCode": "9434",
        "JobTitle": "groover operator, plywood panels – wood processing"
    },
    {
        "value": 22138,
        "NOCCode": "9434",
        "JobTitle": "hardboard press operator – wood processing"
    },
    {
        "value": 22139,
        "NOCCode": "9434",
        "JobTitle": "hardboard-forming line operator"
    },
    {
        "value": 22140,
        "NOCCode": "9434",
        "JobTitle": "hardboard-grinding machine operator"
    },
    {
        "value": 22141,
        "NOCCode": "9434",
        "JobTitle": "hardboard-oiling machine tender"
    },
    {
        "value": 22142,
        "NOCCode": "9434",
        "JobTitle": "hot press operator – wood processing"
    },
    {
        "value": 22143,
        "NOCCode": "9434",
        "JobTitle": "humidifier tender – wood processing"
    },
    {
        "value": 22144,
        "NOCCode": "9434",
        "JobTitle": "hydraulic barker operator – wood processing"
    },
    {
        "value": 22145,
        "NOCCode": "9434",
        "JobTitle": "incising machine tender – wood processing"
    },
    {
        "value": 22146,
        "NOCCode": "9434",
        "JobTitle": "insulation board press tender"
    },
    {
        "value": 22147,
        "NOCCode": "9434",
        "JobTitle": "jointer tender – wood processing"
    },
    {
        "value": 22148,
        "NOCCode": "9434",
        "JobTitle": "jointer, veneer – wood processing"
    },
    {
        "value": 22149,
        "NOCCode": "9434",
        "JobTitle": "lathe spotter – wood processing"
    },
    {
        "value": 22150,
        "NOCCode": "9434",
        "JobTitle": "layup machine operator – wood processing"
    },
    {
        "value": 22151,
        "NOCCode": "9434",
        "JobTitle": "log cooker – wood processing"
    },
    {
        "value": 22152,
        "NOCCode": "9434",
        "JobTitle": "log vat tender – wood processing"
    },
    {
        "value": 22153,
        "NOCCode": "9434",
        "JobTitle": "lumber drop sorter – wood processing"
    },
    {
        "value": 22154,
        "NOCCode": "9434",
        "JobTitle": "lumber kiln operator"
    },
    {
        "value": 22155,
        "NOCCode": "9434",
        "JobTitle": "lumber sorter, machine"
    },
    {
        "value": 22156,
        "NOCCode": "9434",
        "JobTitle": "machine plywood patcher"
    },
    {
        "value": 22157,
        "NOCCode": "9434",
        "JobTitle": "miller, wood flour"
    },
    {
        "value": 22158,
        "NOCCode": "9434",
        "JobTitle": "mixer-dryer tender, wood particles"
    },
    {
        "value": 22159,
        "NOCCode": "9434",
        "JobTitle": "multidrum sander operator"
    },
    {
        "value": 22160,
        "NOCCode": "9434",
        "JobTitle": "operator, lumber kiln"
    },
    {
        "value": 22161,
        "NOCCode": "9434",
        "JobTitle": "operator, multidrum sander"
    },
    {
        "value": 22162,
        "NOCCode": "9434",
        "JobTitle": "operator, particleboard"
    },
    {
        "value": 22163,
        "NOCCode": "9434",
        "JobTitle": "operator, plywood press"
    },
    {
        "value": 22164,
        "NOCCode": "9434",
        "JobTitle": "panel-groover operator – wood processing"
    },
    {
        "value": 22165,
        "NOCCode": "9434",
        "JobTitle": "panel-sanding machine operator – wood processing"
    },
    {
        "value": 22166,
        "NOCCode": "9434",
        "JobTitle": "particleboard line operator"
    },
    {
        "value": 22167,
        "NOCCode": "9434",
        "JobTitle": "particleboard-forming machine operator"
    },
    {
        "value": 22168,
        "NOCCode": "9434",
        "JobTitle": "particleboard-sizing saw tender"
    },
    {
        "value": 22169,
        "NOCCode": "9434",
        "JobTitle": "patcher operator – wood processing"
    },
    {
        "value": 22170,
        "NOCCode": "9434",
        "JobTitle": "plywood and veneer patcher and repairer"
    },
    {
        "value": 22171,
        "NOCCode": "9434",
        "JobTitle": "plywood bundler"
    },
    {
        "value": 22172,
        "NOCCode": "9434",
        "JobTitle": "plywood panel assembler"
    },
    {
        "value": 22173,
        "NOCCode": "9434",
        "JobTitle": "plywood panel groover operator – wood processing"
    },
    {
        "value": 22174,
        "NOCCode": "9434",
        "JobTitle": "plywood patcher"
    },
    {
        "value": 22175,
        "NOCCode": "9434",
        "JobTitle": "plywood patcher, machine"
    },
    {
        "value": 22176,
        "NOCCode": "9434",
        "JobTitle": "plywood press operator"
    },
    {
        "value": 22177,
        "NOCCode": "9434",
        "JobTitle": "plywood-sizing saw tender – wood processing"
    },
    {
        "value": 22178,
        "NOCCode": "9434",
        "JobTitle": "press operator, hardboard – wood processing"
    },
    {
        "value": 22179,
        "NOCCode": "9434",
        "JobTitle": "press tender, insulation board"
    },
    {
        "value": 22180,
        "NOCCode": "9434",
        "JobTitle": "ring barker operator – wood processing"
    },
    {
        "value": 22181,
        "NOCCode": "9434",
        "JobTitle": "scarf gluer – wood processing"
    },
    {
        "value": 22182,
        "NOCCode": "9434",
        "JobTitle": "scarfer tender – wood processing"
    },
    {
        "value": 22183,
        "NOCCode": "9434",
        "JobTitle": "scarfing machine tender – wood processing"
    },
    {
        "value": 22184,
        "NOCCode": "9434",
        "JobTitle": "screen tender, chips – wood processing"
    },
    {
        "value": 22185,
        "NOCCode": "9434",
        "JobTitle": "sizing saw tender, plywood – wood processing"
    },
    {
        "value": 22186,
        "NOCCode": "9434",
        "JobTitle": "tempering plant tender"
    },
    {
        "value": 22187,
        "NOCCode": "9434",
        "JobTitle": "tender, automatic veneer clipper – wood processing"
    },
    {
        "value": 22188,
        "NOCCode": "9434",
        "JobTitle": "tender, log vat – wood processing"
    },
    {
        "value": 22189,
        "NOCCode": "9434",
        "JobTitle": "tender, scarfing machine – wood processing"
    },
    {
        "value": 22190,
        "NOCCode": "9434",
        "JobTitle": "tender, tempering plant"
    },
    {
        "value": 22191,
        "NOCCode": "9434",
        "JobTitle": "timber treating tank operator"
    },
    {
        "value": 22192,
        "NOCCode": "9434",
        "JobTitle": "treating worker – wood preserving"
    },
    {
        "value": 22193,
        "NOCCode": "9434",
        "JobTitle": "vat tender, logs – wood processing"
    },
    {
        "value": 22194,
        "NOCCode": "9434",
        "JobTitle": "veneer clipper – wood processing"
    },
    {
        "value": 22195,
        "NOCCode": "9434",
        "JobTitle": "veneer clipper tender, automatic – wood processing"
    },
    {
        "value": 22196,
        "NOCCode": "9434",
        "JobTitle": "veneer cutter – wood processing"
    },
    {
        "value": 22197,
        "NOCCode": "9434",
        "JobTitle": "veneer dryer tender"
    },
    {
        "value": 22198,
        "NOCCode": "9434",
        "JobTitle": "veneer dryer tender – wood processing"
    },
    {
        "value": 22199,
        "NOCCode": "9434",
        "JobTitle": "veneer jointer – wood processing"
    },
    {
        "value": 22200,
        "NOCCode": "9434",
        "JobTitle": "veneer jointer operator – wood processing"
    },
    {
        "value": 22201,
        "NOCCode": "9434",
        "JobTitle": "veneer lathe operator"
    },
    {
        "value": 22202,
        "NOCCode": "9434",
        "JobTitle": "veneer lathe operator – wood processing"
    },
    {
        "value": 22203,
        "NOCCode": "9434",
        "JobTitle": "veneer matcher – wood processing"
    },
    {
        "value": 22204,
        "NOCCode": "9434",
        "JobTitle": "veneer patcher – wood processing"
    },
    {
        "value": 22205,
        "NOCCode": "9434",
        "JobTitle": "veneer reel tender – wood processing"
    },
    {
        "value": 22206,
        "NOCCode": "9434",
        "JobTitle": "veneer slicer-lather – wood processing"
    },
    {
        "value": 22207,
        "NOCCode": "9434",
        "JobTitle": "veneer splicer tender – wood processing"
    },
    {
        "value": 22208,
        "NOCCode": "9434",
        "JobTitle": "veneer strip cut-off sawyer – wood processing"
    },
    {
        "value": 22209,
        "NOCCode": "9434",
        "JobTitle": "veneer taper – wood processing"
    },
    {
        "value": 22210,
        "NOCCode": "9434",
        "JobTitle": "veneer-slicing machine operator – wood processing"
    },
    {
        "value": 22211,
        "NOCCode": "9434",
        "JobTitle": "waferboard press operator"
    },
    {
        "value": 22212,
        "NOCCode": "9434",
        "JobTitle": "waferboard press operator – wood processing"
    },
    {
        "value": 22213,
        "NOCCode": "9434",
        "JobTitle": "wood particle dryer tender"
    },
    {
        "value": 22214,
        "NOCCode": "9434",
        "JobTitle": "wood particles mixer-dryer tender"
    },
    {
        "value": 22215,
        "NOCCode": "9434",
        "JobTitle": "wood treater"
    },
    {
        "value": 22216,
        "NOCCode": "9434",
        "JobTitle": "wood treater – wood preserving"
    },
    {
        "value": 22217,
        "NOCCode": "9434",
        "JobTitle": "wood-oiling machine operator – wood processing"
    },
    {
        "value": 22218,
        "NOCCode": "9435",
        "JobTitle": "assembler, packing cartons"
    },
    {
        "value": 22219,
        "NOCCode": "9435",
        "JobTitle": "automatic carton maker – paper converting"
    },
    {
        "value": 22220,
        "NOCCode": "9435",
        "JobTitle": "automatic carton-making machine operator – paper converting"
    },
    {
        "value": 22221,
        "NOCCode": "9435",
        "JobTitle": "automatic paper-cutting machine operator – paper converting"
    },
    {
        "value": 22222,
        "NOCCode": "9435",
        "JobTitle": "bag maker – paper converting"
    },
    {
        "value": 22223,
        "NOCCode": "9435",
        "JobTitle": "bag maker, paper"
    },
    {
        "value": 22224,
        "NOCCode": "9435",
        "JobTitle": "bag repairer – paper products"
    },
    {
        "value": 22225,
        "NOCCode": "9435",
        "JobTitle": "bag-making machine set-up operator – paper converting"
    },
    {
        "value": 22226,
        "NOCCode": "9435",
        "JobTitle": "book cover creaser"
    },
    {
        "value": 22227,
        "NOCCode": "9435",
        "JobTitle": "book jacket machine tender"
    },
    {
        "value": 22228,
        "NOCCode": "9435",
        "JobTitle": "box cutter – paper converting"
    },
    {
        "value": 22229,
        "NOCCode": "9435",
        "JobTitle": "box cutter, corrugated – paper converting"
    },
    {
        "value": 22230,
        "NOCCode": "9435",
        "JobTitle": "box finisher – paper converting"
    },
    {
        "value": 22231,
        "NOCCode": "9435",
        "JobTitle": "box gluer – paper converting"
    },
    {
        "value": 22232,
        "NOCCode": "9435",
        "JobTitle": "box liner – paper converting"
    },
    {
        "value": 22233,
        "NOCCode": "9435",
        "JobTitle": "box maker – paper converting"
    },
    {
        "value": 22234,
        "NOCCode": "9435",
        "JobTitle": "box maker operator"
    },
    {
        "value": 22235,
        "NOCCode": "9435",
        "JobTitle": "box maker, cardboard – paper converting"
    },
    {
        "value": 22236,
        "NOCCode": "9435",
        "JobTitle": "box maker, paper – paper converting"
    },
    {
        "value": 22237,
        "NOCCode": "9435",
        "JobTitle": "button envelope machine tender"
    },
    {
        "value": 22238,
        "NOCCode": "9435",
        "JobTitle": "carbon coater operator"
    },
    {
        "value": 22239,
        "NOCCode": "9435",
        "JobTitle": "carbon paper cutter"
    },
    {
        "value": 22240,
        "NOCCode": "9435",
        "JobTitle": "carbon-coating machine operator"
    },
    {
        "value": 22241,
        "NOCCode": "9435",
        "JobTitle": "carbon-paper-making machine operator"
    },
    {
        "value": 22242,
        "NOCCode": "9435",
        "JobTitle": "cardboard box gluer"
    },
    {
        "value": 22243,
        "NOCCode": "9435",
        "JobTitle": "cardboard box maker – paper converting"
    },
    {
        "value": 22244,
        "NOCCode": "9435",
        "JobTitle": "cardboard box press operator"
    },
    {
        "value": 22245,
        "NOCCode": "9435",
        "JobTitle": "cardboard maker – paper converting"
    },
    {
        "value": 22246,
        "NOCCode": "9435",
        "JobTitle": "cardboard maker, machine"
    },
    {
        "value": 22247,
        "NOCCode": "9435",
        "JobTitle": "cardboard-tube-winding machine operator – paper converting"
    },
    {
        "value": 22248,
        "NOCCode": "9435",
        "JobTitle": "carton assembler – paper converting"
    },
    {
        "value": 22249,
        "NOCCode": "9435",
        "JobTitle": "carton forming machine operator"
    },
    {
        "value": 22250,
        "NOCCode": "9435",
        "JobTitle": "carton gluer"
    },
    {
        "value": 22251,
        "NOCCode": "9435",
        "JobTitle": "carton-making machine operator"
    },
    {
        "value": 22252,
        "NOCCode": "9435",
        "JobTitle": "coater-laminator – paper products"
    },
    {
        "value": 22253,
        "NOCCode": "9435",
        "JobTitle": "colour card maker, machine"
    },
    {
        "value": 22254,
        "NOCCode": "9435",
        "JobTitle": "cone former – paper converting"
    },
    {
        "value": 22255,
        "NOCCode": "9435",
        "JobTitle": "cone winder – paper converting"
    },
    {
        "value": 22256,
        "NOCCode": "9435",
        "JobTitle": "core cutter – paper converting"
    },
    {
        "value": 22257,
        "NOCCode": "9435",
        "JobTitle": "core maker – paper converting"
    },
    {
        "value": 22258,
        "NOCCode": "9435",
        "JobTitle": "core winder – paper converting"
    },
    {
        "value": 22259,
        "NOCCode": "9435",
        "JobTitle": "core-winding machine operator – paper converting"
    },
    {
        "value": 22260,
        "NOCCode": "9435",
        "JobTitle": "corrugated box cutter – paper converting"
    },
    {
        "value": 22261,
        "NOCCode": "9435",
        "JobTitle": "corrugating machine operator – paper converting"
    },
    {
        "value": 22262,
        "NOCCode": "9435",
        "JobTitle": "corrugating machine operator-cutter – paper converting"
    },
    {
        "value": 22263,
        "NOCCode": "9435",
        "JobTitle": "corrugator operator – paper converting"
    },
    {
        "value": 22264,
        "NOCCode": "9435",
        "JobTitle": "corrugator operator-cutter – paper converting"
    },
    {
        "value": 22265,
        "NOCCode": "9435",
        "JobTitle": "creaser – paper converting"
    },
    {
        "value": 22266,
        "NOCCode": "9435",
        "JobTitle": "creping machine operator – paper converting"
    },
    {
        "value": 22267,
        "NOCCode": "9435",
        "JobTitle": "cutter, carbon paper"
    },
    {
        "value": 22268,
        "NOCCode": "9435",
        "JobTitle": "cutter, cores – paper converting"
    },
    {
        "value": 22269,
        "NOCCode": "9435",
        "JobTitle": "cutter, corrugated boxes – paper converting"
    },
    {
        "value": 22270,
        "NOCCode": "9435",
        "JobTitle": "cutter, labels – paper converting"
    },
    {
        "value": 22271,
        "NOCCode": "9435",
        "JobTitle": "cutter, revenue stamps"
    },
    {
        "value": 22272,
        "NOCCode": "9435",
        "JobTitle": "cutting and creasing press operator – paper converting"
    },
    {
        "value": 22273,
        "NOCCode": "9435",
        "JobTitle": "diaper machine tender – paper converting"
    },
    {
        "value": 22274,
        "NOCCode": "9435",
        "JobTitle": "die-cutting machine operator – paper products"
    },
    {
        "value": 22275,
        "NOCCode": "9435",
        "JobTitle": "drill punch tender – paper converting"
    },
    {
        "value": 22276,
        "NOCCode": "9435",
        "JobTitle": "envelope flap gummer, machine – paper converting"
    },
    {
        "value": 22277,
        "NOCCode": "9435",
        "JobTitle": "envelope machine operator"
    },
    {
        "value": 22278,
        "NOCCode": "9435",
        "JobTitle": "envelope machine tender – paper converting"
    },
    {
        "value": 22279,
        "NOCCode": "9435",
        "JobTitle": "envelope maker operator"
    },
    {
        "value": 22280,
        "NOCCode": "9435",
        "JobTitle": "envelope-flap-cutting machine setter"
    },
    {
        "value": 22281,
        "NOCCode": "9435",
        "JobTitle": "envelope-flap-gumming machine tender – paper converting"
    },
    {
        "value": 22282,
        "NOCCode": "9435",
        "JobTitle": "envelope-making machine operator"
    },
    {
        "value": 22283,
        "NOCCode": "9435",
        "JobTitle": "envelope-making machine setter – paper converting"
    },
    {
        "value": 22284,
        "NOCCode": "9435",
        "JobTitle": "feeder, slitter-creaser – paper converting"
    },
    {
        "value": 22285,
        "NOCCode": "9435",
        "JobTitle": "flexo-folder operator – paper converting"
    },
    {
        "value": 22286,
        "NOCCode": "9435",
        "JobTitle": "folding machine tender – paper converting"
    },
    {
        "value": 22287,
        "NOCCode": "9435",
        "JobTitle": "friction paint machine tender – paper converting"
    },
    {
        "value": 22288,
        "NOCCode": "9435",
        "JobTitle": "gluer, cardboard boxes"
    },
    {
        "value": 22289,
        "NOCCode": "9435",
        "JobTitle": "gumming machine tender – paper converting"
    },
    {
        "value": 22290,
        "NOCCode": "9435",
        "JobTitle": "inspector, paper products"
    },
    {
        "value": 22291,
        "NOCCode": "9435",
        "JobTitle": "label cutter – paper converting"
    },
    {
        "value": 22292,
        "NOCCode": "9435",
        "JobTitle": "laminating machine tender – paper converting"
    },
    {
        "value": 22293,
        "NOCCode": "9435",
        "JobTitle": "lapper, wallpaper"
    },
    {
        "value": 22294,
        "NOCCode": "9435",
        "JobTitle": "lapper, wallpaper – paper converting"
    },
    {
        "value": 22295,
        "NOCCode": "9435",
        "JobTitle": "liner machine operator – paper converting"
    },
    {
        "value": 22296,
        "NOCCode": "9435",
        "JobTitle": "machine cardboard maker"
    },
    {
        "value": 22297,
        "NOCCode": "9435",
        "JobTitle": "machine colour card maker"
    },
    {
        "value": 22298,
        "NOCCode": "9435",
        "JobTitle": "machine cutter, rolls – paper converting"
    },
    {
        "value": 22299,
        "NOCCode": "9435",
        "JobTitle": "machine envelope flap gummer – paper converting"
    },
    {
        "value": 22300,
        "NOCCode": "9435",
        "JobTitle": "machine gluer, pads – paper converting"
    },
    {
        "value": 22301,
        "NOCCode": "9435",
        "JobTitle": "machine gummer, envelope flaps – paper converting"
    },
    {
        "value": 22302,
        "NOCCode": "9435",
        "JobTitle": "machine operator, paper converting"
    },
    {
        "value": 22303,
        "NOCCode": "9435",
        "JobTitle": "machine pad gluer – paper converting"
    },
    {
        "value": 22304,
        "NOCCode": "9435",
        "JobTitle": "machine roll cutter – paper converting"
    },
    {
        "value": 22305,
        "NOCCode": "9435",
        "JobTitle": "machine roll slitter – paper converting"
    },
    {
        "value": 22306,
        "NOCCode": "9435",
        "JobTitle": "machine slitter, rolls – paper converting"
    },
    {
        "value": 22307,
        "NOCCode": "9435",
        "JobTitle": "machine tube maker – paper converting"
    },
    {
        "value": 22308,
        "NOCCode": "9435",
        "JobTitle": "machine tuber – paper converting"
    },
    {
        "value": 22309,
        "NOCCode": "9435",
        "JobTitle": "matchbook comb-cutting machine tender"
    },
    {
        "value": 22310,
        "NOCCode": "9435",
        "JobTitle": "matchbook folding-stapling machine tender"
    },
    {
        "value": 22311,
        "NOCCode": "9435",
        "JobTitle": "moulder, papier-mâché"
    },
    {
        "value": 22312,
        "NOCCode": "9435",
        "JobTitle": "operator, carbon-paper-making machine"
    },
    {
        "value": 22313,
        "NOCCode": "9435",
        "JobTitle": "operator, cardboard box press"
    },
    {
        "value": 22314,
        "NOCCode": "9435",
        "JobTitle": "operator, carton-making machine"
    },
    {
        "value": 22315,
        "NOCCode": "9435",
        "JobTitle": "operator, core-winding machine – paper converting"
    },
    {
        "value": 22316,
        "NOCCode": "9435",
        "JobTitle": "operator, corrugator – paper converting"
    },
    {
        "value": 22317,
        "NOCCode": "9435",
        "JobTitle": "operator, cutting and creasing press – paper converting"
    },
    {
        "value": 22318,
        "NOCCode": "9435",
        "JobTitle": "operator, die-cutting machine – paper products"
    },
    {
        "value": 22319,
        "NOCCode": "9435",
        "JobTitle": "operator, paper bag machine"
    },
    {
        "value": 22320,
        "NOCCode": "9435",
        "JobTitle": "operator, paper box machine"
    },
    {
        "value": 22321,
        "NOCCode": "9435",
        "JobTitle": "operator, paper slitter"
    },
    {
        "value": 22322,
        "NOCCode": "9435",
        "JobTitle": "operator, platen press – paper converting"
    },
    {
        "value": 22323,
        "NOCCode": "9435",
        "JobTitle": "operator, slitter-creaser – paper converting"
    },
    {
        "value": 22324,
        "NOCCode": "9435",
        "JobTitle": "operator-cutter, corrugating machine – paper converting"
    },
    {
        "value": 22325,
        "NOCCode": "9435",
        "JobTitle": "packing carton assembler"
    },
    {
        "value": 22326,
        "NOCCode": "9435",
        "JobTitle": "pad gluer, machine – paper converting"
    },
    {
        "value": 22327,
        "NOCCode": "9435",
        "JobTitle": "paper bag machine operator"
    },
    {
        "value": 22328,
        "NOCCode": "9435",
        "JobTitle": "paper bag machine tender"
    },
    {
        "value": 22329,
        "NOCCode": "9435",
        "JobTitle": "paper bag maker"
    },
    {
        "value": 22330,
        "NOCCode": "9435",
        "JobTitle": "paper box machine operator"
    },
    {
        "value": 22331,
        "NOCCode": "9435",
        "JobTitle": "paper box machine tender"
    },
    {
        "value": 22332,
        "NOCCode": "9435",
        "JobTitle": "paper box maker – paper converting"
    },
    {
        "value": 22333,
        "NOCCode": "9435",
        "JobTitle": "paper cone former – paper converting"
    },
    {
        "value": 22334,
        "NOCCode": "9435",
        "JobTitle": "paper cone winder – paper converting"
    },
    {
        "value": 22335,
        "NOCCode": "9435",
        "JobTitle": "paper cup machine tender – paper converting"
    },
    {
        "value": 22336,
        "NOCCode": "9435",
        "JobTitle": "paper cutter – paper converting"
    },
    {
        "value": 22337,
        "NOCCode": "9435",
        "JobTitle": "paper cutter, carbon"
    },
    {
        "value": 22338,
        "NOCCode": "9435",
        "JobTitle": "paper products inspector"
    },
    {
        "value": 22339,
        "NOCCode": "9435",
        "JobTitle": "paper slitter operator"
    },
    {
        "value": 22340,
        "NOCCode": "9435",
        "JobTitle": "paper twister tender – paper converting"
    },
    {
        "value": 22341,
        "NOCCode": "9435",
        "JobTitle": "paper-converting machine operator"
    },
    {
        "value": 22342,
        "NOCCode": "9435",
        "JobTitle": "paper-punch press tender – paper converting"
    },
    {
        "value": 22343,
        "NOCCode": "9435",
        "JobTitle": "papier-mâché moulder"
    },
    {
        "value": 22344,
        "NOCCode": "9435",
        "JobTitle": "partition assembly machine operator – paper converting"
    },
    {
        "value": 22345,
        "NOCCode": "9435",
        "JobTitle": "partition slotter machine tender – paper converting"
    },
    {
        "value": 22346,
        "NOCCode": "9435",
        "JobTitle": "platen press operator – paper converting"
    },
    {
        "value": 22347,
        "NOCCode": "9435",
        "JobTitle": "reeler, wallpaper"
    },
    {
        "value": 22348,
        "NOCCode": "9435",
        "JobTitle": "revenue stamp cutter"
    },
    {
        "value": 22349,
        "NOCCode": "9435",
        "JobTitle": "roll cutter, machine – paper converting"
    },
    {
        "value": 22350,
        "NOCCode": "9435",
        "JobTitle": "roll slicing machine tender – paper converting"
    },
    {
        "value": 22351,
        "NOCCode": "9435",
        "JobTitle": "roll slitter, machine – paper converting"
    },
    {
        "value": 22352,
        "NOCCode": "9435",
        "JobTitle": "rolling machine tender – paper converting"
    },
    {
        "value": 22353,
        "NOCCode": "9435",
        "JobTitle": "roll-slitting machine tender – paper converting"
    },
    {
        "value": 22354,
        "NOCCode": "9435",
        "JobTitle": "scorer – paper converting"
    },
    {
        "value": 22355,
        "NOCCode": "9435",
        "JobTitle": "sealing machine tender – paper converting"
    },
    {
        "value": 22356,
        "NOCCode": "9435",
        "JobTitle": "setter, envelope-flap-cutting machine"
    },
    {
        "value": 22357,
        "NOCCode": "9435",
        "JobTitle": "setter, envelope-making machine – paper converting"
    },
    {
        "value": 22358,
        "NOCCode": "9435",
        "JobTitle": "set-up operator, bag-making machine – paper converting"
    },
    {
        "value": 22359,
        "NOCCode": "9435",
        "JobTitle": "sleever – paper converting"
    },
    {
        "value": 22360,
        "NOCCode": "9435",
        "JobTitle": "slitter-creaser – paper converting"
    },
    {
        "value": 22361,
        "NOCCode": "9435",
        "JobTitle": "slitter-creaser feeder – paper converting"
    },
    {
        "value": 22362,
        "NOCCode": "9435",
        "JobTitle": "slitter-creaser operator – paper converting"
    },
    {
        "value": 22363,
        "NOCCode": "9435",
        "JobTitle": "slitter-scorer – paper converting"
    },
    {
        "value": 22364,
        "NOCCode": "9435",
        "JobTitle": "slurry mixer"
    },
    {
        "value": 22365,
        "NOCCode": "9435",
        "JobTitle": "stayer machine tender – paper converting"
    },
    {
        "value": 22366,
        "NOCCode": "9435",
        "JobTitle": "tag machine tender – paper converting"
    },
    {
        "value": 22367,
        "NOCCode": "9435",
        "JobTitle": "tender, diaper-folding machine – paper converting"
    },
    {
        "value": 22368,
        "NOCCode": "9435",
        "JobTitle": "tender, drill punch – paper converting"
    },
    {
        "value": 22369,
        "NOCCode": "9435",
        "JobTitle": "tender, envelope flap-button machine – paper converting"
    },
    {
        "value": 22370,
        "NOCCode": "9435",
        "JobTitle": "tender, envelope-flap-gumming machine – paper converting"
    },
    {
        "value": 22371,
        "NOCCode": "9435",
        "JobTitle": "tender, gumming machine – paper converting"
    },
    {
        "value": 22372,
        "NOCCode": "9435",
        "JobTitle": "tender, paper twister – paper converting"
    },
    {
        "value": 22373,
        "NOCCode": "9435",
        "JobTitle": "tender, paper-punching press – paper converting"
    },
    {
        "value": 22374,
        "NOCCode": "9435",
        "JobTitle": "tender, roll-cutting machine – paper converting"
    },
    {
        "value": 22375,
        "NOCCode": "9435",
        "JobTitle": "tender, rolling machine – paper converting"
    },
    {
        "value": 22376,
        "NOCCode": "9435",
        "JobTitle": "tender, roll-slitting machine – paper converting"
    },
    {
        "value": 22377,
        "NOCCode": "9435",
        "JobTitle": "tender, sealing machine – paper converting"
    },
    {
        "value": 22378,
        "NOCCode": "9435",
        "JobTitle": "tube machine operator – paper converting"
    },
    {
        "value": 22379,
        "NOCCode": "9435",
        "JobTitle": "tube maker, machine – paper converting"
    },
    {
        "value": 22380,
        "NOCCode": "9435",
        "JobTitle": "tuber, machine – paper converting"
    },
    {
        "value": 22381,
        "NOCCode": "9435",
        "JobTitle": "varnishing machine tender"
    },
    {
        "value": 22382,
        "NOCCode": "9435",
        "JobTitle": "wad compressor operator"
    },
    {
        "value": 22383,
        "NOCCode": "9435",
        "JobTitle": "wallpaper lapper"
    },
    {
        "value": 22384,
        "NOCCode": "9435",
        "JobTitle": "wallpaper lapper – paper converting"
    },
    {
        "value": 22385,
        "NOCCode": "9435",
        "JobTitle": "wallpaper reeler"
    },
    {
        "value": 22386,
        "NOCCode": "9435",
        "JobTitle": "wallpaper reeler – paper converting"
    },
    {
        "value": 22387,
        "NOCCode": "9435",
        "JobTitle": "winder, cones – paper converting"
    },
    {
        "value": 22388,
        "NOCCode": "9435",
        "JobTitle": "winder, cores – paper converting"
    },
    {
        "value": 22389,
        "NOCCode": "9435",
        "JobTitle": "winder, paper cones – paper converting"
    },
    {
        "value": 22390,
        "NOCCode": "9436",
        "JobTitle": "board grader – wood processing"
    },
    {
        "value": 22391,
        "NOCCode": "9436",
        "JobTitle": "flooring grader – wood processing"
    },
    {
        "value": 22392,
        "NOCCode": "9436",
        "JobTitle": "grader – wood processing"
    },
    {
        "value": 22393,
        "NOCCode": "9436",
        "JobTitle": "grader tallyman/woman – wood processing"
    },
    {
        "value": 22394,
        "NOCCode": "9436",
        "JobTitle": "grader, board – wood processing"
    },
    {
        "value": 22395,
        "NOCCode": "9436",
        "JobTitle": "grader, lumber"
    },
    {
        "value": 22396,
        "NOCCode": "9436",
        "JobTitle": "grader, particleboards – wood processing"
    },
    {
        "value": 22397,
        "NOCCode": "9436",
        "JobTitle": "grader, plywood – wood processing"
    },
    {
        "value": 22398,
        "NOCCode": "9436",
        "JobTitle": "grader, veneer – wood processing"
    },
    {
        "value": 22399,
        "NOCCode": "9436",
        "JobTitle": "grader, veneer and plywood – wood processing"
    },
    {
        "value": 22400,
        "NOCCode": "9436",
        "JobTitle": "grader, wood processing"
    },
    {
        "value": 22401,
        "NOCCode": "9436",
        "JobTitle": "green lumber grader"
    },
    {
        "value": 22402,
        "NOCCode": "9436",
        "JobTitle": "inspector – wood processing"
    },
    {
        "value": 22403,
        "NOCCode": "9436",
        "JobTitle": "inspector, plywood – wood processing"
    },
    {
        "value": 22404,
        "NOCCode": "9436",
        "JobTitle": "inspector, shakes – wood processing"
    },
    {
        "value": 22405,
        "NOCCode": "9436",
        "JobTitle": "inspector, treated poles – wood processing"
    },
    {
        "value": 22406,
        "NOCCode": "9436",
        "JobTitle": "lumber grader"
    },
    {
        "value": 22407,
        "NOCCode": "9436",
        "JobTitle": "lumber inspector"
    },
    {
        "value": 22408,
        "NOCCode": "9436",
        "JobTitle": "lumber marker"
    },
    {
        "value": 22409,
        "NOCCode": "9436",
        "JobTitle": "marker, lumber"
    },
    {
        "value": 22410,
        "NOCCode": "9436",
        "JobTitle": "panel inspector – wood processing"
    },
    {
        "value": 22411,
        "NOCCode": "9436",
        "JobTitle": "particleboard grader – wood processing"
    },
    {
        "value": 22412,
        "NOCCode": "9436",
        "JobTitle": "planer grader – wood processing"
    },
    {
        "value": 22413,
        "NOCCode": "9436",
        "JobTitle": "planer mill grader – wood processing"
    },
    {
        "value": 22414,
        "NOCCode": "9436",
        "JobTitle": "plywood and veneer matcher – wood processing"
    },
    {
        "value": 22415,
        "NOCCode": "9436",
        "JobTitle": "plywood grader"
    },
    {
        "value": 22416,
        "NOCCode": "9436",
        "JobTitle": "plywood grader – wood processing"
    },
    {
        "value": 22417,
        "NOCCode": "9436",
        "JobTitle": "plywood inspector"
    },
    {
        "value": 22418,
        "NOCCode": "9436",
        "JobTitle": "plywood inspector – wood processing"
    },
    {
        "value": 22419,
        "NOCCode": "9436",
        "JobTitle": "pole inspector – wood processing"
    },
    {
        "value": 22420,
        "NOCCode": "9436",
        "JobTitle": "shake inspector – wood processing"
    },
    {
        "value": 22421,
        "NOCCode": "9436",
        "JobTitle": "shingle inspector – wood processing"
    },
    {
        "value": 22422,
        "NOCCode": "9436",
        "JobTitle": "stake grader – wood processing"
    },
    {
        "value": 22423,
        "NOCCode": "9436",
        "JobTitle": "tie and timber inspector"
    },
    {
        "value": 22424,
        "NOCCode": "9436",
        "JobTitle": "treated pole tester – wood processing"
    },
    {
        "value": 22425,
        "NOCCode": "9436",
        "JobTitle": "veneer and plywood grader – wood processing"
    },
    {
        "value": 22426,
        "NOCCode": "9436",
        "JobTitle": "veneer grader"
    },
    {
        "value": 22427,
        "NOCCode": "9436",
        "JobTitle": "veneer grader – wood processing"
    },
    {
        "value": 22428,
        "NOCCode": "9436",
        "JobTitle": "wood marker – wood processing"
    },
    {
        "value": 22429,
        "NOCCode": "9436",
        "JobTitle": "wood measurer"
    },
    {
        "value": 22430,
        "NOCCode": "9436",
        "JobTitle": "wood measurer – wood processing"
    },
    {
        "value": 22431,
        "NOCCode": "9436",
        "JobTitle": "wood processing grader"
    },
    {
        "value": 22432,
        "NOCCode": "9436",
        "JobTitle": "wood processing inspector"
    },
    {
        "value": 22433,
        "NOCCode": "9437",
        "JobTitle": "adzing and boring machine operator"
    },
    {
        "value": 22434,
        "NOCCode": "9437",
        "JobTitle": "automatic nailing machine operator – woodworking"
    },
    {
        "value": 22435,
        "NOCCode": "9437",
        "JobTitle": "automatic shaper operator – woodworking"
    },
    {
        "value": 22436,
        "NOCCode": "9437",
        "JobTitle": "band resaw operator – woodworking"
    },
    {
        "value": 22437,
        "NOCCode": "9437",
        "JobTitle": "band ripsaw operator – woodworking"
    },
    {
        "value": 22438,
        "NOCCode": "9437",
        "JobTitle": "band saw operator – woodworking"
    },
    {
        "value": 22439,
        "NOCCode": "9437",
        "JobTitle": "band saw tender – woodworking"
    },
    {
        "value": 22440,
        "NOCCode": "9437",
        "JobTitle": "band scroll saw operator – woodworking"
    },
    {
        "value": 22441,
        "NOCCode": "9437",
        "JobTitle": "banding machine tender – woodworking"
    },
    {
        "value": 22442,
        "NOCCode": "9437",
        "JobTitle": "band-sawing machine tender – woodworking"
    },
    {
        "value": 22443,
        "NOCCode": "9437",
        "JobTitle": "barrel assembler"
    },
    {
        "value": 22444,
        "NOCCode": "9437",
        "JobTitle": "barrel cooper – woodworking"
    },
    {
        "value": 22445,
        "NOCCode": "9437",
        "JobTitle": "barrel end fitter, wood – woodworking"
    },
    {
        "value": 22446,
        "NOCCode": "9437",
        "JobTitle": "barrel end machine tender – woodworking"
    },
    {
        "value": 22447,
        "NOCCode": "9437",
        "JobTitle": "barrel end sawyer"
    },
    {
        "value": 22448,
        "NOCCode": "9437",
        "JobTitle": "barrel heading sawyer"
    },
    {
        "value": 22449,
        "NOCCode": "9437",
        "JobTitle": "barrel-header fitter, wood – woodworking"
    },
    {
        "value": 22450,
        "NOCCode": "9437",
        "JobTitle": "basket machine operator – woodworking"
    },
    {
        "value": 22451,
        "NOCCode": "9437",
        "JobTitle": "belt sander – woodworking"
    },
    {
        "value": 22452,
        "NOCCode": "9437",
        "JobTitle": "bench machine operator – woodworking"
    },
    {
        "value": 22453,
        "NOCCode": "9437",
        "JobTitle": "bender, lacrosse sticks – woodworking"
    },
    {
        "value": 22454,
        "NOCCode": "9437",
        "JobTitle": "billiard cue maker"
    },
    {
        "value": 22455,
        "NOCCode": "9437",
        "JobTitle": "borer – woodworking"
    },
    {
        "value": 22456,
        "NOCCode": "9437",
        "JobTitle": "boring machine operator – woodworking"
    },
    {
        "value": 22457,
        "NOCCode": "9437",
        "JobTitle": "bowl blank boring and finishing machine operator"
    },
    {
        "value": 22458,
        "NOCCode": "9437",
        "JobTitle": "box blank repairer – woodworking"
    },
    {
        "value": 22459,
        "NOCCode": "9437",
        "JobTitle": "briar-bowl turner – woodworking"
    },
    {
        "value": 22460,
        "NOCCode": "9437",
        "JobTitle": "bucket chucker – woodworking"
    },
    {
        "value": 22461,
        "NOCCode": "9437",
        "JobTitle": "bucket lathe operator"
    },
    {
        "value": 22462,
        "NOCCode": "9437",
        "JobTitle": "carver operator – woodworking"
    },
    {
        "value": 22463,
        "NOCCode": "9437",
        "JobTitle": "carver, machine – woodworking"
    },
    {
        "value": 22464,
        "NOCCode": "9437",
        "JobTitle": "carving machine operator – woodworking"
    },
    {
        "value": 22465,
        "NOCCode": "9437",
        "JobTitle": "cask maker"
    },
    {
        "value": 22466,
        "NOCCode": "9437",
        "JobTitle": "chair leveler – woodworking"
    },
    {
        "value": 22467,
        "NOCCode": "9437",
        "JobTitle": "chair seat planer tender"
    },
    {
        "value": 22468,
        "NOCCode": "9437",
        "JobTitle": "checkerer, gunstocks"
    },
    {
        "value": 22469,
        "NOCCode": "9437",
        "JobTitle": "checkerer, small arms"
    },
    {
        "value": 22470,
        "NOCCode": "9437",
        "JobTitle": "chucking and boring machine operator – woodworking"
    },
    {
        "value": 22471,
        "NOCCode": "9437",
        "JobTitle": "chucking machine operator – woodworking"
    },
    {
        "value": 22472,
        "NOCCode": "9437",
        "JobTitle": "circular saw operator – woodworking"
    },
    {
        "value": 22473,
        "NOCCode": "9437",
        "JobTitle": "clamping machine tender – woodworking"
    },
    {
        "value": 22474,
        "NOCCode": "9437",
        "JobTitle": "clean-up sander – woodworking"
    },
    {
        "value": 22475,
        "NOCCode": "9437",
        "JobTitle": "cleat notcher – woodworking"
    },
    {
        "value": 22476,
        "NOCCode": "9437",
        "JobTitle": "CNC (computer numerically controlled) band saw operator"
    },
    {
        "value": 22477,
        "NOCCode": "9437",
        "JobTitle": "CNC (computer numerically controlled) woodworking machine tool operator"
    },
    {
        "value": 22478,
        "NOCCode": "9437",
        "JobTitle": "cold press tender – woodworking"
    },
    {
        "value": 22479,
        "NOCCode": "9437",
        "JobTitle": "computer numerically controlled (CNC) band saw operator"
    },
    {
        "value": 22480,
        "NOCCode": "9437",
        "JobTitle": "computerized panel saw tender"
    },
    {
        "value": 22481,
        "NOCCode": "9437",
        "JobTitle": "contour sander operator – woodworking"
    },
    {
        "value": 22482,
        "NOCCode": "9437",
        "JobTitle": "cooper header – woodworking"
    },
    {
        "value": 22483,
        "NOCCode": "9437",
        "JobTitle": "cooper header operator – wood"
    },
    {
        "value": 22484,
        "NOCCode": "9437",
        "JobTitle": "cooper operator – woodworking"
    },
    {
        "value": 22485,
        "NOCCode": "9437",
        "JobTitle": "cooperage heading machine operator – woodworking"
    },
    {
        "value": 22486,
        "NOCCode": "9437",
        "JobTitle": "cooperage heading sawyer"
    },
    {
        "value": 22487,
        "NOCCode": "9437",
        "JobTitle": "cooperage setter-up – woodworking"
    },
    {
        "value": 22488,
        "NOCCode": "9437",
        "JobTitle": "cooperage set-up man/woman – woodworking"
    },
    {
        "value": 22489,
        "NOCCode": "9437",
        "JobTitle": "cooper's head-saw operator – woodworking"
    },
    {
        "value": 22490,
        "NOCCode": "9437",
        "JobTitle": "copy lathe tender"
    },
    {
        "value": 22491,
        "NOCCode": "9437",
        "JobTitle": "corrugated fastener driver – woodworking"
    },
    {
        "value": 22492,
        "NOCCode": "9437",
        "JobTitle": "crosscut saw operator – woodworking"
    },
    {
        "value": 22493,
        "NOCCode": "9437",
        "JobTitle": "croze machine operator – woodworking"
    },
    {
        "value": 22494,
        "NOCCode": "9437",
        "JobTitle": "custom cue maker and repairer"
    },
    {
        "value": 22495,
        "NOCCode": "9437",
        "JobTitle": "custom cue maker and repairman/woman"
    },
    {
        "value": 22496,
        "NOCCode": "9437",
        "JobTitle": "cut-off saw operator – woodworking"
    },
    {
        "value": 22497,
        "NOCCode": "9437",
        "JobTitle": "cutter, disks – wood products manufacturing"
    },
    {
        "value": 22498,
        "NOCCode": "9437",
        "JobTitle": "cutter, mouldings – wood products manufacturing"
    },
    {
        "value": 22499,
        "NOCCode": "9437",
        "JobTitle": "cutter, stock – woodworking"
    },
    {
        "value": 22500,
        "NOCCode": "9437",
        "JobTitle": "cylinder saw tender – woodworking"
    },
    {
        "value": 22501,
        "NOCCode": "9437",
        "JobTitle": "cylinder-sander feeder – woodworking"
    },
    {
        "value": 22502,
        "NOCCode": "9437",
        "JobTitle": "dado operator – woodworking"
    },
    {
        "value": 22503,
        "NOCCode": "9437",
        "JobTitle": "dado operator, overhead – woodworking"
    },
    {
        "value": 22504,
        "NOCCode": "9437",
        "JobTitle": "dado saw operator – woodworking"
    },
    {
        "value": 22505,
        "NOCCode": "9437",
        "JobTitle": "defect trimmer – woodworking"
    },
    {
        "value": 22506,
        "NOCCode": "9437",
        "JobTitle": "desk sander – woodworking"
    },
    {
        "value": 22507,
        "NOCCode": "9437",
        "JobTitle": "detail shaper – woodworking"
    },
    {
        "value": 22508,
        "NOCCode": "9437",
        "JobTitle": "dimension planer operator – woodworking"
    },
    {
        "value": 22509,
        "NOCCode": "9437",
        "JobTitle": "disk cutter – wood products"
    },
    {
        "value": 22510,
        "NOCCode": "9437",
        "JobTitle": "door-hang machine operator – woodworking"
    },
    {
        "value": 22511,
        "NOCCode": "9437",
        "JobTitle": "dovetail machine operator – woodworking"
    },
    {
        "value": 22512,
        "NOCCode": "9437",
        "JobTitle": "dowel machine operator – woodworking"
    },
    {
        "value": 22513,
        "NOCCode": "9437",
        "JobTitle": "dowel machine set-up operator – woodworking"
    },
    {
        "value": 22514,
        "NOCCode": "9437",
        "JobTitle": "dowel machine tender"
    },
    {
        "value": 22515,
        "NOCCode": "9437",
        "JobTitle": "dowel pointer – woodworking"
    },
    {
        "value": 22516,
        "NOCCode": "9437",
        "JobTitle": "dowelling machine operator – woodworking"
    },
    {
        "value": 22517,
        "NOCCode": "9437",
        "JobTitle": "dowelling machine set-up operator – woodworking"
    },
    {
        "value": 22518,
        "NOCCode": "9437",
        "JobTitle": "dowelling machine tender – woodworking"
    },
    {
        "value": 22519,
        "NOCCode": "9437",
        "JobTitle": "dowel-making machine operator – woodworking"
    },
    {
        "value": 22520,
        "NOCCode": "9437",
        "JobTitle": "dowel-making machine set-up operator – woodworking"
    },
    {
        "value": 22521,
        "NOCCode": "9437",
        "JobTitle": "dowel-making machine tender – woodworking"
    },
    {
        "value": 22522,
        "NOCCode": "9437",
        "JobTitle": "drill operator – woodworking"
    },
    {
        "value": 22523,
        "NOCCode": "9437",
        "JobTitle": "drill press operator – woodworking"
    },
    {
        "value": 22524,
        "NOCCode": "9437",
        "JobTitle": "driller, wood – woodworking"
    },
    {
        "value": 22525,
        "NOCCode": "9437",
        "JobTitle": "drum sander – woodworking"
    },
    {
        "value": 22526,
        "NOCCode": "9437",
        "JobTitle": "drum sander operator – woodworking"
    },
    {
        "value": 22527,
        "NOCCode": "9437",
        "JobTitle": "edge bander – woodworking"
    },
    {
        "value": 22528,
        "NOCCode": "9437",
        "JobTitle": "edge bander operator – woodworking"
    },
    {
        "value": 22529,
        "NOCCode": "9437",
        "JobTitle": "edger, table tops – woodworking"
    },
    {
        "value": 22530,
        "NOCCode": "9437",
        "JobTitle": "electronic gluing machine tender – woodworking"
    },
    {
        "value": 22531,
        "NOCCode": "9437",
        "JobTitle": "embosser – woodworking"
    },
    {
        "value": 22532,
        "NOCCode": "9437",
        "JobTitle": "embossing machine operator – woodworking"
    },
    {
        "value": 22533,
        "NOCCode": "9437",
        "JobTitle": "embossing machine tender – woodworking"
    },
    {
        "value": 22534,
        "NOCCode": "9437",
        "JobTitle": "end bore operator – woodworking"
    },
    {
        "value": 22535,
        "NOCCode": "9437",
        "JobTitle": "end matcher, sash and door – woodworking"
    },
    {
        "value": 22536,
        "NOCCode": "9437",
        "JobTitle": "end trimming and boring machine operator – woodworking"
    },
    {
        "value": 22537,
        "NOCCode": "9437",
        "JobTitle": "end-boring machine operator – woodworking"
    },
    {
        "value": 22538,
        "NOCCode": "9437",
        "JobTitle": "equalizer operator – woodworking"
    },
    {
        "value": 22539,
        "NOCCode": "9437",
        "JobTitle": "facer operator – woodworking"
    },
    {
        "value": 22540,
        "NOCCode": "9437",
        "JobTitle": "fastener-driver operator – woodworking"
    },
    {
        "value": 22541,
        "NOCCode": "9437",
        "JobTitle": "feeder, cylinder sander – woodworking"
    },
    {
        "value": 22542,
        "NOCCode": "9437",
        "JobTitle": "feeder, planer – woodworking"
    },
    {
        "value": 22543,
        "NOCCode": "9437",
        "JobTitle": "feeder, sanding machine – woodworking"
    },
    {
        "value": 22544,
        "NOCCode": "9437",
        "JobTitle": "finish planer operator – woodworking"
    },
    {
        "value": 22545,
        "NOCCode": "9437",
        "JobTitle": "finisher, packing trunk panels – woodworking"
    },
    {
        "value": 22546,
        "NOCCode": "9437",
        "JobTitle": "fitter, gunstocks – woodworking"
    },
    {
        "value": 22547,
        "NOCCode": "9437",
        "JobTitle": "flarer, wood products"
    },
    {
        "value": 22548,
        "NOCCode": "9437",
        "JobTitle": "fluting machine operator – woodworking"
    },
    {
        "value": 22549,
        "NOCCode": "9437",
        "JobTitle": "frame table operator – woodworking"
    },
    {
        "value": 22550,
        "NOCCode": "9437",
        "JobTitle": "framer, poles – wood products"
    },
    {
        "value": 22551,
        "NOCCode": "9437",
        "JobTitle": "frazer – woodworking"
    },
    {
        "value": 22552,
        "NOCCode": "9437",
        "JobTitle": "frazing machine operator – woodworking"
    },
    {
        "value": 22553,
        "NOCCode": "9437",
        "JobTitle": "gang ripsaw operator – woodworking"
    },
    {
        "value": 22554,
        "NOCCode": "9437",
        "JobTitle": "gang sawyer – woodworking"
    },
    {
        "value": 22555,
        "NOCCode": "9437",
        "JobTitle": "glue jointer operator – woodworking"
    },
    {
        "value": 22556,
        "NOCCode": "9437",
        "JobTitle": "glue machine operator – woodworking"
    },
    {
        "value": 22557,
        "NOCCode": "9437",
        "JobTitle": "glue machine tender – woodworking"
    },
    {
        "value": 22558,
        "NOCCode": "9437",
        "JobTitle": "glue-bonding machine operator – woodworking"
    },
    {
        "value": 22559,
        "NOCCode": "9437",
        "JobTitle": "glue-bonding machine tender – woodworking"
    },
    {
        "value": 22560,
        "NOCCode": "9437",
        "JobTitle": "gouger, lacrosse sticks – woodworking"
    },
    {
        "value": 22561,
        "NOCCode": "9437",
        "JobTitle": "grader-operator, ripsaw – woodworking"
    },
    {
        "value": 22562,
        "NOCCode": "9437",
        "JobTitle": "groover – woodworking"
    },
    {
        "value": 22563,
        "NOCCode": "9437",
        "JobTitle": "groover operator – woodworking"
    },
    {
        "value": 22564,
        "NOCCode": "9437",
        "JobTitle": "grooving machine operator – woodworking"
    },
    {
        "value": 22565,
        "NOCCode": "9437",
        "JobTitle": "gunstock checkerer"
    },
    {
        "value": 22566,
        "NOCCode": "9437",
        "JobTitle": "gunstock fitter – woodworking"
    },
    {
        "value": 22567,
        "NOCCode": "9437",
        "JobTitle": "gunstock shaper – woodworking"
    },
    {
        "value": 22568,
        "NOCCode": "9437",
        "JobTitle": "hat block maker – woodworking"
    },
    {
        "value": 22569,
        "NOCCode": "9437",
        "JobTitle": "header operator, wood cooperage"
    },
    {
        "value": 22570,
        "NOCCode": "9437",
        "JobTitle": "header, wooden barrel"
    },
    {
        "value": 22571,
        "NOCCode": "9437",
        "JobTitle": "heading machine operator, cooperage – woodworking"
    },
    {
        "value": 22572,
        "NOCCode": "9437",
        "JobTitle": "headings sawyer, cooperage"
    },
    {
        "value": 22573,
        "NOCCode": "9437",
        "JobTitle": "hole saw tender – woodworking"
    },
    {
        "value": 22574,
        "NOCCode": "9437",
        "JobTitle": "hoop-driver operator – woodworking"
    },
    {
        "value": 22575,
        "NOCCode": "9437",
        "JobTitle": "hoop-driving machine operator – woodworking"
    },
    {
        "value": 22576,
        "NOCCode": "9437",
        "JobTitle": "horizontal boring machine operator – woodworking"
    },
    {
        "value": 22577,
        "NOCCode": "9437",
        "JobTitle": "horizontal resaw operator – woodworking"
    },
    {
        "value": 22578,
        "NOCCode": "9437",
        "JobTitle": "hot press tender – woodworking"
    },
    {
        "value": 22579,
        "NOCCode": "9437",
        "JobTitle": "hydraulic press tender – woodworking"
    },
    {
        "value": 22580,
        "NOCCode": "9437",
        "JobTitle": "jigsaw operator – woodworking"
    },
    {
        "value": 22581,
        "NOCCode": "9437",
        "JobTitle": "jointer – woodworking"
    },
    {
        "value": 22582,
        "NOCCode": "9437",
        "JobTitle": "jointer machine tender, panels – woodworking"
    },
    {
        "value": 22583,
        "NOCCode": "9437",
        "JobTitle": "jointer operator – woodworking"
    },
    {
        "value": 22584,
        "NOCCode": "9437",
        "JobTitle": "jointer, staves – woodworking"
    },
    {
        "value": 22585,
        "NOCCode": "9437",
        "JobTitle": "jointing machine operator – woodworking"
    },
    {
        "value": 22586,
        "NOCCode": "9437",
        "JobTitle": "lacrosse stick bender – woodworking"
    },
    {
        "value": 22587,
        "NOCCode": "9437",
        "JobTitle": "lacrosse stick gouger – woodworking"
    },
    {
        "value": 22588,
        "NOCCode": "9437",
        "JobTitle": "lacrosse stick maker – woodworking"
    },
    {
        "value": 22589,
        "NOCCode": "9437",
        "JobTitle": "lacrosse stick planer – woodworking"
    },
    {
        "value": 22590,
        "NOCCode": "9437",
        "JobTitle": "lacrosse stick shaper – woodworking"
    },
    {
        "value": 22591,
        "NOCCode": "9437",
        "JobTitle": "lag bolt machine tender – woodworking"
    },
    {
        "value": 22592,
        "NOCCode": "9437",
        "JobTitle": "laminated bowl machine tender – woodworking"
    },
    {
        "value": 22593,
        "NOCCode": "9437",
        "JobTitle": "laminating press tender – woodworking"
    },
    {
        "value": 22594,
        "NOCCode": "9437",
        "JobTitle": "laminator, plastics – wood furniture manufacturing"
    },
    {
        "value": 22595,
        "NOCCode": "9437",
        "JobTitle": "last remodeler – woodworking"
    },
    {
        "value": 22596,
        "NOCCode": "9437",
        "JobTitle": "last repairman/woman – woodworking"
    },
    {
        "value": 22597,
        "NOCCode": "9437",
        "JobTitle": "last trimmer – woodworking"
    },
    {
        "value": 22598,
        "NOCCode": "9437",
        "JobTitle": "last turner – woodworking"
    },
    {
        "value": 22599,
        "NOCCode": "9437",
        "JobTitle": "last-lathe tender – woodworking"
    },
    {
        "value": 22600,
        "NOCCode": "9437",
        "JobTitle": "lathe operator – woodworking"
    },
    {
        "value": 22601,
        "NOCCode": "9437",
        "JobTitle": "lathe operator, toothpicks"
    },
    {
        "value": 22602,
        "NOCCode": "9437",
        "JobTitle": "lathe sander – woodworking"
    },
    {
        "value": 22603,
        "NOCCode": "9437",
        "JobTitle": "lathe turner – woodworking"
    },
    {
        "value": 22604,
        "NOCCode": "9437",
        "JobTitle": "lay-out marker – woodworking"
    },
    {
        "value": 22605,
        "NOCCode": "9437",
        "JobTitle": "leveller operator – woodworking"
    },
    {
        "value": 22606,
        "NOCCode": "9437",
        "JobTitle": "line and frame pole worker – wood products"
    },
    {
        "value": 22607,
        "NOCCode": "9437",
        "JobTitle": "looping machine tender – wood products manufacturing"
    },
    {
        "value": 22608,
        "NOCCode": "9437",
        "JobTitle": "machine carver – woodworking"
    },
    {
        "value": 22609,
        "NOCCode": "9437",
        "JobTitle": "machine jointer, panels – woodworking"
    },
    {
        "value": 22610,
        "NOCCode": "9437",
        "JobTitle": "machine operator, woodworking"
    },
    {
        "value": 22611,
        "NOCCode": "9437",
        "JobTitle": "machine sander – woodworking"
    },
    {
        "value": 22612,
        "NOCCode": "9437",
        "JobTitle": "machine setter, woodworking"
    },
    {
        "value": 22613,
        "NOCCode": "9437",
        "JobTitle": "mitre saw tender – woodworking"
    },
    {
        "value": 22614,
        "NOCCode": "9437",
        "JobTitle": "mortiser operator – woodworking"
    },
    {
        "value": 22615,
        "NOCCode": "9437",
        "JobTitle": "mortising machine operator – woodworking"
    },
    {
        "value": 22616,
        "NOCCode": "9437",
        "JobTitle": "moulder – wood products manufacturing"
    },
    {
        "value": 22617,
        "NOCCode": "9437",
        "JobTitle": "moulder – woodworking"
    },
    {
        "value": 22618,
        "NOCCode": "9437",
        "JobTitle": "moulder operator – woodworking"
    },
    {
        "value": 22619,
        "NOCCode": "9437",
        "JobTitle": "moulding cutter – wood products manufacturing"
    },
    {
        "value": 22620,
        "NOCCode": "9437",
        "JobTitle": "moulding machine operator – woodworking"
    },
    {
        "value": 22621,
        "NOCCode": "9437",
        "JobTitle": "nailing machine operator – woodworking"
    },
    {
        "value": 22622,
        "NOCCode": "9437",
        "JobTitle": "notcher operator – woodworking"
    },
    {
        "value": 22623,
        "NOCCode": "9437",
        "JobTitle": "notching saw operator – woodworking"
    },
    {
        "value": 22624,
        "NOCCode": "9437",
        "JobTitle": "oar maker – woodworking"
    },
    {
        "value": 22625,
        "NOCCode": "9437",
        "JobTitle": "oar shaper – woodworking"
    },
    {
        "value": 22626,
        "NOCCode": "9437",
        "JobTitle": "operator, automatic nailing machine – woodworking"
    },
    {
        "value": 22627,
        "NOCCode": "9437",
        "JobTitle": "operator, automatic shaper – woodworking"
    },
    {
        "value": 22628,
        "NOCCode": "9437",
        "JobTitle": "operator, band saw – woodworking"
    },
    {
        "value": 22629,
        "NOCCode": "9437",
        "JobTitle": "operator, belt sander – woodworking"
    },
    {
        "value": 22630,
        "NOCCode": "9437",
        "JobTitle": "operator, boring machine – woodworking"
    },
    {
        "value": 22631,
        "NOCCode": "9437",
        "JobTitle": "operator, carver – woodworking"
    },
    {
        "value": 22632,
        "NOCCode": "9437",
        "JobTitle": "operator, carving machine – woodworking"
    },
    {
        "value": 22633,
        "NOCCode": "9437",
        "JobTitle": "operator, chucking and boring machine – woodworking"
    },
    {
        "value": 22634,
        "NOCCode": "9437",
        "JobTitle": "operator, chucking machine – woodworking"
    },
    {
        "value": 22635,
        "NOCCode": "9437",
        "JobTitle": "operator, circular saw – woodworking"
    },
    {
        "value": 22636,
        "NOCCode": "9437",
        "JobTitle": "operator, CNC (computer numerically controlled) woodworking machine tool"
    },
    {
        "value": 22637,
        "NOCCode": "9437",
        "JobTitle": "operator, cooper's head saw – woodworking"
    },
    {
        "value": 22638,
        "NOCCode": "9437",
        "JobTitle": "operator, cork-splitting machine"
    },
    {
        "value": 22639,
        "NOCCode": "9437",
        "JobTitle": "operator, crosscut saw – woodworking"
    },
    {
        "value": 22640,
        "NOCCode": "9437",
        "JobTitle": "operator, cut-off saw – woodworking"
    },
    {
        "value": 22641,
        "NOCCode": "9437",
        "JobTitle": "operator, dado – woodworking"
    },
    {
        "value": 22642,
        "NOCCode": "9437",
        "JobTitle": "operator, dado saw – woodworking"
    },
    {
        "value": 22643,
        "NOCCode": "9437",
        "JobTitle": "operator, dimension planer – woodworking"
    },
    {
        "value": 22644,
        "NOCCode": "9437",
        "JobTitle": "operator, door-hanging machine – woodworking"
    },
    {
        "value": 22645,
        "NOCCode": "9437",
        "JobTitle": "operator, dovetail machine – woodworking"
    },
    {
        "value": 22646,
        "NOCCode": "9437",
        "JobTitle": "operator, dowel machine – woodworking"
    },
    {
        "value": 22647,
        "NOCCode": "9437",
        "JobTitle": "operator, drill – woodworking"
    },
    {
        "value": 22648,
        "NOCCode": "9437",
        "JobTitle": "operator, drum sander – woodworking"
    },
    {
        "value": 22649,
        "NOCCode": "9437",
        "JobTitle": "operator, edge bander – woodworking"
    },
    {
        "value": 22650,
        "NOCCode": "9437",
        "JobTitle": "operator, frame table – woodworking"
    },
    {
        "value": 22651,
        "NOCCode": "9437",
        "JobTitle": "operator, gang ripsaw – woodworking"
    },
    {
        "value": 22652,
        "NOCCode": "9437",
        "JobTitle": "operator, glue machine – woodworking"
    },
    {
        "value": 22653,
        "NOCCode": "9437",
        "JobTitle": "operator, grooving machine – woodworking"
    },
    {
        "value": 22654,
        "NOCCode": "9437",
        "JobTitle": "operator, horizontal boring machine – woodworking"
    },
    {
        "value": 22655,
        "NOCCode": "9437",
        "JobTitle": "operator, jigsaw – woodworking"
    },
    {
        "value": 22656,
        "NOCCode": "9437",
        "JobTitle": "operator, leveller – woodworking"
    },
    {
        "value": 22657,
        "NOCCode": "9437",
        "JobTitle": "operator, mortiser – woodworking"
    },
    {
        "value": 22658,
        "NOCCode": "9437",
        "JobTitle": "operator, mortising machine – woodworking"
    },
    {
        "value": 22659,
        "NOCCode": "9437",
        "JobTitle": "operator, nailing machine – woodworking"
    },
    {
        "value": 22660,
        "NOCCode": "9437",
        "JobTitle": "operator, panel saw – woodworking"
    },
    {
        "value": 22661,
        "NOCCode": "9437",
        "JobTitle": "operator, planer – woodworking"
    },
    {
        "value": 22662,
        "NOCCode": "9437",
        "JobTitle": "operator, power saw – woodworking"
    },
    {
        "value": 22663,
        "NOCCode": "9437",
        "JobTitle": "operator, profiling shaper – woodworking"
    },
    {
        "value": 22664,
        "NOCCode": "9437",
        "JobTitle": "operator, radial-arm saw – woodworking"
    },
    {
        "value": 22665,
        "NOCCode": "9437",
        "JobTitle": "operator, rough mill – woodworking"
    },
    {
        "value": 22666,
        "NOCCode": "9437",
        "JobTitle": "operator, rough planer – woodworking"
    },
    {
        "value": 22667,
        "NOCCode": "9437",
        "JobTitle": "operator, router – woodworking"
    },
    {
        "value": 22668,
        "NOCCode": "9437",
        "JobTitle": "operator, saw – woodworking"
    },
    {
        "value": 22669,
        "NOCCode": "9437",
        "JobTitle": "operator, sawing machine – woodworking"
    },
    {
        "value": 22670,
        "NOCCode": "9437",
        "JobTitle": "operator, shaper – woodworking"
    },
    {
        "value": 22671,
        "NOCCode": "9437",
        "JobTitle": "operator, shaver – woodworking"
    },
    {
        "value": 22672,
        "NOCCode": "9437",
        "JobTitle": "operator, spindle carver – woodworking"
    },
    {
        "value": 22673,
        "NOCCode": "9437",
        "JobTitle": "operator, stave machine – woodworking"
    },
    {
        "value": 22674,
        "NOCCode": "9437",
        "JobTitle": "operator, surface planer – woodworking"
    },
    {
        "value": 22675,
        "NOCCode": "9437",
        "JobTitle": "operator, swing-type lathe – woodworking"
    },
    {
        "value": 22676,
        "NOCCode": "9437",
        "JobTitle": "operator, trim saw – woodworking"
    },
    {
        "value": 22677,
        "NOCCode": "9437",
        "JobTitle": "operator, trimming and boring machine – woodworking"
    },
    {
        "value": 22678,
        "NOCCode": "9437",
        "JobTitle": "operator, turning machine – woodworking"
    },
    {
        "value": 22679,
        "NOCCode": "9437",
        "JobTitle": "operator, wobble saw – woodworking"
    },
    {
        "value": 22680,
        "NOCCode": "9437",
        "JobTitle": "operator, wood lathe – woodworking"
    },
    {
        "value": 22681,
        "NOCCode": "9437",
        "JobTitle": "operator, wood saw – woodworking"
    },
    {
        "value": 22682,
        "NOCCode": "9437",
        "JobTitle": "operator, wood-carving machine"
    },
    {
        "value": 22683,
        "NOCCode": "9437",
        "JobTitle": "operator, wood-turning lathe – woodworking"
    },
    {
        "value": 22684,
        "NOCCode": "9437",
        "JobTitle": "overhead dado operator – woodworking"
    },
    {
        "value": 22685,
        "NOCCode": "9437",
        "JobTitle": "paddle maker – woodworking"
    },
    {
        "value": 22686,
        "NOCCode": "9437",
        "JobTitle": "panel finisher, packing trunks – woodworking"
    },
    {
        "value": 22687,
        "NOCCode": "9437",
        "JobTitle": "panel jointer machine tender – woodworking"
    },
    {
        "value": 22688,
        "NOCCode": "9437",
        "JobTitle": "panel jointer, machine – woodworking"
    },
    {
        "value": 22689,
        "NOCCode": "9437",
        "JobTitle": "panel raiser operator – woodworking"
    },
    {
        "value": 22690,
        "NOCCode": "9437",
        "JobTitle": "panel saw operator – woodworking"
    },
    {
        "value": 22691,
        "NOCCode": "9437",
        "JobTitle": "panel saw tender, computerized – woodworking"
    },
    {
        "value": 22692,
        "NOCCode": "9437",
        "JobTitle": "pencil-grooving machine tender"
    },
    {
        "value": 22693,
        "NOCCode": "9437",
        "JobTitle": "pencil-rounding machine tender"
    },
    {
        "value": 22694,
        "NOCCode": "9437",
        "JobTitle": "pin maker – wood products manufacturing"
    },
    {
        "value": 22695,
        "NOCCode": "9437",
        "JobTitle": "planer – woodworking"
    },
    {
        "value": 22696,
        "NOCCode": "9437",
        "JobTitle": "planer feeder – woodworking"
    },
    {
        "value": 22697,
        "NOCCode": "9437",
        "JobTitle": "planer operator – woodworking"
    },
    {
        "value": 22698,
        "NOCCode": "9437",
        "JobTitle": "planer tender – woodworking"
    },
    {
        "value": 22699,
        "NOCCode": "9437",
        "JobTitle": "planer, lacrosse sticks – woodworking"
    },
    {
        "value": 22700,
        "NOCCode": "9437",
        "JobTitle": "planer, shuttles – woodworking"
    },
    {
        "value": 22701,
        "NOCCode": "9437",
        "JobTitle": "plastic laminator – wood furniture manufacturing"
    },
    {
        "value": 22702,
        "NOCCode": "9437",
        "JobTitle": "pole framer – wood products"
    },
    {
        "value": 22703,
        "NOCCode": "9437",
        "JobTitle": "portable machine sander tender – woodworking"
    },
    {
        "value": 22704,
        "NOCCode": "9437",
        "JobTitle": "portable sawer – woodworking"
    },
    {
        "value": 22705,
        "NOCCode": "9437",
        "JobTitle": "power saw operator – woodworking"
    },
    {
        "value": 22706,
        "NOCCode": "9437",
        "JobTitle": "profile shaper operator – woodworking"
    },
    {
        "value": 22707,
        "NOCCode": "9437",
        "JobTitle": "radial-arm saw operator – woodworking"
    },
    {
        "value": 22708,
        "NOCCode": "9437",
        "JobTitle": "resaw operator – woodworking"
    },
    {
        "value": 22709,
        "NOCCode": "9437",
        "JobTitle": "rip and groove machine tender – woodworking"
    },
    {
        "value": 22710,
        "NOCCode": "9437",
        "JobTitle": "ripsaw grader-operator – woodworking"
    },
    {
        "value": 22711,
        "NOCCode": "9437",
        "JobTitle": "ripsaw matcher – woodworking"
    },
    {
        "value": 22712,
        "NOCCode": "9437",
        "JobTitle": "ripsaw operator – woodworking"
    },
    {
        "value": 22713,
        "NOCCode": "9437",
        "JobTitle": "rolling pin maker – woodworking"
    },
    {
        "value": 22714,
        "NOCCode": "9437",
        "JobTitle": "rough mill operator – woodworking"
    },
    {
        "value": 22715,
        "NOCCode": "9437",
        "JobTitle": "rough planer operator – woodworking"
    },
    {
        "value": 22716,
        "NOCCode": "9437",
        "JobTitle": "router operator – woodworking"
    },
    {
        "value": 22717,
        "NOCCode": "9437",
        "JobTitle": "saddle machine tender – woodworking"
    },
    {
        "value": 22718,
        "NOCCode": "9437",
        "JobTitle": "salvage sawyer – woodworking"
    },
    {
        "value": 22719,
        "NOCCode": "9437",
        "JobTitle": "sander, desks – woodworking"
    },
    {
        "value": 22720,
        "NOCCode": "9437",
        "JobTitle": "sander, machine – woodworking"
    },
    {
        "value": 22721,
        "NOCCode": "9437",
        "JobTitle": "sander, smoking pipes – woodworking"
    },
    {
        "value": 22722,
        "NOCCode": "9437",
        "JobTitle": "sander, veneer – woodworking"
    },
    {
        "value": 22723,
        "NOCCode": "9437",
        "JobTitle": "sander, wood – woodworking"
    },
    {
        "value": 22724,
        "NOCCode": "9437",
        "JobTitle": "sanding machine feeder – woodworking"
    },
    {
        "value": 22725,
        "NOCCode": "9437",
        "JobTitle": "sash and door end matcher – woodworking"
    },
    {
        "value": 22726,
        "NOCCode": "9437",
        "JobTitle": "saw operator – woodworking"
    },
    {
        "value": 22727,
        "NOCCode": "9437",
        "JobTitle": "saw operator, circular – woodworking"
    },
    {
        "value": 22728,
        "NOCCode": "9437",
        "JobTitle": "sawing machine operator – woodworking"
    },
    {
        "value": 22729,
        "NOCCode": "9437",
        "JobTitle": "sawing machine tender – woodworking"
    },
    {
        "value": 22730,
        "NOCCode": "9437",
        "JobTitle": "sawyer – woodworking"
    },
    {
        "value": 22731,
        "NOCCode": "9437",
        "JobTitle": "sawyer, cooperage headings"
    },
    {
        "value": 22732,
        "NOCCode": "9437",
        "JobTitle": "sawyer, salvage – woodworking"
    },
    {
        "value": 22733,
        "NOCCode": "9437",
        "JobTitle": "sawyer, staves – woodworking"
    },
    {
        "value": 22734,
        "NOCCode": "9437",
        "JobTitle": "sawyer, wood blocks – woodworking"
    },
    {
        "value": 22735,
        "NOCCode": "9437",
        "JobTitle": "scroll saw operator – woodworking"
    },
    {
        "value": 22736,
        "NOCCode": "9437",
        "JobTitle": "setter, woodworking machines"
    },
    {
        "value": 22737,
        "NOCCode": "9437",
        "JobTitle": "setter-up, wood cooperage – woodworking"
    },
    {
        "value": 22738,
        "NOCCode": "9437",
        "JobTitle": "set-up man/woman, cooperage – woodworking"
    },
    {
        "value": 22739,
        "NOCCode": "9437",
        "JobTitle": "set-up operator, dowel machine – woodworking"
    },
    {
        "value": 22740,
        "NOCCode": "9437",
        "JobTitle": "set-up operator, woodworking machine"
    },
    {
        "value": 22741,
        "NOCCode": "9437",
        "JobTitle": "shaper – wood products manufacturing"
    },
    {
        "value": 22742,
        "NOCCode": "9437",
        "JobTitle": "shaper – woodworking"
    },
    {
        "value": 22743,
        "NOCCode": "9437",
        "JobTitle": "shaper operator – woodworking"
    },
    {
        "value": 22744,
        "NOCCode": "9437",
        "JobTitle": "shaper, gunstocks – woodworking"
    },
    {
        "value": 22745,
        "NOCCode": "9437",
        "JobTitle": "shaper, lacrosse sticks – woodworking"
    },
    {
        "value": 22746,
        "NOCCode": "9437",
        "JobTitle": "shaper, oars – woodworking"
    },
    {
        "value": 22747,
        "NOCCode": "9437",
        "JobTitle": "shaver operator – woodworking"
    },
    {
        "value": 22748,
        "NOCCode": "9437",
        "JobTitle": "shaver tender – woodworking"
    },
    {
        "value": 22749,
        "NOCCode": "9437",
        "JobTitle": "shredder operator – woodworking"
    },
    {
        "value": 22750,
        "NOCCode": "9437",
        "JobTitle": "shuttle finisher – woodworking"
    },
    {
        "value": 22751,
        "NOCCode": "9437",
        "JobTitle": "shuttle maker – woodworking"
    },
    {
        "value": 22752,
        "NOCCode": "9437",
        "JobTitle": "shuttle planer – woodworking"
    },
    {
        "value": 22753,
        "NOCCode": "9437",
        "JobTitle": "shuttle shaper and tip inserter – woodworking"
    },
    {
        "value": 22754,
        "NOCCode": "9437",
        "JobTitle": "slat picker – woodworking"
    },
    {
        "value": 22755,
        "NOCCode": "9437",
        "JobTitle": "slat slicer – wood products"
    },
    {
        "value": 22756,
        "NOCCode": "9437",
        "JobTitle": "smoking pipe air hole driller"
    },
    {
        "value": 22757,
        "NOCCode": "9437",
        "JobTitle": "smoking pipe burler – woodworking"
    },
    {
        "value": 22758,
        "NOCCode": "9437",
        "JobTitle": "smoking pipe maker – woodworking"
    },
    {
        "value": 22759,
        "NOCCode": "9437",
        "JobTitle": "smoking pipe sander"
    },
    {
        "value": 22760,
        "NOCCode": "9437",
        "JobTitle": "spindle carver operator – woodworking"
    },
    {
        "value": 22761,
        "NOCCode": "9437",
        "JobTitle": "splitter – woodworking"
    },
    {
        "value": 22762,
        "NOCCode": "9437",
        "JobTitle": "splitting machine operator cork"
    },
    {
        "value": 22763,
        "NOCCode": "9437",
        "JobTitle": "squeezer tender – woodworking"
    },
    {
        "value": 22764,
        "NOCCode": "9437",
        "JobTitle": "stave jointer – woodworking"
    },
    {
        "value": 22765,
        "NOCCode": "9437",
        "JobTitle": "stave machine operator – woodworking"
    },
    {
        "value": 22766,
        "NOCCode": "9437",
        "JobTitle": "stave sawyer – woodworking"
    },
    {
        "value": 22767,
        "NOCCode": "9437",
        "JobTitle": "stave turner, wood – woodworking"
    },
    {
        "value": 22768,
        "NOCCode": "9437",
        "JobTitle": "stitcher operator – woodworking"
    },
    {
        "value": 22769,
        "NOCCode": "9437",
        "JobTitle": "stock cutter – woodworking"
    },
    {
        "value": 22770,
        "NOCCode": "9437",
        "JobTitle": "stummel trimmer – woodworking"
    },
    {
        "value": 22771,
        "NOCCode": "9437",
        "JobTitle": "stummel turner – woodworking"
    },
    {
        "value": 22772,
        "NOCCode": "9437",
        "JobTitle": "surface planer operator – woodworking"
    },
    {
        "value": 22773,
        "NOCCode": "9437",
        "JobTitle": "swing-type lathe operator – woodworking"
    },
    {
        "value": 22774,
        "NOCCode": "9437",
        "JobTitle": "table top edger – woodworking"
    },
    {
        "value": 22775,
        "NOCCode": "9437",
        "JobTitle": "table top laminator – woodworking"
    },
    {
        "value": 22776,
        "NOCCode": "9437",
        "JobTitle": "tender, dowel machine – woodworking"
    },
    {
        "value": 22777,
        "NOCCode": "9437",
        "JobTitle": "tender, dowelling machine – woodworking"
    },
    {
        "value": 22778,
        "NOCCode": "9437",
        "JobTitle": "tender, hole saw – woodworking"
    },
    {
        "value": 22779,
        "NOCCode": "9437",
        "JobTitle": "tender, mitre saw – woodworking"
    },
    {
        "value": 22780,
        "NOCCode": "9437",
        "JobTitle": "tender, portable sanding machine – woodworking"
    },
    {
        "value": 22781,
        "NOCCode": "9437",
        "JobTitle": "tender, portable saw – woodworking"
    },
    {
        "value": 22782,
        "NOCCode": "9437",
        "JobTitle": "tender, ripping and grooving machine – woodworking"
    },
    {
        "value": 22783,
        "NOCCode": "9437",
        "JobTitle": "tender, saddle machine – woodworking"
    },
    {
        "value": 22784,
        "NOCCode": "9437",
        "JobTitle": "tender, sawing machine – woodworking"
    },
    {
        "value": 22785,
        "NOCCode": "9437",
        "JobTitle": "tender, wood products machine"
    },
    {
        "value": 22786,
        "NOCCode": "9437",
        "JobTitle": "tenoner operator – woodworking"
    },
    {
        "value": 22787,
        "NOCCode": "9437",
        "JobTitle": "toothpick lathe operator"
    },
    {
        "value": 22788,
        "NOCCode": "9437",
        "JobTitle": "toothpick-chopping machine tender"
    },
    {
        "value": 22789,
        "NOCCode": "9437",
        "JobTitle": "treadle saw operator – woodworking"
    },
    {
        "value": 22790,
        "NOCCode": "9437",
        "JobTitle": "trim saw operator – woodworking"
    },
    {
        "value": 22791,
        "NOCCode": "9437",
        "JobTitle": "trimmer operator – woodworking"
    },
    {
        "value": 22792,
        "NOCCode": "9437",
        "JobTitle": "trimmer, defects – woodworking"
    },
    {
        "value": 22793,
        "NOCCode": "9437",
        "JobTitle": "trimmer, stummels – woodworking"
    },
    {
        "value": 22794,
        "NOCCode": "9437",
        "JobTitle": "trimming and boring machine operator – woodworking"
    },
    {
        "value": 22795,
        "NOCCode": "9437",
        "JobTitle": "trimming machine operator – woodworking"
    },
    {
        "value": 22796,
        "NOCCode": "9437",
        "JobTitle": "trunk panel finisher – woodworking"
    },
    {
        "value": 22797,
        "NOCCode": "9437",
        "JobTitle": "turner – woodworking"
    },
    {
        "value": 22798,
        "NOCCode": "9437",
        "JobTitle": "turner, lather – woodworking"
    },
    {
        "value": 22799,
        "NOCCode": "9437",
        "JobTitle": "turner, stummels – woodworking"
    },
    {
        "value": 22800,
        "NOCCode": "9437",
        "JobTitle": "turner, wood – woodworking"
    },
    {
        "value": 22801,
        "NOCCode": "9437",
        "JobTitle": "turner, wood lathe – woodworking"
    },
    {
        "value": 22802,
        "NOCCode": "9437",
        "JobTitle": "turner, woodworking"
    },
    {
        "value": 22803,
        "NOCCode": "9437",
        "JobTitle": "turning machine operator – woodworking"
    },
    {
        "value": 22804,
        "NOCCode": "9437",
        "JobTitle": "turning sander tender – woodworking"
    },
    {
        "value": 22805,
        "NOCCode": "9437",
        "JobTitle": "variety saw operator – woodworking"
    },
    {
        "value": 22806,
        "NOCCode": "9437",
        "JobTitle": "veneer sander – woodworking"
    },
    {
        "value": 22807,
        "NOCCode": "9437",
        "JobTitle": "vertical resaw operator – woodworking"
    },
    {
        "value": 22808,
        "NOCCode": "9437",
        "JobTitle": "wobble saw operator – woodworking"
    },
    {
        "value": 22809,
        "NOCCode": "9437",
        "JobTitle": "wood blank sawyer"
    },
    {
        "value": 22810,
        "NOCCode": "9437",
        "JobTitle": "wood driller – woodworking"
    },
    {
        "value": 22811,
        "NOCCode": "9437",
        "JobTitle": "wood lathe turner – woodworking"
    },
    {
        "value": 22812,
        "NOCCode": "9437",
        "JobTitle": "wood products machine tender"
    },
    {
        "value": 22813,
        "NOCCode": "9437",
        "JobTitle": "wood sander – woodworking"
    },
    {
        "value": 22814,
        "NOCCode": "9437",
        "JobTitle": "wood saw operator – woodworking"
    },
    {
        "value": 22815,
        "NOCCode": "9437",
        "JobTitle": "wood turner – woodworking"
    },
    {
        "value": 22816,
        "NOCCode": "9437",
        "JobTitle": "wood turning lathe operator – woodworking"
    },
    {
        "value": 22817,
        "NOCCode": "9437",
        "JobTitle": "wood-carving machine operator"
    },
    {
        "value": 22818,
        "NOCCode": "9437",
        "JobTitle": "wooden barrel header – woodworking"
    },
    {
        "value": 22819,
        "NOCCode": "9437",
        "JobTitle": "wooden barrel-header fitter – woodworking"
    },
    {
        "value": 22820,
        "NOCCode": "9437",
        "JobTitle": "wooden stave turner – woodworking"
    },
    {
        "value": 22821,
        "NOCCode": "9437",
        "JobTitle": "woodsawyer – woodworking"
    },
    {
        "value": 22822,
        "NOCCode": "9437",
        "JobTitle": "wood-turning lathe operator – woodworking"
    },
    {
        "value": 22823,
        "NOCCode": "9437",
        "JobTitle": "woodworking machine operator"
    },
    {
        "value": 22824,
        "NOCCode": "9437",
        "JobTitle": "woodworking machine setter"
    },
    {
        "value": 22825,
        "NOCCode": "9437",
        "JobTitle": "woodworking machine set-up operator"
    },
    {
        "value": 22826,
        "NOCCode": "9441",
        "JobTitle": "ager operator – textile manufacturing"
    },
    {
        "value": 22827,
        "NOCCode": "9441",
        "JobTitle": "ager tender – textile manufacturing"
    },
    {
        "value": 22828,
        "NOCCode": "9441",
        "JobTitle": "aging machine operator – textile manufacturing"
    },
    {
        "value": 22829,
        "NOCCode": "9441",
        "JobTitle": "aging machine tender – textile manufacturing"
    },
    {
        "value": 22830,
        "NOCCode": "9441",
        "JobTitle": "asbestos rope maker"
    },
    {
        "value": 22831,
        "NOCCode": "9441",
        "JobTitle": "autoclave tender – textile manufacturing"
    },
    {
        "value": 22832,
        "NOCCode": "9441",
        "JobTitle": "automatic quiller tender – textile manufacturing"
    },
    {
        "value": 22833,
        "NOCCode": "9441",
        "JobTitle": "back tender – cloth printing"
    },
    {
        "value": 22834,
        "NOCCode": "9441",
        "JobTitle": "bale breaker – textile manufacturing"
    },
    {
        "value": 22835,
        "NOCCode": "9441",
        "JobTitle": "bale-breaker tender – textile manufacturing"
    },
    {
        "value": 22836,
        "NOCCode": "9441",
        "JobTitle": "batch dyer – textile manufacturing"
    },
    {
        "value": 22837,
        "NOCCode": "9441",
        "JobTitle": "bater – hide and pelt processing"
    },
    {
        "value": 22838,
        "NOCCode": "9441",
        "JobTitle": "batting machine tender – textile manufacturing"
    },
    {
        "value": 22839,
        "NOCCode": "9441",
        "JobTitle": "beam dyer – textile manufacturing"
    },
    {
        "value": 22840,
        "NOCCode": "9441",
        "JobTitle": "beam setter – textile manufacturing"
    },
    {
        "value": 22841,
        "NOCCode": "9441",
        "JobTitle": "beam tier – textile manufacturing"
    },
    {
        "value": 22842,
        "NOCCode": "9441",
        "JobTitle": "beam warper – textile manufacturing"
    },
    {
        "value": 22843,
        "NOCCode": "9441",
        "JobTitle": "beamer – hide and pelt processing"
    },
    {
        "value": 22844,
        "NOCCode": "9441",
        "JobTitle": "beamer – textile manufacturing"
    },
    {
        "value": 22845,
        "NOCCode": "9441",
        "JobTitle": "beamhouse worker – hide and pelt processing"
    },
    {
        "value": 22846,
        "NOCCode": "9441",
        "JobTitle": "beating machine tender – hide and pelt processing"
    },
    {
        "value": 22847,
        "NOCCode": "9441",
        "JobTitle": "bleacher – textile manufacturing"
    },
    {
        "value": 22848,
        "NOCCode": "9441",
        "JobTitle": "bleaching machine operator – textile manufacturing"
    },
    {
        "value": 22849,
        "NOCCode": "9441",
        "JobTitle": "bleaching range operator – textile manufacturing"
    },
    {
        "value": 22850,
        "NOCCode": "9441",
        "JobTitle": "blending machine tender – textile manufacturing"
    },
    {
        "value": 22851,
        "NOCCode": "9441",
        "JobTitle": "boarder – textile manufacturing"
    },
    {
        "value": 22852,
        "NOCCode": "9441",
        "JobTitle": "boarding machine tender – hide and pelt processing"
    },
    {
        "value": 22853,
        "NOCCode": "9441",
        "JobTitle": "boarding machine tender – textile manufacturing"
    },
    {
        "value": 22854,
        "NOCCode": "9441",
        "JobTitle": "bobbin dryer – textile manufacturing"
    },
    {
        "value": 22855,
        "NOCCode": "9441",
        "JobTitle": "bobbin stripper – textile manufacturing"
    },
    {
        "value": 22856,
        "NOCCode": "9441",
        "JobTitle": "bobbin winder – textile manufacturing"
    },
    {
        "value": 22857,
        "NOCCode": "9441",
        "JobTitle": "boiler – textile manufacturing"
    },
    {
        "value": 22858,
        "NOCCode": "9441",
        "JobTitle": "bonding machine tender – textile manufacturing"
    },
    {
        "value": 22859,
        "NOCCode": "9441",
        "JobTitle": "bowker – textile manufacturing"
    },
    {
        "value": 22860,
        "NOCCode": "9441",
        "JobTitle": "breaker feeder – textile manufacturing"
    },
    {
        "value": 22861,
        "NOCCode": "9441",
        "JobTitle": "breaker tender – textile manufacturing"
    },
    {
        "value": 22862,
        "NOCCode": "9441",
        "JobTitle": "brush machine tender – textile manufacturing"
    },
    {
        "value": 22863,
        "NOCCode": "9441",
        "JobTitle": "brusher and shearer – textile manufacturing"
    },
    {
        "value": 22864,
        "NOCCode": "9441",
        "JobTitle": "brushing machine operator – hide and pelt processing"
    },
    {
        "value": 22865,
        "NOCCode": "9441",
        "JobTitle": "brushing operator – textile manufacturing"
    },
    {
        "value": 22866,
        "NOCCode": "9441",
        "JobTitle": "buffer, leather – hide and pelt processing"
    },
    {
        "value": 22867,
        "NOCCode": "9441",
        "JobTitle": "burnisher – hide and pelt processing"
    },
    {
        "value": 22868,
        "NOCCode": "9441",
        "JobTitle": "calender operator – textile manufacturing"
    },
    {
        "value": 22869,
        "NOCCode": "9441",
        "JobTitle": "calendering machine tender – textile manufacturing"
    },
    {
        "value": 22870,
        "NOCCode": "9441",
        "JobTitle": "can tender"
    },
    {
        "value": 22871,
        "NOCCode": "9441",
        "JobTitle": "carbonizer tender – textile manufacturing"
    },
    {
        "value": 22872,
        "NOCCode": "9441",
        "JobTitle": "card feeder – textile manufacturing"
    },
    {
        "value": 22873,
        "NOCCode": "9441",
        "JobTitle": "card operator – textile manufacturing"
    },
    {
        "value": 22874,
        "NOCCode": "9441",
        "JobTitle": "card stripper – textile manufacturing"
    },
    {
        "value": 22875,
        "NOCCode": "9441",
        "JobTitle": "card tender – textile manufacturing"
    },
    {
        "value": 22876,
        "NOCCode": "9441",
        "JobTitle": "carder – textile manufacturing"
    },
    {
        "value": 22877,
        "NOCCode": "9441",
        "JobTitle": "carding machine feeder – textile manufacturing"
    },
    {
        "value": 22878,
        "NOCCode": "9441",
        "JobTitle": "carding machine operator – textile manufacturing"
    },
    {
        "value": 22879,
        "NOCCode": "9441",
        "JobTitle": "carding machine tender – textile manufacturing"
    },
    {
        "value": 22880,
        "NOCCode": "9441",
        "JobTitle": "carpet drying machine tender"
    },
    {
        "value": 22881,
        "NOCCode": "9441",
        "JobTitle": "carpet drying machine tender – textile manufacturing"
    },
    {
        "value": 22882,
        "NOCCode": "9441",
        "JobTitle": "centrifugal extractor – textile manufacturing"
    },
    {
        "value": 22883,
        "NOCCode": "9441",
        "JobTitle": "centrifuge operator – textile manufacturing"
    },
    {
        "value": 22884,
        "NOCCode": "9441",
        "JobTitle": "chenille tender – textile manufacturing"
    },
    {
        "value": 22885,
        "NOCCode": "9441",
        "JobTitle": "chopping machine tender"
    },
    {
        "value": 22886,
        "NOCCode": "9441",
        "JobTitle": "clipping machine tender"
    },
    {
        "value": 22887,
        "NOCCode": "9441",
        "JobTitle": "cloth bleacher"
    },
    {
        "value": 22888,
        "NOCCode": "9441",
        "JobTitle": "cloth dryer"
    },
    {
        "value": 22889,
        "NOCCode": "9441",
        "JobTitle": "cloth dyer"
    },
    {
        "value": 22890,
        "NOCCode": "9441",
        "JobTitle": "cloth finisher"
    },
    {
        "value": 22891,
        "NOCCode": "9441",
        "JobTitle": "cloth mercerizer operator"
    },
    {
        "value": 22892,
        "NOCCode": "9441",
        "JobTitle": "cloth neutralizer"
    },
    {
        "value": 22893,
        "NOCCode": "9441",
        "JobTitle": "cloth printer"
    },
    {
        "value": 22894,
        "NOCCode": "9441",
        "JobTitle": "cloth shade maker"
    },
    {
        "value": 22895,
        "NOCCode": "9441",
        "JobTitle": "cloth shader"
    },
    {
        "value": 22896,
        "NOCCode": "9441",
        "JobTitle": "cloth shrinker"
    },
    {
        "value": 22897,
        "NOCCode": "9441",
        "JobTitle": "cloth steamer"
    },
    {
        "value": 22898,
        "NOCCode": "9441",
        "JobTitle": "cloth stretcher-dryer tender"
    },
    {
        "value": 22899,
        "NOCCode": "9441",
        "JobTitle": "cloth washer – textile manufacturing"
    },
    {
        "value": 22900,
        "NOCCode": "9441",
        "JobTitle": "cloth-bleaching range tender"
    },
    {
        "value": 22901,
        "NOCCode": "9441",
        "JobTitle": "cloth-doubling machine operator"
    },
    {
        "value": 22902,
        "NOCCode": "9441",
        "JobTitle": "cloth-dyeing range tender"
    },
    {
        "value": 22903,
        "NOCCode": "9441",
        "JobTitle": "cloth-finishing range operator"
    },
    {
        "value": 22904,
        "NOCCode": "9441",
        "JobTitle": "cloth-neutralizing and cloth-rinsing machine operator"
    },
    {
        "value": 22905,
        "NOCCode": "9441",
        "JobTitle": "cloth-printing machine helper"
    },
    {
        "value": 22906,
        "NOCCode": "9441",
        "JobTitle": "cloth-printing machine tender"
    },
    {
        "value": 22907,
        "NOCCode": "9441",
        "JobTitle": "cloth-printing roller changer"
    },
    {
        "value": 22908,
        "NOCCode": "9441",
        "JobTitle": "cloth-processing range tender"
    },
    {
        "value": 22909,
        "NOCCode": "9441",
        "JobTitle": "coater operator – textile manufacturing"
    },
    {
        "value": 22910,
        "NOCCode": "9441",
        "JobTitle": "coater tender – textile manufacturing"
    },
    {
        "value": 22911,
        "NOCCode": "9441",
        "JobTitle": "coating and embossing unit operator – textile manufacturing"
    },
    {
        "value": 22912,
        "NOCCode": "9441",
        "JobTitle": "coating machine operator – textile manufacturing"
    },
    {
        "value": 22913,
        "NOCCode": "9441",
        "JobTitle": "coating machine tender – textile manufacturing"
    },
    {
        "value": 22914,
        "NOCCode": "9441",
        "JobTitle": "colour mixer – textile manufacturing"
    },
    {
        "value": 22915,
        "NOCCode": "9441",
        "JobTitle": "colourer tender – hide and pelt processing"
    },
    {
        "value": 22916,
        "NOCCode": "9441",
        "JobTitle": "colourist – textile manufacturing"
    },
    {
        "value": 22917,
        "NOCCode": "9441",
        "JobTitle": "combed sliver dyer – textile manufacturing"
    },
    {
        "value": 22918,
        "NOCCode": "9441",
        "JobTitle": "comber – textile manufacturing"
    },
    {
        "value": 22919,
        "NOCCode": "9441",
        "JobTitle": "combining and drawing machine tender – textile manufacturing"
    },
    {
        "value": 22920,
        "NOCCode": "9441",
        "JobTitle": "conditioner tender – textile manufacturing"
    },
    {
        "value": 22921,
        "NOCCode": "9441",
        "JobTitle": "cone winder – textile manufacturing"
    },
    {
        "value": 22922,
        "NOCCode": "9441",
        "JobTitle": "continuous dyeing machine operator – textile manufacturing"
    },
    {
        "value": 22923,
        "NOCCode": "9441",
        "JobTitle": "continuous felt dryer operator – textile manufacturing"
    },
    {
        "value": 22924,
        "NOCCode": "9441",
        "JobTitle": "continuous press tender – textile manufacturing"
    },
    {
        "value": 22925,
        "NOCCode": "9441",
        "JobTitle": "cotton ball machine tender"
    },
    {
        "value": 22926,
        "NOCCode": "9441",
        "JobTitle": "covering-machine tender"
    },
    {
        "value": 22927,
        "NOCCode": "9441",
        "JobTitle": "crabber – textile manufacturing"
    },
    {
        "value": 22928,
        "NOCCode": "9441",
        "JobTitle": "crabbing machine tender"
    },
    {
        "value": 22929,
        "NOCCode": "9441",
        "JobTitle": "crabbing machine tender – textile manufacturing"
    },
    {
        "value": 22930,
        "NOCCode": "9441",
        "JobTitle": "crimp setter"
    },
    {
        "value": 22931,
        "NOCCode": "9441",
        "JobTitle": "crimper"
    },
    {
        "value": 22932,
        "NOCCode": "9441",
        "JobTitle": "crimping machine tender – textile manufacturing"
    },
    {
        "value": 22933,
        "NOCCode": "9441",
        "JobTitle": "cropping machine tender – textile manufacturing"
    },
    {
        "value": 22934,
        "NOCCode": "9441",
        "JobTitle": "cutting and printing machine set-up operator – textile manufacturing"
    },
    {
        "value": 22935,
        "NOCCode": "9441",
        "JobTitle": "decating machine tender – textile manufacturing"
    },
    {
        "value": 22936,
        "NOCCode": "9441",
        "JobTitle": "dehairing machine operator – hide and pelt processing"
    },
    {
        "value": 22937,
        "NOCCode": "9441",
        "JobTitle": "doubling machine operator – textile manufacturing"
    },
    {
        "value": 22938,
        "NOCCode": "9441",
        "JobTitle": "doubling machine tender – textile manufacturing"
    },
    {
        "value": 22939,
        "NOCCode": "9441",
        "JobTitle": "drawing machine tender – textile fibre"
    },
    {
        "value": 22940,
        "NOCCode": "9441",
        "JobTitle": "dressed hide and pelt finisher"
    },
    {
        "value": 22941,
        "NOCCode": "9441",
        "JobTitle": "dresser – textile manufacturing"
    },
    {
        "value": 22942,
        "NOCCode": "9441",
        "JobTitle": "dry cans operator – textile manufacturing"
    },
    {
        "value": 22943,
        "NOCCode": "9441",
        "JobTitle": "dryer – textile manufacturing"
    },
    {
        "value": 22944,
        "NOCCode": "9441",
        "JobTitle": "dryer operator – textile manufacturing"
    },
    {
        "value": 22945,
        "NOCCode": "9441",
        "JobTitle": "dryer tender – textile manufacturing"
    },
    {
        "value": 22946,
        "NOCCode": "9441",
        "JobTitle": "drying machine operator – textile manufacturing"
    },
    {
        "value": 22947,
        "NOCCode": "9441",
        "JobTitle": "drying machine tender – textile manufacturing"
    },
    {
        "value": 22948,
        "NOCCode": "9441",
        "JobTitle": "drying machine tender, yarn"
    },
    {
        "value": 22949,
        "NOCCode": "9441",
        "JobTitle": "dye mixer"
    },
    {
        "value": 22950,
        "NOCCode": "9441",
        "JobTitle": "dyeing machine tender – textile manufacturing"
    },
    {
        "value": 22951,
        "NOCCode": "9441",
        "JobTitle": "dyeing range operator – textile manufacturing"
    },
    {
        "value": 22952,
        "NOCCode": "9441",
        "JobTitle": "dyeing tub tender – textile manufacturing"
    },
    {
        "value": 22953,
        "NOCCode": "9441",
        "JobTitle": "dyeing vat operator – textile manufacturing"
    },
    {
        "value": 22954,
        "NOCCode": "9441",
        "JobTitle": "dyeing worker – textile manufacturing"
    },
    {
        "value": 22955,
        "NOCCode": "9441",
        "JobTitle": "dyer"
    },
    {
        "value": 22956,
        "NOCCode": "9441",
        "JobTitle": "dyer – textile manufacturing"
    },
    {
        "value": 22957,
        "NOCCode": "9441",
        "JobTitle": "dye-reel tender – textile manufacturing"
    },
    {
        "value": 22958,
        "NOCCode": "9441",
        "JobTitle": "embossing calender tender – textile manufacturing"
    },
    {
        "value": 22959,
        "NOCCode": "9441",
        "JobTitle": "extractor tender – textile manufacturing"
    },
    {
        "value": 22960,
        "NOCCode": "9441",
        "JobTitle": "fabric dyer"
    },
    {
        "value": 22961,
        "NOCCode": "9441",
        "JobTitle": "fabric printer"
    },
    {
        "value": 22962,
        "NOCCode": "9441",
        "JobTitle": "fabric-printing machine operator"
    },
    {
        "value": 22963,
        "NOCCode": "9441",
        "JobTitle": "felt dryer – textile manufacturing"
    },
    {
        "value": 22964,
        "NOCCode": "9441",
        "JobTitle": "fibre classer"
    },
    {
        "value": 22965,
        "NOCCode": "9441",
        "JobTitle": "fibre mixer"
    },
    {
        "value": 22966,
        "NOCCode": "9441",
        "JobTitle": "fibre washer"
    },
    {
        "value": 22967,
        "NOCCode": "9441",
        "JobTitle": "fibreglass roving winder"
    },
    {
        "value": 22968,
        "NOCCode": "9441",
        "JobTitle": "finisher – textile manufacturing"
    },
    {
        "value": 22969,
        "NOCCode": "9441",
        "JobTitle": "finishing machine operator – textile manufacturing"
    },
    {
        "value": 22970,
        "NOCCode": "9441",
        "JobTitle": "finishing range operator – textile manufacturing"
    },
    {
        "value": 22971,
        "NOCCode": "9441",
        "JobTitle": "fleecer – textile manufacturing"
    },
    {
        "value": 22972,
        "NOCCode": "9441",
        "JobTitle": "fleecing machine operator – textile manufacturing"
    },
    {
        "value": 22973,
        "NOCCode": "9441",
        "JobTitle": "fleecing machine tender – textile manufacturing"
    },
    {
        "value": 22974,
        "NOCCode": "9441",
        "JobTitle": "flesher, hand – hide and pelt processing"
    },
    {
        "value": 22975,
        "NOCCode": "9441",
        "JobTitle": "fleshing machine tender – hide and pelt processing"
    },
    {
        "value": 22976,
        "NOCCode": "9441",
        "JobTitle": "flocking machine tender"
    },
    {
        "value": 22977,
        "NOCCode": "9441",
        "JobTitle": "folding machine tender – textile manufacturing"
    },
    {
        "value": 22978,
        "NOCCode": "9441",
        "JobTitle": "frame spinner – textile manufacturing"
    },
    {
        "value": 22979,
        "NOCCode": "9441",
        "JobTitle": "fuller – textile manufacturing"
    },
    {
        "value": 22980,
        "NOCCode": "9441",
        "JobTitle": "fulling machine operator – textile manufacturing"
    },
    {
        "value": 22981,
        "NOCCode": "9441",
        "JobTitle": "fulling mill operator – textile manufacturing"
    },
    {
        "value": 22982,
        "NOCCode": "9441",
        "JobTitle": "fur blender"
    },
    {
        "value": 22983,
        "NOCCode": "9441",
        "JobTitle": "fur blower – textile manufacturing"
    },
    {
        "value": 22984,
        "NOCCode": "9441",
        "JobTitle": "fur dresser – hide and pelt processing"
    },
    {
        "value": 22985,
        "NOCCode": "9441",
        "JobTitle": "fur floor worker – hide and pelt processing"
    },
    {
        "value": 22986,
        "NOCCode": "9441",
        "JobTitle": "fur mixer"
    },
    {
        "value": 22987,
        "NOCCode": "9441",
        "JobTitle": "fur pelt dresser – hide and pelt processing"
    },
    {
        "value": 22988,
        "NOCCode": "9441",
        "JobTitle": "fur plucker – hide and pelt processing"
    },
    {
        "value": 22989,
        "NOCCode": "9441",
        "JobTitle": "fur-shaving machine tender – hide and pelt processing"
    },
    {
        "value": 22990,
        "NOCCode": "9441",
        "JobTitle": "furskin dresser – hide and pelt processing"
    },
    {
        "value": 22991,
        "NOCCode": "9441",
        "JobTitle": "garment steamer tender"
    },
    {
        "value": 22992,
        "NOCCode": "9441",
        "JobTitle": "garnett machine tender – textile manufacturing"
    },
    {
        "value": 22993,
        "NOCCode": "9441",
        "JobTitle": "gig machine operator – textile manufacturing"
    },
    {
        "value": 22994,
        "NOCCode": "9441",
        "JobTitle": "glass fibre dyer – textile manufacturing"
    },
    {
        "value": 22995,
        "NOCCode": "9441",
        "JobTitle": "graining machine operator – tannery"
    },
    {
        "value": 22996,
        "NOCCode": "9441",
        "JobTitle": "hand screen printer – textile manufacturing"
    },
    {
        "value": 22997,
        "NOCCode": "9441",
        "JobTitle": "heat curer – textile manufacturing"
    },
    {
        "value": 22998,
        "NOCCode": "9441",
        "JobTitle": "hide and pelt processing worker"
    },
    {
        "value": 22999,
        "NOCCode": "9441",
        "JobTitle": "hide and skin preparer"
    },
    {
        "value": 23000,
        "NOCCode": "9441",
        "JobTitle": "hide fluffer – hide and pelt processing"
    },
    {
        "value": 23001,
        "NOCCode": "9441",
        "JobTitle": "hide parer – hide and pelt processing"
    },
    {
        "value": 23002,
        "NOCCode": "9441",
        "JobTitle": "hide processing worker"
    },
    {
        "value": 23003,
        "NOCCode": "9441",
        "JobTitle": "hide stretcher"
    },
    {
        "value": 23004,
        "NOCCode": "9441",
        "JobTitle": "high-speed machine warper – textile manufacturing"
    },
    {
        "value": 23005,
        "NOCCode": "9441",
        "JobTitle": "hosiery boarder"
    },
    {
        "value": 23006,
        "NOCCode": "9441",
        "JobTitle": "jig operator – textile manufacturing"
    },
    {
        "value": 23007,
        "NOCCode": "9441",
        "JobTitle": "jigger operator – textile manufacturing"
    },
    {
        "value": 23008,
        "NOCCode": "9441",
        "JobTitle": "kettle tender – textile manufacturing"
    },
    {
        "value": 23009,
        "NOCCode": "9441",
        "JobTitle": "kier-boiler tender – textile manufacturing"
    },
    {
        "value": 23010,
        "NOCCode": "9441",
        "JobTitle": "lap machine tender – textile manufacturing"
    },
    {
        "value": 23011,
        "NOCCode": "9441",
        "JobTitle": "lapper – textile manufacturing"
    },
    {
        "value": 23012,
        "NOCCode": "9441",
        "JobTitle": "lapper tender – textile manufacturing"
    },
    {
        "value": 23013,
        "NOCCode": "9441",
        "JobTitle": "lap-winder tender – textile manufacturing"
    },
    {
        "value": 23014,
        "NOCCode": "9441",
        "JobTitle": "latex-coating machine operator – textile manufacturing"
    },
    {
        "value": 23015,
        "NOCCode": "9441",
        "JobTitle": "leather buffer – hide and pelt processing"
    },
    {
        "value": 23016,
        "NOCCode": "9441",
        "JobTitle": "leather dyer – hide and pelt processing"
    },
    {
        "value": 23017,
        "NOCCode": "9441",
        "JobTitle": "leather measurer – hide and pelt processing"
    },
    {
        "value": 23018,
        "NOCCode": "9441",
        "JobTitle": "leather polisher – hide and pelt processing"
    },
    {
        "value": 23019,
        "NOCCode": "9441",
        "JobTitle": "leather sander – hide and pelt processing"
    },
    {
        "value": 23020,
        "NOCCode": "9441",
        "JobTitle": "leather shaver – hide and pelt processing"
    },
    {
        "value": 23021,
        "NOCCode": "9441",
        "JobTitle": "leather stainer – hide and pelt processing"
    },
    {
        "value": 23022,
        "NOCCode": "9441",
        "JobTitle": "leather stretcher – hide and pelt processing"
    },
    {
        "value": 23023,
        "NOCCode": "9441",
        "JobTitle": "leather tanner"
    },
    {
        "value": 23024,
        "NOCCode": "9441",
        "JobTitle": "limer – hide and pelt processing"
    },
    {
        "value": 23025,
        "NOCCode": "9441",
        "JobTitle": "line spooler"
    },
    {
        "value": 23026,
        "NOCCode": "9441",
        "JobTitle": "loom-winder tender"
    },
    {
        "value": 23027,
        "NOCCode": "9441",
        "JobTitle": "lusterer – textile manufacturing"
    },
    {
        "value": 23028,
        "NOCCode": "9441",
        "JobTitle": "machine flesher – hide and pelt processing"
    },
    {
        "value": 23029,
        "NOCCode": "9441",
        "JobTitle": "machine operator – hide and pelt processing"
    },
    {
        "value": 23030,
        "NOCCode": "9441",
        "JobTitle": "machine operator – textile fibre and yarn preparation"
    },
    {
        "value": 23031,
        "NOCCode": "9441",
        "JobTitle": "mangler tender – textile manufacturing"
    },
    {
        "value": 23032,
        "NOCCode": "9441",
        "JobTitle": "measuring machine tender – hide and pelt processing"
    },
    {
        "value": 23033,
        "NOCCode": "9441",
        "JobTitle": "mercerizer – textile manufacturing"
    },
    {
        "value": 23034,
        "NOCCode": "9441",
        "JobTitle": "mercerizer machine operator – textile manufacturing"
    },
    {
        "value": 23035,
        "NOCCode": "9441",
        "JobTitle": "napper – textile manufacturing"
    },
    {
        "value": 23036,
        "NOCCode": "9441",
        "JobTitle": "napper operator – textile manufacturing"
    },
    {
        "value": 23037,
        "NOCCode": "9441",
        "JobTitle": "napper tender – textile manufacturing"
    },
    {
        "value": 23038,
        "NOCCode": "9441",
        "JobTitle": "naps teaseller – textile manufacturing"
    },
    {
        "value": 23039,
        "NOCCode": "9441",
        "JobTitle": "neutralizer – textile manufacturing"
    },
    {
        "value": 23040,
        "NOCCode": "9441",
        "JobTitle": "open-end spinner – textile manufacturing"
    },
    {
        "value": 23041,
        "NOCCode": "9441",
        "JobTitle": "opener tender – textiles"
    },
    {
        "value": 23042,
        "NOCCode": "9441",
        "JobTitle": "opening and blending operator"
    },
    {
        "value": 23043,
        "NOCCode": "9441",
        "JobTitle": "operator, bleaching range – textile manufacturing"
    },
    {
        "value": 23044,
        "NOCCode": "9441",
        "JobTitle": "operator, dye-range – textile manufacturing"
    },
    {
        "value": 23045,
        "NOCCode": "9441",
        "JobTitle": "padder tender – textile manufacturing"
    },
    {
        "value": 23046,
        "NOCCode": "9441",
        "JobTitle": "padding machine tender – textile manufacturing"
    },
    {
        "value": 23047,
        "NOCCode": "9441",
        "JobTitle": "pad-extractor tender – textile manufacturing"
    },
    {
        "value": 23048,
        "NOCCode": "9441",
        "JobTitle": "pelt dresser"
    },
    {
        "value": 23049,
        "NOCCode": "9441",
        "JobTitle": "pelt dyer – hide and pelt processing"
    },
    {
        "value": 23050,
        "NOCCode": "9441",
        "JobTitle": "pelt processing worker"
    },
    {
        "value": 23051,
        "NOCCode": "9441",
        "JobTitle": "pelt shearer"
    },
    {
        "value": 23052,
        "NOCCode": "9441",
        "JobTitle": "pelt stretcher – hide and pelt processing"
    },
    {
        "value": 23053,
        "NOCCode": "9441",
        "JobTitle": "picker – textile manufacturing"
    },
    {
        "value": 23054,
        "NOCCode": "9441",
        "JobTitle": "picker tender – textile manufacturing"
    },
    {
        "value": 23055,
        "NOCCode": "9441",
        "JobTitle": "picking machine operator – textile manufacturing"
    },
    {
        "value": 23056,
        "NOCCode": "9441",
        "JobTitle": "picking machine tender – textile manufacturing"
    },
    {
        "value": 23057,
        "NOCCode": "9441",
        "JobTitle": "pickler – hide and pelt processing"
    },
    {
        "value": 23058,
        "NOCCode": "9441",
        "JobTitle": "pile fabric finisher"
    },
    {
        "value": 23059,
        "NOCCode": "9441",
        "JobTitle": "pile-finishing machine tender – textile manufacturing"
    },
    {
        "value": 23060,
        "NOCCode": "9441",
        "JobTitle": "pleater – textile manufacturing"
    },
    {
        "value": 23061,
        "NOCCode": "9441",
        "JobTitle": "poler – textile manufacturing"
    },
    {
        "value": 23062,
        "NOCCode": "9441",
        "JobTitle": "polisher – textile fibre"
    },
    {
        "value": 23063,
        "NOCCode": "9441",
        "JobTitle": "prepared hide and pelt dresser"
    },
    {
        "value": 23064,
        "NOCCode": "9441",
        "JobTitle": "presser and threader – textile manufacturing"
    },
    {
        "value": 23065,
        "NOCCode": "9441",
        "JobTitle": "presser-threader – textile manufacturing"
    },
    {
        "value": 23066,
        "NOCCode": "9441",
        "JobTitle": "printing machine operator – textile manufacturing"
    },
    {
        "value": 23067,
        "NOCCode": "9441",
        "JobTitle": "printing operator – textile manufacturing"
    },
    {
        "value": 23068,
        "NOCCode": "9441",
        "JobTitle": "quill winder – textile manufacturing"
    },
    {
        "value": 23069,
        "NOCCode": "9441",
        "JobTitle": "quiller – textile manufacturing"
    },
    {
        "value": 23070,
        "NOCCode": "9441",
        "JobTitle": "quilling machine tender, automatic – textile manufacturing"
    },
    {
        "value": 23071,
        "NOCCode": "9441",
        "JobTitle": "raw stock dryer – textile manufacturing"
    },
    {
        "value": 23072,
        "NOCCode": "9441",
        "JobTitle": "raw stock dyer – textile manufacturing"
    },
    {
        "value": 23073,
        "NOCCode": "9441",
        "JobTitle": "rebeamer – textile manufacturing"
    },
    {
        "value": 23074,
        "NOCCode": "9441",
        "JobTitle": "redraw operator – textile manufacturing"
    },
    {
        "value": 23075,
        "NOCCode": "9441",
        "JobTitle": "reel tender – textile manufacturing"
    },
    {
        "value": 23076,
        "NOCCode": "9441",
        "JobTitle": "reel winder – textile manufacturing"
    },
    {
        "value": 23077,
        "NOCCode": "9441",
        "JobTitle": "reeler – textile manufacturing"
    },
    {
        "value": 23078,
        "NOCCode": "9441",
        "JobTitle": "reeling machine operator – textile manufacturing"
    },
    {
        "value": 23079,
        "NOCCode": "9441",
        "JobTitle": "respooler – textile manufacturing"
    },
    {
        "value": 23080,
        "NOCCode": "9441",
        "JobTitle": "rewarper – textile manufacturing"
    },
    {
        "value": 23081,
        "NOCCode": "9441",
        "JobTitle": "rewinder – textile manufacturing"
    },
    {
        "value": 23082,
        "NOCCode": "9441",
        "JobTitle": "ring frame operator – textile manufacturing"
    },
    {
        "value": 23083,
        "NOCCode": "9441",
        "JobTitle": "ring spinner – textile manufacturing"
    },
    {
        "value": 23084,
        "NOCCode": "9441",
        "JobTitle": "rinser – textile manufacturing"
    },
    {
        "value": 23085,
        "NOCCode": "9441",
        "JobTitle": "roller machine tender – tannery"
    },
    {
        "value": 23086,
        "NOCCode": "9441",
        "JobTitle": "roller printer – textile manufacturing"
    },
    {
        "value": 23087,
        "NOCCode": "9441",
        "JobTitle": "rope coiler"
    },
    {
        "value": 23088,
        "NOCCode": "9441",
        "JobTitle": "rotogravure press operator – textile manufacturing"
    },
    {
        "value": 23089,
        "NOCCode": "9441",
        "JobTitle": "rover – textile manufacturing"
    },
    {
        "value": 23090,
        "NOCCode": "9441",
        "JobTitle": "roving frame tender – textile manufacturing"
    },
    {
        "value": 23091,
        "NOCCode": "9441",
        "JobTitle": "roving machine operator – textile manufacturing"
    },
    {
        "value": 23092,
        "NOCCode": "9441",
        "JobTitle": "rubber and vinyl coater operator – textile manufacturing"
    },
    {
        "value": 23093,
        "NOCCode": "9441",
        "JobTitle": "rubber-coating and vinyl-coating machine operator – textile manufacturing"
    },
    {
        "value": 23094,
        "NOCCode": "9441",
        "JobTitle": "rug setter – textile manufacturing"
    },
    {
        "value": 23095,
        "NOCCode": "9441",
        "JobTitle": "rug-coating machine operator"
    },
    {
        "value": 23096,
        "NOCCode": "9441",
        "JobTitle": "rug-yarn-setting machine operator – textile manufacturing"
    },
    {
        "value": 23097,
        "NOCCode": "9441",
        "JobTitle": "sample printer – textile manufacturing"
    },
    {
        "value": 23098,
        "NOCCode": "9441",
        "JobTitle": "saturator – textile manufacturing"
    },
    {
        "value": 23099,
        "NOCCode": "9441",
        "JobTitle": "scouring machine operator"
    },
    {
        "value": 23100,
        "NOCCode": "9441",
        "JobTitle": "scray machine feeder"
    },
    {
        "value": 23101,
        "NOCCode": "9441",
        "JobTitle": "screen printer, hand – textile manufacturing"
    },
    {
        "value": 23102,
        "NOCCode": "9441",
        "JobTitle": "screen printing operator – textile manufacturing"
    },
    {
        "value": 23103,
        "NOCCode": "9441",
        "JobTitle": "screen-printing machine operator – textile manufacturing"
    },
    {
        "value": 23104,
        "NOCCode": "9441",
        "JobTitle": "scudder – hide and pelt processing"
    },
    {
        "value": 23105,
        "NOCCode": "9441",
        "JobTitle": "shaving machine tender – hide and pelt processing"
    },
    {
        "value": 23106,
        "NOCCode": "9441",
        "JobTitle": "shearing machine tender"
    },
    {
        "value": 23107,
        "NOCCode": "9441",
        "JobTitle": "shearing machine tender – textile manufacturing"
    },
    {
        "value": 23108,
        "NOCCode": "9441",
        "JobTitle": "shrinking machine operator – textile manufacturing"
    },
    {
        "value": 23109,
        "NOCCode": "9441",
        "JobTitle": "silk-screen printing machine operator – textile manufacturing"
    },
    {
        "value": 23110,
        "NOCCode": "9441",
        "JobTitle": "singeing and boiling machine tender – textile manufacturing"
    },
    {
        "value": 23111,
        "NOCCode": "9441",
        "JobTitle": "size maker – textile manufacturing"
    },
    {
        "value": 23112,
        "NOCCode": "9441",
        "JobTitle": "sizing machine tender – textiles"
    },
    {
        "value": 23113,
        "NOCCode": "9441",
        "JobTitle": "skein spooler – textile manufacturing"
    },
    {
        "value": 23114,
        "NOCCode": "9441",
        "JobTitle": "skein winder – textile manufacturing"
    },
    {
        "value": 23115,
        "NOCCode": "9441",
        "JobTitle": "skeiner – textile manufacturing"
    },
    {
        "value": 23116,
        "NOCCode": "9441",
        "JobTitle": "slasher – textile manufacturing"
    },
    {
        "value": 23117,
        "NOCCode": "9441",
        "JobTitle": "slasher operator – textile manufacturing"
    },
    {
        "value": 23118,
        "NOCCode": "9441",
        "JobTitle": "sliver lapper – textile manufacturing"
    },
    {
        "value": 23119,
        "NOCCode": "9441",
        "JobTitle": "slubber tender – textile manufacturing"
    },
    {
        "value": 23120,
        "NOCCode": "9441",
        "JobTitle": "slubber, hand"
    },
    {
        "value": 23121,
        "NOCCode": "9441",
        "JobTitle": "spinner – textile manufacturing"
    },
    {
        "value": 23122,
        "NOCCode": "9441",
        "JobTitle": "spinning frame tender – textile manufacturing"
    },
    {
        "value": 23123,
        "NOCCode": "9441",
        "JobTitle": "spinning machine operator – textile manufacturing"
    },
    {
        "value": 23124,
        "NOCCode": "9441",
        "JobTitle": "spinning machine tender – textile manufacturing"
    },
    {
        "value": 23125,
        "NOCCode": "9441",
        "JobTitle": "spinning operator – textile manufacturing"
    },
    {
        "value": 23126,
        "NOCCode": "9441",
        "JobTitle": "splicing machine tender – textile manufacturing"
    },
    {
        "value": 23127,
        "NOCCode": "9441",
        "JobTitle": "splitter – hide and pelt processing"
    },
    {
        "value": 23128,
        "NOCCode": "9441",
        "JobTitle": "sponge-range operator – textile manufacturing"
    },
    {
        "value": 23129,
        "NOCCode": "9441",
        "JobTitle": "spool winder – textile manufacturing"
    },
    {
        "value": 23130,
        "NOCCode": "9441",
        "JobTitle": "spooler – textile manufacturing"
    },
    {
        "value": 23131,
        "NOCCode": "9441",
        "JobTitle": "spooler operator, automatic – textile manufacturing"
    },
    {
        "value": 23132,
        "NOCCode": "9441",
        "JobTitle": "spooling machine tender – textile manufacturing"
    },
    {
        "value": 23133,
        "NOCCode": "9441",
        "JobTitle": "spray-coating machine tender – textile manufacturing"
    },
    {
        "value": 23134,
        "NOCCode": "9441",
        "JobTitle": "spraying machine tender – textile manufacturing"
    },
    {
        "value": 23135,
        "NOCCode": "9441",
        "JobTitle": "spun yarn doubler – textile manufacturing"
    },
    {
        "value": 23136,
        "NOCCode": "9441",
        "JobTitle": "staple cutter – textile manufacturing"
    },
    {
        "value": 23137,
        "NOCCode": "9441",
        "JobTitle": "staple processing machine tender – textile manufacturing"
    },
    {
        "value": 23138,
        "NOCCode": "9441",
        "JobTitle": "steam cabinet operator – textile manufacturing"
    },
    {
        "value": 23139,
        "NOCCode": "9441",
        "JobTitle": "steam sponger – textile manufacturing"
    },
    {
        "value": 23140,
        "NOCCode": "9441",
        "JobTitle": "steamer tender – textile manufacturing"
    },
    {
        "value": 23141,
        "NOCCode": "9441",
        "JobTitle": "stender tender"
    },
    {
        "value": 23142,
        "NOCCode": "9441",
        "JobTitle": "stock mixer – textile manufacturing"
    },
    {
        "value": 23143,
        "NOCCode": "9441",
        "JobTitle": "strand-forming machine tender – textile manufacturing"
    },
    {
        "value": 23144,
        "NOCCode": "9441",
        "JobTitle": "stretching machine operator – hide and pelt processing"
    },
    {
        "value": 23145,
        "NOCCode": "9441",
        "JobTitle": "stretching machine tender – textile fibre"
    },
    {
        "value": 23146,
        "NOCCode": "9441",
        "JobTitle": "stretching machine tender – textile manufacturing"
    },
    {
        "value": 23147,
        "NOCCode": "9441",
        "JobTitle": "strike-off machine tender – textile manufacturing"
    },
    {
        "value": 23148,
        "NOCCode": "9441",
        "JobTitle": "super-draft tender"
    },
    {
        "value": 23149,
        "NOCCode": "9441",
        "JobTitle": "tanner – hide and pelt processing"
    },
    {
        "value": 23150,
        "NOCCode": "9441",
        "JobTitle": "tannery vatman/woman"
    },
    {
        "value": 23151,
        "NOCCode": "9441",
        "JobTitle": "tanning solution preparer"
    },
    {
        "value": 23152,
        "NOCCode": "9441",
        "JobTitle": "tape-folding machine tender"
    },
    {
        "value": 23153,
        "NOCCode": "9441",
        "JobTitle": "tar machine tender – textile manufacturing"
    },
    {
        "value": 23154,
        "NOCCode": "9441",
        "JobTitle": "tension drying machine tender – textile manufacturing"
    },
    {
        "value": 23155,
        "NOCCode": "9441",
        "JobTitle": "tenter machine tender"
    },
    {
        "value": 23156,
        "NOCCode": "9441",
        "JobTitle": "tenter machine tender – textile manufacturing"
    },
    {
        "value": 23157,
        "NOCCode": "9441",
        "JobTitle": "tenterframe tender"
    },
    {
        "value": 23158,
        "NOCCode": "9441",
        "JobTitle": "textile colourist"
    },
    {
        "value": 23159,
        "NOCCode": "9441",
        "JobTitle": "textile dyer"
    },
    {
        "value": 23160,
        "NOCCode": "9441",
        "JobTitle": "textile fleecer – textile manufacturing"
    },
    {
        "value": 23161,
        "NOCCode": "9441",
        "JobTitle": "textile napper – textile manufacturing"
    },
    {
        "value": 23162,
        "NOCCode": "9441",
        "JobTitle": "textile printer"
    },
    {
        "value": 23163,
        "NOCCode": "9441",
        "JobTitle": "textile product bleacher"
    },
    {
        "value": 23164,
        "NOCCode": "9441",
        "JobTitle": "textile spinner"
    },
    {
        "value": 23165,
        "NOCCode": "9441",
        "JobTitle": "textile washer"
    },
    {
        "value": 23166,
        "NOCCode": "9441",
        "JobTitle": "textile wringer"
    },
    {
        "value": 23167,
        "NOCCode": "9441",
        "JobTitle": "textile-dyeing and finishing machine operator"
    },
    {
        "value": 23168,
        "NOCCode": "9441",
        "JobTitle": "textile-twisting-frame operator"
    },
    {
        "value": 23169,
        "NOCCode": "9441",
        "JobTitle": "texturing machine tender – textile manufacturing"
    },
    {
        "value": 23170,
        "NOCCode": "9441",
        "JobTitle": "thread doubler"
    },
    {
        "value": 23171,
        "NOCCode": "9441",
        "JobTitle": "thread spinner"
    },
    {
        "value": 23172,
        "NOCCode": "9441",
        "JobTitle": "thread spooler"
    },
    {
        "value": 23173,
        "NOCCode": "9441",
        "JobTitle": "thread winder"
    },
    {
        "value": 23174,
        "NOCCode": "9441",
        "JobTitle": "top dyer – textile manufacturing"
    },
    {
        "value": 23175,
        "NOCCode": "9441",
        "JobTitle": "tumbler tender – textile manufacturing"
    },
    {
        "value": 23176,
        "NOCCode": "9441",
        "JobTitle": "twine dyer"
    },
    {
        "value": 23177,
        "NOCCode": "9441",
        "JobTitle": "twine maker"
    },
    {
        "value": 23178,
        "NOCCode": "9441",
        "JobTitle": "twine-cropping machine tender"
    },
    {
        "value": 23179,
        "NOCCode": "9441",
        "JobTitle": "twine-shearing machine tender"
    },
    {
        "value": 23180,
        "NOCCode": "9441",
        "JobTitle": "twister operator – textile manufacturing"
    },
    {
        "value": 23181,
        "NOCCode": "9441",
        "JobTitle": "twister tender – textile manufacturing"
    },
    {
        "value": 23182,
        "NOCCode": "9441",
        "JobTitle": "twisting frame operator – textile manufacturing"
    },
    {
        "value": 23183,
        "NOCCode": "9441",
        "JobTitle": "twisting operator – textile manufacturing"
    },
    {
        "value": 23184,
        "NOCCode": "9441",
        "JobTitle": "uptwister operator – textile manufacturing"
    },
    {
        "value": 23185,
        "NOCCode": "9441",
        "JobTitle": "uptwister tender – textile manufacturing"
    },
    {
        "value": 23186,
        "NOCCode": "9441",
        "JobTitle": "warp beamer – textile manufacturing"
    },
    {
        "value": 23187,
        "NOCCode": "9441",
        "JobTitle": "warp dresser – textile manufacturing"
    },
    {
        "value": 23188,
        "NOCCode": "9441",
        "JobTitle": "warp end dresser – textile manufacturing"
    },
    {
        "value": 23189,
        "NOCCode": "9441",
        "JobTitle": "warp end dyer – textile manufacturing"
    },
    {
        "value": 23190,
        "NOCCode": "9441",
        "JobTitle": "warp spinner – textile manufacturing"
    },
    {
        "value": 23191,
        "NOCCode": "9441",
        "JobTitle": "warp twister tender – textile manufacturing"
    },
    {
        "value": 23192,
        "NOCCode": "9441",
        "JobTitle": "warper – textile manufacturing"
    },
    {
        "value": 23193,
        "NOCCode": "9441",
        "JobTitle": "warper operator – textile manufacturing"
    },
    {
        "value": 23194,
        "NOCCode": "9441",
        "JobTitle": "warper tender, automatic – textiles manufacturing"
    },
    {
        "value": 23195,
        "NOCCode": "9441",
        "JobTitle": "warping operator – textile manufacturing"
    },
    {
        "value": 23196,
        "NOCCode": "9441",
        "JobTitle": "washing machine tender – textile manufacturing"
    },
    {
        "value": 23197,
        "NOCCode": "9441",
        "JobTitle": "waxer – textile manufacturing"
    },
    {
        "value": 23198,
        "NOCCode": "9441",
        "JobTitle": "weft straightener – textile manufacturing"
    },
    {
        "value": 23199,
        "NOCCode": "9441",
        "JobTitle": "willow operator – textile manufacturing"
    },
    {
        "value": 23200,
        "NOCCode": "9441",
        "JobTitle": "winder operator – textile manufacturing"
    },
    {
        "value": 23201,
        "NOCCode": "9441",
        "JobTitle": "winder tender – textile manufacturing"
    },
    {
        "value": 23202,
        "NOCCode": "9441",
        "JobTitle": "winding machine operator – textile manufacturing"
    },
    {
        "value": 23203,
        "NOCCode": "9441",
        "JobTitle": "winding machine tender – textile manufacturing"
    },
    {
        "value": 23204,
        "NOCCode": "9441",
        "JobTitle": "wool batcher"
    },
    {
        "value": 23205,
        "NOCCode": "9441",
        "JobTitle": "wool blender"
    },
    {
        "value": 23206,
        "NOCCode": "9441",
        "JobTitle": "wool mixer"
    },
    {
        "value": 23207,
        "NOCCode": "9441",
        "JobTitle": "wool opener and duster"
    },
    {
        "value": 23208,
        "NOCCode": "9441",
        "JobTitle": "wool scourer"
    },
    {
        "value": 23209,
        "NOCCode": "9441",
        "JobTitle": "wool sorter"
    },
    {
        "value": 23210,
        "NOCCode": "9441",
        "JobTitle": "wool washer"
    },
    {
        "value": 23211,
        "NOCCode": "9441",
        "JobTitle": "wringer operator – textile manufacturing"
    },
    {
        "value": 23212,
        "NOCCode": "9441",
        "JobTitle": "wringer tender – textile manufacturing"
    },
    {
        "value": 23213,
        "NOCCode": "9441",
        "JobTitle": "wring-out machine operator – textile manufacturing"
    },
    {
        "value": 23214,
        "NOCCode": "9441",
        "JobTitle": "yarn conditioner"
    },
    {
        "value": 23215,
        "NOCCode": "9441",
        "JobTitle": "yarn doubler – textile manufacturing"
    },
    {
        "value": 23216,
        "NOCCode": "9441",
        "JobTitle": "yarn dryer"
    },
    {
        "value": 23217,
        "NOCCode": "9441",
        "JobTitle": "yarn dyer"
    },
    {
        "value": 23218,
        "NOCCode": "9441",
        "JobTitle": "yarn finisher"
    },
    {
        "value": 23219,
        "NOCCode": "9441",
        "JobTitle": "yarn mercerizer"
    },
    {
        "value": 23220,
        "NOCCode": "9441",
        "JobTitle": "yarn soaker"
    },
    {
        "value": 23221,
        "NOCCode": "9441",
        "JobTitle": "yarn spinner"
    },
    {
        "value": 23222,
        "NOCCode": "9441",
        "JobTitle": "yarn spooler"
    },
    {
        "value": 23223,
        "NOCCode": "9441",
        "JobTitle": "yarn winder"
    },
    {
        "value": 23224,
        "NOCCode": "9441",
        "JobTitle": "yarn winder tender"
    },
    {
        "value": 23225,
        "NOCCode": "9441",
        "JobTitle": "yarn winder, automatic"
    },
    {
        "value": 23226,
        "NOCCode": "9441",
        "JobTitle": "yarn-drying machine tender"
    },
    {
        "value": 23227,
        "NOCCode": "9441",
        "JobTitle": "yarn-plaiting equipment tender"
    },
    {
        "value": 23228,
        "NOCCode": "9441",
        "JobTitle": "yarn-texturing machine tender – textile fibre"
    },
    {
        "value": 23229,
        "NOCCode": "9441",
        "JobTitle": "yarn-texturing machine tender – textile manufacturing"
    },
    {
        "value": 23230,
        "NOCCode": "9442",
        "JobTitle": "automatic embroidery machine tender"
    },
    {
        "value": 23231,
        "NOCCode": "9442",
        "JobTitle": "automatic stocking loom tender"
    },
    {
        "value": 23232,
        "NOCCode": "9442",
        "JobTitle": "Axminster carpet weaver"
    },
    {
        "value": 23233,
        "NOCCode": "9442",
        "JobTitle": "ball fringe machine tender"
    },
    {
        "value": 23234,
        "NOCCode": "9442",
        "JobTitle": "ball fringe maker, machine"
    },
    {
        "value": 23235,
        "NOCCode": "9442",
        "JobTitle": "band-knitting machine operator"
    },
    {
        "value": 23236,
        "NOCCode": "9442",
        "JobTitle": "beam changer – textiles"
    },
    {
        "value": 23237,
        "NOCCode": "9442",
        "JobTitle": "blanket weaver"
    },
    {
        "value": 23238,
        "NOCCode": "9442",
        "JobTitle": "braid maker – textiles"
    },
    {
        "value": 23239,
        "NOCCode": "9442",
        "JobTitle": "braid weaver – textiles"
    },
    {
        "value": 23240,
        "NOCCode": "9442",
        "JobTitle": "braider – textiles"
    },
    {
        "value": 23241,
        "NOCCode": "9442",
        "JobTitle": "braiding machine tender – textiles"
    },
    {
        "value": 23242,
        "NOCCode": "9442",
        "JobTitle": "card lacer – textiles"
    },
    {
        "value": 23243,
        "NOCCode": "9442",
        "JobTitle": "carpet weaver"
    },
    {
        "value": 23244,
        "NOCCode": "9442",
        "JobTitle": "carpet-tufting machine operator"
    },
    {
        "value": 23245,
        "NOCCode": "9442",
        "JobTitle": "carpet-weaving machine operator"
    },
    {
        "value": 23246,
        "NOCCode": "9442",
        "JobTitle": "chain builder – textiles"
    },
    {
        "value": 23247,
        "NOCCode": "9442",
        "JobTitle": "chainman/woman – textiles"
    },
    {
        "value": 23248,
        "NOCCode": "9442",
        "JobTitle": "circular knitter"
    },
    {
        "value": 23249,
        "NOCCode": "9442",
        "JobTitle": "circular knitting machine knitter"
    },
    {
        "value": 23250,
        "NOCCode": "9442",
        "JobTitle": "cloth ribber"
    },
    {
        "value": 23251,
        "NOCCode": "9442",
        "JobTitle": "cloth weaver"
    },
    {
        "value": 23252,
        "NOCCode": "9442",
        "JobTitle": "crochet machine operator"
    },
    {
        "value": 23253,
        "NOCCode": "9442",
        "JobTitle": "crocheting machine operator"
    },
    {
        "value": 23254,
        "NOCCode": "9442",
        "JobTitle": "dobby loom drawer-in"
    },
    {
        "value": 23255,
        "NOCCode": "9442",
        "JobTitle": "dobby loom weaver"
    },
    {
        "value": 23256,
        "NOCCode": "9442",
        "JobTitle": "drawer, warps – textiles"
    },
    {
        "value": 23257,
        "NOCCode": "9442",
        "JobTitle": "drawer-in – textiles"
    },
    {
        "value": 23258,
        "NOCCode": "9442",
        "JobTitle": "drawer-in helper – textiles"
    },
    {
        "value": 23259,
        "NOCCode": "9442",
        "JobTitle": "drawer-in, dobby loom"
    },
    {
        "value": 23260,
        "NOCCode": "9442",
        "JobTitle": "drawer-in, loom"
    },
    {
        "value": 23261,
        "NOCCode": "9442",
        "JobTitle": "drawing-in machine operator"
    },
    {
        "value": 23262,
        "NOCCode": "9442",
        "JobTitle": "drawing-in machine operator – textiles"
    },
    {
        "value": 23263,
        "NOCCode": "9442",
        "JobTitle": "drop-pinning machine operator – textiles"
    },
    {
        "value": 23264,
        "NOCCode": "9442",
        "JobTitle": "embroidery machine operator"
    },
    {
        "value": 23265,
        "NOCCode": "9442",
        "JobTitle": "embroidery machine tender"
    },
    {
        "value": 23266,
        "NOCCode": "9442",
        "JobTitle": "entering machine operator – textiles"
    },
    {
        "value": 23267,
        "NOCCode": "9442",
        "JobTitle": "felt press tender – textiles"
    },
    {
        "value": 23268,
        "NOCCode": "9442",
        "JobTitle": "felting machine tender"
    },
    {
        "value": 23269,
        "NOCCode": "9442",
        "JobTitle": "felt-making machine operator"
    },
    {
        "value": 23270,
        "NOCCode": "9442",
        "JobTitle": "flat knitter"
    },
    {
        "value": 23271,
        "NOCCode": "9442",
        "JobTitle": "flat knitting machine knitter"
    },
    {
        "value": 23272,
        "NOCCode": "9442",
        "JobTitle": "garment looper"
    },
    {
        "value": 23273,
        "NOCCode": "9442",
        "JobTitle": "glass-fibre-bonding machine tender"
    },
    {
        "value": 23274,
        "NOCCode": "9442",
        "JobTitle": "glove knitter"
    },
    {
        "value": 23275,
        "NOCCode": "9442",
        "JobTitle": "harness preparer – textiles"
    },
    {
        "value": 23276,
        "NOCCode": "9442",
        "JobTitle": "harness tier – textiles"
    },
    {
        "value": 23277,
        "NOCCode": "9442",
        "JobTitle": "heddles tier, jacquard loom"
    },
    {
        "value": 23278,
        "NOCCode": "9442",
        "JobTitle": "helper, drawer-in – textiles"
    },
    {
        "value": 23279,
        "NOCCode": "9442",
        "JobTitle": "helper, loom drawer-in"
    },
    {
        "value": 23280,
        "NOCCode": "9442",
        "JobTitle": "helper, loom threader"
    },
    {
        "value": 23281,
        "NOCCode": "9442",
        "JobTitle": "helper, warp drawer-in – textiles"
    },
    {
        "value": 23282,
        "NOCCode": "9442",
        "JobTitle": "hosiery knitter"
    },
    {
        "value": 23283,
        "NOCCode": "9442",
        "JobTitle": "hosiery looper – textiles"
    },
    {
        "value": 23284,
        "NOCCode": "9442",
        "JobTitle": "hosiery seamer – textiles"
    },
    {
        "value": 23285,
        "NOCCode": "9442",
        "JobTitle": "hosiery-knitting machine tender"
    },
    {
        "value": 23286,
        "NOCCode": "9442",
        "JobTitle": "jacquard card cutter – textiles"
    },
    {
        "value": 23287,
        "NOCCode": "9442",
        "JobTitle": "jacquard card lacer – textiles"
    },
    {
        "value": 23288,
        "NOCCode": "9442",
        "JobTitle": "jacquard card-cutting machine operator – textiles"
    },
    {
        "value": 23289,
        "NOCCode": "9442",
        "JobTitle": "jacquard card-lacing machine operator – textiles"
    },
    {
        "value": 23290,
        "NOCCode": "9442",
        "JobTitle": "jacquard loom heddles tier"
    },
    {
        "value": 23291,
        "NOCCode": "9442",
        "JobTitle": "jacquard loom knitter"
    },
    {
        "value": 23292,
        "NOCCode": "9442",
        "JobTitle": "jacquard loom pattern changer"
    },
    {
        "value": 23293,
        "NOCCode": "9442",
        "JobTitle": "jacquard loom weaver"
    },
    {
        "value": 23294,
        "NOCCode": "9442",
        "JobTitle": "jacquard machine knitter"
    },
    {
        "value": 23295,
        "NOCCode": "9442",
        "JobTitle": "jacquard machine operator"
    },
    {
        "value": 23296,
        "NOCCode": "9442",
        "JobTitle": "jersey knitter"
    },
    {
        "value": 23297,
        "NOCCode": "9442",
        "JobTitle": "knitter – textiles"
    },
    {
        "value": 23298,
        "NOCCode": "9442",
        "JobTitle": "knitter, gloves"
    },
    {
        "value": 23299,
        "NOCCode": "9442",
        "JobTitle": "knitter, hosiery"
    },
    {
        "value": 23300,
        "NOCCode": "9442",
        "JobTitle": "knitter, jersey"
    },
    {
        "value": 23301,
        "NOCCode": "9442",
        "JobTitle": "knitter, link machine"
    },
    {
        "value": 23302,
        "NOCCode": "9442",
        "JobTitle": "knitter, ribs – textiles"
    },
    {
        "value": 23303,
        "NOCCode": "9442",
        "JobTitle": "knitter, samples"
    },
    {
        "value": 23304,
        "NOCCode": "9442",
        "JobTitle": "knitter, seamless hosiery"
    },
    {
        "value": 23305,
        "NOCCode": "9442",
        "JobTitle": "knitter, sock feet"
    },
    {
        "value": 23306,
        "NOCCode": "9442",
        "JobTitle": "knitter, socks – textiles"
    },
    {
        "value": 23307,
        "NOCCode": "9442",
        "JobTitle": "knitter, surgical pads"
    },
    {
        "value": 23308,
        "NOCCode": "9442",
        "JobTitle": "knitting area patroller – textiles"
    },
    {
        "value": 23309,
        "NOCCode": "9442",
        "JobTitle": "knitting machine operator"
    },
    {
        "value": 23310,
        "NOCCode": "9442",
        "JobTitle": "knitting machine tender"
    },
    {
        "value": 23311,
        "NOCCode": "9442",
        "JobTitle": "knitting mender, machine"
    },
    {
        "value": 23312,
        "NOCCode": "9442",
        "JobTitle": "knotting machine operator – textiles"
    },
    {
        "value": 23313,
        "NOCCode": "9442",
        "JobTitle": "lace weaver"
    },
    {
        "value": 23314,
        "NOCCode": "9442",
        "JobTitle": "lease worker – textiles"
    },
    {
        "value": 23315,
        "NOCCode": "9442",
        "JobTitle": "leaser – textiles"
    },
    {
        "value": 23316,
        "NOCCode": "9442",
        "JobTitle": "leasing machine tender – textiles"
    },
    {
        "value": 23317,
        "NOCCode": "9442",
        "JobTitle": "link machine knitter"
    },
    {
        "value": 23318,
        "NOCCode": "9442",
        "JobTitle": "loom drawer-in"
    },
    {
        "value": 23319,
        "NOCCode": "9442",
        "JobTitle": "loom drawer-in helper"
    },
    {
        "value": 23320,
        "NOCCode": "9442",
        "JobTitle": "loom drawer-in, machine"
    },
    {
        "value": 23321,
        "NOCCode": "9442",
        "JobTitle": "loom operator"
    },
    {
        "value": 23322,
        "NOCCode": "9442",
        "JobTitle": "loom threader"
    },
    {
        "value": 23323,
        "NOCCode": "9442",
        "JobTitle": "loom threader helper"
    },
    {
        "value": 23324,
        "NOCCode": "9442",
        "JobTitle": "loom threader, machine"
    },
    {
        "value": 23325,
        "NOCCode": "9442",
        "JobTitle": "loom weaver"
    },
    {
        "value": 23326,
        "NOCCode": "9442",
        "JobTitle": "looper – textiles"
    },
    {
        "value": 23327,
        "NOCCode": "9442",
        "JobTitle": "looper, garments"
    },
    {
        "value": 23328,
        "NOCCode": "9442",
        "JobTitle": "looper, hosiery – textiles"
    },
    {
        "value": 23329,
        "NOCCode": "9442",
        "JobTitle": "looper, socks"
    },
    {
        "value": 23330,
        "NOCCode": "9442",
        "JobTitle": "machine ball fringe maker"
    },
    {
        "value": 23331,
        "NOCCode": "9442",
        "JobTitle": "machine knitting mender"
    },
    {
        "value": 23332,
        "NOCCode": "9442",
        "JobTitle": "machine loom drawer-in"
    },
    {
        "value": 23333,
        "NOCCode": "9442",
        "JobTitle": "machine loom threader"
    },
    {
        "value": 23334,
        "NOCCode": "9442",
        "JobTitle": "machine mender, knitting"
    },
    {
        "value": 23335,
        "NOCCode": "9442",
        "JobTitle": "machine tassel maker"
    },
    {
        "value": 23336,
        "NOCCode": "9442",
        "JobTitle": "machine tier-in, warps – textiles"
    },
    {
        "value": 23337,
        "NOCCode": "9442",
        "JobTitle": "machine warp tier-in – textiles"
    },
    {
        "value": 23338,
        "NOCCode": "9442",
        "JobTitle": "mattress sewing machine operator"
    },
    {
        "value": 23339,
        "NOCCode": "9442",
        "JobTitle": "needle loom operator"
    },
    {
        "value": 23340,
        "NOCCode": "9442",
        "JobTitle": "needle loom weaver"
    },
    {
        "value": 23341,
        "NOCCode": "9442",
        "JobTitle": "net maker, machine – textiles"
    },
    {
        "value": 23342,
        "NOCCode": "9442",
        "JobTitle": "net weaver – textiles"
    },
    {
        "value": 23343,
        "NOCCode": "9442",
        "JobTitle": "net-knitting machine operator – textiles"
    },
    {
        "value": 23344,
        "NOCCode": "9442",
        "JobTitle": "netting-making machine operator – textiles"
    },
    {
        "value": 23345,
        "NOCCode": "9442",
        "JobTitle": "operator, carpet-tufting machine"
    },
    {
        "value": 23346,
        "NOCCode": "9442",
        "JobTitle": "operator, drawer-in machine – textiles"
    },
    {
        "value": 23347,
        "NOCCode": "9442",
        "JobTitle": "operator, drop-pinning machine – textiles"
    },
    {
        "value": 23348,
        "NOCCode": "9442",
        "JobTitle": "operator, embroidery machine"
    },
    {
        "value": 23349,
        "NOCCode": "9442",
        "JobTitle": "operator, entering machine – textiles"
    },
    {
        "value": 23350,
        "NOCCode": "9442",
        "JobTitle": "operator, felt-making machine"
    },
    {
        "value": 23351,
        "NOCCode": "9442",
        "JobTitle": "operator, jacquard card-cutting machine – textiles"
    },
    {
        "value": 23352,
        "NOCCode": "9442",
        "JobTitle": "operator, jacquard card-lacing machine – textiles"
    },
    {
        "value": 23353,
        "NOCCode": "9442",
        "JobTitle": "operator, jacquard machine"
    },
    {
        "value": 23354,
        "NOCCode": "9442",
        "JobTitle": "operator, knitting machine"
    },
    {
        "value": 23355,
        "NOCCode": "9442",
        "JobTitle": "operator, knotting machine – textiles"
    },
    {
        "value": 23356,
        "NOCCode": "9442",
        "JobTitle": "operator, loom"
    },
    {
        "value": 23357,
        "NOCCode": "9442",
        "JobTitle": "operator, mattress-sewing machine"
    },
    {
        "value": 23358,
        "NOCCode": "9442",
        "JobTitle": "operator, needle loom"
    },
    {
        "value": 23359,
        "NOCCode": "9442",
        "JobTitle": "operator, net-knitting machine – textiles"
    },
    {
        "value": 23360,
        "NOCCode": "9442",
        "JobTitle": "operator, netting-making machine – textiles"
    },
    {
        "value": 23361,
        "NOCCode": "9442",
        "JobTitle": "operator, pad mangle – textiles"
    },
    {
        "value": 23362,
        "NOCCode": "9442",
        "JobTitle": "operator, padding machine – textiles"
    },
    {
        "value": 23363,
        "NOCCode": "9442",
        "JobTitle": "operator, pinning machine – textiles"
    },
    {
        "value": 23364,
        "NOCCode": "9442",
        "JobTitle": "operator, quilting machine"
    },
    {
        "value": 23365,
        "NOCCode": "9442",
        "JobTitle": "operator, ribbing machine – textiles"
    },
    {
        "value": 23366,
        "NOCCode": "9442",
        "JobTitle": "operator, scroll-quilting machine"
    },
    {
        "value": 23367,
        "NOCCode": "9442",
        "JobTitle": "operator, tufting machine – textiles"
    },
    {
        "value": 23368,
        "NOCCode": "9442",
        "JobTitle": "pad mangle operator – textiles"
    },
    {
        "value": 23369,
        "NOCCode": "9442",
        "JobTitle": "padding machine operator – textiles"
    },
    {
        "value": 23370,
        "NOCCode": "9442",
        "JobTitle": "patroller, knitting area – textiles"
    },
    {
        "value": 23371,
        "NOCCode": "9442",
        "JobTitle": "pattern card preparer – textiles"
    },
    {
        "value": 23372,
        "NOCCode": "9442",
        "JobTitle": "pattern changer, jacquard looms"
    },
    {
        "value": 23373,
        "NOCCode": "9442",
        "JobTitle": "pattern puncher – textiles"
    },
    {
        "value": 23374,
        "NOCCode": "9442",
        "JobTitle": "pattern weaver – textiles"
    },
    {
        "value": 23375,
        "NOCCode": "9442",
        "JobTitle": "pinning machine operator"
    },
    {
        "value": 23376,
        "NOCCode": "9442",
        "JobTitle": "pinning machine operator – textiles"
    },
    {
        "value": 23377,
        "NOCCode": "9442",
        "JobTitle": "power loom weaver"
    },
    {
        "value": 23378,
        "NOCCode": "9442",
        "JobTitle": "preparer, harnesses – textiles"
    },
    {
        "value": 23379,
        "NOCCode": "9442",
        "JobTitle": "press tender, felt – textiles"
    },
    {
        "value": 23380,
        "NOCCode": "9442",
        "JobTitle": "quilting machine operator"
    },
    {
        "value": 23381,
        "NOCCode": "9442",
        "JobTitle": "rib knitter – textiles"
    },
    {
        "value": 23382,
        "NOCCode": "9442",
        "JobTitle": "ribbing machine operator – textiles"
    },
    {
        "value": 23383,
        "NOCCode": "9442",
        "JobTitle": "rope maker – textiles"
    },
    {
        "value": 23384,
        "NOCCode": "9442",
        "JobTitle": "rope twister"
    },
    {
        "value": 23385,
        "NOCCode": "9442",
        "JobTitle": "rope-coiling machine tender – textiles"
    },
    {
        "value": 23386,
        "NOCCode": "9442",
        "JobTitle": "rope-twisting machine tender"
    },
    {
        "value": 23387,
        "NOCCode": "9442",
        "JobTitle": "rug weaver"
    },
    {
        "value": 23388,
        "NOCCode": "9442",
        "JobTitle": "sample knitter"
    },
    {
        "value": 23389,
        "NOCCode": "9442",
        "JobTitle": "sample weaver"
    },
    {
        "value": 23390,
        "NOCCode": "9442",
        "JobTitle": "scroll-quilting machine operator"
    },
    {
        "value": 23391,
        "NOCCode": "9442",
        "JobTitle": "seamless hosiery knitter"
    },
    {
        "value": 23392,
        "NOCCode": "9442",
        "JobTitle": "sock foot knitter"
    },
    {
        "value": 23393,
        "NOCCode": "9442",
        "JobTitle": "sock knitter – textiles"
    },
    {
        "value": 23394,
        "NOCCode": "9442",
        "JobTitle": "sock looper"
    },
    {
        "value": 23395,
        "NOCCode": "9442",
        "JobTitle": "surgical pad knitter"
    },
    {
        "value": 23396,
        "NOCCode": "9442",
        "JobTitle": "tassel machine tender – textiles"
    },
    {
        "value": 23397,
        "NOCCode": "9442",
        "JobTitle": "tassel maker, machine"
    },
    {
        "value": 23398,
        "NOCCode": "9442",
        "JobTitle": "tender, automatic embroidery machine"
    },
    {
        "value": 23399,
        "NOCCode": "9442",
        "JobTitle": "tender, embroidery machine"
    },
    {
        "value": 23400,
        "NOCCode": "9442",
        "JobTitle": "tender, felt press – textiles"
    },
    {
        "value": 23401,
        "NOCCode": "9442",
        "JobTitle": "tender, felting machine"
    },
    {
        "value": 23402,
        "NOCCode": "9442",
        "JobTitle": "tender, glass-fibre-bonding machine"
    },
    {
        "value": 23403,
        "NOCCode": "9442",
        "JobTitle": "tender, hosiery-knitting machine"
    },
    {
        "value": 23404,
        "NOCCode": "9442",
        "JobTitle": "tender, knitting machine"
    },
    {
        "value": 23405,
        "NOCCode": "9442",
        "JobTitle": "tender, leasing machine – textiles"
    },
    {
        "value": 23406,
        "NOCCode": "9442",
        "JobTitle": "tender, rope-coiling machine – textiles"
    },
    {
        "value": 23407,
        "NOCCode": "9442",
        "JobTitle": "tender, rope-twisting machine"
    },
    {
        "value": 23408,
        "NOCCode": "9442",
        "JobTitle": "tender, tassel machine – textiles"
    },
    {
        "value": 23409,
        "NOCCode": "9442",
        "JobTitle": "tender, warp tying machine – textiles"
    },
    {
        "value": 23410,
        "NOCCode": "9442",
        "JobTitle": "textile weaver"
    },
    {
        "value": 23411,
        "NOCCode": "9442",
        "JobTitle": "threader – textiles"
    },
    {
        "value": 23412,
        "NOCCode": "9442",
        "JobTitle": "threader, loom"
    },
    {
        "value": 23413,
        "NOCCode": "9442",
        "JobTitle": "tier-in, warps – textiles"
    },
    {
        "value": 23414,
        "NOCCode": "9442",
        "JobTitle": "top knitter"
    },
    {
        "value": 23415,
        "NOCCode": "9442",
        "JobTitle": "topper – textiles"
    },
    {
        "value": 23416,
        "NOCCode": "9442",
        "JobTitle": "transfer knitter"
    },
    {
        "value": 23417,
        "NOCCode": "9442",
        "JobTitle": "tufting machine operator – textiles"
    },
    {
        "value": 23418,
        "NOCCode": "9442",
        "JobTitle": "tufting machine operator, carpets"
    },
    {
        "value": 23419,
        "NOCCode": "9442",
        "JobTitle": "tufting operator"
    },
    {
        "value": 23420,
        "NOCCode": "9442",
        "JobTitle": "warp drawer – textiles"
    },
    {
        "value": 23421,
        "NOCCode": "9442",
        "JobTitle": "warp drawer-in helper – textiles"
    },
    {
        "value": 23422,
        "NOCCode": "9442",
        "JobTitle": "warp knitting machine tender"
    },
    {
        "value": 23423,
        "NOCCode": "9442",
        "JobTitle": "warp placer – textiles"
    },
    {
        "value": 23424,
        "NOCCode": "9442",
        "JobTitle": "warp tier-in"
    },
    {
        "value": 23425,
        "NOCCode": "9442",
        "JobTitle": "warp tier-in – textiles"
    },
    {
        "value": 23426,
        "NOCCode": "9442",
        "JobTitle": "warp tier-in, machine – textiles"
    },
    {
        "value": 23427,
        "NOCCode": "9442",
        "JobTitle": "warp tying machine tender – textiles"
    },
    {
        "value": 23428,
        "NOCCode": "9442",
        "JobTitle": "weaver – textiles"
    },
    {
        "value": 23429,
        "NOCCode": "9442",
        "JobTitle": "weaver, blankets"
    },
    {
        "value": 23430,
        "NOCCode": "9442",
        "JobTitle": "weaver, carpet"
    },
    {
        "value": 23431,
        "NOCCode": "9442",
        "JobTitle": "weaver, cloth"
    },
    {
        "value": 23432,
        "NOCCode": "9442",
        "JobTitle": "weaver, jacquard loom"
    },
    {
        "value": 23433,
        "NOCCode": "9442",
        "JobTitle": "weaver, loom"
    },
    {
        "value": 23434,
        "NOCCode": "9442",
        "JobTitle": "weaver, needle loom"
    },
    {
        "value": 23435,
        "NOCCode": "9442",
        "JobTitle": "weaver, nets – textiles"
    },
    {
        "value": 23436,
        "NOCCode": "9442",
        "JobTitle": "weaver, patterns – textiles"
    },
    {
        "value": 23437,
        "NOCCode": "9442",
        "JobTitle": "weaver, power loom"
    },
    {
        "value": 23438,
        "NOCCode": "9442",
        "JobTitle": "weaver, rugs"
    },
    {
        "value": 23439,
        "NOCCode": "9442",
        "JobTitle": "weaver, samples"
    },
    {
        "value": 23440,
        "NOCCode": "9445",
        "JobTitle": "bias-cutting machine tender – fabric, fur and leather products manufacturing"
    },
    {
        "value": 23441,
        "NOCCode": "9445",
        "JobTitle": "canvas cutter – fabric products manufacturing"
    },
    {
        "value": 23442,
        "NOCCode": "9445",
        "JobTitle": "cloth cutter – fabric products manufacturing"
    },
    {
        "value": 23443,
        "NOCCode": "9445",
        "JobTitle": "cloth-cutting machine operator – fabric products manufacturing"
    },
    {
        "value": 23444,
        "NOCCode": "9445",
        "JobTitle": "clothing cutter"
    },
    {
        "value": 23445,
        "NOCCode": "9445",
        "JobTitle": "clothing cutter – clothing manufacturing"
    },
    {
        "value": 23446,
        "NOCCode": "9445",
        "JobTitle": "cuff-cutting machine tender"
    },
    {
        "value": 23447,
        "NOCCode": "9445",
        "JobTitle": "cutter – leather products manufacturing"
    },
    {
        "value": 23448,
        "NOCCode": "9445",
        "JobTitle": "cutter, shoe parts – footwear manufacturing"
    },
    {
        "value": 23449,
        "NOCCode": "9445",
        "JobTitle": "cutting machine operator – clothing manufacturing"
    },
    {
        "value": 23450,
        "NOCCode": "9445",
        "JobTitle": "die cutter – fabric, fur and leather products manufacturing"
    },
    {
        "value": 23451,
        "NOCCode": "9445",
        "JobTitle": "fabric cutter"
    },
    {
        "value": 23452,
        "NOCCode": "9445",
        "JobTitle": "fabric cutter – fabric products manufacturing"
    },
    {
        "value": 23453,
        "NOCCode": "9445",
        "JobTitle": "fabric marker"
    },
    {
        "value": 23454,
        "NOCCode": "9445",
        "JobTitle": "felt cutter, machine"
    },
    {
        "value": 23455,
        "NOCCode": "9445",
        "JobTitle": "fur cutter"
    },
    {
        "value": 23456,
        "NOCCode": "9445",
        "JobTitle": "garment marker"
    },
    {
        "value": 23457,
        "NOCCode": "9445",
        "JobTitle": "glove cutter"
    },
    {
        "value": 23458,
        "NOCCode": "9445",
        "JobTitle": "hand cutter – clothing manufacturing"
    },
    {
        "value": 23459,
        "NOCCode": "9445",
        "JobTitle": "hand cutter – fabric, fur and leather products manufacturing"
    },
    {
        "value": 23460,
        "NOCCode": "9445",
        "JobTitle": "hand leather cutter – leather products manufacturing"
    },
    {
        "value": 23461,
        "NOCCode": "9445",
        "JobTitle": "harness maker, leather"
    },
    {
        "value": 23462,
        "NOCCode": "9445",
        "JobTitle": "leather cutter"
    },
    {
        "value": 23463,
        "NOCCode": "9445",
        "JobTitle": "leather cutter – leather footwear manufacturing"
    },
    {
        "value": 23464,
        "NOCCode": "9445",
        "JobTitle": "leather garment cutter – manufacturing"
    },
    {
        "value": 23465,
        "NOCCode": "9445",
        "JobTitle": "leather harness maker"
    },
    {
        "value": 23466,
        "NOCCode": "9445",
        "JobTitle": "leather marker"
    },
    {
        "value": 23467,
        "NOCCode": "9445",
        "JobTitle": "leather pressman/woman"
    },
    {
        "value": 23468,
        "NOCCode": "9445",
        "JobTitle": "leather rifle case maker"
    },
    {
        "value": 23469,
        "NOCCode": "9445",
        "JobTitle": "leather trunk maker"
    },
    {
        "value": 23470,
        "NOCCode": "9445",
        "JobTitle": "leather-stripping machine tender – leather products manufacturing"
    },
    {
        "value": 23471,
        "NOCCode": "9445",
        "JobTitle": "machine felt cutter"
    },
    {
        "value": 23472,
        "NOCCode": "9445",
        "JobTitle": "marker and cutter, upholstery"
    },
    {
        "value": 23473,
        "NOCCode": "9445",
        "JobTitle": "master pattern cutter"
    },
    {
        "value": 23474,
        "NOCCode": "9445",
        "JobTitle": "mink tail slitter – fur products manufacturing"
    },
    {
        "value": 23475,
        "NOCCode": "9445",
        "JobTitle": "pattern cutter"
    },
    {
        "value": 23476,
        "NOCCode": "9445",
        "JobTitle": "rifle case maker, leather"
    },
    {
        "value": 23477,
        "NOCCode": "9445",
        "JobTitle": "saddle cutter – leather products manufacturing"
    },
    {
        "value": 23478,
        "NOCCode": "9445",
        "JobTitle": "sailcloth cutter – fabric products manufacturing"
    },
    {
        "value": 23479,
        "NOCCode": "9445",
        "JobTitle": "sample cutter"
    },
    {
        "value": 23480,
        "NOCCode": "9445",
        "JobTitle": "sample cutter – fabric, fur and leather products manufacturing"
    },
    {
        "value": 23481,
        "NOCCode": "9445",
        "JobTitle": "shoe cutter"
    },
    {
        "value": 23482,
        "NOCCode": "9445",
        "JobTitle": "shoe parts cutter – footwear manufacturing"
    },
    {
        "value": 23483,
        "NOCCode": "9445",
        "JobTitle": "slipcover cutter – fabric products manufacturing"
    },
    {
        "value": 23484,
        "NOCCode": "9445",
        "JobTitle": "slitter and rewinder"
    },
    {
        "value": 23485,
        "NOCCode": "9445",
        "JobTitle": "slitter-rewinder"
    },
    {
        "value": 23486,
        "NOCCode": "9445",
        "JobTitle": "stripping machine tender – leather products manufacturing"
    },
    {
        "value": 23487,
        "NOCCode": "9445",
        "JobTitle": "trimmer – leather products manufacturing"
    },
    {
        "value": 23488,
        "NOCCode": "9445",
        "JobTitle": "trunk maker, leather"
    },
    {
        "value": 23489,
        "NOCCode": "9445",
        "JobTitle": "upholstery marker and cutter"
    },
    {
        "value": 23490,
        "NOCCode": "9445",
        "JobTitle": "welt maker – hat manufacturing"
    },
    {
        "value": 23491,
        "NOCCode": "9445",
        "JobTitle": "welt-trimming machine tender – hat manufacturing"
    },
    {
        "value": 23492,
        "NOCCode": "9446",
        "JobTitle": "automatic sewing machine operator"
    },
    {
        "value": 23493,
        "NOCCode": "9446",
        "JobTitle": "bag sewer – textile manufacturing"
    },
    {
        "value": 23494,
        "NOCCode": "9446",
        "JobTitle": "bag sewer, leather"
    },
    {
        "value": 23495,
        "NOCCode": "9446",
        "JobTitle": "basting machine operator – sewing"
    },
    {
        "value": 23496,
        "NOCCode": "9446",
        "JobTitle": "basting sewing machine operator, automatic"
    },
    {
        "value": 23497,
        "NOCCode": "9446",
        "JobTitle": "binding machine operator – sewing"
    },
    {
        "value": 23498,
        "NOCCode": "9446",
        "JobTitle": "binding sewing machine operator"
    },
    {
        "value": 23499,
        "NOCCode": "9446",
        "JobTitle": "binding stitcher – footwear manufacturing"
    },
    {
        "value": 23500,
        "NOCCode": "9446",
        "JobTitle": "blindstitch-sewing machine operator"
    },
    {
        "value": 23501,
        "NOCCode": "9446",
        "JobTitle": "button sewer, machine"
    },
    {
        "value": 23502,
        "NOCCode": "9446",
        "JobTitle": "button stayer"
    },
    {
        "value": 23503,
        "NOCCode": "9446",
        "JobTitle": "buttonhole finisher"
    },
    {
        "value": 23504,
        "NOCCode": "9446",
        "JobTitle": "buttonhole machine operator"
    },
    {
        "value": 23505,
        "NOCCode": "9446",
        "JobTitle": "buttonhole maker"
    },
    {
        "value": 23506,
        "NOCCode": "9446",
        "JobTitle": "buttonhole stayer"
    },
    {
        "value": 23507,
        "NOCCode": "9446",
        "JobTitle": "canvas goods sewing machine operator"
    },
    {
        "value": 23508,
        "NOCCode": "9446",
        "JobTitle": "carpet binder"
    },
    {
        "value": 23509,
        "NOCCode": "9446",
        "JobTitle": "carpet sewer"
    },
    {
        "value": 23510,
        "NOCCode": "9446",
        "JobTitle": "closer – sewing"
    },
    {
        "value": 23511,
        "NOCCode": "9446",
        "JobTitle": "closer sewing machine operator"
    },
    {
        "value": 23512,
        "NOCCode": "9446",
        "JobTitle": "cloth mender"
    },
    {
        "value": 23513,
        "NOCCode": "9446",
        "JobTitle": "commercial sewing machine operator"
    },
    {
        "value": 23514,
        "NOCCode": "9446",
        "JobTitle": "domestic sewing machine operator"
    },
    {
        "value": 23515,
        "NOCCode": "9446",
        "JobTitle": "double needle sewing machine operator"
    },
    {
        "value": 23516,
        "NOCCode": "9446",
        "JobTitle": "double needle sewing machine stitcher"
    },
    {
        "value": 23517,
        "NOCCode": "9446",
        "JobTitle": "drapery header"
    },
    {
        "value": 23518,
        "NOCCode": "9446",
        "JobTitle": "drapery heading maker"
    },
    {
        "value": 23519,
        "NOCCode": "9446",
        "JobTitle": "drapery sewer"
    },
    {
        "value": 23520,
        "NOCCode": "9446",
        "JobTitle": "fabric mender"
    },
    {
        "value": 23521,
        "NOCCode": "9446",
        "JobTitle": "fabric repairer"
    },
    {
        "value": 23522,
        "NOCCode": "9446",
        "JobTitle": "factory hat sewer"
    },
    {
        "value": 23523,
        "NOCCode": "9446",
        "JobTitle": "fancy stitch machine operator"
    },
    {
        "value": 23524,
        "NOCCode": "9446",
        "JobTitle": "footwear sewer"
    },
    {
        "value": 23525,
        "NOCCode": "9446",
        "JobTitle": "fur goods sewer"
    },
    {
        "value": 23526,
        "NOCCode": "9446",
        "JobTitle": "fur sewer"
    },
    {
        "value": 23527,
        "NOCCode": "9446",
        "JobTitle": "fur sewing machine operator"
    },
    {
        "value": 23528,
        "NOCCode": "9446",
        "JobTitle": "garment braid maker – garment manufacturing"
    },
    {
        "value": 23529,
        "NOCCode": "9446",
        "JobTitle": "garment braider – garment manufacturing"
    },
    {
        "value": 23530,
        "NOCCode": "9446",
        "JobTitle": "garment mender – sewing"
    },
    {
        "value": 23531,
        "NOCCode": "9446",
        "JobTitle": "glove stitcher"
    },
    {
        "value": 23532,
        "NOCCode": "9446",
        "JobTitle": "hat sewer, factory"
    },
    {
        "value": 23533,
        "NOCCode": "9446",
        "JobTitle": "hemmer machine operator – sewing"
    },
    {
        "value": 23534,
        "NOCCode": "9446",
        "JobTitle": "industrial sewing machine operator"
    },
    {
        "value": 23535,
        "NOCCode": "9446",
        "JobTitle": "joiner sewing machine operator"
    },
    {
        "value": 23536,
        "NOCCode": "9446",
        "JobTitle": "leather garment sewer"
    },
    {
        "value": 23537,
        "NOCCode": "9446",
        "JobTitle": "leather garment sewing machine operator"
    },
    {
        "value": 23538,
        "NOCCode": "9446",
        "JobTitle": "leather glove repairer"
    },
    {
        "value": 23539,
        "NOCCode": "9446",
        "JobTitle": "leather products sewing machine operator"
    },
    {
        "value": 23540,
        "NOCCode": "9446",
        "JobTitle": "leather sewer"
    },
    {
        "value": 23541,
        "NOCCode": "9446",
        "JobTitle": "leather-glove-stitching machine operator"
    },
    {
        "value": 23542,
        "NOCCode": "9446",
        "JobTitle": "leather-sewing machine operator"
    },
    {
        "value": 23543,
        "NOCCode": "9446",
        "JobTitle": "lining sewer"
    },
    {
        "value": 23544,
        "NOCCode": "9446",
        "JobTitle": "lining stitcher"
    },
    {
        "value": 23545,
        "NOCCode": "9446",
        "JobTitle": "machine operator – garment manufacturing"
    },
    {
        "value": 23546,
        "NOCCode": "9446",
        "JobTitle": "material mender"
    },
    {
        "value": 23547,
        "NOCCode": "9446",
        "JobTitle": "mender, garment – sewing"
    },
    {
        "value": 23548,
        "NOCCode": "9446",
        "JobTitle": "mender, textile products"
    },
    {
        "value": 23549,
        "NOCCode": "9446",
        "JobTitle": "moccasin sewer"
    },
    {
        "value": 23550,
        "NOCCode": "9446",
        "JobTitle": "multineedle sewing machine operator"
    },
    {
        "value": 23551,
        "NOCCode": "9446",
        "JobTitle": "operator, leather gloves stitching machine"
    },
    {
        "value": 23552,
        "NOCCode": "9446",
        "JobTitle": "operator, sewing machine"
    },
    {
        "value": 23553,
        "NOCCode": "9446",
        "JobTitle": "overcaster – garment manufacturing"
    },
    {
        "value": 23554,
        "NOCCode": "9446",
        "JobTitle": "overcasting machine operator"
    },
    {
        "value": 23555,
        "NOCCode": "9446",
        "JobTitle": "overedging machine operator"
    },
    {
        "value": 23556,
        "NOCCode": "9446",
        "JobTitle": "overlock sewing machine operator"
    },
    {
        "value": 23557,
        "NOCCode": "9446",
        "JobTitle": "overseamer – garment manufacturing"
    },
    {
        "value": 23558,
        "NOCCode": "9446",
        "JobTitle": "piping-sewing machine operator"
    },
    {
        "value": 23559,
        "NOCCode": "9446",
        "JobTitle": "pocket setter"
    },
    {
        "value": 23560,
        "NOCCode": "9446",
        "JobTitle": "profile-pocket sewing machine operator"
    },
    {
        "value": 23561,
        "NOCCode": "9446",
        "JobTitle": "repairer, leather glove"
    },
    {
        "value": 23562,
        "NOCCode": "9446",
        "JobTitle": "rug joiner"
    },
    {
        "value": 23563,
        "NOCCode": "9446",
        "JobTitle": "rug sewer"
    },
    {
        "value": 23564,
        "NOCCode": "9446",
        "JobTitle": "sample sewer"
    },
    {
        "value": 23565,
        "NOCCode": "9446",
        "JobTitle": "serger – garment manufacturing"
    },
    {
        "value": 23566,
        "NOCCode": "9446",
        "JobTitle": "serging machine operator"
    },
    {
        "value": 23567,
        "NOCCode": "9446",
        "JobTitle": "sewer – garment manufacturing"
    },
    {
        "value": 23568,
        "NOCCode": "9446",
        "JobTitle": "sewer, fur goods"
    },
    {
        "value": 23569,
        "NOCCode": "9446",
        "JobTitle": "sewer, textile products"
    },
    {
        "value": 23570,
        "NOCCode": "9446",
        "JobTitle": "sewing machine operator"
    },
    {
        "value": 23571,
        "NOCCode": "9446",
        "JobTitle": "sewing machine operator, automatic"
    },
    {
        "value": 23572,
        "NOCCode": "9446",
        "JobTitle": "sewing machine operator, fur"
    },
    {
        "value": 23573,
        "NOCCode": "9446",
        "JobTitle": "shoe parts sewer"
    },
    {
        "value": 23574,
        "NOCCode": "9446",
        "JobTitle": "shoe sewer"
    },
    {
        "value": 23575,
        "NOCCode": "9446",
        "JobTitle": "shoe stitcher"
    },
    {
        "value": 23576,
        "NOCCode": "9446",
        "JobTitle": "single-needle sewing machine operator"
    },
    {
        "value": 23577,
        "NOCCode": "9446",
        "JobTitle": "slide-fastener-sewing machine operator"
    },
    {
        "value": 23578,
        "NOCCode": "9446",
        "JobTitle": "slipper maker"
    },
    {
        "value": 23579,
        "NOCCode": "9446",
        "JobTitle": "stitcher – garment manufacturing"
    },
    {
        "value": 23580,
        "NOCCode": "9446",
        "JobTitle": "stitching machine operator – industrial sewing machine"
    },
    {
        "value": 23581,
        "NOCCode": "9446",
        "JobTitle": "stocking stitcher"
    },
    {
        "value": 23582,
        "NOCCode": "9446",
        "JobTitle": "tacking machine operator – sewing"
    },
    {
        "value": 23583,
        "NOCCode": "9446",
        "JobTitle": "textile products mender"
    },
    {
        "value": 23584,
        "NOCCode": "9446",
        "JobTitle": "tufted carpet mender"
    },
    {
        "value": 23585,
        "NOCCode": "9446",
        "JobTitle": "underwear sewer"
    },
    {
        "value": 23586,
        "NOCCode": "9446",
        "JobTitle": "vamp stitcher"
    },
    {
        "value": 23587,
        "NOCCode": "9446",
        "JobTitle": "zipper-sewing machine operator"
    },
    {
        "value": 23588,
        "NOCCode": "9447",
        "JobTitle": "blue-leather grader"
    },
    {
        "value": 23589,
        "NOCCode": "9447",
        "JobTitle": "boot and shoe grader"
    },
    {
        "value": 23590,
        "NOCCode": "9447",
        "JobTitle": "boot and shoe inspector"
    },
    {
        "value": 23591,
        "NOCCode": "9447",
        "JobTitle": "boot and shoe sorter"
    },
    {
        "value": 23592,
        "NOCCode": "9447",
        "JobTitle": "canvas goods inspector"
    },
    {
        "value": 23593,
        "NOCCode": "9447",
        "JobTitle": "carpet inspector"
    },
    {
        "value": 23594,
        "NOCCode": "9447",
        "JobTitle": "cloth examiner"
    },
    {
        "value": 23595,
        "NOCCode": "9447",
        "JobTitle": "cloth grader"
    },
    {
        "value": 23596,
        "NOCCode": "9447",
        "JobTitle": "cloth inspector"
    },
    {
        "value": 23597,
        "NOCCode": "9447",
        "JobTitle": "cloth measurer"
    },
    {
        "value": 23598,
        "NOCCode": "9447",
        "JobTitle": "cloth sampler"
    },
    {
        "value": 23599,
        "NOCCode": "9447",
        "JobTitle": "cloth sorter"
    },
    {
        "value": 23600,
        "NOCCode": "9447",
        "JobTitle": "cloth tester"
    },
    {
        "value": 23601,
        "NOCCode": "9447",
        "JobTitle": "clothing inspector"
    },
    {
        "value": 23602,
        "NOCCode": "9447",
        "JobTitle": "cloth-measuring machine tender"
    },
    {
        "value": 23603,
        "NOCCode": "9447",
        "JobTitle": "colour grader – textiles"
    },
    {
        "value": 23604,
        "NOCCode": "9447",
        "JobTitle": "colour matcher – textiles"
    },
    {
        "value": 23605,
        "NOCCode": "9447",
        "JobTitle": "cushion and cover inspector"
    },
    {
        "value": 23606,
        "NOCCode": "9447",
        "JobTitle": "cushion and furniture-coverings manufacturing inspector"
    },
    {
        "value": 23607,
        "NOCCode": "9447",
        "JobTitle": "draper"
    },
    {
        "value": 23608,
        "NOCCode": "9447",
        "JobTitle": "fabric examiner"
    },
    {
        "value": 23609,
        "NOCCode": "9447",
        "JobTitle": "fabric grader"
    },
    {
        "value": 23610,
        "NOCCode": "9447",
        "JobTitle": "fabric inspector"
    },
    {
        "value": 23611,
        "NOCCode": "9447",
        "JobTitle": "fabric manufacturing inspector"
    },
    {
        "value": 23612,
        "NOCCode": "9447",
        "JobTitle": "fabric sampler"
    },
    {
        "value": 23613,
        "NOCCode": "9447",
        "JobTitle": "fibre examiner – textiles"
    },
    {
        "value": 23614,
        "NOCCode": "9447",
        "JobTitle": "fibre grader – textiles"
    },
    {
        "value": 23615,
        "NOCCode": "9447",
        "JobTitle": "fibre inspector – textiles"
    },
    {
        "value": 23616,
        "NOCCode": "9447",
        "JobTitle": "final inspector, garments"
    },
    {
        "value": 23617,
        "NOCCode": "9447",
        "JobTitle": "finishing inspector – textiles"
    },
    {
        "value": 23618,
        "NOCCode": "9447",
        "JobTitle": "fitting room inspector – fabric products manufacturing"
    },
    {
        "value": 23619,
        "NOCCode": "9447",
        "JobTitle": "foundation garments inspector"
    },
    {
        "value": 23620,
        "NOCCode": "9447",
        "JobTitle": "fur grader"
    },
    {
        "value": 23621,
        "NOCCode": "9447",
        "JobTitle": "fur matcher – fur products manufacturing"
    },
    {
        "value": 23622,
        "NOCCode": "9447",
        "JobTitle": "fur sorter"
    },
    {
        "value": 23623,
        "NOCCode": "9447",
        "JobTitle": "fur sorter, hats"
    },
    {
        "value": 23624,
        "NOCCode": "9447",
        "JobTitle": "garment final inspector"
    },
    {
        "value": 23625,
        "NOCCode": "9447",
        "JobTitle": "garment inspector"
    },
    {
        "value": 23626,
        "NOCCode": "9447",
        "JobTitle": "garment manufacturing inspector"
    },
    {
        "value": 23627,
        "NOCCode": "9447",
        "JobTitle": "glove and mitten examiner"
    },
    {
        "value": 23628,
        "NOCCode": "9447",
        "JobTitle": "grader – textiles"
    },
    {
        "value": 23629,
        "NOCCode": "9447",
        "JobTitle": "grader, fabric"
    },
    {
        "value": 23630,
        "NOCCode": "9447",
        "JobTitle": "grader, hosiery"
    },
    {
        "value": 23631,
        "NOCCode": "9447",
        "JobTitle": "greige-cloth examiner"
    },
    {
        "value": 23632,
        "NOCCode": "9447",
        "JobTitle": "hat fur sorter"
    },
    {
        "value": 23633,
        "NOCCode": "9447",
        "JobTitle": "hat inspector"
    },
    {
        "value": 23634,
        "NOCCode": "9447",
        "JobTitle": "hide and pelt processing inspector"
    },
    {
        "value": 23635,
        "NOCCode": "9447",
        "JobTitle": "hide grader"
    },
    {
        "value": 23636,
        "NOCCode": "9447",
        "JobTitle": "hide inspector"
    },
    {
        "value": 23637,
        "NOCCode": "9447",
        "JobTitle": "hide sorter"
    },
    {
        "value": 23638,
        "NOCCode": "9447",
        "JobTitle": "hosiery grader"
    },
    {
        "value": 23639,
        "NOCCode": "9447",
        "JobTitle": "hosiery inspector"
    },
    {
        "value": 23640,
        "NOCCode": "9447",
        "JobTitle": "hosiery pairer"
    },
    {
        "value": 23641,
        "NOCCode": "9447",
        "JobTitle": "hosiery sizer"
    },
    {
        "value": 23642,
        "NOCCode": "9447",
        "JobTitle": "inspecting machine tender – textiles"
    },
    {
        "value": 23643,
        "NOCCode": "9447",
        "JobTitle": "inspector – fabric products manufacturing"
    },
    {
        "value": 23644,
        "NOCCode": "9447",
        "JobTitle": "inspector – fur products manufacturing"
    },
    {
        "value": 23645,
        "NOCCode": "9447",
        "JobTitle": "inspector – textiles"
    },
    {
        "value": 23646,
        "NOCCode": "9447",
        "JobTitle": "inspector, foundation garments"
    },
    {
        "value": 23647,
        "NOCCode": "9447",
        "JobTitle": "inspector, garment"
    },
    {
        "value": 23648,
        "NOCCode": "9447",
        "JobTitle": "inspector, knitting"
    },
    {
        "value": 23649,
        "NOCCode": "9447",
        "JobTitle": "inspector, leather goods"
    },
    {
        "value": 23650,
        "NOCCode": "9447",
        "JobTitle": "inspector, loom"
    },
    {
        "value": 23651,
        "NOCCode": "9447",
        "JobTitle": "inspector, trimming material"
    },
    {
        "value": 23652,
        "NOCCode": "9447",
        "JobTitle": "inspector-grader, seconds"
    },
    {
        "value": 23653,
        "NOCCode": "9447",
        "JobTitle": "jacket manufacturing inspector"
    },
    {
        "value": 23654,
        "NOCCode": "9447",
        "JobTitle": "knitted fabric inspector"
    },
    {
        "value": 23655,
        "NOCCode": "9447",
        "JobTitle": "knitting inspector"
    },
    {
        "value": 23656,
        "NOCCode": "9447",
        "JobTitle": "leather glove examiner"
    },
    {
        "value": 23657,
        "NOCCode": "9447",
        "JobTitle": "leather goods inspector"
    },
    {
        "value": 23658,
        "NOCCode": "9447",
        "JobTitle": "leather grader"
    },
    {
        "value": 23659,
        "NOCCode": "9447",
        "JobTitle": "leather inspector"
    },
    {
        "value": 23660,
        "NOCCode": "9447",
        "JobTitle": "leather sorter"
    },
    {
        "value": 23661,
        "NOCCode": "9447",
        "JobTitle": "leather stock inspector"
    },
    {
        "value": 23662,
        "NOCCode": "9447",
        "JobTitle": "leather tanneries inspector"
    },
    {
        "value": 23663,
        "NOCCode": "9447",
        "JobTitle": "loom inspector"
    },
    {
        "value": 23664,
        "NOCCode": "9447",
        "JobTitle": "narrow fabric examiner"
    },
    {
        "value": 23665,
        "NOCCode": "9447",
        "JobTitle": "parachute inspector – fabric products manufacturing"
    },
    {
        "value": 23666,
        "NOCCode": "9447",
        "JobTitle": "pelt grader – hide and pelt processing"
    },
    {
        "value": 23667,
        "NOCCode": "9447",
        "JobTitle": "pelt processing inspector"
    },
    {
        "value": 23668,
        "NOCCode": "9447",
        "JobTitle": "perch operator"
    },
    {
        "value": 23669,
        "NOCCode": "9447",
        "JobTitle": "perch operator – textiles"
    },
    {
        "value": 23670,
        "NOCCode": "9447",
        "JobTitle": "percher – textiles"
    },
    {
        "value": 23671,
        "NOCCode": "9447",
        "JobTitle": "preshrinking process tester – textiles"
    },
    {
        "value": 23672,
        "NOCCode": "9447",
        "JobTitle": "quality control inspector – textiles"
    },
    {
        "value": 23673,
        "NOCCode": "9447",
        "JobTitle": "quality control tester – textiles"
    },
    {
        "value": 23674,
        "NOCCode": "9447",
        "JobTitle": "quality controller – textiles"
    },
    {
        "value": 23675,
        "NOCCode": "9447",
        "JobTitle": "roving weight gauger – textiles"
    },
    {
        "value": 23676,
        "NOCCode": "9447",
        "JobTitle": "sample checker – textiles"
    },
    {
        "value": 23677,
        "NOCCode": "9447",
        "JobTitle": "sample preparer – textiles"
    },
    {
        "value": 23678,
        "NOCCode": "9447",
        "JobTitle": "sampler – textiles"
    },
    {
        "value": 23679,
        "NOCCode": "9447",
        "JobTitle": "seconds inspector-grader"
    },
    {
        "value": 23680,
        "NOCCode": "9447",
        "JobTitle": "shade matcher – textiles"
    },
    {
        "value": 23681,
        "NOCCode": "9447",
        "JobTitle": "shirt inspector"
    },
    {
        "value": 23682,
        "NOCCode": "9447",
        "JobTitle": "shoe inspector"
    },
    {
        "value": 23683,
        "NOCCode": "9447",
        "JobTitle": "skein yarn examiner – textiles"
    },
    {
        "value": 23684,
        "NOCCode": "9447",
        "JobTitle": "sorter, boot and shoe"
    },
    {
        "value": 23685,
        "NOCCode": "9447",
        "JobTitle": "sorter, leather"
    },
    {
        "value": 23686,
        "NOCCode": "9447",
        "JobTitle": "stocking inspector"
    },
    {
        "value": 23687,
        "NOCCode": "9447",
        "JobTitle": "swatch checker – textiles"
    },
    {
        "value": 23688,
        "NOCCode": "9447",
        "JobTitle": "textile grader"
    },
    {
        "value": 23689,
        "NOCCode": "9447",
        "JobTitle": "textile inspector"
    },
    {
        "value": 23690,
        "NOCCode": "9447",
        "JobTitle": "textile product sampler"
    },
    {
        "value": 23691,
        "NOCCode": "9447",
        "JobTitle": "textile products inspector"
    },
    {
        "value": 23692,
        "NOCCode": "9447",
        "JobTitle": "textile sampler"
    },
    {
        "value": 23693,
        "NOCCode": "9447",
        "JobTitle": "textile shader"
    },
    {
        "value": 23694,
        "NOCCode": "9447",
        "JobTitle": "textile tester"
    },
    {
        "value": 23695,
        "NOCCode": "9447",
        "JobTitle": "thread inspector – textiles"
    },
    {
        "value": 23696,
        "NOCCode": "9447",
        "JobTitle": "trimming material inspector"
    },
    {
        "value": 23697,
        "NOCCode": "9447",
        "JobTitle": "warp tension tester – textiles"
    },
    {
        "value": 23698,
        "NOCCode": "9447",
        "JobTitle": "weaving inspector"
    },
    {
        "value": 23699,
        "NOCCode": "9447",
        "JobTitle": "wool fleece grader"
    },
    {
        "value": 23700,
        "NOCCode": "9447",
        "JobTitle": "wool grader"
    },
    {
        "value": 23701,
        "NOCCode": "9447",
        "JobTitle": "yarn and spun yarn inspector"
    },
    {
        "value": 23702,
        "NOCCode": "9447",
        "JobTitle": "yarn examiner"
    },
    {
        "value": 23703,
        "NOCCode": "9447",
        "JobTitle": "yarn inspector"
    },
    {
        "value": 23704,
        "NOCCode": "9447",
        "JobTitle": "yarn tester"
    },
    {
        "value": 23705,
        "NOCCode": "9461",
        "JobTitle": "applesauce processor operator"
    },
    {
        "value": 23706,
        "NOCCode": "9461",
        "JobTitle": "assistant brewer – food and beverage processing"
    },
    {
        "value": 23707,
        "NOCCode": "9461",
        "JobTitle": "assistant miller – food and beverage processing"
    },
    {
        "value": 23708,
        "NOCCode": "9461",
        "JobTitle": "bacon curer"
    },
    {
        "value": 23709,
        "NOCCode": "9461",
        "JobTitle": "bagging machine setter"
    },
    {
        "value": 23710,
        "NOCCode": "9461",
        "JobTitle": "bagging machine set-up man/woman"
    },
    {
        "value": 23711,
        "NOCCode": "9461",
        "JobTitle": "Bake-off equipment tender – food and beverage processing"
    },
    {
        "value": 23712,
        "NOCCode": "9461",
        "JobTitle": "bakery machine operator – food and beverage processing"
    },
    {
        "value": 23713,
        "NOCCode": "9461",
        "JobTitle": "basket unloader"
    },
    {
        "value": 23714,
        "NOCCode": "9461",
        "JobTitle": "batch mixer, feed – food and beverage processing"
    },
    {
        "value": 23715,
        "NOCCode": "9461",
        "JobTitle": "batter and dough mixer – food and beverage processing"
    },
    {
        "value": 23716,
        "NOCCode": "9461",
        "JobTitle": "beer carton packer"
    },
    {
        "value": 23717,
        "NOCCode": "9461",
        "JobTitle": "beer filter operator"
    },
    {
        "value": 23718,
        "NOCCode": "9461",
        "JobTitle": "beer pasteurizer"
    },
    {
        "value": 23719,
        "NOCCode": "9461",
        "JobTitle": "beet pulp press tender"
    },
    {
        "value": 23720,
        "NOCCode": "9461",
        "JobTitle": "beverage-canning machine operator"
    },
    {
        "value": 23721,
        "NOCCode": "9461",
        "JobTitle": "blanching machine tender"
    },
    {
        "value": 23722,
        "NOCCode": "9461",
        "JobTitle": "bleacher operator – food and beverage processing"
    },
    {
        "value": 23723,
        "NOCCode": "9461",
        "JobTitle": "blender – tobacco processing"
    },
    {
        "value": 23724,
        "NOCCode": "9461",
        "JobTitle": "blender operator – food and beverage processing"
    },
    {
        "value": 23725,
        "NOCCode": "9461",
        "JobTitle": "blender, margarine"
    },
    {
        "value": 23726,
        "NOCCode": "9461",
        "JobTitle": "bologna maker"
    },
    {
        "value": 23727,
        "NOCCode": "9461",
        "JobTitle": "bolter operator – food and beverage processing"
    },
    {
        "value": 23728,
        "NOCCode": "9461",
        "JobTitle": "bone crusher – food and beverage processing"
    },
    {
        "value": 23729,
        "NOCCode": "9461",
        "JobTitle": "booker – tobacco processing"
    },
    {
        "value": 23730,
        "NOCCode": "9461",
        "JobTitle": "bottling machine operator"
    },
    {
        "value": 23731,
        "NOCCode": "9461",
        "JobTitle": "bottling machine operator – food and beverage processing"
    },
    {
        "value": 23732,
        "NOCCode": "9461",
        "JobTitle": "box machine operator – food and beverage processing"
    },
    {
        "value": 23733,
        "NOCCode": "9461",
        "JobTitle": "bread slicer operator"
    },
    {
        "value": 23734,
        "NOCCode": "9461",
        "JobTitle": "bread-slicing machine operator"
    },
    {
        "value": 23735,
        "NOCCode": "9461",
        "JobTitle": "breaker tank attendant – food and beverage processing"
    },
    {
        "value": 23736,
        "NOCCode": "9461",
        "JobTitle": "brewer"
    },
    {
        "value": 23737,
        "NOCCode": "9461",
        "JobTitle": "brewer helper – food and beverage processing"
    },
    {
        "value": 23738,
        "NOCCode": "9461",
        "JobTitle": "brewery kettle tender – food and beverage processing"
    },
    {
        "value": 23739,
        "NOCCode": "9461",
        "JobTitle": "brewery pumpman/woman"
    },
    {
        "value": 23740,
        "NOCCode": "9461",
        "JobTitle": "brewery stillman/woman – beverage processing"
    },
    {
        "value": 23741,
        "NOCCode": "9461",
        "JobTitle": "brewery worker"
    },
    {
        "value": 23742,
        "NOCCode": "9461",
        "JobTitle": "brewhouse operator"
    },
    {
        "value": 23743,
        "NOCCode": "9461",
        "JobTitle": "brine man/woman – food and beverage processing"
    },
    {
        "value": 23744,
        "NOCCode": "9461",
        "JobTitle": "brine mixer operator – food and beverage processing"
    },
    {
        "value": 23745,
        "NOCCode": "9461",
        "JobTitle": "brine tank separator tender"
    },
    {
        "value": 23746,
        "NOCCode": "9461",
        "JobTitle": "brown sugar maker"
    },
    {
        "value": 23747,
        "NOCCode": "9461",
        "JobTitle": "buhr mill operator – food and beverage processing"
    },
    {
        "value": 23748,
        "NOCCode": "9461",
        "JobTitle": "bulk blender – tobacco processing"
    },
    {
        "value": 23749,
        "NOCCode": "9461",
        "JobTitle": "bulker blender – tobacco processing"
    },
    {
        "value": 23750,
        "NOCCode": "9461",
        "JobTitle": "bulk-sausage-stuffing machine operator – food and beverage processing"
    },
    {
        "value": 23751,
        "NOCCode": "9461",
        "JobTitle": "buncher, cigar"
    },
    {
        "value": 23752,
        "NOCCode": "9461",
        "JobTitle": "butter maker"
    },
    {
        "value": 23753,
        "NOCCode": "9461",
        "JobTitle": "cake mix equipment operator – food and beverage processing"
    },
    {
        "value": 23754,
        "NOCCode": "9461",
        "JobTitle": "candied fruit processor"
    },
    {
        "value": 23755,
        "NOCCode": "9461",
        "JobTitle": "candy centre maker"
    },
    {
        "value": 23756,
        "NOCCode": "9461",
        "JobTitle": "candy maker"
    },
    {
        "value": 23757,
        "NOCCode": "9461",
        "JobTitle": "candy puller and roller"
    },
    {
        "value": 23758,
        "NOCCode": "9461",
        "JobTitle": "candy-cutting machine tender"
    },
    {
        "value": 23759,
        "NOCCode": "9461",
        "JobTitle": "candy-making machine tender"
    },
    {
        "value": 23760,
        "NOCCode": "9461",
        "JobTitle": "candy-rolling machine operator"
    },
    {
        "value": 23761,
        "NOCCode": "9461",
        "JobTitle": "candy-spinning machine operator – food and beverage processing"
    },
    {
        "value": 23762,
        "NOCCode": "9461",
        "JobTitle": "can-filling machine operator – food and beverage processing"
    },
    {
        "value": 23763,
        "NOCCode": "9461",
        "JobTitle": "canned goods cooker"
    },
    {
        "value": 23764,
        "NOCCode": "9461",
        "JobTitle": "canned milk sterilizer"
    },
    {
        "value": 23765,
        "NOCCode": "9461",
        "JobTitle": "canning machine operator"
    },
    {
        "value": 23766,
        "NOCCode": "9461",
        "JobTitle": "caramel equipment operator"
    },
    {
        "value": 23767,
        "NOCCode": "9461",
        "JobTitle": "carbonation equipment operator – food and beverage processing"
    },
    {
        "value": 23768,
        "NOCCode": "9461",
        "JobTitle": "carbonator – food and beverage processing"
    },
    {
        "value": 23769,
        "NOCCode": "9461",
        "JobTitle": "cascade blender – tobacco processing"
    },
    {
        "value": 23770,
        "NOCCode": "9461",
        "JobTitle": "casing machine operator – food and beverage processing"
    },
    {
        "value": 23771,
        "NOCCode": "9461",
        "JobTitle": "casing machine operator – tobacco processing"
    },
    {
        "value": 23772,
        "NOCCode": "9461",
        "JobTitle": "casing machine tender – tobacco processing"
    },
    {
        "value": 23773,
        "NOCCode": "9461",
        "JobTitle": "catsup maker"
    },
    {
        "value": 23774,
        "NOCCode": "9461",
        "JobTitle": "cellarman/woman, winery – food and beverage processing"
    },
    {
        "value": 23775,
        "NOCCode": "9461",
        "JobTitle": "centrifugal station tender – food and beverage processing"
    },
    {
        "value": 23776,
        "NOCCode": "9461",
        "JobTitle": "cereal baker – food and beverage processing"
    },
    {
        "value": 23777,
        "NOCCode": "9461",
        "JobTitle": "cereal cleaner"
    },
    {
        "value": 23778,
        "NOCCode": "9461",
        "JobTitle": "cereal cooker and extruder operator"
    },
    {
        "value": 23779,
        "NOCCode": "9461",
        "JobTitle": "cereal maker – food and beverage processing"
    },
    {
        "value": 23780,
        "NOCCode": "9461",
        "JobTitle": "cereal oven tender"
    },
    {
        "value": 23781,
        "NOCCode": "9461",
        "JobTitle": "cereal-cleaning equipment operator"
    },
    {
        "value": 23782,
        "NOCCode": "9461",
        "JobTitle": "cereal-coating operator"
    },
    {
        "value": 23783,
        "NOCCode": "9461",
        "JobTitle": "char filter tender – food and beverage processing"
    },
    {
        "value": 23784,
        "NOCCode": "9461",
        "JobTitle": "char kiln tender – food and beverage processing"
    },
    {
        "value": 23785,
        "NOCCode": "9461",
        "JobTitle": "cheese blender – food and beverage processing"
    },
    {
        "value": 23786,
        "NOCCode": "9461",
        "JobTitle": "cheese cooker"
    },
    {
        "value": 23787,
        "NOCCode": "9461",
        "JobTitle": "cheese grater"
    },
    {
        "value": 23788,
        "NOCCode": "9461",
        "JobTitle": "cheese maker"
    },
    {
        "value": 23789,
        "NOCCode": "9461",
        "JobTitle": "chewing-gum machine tender"
    },
    {
        "value": 23790,
        "NOCCode": "9461",
        "JobTitle": "chicle centrifuge operator – food and beverage processing"
    },
    {
        "value": 23791,
        "NOCCode": "9461",
        "JobTitle": "chocolate chips maker"
    },
    {
        "value": 23792,
        "NOCCode": "9461",
        "JobTitle": "chocolate coater – food and beverage processing"
    },
    {
        "value": 23793,
        "NOCCode": "9461",
        "JobTitle": "chocolate crumbs maker"
    },
    {
        "value": 23794,
        "NOCCode": "9461",
        "JobTitle": "chocolate moulder, machine"
    },
    {
        "value": 23795,
        "NOCCode": "9461",
        "JobTitle": "chocolate refiner"
    },
    {
        "value": 23796,
        "NOCCode": "9461",
        "JobTitle": "chocolate temperer"
    },
    {
        "value": 23797,
        "NOCCode": "9461",
        "JobTitle": "chub sausage machine tender"
    },
    {
        "value": 23798,
        "NOCCode": "9461",
        "JobTitle": "cider maker"
    },
    {
        "value": 23799,
        "NOCCode": "9461",
        "JobTitle": "cider plant worker"
    },
    {
        "value": 23800,
        "NOCCode": "9461",
        "JobTitle": "cigar bunch maker"
    },
    {
        "value": 23801,
        "NOCCode": "9461",
        "JobTitle": "cigar buncher"
    },
    {
        "value": 23802,
        "NOCCode": "9461",
        "JobTitle": "cigar dryer"
    },
    {
        "value": 23803,
        "NOCCode": "9461",
        "JobTitle": "cigar filler, mixer and shredder"
    },
    {
        "value": 23804,
        "NOCCode": "9461",
        "JobTitle": "cigar maker"
    },
    {
        "value": 23805,
        "NOCCode": "9461",
        "JobTitle": "cigar maker, hand"
    },
    {
        "value": 23806,
        "NOCCode": "9461",
        "JobTitle": "cigar maker, machine"
    },
    {
        "value": 23807,
        "NOCCode": "9461",
        "JobTitle": "cigar moulder, hand"
    },
    {
        "value": 23808,
        "NOCCode": "9461",
        "JobTitle": "cigar patcher"
    },
    {
        "value": 23809,
        "NOCCode": "9461",
        "JobTitle": "cigar roller, hand"
    },
    {
        "value": 23810,
        "NOCCode": "9461",
        "JobTitle": "cigarette filter machine tender"
    },
    {
        "value": 23811,
        "NOCCode": "9461",
        "JobTitle": "cigarette machine tender"
    },
    {
        "value": 23812,
        "NOCCode": "9461",
        "JobTitle": "cigarette maker"
    },
    {
        "value": 23813,
        "NOCCode": "9461",
        "JobTitle": "cigarette maker operator"
    },
    {
        "value": 23814,
        "NOCCode": "9461",
        "JobTitle": "cigarette roller"
    },
    {
        "value": 23815,
        "NOCCode": "9461",
        "JobTitle": "cigarette stamper"
    },
    {
        "value": 23816,
        "NOCCode": "9461",
        "JobTitle": "cigarette tipper"
    },
    {
        "value": 23817,
        "NOCCode": "9461",
        "JobTitle": "citrus juice extractor"
    },
    {
        "value": 23818,
        "NOCCode": "9461",
        "JobTitle": "clarifying equipment tender – food and beverage processing"
    },
    {
        "value": 23819,
        "NOCCode": "9461",
        "JobTitle": "cleaner-dryer, grain – food and beverage processing"
    },
    {
        "value": 23820,
        "NOCCode": "9461",
        "JobTitle": "cleaning and classifying operator – tobacco processing"
    },
    {
        "value": 23821,
        "NOCCode": "9461",
        "JobTitle": "coating pan operator – food and beverage processing"
    },
    {
        "value": 23822,
        "NOCCode": "9461",
        "JobTitle": "cocoa bean roaster"
    },
    {
        "value": 23823,
        "NOCCode": "9461",
        "JobTitle": "cocoa bean-shelling machine tender"
    },
    {
        "value": 23824,
        "NOCCode": "9461",
        "JobTitle": "cocoa butter filterer"
    },
    {
        "value": 23825,
        "NOCCode": "9461",
        "JobTitle": "cocoa mill operator"
    },
    {
        "value": 23826,
        "NOCCode": "9461",
        "JobTitle": "cocoa-nib grinder"
    },
    {
        "value": 23827,
        "NOCCode": "9461",
        "JobTitle": "coffee blender"
    },
    {
        "value": 23828,
        "NOCCode": "9461",
        "JobTitle": "coffee grinder"
    },
    {
        "value": 23829,
        "NOCCode": "9461",
        "JobTitle": "coffee roaster"
    },
    {
        "value": 23830,
        "NOCCode": "9461",
        "JobTitle": "conche machine tender"
    },
    {
        "value": 23831,
        "NOCCode": "9461",
        "JobTitle": "cone and wafer machine tender"
    },
    {
        "value": 23832,
        "NOCCode": "9461",
        "JobTitle": "cone maker – food and beverage processing"
    },
    {
        "value": 23833,
        "NOCCode": "9461",
        "JobTitle": "confection syrup maker"
    },
    {
        "value": 23834,
        "NOCCode": "9461",
        "JobTitle": "confectionery cooker"
    },
    {
        "value": 23835,
        "NOCCode": "9461",
        "JobTitle": "console operator – food and beverage processing"
    },
    {
        "value": 23836,
        "NOCCode": "9461",
        "JobTitle": "control room operator – food and beverage processing"
    },
    {
        "value": 23837,
        "NOCCode": "9461",
        "JobTitle": "conveyor oven operator – food and beverage processing"
    },
    {
        "value": 23838,
        "NOCCode": "9461",
        "JobTitle": "conveyor tender – tobacco processing"
    },
    {
        "value": 23839,
        "NOCCode": "9461",
        "JobTitle": "cook tank operator – food and beverage processing"
    },
    {
        "value": 23840,
        "NOCCode": "9461",
        "JobTitle": "cooker, canned goods"
    },
    {
        "value": 23841,
        "NOCCode": "9461",
        "JobTitle": "cooker, meats – food and beverage processing"
    },
    {
        "value": 23842,
        "NOCCode": "9461",
        "JobTitle": "cooker, offal – food and beverage processing"
    },
    {
        "value": 23843,
        "NOCCode": "9461",
        "JobTitle": "cooker, process cheese"
    },
    {
        "value": 23844,
        "NOCCode": "9461",
        "JobTitle": "cooler tender – tobacco processing"
    },
    {
        "value": 23845,
        "NOCCode": "9461",
        "JobTitle": "cooling machine tender – food and beverage processing"
    },
    {
        "value": 23846,
        "NOCCode": "9461",
        "JobTitle": "cork tipper, cigarettes"
    },
    {
        "value": 23847,
        "NOCCode": "9461",
        "JobTitle": "corn chips cooker"
    },
    {
        "value": 23848,
        "NOCCode": "9461",
        "JobTitle": "corn cooker – food and beverage processing"
    },
    {
        "value": 23849,
        "NOCCode": "9461",
        "JobTitle": "corn dough machine tender"
    },
    {
        "value": 23850,
        "NOCCode": "9461",
        "JobTitle": "corn flakes roller – food and beverage processing"
    },
    {
        "value": 23851,
        "NOCCode": "9461",
        "JobTitle": "corn germ preparer – food and beverage processing"
    },
    {
        "value": 23852,
        "NOCCode": "9461",
        "JobTitle": "corn products presser – food and beverage processing"
    },
    {
        "value": 23853,
        "NOCCode": "9461",
        "JobTitle": "corn products pressman/woman – food and beverage processing"
    },
    {
        "value": 23854,
        "NOCCode": "9461",
        "JobTitle": "corn products starch presser"
    },
    {
        "value": 23855,
        "NOCCode": "9461",
        "JobTitle": "corn products sugar crystallizer operator"
    },
    {
        "value": 23856,
        "NOCCode": "9461",
        "JobTitle": "corn sugar filter operator"
    },
    {
        "value": 23857,
        "NOCCode": "9461",
        "JobTitle": "corn sugar refinery operator"
    },
    {
        "value": 23858,
        "NOCCode": "9461",
        "JobTitle": "corn syrup cooler and decolourizer"
    },
    {
        "value": 23859,
        "NOCCode": "9461",
        "JobTitle": "corn syrup maker"
    },
    {
        "value": 23860,
        "NOCCode": "9461",
        "JobTitle": "cottage cheese maker"
    },
    {
        "value": 23861,
        "NOCCode": "9461",
        "JobTitle": "cracker and cookie machine operator"
    },
    {
        "value": 23862,
        "NOCCode": "9461",
        "JobTitle": "cream cheese maker"
    },
    {
        "value": 23863,
        "NOCCode": "9461",
        "JobTitle": "crusher operator – tobacco processing"
    },
    {
        "value": 23864,
        "NOCCode": "9461",
        "JobTitle": "crushing machine tender – food and beverage processing"
    },
    {
        "value": 23865,
        "NOCCode": "9461",
        "JobTitle": "crystallizer tender – food and beverage processing"
    },
    {
        "value": 23866,
        "NOCCode": "9461",
        "JobTitle": "cube sugar machine tender – food and beverage processing"
    },
    {
        "value": 23867,
        "NOCCode": "9461",
        "JobTitle": "curing bin tender – food and beverage processing"
    },
    {
        "value": 23868,
        "NOCCode": "9461",
        "JobTitle": "custom feed miller – food and beverage processing"
    },
    {
        "value": 23869,
        "NOCCode": "9461",
        "JobTitle": "custom feed preparer – food and beverage processing"
    },
    {
        "value": 23870,
        "NOCCode": "9461",
        "JobTitle": "cutter, tobacco leaf"
    },
    {
        "value": 23871,
        "NOCCode": "9461",
        "JobTitle": "dairy plant machine operator"
    },
    {
        "value": 23872,
        "NOCCode": "9461",
        "JobTitle": "dairy products specialty maker"
    },
    {
        "value": 23873,
        "NOCCode": "9461",
        "JobTitle": "dehydrator, grain – food and beverage processing"
    },
    {
        "value": 23874,
        "NOCCode": "9461",
        "JobTitle": "dextrine maker – food and beverage processing"
    },
    {
        "value": 23875,
        "NOCCode": "9461",
        "JobTitle": "diffuser operator – food and beverage processing"
    },
    {
        "value": 23876,
        "NOCCode": "9461",
        "JobTitle": "distilled liquors rectifier – food and beverage processing"
    },
    {
        "value": 23877,
        "NOCCode": "9461",
        "JobTitle": "distilled liquors stillman/woman – food and beverage processing"
    },
    {
        "value": 23878,
        "NOCCode": "9461",
        "JobTitle": "distillery cooker – food and beverage processing"
    },
    {
        "value": 23879,
        "NOCCode": "9461",
        "JobTitle": "distillery grinder operator"
    },
    {
        "value": 23880,
        "NOCCode": "9461",
        "JobTitle": "distillery press operator"
    },
    {
        "value": 23881,
        "NOCCode": "9461",
        "JobTitle": "distillery presser"
    },
    {
        "value": 23882,
        "NOCCode": "9461",
        "JobTitle": "distillery skimmer"
    },
    {
        "value": 23883,
        "NOCCode": "9461",
        "JobTitle": "distillery yeast maker – food and beverage processing"
    },
    {
        "value": 23884,
        "NOCCode": "9461",
        "JobTitle": "dividing machine tender – food and beverage processing"
    },
    {
        "value": 23885,
        "NOCCode": "9461",
        "JobTitle": "dough maker – food and beverage processing"
    },
    {
        "value": 23886,
        "NOCCode": "9461",
        "JobTitle": "dough-kneading machine tender"
    },
    {
        "value": 23887,
        "NOCCode": "9461",
        "JobTitle": "dressing and sauce mixer"
    },
    {
        "value": 23888,
        "NOCCode": "9461",
        "JobTitle": "dressing mixer operator – food and beverage processing"
    },
    {
        "value": 23889,
        "NOCCode": "9461",
        "JobTitle": "dry starch maker"
    },
    {
        "value": 23890,
        "NOCCode": "9461",
        "JobTitle": "dry starch maker helper"
    },
    {
        "value": 23891,
        "NOCCode": "9461",
        "JobTitle": "dry starch operator – food and beverage processing"
    },
    {
        "value": 23892,
        "NOCCode": "9461",
        "JobTitle": "dry starch worker – food and beverage processing"
    },
    {
        "value": 23893,
        "NOCCode": "9461",
        "JobTitle": "dryer and cooler operator – tobacco processing"
    },
    {
        "value": 23894,
        "NOCCode": "9461",
        "JobTitle": "dryer operator – food processing"
    },
    {
        "value": 23895,
        "NOCCode": "9461",
        "JobTitle": "dryer tender – tobacco processing"
    },
    {
        "value": 23896,
        "NOCCode": "9461",
        "JobTitle": "drying drum tender – food and beverage processing"
    },
    {
        "value": 23897,
        "NOCCode": "9461",
        "JobTitle": "drying machine operator – tobacco processing"
    },
    {
        "value": 23898,
        "NOCCode": "9461",
        "JobTitle": "edible oil pumper – food and beverage processing"
    },
    {
        "value": 23899,
        "NOCCode": "9461",
        "JobTitle": "edible oil refiner – food and beverage processing"
    },
    {
        "value": 23900,
        "NOCCode": "9461",
        "JobTitle": "enrobing machine operator – food and beverage processing"
    },
    {
        "value": 23901,
        "NOCCode": "9461",
        "JobTitle": "evaporator operator – food processing"
    },
    {
        "value": 23902,
        "NOCCode": "9461",
        "JobTitle": "extract maker – food and beverage processing"
    },
    {
        "value": 23903,
        "NOCCode": "9461",
        "JobTitle": "feed batch mixer – food and beverage processing"
    },
    {
        "value": 23904,
        "NOCCode": "9461",
        "JobTitle": "feed mill production worker"
    },
    {
        "value": 23905,
        "NOCCode": "9461",
        "JobTitle": "feed miller – food and beverage processing"
    },
    {
        "value": 23906,
        "NOCCode": "9461",
        "JobTitle": "feed mixer – food and beverage processing"
    },
    {
        "value": 23907,
        "NOCCode": "9461",
        "JobTitle": "fermentation process operator – food and beverage processing"
    },
    {
        "value": 23908,
        "NOCCode": "9461",
        "JobTitle": "fermenter – tobacco processing"
    },
    {
        "value": 23909,
        "NOCCode": "9461",
        "JobTitle": "fermenter operator"
    },
    {
        "value": 23910,
        "NOCCode": "9461",
        "JobTitle": "filling machine setter – food and beverage processing"
    },
    {
        "value": 23911,
        "NOCCode": "9461",
        "JobTitle": "filling machine set-up man/woman – food and beverage processing"
    },
    {
        "value": 23912,
        "NOCCode": "9461",
        "JobTitle": "filter cigarette machine tender"
    },
    {
        "value": 23913,
        "NOCCode": "9461",
        "JobTitle": "filter rod machine operator – tobacco products"
    },
    {
        "value": 23914,
        "NOCCode": "9461",
        "JobTitle": "flaking roll tender – food and beverage processing"
    },
    {
        "value": 23915,
        "NOCCode": "9461",
        "JobTitle": "flavour maker – food and beverage processing"
    },
    {
        "value": 23916,
        "NOCCode": "9461",
        "JobTitle": "flavour maker – tobacco processing"
    },
    {
        "value": 23917,
        "NOCCode": "9461",
        "JobTitle": "flavourer – tobacco processing"
    },
    {
        "value": 23918,
        "NOCCode": "9461",
        "JobTitle": "flavouring mixer – tobacco processing"
    },
    {
        "value": 23919,
        "NOCCode": "9461",
        "JobTitle": "flour blender operator – food and beverage processing"
    },
    {
        "value": 23920,
        "NOCCode": "9461",
        "JobTitle": "flour mill machine operator"
    },
    {
        "value": 23921,
        "NOCCode": "9461",
        "JobTitle": "flour miller"
    },
    {
        "value": 23922,
        "NOCCode": "9461",
        "JobTitle": "flour purifier"
    },
    {
        "value": 23923,
        "NOCCode": "9461",
        "JobTitle": "flumer – food and beverage processing"
    },
    {
        "value": 23924,
        "NOCCode": "9461",
        "JobTitle": "food slicer – food and beverage processing"
    },
    {
        "value": 23925,
        "NOCCode": "9461",
        "JobTitle": "food-colouring equipment operator"
    },
    {
        "value": 23926,
        "NOCCode": "9461",
        "JobTitle": "food-flavouring equipment tender"
    },
    {
        "value": 23927,
        "NOCCode": "9461",
        "JobTitle": "food-mixing machine tender"
    },
    {
        "value": 23928,
        "NOCCode": "9461",
        "JobTitle": "freeze-dry food processor"
    },
    {
        "value": 23929,
        "NOCCode": "9461",
        "JobTitle": "freezer operator – food and beverage processing"
    },
    {
        "value": 23930,
        "NOCCode": "9461",
        "JobTitle": "frosting maker – food and beverage processing"
    },
    {
        "value": 23931,
        "NOCCode": "9461",
        "JobTitle": "frosting mixer – food and beverage processing"
    },
    {
        "value": 23932,
        "NOCCode": "9461",
        "JobTitle": "frozen stick novelty maker – food and beverage processing"
    },
    {
        "value": 23933,
        "NOCCode": "9461",
        "JobTitle": "frozen-dough-baking machine tender"
    },
    {
        "value": 23934,
        "NOCCode": "9461",
        "JobTitle": "fruit and vegetable machine operator"
    },
    {
        "value": 23935,
        "NOCCode": "9461",
        "JobTitle": "fruit cooker – food and beverage processing"
    },
    {
        "value": 23936,
        "NOCCode": "9461",
        "JobTitle": "fruit mixer – food and beverage processing"
    },
    {
        "value": 23937,
        "NOCCode": "9461",
        "JobTitle": "fruit preserver – food and beverage processing"
    },
    {
        "value": 23938,
        "NOCCode": "9461",
        "JobTitle": "fruit press tender – food and beverage processing"
    },
    {
        "value": 23939,
        "NOCCode": "9461",
        "JobTitle": "fruit presser"
    },
    {
        "value": 23940,
        "NOCCode": "9461",
        "JobTitle": "fry cook – food and beverage processing"
    },
    {
        "value": 23941,
        "NOCCode": "9461",
        "JobTitle": "fryer operator – food and beverage processing"
    },
    {
        "value": 23942,
        "NOCCode": "9461",
        "JobTitle": "gelatin evaporator filter operator"
    },
    {
        "value": 23943,
        "NOCCode": "9461",
        "JobTitle": "gelatin extractor tender"
    },
    {
        "value": 23944,
        "NOCCode": "9461",
        "JobTitle": "gelatin powder mixer"
    },
    {
        "value": 23945,
        "NOCCode": "9461",
        "JobTitle": "gelatin stock maker"
    },
    {
        "value": 23946,
        "NOCCode": "9461",
        "JobTitle": "gelatin-blending machine tender"
    },
    {
        "value": 23947,
        "NOCCode": "9461",
        "JobTitle": "germ separator – food and beverage processing"
    },
    {
        "value": 23948,
        "NOCCode": "9461",
        "JobTitle": "germ separator operator – food and beverage processing"
    },
    {
        "value": 23949,
        "NOCCode": "9461",
        "JobTitle": "grain cleaner-dryer – food and beverage processing"
    },
    {
        "value": 23950,
        "NOCCode": "9461",
        "JobTitle": "grain cooker – food and beverage processing"
    },
    {
        "value": 23951,
        "NOCCode": "9461",
        "JobTitle": "grain meal processor – food and beverage processing"
    },
    {
        "value": 23952,
        "NOCCode": "9461",
        "JobTitle": "grain miller"
    },
    {
        "value": 23953,
        "NOCCode": "9461",
        "JobTitle": "grain mixer – food and beverage processing"
    },
    {
        "value": 23954,
        "NOCCode": "9461",
        "JobTitle": "grain roaster"
    },
    {
        "value": 23955,
        "NOCCode": "9461",
        "JobTitle": "grain sample miller"
    },
    {
        "value": 23956,
        "NOCCode": "9461",
        "JobTitle": "grain-cleaning machine operator – food and beverage processing"
    },
    {
        "value": 23957,
        "NOCCode": "9461",
        "JobTitle": "grain-processing machine operator"
    },
    {
        "value": 23958,
        "NOCCode": "9461",
        "JobTitle": "granulating machine tender – tobacco processing"
    },
    {
        "value": 23959,
        "NOCCode": "9461",
        "JobTitle": "granulator – tobacco processing"
    },
    {
        "value": 23960,
        "NOCCode": "9461",
        "JobTitle": "granulator tender – food and beverage processing"
    },
    {
        "value": 23961,
        "NOCCode": "9461",
        "JobTitle": "grated cheese maker"
    },
    {
        "value": 23962,
        "NOCCode": "9461",
        "JobTitle": "grinder, tobacco leaf"
    },
    {
        "value": 23963,
        "NOCCode": "9461",
        "JobTitle": "grist mill operator – food and beverage processing"
    },
    {
        "value": 23964,
        "NOCCode": "9461",
        "JobTitle": "gum-sheeting and scoring machine operator – food and beverage processing"
    },
    {
        "value": 23965,
        "NOCCode": "9461",
        "JobTitle": "ham curer"
    },
    {
        "value": 23966,
        "NOCCode": "9461",
        "JobTitle": "hammer mill tender – food and beverage processing"
    },
    {
        "value": 23967,
        "NOCCode": "9461",
        "JobTitle": "heat exchanger tender, Votator – food and beverage processing"
    },
    {
        "value": 23968,
        "NOCCode": "9461",
        "JobTitle": "high-speed machine operator – tobacco products"
    },
    {
        "value": 23969,
        "NOCCode": "9461",
        "JobTitle": "high-speed packer operator – tobacco products"
    },
    {
        "value": 23970,
        "NOCCode": "9461",
        "JobTitle": "homogenizer operator – food and beverage processing"
    },
    {
        "value": 23971,
        "NOCCode": "9461",
        "JobTitle": "honey-processing equipment operator"
    },
    {
        "value": 23972,
        "NOCCode": "9461",
        "JobTitle": "hydrogenation operator – food and beverage processing"
    },
    {
        "value": 23973,
        "NOCCode": "9461",
        "JobTitle": "ice cream decorator"
    },
    {
        "value": 23974,
        "NOCCode": "9461",
        "JobTitle": "ice cream maker"
    },
    {
        "value": 23975,
        "NOCCode": "9461",
        "JobTitle": "ice cream novelty maker"
    },
    {
        "value": 23976,
        "NOCCode": "9461",
        "JobTitle": "icing machine operator – food and beverage processing"
    },
    {
        "value": 23977,
        "NOCCode": "9461",
        "JobTitle": "icing maker – food and beverage processing"
    },
    {
        "value": 23978,
        "NOCCode": "9461",
        "JobTitle": "icing mixer – food and beverage processing"
    },
    {
        "value": 23979,
        "NOCCode": "9461",
        "JobTitle": "icing sugar mill tender"
    },
    {
        "value": 23980,
        "NOCCode": "9461",
        "JobTitle": "ingredients scaler and dispenser – food and beverage processing"
    },
    {
        "value": 23981,
        "NOCCode": "9461",
        "JobTitle": "instant coffee dryer"
    },
    {
        "value": 23982,
        "NOCCode": "9461",
        "JobTitle": "instant coffee extractor operator"
    },
    {
        "value": 23983,
        "NOCCode": "9461",
        "JobTitle": "instant powdered foods equipment operator"
    },
    {
        "value": 23984,
        "NOCCode": "9461",
        "JobTitle": "jam processor operator"
    },
    {
        "value": 23985,
        "NOCCode": "9461",
        "JobTitle": "ketchup maker"
    },
    {
        "value": 23986,
        "NOCCode": "9461",
        "JobTitle": "kettleman/woman – food processing"
    },
    {
        "value": 23987,
        "NOCCode": "9461",
        "JobTitle": "kiln operator, malt house – food and beverage processing"
    },
    {
        "value": 23988,
        "NOCCode": "9461",
        "JobTitle": "lard renderer – food and beverage processing"
    },
    {
        "value": 23989,
        "NOCCode": "9461",
        "JobTitle": "linseed oil extractor"
    },
    {
        "value": 23990,
        "NOCCode": "9461",
        "JobTitle": "liquid sugar operator"
    },
    {
        "value": 23991,
        "NOCCode": "9461",
        "JobTitle": "liquor blender – food and beverage processing"
    },
    {
        "value": 23992,
        "NOCCode": "9461",
        "JobTitle": "liquor gallery tender – food and beverage processing"
    },
    {
        "value": 23993,
        "NOCCode": "9461",
        "JobTitle": "locker plant attendant – food and beverage processing"
    },
    {
        "value": 23994,
        "NOCCode": "9461",
        "JobTitle": "lollipop maker"
    },
    {
        "value": 23995,
        "NOCCode": "9461",
        "JobTitle": "lump maker – tobacco processing"
    },
    {
        "value": 23996,
        "NOCCode": "9461",
        "JobTitle": "lump roller – tobacco processing"
    },
    {
        "value": 23997,
        "NOCCode": "9461",
        "JobTitle": "lye peel tender – food and beverage processing"
    },
    {
        "value": 23998,
        "NOCCode": "9461",
        "JobTitle": "macaroni press operator"
    },
    {
        "value": 23999,
        "NOCCode": "9461",
        "JobTitle": "machine operator – food and beverage processing"
    },
    {
        "value": 24000,
        "NOCCode": "9461",
        "JobTitle": "machine operator – tobacco processing"
    },
    {
        "value": 24001,
        "NOCCode": "9461",
        "JobTitle": "machine plug moulder – tobacco processing"
    },
    {
        "value": 24002,
        "NOCCode": "9461",
        "JobTitle": "machine stripper – tobacco processing"
    },
    {
        "value": 24003,
        "NOCCode": "9461",
        "JobTitle": "malt dryer tender – food and beverage processing"
    },
    {
        "value": 24004,
        "NOCCode": "9461",
        "JobTitle": "malt roaster – food and beverage processing"
    },
    {
        "value": 24005,
        "NOCCode": "9461",
        "JobTitle": "malthouse operator – food and beverage processing"
    },
    {
        "value": 24006,
        "NOCCode": "9461",
        "JobTitle": "maltster – food and beverage processing"
    },
    {
        "value": 24007,
        "NOCCode": "9461",
        "JobTitle": "maraschino cherry processor – food and beverage processing"
    },
    {
        "value": 24008,
        "NOCCode": "9461",
        "JobTitle": "margarine blender"
    },
    {
        "value": 24009,
        "NOCCode": "9461",
        "JobTitle": "margarine clarifier – food and beverage processing"
    },
    {
        "value": 24010,
        "NOCCode": "9461",
        "JobTitle": "margarine processor"
    },
    {
        "value": 24011,
        "NOCCode": "9461",
        "JobTitle": "marshmallow maker"
    },
    {
        "value": 24012,
        "NOCCode": "9461",
        "JobTitle": "marzipan maker"
    },
    {
        "value": 24013,
        "NOCCode": "9461",
        "JobTitle": "mash dryer – food and beverage processing"
    },
    {
        "value": 24014,
        "NOCCode": "9461",
        "JobTitle": "mash operator – food and beverage processing"
    },
    {
        "value": 24015,
        "NOCCode": "9461",
        "JobTitle": "meat canner"
    },
    {
        "value": 24016,
        "NOCCode": "9461",
        "JobTitle": "meat chopper – food and beverage processing"
    },
    {
        "value": 24017,
        "NOCCode": "9461",
        "JobTitle": "meat cook – food and beverage processing"
    },
    {
        "value": 24018,
        "NOCCode": "9461",
        "JobTitle": "meat curer"
    },
    {
        "value": 24019,
        "NOCCode": "9461",
        "JobTitle": "meat grinder – food and beverage processing"
    },
    {
        "value": 24020,
        "NOCCode": "9461",
        "JobTitle": "meat mincer – food and beverage processing"
    },
    {
        "value": 24021,
        "NOCCode": "9461",
        "JobTitle": "meat mincer – packaging"
    },
    {
        "value": 24022,
        "NOCCode": "9461",
        "JobTitle": "meat mixer"
    },
    {
        "value": 24023,
        "NOCCode": "9461",
        "JobTitle": "meat moulder – food and beverage processing"
    },
    {
        "value": 24024,
        "NOCCode": "9461",
        "JobTitle": "meat pickler"
    },
    {
        "value": 24025,
        "NOCCode": "9461",
        "JobTitle": "meat salter"
    },
    {
        "value": 24026,
        "NOCCode": "9461",
        "JobTitle": "melter tender – food and beverage processing"
    },
    {
        "value": 24027,
        "NOCCode": "9461",
        "JobTitle": "merchant miller – food and beverage processing"
    },
    {
        "value": 24028,
        "NOCCode": "9461",
        "JobTitle": "milk dryer – food and beverage processing"
    },
    {
        "value": 24029,
        "NOCCode": "9461",
        "JobTitle": "milk pasteurizer – food and beverage processing"
    },
    {
        "value": 24030,
        "NOCCode": "9461",
        "JobTitle": "milk-processing equipment operator – food and beverage processing"
    },
    {
        "value": 24031,
        "NOCCode": "9461",
        "JobTitle": "miller – food and beverage processing"
    },
    {
        "value": 24032,
        "NOCCode": "9461",
        "JobTitle": "mincemeat maker"
    },
    {
        "value": 24033,
        "NOCCode": "9461",
        "JobTitle": "mingler tender"
    },
    {
        "value": 24034,
        "NOCCode": "9461",
        "JobTitle": "mixer – tobacco processing"
    },
    {
        "value": 24035,
        "NOCCode": "9461",
        "JobTitle": "mixer operator, dry foods"
    },
    {
        "value": 24036,
        "NOCCode": "9461",
        "JobTitle": "module tender – tobacco processing"
    },
    {
        "value": 24037,
        "NOCCode": "9461",
        "JobTitle": "molasses mixer – food and beverage processing"
    },
    {
        "value": 24038,
        "NOCCode": "9461",
        "JobTitle": "molasses wort processor"
    },
    {
        "value": 24039,
        "NOCCode": "9461",
        "JobTitle": "moulding machine operator – food and beverage processing"
    },
    {
        "value": 24040,
        "NOCCode": "9461",
        "JobTitle": "nut mixer operator"
    },
    {
        "value": 24041,
        "NOCCode": "9461",
        "JobTitle": "nut-roasting equipment operator"
    },
    {
        "value": 24042,
        "NOCCode": "9461",
        "JobTitle": "oats miller"
    },
    {
        "value": 24043,
        "NOCCode": "9461",
        "JobTitle": "oil clarifier tender – food and beverage processing"
    },
    {
        "value": 24044,
        "NOCCode": "9461",
        "JobTitle": "operator, dryer and cooler – tobacco processing"
    },
    {
        "value": 24045,
        "NOCCode": "9461",
        "JobTitle": "packaging machine adjuster"
    },
    {
        "value": 24046,
        "NOCCode": "9461",
        "JobTitle": "packaging machine operator – food processing"
    },
    {
        "value": 24047,
        "NOCCode": "9461",
        "JobTitle": "packer operator – tobacco products"
    },
    {
        "value": 24048,
        "NOCCode": "9461",
        "JobTitle": "packing machine set-up operator"
    },
    {
        "value": 24049,
        "NOCCode": "9461",
        "JobTitle": "pantry worker – food and beverage processing"
    },
    {
        "value": 24050,
        "NOCCode": "9461",
        "JobTitle": "pasta maker"
    },
    {
        "value": 24051,
        "NOCCode": "9461",
        "JobTitle": "pasteurizer operator"
    },
    {
        "value": 24052,
        "NOCCode": "9461",
        "JobTitle": "pasteurizer operator – food and beverage processing"
    },
    {
        "value": 24053,
        "NOCCode": "9461",
        "JobTitle": "peanut butter maker"
    },
    {
        "value": 24054,
        "NOCCode": "9461",
        "JobTitle": "peanut-blanching machine tender – food and beverage processing"
    },
    {
        "value": 24055,
        "NOCCode": "9461",
        "JobTitle": "pet food cook – food and beverage processing"
    },
    {
        "value": 24056,
        "NOCCode": "9461",
        "JobTitle": "pickleman/woman – food and beverage processing"
    },
    {
        "value": 24057,
        "NOCCode": "9461",
        "JobTitle": "pickler – food and beverage processing"
    },
    {
        "value": 24058,
        "NOCCode": "9461",
        "JobTitle": "pickler, meat"
    },
    {
        "value": 24059,
        "NOCCode": "9461",
        "JobTitle": "pickling solution injector – food and beverage processing"
    },
    {
        "value": 24060,
        "NOCCode": "9461",
        "JobTitle": "pie dough roller"
    },
    {
        "value": 24061,
        "NOCCode": "9461",
        "JobTitle": "pie filling cooker"
    },
    {
        "value": 24062,
        "NOCCode": "9461",
        "JobTitle": "pie filling mixer"
    },
    {
        "value": 24063,
        "NOCCode": "9461",
        "JobTitle": "pie maker, machine"
    },
    {
        "value": 24064,
        "NOCCode": "9461",
        "JobTitle": "pie-making machine operator"
    },
    {
        "value": 24065,
        "NOCCode": "9461",
        "JobTitle": "plug maker – tobacco processing"
    },
    {
        "value": 24066,
        "NOCCode": "9461",
        "JobTitle": "plug moulder, machine – tobacco processing"
    },
    {
        "value": 24067,
        "NOCCode": "9461",
        "JobTitle": "plug-cutting machine tender – tobacco processing"
    },
    {
        "value": 24068,
        "NOCCode": "9461",
        "JobTitle": "popcorn candy maker"
    },
    {
        "value": 24069,
        "NOCCode": "9461",
        "JobTitle": "potato chip machine operator"
    },
    {
        "value": 24070,
        "NOCCode": "9461",
        "JobTitle": "pouch line operator – food processing"
    },
    {
        "value": 24071,
        "NOCCode": "9461",
        "JobTitle": "powdered milk dryer operator"
    },
    {
        "value": 24072,
        "NOCCode": "9461",
        "JobTitle": "precooked sausage maker"
    },
    {
        "value": 24073,
        "NOCCode": "9461",
        "JobTitle": "press operator – food and beverage processing"
    },
    {
        "value": 24074,
        "NOCCode": "9461",
        "JobTitle": "press operator – tobacco processing"
    },
    {
        "value": 24075,
        "NOCCode": "9461",
        "JobTitle": "presser – food and beverage processing"
    },
    {
        "value": 24076,
        "NOCCode": "9461",
        "JobTitle": "pressure cooker operator – food and beverage processing"
    },
    {
        "value": 24077,
        "NOCCode": "9461",
        "JobTitle": "pressurized container filler"
    },
    {
        "value": 24078,
        "NOCCode": "9461",
        "JobTitle": "pretzel baker – food and beverage processing"
    },
    {
        "value": 24079,
        "NOCCode": "9461",
        "JobTitle": "process cheese cooker"
    },
    {
        "value": 24080,
        "NOCCode": "9461",
        "JobTitle": "process cheese formulator"
    },
    {
        "value": 24081,
        "NOCCode": "9461",
        "JobTitle": "process control operator – food and beverage processing"
    },
    {
        "value": 24082,
        "NOCCode": "9461",
        "JobTitle": "process operator – food and beverage processing"
    },
    {
        "value": 24083,
        "NOCCode": "9461",
        "JobTitle": "puff gun operator – food and beverage processing"
    },
    {
        "value": 24084,
        "NOCCode": "9461",
        "JobTitle": "puffed cereal maker"
    },
    {
        "value": 24085,
        "NOCCode": "9461",
        "JobTitle": "pulper tender – food and beverage processing"
    },
    {
        "value": 24086,
        "NOCCode": "9461",
        "JobTitle": "purifier operator – food and beverage processing"
    },
    {
        "value": 24087,
        "NOCCode": "9461",
        "JobTitle": "rectifier, distilled liquors – food and beverage processing"
    },
    {
        "value": 24088,
        "NOCCode": "9461",
        "JobTitle": "redryer tender – tobacco processing"
    },
    {
        "value": 24089,
        "NOCCode": "9461",
        "JobTitle": "redrying machine operator – tobacco processing"
    },
    {
        "value": 24090,
        "NOCCode": "9461",
        "JobTitle": "reel oven operator – food and beverage processing"
    },
    {
        "value": 24091,
        "NOCCode": "9461",
        "JobTitle": "relish maker – food and beverage processing"
    },
    {
        "value": 24092,
        "NOCCode": "9461",
        "JobTitle": "retort battery cook – food and beverage processing"
    },
    {
        "value": 24093,
        "NOCCode": "9461",
        "JobTitle": "retort tender – food and beverage processing"
    },
    {
        "value": 24094,
        "NOCCode": "9461",
        "JobTitle": "rice mill worker"
    },
    {
        "value": 24095,
        "NOCCode": "9461",
        "JobTitle": "rice miller"
    },
    {
        "value": 24096,
        "NOCCode": "9461",
        "JobTitle": "roller mill tender – food and beverage processing"
    },
    {
        "value": 24097,
        "NOCCode": "9461",
        "JobTitle": "roller room equipment tender – food and beverage processing"
    },
    {
        "value": 24098,
        "NOCCode": "9461",
        "JobTitle": "salad dressing finisher"
    },
    {
        "value": 24099,
        "NOCCode": "9461",
        "JobTitle": "sanding machine tender – food and beverage processing"
    },
    {
        "value": 24100,
        "NOCCode": "9461",
        "JobTitle": "sausage dryer and smoker"
    },
    {
        "value": 24101,
        "NOCCode": "9461",
        "JobTitle": "sausage maker"
    },
    {
        "value": 24102,
        "NOCCode": "9461",
        "JobTitle": "sausage-peeling machine tender"
    },
    {
        "value": 24103,
        "NOCCode": "9461",
        "JobTitle": "screening machine tender – food and beverage processing"
    },
    {
        "value": 24104,
        "NOCCode": "9461",
        "JobTitle": "separator operator – food processing"
    },
    {
        "value": 24105,
        "NOCCode": "9461",
        "JobTitle": "separator-pasteurizer – food processing"
    },
    {
        "value": 24106,
        "NOCCode": "9461",
        "JobTitle": "shredder operator – tobacco processing"
    },
    {
        "value": 24107,
        "NOCCode": "9461",
        "JobTitle": "shredding machine operator – food processing"
    },
    {
        "value": 24108,
        "NOCCode": "9461",
        "JobTitle": "skimmer, distillery"
    },
    {
        "value": 24109,
        "NOCCode": "9461",
        "JobTitle": "sliced cheese maker"
    },
    {
        "value": 24110,
        "NOCCode": "9461",
        "JobTitle": "slicing machine operator – food and beverage processing"
    },
    {
        "value": 24111,
        "NOCCode": "9461",
        "JobTitle": "slicing machine tender – food and beverage processing"
    },
    {
        "value": 24112,
        "NOCCode": "9461",
        "JobTitle": "smokehouse operator – food and beverage processing"
    },
    {
        "value": 24113,
        "NOCCode": "9461",
        "JobTitle": "snuff can inspector"
    },
    {
        "value": 24114,
        "NOCCode": "9461",
        "JobTitle": "snuff dryer – tobacco processing"
    },
    {
        "value": 24115,
        "NOCCode": "9461",
        "JobTitle": "snuff grinder – tobacco processing"
    },
    {
        "value": 24116,
        "NOCCode": "9461",
        "JobTitle": "snuff-blending machine operator – tobacco processing"
    },
    {
        "value": 24117,
        "NOCCode": "9461",
        "JobTitle": "soft drink powder mixer"
    },
    {
        "value": 24118,
        "NOCCode": "9461",
        "JobTitle": "soya mill operator"
    },
    {
        "value": 24119,
        "NOCCode": "9461",
        "JobTitle": "spice fumigator – food and beverage processing"
    },
    {
        "value": 24120,
        "NOCCode": "9461",
        "JobTitle": "spice miller"
    },
    {
        "value": 24121,
        "NOCCode": "9461",
        "JobTitle": "spice mixer"
    },
    {
        "value": 24122,
        "NOCCode": "9461",
        "JobTitle": "spinner – food and beverage processing"
    },
    {
        "value": 24123,
        "NOCCode": "9461",
        "JobTitle": "sponge and dough mixer – food and beverage processing"
    },
    {
        "value": 24124,
        "NOCCode": "9461",
        "JobTitle": "starch converter"
    },
    {
        "value": 24125,
        "NOCCode": "9461",
        "JobTitle": "starch maker – food and beverage processing"
    },
    {
        "value": 24126,
        "NOCCode": "9461",
        "JobTitle": "starch press tender"
    },
    {
        "value": 24127,
        "NOCCode": "9461",
        "JobTitle": "starch presser, corn products"
    },
    {
        "value": 24128,
        "NOCCode": "9461",
        "JobTitle": "starch separator operator"
    },
    {
        "value": 24129,
        "NOCCode": "9461",
        "JobTitle": "starch washer – food and beverage processing"
    },
    {
        "value": 24130,
        "NOCCode": "9461",
        "JobTitle": "steam dryer – food and beverage processing"
    },
    {
        "value": 24131,
        "NOCCode": "9461",
        "JobTitle": "steam kettle operator – food and beverage processing"
    },
    {
        "value": 24132,
        "NOCCode": "9461",
        "JobTitle": "steam oven operator – food and beverage processing"
    },
    {
        "value": 24133,
        "NOCCode": "9461",
        "JobTitle": "steam table operator – food and beverage processing"
    },
    {
        "value": 24134,
        "NOCCode": "9461",
        "JobTitle": "steamer – tobacco processing"
    },
    {
        "value": 24135,
        "NOCCode": "9461",
        "JobTitle": "steepman/woman – food and beverage processing"
    },
    {
        "value": 24136,
        "NOCCode": "9461",
        "JobTitle": "stem cutter – tobacco processing"
    },
    {
        "value": 24137,
        "NOCCode": "9461",
        "JobTitle": "stem roller – tobacco processing"
    },
    {
        "value": 24138,
        "NOCCode": "9461",
        "JobTitle": "stem-flattening machine operator – tobacco processing"
    },
    {
        "value": 24139,
        "NOCCode": "9461",
        "JobTitle": "stem-flattening machine tender – tobacco processing"
    },
    {
        "value": 24140,
        "NOCCode": "9461",
        "JobTitle": "stemming machine operator – tobacco processing"
    },
    {
        "value": 24141,
        "NOCCode": "9461",
        "JobTitle": "sterilizer machine operator – food and beverage processing"
    },
    {
        "value": 24142,
        "NOCCode": "9461",
        "JobTitle": "sterilizer operator – food and beverage processing"
    },
    {
        "value": 24143,
        "NOCCode": "9461",
        "JobTitle": "still operator – brewery"
    },
    {
        "value": 24144,
        "NOCCode": "9461",
        "JobTitle": "strip-cutting and blending operator – tobacco processing"
    },
    {
        "value": 24145,
        "NOCCode": "9461",
        "JobTitle": "strip-cutting machine tender – tobacco processing"
    },
    {
        "value": 24146,
        "NOCCode": "9461",
        "JobTitle": "stripping machine operator – tobacco processing"
    },
    {
        "value": 24147,
        "NOCCode": "9461",
        "JobTitle": "stripping machine tender – tobacco processing"
    },
    {
        "value": 24148,
        "NOCCode": "9461",
        "JobTitle": "sugar boiler"
    },
    {
        "value": 24149,
        "NOCCode": "9461",
        "JobTitle": "sugar-processing machine operator"
    },
    {
        "value": 24150,
        "NOCCode": "9461",
        "JobTitle": "syrup kettle operator"
    },
    {
        "value": 24151,
        "NOCCode": "9461",
        "JobTitle": "syrup maker"
    },
    {
        "value": 24152,
        "NOCCode": "9461",
        "JobTitle": "tallow extractor tender – food processing"
    },
    {
        "value": 24153,
        "NOCCode": "9461",
        "JobTitle": "tallow refiner"
    },
    {
        "value": 24154,
        "NOCCode": "9461",
        "JobTitle": "tankhouse operator – food and beverage processing"
    },
    {
        "value": 24155,
        "NOCCode": "9461",
        "JobTitle": "tart maker"
    },
    {
        "value": 24156,
        "NOCCode": "9461",
        "JobTitle": "tempering bin operator – food and beverage processing"
    },
    {
        "value": 24157,
        "NOCCode": "9461",
        "JobTitle": "tipping machine tender – tobacco processing"
    },
    {
        "value": 24158,
        "NOCCode": "9461",
        "JobTitle": "tobacco blender"
    },
    {
        "value": 24159,
        "NOCCode": "9461",
        "JobTitle": "tobacco blending line attendant"
    },
    {
        "value": 24160,
        "NOCCode": "9461",
        "JobTitle": "tobacco casing fluid blender"
    },
    {
        "value": 24161,
        "NOCCode": "9461",
        "JobTitle": "tobacco casing fluid cooker – tobacco processing"
    },
    {
        "value": 24162,
        "NOCCode": "9461",
        "JobTitle": "tobacco conditioner tender"
    },
    {
        "value": 24163,
        "NOCCode": "9461",
        "JobTitle": "tobacco cutter – tobacco processing"
    },
    {
        "value": 24164,
        "NOCCode": "9461",
        "JobTitle": "tobacco dryer – tobacco processing"
    },
    {
        "value": 24165,
        "NOCCode": "9461",
        "JobTitle": "tobacco flavourer"
    },
    {
        "value": 24166,
        "NOCCode": "9461",
        "JobTitle": "tobacco leaf blender"
    },
    {
        "value": 24167,
        "NOCCode": "9461",
        "JobTitle": "tobacco leaf cutter"
    },
    {
        "value": 24168,
        "NOCCode": "9461",
        "JobTitle": "tobacco leaf grinder"
    },
    {
        "value": 24169,
        "NOCCode": "9461",
        "JobTitle": "tobacco leaf twister"
    },
    {
        "value": 24170,
        "NOCCode": "9461",
        "JobTitle": "tobacco packaging machine tender"
    },
    {
        "value": 24171,
        "NOCCode": "9461",
        "JobTitle": "tobacco roller"
    },
    {
        "value": 24172,
        "NOCCode": "9461",
        "JobTitle": "tobacco shredder"
    },
    {
        "value": 24173,
        "NOCCode": "9461",
        "JobTitle": "tobacco stripper – tobacco processing"
    },
    {
        "value": 24174,
        "NOCCode": "9461",
        "JobTitle": "tobacco-blending machine operator"
    },
    {
        "value": 24175,
        "NOCCode": "9461",
        "JobTitle": "tobacco-cutting machine tender"
    },
    {
        "value": 24176,
        "NOCCode": "9461",
        "JobTitle": "tobacco-dressing machine operator – tobacco processing"
    },
    {
        "value": 24177,
        "NOCCode": "9461",
        "JobTitle": "tobacco-processing machine operator"
    },
    {
        "value": 24178,
        "NOCCode": "9461",
        "JobTitle": "tomato juice extractor"
    },
    {
        "value": 24179,
        "NOCCode": "9461",
        "JobTitle": "tripe cooker – food and beverage processing"
    },
    {
        "value": 24180,
        "NOCCode": "9461",
        "JobTitle": "turnip waxer – food and beverage processing"
    },
    {
        "value": 24181,
        "NOCCode": "9461",
        "JobTitle": "turret machine tender – tobacco processing"
    },
    {
        "value": 24182,
        "NOCCode": "9461",
        "JobTitle": "twister, tobacco leaves"
    },
    {
        "value": 24183,
        "NOCCode": "9461",
        "JobTitle": "vegetable canner – food and beverage processing"
    },
    {
        "value": 24184,
        "NOCCode": "9461",
        "JobTitle": "vegetable dryer tender – food and beverage processing"
    },
    {
        "value": 24185,
        "NOCCode": "9461",
        "JobTitle": "vegetable grinder – food and beverage processing"
    },
    {
        "value": 24186,
        "NOCCode": "9461",
        "JobTitle": "vegetable oil extractor"
    },
    {
        "value": 24187,
        "NOCCode": "9461",
        "JobTitle": "vegetable oil extractor, solvent process"
    },
    {
        "value": 24188,
        "NOCCode": "9461",
        "JobTitle": "vinegar maker"
    },
    {
        "value": 24189,
        "NOCCode": "9461",
        "JobTitle": "weigh tank tender – food and beverage processing"
    },
    {
        "value": 24190,
        "NOCCode": "9461",
        "JobTitle": "wet process miller – food and beverage processing"
    },
    {
        "value": 24191,
        "NOCCode": "9461",
        "JobTitle": "wine fermenter – food and beverage processing"
    },
    {
        "value": 24192,
        "NOCCode": "9461",
        "JobTitle": "wine pasteurizer"
    },
    {
        "value": 24193,
        "NOCCode": "9461",
        "JobTitle": "winemaker – food and beverage processing"
    },
    {
        "value": 24194,
        "NOCCode": "9461",
        "JobTitle": "winery cellarman/woman – food and beverage processing"
    },
    {
        "value": 24195,
        "NOCCode": "9461",
        "JobTitle": "winery-press operator"
    },
    {
        "value": 24196,
        "NOCCode": "9461",
        "JobTitle": "winterizer tender – food and beverage processing"
    },
    {
        "value": 24197,
        "NOCCode": "9461",
        "JobTitle": "wort cooler – food and beverage processing"
    },
    {
        "value": 24198,
        "NOCCode": "9461",
        "JobTitle": "wort maker – food and beverage processing"
    },
    {
        "value": 24199,
        "NOCCode": "9461",
        "JobTitle": "yeast cutter"
    },
    {
        "value": 24200,
        "NOCCode": "9461",
        "JobTitle": "yeast dryer"
    },
    {
        "value": 24201,
        "NOCCode": "9461",
        "JobTitle": "yeast emulsifier tender – food and beverage processing"
    },
    {
        "value": 24202,
        "NOCCode": "9461",
        "JobTitle": "yeast maker"
    },
    {
        "value": 24203,
        "NOCCode": "9461",
        "JobTitle": "yeast room operator"
    },
    {
        "value": 24204,
        "NOCCode": "9461",
        "JobTitle": "yeast separator – food and beverage processing"
    },
    {
        "value": 24205,
        "NOCCode": "9461",
        "JobTitle": "yogurt maker"
    },
    {
        "value": 24206,
        "NOCCode": "9462",
        "JobTitle": "animal eviscerator – meat packing plant"
    },
    {
        "value": 24207,
        "NOCCode": "9462",
        "JobTitle": "animal skinner – meat packing plant"
    },
    {
        "value": 24208,
        "NOCCode": "9462",
        "JobTitle": "animal sticker – meat packing plant"
    },
    {
        "value": 24209,
        "NOCCode": "9462",
        "JobTitle": "animal stunner"
    },
    {
        "value": 24210,
        "NOCCode": "9462",
        "JobTitle": "beef boner"
    },
    {
        "value": 24211,
        "NOCCode": "9462",
        "JobTitle": "beef boner – meat packing plant"
    },
    {
        "value": 24212,
        "NOCCode": "9462",
        "JobTitle": "beef cooler worker – meat packing plant"
    },
    {
        "value": 24213,
        "NOCCode": "9462",
        "JobTitle": "beef sticker – meat packing plant"
    },
    {
        "value": 24214,
        "NOCCode": "9462",
        "JobTitle": "beef trimmer – meat packing plant"
    },
    {
        "value": 24215,
        "NOCCode": "9462",
        "JobTitle": "beef-pluck trimmer"
    },
    {
        "value": 24216,
        "NOCCode": "9462",
        "JobTitle": "benchman/woman – meat packing plant"
    },
    {
        "value": 24217,
        "NOCCode": "9462",
        "JobTitle": "boner, meat"
    },
    {
        "value": 24218,
        "NOCCode": "9462",
        "JobTitle": "brisket cutter – meat packing plant"
    },
    {
        "value": 24219,
        "NOCCode": "9462",
        "JobTitle": "butcher, industrial"
    },
    {
        "value": 24220,
        "NOCCode": "9462",
        "JobTitle": "butcher, slaughterhouse"
    },
    {
        "value": 24221,
        "NOCCode": "9462",
        "JobTitle": "calf skinner – meat packing plant"
    },
    {
        "value": 24222,
        "NOCCode": "9462",
        "JobTitle": "carcass splitter – meat packing plant"
    },
    {
        "value": 24223,
        "NOCCode": "9462",
        "JobTitle": "chicken plucker"
    },
    {
        "value": 24224,
        "NOCCode": "9462",
        "JobTitle": "cutter – meat packing plant"
    },
    {
        "value": 24225,
        "NOCCode": "9462",
        "JobTitle": "dehairing machine dropper – meat packing plant"
    },
    {
        "value": 24226,
        "NOCCode": "9462",
        "JobTitle": "dropper, dehairing machine – meat packing plant"
    },
    {
        "value": 24227,
        "NOCCode": "9462",
        "JobTitle": "enzyme injector – food and beverage processing"
    },
    {
        "value": 24228,
        "NOCCode": "9462",
        "JobTitle": "eviscerator, animals – meat packing plant"
    },
    {
        "value": 24229,
        "NOCCode": "9462",
        "JobTitle": "eviscerator, poultry"
    },
    {
        "value": 24230,
        "NOCCode": "9462",
        "JobTitle": "ham cutter"
    },
    {
        "value": 24231,
        "NOCCode": "9462",
        "JobTitle": "ham sawyer – meat packing plant"
    },
    {
        "value": 24232,
        "NOCCode": "9462",
        "JobTitle": "head boner – meat packing plant"
    },
    {
        "value": 24233,
        "NOCCode": "9462",
        "JobTitle": "hog killer"
    },
    {
        "value": 24234,
        "NOCCode": "9462",
        "JobTitle": "industrial butcher"
    },
    {
        "value": 24235,
        "NOCCode": "9462",
        "JobTitle": "industrial meat chopper"
    },
    {
        "value": 24236,
        "NOCCode": "9462",
        "JobTitle": "industrial meat cutter"
    },
    {
        "value": 24237,
        "NOCCode": "9462",
        "JobTitle": "livestock killer"
    },
    {
        "value": 24238,
        "NOCCode": "9462",
        "JobTitle": "meat boner"
    },
    {
        "value": 24239,
        "NOCCode": "9462",
        "JobTitle": "meat chopper – meat packing plant"
    },
    {
        "value": 24240,
        "NOCCode": "9462",
        "JobTitle": "meat cutter – meat packing plant"
    },
    {
        "value": 24241,
        "NOCCode": "9462",
        "JobTitle": "meat dresser – meat packing plant"
    },
    {
        "value": 24242,
        "NOCCode": "9462",
        "JobTitle": "meat portion cutter – meat packing plant"
    },
    {
        "value": 24243,
        "NOCCode": "9462",
        "JobTitle": "meat roll tier – food and beverage processing"
    },
    {
        "value": 24244,
        "NOCCode": "9462",
        "JobTitle": "meat sawyer – meat packing plant"
    },
    {
        "value": 24245,
        "NOCCode": "9462",
        "JobTitle": "meat tenderizer – food and beverage processing"
    },
    {
        "value": 24246,
        "NOCCode": "9462",
        "JobTitle": "meat trimmer"
    },
    {
        "value": 24247,
        "NOCCode": "9462",
        "JobTitle": "meat trimmer – meat packing plant"
    },
    {
        "value": 24248,
        "NOCCode": "9462",
        "JobTitle": "poultry boner"
    },
    {
        "value": 24249,
        "NOCCode": "9462",
        "JobTitle": "poultry butcher – meat packing plant"
    },
    {
        "value": 24250,
        "NOCCode": "9462",
        "JobTitle": "poultry dresser"
    },
    {
        "value": 24251,
        "NOCCode": "9462",
        "JobTitle": "poultry eviscerator"
    },
    {
        "value": 24252,
        "NOCCode": "9462",
        "JobTitle": "poultry hanger"
    },
    {
        "value": 24253,
        "NOCCode": "9462",
        "JobTitle": "poultry plucker"
    },
    {
        "value": 24254,
        "NOCCode": "9462",
        "JobTitle": "poultry plucker tender"
    },
    {
        "value": 24255,
        "NOCCode": "9462",
        "JobTitle": "poultry preparer"
    },
    {
        "value": 24256,
        "NOCCode": "9462",
        "JobTitle": "poultry slaughterer – food and beverage processing"
    },
    {
        "value": 24257,
        "NOCCode": "9462",
        "JobTitle": "poultry worker – meat packing plant"
    },
    {
        "value": 24258,
        "NOCCode": "9462",
        "JobTitle": "ritual slaughterer – meat packing plant"
    },
    {
        "value": 24259,
        "NOCCode": "9462",
        "JobTitle": "shackle hoist operator – meat packing plant"
    },
    {
        "value": 24260,
        "NOCCode": "9462",
        "JobTitle": "sheep sticker"
    },
    {
        "value": 24261,
        "NOCCode": "9462",
        "JobTitle": "shohet – meat packing plant"
    },
    {
        "value": 24262,
        "NOCCode": "9462",
        "JobTitle": "skinner, animals – meat packing plant"
    },
    {
        "value": 24263,
        "NOCCode": "9462",
        "JobTitle": "slaughterer"
    },
    {
        "value": 24264,
        "NOCCode": "9462",
        "JobTitle": "slaughterer – meat packing plant"
    },
    {
        "value": 24265,
        "NOCCode": "9462",
        "JobTitle": "slaughterer, ritual – meat packing plant"
    },
    {
        "value": 24266,
        "NOCCode": "9462",
        "JobTitle": "slaughterhouse butcher"
    },
    {
        "value": 24267,
        "NOCCode": "9462",
        "JobTitle": "sticker, animals – meat packing plant"
    },
    {
        "value": 24268,
        "NOCCode": "9462",
        "JobTitle": "trimmer – meat packing plant"
    },
    {
        "value": 24269,
        "NOCCode": "9462",
        "JobTitle": "trimmer, meat – meat packing plant"
    },
    {
        "value": 24270,
        "NOCCode": "9463",
        "JobTitle": "basket filter tender – fish and seafood processing"
    },
    {
        "value": 24271,
        "NOCCode": "9463",
        "JobTitle": "can filler – fish and seafood processing"
    },
    {
        "value": 24272,
        "NOCCode": "9463",
        "JobTitle": "can-filling machine operator – fish processing"
    },
    {
        "value": 24273,
        "NOCCode": "9463",
        "JobTitle": "canner, oysters – fish processing"
    },
    {
        "value": 24274,
        "NOCCode": "9463",
        "JobTitle": "centrifuge machine tender – fish processing"
    },
    {
        "value": 24275,
        "NOCCode": "9463",
        "JobTitle": "clam shucker – fish and seafood processing"
    },
    {
        "value": 24276,
        "NOCCode": "9463",
        "JobTitle": "clam steamer – fish and seafood processing"
    },
    {
        "value": 24277,
        "NOCCode": "9463",
        "JobTitle": "clam-shucking machine tender – fish and seafood processing"
    },
    {
        "value": 24278,
        "NOCCode": "9463",
        "JobTitle": "cleaner and cutter, fish – fish processing"
    },
    {
        "value": 24279,
        "NOCCode": "9463",
        "JobTitle": "crab butcher – fish and seafood processing"
    },
    {
        "value": 24280,
        "NOCCode": "9463",
        "JobTitle": "crab steamer – fish and seafood processing"
    },
    {
        "value": 24281,
        "NOCCode": "9463",
        "JobTitle": "curer, fish – fish processing"
    },
    {
        "value": 24282,
        "NOCCode": "9463",
        "JobTitle": "cutter, fish – fish processing"
    },
    {
        "value": 24283,
        "NOCCode": "9463",
        "JobTitle": "fillet chopper – fish processing"
    },
    {
        "value": 24284,
        "NOCCode": "9463",
        "JobTitle": "filleting machine operator – fish processing"
    },
    {
        "value": 24285,
        "NOCCode": "9463",
        "JobTitle": "filleting machine setter – fish processing"
    },
    {
        "value": 24286,
        "NOCCode": "9463",
        "JobTitle": "filling machine operator – fish and seafood products"
    },
    {
        "value": 24287,
        "NOCCode": "9463",
        "JobTitle": "fish boner – fish processing"
    },
    {
        "value": 24288,
        "NOCCode": "9463",
        "JobTitle": "fish broiler – fish processing"
    },
    {
        "value": 24289,
        "NOCCode": "9463",
        "JobTitle": "fish by-products processor"
    },
    {
        "value": 24290,
        "NOCCode": "9463",
        "JobTitle": "fish cake former – fish processing"
    },
    {
        "value": 24291,
        "NOCCode": "9463",
        "JobTitle": "fish cake maker"
    },
    {
        "value": 24292,
        "NOCCode": "9463",
        "JobTitle": "fish cake maker – fish processing"
    },
    {
        "value": 24293,
        "NOCCode": "9463",
        "JobTitle": "fish cake processor – fish processing"
    },
    {
        "value": 24294,
        "NOCCode": "9463",
        "JobTitle": "fish canning machine operator"
    },
    {
        "value": 24295,
        "NOCCode": "9463",
        "JobTitle": "fish canning machine operator – fish processing"
    },
    {
        "value": 24296,
        "NOCCode": "9463",
        "JobTitle": "fish cleaner and cutter"
    },
    {
        "value": 24297,
        "NOCCode": "9463",
        "JobTitle": "fish cleaner and cutter – fish processing"
    },
    {
        "value": 24298,
        "NOCCode": "9463",
        "JobTitle": "fish cook – fish processing"
    },
    {
        "value": 24299,
        "NOCCode": "9463",
        "JobTitle": "fish curer – fish processing"
    },
    {
        "value": 24300,
        "NOCCode": "9463",
        "JobTitle": "fish cutter – fish processing"
    },
    {
        "value": 24301,
        "NOCCode": "9463",
        "JobTitle": "fish cutting machine operator – fish processing"
    },
    {
        "value": 24302,
        "NOCCode": "9463",
        "JobTitle": "fish cutting machine setter – fish processing"
    },
    {
        "value": 24303,
        "NOCCode": "9463",
        "JobTitle": "fish dresser – fish processing"
    },
    {
        "value": 24304,
        "NOCCode": "9463",
        "JobTitle": "fish dryer and grinder tender – fish processing"
    },
    {
        "value": 24305,
        "NOCCode": "9463",
        "JobTitle": "fish dryer tender – fish processing"
    },
    {
        "value": 24306,
        "NOCCode": "9463",
        "JobTitle": "fish egg processor – fish processing"
    },
    {
        "value": 24307,
        "NOCCode": "9463",
        "JobTitle": "fish fryer – fish processing"
    },
    {
        "value": 24308,
        "NOCCode": "9463",
        "JobTitle": "fish header – fish processing"
    },
    {
        "value": 24309,
        "NOCCode": "9463",
        "JobTitle": "fish offal processor – fish processing"
    },
    {
        "value": 24310,
        "NOCCode": "9463",
        "JobTitle": "fish oil extractor tender – fish processing"
    },
    {
        "value": 24311,
        "NOCCode": "9463",
        "JobTitle": "fish paste grinder – fish processing"
    },
    {
        "value": 24312,
        "NOCCode": "9463",
        "JobTitle": "fish plant machine operator – fish processing"
    },
    {
        "value": 24313,
        "NOCCode": "9463",
        "JobTitle": "fish plant worker"
    },
    {
        "value": 24314,
        "NOCCode": "9463",
        "JobTitle": "fish presser operator – fish processing"
    },
    {
        "value": 24315,
        "NOCCode": "9463",
        "JobTitle": "fish processor"
    },
    {
        "value": 24316,
        "NOCCode": "9463",
        "JobTitle": "fish products maker – fish processing"
    },
    {
        "value": 24317,
        "NOCCode": "9463",
        "JobTitle": "fish skinner operator – fish processing"
    },
    {
        "value": 24318,
        "NOCCode": "9463",
        "JobTitle": "fish smoke chamber tender – fish processing"
    },
    {
        "value": 24319,
        "NOCCode": "9463",
        "JobTitle": "fish smoker – fish processing"
    },
    {
        "value": 24320,
        "NOCCode": "9463",
        "JobTitle": "fish splitter – fish processing"
    },
    {
        "value": 24321,
        "NOCCode": "9463",
        "JobTitle": "fish stick machine tender – fish processing"
    },
    {
        "value": 24322,
        "NOCCode": "9463",
        "JobTitle": "fish trimmer – fish processing"
    },
    {
        "value": 24323,
        "NOCCode": "9463",
        "JobTitle": "fish-cleaning machine tender – fish processing"
    },
    {
        "value": 24324,
        "NOCCode": "9463",
        "JobTitle": "fish-processing machine operator"
    },
    {
        "value": 24325,
        "NOCCode": "9463",
        "JobTitle": "fish-processing machine tender"
    },
    {
        "value": 24326,
        "NOCCode": "9463",
        "JobTitle": "fish-processing plant worker"
    },
    {
        "value": 24327,
        "NOCCode": "9463",
        "JobTitle": "fish-skinning machine operator – fish processing"
    },
    {
        "value": 24328,
        "NOCCode": "9463",
        "JobTitle": "frozen fish cutter – fish processing"
    },
    {
        "value": 24329,
        "NOCCode": "9463",
        "JobTitle": "fryer, fish – fish processing"
    },
    {
        "value": 24330,
        "NOCCode": "9463",
        "JobTitle": "hand shellfish processor – fish and seafood processing"
    },
    {
        "value": 24331,
        "NOCCode": "9463",
        "JobTitle": "lobster processor – fish processing"
    },
    {
        "value": 24332,
        "NOCCode": "9463",
        "JobTitle": "machine operator – fish processing"
    },
    {
        "value": 24333,
        "NOCCode": "9463",
        "JobTitle": "oyster canner – fish processing"
    },
    {
        "value": 24334,
        "NOCCode": "9463",
        "JobTitle": "oyster packer – fish processing"
    },
    {
        "value": 24335,
        "NOCCode": "9463",
        "JobTitle": "oyster shucker – fish processing"
    },
    {
        "value": 24336,
        "NOCCode": "9463",
        "JobTitle": "oyster washer tender – fish processing"
    },
    {
        "value": 24337,
        "NOCCode": "9463",
        "JobTitle": "packer, oysters – fish processing"
    },
    {
        "value": 24338,
        "NOCCode": "9463",
        "JobTitle": "plant worker, fish"
    },
    {
        "value": 24339,
        "NOCCode": "9463",
        "JobTitle": "preparer, seafood – fish and seafood processing"
    },
    {
        "value": 24340,
        "NOCCode": "9463",
        "JobTitle": "processing plant worker, fish"
    },
    {
        "value": 24341,
        "NOCCode": "9463",
        "JobTitle": "scallop shucker – fish and seafood processing"
    },
    {
        "value": 24342,
        "NOCCode": "9463",
        "JobTitle": "seafood preparer – fish and seafood processing"
    },
    {
        "value": 24343,
        "NOCCode": "9463",
        "JobTitle": "shellfish processor"
    },
    {
        "value": 24344,
        "NOCCode": "9463",
        "JobTitle": "shellfish processor, hand – fish and seafood processing"
    },
    {
        "value": 24345,
        "NOCCode": "9463",
        "JobTitle": "shellfish separator tender – fish and seafood processing"
    },
    {
        "value": 24346,
        "NOCCode": "9463",
        "JobTitle": "shellfish shucker"
    },
    {
        "value": 24347,
        "NOCCode": "9463",
        "JobTitle": "shellfish shucker – fish and seafood processing"
    },
    {
        "value": 24348,
        "NOCCode": "9463",
        "JobTitle": "shellfish-processing machine tender"
    },
    {
        "value": 24349,
        "NOCCode": "9463",
        "JobTitle": "shrimp peeler – fish and seafood processing"
    },
    {
        "value": 24350,
        "NOCCode": "9463",
        "JobTitle": "shrimp peeler tender – fish and seafood processing"
    },
    {
        "value": 24351,
        "NOCCode": "9463",
        "JobTitle": "shrimp processor – fish and seafood processing"
    },
    {
        "value": 24352,
        "NOCCode": "9463",
        "JobTitle": "shucker – fish and seafood processing"
    },
    {
        "value": 24353,
        "NOCCode": "9463",
        "JobTitle": "shucker, clams – fish and seafood processing"
    },
    {
        "value": 24354,
        "NOCCode": "9463",
        "JobTitle": "shucker, oysters – fish processing"
    },
    {
        "value": 24355,
        "NOCCode": "9463",
        "JobTitle": "shucker, scallops – fish and seafood processing"
    },
    {
        "value": 24356,
        "NOCCode": "9463",
        "JobTitle": "skinner operator – fish processing"
    },
    {
        "value": 24357,
        "NOCCode": "9463",
        "JobTitle": "skinning machine setter – fish processing"
    },
    {
        "value": 24358,
        "NOCCode": "9463",
        "JobTitle": "smoke chamber tender, fish – fish processing"
    },
    {
        "value": 24359,
        "NOCCode": "9463",
        "JobTitle": "splitter, fish – fish processing"
    },
    {
        "value": 24360,
        "NOCCode": "9463",
        "JobTitle": "steamer, clams – fish and seafood processing"
    },
    {
        "value": 24361,
        "NOCCode": "9463",
        "JobTitle": "trimmer – fish processing"
    },
    {
        "value": 24362,
        "NOCCode": "9465",
        "JobTitle": "bacon grader"
    },
    {
        "value": 24363,
        "NOCCode": "9465",
        "JobTitle": "beef grader"
    },
    {
        "value": 24364,
        "NOCCode": "9465",
        "JobTitle": "beer bottling line quality control tester"
    },
    {
        "value": 24365,
        "NOCCode": "9465",
        "JobTitle": "beer tester"
    },
    {
        "value": 24366,
        "NOCCode": "9465",
        "JobTitle": "beverage and bottle inspector"
    },
    {
        "value": 24367,
        "NOCCode": "9465",
        "JobTitle": "beverage inspector"
    },
    {
        "value": 24368,
        "NOCCode": "9465",
        "JobTitle": "bread tester"
    },
    {
        "value": 24369,
        "NOCCode": "9465",
        "JobTitle": "brewery tester – food and beverage processing"
    },
    {
        "value": 24370,
        "NOCCode": "9465",
        "JobTitle": "butter grader"
    },
    {
        "value": 24371,
        "NOCCode": "9465",
        "JobTitle": "butterfat tester"
    },
    {
        "value": 24372,
        "NOCCode": "9465",
        "JobTitle": "can inspector – food and beverage processing"
    },
    {
        "value": 24373,
        "NOCCode": "9465",
        "JobTitle": "candy inspector"
    },
    {
        "value": 24374,
        "NOCCode": "9465",
        "JobTitle": "canned and bottled foods inspector"
    },
    {
        "value": 24375,
        "NOCCode": "9465",
        "JobTitle": "capon grader – food and beverage processing"
    },
    {
        "value": 24376,
        "NOCCode": "9465",
        "JobTitle": "casing grader – food and beverage processing"
    },
    {
        "value": 24377,
        "NOCCode": "9465",
        "JobTitle": "cheese grader"
    },
    {
        "value": 24378,
        "NOCCode": "9465",
        "JobTitle": "cigar grader"
    },
    {
        "value": 24379,
        "NOCCode": "9465",
        "JobTitle": "cigarette grader"
    },
    {
        "value": 24380,
        "NOCCode": "9465",
        "JobTitle": "cigarette tester"
    },
    {
        "value": 24381,
        "NOCCode": "9465",
        "JobTitle": "cookie and cracker tester"
    },
    {
        "value": 24382,
        "NOCCode": "9465",
        "JobTitle": "cream grader"
    },
    {
        "value": 24383,
        "NOCCode": "9465",
        "JobTitle": "dairy cream tester"
    },
    {
        "value": 24384,
        "NOCCode": "9465",
        "JobTitle": "dairy grader – food and beverage processing"
    },
    {
        "value": 24385,
        "NOCCode": "9465",
        "JobTitle": "dairy moisture tester"
    },
    {
        "value": 24386,
        "NOCCode": "9465",
        "JobTitle": "dairy product tester – food and beverage processing"
    },
    {
        "value": 24387,
        "NOCCode": "9465",
        "JobTitle": "dairy tester – food and beverage processing"
    },
    {
        "value": 24388,
        "NOCCode": "9465",
        "JobTitle": "dressed poultry grader"
    },
    {
        "value": 24389,
        "NOCCode": "9465",
        "JobTitle": "feed tester – food and beverage processing"
    },
    {
        "value": 24390,
        "NOCCode": "9465",
        "JobTitle": "fermentation inspector, wineries"
    },
    {
        "value": 24391,
        "NOCCode": "9465",
        "JobTitle": "filter tip inspector – tobacco processing"
    },
    {
        "value": 24392,
        "NOCCode": "9465",
        "JobTitle": "fish grader"
    },
    {
        "value": 24393,
        "NOCCode": "9465",
        "JobTitle": "food and beverage processing grader"
    },
    {
        "value": 24394,
        "NOCCode": "9465",
        "JobTitle": "fruit and vegetable grader – food and beverage processing"
    },
    {
        "value": 24395,
        "NOCCode": "9465",
        "JobTitle": "gauger – wineries"
    },
    {
        "value": 24396,
        "NOCCode": "9465",
        "JobTitle": "grader – food and beverage processing"
    },
    {
        "value": 24397,
        "NOCCode": "9465",
        "JobTitle": "grader, butter"
    },
    {
        "value": 24398,
        "NOCCode": "9465",
        "JobTitle": "grader, cheeses"
    },
    {
        "value": 24399,
        "NOCCode": "9465",
        "JobTitle": "grader, cigarettes"
    },
    {
        "value": 24400,
        "NOCCode": "9465",
        "JobTitle": "grader, cigars"
    },
    {
        "value": 24401,
        "NOCCode": "9465",
        "JobTitle": "grader, cream"
    },
    {
        "value": 24402,
        "NOCCode": "9465",
        "JobTitle": "grader, fish"
    },
    {
        "value": 24403,
        "NOCCode": "9465",
        "JobTitle": "grader, lamb"
    },
    {
        "value": 24404,
        "NOCCode": "9465",
        "JobTitle": "grader, meat"
    },
    {
        "value": 24405,
        "NOCCode": "9465",
        "JobTitle": "grader, milk"
    },
    {
        "value": 24406,
        "NOCCode": "9465",
        "JobTitle": "grader, tobacco"
    },
    {
        "value": 24407,
        "NOCCode": "9465",
        "JobTitle": "grain sampler – food and beverage processing"
    },
    {
        "value": 24408,
        "NOCCode": "9465",
        "JobTitle": "ham grader"
    },
    {
        "value": 24409,
        "NOCCode": "9465",
        "JobTitle": "hogshead inspector – tobacco processing"
    },
    {
        "value": 24410,
        "NOCCode": "9465",
        "JobTitle": "honey grader and blender"
    },
    {
        "value": 24411,
        "NOCCode": "9465",
        "JobTitle": "inspector, beverages"
    },
    {
        "value": 24412,
        "NOCCode": "9465",
        "JobTitle": "inspector, beverages and bottles"
    },
    {
        "value": 24413,
        "NOCCode": "9465",
        "JobTitle": "inspector, canned and bottled foods"
    },
    {
        "value": 24414,
        "NOCCode": "9465",
        "JobTitle": "inspector, filter tip – tobacco processing"
    },
    {
        "value": 24415,
        "NOCCode": "9465",
        "JobTitle": "inspector, kosher – food and beverage processing"
    },
    {
        "value": 24416,
        "NOCCode": "9465",
        "JobTitle": "kosher inspector – food and beverage processing"
    },
    {
        "value": 24417,
        "NOCCode": "9465",
        "JobTitle": "lamb grader"
    },
    {
        "value": 24418,
        "NOCCode": "9465",
        "JobTitle": "liquor tester – food and beverage processing"
    },
    {
        "value": 24419,
        "NOCCode": "9465",
        "JobTitle": "mashgiach – food and beverage processing"
    },
    {
        "value": 24420,
        "NOCCode": "9465",
        "JobTitle": "meat grader"
    },
    {
        "value": 24421,
        "NOCCode": "9465",
        "JobTitle": "milk grader"
    },
    {
        "value": 24422,
        "NOCCode": "9465",
        "JobTitle": "milk receiver-tester"
    },
    {
        "value": 24423,
        "NOCCode": "9465",
        "JobTitle": "moisture content tester – dairy products"
    },
    {
        "value": 24424,
        "NOCCode": "9465",
        "JobTitle": "moisture content tester – tobacco processing"
    },
    {
        "value": 24425,
        "NOCCode": "9465",
        "JobTitle": "pork grader"
    },
    {
        "value": 24426,
        "NOCCode": "9465",
        "JobTitle": "poultry grader"
    },
    {
        "value": 24427,
        "NOCCode": "9465",
        "JobTitle": "process control checker – food and beverage processing"
    },
    {
        "value": 24428,
        "NOCCode": "9465",
        "JobTitle": "produce grader – food and beverage processing"
    },
    {
        "value": 24429,
        "NOCCode": "9465",
        "JobTitle": "product tester – food and beverage processing"
    },
    {
        "value": 24430,
        "NOCCode": "9465",
        "JobTitle": "quality control checker – food and beverage processing"
    },
    {
        "value": 24431,
        "NOCCode": "9465",
        "JobTitle": "quality control tester, beer bottling line"
    },
    {
        "value": 24432,
        "NOCCode": "9465",
        "JobTitle": "seed corn grader"
    },
    {
        "value": 24433,
        "NOCCode": "9465",
        "JobTitle": "soybean tester"
    },
    {
        "value": 24434,
        "NOCCode": "9465",
        "JobTitle": "starch tester – food and beverage processing"
    },
    {
        "value": 24435,
        "NOCCode": "9465",
        "JobTitle": "sugar tester"
    },
    {
        "value": 24436,
        "NOCCode": "9465",
        "JobTitle": "tenderometer tender – food and beverage processing"
    },
    {
        "value": 24437,
        "NOCCode": "9465",
        "JobTitle": "test baker – food and beverage processing"
    },
    {
        "value": 24438,
        "NOCCode": "9465",
        "JobTitle": "tester, beer"
    },
    {
        "value": 24439,
        "NOCCode": "9465",
        "JobTitle": "tester, cookies and crackers"
    },
    {
        "value": 24440,
        "NOCCode": "9465",
        "JobTitle": "tester, liquor – food and beverage processing"
    },
    {
        "value": 24441,
        "NOCCode": "9465",
        "JobTitle": "tester, moisture content – tobacco processing"
    },
    {
        "value": 24442,
        "NOCCode": "9465",
        "JobTitle": "tester, products – food and beverage processing"
    },
    {
        "value": 24443,
        "NOCCode": "9465",
        "JobTitle": "tester, sugar"
    },
    {
        "value": 24444,
        "NOCCode": "9465",
        "JobTitle": "tobacco grader"
    },
    {
        "value": 24445,
        "NOCCode": "9465",
        "JobTitle": "tobacco leaf grader"
    },
    {
        "value": 24446,
        "NOCCode": "9465",
        "JobTitle": "tobacco sample puller"
    },
    {
        "value": 24447,
        "NOCCode": "9465",
        "JobTitle": "veal grader"
    },
    {
        "value": 24448,
        "NOCCode": "9465",
        "JobTitle": "wine tester"
    },
    {
        "value": 24449,
        "NOCCode": "9465",
        "JobTitle": "wineries fermentation inspector"
    },
    {
        "value": 24450,
        "NOCCode": "9465",
        "JobTitle": "wineries gauger"
    },
    {
        "value": 24451,
        "NOCCode": "9471",
        "JobTitle": "balloon printer"
    },
    {
        "value": 24452,
        "NOCCode": "9471",
        "JobTitle": "blueprint blocker"
    },
    {
        "value": 24453,
        "NOCCode": "9471",
        "JobTitle": "blueprint developer"
    },
    {
        "value": 24454,
        "NOCCode": "9471",
        "JobTitle": "blueprinting machine operator"
    },
    {
        "value": 24455,
        "NOCCode": "9471",
        "JobTitle": "box printer – printing"
    },
    {
        "value": 24456,
        "NOCCode": "9471",
        "JobTitle": "box, case and lining printer"
    },
    {
        "value": 24457,
        "NOCCode": "9471",
        "JobTitle": "box-printing machine operator"
    },
    {
        "value": 24458,
        "NOCCode": "9471",
        "JobTitle": "braille duplicator"
    },
    {
        "value": 24459,
        "NOCCode": "9471",
        "JobTitle": "braille printer"
    },
    {
        "value": 24460,
        "NOCCode": "9471",
        "JobTitle": "braille-impressing machine operator"
    },
    {
        "value": 24461,
        "NOCCode": "9471",
        "JobTitle": "colour and large copier operator"
    },
    {
        "value": 24462,
        "NOCCode": "9471",
        "JobTitle": "colour copier operator"
    },
    {
        "value": 24463,
        "NOCCode": "9471",
        "JobTitle": "colour photocopier operator"
    },
    {
        "value": 24464,
        "NOCCode": "9471",
        "JobTitle": "colour photocopy operator"
    },
    {
        "value": 24465,
        "NOCCode": "9471",
        "JobTitle": "copy, staple and glue machine operator"
    },
    {
        "value": 24466,
        "NOCCode": "9471",
        "JobTitle": "die stamper – printing"
    },
    {
        "value": 24467,
        "NOCCode": "9471",
        "JobTitle": "electronic printing machine operator"
    },
    {
        "value": 24468,
        "NOCCode": "9471",
        "JobTitle": "electronic sign maker operator"
    },
    {
        "value": 24469,
        "NOCCode": "9471",
        "JobTitle": "embossing printer"
    },
    {
        "value": 24470,
        "NOCCode": "9471",
        "JobTitle": "embossing-imprinting machine operator"
    },
    {
        "value": 24471,
        "NOCCode": "9471",
        "JobTitle": "engraving machine operator – printing"
    },
    {
        "value": 24472,
        "NOCCode": "9471",
        "JobTitle": "floorcovering printer"
    },
    {
        "value": 24473,
        "NOCCode": "9471",
        "JobTitle": "glove printer"
    },
    {
        "value": 24474,
        "NOCCode": "9471",
        "JobTitle": "high speed printer operator"
    },
    {
        "value": 24475,
        "NOCCode": "9471",
        "JobTitle": "label printer"
    },
    {
        "value": 24476,
        "NOCCode": "9471",
        "JobTitle": "laser printer operator"
    },
    {
        "value": 24477,
        "NOCCode": "9471",
        "JobTitle": "machine advertising signs printer"
    },
    {
        "value": 24478,
        "NOCCode": "9471",
        "JobTitle": "machine marker"
    },
    {
        "value": 24479,
        "NOCCode": "9471",
        "JobTitle": "machine printer, advertising signs"
    },
    {
        "value": 24480,
        "NOCCode": "9471",
        "JobTitle": "machine printer, signs"
    },
    {
        "value": 24481,
        "NOCCode": "9471",
        "JobTitle": "machine shotgun-shell printer"
    },
    {
        "value": 24482,
        "NOCCode": "9471",
        "JobTitle": "machine sign writer"
    },
    {
        "value": 24483,
        "NOCCode": "9471",
        "JobTitle": "offline-printing machine operator"
    },
    {
        "value": 24484,
        "NOCCode": "9471",
        "JobTitle": "operator, printing machine – printing"
    },
    {
        "value": 24485,
        "NOCCode": "9471",
        "JobTitle": "operator, transfer press"
    },
    {
        "value": 24486,
        "NOCCode": "9471",
        "JobTitle": "photocopy operator, colour"
    },
    {
        "value": 24487,
        "NOCCode": "9471",
        "JobTitle": "photocopying machine tender"
    },
    {
        "value": 24488,
        "NOCCode": "9471",
        "JobTitle": "plastic printer"
    },
    {
        "value": 24489,
        "NOCCode": "9471",
        "JobTitle": "pochoir silk-screen printing machine operator"
    },
    {
        "value": 24490,
        "NOCCode": "9471",
        "JobTitle": "press operator, transfer"
    },
    {
        "value": 24491,
        "NOCCode": "9471",
        "JobTitle": "printer operator – printing"
    },
    {
        "value": 24492,
        "NOCCode": "9471",
        "JobTitle": "printer, boxes, cases and linings"
    },
    {
        "value": 24493,
        "NOCCode": "9471",
        "JobTitle": "printer-slotter operator"
    },
    {
        "value": 24494,
        "NOCCode": "9471",
        "JobTitle": "printer-slotter tender"
    },
    {
        "value": 24495,
        "NOCCode": "9471",
        "JobTitle": "printing machine operator – printing"
    },
    {
        "value": 24496,
        "NOCCode": "9471",
        "JobTitle": "proofsheet approver – printing"
    },
    {
        "value": 24497,
        "NOCCode": "9471",
        "JobTitle": "quick print machine operator"
    },
    {
        "value": 24498,
        "NOCCode": "9471",
        "JobTitle": "ruling machine operator – printing"
    },
    {
        "value": 24499,
        "NOCCode": "9471",
        "JobTitle": "screen print operator"
    },
    {
        "value": 24500,
        "NOCCode": "9471",
        "JobTitle": "screen printing machine operator – printing"
    },
    {
        "value": 24501,
        "NOCCode": "9471",
        "JobTitle": "shotgun-shell printer, machine"
    },
    {
        "value": 24502,
        "NOCCode": "9471",
        "JobTitle": "shotgun-shell-printing machine tender"
    },
    {
        "value": 24503,
        "NOCCode": "9471",
        "JobTitle": "sign writer, machine"
    },
    {
        "value": 24504,
        "NOCCode": "9471",
        "JobTitle": "silk-screen printing machine operator"
    },
    {
        "value": 24505,
        "NOCCode": "9471",
        "JobTitle": "silk-screen tender – printing"
    },
    {
        "value": 24506,
        "NOCCode": "9471",
        "JobTitle": "silk-screening machine operator"
    },
    {
        "value": 24507,
        "NOCCode": "9471",
        "JobTitle": "steel die printer"
    },
    {
        "value": 24508,
        "NOCCode": "9471",
        "JobTitle": "stencil silk-screen printing machine operator"
    },
    {
        "value": 24509,
        "NOCCode": "9471",
        "JobTitle": "tabulating cards printer"
    },
    {
        "value": 24510,
        "NOCCode": "9471",
        "JobTitle": "tag-printing machine tender"
    },
    {
        "value": 24511,
        "NOCCode": "9471",
        "JobTitle": "tape rule printer"
    },
    {
        "value": 24512,
        "NOCCode": "9471",
        "JobTitle": "transfer machine tender – printing"
    },
    {
        "value": 24513,
        "NOCCode": "9471",
        "JobTitle": "transfer press operator"
    },
    {
        "value": 24514,
        "NOCCode": "9471",
        "JobTitle": "wallpaper printer"
    },
    {
        "value": 24515,
        "NOCCode": "9471",
        "JobTitle": "wallpaper sample sheet cutter-marker"
    },
    {
        "value": 24516,
        "NOCCode": "9471",
        "JobTitle": "wallpaper sample sheet marker"
    },
    {
        "value": 24517,
        "NOCCode": "9472",
        "JobTitle": "alignment examiner – printing"
    },
    {
        "value": 24518,
        "NOCCode": "9472",
        "JobTitle": "assembler, flexographic printing cylinders"
    },
    {
        "value": 24519,
        "NOCCode": "9472",
        "JobTitle": "assistant newspaper make-up man/woman"
    },
    {
        "value": 24520,
        "NOCCode": "9472",
        "JobTitle": "automated letterer-engraver"
    },
    {
        "value": 24521,
        "NOCCode": "9472",
        "JobTitle": "bright type processor"
    },
    {
        "value": 24522,
        "NOCCode": "9472",
        "JobTitle": "camera operator – graphic arts"
    },
    {
        "value": 24523,
        "NOCCode": "9472",
        "JobTitle": "camera operator – printing"
    },
    {
        "value": 24524,
        "NOCCode": "9472",
        "JobTitle": "camera operator, colour separation – printing"
    },
    {
        "value": 24525,
        "NOCCode": "9472",
        "JobTitle": "camera operator, photogravure – printing"
    },
    {
        "value": 24526,
        "NOCCode": "9472",
        "JobTitle": "carbon tissue cylinder preparer – printing"
    },
    {
        "value": 24527,
        "NOCCode": "9472",
        "JobTitle": "clamper – camera and platemaking"
    },
    {
        "value": 24528,
        "NOCCode": "9472",
        "JobTitle": "colour correction scanning machine operator"
    },
    {
        "value": 24529,
        "NOCCode": "9472",
        "JobTitle": "colour edition preparer – printing"
    },
    {
        "value": 24530,
        "NOCCode": "9472",
        "JobTitle": "colour printer-technician – photographic processing"
    },
    {
        "value": 24531,
        "NOCCode": "9472",
        "JobTitle": "colour scanner operator – printing"
    },
    {
        "value": 24532,
        "NOCCode": "9472",
        "JobTitle": "colour separation assistant – scanning"
    },
    {
        "value": 24533,
        "NOCCode": "9472",
        "JobTitle": "colour separation camera operator"
    },
    {
        "value": 24534,
        "NOCCode": "9472",
        "JobTitle": "colour separation camera operator – printing"
    },
    {
        "value": 24535,
        "NOCCode": "9472",
        "JobTitle": "colour separation electronic scanning machine operator"
    },
    {
        "value": 24536,
        "NOCCode": "9472",
        "JobTitle": "colour separation operator"
    },
    {
        "value": 24537,
        "NOCCode": "9472",
        "JobTitle": "colour-editing terminal operator – printing"
    },
    {
        "value": 24538,
        "NOCCode": "9472",
        "JobTitle": "computer-assisted letterer-engraver"
    },
    {
        "value": 24539,
        "NOCCode": "9472",
        "JobTitle": "contact printer"
    },
    {
        "value": 24540,
        "NOCCode": "9472",
        "JobTitle": "copper engraver – camera and platemaking"
    },
    {
        "value": 24541,
        "NOCCode": "9472",
        "JobTitle": "copper photoengraver"
    },
    {
        "value": 24542,
        "NOCCode": "9472",
        "JobTitle": "copperplate photoengraver"
    },
    {
        "value": 24543,
        "NOCCode": "9472",
        "JobTitle": "cylinder engraver, printing"
    },
    {
        "value": 24544,
        "NOCCode": "9472",
        "JobTitle": "cylinder painter – printing"
    },
    {
        "value": 24545,
        "NOCCode": "9472",
        "JobTitle": "cylinder preparer – printing"
    },
    {
        "value": 24546,
        "NOCCode": "9472",
        "JobTitle": "cylinder preparer, carbon tissue – printing"
    },
    {
        "value": 24547,
        "NOCCode": "9472",
        "JobTitle": "cylinder-impresser operator"
    },
    {
        "value": 24548,
        "NOCCode": "9472",
        "JobTitle": "digital printing technician"
    },
    {
        "value": 24549,
        "NOCCode": "9472",
        "JobTitle": "dot etcher"
    },
    {
        "value": 24550,
        "NOCCode": "9472",
        "JobTitle": "dot etcher – printing"
    },
    {
        "value": 24551,
        "NOCCode": "9472",
        "JobTitle": "electronic engraver"
    },
    {
        "value": 24552,
        "NOCCode": "9472",
        "JobTitle": "electronic prepress technician"
    },
    {
        "value": 24553,
        "NOCCode": "9472",
        "JobTitle": "electronic scanning machine operator, colour separation"
    },
    {
        "value": 24554,
        "NOCCode": "9472",
        "JobTitle": "electroplating tank tender"
    },
    {
        "value": 24555,
        "NOCCode": "9472",
        "JobTitle": "electrostatic camera operator"
    },
    {
        "value": 24556,
        "NOCCode": "9472",
        "JobTitle": "electrotyper – printing"
    },
    {
        "value": 24557,
        "NOCCode": "9472",
        "JobTitle": "electrotyper and platemaker"
    },
    {
        "value": 24558,
        "NOCCode": "9472",
        "JobTitle": "engraver, pictures – printing"
    },
    {
        "value": 24559,
        "NOCCode": "9472",
        "JobTitle": "engraver, printing cylinders"
    },
    {
        "value": 24560,
        "NOCCode": "9472",
        "JobTitle": "engraver, printing plates"
    },
    {
        "value": 24561,
        "NOCCode": "9472",
        "JobTitle": "engraver, templates"
    },
    {
        "value": 24562,
        "NOCCode": "9472",
        "JobTitle": "engraver-letterer"
    },
    {
        "value": 24563,
        "NOCCode": "9472",
        "JobTitle": "engraver-transferrer"
    },
    {
        "value": 24564,
        "NOCCode": "9472",
        "JobTitle": "etcher – printing"
    },
    {
        "value": 24565,
        "NOCCode": "9472",
        "JobTitle": "etcher, photoengraving – printing"
    },
    {
        "value": 24566,
        "NOCCode": "9472",
        "JobTitle": "etcher, platemaking"
    },
    {
        "value": 24567,
        "NOCCode": "9472",
        "JobTitle": "etching corrector"
    },
    {
        "value": 24568,
        "NOCCode": "9472",
        "JobTitle": "file preparation operator"
    },
    {
        "value": 24569,
        "NOCCode": "9472",
        "JobTitle": "film assembler"
    },
    {
        "value": 24570,
        "NOCCode": "9472",
        "JobTitle": "film sizer"
    },
    {
        "value": 24571,
        "NOCCode": "9472",
        "JobTitle": "film stripper"
    },
    {
        "value": 24572,
        "NOCCode": "9472",
        "JobTitle": "film stripper and layout specialist – printing"
    },
    {
        "value": 24573,
        "NOCCode": "9472",
        "JobTitle": "film stripper specialist – printing"
    },
    {
        "value": 24574,
        "NOCCode": "9472",
        "JobTitle": "film stripper-assembler"
    },
    {
        "value": 24575,
        "NOCCode": "9472",
        "JobTitle": "film stripper-assembler – printing"
    },
    {
        "value": 24576,
        "NOCCode": "9472",
        "JobTitle": "flexible plate engraver"
    },
    {
        "value": 24577,
        "NOCCode": "9472",
        "JobTitle": "flexible printing plate mounter"
    },
    {
        "value": 24578,
        "NOCCode": "9472",
        "JobTitle": "flexible-plate hand engraver"
    },
    {
        "value": 24579,
        "NOCCode": "9472",
        "JobTitle": "flexographic mounter-proofer"
    },
    {
        "value": 24580,
        "NOCCode": "9472",
        "JobTitle": "flexographic printing cylinder assembler"
    },
    {
        "value": 24581,
        "NOCCode": "9472",
        "JobTitle": "graphic arts camera operator"
    },
    {
        "value": 24582,
        "NOCCode": "9472",
        "JobTitle": "gravure printer – prepress"
    },
    {
        "value": 24583,
        "NOCCode": "9472",
        "JobTitle": "halftone camera operator – printing"
    },
    {
        "value": 24584,
        "NOCCode": "9472",
        "JobTitle": "image processing operator"
    },
    {
        "value": 24585,
        "NOCCode": "9472",
        "JobTitle": "image reproduction machine operator"
    },
    {
        "value": 24586,
        "NOCCode": "9472",
        "JobTitle": "image setter operator"
    },
    {
        "value": 24587,
        "NOCCode": "9472",
        "JobTitle": "image-processing system operator"
    },
    {
        "value": 24588,
        "NOCCode": "9472",
        "JobTitle": "laser beam colour scanner operator"
    },
    {
        "value": 24589,
        "NOCCode": "9472",
        "JobTitle": "lineup examiner – printing"
    },
    {
        "value": 24590,
        "NOCCode": "9472",
        "JobTitle": "lithographic plate preparer"
    },
    {
        "value": 24591,
        "NOCCode": "9472",
        "JobTitle": "lithographic platemaker"
    },
    {
        "value": 24592,
        "NOCCode": "9472",
        "JobTitle": "monochrome plate film stripper"
    },
    {
        "value": 24593,
        "NOCCode": "9472",
        "JobTitle": "negative merger"
    },
    {
        "value": 24594,
        "NOCCode": "9472",
        "JobTitle": "negative spotter – printing"
    },
    {
        "value": 24595,
        "NOCCode": "9472",
        "JobTitle": "offset plate preparer"
    },
    {
        "value": 24596,
        "NOCCode": "9472",
        "JobTitle": "offset platemaker"
    },
    {
        "value": 24597,
        "NOCCode": "9472",
        "JobTitle": "offset printing plate preparer"
    },
    {
        "value": 24598,
        "NOCCode": "9472",
        "JobTitle": "opticopy keypunch operator – printing"
    },
    {
        "value": 24599,
        "NOCCode": "9472",
        "JobTitle": "opticopy machine operator – printing"
    },
    {
        "value": 24600,
        "NOCCode": "9472",
        "JobTitle": "opticopy offline programmer – printing"
    },
    {
        "value": 24601,
        "NOCCode": "9472",
        "JobTitle": "opticopy program tape preparer – printing"
    },
    {
        "value": 24602,
        "NOCCode": "9472",
        "JobTitle": "pantograph setter"
    },
    {
        "value": 24603,
        "NOCCode": "9472",
        "JobTitle": "pantographer – printing"
    },
    {
        "value": 24604,
        "NOCCode": "9472",
        "JobTitle": "paper-plate maker"
    },
    {
        "value": 24605,
        "NOCCode": "9472",
        "JobTitle": "photoengraver – printing"
    },
    {
        "value": 24606,
        "NOCCode": "9472",
        "JobTitle": "photoengraving etcher – printing"
    },
    {
        "value": 24607,
        "NOCCode": "9472",
        "JobTitle": "photoengraving finisher"
    },
    {
        "value": 24608,
        "NOCCode": "9472",
        "JobTitle": "photoengraving platemaker"
    },
    {
        "value": 24609,
        "NOCCode": "9472",
        "JobTitle": "photoengraving retoucher – printing"
    },
    {
        "value": 24610,
        "NOCCode": "9472",
        "JobTitle": "photogravure camera operator – printing"
    },
    {
        "value": 24611,
        "NOCCode": "9472",
        "JobTitle": "photopolymer platemaker"
    },
    {
        "value": 24612,
        "NOCCode": "9472",
        "JobTitle": "picture engraver – printing"
    },
    {
        "value": 24613,
        "NOCCode": "9472",
        "JobTitle": "plate finisher – printing"
    },
    {
        "value": 24614,
        "NOCCode": "9472",
        "JobTitle": "plate grainer – printing"
    },
    {
        "value": 24615,
        "NOCCode": "9472",
        "JobTitle": "plate processor – printing"
    },
    {
        "value": 24616,
        "NOCCode": "9472",
        "JobTitle": "plate processor operator – printing"
    },
    {
        "value": 24617,
        "NOCCode": "9472",
        "JobTitle": "plate-graining machine tender"
    },
    {
        "value": 24618,
        "NOCCode": "9472",
        "JobTitle": "platemaker"
    },
    {
        "value": 24619,
        "NOCCode": "9472",
        "JobTitle": "platemaker – printing"
    },
    {
        "value": 24620,
        "NOCCode": "9472",
        "JobTitle": "platemaking etcher"
    },
    {
        "value": 24621,
        "NOCCode": "9472",
        "JobTitle": "pre-flight operator – printing"
    },
    {
        "value": 24622,
        "NOCCode": "9472",
        "JobTitle": "prepress technician"
    },
    {
        "value": 24623,
        "NOCCode": "9472",
        "JobTitle": "preprint-coating machine tender"
    },
    {
        "value": 24624,
        "NOCCode": "9472",
        "JobTitle": "print roller repairer"
    },
    {
        "value": 24625,
        "NOCCode": "9472",
        "JobTitle": "print transferrer – printing"
    },
    {
        "value": 24626,
        "NOCCode": "9472",
        "JobTitle": "printing cylinder engraver"
    },
    {
        "value": 24627,
        "NOCCode": "9472",
        "JobTitle": "printing plate engraver"
    },
    {
        "value": 24628,
        "NOCCode": "9472",
        "JobTitle": "printing plate moulder"
    },
    {
        "value": 24629,
        "NOCCode": "9472",
        "JobTitle": "printing plate presensitizer tender"
    },
    {
        "value": 24630,
        "NOCCode": "9472",
        "JobTitle": "process camera operator"
    },
    {
        "value": 24631,
        "NOCCode": "9472",
        "JobTitle": "processor – printing"
    },
    {
        "value": 24632,
        "NOCCode": "9472",
        "JobTitle": "proofmaker"
    },
    {
        "value": 24633,
        "NOCCode": "9472",
        "JobTitle": "proofmaker – printing"
    },
    {
        "value": 24634,
        "NOCCode": "9472",
        "JobTitle": "retoucher, photoengraving – printing"
    },
    {
        "value": 24635,
        "NOCCode": "9472",
        "JobTitle": "roller engraver – printing"
    },
    {
        "value": 24636,
        "NOCCode": "9472",
        "JobTitle": "rotogravure cylinder technician"
    },
    {
        "value": 24637,
        "NOCCode": "9472",
        "JobTitle": "rotogravure press technician"
    },
    {
        "value": 24638,
        "NOCCode": "9472",
        "JobTitle": "scanner operator – printing"
    },
    {
        "value": 24639,
        "NOCCode": "9472",
        "JobTitle": "scanning machine operator, colour corrections"
    },
    {
        "value": 24640,
        "NOCCode": "9472",
        "JobTitle": "screenmaker"
    },
    {
        "value": 24641,
        "NOCCode": "9472",
        "JobTitle": "siderographer – printing"
    },
    {
        "value": 24642,
        "NOCCode": "9472",
        "JobTitle": "silkscreen assembler – printing"
    },
    {
        "value": 24643,
        "NOCCode": "9472",
        "JobTitle": "stager – printing"
    },
    {
        "value": 24644,
        "NOCCode": "9472",
        "JobTitle": "stencil preparer"
    },
    {
        "value": 24645,
        "NOCCode": "9472",
        "JobTitle": "step and repeat camera operator"
    },
    {
        "value": 24646,
        "NOCCode": "9472",
        "JobTitle": "stereotyper"
    },
    {
        "value": 24647,
        "NOCCode": "9472",
        "JobTitle": "stripper – printing"
    },
    {
        "value": 24648,
        "NOCCode": "9472",
        "JobTitle": "stripper-assembler, film – printing"
    },
    {
        "value": 24649,
        "NOCCode": "9472",
        "JobTitle": "stripper-image assembler – printing"
    },
    {
        "value": 24650,
        "NOCCode": "9472",
        "JobTitle": "stripper-layout specialist – printing"
    },
    {
        "value": 24651,
        "NOCCode": "9472",
        "JobTitle": "studio-image-processing system operator"
    },
    {
        "value": 24652,
        "NOCCode": "9472",
        "JobTitle": "template engraver"
    },
    {
        "value": 24653,
        "NOCCode": "9472",
        "JobTitle": "tracer-transferrer"
    },
    {
        "value": 24654,
        "NOCCode": "9472",
        "JobTitle": "transferrer"
    },
    {
        "value": 24655,
        "NOCCode": "9473",
        "JobTitle": "assistant binder"
    },
    {
        "value": 24656,
        "NOCCode": "9473",
        "JobTitle": "assistant bookbinder"
    },
    {
        "value": 24657,
        "NOCCode": "9473",
        "JobTitle": "binder – printing"
    },
    {
        "value": 24658,
        "NOCCode": "9473",
        "JobTitle": "binder and finisher"
    },
    {
        "value": 24659,
        "NOCCode": "9473",
        "JobTitle": "binder and finisher – printing"
    },
    {
        "value": 24660,
        "NOCCode": "9473",
        "JobTitle": "binder assistant"
    },
    {
        "value": 24661,
        "NOCCode": "9473",
        "JobTitle": "binder, machine – printing"
    },
    {
        "value": 24662,
        "NOCCode": "9473",
        "JobTitle": "bindery machine operator – printing"
    },
    {
        "value": 24663,
        "NOCCode": "9473",
        "JobTitle": "bindery operator"
    },
    {
        "value": 24664,
        "NOCCode": "9473",
        "JobTitle": "bindery worker – printing"
    },
    {
        "value": 24665,
        "NOCCode": "9473",
        "JobTitle": "binding and finishing machine operator"
    },
    {
        "value": 24666,
        "NOCCode": "9473",
        "JobTitle": "blocking press operator – printing"
    },
    {
        "value": 24667,
        "NOCCode": "9473",
        "JobTitle": "blocking press stamper – printing"
    },
    {
        "value": 24668,
        "NOCCode": "9473",
        "JobTitle": "book finisher – printing"
    },
    {
        "value": 24669,
        "NOCCode": "9473",
        "JobTitle": "book trimmer"
    },
    {
        "value": 24670,
        "NOCCode": "9473",
        "JobTitle": "bookbinder – printing"
    },
    {
        "value": 24671,
        "NOCCode": "9473",
        "JobTitle": "bookbinder assistant"
    },
    {
        "value": 24672,
        "NOCCode": "9473",
        "JobTitle": "bookbinder, machine"
    },
    {
        "value": 24673,
        "NOCCode": "9473",
        "JobTitle": "bookbinding machine operator"
    },
    {
        "value": 24674,
        "NOCCode": "9473",
        "JobTitle": "book-cutting machine operator"
    },
    {
        "value": 24675,
        "NOCCode": "9473",
        "JobTitle": "book-sewing machine operator – printing"
    },
    {
        "value": 24676,
        "NOCCode": "9473",
        "JobTitle": "bronzer – printing"
    },
    {
        "value": 24677,
        "NOCCode": "9473",
        "JobTitle": "bronzer, machine – printing"
    },
    {
        "value": 24678,
        "NOCCode": "9473",
        "JobTitle": "bronzing machine tender – printing"
    },
    {
        "value": 24679,
        "NOCCode": "9473",
        "JobTitle": "casing-in machine set-up operator – printing"
    },
    {
        "value": 24680,
        "NOCCode": "9473",
        "JobTitle": "casing-in machine tender – printing"
    },
    {
        "value": 24681,
        "NOCCode": "9473",
        "JobTitle": "Cerlox binder operator"
    },
    {
        "value": 24682,
        "NOCCode": "9473",
        "JobTitle": "Cerlox binder operator – printing"
    },
    {
        "value": 24683,
        "NOCCode": "9473",
        "JobTitle": "collating machine operator – printing"
    },
    {
        "value": 24684,
        "NOCCode": "9473",
        "JobTitle": "collator and gluer, machine – printing"
    },
    {
        "value": 24685,
        "NOCCode": "9473",
        "JobTitle": "continuous glue machine operator – printing"
    },
    {
        "value": 24686,
        "NOCCode": "9473",
        "JobTitle": "covering machine operator – printing"
    },
    {
        "value": 24687,
        "NOCCode": "9473",
        "JobTitle": "credit card die-cutter operator – printing"
    },
    {
        "value": 24688,
        "NOCCode": "9473",
        "JobTitle": "cutter operator – printing"
    },
    {
        "value": 24689,
        "NOCCode": "9473",
        "JobTitle": "cutter, guillotine – printing"
    },
    {
        "value": 24690,
        "NOCCode": "9473",
        "JobTitle": "cutter, machine – printing"
    },
    {
        "value": 24691,
        "NOCCode": "9473",
        "JobTitle": "cutting machine operator – printing"
    },
    {
        "value": 24692,
        "NOCCode": "9473",
        "JobTitle": "cutting machine operator, books"
    },
    {
        "value": 24693,
        "NOCCode": "9473",
        "JobTitle": "design and lettering stamper – printing"
    },
    {
        "value": 24694,
        "NOCCode": "9473",
        "JobTitle": "die-cutting or die-numbering press operator – printing"
    },
    {
        "value": 24695,
        "NOCCode": "9473",
        "JobTitle": "die-cutting platen press operator – printing"
    },
    {
        "value": 24696,
        "NOCCode": "9473",
        "JobTitle": "embossing press operator – printing"
    },
    {
        "value": 24697,
        "NOCCode": "9473",
        "JobTitle": "embossing press tender – printing"
    },
    {
        "value": 24698,
        "NOCCode": "9473",
        "JobTitle": "embossing/encoding machine tender – printing"
    },
    {
        "value": 24699,
        "NOCCode": "9473",
        "JobTitle": "finishing machine operator – printing"
    },
    {
        "value": 24700,
        "NOCCode": "9473",
        "JobTitle": "foil stamper – printing"
    },
    {
        "value": 24701,
        "NOCCode": "9473",
        "JobTitle": "foil-stamping platen press operator"
    },
    {
        "value": 24702,
        "NOCCode": "9473",
        "JobTitle": "folder operator – printing"
    },
    {
        "value": 24703,
        "NOCCode": "9473",
        "JobTitle": "folder, machine – printing"
    },
    {
        "value": 24704,
        "NOCCode": "9473",
        "JobTitle": "folding machine operator – printing"
    },
    {
        "value": 24705,
        "NOCCode": "9473",
        "JobTitle": "gatherer and gluer, machine – printing"
    },
    {
        "value": 24706,
        "NOCCode": "9473",
        "JobTitle": "gathering machine operator – printing"
    },
    {
        "value": 24707,
        "NOCCode": "9473",
        "JobTitle": "gathering machine set-up operator – printing"
    },
    {
        "value": 24708,
        "NOCCode": "9473",
        "JobTitle": "gluer, headbands and liners – printing"
    },
    {
        "value": 24709,
        "NOCCode": "9473",
        "JobTitle": "gluing and folding machine operator – printing"
    },
    {
        "value": 24710,
        "NOCCode": "9473",
        "JobTitle": "gold leaf stamper"
    },
    {
        "value": 24711,
        "NOCCode": "9473",
        "JobTitle": "gold leaf stamper – printing"
    },
    {
        "value": 24712,
        "NOCCode": "9473",
        "JobTitle": "guillotine cutter – printing"
    },
    {
        "value": 24713,
        "NOCCode": "9473",
        "JobTitle": "guillotine operator – printing"
    },
    {
        "value": 24714,
        "NOCCode": "9473",
        "JobTitle": "hand stitcher – binding"
    },
    {
        "value": 24715,
        "NOCCode": "9473",
        "JobTitle": "headband and liner gluer – printing"
    },
    {
        "value": 24716,
        "NOCCode": "9473",
        "JobTitle": "hologram-imprinting machine tender"
    },
    {
        "value": 24717,
        "NOCCode": "9473",
        "JobTitle": "industrial binder – printing"
    },
    {
        "value": 24718,
        "NOCCode": "9473",
        "JobTitle": "inserting machine operator – printing"
    },
    {
        "value": 24719,
        "NOCCode": "9473",
        "JobTitle": "laminating machine operator"
    },
    {
        "value": 24720,
        "NOCCode": "9473",
        "JobTitle": "laminating machine operator – printing"
    },
    {
        "value": 24721,
        "NOCCode": "9473",
        "JobTitle": "machine binder – printing"
    },
    {
        "value": 24722,
        "NOCCode": "9473",
        "JobTitle": "machine bronzer – printing"
    },
    {
        "value": 24723,
        "NOCCode": "9473",
        "JobTitle": "machine collator and gluer – printing"
    },
    {
        "value": 24724,
        "NOCCode": "9473",
        "JobTitle": "machine cutter – printing"
    },
    {
        "value": 24725,
        "NOCCode": "9473",
        "JobTitle": "machine folder – printing"
    },
    {
        "value": 24726,
        "NOCCode": "9473",
        "JobTitle": "machine gatherer and gluer – printing"
    },
    {
        "value": 24727,
        "NOCCode": "9473",
        "JobTitle": "machine operator, binding and finishing"
    },
    {
        "value": 24728,
        "NOCCode": "9473",
        "JobTitle": "machine side stitcher – printing"
    },
    {
        "value": 24729,
        "NOCCode": "9473",
        "JobTitle": "machine stitcher – binding"
    },
    {
        "value": 24730,
        "NOCCode": "9473",
        "JobTitle": "numbering machine operator – printing"
    },
    {
        "value": 24731,
        "NOCCode": "9473",
        "JobTitle": "operator, bindery"
    },
    {
        "value": 24732,
        "NOCCode": "9473",
        "JobTitle": "operator, bindery machine – printing"
    },
    {
        "value": 24733,
        "NOCCode": "9473",
        "JobTitle": "operator, binding and finishing machine"
    },
    {
        "value": 24734,
        "NOCCode": "9473",
        "JobTitle": "operator, bookbinding machine"
    },
    {
        "value": 24735,
        "NOCCode": "9473",
        "JobTitle": "operator, book-cutting machine"
    },
    {
        "value": 24736,
        "NOCCode": "9473",
        "JobTitle": "operator, book-sewing machine – printing"
    },
    {
        "value": 24737,
        "NOCCode": "9473",
        "JobTitle": "operator, Cerlox binder – printing"
    },
    {
        "value": 24738,
        "NOCCode": "9473",
        "JobTitle": "operator, collating machine – printing"
    },
    {
        "value": 24739,
        "NOCCode": "9473",
        "JobTitle": "operator, continuous glue machine – printing"
    },
    {
        "value": 24740,
        "NOCCode": "9473",
        "JobTitle": "operator, covering machine – printing"
    },
    {
        "value": 24741,
        "NOCCode": "9473",
        "JobTitle": "operator, credit card die cutter – printing"
    },
    {
        "value": 24742,
        "NOCCode": "9473",
        "JobTitle": "operator, cutter – printing"
    },
    {
        "value": 24743,
        "NOCCode": "9473",
        "JobTitle": "operator, cutting machine – printing"
    },
    {
        "value": 24744,
        "NOCCode": "9473",
        "JobTitle": "operator, die-cutting or die-numbering press – printing"
    },
    {
        "value": 24745,
        "NOCCode": "9473",
        "JobTitle": "operator, die-cutting platen press"
    },
    {
        "value": 24746,
        "NOCCode": "9473",
        "JobTitle": "operator, embossing press – printing"
    },
    {
        "value": 24747,
        "NOCCode": "9473",
        "JobTitle": "operator, finishing machine – printing"
    },
    {
        "value": 24748,
        "NOCCode": "9473",
        "JobTitle": "operator, foil-stamping platen press"
    },
    {
        "value": 24749,
        "NOCCode": "9473",
        "JobTitle": "operator, folder – printing"
    },
    {
        "value": 24750,
        "NOCCode": "9473",
        "JobTitle": "operator, folding machine – printing"
    },
    {
        "value": 24751,
        "NOCCode": "9473",
        "JobTitle": "operator, gathering machine – printing"
    },
    {
        "value": 24752,
        "NOCCode": "9473",
        "JobTitle": "operator, gluing and folding machine – printing"
    },
    {
        "value": 24753,
        "NOCCode": "9473",
        "JobTitle": "operator, guillotine – printing"
    },
    {
        "value": 24754,
        "NOCCode": "9473",
        "JobTitle": "operator, inserting machine – printing"
    },
    {
        "value": 24755,
        "NOCCode": "9473",
        "JobTitle": "operator, laminating machine – printing"
    },
    {
        "value": 24756,
        "NOCCode": "9473",
        "JobTitle": "operator, numbering machine – printing"
    },
    {
        "value": 24757,
        "NOCCode": "9473",
        "JobTitle": "operator, perfect binder – printing"
    },
    {
        "value": 24758,
        "NOCCode": "9473",
        "JobTitle": "operator, perforator – printing"
    },
    {
        "value": 24759,
        "NOCCode": "9473",
        "JobTitle": "operator, rounding and backing machine – printing"
    },
    {
        "value": 24760,
        "NOCCode": "9473",
        "JobTitle": "operator, saddle stitching machine – printing"
    },
    {
        "value": 24761,
        "NOCCode": "9473",
        "JobTitle": "operator, side stitching machine – printing"
    },
    {
        "value": 24762,
        "NOCCode": "9473",
        "JobTitle": "operator, stitcher – printing"
    },
    {
        "value": 24763,
        "NOCCode": "9473",
        "JobTitle": "operator, stitcher-trimmer – printing"
    },
    {
        "value": 24764,
        "NOCCode": "9473",
        "JobTitle": "perfect-binder operator – printing"
    },
    {
        "value": 24765,
        "NOCCode": "9473",
        "JobTitle": "perfect-binder set-up operator – printing"
    },
    {
        "value": 24766,
        "NOCCode": "9473",
        "JobTitle": "perforating machine operator – printing"
    },
    {
        "value": 24767,
        "NOCCode": "9473",
        "JobTitle": "perforating machine tender – printing"
    },
    {
        "value": 24768,
        "NOCCode": "9473",
        "JobTitle": "perforator operator – printing"
    },
    {
        "value": 24769,
        "NOCCode": "9473",
        "JobTitle": "perforator tender – printing"
    },
    {
        "value": 24770,
        "NOCCode": "9473",
        "JobTitle": "platen press operator, die cutting"
    },
    {
        "value": 24771,
        "NOCCode": "9473",
        "JobTitle": "platen press operator, foil stamping"
    },
    {
        "value": 24772,
        "NOCCode": "9473",
        "JobTitle": "press operator, die-cutting or die-numbering – printing"
    },
    {
        "value": 24773,
        "NOCCode": "9473",
        "JobTitle": "press operator, embossing – printing"
    },
    {
        "value": 24774,
        "NOCCode": "9473",
        "JobTitle": "rounding and backing machine operator – printing"
    },
    {
        "value": 24775,
        "NOCCode": "9473",
        "JobTitle": "saddle stitching machine operator – printing"
    },
    {
        "value": 24776,
        "NOCCode": "9473",
        "JobTitle": "saddle stitching machine set-up operator – printing"
    },
    {
        "value": 24777,
        "NOCCode": "9473",
        "JobTitle": "set-up operator, casing-in machine – printing"
    },
    {
        "value": 24778,
        "NOCCode": "9473",
        "JobTitle": "set-up operator, gathering machine – printing"
    },
    {
        "value": 24779,
        "NOCCode": "9473",
        "JobTitle": "set-up operator, perfect binder – printing"
    },
    {
        "value": 24780,
        "NOCCode": "9473",
        "JobTitle": "set-up operator, saddle stitching machine – printing"
    },
    {
        "value": 24781,
        "NOCCode": "9473",
        "JobTitle": "set-up operator, stitching machine – printing"
    },
    {
        "value": 24782,
        "NOCCode": "9473",
        "JobTitle": "side stitcher, machine"
    },
    {
        "value": 24783,
        "NOCCode": "9473",
        "JobTitle": "side stitching machine operator – printing"
    },
    {
        "value": 24784,
        "NOCCode": "9473",
        "JobTitle": "spiral binding machine operator – printing"
    },
    {
        "value": 24785,
        "NOCCode": "9473",
        "JobTitle": "spiral binding worker – printing"
    },
    {
        "value": 24786,
        "NOCCode": "9473",
        "JobTitle": "stamper, blocking press – printing"
    },
    {
        "value": 24787,
        "NOCCode": "9473",
        "JobTitle": "stamper, design and lettering – printing"
    },
    {
        "value": 24788,
        "NOCCode": "9473",
        "JobTitle": "stamper, gold-leaf – printing"
    },
    {
        "value": 24789,
        "NOCCode": "9473",
        "JobTitle": "stamping press operator – printing"
    },
    {
        "value": 24790,
        "NOCCode": "9473",
        "JobTitle": "stitcher operator – printing"
    },
    {
        "value": 24791,
        "NOCCode": "9473",
        "JobTitle": "stitcher set-up operator – printing"
    },
    {
        "value": 24792,
        "NOCCode": "9473",
        "JobTitle": "stitcher, hand – binding"
    },
    {
        "value": 24793,
        "NOCCode": "9473",
        "JobTitle": "stitcher, machine – binding"
    },
    {
        "value": 24794,
        "NOCCode": "9473",
        "JobTitle": "stitcher-trimmer operator – printing"
    },
    {
        "value": 24795,
        "NOCCode": "9473",
        "JobTitle": "stitching machine operator – printing"
    },
    {
        "value": 24796,
        "NOCCode": "9473",
        "JobTitle": "stitching machine set-up operator – printing"
    },
    {
        "value": 24797,
        "NOCCode": "9473",
        "JobTitle": "tender, bronzing machine – printing"
    },
    {
        "value": 24798,
        "NOCCode": "9473",
        "JobTitle": "tender, casing-in machine – printing"
    },
    {
        "value": 24799,
        "NOCCode": "9473",
        "JobTitle": "tender, embossing press – printing"
    },
    {
        "value": 24800,
        "NOCCode": "9473",
        "JobTitle": "tender, embossing/encoding machine"
    },
    {
        "value": 24801,
        "NOCCode": "9473",
        "JobTitle": "tender, hologram-imprinting machine"
    },
    {
        "value": 24802,
        "NOCCode": "9473",
        "JobTitle": "tender, perforating machine – printing"
    },
    {
        "value": 24803,
        "NOCCode": "9473",
        "JobTitle": "trimmer, books"
    },
    {
        "value": 24804,
        "NOCCode": "9474",
        "JobTitle": "analyzer, colour films – photographic processing"
    },
    {
        "value": 24805,
        "NOCCode": "9474",
        "JobTitle": "chemical mixer – photographic processing"
    },
    {
        "value": 24806,
        "NOCCode": "9474",
        "JobTitle": "colour film analyzer – photographic processing"
    },
    {
        "value": 24807,
        "NOCCode": "9474",
        "JobTitle": "copy camera operator – photographic processing"
    },
    {
        "value": 24808,
        "NOCCode": "9474",
        "JobTitle": "cutter, negatives – film processing"
    },
    {
        "value": 24809,
        "NOCCode": "9474",
        "JobTitle": "darkroom technician"
    },
    {
        "value": 24810,
        "NOCCode": "9474",
        "JobTitle": "darkroom technician – photographic processing"
    },
    {
        "value": 24811,
        "NOCCode": "9474",
        "JobTitle": "developer, films"
    },
    {
        "value": 24812,
        "NOCCode": "9474",
        "JobTitle": "developer, motion picture film"
    },
    {
        "value": 24813,
        "NOCCode": "9474",
        "JobTitle": "developer, photographs"
    },
    {
        "value": 24814,
        "NOCCode": "9474",
        "JobTitle": "developer, photos"
    },
    {
        "value": 24815,
        "NOCCode": "9474",
        "JobTitle": "developer, prints"
    },
    {
        "value": 24816,
        "NOCCode": "9474",
        "JobTitle": "developer, X-ray films"
    },
    {
        "value": 24817,
        "NOCCode": "9474",
        "JobTitle": "developer, X-ray plates"
    },
    {
        "value": 24818,
        "NOCCode": "9474",
        "JobTitle": "developer-printer machine tender"
    },
    {
        "value": 24819,
        "NOCCode": "9474",
        "JobTitle": "developer-printer, photos"
    },
    {
        "value": 24820,
        "NOCCode": "9474",
        "JobTitle": "developing machine operator – photographic processing"
    },
    {
        "value": 24821,
        "NOCCode": "9474",
        "JobTitle": "enlarger operator – photographic processing"
    },
    {
        "value": 24822,
        "NOCCode": "9474",
        "JobTitle": "enlarger, photographs"
    },
    {
        "value": 24823,
        "NOCCode": "9474",
        "JobTitle": "enlarger, pictures – photographic processing"
    },
    {
        "value": 24824,
        "NOCCode": "9474",
        "JobTitle": "examiner, motion picture film – photographic processing"
    },
    {
        "value": 24825,
        "NOCCode": "9474",
        "JobTitle": "film developer"
    },
    {
        "value": 24826,
        "NOCCode": "9474",
        "JobTitle": "film printing machine operator"
    },
    {
        "value": 24827,
        "NOCCode": "9474",
        "JobTitle": "film processing technician"
    },
    {
        "value": 24828,
        "NOCCode": "9474",
        "JobTitle": "film processor"
    },
    {
        "value": 24829,
        "NOCCode": "9474",
        "JobTitle": "film splicer – photographic processing"
    },
    {
        "value": 24830,
        "NOCCode": "9474",
        "JobTitle": "film-developing machine tender"
    },
    {
        "value": 24831,
        "NOCCode": "9474",
        "JobTitle": "film-printing machine tender"
    },
    {
        "value": 24832,
        "NOCCode": "9474",
        "JobTitle": "film-processing machine operator"
    },
    {
        "value": 24833,
        "NOCCode": "9474",
        "JobTitle": "finisher, photos"
    },
    {
        "value": 24834,
        "NOCCode": "9474",
        "JobTitle": "finisher, prints – photographic processing"
    },
    {
        "value": 24835,
        "NOCCode": "9474",
        "JobTitle": "inspector, photographs – photographic processing"
    },
    {
        "value": 24836,
        "NOCCode": "9474",
        "JobTitle": "inspector, prints – photographic processing"
    },
    {
        "value": 24837,
        "NOCCode": "9474",
        "JobTitle": "maker, slides – photographic processing"
    },
    {
        "value": 24838,
        "NOCCode": "9474",
        "JobTitle": "mixer, chemicals – photographic processing"
    },
    {
        "value": 24839,
        "NOCCode": "9474",
        "JobTitle": "motion picture film developer"
    },
    {
        "value": 24840,
        "NOCCode": "9474",
        "JobTitle": "motion picture film examiner – photographic processing"
    },
    {
        "value": 24841,
        "NOCCode": "9474",
        "JobTitle": "motion picture film printer"
    },
    {
        "value": 24842,
        "NOCCode": "9474",
        "JobTitle": "mounter, photographs"
    },
    {
        "value": 24843,
        "NOCCode": "9474",
        "JobTitle": "negative cutter – film processing"
    },
    {
        "value": 24844,
        "NOCCode": "9474",
        "JobTitle": "negative restorer – photographic processing"
    },
    {
        "value": 24845,
        "NOCCode": "9474",
        "JobTitle": "negative retoucher"
    },
    {
        "value": 24846,
        "NOCCode": "9474",
        "JobTitle": "negative spotter – film processing"
    },
    {
        "value": 24847,
        "NOCCode": "9474",
        "JobTitle": "negative-contact-frame operator"
    },
    {
        "value": 24848,
        "NOCCode": "9474",
        "JobTitle": "operator, copy camera – photographic processing"
    },
    {
        "value": 24849,
        "NOCCode": "9474",
        "JobTitle": "operator, developing machine – photographic processing"
    },
    {
        "value": 24850,
        "NOCCode": "9474",
        "JobTitle": "operator, film-printing machine"
    },
    {
        "value": 24851,
        "NOCCode": "9474",
        "JobTitle": "operator, film-processing machine"
    },
    {
        "value": 24852,
        "NOCCode": "9474",
        "JobTitle": "operator, negative-contact-frame"
    },
    {
        "value": 24853,
        "NOCCode": "9474",
        "JobTitle": "operator, photographic and film processing machine"
    },
    {
        "value": 24854,
        "NOCCode": "9474",
        "JobTitle": "photo developer"
    },
    {
        "value": 24855,
        "NOCCode": "9474",
        "JobTitle": "photo developer-printer"
    },
    {
        "value": 24856,
        "NOCCode": "9474",
        "JobTitle": "photo developing inspector"
    },
    {
        "value": 24857,
        "NOCCode": "9474",
        "JobTitle": "photo lab technician"
    },
    {
        "value": 24858,
        "NOCCode": "9474",
        "JobTitle": "photo processor"
    },
    {
        "value": 24859,
        "NOCCode": "9474",
        "JobTitle": "photo technician"
    },
    {
        "value": 24860,
        "NOCCode": "9474",
        "JobTitle": "photofinisher"
    },
    {
        "value": 24861,
        "NOCCode": "9474",
        "JobTitle": "photograph developer"
    },
    {
        "value": 24862,
        "NOCCode": "9474",
        "JobTitle": "photograph developing inspector"
    },
    {
        "value": 24863,
        "NOCCode": "9474",
        "JobTitle": "photograph enlarger"
    },
    {
        "value": 24864,
        "NOCCode": "9474",
        "JobTitle": "photograph inspector – photographic processing"
    },
    {
        "value": 24865,
        "NOCCode": "9474",
        "JobTitle": "photograph mounter"
    },
    {
        "value": 24866,
        "NOCCode": "9474",
        "JobTitle": "photographic and film-processing machine operator"
    },
    {
        "value": 24867,
        "NOCCode": "9474",
        "JobTitle": "photographic enlarger operator"
    },
    {
        "value": 24868,
        "NOCCode": "9474",
        "JobTitle": "photographic film printer"
    },
    {
        "value": 24869,
        "NOCCode": "9474",
        "JobTitle": "photographic film processor"
    },
    {
        "value": 24870,
        "NOCCode": "9474",
        "JobTitle": "photographic printer"
    },
    {
        "value": 24871,
        "NOCCode": "9474",
        "JobTitle": "photographic processor"
    },
    {
        "value": 24872,
        "NOCCode": "9474",
        "JobTitle": "photographic-film-processing machine operator"
    },
    {
        "value": 24873,
        "NOCCode": "9474",
        "JobTitle": "photographic-processing machine operator"
    },
    {
        "value": 24874,
        "NOCCode": "9474",
        "JobTitle": "picture enlarger – photographic processing"
    },
    {
        "value": 24875,
        "NOCCode": "9474",
        "JobTitle": "print developer"
    },
    {
        "value": 24876,
        "NOCCode": "9474",
        "JobTitle": "print finisher – photographic processing"
    },
    {
        "value": 24877,
        "NOCCode": "9474",
        "JobTitle": "print inspector – photographic processing"
    },
    {
        "value": 24878,
        "NOCCode": "9474",
        "JobTitle": "print retoucher – photographic processing"
    },
    {
        "value": 24879,
        "NOCCode": "9474",
        "JobTitle": "print tender – photographic processing"
    },
    {
        "value": 24880,
        "NOCCode": "9474",
        "JobTitle": "printer, motion picture film"
    },
    {
        "value": 24881,
        "NOCCode": "9474",
        "JobTitle": "printer, photographic films"
    },
    {
        "value": 24882,
        "NOCCode": "9474",
        "JobTitle": "printer, photographs"
    },
    {
        "value": 24883,
        "NOCCode": "9474",
        "JobTitle": "processor, films"
    },
    {
        "value": 24884,
        "NOCCode": "9474",
        "JobTitle": "processor, photographic film"
    },
    {
        "value": 24885,
        "NOCCode": "9474",
        "JobTitle": "quick print machine tender – photographic processing"
    },
    {
        "value": 24886,
        "NOCCode": "9474",
        "JobTitle": "restorer, negatives – photographic processing"
    },
    {
        "value": 24887,
        "NOCCode": "9474",
        "JobTitle": "retoucher, negatives"
    },
    {
        "value": 24888,
        "NOCCode": "9474",
        "JobTitle": "slide maker – photographic processing"
    },
    {
        "value": 24889,
        "NOCCode": "9474",
        "JobTitle": "splicer, films – photographic processing"
    },
    {
        "value": 24890,
        "NOCCode": "9474",
        "JobTitle": "spotter, negatives – film processing"
    },
    {
        "value": 24891,
        "NOCCode": "9474",
        "JobTitle": "technician, darkroom – photographic processing"
    },
    {
        "value": 24892,
        "NOCCode": "9474",
        "JobTitle": "technician, film processing"
    },
    {
        "value": 24893,
        "NOCCode": "9474",
        "JobTitle": "technician, photo"
    },
    {
        "value": 24894,
        "NOCCode": "9474",
        "JobTitle": "technician, photo lab"
    },
    {
        "value": 24895,
        "NOCCode": "9474",
        "JobTitle": "tender, developer-printer machine"
    },
    {
        "value": 24896,
        "NOCCode": "9474",
        "JobTitle": "tender, film-developing machine"
    },
    {
        "value": 24897,
        "NOCCode": "9474",
        "JobTitle": "tender, film-printing machine"
    },
    {
        "value": 24898,
        "NOCCode": "9474",
        "JobTitle": "tender, quick print machine – photographic processing"
    },
    {
        "value": 24899,
        "NOCCode": "9474",
        "JobTitle": "X-ray film developer"
    },
    {
        "value": 24900,
        "NOCCode": "9474",
        "JobTitle": "X-ray plate developer"
    },
    {
        "value": 24901,
        "NOCCode": "9521",
        "JobTitle": "aileron fitter – aircraft assembly"
    },
    {
        "value": 24902,
        "NOCCode": "9521",
        "JobTitle": "aircraft assembler"
    },
    {
        "value": 24903,
        "NOCCode": "9521",
        "JobTitle": "aircraft assembly inspector"
    },
    {
        "value": 24904,
        "NOCCode": "9521",
        "JobTitle": "aircraft assembly rigger"
    },
    {
        "value": 24905,
        "NOCCode": "9521",
        "JobTitle": "aircraft bench assembler"
    },
    {
        "value": 24906,
        "NOCCode": "9521",
        "JobTitle": "aircraft fabrication inspector"
    },
    {
        "value": 24907,
        "NOCCode": "9521",
        "JobTitle": "aircraft final assembly inspector"
    },
    {
        "value": 24908,
        "NOCCode": "9521",
        "JobTitle": "aircraft fitter-assembler – aircraft assembly"
    },
    {
        "value": 24909,
        "NOCCode": "9521",
        "JobTitle": "aircraft inspector, assemblies and installations"
    },
    {
        "value": 24910,
        "NOCCode": "9521",
        "JobTitle": "aircraft inspector, assembly"
    },
    {
        "value": 24911,
        "NOCCode": "9521",
        "JobTitle": "aircraft parts fitter – aircraft assembly"
    },
    {
        "value": 24912,
        "NOCCode": "9521",
        "JobTitle": "aircraft power plant assembler-installer"
    },
    {
        "value": 24913,
        "NOCCode": "9521",
        "JobTitle": "aircraft rigger – aircraft assembly"
    },
    {
        "value": 24914,
        "NOCCode": "9521",
        "JobTitle": "aircraft rigging and controls mechanic – aircraft assembly"
    },
    {
        "value": 24915,
        "NOCCode": "9521",
        "JobTitle": "aircraft sealer – aircraft assembly"
    },
    {
        "value": 24916,
        "NOCCode": "9521",
        "JobTitle": "aircraft structure and surface assembler"
    },
    {
        "value": 24917,
        "NOCCode": "9521",
        "JobTitle": "aircraft structure assembler"
    },
    {
        "value": 24918,
        "NOCCode": "9521",
        "JobTitle": "aircraft tester – aircraft assembly"
    },
    {
        "value": 24919,
        "NOCCode": "9521",
        "JobTitle": "airframe assembler"
    },
    {
        "value": 24920,
        "NOCCode": "9521",
        "JobTitle": "airframe assembler – aircraft assembly"
    },
    {
        "value": 24921,
        "NOCCode": "9521",
        "JobTitle": "airframe fitter – aircraft assembly"
    },
    {
        "value": 24922,
        "NOCCode": "9521",
        "JobTitle": "airframe subassembler"
    },
    {
        "value": 24923,
        "NOCCode": "9521",
        "JobTitle": "airframe unit assembler"
    },
    {
        "value": 24924,
        "NOCCode": "9521",
        "JobTitle": "assembler, aircraft"
    },
    {
        "value": 24925,
        "NOCCode": "9521",
        "JobTitle": "assembler, aircraft structures and surfaces"
    },
    {
        "value": 24926,
        "NOCCode": "9521",
        "JobTitle": "assembler, airframes – aircraft assembly"
    },
    {
        "value": 24927,
        "NOCCode": "9521",
        "JobTitle": "assembler, power plants – aircraft assembly"
    },
    {
        "value": 24928,
        "NOCCode": "9521",
        "JobTitle": "assembly inspector, aircraft"
    },
    {
        "value": 24929,
        "NOCCode": "9521",
        "JobTitle": "bench and structural assembler"
    },
    {
        "value": 24930,
        "NOCCode": "9521",
        "JobTitle": "bench and structural assembler – aircraft assembly"
    },
    {
        "value": 24931,
        "NOCCode": "9521",
        "JobTitle": "bench assembler, aircraft"
    },
    {
        "value": 24932,
        "NOCCode": "9521",
        "JobTitle": "bench fitter – aircraft assembly"
    },
    {
        "value": 24933,
        "NOCCode": "9521",
        "JobTitle": "bench fitter mechanic – aircraft assembly"
    },
    {
        "value": 24934,
        "NOCCode": "9521",
        "JobTitle": "control assembler, aircraft"
    },
    {
        "value": 24935,
        "NOCCode": "9521",
        "JobTitle": "detail assembler – aircraft assembly"
    },
    {
        "value": 24936,
        "NOCCode": "9521",
        "JobTitle": "detail inspector – aircraft assembly"
    },
    {
        "value": 24937,
        "NOCCode": "9521",
        "JobTitle": "development mechanic – aircraft assembly"
    },
    {
        "value": 24938,
        "NOCCode": "9521",
        "JobTitle": "drill and ream mechanic – aircraft assembly"
    },
    {
        "value": 24939,
        "NOCCode": "9521",
        "JobTitle": "fabric installer-repairer – aircraft assembly"
    },
    {
        "value": 24940,
        "NOCCode": "9521",
        "JobTitle": "final-assembly inspector – aircraft assembly"
    },
    {
        "value": 24941,
        "NOCCode": "9521",
        "JobTitle": "final-assembly inspector, aircraft"
    },
    {
        "value": 24942,
        "NOCCode": "9521",
        "JobTitle": "fitter, ailerons – aircraft assembly"
    },
    {
        "value": 24943,
        "NOCCode": "9521",
        "JobTitle": "fitter, bench – aircraft assembly"
    },
    {
        "value": 24944,
        "NOCCode": "9521",
        "JobTitle": "fitter-tester, welded assembly – aircraft assembly"
    },
    {
        "value": 24945,
        "NOCCode": "9521",
        "JobTitle": "fuselage assembler"
    },
    {
        "value": 24946,
        "NOCCode": "9521",
        "JobTitle": "fuselage fitter – aircraft assembly"
    },
    {
        "value": 24947,
        "NOCCode": "9521",
        "JobTitle": "hydraulic installation – aircraft assembly"
    },
    {
        "value": 24948,
        "NOCCode": "9521",
        "JobTitle": "hydraulic system installer – aircraft assembly"
    },
    {
        "value": 24949,
        "NOCCode": "9521",
        "JobTitle": "inspector, aircraft assembly"
    },
    {
        "value": 24950,
        "NOCCode": "9521",
        "JobTitle": "inspector, aircraft fabrication"
    },
    {
        "value": 24951,
        "NOCCode": "9521",
        "JobTitle": "installation mechanic, power plants – aircraft assembly"
    },
    {
        "value": 24952,
        "NOCCode": "9521",
        "JobTitle": "installation, hydraulic – aircraft assembly"
    },
    {
        "value": 24953,
        "NOCCode": "9521",
        "JobTitle": "mechanic, power plant installation – aircraft assembly"
    },
    {
        "value": 24954,
        "NOCCode": "9521",
        "JobTitle": "mechanic, wing marry-up – aircraft assembly"
    },
    {
        "value": 24955,
        "NOCCode": "9521",
        "JobTitle": "mechanic, wing tanks – aircraft assembly"
    },
    {
        "value": 24956,
        "NOCCode": "9521",
        "JobTitle": "power plant assembler – aircraft assembly"
    },
    {
        "value": 24957,
        "NOCCode": "9521",
        "JobTitle": "power plant installation mechanic – aircraft assembly"
    },
    {
        "value": 24958,
        "NOCCode": "9521",
        "JobTitle": "power plant mechanic – aircraft assembly"
    },
    {
        "value": 24959,
        "NOCCode": "9521",
        "JobTitle": "pressure compartment sealer and tester – aircraft assembly"
    },
    {
        "value": 24960,
        "NOCCode": "9521",
        "JobTitle": "propeller mechanic – aircraft assembly"
    },
    {
        "value": 24961,
        "NOCCode": "9521",
        "JobTitle": "rigger – aircraft assembly"
    },
    {
        "value": 24962,
        "NOCCode": "9521",
        "JobTitle": "rigger mechanic, aircraft controls"
    },
    {
        "value": 24963,
        "NOCCode": "9521",
        "JobTitle": "rigger, aircraft – aircraft assembly"
    },
    {
        "value": 24964,
        "NOCCode": "9521",
        "JobTitle": "rigger, aircraft assembly"
    },
    {
        "value": 24965,
        "NOCCode": "9521",
        "JobTitle": "sealer – aircraft assembly"
    },
    {
        "value": 24966,
        "NOCCode": "9521",
        "JobTitle": "sealer and tester, pressurized compartments – aircraft assembly"
    },
    {
        "value": 24967,
        "NOCCode": "9521",
        "JobTitle": "sealer, aircraft – aircraft assembly"
    },
    {
        "value": 24968,
        "NOCCode": "9521",
        "JobTitle": "sheet metal assembler – aircraft assembly"
    },
    {
        "value": 24969,
        "NOCCode": "9521",
        "JobTitle": "skin fitter – aircraft assembly"
    },
    {
        "value": 24970,
        "NOCCode": "9521",
        "JobTitle": "spar assembler – aircraft assembly"
    },
    {
        "value": 24971,
        "NOCCode": "9521",
        "JobTitle": "structural assembler, bench – aircraft assembly"
    },
    {
        "value": 24972,
        "NOCCode": "9521",
        "JobTitle": "subassembler – aircraft assembly"
    },
    {
        "value": 24973,
        "NOCCode": "9521",
        "JobTitle": "welded assemblies fitter-tester – aircraft assembly"
    },
    {
        "value": 24974,
        "NOCCode": "9521",
        "JobTitle": "wing assembler, aircraft"
    },
    {
        "value": 24975,
        "NOCCode": "9521",
        "JobTitle": "wing inspector – aircraft assembly"
    },
    {
        "value": 24976,
        "NOCCode": "9521",
        "JobTitle": "wing marry-up mechanic – aircraft assembly"
    },
    {
        "value": 24977,
        "NOCCode": "9521",
        "JobTitle": "wing tank mechanic – aircraft assembly"
    },
    {
        "value": 24978,
        "NOCCode": "9522",
        "JobTitle": "accessories installer, automobiles – motor vehicle manufacturing"
    },
    {
        "value": 24979,
        "NOCCode": "9522",
        "JobTitle": "adjuster, doors – motor vehicle manufacturing"
    },
    {
        "value": 24980,
        "NOCCode": "9522",
        "JobTitle": "adjuster, doors and deck lids – motor vehicle manufacturing"
    },
    {
        "value": 24981,
        "NOCCode": "9522",
        "JobTitle": "adjuster, headlights – motor vehicle manufacturing"
    },
    {
        "value": 24982,
        "NOCCode": "9522",
        "JobTitle": "adjuster, line-up – motor vehicle manufacturing"
    },
    {
        "value": 24983,
        "NOCCode": "9522",
        "JobTitle": "assembler – automobile assembly"
    },
    {
        "value": 24984,
        "NOCCode": "9522",
        "JobTitle": "assembler, automobile hoods – motor vehicle manufacturing"
    },
    {
        "value": 24985,
        "NOCCode": "9522",
        "JobTitle": "assembler, automobiles"
    },
    {
        "value": 24986,
        "NOCCode": "9522",
        "JobTitle": "assembler, axles – automobile assembly"
    },
    {
        "value": 24987,
        "NOCCode": "9522",
        "JobTitle": "assembler, bodies – motor vehicle manufacturing"
    },
    {
        "value": 24988,
        "NOCCode": "9522",
        "JobTitle": "assembler, brakes – motor vehicle manufacturing"
    },
    {
        "value": 24989,
        "NOCCode": "9522",
        "JobTitle": "assembler, cars"
    },
    {
        "value": 24990,
        "NOCCode": "9522",
        "JobTitle": "assembler, chassis – motor vehicle manufacturing"
    },
    {
        "value": 24991,
        "NOCCode": "9522",
        "JobTitle": "assembler, convertible tops – motor vehicle manufacturing"
    },
    {
        "value": 24992,
        "NOCCode": "9522",
        "JobTitle": "assembler, crankshafts – motor vehicle manufacturing"
    },
    {
        "value": 24993,
        "NOCCode": "9522",
        "JobTitle": "assembler, doors – motor vehicle manufacturing"
    },
    {
        "value": 24994,
        "NOCCode": "9522",
        "JobTitle": "assembler, frames – motor vehicle manufacturing"
    },
    {
        "value": 24995,
        "NOCCode": "9522",
        "JobTitle": "assembler, instrument clusters – motor vehicle manufacturing"
    },
    {
        "value": 24996,
        "NOCCode": "9522",
        "JobTitle": "assembler, instrument panels – automobile assembly"
    },
    {
        "value": 24997,
        "NOCCode": "9522",
        "JobTitle": "assembler, interior finishing – automobile assembly"
    },
    {
        "value": 24998,
        "NOCCode": "9522",
        "JobTitle": "assembler, interior trim – automobile assembly"
    },
    {
        "value": 24999,
        "NOCCode": "9522",
        "JobTitle": "assembler, leaf springs – motor vehicle manufacturing"
    },
    {
        "value": 25000,
        "NOCCode": "9522",
        "JobTitle": "assembler, light trucks – automobile assembly"
    },
    {
        "value": 25001,
        "NOCCode": "9522",
        "JobTitle": "assembler, minivans – motor vehicle manufacturing"
    },
    {
        "value": 25002,
        "NOCCode": "9522",
        "JobTitle": "assembler, motor vehicles – motor vehicle manufacturing"
    },
    {
        "value": 25003,
        "NOCCode": "9522",
        "JobTitle": "assembler, seats – motor vehicle manufacturing"
    },
    {
        "value": 25004,
        "NOCCode": "9522",
        "JobTitle": "assembler, small parts – motor vehicle manufacturing"
    },
    {
        "value": 25005,
        "NOCCode": "9522",
        "JobTitle": "assembler, springs – motor vehicle manufacturing"
    },
    {
        "value": 25006,
        "NOCCode": "9522",
        "JobTitle": "assembler, vans"
    },
    {
        "value": 25007,
        "NOCCode": "9522",
        "JobTitle": "assembler-mechanic – motor vehicle manufacturing"
    },
    {
        "value": 25008,
        "NOCCode": "9522",
        "JobTitle": "assembly inspector – motor vehicle manufacturing"
    },
    {
        "value": 25009,
        "NOCCode": "9522",
        "JobTitle": "assembly inspector, chassis – motor vehicle manufacturing"
    },
    {
        "value": 25010,
        "NOCCode": "9522",
        "JobTitle": "assembly quality upgrader – motor vehicle manufacturing"
    },
    {
        "value": 25011,
        "NOCCode": "9522",
        "JobTitle": "assembly worker, autos"
    },
    {
        "value": 25012,
        "NOCCode": "9522",
        "JobTitle": "auto assembly worker"
    },
    {
        "value": 25013,
        "NOCCode": "9522",
        "JobTitle": "auto parts assembler"
    },
    {
        "value": 25014,
        "NOCCode": "9522",
        "JobTitle": "automobile accessories installer"
    },
    {
        "value": 25015,
        "NOCCode": "9522",
        "JobTitle": "automobile accessories installer and repairer"
    },
    {
        "value": 25016,
        "NOCCode": "9522",
        "JobTitle": "automobile assembler"
    },
    {
        "value": 25017,
        "NOCCode": "9522",
        "JobTitle": "automobile assembly worker"
    },
    {
        "value": 25018,
        "NOCCode": "9522",
        "JobTitle": "automobile engine tester – automobile assembly"
    },
    {
        "value": 25019,
        "NOCCode": "9522",
        "JobTitle": "automobile final inspector – automobile assembly"
    },
    {
        "value": 25020,
        "NOCCode": "9522",
        "JobTitle": "automobile hood assembler – motor vehicle manufacturing"
    },
    {
        "value": 25021,
        "NOCCode": "9522",
        "JobTitle": "automobile inspector – motor vehicle manufacturing"
    },
    {
        "value": 25022,
        "NOCCode": "9522",
        "JobTitle": "automobile inspector and tester – motor vehicle manufacturing"
    },
    {
        "value": 25023,
        "NOCCode": "9522",
        "JobTitle": "automobile radio and accessories installer – motor vehicle manufacturing"
    },
    {
        "value": 25024,
        "NOCCode": "9522",
        "JobTitle": "automotive assembler – motor vehicle manufacturing"
    },
    {
        "value": 25025,
        "NOCCode": "9522",
        "JobTitle": "automotive electronic accessories installer and repairer – motor vehicle manufacturing"
    },
    {
        "value": 25026,
        "NOCCode": "9522",
        "JobTitle": "automotive engine quality control inspector"
    },
    {
        "value": 25027,
        "NOCCode": "9522",
        "JobTitle": "axle assembler – automobile assembly"
    },
    {
        "value": 25028,
        "NOCCode": "9522",
        "JobTitle": "battery installer – motor vehicle manufacturing"
    },
    {
        "value": 25029,
        "NOCCode": "9522",
        "JobTitle": "body assembler – motor vehicle manufacturing"
    },
    {
        "value": 25030,
        "NOCCode": "9522",
        "JobTitle": "body department technician – motor vehicle manufacturing"
    },
    {
        "value": 25031,
        "NOCCode": "9522",
        "JobTitle": "body frame inspector – motor vehicle manufacturing"
    },
    {
        "value": 25032,
        "NOCCode": "9522",
        "JobTitle": "body production operator – motor vehicle manufacturing"
    },
    {
        "value": 25033,
        "NOCCode": "9522",
        "JobTitle": "body-in-white inspector – motor vehicle manufacturing"
    },
    {
        "value": 25034,
        "NOCCode": "9522",
        "JobTitle": "brake assembler – motor vehicle manufacturing"
    },
    {
        "value": 25035,
        "NOCCode": "9522",
        "JobTitle": "car assembler"
    },
    {
        "value": 25036,
        "NOCCode": "9522",
        "JobTitle": "car examiner – automobile assembly"
    },
    {
        "value": 25037,
        "NOCCode": "9522",
        "JobTitle": "car inspector – automobile assembly"
    },
    {
        "value": 25038,
        "NOCCode": "9522",
        "JobTitle": "chassis assembler – motor vehicle manufacturing"
    },
    {
        "value": 25039,
        "NOCCode": "9522",
        "JobTitle": "chassis assembly inspector – motor vehicle manufacturing"
    },
    {
        "value": 25040,
        "NOCCode": "9522",
        "JobTitle": "chassis inspector – motor vehicle manufacturing"
    },
    {
        "value": 25041,
        "NOCCode": "9522",
        "JobTitle": "convertible top assembler – motor vehicle manufacturing"
    },
    {
        "value": 25042,
        "NOCCode": "9522",
        "JobTitle": "crankshaft assembler – motor vehicle manufacturing"
    },
    {
        "value": 25043,
        "NOCCode": "9522",
        "JobTitle": "door adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25044,
        "NOCCode": "9522",
        "JobTitle": "door and deck lid adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25045,
        "NOCCode": "9522",
        "JobTitle": "door assembler – motor vehicle manufacturing"
    },
    {
        "value": 25046,
        "NOCCode": "9522",
        "JobTitle": "door fitter"
    },
    {
        "value": 25047,
        "NOCCode": "9522",
        "JobTitle": "door fitter – motor vehicle manufacturing"
    },
    {
        "value": 25048,
        "NOCCode": "9522",
        "JobTitle": "door hanger – motor vehicle manufacturing"
    },
    {
        "value": 25049,
        "NOCCode": "9522",
        "JobTitle": "door paneler – motor vehicle manufacturing"
    },
    {
        "value": 25050,
        "NOCCode": "9522",
        "JobTitle": "electrical quality assurance inspector – motor vehicle manufacturing"
    },
    {
        "value": 25051,
        "NOCCode": "9522",
        "JobTitle": "electrical test technician – motor vehicle manufacturing"
    },
    {
        "value": 25052,
        "NOCCode": "9522",
        "JobTitle": "electrical tester, instrument panels – motor vehicle manufacturing"
    },
    {
        "value": 25053,
        "NOCCode": "9522",
        "JobTitle": "electrical testing technician – motor vehicle manufacturing"
    },
    {
        "value": 25054,
        "NOCCode": "9522",
        "JobTitle": "electrical wirer – automotive assembly"
    },
    {
        "value": 25055,
        "NOCCode": "9522",
        "JobTitle": "engine block tester – motor vehicle manufacturing"
    },
    {
        "value": 25056,
        "NOCCode": "9522",
        "JobTitle": "engine dress assembler – motor vehicle manufacturing"
    },
    {
        "value": 25057,
        "NOCCode": "9522",
        "JobTitle": "engine inspector – motor vehicle manufacturing"
    },
    {
        "value": 25058,
        "NOCCode": "9522",
        "JobTitle": "engine installer – motor vehicle manufacturing"
    },
    {
        "value": 25059,
        "NOCCode": "9522",
        "JobTitle": "engine tester – automobile assembly"
    },
    {
        "value": 25060,
        "NOCCode": "9522",
        "JobTitle": "engine tester, automobiles – automobile assembly"
    },
    {
        "value": 25061,
        "NOCCode": "9522",
        "JobTitle": "examiner, cars – motor vehicle manufacturing"
    },
    {
        "value": 25062,
        "NOCCode": "9522",
        "JobTitle": "examiner, water leaks – motor vehicle manufacturing"
    },
    {
        "value": 25063,
        "NOCCode": "9522",
        "JobTitle": "final inspector, automobiles – automobile assembly"
    },
    {
        "value": 25064,
        "NOCCode": "9522",
        "JobTitle": "finished assembled vehicle inspector – motor vehicle manufacturing"
    },
    {
        "value": 25065,
        "NOCCode": "9522",
        "JobTitle": "finishing department technician – motor vehicle manufacturing"
    },
    {
        "value": 25066,
        "NOCCode": "9522",
        "JobTitle": "fitter and adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25067,
        "NOCCode": "9522",
        "JobTitle": "fitter, doors – motor vehicle manufacturing"
    },
    {
        "value": 25068,
        "NOCCode": "9522",
        "JobTitle": "fitter, hoods – motor vehicle manufacturing"
    },
    {
        "value": 25069,
        "NOCCode": "9522",
        "JobTitle": "floor inspector – motor vehicle manufacturing"
    },
    {
        "value": 25070,
        "NOCCode": "9522",
        "JobTitle": "frame assembler – motor vehicle manufacturing"
    },
    {
        "value": 25071,
        "NOCCode": "9522",
        "JobTitle": "general repairer and adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25072,
        "NOCCode": "9522",
        "JobTitle": "headlight adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25073,
        "NOCCode": "9522",
        "JobTitle": "hood fitter – motor vehicle manufacturing"
    },
    {
        "value": 25074,
        "NOCCode": "9522",
        "JobTitle": "in-process assembly inspector – automobile assembly"
    },
    {
        "value": 25075,
        "NOCCode": "9522",
        "JobTitle": "inspector and tester, automobiles – motor vehicle manufacturing"
    },
    {
        "value": 25076,
        "NOCCode": "9522",
        "JobTitle": "inspector, assembly – motor vehicle manufacturing"
    },
    {
        "value": 25077,
        "NOCCode": "9522",
        "JobTitle": "inspector, automobiles – motor vehicle manufacturing"
    },
    {
        "value": 25078,
        "NOCCode": "9522",
        "JobTitle": "inspector, body frames – motor vehicle manufacturing"
    },
    {
        "value": 25079,
        "NOCCode": "9522",
        "JobTitle": "inspector, body-in-white – motor vehicle manufacturing"
    },
    {
        "value": 25080,
        "NOCCode": "9522",
        "JobTitle": "inspector, cars – motor vehicle manufacturing"
    },
    {
        "value": 25081,
        "NOCCode": "9522",
        "JobTitle": "inspector, chassis – motor vehicle manufacturing"
    },
    {
        "value": 25082,
        "NOCCode": "9522",
        "JobTitle": "inspector, chassis assembly – motor vehicle manufacturing"
    },
    {
        "value": 25083,
        "NOCCode": "9522",
        "JobTitle": "inspector, electrical quality assurance – motor vehicle manufacturing"
    },
    {
        "value": 25084,
        "NOCCode": "9522",
        "JobTitle": "inspector, engines – motor vehicle manufacturing"
    },
    {
        "value": 25085,
        "NOCCode": "9522",
        "JobTitle": "inspector, finished assembled vehicle – motor vehicle manufacturing"
    },
    {
        "value": 25086,
        "NOCCode": "9522",
        "JobTitle": "inspector, floors – motor vehicle manufacturing"
    },
    {
        "value": 25087,
        "NOCCode": "9522",
        "JobTitle": "inspector, in-process assembly – automobile assembly"
    },
    {
        "value": 25088,
        "NOCCode": "9522",
        "JobTitle": "inspector, mechanical quality assurance – motor vehicle manufacturing"
    },
    {
        "value": 25089,
        "NOCCode": "9522",
        "JobTitle": "inspector, motor vehicle mechanical equipment – motor vehicle manufacturing"
    },
    {
        "value": 25090,
        "NOCCode": "9522",
        "JobTitle": "inspector, motors and chassis – motor vehicle manufacturing"
    },
    {
        "value": 25091,
        "NOCCode": "9522",
        "JobTitle": "inspector, new passenger cars"
    },
    {
        "value": 25092,
        "NOCCode": "9522",
        "JobTitle": "inspector, plastic and painted panels – motor vehicle manufacturing"
    },
    {
        "value": 25093,
        "NOCCode": "9522",
        "JobTitle": "inspector, radiator – motor vehicle manufacturing"
    },
    {
        "value": 25094,
        "NOCCode": "9522",
        "JobTitle": "inspector, trim and hardware – motor vehicle manufacturing"
    },
    {
        "value": 25095,
        "NOCCode": "9522",
        "JobTitle": "inspector, water leaks – motor vehicle manufacturing"
    },
    {
        "value": 25096,
        "NOCCode": "9522",
        "JobTitle": "installer and repairer, automobile accessories – motor vehicle manufacturing"
    },
    {
        "value": 25097,
        "NOCCode": "9522",
        "JobTitle": "installer and repairer, automotive electronic accessories – motor vehicle manufacturing"
    },
    {
        "value": 25098,
        "NOCCode": "9522",
        "JobTitle": "installer, automobile radios and accessories – motor vehicle manufacturing"
    },
    {
        "value": 25099,
        "NOCCode": "9522",
        "JobTitle": "installer, batteries – motor vehicle manufacturing"
    },
    {
        "value": 25100,
        "NOCCode": "9522",
        "JobTitle": "installer, engines – motor vehicle manufacturing"
    },
    {
        "value": 25101,
        "NOCCode": "9522",
        "JobTitle": "installer, radiators – motor vehicle manufacturing"
    },
    {
        "value": 25102,
        "NOCCode": "9522",
        "JobTitle": "installer, transmissions – motor vehicle manufacturing"
    },
    {
        "value": 25103,
        "NOCCode": "9522",
        "JobTitle": "installer, vinyl roofs – motor vehicle manufacturing"
    },
    {
        "value": 25104,
        "NOCCode": "9522",
        "JobTitle": "installer, wheels and tires – automobile assembly"
    },
    {
        "value": 25105,
        "NOCCode": "9522",
        "JobTitle": "instrument cluster assembler – motor vehicle manufacturing"
    },
    {
        "value": 25106,
        "NOCCode": "9522",
        "JobTitle": "instrument panel assembler – automobile assembly"
    },
    {
        "value": 25107,
        "NOCCode": "9522",
        "JobTitle": "instrument panel electrical tester – motor vehicle manufacturing"
    },
    {
        "value": 25108,
        "NOCCode": "9522",
        "JobTitle": "interior finishing assembler – automobile assembly"
    },
    {
        "value": 25109,
        "NOCCode": "9522",
        "JobTitle": "interior trim assembler – automobile assembly"
    },
    {
        "value": 25110,
        "NOCCode": "9522",
        "JobTitle": "leaf spring assembler – motor vehicle manufacturing"
    },
    {
        "value": 25111,
        "NOCCode": "9522",
        "JobTitle": "light truck assembler – automobile assembly"
    },
    {
        "value": 25112,
        "NOCCode": "9522",
        "JobTitle": "line-up adjuster – motor vehicle manufacturing"
    },
    {
        "value": 25113,
        "NOCCode": "9522",
        "JobTitle": "line-up man/woman – motor vehicle manufacturing"
    },
    {
        "value": 25114,
        "NOCCode": "9522",
        "JobTitle": "machine operator – motor vehicle manufacturing"
    },
    {
        "value": 25115,
        "NOCCode": "9522",
        "JobTitle": "mechanic, road tests – motor vehicle manufacturing"
    },
    {
        "value": 25116,
        "NOCCode": "9522",
        "JobTitle": "mechanical inspector – motor vehicle manufacturing"
    },
    {
        "value": 25117,
        "NOCCode": "9522",
        "JobTitle": "mechanical quality assurance inspector – motor vehicle manufacturing"
    },
    {
        "value": 25118,
        "NOCCode": "9522",
        "JobTitle": "mechanical test technician – motor vehicle manufacturing"
    },
    {
        "value": 25119,
        "NOCCode": "9522",
        "JobTitle": "minivan assembler – motor vehicle manufacturing"
    },
    {
        "value": 25120,
        "NOCCode": "9522",
        "JobTitle": "motor and chassis inspector – motor vehicle manufacturing"
    },
    {
        "value": 25121,
        "NOCCode": "9522",
        "JobTitle": "motor installer – motor vehicle manufacturing"
    },
    {
        "value": 25122,
        "NOCCode": "9522",
        "JobTitle": "motor vehicle assembler"
    },
    {
        "value": 25123,
        "NOCCode": "9522",
        "JobTitle": "motor vehicle assembler – motor vehicle manufacturing"
    },
    {
        "value": 25124,
        "NOCCode": "9522",
        "JobTitle": "motor vehicle mechanical equipment inspector – motor vehicle manufacturing"
    },
    {
        "value": 25125,
        "NOCCode": "9522",
        "JobTitle": "new passenger car inspector"
    },
    {
        "value": 25126,
        "NOCCode": "9522",
        "JobTitle": "new passenger car preparer – motor vehicle manufacturing"
    },
    {
        "value": 25127,
        "NOCCode": "9522",
        "JobTitle": "operator, machines – motor vehicle manufacturing"
    },
    {
        "value": 25128,
        "NOCCode": "9522",
        "JobTitle": "operator, sealer – motor vehicle manufacturing"
    },
    {
        "value": 25129,
        "NOCCode": "9522",
        "JobTitle": "paint department technician – motor vehicle manufacturing"
    },
    {
        "value": 25130,
        "NOCCode": "9522",
        "JobTitle": "plastic and painted panels inspector – motor vehicle manufacturing"
    },
    {
        "value": 25131,
        "NOCCode": "9522",
        "JobTitle": "preparer, new passenger cars – motor vehicle manufacturing"
    },
    {
        "value": 25132,
        "NOCCode": "9522",
        "JobTitle": "production operator – motor vehicle manufacturing"
    },
    {
        "value": 25133,
        "NOCCode": "9522",
        "JobTitle": "production operator, body – motor vehicle manufacturing"
    },
    {
        "value": 25134,
        "NOCCode": "9522",
        "JobTitle": "production operator, trim and final – motor vehicle manufacturing"
    },
    {
        "value": 25135,
        "NOCCode": "9522",
        "JobTitle": "quality control inspector, automotive engines – motor vehicle manufacturing"
    },
    {
        "value": 25136,
        "NOCCode": "9522",
        "JobTitle": "quality upgrader, assembly – motor vehicle manufacturing"
    },
    {
        "value": 25137,
        "NOCCode": "9522",
        "JobTitle": "radiator inspector – motor vehicle manufacturing"
    },
    {
        "value": 25138,
        "NOCCode": "9522",
        "JobTitle": "radiator installer – motor vehicle manufacturing"
    },
    {
        "value": 25139,
        "NOCCode": "9522",
        "JobTitle": "road and roll tester"
    },
    {
        "value": 25140,
        "NOCCode": "9522",
        "JobTitle": "road test mechanic"
    },
    {
        "value": 25141,
        "NOCCode": "9522",
        "JobTitle": "roll tester – motor vehicle manufacturing"
    },
    {
        "value": 25142,
        "NOCCode": "9522",
        "JobTitle": "sealer operator – motor vehicle manufacturing"
    },
    {
        "value": 25143,
        "NOCCode": "9522",
        "JobTitle": "seat assembler – motor vehicle manufacturing"
    },
    {
        "value": 25144,
        "NOCCode": "9522",
        "JobTitle": "small parts assembler – motor vehicle manufacturing"
    },
    {
        "value": 25145,
        "NOCCode": "9522",
        "JobTitle": "soft top trimmer – motor vehicle manufacturing"
    },
    {
        "value": 25146,
        "NOCCode": "9522",
        "JobTitle": "sport utility vehicle (SUV) assembler"
    },
    {
        "value": 25147,
        "NOCCode": "9522",
        "JobTitle": "spring assembler – motor vehicle manufacturing"
    },
    {
        "value": 25148,
        "NOCCode": "9522",
        "JobTitle": "subassembler – motor vehicle manufacturing"
    },
    {
        "value": 25149,
        "NOCCode": "9522",
        "JobTitle": "SUV (sport utility vehicle) assembler"
    },
    {
        "value": 25150,
        "NOCCode": "9522",
        "JobTitle": "technician, body department – motor vehicle manufacturing"
    },
    {
        "value": 25151,
        "NOCCode": "9522",
        "JobTitle": "technician, electrical testing – motor vehicle manufacturing"
    },
    {
        "value": 25152,
        "NOCCode": "9522",
        "JobTitle": "technician, electrical tests – motor vehicle manufacturing"
    },
    {
        "value": 25153,
        "NOCCode": "9522",
        "JobTitle": "technician, finishing department – motor vehicle manufacturing"
    },
    {
        "value": 25154,
        "NOCCode": "9522",
        "JobTitle": "technician, mechanical tests – motor vehicle manufacturing"
    },
    {
        "value": 25155,
        "NOCCode": "9522",
        "JobTitle": "technician, paint department – motor vehicle manufacturing"
    },
    {
        "value": 25156,
        "NOCCode": "9522",
        "JobTitle": "test driver – automobile assembly"
    },
    {
        "value": 25157,
        "NOCCode": "9522",
        "JobTitle": "tester, automobile engines – automobile assembly"
    },
    {
        "value": 25158,
        "NOCCode": "9522",
        "JobTitle": "tester, engine blocks – motor vehicle manufacturing"
    },
    {
        "value": 25159,
        "NOCCode": "9522",
        "JobTitle": "tester, engines – automobile assembly"
    },
    {
        "value": 25160,
        "NOCCode": "9522",
        "JobTitle": "tester, transmissions – motor vehicle manufacturing"
    },
    {
        "value": 25161,
        "NOCCode": "9522",
        "JobTitle": "transmission installer"
    },
    {
        "value": 25162,
        "NOCCode": "9522",
        "JobTitle": "transmission tester – motor vehicle manufacturing"
    },
    {
        "value": 25163,
        "NOCCode": "9522",
        "JobTitle": "trim and hardware inspector – motor vehicle manufacturing"
    },
    {
        "value": 25164,
        "NOCCode": "9522",
        "JobTitle": "trimmer, soft top – motor vehicle manufacturing"
    },
    {
        "value": 25165,
        "NOCCode": "9522",
        "JobTitle": "utility operator – motor vehicle manufacturing"
    },
    {
        "value": 25166,
        "NOCCode": "9522",
        "JobTitle": "utility repairer – motor vehicle manufacturing"
    },
    {
        "value": 25167,
        "NOCCode": "9522",
        "JobTitle": "van assembler"
    },
    {
        "value": 25168,
        "NOCCode": "9522",
        "JobTitle": "vinyl roof installer – motor vehicle manufacturing"
    },
    {
        "value": 25169,
        "NOCCode": "9522",
        "JobTitle": "water leak examiner – motor vehicle manufacturing"
    },
    {
        "value": 25170,
        "NOCCode": "9522",
        "JobTitle": "water leak inspector – motor vehicle manufacturing"
    },
    {
        "value": 25171,
        "NOCCode": "9522",
        "JobTitle": "wheel and tire installer – automobile assembly"
    },
    {
        "value": 25172,
        "NOCCode": "9523",
        "JobTitle": "adjuster, filament-winding machine"
    },
    {
        "value": 25173,
        "NOCCode": "9523",
        "JobTitle": "aligner – electronic equipment manufacturing"
    },
    {
        "value": 25174,
        "NOCCode": "9523",
        "JobTitle": "aluminizing equipment operator – electronic equipment manufacturing"
    },
    {
        "value": 25175,
        "NOCCode": "9523",
        "JobTitle": "antenna-tuner inspector – electronic equipment manufacturing"
    },
    {
        "value": 25176,
        "NOCCode": "9523",
        "JobTitle": "assembler – electronic equipment manufacturing"
    },
    {
        "value": 25177,
        "NOCCode": "9523",
        "JobTitle": "assembler, avionics equipment"
    },
    {
        "value": 25178,
        "NOCCode": "9523",
        "JobTitle": "assembler, electronic cash registers"
    },
    {
        "value": 25179,
        "NOCCode": "9523",
        "JobTitle": "assembler, electronic equipment – electronic equipment manufacturing"
    },
    {
        "value": 25180,
        "NOCCode": "9523",
        "JobTitle": "assembler, electronic keyboard"
    },
    {
        "value": 25181,
        "NOCCode": "9523",
        "JobTitle": "assembler, office machines"
    },
    {
        "value": 25182,
        "NOCCode": "9523",
        "JobTitle": "assembler, telecommunications equipment"
    },
    {
        "value": 25183,
        "NOCCode": "9523",
        "JobTitle": "assembly inspector – electronic equipment manufacturing"
    },
    {
        "value": 25184,
        "NOCCode": "9523",
        "JobTitle": "assembly tester – electronic equipment manufacturing"
    },
    {
        "value": 25185,
        "NOCCode": "9523",
        "JobTitle": "automatic component-assembly-machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25186,
        "NOCCode": "9523",
        "JobTitle": "automatic wire-wrapping machine tender – electronic equipment manufacturing"
    },
    {
        "value": 25187,
        "NOCCode": "9523",
        "JobTitle": "avionics assembler"
    },
    {
        "value": 25188,
        "NOCCode": "9523",
        "JobTitle": "avionics equipment assembler"
    },
    {
        "value": 25189,
        "NOCCode": "9523",
        "JobTitle": "backplane and frame wiring operator"
    },
    {
        "value": 25190,
        "NOCCode": "9523",
        "JobTitle": "base-filling machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25191,
        "NOCCode": "9523",
        "JobTitle": "bench assembler – electronic equipment manufacturing"
    },
    {
        "value": 25192,
        "NOCCode": "9523",
        "JobTitle": "bench worker – electronic equipment manufacturing"
    },
    {
        "value": 25193,
        "NOCCode": "9523",
        "JobTitle": "board laboratory operator"
    },
    {
        "value": 25194,
        "NOCCode": "9523",
        "JobTitle": "calibrator, wire wound resistors"
    },
    {
        "value": 25195,
        "NOCCode": "9523",
        "JobTitle": "capacitor assembler"
    },
    {
        "value": 25196,
        "NOCCode": "9523",
        "JobTitle": "capacitor inspector"
    },
    {
        "value": 25197,
        "NOCCode": "9523",
        "JobTitle": "capacitor-treating tank operator"
    },
    {
        "value": 25198,
        "NOCCode": "9523",
        "JobTitle": "cathode coater – electronic equipment manufacturing"
    },
    {
        "value": 25199,
        "NOCCode": "9523",
        "JobTitle": "cathode-ray-tube-processing equipment setter"
    },
    {
        "value": 25200,
        "NOCCode": "9523",
        "JobTitle": "chassis assembler – electronic equipment manufacturing"
    },
    {
        "value": 25201,
        "NOCCode": "9523",
        "JobTitle": "chassis installer – electronic equipment manufacturing"
    },
    {
        "value": 25202,
        "NOCCode": "9523",
        "JobTitle": "choke winder – electronic equipment manufacturing"
    },
    {
        "value": 25203,
        "NOCCode": "9523",
        "JobTitle": "circuit board assembler"
    },
    {
        "value": 25204,
        "NOCCode": "9523",
        "JobTitle": "coil assembler – electronic equipment manufacturing"
    },
    {
        "value": 25205,
        "NOCCode": "9523",
        "JobTitle": "coil inspector – electronic equipment manufacturing"
    },
    {
        "value": 25206,
        "NOCCode": "9523",
        "JobTitle": "coil-winding machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25207,
        "NOCCode": "9523",
        "JobTitle": "colour picture tube assembler"
    },
    {
        "value": 25208,
        "NOCCode": "9523",
        "JobTitle": "communications equipment assembler"
    },
    {
        "value": 25209,
        "NOCCode": "9523",
        "JobTitle": "compact disc player assembler"
    },
    {
        "value": 25210,
        "NOCCode": "9523",
        "JobTitle": "compact disc player inspector and tester"
    },
    {
        "value": 25211,
        "NOCCode": "9523",
        "JobTitle": "component inserting machine operator"
    },
    {
        "value": 25212,
        "NOCCode": "9523",
        "JobTitle": "component inspector – electronic equipment manufacturing"
    },
    {
        "value": 25213,
        "NOCCode": "9523",
        "JobTitle": "components assembler – electronic equipment manufacturing"
    },
    {
        "value": 25214,
        "NOCCode": "9523",
        "JobTitle": "condenser winder – electronic equipment manufacturing"
    },
    {
        "value": 25215,
        "NOCCode": "9523",
        "JobTitle": "cone assembler – electronic equipment manufacturing"
    },
    {
        "value": 25216,
        "NOCCode": "9523",
        "JobTitle": "console assembler – electronic equipment manufacturing"
    },
    {
        "value": 25217,
        "NOCCode": "9523",
        "JobTitle": "conveyor line worker – electronic equipment manufacturing"
    },
    {
        "value": 25218,
        "NOCCode": "9523",
        "JobTitle": "crystal calibrator – electronic equipment manufacturing"
    },
    {
        "value": 25219,
        "NOCCode": "9523",
        "JobTitle": "crystal final tester"
    },
    {
        "value": 25220,
        "NOCCode": "9523",
        "JobTitle": "crystal final tester – electronic equipment manufacturing"
    },
    {
        "value": 25221,
        "NOCCode": "9523",
        "JobTitle": "crystal finisher – electronic equipment manufacturing"
    },
    {
        "value": 25222,
        "NOCCode": "9523",
        "JobTitle": "crystal frequency measurer – electronic equipment manufacturing"
    },
    {
        "value": 25223,
        "NOCCode": "9523",
        "JobTitle": "crystal grower"
    },
    {
        "value": 25224,
        "NOCCode": "9523",
        "JobTitle": "crystal inspector – electronic equipment manufacturing"
    },
    {
        "value": 25225,
        "NOCCode": "9523",
        "JobTitle": "crystal lapper"
    },
    {
        "value": 25226,
        "NOCCode": "9523",
        "JobTitle": "crystal unit assembler"
    },
    {
        "value": 25227,
        "NOCCode": "9523",
        "JobTitle": "crystal-dicing saw operator"
    },
    {
        "value": 25228,
        "NOCCode": "9523",
        "JobTitle": "die attacher – electronic equipment manufacturing"
    },
    {
        "value": 25229,
        "NOCCode": "9523",
        "JobTitle": "diffusion furnace operator – electronic equipment manufacturing"
    },
    {
        "value": 25230,
        "NOCCode": "9523",
        "JobTitle": "dip-soldering machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25231,
        "NOCCode": "9523",
        "JobTitle": "duplicating machine assembler"
    },
    {
        "value": 25232,
        "NOCCode": "9523",
        "JobTitle": "electrical wire group assembler – electronic equipment manufacturing"
    },
    {
        "value": 25233,
        "NOCCode": "9523",
        "JobTitle": "electron tube assembler"
    },
    {
        "value": 25234,
        "NOCCode": "9523",
        "JobTitle": "electron tube inspector and tester"
    },
    {
        "value": 25235,
        "NOCCode": "9523",
        "JobTitle": "electron tube stem assembly inspector"
    },
    {
        "value": 25236,
        "NOCCode": "9523",
        "JobTitle": "electronic business machine assembler"
    },
    {
        "value": 25237,
        "NOCCode": "9523",
        "JobTitle": "electronic cash register assembler"
    },
    {
        "value": 25238,
        "NOCCode": "9523",
        "JobTitle": "electronic component assembler"
    },
    {
        "value": 25239,
        "NOCCode": "9523",
        "JobTitle": "electronic component cleaner"
    },
    {
        "value": 25240,
        "NOCCode": "9523",
        "JobTitle": "electronic component insertion colour coder"
    },
    {
        "value": 25241,
        "NOCCode": "9523",
        "JobTitle": "electronic component tester – electronic equipment manufacturing"
    },
    {
        "value": 25242,
        "NOCCode": "9523",
        "JobTitle": "electronic control assembler"
    },
    {
        "value": 25243,
        "NOCCode": "9523",
        "JobTitle": "electronic control operator – electronic equipment manufacturing"
    },
    {
        "value": 25244,
        "NOCCode": "9523",
        "JobTitle": "electronic equipment assembler – electronic equipment manufacturing"
    },
    {
        "value": 25245,
        "NOCCode": "9523",
        "JobTitle": "electronic equipment inspector"
    },
    {
        "value": 25246,
        "NOCCode": "9523",
        "JobTitle": "electronic equipment subassembler"
    },
    {
        "value": 25247,
        "NOCCode": "9523",
        "JobTitle": "electronic instrument maker"
    },
    {
        "value": 25248,
        "NOCCode": "9523",
        "JobTitle": "electronic keyboard assembler"
    },
    {
        "value": 25249,
        "NOCCode": "9523",
        "JobTitle": "electronic office machines assembler"
    },
    {
        "value": 25250,
        "NOCCode": "9523",
        "JobTitle": "electronic organ assembler"
    },
    {
        "value": 25251,
        "NOCCode": "9523",
        "JobTitle": "electronic peripheral equipment assembler"
    },
    {
        "value": 25252,
        "NOCCode": "9523",
        "JobTitle": "electronic products mounter – electronic equipment manufacturing"
    },
    {
        "value": 25253,
        "NOCCode": "9523",
        "JobTitle": "electronic unit inspector – electronic equipment manufacturing"
    },
    {
        "value": 25254,
        "NOCCode": "9523",
        "JobTitle": "electronics assembler"
    },
    {
        "value": 25255,
        "NOCCode": "9523",
        "JobTitle": "electronics equipment assembler"
    },
    {
        "value": 25256,
        "NOCCode": "9523",
        "JobTitle": "electronics inspector – electronic equipment manufacturing"
    },
    {
        "value": 25257,
        "NOCCode": "9523",
        "JobTitle": "electronics manufacturing shop worker"
    },
    {
        "value": 25258,
        "NOCCode": "9523",
        "JobTitle": "electronics tester"
    },
    {
        "value": 25259,
        "NOCCode": "9523",
        "JobTitle": "electronics-manufacturing process inspector"
    },
    {
        "value": 25260,
        "NOCCode": "9523",
        "JobTitle": "etcher and plater, quartz crystals – electronic equipment manufacturing"
    },
    {
        "value": 25261,
        "NOCCode": "9523",
        "JobTitle": "etcher, printed circuit board (PCB)"
    },
    {
        "value": 25262,
        "NOCCode": "9523",
        "JobTitle": "exhaust and sealing machine operator"
    },
    {
        "value": 25263,
        "NOCCode": "9523",
        "JobTitle": "exhaust equipment setter – electronic equipment manufacturing"
    },
    {
        "value": 25264,
        "NOCCode": "9523",
        "JobTitle": "fabricator, microelectronic circuits"
    },
    {
        "value": 25265,
        "NOCCode": "9523",
        "JobTitle": "filament stem inspector – electronic equipment manufacturing"
    },
    {
        "value": 25266,
        "NOCCode": "9523",
        "JobTitle": "filament tester – electronic equipment manufacturing"
    },
    {
        "value": 25267,
        "NOCCode": "9523",
        "JobTitle": "filter assembler – electronic equipment manufacturing"
    },
    {
        "value": 25268,
        "NOCCode": "9523",
        "JobTitle": "final-assembly tester – electronic equipment manufacturing"
    },
    {
        "value": 25269,
        "NOCCode": "9523",
        "JobTitle": "finished product inspector – electronic equipment manufacturing"
    },
    {
        "value": 25270,
        "NOCCode": "9523",
        "JobTitle": "fluorescent screen former"
    },
    {
        "value": 25271,
        "NOCCode": "9523",
        "JobTitle": "frequency measurer, crystals – electronic equipment manufacturing"
    },
    {
        "value": 25272,
        "NOCCode": "9523",
        "JobTitle": "funnel coater – electronic equipment manufacturing"
    },
    {
        "value": 25273,
        "NOCCode": "9523",
        "JobTitle": "gauger, semiconductor material – electronic equipment manufacturing"
    },
    {
        "value": 25274,
        "NOCCode": "9523",
        "JobTitle": "general assembler and light machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25275,
        "NOCCode": "9523",
        "JobTitle": "geophysical instrument assembler – electronic equipment manufacturing"
    },
    {
        "value": 25276,
        "NOCCode": "9523",
        "JobTitle": "grid gauger – electronic equipment manufacturing"
    },
    {
        "value": 25277,
        "NOCCode": "9523",
        "JobTitle": "grid inspector – electronic equipment manufacturing"
    },
    {
        "value": 25278,
        "NOCCode": "9523",
        "JobTitle": "grid operator – electronic equipment manufacturing"
    },
    {
        "value": 25279,
        "NOCCode": "9523",
        "JobTitle": "gun assembler, television pictures tubes"
    },
    {
        "value": 25280,
        "NOCCode": "9523",
        "JobTitle": "harness and cable fabricator – electronic equipment manufacturing"
    },
    {
        "value": 25281,
        "NOCCode": "9523",
        "JobTitle": "hearing aid inspector – electronic equipment manufacturing"
    },
    {
        "value": 25282,
        "NOCCode": "9523",
        "JobTitle": "hybrid circuit assembler – electronic equipment manufacturing"
    },
    {
        "value": 25283,
        "NOCCode": "9523",
        "JobTitle": "hybrid inspector – electronic equipment manufacturing"
    },
    {
        "value": 25284,
        "NOCCode": "9523",
        "JobTitle": "impregnator and dryer – electronic equipment manufacturing"
    },
    {
        "value": 25285,
        "NOCCode": "9523",
        "JobTitle": "in-circuit test operator"
    },
    {
        "value": 25286,
        "NOCCode": "9523",
        "JobTitle": "inspector – electronic equipment manufacturing"
    },
    {
        "value": 25287,
        "NOCCode": "9523",
        "JobTitle": "inspector – printed circuit board fabrication"
    },
    {
        "value": 25288,
        "NOCCode": "9523",
        "JobTitle": "inspector and tester, electron tube"
    },
    {
        "value": 25289,
        "NOCCode": "9523",
        "JobTitle": "inspector, components – electronic equipment manufacturing"
    },
    {
        "value": 25290,
        "NOCCode": "9523",
        "JobTitle": "inspector, electron tube stem assemblies"
    },
    {
        "value": 25291,
        "NOCCode": "9523",
        "JobTitle": "inspector, printed circuit board (PCB) assembly"
    },
    {
        "value": 25292,
        "NOCCode": "9523",
        "JobTitle": "installer – electronic equipment manufacturing"
    },
    {
        "value": 25293,
        "NOCCode": "9523",
        "JobTitle": "integrated circuit assembler"
    },
    {
        "value": 25294,
        "NOCCode": "9523",
        "JobTitle": "lab technician, wafer fabrication – electronic equipment manufacturing"
    },
    {
        "value": 25295,
        "NOCCode": "9523",
        "JobTitle": "laminator, television picture tubes"
    },
    {
        "value": 25296,
        "NOCCode": "9523",
        "JobTitle": "laser assembler – electronic equipment manufacturing"
    },
    {
        "value": 25297,
        "NOCCode": "9523",
        "JobTitle": "laser-trimmer operator – electronic equipment manufacturing"
    },
    {
        "value": 25298,
        "NOCCode": "9523",
        "JobTitle": "machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25299,
        "NOCCode": "9523",
        "JobTitle": "magnetometer assembler – electronic equipment manufacturing"
    },
    {
        "value": 25300,
        "NOCCode": "9523",
        "JobTitle": "manufacturing operator – electronic equipment manufacturing"
    },
    {
        "value": 25301,
        "NOCCode": "9523",
        "JobTitle": "mica capacitor assembler"
    },
    {
        "value": 25302,
        "NOCCode": "9523",
        "JobTitle": "microcircuit assembler"
    },
    {
        "value": 25303,
        "NOCCode": "9523",
        "JobTitle": "microelectronic circuit fabricator"
    },
    {
        "value": 25304,
        "NOCCode": "9523",
        "JobTitle": "mobile transceiver inspector – electronic equipment manufacturing"
    },
    {
        "value": 25305,
        "NOCCode": "9523",
        "JobTitle": "mount operator – electronic equipment manufacturing"
    },
    {
        "value": 25306,
        "NOCCode": "9523",
        "JobTitle": "mounter – electronic equipment manufacturing"
    },
    {
        "value": 25307,
        "NOCCode": "9523",
        "JobTitle": "nautical instrument maker – electronic equipment manufacturing"
    },
    {
        "value": 25308,
        "NOCCode": "9523",
        "JobTitle": "navigation aid inspector – electronic equipment manufacturing"
    },
    {
        "value": 25309,
        "NOCCode": "9523",
        "JobTitle": "nucleonics instrument assembler"
    },
    {
        "value": 25310,
        "NOCCode": "9523",
        "JobTitle": "pacemaker assembler – electronic equipment manufacturing"
    },
    {
        "value": 25311,
        "NOCCode": "9523",
        "JobTitle": "parts tester – electronic equipment manufacturing"
    },
    {
        "value": 25312,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) assembler"
    },
    {
        "value": 25313,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) assembly inspector"
    },
    {
        "value": 25314,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) assembly machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25315,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) etcher"
    },
    {
        "value": 25316,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) fabrication inspector"
    },
    {
        "value": 25317,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) fabrication machine operator"
    },
    {
        "value": 25318,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) in-circuit machine -and repairing-machine operator"
    },
    {
        "value": 25319,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) inspector"
    },
    {
        "value": 25320,
        "NOCCode": "9523",
        "JobTitle": "PCB (printed circuit board) inspector and tester – electronic equipment manufacturing"
    },
    {
        "value": 25321,
        "NOCCode": "9523",
        "JobTitle": "photoresist printer – electronic equipment manufacturing"
    },
    {
        "value": 25322,
        "NOCCode": "9523",
        "JobTitle": "pick and place machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25323,
        "NOCCode": "9523",
        "JobTitle": "picture tube assembler"
    },
    {
        "value": 25324,
        "NOCCode": "9523",
        "JobTitle": "picture tube screen aluminizer"
    },
    {
        "value": 25325,
        "NOCCode": "9523",
        "JobTitle": "picture tube tester – electronic equipment manufacturing"
    },
    {
        "value": 25326,
        "NOCCode": "9523",
        "JobTitle": "plater, vacuum evaporation process – electronic equipment manufacturing"
    },
    {
        "value": 25327,
        "NOCCode": "9523",
        "JobTitle": "precision instrument assembler – electronic equipment manufacturing"
    },
    {
        "value": 25328,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) assembler"
    },
    {
        "value": 25329,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) assembly machine operator"
    },
    {
        "value": 25330,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) assembly machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25331,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) etcher"
    },
    {
        "value": 25332,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) fabrication inspector"
    },
    {
        "value": 25333,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) fabrication machine operator"
    },
    {
        "value": 25334,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) in-circuit machine -and repairing-machine operator"
    },
    {
        "value": 25335,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) in-circuit test machine and repair operator"
    },
    {
        "value": 25336,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) inspector"
    },
    {
        "value": 25337,
        "NOCCode": "9523",
        "JobTitle": "printed circuit board (PCB) inspector and tester – electronic equipment manufacturing"
    },
    {
        "value": 25338,
        "NOCCode": "9523",
        "JobTitle": "process inspector – electronic equipment manufacturing"
    },
    {
        "value": 25339,
        "NOCCode": "9523",
        "JobTitle": "process technician – electronic equipment manufacturing"
    },
    {
        "value": 25340,
        "NOCCode": "9523",
        "JobTitle": "process tester – electronic equipment manufacturing"
    },
    {
        "value": 25341,
        "NOCCode": "9523",
        "JobTitle": "production operator – electronic equipment manufacturing"
    },
    {
        "value": 25342,
        "NOCCode": "9523",
        "JobTitle": "production tester – electronic equipment manufacturing"
    },
    {
        "value": 25343,
        "NOCCode": "9523",
        "JobTitle": "quartz crystal etcher and plater – electronic equipment manufacturing"
    },
    {
        "value": 25344,
        "NOCCode": "9523",
        "JobTitle": "radar-indicator inspector – electronic equipment manufacturing"
    },
    {
        "value": 25345,
        "NOCCode": "9523",
        "JobTitle": "radar-receiver assembler"
    },
    {
        "value": 25346,
        "NOCCode": "9523",
        "JobTitle": "radiation-detector assembler"
    },
    {
        "value": 25347,
        "NOCCode": "9523",
        "JobTitle": "radio assembler and installer"
    },
    {
        "value": 25348,
        "NOCCode": "9523",
        "JobTitle": "resistor assembler – electronic equipment manufacturing"
    },
    {
        "value": 25349,
        "NOCCode": "9523",
        "JobTitle": "resistor machine feeder"
    },
    {
        "value": 25350,
        "NOCCode": "9523",
        "JobTitle": "resistor-processing equipment operator"
    },
    {
        "value": 25351,
        "NOCCode": "9523",
        "JobTitle": "rim bander, television picture tube"
    },
    {
        "value": 25352,
        "NOCCode": "9523",
        "JobTitle": "salvage operator – electronic equipment manufacturing"
    },
    {
        "value": 25353,
        "NOCCode": "9523",
        "JobTitle": "screen aluminizer, picture tube assemblies"
    },
    {
        "value": 25354,
        "NOCCode": "9523",
        "JobTitle": "semiconductor assembler"
    },
    {
        "value": 25355,
        "NOCCode": "9523",
        "JobTitle": "semiconductor calibrator"
    },
    {
        "value": 25356,
        "NOCCode": "9523",
        "JobTitle": "semiconductor material gauger – electronic equipment manufacturing"
    },
    {
        "value": 25357,
        "NOCCode": "9523",
        "JobTitle": "semiconductor mounter"
    },
    {
        "value": 25358,
        "NOCCode": "9523",
        "JobTitle": "setter, exhaust equipment – electronic equipment manufacturing"
    },
    {
        "value": 25359,
        "NOCCode": "9523",
        "JobTitle": "silicon wafer fabricator"
    },
    {
        "value": 25360,
        "NOCCode": "9523",
        "JobTitle": "silicon wafer grower"
    },
    {
        "value": 25361,
        "NOCCode": "9523",
        "JobTitle": "speaker assembler"
    },
    {
        "value": 25362,
        "NOCCode": "9523",
        "JobTitle": "subassembler, electronic equipment"
    },
    {
        "value": 25363,
        "NOCCode": "9523",
        "JobTitle": "surface mount assembler"
    },
    {
        "value": 25364,
        "NOCCode": "9523",
        "JobTitle": "surface mount assembler – electronic equipment manufacturing"
    },
    {
        "value": 25365,
        "NOCCode": "9523",
        "JobTitle": "tape cartridge and cassette assembler"
    },
    {
        "value": 25366,
        "NOCCode": "9523",
        "JobTitle": "tape cartridge and cassette inspector"
    },
    {
        "value": 25367,
        "NOCCode": "9523",
        "JobTitle": "telephone assembler"
    },
    {
        "value": 25368,
        "NOCCode": "9523",
        "JobTitle": "television assembler"
    },
    {
        "value": 25369,
        "NOCCode": "9523",
        "JobTitle": "television assembly chief inspector"
    },
    {
        "value": 25370,
        "NOCCode": "9523",
        "JobTitle": "television picture tube laminator"
    },
    {
        "value": 25371,
        "NOCCode": "9523",
        "JobTitle": "television picture tube tester – electronic equipment manufacturing"
    },
    {
        "value": 25372,
        "NOCCode": "9523",
        "JobTitle": "television receiver inspector and tester – electronic equipment manufacturing"
    },
    {
        "value": 25373,
        "NOCCode": "9523",
        "JobTitle": "television tube assembler"
    },
    {
        "value": 25374,
        "NOCCode": "9523",
        "JobTitle": "tension meter assembler – electronic equipment manufacturing"
    },
    {
        "value": 25375,
        "NOCCode": "9523",
        "JobTitle": "test and trim operator – electronic equipment manufacturing"
    },
    {
        "value": 25376,
        "NOCCode": "9523",
        "JobTitle": "tester, electronic components"
    },
    {
        "value": 25377,
        "NOCCode": "9523",
        "JobTitle": "testing machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25378,
        "NOCCode": "9523",
        "JobTitle": "through-hole assembler"
    },
    {
        "value": 25379,
        "NOCCode": "9523",
        "JobTitle": "through-hole assembler – electronic equipment manufacturing"
    },
    {
        "value": 25380,
        "NOCCode": "9523",
        "JobTitle": "transistor assembler"
    },
    {
        "value": 25381,
        "NOCCode": "9523",
        "JobTitle": "tube assembler – electronic equipment manufacturing"
    },
    {
        "value": 25382,
        "NOCCode": "9523",
        "JobTitle": "tube tester"
    },
    {
        "value": 25383,
        "NOCCode": "9523",
        "JobTitle": "TV set inspector and tester – electronic equipment manufacturing"
    },
    {
        "value": 25384,
        "NOCCode": "9523",
        "JobTitle": "type-bar-and-segment assembler"
    },
    {
        "value": 25385,
        "NOCCode": "9523",
        "JobTitle": "typewriter aligner"
    },
    {
        "value": 25386,
        "NOCCode": "9523",
        "JobTitle": "typewriter inspector, parts and assemblies"
    },
    {
        "value": 25387,
        "NOCCode": "9523",
        "JobTitle": "typewriter parts salvager"
    },
    {
        "value": 25388,
        "NOCCode": "9523",
        "JobTitle": "utility operator – electronic equipment manufacturing"
    },
    {
        "value": 25389,
        "NOCCode": "9523",
        "JobTitle": "vacuum evaporation process plater – electronic equipment manufacturing"
    },
    {
        "value": 25390,
        "NOCCode": "9523",
        "JobTitle": "variable capacitor tester"
    },
    {
        "value": 25391,
        "NOCCode": "9523",
        "JobTitle": "variable resistor assembler"
    },
    {
        "value": 25392,
        "NOCCode": "9523",
        "JobTitle": "wafer fabrication operator"
    },
    {
        "value": 25393,
        "NOCCode": "9523",
        "JobTitle": "wafer fabrication operator – electronic equipment manufacturing"
    },
    {
        "value": 25394,
        "NOCCode": "9523",
        "JobTitle": "wafer technician – electronic equipment manufacturing"
    },
    {
        "value": 25395,
        "NOCCode": "9523",
        "JobTitle": "wave soldering machine operator"
    },
    {
        "value": 25396,
        "NOCCode": "9523",
        "JobTitle": "wave soldering machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25397,
        "NOCCode": "9523",
        "JobTitle": "wire and harness assembler"
    },
    {
        "value": 25398,
        "NOCCode": "9523",
        "JobTitle": "wire bonder – electronic equipment manufacturing"
    },
    {
        "value": 25399,
        "NOCCode": "9523",
        "JobTitle": "wire soldering operator"
    },
    {
        "value": 25400,
        "NOCCode": "9523",
        "JobTitle": "wire wound resistor calibrator"
    },
    {
        "value": 25401,
        "NOCCode": "9523",
        "JobTitle": "wire wound resistor calibrator – electronic equipment manufacturing"
    },
    {
        "value": 25402,
        "NOCCode": "9523",
        "JobTitle": "wire wound resistor inspector – electronic equipment manufacturing"
    },
    {
        "value": 25403,
        "NOCCode": "9523",
        "JobTitle": "wire-cutting machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25404,
        "NOCCode": "9523",
        "JobTitle": "wire-wrapping machine operator – electronic equipment manufacturing"
    },
    {
        "value": 25405,
        "NOCCode": "9523",
        "JobTitle": "wiring and assembly operator"
    },
    {
        "value": 25406,
        "NOCCode": "9523",
        "JobTitle": "wiring and soldering operator – electronic equipment manufacturing"
    },
    {
        "value": 25407,
        "NOCCode": "9524",
        "JobTitle": "adjuster, coils – electrical equipment manufacturing"
    },
    {
        "value": 25408,
        "NOCCode": "9524",
        "JobTitle": "air conditioner coil assembler"
    },
    {
        "value": 25409,
        "NOCCode": "9524",
        "JobTitle": "air conditioning unit tester"
    },
    {
        "value": 25410,
        "NOCCode": "9524",
        "JobTitle": "armature bander"
    },
    {
        "value": 25411,
        "NOCCode": "9524",
        "JobTitle": "armature winder, small electric motor"
    },
    {
        "value": 25412,
        "NOCCode": "9524",
        "JobTitle": "assembler air conditioner"
    },
    {
        "value": 25413,
        "NOCCode": "9524",
        "JobTitle": "assembler, air conditioner coil"
    },
    {
        "value": 25414,
        "NOCCode": "9524",
        "JobTitle": "assembler, air conditioning"
    },
    {
        "value": 25415,
        "NOCCode": "9524",
        "JobTitle": "assembler, ballast transformers"
    },
    {
        "value": 25416,
        "NOCCode": "9524",
        "JobTitle": "assembler, bushings – small transformer manufacturing"
    },
    {
        "value": 25417,
        "NOCCode": "9524",
        "JobTitle": "assembler, circuit breakers"
    },
    {
        "value": 25418,
        "NOCCode": "9524",
        "JobTitle": "assembler, commercial refrigerators"
    },
    {
        "value": 25419,
        "NOCCode": "9524",
        "JobTitle": "assembler, compact discs"
    },
    {
        "value": 25420,
        "NOCCode": "9524",
        "JobTitle": "assembler, cooling units – electrical equipment manufacturing"
    },
    {
        "value": 25421,
        "NOCCode": "9524",
        "JobTitle": "assembler, dishwashers"
    },
    {
        "value": 25422,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric air conditioning units"
    },
    {
        "value": 25423,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric baseboard heaters"
    },
    {
        "value": 25424,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric clocks"
    },
    {
        "value": 25425,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric clothes dryers"
    },
    {
        "value": 25426,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric coffee makers"
    },
    {
        "value": 25427,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric fans"
    },
    {
        "value": 25428,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric heaters"
    },
    {
        "value": 25429,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric lamps"
    },
    {
        "value": 25430,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric lawn mowers"
    },
    {
        "value": 25431,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric meters"
    },
    {
        "value": 25432,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric refrigerators"
    },
    {
        "value": 25433,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric signs"
    },
    {
        "value": 25434,
        "NOCCode": "9524",
        "JobTitle": "assembler, electric toasters"
    },
    {
        "value": 25435,
        "NOCCode": "9524",
        "JobTitle": "assembler, electrical accessories"
    },
    {
        "value": 25436,
        "NOCCode": "9524",
        "JobTitle": "assembler, electrical appliances"
    },
    {
        "value": 25437,
        "NOCCode": "9524",
        "JobTitle": "assembler, electrical equipment"
    },
    {
        "value": 25438,
        "NOCCode": "9524",
        "JobTitle": "assembler, electrical switches"
    },
    {
        "value": 25439,
        "NOCCode": "9524",
        "JobTitle": "assembler, electrical wire groups"
    },
    {
        "value": 25440,
        "NOCCode": "9524",
        "JobTitle": "assembler, engine generator sets"
    },
    {
        "value": 25441,
        "NOCCode": "9524",
        "JobTitle": "assembler, fluorescent light fixtures"
    },
    {
        "value": 25442,
        "NOCCode": "9524",
        "JobTitle": "assembler, heavy current switches"
    },
    {
        "value": 25443,
        "NOCCode": "9524",
        "JobTitle": "assembler, heavy-duty capacitors"
    },
    {
        "value": 25444,
        "NOCCode": "9524",
        "JobTitle": "assembler, heavy-duty resistors"
    },
    {
        "value": 25445,
        "NOCCode": "9524",
        "JobTitle": "assembler, large electrical appliances"
    },
    {
        "value": 25446,
        "NOCCode": "9524",
        "JobTitle": "assembler, light fixtures"
    },
    {
        "value": 25447,
        "NOCCode": "9524",
        "JobTitle": "assembler, lighting fixtures"
    },
    {
        "value": 25448,
        "NOCCode": "9524",
        "JobTitle": "assembler, motor and generator – electrical equipment manufacturing"
    },
    {
        "value": 25449,
        "NOCCode": "9524",
        "JobTitle": "assembler, motor vehicle lighting fixtures"
    },
    {
        "value": 25450,
        "NOCCode": "9524",
        "JobTitle": "assembler, neon signs – electrical equipment manufacturing"
    },
    {
        "value": 25451,
        "NOCCode": "9524",
        "JobTitle": "assembler, portable electrical appliance"
    },
    {
        "value": 25452,
        "NOCCode": "9524",
        "JobTitle": "assembler, quartz lamps"
    },
    {
        "value": 25453,
        "NOCCode": "9524",
        "JobTitle": "assembler, small electric motor"
    },
    {
        "value": 25454,
        "NOCCode": "9524",
        "JobTitle": "assembler, small electrical appliances"
    },
    {
        "value": 25455,
        "NOCCode": "9524",
        "JobTitle": "assembler, small electrical products"
    },
    {
        "value": 25456,
        "NOCCode": "9524",
        "JobTitle": "assembler, small transformer"
    },
    {
        "value": 25457,
        "NOCCode": "9524",
        "JobTitle": "assembler, spark plugs"
    },
    {
        "value": 25458,
        "NOCCode": "9524",
        "JobTitle": "assembler, thermostat"
    },
    {
        "value": 25459,
        "NOCCode": "9524",
        "JobTitle": "assembler, toggle switches – electrical equipment manufacturing"
    },
    {
        "value": 25460,
        "NOCCode": "9524",
        "JobTitle": "assembler, transformer static plate"
    },
    {
        "value": 25461,
        "NOCCode": "9524",
        "JobTitle": "assembler, vacuum cleaners"
    },
    {
        "value": 25462,
        "NOCCode": "9524",
        "JobTitle": "assembler, washing machines – electrical equipment manufacturing"
    },
    {
        "value": 25463,
        "NOCCode": "9524",
        "JobTitle": "assembly inspector, electrical appliances"
    },
    {
        "value": 25464,
        "NOCCode": "9524",
        "JobTitle": "assembly lead hand – electrical equipment manufacturing"
    },
    {
        "value": 25465,
        "NOCCode": "9524",
        "JobTitle": "assembly lead hand, large electrical appliances"
    },
    {
        "value": 25466,
        "NOCCode": "9524",
        "JobTitle": "assembly lead hand, small electrical appliances"
    },
    {
        "value": 25467,
        "NOCCode": "9524",
        "JobTitle": "assembly line expediter – electrical equipment manufacturing"
    },
    {
        "value": 25468,
        "NOCCode": "9524",
        "JobTitle": "assembly line inspector – electrical equipment manufacturing"
    },
    {
        "value": 25469,
        "NOCCode": "9524",
        "JobTitle": "assembly line inspector and tester – electrical equipment manufacturing"
    },
    {
        "value": 25470,
        "NOCCode": "9524",
        "JobTitle": "assembly line repairer, electrical appliances"
    },
    {
        "value": 25471,
        "NOCCode": "9524",
        "JobTitle": "assembly line worker, electric heating systems"
    },
    {
        "value": 25472,
        "NOCCode": "9524",
        "JobTitle": "assembly section leader, lighting fixtures"
    },
    {
        "value": 25473,
        "NOCCode": "9524",
        "JobTitle": "assembly, hair dryers"
    },
    {
        "value": 25474,
        "NOCCode": "9524",
        "JobTitle": "automatic coil winder operator – electrical equipment manufacturing"
    },
    {
        "value": 25475,
        "NOCCode": "9524",
        "JobTitle": "ballast transformer assembler"
    },
    {
        "value": 25476,
        "NOCCode": "9524",
        "JobTitle": "ballast transformer tester"
    },
    {
        "value": 25477,
        "NOCCode": "9524",
        "JobTitle": "bench assembler, electrical appliances"
    },
    {
        "value": 25478,
        "NOCCode": "9524",
        "JobTitle": "bench worker, electrical appliances"
    },
    {
        "value": 25479,
        "NOCCode": "9524",
        "JobTitle": "bushing assembler – small transformer manufacturing"
    },
    {
        "value": 25480,
        "NOCCode": "9524",
        "JobTitle": "calibrator, electrical controls"
    },
    {
        "value": 25481,
        "NOCCode": "9524",
        "JobTitle": "calibrator, thermostats – electrical equipment manufacturing"
    },
    {
        "value": 25482,
        "NOCCode": "9524",
        "JobTitle": "carbon brush inspector"
    },
    {
        "value": 25483,
        "NOCCode": "9524",
        "JobTitle": "carbon products inspector"
    },
    {
        "value": 25484,
        "NOCCode": "9524",
        "JobTitle": "circuit breaker assembler"
    },
    {
        "value": 25485,
        "NOCCode": "9524",
        "JobTitle": "circuit breaker assembly tester"
    },
    {
        "value": 25486,
        "NOCCode": "9524",
        "JobTitle": "clothes dryer assembler, electric"
    },
    {
        "value": 25487,
        "NOCCode": "9524",
        "JobTitle": "coffee maker assembler"
    },
    {
        "value": 25488,
        "NOCCode": "9524",
        "JobTitle": "coil adjuster – electrical equipment manufacturing"
    },
    {
        "value": 25489,
        "NOCCode": "9524",
        "JobTitle": "coil finisher – electrical equipment manufacturing"
    },
    {
        "value": 25490,
        "NOCCode": "9524",
        "JobTitle": "coil taper – electrical equipment manufacturing"
    },
    {
        "value": 25491,
        "NOCCode": "9524",
        "JobTitle": "coil-winding machine tender – automatic electrical equipment"
    },
    {
        "value": 25492,
        "NOCCode": "9524",
        "JobTitle": "coil-winding machine tender, automatic – electrical equipment manufacturing"
    },
    {
        "value": 25493,
        "NOCCode": "9524",
        "JobTitle": "commercial refrigerator assembler"
    },
    {
        "value": 25494,
        "NOCCode": "9524",
        "JobTitle": "compact disc assembler"
    },
    {
        "value": 25495,
        "NOCCode": "9524",
        "JobTitle": "connector assembler – electrical equipment manufacturing"
    },
    {
        "value": 25496,
        "NOCCode": "9524",
        "JobTitle": "cooling unit assembler – electrical equipment manufacturing"
    },
    {
        "value": 25497,
        "NOCCode": "9524",
        "JobTitle": "core builder, small transformers"
    },
    {
        "value": 25498,
        "NOCCode": "9524",
        "JobTitle": "cutter and former, insulation – electrical equipment manufacturing"
    },
    {
        "value": 25499,
        "NOCCode": "9524",
        "JobTitle": "dishwasher assembler"
    },
    {
        "value": 25500,
        "NOCCode": "9524",
        "JobTitle": "electric air conditioning assembler"
    },
    {
        "value": 25501,
        "NOCCode": "9524",
        "JobTitle": "electric appliance manufacturing inspector"
    },
    {
        "value": 25502,
        "NOCCode": "9524",
        "JobTitle": "electric baseboard heater assembler"
    },
    {
        "value": 25503,
        "NOCCode": "9524",
        "JobTitle": "electric clock assembler"
    },
    {
        "value": 25504,
        "NOCCode": "9524",
        "JobTitle": "electric clock components inspector"
    },
    {
        "value": 25505,
        "NOCCode": "9524",
        "JobTitle": "electric clock parts inspector"
    },
    {
        "value": 25506,
        "NOCCode": "9524",
        "JobTitle": "electric coffee maker assembler"
    },
    {
        "value": 25507,
        "NOCCode": "9524",
        "JobTitle": "electric contact tester"
    },
    {
        "value": 25508,
        "NOCCode": "9524",
        "JobTitle": "electric fan assembler"
    },
    {
        "value": 25509,
        "NOCCode": "9524",
        "JobTitle": "electric heating system assembly line worker"
    },
    {
        "value": 25510,
        "NOCCode": "9524",
        "JobTitle": "electric lamp inspector and tester"
    },
    {
        "value": 25511,
        "NOCCode": "9524",
        "JobTitle": "electric lamp maker"
    },
    {
        "value": 25512,
        "NOCCode": "9524",
        "JobTitle": "electric lamp parts inspector"
    },
    {
        "value": 25513,
        "NOCCode": "9524",
        "JobTitle": "electric lamp wirer"
    },
    {
        "value": 25514,
        "NOCCode": "9524",
        "JobTitle": "electric lantern maker"
    },
    {
        "value": 25515,
        "NOCCode": "9524",
        "JobTitle": "electric lawn mower inspector and tester"
    },
    {
        "value": 25516,
        "NOCCode": "9524",
        "JobTitle": "electric range assembler"
    },
    {
        "value": 25517,
        "NOCCode": "9524",
        "JobTitle": "electric sign assembler"
    },
    {
        "value": 25518,
        "NOCCode": "9524",
        "JobTitle": "electric snowblower inspector and tester"
    },
    {
        "value": 25519,
        "NOCCode": "9524",
        "JobTitle": "electric stove assembler"
    },
    {
        "value": 25520,
        "NOCCode": "9524",
        "JobTitle": "electric toaster inspector"
    },
    {
        "value": 25521,
        "NOCCode": "9524",
        "JobTitle": "electrical accessories assembler"
    },
    {
        "value": 25522,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance assembly inspector"
    },
    {
        "value": 25523,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance assembly tester"
    },
    {
        "value": 25524,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance bench assembler"
    },
    {
        "value": 25525,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance bench worker"
    },
    {
        "value": 25526,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance inspector"
    },
    {
        "value": 25527,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance operator-assembler"
    },
    {
        "value": 25528,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance production assembler"
    },
    {
        "value": 25529,
        "NOCCode": "9524",
        "JobTitle": "electrical appliance repairer, production line"
    },
    {
        "value": 25530,
        "NOCCode": "9524",
        "JobTitle": "electrical continuity tester – electrical appliance manufacturing"
    },
    {
        "value": 25531,
        "NOCCode": "9524",
        "JobTitle": "electrical control tester"
    },
    {
        "value": 25532,
        "NOCCode": "9524",
        "JobTitle": "electrical controls calibrator"
    },
    {
        "value": 25533,
        "NOCCode": "9524",
        "JobTitle": "electrical controls inspector"
    },
    {
        "value": 25534,
        "NOCCode": "9524",
        "JobTitle": "electrical equipment assembler"
    },
    {
        "value": 25535,
        "NOCCode": "9524",
        "JobTitle": "electrical equipment assembly line setter"
    },
    {
        "value": 25536,
        "NOCCode": "9524",
        "JobTitle": "electrical equipment manufacturing inspector"
    },
    {
        "value": 25537,
        "NOCCode": "9524",
        "JobTitle": "electrical equipment manufacturing utility operator"
    },
    {
        "value": 25538,
        "NOCCode": "9524",
        "JobTitle": "electrical household appliance assembler"
    },
    {
        "value": 25539,
        "NOCCode": "9524",
        "JobTitle": "electrical household appliance inspector"
    },
    {
        "value": 25540,
        "NOCCode": "9524",
        "JobTitle": "electrical inspector – manufacturing"
    },
    {
        "value": 25541,
        "NOCCode": "9524",
        "JobTitle": "electrical insulator assembler – small transformer manufacturing"
    },
    {
        "value": 25542,
        "NOCCode": "9524",
        "JobTitle": "electrical lighting fixture tester"
    },
    {
        "value": 25543,
        "NOCCode": "9524",
        "JobTitle": "electrical mercury switch assembler"
    },
    {
        "value": 25544,
        "NOCCode": "9524",
        "JobTitle": "electrical mercury switch assembly tester"
    },
    {
        "value": 25545,
        "NOCCode": "9524",
        "JobTitle": "electrical meter assembler"
    },
    {
        "value": 25546,
        "NOCCode": "9524",
        "JobTitle": "electrical relay tester and adjuster"
    },
    {
        "value": 25547,
        "NOCCode": "9524",
        "JobTitle": "electrical switch assembler"
    },
    {
        "value": 25548,
        "NOCCode": "9524",
        "JobTitle": "electrical switch maker"
    },
    {
        "value": 25549,
        "NOCCode": "9524",
        "JobTitle": "electrical tester, manufacturing"
    },
    {
        "value": 25550,
        "NOCCode": "9524",
        "JobTitle": "electrical wire group assembler – electrical equipment manufacturing"
    },
    {
        "value": 25551,
        "NOCCode": "9524",
        "JobTitle": "electrical wire insulation tester"
    },
    {
        "value": 25552,
        "NOCCode": "9524",
        "JobTitle": "electrolysis tester – electrical equipment manufacturing"
    },
    {
        "value": 25553,
        "NOCCode": "9524",
        "JobTitle": "engine generator set assembler"
    },
    {
        "value": 25554,
        "NOCCode": "9524",
        "JobTitle": "environmental chamber fitter-assembler"
    },
    {
        "value": 25555,
        "NOCCode": "9524",
        "JobTitle": "filament mounter, hand"
    },
    {
        "value": 25556,
        "NOCCode": "9524",
        "JobTitle": "finisher, coils – electrical equipment manufacturing"
    },
    {
        "value": 25557,
        "NOCCode": "9524",
        "JobTitle": "fitter-assembler, environmental chamber"
    },
    {
        "value": 25558,
        "NOCCode": "9524",
        "JobTitle": "fluorescent lighting fixture assembler"
    },
    {
        "value": 25559,
        "NOCCode": "9524",
        "JobTitle": "furnace electrode inspector"
    },
    {
        "value": 25560,
        "NOCCode": "9524",
        "JobTitle": "gas charger, refrigeration equipment"
    },
    {
        "value": 25561,
        "NOCCode": "9524",
        "JobTitle": "grid inspector – electrical equipment manufacturing"
    },
    {
        "value": 25562,
        "NOCCode": "9524",
        "JobTitle": "group leader, large electrical appliance assembly"
    },
    {
        "value": 25563,
        "NOCCode": "9524",
        "JobTitle": "group leader, small electrical appliance assembly"
    },
    {
        "value": 25564,
        "NOCCode": "9524",
        "JobTitle": "hair dryer assembler"
    },
    {
        "value": 25565,
        "NOCCode": "9524",
        "JobTitle": "hand filament mounter"
    },
    {
        "value": 25566,
        "NOCCode": "9524",
        "JobTitle": "hand mounter, filaments"
    },
    {
        "value": 25567,
        "NOCCode": "9524",
        "JobTitle": "heavy-duty capacitor assembler"
    },
    {
        "value": 25568,
        "NOCCode": "9524",
        "JobTitle": "heavy-duty resistor assembler"
    },
    {
        "value": 25569,
        "NOCCode": "9524",
        "JobTitle": "in-process inspector – electric equipment manufacturing"
    },
    {
        "value": 25570,
        "NOCCode": "9524",
        "JobTitle": "inspector – electrical appliance assembly"
    },
    {
        "value": 25571,
        "NOCCode": "9524",
        "JobTitle": "inspector and tester, electric lamps"
    },
    {
        "value": 25572,
        "NOCCode": "9524",
        "JobTitle": "inspector and tester, electric lawn mower"
    },
    {
        "value": 25573,
        "NOCCode": "9524",
        "JobTitle": "inspector and tester, electric snowblower"
    },
    {
        "value": 25574,
        "NOCCode": "9524",
        "JobTitle": "inspector, assembly line – electric equipment manufacturing"
    },
    {
        "value": 25575,
        "NOCCode": "9524",
        "JobTitle": "inspector, carbon brushes – electrical equipment manufacturing"
    },
    {
        "value": 25576,
        "NOCCode": "9524",
        "JobTitle": "inspector, carbon products"
    },
    {
        "value": 25577,
        "NOCCode": "9524",
        "JobTitle": "inspector, electric appliance manufacturing"
    },
    {
        "value": 25578,
        "NOCCode": "9524",
        "JobTitle": "inspector, electric clock components"
    },
    {
        "value": 25579,
        "NOCCode": "9524",
        "JobTitle": "inspector, electric clock parts"
    },
    {
        "value": 25580,
        "NOCCode": "9524",
        "JobTitle": "inspector, electric controls"
    },
    {
        "value": 25581,
        "NOCCode": "9524",
        "JobTitle": "inspector, electrical appliance assembly"
    },
    {
        "value": 25582,
        "NOCCode": "9524",
        "JobTitle": "inspector, electrical appliances"
    },
    {
        "value": 25583,
        "NOCCode": "9524",
        "JobTitle": "inspector, electrical controls assembly"
    },
    {
        "value": 25584,
        "NOCCode": "9524",
        "JobTitle": "inspector, electrical equipment manufacturing"
    },
    {
        "value": 25585,
        "NOCCode": "9524",
        "JobTitle": "inspector, electrical household appliances"
    },
    {
        "value": 25586,
        "NOCCode": "9524",
        "JobTitle": "inspector, furnace electrodes – electrical equipment manufacturing"
    },
    {
        "value": 25587,
        "NOCCode": "9524",
        "JobTitle": "inspector, grids – electrical equipment manufacturing"
    },
    {
        "value": 25588,
        "NOCCode": "9524",
        "JobTitle": "inspector, refrigeration unit"
    },
    {
        "value": 25589,
        "NOCCode": "9524",
        "JobTitle": "inspector, refrigerators – electrical appliance manufacturing"
    },
    {
        "value": 25590,
        "NOCCode": "9524",
        "JobTitle": "inspector, small electrical appliances"
    },
    {
        "value": 25591,
        "NOCCode": "9524",
        "JobTitle": "inspector, small transformers"
    },
    {
        "value": 25592,
        "NOCCode": "9524",
        "JobTitle": "inspector, thermostats – electrical equipment manufacturing"
    },
    {
        "value": 25593,
        "NOCCode": "9524",
        "JobTitle": "installer, insulation – electrical equipment manufacturing"
    },
    {
        "value": 25594,
        "NOCCode": "9524",
        "JobTitle": "insulation cutter and former – electrical equipment manufacturing"
    },
    {
        "value": 25595,
        "NOCCode": "9524",
        "JobTitle": "insulation installer – electrical equipment manufacturing"
    },
    {
        "value": 25596,
        "NOCCode": "9524",
        "JobTitle": "large electrical appliance assembly lead hand"
    },
    {
        "value": 25597,
        "NOCCode": "9524",
        "JobTitle": "lawn mower assembler, electric"
    },
    {
        "value": 25598,
        "NOCCode": "9524",
        "JobTitle": "lead hand, assembly – electrical equipment manufacturing"
    },
    {
        "value": 25599,
        "NOCCode": "9524",
        "JobTitle": "lead hand, large electrical appliance assembly"
    },
    {
        "value": 25600,
        "NOCCode": "9524",
        "JobTitle": "lead hand, small electrical appliance assembly"
    },
    {
        "value": 25601,
        "NOCCode": "9524",
        "JobTitle": "leak tester, refrigerant gases"
    },
    {
        "value": 25602,
        "NOCCode": "9524",
        "JobTitle": "light fixtures assembler"
    },
    {
        "value": 25603,
        "NOCCode": "9524",
        "JobTitle": "lighting fixture assembler"
    },
    {
        "value": 25604,
        "NOCCode": "9524",
        "JobTitle": "lighting fixture assembly section leader"
    },
    {
        "value": 25605,
        "NOCCode": "9524",
        "JobTitle": "lighting fixtures wirer"
    },
    {
        "value": 25606,
        "NOCCode": "9524",
        "JobTitle": "line assembly utilityman/woman – electrical equipment manufacturing"
    },
    {
        "value": 25607,
        "NOCCode": "9524",
        "JobTitle": "line assembly, utilityman/woman"
    },
    {
        "value": 25608,
        "NOCCode": "9524",
        "JobTitle": "mica products maker"
    },
    {
        "value": 25609,
        "NOCCode": "9524",
        "JobTitle": "motor and generator assembler – electrical equipment manufacturing"
    },
    {
        "value": 25610,
        "NOCCode": "9524",
        "JobTitle": "motor vehicle lighting fixture assembler"
    },
    {
        "value": 25611,
        "NOCCode": "9524",
        "JobTitle": "neon sign builder – electrical equipment manufacturing"
    },
    {
        "value": 25612,
        "NOCCode": "9524",
        "JobTitle": "neon sign maker"
    },
    {
        "value": 25613,
        "NOCCode": "9524",
        "JobTitle": "operator, transformer coil impregnator – electrical equipment manufacturing"
    },
    {
        "value": 25614,
        "NOCCode": "9524",
        "JobTitle": "operator-assembler, electrical appliances"
    },
    {
        "value": 25615,
        "NOCCode": "9524",
        "JobTitle": "portable electrical appliance assembler"
    },
    {
        "value": 25616,
        "NOCCode": "9524",
        "JobTitle": "process-inspector and tester – electrical equipment manufacturing"
    },
    {
        "value": 25617,
        "NOCCode": "9524",
        "JobTitle": "production assembler – electrical equipment manufacturing"
    },
    {
        "value": 25618,
        "NOCCode": "9524",
        "JobTitle": "production assembler, electrical appliances"
    },
    {
        "value": 25619,
        "NOCCode": "9524",
        "JobTitle": "production assembler, electrical equipment"
    },
    {
        "value": 25620,
        "NOCCode": "9524",
        "JobTitle": "quartz lamp assembler"
    },
    {
        "value": 25621,
        "NOCCode": "9524",
        "JobTitle": "refrigerant gas leak tester"
    },
    {
        "value": 25622,
        "NOCCode": "9524",
        "JobTitle": "refrigeration equipment gas charger"
    },
    {
        "value": 25623,
        "NOCCode": "9524",
        "JobTitle": "refrigeration unit inspector"
    },
    {
        "value": 25624,
        "NOCCode": "9524",
        "JobTitle": "refrigerator assembler, electric"
    },
    {
        "value": 25625,
        "NOCCode": "9524",
        "JobTitle": "refrigerator inspector – electrical appliance manufacturing"
    },
    {
        "value": 25626,
        "NOCCode": "9524",
        "JobTitle": "section leader, light fixture assembly"
    },
    {
        "value": 25627,
        "NOCCode": "9524",
        "JobTitle": "small electric motor assembler"
    },
    {
        "value": 25628,
        "NOCCode": "9524",
        "JobTitle": "small electric motor core and shaft assembler"
    },
    {
        "value": 25629,
        "NOCCode": "9524",
        "JobTitle": "small electric motor tester"
    },
    {
        "value": 25630,
        "NOCCode": "9524",
        "JobTitle": "small electrical appliance assembler"
    },
    {
        "value": 25631,
        "NOCCode": "9524",
        "JobTitle": "small electrical appliance assembly lead hand"
    },
    {
        "value": 25632,
        "NOCCode": "9524",
        "JobTitle": "small electrical appliances inspector"
    },
    {
        "value": 25633,
        "NOCCode": "9524",
        "JobTitle": "small electrical products assembler"
    },
    {
        "value": 25634,
        "NOCCode": "9524",
        "JobTitle": "small transformer assembler – electrical equipment manufacturing"
    },
    {
        "value": 25635,
        "NOCCode": "9524",
        "JobTitle": "small transformer core builder"
    },
    {
        "value": 25636,
        "NOCCode": "9524",
        "JobTitle": "small transformer inspector"
    },
    {
        "value": 25637,
        "NOCCode": "9524",
        "JobTitle": "small transformer tester"
    },
    {
        "value": 25638,
        "NOCCode": "9524",
        "JobTitle": "spark plug assembler"
    },
    {
        "value": 25639,
        "NOCCode": "9524",
        "JobTitle": "temperature-actuated controls tester"
    },
    {
        "value": 25640,
        "NOCCode": "9524",
        "JobTitle": "tester and adjuster, electrical relays"
    },
    {
        "value": 25641,
        "NOCCode": "9524",
        "JobTitle": "tester, air conditioning unit"
    },
    {
        "value": 25642,
        "NOCCode": "9524",
        "JobTitle": "tester, ballast transformers"
    },
    {
        "value": 25643,
        "NOCCode": "9524",
        "JobTitle": "tester, circuit breaker assembly"
    },
    {
        "value": 25644,
        "NOCCode": "9524",
        "JobTitle": "tester, electric contacts"
    },
    {
        "value": 25645,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical appliance assembly"
    },
    {
        "value": 25646,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical continuity"
    },
    {
        "value": 25647,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical control"
    },
    {
        "value": 25648,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical equipment manufacturing"
    },
    {
        "value": 25649,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical light fixtures"
    },
    {
        "value": 25650,
        "NOCCode": "9524",
        "JobTitle": "tester, electrical wire insulation"
    },
    {
        "value": 25651,
        "NOCCode": "9524",
        "JobTitle": "tester, electrolysis – electrical equipment manufacturing"
    },
    {
        "value": 25652,
        "NOCCode": "9524",
        "JobTitle": "tester, refrigerant gas leaks"
    },
    {
        "value": 25653,
        "NOCCode": "9524",
        "JobTitle": "tester, small electric motors"
    },
    {
        "value": 25654,
        "NOCCode": "9524",
        "JobTitle": "tester, small transformers"
    },
    {
        "value": 25655,
        "NOCCode": "9524",
        "JobTitle": "tester, temperature-actuated controls"
    },
    {
        "value": 25656,
        "NOCCode": "9524",
        "JobTitle": "thermostat assembler – electrical equipment manufacturing"
    },
    {
        "value": 25657,
        "NOCCode": "9524",
        "JobTitle": "thermostat calibrator"
    },
    {
        "value": 25658,
        "NOCCode": "9524",
        "JobTitle": "thermostat inspector"
    },
    {
        "value": 25659,
        "NOCCode": "9524",
        "JobTitle": "toaster assembler"
    },
    {
        "value": 25660,
        "NOCCode": "9524",
        "JobTitle": "toggle switch assembler – electrical equipment manufacturing"
    },
    {
        "value": 25661,
        "NOCCode": "9524",
        "JobTitle": "transformer coil impregnator operator – electrical equipment manufacturing"
    },
    {
        "value": 25662,
        "NOCCode": "9524",
        "JobTitle": "transformer static plate assembler"
    },
    {
        "value": 25663,
        "NOCCode": "9524",
        "JobTitle": "tubular heating element maker"
    },
    {
        "value": 25664,
        "NOCCode": "9524",
        "JobTitle": "utility operator, electrical equipment manufacturing"
    },
    {
        "value": 25665,
        "NOCCode": "9524",
        "JobTitle": "utilityman/woman, assembly lines – electrical equipment manufacturing"
    },
    {
        "value": 25666,
        "NOCCode": "9524",
        "JobTitle": "vacuum cleaner assembler"
    },
    {
        "value": 25667,
        "NOCCode": "9524",
        "JobTitle": "washing machine assembler – electrical equipment manufacturing"
    },
    {
        "value": 25668,
        "NOCCode": "9524",
        "JobTitle": "wire connector – electrical equipment manufacturing"
    },
    {
        "value": 25669,
        "NOCCode": "9524",
        "JobTitle": "wirer, electric lamps"
    },
    {
        "value": 25670,
        "NOCCode": "9524",
        "JobTitle": "wirer, electric lighting fixtures"
    },
    {
        "value": 25671,
        "NOCCode": "9525",
        "JobTitle": "AC (alternating current) and DC (direct current) motors fitter-assembler"
    },
    {
        "value": 25672,
        "NOCCode": "9525",
        "JobTitle": "alternating current (AC) and direct current (DC) motors fitter-assembler"
    },
    {
        "value": 25673,
        "NOCCode": "9525",
        "JobTitle": "alternator tester"
    },
    {
        "value": 25674,
        "NOCCode": "9525",
        "JobTitle": "armature assembler – industrial electrical equipment"
    },
    {
        "value": 25675,
        "NOCCode": "9525",
        "JobTitle": "armature coil former – industrial electrical equipment"
    },
    {
        "value": 25676,
        "NOCCode": "9525",
        "JobTitle": "armature coil winder – industrial electric equipment"
    },
    {
        "value": 25677,
        "NOCCode": "9525",
        "JobTitle": "armature inspector – industrial electrical equipment"
    },
    {
        "value": 25678,
        "NOCCode": "9525",
        "JobTitle": "armature insulator – industrial electrical equipment"
    },
    {
        "value": 25679,
        "NOCCode": "9525",
        "JobTitle": "armature winder – industrial electrical equipment"
    },
    {
        "value": 25680,
        "NOCCode": "9525",
        "JobTitle": "assembler and wirer, electrical panels"
    },
    {
        "value": 25681,
        "NOCCode": "9525",
        "JobTitle": "assembler and wirer, motors and generators"
    },
    {
        "value": 25682,
        "NOCCode": "9525",
        "JobTitle": "assembler and wirer, switchgear"
    },
    {
        "value": 25683,
        "NOCCode": "9525",
        "JobTitle": "assembler, carbon brushes"
    },
    {
        "value": 25684,
        "NOCCode": "9525",
        "JobTitle": "assembler, commutators"
    },
    {
        "value": 25685,
        "NOCCode": "9525",
        "JobTitle": "assembler, current transformers"
    },
    {
        "value": 25686,
        "NOCCode": "9525",
        "JobTitle": "assembler, electrical control panel"
    },
    {
        "value": 25687,
        "NOCCode": "9525",
        "JobTitle": "assembler, heavy-duty transformer"
    },
    {
        "value": 25688,
        "NOCCode": "9525",
        "JobTitle": "assembler, industrial brakes and AC (alternating current) motors"
    },
    {
        "value": 25689,
        "NOCCode": "9525",
        "JobTitle": "assembler, industrial brakes and alternating current (AC) motors"
    },
    {
        "value": 25690,
        "NOCCode": "9525",
        "JobTitle": "assembler, laminated cores – industrial electrical equipment"
    },
    {
        "value": 25691,
        "NOCCode": "9525",
        "JobTitle": "assembler, motor and generator – industrial electrical equipment"
    },
    {
        "value": 25692,
        "NOCCode": "9525",
        "JobTitle": "assembler, motor control centres – industrial electrical equipment"
    },
    {
        "value": 25693,
        "NOCCode": "9525",
        "JobTitle": "assembler, panelboards – industrial electrical equipment"
    },
    {
        "value": 25694,
        "NOCCode": "9525",
        "JobTitle": "assembler, rotating field coils – industrial electrical equipment"
    },
    {
        "value": 25695,
        "NOCCode": "9525",
        "JobTitle": "assembler, switchgear and control panel"
    },
    {
        "value": 25696,
        "NOCCode": "9525",
        "JobTitle": "assembler, switchgear and control panel – industrial electrical equipment"
    },
    {
        "value": 25697,
        "NOCCode": "9525",
        "JobTitle": "assembler, switchgear panels – industrial electrical equipment"
    },
    {
        "value": 25698,
        "NOCCode": "9525",
        "JobTitle": "assembler, switchgear racks – industrial electrical equipment"
    },
    {
        "value": 25699,
        "NOCCode": "9525",
        "JobTitle": "assembler, transformer accessories"
    },
    {
        "value": 25700,
        "NOCCode": "9525",
        "JobTitle": "assembler, transformer cores"
    },
    {
        "value": 25701,
        "NOCCode": "9525",
        "JobTitle": "assembler, transformer gas detector relays"
    },
    {
        "value": 25702,
        "NOCCode": "9525",
        "JobTitle": "automotive wiring harness inspector – industrial electrical equipment"
    },
    {
        "value": 25703,
        "NOCCode": "9525",
        "JobTitle": "builder and welder, stator and rotor cores – industrial electrical equipment"
    },
    {
        "value": 25704,
        "NOCCode": "9525",
        "JobTitle": "bushing assembler, high voltage transformers"
    },
    {
        "value": 25705,
        "NOCCode": "9525",
        "JobTitle": "bushing core winder – industrial electrical equipment"
    },
    {
        "value": 25706,
        "NOCCode": "9525",
        "JobTitle": "carbon brush assembler"
    },
    {
        "value": 25707,
        "NOCCode": "9525",
        "JobTitle": "carbon brush maker"
    },
    {
        "value": 25708,
        "NOCCode": "9525",
        "JobTitle": "circuit breaker fitter – industrial electrical equipment"
    },
    {
        "value": 25709,
        "NOCCode": "9525",
        "JobTitle": "clamper, transformer assembly"
    },
    {
        "value": 25710,
        "NOCCode": "9525",
        "JobTitle": "clamper, transformer assembly coils"
    },
    {
        "value": 25711,
        "NOCCode": "9525",
        "JobTitle": "coil assembler – industrial electrical equipment"
    },
    {
        "value": 25712,
        "NOCCode": "9525",
        "JobTitle": "coil builder – industrial electrical equipment"
    },
    {
        "value": 25713,
        "NOCCode": "9525",
        "JobTitle": "coil clamper, transformer assembly"
    },
    {
        "value": 25714,
        "NOCCode": "9525",
        "JobTitle": "coil former – industrial electrical equipment"
    },
    {
        "value": 25715,
        "NOCCode": "9525",
        "JobTitle": "coil installer, motor and generator – industrial electrical equipment"
    },
    {
        "value": 25716,
        "NOCCode": "9525",
        "JobTitle": "coil maker – industrial electrical equipment"
    },
    {
        "value": 25717,
        "NOCCode": "9525",
        "JobTitle": "coil shaper – industrial electrical equipment"
    },
    {
        "value": 25718,
        "NOCCode": "9525",
        "JobTitle": "coil taper, hand – industrial electrical equipment"
    },
    {
        "value": 25719,
        "NOCCode": "9525",
        "JobTitle": "coil winder, transformer"
    },
    {
        "value": 25720,
        "NOCCode": "9525",
        "JobTitle": "coil-winding machine setter – industrial electrical equipment"
    },
    {
        "value": 25721,
        "NOCCode": "9525",
        "JobTitle": "commutator assembler"
    },
    {
        "value": 25722,
        "NOCCode": "9525",
        "JobTitle": "commutator assembler – industrial electrical equipment"
    },
    {
        "value": 25723,
        "NOCCode": "9525",
        "JobTitle": "control panel assembler"
    },
    {
        "value": 25724,
        "NOCCode": "9525",
        "JobTitle": "control panel assembler – industrial electrical equipment"
    },
    {
        "value": 25725,
        "NOCCode": "9525",
        "JobTitle": "control panel technician – industrial electrical equipment"
    },
    {
        "value": 25726,
        "NOCCode": "9525",
        "JobTitle": "core assembler – industrial electrical equipment"
    },
    {
        "value": 25727,
        "NOCCode": "9525",
        "JobTitle": "core lapper, transformers"
    },
    {
        "value": 25728,
        "NOCCode": "9525",
        "JobTitle": "core stacker, transformers"
    },
    {
        "value": 25729,
        "NOCCode": "9525",
        "JobTitle": "current-transformer assembler"
    },
    {
        "value": 25730,
        "NOCCode": "9525",
        "JobTitle": "current-transformer coil winder"
    },
    {
        "value": 25731,
        "NOCCode": "9525",
        "JobTitle": "custom electrical panel assembler"
    },
    {
        "value": 25732,
        "NOCCode": "9525",
        "JobTitle": "electric meter tester – industrial electrical equipment"
    },
    {
        "value": 25733,
        "NOCCode": "9525",
        "JobTitle": "electric motor assembler"
    },
    {
        "value": 25734,
        "NOCCode": "9525",
        "JobTitle": "electric motor inspector"
    },
    {
        "value": 25735,
        "NOCCode": "9525",
        "JobTitle": "electrical continuity tester – industrial electrical equipment"
    },
    {
        "value": 25736,
        "NOCCode": "9525",
        "JobTitle": "electrical control equipment fitter-assembler"
    },
    {
        "value": 25737,
        "NOCCode": "9525",
        "JobTitle": "electrical control panel assembler"
    },
    {
        "value": 25738,
        "NOCCode": "9525",
        "JobTitle": "electrical control panel final assembler"
    },
    {
        "value": 25739,
        "NOCCode": "9525",
        "JobTitle": "electrical control panel subassembler"
    },
    {
        "value": 25740,
        "NOCCode": "9525",
        "JobTitle": "electrical fitter"
    },
    {
        "value": 25741,
        "NOCCode": "9525",
        "JobTitle": "electrical fitter – industrial electrical equipment"
    },
    {
        "value": 25742,
        "NOCCode": "9525",
        "JobTitle": "electrical inspector, locomotive"
    },
    {
        "value": 25743,
        "NOCCode": "9525",
        "JobTitle": "electrical panel assembler and wirer – industrial electrical equipment"
    },
    {
        "value": 25744,
        "NOCCode": "9525",
        "JobTitle": "electrical switchgear inspector"
    },
    {
        "value": 25745,
        "NOCCode": "9525",
        "JobTitle": "electrical switchgear tester"
    },
    {
        "value": 25746,
        "NOCCode": "9525",
        "JobTitle": "electrical switchgear wireperson"
    },
    {
        "value": 25747,
        "NOCCode": "9525",
        "JobTitle": "electrolytic cell repairer – industrial electrical equipment"
    },
    {
        "value": 25748,
        "NOCCode": "9525",
        "JobTitle": "field coil taper – industrial electrical equipment"
    },
    {
        "value": 25749,
        "NOCCode": "9525",
        "JobTitle": "final assembler, electrical control panel"
    },
    {
        "value": 25750,
        "NOCCode": "9525",
        "JobTitle": "fitter-assembler – electrical control equipment"
    },
    {
        "value": 25751,
        "NOCCode": "9525",
        "JobTitle": "fitter-assembler – industrial electrical equipment"
    },
    {
        "value": 25752,
        "NOCCode": "9525",
        "JobTitle": "fitter-assembler, AC (alternating current) and DC (direct current) motors"
    },
    {
        "value": 25753,
        "NOCCode": "9525",
        "JobTitle": "former, heavy-duty coils – industrial electrical equipment"
    },
    {
        "value": 25754,
        "NOCCode": "9525",
        "JobTitle": "generator tester – industrial electrical equipment"
    },
    {
        "value": 25755,
        "NOCCode": "9525",
        "JobTitle": "hand coil taper – industrial electrical equipment"
    },
    {
        "value": 25756,
        "NOCCode": "9525",
        "JobTitle": "heavy-duty coil former – industrial electrical equipment"
    },
    {
        "value": 25757,
        "NOCCode": "9525",
        "JobTitle": "heavy-duty transformer assembler"
    },
    {
        "value": 25758,
        "NOCCode": "9525",
        "JobTitle": "heavy-duty transformer coil winder"
    },
    {
        "value": 25759,
        "NOCCode": "9525",
        "JobTitle": "high voltage transformer bushing assembler"
    },
    {
        "value": 25760,
        "NOCCode": "9525",
        "JobTitle": "industrial brake and AC (alternating current) motor assembler"
    },
    {
        "value": 25761,
        "NOCCode": "9525",
        "JobTitle": "industrial brake and alternating current (AC) motor assembler"
    },
    {
        "value": 25762,
        "NOCCode": "9525",
        "JobTitle": "industrial electric equipment assembler"
    },
    {
        "value": 25763,
        "NOCCode": "9525",
        "JobTitle": "industrial electric equipment fabricator"
    },
    {
        "value": 25764,
        "NOCCode": "9525",
        "JobTitle": "industrial electric equipment inspector"
    },
    {
        "value": 25765,
        "NOCCode": "9525",
        "JobTitle": "inspector – large transformer manufacturing"
    },
    {
        "value": 25766,
        "NOCCode": "9525",
        "JobTitle": "inspector and tester, AC (alternating current) and DC (direct current) motors"
    },
    {
        "value": 25767,
        "NOCCode": "9525",
        "JobTitle": "inspector and tester, AC and DC motors"
    },
    {
        "value": 25768,
        "NOCCode": "9525",
        "JobTitle": "inspector, AC (alternating current) and DC (direct current) motors"
    },
    {
        "value": 25769,
        "NOCCode": "9525",
        "JobTitle": "inspector, automotive wiring harnesses"
    },
    {
        "value": 25770,
        "NOCCode": "9525",
        "JobTitle": "inspector, electrical control panel"
    },
    {
        "value": 25771,
        "NOCCode": "9525",
        "JobTitle": "inspector, electrical motor"
    },
    {
        "value": 25772,
        "NOCCode": "9525",
        "JobTitle": "inspector, electrical switchboards and panels"
    },
    {
        "value": 25773,
        "NOCCode": "9525",
        "JobTitle": "inspector, electrical switchgear"
    },
    {
        "value": 25774,
        "NOCCode": "9525",
        "JobTitle": "inspector, electrical switchgear panel"
    },
    {
        "value": 25775,
        "NOCCode": "9525",
        "JobTitle": "inspector, motor and generator"
    },
    {
        "value": 25776,
        "NOCCode": "9525",
        "JobTitle": "inspector, winding – large electric motors manufacturing"
    },
    {
        "value": 25777,
        "NOCCode": "9525",
        "JobTitle": "installer, motor and generator coils – industrial electrical equipment"
    },
    {
        "value": 25778,
        "NOCCode": "9525",
        "JobTitle": "laminated core assembler – industrial electrical equipment"
    },
    {
        "value": 25779,
        "NOCCode": "9525",
        "JobTitle": "lamination stacker – industrial electrical equipment"
    },
    {
        "value": 25780,
        "NOCCode": "9525",
        "JobTitle": "lapper, transformer cores"
    },
    {
        "value": 25781,
        "NOCCode": "9525",
        "JobTitle": "large power transformer coil winder"
    },
    {
        "value": 25782,
        "NOCCode": "9525",
        "JobTitle": "large transformer coil winder"
    },
    {
        "value": 25783,
        "NOCCode": "9525",
        "JobTitle": "locomotive electrical inspector"
    },
    {
        "value": 25784,
        "NOCCode": "9525",
        "JobTitle": "motor and control tester"
    },
    {
        "value": 25785,
        "NOCCode": "9525",
        "JobTitle": "motor and generator assembler – industrial electrical equipment"
    },
    {
        "value": 25786,
        "NOCCode": "9525",
        "JobTitle": "motor and generator assembler and wirer"
    },
    {
        "value": 25787,
        "NOCCode": "9525",
        "JobTitle": "motor and generator inspector"
    },
    {
        "value": 25788,
        "NOCCode": "9525",
        "JobTitle": "motor and transformer tester"
    },
    {
        "value": 25789,
        "NOCCode": "9525",
        "JobTitle": "motor control assembler – industrial electrical equipment"
    },
    {
        "value": 25790,
        "NOCCode": "9525",
        "JobTitle": "motor winder – industrial electrical equipment"
    },
    {
        "value": 25791,
        "NOCCode": "9525",
        "JobTitle": "motor-coil and generator-coil builder"
    },
    {
        "value": 25792,
        "NOCCode": "9525",
        "JobTitle": "motor-coil and generator-coil installer – industrial electrical equipment"
    },
    {
        "value": 25793,
        "NOCCode": "9525",
        "JobTitle": "panelboard assembler – industrial electrical equipment"
    },
    {
        "value": 25794,
        "NOCCode": "9525",
        "JobTitle": "pole shoe assembler – industrial electrical equipment"
    },
    {
        "value": 25795,
        "NOCCode": "9525",
        "JobTitle": "power transformer assembler"
    },
    {
        "value": 25796,
        "NOCCode": "9525",
        "JobTitle": "power transformer core builder"
    },
    {
        "value": 25797,
        "NOCCode": "9525",
        "JobTitle": "rotating field coil assembler – industrial electrical equipment"
    },
    {
        "value": 25798,
        "NOCCode": "9525",
        "JobTitle": "rotor assembler – industrial electrical equipment"
    },
    {
        "value": 25799,
        "NOCCode": "9525",
        "JobTitle": "rotor coil former – industrial electrical equipment"
    },
    {
        "value": 25800,
        "NOCCode": "9525",
        "JobTitle": "rotor core assembler – industrial electrical equipment"
    },
    {
        "value": 25801,
        "NOCCode": "9525",
        "JobTitle": "salvager, storage battery"
    },
    {
        "value": 25802,
        "NOCCode": "9525",
        "JobTitle": "setter, coil-winding machine – industrial electrical equipment"
    },
    {
        "value": 25803,
        "NOCCode": "9525",
        "JobTitle": "stator inspector – industrial electrical equipment"
    },
    {
        "value": 25804,
        "NOCCode": "9525",
        "JobTitle": "stator-core and rotor-core builder and welder – industrial electrical equipment"
    },
    {
        "value": 25805,
        "NOCCode": "9525",
        "JobTitle": "storage battery salvager"
    },
    {
        "value": 25806,
        "NOCCode": "9525",
        "JobTitle": "subassembler, electrical control panels"
    },
    {
        "value": 25807,
        "NOCCode": "9525",
        "JobTitle": "switchgear assembler and wirer"
    },
    {
        "value": 25808,
        "NOCCode": "9525",
        "JobTitle": "switchgear fitter-wirer"
    },
    {
        "value": 25809,
        "NOCCode": "9525",
        "JobTitle": "switchgear fitter-wirer – industrial electrical equipment"
    },
    {
        "value": 25810,
        "NOCCode": "9525",
        "JobTitle": "switchgear panel assembler – industrial electrical equipment"
    },
    {
        "value": 25811,
        "NOCCode": "9525",
        "JobTitle": "switchgear panel installer-fitter"
    },
    {
        "value": 25812,
        "NOCCode": "9525",
        "JobTitle": "switchgear rack assembler – industrial electrical equipment"
    },
    {
        "value": 25813,
        "NOCCode": "9525",
        "JobTitle": "tester, motors and controls"
    },
    {
        "value": 25814,
        "NOCCode": "9525",
        "JobTitle": "tester, transformer"
    },
    {
        "value": 25815,
        "NOCCode": "9525",
        "JobTitle": "transformer accessories assembler"
    },
    {
        "value": 25816,
        "NOCCode": "9525",
        "JobTitle": "transformer assembler"
    },
    {
        "value": 25817,
        "NOCCode": "9525",
        "JobTitle": "transformer assembly clamper"
    },
    {
        "value": 25818,
        "NOCCode": "9525",
        "JobTitle": "transformer assembly coil clamper"
    },
    {
        "value": 25819,
        "NOCCode": "9525",
        "JobTitle": "transformer builder"
    },
    {
        "value": 25820,
        "NOCCode": "9525",
        "JobTitle": "transformer core assembler"
    },
    {
        "value": 25821,
        "NOCCode": "9525",
        "JobTitle": "transformer core lapper"
    },
    {
        "value": 25822,
        "NOCCode": "9525",
        "JobTitle": "transformer core maker"
    },
    {
        "value": 25823,
        "NOCCode": "9525",
        "JobTitle": "transformer core stacker"
    },
    {
        "value": 25824,
        "NOCCode": "9525",
        "JobTitle": "transformer department lead hand"
    },
    {
        "value": 25825,
        "NOCCode": "9525",
        "JobTitle": "transformer fitter"
    },
    {
        "value": 25826,
        "NOCCode": "9525",
        "JobTitle": "transformer fitter-wirer"
    },
    {
        "value": 25827,
        "NOCCode": "9525",
        "JobTitle": "transformer gas detector relay assembler"
    },
    {
        "value": 25828,
        "NOCCode": "9525",
        "JobTitle": "transformer inspector"
    },
    {
        "value": 25829,
        "NOCCode": "9525",
        "JobTitle": "transformer maker"
    },
    {
        "value": 25830,
        "NOCCode": "9525",
        "JobTitle": "transformer subassembler"
    },
    {
        "value": 25831,
        "NOCCode": "9525",
        "JobTitle": "transformer tapgear assembler"
    },
    {
        "value": 25832,
        "NOCCode": "9525",
        "JobTitle": "transformer tester"
    },
    {
        "value": 25833,
        "NOCCode": "9525",
        "JobTitle": "transformer winder"
    },
    {
        "value": 25834,
        "NOCCode": "9525",
        "JobTitle": "undercutting machine operator – industrial electrical equipment"
    },
    {
        "value": 25835,
        "NOCCode": "9525",
        "JobTitle": "winder, AC (alternating current) and DC (direct current) armatures"
    },
    {
        "value": 25836,
        "NOCCode": "9525",
        "JobTitle": "winder, AC and DC armatures"
    },
    {
        "value": 25837,
        "NOCCode": "9525",
        "JobTitle": "winder, large transformer coils"
    },
    {
        "value": 25838,
        "NOCCode": "9525",
        "JobTitle": "windings inspector – large electric motors manufacturing"
    },
    {
        "value": 25839,
        "NOCCode": "9525",
        "JobTitle": "wireman/woman – industrial electrical equipment"
    },
    {
        "value": 25840,
        "NOCCode": "9525",
        "JobTitle": "wireperson, electrical switchgear"
    },
    {
        "value": 25841,
        "NOCCode": "9525",
        "JobTitle": "wirer, electrical switchgear panels"
    },
    {
        "value": 25842,
        "NOCCode": "9525",
        "JobTitle": "wirer/woman – industrial electrical equipment"
    },
    {
        "value": 25843,
        "NOCCode": "9526",
        "JobTitle": "agricultural equipment assembler"
    },
    {
        "value": 25844,
        "NOCCode": "9526",
        "JobTitle": "agricultural equipment assembly inspector"
    },
    {
        "value": 25845,
        "NOCCode": "9526",
        "JobTitle": "agricultural equipment bench assembler"
    },
    {
        "value": 25846,
        "NOCCode": "9526",
        "JobTitle": "agricultural equipment inspector"
    },
    {
        "value": 25847,
        "NOCCode": "9526",
        "JobTitle": "agricultural implement gearcase assembler"
    },
    {
        "value": 25848,
        "NOCCode": "9526",
        "JobTitle": "agricultural implements inspector"
    },
    {
        "value": 25849,
        "NOCCode": "9526",
        "JobTitle": "air-cooled engine assembler"
    },
    {
        "value": 25850,
        "NOCCode": "9526",
        "JobTitle": "all-terrain vehicles inspector"
    },
    {
        "value": 25851,
        "NOCCode": "9526",
        "JobTitle": "assembler, agricultural implements"
    },
    {
        "value": 25852,
        "NOCCode": "9526",
        "JobTitle": "assembler, air-cooled engines"
    },
    {
        "value": 25853,
        "NOCCode": "9526",
        "JobTitle": "assembler, automobile gear"
    },
    {
        "value": 25854,
        "NOCCode": "9526",
        "JobTitle": "assembler, automotive engine accessories"
    },
    {
        "value": 25855,
        "NOCCode": "9526",
        "JobTitle": "assembler, automotive engines"
    },
    {
        "value": 25856,
        "NOCCode": "9526",
        "JobTitle": "assembler, automotive radiator"
    },
    {
        "value": 25857,
        "NOCCode": "9526",
        "JobTitle": "assembler, buses"
    },
    {
        "value": 25858,
        "NOCCode": "9526",
        "JobTitle": "assembler, carburetors"
    },
    {
        "value": 25859,
        "NOCCode": "9526",
        "JobTitle": "assembler, clutches"
    },
    {
        "value": 25860,
        "NOCCode": "9526",
        "JobTitle": "assembler, cylinder heads"
    },
    {
        "value": 25861,
        "NOCCode": "9526",
        "JobTitle": "assembler, diesel engines"
    },
    {
        "value": 25862,
        "NOCCode": "9526",
        "JobTitle": "assembler, differentials"
    },
    {
        "value": 25863,
        "NOCCode": "9526",
        "JobTitle": "assembler, electric furnace"
    },
    {
        "value": 25864,
        "NOCCode": "9526",
        "JobTitle": "assembler, farm equipment"
    },
    {
        "value": 25865,
        "NOCCode": "9526",
        "JobTitle": "assembler, fuel pumps"
    },
    {
        "value": 25866,
        "NOCCode": "9526",
        "JobTitle": "assembler, garden machinery"
    },
    {
        "value": 25867,
        "NOCCode": "9526",
        "JobTitle": "assembler, gas furnace"
    },
    {
        "value": 25868,
        "NOCCode": "9526",
        "JobTitle": "assembler, gasoline engines"
    },
    {
        "value": 25869,
        "NOCCode": "9526",
        "JobTitle": "assembler, gearbox"
    },
    {
        "value": 25870,
        "NOCCode": "9526",
        "JobTitle": "assembler, hydraulic hoist"
    },
    {
        "value": 25871,
        "NOCCode": "9526",
        "JobTitle": "assembler, hydraulic pump"
    },
    {
        "value": 25872,
        "NOCCode": "9526",
        "JobTitle": "assembler, light material-handling equipment"
    },
    {
        "value": 25873,
        "NOCCode": "9526",
        "JobTitle": "assembler, mechanical pumps"
    },
    {
        "value": 25874,
        "NOCCode": "9526",
        "JobTitle": "assembler, outboard motor – mechanical assembly"
    },
    {
        "value": 25875,
        "NOCCode": "9526",
        "JobTitle": "assembler, power lawn mowers"
    },
    {
        "value": 25876,
        "NOCCode": "9526",
        "JobTitle": "assembler, power snowblowers"
    },
    {
        "value": 25877,
        "NOCCode": "9526",
        "JobTitle": "assembler, railway cars"
    },
    {
        "value": 25878,
        "NOCCode": "9526",
        "JobTitle": "assembler, recreational vehicles"
    },
    {
        "value": 25879,
        "NOCCode": "9526",
        "JobTitle": "assembler, rotary tillers"
    },
    {
        "value": 25880,
        "NOCCode": "9526",
        "JobTitle": "assembler, sewing machine"
    },
    {
        "value": 25881,
        "NOCCode": "9526",
        "JobTitle": "assembler, small marine engines"
    },
    {
        "value": 25882,
        "NOCCode": "9526",
        "JobTitle": "assembler, snowmobiles"
    },
    {
        "value": 25883,
        "NOCCode": "9526",
        "JobTitle": "assembler, tractors"
    },
    {
        "value": 25884,
        "NOCCode": "9526",
        "JobTitle": "assembler, trailer"
    },
    {
        "value": 25885,
        "NOCCode": "9526",
        "JobTitle": "assembler, transmissions"
    },
    {
        "value": 25886,
        "NOCCode": "9526",
        "JobTitle": "assembler, truck"
    },
    {
        "value": 25887,
        "NOCCode": "9526",
        "JobTitle": "assembler, truck trailers"
    },
    {
        "value": 25888,
        "NOCCode": "9526",
        "JobTitle": "assembler, vending machines"
    },
    {
        "value": 25889,
        "NOCCode": "9526",
        "JobTitle": "automobile gear assembler"
    },
    {
        "value": 25890,
        "NOCCode": "9526",
        "JobTitle": "automotive engine accessories assembler"
    },
    {
        "value": 25891,
        "NOCCode": "9526",
        "JobTitle": "automotive engine assembler"
    },
    {
        "value": 25892,
        "NOCCode": "9526",
        "JobTitle": "automotive engine quality-control assembly inspector"
    },
    {
        "value": 25893,
        "NOCCode": "9526",
        "JobTitle": "automotive production fitter"
    },
    {
        "value": 25894,
        "NOCCode": "9526",
        "JobTitle": "automotive radiator assembler"
    },
    {
        "value": 25895,
        "NOCCode": "9526",
        "JobTitle": "bench assembler, agricultural implements"
    },
    {
        "value": 25896,
        "NOCCode": "9526",
        "JobTitle": "bench fitter – buses and trucks"
    },
    {
        "value": 25897,
        "NOCCode": "9526",
        "JobTitle": "bus and truck floor framer"
    },
    {
        "value": 25898,
        "NOCCode": "9526",
        "JobTitle": "bus and truck frame builder"
    },
    {
        "value": 25899,
        "NOCCode": "9526",
        "JobTitle": "bus assembler"
    },
    {
        "value": 25900,
        "NOCCode": "9526",
        "JobTitle": "bus parts inspector"
    },
    {
        "value": 25901,
        "NOCCode": "9526",
        "JobTitle": "calibrator, gasoline pumps"
    },
    {
        "value": 25902,
        "NOCCode": "9526",
        "JobTitle": "carburetor assembler"
    },
    {
        "value": 25903,
        "NOCCode": "9526",
        "JobTitle": "carburetor assembly inspector"
    },
    {
        "value": 25904,
        "NOCCode": "9526",
        "JobTitle": "clutch assembler"
    },
    {
        "value": 25905,
        "NOCCode": "9526",
        "JobTitle": "clutch assembly inspector, motor vehicles"
    },
    {
        "value": 25906,
        "NOCCode": "9526",
        "JobTitle": "clutch inspector"
    },
    {
        "value": 25907,
        "NOCCode": "9526",
        "JobTitle": "clutch preassembler"
    },
    {
        "value": 25908,
        "NOCCode": "9526",
        "JobTitle": "clutch rebuilder"
    },
    {
        "value": 25909,
        "NOCCode": "9526",
        "JobTitle": "coach assembler – bus assembly"
    },
    {
        "value": 25910,
        "NOCCode": "9526",
        "JobTitle": "cylinder head assembler"
    },
    {
        "value": 25911,
        "NOCCode": "9526",
        "JobTitle": "diesel engine assembler"
    },
    {
        "value": 25912,
        "NOCCode": "9526",
        "JobTitle": "diesel engine erector"
    },
    {
        "value": 25913,
        "NOCCode": "9526",
        "JobTitle": "diesel engine tester"
    },
    {
        "value": 25914,
        "NOCCode": "9526",
        "JobTitle": "differential assembler"
    },
    {
        "value": 25915,
        "NOCCode": "9526",
        "JobTitle": "dismantler, farm machinery"
    },
    {
        "value": 25916,
        "NOCCode": "9526",
        "JobTitle": "dynamometer tester, engines"
    },
    {
        "value": 25917,
        "NOCCode": "9526",
        "JobTitle": "electric furnace assembler"
    },
    {
        "value": 25918,
        "NOCCode": "9526",
        "JobTitle": "engine accessories inspector"
    },
    {
        "value": 25919,
        "NOCCode": "9526",
        "JobTitle": "engine dynamometer tester"
    },
    {
        "value": 25920,
        "NOCCode": "9526",
        "JobTitle": "erector, diesel engines"
    },
    {
        "value": 25921,
        "NOCCode": "9526",
        "JobTitle": "exterior trailer finisher"
    },
    {
        "value": 25922,
        "NOCCode": "9526",
        "JobTitle": "farm equipment assembly inspector"
    },
    {
        "value": 25923,
        "NOCCode": "9526",
        "JobTitle": "farm implement assembler"
    },
    {
        "value": 25924,
        "NOCCode": "9526",
        "JobTitle": "farm machinery dismantler"
    },
    {
        "value": 25925,
        "NOCCode": "9526",
        "JobTitle": "final inspector, snowmobile"
    },
    {
        "value": 25926,
        "NOCCode": "9526",
        "JobTitle": "final inspector, truck trailer"
    },
    {
        "value": 25927,
        "NOCCode": "9526",
        "JobTitle": "finisher, trailer exteriors"
    },
    {
        "value": 25928,
        "NOCCode": "9526",
        "JobTitle": "firearms fitter"
    },
    {
        "value": 25929,
        "NOCCode": "9526",
        "JobTitle": "fitter, firearms"
    },
    {
        "value": 25930,
        "NOCCode": "9526",
        "JobTitle": "floor framer, buses and trucks"
    },
    {
        "value": 25931,
        "NOCCode": "9526",
        "JobTitle": "food-packaging equipment assembly inspector"
    },
    {
        "value": 25932,
        "NOCCode": "9526",
        "JobTitle": "frame builder, buses and trucks"
    },
    {
        "value": 25933,
        "NOCCode": "9526",
        "JobTitle": "fuel pump assembler"
    },
    {
        "value": 25934,
        "NOCCode": "9526",
        "JobTitle": "garden machinery assembler"
    },
    {
        "value": 25935,
        "NOCCode": "9526",
        "JobTitle": "gas furnace assembler"
    },
    {
        "value": 25936,
        "NOCCode": "9526",
        "JobTitle": "gasoline engine assembler"
    },
    {
        "value": 25937,
        "NOCCode": "9526",
        "JobTitle": "gasoline pump calibrator"
    },
    {
        "value": 25938,
        "NOCCode": "9526",
        "JobTitle": "gasoline pump tester"
    },
    {
        "value": 25939,
        "NOCCode": "9526",
        "JobTitle": "gear train assembler"
    },
    {
        "value": 25940,
        "NOCCode": "9526",
        "JobTitle": "gearbox assembler"
    },
    {
        "value": 25941,
        "NOCCode": "9526",
        "JobTitle": "gearcase assembler"
    },
    {
        "value": 25942,
        "NOCCode": "9526",
        "JobTitle": "gearcase assembler, agricultural implements"
    },
    {
        "value": 25943,
        "NOCCode": "9526",
        "JobTitle": "gearcase tester"
    },
    {
        "value": 25944,
        "NOCCode": "9526",
        "JobTitle": "go-kart assembler"
    },
    {
        "value": 25945,
        "NOCCode": "9526",
        "JobTitle": "group leader, mechanical assemblers"
    },
    {
        "value": 25946,
        "NOCCode": "9526",
        "JobTitle": "gun assembler"
    },
    {
        "value": 25947,
        "NOCCode": "9526",
        "JobTitle": "gun subassembler"
    },
    {
        "value": 25948,
        "NOCCode": "9526",
        "JobTitle": "hydraulic hoist assembler"
    },
    {
        "value": 25949,
        "NOCCode": "9526",
        "JobTitle": "hydraulic pump assembler"
    },
    {
        "value": 25950,
        "NOCCode": "9526",
        "JobTitle": "inspector and tester, outboard motors"
    },
    {
        "value": 25951,
        "NOCCode": "9526",
        "JobTitle": "inspector and tester, turbine assembly"
    },
    {
        "value": 25952,
        "NOCCode": "9526",
        "JobTitle": "inspector, agricultural implements"
    },
    {
        "value": 25953,
        "NOCCode": "9526",
        "JobTitle": "inspector, agricultural implements assembly"
    },
    {
        "value": 25954,
        "NOCCode": "9526",
        "JobTitle": "inspector, all-terrain vehicles"
    },
    {
        "value": 25955,
        "NOCCode": "9526",
        "JobTitle": "inspector, bus parts"
    },
    {
        "value": 25956,
        "NOCCode": "9526",
        "JobTitle": "inspector, carburetor assembly"
    },
    {
        "value": 25957,
        "NOCCode": "9526",
        "JobTitle": "inspector, clutches"
    },
    {
        "value": 25958,
        "NOCCode": "9526",
        "JobTitle": "inspector, engine accessories"
    },
    {
        "value": 25959,
        "NOCCode": "9526",
        "JobTitle": "inspector, farm implements assembly"
    },
    {
        "value": 25960,
        "NOCCode": "9526",
        "JobTitle": "inspector, food-packaging equipment assembly"
    },
    {
        "value": 25961,
        "NOCCode": "9526",
        "JobTitle": "inspector, motor vehicle clutch assembly"
    },
    {
        "value": 25962,
        "NOCCode": "9526",
        "JobTitle": "inspector, snowmobiles"
    },
    {
        "value": 25963,
        "NOCCode": "9526",
        "JobTitle": "interior trailer finisher"
    },
    {
        "value": 25964,
        "NOCCode": "9526",
        "JobTitle": "internal combustion engine assembler"
    },
    {
        "value": 25965,
        "NOCCode": "9526",
        "JobTitle": "lead hand, mechanical assemblers"
    },
    {
        "value": 25966,
        "NOCCode": "9526",
        "JobTitle": "light agricultural equipment assembler"
    },
    {
        "value": 25967,
        "NOCCode": "9526",
        "JobTitle": "light agricultural machinery assembler"
    },
    {
        "value": 25968,
        "NOCCode": "9526",
        "JobTitle": "light construction equipment assembler"
    },
    {
        "value": 25969,
        "NOCCode": "9526",
        "JobTitle": "light farm equipment assembler"
    },
    {
        "value": 25970,
        "NOCCode": "9526",
        "JobTitle": "light industrial truck assembler"
    },
    {
        "value": 25971,
        "NOCCode": "9526",
        "JobTitle": "light material-handling equipment assembler"
    },
    {
        "value": 25972,
        "NOCCode": "9526",
        "JobTitle": "machine try-out tester"
    },
    {
        "value": 25973,
        "NOCCode": "9526",
        "JobTitle": "marine engine quality-control assembly inspector"
    },
    {
        "value": 25974,
        "NOCCode": "9526",
        "JobTitle": "mechanical assembler"
    },
    {
        "value": 25975,
        "NOCCode": "9526",
        "JobTitle": "mechanical pump assembler"
    },
    {
        "value": 25976,
        "NOCCode": "9526",
        "JobTitle": "outboard motor assembler – mechanical assembly"
    },
    {
        "value": 25977,
        "NOCCode": "9526",
        "JobTitle": "outboard motor inspector and tester"
    },
    {
        "value": 25978,
        "NOCCode": "9526",
        "JobTitle": "outboard motor prototype tester"
    },
    {
        "value": 25979,
        "NOCCode": "9526",
        "JobTitle": "power lawn mower assembler"
    },
    {
        "value": 25980,
        "NOCCode": "9526",
        "JobTitle": "power snowblower assembler"
    },
    {
        "value": 25981,
        "NOCCode": "9526",
        "JobTitle": "preassembler, clutches"
    },
    {
        "value": 25982,
        "NOCCode": "9526",
        "JobTitle": "prototype tester, outboard motors"
    },
    {
        "value": 25983,
        "NOCCode": "9526",
        "JobTitle": "quality-control assembly inspector, automotive engines"
    },
    {
        "value": 25984,
        "NOCCode": "9526",
        "JobTitle": "quality-control assembly inspector, marine engines"
    },
    {
        "value": 25985,
        "NOCCode": "9526",
        "JobTitle": "radiator maker"
    },
    {
        "value": 25986,
        "NOCCode": "9526",
        "JobTitle": "railway car assembler"
    },
    {
        "value": 25987,
        "NOCCode": "9526",
        "JobTitle": "railway car liner"
    },
    {
        "value": 25988,
        "NOCCode": "9526",
        "JobTitle": "railway car trimmer"
    },
    {
        "value": 25989,
        "NOCCode": "9526",
        "JobTitle": "rebuilder, clutches"
    },
    {
        "value": 25990,
        "NOCCode": "9526",
        "JobTitle": "recreation vehicle assembler"
    },
    {
        "value": 25991,
        "NOCCode": "9526",
        "JobTitle": "rotary tiller assembler"
    },
    {
        "value": 25992,
        "NOCCode": "9526",
        "JobTitle": "sewing machine assembler"
    },
    {
        "value": 25993,
        "NOCCode": "9526",
        "JobTitle": "sewing machine assembly tester"
    },
    {
        "value": 25994,
        "NOCCode": "9526",
        "JobTitle": "small marine engine assembler"
    },
    {
        "value": 25995,
        "NOCCode": "9526",
        "JobTitle": "snowmobile assembler"
    },
    {
        "value": 25996,
        "NOCCode": "9526",
        "JobTitle": "snowmobile final inspector"
    },
    {
        "value": 25997,
        "NOCCode": "9526",
        "JobTitle": "snowmobile inspector"
    },
    {
        "value": 25998,
        "NOCCode": "9526",
        "JobTitle": "snowmobile test rider"
    },
    {
        "value": 25999,
        "NOCCode": "9526",
        "JobTitle": "steel plate caulker – shipbuilding"
    },
    {
        "value": 26000,
        "NOCCode": "9526",
        "JobTitle": "steering box assembler"
    },
    {
        "value": 26001,
        "NOCCode": "9526",
        "JobTitle": "tester, diesel engines"
    },
    {
        "value": 26002,
        "NOCCode": "9526",
        "JobTitle": "tester, gasoline pumps"
    },
    {
        "value": 26003,
        "NOCCode": "9526",
        "JobTitle": "tester, gearcases"
    },
    {
        "value": 26004,
        "NOCCode": "9526",
        "JobTitle": "tooling and metalworking machine try-out tester"
    },
    {
        "value": 26005,
        "NOCCode": "9526",
        "JobTitle": "tractor assembler"
    },
    {
        "value": 26006,
        "NOCCode": "9526",
        "JobTitle": "trailer assembler"
    },
    {
        "value": 26007,
        "NOCCode": "9526",
        "JobTitle": "trailer frame assembler"
    },
    {
        "value": 26008,
        "NOCCode": "9526",
        "JobTitle": "transmission assembler"
    },
    {
        "value": 26009,
        "NOCCode": "9526",
        "JobTitle": "transmission assembly inspector"
    },
    {
        "value": 26010,
        "NOCCode": "9526",
        "JobTitle": "transmission assembly tester"
    },
    {
        "value": 26011,
        "NOCCode": "9526",
        "JobTitle": "truck assembler"
    },
    {
        "value": 26012,
        "NOCCode": "9526",
        "JobTitle": "truck assembly inspector"
    },
    {
        "value": 26013,
        "NOCCode": "9526",
        "JobTitle": "truck body builder"
    },
    {
        "value": 26014,
        "NOCCode": "9526",
        "JobTitle": "truck trailer assembler"
    },
    {
        "value": 26015,
        "NOCCode": "9526",
        "JobTitle": "truck trailer builder"
    },
    {
        "value": 26016,
        "NOCCode": "9526",
        "JobTitle": "truck trailer final inspector"
    },
    {
        "value": 26017,
        "NOCCode": "9526",
        "JobTitle": "try-out tester, tooling and metalworking machines"
    },
    {
        "value": 26018,
        "NOCCode": "9526",
        "JobTitle": "turbine assembly inspector and tester"
    },
    {
        "value": 26019,
        "NOCCode": "9526",
        "JobTitle": "vending machine assembler"
    },
    {
        "value": 26020,
        "NOCCode": "9526",
        "JobTitle": "vending machine tester"
    },
    {
        "value": 26021,
        "NOCCode": "9526",
        "JobTitle": "water pump assembler"
    },
    {
        "value": 26022,
        "NOCCode": "9526",
        "JobTitle": "wheelchair lift assembler"
    },
    {
        "value": 26023,
        "NOCCode": "9527",
        "JobTitle": "arbor press tender – electrical equipment manufacturing"
    },
    {
        "value": 26024,
        "NOCCode": "9527",
        "JobTitle": "armature tester"
    },
    {
        "value": 26025,
        "NOCCode": "9527",
        "JobTitle": "assembler, battery chargers"
    },
    {
        "value": 26026,
        "NOCCode": "9527",
        "JobTitle": "assembler, dry cell and battery"
    },
    {
        "value": 26027,
        "NOCCode": "9527",
        "JobTitle": "assembler, dry cell batteries"
    },
    {
        "value": 26028,
        "NOCCode": "9527",
        "JobTitle": "assembler, dry cells"
    },
    {
        "value": 26029,
        "NOCCode": "9527",
        "JobTitle": "assembler, flat dry batteries"
    },
    {
        "value": 26030,
        "NOCCode": "9527",
        "JobTitle": "assembler, flat dry cells"
    },
    {
        "value": 26031,
        "NOCCode": "9527",
        "JobTitle": "assembler, industrial storage battery plates"
    },
    {
        "value": 26032,
        "NOCCode": "9527",
        "JobTitle": "assembler, storage batteries"
    },
    {
        "value": 26033,
        "NOCCode": "9527",
        "JobTitle": "assembly line operator, storage batteries"
    },
    {
        "value": 26034,
        "NOCCode": "9527",
        "JobTitle": "assembly machine setter – electrical equipment manufacturing"
    },
    {
        "value": 26035,
        "NOCCode": "9527",
        "JobTitle": "assembly machine set-up person – electrical equipment manufacturing"
    },
    {
        "value": 26036,
        "NOCCode": "9527",
        "JobTitle": "automatic coil winder – electrical equipment manufacturing"
    },
    {
        "value": 26037,
        "NOCCode": "9527",
        "JobTitle": "balancing machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26038,
        "NOCCode": "9527",
        "JobTitle": "balancing machine operator – small electric motors"
    },
    {
        "value": 26039,
        "NOCCode": "9527",
        "JobTitle": "balancing machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26040,
        "NOCCode": "9527",
        "JobTitle": "base filler – electrical equipment manufacturing"
    },
    {
        "value": 26041,
        "NOCCode": "9527",
        "JobTitle": "base-filling machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26042,
        "NOCCode": "9527",
        "JobTitle": "base-filling machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26043,
        "NOCCode": "9527",
        "JobTitle": "battery assembler"
    },
    {
        "value": 26044,
        "NOCCode": "9527",
        "JobTitle": "battery case inspector and tester"
    },
    {
        "value": 26045,
        "NOCCode": "9527",
        "JobTitle": "battery charger – electrical equipment manufacturing"
    },
    {
        "value": 26046,
        "NOCCode": "9527",
        "JobTitle": "battery charger assembler"
    },
    {
        "value": 26047,
        "NOCCode": "9527",
        "JobTitle": "battery charger operator – electrical equipment manufacturing"
    },
    {
        "value": 26048,
        "NOCCode": "9527",
        "JobTitle": "battery maker"
    },
    {
        "value": 26049,
        "NOCCode": "9527",
        "JobTitle": "brush lacer and trimmer – electrical equipment manufacturing"
    },
    {
        "value": 26050,
        "NOCCode": "9527",
        "JobTitle": "brush-lacing and trimming operator – electrical equipment manufacturing"
    },
    {
        "value": 26051,
        "NOCCode": "9527",
        "JobTitle": "cable-coding machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26052,
        "NOCCode": "9527",
        "JobTitle": "cable-marking machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26053,
        "NOCCode": "9527",
        "JobTitle": "capping machine tender, dry cells – electrical equipment manufacturing"
    },
    {
        "value": 26054,
        "NOCCode": "9527",
        "JobTitle": "cement-filling machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26055,
        "NOCCode": "9527",
        "JobTitle": "charger – electrical equipment manufacturing"
    },
    {
        "value": 26056,
        "NOCCode": "9527",
        "JobTitle": "charger operator – electrical equipment manufacturing"
    },
    {
        "value": 26057,
        "NOCCode": "9527",
        "JobTitle": "charging room operator – electrical equipment manufacturing"
    },
    {
        "value": 26058,
        "NOCCode": "9527",
        "JobTitle": "coater – electrical equipment manufacturing"
    },
    {
        "value": 26059,
        "NOCCode": "9527",
        "JobTitle": "coating machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26060,
        "NOCCode": "9527",
        "JobTitle": "coding machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26061,
        "NOCCode": "9527",
        "JobTitle": "crimping machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26062,
        "NOCCode": "9527",
        "JobTitle": "dip and bake operator – electrical equipment manufacturing"
    },
    {
        "value": 26063,
        "NOCCode": "9527",
        "JobTitle": "dry battery coremaking machine tender"
    },
    {
        "value": 26064,
        "NOCCode": "9527",
        "JobTitle": "dry battery inspector – electrical equipment manufacturing"
    },
    {
        "value": 26065,
        "NOCCode": "9527",
        "JobTitle": "dry battery process room operator – electrical equipment manufacturing"
    },
    {
        "value": 26066,
        "NOCCode": "9527",
        "JobTitle": "dry battery tester – electrical equipment manufacturing"
    },
    {
        "value": 26067,
        "NOCCode": "9527",
        "JobTitle": "dry cell and battery assembler"
    },
    {
        "value": 26068,
        "NOCCode": "9527",
        "JobTitle": "dry cell assembler"
    },
    {
        "value": 26069,
        "NOCCode": "9527",
        "JobTitle": "dry cell assembly machine feeder – electrical equipment manufacturing"
    },
    {
        "value": 26070,
        "NOCCode": "9527",
        "JobTitle": "dry cell battery assembler"
    },
    {
        "value": 26071,
        "NOCCode": "9527",
        "JobTitle": "dry cell charge machine operator"
    },
    {
        "value": 26072,
        "NOCCode": "9527",
        "JobTitle": "dry cell charge machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26073,
        "NOCCode": "9527",
        "JobTitle": "dry cell charger operator – electrical equipment manufacturing"
    },
    {
        "value": 26074,
        "NOCCode": "9527",
        "JobTitle": "dry cell coremaking machine tender"
    },
    {
        "value": 26075,
        "NOCCode": "9527",
        "JobTitle": "dry cell coremaking machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26076,
        "NOCCode": "9527",
        "JobTitle": "dry cell sealer – electrical equipment manufacturing"
    },
    {
        "value": 26077,
        "NOCCode": "9527",
        "JobTitle": "dry cell tester – electrical equipment manufacturing"
    },
    {
        "value": 26078,
        "NOCCode": "9527",
        "JobTitle": "dry cell tube machine assembler – electrical equipment manufacturing"
    },
    {
        "value": 26079,
        "NOCCode": "9527",
        "JobTitle": "electric bulb maker"
    },
    {
        "value": 26080,
        "NOCCode": "9527",
        "JobTitle": "electric fuse maker"
    },
    {
        "value": 26081,
        "NOCCode": "9527",
        "JobTitle": "electrical connection crimper"
    },
    {
        "value": 26082,
        "NOCCode": "9527",
        "JobTitle": "electrical dry cell inspector"
    },
    {
        "value": 26083,
        "NOCCode": "9527",
        "JobTitle": "electrical dry cell inspector – electrical equipment manufacturing"
    },
    {
        "value": 26084,
        "NOCCode": "9527",
        "JobTitle": "electrical dry cell-making inspector"
    },
    {
        "value": 26085,
        "NOCCode": "9527",
        "JobTitle": "electrical equipment base filler"
    },
    {
        "value": 26086,
        "NOCCode": "9527",
        "JobTitle": "electrical equipment impregnator"
    },
    {
        "value": 26087,
        "NOCCode": "9527",
        "JobTitle": "electrical equipment manufacturing machine operator"
    },
    {
        "value": 26088,
        "NOCCode": "9527",
        "JobTitle": "electrical lamp-making machine set-up man/woman"
    },
    {
        "value": 26089,
        "NOCCode": "9527",
        "JobTitle": "electrical lead acid storage battery filler"
    },
    {
        "value": 26090,
        "NOCCode": "9527",
        "JobTitle": "electrical plug maker"
    },
    {
        "value": 26091,
        "NOCCode": "9527",
        "JobTitle": "electrical storage battery charger operator"
    },
    {
        "value": 26092,
        "NOCCode": "9527",
        "JobTitle": "electrical storage battery inspector"
    },
    {
        "value": 26093,
        "NOCCode": "9527",
        "JobTitle": "electrical storage battery plate washer and dryer"
    },
    {
        "value": 26094,
        "NOCCode": "9527",
        "JobTitle": "electrical storage battery repairer – electrical equipment manufacturing"
    },
    {
        "value": 26095,
        "NOCCode": "9527",
        "JobTitle": "electrical storage battery tester"
    },
    {
        "value": 26096,
        "NOCCode": "9527",
        "JobTitle": "electrical-equipment-impregnating machine operator"
    },
    {
        "value": 26097,
        "NOCCode": "9527",
        "JobTitle": "electrode installer"
    },
    {
        "value": 26098,
        "NOCCode": "9527",
        "JobTitle": "electrolytic anode changer"
    },
    {
        "value": 26099,
        "NOCCode": "9527",
        "JobTitle": "encapsulator – electrical equipment manufacturing"
    },
    {
        "value": 26100,
        "NOCCode": "9527",
        "JobTitle": "epoxy coater – electrical equipment manufacturing"
    },
    {
        "value": 26101,
        "NOCCode": "9527",
        "JobTitle": "epoxy coating machine operator"
    },
    {
        "value": 26102,
        "NOCCode": "9527",
        "JobTitle": "epoxy coating machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26103,
        "NOCCode": "9527",
        "JobTitle": "epoxy encapsulator – electrical equipment manufacturing"
    },
    {
        "value": 26104,
        "NOCCode": "9527",
        "JobTitle": "filler, electrical lead acid storage batteries"
    },
    {
        "value": 26105,
        "NOCCode": "9527",
        "JobTitle": "filling machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26106,
        "NOCCode": "9527",
        "JobTitle": "flat battery maker"
    },
    {
        "value": 26107,
        "NOCCode": "9527",
        "JobTitle": "flat cell maker – electrical equipment manufacturing"
    },
    {
        "value": 26108,
        "NOCCode": "9527",
        "JobTitle": "flat dry battery assembler"
    },
    {
        "value": 26109,
        "NOCCode": "9527",
        "JobTitle": "flat dry cell assembler"
    },
    {
        "value": 26110,
        "NOCCode": "9527",
        "JobTitle": "flat dry cell machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26111,
        "NOCCode": "9527",
        "JobTitle": "foam machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26112,
        "NOCCode": "9527",
        "JobTitle": "forming room operator – electrical equipment manufacturing"
    },
    {
        "value": 26113,
        "NOCCode": "9527",
        "JobTitle": "heat seal equipment operator – electrical equipment manufacturing"
    },
    {
        "value": 26114,
        "NOCCode": "9527",
        "JobTitle": "heating element coil former – electrical equipment manufacturing"
    },
    {
        "value": 26115,
        "NOCCode": "9527",
        "JobTitle": "heating element coil-former operator"
    },
    {
        "value": 26116,
        "NOCCode": "9527",
        "JobTitle": "heavy-duty electrical battery charger tester"
    },
    {
        "value": 26117,
        "NOCCode": "9527",
        "JobTitle": "impregnator, electrical equipment"
    },
    {
        "value": 26118,
        "NOCCode": "9527",
        "JobTitle": "incandescent lamp making inspector"
    },
    {
        "value": 26119,
        "NOCCode": "9527",
        "JobTitle": "industrial battery assembler"
    },
    {
        "value": 26120,
        "NOCCode": "9527",
        "JobTitle": "industrial storage battery plate assembler"
    },
    {
        "value": 26121,
        "NOCCode": "9527",
        "JobTitle": "inspector and tester, battery case – electrical equipment manufacturing"
    },
    {
        "value": 26122,
        "NOCCode": "9527",
        "JobTitle": "inspector and tester, battery cases"
    },
    {
        "value": 26123,
        "NOCCode": "9527",
        "JobTitle": "inspector, electrical dry battery"
    },
    {
        "value": 26124,
        "NOCCode": "9527",
        "JobTitle": "inspector, electrical dry cell"
    },
    {
        "value": 26125,
        "NOCCode": "9527",
        "JobTitle": "inspector, electrical light bulbs"
    },
    {
        "value": 26126,
        "NOCCode": "9527",
        "JobTitle": "inspector, lead acid battery parts"
    },
    {
        "value": 26127,
        "NOCCode": "9527",
        "JobTitle": "inspector, light bulbs"
    },
    {
        "value": 26128,
        "NOCCode": "9527",
        "JobTitle": "lamination-stacking machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26129,
        "NOCCode": "9527",
        "JobTitle": "lamp filament processor"
    },
    {
        "value": 26130,
        "NOCCode": "9527",
        "JobTitle": "lead acid battery parts inspector"
    },
    {
        "value": 26131,
        "NOCCode": "9527",
        "JobTitle": "lead acid battery production tester – electrical equipment manufacturing"
    },
    {
        "value": 26132,
        "NOCCode": "9527",
        "JobTitle": "lead acid electrical battery production tester"
    },
    {
        "value": 26133,
        "NOCCode": "9527",
        "JobTitle": "lead acid storage battery plate former – electrical equipment manufacturing"
    },
    {
        "value": 26134,
        "NOCCode": "9527",
        "JobTitle": "lead acid storage battery plate maker"
    },
    {
        "value": 26135,
        "NOCCode": "9527",
        "JobTitle": "lead acid storage battery tester"
    },
    {
        "value": 26136,
        "NOCCode": "9527",
        "JobTitle": "lead acid storage battery tester – electrical equipment manufacturing"
    },
    {
        "value": 26137,
        "NOCCode": "9527",
        "JobTitle": "lead former – electrical equipment manufacturing"
    },
    {
        "value": 26138,
        "NOCCode": "9527",
        "JobTitle": "light bulb inspector"
    },
    {
        "value": 26139,
        "NOCCode": "9527",
        "JobTitle": "light bulb inspector and tester"
    },
    {
        "value": 26140,
        "NOCCode": "9527",
        "JobTitle": "light bulb maker"
    },
    {
        "value": 26141,
        "NOCCode": "9527",
        "JobTitle": "light bulb making inspector"
    },
    {
        "value": 26142,
        "NOCCode": "9527",
        "JobTitle": "light bulb making machine operator"
    },
    {
        "value": 26143,
        "NOCCode": "9527",
        "JobTitle": "light bulb tester"
    },
    {
        "value": 26144,
        "NOCCode": "9527",
        "JobTitle": "light-bulb-sealing machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26145,
        "NOCCode": "9527",
        "JobTitle": "light-bulb-sealing machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26146,
        "NOCCode": "9527",
        "JobTitle": "machine base cement filler – electrical equipment manufacturing"
    },
    {
        "value": 26147,
        "NOCCode": "9527",
        "JobTitle": "machine operator – electrical appliance and equipment manufacturing"
    },
    {
        "value": 26148,
        "NOCCode": "9527",
        "JobTitle": "machine operator – electrical appliance manufacturing"
    },
    {
        "value": 26149,
        "NOCCode": "9527",
        "JobTitle": "machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26150,
        "NOCCode": "9527",
        "JobTitle": "machine riveter – electrical equipment manufacturing"
    },
    {
        "value": 26151,
        "NOCCode": "9527",
        "JobTitle": "machine set-up operator – electric equipment manufacturing"
    },
    {
        "value": 26152,
        "NOCCode": "9527",
        "JobTitle": "machine set-up operator – electrical appliance manufacturing"
    },
    {
        "value": 26153,
        "NOCCode": "9527",
        "JobTitle": "machine set-up operator – electrical equipment manufacturing"
    },
    {
        "value": 26154,
        "NOCCode": "9527",
        "JobTitle": "machine sleeve assembler – electrical equipment manufacturing"
    },
    {
        "value": 26155,
        "NOCCode": "9527",
        "JobTitle": "machine tube assembler – electrical equipment manufacturing"
    },
    {
        "value": 26156,
        "NOCCode": "9527",
        "JobTitle": "machine tuber – electrical equipment manufacturing"
    },
    {
        "value": 26157,
        "NOCCode": "9527",
        "JobTitle": "machine wire-cutter and wire-stripper – electrical equipment manufacturing"
    },
    {
        "value": 26158,
        "NOCCode": "9527",
        "JobTitle": "operator – electrical equipment manufacturing"
    },
    {
        "value": 26159,
        "NOCCode": "9527",
        "JobTitle": "operator, forming room – electrical equipment manufacturing"
    },
    {
        "value": 26160,
        "NOCCode": "9527",
        "JobTitle": "operator, press – electrical equipment manufacturing"
    },
    {
        "value": 26161,
        "NOCCode": "9527",
        "JobTitle": "pasting machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26162,
        "NOCCode": "9527",
        "JobTitle": "plate former, lead acid storage battery – electrical equipment manufacturing"
    },
    {
        "value": 26163,
        "NOCCode": "9527",
        "JobTitle": "plate-parting machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26164,
        "NOCCode": "9527",
        "JobTitle": "press operator – electrical equipment manufacturing"
    },
    {
        "value": 26165,
        "NOCCode": "9527",
        "JobTitle": "press tender – electrical equipment manufacturing"
    },
    {
        "value": 26166,
        "NOCCode": "9527",
        "JobTitle": "process room operator, dry battery – electrical equipment manufacturing"
    },
    {
        "value": 26167,
        "NOCCode": "9527",
        "JobTitle": "production line battery repairer – electrical equipment manufacturing"
    },
    {
        "value": 26168,
        "NOCCode": "9527",
        "JobTitle": "production line setter and servicer – electrical equipment manufacturing"
    },
    {
        "value": 26169,
        "NOCCode": "9527",
        "JobTitle": "production line storage battery repairer – electrical equipment manufacturing"
    },
    {
        "value": 26170,
        "NOCCode": "9527",
        "JobTitle": "production machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26171,
        "NOCCode": "9527",
        "JobTitle": "production machine set-up operator – electrical appliance and equipment manufacturing"
    },
    {
        "value": 26172,
        "NOCCode": "9527",
        "JobTitle": "production tester, lead acid electrical battery"
    },
    {
        "value": 26173,
        "NOCCode": "9527",
        "JobTitle": "riveting machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26174,
        "NOCCode": "9527",
        "JobTitle": "riveting machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26175,
        "NOCCode": "9527",
        "JobTitle": "sealer, dry cell – electrical equipment manufacturing"
    },
    {
        "value": 26176,
        "NOCCode": "9527",
        "JobTitle": "set-up man/woman, electrical lamp-making machines"
    },
    {
        "value": 26177,
        "NOCCode": "9527",
        "JobTitle": "sleeve-making machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26178,
        "NOCCode": "9527",
        "JobTitle": "spray foam machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26179,
        "NOCCode": "9527",
        "JobTitle": "stacking machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26180,
        "NOCCode": "9527",
        "JobTitle": "storage battery assembler"
    },
    {
        "value": 26181,
        "NOCCode": "9527",
        "JobTitle": "storage battery assembly line operator"
    },
    {
        "value": 26182,
        "NOCCode": "9527",
        "JobTitle": "storage battery charger – electrical equipment manufacturing"
    },
    {
        "value": 26183,
        "NOCCode": "9527",
        "JobTitle": "storage battery tester"
    },
    {
        "value": 26184,
        "NOCCode": "9527",
        "JobTitle": "storage battery tube filler – electrical equipment manufacturing"
    },
    {
        "value": 26185,
        "NOCCode": "9527",
        "JobTitle": "taper operator – electrical equipment manufacturing"
    },
    {
        "value": 26186,
        "NOCCode": "9527",
        "JobTitle": "taper tender – electrical equipment manufacturing"
    },
    {
        "value": 26187,
        "NOCCode": "9527",
        "JobTitle": "taping machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26188,
        "NOCCode": "9527",
        "JobTitle": "tester, electrical storage batteries"
    },
    {
        "value": 26189,
        "NOCCode": "9527",
        "JobTitle": "tester, lead acid storage battery"
    },
    {
        "value": 26190,
        "NOCCode": "9527",
        "JobTitle": "tester, light bulbs"
    },
    {
        "value": 26191,
        "NOCCode": "9527",
        "JobTitle": "top-capping machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26192,
        "NOCCode": "9527",
        "JobTitle": "transformer coil impregnator"
    },
    {
        "value": 26193,
        "NOCCode": "9527",
        "JobTitle": "wafer machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26194,
        "NOCCode": "9527",
        "JobTitle": "wafering machine operator – electrical equipment manufacturing"
    },
    {
        "value": 26195,
        "NOCCode": "9527",
        "JobTitle": "wire stripper – electrical equipment manufacturing"
    },
    {
        "value": 26196,
        "NOCCode": "9527",
        "JobTitle": "wire-numbering machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26197,
        "NOCCode": "9527",
        "JobTitle": "wireworker – electrical equipment manufacturing"
    },
    {
        "value": 26198,
        "NOCCode": "9527",
        "JobTitle": "wrapper-stacker operator – electrical equipment manufacturing"
    },
    {
        "value": 26199,
        "NOCCode": "9527",
        "JobTitle": "wrapping machine tender – electrical equipment manufacturing"
    },
    {
        "value": 26200,
        "NOCCode": "9531",
        "JobTitle": "aluminum boat assembler"
    },
    {
        "value": 26201,
        "NOCCode": "9531",
        "JobTitle": "aluminum boat leak tester"
    },
    {
        "value": 26202,
        "NOCCode": "9531",
        "JobTitle": "assembler, boats"
    },
    {
        "value": 26203,
        "NOCCode": "9531",
        "JobTitle": "assembler, canoes"
    },
    {
        "value": 26204,
        "NOCCode": "9531",
        "JobTitle": "assembler, dinghies"
    },
    {
        "value": 26205,
        "NOCCode": "9531",
        "JobTitle": "assembler, motorboats"
    },
    {
        "value": 26206,
        "NOCCode": "9531",
        "JobTitle": "assembler, sailboats"
    },
    {
        "value": 26207,
        "NOCCode": "9531",
        "JobTitle": "assembly inspector, boats"
    },
    {
        "value": 26208,
        "NOCCode": "9531",
        "JobTitle": "assembly inspector, motorboats"
    },
    {
        "value": 26209,
        "NOCCode": "9531",
        "JobTitle": "assembly inspector, wooden boats"
    },
    {
        "value": 26210,
        "NOCCode": "9531",
        "JobTitle": "assembly laminator, boats"
    },
    {
        "value": 26211,
        "NOCCode": "9531",
        "JobTitle": "assembly repairer, boats"
    },
    {
        "value": 26212,
        "NOCCode": "9531",
        "JobTitle": "boat assembler"
    },
    {
        "value": 26213,
        "NOCCode": "9531",
        "JobTitle": "boat assembler, aluminum"
    },
    {
        "value": 26214,
        "NOCCode": "9531",
        "JobTitle": "boat assembler, fibreglass"
    },
    {
        "value": 26215,
        "NOCCode": "9531",
        "JobTitle": "boat assembly inspector"
    },
    {
        "value": 26216,
        "NOCCode": "9531",
        "JobTitle": "boat assembly laminator"
    },
    {
        "value": 26217,
        "NOCCode": "9531",
        "JobTitle": "boat assembly repairer"
    },
    {
        "value": 26218,
        "NOCCode": "9531",
        "JobTitle": "boat assembly repairer, fibreglass"
    },
    {
        "value": 26219,
        "NOCCode": "9531",
        "JobTitle": "boat inspector"
    },
    {
        "value": 26220,
        "NOCCode": "9531",
        "JobTitle": "boat inspector and finisher, fibreglass"
    },
    {
        "value": 26221,
        "NOCCode": "9531",
        "JobTitle": "boat inspector, wood"
    },
    {
        "value": 26222,
        "NOCCode": "9531",
        "JobTitle": "boat motor installer"
    },
    {
        "value": 26223,
        "NOCCode": "9531",
        "JobTitle": "canoe assembler"
    },
    {
        "value": 26224,
        "NOCCode": "9531",
        "JobTitle": "canoe building inspector"
    },
    {
        "value": 26225,
        "NOCCode": "9531",
        "JobTitle": "canoe canvasser"
    },
    {
        "value": 26226,
        "NOCCode": "9531",
        "JobTitle": "canvas canoe waterproofer"
    },
    {
        "value": 26227,
        "NOCCode": "9531",
        "JobTitle": "caulker, wooden hulls – boat assembly"
    },
    {
        "value": 26228,
        "NOCCode": "9531",
        "JobTitle": "dinghy assembler"
    },
    {
        "value": 26229,
        "NOCCode": "9531",
        "JobTitle": "fibreglass boat assembler"
    },
    {
        "value": 26230,
        "NOCCode": "9531",
        "JobTitle": "fibreglass boat inspector and finisher"
    },
    {
        "value": 26231,
        "NOCCode": "9531",
        "JobTitle": "hull inspector"
    },
    {
        "value": 26232,
        "NOCCode": "9531",
        "JobTitle": "hull surveyor"
    },
    {
        "value": 26233,
        "NOCCode": "9531",
        "JobTitle": "inspector and finisher, fibreglass boats"
    },
    {
        "value": 26234,
        "NOCCode": "9531",
        "JobTitle": "inspector, boat assembly"
    },
    {
        "value": 26235,
        "NOCCode": "9531",
        "JobTitle": "inspector, boats"
    },
    {
        "value": 26236,
        "NOCCode": "9531",
        "JobTitle": "inspector, canoe building"
    },
    {
        "value": 26237,
        "NOCCode": "9531",
        "JobTitle": "inspector, hull"
    },
    {
        "value": 26238,
        "NOCCode": "9531",
        "JobTitle": "inspector, motorboat assembly"
    },
    {
        "value": 26239,
        "NOCCode": "9531",
        "JobTitle": "inspector, wooden boat assembly"
    },
    {
        "value": 26240,
        "NOCCode": "9531",
        "JobTitle": "inspector, wooden boats"
    },
    {
        "value": 26241,
        "NOCCode": "9531",
        "JobTitle": "installer, boat motors"
    },
    {
        "value": 26242,
        "NOCCode": "9531",
        "JobTitle": "installer, outboard motors"
    },
    {
        "value": 26243,
        "NOCCode": "9531",
        "JobTitle": "installer-fitter, outboard motors"
    },
    {
        "value": 26244,
        "NOCCode": "9531",
        "JobTitle": "laminator, boat assembly"
    },
    {
        "value": 26245,
        "NOCCode": "9531",
        "JobTitle": "leak tester, aluminum boats"
    },
    {
        "value": 26246,
        "NOCCode": "9531",
        "JobTitle": "loft rigger – boat assembly"
    },
    {
        "value": 26247,
        "NOCCode": "9531",
        "JobTitle": "motor installer, boats"
    },
    {
        "value": 26248,
        "NOCCode": "9531",
        "JobTitle": "motorboat assembler"
    },
    {
        "value": 26249,
        "NOCCode": "9531",
        "JobTitle": "motorboat assembly inspector"
    },
    {
        "value": 26250,
        "NOCCode": "9531",
        "JobTitle": "outboard motor installer"
    },
    {
        "value": 26251,
        "NOCCode": "9531",
        "JobTitle": "outboard motor installer-fitter"
    },
    {
        "value": 26252,
        "NOCCode": "9531",
        "JobTitle": "plywood boat veneer layer"
    },
    {
        "value": 26253,
        "NOCCode": "9531",
        "JobTitle": "repairer, boat assembly"
    },
    {
        "value": 26254,
        "NOCCode": "9531",
        "JobTitle": "repairer, fibreglass boat assembly"
    },
    {
        "value": 26255,
        "NOCCode": "9531",
        "JobTitle": "rivet tester – boat assembly"
    },
    {
        "value": 26256,
        "NOCCode": "9531",
        "JobTitle": "sailboat assembler"
    },
    {
        "value": 26257,
        "NOCCode": "9531",
        "JobTitle": "tester, rivets – boat assembly"
    },
    {
        "value": 26258,
        "NOCCode": "9531",
        "JobTitle": "veneer layer, plywood boat"
    },
    {
        "value": 26259,
        "NOCCode": "9531",
        "JobTitle": "waterproofer, canoe canvas"
    },
    {
        "value": 26260,
        "NOCCode": "9531",
        "JobTitle": "wooden boat assembly inspector"
    },
    {
        "value": 26261,
        "NOCCode": "9531",
        "JobTitle": "wooden boat inspector"
    },
    {
        "value": 26262,
        "NOCCode": "9531",
        "JobTitle": "wooden hull caulker – boat assembly"
    },
    {
        "value": 26263,
        "NOCCode": "9532",
        "JobTitle": "assembler, box spring units – furniture manufacturing"
    },
    {
        "value": 26264,
        "NOCCode": "9532",
        "JobTitle": "assembler, box springs"
    },
    {
        "value": 26265,
        "NOCCode": "9532",
        "JobTitle": "assembler, cabinet – furniture manufacturing"
    },
    {
        "value": 26266,
        "NOCCode": "9532",
        "JobTitle": "assembler, chairs"
    },
    {
        "value": 26267,
        "NOCCode": "9532",
        "JobTitle": "assembler, desk"
    },
    {
        "value": 26268,
        "NOCCode": "9532",
        "JobTitle": "assembler, fixture"
    },
    {
        "value": 26269,
        "NOCCode": "9532",
        "JobTitle": "assembler, furniture"
    },
    {
        "value": 26270,
        "NOCCode": "9532",
        "JobTitle": "assembler, mattress – furniture manufacturing"
    },
    {
        "value": 26271,
        "NOCCode": "9532",
        "JobTitle": "assembler, metal cabinets"
    },
    {
        "value": 26272,
        "NOCCode": "9532",
        "JobTitle": "assembler, metal chairs"
    },
    {
        "value": 26273,
        "NOCCode": "9532",
        "JobTitle": "assembler, metal tables"
    },
    {
        "value": 26274,
        "NOCCode": "9532",
        "JobTitle": "assembler, mirror and picture frames"
    },
    {
        "value": 26275,
        "NOCCode": "9532",
        "JobTitle": "assembler, office chairs"
    },
    {
        "value": 26276,
        "NOCCode": "9532",
        "JobTitle": "assembler, picture frames – furniture and fixtures manufacturing"
    },
    {
        "value": 26277,
        "NOCCode": "9532",
        "JobTitle": "assembler, tables"
    },
    {
        "value": 26278,
        "NOCCode": "9532",
        "JobTitle": "assembler, wooden tables"
    },
    {
        "value": 26279,
        "NOCCode": "9532",
        "JobTitle": "assembly inspector – furniture manufacturing"
    },
    {
        "value": 26280,
        "NOCCode": "9532",
        "JobTitle": "assembly repairer, wooden furniture"
    },
    {
        "value": 26281,
        "NOCCode": "9532",
        "JobTitle": "assembly repairman/woman, wooden furniture"
    },
    {
        "value": 26282,
        "NOCCode": "9532",
        "JobTitle": "bed assembler"
    },
    {
        "value": 26283,
        "NOCCode": "9532",
        "JobTitle": "bedspring stretcher"
    },
    {
        "value": 26284,
        "NOCCode": "9532",
        "JobTitle": "bench worker – furniture manufacturing"
    },
    {
        "value": 26285,
        "NOCCode": "9532",
        "JobTitle": "billiard table assembler"
    },
    {
        "value": 26286,
        "NOCCode": "9532",
        "JobTitle": "box spring assembler"
    },
    {
        "value": 26287,
        "NOCCode": "9532",
        "JobTitle": "box spring frame assembler"
    },
    {
        "value": 26288,
        "NOCCode": "9532",
        "JobTitle": "box spring unit assembler – furniture manufacturing"
    },
    {
        "value": 26289,
        "NOCCode": "9532",
        "JobTitle": "cabinet assembler – furniture manufacturing"
    },
    {
        "value": 26290,
        "NOCCode": "9532",
        "JobTitle": "cabinet assembler – wood products manufacturing"
    },
    {
        "value": 26291,
        "NOCCode": "9532",
        "JobTitle": "cabinet inspector – furniture manufacturing"
    },
    {
        "value": 26292,
        "NOCCode": "9532",
        "JobTitle": "casket assembler, wood"
    },
    {
        "value": 26293,
        "NOCCode": "9532",
        "JobTitle": "casket maker, wood"
    },
    {
        "value": 26294,
        "NOCCode": "9532",
        "JobTitle": "chair assembler"
    },
    {
        "value": 26295,
        "NOCCode": "9532",
        "JobTitle": "chair caner – furniture manufacturing"
    },
    {
        "value": 26296,
        "NOCCode": "9532",
        "JobTitle": "chair inspector – furniture manufacturing"
    },
    {
        "value": 26297,
        "NOCCode": "9532",
        "JobTitle": "chair seat weaver"
    },
    {
        "value": 26298,
        "NOCCode": "9532",
        "JobTitle": "coffin and casket fitter"
    },
    {
        "value": 26299,
        "NOCCode": "9532",
        "JobTitle": "desk assembler"
    },
    {
        "value": 26300,
        "NOCCode": "9532",
        "JobTitle": "drawer assembler"
    },
    {
        "value": 26301,
        "NOCCode": "9532",
        "JobTitle": "drawer fitter"
    },
    {
        "value": 26302,
        "NOCCode": "9532",
        "JobTitle": "final inspector, furniture assembly"
    },
    {
        "value": 26303,
        "NOCCode": "9532",
        "JobTitle": "fitter-up, furniture – furniture manufacturing"
    },
    {
        "value": 26304,
        "NOCCode": "9532",
        "JobTitle": "fixture assembler"
    },
    {
        "value": 26305,
        "NOCCode": "9532",
        "JobTitle": "frame assembler, wood furniture"
    },
    {
        "value": 26306,
        "NOCCode": "9532",
        "JobTitle": "frame maker – furniture manufacturing"
    },
    {
        "value": 26307,
        "NOCCode": "9532",
        "JobTitle": "furniture and fixtures assembler"
    },
    {
        "value": 26308,
        "NOCCode": "9532",
        "JobTitle": "furniture and fixtures assembling inspector"
    },
    {
        "value": 26309,
        "NOCCode": "9532",
        "JobTitle": "furniture and fixtures assembly inspector"
    },
    {
        "value": 26310,
        "NOCCode": "9532",
        "JobTitle": "furniture and fixtures inspector – furniture manufacturing"
    },
    {
        "value": 26311,
        "NOCCode": "9532",
        "JobTitle": "furniture and fixtures manufacturing inspector"
    },
    {
        "value": 26312,
        "NOCCode": "9532",
        "JobTitle": "furniture assembler"
    },
    {
        "value": 26313,
        "NOCCode": "9532",
        "JobTitle": "furniture assembler, metal"
    },
    {
        "value": 26314,
        "NOCCode": "9532",
        "JobTitle": "furniture assembler, wood"
    },
    {
        "value": 26315,
        "NOCCode": "9532",
        "JobTitle": "furniture assembly inspector – furniture manufacturing"
    },
    {
        "value": 26316,
        "NOCCode": "9532",
        "JobTitle": "furniture caner"
    },
    {
        "value": 26317,
        "NOCCode": "9532",
        "JobTitle": "furniture checker"
    },
    {
        "value": 26318,
        "NOCCode": "9532",
        "JobTitle": "furniture finishing inspector"
    },
    {
        "value": 26319,
        "NOCCode": "9532",
        "JobTitle": "furniture fitter-up – furniture manufacturing"
    },
    {
        "value": 26320,
        "NOCCode": "9532",
        "JobTitle": "furniture inspector"
    },
    {
        "value": 26321,
        "NOCCode": "9532",
        "JobTitle": "furniture parts inspector"
    },
    {
        "value": 26322,
        "NOCCode": "9532",
        "JobTitle": "furniture shipping inspector"
    },
    {
        "value": 26323,
        "NOCCode": "9532",
        "JobTitle": "gluer – furniture manufacturing"
    },
    {
        "value": 26324,
        "NOCCode": "9532",
        "JobTitle": "grader, stock – furniture manufacturing"
    },
    {
        "value": 26325,
        "NOCCode": "9532",
        "JobTitle": "hand laminator – furniture manufacturing"
    },
    {
        "value": 26326,
        "NOCCode": "9532",
        "JobTitle": "hardware assembler – furniture manufacturing"
    },
    {
        "value": 26327,
        "NOCCode": "9532",
        "JobTitle": "hardware installer – furniture manufacturing"
    },
    {
        "value": 26328,
        "NOCCode": "9532",
        "JobTitle": "hardware installer, metal – furniture manufacturing"
    },
    {
        "value": 26329,
        "NOCCode": "9532",
        "JobTitle": "hardware mounter – furniture manufacturing"
    },
    {
        "value": 26330,
        "NOCCode": "9532",
        "JobTitle": "hydraulic chair assembler"
    },
    {
        "value": 26331,
        "NOCCode": "9532",
        "JobTitle": "inlayer – woodworking"
    },
    {
        "value": 26332,
        "NOCCode": "9532",
        "JobTitle": "inspector – furniture manufacturing"
    },
    {
        "value": 26333,
        "NOCCode": "9532",
        "JobTitle": "inspector, assembled furniture"
    },
    {
        "value": 26334,
        "NOCCode": "9532",
        "JobTitle": "inspector, assembly – furniture manufacturing"
    },
    {
        "value": 26335,
        "NOCCode": "9532",
        "JobTitle": "inspector, chairs – furniture manufacturing"
    },
    {
        "value": 26336,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture"
    },
    {
        "value": 26337,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture and fixtures – furniture manufacturing"
    },
    {
        "value": 26338,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture and fixtures assembling"
    },
    {
        "value": 26339,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture and fixtures assembly"
    },
    {
        "value": 26340,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture assembly – furniture manufacturing"
    },
    {
        "value": 26341,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture finishing"
    },
    {
        "value": 26342,
        "NOCCode": "9532",
        "JobTitle": "inspector, furniture parts"
    },
    {
        "value": 26343,
        "NOCCode": "9532",
        "JobTitle": "inspector, metal furniture"
    },
    {
        "value": 26344,
        "NOCCode": "9532",
        "JobTitle": "inspector, product – furniture manufacturing"
    },
    {
        "value": 26345,
        "NOCCode": "9532",
        "JobTitle": "inspector, sofas – furniture manufacturing"
    },
    {
        "value": 26346,
        "NOCCode": "9532",
        "JobTitle": "installer, hardware – furniture manufacturing"
    },
    {
        "value": 26347,
        "NOCCode": "9532",
        "JobTitle": "installer, metal hardware – furniture manufacturing"
    },
    {
        "value": 26348,
        "NOCCode": "9532",
        "JobTitle": "laminator, hand – furniture manufacturing"
    },
    {
        "value": 26349,
        "NOCCode": "9532",
        "JobTitle": "lead hand assembler – furniture manufacturing"
    },
    {
        "value": 26350,
        "NOCCode": "9532",
        "JobTitle": "marquetry worker – furniture manufacturing"
    },
    {
        "value": 26351,
        "NOCCode": "9532",
        "JobTitle": "mattress assembler – furniture manufacturing"
    },
    {
        "value": 26352,
        "NOCCode": "9532",
        "JobTitle": "mattress inspector"
    },
    {
        "value": 26353,
        "NOCCode": "9532",
        "JobTitle": "mattress maker"
    },
    {
        "value": 26354,
        "NOCCode": "9532",
        "JobTitle": "mattress manufacturing inspector"
    },
    {
        "value": 26355,
        "NOCCode": "9532",
        "JobTitle": "metal cabinet assembler"
    },
    {
        "value": 26356,
        "NOCCode": "9532",
        "JobTitle": "metal casket assembler"
    },
    {
        "value": 26357,
        "NOCCode": "9532",
        "JobTitle": "metal chair assembler"
    },
    {
        "value": 26358,
        "NOCCode": "9532",
        "JobTitle": "metal furniture assembler"
    },
    {
        "value": 26359,
        "NOCCode": "9532",
        "JobTitle": "metal furniture inspector"
    },
    {
        "value": 26360,
        "NOCCode": "9532",
        "JobTitle": "metal hardware installer – furniture manufacturing"
    },
    {
        "value": 26361,
        "NOCCode": "9532",
        "JobTitle": "metal locker assembler"
    },
    {
        "value": 26362,
        "NOCCode": "9532",
        "JobTitle": "metal table assembler"
    },
    {
        "value": 26363,
        "NOCCode": "9532",
        "JobTitle": "mirror and picture frame assembler"
    },
    {
        "value": 26364,
        "NOCCode": "9532",
        "JobTitle": "mirror and picture frame maker"
    },
    {
        "value": 26365,
        "NOCCode": "9532",
        "JobTitle": "office chair assembler"
    },
    {
        "value": 26366,
        "NOCCode": "9532",
        "JobTitle": "picture frame assembler – furniture and fixtures manufacturing"
    },
    {
        "value": 26367,
        "NOCCode": "9532",
        "JobTitle": "product inspector – furniture manufacturing"
    },
    {
        "value": 26368,
        "NOCCode": "9532",
        "JobTitle": "production worker – furniture manufacturing"
    },
    {
        "value": 26369,
        "NOCCode": "9532",
        "JobTitle": "sofa inspector – furniture manufacturing"
    },
    {
        "value": 26370,
        "NOCCode": "9532",
        "JobTitle": "stock grader – furniture manufacturing"
    },
    {
        "value": 26371,
        "NOCCode": "9532",
        "JobTitle": "subassembler – furniture manufacturing"
    },
    {
        "value": 26372,
        "NOCCode": "9532",
        "JobTitle": "table assembler"
    },
    {
        "value": 26373,
        "NOCCode": "9532",
        "JobTitle": "table assembler, wood"
    },
    {
        "value": 26374,
        "NOCCode": "9532",
        "JobTitle": "upfitter – furniture manufacturing"
    },
    {
        "value": 26375,
        "NOCCode": "9532",
        "JobTitle": "weaver, chair seat"
    },
    {
        "value": 26376,
        "NOCCode": "9532",
        "JobTitle": "wood furniture assembler"
    },
    {
        "value": 26377,
        "NOCCode": "9532",
        "JobTitle": "wooden casket assembler"
    },
    {
        "value": 26378,
        "NOCCode": "9532",
        "JobTitle": "wooden furniture assembly repairer"
    },
    {
        "value": 26379,
        "NOCCode": "9532",
        "JobTitle": "wooden table assembler"
    },
    {
        "value": 26380,
        "NOCCode": "9533",
        "JobTitle": "assembler, bench – wood products manufacturing"
    },
    {
        "value": 26381,
        "NOCCode": "9533",
        "JobTitle": "assembler, hockey sticks"
    },
    {
        "value": 26382,
        "NOCCode": "9533",
        "JobTitle": "assembler, hogsheads – wood products manufacturing"
    },
    {
        "value": 26383,
        "NOCCode": "9533",
        "JobTitle": "assembler, manufactured homes"
    },
    {
        "value": 26384,
        "NOCCode": "9533",
        "JobTitle": "assembler, millwork – wood products manufacturing"
    },
    {
        "value": 26385,
        "NOCCode": "9533",
        "JobTitle": "assembler, prefab housing"
    },
    {
        "value": 26386,
        "NOCCode": "9533",
        "JobTitle": "assembler, wood products"
    },
    {
        "value": 26387,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden barrel staves"
    },
    {
        "value": 26388,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden boxes"
    },
    {
        "value": 26389,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden bucket staves"
    },
    {
        "value": 26390,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden cases"
    },
    {
        "value": 26391,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden casket frames"
    },
    {
        "value": 26392,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden crates"
    },
    {
        "value": 26393,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden doors"
    },
    {
        "value": 26394,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden prefab housing"
    },
    {
        "value": 26395,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden reels"
    },
    {
        "value": 26396,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden tank staves"
    },
    {
        "value": 26397,
        "NOCCode": "9533",
        "JobTitle": "assembler, wooden tanks"
    },
    {
        "value": 26398,
        "NOCCode": "9533",
        "JobTitle": "assembler, woodenware"
    },
    {
        "value": 26399,
        "NOCCode": "9533",
        "JobTitle": "assemblers lead hand – wood products manufacturing"
    },
    {
        "value": 26400,
        "NOCCode": "9533",
        "JobTitle": "assembly line gluer – wood products manufacturing"
    },
    {
        "value": 26401,
        "NOCCode": "9533",
        "JobTitle": "banquette assembler – wood product manufacturing"
    },
    {
        "value": 26402,
        "NOCCode": "9533",
        "JobTitle": "barrel builder, wood"
    },
    {
        "value": 26403,
        "NOCCode": "9533",
        "JobTitle": "barrel end fitter, wood"
    },
    {
        "value": 26404,
        "NOCCode": "9533",
        "JobTitle": "barrel header, wood"
    },
    {
        "value": 26405,
        "NOCCode": "9533",
        "JobTitle": "barrel heading fitter, wood"
    },
    {
        "value": 26406,
        "NOCCode": "9533",
        "JobTitle": "barrel inspector – wood products manufacturing"
    },
    {
        "value": 26407,
        "NOCCode": "9533",
        "JobTitle": "barrel maker, wood"
    },
    {
        "value": 26408,
        "NOCCode": "9533",
        "JobTitle": "barrel stave assembler, wood"
    },
    {
        "value": 26409,
        "NOCCode": "9533",
        "JobTitle": "basket maker, wood"
    },
    {
        "value": 26410,
        "NOCCode": "9533",
        "JobTitle": "bench assembler – wood products manufacturing"
    },
    {
        "value": 26411,
        "NOCCode": "9533",
        "JobTitle": "bench assembler, wood products"
    },
    {
        "value": 26412,
        "NOCCode": "9533",
        "JobTitle": "bench hand, wooden box"
    },
    {
        "value": 26413,
        "NOCCode": "9533",
        "JobTitle": "box assembler, wood"
    },
    {
        "value": 26414,
        "NOCCode": "9533",
        "JobTitle": "box blank former, wood"
    },
    {
        "value": 26415,
        "NOCCode": "9533",
        "JobTitle": "box builder, wood"
    },
    {
        "value": 26416,
        "NOCCode": "9533",
        "JobTitle": "box maker, wood"
    },
    {
        "value": 26417,
        "NOCCode": "9533",
        "JobTitle": "case maker, wood"
    },
    {
        "value": 26418,
        "NOCCode": "9533",
        "JobTitle": "casket frame assembler, wood"
    },
    {
        "value": 26419,
        "NOCCode": "9533",
        "JobTitle": "casket inspector, wood"
    },
    {
        "value": 26420,
        "NOCCode": "9533",
        "JobTitle": "casket moulding setter, wood"
    },
    {
        "value": 26421,
        "NOCCode": "9533",
        "JobTitle": "casket top frame fitter, wood"
    },
    {
        "value": 26422,
        "NOCCode": "9533",
        "JobTitle": "casket top frame setter, wood"
    },
    {
        "value": 26423,
        "NOCCode": "9533",
        "JobTitle": "clamp carrier"
    },
    {
        "value": 26424,
        "NOCCode": "9533",
        "JobTitle": "cooper"
    },
    {
        "value": 26425,
        "NOCCode": "9533",
        "JobTitle": "crate builder, wood"
    },
    {
        "value": 26426,
        "NOCCode": "9533",
        "JobTitle": "crate maker, wood"
    },
    {
        "value": 26427,
        "NOCCode": "9533",
        "JobTitle": "door assembler, wood"
    },
    {
        "value": 26428,
        "NOCCode": "9533",
        "JobTitle": "door finisher – wood products manufacturing"
    },
    {
        "value": 26429,
        "NOCCode": "9533",
        "JobTitle": "door inspector, wood"
    },
    {
        "value": 26430,
        "NOCCode": "9533",
        "JobTitle": "door maker, wood"
    },
    {
        "value": 26431,
        "NOCCode": "9533",
        "JobTitle": "door patcher, wood – wood products manufacturing"
    },
    {
        "value": 26432,
        "NOCCode": "9533",
        "JobTitle": "door repairer, wood – wood products manufacturing"
    },
    {
        "value": 26433,
        "NOCCode": "9533",
        "JobTitle": "fabricator, wooden modular housing"
    },
    {
        "value": 26434,
        "NOCCode": "9533",
        "JobTitle": "factory assembler, wood products"
    },
    {
        "value": 26435,
        "NOCCode": "9533",
        "JobTitle": "finisher, doors – wood products manufacturing"
    },
    {
        "value": 26436,
        "NOCCode": "9533",
        "JobTitle": "finisher, wooden golf club heads"
    },
    {
        "value": 26437,
        "NOCCode": "9533",
        "JobTitle": "fitter, wooden barrel ends"
    },
    {
        "value": 26438,
        "NOCCode": "9533",
        "JobTitle": "fitter, wooden barrel headings"
    },
    {
        "value": 26439,
        "NOCCode": "9533",
        "JobTitle": "fitter, wooden casket top frames"
    },
    {
        "value": 26440,
        "NOCCode": "9533",
        "JobTitle": "flask maker, wood – foundry"
    },
    {
        "value": 26441,
        "NOCCode": "9533",
        "JobTitle": "glue clamp operator – wood products manufacturing"
    },
    {
        "value": 26442,
        "NOCCode": "9533",
        "JobTitle": "glue clamp operator, chain carrier – wood products manufacturing"
    },
    {
        "value": 26443,
        "NOCCode": "9533",
        "JobTitle": "gluer, assembly line – wood products manufacturing"
    },
    {
        "value": 26444,
        "NOCCode": "9533",
        "JobTitle": "gluer, wood products"
    },
    {
        "value": 26445,
        "NOCCode": "9533",
        "JobTitle": "golf club facer, wood"
    },
    {
        "value": 26446,
        "NOCCode": "9533",
        "JobTitle": "golf club head finisher, wood"
    },
    {
        "value": 26447,
        "NOCCode": "9533",
        "JobTitle": "grader, wooden sashes and doors"
    },
    {
        "value": 26448,
        "NOCCode": "9533",
        "JobTitle": "hockey stick assembler"
    },
    {
        "value": 26449,
        "NOCCode": "9533",
        "JobTitle": "hockey stick inspector-grader"
    },
    {
        "value": 26450,
        "NOCCode": "9533",
        "JobTitle": "hogshead assembler – wood products manufacturing"
    },
    {
        "value": 26451,
        "NOCCode": "9533",
        "JobTitle": "inspector, barrels – wood products manufacturing"
    },
    {
        "value": 26452,
        "NOCCode": "9533",
        "JobTitle": "inspector, laminated beams"
    },
    {
        "value": 26453,
        "NOCCode": "9533",
        "JobTitle": "inspector, quality control – wood products manufacturing"
    },
    {
        "value": 26454,
        "NOCCode": "9533",
        "JobTitle": "inspector, sanding – woodworking"
    },
    {
        "value": 26455,
        "NOCCode": "9533",
        "JobTitle": "inspector, sashes and doors – millwork manufacturing"
    },
    {
        "value": 26456,
        "NOCCode": "9533",
        "JobTitle": "inspector, wood machining"
    },
    {
        "value": 26457,
        "NOCCode": "9533",
        "JobTitle": "inspector, wood products – wood products manufacturing"
    },
    {
        "value": 26458,
        "NOCCode": "9533",
        "JobTitle": "inspector, wood products assembly"
    },
    {
        "value": 26459,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden boxes"
    },
    {
        "value": 26460,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden caskets"
    },
    {
        "value": 26461,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden doors"
    },
    {
        "value": 26462,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden sashes and doors"
    },
    {
        "value": 26463,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden shuttles"
    },
    {
        "value": 26464,
        "NOCCode": "9533",
        "JobTitle": "inspector, wooden staves"
    },
    {
        "value": 26465,
        "NOCCode": "9533",
        "JobTitle": "inspector-grader, hockey sticks"
    },
    {
        "value": 26466,
        "NOCCode": "9533",
        "JobTitle": "keg builder, wood"
    },
    {
        "value": 26467,
        "NOCCode": "9533",
        "JobTitle": "keg maker, wood"
    },
    {
        "value": 26468,
        "NOCCode": "9533",
        "JobTitle": "ladder assembler, wood"
    },
    {
        "value": 26469,
        "NOCCode": "9533",
        "JobTitle": "ladder builder, wood"
    },
    {
        "value": 26470,
        "NOCCode": "9533",
        "JobTitle": "laminated beam inspector"
    },
    {
        "value": 26471,
        "NOCCode": "9533",
        "JobTitle": "lead hand, assemblers – wood products manufacturing"
    },
    {
        "value": 26472,
        "NOCCode": "9533",
        "JobTitle": "lead hand, wood products assembly"
    },
    {
        "value": 26473,
        "NOCCode": "9533",
        "JobTitle": "manufactured home assembler"
    },
    {
        "value": 26474,
        "NOCCode": "9533",
        "JobTitle": "manufactured housing production worker"
    },
    {
        "value": 26475,
        "NOCCode": "9533",
        "JobTitle": "matcher and assembler, wooden barrel headings"
    },
    {
        "value": 26476,
        "NOCCode": "9533",
        "JobTitle": "millwork assembler"
    },
    {
        "value": 26477,
        "NOCCode": "9533",
        "JobTitle": "millwork assembler – wood products manufacturing"
    },
    {
        "value": 26478,
        "NOCCode": "9533",
        "JobTitle": "modular housing fabricator, wood"
    },
    {
        "value": 26479,
        "NOCCode": "9533",
        "JobTitle": "pallet maker, wood"
    },
    {
        "value": 26480,
        "NOCCode": "9533",
        "JobTitle": "panel matcher, wood"
    },
    {
        "value": 26481,
        "NOCCode": "9533",
        "JobTitle": "prefab housing assembler"
    },
    {
        "value": 26482,
        "NOCCode": "9533",
        "JobTitle": "prefab housing assembler, wood"
    },
    {
        "value": 26483,
        "NOCCode": "9533",
        "JobTitle": "prefabricated housing assembler"
    },
    {
        "value": 26484,
        "NOCCode": "9533",
        "JobTitle": "prefabricated housing production worker, wood"
    },
    {
        "value": 26485,
        "NOCCode": "9533",
        "JobTitle": "production worker, wooden manufactured homes"
    },
    {
        "value": 26486,
        "NOCCode": "9533",
        "JobTitle": "production worker, wooden manufactured housing"
    },
    {
        "value": 26487,
        "NOCCode": "9533",
        "JobTitle": "production worker, wooden prefabricated housing"
    },
    {
        "value": 26488,
        "NOCCode": "9533",
        "JobTitle": "quality control inspector – wood products manufacturing"
    },
    {
        "value": 26489,
        "NOCCode": "9533",
        "JobTitle": "reel assembler, wood"
    },
    {
        "value": 26490,
        "NOCCode": "9533",
        "JobTitle": "repairer, assembled wood products"
    },
    {
        "value": 26491,
        "NOCCode": "9533",
        "JobTitle": "repairer, wood furniture – wood products manufacturing"
    },
    {
        "value": 26492,
        "NOCCode": "9533",
        "JobTitle": "repairer, wooden doors – wood products manufacturing"
    },
    {
        "value": 26493,
        "NOCCode": "9533",
        "JobTitle": "repairman/woman, sash and door manufacturing"
    },
    {
        "value": 26494,
        "NOCCode": "9533",
        "JobTitle": "sanding inspector – woodworking"
    },
    {
        "value": 26495,
        "NOCCode": "9533",
        "JobTitle": "sash and door grader, wood"
    },
    {
        "value": 26496,
        "NOCCode": "9533",
        "JobTitle": "sash and door inspector"
    },
    {
        "value": 26497,
        "NOCCode": "9533",
        "JobTitle": "sash and door inspector – millwork manufacturing"
    },
    {
        "value": 26498,
        "NOCCode": "9533",
        "JobTitle": "sash and door manufacturing repairman/woman"
    },
    {
        "value": 26499,
        "NOCCode": "9533",
        "JobTitle": "sawyer and assembler, wooden tank bottoms"
    },
    {
        "value": 26500,
        "NOCCode": "9533",
        "JobTitle": "setter, wooden casket moulding"
    },
    {
        "value": 26501,
        "NOCCode": "9533",
        "JobTitle": "setter, wooden casket top frames"
    },
    {
        "value": 26502,
        "NOCCode": "9533",
        "JobTitle": "shop assembler – wood products manufacturing"
    },
    {
        "value": 26503,
        "NOCCode": "9533",
        "JobTitle": "shuttle inspector, wood"
    },
    {
        "value": 26504,
        "NOCCode": "9533",
        "JobTitle": "stave inspector, wood"
    },
    {
        "value": 26505,
        "NOCCode": "9533",
        "JobTitle": "tank assembler, wood"
    },
    {
        "value": 26506,
        "NOCCode": "9533",
        "JobTitle": "tank stave assembler, wood"
    },
    {
        "value": 26507,
        "NOCCode": "9533",
        "JobTitle": "truss maker, wood"
    },
    {
        "value": 26508,
        "NOCCode": "9533",
        "JobTitle": "wood furniture repairer – wood products manufacturing"
    },
    {
        "value": 26509,
        "NOCCode": "9533",
        "JobTitle": "wood machining inspector"
    },
    {
        "value": 26510,
        "NOCCode": "9533",
        "JobTitle": "wood products assembler"
    },
    {
        "value": 26511,
        "NOCCode": "9533",
        "JobTitle": "wood products assembling inspector"
    },
    {
        "value": 26512,
        "NOCCode": "9533",
        "JobTitle": "wood products assembly inspector"
    },
    {
        "value": 26513,
        "NOCCode": "9533",
        "JobTitle": "wood products assembly lead hand"
    },
    {
        "value": 26514,
        "NOCCode": "9533",
        "JobTitle": "wood products factory assembler"
    },
    {
        "value": 26515,
        "NOCCode": "9533",
        "JobTitle": "wood products gluer"
    },
    {
        "value": 26516,
        "NOCCode": "9533",
        "JobTitle": "wood products inspector – wood products manufacturing"
    },
    {
        "value": 26517,
        "NOCCode": "9533",
        "JobTitle": "wooden barrel builder"
    },
    {
        "value": 26518,
        "NOCCode": "9533",
        "JobTitle": "wooden barrel header"
    },
    {
        "value": 26519,
        "NOCCode": "9533",
        "JobTitle": "wooden barrel heading matcher and assembler"
    },
    {
        "value": 26520,
        "NOCCode": "9533",
        "JobTitle": "wooden barrel maker"
    },
    {
        "value": 26521,
        "NOCCode": "9533",
        "JobTitle": "wooden basket maker"
    },
    {
        "value": 26522,
        "NOCCode": "9533",
        "JobTitle": "wooden box assembler"
    },
    {
        "value": 26523,
        "NOCCode": "9533",
        "JobTitle": "wooden box bench hand"
    },
    {
        "value": 26524,
        "NOCCode": "9533",
        "JobTitle": "wooden box builder"
    },
    {
        "value": 26525,
        "NOCCode": "9533",
        "JobTitle": "wooden case builder"
    },
    {
        "value": 26526,
        "NOCCode": "9533",
        "JobTitle": "wooden casket inspector"
    },
    {
        "value": 26527,
        "NOCCode": "9533",
        "JobTitle": "wooden door assembler"
    },
    {
        "value": 26528,
        "NOCCode": "9533",
        "JobTitle": "wooden door inspector"
    },
    {
        "value": 26529,
        "NOCCode": "9533",
        "JobTitle": "wooden door maker"
    },
    {
        "value": 26530,
        "NOCCode": "9533",
        "JobTitle": "wooden door repairer – wood products manufacturing"
    },
    {
        "value": 26531,
        "NOCCode": "9533",
        "JobTitle": "wooden golf club facer"
    },
    {
        "value": 26532,
        "NOCCode": "9533",
        "JobTitle": "wooden hoop maker"
    },
    {
        "value": 26533,
        "NOCCode": "9533",
        "JobTitle": "wooden keg builder"
    },
    {
        "value": 26534,
        "NOCCode": "9533",
        "JobTitle": "wooden keg maker"
    },
    {
        "value": 26535,
        "NOCCode": "9533",
        "JobTitle": "wooden ladder assembler"
    },
    {
        "value": 26536,
        "NOCCode": "9533",
        "JobTitle": "wooden ladder builder"
    },
    {
        "value": 26537,
        "NOCCode": "9533",
        "JobTitle": "wooden pallet assembler"
    },
    {
        "value": 26538,
        "NOCCode": "9533",
        "JobTitle": "wooden pallet maker"
    },
    {
        "value": 26539,
        "NOCCode": "9533",
        "JobTitle": "wooden panel matcher"
    },
    {
        "value": 26540,
        "NOCCode": "9533",
        "JobTitle": "wooden reel assembler"
    },
    {
        "value": 26541,
        "NOCCode": "9533",
        "JobTitle": "wooden sash and door assembler"
    },
    {
        "value": 26542,
        "NOCCode": "9533",
        "JobTitle": "wooden sash and door inspector"
    },
    {
        "value": 26543,
        "NOCCode": "9533",
        "JobTitle": "wooden shuttle inspector"
    },
    {
        "value": 26544,
        "NOCCode": "9533",
        "JobTitle": "wooden tank bottom sawyer and assembler"
    },
    {
        "value": 26545,
        "NOCCode": "9533",
        "JobTitle": "wooden truss maker"
    },
    {
        "value": 26546,
        "NOCCode": "9533",
        "JobTitle": "woodenware assembler"
    },
    {
        "value": 26547,
        "NOCCode": "9534",
        "JobTitle": "antiquer – furniture finishing"
    },
    {
        "value": 26548,
        "NOCCode": "9534",
        "JobTitle": "buffer, furniture – furniture finishing"
    },
    {
        "value": 26549,
        "NOCCode": "9534",
        "JobTitle": "decorator – furniture finishing and refinishing"
    },
    {
        "value": 26550,
        "NOCCode": "9534",
        "JobTitle": "decorator, furniture finishing"
    },
    {
        "value": 26551,
        "NOCCode": "9534",
        "JobTitle": "finisher – furniture finishing"
    },
    {
        "value": 26552,
        "NOCCode": "9534",
        "JobTitle": "finisher and repairer, furniture"
    },
    {
        "value": 26553,
        "NOCCode": "9534",
        "JobTitle": "finisher, frame mouldings – furniture manufacturing"
    },
    {
        "value": 26554,
        "NOCCode": "9534",
        "JobTitle": "finisher, furniture"
    },
    {
        "value": 26555,
        "NOCCode": "9534",
        "JobTitle": "finisher, wood – furniture finishing and refinishing"
    },
    {
        "value": 26556,
        "NOCCode": "9534",
        "JobTitle": "finishing machine operator – furniture manufacturing"
    },
    {
        "value": 26557,
        "NOCCode": "9534",
        "JobTitle": "frame moulding finisher – furniture manufacturing"
    },
    {
        "value": 26558,
        "NOCCode": "9534",
        "JobTitle": "frame moulding painter – furniture manufacturing"
    },
    {
        "value": 26559,
        "NOCCode": "9534",
        "JobTitle": "furniture buffer – furniture finishing"
    },
    {
        "value": 26560,
        "NOCCode": "9534",
        "JobTitle": "furniture decorator – furniture finishing and refinishing"
    },
    {
        "value": 26561,
        "NOCCode": "9534",
        "JobTitle": "furniture enameller"
    },
    {
        "value": 26562,
        "NOCCode": "9534",
        "JobTitle": "furniture finisher"
    },
    {
        "value": 26563,
        "NOCCode": "9534",
        "JobTitle": "furniture finisher and repairer"
    },
    {
        "value": 26564,
        "NOCCode": "9534",
        "JobTitle": "furniture finishing decorator"
    },
    {
        "value": 26565,
        "NOCCode": "9534",
        "JobTitle": "furniture finishing varnisher"
    },
    {
        "value": 26566,
        "NOCCode": "9534",
        "JobTitle": "furniture polisher, wood – furniture finishing"
    },
    {
        "value": 26567,
        "NOCCode": "9534",
        "JobTitle": "furniture refinisher"
    },
    {
        "value": 26568,
        "NOCCode": "9534",
        "JobTitle": "furniture refinishing varnisher"
    },
    {
        "value": 26569,
        "NOCCode": "9534",
        "JobTitle": "furniture reproducer"
    },
    {
        "value": 26570,
        "NOCCode": "9534",
        "JobTitle": "furniture stainer"
    },
    {
        "value": 26571,
        "NOCCode": "9534",
        "JobTitle": "furniture stripper"
    },
    {
        "value": 26572,
        "NOCCode": "9534",
        "JobTitle": "furniture varnisher"
    },
    {
        "value": 26573,
        "NOCCode": "9534",
        "JobTitle": "furniture-finishing machine operator"
    },
    {
        "value": 26574,
        "NOCCode": "9534",
        "JobTitle": "grainer – furniture finishing and refinishing"
    },
    {
        "value": 26575,
        "NOCCode": "9534",
        "JobTitle": "grainer, wood – furniture finishing and refinishing"
    },
    {
        "value": 26576,
        "NOCCode": "9534",
        "JobTitle": "matcher – furniture finishing and refinishing"
    },
    {
        "value": 26577,
        "NOCCode": "9534",
        "JobTitle": "metal furniture repairer"
    },
    {
        "value": 26578,
        "NOCCode": "9534",
        "JobTitle": "ornamenter – furniture finishing and refinishing"
    },
    {
        "value": 26579,
        "NOCCode": "9534",
        "JobTitle": "painter, frame mouldings – furniture manufacturing"
    },
    {
        "value": 26580,
        "NOCCode": "9534",
        "JobTitle": "piano and organ finisher"
    },
    {
        "value": 26581,
        "NOCCode": "9534",
        "JobTitle": "piano and organ refinisher"
    },
    {
        "value": 26582,
        "NOCCode": "9534",
        "JobTitle": "polisher, furniture – furniture finishing"
    },
    {
        "value": 26583,
        "NOCCode": "9534",
        "JobTitle": "polisher, wooden furniture – furniture finishing"
    },
    {
        "value": 26584,
        "NOCCode": "9534",
        "JobTitle": "refinisher, furniture"
    },
    {
        "value": 26585,
        "NOCCode": "9534",
        "JobTitle": "repairer, metal furniture"
    },
    {
        "value": 26586,
        "NOCCode": "9534",
        "JobTitle": "satin finisher – furniture finishing and refinishing"
    },
    {
        "value": 26587,
        "NOCCode": "9534",
        "JobTitle": "shader – furniture finishing and refinishing"
    },
    {
        "value": 26588,
        "NOCCode": "9534",
        "JobTitle": "shellac sprayers – furniture finishing and refinishing"
    },
    {
        "value": 26589,
        "NOCCode": "9534",
        "JobTitle": "spray stainer – furniture finishing and refinishing"
    },
    {
        "value": 26590,
        "NOCCode": "9534",
        "JobTitle": "sprayer, rough shellac – furniture finishing and refinishing"
    },
    {
        "value": 26591,
        "NOCCode": "9534",
        "JobTitle": "sprayer, shellac – furniture finishing and refinishing"
    },
    {
        "value": 26592,
        "NOCCode": "9534",
        "JobTitle": "stainer, furniture"
    },
    {
        "value": 26593,
        "NOCCode": "9534",
        "JobTitle": "stripper, furniture"
    },
    {
        "value": 26594,
        "NOCCode": "9534",
        "JobTitle": "touch-up person – furniture finishing"
    },
    {
        "value": 26595,
        "NOCCode": "9534",
        "JobTitle": "touch-up worker – furniture finishing"
    },
    {
        "value": 26596,
        "NOCCode": "9534",
        "JobTitle": "trimmer – furniture finishing"
    },
    {
        "value": 26597,
        "NOCCode": "9534",
        "JobTitle": "varnisher, furniture"
    },
    {
        "value": 26598,
        "NOCCode": "9534",
        "JobTitle": "varnisher, furniture finishing"
    },
    {
        "value": 26599,
        "NOCCode": "9534",
        "JobTitle": "varnisher, furniture refinishing"
    },
    {
        "value": 26600,
        "NOCCode": "9534",
        "JobTitle": "wash-off operator – furniture finishing"
    },
    {
        "value": 26601,
        "NOCCode": "9534",
        "JobTitle": "whitener – furniture finishing and refinishing"
    },
    {
        "value": 26602,
        "NOCCode": "9534",
        "JobTitle": "wood finisher – furniture finishing and refinishing"
    },
    {
        "value": 26603,
        "NOCCode": "9534",
        "JobTitle": "wood grainer – furniture finishing and refinishing"
    },
    {
        "value": 26604,
        "NOCCode": "9535",
        "JobTitle": "aircraft parts assembly repairer – plastic products manufacturing"
    },
    {
        "value": 26605,
        "NOCCode": "9535",
        "JobTitle": "aircraft plastic panelling assembler"
    },
    {
        "value": 26606,
        "NOCCode": "9535",
        "JobTitle": "aircraft plastic parts trimmer-assembler"
    },
    {
        "value": 26607,
        "NOCCode": "9535",
        "JobTitle": "assembler, aircraft plastic panelling"
    },
    {
        "value": 26608,
        "NOCCode": "9535",
        "JobTitle": "assembler, automotive door panelling – plastic products manufacturing"
    },
    {
        "value": 26609,
        "NOCCode": "9535",
        "JobTitle": "assembler, cooler shells – plastic products manufacturing"
    },
    {
        "value": 26610,
        "NOCCode": "9535",
        "JobTitle": "assembler, fibreglass filters"
    },
    {
        "value": 26611,
        "NOCCode": "9535",
        "JobTitle": "assembler, luggage bindings – plastic products manufacturing"
    },
    {
        "value": 26612,
        "NOCCode": "9535",
        "JobTitle": "assembler, marine craft plastic panelling"
    },
    {
        "value": 26613,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastic cases"
    },
    {
        "value": 26614,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastic parts"
    },
    {
        "value": 26615,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastic products"
    },
    {
        "value": 26616,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastic tanks"
    },
    {
        "value": 26617,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastic toys"
    },
    {
        "value": 26618,
        "NOCCode": "9535",
        "JobTitle": "assembler, plastics"
    },
    {
        "value": 26619,
        "NOCCode": "9535",
        "JobTitle": "assembler, Plexiglas"
    },
    {
        "value": 26620,
        "NOCCode": "9535",
        "JobTitle": "assembler, skylights – plastic products manufacturing"
    },
    {
        "value": 26621,
        "NOCCode": "9535",
        "JobTitle": "assembly inspector – plastic manufacturing"
    },
    {
        "value": 26622,
        "NOCCode": "9535",
        "JobTitle": "assembly line inspector – plastic manufacturing"
    },
    {
        "value": 26623,
        "NOCCode": "9535",
        "JobTitle": "assembly repairer, plastic products"
    },
    {
        "value": 26624,
        "NOCCode": "9535",
        "JobTitle": "atomizer cap machine tender"
    },
    {
        "value": 26625,
        "NOCCode": "9535",
        "JobTitle": "automotive door panelling assembler – plastic products manufacturing"
    },
    {
        "value": 26626,
        "NOCCode": "9535",
        "JobTitle": "belt laminator, hand – plastic products manufacturing"
    },
    {
        "value": 26627,
        "NOCCode": "9535",
        "JobTitle": "blister former – plastic products manufacturing"
    },
    {
        "value": 26628,
        "NOCCode": "9535",
        "JobTitle": "buffer, plastic products"
    },
    {
        "value": 26629,
        "NOCCode": "9535",
        "JobTitle": "buffing machine operator – plastic manufacturing"
    },
    {
        "value": 26630,
        "NOCCode": "9535",
        "JobTitle": "builder, plastic templates"
    },
    {
        "value": 26631,
        "NOCCode": "9535",
        "JobTitle": "cellophane finisher – plastic manufacturing"
    },
    {
        "value": 26632,
        "NOCCode": "9535",
        "JobTitle": "cementer, plastics"
    },
    {
        "value": 26633,
        "NOCCode": "9535",
        "JobTitle": "changer, plates – plastic manufacturing"
    },
    {
        "value": 26634,
        "NOCCode": "9535",
        "JobTitle": "compact disc inspector – plastic products manufacturing"
    },
    {
        "value": 26635,
        "NOCCode": "9535",
        "JobTitle": "cooler shell assembler – plastic products manufacturing"
    },
    {
        "value": 26636,
        "NOCCode": "9535",
        "JobTitle": "cutter, floorcovering insets – plastic manufacturing"
    },
    {
        "value": 26637,
        "NOCCode": "9535",
        "JobTitle": "cutter, plastics"
    },
    {
        "value": 26638,
        "NOCCode": "9535",
        "JobTitle": "dicer tender – plastic manufacturing"
    },
    {
        "value": 26639,
        "NOCCode": "9535",
        "JobTitle": "dicing machine tender – plastic manufacturing"
    },
    {
        "value": 26640,
        "NOCCode": "9535",
        "JobTitle": "fabricator, plastic coating"
    },
    {
        "value": 26641,
        "NOCCode": "9535",
        "JobTitle": "fabricator, plastic packing"
    },
    {
        "value": 26642,
        "NOCCode": "9535",
        "JobTitle": "fabricator, plastic piping"
    },
    {
        "value": 26643,
        "NOCCode": "9535",
        "JobTitle": "fabricator, plastic products"
    },
    {
        "value": 26644,
        "NOCCode": "9535",
        "JobTitle": "fibreglass filter assembler"
    },
    {
        "value": 26645,
        "NOCCode": "9535",
        "JobTitle": "fibreglass inspector"
    },
    {
        "value": 26646,
        "NOCCode": "9535",
        "JobTitle": "fibreglass laminator"
    },
    {
        "value": 26647,
        "NOCCode": "9535",
        "JobTitle": "fibreglass layup man/woman – plastic manufacturing"
    },
    {
        "value": 26648,
        "NOCCode": "9535",
        "JobTitle": "fibreglass layup worker – plastic manufacturing"
    },
    {
        "value": 26649,
        "NOCCode": "9535",
        "JobTitle": "filler-stripper, moulds – plastic manufacturing"
    },
    {
        "value": 26650,
        "NOCCode": "9535",
        "JobTitle": "finisher, cellophane – plastic manufacturing"
    },
    {
        "value": 26651,
        "NOCCode": "9535",
        "JobTitle": "finisher, moulded plastic"
    },
    {
        "value": 26652,
        "NOCCode": "9535",
        "JobTitle": "finisher, plastic products"
    },
    {
        "value": 26653,
        "NOCCode": "9535",
        "JobTitle": "finisher, plastic sheets"
    },
    {
        "value": 26654,
        "NOCCode": "9535",
        "JobTitle": "finisher, radomes – plastic manufacturing"
    },
    {
        "value": 26655,
        "NOCCode": "9535",
        "JobTitle": "finisher-grinder – plastic manufacturing"
    },
    {
        "value": 26656,
        "NOCCode": "9535",
        "JobTitle": "floorcovering inset cutter – plastic manufacturing"
    },
    {
        "value": 26657,
        "NOCCode": "9535",
        "JobTitle": "foam cushion production repairer – plastic products manufacturing"
    },
    {
        "value": 26658,
        "NOCCode": "9535",
        "JobTitle": "foam cushion reinforcer – plastic products manufacturing"
    },
    {
        "value": 26659,
        "NOCCode": "9535",
        "JobTitle": "former, blisters – plastic products manufacturing"
    },
    {
        "value": 26660,
        "NOCCode": "9535",
        "JobTitle": "former, Plexiglas"
    },
    {
        "value": 26661,
        "NOCCode": "9535",
        "JobTitle": "inspector and tester, plastic products"
    },
    {
        "value": 26662,
        "NOCCode": "9535",
        "JobTitle": "inspector, assembly line – plastic manufacturing"
    },
    {
        "value": 26663,
        "NOCCode": "9535",
        "JobTitle": "inspector, fibreglass"
    },
    {
        "value": 26664,
        "NOCCode": "9535",
        "JobTitle": "inspector, linoleum"
    },
    {
        "value": 26665,
        "NOCCode": "9535",
        "JobTitle": "inspector, moulded plastic"
    },
    {
        "value": 26666,
        "NOCCode": "9535",
        "JobTitle": "inspector, plastic products"
    },
    {
        "value": 26667,
        "NOCCode": "9535",
        "JobTitle": "inspector, plastic products manufacturing"
    },
    {
        "value": 26668,
        "NOCCode": "9535",
        "JobTitle": "inspector, plastics"
    },
    {
        "value": 26669,
        "NOCCode": "9535",
        "JobTitle": "inspector-tester, moulded plastic"
    },
    {
        "value": 26670,
        "NOCCode": "9535",
        "JobTitle": "laminator, moulds – plastic manufacturing"
    },
    {
        "value": 26671,
        "NOCCode": "9535",
        "JobTitle": "laminator, plastics – plastic manufacturing"
    },
    {
        "value": 26672,
        "NOCCode": "9535",
        "JobTitle": "layup man/woman, fibreglass – plastic manufacturing"
    },
    {
        "value": 26673,
        "NOCCode": "9535",
        "JobTitle": "layup worker, fibreglass – plastic manufacturing"
    },
    {
        "value": 26674,
        "NOCCode": "9535",
        "JobTitle": "layup worker, plastic reproduction"
    },
    {
        "value": 26675,
        "NOCCode": "9535",
        "JobTitle": "line inspector, linoleum"
    },
    {
        "value": 26676,
        "NOCCode": "9535",
        "JobTitle": "linoleum inspector"
    },
    {
        "value": 26677,
        "NOCCode": "9535",
        "JobTitle": "linoleum line inspector"
    },
    {
        "value": 26678,
        "NOCCode": "9535",
        "JobTitle": "linoleum quality control inspector"
    },
    {
        "value": 26679,
        "NOCCode": "9535",
        "JobTitle": "manufacturing inspector, plastic products"
    },
    {
        "value": 26680,
        "NOCCode": "9535",
        "JobTitle": "marine craft plastic panelling assembler"
    },
    {
        "value": 26681,
        "NOCCode": "9535",
        "JobTitle": "mould filler-stripper – plastic manufacturing"
    },
    {
        "value": 26682,
        "NOCCode": "9535",
        "JobTitle": "mould laminator – plastic manufacturing"
    },
    {
        "value": 26683,
        "NOCCode": "9535",
        "JobTitle": "mould stripper – plastic manufacturing"
    },
    {
        "value": 26684,
        "NOCCode": "9535",
        "JobTitle": "mould waxer – plastic manufacturing"
    },
    {
        "value": 26685,
        "NOCCode": "9535",
        "JobTitle": "moulded plastic finisher"
    },
    {
        "value": 26686,
        "NOCCode": "9535",
        "JobTitle": "moulded plastic inspector"
    },
    {
        "value": 26687,
        "NOCCode": "9535",
        "JobTitle": "moulded plastic inspector-tester"
    },
    {
        "value": 26688,
        "NOCCode": "9535",
        "JobTitle": "moulded plastic products trimmer"
    },
    {
        "value": 26689,
        "NOCCode": "9535",
        "JobTitle": "moulded products finisher – plastic products manufacturing"
    },
    {
        "value": 26690,
        "NOCCode": "9535",
        "JobTitle": "moulded products stripper – plastic products manufacturing"
    },
    {
        "value": 26691,
        "NOCCode": "9535",
        "JobTitle": "moulder, plastic ankle pieces"
    },
    {
        "value": 26692,
        "NOCCode": "9535",
        "JobTitle": "moulder, upholstery – plastic manufacturing"
    },
    {
        "value": 26693,
        "NOCCode": "9535",
        "JobTitle": "net applicator – plastic products manufacturing"
    },
    {
        "value": 26694,
        "NOCCode": "9535",
        "JobTitle": "padded dashboard maker – plastic products manufacturing"
    },
    {
        "value": 26695,
        "NOCCode": "9535",
        "JobTitle": "panelling assembler, automotive doors – plastic products manufacturing"
    },
    {
        "value": 26696,
        "NOCCode": "9535",
        "JobTitle": "plastic and composite worker"
    },
    {
        "value": 26697,
        "NOCCode": "9535",
        "JobTitle": "plastic ankle piece moulder"
    },
    {
        "value": 26698,
        "NOCCode": "9535",
        "JobTitle": "plastic bottle trimmer"
    },
    {
        "value": 26699,
        "NOCCode": "9535",
        "JobTitle": "plastic box maker"
    },
    {
        "value": 26700,
        "NOCCode": "9535",
        "JobTitle": "plastic case assembler"
    },
    {
        "value": 26701,
        "NOCCode": "9535",
        "JobTitle": "plastic casket maker"
    },
    {
        "value": 26702,
        "NOCCode": "9535",
        "JobTitle": "plastic coating fabricator"
    },
    {
        "value": 26703,
        "NOCCode": "9535",
        "JobTitle": "plastic cutter"
    },
    {
        "value": 26704,
        "NOCCode": "9535",
        "JobTitle": "plastic material reeler and cutter"
    },
    {
        "value": 26705,
        "NOCCode": "9535",
        "JobTitle": "plastic packing fabricator"
    },
    {
        "value": 26706,
        "NOCCode": "9535",
        "JobTitle": "plastic panelling assembler, marine craft"
    },
    {
        "value": 26707,
        "NOCCode": "9535",
        "JobTitle": "plastic parts assembler"
    },
    {
        "value": 26708,
        "NOCCode": "9535",
        "JobTitle": "plastic piping fabricator"
    },
    {
        "value": 26709,
        "NOCCode": "9535",
        "JobTitle": "plastic products assembler"
    },
    {
        "value": 26710,
        "NOCCode": "9535",
        "JobTitle": "plastic products assembly repairer"
    },
    {
        "value": 26711,
        "NOCCode": "9535",
        "JobTitle": "plastic products buffer"
    },
    {
        "value": 26712,
        "NOCCode": "9535",
        "JobTitle": "plastic products fabricator"
    },
    {
        "value": 26713,
        "NOCCode": "9535",
        "JobTitle": "plastic products finisher"
    },
    {
        "value": 26714,
        "NOCCode": "9535",
        "JobTitle": "plastic products former"
    },
    {
        "value": 26715,
        "NOCCode": "9535",
        "JobTitle": "plastic products inspector"
    },
    {
        "value": 26716,
        "NOCCode": "9535",
        "JobTitle": "plastic products inspector and tester"
    },
    {
        "value": 26717,
        "NOCCode": "9535",
        "JobTitle": "plastic products laminator"
    },
    {
        "value": 26718,
        "NOCCode": "9535",
        "JobTitle": "plastic products manufacturing inspector"
    },
    {
        "value": 26719,
        "NOCCode": "9535",
        "JobTitle": "plastic products trimmer"
    },
    {
        "value": 26720,
        "NOCCode": "9535",
        "JobTitle": "plastic products vacuum-forming machine tender"
    },
    {
        "value": 26721,
        "NOCCode": "9535",
        "JobTitle": "plastic reproduction layup worker"
    },
    {
        "value": 26722,
        "NOCCode": "9535",
        "JobTitle": "plastic sheet finisher"
    },
    {
        "value": 26723,
        "NOCCode": "9535",
        "JobTitle": "plastic sign trimmer and assembler"
    },
    {
        "value": 26724,
        "NOCCode": "9535",
        "JobTitle": "plastic tank assembler"
    },
    {
        "value": 26725,
        "NOCCode": "9535",
        "JobTitle": "plastic template builder"
    },
    {
        "value": 26726,
        "NOCCode": "9535",
        "JobTitle": "plastic toy assembler"
    },
    {
        "value": 26727,
        "NOCCode": "9535",
        "JobTitle": "plastics assembler"
    },
    {
        "value": 26728,
        "NOCCode": "9535",
        "JobTitle": "plastics cementer"
    },
    {
        "value": 26729,
        "NOCCode": "9535",
        "JobTitle": "plastics cutter"
    },
    {
        "value": 26730,
        "NOCCode": "9535",
        "JobTitle": "plastics inspector"
    },
    {
        "value": 26731,
        "NOCCode": "9535",
        "JobTitle": "plastics laminator – plastic manufacturing"
    },
    {
        "value": 26732,
        "NOCCode": "9535",
        "JobTitle": "plastics trimmer"
    },
    {
        "value": 26733,
        "NOCCode": "9535",
        "JobTitle": "plate changer – plastic manufacturing"
    },
    {
        "value": 26734,
        "NOCCode": "9535",
        "JobTitle": "Plexiglas assembler"
    },
    {
        "value": 26735,
        "NOCCode": "9535",
        "JobTitle": "Plexiglas former"
    },
    {
        "value": 26736,
        "NOCCode": "9535",
        "JobTitle": "polishing machine operator – plastic manufacturing"
    },
    {
        "value": 26737,
        "NOCCode": "9535",
        "JobTitle": "presser, upholstery – plastic manufacturing"
    },
    {
        "value": 26738,
        "NOCCode": "9535",
        "JobTitle": "production repairer, foam cushions – plastic products manufacturing"
    },
    {
        "value": 26739,
        "NOCCode": "9535",
        "JobTitle": "quality control inspector – plastic products manufacturing"
    },
    {
        "value": 26740,
        "NOCCode": "9535",
        "JobTitle": "quality control inspector, linoleum"
    },
    {
        "value": 26741,
        "NOCCode": "9535",
        "JobTitle": "radome finisher – plastic manufacturing"
    },
    {
        "value": 26742,
        "NOCCode": "9535",
        "JobTitle": "reeler and cutter – plastic manufacturing"
    },
    {
        "value": 26743,
        "NOCCode": "9535",
        "JobTitle": "reeler and cutter, plastic material"
    },
    {
        "value": 26744,
        "NOCCode": "9535",
        "JobTitle": "reinforcer, foam cushions – plastic products manufacturing"
    },
    {
        "value": 26745,
        "NOCCode": "9535",
        "JobTitle": "repairer, rollers – plastic manufacturing"
    },
    {
        "value": 26746,
        "NOCCode": "9535",
        "JobTitle": "roller repairer – plastic manufacturing"
    },
    {
        "value": 26747,
        "NOCCode": "9535",
        "JobTitle": "roller repairman/woman – plastic manufacturing"
    },
    {
        "value": 26748,
        "NOCCode": "9535",
        "JobTitle": "safety goggle assembly repairer – plastic products manufacturing"
    },
    {
        "value": 26749,
        "NOCCode": "9535",
        "JobTitle": "sheet finisher, plastic"
    },
    {
        "value": 26750,
        "NOCCode": "9535",
        "JobTitle": "skylight assembler – plastic products manufacturing"
    },
    {
        "value": 26751,
        "NOCCode": "9535",
        "JobTitle": "stripper, moulds – plastic manufacturing"
    },
    {
        "value": 26752,
        "NOCCode": "9535",
        "JobTitle": "tender, dicer – plastic manufacturing"
    },
    {
        "value": 26753,
        "NOCCode": "9535",
        "JobTitle": "tender, dicing machine – plastic manufacturing"
    },
    {
        "value": 26754,
        "NOCCode": "9535",
        "JobTitle": "tender, plastic products vacuum-forming machine"
    },
    {
        "value": 26755,
        "NOCCode": "9535",
        "JobTitle": "trimmer and assembler, plastic signs"
    },
    {
        "value": 26756,
        "NOCCode": "9535",
        "JobTitle": "trimmer, moulded plastic products"
    },
    {
        "value": 26757,
        "NOCCode": "9535",
        "JobTitle": "trimmer, plastic bottles"
    },
    {
        "value": 26758,
        "NOCCode": "9535",
        "JobTitle": "trimmer, plastic products"
    },
    {
        "value": 26759,
        "NOCCode": "9535",
        "JobTitle": "trimmer-assembler, aircraft plastic parts"
    },
    {
        "value": 26760,
        "NOCCode": "9535",
        "JobTitle": "upholstery moulder – plastic manufacturing"
    },
    {
        "value": 26761,
        "NOCCode": "9535",
        "JobTitle": "upholstery presser – plastic manufacturing"
    },
    {
        "value": 26762,
        "NOCCode": "9535",
        "JobTitle": "vacuum-forming machine tender, plastic products"
    },
    {
        "value": 26763,
        "NOCCode": "9535",
        "JobTitle": "waxer, moulds – plastic manufacturing"
    },
    {
        "value": 26764,
        "NOCCode": "9535",
        "JobTitle": "zipper-forming machine tender – plastic products manufacturing"
    },
    {
        "value": 26765,
        "NOCCode": "9536",
        "JobTitle": "airbrush painter"
    },
    {
        "value": 26766,
        "NOCCode": "9536",
        "JobTitle": "aircraft painter"
    },
    {
        "value": 26767,
        "NOCCode": "9536",
        "JobTitle": "anodizer"
    },
    {
        "value": 26768,
        "NOCCode": "9536",
        "JobTitle": "assembly line painter, automobiles"
    },
    {
        "value": 26769,
        "NOCCode": "9536",
        "JobTitle": "assembly painter"
    },
    {
        "value": 26770,
        "NOCCode": "9536",
        "JobTitle": "automatic paint-sprayer operator"
    },
    {
        "value": 26771,
        "NOCCode": "9536",
        "JobTitle": "automobile assembly line painter"
    },
    {
        "value": 26772,
        "NOCCode": "9536",
        "JobTitle": "automobile assembly painter"
    },
    {
        "value": 26773,
        "NOCCode": "9536",
        "JobTitle": "automobile dipper-painter"
    },
    {
        "value": 26774,
        "NOCCode": "9536",
        "JobTitle": "automobile painter – motor vehicle manufacturing"
    },
    {
        "value": 26775,
        "NOCCode": "9536",
        "JobTitle": "aviation painter"
    },
    {
        "value": 26776,
        "NOCCode": "9536",
        "JobTitle": "bathtub refinisher"
    },
    {
        "value": 26777,
        "NOCCode": "9536",
        "JobTitle": "bathtub varnisher"
    },
    {
        "value": 26778,
        "NOCCode": "9536",
        "JobTitle": "bench lacquer sprayer"
    },
    {
        "value": 26779,
        "NOCCode": "9536",
        "JobTitle": "bench spray painter"
    },
    {
        "value": 26780,
        "NOCCode": "9536",
        "JobTitle": "block sander and glazier"
    },
    {
        "value": 26781,
        "NOCCode": "9536",
        "JobTitle": "boiler painter"
    },
    {
        "value": 26782,
        "NOCCode": "9536",
        "JobTitle": "bronze plater"
    },
    {
        "value": 26783,
        "NOCCode": "9536",
        "JobTitle": "brush painter, production"
    },
    {
        "value": 26784,
        "NOCCode": "9536",
        "JobTitle": "brush stainer – production"
    },
    {
        "value": 26785,
        "NOCCode": "9536",
        "JobTitle": "cadmium galvanizer"
    },
    {
        "value": 26786,
        "NOCCode": "9536",
        "JobTitle": "car painter – manufacturing"
    },
    {
        "value": 26787,
        "NOCCode": "9536",
        "JobTitle": "ceramic and enamel refinisher"
    },
    {
        "value": 26788,
        "NOCCode": "9536",
        "JobTitle": "ceramic enameler"
    },
    {
        "value": 26789,
        "NOCCode": "9536",
        "JobTitle": "ceramic painter"
    },
    {
        "value": 26790,
        "NOCCode": "9536",
        "JobTitle": "ceramic spray painter"
    },
    {
        "value": 26791,
        "NOCCode": "9536",
        "JobTitle": "ceramic sprayer"
    },
    {
        "value": 26792,
        "NOCCode": "9536",
        "JobTitle": "chromium plater"
    },
    {
        "value": 26793,
        "NOCCode": "9536",
        "JobTitle": "cleaner-coater, vehicles"
    },
    {
        "value": 26794,
        "NOCCode": "9536",
        "JobTitle": "coater, rustproof – motor vehicle manufacturing"
    },
    {
        "value": 26795,
        "NOCCode": "9536",
        "JobTitle": "coater-painter, pipes"
    },
    {
        "value": 26796,
        "NOCCode": "9536",
        "JobTitle": "coating equipment operator"
    },
    {
        "value": 26797,
        "NOCCode": "9536",
        "JobTitle": "coating equipment operator – metal plating"
    },
    {
        "value": 26798,
        "NOCCode": "9536",
        "JobTitle": "coating machine operator – industrial painting and coating"
    },
    {
        "value": 26799,
        "NOCCode": "9536",
        "JobTitle": "coating machine operator, production"
    },
    {
        "value": 26800,
        "NOCCode": "9536",
        "JobTitle": "coating machine tender – product manufacturing"
    },
    {
        "value": 26801,
        "NOCCode": "9536",
        "JobTitle": "coating machine tender, pencils"
    },
    {
        "value": 26802,
        "NOCCode": "9536",
        "JobTitle": "coating oven tender – manufacturing"
    },
    {
        "value": 26803,
        "NOCCode": "9536",
        "JobTitle": "coating tank dipper"
    },
    {
        "value": 26804,
        "NOCCode": "9536",
        "JobTitle": "continuous galvanizing line operator"
    },
    {
        "value": 26805,
        "NOCCode": "9536",
        "JobTitle": "copper coater – metal plating"
    },
    {
        "value": 26806,
        "NOCCode": "9536",
        "JobTitle": "copper plater"
    },
    {
        "value": 26807,
        "NOCCode": "9536",
        "JobTitle": "corrosion resistant metal sprayer"
    },
    {
        "value": 26808,
        "NOCCode": "9536",
        "JobTitle": "crester"
    },
    {
        "value": 26809,
        "NOCCode": "9536",
        "JobTitle": "cylinder painter – industrial"
    },
    {
        "value": 26810,
        "NOCCode": "9536",
        "JobTitle": "cylinder plater-grinder – metal processing"
    },
    {
        "value": 26811,
        "NOCCode": "9536",
        "JobTitle": "decal applier"
    },
    {
        "value": 26812,
        "NOCCode": "9536",
        "JobTitle": "dip tank attendant"
    },
    {
        "value": 26813,
        "NOCCode": "9536",
        "JobTitle": "dipper and coater – metal plating"
    },
    {
        "value": 26814,
        "NOCCode": "9536",
        "JobTitle": "dipper operator, paint and varnish"
    },
    {
        "value": 26815,
        "NOCCode": "9536",
        "JobTitle": "dipper, coating tank"
    },
    {
        "value": 26816,
        "NOCCode": "9536",
        "JobTitle": "dipper, coil springs"
    },
    {
        "value": 26817,
        "NOCCode": "9536",
        "JobTitle": "dipper, enamel"
    },
    {
        "value": 26818,
        "NOCCode": "9536",
        "JobTitle": "dipper-baker"
    },
    {
        "value": 26819,
        "NOCCode": "9536",
        "JobTitle": "dipping tank operator"
    },
    {
        "value": 26820,
        "NOCCode": "9536",
        "JobTitle": "dipping tank tender"
    },
    {
        "value": 26821,
        "NOCCode": "9536",
        "JobTitle": "drum painter"
    },
    {
        "value": 26822,
        "NOCCode": "9536",
        "JobTitle": "electroformer"
    },
    {
        "value": 26823,
        "NOCCode": "9536",
        "JobTitle": "electrogalvanizer"
    },
    {
        "value": 26824,
        "NOCCode": "9536",
        "JobTitle": "electrogalvanizing machine operator"
    },
    {
        "value": 26825,
        "NOCCode": "9536",
        "JobTitle": "electroless plater"
    },
    {
        "value": 26826,
        "NOCCode": "9536",
        "JobTitle": "electrolytic tank tender"
    },
    {
        "value": 26827,
        "NOCCode": "9536",
        "JobTitle": "electroplasty equipment operator"
    },
    {
        "value": 26828,
        "NOCCode": "9536",
        "JobTitle": "electroplater"
    },
    {
        "value": 26829,
        "NOCCode": "9536",
        "JobTitle": "electroplater operator"
    },
    {
        "value": 26830,
        "NOCCode": "9536",
        "JobTitle": "electroplater, metal"
    },
    {
        "value": 26831,
        "NOCCode": "9536",
        "JobTitle": "electroplater, production"
    },
    {
        "value": 26832,
        "NOCCode": "9536",
        "JobTitle": "electrostatic machine painter"
    },
    {
        "value": 26833,
        "NOCCode": "9536",
        "JobTitle": "electrostatic painter"
    },
    {
        "value": 26834,
        "NOCCode": "9536",
        "JobTitle": "electrostatic painting line tender"
    },
    {
        "value": 26835,
        "NOCCode": "9536",
        "JobTitle": "electrostatic painting machine operator"
    },
    {
        "value": 26836,
        "NOCCode": "9536",
        "JobTitle": "electrostatic painting machine set-up operator"
    },
    {
        "value": 26837,
        "NOCCode": "9536",
        "JobTitle": "Elpo tank attendant"
    },
    {
        "value": 26838,
        "NOCCode": "9536",
        "JobTitle": "enamel baker – manufacturing"
    },
    {
        "value": 26839,
        "NOCCode": "9536",
        "JobTitle": "enamel dipper"
    },
    {
        "value": 26840,
        "NOCCode": "9536",
        "JobTitle": "enamel painter"
    },
    {
        "value": 26841,
        "NOCCode": "9536",
        "JobTitle": "enameller"
    },
    {
        "value": 26842,
        "NOCCode": "9536",
        "JobTitle": "enameller – industrial painting and coating"
    },
    {
        "value": 26843,
        "NOCCode": "9536",
        "JobTitle": "enameller – ship and boat building"
    },
    {
        "value": 26844,
        "NOCCode": "9536",
        "JobTitle": "enameller, ceramic"
    },
    {
        "value": 26845,
        "NOCCode": "9536",
        "JobTitle": "enameller, electrical equipment"
    },
    {
        "value": 26846,
        "NOCCode": "9536",
        "JobTitle": "enameller, tin can dipping"
    },
    {
        "value": 26847,
        "NOCCode": "9536",
        "JobTitle": "enamelling machine tender"
    },
    {
        "value": 26848,
        "NOCCode": "9536",
        "JobTitle": "engine and generator support painter"
    },
    {
        "value": 26849,
        "NOCCode": "9536",
        "JobTitle": "filament coater"
    },
    {
        "value": 26850,
        "NOCCode": "9536",
        "JobTitle": "final colour production painter"
    },
    {
        "value": 26851,
        "NOCCode": "9536",
        "JobTitle": "finished enamel sprayman/woman"
    },
    {
        "value": 26852,
        "NOCCode": "9536",
        "JobTitle": "finished vehicle paint repairman/woman"
    },
    {
        "value": 26853,
        "NOCCode": "9536",
        "JobTitle": "finishing roller industrial painter"
    },
    {
        "value": 26854,
        "NOCCode": "9536",
        "JobTitle": "firearm burnisher – metal plating"
    },
    {
        "value": 26855,
        "NOCCode": "9536",
        "JobTitle": "firearm finisher – metal plating"
    },
    {
        "value": 26856,
        "NOCCode": "9536",
        "JobTitle": "galvanizer"
    },
    {
        "value": 26857,
        "NOCCode": "9536",
        "JobTitle": "galvanizing machine operator"
    },
    {
        "value": 26858,
        "NOCCode": "9536",
        "JobTitle": "gilder, metal-leaf"
    },
    {
        "value": 26859,
        "NOCCode": "9536",
        "JobTitle": "hand touch-up painter – production"
    },
    {
        "value": 26860,
        "NOCCode": "9536",
        "JobTitle": "hardboard spray-coating machine tender"
    },
    {
        "value": 26861,
        "NOCCode": "9536",
        "JobTitle": "hot-dip coater – metal plating"
    },
    {
        "value": 26862,
        "NOCCode": "9536",
        "JobTitle": "hot-dip galvanizer – metal plating"
    },
    {
        "value": 26863,
        "NOCCode": "9536",
        "JobTitle": "industrial coater"
    },
    {
        "value": 26864,
        "NOCCode": "9536",
        "JobTitle": "industrial paint baker"
    },
    {
        "value": 26865,
        "NOCCode": "9536",
        "JobTitle": "industrial paint dipper"
    },
    {
        "value": 26866,
        "NOCCode": "9536",
        "JobTitle": "industrial paint finisher"
    },
    {
        "value": 26867,
        "NOCCode": "9536",
        "JobTitle": "industrial paint mixer"
    },
    {
        "value": 26868,
        "NOCCode": "9536",
        "JobTitle": "industrial paint sprayer operator"
    },
    {
        "value": 26869,
        "NOCCode": "9536",
        "JobTitle": "industrial painter"
    },
    {
        "value": 26870,
        "NOCCode": "9536",
        "JobTitle": "industrial painter, finishing rollers"
    },
    {
        "value": 26871,
        "NOCCode": "9536",
        "JobTitle": "industrial products painter"
    },
    {
        "value": 26872,
        "NOCCode": "9536",
        "JobTitle": "industrial spray painter"
    },
    {
        "value": 26873,
        "NOCCode": "9536",
        "JobTitle": "inspector, paint spray"
    },
    {
        "value": 26874,
        "NOCCode": "9536",
        "JobTitle": "lacquer sprayer, bench"
    },
    {
        "value": 26875,
        "NOCCode": "9536",
        "JobTitle": "lacquer-sprayer"
    },
    {
        "value": 26876,
        "NOCCode": "9536",
        "JobTitle": "lacquer-sprayer, products"
    },
    {
        "value": 26877,
        "NOCCode": "9536",
        "JobTitle": "large surface spray painter"
    },
    {
        "value": 26878,
        "NOCCode": "9536",
        "JobTitle": "lead coater"
    },
    {
        "value": 26879,
        "NOCCode": "9536",
        "JobTitle": "lead hand, industrial painters"
    },
    {
        "value": 26880,
        "NOCCode": "9536",
        "JobTitle": "leather goods spray painter"
    },
    {
        "value": 26881,
        "NOCCode": "9536",
        "JobTitle": "leather goods sprayer"
    },
    {
        "value": 26882,
        "NOCCode": "9536",
        "JobTitle": "letterer, railway cars"
    },
    {
        "value": 26883,
        "NOCCode": "9536",
        "JobTitle": "letterer, signs"
    },
    {
        "value": 26884,
        "NOCCode": "9536",
        "JobTitle": "locomotive painter"
    },
    {
        "value": 26885,
        "NOCCode": "9536",
        "JobTitle": "machine enameller"
    },
    {
        "value": 26886,
        "NOCCode": "9536",
        "JobTitle": "machine operator – plating and metal spraying"
    },
    {
        "value": 26887,
        "NOCCode": "9536",
        "JobTitle": "manufacturing painter"
    },
    {
        "value": 26888,
        "NOCCode": "9536",
        "JobTitle": "metal bluer"
    },
    {
        "value": 26889,
        "NOCCode": "9536",
        "JobTitle": "metal coater"
    },
    {
        "value": 26890,
        "NOCCode": "9536",
        "JobTitle": "metal coater operator"
    },
    {
        "value": 26891,
        "NOCCode": "9536",
        "JobTitle": "metal coating-equipment operator"
    },
    {
        "value": 26892,
        "NOCCode": "9536",
        "JobTitle": "metal dipper"
    },
    {
        "value": 26893,
        "NOCCode": "9536",
        "JobTitle": "metal electroplater"
    },
    {
        "value": 26894,
        "NOCCode": "9536",
        "JobTitle": "metal electroplating inspector"
    },
    {
        "value": 26895,
        "NOCCode": "9536",
        "JobTitle": "metal plater-polisher"
    },
    {
        "value": 26896,
        "NOCCode": "9536",
        "JobTitle": "metal plating-equipment operator"
    },
    {
        "value": 26897,
        "NOCCode": "9536",
        "JobTitle": "metal product spray painter"
    },
    {
        "value": 26898,
        "NOCCode": "9536",
        "JobTitle": "metal spray operator"
    },
    {
        "value": 26899,
        "NOCCode": "9536",
        "JobTitle": "metal sprayer"
    },
    {
        "value": 26900,
        "NOCCode": "9536",
        "JobTitle": "metal-dipping equipment operator"
    },
    {
        "value": 26901,
        "NOCCode": "9536",
        "JobTitle": "metal-leaf gilder"
    },
    {
        "value": 26902,
        "NOCCode": "9536",
        "JobTitle": "metallizer"
    },
    {
        "value": 26903,
        "NOCCode": "9536",
        "JobTitle": "motor and generator subassemblies painter"
    },
    {
        "value": 26904,
        "NOCCode": "9536",
        "JobTitle": "motor vehicle painter – motor vehicle manufacturing"
    },
    {
        "value": 26905,
        "NOCCode": "9536",
        "JobTitle": "nickel plater"
    },
    {
        "value": 26906,
        "NOCCode": "9536",
        "JobTitle": "oven tender – painting and coating"
    },
    {
        "value": 26907,
        "NOCCode": "9536",
        "JobTitle": "paint and mix production and control operator"
    },
    {
        "value": 26908,
        "NOCCode": "9536",
        "JobTitle": "paint and varnish dipper operator"
    },
    {
        "value": 26909,
        "NOCCode": "9536",
        "JobTitle": "paint baking oven tender"
    },
    {
        "value": 26910,
        "NOCCode": "9536",
        "JobTitle": "paint control operator"
    },
    {
        "value": 26911,
        "NOCCode": "9536",
        "JobTitle": "paint dipper – flow coating"
    },
    {
        "value": 26912,
        "NOCCode": "9536",
        "JobTitle": "paint machine operator"
    },
    {
        "value": 26913,
        "NOCCode": "9536",
        "JobTitle": "paint production operator"
    },
    {
        "value": 26914,
        "NOCCode": "9536",
        "JobTitle": "paint repairman/woman, finished vehicles"
    },
    {
        "value": 26915,
        "NOCCode": "9536",
        "JobTitle": "paint room attendant"
    },
    {
        "value": 26916,
        "NOCCode": "9536",
        "JobTitle": "paint spray inspector"
    },
    {
        "value": 26917,
        "NOCCode": "9536",
        "JobTitle": "paint systems attendant"
    },
    {
        "value": 26918,
        "NOCCode": "9536",
        "JobTitle": "painter and letterer, railway cars"
    },
    {
        "value": 26919,
        "NOCCode": "9536",
        "JobTitle": "painter, airbrush"
    },
    {
        "value": 26920,
        "NOCCode": "9536",
        "JobTitle": "painter, aircraft"
    },
    {
        "value": 26921,
        "NOCCode": "9536",
        "JobTitle": "painter, assembly line"
    },
    {
        "value": 26922,
        "NOCCode": "9536",
        "JobTitle": "painter, automobile assembly"
    },
    {
        "value": 26923,
        "NOCCode": "9536",
        "JobTitle": "painter, automobiles – motor vehicle manufacturing"
    },
    {
        "value": 26924,
        "NOCCode": "9536",
        "JobTitle": "painter, boilers"
    },
    {
        "value": 26925,
        "NOCCode": "9536",
        "JobTitle": "painter, cylinders"
    },
    {
        "value": 26926,
        "NOCCode": "9536",
        "JobTitle": "painter, drum"
    },
    {
        "value": 26927,
        "NOCCode": "9536",
        "JobTitle": "painter, enamel"
    },
    {
        "value": 26928,
        "NOCCode": "9536",
        "JobTitle": "painter, industrial products"
    },
    {
        "value": 26929,
        "NOCCode": "9536",
        "JobTitle": "painter, locomotives"
    },
    {
        "value": 26930,
        "NOCCode": "9536",
        "JobTitle": "painter, motor and generator subassemblies"
    },
    {
        "value": 26931,
        "NOCCode": "9536",
        "JobTitle": "painter, products"
    },
    {
        "value": 26932,
        "NOCCode": "9536",
        "JobTitle": "painter, shipyard"
    },
    {
        "value": 26933,
        "NOCCode": "9536",
        "JobTitle": "painter, tumbling barrel"
    },
    {
        "value": 26934,
        "NOCCode": "9536",
        "JobTitle": "painter-finisher, production"
    },
    {
        "value": 26935,
        "NOCCode": "9536",
        "JobTitle": "painting machine attendant"
    },
    {
        "value": 26936,
        "NOCCode": "9536",
        "JobTitle": "painting machine operator"
    },
    {
        "value": 26937,
        "NOCCode": "9536",
        "JobTitle": "painting machine tender"
    },
    {
        "value": 26938,
        "NOCCode": "9536",
        "JobTitle": "paint-sprayer operator"
    },
    {
        "value": 26939,
        "NOCCode": "9536",
        "JobTitle": "particleboard spraying machine tender"
    },
    {
        "value": 26940,
        "NOCCode": "9536",
        "JobTitle": "pencil-coating machine tender"
    },
    {
        "value": 26941,
        "NOCCode": "9536",
        "JobTitle": "pencil-tip-banding machine tender"
    },
    {
        "value": 26942,
        "NOCCode": "9536",
        "JobTitle": "pipe coater-painter"
    },
    {
        "value": 26943,
        "NOCCode": "9536",
        "JobTitle": "pipe spray painter"
    },
    {
        "value": 26944,
        "NOCCode": "9536",
        "JobTitle": "plating tank operator"
    },
    {
        "value": 26945,
        "NOCCode": "9536",
        "JobTitle": "prep line attendant – bulk galvanizing"
    },
    {
        "value": 26946,
        "NOCCode": "9536",
        "JobTitle": "primer sprayer"
    },
    {
        "value": 26947,
        "NOCCode": "9536",
        "JobTitle": "print applier – production"
    },
    {
        "value": 26948,
        "NOCCode": "9536",
        "JobTitle": "product lacquer-sprayer"
    },
    {
        "value": 26949,
        "NOCCode": "9536",
        "JobTitle": "product painter"
    },
    {
        "value": 26950,
        "NOCCode": "9536",
        "JobTitle": "product spray painter"
    },
    {
        "value": 26951,
        "NOCCode": "9536",
        "JobTitle": "product stainer"
    },
    {
        "value": 26952,
        "NOCCode": "9536",
        "JobTitle": "product surface preparer and prefinisher"
    },
    {
        "value": 26953,
        "NOCCode": "9536",
        "JobTitle": "production and control operator, paint and mix"
    },
    {
        "value": 26954,
        "NOCCode": "9536",
        "JobTitle": "production brush painter"
    },
    {
        "value": 26955,
        "NOCCode": "9536",
        "JobTitle": "production coating machine operator"
    },
    {
        "value": 26956,
        "NOCCode": "9536",
        "JobTitle": "production electroplater"
    },
    {
        "value": 26957,
        "NOCCode": "9536",
        "JobTitle": "production operator, paint"
    },
    {
        "value": 26958,
        "NOCCode": "9536",
        "JobTitle": "production painter"
    },
    {
        "value": 26959,
        "NOCCode": "9536",
        "JobTitle": "production painter, final colours"
    },
    {
        "value": 26960,
        "NOCCode": "9536",
        "JobTitle": "production painter-finisher"
    },
    {
        "value": 26961,
        "NOCCode": "9536",
        "JobTitle": "production touch-up painter"
    },
    {
        "value": 26962,
        "NOCCode": "9536",
        "JobTitle": "railway car letterer"
    },
    {
        "value": 26963,
        "NOCCode": "9536",
        "JobTitle": "railway car stenciller"
    },
    {
        "value": 26964,
        "NOCCode": "9536",
        "JobTitle": "railway car varnisher"
    },
    {
        "value": 26965,
        "NOCCode": "9536",
        "JobTitle": "refinisher, bathtubs"
    },
    {
        "value": 26966,
        "NOCCode": "9536",
        "JobTitle": "refinisher, ceramic and enamel"
    },
    {
        "value": 26967,
        "NOCCode": "9536",
        "JobTitle": "refinisher, stoves"
    },
    {
        "value": 26968,
        "NOCCode": "9536",
        "JobTitle": "robotic paint sprayer attendant"
    },
    {
        "value": 26969,
        "NOCCode": "9536",
        "JobTitle": "robotic spray paint system attendant"
    },
    {
        "value": 26970,
        "NOCCode": "9536",
        "JobTitle": "rough benchwork spray painter"
    },
    {
        "value": 26971,
        "NOCCode": "9536",
        "JobTitle": "rough spray painter – manufacturing"
    },
    {
        "value": 26972,
        "NOCCode": "9536",
        "JobTitle": "rustproof coater – motor vehicle manufacturing"
    },
    {
        "value": 26973,
        "NOCCode": "9536",
        "JobTitle": "rustproof sprayer – motor vehicle manufacturing"
    },
    {
        "value": 26974,
        "NOCCode": "9536",
        "JobTitle": "rustproofer, motor vehicle"
    },
    {
        "value": 26975,
        "NOCCode": "9536",
        "JobTitle": "set-up operator, electrostatic painting machine"
    },
    {
        "value": 26976,
        "NOCCode": "9536",
        "JobTitle": "shellacker"
    },
    {
        "value": 26977,
        "NOCCode": "9536",
        "JobTitle": "sherardizer"
    },
    {
        "value": 26978,
        "NOCCode": "9536",
        "JobTitle": "shipyard painter"
    },
    {
        "value": 26979,
        "NOCCode": "9536",
        "JobTitle": "sign letterer"
    },
    {
        "value": 26980,
        "NOCCode": "9536",
        "JobTitle": "sign panel primer"
    },
    {
        "value": 26981,
        "NOCCode": "9536",
        "JobTitle": "sign spray painter"
    },
    {
        "value": 26982,
        "NOCCode": "9536",
        "JobTitle": "silver plater"
    },
    {
        "value": 26983,
        "NOCCode": "9536",
        "JobTitle": "silver spray operator"
    },
    {
        "value": 26984,
        "NOCCode": "9536",
        "JobTitle": "smoking-pipe stainer"
    },
    {
        "value": 26985,
        "NOCCode": "9536",
        "JobTitle": "spray paint machine operator"
    },
    {
        "value": 26986,
        "NOCCode": "9536",
        "JobTitle": "spray paint machine tender"
    },
    {
        "value": 26987,
        "NOCCode": "9536",
        "JobTitle": "spray paint operator"
    },
    {
        "value": 26988,
        "NOCCode": "9536",
        "JobTitle": "spray painter"
    },
    {
        "value": 26989,
        "NOCCode": "9536",
        "JobTitle": "spray painter – motor vehicle manufacturing"
    },
    {
        "value": 26990,
        "NOCCode": "9536",
        "JobTitle": "spray painter – production"
    },
    {
        "value": 26991,
        "NOCCode": "9536",
        "JobTitle": "spray painter, bench"
    },
    {
        "value": 26992,
        "NOCCode": "9536",
        "JobTitle": "spray painter, large surfaces"
    },
    {
        "value": 26993,
        "NOCCode": "9536",
        "JobTitle": "spray painter, metal products"
    },
    {
        "value": 26994,
        "NOCCode": "9536",
        "JobTitle": "spray painter, pipes"
    },
    {
        "value": 26995,
        "NOCCode": "9536",
        "JobTitle": "spray painter, products"
    },
    {
        "value": 26996,
        "NOCCode": "9536",
        "JobTitle": "spray painter, signs"
    },
    {
        "value": 26997,
        "NOCCode": "9536",
        "JobTitle": "spray stainer – production"
    },
    {
        "value": 26998,
        "NOCCode": "9536",
        "JobTitle": "spray-coating machine tender, hardboard"
    },
    {
        "value": 26999,
        "NOCCode": "9536",
        "JobTitle": "sprayer, ceramic"
    },
    {
        "value": 27000,
        "NOCCode": "9536",
        "JobTitle": "sprayer, leather goods"
    },
    {
        "value": 27001,
        "NOCCode": "9536",
        "JobTitle": "sprayman/woman, finished enamel"
    },
    {
        "value": 27002,
        "NOCCode": "9536",
        "JobTitle": "stainer, products"
    },
    {
        "value": 27003,
        "NOCCode": "9536",
        "JobTitle": "stainer, smoking-pipe"
    },
    {
        "value": 27004,
        "NOCCode": "9536",
        "JobTitle": "stenciller, railway cars"
    },
    {
        "value": 27005,
        "NOCCode": "9536",
        "JobTitle": "stove refinisher"
    },
    {
        "value": 27006,
        "NOCCode": "9536",
        "JobTitle": "stove varnisher"
    },
    {
        "value": 27007,
        "NOCCode": "9536",
        "JobTitle": "temperer – plating and metal spraying"
    },
    {
        "value": 27008,
        "NOCCode": "9536",
        "JobTitle": "thermospray operator"
    },
    {
        "value": 27009,
        "NOCCode": "9536",
        "JobTitle": "tin can dipping enameller"
    },
    {
        "value": 27010,
        "NOCCode": "9536",
        "JobTitle": "tin plater"
    },
    {
        "value": 27011,
        "NOCCode": "9536",
        "JobTitle": "tinned sheet coating machine operator"
    },
    {
        "value": 27012,
        "NOCCode": "9536",
        "JobTitle": "tinware dipper and enameller"
    },
    {
        "value": 27013,
        "NOCCode": "9536",
        "JobTitle": "tinware dipping enameller"
    },
    {
        "value": 27014,
        "NOCCode": "9536",
        "JobTitle": "touch-up painter, production"
    },
    {
        "value": 27015,
        "NOCCode": "9536",
        "JobTitle": "tumbling barrel painter"
    },
    {
        "value": 27016,
        "NOCCode": "9536",
        "JobTitle": "tumbling barrel tender"
    },
    {
        "value": 27017,
        "NOCCode": "9536",
        "JobTitle": "undercoater – motor vehicle manufacturing"
    },
    {
        "value": 27018,
        "NOCCode": "9536",
        "JobTitle": "vacuum-metallizer tender"
    },
    {
        "value": 27019,
        "NOCCode": "9536",
        "JobTitle": "varnisher, railway cars"
    },
    {
        "value": 27020,
        "NOCCode": "9536",
        "JobTitle": "vehicle cleaner-coater"
    },
    {
        "value": 27021,
        "NOCCode": "9536",
        "JobTitle": "wire coater"
    },
    {
        "value": 27022,
        "NOCCode": "9536",
        "JobTitle": "wire tinner"
    },
    {
        "value": 27023,
        "NOCCode": "9536",
        "JobTitle": "wire-coating equipment operator"
    },
    {
        "value": 27024,
        "NOCCode": "9536",
        "JobTitle": "wire-paint-coating machine tender"
    },
    {
        "value": 27025,
        "NOCCode": "9536",
        "JobTitle": "zinc galvanizer"
    },
    {
        "value": 27026,
        "NOCCode": "9537",
        "JobTitle": "abrasive belt cutter"
    },
    {
        "value": 27027,
        "NOCCode": "9537",
        "JobTitle": "abrasive wheel binder"
    },
    {
        "value": 27028,
        "NOCCode": "9537",
        "JobTitle": "acoustic tile surfacer"
    },
    {
        "value": 27029,
        "NOCCode": "9537",
        "JobTitle": "adhesive bandage machine tender"
    },
    {
        "value": 27030,
        "NOCCode": "9537",
        "JobTitle": "adjuster, watch balance wheel"
    },
    {
        "value": 27031,
        "NOCCode": "9537",
        "JobTitle": "adjuster, watch escapement put-in-beat"
    },
    {
        "value": 27032,
        "NOCCode": "9537",
        "JobTitle": "air filter assembler"
    },
    {
        "value": 27033,
        "NOCCode": "9537",
        "JobTitle": "aircraft heat shield maker"
    },
    {
        "value": 27034,
        "NOCCode": "9537",
        "JobTitle": "aligner, archery bow limbs"
    },
    {
        "value": 27035,
        "NOCCode": "9537",
        "JobTitle": "aluminum awning assembler"
    },
    {
        "value": 27036,
        "NOCCode": "9537",
        "JobTitle": "ammunition assembler"
    },
    {
        "value": 27037,
        "NOCCode": "9537",
        "JobTitle": "ammunition inspector"
    },
    {
        "value": 27038,
        "NOCCode": "9537",
        "JobTitle": "ammunition loading inspector"
    },
    {
        "value": 27039,
        "NOCCode": "9537",
        "JobTitle": "ammunition maker"
    },
    {
        "value": 27040,
        "NOCCode": "9537",
        "JobTitle": "ammunition manufacturing inspector"
    },
    {
        "value": 27041,
        "NOCCode": "9537",
        "JobTitle": "ammunition salvage inspector"
    },
    {
        "value": 27042,
        "NOCCode": "9537",
        "JobTitle": "ammunition worker"
    },
    {
        "value": 27043,
        "NOCCode": "9537",
        "JobTitle": "annealer, jewellery"
    },
    {
        "value": 27044,
        "NOCCode": "9537",
        "JobTitle": "antenna assembler"
    },
    {
        "value": 27045,
        "NOCCode": "9537",
        "JobTitle": "anvil-seating press tender"
    },
    {
        "value": 27046,
        "NOCCode": "9537",
        "JobTitle": "archery bow limb aligner"
    },
    {
        "value": 27047,
        "NOCCode": "9537",
        "JobTitle": "archery bow maker"
    },
    {
        "value": 27048,
        "NOCCode": "9537",
        "JobTitle": "archery bow string maker"
    },
    {
        "value": 27049,
        "NOCCode": "9537",
        "JobTitle": "arrow assembler"
    },
    {
        "value": 27050,
        "NOCCode": "9537",
        "JobTitle": "artificial pearl maker"
    },
    {
        "value": 27051,
        "NOCCode": "9537",
        "JobTitle": "artificial turf maker"
    },
    {
        "value": 27052,
        "NOCCode": "9537",
        "JobTitle": "asphalt roofing slitter operator"
    },
    {
        "value": 27053,
        "NOCCode": "9537",
        "JobTitle": "assembler and finisher, cases"
    },
    {
        "value": 27054,
        "NOCCode": "9537",
        "JobTitle": "assembler, advertising buttons"
    },
    {
        "value": 27055,
        "NOCCode": "9537",
        "JobTitle": "assembler, air filters"
    },
    {
        "value": 27056,
        "NOCCode": "9537",
        "JobTitle": "assembler, aluminum awnings"
    },
    {
        "value": 27057,
        "NOCCode": "9537",
        "JobTitle": "assembler, aluminum doors and windows"
    },
    {
        "value": 27058,
        "NOCCode": "9537",
        "JobTitle": "assembler, ammunition"
    },
    {
        "value": 27059,
        "NOCCode": "9537",
        "JobTitle": "assembler, antennas"
    },
    {
        "value": 27060,
        "NOCCode": "9537",
        "JobTitle": "assembler, arrow points"
    },
    {
        "value": 27061,
        "NOCCode": "9537",
        "JobTitle": "assembler, arrows"
    },
    {
        "value": 27062,
        "NOCCode": "9537",
        "JobTitle": "assembler, atomic fuel bundles"
    },
    {
        "value": 27063,
        "NOCCode": "9537",
        "JobTitle": "assembler, automated valves"
    },
    {
        "value": 27064,
        "NOCCode": "9537",
        "JobTitle": "assembler, awning frames"
    },
    {
        "value": 27065,
        "NOCCode": "9537",
        "JobTitle": "assembler, baby carriages"
    },
    {
        "value": 27066,
        "NOCCode": "9537",
        "JobTitle": "assembler, barometers"
    },
    {
        "value": 27067,
        "NOCCode": "9537",
        "JobTitle": "assembler, bearings"
    },
    {
        "value": 27068,
        "NOCCode": "9537",
        "JobTitle": "assembler, bicycles"
    },
    {
        "value": 27069,
        "NOCCode": "9537",
        "JobTitle": "assembler, blasting caps"
    },
    {
        "value": 27070,
        "NOCCode": "9537",
        "JobTitle": "assembler, bracelets"
    },
    {
        "value": 27071,
        "NOCCode": "9537",
        "JobTitle": "assembler, brooms"
    },
    {
        "value": 27072,
        "NOCCode": "9537",
        "JobTitle": "assembler, brushes and mops"
    },
    {
        "value": 27073,
        "NOCCode": "9537",
        "JobTitle": "assembler, cameras"
    },
    {
        "value": 27074,
        "NOCCode": "9537",
        "JobTitle": "assembler, canvas goods"
    },
    {
        "value": 27075,
        "NOCCode": "9537",
        "JobTitle": "assembler, car seats"
    },
    {
        "value": 27076,
        "NOCCode": "9537",
        "JobTitle": "assembler, cartridges"
    },
    {
        "value": 27077,
        "NOCCode": "9537",
        "JobTitle": "assembler, clocks"
    },
    {
        "value": 27078,
        "NOCCode": "9537",
        "JobTitle": "assembler, clocks and watches"
    },
    {
        "value": 27079,
        "NOCCode": "9537",
        "JobTitle": "assembler, compasses"
    },
    {
        "value": 27080,
        "NOCCode": "9537",
        "JobTitle": "assembler, compressed air and gas regulators"
    },
    {
        "value": 27081,
        "NOCCode": "9537",
        "JobTitle": "assembler, corncob pipes"
    },
    {
        "value": 27082,
        "NOCCode": "9537",
        "JobTitle": "assembler, curtain rods"
    },
    {
        "value": 27083,
        "NOCCode": "9537",
        "JobTitle": "assembler, dental instruments"
    },
    {
        "value": 27084,
        "NOCCode": "9537",
        "JobTitle": "assembler, detonators"
    },
    {
        "value": 27085,
        "NOCCode": "9537",
        "JobTitle": "assembler, drawing instruments"
    },
    {
        "value": 27086,
        "NOCCode": "9537",
        "JobTitle": "assembler, dust brushes"
    },
    {
        "value": 27087,
        "NOCCode": "9537",
        "JobTitle": "assembler, dust mop"
    },
    {
        "value": 27088,
        "NOCCode": "9537",
        "JobTitle": "assembler, earmuffs"
    },
    {
        "value": 27089,
        "NOCCode": "9537",
        "JobTitle": "assembler, eyeglass frames"
    },
    {
        "value": 27090,
        "NOCCode": "9537",
        "JobTitle": "assembler, fabric hats"
    },
    {
        "value": 27091,
        "NOCCode": "9537",
        "JobTitle": "assembler, filters"
    },
    {
        "value": 27092,
        "NOCCode": "9537",
        "JobTitle": "assembler, firefighters' hats"
    },
    {
        "value": 27093,
        "NOCCode": "9537",
        "JobTitle": "assembler, fireworks"
    },
    {
        "value": 27094,
        "NOCCode": "9537",
        "JobTitle": "assembler, fishing reels"
    },
    {
        "value": 27095,
        "NOCCode": "9537",
        "JobTitle": "assembler, fishing rods"
    },
    {
        "value": 27096,
        "NOCCode": "9537",
        "JobTitle": "assembler, flares"
    },
    {
        "value": 27097,
        "NOCCode": "9537",
        "JobTitle": "assembler, fuel filters"
    },
    {
        "value": 27098,
        "NOCCode": "9537",
        "JobTitle": "assembler, gas meters"
    },
    {
        "value": 27099,
        "NOCCode": "9537",
        "JobTitle": "assembler, golf clubs"
    },
    {
        "value": 27100,
        "NOCCode": "9537",
        "JobTitle": "assembler, hard hats"
    },
    {
        "value": 27101,
        "NOCCode": "9537",
        "JobTitle": "assembler, hardware"
    },
    {
        "value": 27102,
        "NOCCode": "9537",
        "JobTitle": "assembler, heat sensors"
    },
    {
        "value": 27103,
        "NOCCode": "9537",
        "JobTitle": "assembler, hydrometers"
    },
    {
        "value": 27104,
        "NOCCode": "9537",
        "JobTitle": "assembler, igniters"
    },
    {
        "value": 27105,
        "NOCCode": "9537",
        "JobTitle": "assembler, jewellery"
    },
    {
        "value": 27106,
        "NOCCode": "9537",
        "JobTitle": "assembler, jewellery cases"
    },
    {
        "value": 27107,
        "NOCCode": "9537",
        "JobTitle": "assembler, jewellery parts"
    },
    {
        "value": 27108,
        "NOCCode": "9537",
        "JobTitle": "assembler, knives"
    },
    {
        "value": 27109,
        "NOCCode": "9537",
        "JobTitle": "assembler, lampshades"
    },
    {
        "value": 27110,
        "NOCCode": "9537",
        "JobTitle": "assembler, leather products"
    },
    {
        "value": 27111,
        "NOCCode": "9537",
        "JobTitle": "assembler, lint roller brushes"
    },
    {
        "value": 27112,
        "NOCCode": "9537",
        "JobTitle": "assembler, locks"
    },
    {
        "value": 27113,
        "NOCCode": "9537",
        "JobTitle": "assembler, mannequins"
    },
    {
        "value": 27114,
        "NOCCode": "9537",
        "JobTitle": "assembler, metal door frames"
    },
    {
        "value": 27115,
        "NOCCode": "9537",
        "JobTitle": "assembler, metal products"
    },
    {
        "value": 27116,
        "NOCCode": "9537",
        "JobTitle": "assembler, meters"
    },
    {
        "value": 27117,
        "NOCCode": "9537",
        "JobTitle": "assembler, mirrors"
    },
    {
        "value": 27118,
        "NOCCode": "9537",
        "JobTitle": "assembler, mops"
    },
    {
        "value": 27119,
        "NOCCode": "9537",
        "JobTitle": "assembler, multifocal lenses"
    },
    {
        "value": 27120,
        "NOCCode": "9537",
        "JobTitle": "assembler, munitions"
    },
    {
        "value": 27121,
        "NOCCode": "9537",
        "JobTitle": "assembler, necklaces"
    },
    {
        "value": 27122,
        "NOCCode": "9537",
        "JobTitle": "assembler, oil filters"
    },
    {
        "value": 27123,
        "NOCCode": "9537",
        "JobTitle": "assembler, optical instruments"
    },
    {
        "value": 27124,
        "NOCCode": "9537",
        "JobTitle": "assembler, pens and pencils"
    },
    {
        "value": 27125,
        "NOCCode": "9537",
        "JobTitle": "assembler, photographic equipment"
    },
    {
        "value": 27126,
        "NOCCode": "9537",
        "JobTitle": "assembler, piano backs"
    },
    {
        "value": 27127,
        "NOCCode": "9537",
        "JobTitle": "assembler, piano cases"
    },
    {
        "value": 27128,
        "NOCCode": "9537",
        "JobTitle": "assembler, pianos"
    },
    {
        "value": 27129,
        "NOCCode": "9537",
        "JobTitle": "assembler, projectors"
    },
    {
        "value": 27130,
        "NOCCode": "9537",
        "JobTitle": "assembler, protective earmuffs"
    },
    {
        "value": 27131,
        "NOCCode": "9537",
        "JobTitle": "assembler, pyrotechnics"
    },
    {
        "value": 27132,
        "NOCCode": "9537",
        "JobTitle": "assembler, scales"
    },
    {
        "value": 27133,
        "NOCCode": "9537",
        "JobTitle": "assembler, scrubber brushes"
    },
    {
        "value": 27134,
        "NOCCode": "9537",
        "JobTitle": "assembler, shells – explosives"
    },
    {
        "value": 27135,
        "NOCCode": "9537",
        "JobTitle": "assembler, shovel handles"
    },
    {
        "value": 27136,
        "NOCCode": "9537",
        "JobTitle": "assembler, shower doors and panels"
    },
    {
        "value": 27137,
        "NOCCode": "9537",
        "JobTitle": "assembler, solenoid valves"
    },
    {
        "value": 27138,
        "NOCCode": "9537",
        "JobTitle": "assembler, spoked wheels"
    },
    {
        "value": 27139,
        "NOCCode": "9537",
        "JobTitle": "assembler, sponge mops"
    },
    {
        "value": 27140,
        "NOCCode": "9537",
        "JobTitle": "assembler, sports headgear"
    },
    {
        "value": 27141,
        "NOCCode": "9537",
        "JobTitle": "assembler, sports helmets"
    },
    {
        "value": 27142,
        "NOCCode": "9537",
        "JobTitle": "assembler, Thermos bottles"
    },
    {
        "value": 27143,
        "NOCCode": "9537",
        "JobTitle": "assembler, toys"
    },
    {
        "value": 27144,
        "NOCCode": "9537",
        "JobTitle": "assembler, trophies"
    },
    {
        "value": 27145,
        "NOCCode": "9537",
        "JobTitle": "assembler, twisted-wire brushes"
    },
    {
        "value": 27146,
        "NOCCode": "9537",
        "JobTitle": "assembler, vacuum bottles"
    },
    {
        "value": 27147,
        "NOCCode": "9537",
        "JobTitle": "assembler, Venetian blinds"
    },
    {
        "value": 27148,
        "NOCCode": "9537",
        "JobTitle": "assembler, watches"
    },
    {
        "value": 27149,
        "NOCCode": "9537",
        "JobTitle": "assembler, whisks"
    },
    {
        "value": 27150,
        "NOCCode": "9537",
        "JobTitle": "assembler, windows and screens"
    },
    {
        "value": 27151,
        "NOCCode": "9537",
        "JobTitle": "assembler, zipper chains"
    },
    {
        "value": 27152,
        "NOCCode": "9537",
        "JobTitle": "assembler, zippers"
    },
    {
        "value": 27153,
        "NOCCode": "9537",
        "JobTitle": "assembly adjuster, clocks"
    },
    {
        "value": 27154,
        "NOCCode": "9537",
        "JobTitle": "assembly press operator"
    },
    {
        "value": 27155,
        "NOCCode": "9537",
        "JobTitle": "assembly press set-up operator"
    },
    {
        "value": 27156,
        "NOCCode": "9537",
        "JobTitle": "assembly repairer, dolls"
    },
    {
        "value": 27157,
        "NOCCode": "9537",
        "JobTitle": "atomic fuel bundle assembler"
    },
    {
        "value": 27158,
        "NOCCode": "9537",
        "JobTitle": "attacher, heels"
    },
    {
        "value": 27159,
        "NOCCode": "9537",
        "JobTitle": "automated line assembler, clock and timer movements"
    },
    {
        "value": 27160,
        "NOCCode": "9537",
        "JobTitle": "automated sphere-polishing machine operator"
    },
    {
        "value": 27161,
        "NOCCode": "9537",
        "JobTitle": "automatic lathe operator – clock"
    },
    {
        "value": 27162,
        "NOCCode": "9537",
        "JobTitle": "awning frame assembler"
    },
    {
        "value": 27163,
        "NOCCode": "9537",
        "JobTitle": "awning maker, aluminum"
    },
    {
        "value": 27164,
        "NOCCode": "9537",
        "JobTitle": "awning maker, canvas"
    },
    {
        "value": 27165,
        "NOCCode": "9537",
        "JobTitle": "baby carriage assembler"
    },
    {
        "value": 27166,
        "NOCCode": "9537",
        "JobTitle": "bag maker, leather"
    },
    {
        "value": 27167,
        "NOCCode": "9537",
        "JobTitle": "ball bearing inspector"
    },
    {
        "value": 27168,
        "NOCCode": "9537",
        "JobTitle": "ball bearing tester"
    },
    {
        "value": 27169,
        "NOCCode": "9537",
        "JobTitle": "ball maker, billiards"
    },
    {
        "value": 27170,
        "NOCCode": "9537",
        "JobTitle": "barometer assembler"
    },
    {
        "value": 27171,
        "NOCCode": "9537",
        "JobTitle": "baseball centre winder"
    },
    {
        "value": 27172,
        "NOCCode": "9537",
        "JobTitle": "baseball maker"
    },
    {
        "value": 27173,
        "NOCCode": "9537",
        "JobTitle": "basketball coverer"
    },
    {
        "value": 27174,
        "NOCCode": "9537",
        "JobTitle": "bead stringer"
    },
    {
        "value": 27175,
        "NOCCode": "9537",
        "JobTitle": "bearing assembler"
    },
    {
        "value": 27176,
        "NOCCode": "9537",
        "JobTitle": "bearing inspector"
    },
    {
        "value": 27177,
        "NOCCode": "9537",
        "JobTitle": "belt-maker operator"
    },
    {
        "value": 27178,
        "NOCCode": "9537",
        "JobTitle": "bench hand – jewellery manufacturing"
    },
    {
        "value": 27179,
        "NOCCode": "9537",
        "JobTitle": "bench worker, pianos and organs"
    },
    {
        "value": 27180,
        "NOCCode": "9537",
        "JobTitle": "beveller, hand lenses – non-prescription"
    },
    {
        "value": 27181,
        "NOCCode": "9537",
        "JobTitle": "bicycle assembler"
    },
    {
        "value": 27182,
        "NOCCode": "9537",
        "JobTitle": "bicycle inspector"
    },
    {
        "value": 27183,
        "NOCCode": "9537",
        "JobTitle": "billiard ball maker"
    },
    {
        "value": 27184,
        "NOCCode": "9537",
        "JobTitle": "billiard cloth inspector"
    },
    {
        "value": 27185,
        "NOCCode": "9537",
        "JobTitle": "billiard table cushion mounter"
    },
    {
        "value": 27186,
        "NOCCode": "9537",
        "JobTitle": "blackboard maker"
    },
    {
        "value": 27187,
        "NOCCode": "9537",
        "JobTitle": "blasting cap assembler"
    },
    {
        "value": 27188,
        "NOCCode": "9537",
        "JobTitle": "bonder operator, luggage shell"
    },
    {
        "value": 27189,
        "NOCCode": "9537",
        "JobTitle": "bottle assembler, Thermos"
    },
    {
        "value": 27190,
        "NOCCode": "9537",
        "JobTitle": "bottle cap seal machine tender"
    },
    {
        "value": 27191,
        "NOCCode": "9537",
        "JobTitle": "box blank machine operator"
    },
    {
        "value": 27192,
        "NOCCode": "9537",
        "JobTitle": "box-end-stapling machine tender"
    },
    {
        "value": 27193,
        "NOCCode": "9537",
        "JobTitle": "box-sealing machine operator"
    },
    {
        "value": 27194,
        "NOCCode": "9537",
        "JobTitle": "bracelet assembler"
    },
    {
        "value": 27195,
        "NOCCode": "9537",
        "JobTitle": "breaster, heels – footwear manufacturing"
    },
    {
        "value": 27196,
        "NOCCode": "9537",
        "JobTitle": "broom assembler"
    },
    {
        "value": 27197,
        "NOCCode": "9537",
        "JobTitle": "broom maker"
    },
    {
        "value": 27198,
        "NOCCode": "9537",
        "JobTitle": "broom maker, hand"
    },
    {
        "value": 27199,
        "NOCCode": "9537",
        "JobTitle": "broom stitcher"
    },
    {
        "value": 27200,
        "NOCCode": "9537",
        "JobTitle": "broomcorn dyer"
    },
    {
        "value": 27201,
        "NOCCode": "9537",
        "JobTitle": "broom-stitching machine operator"
    },
    {
        "value": 27202,
        "NOCCode": "9537",
        "JobTitle": "brush assembler, twisted wire"
    },
    {
        "value": 27203,
        "NOCCode": "9537",
        "JobTitle": "brush finisher"
    },
    {
        "value": 27204,
        "NOCCode": "9537",
        "JobTitle": "brush maker operator"
    },
    {
        "value": 27205,
        "NOCCode": "9537",
        "JobTitle": "brush maker, hand"
    },
    {
        "value": 27206,
        "NOCCode": "9537",
        "JobTitle": "brush maker, twisted wires"
    },
    {
        "value": 27207,
        "NOCCode": "9537",
        "JobTitle": "brush making machine operator"
    },
    {
        "value": 27208,
        "NOCCode": "9537",
        "JobTitle": "brush-making machine operator, rotary"
    },
    {
        "value": 27209,
        "NOCCode": "9537",
        "JobTitle": "buffing machine set-up operator, flatware"
    },
    {
        "value": 27210,
        "NOCCode": "9537",
        "JobTitle": "builder, conveyor wire belts"
    },
    {
        "value": 27211,
        "NOCCode": "9537",
        "JobTitle": "bullet and cartridge case inspector"
    },
    {
        "value": 27212,
        "NOCCode": "9537",
        "JobTitle": "bullet and cartridge inspector"
    },
    {
        "value": 27213,
        "NOCCode": "9537",
        "JobTitle": "bulletin board fabricator"
    },
    {
        "value": 27214,
        "NOCCode": "9537",
        "JobTitle": "bulletin board maker"
    },
    {
        "value": 27215,
        "NOCCode": "9537",
        "JobTitle": "burner, enamel – jewellery manufacturing"
    },
    {
        "value": 27216,
        "NOCCode": "9537",
        "JobTitle": "burnisher operator – footwear manufacturing"
    },
    {
        "value": 27217,
        "NOCCode": "9537",
        "JobTitle": "burnishing machine operator – footwear manufacturing"
    },
    {
        "value": 27218,
        "NOCCode": "9537",
        "JobTitle": "button designer and sample maker"
    },
    {
        "value": 27219,
        "NOCCode": "9537",
        "JobTitle": "button finisher"
    },
    {
        "value": 27220,
        "NOCCode": "9537",
        "JobTitle": "button machine operator"
    },
    {
        "value": 27221,
        "NOCCode": "9537",
        "JobTitle": "button machine setter"
    },
    {
        "value": 27222,
        "NOCCode": "9537",
        "JobTitle": "button maker"
    },
    {
        "value": 27223,
        "NOCCode": "9537",
        "JobTitle": "button press operator"
    },
    {
        "value": 27224,
        "NOCCode": "9537",
        "JobTitle": "button-covering machine setter"
    },
    {
        "value": 27225,
        "NOCCode": "9537",
        "JobTitle": "button-moulder operator"
    },
    {
        "value": 27226,
        "NOCCode": "9537",
        "JobTitle": "buttons inspector"
    },
    {
        "value": 27227,
        "NOCCode": "9537",
        "JobTitle": "calibrator, clock hairsprings"
    },
    {
        "value": 27228,
        "NOCCode": "9537",
        "JobTitle": "calibrator, optical instruments"
    },
    {
        "value": 27229,
        "NOCCode": "9537",
        "JobTitle": "camera assembler"
    },
    {
        "value": 27230,
        "NOCCode": "9537",
        "JobTitle": "camera assembly inspector"
    },
    {
        "value": 27231,
        "NOCCode": "9537",
        "JobTitle": "camera inspector"
    },
    {
        "value": 27232,
        "NOCCode": "9537",
        "JobTitle": "candle decorator"
    },
    {
        "value": 27233,
        "NOCCode": "9537",
        "JobTitle": "canvas awning maker"
    },
    {
        "value": 27234,
        "NOCCode": "9537",
        "JobTitle": "canvas goods assembler"
    },
    {
        "value": 27235,
        "NOCCode": "9537",
        "JobTitle": "canvas tent maker"
    },
    {
        "value": 27236,
        "NOCCode": "9537",
        "JobTitle": "car seat assembler"
    },
    {
        "value": 27237,
        "NOCCode": "9537",
        "JobTitle": "carpet tape machine tender"
    },
    {
        "value": 27238,
        "NOCCode": "9537",
        "JobTitle": "cartridge assembler"
    },
    {
        "value": 27239,
        "NOCCode": "9537",
        "JobTitle": "cartridge inspector"
    },
    {
        "value": 27240,
        "NOCCode": "9537",
        "JobTitle": "cartridge-filler operator"
    },
    {
        "value": 27241,
        "NOCCode": "9537",
        "JobTitle": "cartridge-loader machine operator"
    },
    {
        "value": 27242,
        "NOCCode": "9537",
        "JobTitle": "case assembler and finisher"
    },
    {
        "value": 27243,
        "NOCCode": "9537",
        "JobTitle": "caster, ornamental designs"
    },
    {
        "value": 27244,
        "NOCCode": "9537",
        "JobTitle": "centre winder, baseballs"
    },
    {
        "value": 27245,
        "NOCCode": "9537",
        "JobTitle": "chain maker, hand – jewellery manufacturing"
    },
    {
        "value": 27246,
        "NOCCode": "9537",
        "JobTitle": "channeler – footwear manufacturing"
    },
    {
        "value": 27247,
        "NOCCode": "9537",
        "JobTitle": "charger – jewellery manufacturing"
    },
    {
        "value": 27248,
        "NOCCode": "9537",
        "JobTitle": "charger, thermometric systems capillary tubes"
    },
    {
        "value": 27249,
        "NOCCode": "9537",
        "JobTitle": "cigarette case maker"
    },
    {
        "value": 27250,
        "NOCCode": "9537",
        "JobTitle": "cigarette holder maker"
    },
    {
        "value": 27251,
        "NOCCode": "9537",
        "JobTitle": "cleaner, jewellery"
    },
    {
        "value": 27252,
        "NOCCode": "9537",
        "JobTitle": "cleaning machine tender, ultrasonic"
    },
    {
        "value": 27253,
        "NOCCode": "9537",
        "JobTitle": "clock and timer movement automated line assembler"
    },
    {
        "value": 27254,
        "NOCCode": "9537",
        "JobTitle": "clock and watch assembler"
    },
    {
        "value": 27255,
        "NOCCode": "9537",
        "JobTitle": "clock and watch assembly inspector"
    },
    {
        "value": 27256,
        "NOCCode": "9537",
        "JobTitle": "clock and watch dial installer"
    },
    {
        "value": 27257,
        "NOCCode": "9537",
        "JobTitle": "clock and watch finisher"
    },
    {
        "value": 27258,
        "NOCCode": "9537",
        "JobTitle": "clock and watch hairspring truer"
    },
    {
        "value": 27259,
        "NOCCode": "9537",
        "JobTitle": "clock and watch movements tester"
    },
    {
        "value": 27260,
        "NOCCode": "9537",
        "JobTitle": "clock assembler"
    },
    {
        "value": 27261,
        "NOCCode": "9537",
        "JobTitle": "clock assembly adjuster"
    },
    {
        "value": 27262,
        "NOCCode": "9537",
        "JobTitle": "clock balance wheel assembler"
    },
    {
        "value": 27263,
        "NOCCode": "9537",
        "JobTitle": "clock finisher"
    },
    {
        "value": 27264,
        "NOCCode": "9537",
        "JobTitle": "clock hairspring calibrator"
    },
    {
        "value": 27265,
        "NOCCode": "9537",
        "JobTitle": "clock hairspring maker"
    },
    {
        "value": 27266,
        "NOCCode": "9537",
        "JobTitle": "clock part die casting assembler"
    },
    {
        "value": 27267,
        "NOCCode": "9537",
        "JobTitle": "clock spring assembler"
    },
    {
        "value": 27268,
        "NOCCode": "9537",
        "JobTitle": "clock staker"
    },
    {
        "value": 27269,
        "NOCCode": "9537",
        "JobTitle": "clockmaker – manufacturing"
    },
    {
        "value": 27270,
        "NOCCode": "9537",
        "JobTitle": "coater operator, optical mirrors"
    },
    {
        "value": 27271,
        "NOCCode": "9537",
        "JobTitle": "coater, optical elements – non-prescription"
    },
    {
        "value": 27272,
        "NOCCode": "9537",
        "JobTitle": "collar and belt turner"
    },
    {
        "value": 27273,
        "NOCCode": "9537",
        "JobTitle": "colour-card maker, hand"
    },
    {
        "value": 27274,
        "NOCCode": "9537",
        "JobTitle": "comb maker"
    },
    {
        "value": 27275,
        "NOCCode": "9537",
        "JobTitle": "compact disc matrix inspector"
    },
    {
        "value": 27276,
        "NOCCode": "9537",
        "JobTitle": "compass assembler"
    },
    {
        "value": 27277,
        "NOCCode": "9537",
        "JobTitle": "compressed air and gas regulator assembler"
    },
    {
        "value": 27278,
        "NOCCode": "9537",
        "JobTitle": "conveyor wire belt builder"
    },
    {
        "value": 27279,
        "NOCCode": "9537",
        "JobTitle": "corks and bottle caps inspector"
    },
    {
        "value": 27280,
        "NOCCode": "9537",
        "JobTitle": "corncob pipe assembler"
    },
    {
        "value": 27281,
        "NOCCode": "9537",
        "JobTitle": "counter and upper tacker – footwear manufacturing"
    },
    {
        "value": 27282,
        "NOCCode": "9537",
        "JobTitle": "covered button maker"
    },
    {
        "value": 27283,
        "NOCCode": "9537",
        "JobTitle": "crayon making machine tender"
    },
    {
        "value": 27284,
        "NOCCode": "9537",
        "JobTitle": "creaser – garment and fabric"
    },
    {
        "value": 27285,
        "NOCCode": "9537",
        "JobTitle": "crimping machine tender – leather products manufacturing"
    },
    {
        "value": 27286,
        "NOCCode": "9537",
        "JobTitle": "curtain rod assembler"
    },
    {
        "value": 27287,
        "NOCCode": "9537",
        "JobTitle": "custom golf club maker"
    },
    {
        "value": 27288,
        "NOCCode": "9537",
        "JobTitle": "cutlery assembly inspector"
    },
    {
        "value": 27289,
        "NOCCode": "9537",
        "JobTitle": "cutlery maker"
    },
    {
        "value": 27290,
        "NOCCode": "9537",
        "JobTitle": "cutlery maker operator"
    },
    {
        "value": 27291,
        "NOCCode": "9537",
        "JobTitle": "cutter operator – other products manufacturing"
    },
    {
        "value": 27292,
        "NOCCode": "9537",
        "JobTitle": "cutter, eyeglass frames"
    },
    {
        "value": 27293,
        "NOCCode": "9537",
        "JobTitle": "cutter, hat brims – fabric products manufacturing"
    },
    {
        "value": 27294,
        "NOCCode": "9537",
        "JobTitle": "cutting machine"
    },
    {
        "value": 27295,
        "NOCCode": "9537",
        "JobTitle": "cutting machine tender – textile products manufacturing"
    },
    {
        "value": 27296,
        "NOCCode": "9537",
        "JobTitle": "decoration maker, hats"
    },
    {
        "value": 27297,
        "NOCCode": "9537",
        "JobTitle": "decorator, candles"
    },
    {
        "value": 27298,
        "NOCCode": "9537",
        "JobTitle": "decorator, enamel – jewellery manufacturing"
    },
    {
        "value": 27299,
        "NOCCode": "9537",
        "JobTitle": "decorator, hats – fabric products manufacturing"
    },
    {
        "value": 27300,
        "NOCCode": "9537",
        "JobTitle": "decorator, shoes – footwear manufacturing"
    },
    {
        "value": 27301,
        "NOCCode": "9537",
        "JobTitle": "dental instrument assembler"
    },
    {
        "value": 27302,
        "NOCCode": "9537",
        "JobTitle": "detonator and fuse assembler"
    },
    {
        "value": 27303,
        "NOCCode": "9537",
        "JobTitle": "detonator assembler"
    },
    {
        "value": 27304,
        "NOCCode": "9537",
        "JobTitle": "dial marker"
    },
    {
        "value": 27305,
        "NOCCode": "9537",
        "JobTitle": "dice maker"
    },
    {
        "value": 27306,
        "NOCCode": "9537",
        "JobTitle": "display balloon maker"
    },
    {
        "value": 27307,
        "NOCCode": "9537",
        "JobTitle": "doll assembly repairer"
    },
    {
        "value": 27308,
        "NOCCode": "9537",
        "JobTitle": "doll hairstylist"
    },
    {
        "value": 27309,
        "NOCCode": "9537",
        "JobTitle": "doll maker – manufacturing"
    },
    {
        "value": 27310,
        "NOCCode": "9537",
        "JobTitle": "door and window assembler, aluminum"
    },
    {
        "value": 27311,
        "NOCCode": "9537",
        "JobTitle": "door assembler, aluminum"
    },
    {
        "value": 27312,
        "NOCCode": "9537",
        "JobTitle": "drapery pleater, machine"
    },
    {
        "value": 27313,
        "NOCCode": "9537",
        "JobTitle": "drawing instrument assembler"
    },
    {
        "value": 27314,
        "NOCCode": "9537",
        "JobTitle": "driller, optical goods – non-prescription"
    },
    {
        "value": 27315,
        "NOCCode": "9537",
        "JobTitle": "drug and toilet preparations inspector"
    },
    {
        "value": 27316,
        "NOCCode": "9537",
        "JobTitle": "dust brush maker"
    },
    {
        "value": 27317,
        "NOCCode": "9537",
        "JobTitle": "dust mop assembler"
    },
    {
        "value": 27318,
        "NOCCode": "9537",
        "JobTitle": "dust mop maker"
    },
    {
        "value": 27319,
        "NOCCode": "9537",
        "JobTitle": "duster maker"
    },
    {
        "value": 27320,
        "NOCCode": "9537",
        "JobTitle": "earring maker, pearls"
    },
    {
        "value": 27321,
        "NOCCode": "9537",
        "JobTitle": "edge grinder, watch crystals"
    },
    {
        "value": 27322,
        "NOCCode": "9537",
        "JobTitle": "edger, hand lenses – non-prescription"
    },
    {
        "value": 27323,
        "NOCCode": "9537",
        "JobTitle": "edger, precision optical lenses – non-prescription"
    },
    {
        "value": 27324,
        "NOCCode": "9537",
        "JobTitle": "embossing machine operator – footwear"
    },
    {
        "value": 27325,
        "NOCCode": "9537",
        "JobTitle": "enamel applier – jewellery manufacturing"
    },
    {
        "value": 27326,
        "NOCCode": "9537",
        "JobTitle": "enamel burner – jewellery manufacturing"
    },
    {
        "value": 27327,
        "NOCCode": "9537",
        "JobTitle": "enamel decorator – jewellery manufacturing"
    },
    {
        "value": 27328,
        "NOCCode": "9537",
        "JobTitle": "engraver, machine – manufacturing"
    },
    {
        "value": 27329,
        "NOCCode": "9537",
        "JobTitle": "engraving machine operator"
    },
    {
        "value": 27330,
        "NOCCode": "9537",
        "JobTitle": "epoxy-pouring machine operator"
    },
    {
        "value": 27331,
        "NOCCode": "9537",
        "JobTitle": "etcher, optical glasses"
    },
    {
        "value": 27332,
        "NOCCode": "9537",
        "JobTitle": "explosive primer inspector"
    },
    {
        "value": 27333,
        "NOCCode": "9537",
        "JobTitle": "eyeglass frame assembler"
    },
    {
        "value": 27334,
        "NOCCode": "9537",
        "JobTitle": "eyeglass frame cutter"
    },
    {
        "value": 27335,
        "NOCCode": "9537",
        "JobTitle": "eyeglass frame inspector and sorter"
    },
    {
        "value": 27336,
        "NOCCode": "9537",
        "JobTitle": "eyeglass frame maker"
    },
    {
        "value": 27337,
        "NOCCode": "9537",
        "JobTitle": "eyeglass frame polisher"
    },
    {
        "value": 27338,
        "NOCCode": "9537",
        "JobTitle": "eyeglass lens mounter"
    },
    {
        "value": 27339,
        "NOCCode": "9537",
        "JobTitle": "eyeglass lenses edger tender – non-prescription"
    },
    {
        "value": 27340,
        "NOCCode": "9537",
        "JobTitle": "eyelet-punching machine tender"
    },
    {
        "value": 27341,
        "NOCCode": "9537",
        "JobTitle": "fabric hat assembler"
    },
    {
        "value": 27342,
        "NOCCode": "9537",
        "JobTitle": "fabricator, reflective signs"
    },
    {
        "value": 27343,
        "NOCCode": "9537",
        "JobTitle": "fancy wire drawer – jewellery manufacturing"
    },
    {
        "value": 27344,
        "NOCCode": "9537",
        "JobTitle": "fastener, shoe parts"
    },
    {
        "value": 27345,
        "NOCCode": "9537",
        "JobTitle": "feeder, optical lens edger – non-prescription"
    },
    {
        "value": 27346,
        "NOCCode": "9537",
        "JobTitle": "felt hat finisher"
    },
    {
        "value": 27347,
        "NOCCode": "9537",
        "JobTitle": "fibre strap machine tender"
    },
    {
        "value": 27348,
        "NOCCode": "9537",
        "JobTitle": "filer, jewellery"
    },
    {
        "value": 27349,
        "NOCCode": "9537",
        "JobTitle": "filigree finisher"
    },
    {
        "value": 27350,
        "NOCCode": "9537",
        "JobTitle": "filigree wire drawer – jewellery manufacturing"
    },
    {
        "value": 27351,
        "NOCCode": "9537",
        "JobTitle": "film-processing unit assembler"
    },
    {
        "value": 27352,
        "NOCCode": "9537",
        "JobTitle": "filter assembler"
    },
    {
        "value": 27353,
        "NOCCode": "9537",
        "JobTitle": "final assembly inspector, watches"
    },
    {
        "value": 27354,
        "NOCCode": "9537",
        "JobTitle": "findings attacher – jewellery manufacturing"
    },
    {
        "value": 27355,
        "NOCCode": "9537",
        "JobTitle": "finisher, clocks"
    },
    {
        "value": 27356,
        "NOCCode": "9537",
        "JobTitle": "finisher, clocks and watches"
    },
    {
        "value": 27357,
        "NOCCode": "9537",
        "JobTitle": "finisher, felt hats"
    },
    {
        "value": 27358,
        "NOCCode": "9537",
        "JobTitle": "finisher, filigree"
    },
    {
        "value": 27359,
        "NOCCode": "9537",
        "JobTitle": "finisher, hats"
    },
    {
        "value": 27360,
        "NOCCode": "9537",
        "JobTitle": "finisher, jewellery wire"
    },
    {
        "value": 27361,
        "NOCCode": "9537",
        "JobTitle": "finisher, sleeping bags"
    },
    {
        "value": 27362,
        "NOCCode": "9537",
        "JobTitle": "finisher, tarpaulins"
    },
    {
        "value": 27363,
        "NOCCode": "9537",
        "JobTitle": "finisher, tents"
    },
    {
        "value": 27364,
        "NOCCode": "9537",
        "JobTitle": "firefighters' hats assembler"
    },
    {
        "value": 27365,
        "NOCCode": "9537",
        "JobTitle": "firework assembler"
    },
    {
        "value": 27366,
        "NOCCode": "9537",
        "JobTitle": "firework maker"
    },
    {
        "value": 27367,
        "NOCCode": "9537",
        "JobTitle": "fish net maker, hand"
    },
    {
        "value": 27368,
        "NOCCode": "9537",
        "JobTitle": "fish net making machine operator"
    },
    {
        "value": 27369,
        "NOCCode": "9537",
        "JobTitle": "fishing reel assembler"
    },
    {
        "value": 27370,
        "NOCCode": "9537",
        "JobTitle": "fishing rod assembler"
    },
    {
        "value": 27371,
        "NOCCode": "9537",
        "JobTitle": "fishing rod finisher"
    },
    {
        "value": 27372,
        "NOCCode": "9537",
        "JobTitle": "fishing tackle maker"
    },
    {
        "value": 27373,
        "NOCCode": "9537",
        "JobTitle": "fish-net-making machine tender"
    },
    {
        "value": 27374,
        "NOCCode": "9537",
        "JobTitle": "fitter, heel seats"
    },
    {
        "value": 27375,
        "NOCCode": "9537",
        "JobTitle": "flag-making machine operator"
    },
    {
        "value": 27376,
        "NOCCode": "9537",
        "JobTitle": "flare assembler"
    },
    {
        "value": 27377,
        "NOCCode": "9537",
        "JobTitle": "flat optical element maker – non-prescription"
    },
    {
        "value": 27378,
        "NOCCode": "9537",
        "JobTitle": "flatware-buffing machine set-up operator"
    },
    {
        "value": 27379,
        "NOCCode": "9537",
        "JobTitle": "fletcher – sporting goods manufacturing"
    },
    {
        "value": 27380,
        "NOCCode": "9537",
        "JobTitle": "foiling machine tender"
    },
    {
        "value": 27381,
        "NOCCode": "9537",
        "JobTitle": "folding machine operator – garment manufacturing"
    },
    {
        "value": 27382,
        "NOCCode": "9537",
        "JobTitle": "footwear parts trimmer – footwear manufacturing"
    },
    {
        "value": 27383,
        "NOCCode": "9537",
        "JobTitle": "former, metal-sheathed insulation"
    },
    {
        "value": 27384,
        "NOCCode": "9537",
        "JobTitle": "foundation maker, hats – fabric products manufacturing"
    },
    {
        "value": 27385,
        "NOCCode": "9537",
        "JobTitle": "fountain pen inspector"
    },
    {
        "value": 27386,
        "NOCCode": "9537",
        "JobTitle": "fountain pen maker"
    },
    {
        "value": 27387,
        "NOCCode": "9537",
        "JobTitle": "foxer – footwear"
    },
    {
        "value": 27388,
        "NOCCode": "9537",
        "JobTitle": "frame maker, eyeglasses"
    },
    {
        "value": 27389,
        "NOCCode": "9537",
        "JobTitle": "framer, mirrors"
    },
    {
        "value": 27390,
        "NOCCode": "9537",
        "JobTitle": "fuel filter assembler"
    },
    {
        "value": 27391,
        "NOCCode": "9537",
        "JobTitle": "fuel rod assembler"
    },
    {
        "value": 27392,
        "NOCCode": "9537",
        "JobTitle": "fur blocker"
    },
    {
        "value": 27393,
        "NOCCode": "9537",
        "JobTitle": "gas meter assembler"
    },
    {
        "value": 27394,
        "NOCCode": "9537",
        "JobTitle": "gauze pad machine tender"
    },
    {
        "value": 27395,
        "NOCCode": "9537",
        "JobTitle": "gluer – other products manufacturing"
    },
    {
        "value": 27396,
        "NOCCode": "9537",
        "JobTitle": "golf club adjuster"
    },
    {
        "value": 27397,
        "NOCCode": "9537",
        "JobTitle": "golf club assembler"
    },
    {
        "value": 27398,
        "NOCCode": "9537",
        "JobTitle": "golf club inspector"
    },
    {
        "value": 27399,
        "NOCCode": "9537",
        "JobTitle": "grinder, precision optical lenses – non-prescription"
    },
    {
        "value": 27400,
        "NOCCode": "9537",
        "JobTitle": "grinder, watch crystal edges"
    },
    {
        "value": 27401,
        "NOCCode": "9537",
        "JobTitle": "hair-rooting machine operator – toy manufacturing"
    },
    {
        "value": 27402,
        "NOCCode": "9537",
        "JobTitle": "hairspring maker, clocks"
    },
    {
        "value": 27403,
        "NOCCode": "9537",
        "JobTitle": "hairspring truer"
    },
    {
        "value": 27404,
        "NOCCode": "9537",
        "JobTitle": "hairstylist, dolls"
    },
    {
        "value": 27405,
        "NOCCode": "9537",
        "JobTitle": "hand bag-maker"
    },
    {
        "value": 27406,
        "NOCCode": "9537",
        "JobTitle": "hand blocker, hats"
    },
    {
        "value": 27407,
        "NOCCode": "9537",
        "JobTitle": "hand colour-card maker"
    },
    {
        "value": 27408,
        "NOCCode": "9537",
        "JobTitle": "hand fish net maker"
    },
    {
        "value": 27409,
        "NOCCode": "9537",
        "JobTitle": "hand former, hat brims"
    },
    {
        "value": 27410,
        "NOCCode": "9537",
        "JobTitle": "hand hat blocker"
    },
    {
        "value": 27411,
        "NOCCode": "9537",
        "JobTitle": "hand hat brim former"
    },
    {
        "value": 27412,
        "NOCCode": "9537",
        "JobTitle": "hand lacer – sporting goods manufacturing"
    },
    {
        "value": 27413,
        "NOCCode": "9537",
        "JobTitle": "hand lens beveller – non-prescription"
    },
    {
        "value": 27414,
        "NOCCode": "9537",
        "JobTitle": "hand lens edger – non-prescription"
    },
    {
        "value": 27415,
        "NOCCode": "9537",
        "JobTitle": "hand net-maker"
    },
    {
        "value": 27416,
        "NOCCode": "9537",
        "JobTitle": "hand pleater – garment manufacturing"
    },
    {
        "value": 27417,
        "NOCCode": "9537",
        "JobTitle": "hand sample container maker"
    },
    {
        "value": 27418,
        "NOCCode": "9537",
        "JobTitle": "handbag maker, leather"
    },
    {
        "value": 27419,
        "NOCCode": "9537",
        "JobTitle": "handle and vent machine tender"
    },
    {
        "value": 27420,
        "NOCCode": "9537",
        "JobTitle": "hard hat assembler"
    },
    {
        "value": 27421,
        "NOCCode": "9537",
        "JobTitle": "hardboard-coating line checker"
    },
    {
        "value": 27422,
        "NOCCode": "9537",
        "JobTitle": "hardware assembler"
    },
    {
        "value": 27423,
        "NOCCode": "9537",
        "JobTitle": "hardware, tool and knife inspector"
    },
    {
        "value": 27424,
        "NOCCode": "9537",
        "JobTitle": "hat and cap eyelet machine operator"
    },
    {
        "value": 27425,
        "NOCCode": "9537",
        "JobTitle": "hat blocker, hand"
    },
    {
        "value": 27426,
        "NOCCode": "9537",
        "JobTitle": "hat brim cutter – fabric products manufacturing"
    },
    {
        "value": 27427,
        "NOCCode": "9537",
        "JobTitle": "hat brim former, hand"
    },
    {
        "value": 27428,
        "NOCCode": "9537",
        "JobTitle": "hat cone former – hat manufacturing"
    },
    {
        "value": 27429,
        "NOCCode": "9537",
        "JobTitle": "hat cone shrinker"
    },
    {
        "value": 27430,
        "NOCCode": "9537",
        "JobTitle": "hat crown and brim former – hat manufacturing"
    },
    {
        "value": 27431,
        "NOCCode": "9537",
        "JobTitle": "hat decoration maker"
    },
    {
        "value": 27432,
        "NOCCode": "9537",
        "JobTitle": "hat decorator – fabric products manufacturing"
    },
    {
        "value": 27433,
        "NOCCode": "9537",
        "JobTitle": "hat finisher"
    },
    {
        "value": 27434,
        "NOCCode": "9537",
        "JobTitle": "hat finisher, felt"
    },
    {
        "value": 27435,
        "NOCCode": "9537",
        "JobTitle": "hat foundation maker – fabric products manufacturing"
    },
    {
        "value": 27436,
        "NOCCode": "9537",
        "JobTitle": "hat ornament maker"
    },
    {
        "value": 27437,
        "NOCCode": "9537",
        "JobTitle": "hat presser, machine – hat manufacturing"
    },
    {
        "value": 27438,
        "NOCCode": "9537",
        "JobTitle": "hat-brim-flexing machine tender – hat manufacturing"
    },
    {
        "value": 27439,
        "NOCCode": "9537",
        "JobTitle": "hat-greasing machine tender – hat manufacturing"
    },
    {
        "value": 27440,
        "NOCCode": "9537",
        "JobTitle": "hatmaking machine operator"
    },
    {
        "value": 27441,
        "NOCCode": "9537",
        "JobTitle": "hat-pouncing machine tender – hat manufacturing"
    },
    {
        "value": 27442,
        "NOCCode": "9537",
        "JobTitle": "head maker, paintbrushes"
    },
    {
        "value": 27443,
        "NOCCode": "9537",
        "JobTitle": "heading machine tender"
    },
    {
        "value": 27444,
        "NOCCode": "9537",
        "JobTitle": "heat sensor assembler"
    },
    {
        "value": 27445,
        "NOCCode": "9537",
        "JobTitle": "heat shield maker, aircraft"
    },
    {
        "value": 27446,
        "NOCCode": "9537",
        "JobTitle": "heel attacher"
    },
    {
        "value": 27447,
        "NOCCode": "9537",
        "JobTitle": "heel breaster"
    },
    {
        "value": 27448,
        "NOCCode": "9537",
        "JobTitle": "heel finisher, wood – footwear manufacturing"
    },
    {
        "value": 27449,
        "NOCCode": "9537",
        "JobTitle": "heel maker"
    },
    {
        "value": 27450,
        "NOCCode": "9537",
        "JobTitle": "heel seat fitter"
    },
    {
        "value": 27451,
        "NOCCode": "9537",
        "JobTitle": "heel-trimming machine operator – footwear manufacturing"
    },
    {
        "value": 27452,
        "NOCCode": "9537",
        "JobTitle": "hollowware-buffing machine tender"
    },
    {
        "value": 27453,
        "NOCCode": "9537",
        "JobTitle": "honer, safety razor blades"
    },
    {
        "value": 27454,
        "NOCCode": "9537",
        "JobTitle": "hydrometer assembler"
    },
    {
        "value": 27455,
        "NOCCode": "9537",
        "JobTitle": "ice chip maker tender"
    },
    {
        "value": 27456,
        "NOCCode": "9537",
        "JobTitle": "ice machine tender"
    },
    {
        "value": 27457,
        "NOCCode": "9537",
        "JobTitle": "ice maker"
    },
    {
        "value": 27458,
        "NOCCode": "9537",
        "JobTitle": "ice-cutting machine tender"
    },
    {
        "value": 27459,
        "NOCCode": "9537",
        "JobTitle": "igniter assembler"
    },
    {
        "value": 27460,
        "NOCCode": "9537",
        "JobTitle": "inflated ball moulder"
    },
    {
        "value": 27461,
        "NOCCode": "9537",
        "JobTitle": "inker, ribbons"
    },
    {
        "value": 27462,
        "NOCCode": "9537",
        "JobTitle": "insole tape machine operator"
    },
    {
        "value": 27463,
        "NOCCode": "9537",
        "JobTitle": "insole-moulding machine operator"
    },
    {
        "value": 27464,
        "NOCCode": "9537",
        "JobTitle": "insole-moulding machine tender"
    },
    {
        "value": 27465,
        "NOCCode": "9537",
        "JobTitle": "insole-tacking machine operator"
    },
    {
        "value": 27466,
        "NOCCode": "9537",
        "JobTitle": "inspector and repairer, sports balls"
    },
    {
        "value": 27467,
        "NOCCode": "9537",
        "JobTitle": "inspector and sorter, eyeglass frames"
    },
    {
        "value": 27468,
        "NOCCode": "9537",
        "JobTitle": "inspector, ammunition"
    },
    {
        "value": 27469,
        "NOCCode": "9537",
        "JobTitle": "inspector, ammunition loading"
    },
    {
        "value": 27470,
        "NOCCode": "9537",
        "JobTitle": "inspector, ammunition manufacturing"
    },
    {
        "value": 27471,
        "NOCCode": "9537",
        "JobTitle": "inspector, ball bearings"
    },
    {
        "value": 27472,
        "NOCCode": "9537",
        "JobTitle": "inspector, bearings"
    },
    {
        "value": 27473,
        "NOCCode": "9537",
        "JobTitle": "inspector, bicycles"
    },
    {
        "value": 27474,
        "NOCCode": "9537",
        "JobTitle": "inspector, bullet and cartridge cases"
    },
    {
        "value": 27475,
        "NOCCode": "9537",
        "JobTitle": "inspector, bullets and cartridges"
    },
    {
        "value": 27476,
        "NOCCode": "9537",
        "JobTitle": "inspector, buttons"
    },
    {
        "value": 27477,
        "NOCCode": "9537",
        "JobTitle": "inspector, camera assembly"
    },
    {
        "value": 27478,
        "NOCCode": "9537",
        "JobTitle": "inspector, cameras"
    },
    {
        "value": 27479,
        "NOCCode": "9537",
        "JobTitle": "inspector, clock and watch assembly"
    },
    {
        "value": 27480,
        "NOCCode": "9537",
        "JobTitle": "inspector, corks and bottle caps"
    },
    {
        "value": 27481,
        "NOCCode": "9537",
        "JobTitle": "inspector, cutlery assembly"
    },
    {
        "value": 27482,
        "NOCCode": "9537",
        "JobTitle": "inspector, drug and toilet preparations"
    },
    {
        "value": 27483,
        "NOCCode": "9537",
        "JobTitle": "inspector, explosive primers"
    },
    {
        "value": 27484,
        "NOCCode": "9537",
        "JobTitle": "inspector, fountain pens"
    },
    {
        "value": 27485,
        "NOCCode": "9537",
        "JobTitle": "inspector, golf clubs"
    },
    {
        "value": 27486,
        "NOCCode": "9537",
        "JobTitle": "inspector, jewellery"
    },
    {
        "value": 27487,
        "NOCCode": "9537",
        "JobTitle": "inspector, jewellery assembly"
    },
    {
        "value": 27488,
        "NOCCode": "9537",
        "JobTitle": "inspector, matchbooks"
    },
    {
        "value": 27489,
        "NOCCode": "9537",
        "JobTitle": "inspector, matches"
    },
    {
        "value": 27490,
        "NOCCode": "9537",
        "JobTitle": "inspector, munitions"
    },
    {
        "value": 27491,
        "NOCCode": "9537",
        "JobTitle": "inspector, munitions manufacturing"
    },
    {
        "value": 27492,
        "NOCCode": "9537",
        "JobTitle": "inspector, pens and pencils"
    },
    {
        "value": 27493,
        "NOCCode": "9537",
        "JobTitle": "inspector, photographic equipment"
    },
    {
        "value": 27494,
        "NOCCode": "9537",
        "JobTitle": "inspector, photographic equipment assembly"
    },
    {
        "value": 27495,
        "NOCCode": "9537",
        "JobTitle": "inspector, piano assembly"
    },
    {
        "value": 27496,
        "NOCCode": "9537",
        "JobTitle": "inspector, pianos"
    },
    {
        "value": 27497,
        "NOCCode": "9537",
        "JobTitle": "inspector, precision instrument assembly"
    },
    {
        "value": 27498,
        "NOCCode": "9537",
        "JobTitle": "inspector, projector assembly"
    },
    {
        "value": 27499,
        "NOCCode": "9537",
        "JobTitle": "inspector, purchased metal parts"
    },
    {
        "value": 27500,
        "NOCCode": "9537",
        "JobTitle": "inspector, roller bearing assembly"
    },
    {
        "value": 27501,
        "NOCCode": "9537",
        "JobTitle": "inspector, safety razor blades"
    },
    {
        "value": 27502,
        "NOCCode": "9537",
        "JobTitle": "inspector, scales"
    },
    {
        "value": 27503,
        "NOCCode": "9537",
        "JobTitle": "inspector, seat belt assembly"
    },
    {
        "value": 27504,
        "NOCCode": "9537",
        "JobTitle": "inspector, soap and toilet goods preparations"
    },
    {
        "value": 27505,
        "NOCCode": "9537",
        "JobTitle": "inspector, tool and cutlery assembly"
    },
    {
        "value": 27506,
        "NOCCode": "9537",
        "JobTitle": "inspector, toys"
    },
    {
        "value": 27507,
        "NOCCode": "9537",
        "JobTitle": "inspector, umbrellas"
    },
    {
        "value": 27508,
        "NOCCode": "9537",
        "JobTitle": "inspector, wallpaper"
    },
    {
        "value": 27509,
        "NOCCode": "9537",
        "JobTitle": "inspector, watch assembly"
    },
    {
        "value": 27510,
        "NOCCode": "9537",
        "JobTitle": "inspector, watches"
    },
    {
        "value": 27511,
        "NOCCode": "9537",
        "JobTitle": "inspector, zipper manufacturing"
    },
    {
        "value": 27512,
        "NOCCode": "9537",
        "JobTitle": "inspector, zippers"
    },
    {
        "value": 27513,
        "NOCCode": "9537",
        "JobTitle": "inspector-adjuster, piano actions"
    },
    {
        "value": 27514,
        "NOCCode": "9537",
        "JobTitle": "installer, clock and watch dial"
    },
    {
        "value": 27515,
        "NOCCode": "9537",
        "JobTitle": "insulating panel maker"
    },
    {
        "value": 27516,
        "NOCCode": "9537",
        "JobTitle": "insulating tube winder"
    },
    {
        "value": 27517,
        "NOCCode": "9537",
        "JobTitle": "insulation batt cutter operator"
    },
    {
        "value": 27518,
        "NOCCode": "9537",
        "JobTitle": "insulation blanket maker"
    },
    {
        "value": 27519,
        "NOCCode": "9537",
        "JobTitle": "jewellery annealer"
    },
    {
        "value": 27520,
        "NOCCode": "9537",
        "JobTitle": "jewellery assembler"
    },
    {
        "value": 27521,
        "NOCCode": "9537",
        "JobTitle": "jewellery assembly inspector"
    },
    {
        "value": 27522,
        "NOCCode": "9537",
        "JobTitle": "jewellery case assembler"
    },
    {
        "value": 27523,
        "NOCCode": "9537",
        "JobTitle": "jewellery cleaner"
    },
    {
        "value": 27524,
        "NOCCode": "9537",
        "JobTitle": "jewellery filer"
    },
    {
        "value": 27525,
        "NOCCode": "9537",
        "JobTitle": "jewellery inspector"
    },
    {
        "value": 27526,
        "NOCCode": "9537",
        "JobTitle": "jewellery parts assembler"
    },
    {
        "value": 27527,
        "NOCCode": "9537",
        "JobTitle": "jewellery polisher"
    },
    {
        "value": 27528,
        "NOCCode": "9537",
        "JobTitle": "jewellery riveter"
    },
    {
        "value": 27529,
        "NOCCode": "9537",
        "JobTitle": "jewellery solderer"
    },
    {
        "value": 27530,
        "NOCCode": "9537",
        "JobTitle": "jewellery strip roller"
    },
    {
        "value": 27531,
        "NOCCode": "9537",
        "JobTitle": "jewellery turner"
    },
    {
        "value": 27532,
        "NOCCode": "9537",
        "JobTitle": "jewellery wire finisher"
    },
    {
        "value": 27533,
        "NOCCode": "9537",
        "JobTitle": "jewellery-soldering machine tender"
    },
    {
        "value": 27534,
        "NOCCode": "9537",
        "JobTitle": "knife assembler"
    },
    {
        "value": 27535,
        "NOCCode": "9537",
        "JobTitle": "knife inspector"
    },
    {
        "value": 27536,
        "NOCCode": "9537",
        "JobTitle": "knurling machine operator, pen and pencil"
    },
    {
        "value": 27537,
        "NOCCode": "9537",
        "JobTitle": "label-cutting machine tender"
    },
    {
        "value": 27538,
        "NOCCode": "9537",
        "JobTitle": "lacer, lacrosse stick"
    },
    {
        "value": 27539,
        "NOCCode": "9537",
        "JobTitle": "lacrosse stick lacer"
    },
    {
        "value": 27540,
        "NOCCode": "9537",
        "JobTitle": "lampshade assembler"
    },
    {
        "value": 27541,
        "NOCCode": "9537",
        "JobTitle": "lampshade maker"
    },
    {
        "value": 27542,
        "NOCCode": "9537",
        "JobTitle": "lampshade sewer"
    },
    {
        "value": 27543,
        "NOCCode": "9537",
        "JobTitle": "laster – footwear manufacturing (except rubber)"
    },
    {
        "value": 27544,
        "NOCCode": "9537",
        "JobTitle": "layout table hemmer"
    },
    {
        "value": 27545,
        "NOCCode": "9537",
        "JobTitle": "leather bag maker"
    },
    {
        "value": 27546,
        "NOCCode": "9537",
        "JobTitle": "leather case maker"
    },
    {
        "value": 27547,
        "NOCCode": "9537",
        "JobTitle": "leather products assembler"
    },
    {
        "value": 27548,
        "NOCCode": "9537",
        "JobTitle": "leather riveter – footwear manufacturing"
    },
    {
        "value": 27549,
        "NOCCode": "9537",
        "JobTitle": "lens edger feeder – non-prescription"
    },
    {
        "value": 27550,
        "NOCCode": "9537",
        "JobTitle": "lens edger tender – non-prescription"
    },
    {
        "value": 27551,
        "NOCCode": "9537",
        "JobTitle": "lens mounter, eyeglasses"
    },
    {
        "value": 27552,
        "NOCCode": "9537",
        "JobTitle": "lens-grinding machine tender – non-prescription"
    },
    {
        "value": 27553,
        "NOCCode": "9537",
        "JobTitle": "lensometer operator – non-prescription"
    },
    {
        "value": 27554,
        "NOCCode": "9537",
        "JobTitle": "lens-polisher operator – non-prescription"
    },
    {
        "value": 27555,
        "NOCCode": "9537",
        "JobTitle": "lens-polishing machine operator – non-prescription"
    },
    {
        "value": 27556,
        "NOCCode": "9537",
        "JobTitle": "lens-silverer operator – non-prescription"
    },
    {
        "value": 27557,
        "NOCCode": "9537",
        "JobTitle": "lens-silvering machine operator – non-prescription"
    },
    {
        "value": 27558,
        "NOCCode": "9537",
        "JobTitle": "line checker, hardboard coating"
    },
    {
        "value": 27559,
        "NOCCode": "9537",
        "JobTitle": "lock assembler"
    },
    {
        "value": 27560,
        "NOCCode": "9537",
        "JobTitle": "lock maker"
    },
    {
        "value": 27561,
        "NOCCode": "9537",
        "JobTitle": "luggage finisher"
    },
    {
        "value": 27562,
        "NOCCode": "9537",
        "JobTitle": "luggage shell bonder operator"
    },
    {
        "value": 27563,
        "NOCCode": "9537",
        "JobTitle": "machine drapery pleater"
    },
    {
        "value": 27564,
        "NOCCode": "9537",
        "JobTitle": "machine engraver – manufacturing"
    },
    {
        "value": 27565,
        "NOCCode": "9537",
        "JobTitle": "machine heel maker"
    },
    {
        "value": 27566,
        "NOCCode": "9537",
        "JobTitle": "machine pleater, draperies"
    },
    {
        "value": 27567,
        "NOCCode": "9537",
        "JobTitle": "machine skiver – leather products manufacturing"
    },
    {
        "value": 27568,
        "NOCCode": "9537",
        "JobTitle": "magnetic valve assembler"
    },
    {
        "value": 27569,
        "NOCCode": "9537",
        "JobTitle": "mannequin assembler"
    },
    {
        "value": 27570,
        "NOCCode": "9537",
        "JobTitle": "mannequin wig maker"
    },
    {
        "value": 27571,
        "NOCCode": "9537",
        "JobTitle": "match inspector"
    },
    {
        "value": 27572,
        "NOCCode": "9537",
        "JobTitle": "matchbook inspector"
    },
    {
        "value": 27573,
        "NOCCode": "9537",
        "JobTitle": "metal door frame assembler"
    },
    {
        "value": 27574,
        "NOCCode": "9537",
        "JobTitle": "metal products assembler"
    },
    {
        "value": 27575,
        "NOCCode": "9537",
        "JobTitle": "metal-sheathed insulation former"
    },
    {
        "value": 27576,
        "NOCCode": "9537",
        "JobTitle": "meter assembler"
    },
    {
        "value": 27577,
        "NOCCode": "9537",
        "JobTitle": "mirror assembler"
    },
    {
        "value": 27578,
        "NOCCode": "9537",
        "JobTitle": "mirror framer"
    },
    {
        "value": 27579,
        "NOCCode": "9537",
        "JobTitle": "mop assembler"
    },
    {
        "value": 27580,
        "NOCCode": "9537",
        "JobTitle": "mop maker"
    },
    {
        "value": 27581,
        "NOCCode": "9537",
        "JobTitle": "moulder, inflatable balls"
    },
    {
        "value": 27582,
        "NOCCode": "9537",
        "JobTitle": "moulder, seamless balls"
    },
    {
        "value": 27583,
        "NOCCode": "9537",
        "JobTitle": "moulder, sports balls"
    },
    {
        "value": 27584,
        "NOCCode": "9537",
        "JobTitle": "moulding machine tender, soles – footwear manufacturing"
    },
    {
        "value": 27585,
        "NOCCode": "9537",
        "JobTitle": "moulding press operator, sports balls"
    },
    {
        "value": 27586,
        "NOCCode": "9537",
        "JobTitle": "mounter, hollowware"
    },
    {
        "value": 27587,
        "NOCCode": "9537",
        "JobTitle": "movement tester, clocks and watches"
    },
    {
        "value": 27588,
        "NOCCode": "9537",
        "JobTitle": "movement tester, watches"
    },
    {
        "value": 27589,
        "NOCCode": "9537",
        "JobTitle": "multifocal lens assembler"
    },
    {
        "value": 27590,
        "NOCCode": "9537",
        "JobTitle": "munitions assembler"
    },
    {
        "value": 27591,
        "NOCCode": "9537",
        "JobTitle": "munitions inspector"
    },
    {
        "value": 27592,
        "NOCCode": "9537",
        "JobTitle": "munitions loading inspector"
    },
    {
        "value": 27593,
        "NOCCode": "9537",
        "JobTitle": "munitions manufacturing inspector"
    },
    {
        "value": 27594,
        "NOCCode": "9537",
        "JobTitle": "munitions salvage inspector"
    },
    {
        "value": 27595,
        "NOCCode": "9537",
        "JobTitle": "munitions worker"
    },
    {
        "value": 27596,
        "NOCCode": "9537",
        "JobTitle": "musical instrument assembler"
    },
    {
        "value": 27597,
        "NOCCode": "9537",
        "JobTitle": "nailer – footwear manufacturing"
    },
    {
        "value": 27598,
        "NOCCode": "9537",
        "JobTitle": "necklace assembler"
    },
    {
        "value": 27599,
        "NOCCode": "9537",
        "JobTitle": "net maker, hand"
    },
    {
        "value": 27600,
        "NOCCode": "9537",
        "JobTitle": "nibbling machine operator – jewellery manufacturing"
    },
    {
        "value": 27601,
        "NOCCode": "9537",
        "JobTitle": "oil filter assembler"
    },
    {
        "value": 27602,
        "NOCCode": "9537",
        "JobTitle": "operator, button machine"
    },
    {
        "value": 27603,
        "NOCCode": "9537",
        "JobTitle": "operator, embossing machine – footwear manufacturing"
    },
    {
        "value": 27604,
        "NOCCode": "9537",
        "JobTitle": "operator, engraving machine"
    },
    {
        "value": 27605,
        "NOCCode": "9537",
        "JobTitle": "operator, epoxy-pouring machine"
    },
    {
        "value": 27606,
        "NOCCode": "9537",
        "JobTitle": "operator, hair-rooting machine – toy manufacturing"
    },
    {
        "value": 27607,
        "NOCCode": "9537",
        "JobTitle": "operator, hatmaking machine"
    },
    {
        "value": 27608,
        "NOCCode": "9537",
        "JobTitle": "operator, pill-coating machine"
    },
    {
        "value": 27609,
        "NOCCode": "9537",
        "JobTitle": "operator, prism maker"
    },
    {
        "value": 27610,
        "NOCCode": "9537",
        "JobTitle": "operator, profile saw – jewellery manufacturing"
    },
    {
        "value": 27611,
        "NOCCode": "9537",
        "JobTitle": "operator, skate press"
    },
    {
        "value": 27612,
        "NOCCode": "9537",
        "JobTitle": "operator, tennis ball maker"
    },
    {
        "value": 27613,
        "NOCCode": "9537",
        "JobTitle": "optical element coater – non-prescription"
    },
    {
        "value": 27614,
        "NOCCode": "9537",
        "JobTitle": "optical glass etcher (non-prescription)"
    },
    {
        "value": 27615,
        "NOCCode": "9537",
        "JobTitle": "optical instrument assembler"
    },
    {
        "value": 27616,
        "NOCCode": "9537",
        "JobTitle": "optical instrument calibrator"
    },
    {
        "value": 27617,
        "NOCCode": "9537",
        "JobTitle": "optical instrument driller – non-prescription"
    },
    {
        "value": 27618,
        "NOCCode": "9537",
        "JobTitle": "optical instrument maker"
    },
    {
        "value": 27619,
        "NOCCode": "9537",
        "JobTitle": "optical lens edger, precision – non-prescription"
    },
    {
        "value": 27620,
        "NOCCode": "9537",
        "JobTitle": "optical lens grinder, precision – non-prescription"
    },
    {
        "value": 27621,
        "NOCCode": "9537",
        "JobTitle": "optical lens polisher, precision – non-prescription"
    },
    {
        "value": 27622,
        "NOCCode": "9537",
        "JobTitle": "optical mirror coater operator"
    },
    {
        "value": 27623,
        "NOCCode": "9537",
        "JobTitle": "ornament maker, hats"
    },
    {
        "value": 27624,
        "NOCCode": "9537",
        "JobTitle": "ornament setter"
    },
    {
        "value": 27625,
        "NOCCode": "9537",
        "JobTitle": "ornamental design caster"
    },
    {
        "value": 27626,
        "NOCCode": "9537",
        "JobTitle": "packaging tester"
    },
    {
        "value": 27627,
        "NOCCode": "9537",
        "JobTitle": "packaging tester and inspector"
    },
    {
        "value": 27628,
        "NOCCode": "9537",
        "JobTitle": "paint brush final assembler"
    },
    {
        "value": 27629,
        "NOCCode": "9537",
        "JobTitle": "paint roller cover trimmer"
    },
    {
        "value": 27630,
        "NOCCode": "9537",
        "JobTitle": "paintbrush-head maker"
    },
    {
        "value": 27631,
        "NOCCode": "9537",
        "JobTitle": "parachute stringer"
    },
    {
        "value": 27632,
        "NOCCode": "9537",
        "JobTitle": "parts assembler, jewellery"
    },
    {
        "value": 27633,
        "NOCCode": "9537",
        "JobTitle": "parts trimmer, footwear – footwear manufacturing"
    },
    {
        "value": 27634,
        "NOCCode": "9537",
        "JobTitle": "pearl earring maker"
    },
    {
        "value": 27635,
        "NOCCode": "9537",
        "JobTitle": "pearl maker"
    },
    {
        "value": 27636,
        "NOCCode": "9537",
        "JobTitle": "pearl stringer"
    },
    {
        "value": 27637,
        "NOCCode": "9537",
        "JobTitle": "pellet press operator – explosives manufacturing"
    },
    {
        "value": 27638,
        "NOCCode": "9537",
        "JobTitle": "pellet press tender"
    },
    {
        "value": 27639,
        "NOCCode": "9537",
        "JobTitle": "pen and pencil assembler"
    },
    {
        "value": 27640,
        "NOCCode": "9537",
        "JobTitle": "pen and pencil inspector"
    },
    {
        "value": 27641,
        "NOCCode": "9537",
        "JobTitle": "pen-and-pencil-knurling machine operator"
    },
    {
        "value": 27642,
        "NOCCode": "9537",
        "JobTitle": "pencil maker operator"
    },
    {
        "value": 27643,
        "NOCCode": "9537",
        "JobTitle": "pencil maker tender"
    },
    {
        "value": 27644,
        "NOCCode": "9537",
        "JobTitle": "pencil-eraser maker tender"
    },
    {
        "value": 27645,
        "NOCCode": "9537",
        "JobTitle": "pencil-eraser-making machine tender"
    },
    {
        "value": 27646,
        "NOCCode": "9537",
        "JobTitle": "pencil-making machine operator"
    },
    {
        "value": 27647,
        "NOCCode": "9537",
        "JobTitle": "pencil-making machine setter"
    },
    {
        "value": 27648,
        "NOCCode": "9537",
        "JobTitle": "pencil-making machine tender"
    },
    {
        "value": 27649,
        "NOCCode": "9537",
        "JobTitle": "pencil-pointing machine tender"
    },
    {
        "value": 27650,
        "NOCCode": "9537",
        "JobTitle": "permanent press sprayer machine"
    },
    {
        "value": 27651,
        "NOCCode": "9537",
        "JobTitle": "permanent press sprayer tender – garment and fabric products manufacturing"
    },
    {
        "value": 27652,
        "NOCCode": "9537",
        "JobTitle": "photographic equipment assembler"
    },
    {
        "value": 27653,
        "NOCCode": "9537",
        "JobTitle": "photographic equipment assembly inspector"
    },
    {
        "value": 27654,
        "NOCCode": "9537",
        "JobTitle": "photographic equipment inspector"
    },
    {
        "value": 27655,
        "NOCCode": "9537",
        "JobTitle": "piano action assembler"
    },
    {
        "value": 27656,
        "NOCCode": "9537",
        "JobTitle": "piano action inspector-adjuster"
    },
    {
        "value": 27657,
        "NOCCode": "9537",
        "JobTitle": "piano and organ bench worker"
    },
    {
        "value": 27658,
        "NOCCode": "9537",
        "JobTitle": "piano assembler"
    },
    {
        "value": 27659,
        "NOCCode": "9537",
        "JobTitle": "piano assembly inspector"
    },
    {
        "value": 27660,
        "NOCCode": "9537",
        "JobTitle": "piano back assembler"
    },
    {
        "value": 27661,
        "NOCCode": "9537",
        "JobTitle": "piano case assembler"
    },
    {
        "value": 27662,
        "NOCCode": "9537",
        "JobTitle": "piano inspector"
    },
    {
        "value": 27663,
        "NOCCode": "9537",
        "JobTitle": "piano mechanism assembler"
    },
    {
        "value": 27664,
        "NOCCode": "9537",
        "JobTitle": "piano subassembler"
    },
    {
        "value": 27665,
        "NOCCode": "9537",
        "JobTitle": "pill machine operator"
    },
    {
        "value": 27666,
        "NOCCode": "9537",
        "JobTitle": "pill maker operator"
    },
    {
        "value": 27667,
        "NOCCode": "9537",
        "JobTitle": "pill-coating machine operator"
    },
    {
        "value": 27668,
        "NOCCode": "9537",
        "JobTitle": "pleater, hand – garment manufacturing"
    },
    {
        "value": 27669,
        "NOCCode": "9537",
        "JobTitle": "pleating machine tender – garment manufacturing"
    },
    {
        "value": 27670,
        "NOCCode": "9537",
        "JobTitle": "polisher, eyeglass frames"
    },
    {
        "value": 27671,
        "NOCCode": "9537",
        "JobTitle": "polisher, jewellery"
    },
    {
        "value": 27672,
        "NOCCode": "9537",
        "JobTitle": "polisher, precision optical lenses – non-prescription"
    },
    {
        "value": 27673,
        "NOCCode": "9537",
        "JobTitle": "polisher, spectacle frames"
    },
    {
        "value": 27674,
        "NOCCode": "9537",
        "JobTitle": "pouncer – hat manufacturing"
    },
    {
        "value": 27675,
        "NOCCode": "9537",
        "JobTitle": "power screwdriver operator"
    },
    {
        "value": 27676,
        "NOCCode": "9537",
        "JobTitle": "precision instrument assembly inspector"
    },
    {
        "value": 27677,
        "NOCCode": "9537",
        "JobTitle": "precision lens edger – non-prescription"
    },
    {
        "value": 27678,
        "NOCCode": "9537",
        "JobTitle": "precision lens grinder – non-prescription"
    },
    {
        "value": 27679,
        "NOCCode": "9537",
        "JobTitle": "precision lens polisher – non-prescription"
    },
    {
        "value": 27680,
        "NOCCode": "9537",
        "JobTitle": "press operator, skates"
    },
    {
        "value": 27681,
        "NOCCode": "9537",
        "JobTitle": "press tender, pellets – explosives manufacturing"
    },
    {
        "value": 27682,
        "NOCCode": "9537",
        "JobTitle": "pressure switch inspector"
    },
    {
        "value": 27683,
        "NOCCode": "9537",
        "JobTitle": "pressure switch tester"
    },
    {
        "value": 27684,
        "NOCCode": "9537",
        "JobTitle": "primer-inserting machine tender"
    },
    {
        "value": 27685,
        "NOCCode": "9537",
        "JobTitle": "prism maker"
    },
    {
        "value": 27686,
        "NOCCode": "9537",
        "JobTitle": "prism maker operator"
    },
    {
        "value": 27687,
        "NOCCode": "9537",
        "JobTitle": "product trimmer"
    },
    {
        "value": 27688,
        "NOCCode": "9537",
        "JobTitle": "profile saw operator – jewellery"
    },
    {
        "value": 27689,
        "NOCCode": "9537",
        "JobTitle": "profile trimmer – jewellery"
    },
    {
        "value": 27690,
        "NOCCode": "9537",
        "JobTitle": "projector assembler"
    },
    {
        "value": 27691,
        "NOCCode": "9537",
        "JobTitle": "proof tester, shotguns"
    },
    {
        "value": 27692,
        "NOCCode": "9537",
        "JobTitle": "protective earmuffs assembler"
    },
    {
        "value": 27693,
        "NOCCode": "9537",
        "JobTitle": "purse maker, leather"
    },
    {
        "value": 27694,
        "NOCCode": "9537",
        "JobTitle": "pyrotechnics assembler"
    },
    {
        "value": 27695,
        "NOCCode": "9537",
        "JobTitle": "racquet stringer"
    },
    {
        "value": 27696,
        "NOCCode": "9537",
        "JobTitle": "reflective sign fabricator"
    },
    {
        "value": 27697,
        "NOCCode": "9537",
        "JobTitle": "ribbon inker"
    },
    {
        "value": 27698,
        "NOCCode": "9537",
        "JobTitle": "ribbon winder"
    },
    {
        "value": 27699,
        "NOCCode": "9537",
        "JobTitle": "ribbon-looping machine tender"
    },
    {
        "value": 27700,
        "NOCCode": "9537",
        "JobTitle": "ring mandrel worker"
    },
    {
        "value": 27701,
        "NOCCode": "9537",
        "JobTitle": "ring stamper"
    },
    {
        "value": 27702,
        "NOCCode": "9537",
        "JobTitle": "riveter, jewellery"
    },
    {
        "value": 27703,
        "NOCCode": "9537",
        "JobTitle": "roller bearing assembly inspector"
    },
    {
        "value": 27704,
        "NOCCode": "9537",
        "JobTitle": "rotary brush-making machine operator"
    },
    {
        "value": 27705,
        "NOCCode": "9537",
        "JobTitle": "safety earmuffs assembler"
    },
    {
        "value": 27706,
        "NOCCode": "9537",
        "JobTitle": "safety razor blade honer"
    },
    {
        "value": 27707,
        "NOCCode": "9537",
        "JobTitle": "safety razor blade inspector"
    },
    {
        "value": 27708,
        "NOCCode": "9537",
        "JobTitle": "safety razor blade maker"
    },
    {
        "value": 27709,
        "NOCCode": "9537",
        "JobTitle": "salvage inspector, ammunition"
    },
    {
        "value": 27710,
        "NOCCode": "9537",
        "JobTitle": "sample container maker, hand"
    },
    {
        "value": 27711,
        "NOCCode": "9537",
        "JobTitle": "scale assembler"
    },
    {
        "value": 27712,
        "NOCCode": "9537",
        "JobTitle": "scale inspector"
    },
    {
        "value": 27713,
        "NOCCode": "9537",
        "JobTitle": "scale maker"
    },
    {
        "value": 27714,
        "NOCCode": "9537",
        "JobTitle": "scrubber brush assembler"
    },
    {
        "value": 27715,
        "NOCCode": "9537",
        "JobTitle": "scrubber brush maker"
    },
    {
        "value": 27716,
        "NOCCode": "9537",
        "JobTitle": "seamless ball moulder"
    },
    {
        "value": 27717,
        "NOCCode": "9537",
        "JobTitle": "seat belt assembly inspector"
    },
    {
        "value": 27718,
        "NOCCode": "9537",
        "JobTitle": "setter, button machine"
    },
    {
        "value": 27719,
        "NOCCode": "9537",
        "JobTitle": "setter, button-covering machine"
    },
    {
        "value": 27720,
        "NOCCode": "9537",
        "JobTitle": "setter, ornaments"
    },
    {
        "value": 27721,
        "NOCCode": "9537",
        "JobTitle": "setter, pencil maker"
    },
    {
        "value": 27722,
        "NOCCode": "9537",
        "JobTitle": "set-up operator, flatware-buffing machine"
    },
    {
        "value": 27723,
        "NOCCode": "9537",
        "JobTitle": "shell assembler – explosives"
    },
    {
        "value": 27724,
        "NOCCode": "9537",
        "JobTitle": "shoe decoration assembler – footwear manufacturing"
    },
    {
        "value": 27725,
        "NOCCode": "9537",
        "JobTitle": "shoe decorator – footwear manufacturing"
    },
    {
        "value": 27726,
        "NOCCode": "9537",
        "JobTitle": "shoe parts fastener"
    },
    {
        "value": 27727,
        "NOCCode": "9537",
        "JobTitle": "shoe repairer, factory"
    },
    {
        "value": 27728,
        "NOCCode": "9537",
        "JobTitle": "shoe stitcher, hand"
    },
    {
        "value": 27729,
        "NOCCode": "9537",
        "JobTitle": "shoelace-tipping machine tender"
    },
    {
        "value": 27730,
        "NOCCode": "9537",
        "JobTitle": "shoe-trimming machine operator – footwear manufacturing"
    },
    {
        "value": 27731,
        "NOCCode": "9537",
        "JobTitle": "shotgun proof tester"
    },
    {
        "value": 27732,
        "NOCCode": "9537",
        "JobTitle": "shower door and panel assembler"
    },
    {
        "value": 27733,
        "NOCCode": "9537",
        "JobTitle": "shrinker, hat cones"
    },
    {
        "value": 27734,
        "NOCCode": "9537",
        "JobTitle": "sign maker"
    },
    {
        "value": 27735,
        "NOCCode": "9537",
        "JobTitle": "silverware buffer"
    },
    {
        "value": 27736,
        "NOCCode": "9537",
        "JobTitle": "sizing machine operator – fabric"
    },
    {
        "value": 27737,
        "NOCCode": "9537",
        "JobTitle": "skate maker"
    },
    {
        "value": 27738,
        "NOCCode": "9537",
        "JobTitle": "skate press operator"
    },
    {
        "value": 27739,
        "NOCCode": "9537",
        "JobTitle": "ski maker"
    },
    {
        "value": 27740,
        "NOCCode": "9537",
        "JobTitle": "skiver, machine – leather products"
    },
    {
        "value": 27741,
        "NOCCode": "9537",
        "JobTitle": "sleeping bag finisher"
    },
    {
        "value": 27742,
        "NOCCode": "9537",
        "JobTitle": "slide fastener chain machine tender"
    },
    {
        "value": 27743,
        "NOCCode": "9537",
        "JobTitle": "slide fastener machine operator"
    },
    {
        "value": 27744,
        "NOCCode": "9537",
        "JobTitle": "slide fastener machine tender (except plastic products)"
    },
    {
        "value": 27745,
        "NOCCode": "9537",
        "JobTitle": "slide-fastener-cutting machine operator"
    },
    {
        "value": 27746,
        "NOCCode": "9537",
        "JobTitle": "slitter operator, asphalt roofing materials"
    },
    {
        "value": 27747,
        "NOCCode": "9537",
        "JobTitle": "slitter tender, knit tubing"
    },
    {
        "value": 27748,
        "NOCCode": "9537",
        "JobTitle": "small arms tester"
    },
    {
        "value": 27749,
        "NOCCode": "9537",
        "JobTitle": "snowshoe weaver"
    },
    {
        "value": 27750,
        "NOCCode": "9537",
        "JobTitle": "soap and toilet goods preparations inspector"
    },
    {
        "value": 27751,
        "NOCCode": "9537",
        "JobTitle": "solderer, jewellery"
    },
    {
        "value": 27752,
        "NOCCode": "9537",
        "JobTitle": "sole-moulding machine operator – footwear manufacturing"
    },
    {
        "value": 27753,
        "NOCCode": "9537",
        "JobTitle": "sole-moulding machine tender – footwear manufacturing"
    },
    {
        "value": 27754,
        "NOCCode": "9537",
        "JobTitle": "solenoid valve assembler"
    },
    {
        "value": 27755,
        "NOCCode": "9537",
        "JobTitle": "spectacle frame polisher"
    },
    {
        "value": 27756,
        "NOCCode": "9537",
        "JobTitle": "spoked wheel assembler"
    },
    {
        "value": 27757,
        "NOCCode": "9537",
        "JobTitle": "sporting goods grip-wrapping machine tender"
    },
    {
        "value": 27758,
        "NOCCode": "9537",
        "JobTitle": "sports ball inspector and repairer"
    },
    {
        "value": 27759,
        "NOCCode": "9537",
        "JobTitle": "sports ball moulder"
    },
    {
        "value": 27760,
        "NOCCode": "9537",
        "JobTitle": "sports ball moulder press operator"
    },
    {
        "value": 27761,
        "NOCCode": "9537",
        "JobTitle": "sports helmet assembler"
    },
    {
        "value": 27762,
        "NOCCode": "9537",
        "JobTitle": "sprayer tender, permanent press – garment and fabric products manufacturing"
    },
    {
        "value": 27763,
        "NOCCode": "9537",
        "JobTitle": "staking machine tender"
    },
    {
        "value": 27764,
        "NOCCode": "9537",
        "JobTitle": "stamp pad maker"
    },
    {
        "value": 27765,
        "NOCCode": "9537",
        "JobTitle": "stamper, rings"
    },
    {
        "value": 27766,
        "NOCCode": "9537",
        "JobTitle": "stapling machine tender"
    },
    {
        "value": 27767,
        "NOCCode": "9537",
        "JobTitle": "stapling machine tender, box ends"
    },
    {
        "value": 27768,
        "NOCCode": "9537",
        "JobTitle": "sterilizer operator – surgical equipment manufacturing"
    },
    {
        "value": 27769,
        "NOCCode": "9537",
        "JobTitle": "stitcher, brooms"
    },
    {
        "value": 27770,
        "NOCCode": "9537",
        "JobTitle": "string maker, archery bows"
    },
    {
        "value": 27771,
        "NOCCode": "9537",
        "JobTitle": "stringer, parachutes"
    },
    {
        "value": 27772,
        "NOCCode": "9537",
        "JobTitle": "stringer, racquets"
    },
    {
        "value": 27773,
        "NOCCode": "9537",
        "JobTitle": "stringing machine feeder"
    },
    {
        "value": 27774,
        "NOCCode": "9537",
        "JobTitle": "stuffing machine operator"
    },
    {
        "value": 27775,
        "NOCCode": "9537",
        "JobTitle": "stuffing machine tender"
    },
    {
        "value": 27776,
        "NOCCode": "9537",
        "JobTitle": "stuffing machine tender – toys"
    },
    {
        "value": 27777,
        "NOCCode": "9537",
        "JobTitle": "subassembler, pianos"
    },
    {
        "value": 27778,
        "NOCCode": "9537",
        "JobTitle": "surfacer, acoustical tiles"
    },
    {
        "value": 27779,
        "NOCCode": "9537",
        "JobTitle": "suture material winder"
    },
    {
        "value": 27780,
        "NOCCode": "9537",
        "JobTitle": "tablet maker"
    },
    {
        "value": 27781,
        "NOCCode": "9537",
        "JobTitle": "tablet-coating machine operator"
    },
    {
        "value": 27782,
        "NOCCode": "9537",
        "JobTitle": "tablet-making machine tender"
    },
    {
        "value": 27783,
        "NOCCode": "9537",
        "JobTitle": "tacker – footwear manufacturing"
    },
    {
        "value": 27784,
        "NOCCode": "9537",
        "JobTitle": "tarpaulin finisher"
    },
    {
        "value": 27785,
        "NOCCode": "9537",
        "JobTitle": "tender, chip ice maker"
    },
    {
        "value": 27786,
        "NOCCode": "9537",
        "JobTitle": "tender, crimping machine – leather products manufacturing"
    },
    {
        "value": 27787,
        "NOCCode": "9537",
        "JobTitle": "tender, cutting machine – textile products manufacturing"
    },
    {
        "value": 27788,
        "NOCCode": "9537",
        "JobTitle": "tender, eyelet-punching machine"
    },
    {
        "value": 27789,
        "NOCCode": "9537",
        "JobTitle": "tender, fibre strap machine"
    },
    {
        "value": 27790,
        "NOCCode": "9537",
        "JobTitle": "tender, fishing rod guide winder"
    },
    {
        "value": 27791,
        "NOCCode": "9537",
        "JobTitle": "tender, foiling machine – explosives manufacturing"
    },
    {
        "value": 27792,
        "NOCCode": "9537",
        "JobTitle": "tender, handle and vent machine – mattress manufacturing"
    },
    {
        "value": 27793,
        "NOCCode": "9537",
        "JobTitle": "tender, hat-brim-flexing machine – hat manufacturing"
    },
    {
        "value": 27794,
        "NOCCode": "9537",
        "JobTitle": "tender, ice-cutting machine"
    },
    {
        "value": 27795,
        "NOCCode": "9537",
        "JobTitle": "tender, label-cutting machine – garment and fabric products manufacturing"
    },
    {
        "value": 27796,
        "NOCCode": "9537",
        "JobTitle": "tender, pleating machine – garment manufacturing"
    },
    {
        "value": 27797,
        "NOCCode": "9537",
        "JobTitle": "tender, primer-inserting machine – explosives manufacturing"
    },
    {
        "value": 27798,
        "NOCCode": "9537",
        "JobTitle": "tender, ribbon-looping machine"
    },
    {
        "value": 27799,
        "NOCCode": "9537",
        "JobTitle": "tender, shoelace-tipping machine"
    },
    {
        "value": 27800,
        "NOCCode": "9537",
        "JobTitle": "tender, sole-moulding machine – footwear manufacturing"
    },
    {
        "value": 27801,
        "NOCCode": "9537",
        "JobTitle": "tender, twisting machine"
    },
    {
        "value": 27802,
        "NOCCode": "9537",
        "JobTitle": "tender, ultrasonic cleaning machine"
    },
    {
        "value": 27803,
        "NOCCode": "9537",
        "JobTitle": "tender, winding machine – other products manufacturing"
    },
    {
        "value": 27804,
        "NOCCode": "9537",
        "JobTitle": "tennis ball maker operator"
    },
    {
        "value": 27805,
        "NOCCode": "9537",
        "JobTitle": "tennis ball maker tender"
    },
    {
        "value": 27806,
        "NOCCode": "9537",
        "JobTitle": "tennis net maker, hand"
    },
    {
        "value": 27807,
        "NOCCode": "9537",
        "JobTitle": "tent finisher"
    },
    {
        "value": 27808,
        "NOCCode": "9537",
        "JobTitle": "tent maker"
    },
    {
        "value": 27809,
        "NOCCode": "9537",
        "JobTitle": "tent maker, canvas"
    },
    {
        "value": 27810,
        "NOCCode": "9537",
        "JobTitle": "tester and inspector, packaging"
    },
    {
        "value": 27811,
        "NOCCode": "9537",
        "JobTitle": "tester, ball bearings"
    },
    {
        "value": 27812,
        "NOCCode": "9537",
        "JobTitle": "tester, packaging"
    },
    {
        "value": 27813,
        "NOCCode": "9537",
        "JobTitle": "tester, pressure switches"
    },
    {
        "value": 27814,
        "NOCCode": "9537",
        "JobTitle": "tester, small arms"
    },
    {
        "value": 27815,
        "NOCCode": "9537",
        "JobTitle": "tester, typewriter assembly"
    },
    {
        "value": 27816,
        "NOCCode": "9537",
        "JobTitle": "textile-bags-turner operator"
    },
    {
        "value": 27817,
        "NOCCode": "9537",
        "JobTitle": "thermometric systems capillary tube charger"
    },
    {
        "value": 27818,
        "NOCCode": "9537",
        "JobTitle": "Thermos bottle assembler"
    },
    {
        "value": 27819,
        "NOCCode": "9537",
        "JobTitle": "thermostat and pressure switch calibrator"
    },
    {
        "value": 27820,
        "NOCCode": "9537",
        "JobTitle": "tinsel maker operator"
    },
    {
        "value": 27821,
        "NOCCode": "9537",
        "JobTitle": "tool and cutlery assembly inspector"
    },
    {
        "value": 27822,
        "NOCCode": "9537",
        "JobTitle": "toothbrush maker operator"
    },
    {
        "value": 27823,
        "NOCCode": "9537",
        "JobTitle": "toy assembler"
    },
    {
        "value": 27824,
        "NOCCode": "9537",
        "JobTitle": "toy inspector"
    },
    {
        "value": 27825,
        "NOCCode": "9537",
        "JobTitle": "toy maker"
    },
    {
        "value": 27826,
        "NOCCode": "9537",
        "JobTitle": "toy-stuffing machine operator"
    },
    {
        "value": 27827,
        "NOCCode": "9537",
        "JobTitle": "tracer-bullet-loading machine operator"
    },
    {
        "value": 27828,
        "NOCCode": "9537",
        "JobTitle": "trimmer, footwear parts – footwear manufacturing"
    },
    {
        "value": 27829,
        "NOCCode": "9537",
        "JobTitle": "trimmer, paint roller covers"
    },
    {
        "value": 27830,
        "NOCCode": "9537",
        "JobTitle": "trimmer, products"
    },
    {
        "value": 27831,
        "NOCCode": "9537",
        "JobTitle": "trimming machine operator, shoes – footwear manufacturing"
    },
    {
        "value": 27832,
        "NOCCode": "9537",
        "JobTitle": "trophy assembler"
    },
    {
        "value": 27833,
        "NOCCode": "9537",
        "JobTitle": "truer, clock and watch hairsprings"
    },
    {
        "value": 27834,
        "NOCCode": "9537",
        "JobTitle": "truer, hairsprings"
    },
    {
        "value": 27835,
        "NOCCode": "9537",
        "JobTitle": "tube-cutting machine tender"
    },
    {
        "value": 27836,
        "NOCCode": "9537",
        "JobTitle": "turner, jewellery"
    },
    {
        "value": 27837,
        "NOCCode": "9537",
        "JobTitle": "turning machine operator, textile bags"
    },
    {
        "value": 27838,
        "NOCCode": "9537",
        "JobTitle": "twisted wire brush maker"
    },
    {
        "value": 27839,
        "NOCCode": "9537",
        "JobTitle": "twisting machine tender"
    },
    {
        "value": 27840,
        "NOCCode": "9537",
        "JobTitle": "typewriter assembly tester"
    },
    {
        "value": 27841,
        "NOCCode": "9537",
        "JobTitle": "typewriter tester"
    },
    {
        "value": 27842,
        "NOCCode": "9537",
        "JobTitle": "ultrasonic cleaning machine tender"
    },
    {
        "value": 27843,
        "NOCCode": "9537",
        "JobTitle": "umbrella inspector"
    },
    {
        "value": 27844,
        "NOCCode": "9537",
        "JobTitle": "umbrella maker"
    },
    {
        "value": 27845,
        "NOCCode": "9537",
        "JobTitle": "vacuum bottle assembler"
    },
    {
        "value": 27846,
        "NOCCode": "9537",
        "JobTitle": "Venetian blind assembler"
    },
    {
        "value": 27847,
        "NOCCode": "9537",
        "JobTitle": "Venetian blind maker"
    },
    {
        "value": 27848,
        "NOCCode": "9537",
        "JobTitle": "wallpaper inspector"
    },
    {
        "value": 27849,
        "NOCCode": "9537",
        "JobTitle": "watch assembler"
    },
    {
        "value": 27850,
        "NOCCode": "9537",
        "JobTitle": "watch assembly inspector"
    },
    {
        "value": 27851,
        "NOCCode": "9537",
        "JobTitle": "watch balance wheel adjuster"
    },
    {
        "value": 27852,
        "NOCCode": "9537",
        "JobTitle": "watch crystal edge grinder"
    },
    {
        "value": 27853,
        "NOCCode": "9537",
        "JobTitle": "watch escapement put-in-beat adjuster"
    },
    {
        "value": 27854,
        "NOCCode": "9537",
        "JobTitle": "watch final assembly inspector"
    },
    {
        "value": 27855,
        "NOCCode": "9537",
        "JobTitle": "watch inspector"
    },
    {
        "value": 27856,
        "NOCCode": "9537",
        "JobTitle": "watch movement tester"
    },
    {
        "value": 27857,
        "NOCCode": "9537",
        "JobTitle": "waterproofer"
    },
    {
        "value": 27858,
        "NOCCode": "9537",
        "JobTitle": "weaver, snowshoes"
    },
    {
        "value": 27859,
        "NOCCode": "9537",
        "JobTitle": "wet suit maker"
    },
    {
        "value": 27860,
        "NOCCode": "9537",
        "JobTitle": "whisk assembler"
    },
    {
        "value": 27861,
        "NOCCode": "9537",
        "JobTitle": "wig hair preparer"
    },
    {
        "value": 27862,
        "NOCCode": "9537",
        "JobTitle": "wig maker"
    },
    {
        "value": 27863,
        "NOCCode": "9537",
        "JobTitle": "wig maker, mannequins"
    },
    {
        "value": 27864,
        "NOCCode": "9537",
        "JobTitle": "winder, insulating tubes"
    },
    {
        "value": 27865,
        "NOCCode": "9537",
        "JobTitle": "winder, ribbons"
    },
    {
        "value": 27866,
        "NOCCode": "9537",
        "JobTitle": "winder, suture material"
    },
    {
        "value": 27867,
        "NOCCode": "9537",
        "JobTitle": "winding machine tender – other products manufacturing"
    },
    {
        "value": 27868,
        "NOCCode": "9537",
        "JobTitle": "window and screen assembler"
    },
    {
        "value": 27869,
        "NOCCode": "9537",
        "JobTitle": "window assembler, aluminum"
    },
    {
        "value": 27870,
        "NOCCode": "9537",
        "JobTitle": "window blind mounter"
    },
    {
        "value": 27871,
        "NOCCode": "9537",
        "JobTitle": "window shade mounter"
    },
    {
        "value": 27872,
        "NOCCode": "9537",
        "JobTitle": "wire drawer, filigree – jewellery manufacturing"
    },
    {
        "value": 27873,
        "NOCCode": "9537",
        "JobTitle": "zipper assembler"
    },
    {
        "value": 27874,
        "NOCCode": "9537",
        "JobTitle": "zipper chain assembler"
    },
    {
        "value": 27875,
        "NOCCode": "9537",
        "JobTitle": "zipper chain machine tender"
    },
    {
        "value": 27876,
        "NOCCode": "9537",
        "JobTitle": "zipper inspector"
    },
    {
        "value": 27877,
        "NOCCode": "9537",
        "JobTitle": "zipper machine operator"
    },
    {
        "value": 27878,
        "NOCCode": "9537",
        "JobTitle": "zipper machine tender (except plastic products)"
    },
    {
        "value": 27879,
        "NOCCode": "9537",
        "JobTitle": "zipper-cutting machine operator"
    },
    {
        "value": 27880,
        "NOCCode": "9611",
        "JobTitle": "abrasive reclaimer – mineral products processing"
    },
    {
        "value": 27881,
        "NOCCode": "9611",
        "JobTitle": "acid conditioning worker – primary metal processing"
    },
    {
        "value": 27882,
        "NOCCode": "9611",
        "JobTitle": "alloy mixer – primary metal processing"
    },
    {
        "value": 27883,
        "NOCCode": "9611",
        "JobTitle": "alloy processor – primary metal processing"
    },
    {
        "value": 27884,
        "NOCCode": "9611",
        "JobTitle": "alumina clarifier helper"
    },
    {
        "value": 27885,
        "NOCCode": "9611",
        "JobTitle": "alumina recovery helper"
    },
    {
        "value": 27886,
        "NOCCode": "9611",
        "JobTitle": "aluminum sheet separator"
    },
    {
        "value": 27887,
        "NOCCode": "9611",
        "JobTitle": "annealer helper – primary metal processing"
    },
    {
        "value": 27888,
        "NOCCode": "9611",
        "JobTitle": "asbestos mould stripper"
    },
    {
        "value": 27889,
        "NOCCode": "9611",
        "JobTitle": "asbestos moulder"
    },
    {
        "value": 27890,
        "NOCCode": "9611",
        "JobTitle": "asbestos shingle floor hand"
    },
    {
        "value": 27891,
        "NOCCode": "9611",
        "JobTitle": "asbestos worker"
    },
    {
        "value": 27892,
        "NOCCode": "9611",
        "JobTitle": "asbestos worker – mineral processing"
    },
    {
        "value": 27893,
        "NOCCode": "9611",
        "JobTitle": "asbestosboard sander"
    },
    {
        "value": 27894,
        "NOCCode": "9611",
        "JobTitle": "asbestosboard shearer"
    },
    {
        "value": 27895,
        "NOCCode": "9611",
        "JobTitle": "atomizer operator – primary metal and mineral products processing"
    },
    {
        "value": 27896,
        "NOCCode": "9611",
        "JobTitle": "attendant, filtering – primary metal and mineral products processing"
    },
    {
        "value": 27897,
        "NOCCode": "9611",
        "JobTitle": "automatic scrap washer operator"
    },
    {
        "value": 27898,
        "NOCCode": "9611",
        "JobTitle": "baghouse attendant – mineral processing"
    },
    {
        "value": 27899,
        "NOCCode": "9611",
        "JobTitle": "balcony labourer – glass manufacturing"
    },
    {
        "value": 27900,
        "NOCCode": "9611",
        "JobTitle": "balcony worker – glass manufacturing"
    },
    {
        "value": 27901,
        "NOCCode": "9611",
        "JobTitle": "baler, scrap metal – primary metal processing"
    },
    {
        "value": 27902,
        "NOCCode": "9611",
        "JobTitle": "ball mill helper – mineral products processing"
    },
    {
        "value": 27903,
        "NOCCode": "9611",
        "JobTitle": "ball mill labourer – mineral products processing"
    },
    {
        "value": 27904,
        "NOCCode": "9611",
        "JobTitle": "ball mill worker – mineral products processing"
    },
    {
        "value": 27905,
        "NOCCode": "9611",
        "JobTitle": "billet helper – primary metal processing"
    },
    {
        "value": 27906,
        "NOCCode": "9611",
        "JobTitle": "billet mill helper – primary metal processing"
    },
    {
        "value": 27907,
        "NOCCode": "9611",
        "JobTitle": "billet pusher"
    },
    {
        "value": 27908,
        "NOCCode": "9611",
        "JobTitle": "billet rolling mill helper – primary metal processing"
    },
    {
        "value": 27909,
        "NOCCode": "9611",
        "JobTitle": "bisque placer – mineral products manufacturing"
    },
    {
        "value": 27910,
        "NOCCode": "9611",
        "JobTitle": "blast furnace helper"
    },
    {
        "value": 27911,
        "NOCCode": "9611",
        "JobTitle": "brick and tile batch mixer"
    },
    {
        "value": 27912,
        "NOCCode": "9611",
        "JobTitle": "brick and tile kiln cleaner"
    },
    {
        "value": 27913,
        "NOCCode": "9611",
        "JobTitle": "buggy operator – primary metal processing"
    },
    {
        "value": 27914,
        "NOCCode": "9611",
        "JobTitle": "busher – primary metal processing"
    },
    {
        "value": 27915,
        "NOCCode": "9611",
        "JobTitle": "cadmium leach helper"
    },
    {
        "value": 27916,
        "NOCCode": "9611",
        "JobTitle": "carnallite flux maker"
    },
    {
        "value": 27917,
        "NOCCode": "9611",
        "JobTitle": "cartridge flux maker"
    },
    {
        "value": 27918,
        "NOCCode": "9611",
        "JobTitle": "casting helper"
    },
    {
        "value": 27919,
        "NOCCode": "9611",
        "JobTitle": "casting house labourer"
    },
    {
        "value": 27920,
        "NOCCode": "9611",
        "JobTitle": "casting machine helper"
    },
    {
        "value": 27921,
        "NOCCode": "9611",
        "JobTitle": "casting wheel operator helper"
    },
    {
        "value": 27922,
        "NOCCode": "9611",
        "JobTitle": "castings cleaner – primary metal and mineral products processing"
    },
    {
        "value": 27923,
        "NOCCode": "9611",
        "JobTitle": "castings trimmer"
    },
    {
        "value": 27924,
        "NOCCode": "9611",
        "JobTitle": "cathode loader"
    },
    {
        "value": 27925,
        "NOCCode": "9611",
        "JobTitle": "chalk extruder"
    },
    {
        "value": 27926,
        "NOCCode": "9611",
        "JobTitle": "chalk moulder – mineral products processing"
    },
    {
        "value": 27927,
        "NOCCode": "9611",
        "JobTitle": "charge weigher – primary metal processing"
    },
    {
        "value": 27928,
        "NOCCode": "9611",
        "JobTitle": "charger helper – primary metal processing"
    },
    {
        "value": 27929,
        "NOCCode": "9611",
        "JobTitle": "charger, cupola – primary metal and mineral processing"
    },
    {
        "value": 27930,
        "NOCCode": "9611",
        "JobTitle": "charger, furnace – primary metal processing"
    },
    {
        "value": 27931,
        "NOCCode": "9611",
        "JobTitle": "cladding assembler – primary metal processing"
    },
    {
        "value": 27932,
        "NOCCode": "9611",
        "JobTitle": "classifier helper – primary metal and mineral products processing"
    },
    {
        "value": 27933,
        "NOCCode": "9611",
        "JobTitle": "clay press worker"
    },
    {
        "value": 27934,
        "NOCCode": "9611",
        "JobTitle": "clay products finisher"
    },
    {
        "value": 27935,
        "NOCCode": "9611",
        "JobTitle": "cleaner, brick and tile kilns"
    },
    {
        "value": 27936,
        "NOCCode": "9611",
        "JobTitle": "cleaner, castings – primary metal and mineral products processing"
    },
    {
        "value": 27937,
        "NOCCode": "9611",
        "JobTitle": "cleaner, soaking pit bottom – primary metal processing"
    },
    {
        "value": 27938,
        "NOCCode": "9611",
        "JobTitle": "coil bander – primary metal processing"
    },
    {
        "value": 27939,
        "NOCCode": "9611",
        "JobTitle": "coke loader – primary metal processing"
    },
    {
        "value": 27940,
        "NOCCode": "9611",
        "JobTitle": "coke oven heater helper"
    },
    {
        "value": 27941,
        "NOCCode": "9611",
        "JobTitle": "cold mill feeder – primary metal processing"
    },
    {
        "value": 27942,
        "NOCCode": "9611",
        "JobTitle": "cold roll catcher – primary metal processing"
    },
    {
        "value": 27943,
        "NOCCode": "9611",
        "JobTitle": "cold rolling mill feeder – primary metal processing"
    },
    {
        "value": 27944,
        "NOCCode": "9611",
        "JobTitle": "conveyor labourer – primary metal and mineral products processing"
    },
    {
        "value": 27945,
        "NOCCode": "9611",
        "JobTitle": "conveyor operator – primary metal and mineral products processing"
    },
    {
        "value": 27946,
        "NOCCode": "9611",
        "JobTitle": "copper dust-collector worker"
    },
    {
        "value": 27947,
        "NOCCode": "9611",
        "JobTitle": "core room labourer – foundry"
    },
    {
        "value": 27948,
        "NOCCode": "9611",
        "JobTitle": "core shop labourer – foundry"
    },
    {
        "value": 27949,
        "NOCCode": "9611",
        "JobTitle": "Cottrell precipitator helper – primary metal processing"
    },
    {
        "value": 27950,
        "NOCCode": "9611",
        "JobTitle": "crushing operations helper – mineral products processing"
    },
    {
        "value": 27951,
        "NOCCode": "9611",
        "JobTitle": "cupola charger – primary metal and mineral processing"
    },
    {
        "value": 27952,
        "NOCCode": "9611",
        "JobTitle": "cupola furnace helper"
    },
    {
        "value": 27953,
        "NOCCode": "9611",
        "JobTitle": "cupola tapper"
    },
    {
        "value": 27954,
        "NOCCode": "9611",
        "JobTitle": "depiler helper – primary metal processing"
    },
    {
        "value": 27955,
        "NOCCode": "9611",
        "JobTitle": "drawer helper – primary metal processing"
    },
    {
        "value": 27956,
        "NOCCode": "9611",
        "JobTitle": "dross skimmer – primary metal and mineral products processing"
    },
    {
        "value": 27957,
        "NOCCode": "9611",
        "JobTitle": "dryer worker – primary metal processing"
    },
    {
        "value": 27958,
        "NOCCode": "9611",
        "JobTitle": "dust remover – primary metal processing"
    },
    {
        "value": 27959,
        "NOCCode": "9611",
        "JobTitle": "dust-collector labourer – primary metal processing"
    },
    {
        "value": 27960,
        "NOCCode": "9611",
        "JobTitle": "electrolytic refiner helper – primary metal and mineral products processing"
    },
    {
        "value": 27961,
        "NOCCode": "9611",
        "JobTitle": "extruder helper – primary metal processing"
    },
    {
        "value": 27962,
        "NOCCode": "9611",
        "JobTitle": "extruder tender – mineral products processing"
    },
    {
        "value": 27963,
        "NOCCode": "9611",
        "JobTitle": "extrusion helper – primary metal processing"
    },
    {
        "value": 27964,
        "NOCCode": "9611",
        "JobTitle": "feeder, cold mill – primary metal processing"
    },
    {
        "value": 27965,
        "NOCCode": "9611",
        "JobTitle": "feeder, cold rolling mill – primary metal processing"
    },
    {
        "value": 27966,
        "NOCCode": "9611",
        "JobTitle": "feeder, furnace – primary metal and mineral products processing"
    },
    {
        "value": 27967,
        "NOCCode": "9611",
        "JobTitle": "fettler – clay products manufacturing"
    },
    {
        "value": 27968,
        "NOCCode": "9611",
        "JobTitle": "fibreglass oven helper"
    },
    {
        "value": 27969,
        "NOCCode": "9611",
        "JobTitle": "filter leaves cleaner – primary metal and mineral products processing"
    },
    {
        "value": 27970,
        "NOCCode": "9611",
        "JobTitle": "filtering attendant – primary metal and mineral products processing"
    },
    {
        "value": 27971,
        "NOCCode": "9611",
        "JobTitle": "finisher, clay products"
    },
    {
        "value": 27972,
        "NOCCode": "9611",
        "JobTitle": "flotation cell helper – primary metal and mineral products processing"
    },
    {
        "value": 27973,
        "NOCCode": "9611",
        "JobTitle": "flue dust labourer – primary metal and mineral products processing"
    },
    {
        "value": 27974,
        "NOCCode": "9611",
        "JobTitle": "fluorspar flux maker – primary metal processing"
    },
    {
        "value": 27975,
        "NOCCode": "9611",
        "JobTitle": "foil roller helper – primary metal processing"
    },
    {
        "value": 27976,
        "NOCCode": "9611",
        "JobTitle": "foil separator – primary metal processing"
    },
    {
        "value": 27977,
        "NOCCode": "9611",
        "JobTitle": "foundry helper – primary metal processing"
    },
    {
        "value": 27978,
        "NOCCode": "9611",
        "JobTitle": "foundry labourer"
    },
    {
        "value": 27979,
        "NOCCode": "9611",
        "JobTitle": "foundry worker – primary metal processing"
    },
    {
        "value": 27980,
        "NOCCode": "9611",
        "JobTitle": "furnace charger – primary metal processing"
    },
    {
        "value": 27981,
        "NOCCode": "9611",
        "JobTitle": "furnace cleaner – primary metal and mineral products processing"
    },
    {
        "value": 27982,
        "NOCCode": "9611",
        "JobTitle": "furnace feeder – primary metal and mineral products processing"
    },
    {
        "value": 27983,
        "NOCCode": "9611",
        "JobTitle": "furnace helper"
    },
    {
        "value": 27984,
        "NOCCode": "9611",
        "JobTitle": "furnace loader – primary metal and mineral products processing"
    },
    {
        "value": 27985,
        "NOCCode": "9611",
        "JobTitle": "furnace skimmer – primary metal and mineral products processing"
    },
    {
        "value": 27986,
        "NOCCode": "9611",
        "JobTitle": "furnace tapper – primary metal processing"
    },
    {
        "value": 27987,
        "NOCCode": "9611",
        "JobTitle": "glass packer"
    },
    {
        "value": 27988,
        "NOCCode": "9611",
        "JobTitle": "glass-forming machine operator helper"
    },
    {
        "value": 27989,
        "NOCCode": "9611",
        "JobTitle": "gravel screener"
    },
    {
        "value": 27990,
        "NOCCode": "9611",
        "JobTitle": "heater helper – primary metal processing"
    },
    {
        "value": 27991,
        "NOCCode": "9611",
        "JobTitle": "helper – primary metal and mineral products processing"
    },
    {
        "value": 27992,
        "NOCCode": "9611",
        "JobTitle": "helper, alumina clarifier"
    },
    {
        "value": 27993,
        "NOCCode": "9611",
        "JobTitle": "helper, alumina recovery"
    },
    {
        "value": 27994,
        "NOCCode": "9611",
        "JobTitle": "helper, annealer – primary metal processing"
    },
    {
        "value": 27995,
        "NOCCode": "9611",
        "JobTitle": "helper, ball mill – mineral products processing"
    },
    {
        "value": 27996,
        "NOCCode": "9611",
        "JobTitle": "helper, billet mill – primary metal processing"
    },
    {
        "value": 27997,
        "NOCCode": "9611",
        "JobTitle": "helper, billet rolling mill – primary metal processing"
    },
    {
        "value": 27998,
        "NOCCode": "9611",
        "JobTitle": "helper, billets – primary metal processing"
    },
    {
        "value": 27999,
        "NOCCode": "9611",
        "JobTitle": "helper, blast furnace"
    },
    {
        "value": 28000,
        "NOCCode": "9611",
        "JobTitle": "helper, casting"
    },
    {
        "value": 28001,
        "NOCCode": "9611",
        "JobTitle": "helper, casting machine"
    },
    {
        "value": 28002,
        "NOCCode": "9611",
        "JobTitle": "helper, casting wheel operator"
    },
    {
        "value": 28003,
        "NOCCode": "9611",
        "JobTitle": "helper, charger – primary metal processing"
    },
    {
        "value": 28004,
        "NOCCode": "9611",
        "JobTitle": "helper, classifier – primary metal and metal products processing"
    },
    {
        "value": 28005,
        "NOCCode": "9611",
        "JobTitle": "helper, coke oven heater"
    },
    {
        "value": 28006,
        "NOCCode": "9611",
        "JobTitle": "helper, Cottrell precipitator – primary metal processing"
    },
    {
        "value": 28007,
        "NOCCode": "9611",
        "JobTitle": "helper, crushing operations – mineral products processing"
    },
    {
        "value": 28008,
        "NOCCode": "9611",
        "JobTitle": "helper, cupola furnace"
    },
    {
        "value": 28009,
        "NOCCode": "9611",
        "JobTitle": "helper, drawer – primary metal processing"
    },
    {
        "value": 28010,
        "NOCCode": "9611",
        "JobTitle": "helper, extruder – primary metal processing"
    },
    {
        "value": 28011,
        "NOCCode": "9611",
        "JobTitle": "helper, extrusion – primary metal processing"
    },
    {
        "value": 28012,
        "NOCCode": "9611",
        "JobTitle": "helper, fibreglass oven charger"
    },
    {
        "value": 28013,
        "NOCCode": "9611",
        "JobTitle": "helper, flotation cell – primary metal and mineral products processing"
    },
    {
        "value": 28014,
        "NOCCode": "9611",
        "JobTitle": "helper, foundry – primary metal processing"
    },
    {
        "value": 28015,
        "NOCCode": "9611",
        "JobTitle": "helper, glass-forming machine operator"
    },
    {
        "value": 28016,
        "NOCCode": "9611",
        "JobTitle": "helper, jigger operator – mineral products processing"
    },
    {
        "value": 28017,
        "NOCCode": "9611",
        "JobTitle": "helper, kiln operator – mineral products manufacturing"
    },
    {
        "value": 28018,
        "NOCCode": "9611",
        "JobTitle": "helper, lime kiln – mineral products processing"
    },
    {
        "value": 28019,
        "NOCCode": "9611",
        "JobTitle": "helper, lime kiln operator – mineral products processing"
    },
    {
        "value": 28020,
        "NOCCode": "9611",
        "JobTitle": "helper, melter – primary metal and mineral products processing"
    },
    {
        "value": 28021,
        "NOCCode": "9611",
        "JobTitle": "helper, metal pourer – primary metal processing"
    },
    {
        "value": 28022,
        "NOCCode": "9611",
        "JobTitle": "helper, metal reclaimer – primary metal processing"
    },
    {
        "value": 28023,
        "NOCCode": "9611",
        "JobTitle": "helper, metal salvager – primary metal processing"
    },
    {
        "value": 28024,
        "NOCCode": "9611",
        "JobTitle": "helper, molten metal mixer – primary metal processing"
    },
    {
        "value": 28025,
        "NOCCode": "9611",
        "JobTitle": "helper, precipitator – primary metal processing"
    },
    {
        "value": 28026,
        "NOCCode": "9611",
        "JobTitle": "helper, production – primary metal and mineral products processing"
    },
    {
        "value": 28027,
        "NOCCode": "9611",
        "JobTitle": "helper, reagent – primary metal processing"
    },
    {
        "value": 28028,
        "NOCCode": "9611",
        "JobTitle": "helper, refiner"
    },
    {
        "value": 28029,
        "NOCCode": "9611",
        "JobTitle": "helper, refinery – primary metal and mineral products processing"
    },
    {
        "value": 28030,
        "NOCCode": "9611",
        "JobTitle": "helper, rolling mill – primary metal processing"
    },
    {
        "value": 28031,
        "NOCCode": "9611",
        "JobTitle": "helper, slime recovery plant – primary metal processing"
    },
    {
        "value": 28032,
        "NOCCode": "9611",
        "JobTitle": "helper, smelter"
    },
    {
        "value": 28033,
        "NOCCode": "9611",
        "JobTitle": "helper, stretcher-leveller – primary metal processing"
    },
    {
        "value": 28034,
        "NOCCode": "9611",
        "JobTitle": "helper, tankhouse – primary metal processing"
    },
    {
        "value": 28035,
        "NOCCode": "9611",
        "JobTitle": "helper, welding-rod-extruder operator – primary metal processing"
    },
    {
        "value": 28036,
        "NOCCode": "9611",
        "JobTitle": "helper, wire drawer"
    },
    {
        "value": 28037,
        "NOCCode": "9611",
        "JobTitle": "helper, wire drawing"
    },
    {
        "value": 28038,
        "NOCCode": "9611",
        "JobTitle": "hot bed leverman/woman – primary metal processing"
    },
    {
        "value": 28039,
        "NOCCode": "9611",
        "JobTitle": "hydrate scale reclaimer – primary metal processing"
    },
    {
        "value": 28040,
        "NOCCode": "9611",
        "JobTitle": "industrial furnace cleaner – primary metal and mineral products processing"
    },
    {
        "value": 28041,
        "NOCCode": "9611",
        "JobTitle": "ingot cladder"
    },
    {
        "value": 28042,
        "NOCCode": "9611",
        "JobTitle": "insulation packer – mineral products manufacturing"
    },
    {
        "value": 28043,
        "NOCCode": "9611",
        "JobTitle": "jigger operator helper – mineral products processing"
    },
    {
        "value": 28044,
        "NOCCode": "9611",
        "JobTitle": "kiln cleaner – primary metal and mineral products processing"
    },
    {
        "value": 28045,
        "NOCCode": "9611",
        "JobTitle": "kiln door builder – mineral products processing"
    },
    {
        "value": 28046,
        "NOCCode": "9611",
        "JobTitle": "kiln labourer"
    },
    {
        "value": 28047,
        "NOCCode": "9611",
        "JobTitle": "kiln labourer – primary metal and mineral products processing"
    },
    {
        "value": 28048,
        "NOCCode": "9611",
        "JobTitle": "kiln operator helper – mineral products manufacturing"
    },
    {
        "value": 28049,
        "NOCCode": "9611",
        "JobTitle": "labourer – metallurgy"
    },
    {
        "value": 28050,
        "NOCCode": "9611",
        "JobTitle": "labourer – mineral and metal processing"
    },
    {
        "value": 28051,
        "NOCCode": "9611",
        "JobTitle": "labourer – primary metal and mineral products processing"
    },
    {
        "value": 28052,
        "NOCCode": "9611",
        "JobTitle": "labourer, ball mill – mineral products processing"
    },
    {
        "value": 28053,
        "NOCCode": "9611",
        "JobTitle": "labourer, core room – foundry"
    },
    {
        "value": 28054,
        "NOCCode": "9611",
        "JobTitle": "labourer, core shop – foundry"
    },
    {
        "value": 28055,
        "NOCCode": "9611",
        "JobTitle": "labourer, flue dust – primary metal and mineral products processing"
    },
    {
        "value": 28056,
        "NOCCode": "9611",
        "JobTitle": "labourer, foundry"
    },
    {
        "value": 28057,
        "NOCCode": "9611",
        "JobTitle": "labourer, kiln – primary metal and mineral products processing"
    },
    {
        "value": 28058,
        "NOCCode": "9611",
        "JobTitle": "labourer, mill – mineral products processing"
    },
    {
        "value": 28059,
        "NOCCode": "9611",
        "JobTitle": "labourer, ore processing"
    },
    {
        "value": 28060,
        "NOCCode": "9611",
        "JobTitle": "labourer, primary metal processing"
    },
    {
        "value": 28061,
        "NOCCode": "9611",
        "JobTitle": "labourer, refinery"
    },
    {
        "value": 28062,
        "NOCCode": "9611",
        "JobTitle": "labourer, rolling mill – primary metal processing"
    },
    {
        "value": 28063,
        "NOCCode": "9611",
        "JobTitle": "labourer, salt screening"
    },
    {
        "value": 28064,
        "NOCCode": "9611",
        "JobTitle": "labourer, smelter"
    },
    {
        "value": 28065,
        "NOCCode": "9611",
        "JobTitle": "labourer, steel plant"
    },
    {
        "value": 28066,
        "NOCCode": "9611",
        "JobTitle": "labourer, tailings disposal – primary metal processing"
    },
    {
        "value": 28067,
        "NOCCode": "9611",
        "JobTitle": "lead burner helper, zinc electrolysis"
    },
    {
        "value": 28068,
        "NOCCode": "9611",
        "JobTitle": "lead reclaimer – primary metal processing"
    },
    {
        "value": 28069,
        "NOCCode": "9611",
        "JobTitle": "leverman/woman, depiler table – primary metal processing"
    },
    {
        "value": 28070,
        "NOCCode": "9611",
        "JobTitle": "lime kiln helper – mineral products processing"
    },
    {
        "value": 28071,
        "NOCCode": "9611",
        "JobTitle": "lime kiln operator helper – mineral products processing"
    },
    {
        "value": 28072,
        "NOCCode": "9611",
        "JobTitle": "lime slaker"
    },
    {
        "value": 28073,
        "NOCCode": "9611",
        "JobTitle": "loader, coke – primary metal processing"
    },
    {
        "value": 28074,
        "NOCCode": "9611",
        "JobTitle": "loader, furnace – primary metal and mineral products processing"
    },
    {
        "value": 28075,
        "NOCCode": "9611",
        "JobTitle": "loader, scrap metal"
    },
    {
        "value": 28076,
        "NOCCode": "9611",
        "JobTitle": "machine helper – primary metal and mineral products processing"
    },
    {
        "value": 28077,
        "NOCCode": "9611",
        "JobTitle": "marker – primary metal processing"
    },
    {
        "value": 28078,
        "NOCCode": "9611",
        "JobTitle": "melt shop helper"
    },
    {
        "value": 28079,
        "NOCCode": "9611",
        "JobTitle": "melt shop labourer"
    },
    {
        "value": 28080,
        "NOCCode": "9611",
        "JobTitle": "melter helper – primary metal and mineral products processing"
    },
    {
        "value": 28081,
        "NOCCode": "9611",
        "JobTitle": "metal impregnator – primary metal processing"
    },
    {
        "value": 28082,
        "NOCCode": "9611",
        "JobTitle": "metal pourer helper"
    },
    {
        "value": 28083,
        "NOCCode": "9611",
        "JobTitle": "metal pourer helper – primary metal processing"
    },
    {
        "value": 28084,
        "NOCCode": "9611",
        "JobTitle": "metal powder lubricator finisher"
    },
    {
        "value": 28085,
        "NOCCode": "9611",
        "JobTitle": "metal tube marker – primary metal processing"
    },
    {
        "value": 28086,
        "NOCCode": "9611",
        "JobTitle": "mill helper – primary metal and mineral products processing"
    },
    {
        "value": 28087,
        "NOCCode": "9611",
        "JobTitle": "mill labourer – mineral products processing"
    },
    {
        "value": 28088,
        "NOCCode": "9611",
        "JobTitle": "mixer helper – primary metal and mineral products processing"
    },
    {
        "value": 28089,
        "NOCCode": "9611",
        "JobTitle": "molten metal mixer helper – primary metal processing"
    },
    {
        "value": 28090,
        "NOCCode": "9611",
        "JobTitle": "mould stripper – concrete products manufacturing"
    },
    {
        "value": 28091,
        "NOCCode": "9611",
        "JobTitle": "mould stripper, asbestos"
    },
    {
        "value": 28092,
        "NOCCode": "9611",
        "JobTitle": "moulder helper – primary metal processing"
    },
    {
        "value": 28093,
        "NOCCode": "9611",
        "JobTitle": "moulder, asbestos"
    },
    {
        "value": 28094,
        "NOCCode": "9611",
        "JobTitle": "operator helper, casting wheel"
    },
    {
        "value": 28095,
        "NOCCode": "9611",
        "JobTitle": "operator helper, welding rod extruder – primary metal processing"
    },
    {
        "value": 28096,
        "NOCCode": "9611",
        "JobTitle": "operator, buggy – primary metal processing"
    },
    {
        "value": 28097,
        "NOCCode": "9611",
        "JobTitle": "ore crushing helper"
    },
    {
        "value": 28098,
        "NOCCode": "9611",
        "JobTitle": "ore processing labourer"
    },
    {
        "value": 28099,
        "NOCCode": "9611",
        "JobTitle": "packer, glass"
    },
    {
        "value": 28100,
        "NOCCode": "9611",
        "JobTitle": "pipe maintenance worker, ore processing"
    },
    {
        "value": 28101,
        "NOCCode": "9611",
        "JobTitle": "plasterboard cutter – mineral products processing"
    },
    {
        "value": 28102,
        "NOCCode": "9611",
        "JobTitle": "plasterboard machine feeder – mineral products manufacturing"
    },
    {
        "value": 28103,
        "NOCCode": "9611",
        "JobTitle": "plugger – primary metal processing"
    },
    {
        "value": 28104,
        "NOCCode": "9611",
        "JobTitle": "porcelain glazer – mineral products manufacturing"
    },
    {
        "value": 28105,
        "NOCCode": "9611",
        "JobTitle": "potlining mixer helper – primary metal processing"
    },
    {
        "value": 28106,
        "NOCCode": "9611",
        "JobTitle": "pourer helper, metal – primary metal processing"
    },
    {
        "value": 28107,
        "NOCCode": "9611",
        "JobTitle": "powdered metal mixer – primary metal processing"
    },
    {
        "value": 28108,
        "NOCCode": "9611",
        "JobTitle": "powdered metal salvager – primary metal processing"
    },
    {
        "value": 28109,
        "NOCCode": "9611",
        "JobTitle": "precipitator helper – primary metal processing"
    },
    {
        "value": 28110,
        "NOCCode": "9611",
        "JobTitle": "primary metal processing labourer"
    },
    {
        "value": 28111,
        "NOCCode": "9611",
        "JobTitle": "production helper – primary metal and mineral products processing"
    },
    {
        "value": 28112,
        "NOCCode": "9611",
        "JobTitle": "reagent tender helper – primary metal processing"
    },
    {
        "value": 28113,
        "NOCCode": "9611",
        "JobTitle": "refiner helper"
    },
    {
        "value": 28114,
        "NOCCode": "9611",
        "JobTitle": "refinery helper – primary metal and mineral products processing"
    },
    {
        "value": 28115,
        "NOCCode": "9611",
        "JobTitle": "refinery labourer"
    },
    {
        "value": 28116,
        "NOCCode": "9611",
        "JobTitle": "rod drawer helper – primary metal processing"
    },
    {
        "value": 28117,
        "NOCCode": "9611",
        "JobTitle": "rod puller and coiler – primary metal processing"
    },
    {
        "value": 28118,
        "NOCCode": "9611",
        "JobTitle": "rolling mill catcher – primary metal processing"
    },
    {
        "value": 28119,
        "NOCCode": "9611",
        "JobTitle": "rolling mill helper – primary metal processing"
    },
    {
        "value": 28120,
        "NOCCode": "9611",
        "JobTitle": "rolling mill labourer – primary metal processing"
    },
    {
        "value": 28121,
        "NOCCode": "9611",
        "JobTitle": "rolling mill worker"
    },
    {
        "value": 28122,
        "NOCCode": "9611",
        "JobTitle": "rolling mill worker – primary metal processing"
    },
    {
        "value": 28123,
        "NOCCode": "9611",
        "JobTitle": "saggar placer – clay products manufacturing"
    },
    {
        "value": 28124,
        "NOCCode": "9611",
        "JobTitle": "salt screening labourer"
    },
    {
        "value": 28125,
        "NOCCode": "9611",
        "JobTitle": "salvager, powdered metal – primary metal processing"
    },
    {
        "value": 28126,
        "NOCCode": "9611",
        "JobTitle": "scaler – primary metal processing"
    },
    {
        "value": 28127,
        "NOCCode": "9611",
        "JobTitle": "scalping helper – primary metal processing"
    },
    {
        "value": 28128,
        "NOCCode": "9611",
        "JobTitle": "scrap machine helper – primary metal processing"
    },
    {
        "value": 28129,
        "NOCCode": "9611",
        "JobTitle": "scrap metal baler – primary metal processing"
    },
    {
        "value": 28130,
        "NOCCode": "9611",
        "JobTitle": "scrap metal loader"
    },
    {
        "value": 28131,
        "NOCCode": "9611",
        "JobTitle": "scrap metal processor – primary metal processing"
    },
    {
        "value": 28132,
        "NOCCode": "9611",
        "JobTitle": "scrap metal sorter and baler – primary metal processing"
    },
    {
        "value": 28133,
        "NOCCode": "9611",
        "JobTitle": "scrap shredder helper"
    },
    {
        "value": 28134,
        "NOCCode": "9611",
        "JobTitle": "scrap yard worker – primary metal processing"
    },
    {
        "value": 28135,
        "NOCCode": "9611",
        "JobTitle": "screening equipment helper – primary metal and mineral products processing"
    },
    {
        "value": 28136,
        "NOCCode": "9611",
        "JobTitle": "scrubber tender – mineral products processing"
    },
    {
        "value": 28137,
        "NOCCode": "9611",
        "JobTitle": "selenium recovery helper – primary metal processing"
    },
    {
        "value": 28138,
        "NOCCode": "9611",
        "JobTitle": "sheet roller helper – primary metal processing"
    },
    {
        "value": 28139,
        "NOCCode": "9611",
        "JobTitle": "shot bagger – explosives manufacturing"
    },
    {
        "value": 28140,
        "NOCCode": "9611",
        "JobTitle": "sintering machine loader – primary metal processing"
    },
    {
        "value": 28141,
        "NOCCode": "9611",
        "JobTitle": "skimmer – primary metal and mineral products processing"
    },
    {
        "value": 28142,
        "NOCCode": "9611",
        "JobTitle": "slime recovery plant helper – primary metal processing"
    },
    {
        "value": 28143,
        "NOCCode": "9611",
        "JobTitle": "slip sealer – mineral products processing"
    },
    {
        "value": 28144,
        "NOCCode": "9611",
        "JobTitle": "sludge worker – primary metal processing"
    },
    {
        "value": 28145,
        "NOCCode": "9611",
        "JobTitle": "smelter helper"
    },
    {
        "value": 28146,
        "NOCCode": "9611",
        "JobTitle": "smelter labourer"
    },
    {
        "value": 28147,
        "NOCCode": "9611",
        "JobTitle": "soaking pit bottom cleaner – primary metal processing"
    },
    {
        "value": 28148,
        "NOCCode": "9611",
        "JobTitle": "sorter and baler, scrap metal – primary metal processing"
    },
    {
        "value": 28149,
        "NOCCode": "9611",
        "JobTitle": "stamper – primary metal processing"
    },
    {
        "value": 28150,
        "NOCCode": "9611",
        "JobTitle": "standpipe attendant, coke oven"
    },
    {
        "value": 28151,
        "NOCCode": "9611",
        "JobTitle": "steel plant labourer"
    },
    {
        "value": 28152,
        "NOCCode": "9611",
        "JobTitle": "stone bed setter – mineral products processing"
    },
    {
        "value": 28153,
        "NOCCode": "9611",
        "JobTitle": "stonework filler – mineral products processing"
    },
    {
        "value": 28154,
        "NOCCode": "9611",
        "JobTitle": "stripper, moulds – concrete products manufacturing"
    },
    {
        "value": 28155,
        "NOCCode": "9611",
        "JobTitle": "tailings disposal labourer – primary metal processing"
    },
    {
        "value": 28156,
        "NOCCode": "9611",
        "JobTitle": "tailings labourer"
    },
    {
        "value": 28157,
        "NOCCode": "9611",
        "JobTitle": "tankhouse helper – primary metal processing"
    },
    {
        "value": 28158,
        "NOCCode": "9611",
        "JobTitle": "tapper – glass manufacturing"
    },
    {
        "value": 28159,
        "NOCCode": "9611",
        "JobTitle": "tapper – primary metal processing"
    },
    {
        "value": 28160,
        "NOCCode": "9611",
        "JobTitle": "tapper, cupola"
    },
    {
        "value": 28161,
        "NOCCode": "9611",
        "JobTitle": "tapper, furnace – primary metal processing"
    },
    {
        "value": 28162,
        "NOCCode": "9611",
        "JobTitle": "tapper, glass – mineral products processing"
    },
    {
        "value": 28163,
        "NOCCode": "9611",
        "JobTitle": "tar chaser, coke oven"
    },
    {
        "value": 28164,
        "NOCCode": "9611",
        "JobTitle": "tender, extruder – mineral products processing"
    },
    {
        "value": 28165,
        "NOCCode": "9611",
        "JobTitle": "trimmer, castings"
    },
    {
        "value": 28166,
        "NOCCode": "9611",
        "JobTitle": "tube drawer helper – primary metal processing"
    },
    {
        "value": 28167,
        "NOCCode": "9611",
        "JobTitle": "tube roller helper – primary metal processing"
    },
    {
        "value": 28168,
        "NOCCode": "9611",
        "JobTitle": "utility labourer – primary metal and mineral products processing"
    },
    {
        "value": 28169,
        "NOCCode": "9611",
        "JobTitle": "utility machine worker – primary metal and mineral products processing"
    },
    {
        "value": 28170,
        "NOCCode": "9611",
        "JobTitle": "ware cleaner – clay products manufacturing"
    },
    {
        "value": 28171,
        "NOCCode": "9611",
        "JobTitle": "welding-rod-extruder operator helper – primary metal processing"
    },
    {
        "value": 28172,
        "NOCCode": "9611",
        "JobTitle": "wire drawer helper"
    },
    {
        "value": 28173,
        "NOCCode": "9611",
        "JobTitle": "wire drawing helper"
    },
    {
        "value": 28174,
        "NOCCode": "9611",
        "JobTitle": "zinc cathode puller"
    },
    {
        "value": 28175,
        "NOCCode": "9611",
        "JobTitle": "zinc drosser"
    },
    {
        "value": 28176,
        "NOCCode": "9611",
        "JobTitle": "zinc dust maker"
    },
    {
        "value": 28177,
        "NOCCode": "9611",
        "JobTitle": "zinc electrolysis lead burner helper"
    },
    {
        "value": 28178,
        "NOCCode": "9612",
        "JobTitle": "automatic machine polisher – metal products manufacturing"
    },
    {
        "value": 28179,
        "NOCCode": "9612",
        "JobTitle": "battery lead burner helper"
    },
    {
        "value": 28180,
        "NOCCode": "9612",
        "JobTitle": "bench grinder – metal products manufacturing"
    },
    {
        "value": 28181,
        "NOCCode": "9612",
        "JobTitle": "bench hand, machine shop – metal products manufacturing"
    },
    {
        "value": 28182,
        "NOCCode": "9612",
        "JobTitle": "bending machine helper – metal products manufacturing"
    },
    {
        "value": 28183,
        "NOCCode": "9612",
        "JobTitle": "bit sharpener tender"
    },
    {
        "value": 28184,
        "NOCCode": "9612",
        "JobTitle": "blacksmith helper"
    },
    {
        "value": 28185,
        "NOCCode": "9612",
        "JobTitle": "boilermaker helper"
    },
    {
        "value": 28186,
        "NOCCode": "9612",
        "JobTitle": "brake helper – metal products manufacturing"
    },
    {
        "value": 28187,
        "NOCCode": "9612",
        "JobTitle": "brazing machine feeder"
    },
    {
        "value": 28188,
        "NOCCode": "9612",
        "JobTitle": "buffer, hand – metal products manufacturing"
    },
    {
        "value": 28189,
        "NOCCode": "9612",
        "JobTitle": "buffer, metal – metal products manufacturing"
    },
    {
        "value": 28190,
        "NOCCode": "9612",
        "JobTitle": "buffing machine tender – metal products manufacturing"
    },
    {
        "value": 28191,
        "NOCCode": "9612",
        "JobTitle": "bumper operator, aircraft"
    },
    {
        "value": 28192,
        "NOCCode": "9612",
        "JobTitle": "burnisher – metalworking"
    },
    {
        "value": 28193,
        "NOCCode": "9612",
        "JobTitle": "calibrator, tanks – metal products manufacturing"
    },
    {
        "value": 28194,
        "NOCCode": "9612",
        "JobTitle": "cartridge parts cleaner – explosives and ammunition manufacturing"
    },
    {
        "value": 28195,
        "NOCCode": "9612",
        "JobTitle": "chipper – metal products manufacturing"
    },
    {
        "value": 28196,
        "NOCCode": "9612",
        "JobTitle": "chipper-grinder"
    },
    {
        "value": 28197,
        "NOCCode": "9612",
        "JobTitle": "chipper-grinder – metal products manufacturing"
    },
    {
        "value": 28198,
        "NOCCode": "9612",
        "JobTitle": "chisel grinder – metal products manufacturing"
    },
    {
        "value": 28199,
        "NOCCode": "9612",
        "JobTitle": "cleaner, metal – metal products manufacturing"
    },
    {
        "value": 28200,
        "NOCCode": "9612",
        "JobTitle": "cleaner, moulds – foundry"
    },
    {
        "value": 28201,
        "NOCCode": "9612",
        "JobTitle": "coil spring grinder – metal products manufacturing"
    },
    {
        "value": 28202,
        "NOCCode": "9612",
        "JobTitle": "deburrer – metal products manufacturing"
    },
    {
        "value": 28203,
        "NOCCode": "9612",
        "JobTitle": "die blanking press operator helper – metal products manufacturing"
    },
    {
        "value": 28204,
        "NOCCode": "9612",
        "JobTitle": "dresser – metal products manufacturing"
    },
    {
        "value": 28205,
        "NOCCode": "9612",
        "JobTitle": "drill grinder – metal products manufacturing"
    },
    {
        "value": 28206,
        "NOCCode": "9612",
        "JobTitle": "drop hammer helper – metal fabrication"
    },
    {
        "value": 28207,
        "NOCCode": "9612",
        "JobTitle": "fabrication shop helper – metal products"
    },
    {
        "value": 28208,
        "NOCCode": "9612",
        "JobTitle": "feeder, brazing machine"
    },
    {
        "value": 28209,
        "NOCCode": "9612",
        "JobTitle": "feeder, soldering machine"
    },
    {
        "value": 28210,
        "NOCCode": "9612",
        "JobTitle": "filer, hand – metal products manufacturing"
    },
    {
        "value": 28211,
        "NOCCode": "9612",
        "JobTitle": "finish grinder – metal products manufacturing"
    },
    {
        "value": 28212,
        "NOCCode": "9612",
        "JobTitle": "finisher, foundry moulds"
    },
    {
        "value": 28213,
        "NOCCode": "9612",
        "JobTitle": "finisher, metal castings"
    },
    {
        "value": 28214,
        "NOCCode": "9612",
        "JobTitle": "finisher, wires"
    },
    {
        "value": 28215,
        "NOCCode": "9612",
        "JobTitle": "firearms cleaner – metal products manufacturing"
    },
    {
        "value": 28216,
        "NOCCode": "9612",
        "JobTitle": "fitter helper – metal products manufacturing"
    },
    {
        "value": 28217,
        "NOCCode": "9612",
        "JobTitle": "fitter helper, shipbuilding"
    },
    {
        "value": 28218,
        "NOCCode": "9612",
        "JobTitle": "fitter helper, structural metal"
    },
    {
        "value": 28219,
        "NOCCode": "9612",
        "JobTitle": "flame cutter helper – metal products manufacturing"
    },
    {
        "value": 28220,
        "NOCCode": "9612",
        "JobTitle": "forge helper"
    },
    {
        "value": 28221,
        "NOCCode": "9612",
        "JobTitle": "forger helper"
    },
    {
        "value": 28222,
        "NOCCode": "9612",
        "JobTitle": "foundry mould finisher"
    },
    {
        "value": 28223,
        "NOCCode": "9612",
        "JobTitle": "galvanizer helper"
    },
    {
        "value": 28224,
        "NOCCode": "9612",
        "JobTitle": "grinder – metal products manufacturing"
    },
    {
        "value": 28225,
        "NOCCode": "9612",
        "JobTitle": "grinder and sharpener – metal products manufacturing"
    },
    {
        "value": 28226,
        "NOCCode": "9612",
        "JobTitle": "grinder, plates – metal products manufacturing"
    },
    {
        "value": 28227,
        "NOCCode": "9612",
        "JobTitle": "grinder-chipper – metal products manufacturing"
    },
    {
        "value": 28228,
        "NOCCode": "9612",
        "JobTitle": "grinder-deburrer"
    },
    {
        "value": 28229,
        "NOCCode": "9612",
        "JobTitle": "grinder-deburrer – metal products manufacturing"
    },
    {
        "value": 28230,
        "NOCCode": "9612",
        "JobTitle": "grinder-dresser – metal products manufacturing"
    },
    {
        "value": 28231,
        "NOCCode": "9612",
        "JobTitle": "grinder-trimmer – metal products manufacturing"
    },
    {
        "value": 28232,
        "NOCCode": "9612",
        "JobTitle": "hand buffer – metal products manufacturing"
    },
    {
        "value": 28233,
        "NOCCode": "9612",
        "JobTitle": "hand filer – metal products manufacturing"
    },
    {
        "value": 28234,
        "NOCCode": "9612",
        "JobTitle": "heat treater helper"
    },
    {
        "value": 28235,
        "NOCCode": "9612",
        "JobTitle": "helper, blacksmith"
    },
    {
        "value": 28236,
        "NOCCode": "9612",
        "JobTitle": "helper, boilermaker"
    },
    {
        "value": 28237,
        "NOCCode": "9612",
        "JobTitle": "helper, drop hammer – metal products manufacturing"
    },
    {
        "value": 28238,
        "NOCCode": "9612",
        "JobTitle": "helper, fitter – metal products manufacturing"
    },
    {
        "value": 28239,
        "NOCCode": "9612",
        "JobTitle": "helper, flame cutter – metal products manufacturing"
    },
    {
        "value": 28240,
        "NOCCode": "9612",
        "JobTitle": "helper, forge"
    },
    {
        "value": 28241,
        "NOCCode": "9612",
        "JobTitle": "helper, forger"
    },
    {
        "value": 28242,
        "NOCCode": "9612",
        "JobTitle": "helper, galvanizer"
    },
    {
        "value": 28243,
        "NOCCode": "9612",
        "JobTitle": "helper, heat treater"
    },
    {
        "value": 28244,
        "NOCCode": "9612",
        "JobTitle": "helper, industrial sheet metal worker"
    },
    {
        "value": 28245,
        "NOCCode": "9612",
        "JobTitle": "helper, machine shop – metal products manufacturing"
    },
    {
        "value": 28246,
        "NOCCode": "9612",
        "JobTitle": "helper, metal polisher"
    },
    {
        "value": 28247,
        "NOCCode": "9612",
        "JobTitle": "helper, metal-fabricating shop"
    },
    {
        "value": 28248,
        "NOCCode": "9612",
        "JobTitle": "helper, metalworking machine"
    },
    {
        "value": 28249,
        "NOCCode": "9612",
        "JobTitle": "helper, plate worker – metal products manufacturing"
    },
    {
        "value": 28250,
        "NOCCode": "9612",
        "JobTitle": "helper, plater – metal fabrication"
    },
    {
        "value": 28251,
        "NOCCode": "9612",
        "JobTitle": "helper, plating room"
    },
    {
        "value": 28252,
        "NOCCode": "9612",
        "JobTitle": "helper, production – metal coating"
    },
    {
        "value": 28253,
        "NOCCode": "9612",
        "JobTitle": "helper, punch press – metal products manufacturing"
    },
    {
        "value": 28254,
        "NOCCode": "9612",
        "JobTitle": "helper, rigger – shipbuilding"
    },
    {
        "value": 28255,
        "NOCCode": "9612",
        "JobTitle": "helper, shear – metal fabrication"
    },
    {
        "value": 28256,
        "NOCCode": "9612",
        "JobTitle": "helper, sherardizer"
    },
    {
        "value": 28257,
        "NOCCode": "9612",
        "JobTitle": "helper, shipfitter"
    },
    {
        "value": 28258,
        "NOCCode": "9612",
        "JobTitle": "helper, shipwright"
    },
    {
        "value": 28259,
        "NOCCode": "9612",
        "JobTitle": "helper, structural metal fitter"
    },
    {
        "value": 28260,
        "NOCCode": "9612",
        "JobTitle": "helper, upsetter – metal fabrication"
    },
    {
        "value": 28261,
        "NOCCode": "9612",
        "JobTitle": "helper, welder"
    },
    {
        "value": 28262,
        "NOCCode": "9612",
        "JobTitle": "helper, welder-fitter"
    },
    {
        "value": 28263,
        "NOCCode": "9612",
        "JobTitle": "helper, welder-fitter – manufacturing"
    },
    {
        "value": 28264,
        "NOCCode": "9612",
        "JobTitle": "helper, welding machine operator"
    },
    {
        "value": 28265,
        "NOCCode": "9612",
        "JobTitle": "helper, wire loom"
    },
    {
        "value": 28266,
        "NOCCode": "9612",
        "JobTitle": "helper, wire weaver"
    },
    {
        "value": 28267,
        "NOCCode": "9612",
        "JobTitle": "labourer – metal fabrication"
    },
    {
        "value": 28268,
        "NOCCode": "9612",
        "JobTitle": "labourer, metal polishing-plating"
    },
    {
        "value": 28269,
        "NOCCode": "9612",
        "JobTitle": "loom helper, wire"
    },
    {
        "value": 28270,
        "NOCCode": "9612",
        "JobTitle": "machine shop bench hand – metal products manufacturing"
    },
    {
        "value": 28271,
        "NOCCode": "9612",
        "JobTitle": "machine shop helper – metal products manufacturing"
    },
    {
        "value": 28272,
        "NOCCode": "9612",
        "JobTitle": "mechanical press operator helper – metal products manufacturing"
    },
    {
        "value": 28273,
        "NOCCode": "9612",
        "JobTitle": "metal bluing cleaner – metal products manufacturing"
    },
    {
        "value": 28274,
        "NOCCode": "9612",
        "JobTitle": "metal buffer – metal products manufacturing"
    },
    {
        "value": 28275,
        "NOCCode": "9612",
        "JobTitle": "metal casting finisher"
    },
    {
        "value": 28276,
        "NOCCode": "9612",
        "JobTitle": "metal cleaner"
    },
    {
        "value": 28277,
        "NOCCode": "9612",
        "JobTitle": "metal cleaner – metal products manufacturing"
    },
    {
        "value": 28278,
        "NOCCode": "9612",
        "JobTitle": "metal cleaner, immersion – metal products manufacturing"
    },
    {
        "value": 28279,
        "NOCCode": "9612",
        "JobTitle": "metal fabrication worker"
    },
    {
        "value": 28280,
        "NOCCode": "9612",
        "JobTitle": "metal filer, hand"
    },
    {
        "value": 28281,
        "NOCCode": "9612",
        "JobTitle": "metal pickler operator helper"
    },
    {
        "value": 28282,
        "NOCCode": "9612",
        "JobTitle": "metal polisher helper"
    },
    {
        "value": 28283,
        "NOCCode": "9612",
        "JobTitle": "metal structure fitter helper"
    },
    {
        "value": 28284,
        "NOCCode": "9612",
        "JobTitle": "metal-fabricating shop helper"
    },
    {
        "value": 28285,
        "NOCCode": "9612",
        "JobTitle": "metalworking machine helper"
    },
    {
        "value": 28286,
        "NOCCode": "9612",
        "JobTitle": "mill roll grinder – metal products manufacturing"
    },
    {
        "value": 28287,
        "NOCCode": "9612",
        "JobTitle": "mould cleaner – foundry"
    },
    {
        "value": 28288,
        "NOCCode": "9612",
        "JobTitle": "mould dresser – foundry"
    },
    {
        "value": 28289,
        "NOCCode": "9612",
        "JobTitle": "mould finisher, foundry"
    },
    {
        "value": 28290,
        "NOCCode": "9612",
        "JobTitle": "nib finisher – metal products manufacturing"
    },
    {
        "value": 28291,
        "NOCCode": "9612",
        "JobTitle": "nut and bolt assembler – metal products manufacturing"
    },
    {
        "value": 28292,
        "NOCCode": "9612",
        "JobTitle": "nut and bolt packer – metal products manufacturing"
    },
    {
        "value": 28293,
        "NOCCode": "9612",
        "JobTitle": "operator helper, metal pickler"
    },
    {
        "value": 28294,
        "NOCCode": "9612",
        "JobTitle": "operator, sandblaster – metal products manufacturing"
    },
    {
        "value": 28295,
        "NOCCode": "9612",
        "JobTitle": "operator, wheelabrator – metal products manufacturing"
    },
    {
        "value": 28296,
        "NOCCode": "9612",
        "JobTitle": "pellet grinder – metal products manufacturing"
    },
    {
        "value": 28297,
        "NOCCode": "9612",
        "JobTitle": "pickler operator helper, metal"
    },
    {
        "value": 28298,
        "NOCCode": "9612",
        "JobTitle": "plate grinder – metal products manufacturing"
    },
    {
        "value": 28299,
        "NOCCode": "9612",
        "JobTitle": "plate worker helper – metal products manufacturing"
    },
    {
        "value": 28300,
        "NOCCode": "9612",
        "JobTitle": "plater helper"
    },
    {
        "value": 28301,
        "NOCCode": "9612",
        "JobTitle": "plater helper – metal fabrication"
    },
    {
        "value": 28302,
        "NOCCode": "9612",
        "JobTitle": "plating room helper"
    },
    {
        "value": 28303,
        "NOCCode": "9612",
        "JobTitle": "polisher and buffer, sheet metal"
    },
    {
        "value": 28304,
        "NOCCode": "9612",
        "JobTitle": "polisher, slide fasteners – metal products manufacturing"
    },
    {
        "value": 28305,
        "NOCCode": "9612",
        "JobTitle": "power hammer helper – metal fabrication"
    },
    {
        "value": 28306,
        "NOCCode": "9612",
        "JobTitle": "production helper – metal coating"
    },
    {
        "value": 28307,
        "NOCCode": "9612",
        "JobTitle": "punch press helper – metal products manufacturing"
    },
    {
        "value": 28308,
        "NOCCode": "9612",
        "JobTitle": "reel wrapper – wire products manufacturing"
    },
    {
        "value": 28309,
        "NOCCode": "9612",
        "JobTitle": "rigger helper – shipbuilding"
    },
    {
        "value": 28310,
        "NOCCode": "9612",
        "JobTitle": "sandblaster – metal products manufacturing"
    },
    {
        "value": 28311,
        "NOCCode": "9612",
        "JobTitle": "sandblaster operator – metal products manufacturing"
    },
    {
        "value": 28312,
        "NOCCode": "9612",
        "JobTitle": "sandblasting machine tender – metal products manufacturing"
    },
    {
        "value": 28313,
        "NOCCode": "9612",
        "JobTitle": "sharpener tender, bits"
    },
    {
        "value": 28314,
        "NOCCode": "9612",
        "JobTitle": "shear helper – metal fabrication"
    },
    {
        "value": 28315,
        "NOCCode": "9612",
        "JobTitle": "sheet metal polisher and buffer"
    },
    {
        "value": 28316,
        "NOCCode": "9612",
        "JobTitle": "sherardizer helper"
    },
    {
        "value": 28317,
        "NOCCode": "9612",
        "JobTitle": "shipbuilding fitter helper"
    },
    {
        "value": 28318,
        "NOCCode": "9612",
        "JobTitle": "shipfitter helper"
    },
    {
        "value": 28319,
        "NOCCode": "9612",
        "JobTitle": "shipwright helper"
    },
    {
        "value": 28320,
        "NOCCode": "9612",
        "JobTitle": "shot grinder – metal products manufacturing"
    },
    {
        "value": 28321,
        "NOCCode": "9612",
        "JobTitle": "shotblaster"
    },
    {
        "value": 28322,
        "NOCCode": "9612",
        "JobTitle": "shotblaster – metal fabrication"
    },
    {
        "value": 28323,
        "NOCCode": "9612",
        "JobTitle": "shotblasting machine operator – metal products manufacturing"
    },
    {
        "value": 28324,
        "NOCCode": "9612",
        "JobTitle": "shotblasting machine tender – metal products manufacturing"
    },
    {
        "value": 28325,
        "NOCCode": "9612",
        "JobTitle": "soldering machine feeder"
    },
    {
        "value": 28326,
        "NOCCode": "9612",
        "JobTitle": "spooler, wires – metal products manufacturing"
    },
    {
        "value": 28327,
        "NOCCode": "9612",
        "JobTitle": "structural metal fitter helper"
    },
    {
        "value": 28328,
        "NOCCode": "9612",
        "JobTitle": "structural steel fitter helper"
    },
    {
        "value": 28329,
        "NOCCode": "9612",
        "JobTitle": "tank calibrator – metal products manufacturing"
    },
    {
        "value": 28330,
        "NOCCode": "9612",
        "JobTitle": "tender, tumbling barrel – metal products manufacturing"
    },
    {
        "value": 28331,
        "NOCCode": "9612",
        "JobTitle": "trimmer – metal products manufacturing"
    },
    {
        "value": 28332,
        "NOCCode": "9612",
        "JobTitle": "tumbler tender – metal products manufacturing"
    },
    {
        "value": 28333,
        "NOCCode": "9612",
        "JobTitle": "tumbling barrel tender – metal products manufacturing"
    },
    {
        "value": 28334,
        "NOCCode": "9612",
        "JobTitle": "tumbling machine tender – metal products manufacturing"
    },
    {
        "value": 28335,
        "NOCCode": "9612",
        "JobTitle": "upsetter helper – metal fabrication"
    },
    {
        "value": 28336,
        "NOCCode": "9612",
        "JobTitle": "weaver helper, wire"
    },
    {
        "value": 28337,
        "NOCCode": "9612",
        "JobTitle": "welder helper"
    },
    {
        "value": 28338,
        "NOCCode": "9612",
        "JobTitle": "welder-fitter helper"
    },
    {
        "value": 28339,
        "NOCCode": "9612",
        "JobTitle": "welder-fitter helper – manufacturing"
    },
    {
        "value": 28340,
        "NOCCode": "9612",
        "JobTitle": "welding machine operator helper"
    },
    {
        "value": 28341,
        "NOCCode": "9612",
        "JobTitle": "wheelabrator operator"
    },
    {
        "value": 28342,
        "NOCCode": "9612",
        "JobTitle": "wheelabrator operator – metal products manufacturing"
    },
    {
        "value": 28343,
        "NOCCode": "9612",
        "JobTitle": "wire finisher"
    },
    {
        "value": 28344,
        "NOCCode": "9612",
        "JobTitle": "wire loom helper"
    },
    {
        "value": 28345,
        "NOCCode": "9612",
        "JobTitle": "wire spooler – metal products manufacturing"
    },
    {
        "value": 28346,
        "NOCCode": "9612",
        "JobTitle": "wire weaver helper"
    },
    {
        "value": 28347,
        "NOCCode": "9612",
        "JobTitle": "wrapping machine operator – wire products manufacturing"
    },
    {
        "value": 28348,
        "NOCCode": "9613",
        "JobTitle": "acetylene cylinder preparer"
    },
    {
        "value": 28349,
        "NOCCode": "9613",
        "JobTitle": "batch dryer tender helper"
    },
    {
        "value": 28350,
        "NOCCode": "9613",
        "JobTitle": "bath mix solvent recoverer"
    },
    {
        "value": 28351,
        "NOCCode": "9613",
        "JobTitle": "boiler operator helper"
    },
    {
        "value": 28352,
        "NOCCode": "9613",
        "JobTitle": "boiler water tester"
    },
    {
        "value": 28353,
        "NOCCode": "9613",
        "JobTitle": "boilerhouse helper"
    },
    {
        "value": 28354,
        "NOCCode": "9613",
        "JobTitle": "bullet lubricant compounder"
    },
    {
        "value": 28355,
        "NOCCode": "9613",
        "JobTitle": "catalyst operator helper, gasoline"
    },
    {
        "value": 28356,
        "NOCCode": "9613",
        "JobTitle": "chemical plant labourer"
    },
    {
        "value": 28357,
        "NOCCode": "9613",
        "JobTitle": "cleaner, filter – chemical processing"
    },
    {
        "value": 28358,
        "NOCCode": "9613",
        "JobTitle": "cleaner, still – chemical processing"
    },
    {
        "value": 28359,
        "NOCCode": "9613",
        "JobTitle": "coating machine feeder – chemical processing"
    },
    {
        "value": 28360,
        "NOCCode": "9613",
        "JobTitle": "compressed gas plant worker"
    },
    {
        "value": 28361,
        "NOCCode": "9613",
        "JobTitle": "crutcher operator helper"
    },
    {
        "value": 28362,
        "NOCCode": "9613",
        "JobTitle": "distillation equipment helper"
    },
    {
        "value": 28363,
        "NOCCode": "9613",
        "JobTitle": "distillation equipment helper – chemical processing"
    },
    {
        "value": 28364,
        "NOCCode": "9613",
        "JobTitle": "distiller helper – chemical processing"
    },
    {
        "value": 28365,
        "NOCCode": "9613",
        "JobTitle": "dryer tender helper – chemical processing"
    },
    {
        "value": 28366,
        "NOCCode": "9613",
        "JobTitle": "electrode cleaner"
    },
    {
        "value": 28367,
        "NOCCode": "9613",
        "JobTitle": "electrolytic cell cleaner"
    },
    {
        "value": 28368,
        "NOCCode": "9613",
        "JobTitle": "equipment cleaner – chemical processing"
    },
    {
        "value": 28369,
        "NOCCode": "9613",
        "JobTitle": "film spooler"
    },
    {
        "value": 28370,
        "NOCCode": "9613",
        "JobTitle": "filter cleaner – chemical processing"
    },
    {
        "value": 28371,
        "NOCCode": "9613",
        "JobTitle": "filtration helper – chemical processing"
    },
    {
        "value": 28372,
        "NOCCode": "9613",
        "JobTitle": "flue cleaner – utilities"
    },
    {
        "value": 28373,
        "NOCCode": "9613",
        "JobTitle": "gas fitter helper – utilities"
    },
    {
        "value": 28374,
        "NOCCode": "9613",
        "JobTitle": "gas utility labourer"
    },
    {
        "value": 28375,
        "NOCCode": "9613",
        "JobTitle": "gasket coater"
    },
    {
        "value": 28376,
        "NOCCode": "9613",
        "JobTitle": "helper – chemical processing"
    },
    {
        "value": 28377,
        "NOCCode": "9613",
        "JobTitle": "helper, substation operator"
    },
    {
        "value": 28378,
        "NOCCode": "9613",
        "JobTitle": "integrator operator"
    },
    {
        "value": 28379,
        "NOCCode": "9613",
        "JobTitle": "labourer – chemical processing"
    },
    {
        "value": 28380,
        "NOCCode": "9613",
        "JobTitle": "labourer – gas utility"
    },
    {
        "value": 28381,
        "NOCCode": "9613",
        "JobTitle": "labourer, chemical plant"
    },
    {
        "value": 28382,
        "NOCCode": "9613",
        "JobTitle": "labourer, paint plant"
    },
    {
        "value": 28383,
        "NOCCode": "9613",
        "JobTitle": "labourer, petroleum refinery"
    },
    {
        "value": 28384,
        "NOCCode": "9613",
        "JobTitle": "landfill scale operator"
    },
    {
        "value": 28385,
        "NOCCode": "9613",
        "JobTitle": "landfill weigh scale attendant"
    },
    {
        "value": 28386,
        "NOCCode": "9613",
        "JobTitle": "loader – chemical processing"
    },
    {
        "value": 28387,
        "NOCCode": "9613",
        "JobTitle": "mill charger – chemical processing"
    },
    {
        "value": 28388,
        "NOCCode": "9613",
        "JobTitle": "oven loader – chemical processing"
    },
    {
        "value": 28389,
        "NOCCode": "9613",
        "JobTitle": "paint plant labourer"
    },
    {
        "value": 28390,
        "NOCCode": "9613",
        "JobTitle": "paint pourer"
    },
    {
        "value": 28391,
        "NOCCode": "9613",
        "JobTitle": "petroleum process operator helper"
    },
    {
        "value": 28392,
        "NOCCode": "9613",
        "JobTitle": "petroleum refinery labourer"
    },
    {
        "value": 28393,
        "NOCCode": "9613",
        "JobTitle": "pigment presser"
    },
    {
        "value": 28394,
        "NOCCode": "9613",
        "JobTitle": "powerhouse helper"
    },
    {
        "value": 28395,
        "NOCCode": "9613",
        "JobTitle": "rack loader"
    },
    {
        "value": 28396,
        "NOCCode": "9613",
        "JobTitle": "refrigeration plant operator helper"
    },
    {
        "value": 28397,
        "NOCCode": "9613",
        "JobTitle": "retort unloader"
    },
    {
        "value": 28398,
        "NOCCode": "9613",
        "JobTitle": "sample preparer – chemical processing"
    },
    {
        "value": 28399,
        "NOCCode": "9613",
        "JobTitle": "sampler – chemical processing"
    },
    {
        "value": 28400,
        "NOCCode": "9613",
        "JobTitle": "saturating machine feeder – chemical processing"
    },
    {
        "value": 28401,
        "NOCCode": "9613",
        "JobTitle": "scale operator, landfill"
    },
    {
        "value": 28402,
        "NOCCode": "9613",
        "JobTitle": "shutdown labourer – chemical processing"
    },
    {
        "value": 28403,
        "NOCCode": "9613",
        "JobTitle": "slug-press feeder"
    },
    {
        "value": 28404,
        "NOCCode": "9613",
        "JobTitle": "stationary engineer helper"
    },
    {
        "value": 28405,
        "NOCCode": "9613",
        "JobTitle": "still cleaner – chemical processing"
    },
    {
        "value": 28406,
        "NOCCode": "9613",
        "JobTitle": "stove-bottom worker"
    },
    {
        "value": 28407,
        "NOCCode": "9613",
        "JobTitle": "stove-carriage worker"
    },
    {
        "value": 28408,
        "NOCCode": "9613",
        "JobTitle": "substation operator helper"
    },
    {
        "value": 28409,
        "NOCCode": "9613",
        "JobTitle": "sulphuric acid plant operator helper"
    },
    {
        "value": 28410,
        "NOCCode": "9613",
        "JobTitle": "temperature regulator"
    },
    {
        "value": 28411,
        "NOCCode": "9613",
        "JobTitle": "transformer stockroom attendant"
    },
    {
        "value": 28412,
        "NOCCode": "9613",
        "JobTitle": "treater helper – chemical processing"
    },
    {
        "value": 28413,
        "NOCCode": "9613",
        "JobTitle": "varnish maker helper"
    },
    {
        "value": 28414,
        "NOCCode": "9613",
        "JobTitle": "water intake tender"
    },
    {
        "value": 28415,
        "NOCCode": "9613",
        "JobTitle": "water intake tender – utilities"
    },
    {
        "value": 28416,
        "NOCCode": "9613",
        "JobTitle": "waterworks labourer"
    },
    {
        "value": 28417,
        "NOCCode": "9613",
        "JobTitle": "wax moulder"
    },
    {
        "value": 28418,
        "NOCCode": "9613",
        "JobTitle": "weigh scale attendant, landfill"
    },
    {
        "value": 28419,
        "NOCCode": "9614",
        "JobTitle": "attendant, chip bin – wood processing"
    },
    {
        "value": 28420,
        "NOCCode": "9614",
        "JobTitle": "bag-making machine tender helper"
    },
    {
        "value": 28421,
        "NOCCode": "9614",
        "JobTitle": "barking machine cleaner – wood processing"
    },
    {
        "value": 28422,
        "NOCCode": "9614",
        "JobTitle": "beater helper – pulp and paper"
    },
    {
        "value": 28423,
        "NOCCode": "9614",
        "JobTitle": "beater operator helper – pulp and paper"
    },
    {
        "value": 28424,
        "NOCCode": "9614",
        "JobTitle": "bolt loader – sawmill"
    },
    {
        "value": 28425,
        "NOCCode": "9614",
        "JobTitle": "broke hustler – pulp and paper"
    },
    {
        "value": 28426,
        "NOCCode": "9614",
        "JobTitle": "brown stock washer helper – pulp and paper"
    },
    {
        "value": 28427,
        "NOCCode": "9614",
        "JobTitle": "cant loader – wood processing"
    },
    {
        "value": 28428,
        "NOCCode": "9614",
        "JobTitle": "car stacker – wood processing"
    },
    {
        "value": 28429,
        "NOCCode": "9614",
        "JobTitle": "carton-forming machine feeder"
    },
    {
        "value": 28430,
        "NOCCode": "9614",
        "JobTitle": "chain conveyor tender – sawmill"
    },
    {
        "value": 28431,
        "NOCCode": "9614",
        "JobTitle": "chain conveyor tender – wood processing"
    },
    {
        "value": 28432,
        "NOCCode": "9614",
        "JobTitle": "chainman/woman – wood processing"
    },
    {
        "value": 28433,
        "NOCCode": "9614",
        "JobTitle": "chip bin attendant – wood processing"
    },
    {
        "value": 28434,
        "NOCCode": "9614",
        "JobTitle": "chip dump operator – wood processing"
    },
    {
        "value": 28435,
        "NOCCode": "9614",
        "JobTitle": "chip silo attendant – wood processing"
    },
    {
        "value": 28436,
        "NOCCode": "9614",
        "JobTitle": "chip storage attendant – wood processing"
    },
    {
        "value": 28437,
        "NOCCode": "9614",
        "JobTitle": "coating machine helper"
    },
    {
        "value": 28438,
        "NOCCode": "9614",
        "JobTitle": "combiner machine helper"
    },
    {
        "value": 28439,
        "NOCCode": "9614",
        "JobTitle": "conveyor cleaner – pulp and paper"
    },
    {
        "value": 28440,
        "NOCCode": "9614",
        "JobTitle": "corrugator-operator helper"
    },
    {
        "value": 28441,
        "NOCCode": "9614",
        "JobTitle": "cutter helper – pulp and paper"
    },
    {
        "value": 28442,
        "NOCCode": "9614",
        "JobTitle": "dry chain offbearer – wood processing"
    },
    {
        "value": 28443,
        "NOCCode": "9614",
        "JobTitle": "dry chainman/woman – wood processing"
    },
    {
        "value": 28444,
        "NOCCode": "9614",
        "JobTitle": "feeder – wood processing"
    },
    {
        "value": 28445,
        "NOCCode": "9614",
        "JobTitle": "feeder, logs"
    },
    {
        "value": 28446,
        "NOCCode": "9614",
        "JobTitle": "fifth hand – pulp and paper"
    },
    {
        "value": 28447,
        "NOCCode": "9614",
        "JobTitle": "green sorter – wood processing"
    },
    {
        "value": 28448,
        "NOCCode": "9614",
        "JobTitle": "green wood chainman/woman – wood processing"
    },
    {
        "value": 28449,
        "NOCCode": "9614",
        "JobTitle": "greensaw offbearer – wood processing"
    },
    {
        "value": 28450,
        "NOCCode": "9614",
        "JobTitle": "grinder feeder – pulp and paper"
    },
    {
        "value": 28451,
        "NOCCode": "9614",
        "JobTitle": "hand bundler – wood processing"
    },
    {
        "value": 28452,
        "NOCCode": "9614",
        "JobTitle": "handler – wood treatment"
    },
    {
        "value": 28453,
        "NOCCode": "9614",
        "JobTitle": "helper, lime kiln – pulp and paper"
    },
    {
        "value": 28454,
        "NOCCode": "9614",
        "JobTitle": "helper, sheeter – pulp and paper"
    },
    {
        "value": 28455,
        "NOCCode": "9614",
        "JobTitle": "helper, supercalender – pulp and paper"
    },
    {
        "value": 28456,
        "NOCCode": "9614",
        "JobTitle": "helper, timber-treating tank"
    },
    {
        "value": 28457,
        "NOCCode": "9614",
        "JobTitle": "hog machine tender – wood processing"
    },
    {
        "value": 28458,
        "NOCCode": "9614",
        "JobTitle": "hot plate press tender helper"
    },
    {
        "value": 28459,
        "NOCCode": "9614",
        "JobTitle": "jack ladder tender"
    },
    {
        "value": 28460,
        "NOCCode": "9614",
        "JobTitle": "labourer – pulp and paper"
    },
    {
        "value": 28461,
        "NOCCode": "9614",
        "JobTitle": "labourer – wood processing"
    },
    {
        "value": 28462,
        "NOCCode": "9614",
        "JobTitle": "labourer, paper converting"
    },
    {
        "value": 28463,
        "NOCCode": "9614",
        "JobTitle": "labourer, plywood and veneer plant"
    },
    {
        "value": 28464,
        "NOCCode": "9614",
        "JobTitle": "labourer, sawmill"
    },
    {
        "value": 28465,
        "NOCCode": "9614",
        "JobTitle": "labourer, wood-preserving plant"
    },
    {
        "value": 28466,
        "NOCCode": "9614",
        "JobTitle": "leverman/woman – wood processing"
    },
    {
        "value": 28467,
        "NOCCode": "9614",
        "JobTitle": "lime kiln helper – pulp and paper"
    },
    {
        "value": 28468,
        "NOCCode": "9614",
        "JobTitle": "log deck tender"
    },
    {
        "value": 28469,
        "NOCCode": "9614",
        "JobTitle": "log feeder"
    },
    {
        "value": 28470,
        "NOCCode": "9614",
        "JobTitle": "log handler"
    },
    {
        "value": 28471,
        "NOCCode": "9614",
        "JobTitle": "log peeler, hand"
    },
    {
        "value": 28472,
        "NOCCode": "9614",
        "JobTitle": "log transfer tender"
    },
    {
        "value": 28473,
        "NOCCode": "9614",
        "JobTitle": "lumber feeder – wood processing"
    },
    {
        "value": 28474,
        "NOCCode": "9614",
        "JobTitle": "lumber handler – wood processing"
    },
    {
        "value": 28475,
        "NOCCode": "9614",
        "JobTitle": "lumber salvager – wood processing"
    },
    {
        "value": 28476,
        "NOCCode": "9614",
        "JobTitle": "lumber straightener – wood processing"
    },
    {
        "value": 28477,
        "NOCCode": "9614",
        "JobTitle": "lumber tailer – wood processing"
    },
    {
        "value": 28478,
        "NOCCode": "9614",
        "JobTitle": "lumber tier – wood processing"
    },
    {
        "value": 28479,
        "NOCCode": "9614",
        "JobTitle": "operator helper, beater – pulp and paper"
    },
    {
        "value": 28480,
        "NOCCode": "9614",
        "JobTitle": "paper sorter and counter"
    },
    {
        "value": 28481,
        "NOCCode": "9614",
        "JobTitle": "paper tube production helper"
    },
    {
        "value": 28482,
        "NOCCode": "9614",
        "JobTitle": "piler – wood processing"
    },
    {
        "value": 28483,
        "NOCCode": "9614",
        "JobTitle": "plywood and veneer plant labourer"
    },
    {
        "value": 28484,
        "NOCCode": "9614",
        "JobTitle": "plywood dryer feeder"
    },
    {
        "value": 28485,
        "NOCCode": "9614",
        "JobTitle": "pulp and paper labourer"
    },
    {
        "value": 28486,
        "NOCCode": "9614",
        "JobTitle": "pulp-log recleaner"
    },
    {
        "value": 28487,
        "NOCCode": "9614",
        "JobTitle": "pulp-washer helper"
    },
    {
        "value": 28488,
        "NOCCode": "9614",
        "JobTitle": "rag checker – pulp and paper"
    },
    {
        "value": 28489,
        "NOCCode": "9614",
        "JobTitle": "rag sorter – pulp and paper"
    },
    {
        "value": 28490,
        "NOCCode": "9614",
        "JobTitle": "recovery plant helper – pulp and paper"
    },
    {
        "value": 28491,
        "NOCCode": "9614",
        "JobTitle": "rollerman/woman – wood processing"
    },
    {
        "value": 28492,
        "NOCCode": "9614",
        "JobTitle": "salvager, lumber – wood processing"
    },
    {
        "value": 28493,
        "NOCCode": "9614",
        "JobTitle": "saw tailer – wood processing"
    },
    {
        "value": 28494,
        "NOCCode": "9614",
        "JobTitle": "sawmill labourer"
    },
    {
        "value": 28495,
        "NOCCode": "9614",
        "JobTitle": "sawmill worker"
    },
    {
        "value": 28496,
        "NOCCode": "9614",
        "JobTitle": "scrap piler – wood processing"
    },
    {
        "value": 28497,
        "NOCCode": "9614",
        "JobTitle": "screen cleaner – pulp and paper"
    },
    {
        "value": 28498,
        "NOCCode": "9614",
        "JobTitle": "shake packer"
    },
    {
        "value": 28499,
        "NOCCode": "9614",
        "JobTitle": "sheeter helper – pulp and paper"
    },
    {
        "value": 28500,
        "NOCCode": "9614",
        "JobTitle": "shingle trimmer"
    },
    {
        "value": 28501,
        "NOCCode": "9614",
        "JobTitle": "sixth hand – pulp and paper"
    },
    {
        "value": 28502,
        "NOCCode": "9614",
        "JobTitle": "stacker – wood processing"
    },
    {
        "value": 28503,
        "NOCCode": "9614",
        "JobTitle": "supercalender helper – pulp and paper"
    },
    {
        "value": 28504,
        "NOCCode": "9614",
        "JobTitle": "tender, hogger – wood processing"
    },
    {
        "value": 28505,
        "NOCCode": "9614",
        "JobTitle": "thrasher feeder"
    },
    {
        "value": 28506,
        "NOCCode": "9614",
        "JobTitle": "timber-treating tank helper"
    },
    {
        "value": 28507,
        "NOCCode": "9614",
        "JobTitle": "transfer equipment tender – wood processing"
    },
    {
        "value": 28508,
        "NOCCode": "9614",
        "JobTitle": "transfer man/woman – wood processing"
    },
    {
        "value": 28509,
        "NOCCode": "9614",
        "JobTitle": "trimmer loader – wood processing"
    },
    {
        "value": 28510,
        "NOCCode": "9614",
        "JobTitle": "utility man/woman – pulp and paper"
    },
    {
        "value": 28511,
        "NOCCode": "9614",
        "JobTitle": "veneer dryer feeder"
    },
    {
        "value": 28512,
        "NOCCode": "9614",
        "JobTitle": "wood chip unloader"
    },
    {
        "value": 28513,
        "NOCCode": "9614",
        "JobTitle": "wood processing labourer"
    },
    {
        "value": 28514,
        "NOCCode": "9614",
        "JobTitle": "wood room labourer – pulp and paper"
    },
    {
        "value": 28515,
        "NOCCode": "9614",
        "JobTitle": "wood-preserving plant labourer"
    },
    {
        "value": 28516,
        "NOCCode": "9614",
        "JobTitle": "worker, sawmill"
    },
    {
        "value": 28517,
        "NOCCode": "9614",
        "JobTitle": "yard labourer – pulp and paper"
    },
    {
        "value": 28518,
        "NOCCode": "9615",
        "JobTitle": "airbag extractor – rubber products manufacturing"
    },
    {
        "value": 28519,
        "NOCCode": "9615",
        "JobTitle": "aircraft tire tube balancer"
    },
    {
        "value": 28520,
        "NOCCode": "9615",
        "JobTitle": "baling machine tender – rubber and plastic products manufacturing"
    },
    {
        "value": 28521,
        "NOCCode": "9615",
        "JobTitle": "baling press tender – rubber and plastic products manufacturing"
    },
    {
        "value": 28522,
        "NOCCode": "9615",
        "JobTitle": "balloon collar roller – rubber products manufacturing"
    },
    {
        "value": 28523,
        "NOCCode": "9615",
        "JobTitle": "balloon form stripper and cleaner – rubber products manufacturing"
    },
    {
        "value": 28524,
        "NOCCode": "9615",
        "JobTitle": "balloon leacher and curer – rubber products manufacturing"
    },
    {
        "value": 28525,
        "NOCCode": "9615",
        "JobTitle": "bead picker – rubber products manufacturing"
    },
    {
        "value": 28526,
        "NOCCode": "9615",
        "JobTitle": "belt builder helper"
    },
    {
        "value": 28527,
        "NOCCode": "9615",
        "JobTitle": "belt builder helper – rubber products manufacturing"
    },
    {
        "value": 28528,
        "NOCCode": "9615",
        "JobTitle": "bias cutting machine operator helper – rubber products manufacturing"
    },
    {
        "value": 28529,
        "NOCCode": "9615",
        "JobTitle": "booker – rubber products manufacturing"
    },
    {
        "value": 28530,
        "NOCCode": "9615",
        "JobTitle": "builder helper, belts – rubber products manufacturing"
    },
    {
        "value": 28531,
        "NOCCode": "9615",
        "JobTitle": "calender feeder – rubber and plastic products manufacturing"
    },
    {
        "value": 28532,
        "NOCCode": "9615",
        "JobTitle": "cementer, hand – rubber and plastic products manufacturing"
    },
    {
        "value": 28533,
        "NOCCode": "9615",
        "JobTitle": "cementer, tire repair – rubber products manufacturing"
    },
    {
        "value": 28534,
        "NOCCode": "9615",
        "JobTitle": "cleaner, moulds – rubber products manufacturing"
    },
    {
        "value": 28535,
        "NOCCode": "9615",
        "JobTitle": "cleaner, tires – rubber products manufacturing"
    },
    {
        "value": 28536,
        "NOCCode": "9615",
        "JobTitle": "coating machine feeder – plastic products manufacturing"
    },
    {
        "value": 28537,
        "NOCCode": "9615",
        "JobTitle": "coating machine feeder – rubber products manufacturing"
    },
    {
        "value": 28538,
        "NOCCode": "9615",
        "JobTitle": "extruding machine helper – rubber and plastic products manufacturing"
    },
    {
        "value": 28539,
        "NOCCode": "9615",
        "JobTitle": "feeder, opening machine – rubber products manufacturing"
    },
    {
        "value": 28540,
        "NOCCode": "9615",
        "JobTitle": "feeder, rubber-cutting machine"
    },
    {
        "value": 28541,
        "NOCCode": "9615",
        "JobTitle": "footwear moulding press feeder – rubber products manufacturing"
    },
    {
        "value": 28542,
        "NOCCode": "9615",
        "JobTitle": "footwear sorter – rubber products manufacturing"
    },
    {
        "value": 28543,
        "NOCCode": "9615",
        "JobTitle": "formula weigher – rubber and plastic products manufacturing"
    },
    {
        "value": 28544,
        "NOCCode": "9615",
        "JobTitle": "hand cementer – rubber and plastic products manufacturing"
    },
    {
        "value": 28545,
        "NOCCode": "9615",
        "JobTitle": "helper, belt builder – rubber products manufacturing"
    },
    {
        "value": 28546,
        "NOCCode": "9615",
        "JobTitle": "helper, bias machine operator – rubber products manufacturing"
    },
    {
        "value": 28547,
        "NOCCode": "9615",
        "JobTitle": "helper, extruding machine – rubber and plastic products manufacturing"
    },
    {
        "value": 28548,
        "NOCCode": "9615",
        "JobTitle": "helper, plastic products manufacturing machine operator"
    },
    {
        "value": 28549,
        "NOCCode": "9615",
        "JobTitle": "helper, rubber products manufacturing"
    },
    {
        "value": 28550,
        "NOCCode": "9615",
        "JobTitle": "helper, rubber products manufacturing machine operator"
    },
    {
        "value": 28551,
        "NOCCode": "9615",
        "JobTitle": "hose checker – rubber products manufacturing"
    },
    {
        "value": 28552,
        "NOCCode": "9615",
        "JobTitle": "hose cutter, hand – rubber products manufacturing"
    },
    {
        "value": 28553,
        "NOCCode": "9615",
        "JobTitle": "inner tube checker – rubber products manufacturing"
    },
    {
        "value": 28554,
        "NOCCode": "9615",
        "JobTitle": "inner tube inserter – rubber products manufacturing"
    },
    {
        "value": 28555,
        "NOCCode": "9615",
        "JobTitle": "inner tube repairer – rubber products manufacturing"
    },
    {
        "value": 28556,
        "NOCCode": "9615",
        "JobTitle": "inserter, inner tubes – rubber products manufacturing"
    },
    {
        "value": 28557,
        "NOCCode": "9615",
        "JobTitle": "inserter, tire tubes – rubber products manufacturing"
    },
    {
        "value": 28558,
        "NOCCode": "9615",
        "JobTitle": "inspector, golf balls – rubber products manufacturing"
    },
    {
        "value": 28559,
        "NOCCode": "9615",
        "JobTitle": "insulation packer – rubber products manufacturing"
    },
    {
        "value": 28560,
        "NOCCode": "9615",
        "JobTitle": "labourer – plastic products manufacturing"
    },
    {
        "value": 28561,
        "NOCCode": "9615",
        "JobTitle": "labourer – rubber products manufacturing"
    },
    {
        "value": 28562,
        "NOCCode": "9615",
        "JobTitle": "labourer, plastic products manufacturing"
    },
    {
        "value": 28563,
        "NOCCode": "9615",
        "JobTitle": "latex thread spooler – rubber products manufacturing"
    },
    {
        "value": 28564,
        "NOCCode": "9615",
        "JobTitle": "machine feeder, rubber cutting"
    },
    {
        "value": 28565,
        "NOCCode": "9615",
        "JobTitle": "machine offbearer – rubber products manufacturing"
    },
    {
        "value": 28566,
        "NOCCode": "9615",
        "JobTitle": "machine operator helper, plastic products manufacturing"
    },
    {
        "value": 28567,
        "NOCCode": "9615",
        "JobTitle": "machine operator helper, rubber products manufacturing"
    },
    {
        "value": 28568,
        "NOCCode": "9615",
        "JobTitle": "milled rubber cooler"
    },
    {
        "value": 28569,
        "NOCCode": "9615",
        "JobTitle": "mould cleaner – rubber and plastic products manufacturing"
    },
    {
        "value": 28570,
        "NOCCode": "9615",
        "JobTitle": "mould cleaner – rubber products manufacturing"
    },
    {
        "value": 28571,
        "NOCCode": "9615",
        "JobTitle": "mould filler – rubber and plastic products manufacturing"
    },
    {
        "value": 28572,
        "NOCCode": "9615",
        "JobTitle": "mould preparer – rubber and plastic products manufacturing"
    },
    {
        "value": 28573,
        "NOCCode": "9615",
        "JobTitle": "mould setter – rubber and plastic products manufacturing"
    },
    {
        "value": 28574,
        "NOCCode": "9615",
        "JobTitle": "mould waxer – rubber and plastic products manufacturing"
    },
    {
        "value": 28575,
        "NOCCode": "9615",
        "JobTitle": "moulded products stripper – rubber and plastic products manufacturing"
    },
    {
        "value": 28576,
        "NOCCode": "9615",
        "JobTitle": "moulded rubber products trimmer"
    },
    {
        "value": 28577,
        "NOCCode": "9615",
        "JobTitle": "moulding machine offbearer – rubber products manufacturing"
    },
    {
        "value": 28578,
        "NOCCode": "9615",
        "JobTitle": "moulding press feeder, footwear – rubber products manufacturing"
    },
    {
        "value": 28579,
        "NOCCode": "9615",
        "JobTitle": "offbearer, machine – rubber products manufacturing"
    },
    {
        "value": 28580,
        "NOCCode": "9615",
        "JobTitle": "offbearer, moulding machine – rubber products manufacturing"
    },
    {
        "value": 28581,
        "NOCCode": "9615",
        "JobTitle": "opening machine feeder – rubber products manufacturing"
    },
    {
        "value": 28582,
        "NOCCode": "9615",
        "JobTitle": "packer, insulation – rubber products manufacturing"
    },
    {
        "value": 28583,
        "NOCCode": "9615",
        "JobTitle": "picker, beam press – rubber products manufacturing"
    },
    {
        "value": 28584,
        "NOCCode": "9615",
        "JobTitle": "plastic products manufacturing labourer"
    },
    {
        "value": 28585,
        "NOCCode": "9615",
        "JobTitle": "pole-up man/woman – rubber products manufacturing"
    },
    {
        "value": 28586,
        "NOCCode": "9615",
        "JobTitle": "pressroom worker, tires and inner tubes – rubber products manufacturing"
    },
    {
        "value": 28587,
        "NOCCode": "9615",
        "JobTitle": "production day labourer – tire industry"
    },
    {
        "value": 28588,
        "NOCCode": "9615",
        "JobTitle": "repairer, inner tubes – rubber products manufacturing"
    },
    {
        "value": 28589,
        "NOCCode": "9615",
        "JobTitle": "repairer, tire tubes – rubber products manufacturing"
    },
    {
        "value": 28590,
        "NOCCode": "9615",
        "JobTitle": "roll changer – rubber manufacturing"
    },
    {
        "value": 28591,
        "NOCCode": "9615",
        "JobTitle": "rubber and canvas footwear dipper"
    },
    {
        "value": 28592,
        "NOCCode": "9615",
        "JobTitle": "rubber compound weigher"
    },
    {
        "value": 28593,
        "NOCCode": "9615",
        "JobTitle": "rubber compounder"
    },
    {
        "value": 28594,
        "NOCCode": "9615",
        "JobTitle": "rubber goods seamer"
    },
    {
        "value": 28595,
        "NOCCode": "9615",
        "JobTitle": "rubber hose checker"
    },
    {
        "value": 28596,
        "NOCCode": "9615",
        "JobTitle": "rubber manufacturing roll changer"
    },
    {
        "value": 28597,
        "NOCCode": "9615",
        "JobTitle": "rubber products manufacturing labourer"
    },
    {
        "value": 28598,
        "NOCCode": "9615",
        "JobTitle": "rubber products seamer"
    },
    {
        "value": 28599,
        "NOCCode": "9615",
        "JobTitle": "rubber-cutting machine feeder"
    },
    {
        "value": 28600,
        "NOCCode": "9615",
        "JobTitle": "screen cleaner – rubber products manufacturing"
    },
    {
        "value": 28601,
        "NOCCode": "9615",
        "JobTitle": "screening machine feeder – rubber products manufacturing"
    },
    {
        "value": 28602,
        "NOCCode": "9615",
        "JobTitle": "sifting machine feeder – rubber products manufacturing"
    },
    {
        "value": 28603,
        "NOCCode": "9615",
        "JobTitle": "slab-off machine tender – rubber products manufacturing"
    },
    {
        "value": 28604,
        "NOCCode": "9615",
        "JobTitle": "slab-off man/woman – rubber products manufacturing"
    },
    {
        "value": 28605,
        "NOCCode": "9615",
        "JobTitle": "sorter – rubber products manufacturing"
    },
    {
        "value": 28606,
        "NOCCode": "9615",
        "JobTitle": "sorter, footwear – rubber products manufacturing"
    },
    {
        "value": 28607,
        "NOCCode": "9615",
        "JobTitle": "spooler, latex threads – rubber products manufacturing"
    },
    {
        "value": 28608,
        "NOCCode": "9615",
        "JobTitle": "spreader operator helper – plastic products manufacturing"
    },
    {
        "value": 28609,
        "NOCCode": "9615",
        "JobTitle": "stock lay-up man/woman – rubber products manufacturing"
    },
    {
        "value": 28610,
        "NOCCode": "9615",
        "JobTitle": "stock preparer – rubber products manufacturing"
    },
    {
        "value": 28611,
        "NOCCode": "9615",
        "JobTitle": "stripper, moulded products – rubber and plastic products manufacturing"
    },
    {
        "value": 28612,
        "NOCCode": "9615",
        "JobTitle": "tender, baling machine – rubber and plastic products manufacturing"
    },
    {
        "value": 28613,
        "NOCCode": "9615",
        "JobTitle": "tender, baling press – rubber and plastic products manufacturing"
    },
    {
        "value": 28614,
        "NOCCode": "9615",
        "JobTitle": "tire balance checker – rubber products manufacturing"
    },
    {
        "value": 28615,
        "NOCCode": "9615",
        "JobTitle": "tire band wrapper – rubber products manufacturing"
    },
    {
        "value": 28616,
        "NOCCode": "9615",
        "JobTitle": "tire bead picker – rubber products manufacturing"
    },
    {
        "value": 28617,
        "NOCCode": "9615",
        "JobTitle": "tire cleaner – rubber products manufacturing"
    },
    {
        "value": 28618,
        "NOCCode": "9615",
        "JobTitle": "tire cleaner and painter – rubber products manufacturing"
    },
    {
        "value": 28619,
        "NOCCode": "9615",
        "JobTitle": "tire measurer – rubber products manufacturing"
    },
    {
        "value": 28620,
        "NOCCode": "9615",
        "JobTitle": "tire tube checker – rubber products manufacturing"
    },
    {
        "value": 28621,
        "NOCCode": "9615",
        "JobTitle": "tire tube inserter – rubber products manufacturing"
    },
    {
        "value": 28622,
        "NOCCode": "9615",
        "JobTitle": "tire tube repairer – rubber products manufacturing"
    },
    {
        "value": 28623,
        "NOCCode": "9615",
        "JobTitle": "vulcanization worker – rubber products manufacturing"
    },
    {
        "value": 28624,
        "NOCCode": "9615",
        "JobTitle": "weigher – plastic and rubber manufacturing"
    },
    {
        "value": 28625,
        "NOCCode": "9616",
        "JobTitle": "bale checker – textile processing"
    },
    {
        "value": 28626,
        "NOCCode": "9616",
        "JobTitle": "battery loader – textile processing"
    },
    {
        "value": 28627,
        "NOCCode": "9616",
        "JobTitle": "beam warper helper – textile processing"
    },
    {
        "value": 28628,
        "NOCCode": "9616",
        "JobTitle": "bleacher helper – textile processing"
    },
    {
        "value": 28629,
        "NOCCode": "9616",
        "JobTitle": "bobbin changer – textile processing"
    },
    {
        "value": 28630,
        "NOCCode": "9616",
        "JobTitle": "bobbin cleaner – textile processing"
    },
    {
        "value": 28631,
        "NOCCode": "9616",
        "JobTitle": "bobbin collector – textile processing"
    },
    {
        "value": 28632,
        "NOCCode": "9616",
        "JobTitle": "bobbin processor"
    },
    {
        "value": 28633,
        "NOCCode": "9616",
        "JobTitle": "bobbin scraper – textile processing"
    },
    {
        "value": 28634,
        "NOCCode": "9616",
        "JobTitle": "bobbin sorter – textile processing"
    },
    {
        "value": 28635,
        "NOCCode": "9616",
        "JobTitle": "calender helper – textile processing"
    },
    {
        "value": 28636,
        "NOCCode": "9616",
        "JobTitle": "can doffer – textile processing"
    },
    {
        "value": 28637,
        "NOCCode": "9616",
        "JobTitle": "carding machine cleaner – textile processing"
    },
    {
        "value": 28638,
        "NOCCode": "9616",
        "JobTitle": "chainer – textile processing"
    },
    {
        "value": 28639,
        "NOCCode": "9616",
        "JobTitle": "cleaner, textile machines"
    },
    {
        "value": 28640,
        "NOCCode": "9616",
        "JobTitle": "cloth carrier"
    },
    {
        "value": 28641,
        "NOCCode": "9616",
        "JobTitle": "cloth folder, hand"
    },
    {
        "value": 28642,
        "NOCCode": "9616",
        "JobTitle": "cloth offbearer"
    },
    {
        "value": 28643,
        "NOCCode": "9616",
        "JobTitle": "cloth turner"
    },
    {
        "value": 28644,
        "NOCCode": "9616",
        "JobTitle": "cloth winder"
    },
    {
        "value": 28645,
        "NOCCode": "9616",
        "JobTitle": "creeler – textile processing"
    },
    {
        "value": 28646,
        "NOCCode": "9616",
        "JobTitle": "doffer"
    },
    {
        "value": 28647,
        "NOCCode": "9616",
        "JobTitle": "drying machine helper – textile processing"
    },
    {
        "value": 28648,
        "NOCCode": "9616",
        "JobTitle": "drying machine tender – textile processing"
    },
    {
        "value": 28649,
        "NOCCode": "9616",
        "JobTitle": "dyeing and finishing machine loader"
    },
    {
        "value": 28650,
        "NOCCode": "9616",
        "JobTitle": "dyer helper – textile processing"
    },
    {
        "value": 28651,
        "NOCCode": "9616",
        "JobTitle": "feeder, textile machines"
    },
    {
        "value": 28652,
        "NOCCode": "9616",
        "JobTitle": "felt goods labourer"
    },
    {
        "value": 28653,
        "NOCCode": "9616",
        "JobTitle": "felt press machine tender"
    },
    {
        "value": 28654,
        "NOCCode": "9616",
        "JobTitle": "filling carrier – textile processing"
    },
    {
        "value": 28655,
        "NOCCode": "9616",
        "JobTitle": "floor helper – textile processing"
    },
    {
        "value": 28656,
        "NOCCode": "9616",
        "JobTitle": "hand skeiner – textile processing"
    },
    {
        "value": 28657,
        "NOCCode": "9616",
        "JobTitle": "harness cleaner – textile processing"
    },
    {
        "value": 28658,
        "NOCCode": "9616",
        "JobTitle": "helper – textiles"
    },
    {
        "value": 28659,
        "NOCCode": "9616",
        "JobTitle": "hosiery folder"
    },
    {
        "value": 28660,
        "NOCCode": "9616",
        "JobTitle": "hosiery tray dryer"
    },
    {
        "value": 28661,
        "NOCCode": "9616",
        "JobTitle": "knitted fabric turner"
    },
    {
        "value": 28662,
        "NOCCode": "9616",
        "JobTitle": "knitter helper"
    },
    {
        "value": 28663,
        "NOCCode": "9616",
        "JobTitle": "knitter helper, machine"
    },
    {
        "value": 28664,
        "NOCCode": "9616",
        "JobTitle": "labourer – textile processing"
    },
    {
        "value": 28665,
        "NOCCode": "9616",
        "JobTitle": "labourer, felt goods"
    },
    {
        "value": 28666,
        "NOCCode": "9616",
        "JobTitle": "labourer, woollen mill"
    },
    {
        "value": 28667,
        "NOCCode": "9616",
        "JobTitle": "loom cleaner"
    },
    {
        "value": 28668,
        "NOCCode": "9616",
        "JobTitle": "loom doffer – textile processing"
    },
    {
        "value": 28669,
        "NOCCode": "9616",
        "JobTitle": "machine knitter helper"
    },
    {
        "value": 28670,
        "NOCCode": "9616",
        "JobTitle": "offbearer, textile machines"
    },
    {
        "value": 28671,
        "NOCCode": "9616",
        "JobTitle": "pick remover – textile processing"
    },
    {
        "value": 28672,
        "NOCCode": "9616",
        "JobTitle": "pick-pulling machine tender"
    },
    {
        "value": 28673,
        "NOCCode": "9616",
        "JobTitle": "piece hauler – textile processing"
    },
    {
        "value": 28674,
        "NOCCode": "9616",
        "JobTitle": "rag sorter and cutter – textile processing"
    },
    {
        "value": 28675,
        "NOCCode": "9616",
        "JobTitle": "reed cleaner – textile processing"
    },
    {
        "value": 28676,
        "NOCCode": "9616",
        "JobTitle": "roll carrier – textile processing"
    },
    {
        "value": 28677,
        "NOCCode": "9616",
        "JobTitle": "roll hauler – textile processing"
    },
    {
        "value": 28678,
        "NOCCode": "9616",
        "JobTitle": "roll turner – textile processing"
    },
    {
        "value": 28679,
        "NOCCode": "9616",
        "JobTitle": "roller cleaner – textile processing"
    },
    {
        "value": 28680,
        "NOCCode": "9616",
        "JobTitle": "roll-mat machine feeder – textile processing"
    },
    {
        "value": 28681,
        "NOCCode": "9616",
        "JobTitle": "sample steamer"
    },
    {
        "value": 28682,
        "NOCCode": "9616",
        "JobTitle": "scutcher tender"
    },
    {
        "value": 28683,
        "NOCCode": "9616",
        "JobTitle": "shuttle filler – textile processing"
    },
    {
        "value": 28684,
        "NOCCode": "9616",
        "JobTitle": "slasher operator helper – textile processing"
    },
    {
        "value": 28685,
        "NOCCode": "9616",
        "JobTitle": "spool carrier – textile processing"
    },
    {
        "value": 28686,
        "NOCCode": "9616",
        "JobTitle": "spool hauler – textile processing"
    },
    {
        "value": 28687,
        "NOCCode": "9616",
        "JobTitle": "spun yarn linker"
    },
    {
        "value": 28688,
        "NOCCode": "9616",
        "JobTitle": "spun yarn storekeeper"
    },
    {
        "value": 28689,
        "NOCCode": "9616",
        "JobTitle": "spun yarn weigher"
    },
    {
        "value": 28690,
        "NOCCode": "9616",
        "JobTitle": "textile machine cleaner"
    },
    {
        "value": 28691,
        "NOCCode": "9616",
        "JobTitle": "textile machine feeder"
    },
    {
        "value": 28692,
        "NOCCode": "9616",
        "JobTitle": "textile machine offbearer"
    },
    {
        "value": 28693,
        "NOCCode": "9616",
        "JobTitle": "textile product labourer"
    },
    {
        "value": 28694,
        "NOCCode": "9616",
        "JobTitle": "traveller changer"
    },
    {
        "value": 28695,
        "NOCCode": "9616",
        "JobTitle": "tubing folder – textile processing"
    },
    {
        "value": 28696,
        "NOCCode": "9616",
        "JobTitle": "twisted end finder – textile processing"
    },
    {
        "value": 28697,
        "NOCCode": "9616",
        "JobTitle": "warp beamer helper – textile processing"
    },
    {
        "value": 28698,
        "NOCCode": "9616",
        "JobTitle": "waste machine feeder"
    },
    {
        "value": 28699,
        "NOCCode": "9616",
        "JobTitle": "waste machine feeder – textile processing"
    },
    {
        "value": 28700,
        "NOCCode": "9616",
        "JobTitle": "winder cleaner – textile processing"
    },
    {
        "value": 28701,
        "NOCCode": "9616",
        "JobTitle": "winding frame cleaner – textile processing"
    },
    {
        "value": 28702,
        "NOCCode": "9616",
        "JobTitle": "yarn handler"
    },
    {
        "value": 28703,
        "NOCCode": "9616",
        "JobTitle": "yarn salvager"
    },
    {
        "value": 28704,
        "NOCCode": "9617",
        "JobTitle": "animal feed sack filler"
    },
    {
        "value": 28705,
        "NOCCode": "9617",
        "JobTitle": "bagger – food and beverage processing"
    },
    {
        "value": 28706,
        "NOCCode": "9617",
        "JobTitle": "baker helper"
    },
    {
        "value": 28707,
        "NOCCode": "9617",
        "JobTitle": "bakery labourer"
    },
    {
        "value": 28708,
        "NOCCode": "9617",
        "JobTitle": "bakery products bander"
    },
    {
        "value": 28709,
        "NOCCode": "9617",
        "JobTitle": "bakery products divider – food and beverage processing"
    },
    {
        "value": 28710,
        "NOCCode": "9617",
        "JobTitle": "bakery products packager"
    },
    {
        "value": 28711,
        "NOCCode": "9617",
        "JobTitle": "bakery products wrapper"
    },
    {
        "value": 28712,
        "NOCCode": "9617",
        "JobTitle": "banding machine tender – tobacco products"
    },
    {
        "value": 28713,
        "NOCCode": "9617",
        "JobTitle": "barrel cleaner – food and beverage processing"
    },
    {
        "value": 28714,
        "NOCCode": "9617",
        "JobTitle": "barrel filler – food and beverage processing"
    },
    {
        "value": 28715,
        "NOCCode": "9617",
        "JobTitle": "beef marker – food and beverage processing"
    },
    {
        "value": 28716,
        "NOCCode": "9617",
        "JobTitle": "beer sampler – food and beverage processing"
    },
    {
        "value": 28717,
        "NOCCode": "9617",
        "JobTitle": "beet washer"
    },
    {
        "value": 28718,
        "NOCCode": "9617",
        "JobTitle": "beet washer helper"
    },
    {
        "value": 28719,
        "NOCCode": "9617",
        "JobTitle": "bin cleaner – food and beverage processing"
    },
    {
        "value": 28720,
        "NOCCode": "9617",
        "JobTitle": "biscuit packer"
    },
    {
        "value": 28721,
        "NOCCode": "9617",
        "JobTitle": "blender and packer – food and beverage processing"
    },
    {
        "value": 28722,
        "NOCCode": "9617",
        "JobTitle": "blender and wrapper – food and beverage processing"
    },
    {
        "value": 28723,
        "NOCCode": "9617",
        "JobTitle": "bolter helper – food and beverage processing"
    },
    {
        "value": 28724,
        "NOCCode": "9617",
        "JobTitle": "bottle caser – food and beverage processing"
    },
    {
        "value": 28725,
        "NOCCode": "9617",
        "JobTitle": "bottle checker – food and beverage processing"
    },
    {
        "value": 28726,
        "NOCCode": "9617",
        "JobTitle": "bottle washer"
    },
    {
        "value": 28727,
        "NOCCode": "9617",
        "JobTitle": "bottle washer – food and beverage processing"
    },
    {
        "value": 28728,
        "NOCCode": "9617",
        "JobTitle": "bottler"
    },
    {
        "value": 28729,
        "NOCCode": "9617",
        "JobTitle": "bottle-washing machine tender – food and beverage processing"
    },
    {
        "value": 28730,
        "NOCCode": "9617",
        "JobTitle": "bottling line worker"
    },
    {
        "value": 28731,
        "NOCCode": "9617",
        "JobTitle": "box stitcher – food and beverage processing"
    },
    {
        "value": 28732,
        "NOCCode": "9617",
        "JobTitle": "bread pan greaser – food and beverage processing"
    },
    {
        "value": 28733,
        "NOCCode": "9617",
        "JobTitle": "brewery labourer"
    },
    {
        "value": 28734,
        "NOCCode": "9617",
        "JobTitle": "butcher helper – meat processing"
    },
    {
        "value": 28735,
        "NOCCode": "9617",
        "JobTitle": "butter packer"
    },
    {
        "value": 28736,
        "NOCCode": "9617",
        "JobTitle": "butter sampler"
    },
    {
        "value": 28737,
        "NOCCode": "9617",
        "JobTitle": "butter wrapper"
    },
    {
        "value": 28738,
        "NOCCode": "9617",
        "JobTitle": "cake wrapper"
    },
    {
        "value": 28739,
        "NOCCode": "9617",
        "JobTitle": "candy dipper, hand – food and beverage processing"
    },
    {
        "value": 28740,
        "NOCCode": "9617",
        "JobTitle": "candy maker helper"
    },
    {
        "value": 28741,
        "NOCCode": "9617",
        "JobTitle": "candy packer"
    },
    {
        "value": 28742,
        "NOCCode": "9617",
        "JobTitle": "candy wrapper"
    },
    {
        "value": 28743,
        "NOCCode": "9617",
        "JobTitle": "cannery packer"
    },
    {
        "value": 28744,
        "NOCCode": "9617",
        "JobTitle": "cannery worker – food and beverage processing"
    },
    {
        "value": 28745,
        "NOCCode": "9617",
        "JobTitle": "carcass washer – food and beverage processing"
    },
    {
        "value": 28746,
        "NOCCode": "9617",
        "JobTitle": "carcass weigher – food and beverage processing"
    },
    {
        "value": 28747,
        "NOCCode": "9617",
        "JobTitle": "carton bander"
    },
    {
        "value": 28748,
        "NOCCode": "9617",
        "JobTitle": "carton package stapler"
    },
    {
        "value": 28749,
        "NOCCode": "9617",
        "JobTitle": "carton-packaging machine tender – food and beverage processing"
    },
    {
        "value": 28750,
        "NOCCode": "9617",
        "JobTitle": "case stacker – food and beverage processing"
    },
    {
        "value": 28751,
        "NOCCode": "9617",
        "JobTitle": "caser – tobacco processing"
    },
    {
        "value": 28752,
        "NOCCode": "9617",
        "JobTitle": "casing salter – food and beverage processing"
    },
    {
        "value": 28753,
        "NOCCode": "9617",
        "JobTitle": "casing shirrer – food and beverage processing"
    },
    {
        "value": 28754,
        "NOCCode": "9617",
        "JobTitle": "casing stripper – food and beverage processing"
    },
    {
        "value": 28755,
        "NOCCode": "9617",
        "JobTitle": "cellophaner – tobacco products"
    },
    {
        "value": 28756,
        "NOCCode": "9617",
        "JobTitle": "centrifuge tender – food and beverage processing"
    },
    {
        "value": 28757,
        "NOCCode": "9617",
        "JobTitle": "centrifuge tender – meat packing plant"
    },
    {
        "value": 28758,
        "NOCCode": "9617",
        "JobTitle": "char filter helper – food and beverage processing"
    },
    {
        "value": 28759,
        "NOCCode": "9617",
        "JobTitle": "char kiln helper – food and beverage processing"
    },
    {
        "value": 28760,
        "NOCCode": "9617",
        "JobTitle": "cheese cutter"
    },
    {
        "value": 28761,
        "NOCCode": "9617",
        "JobTitle": "cheese factory worker"
    },
    {
        "value": 28762,
        "NOCCode": "9617",
        "JobTitle": "cheese maker helper"
    },
    {
        "value": 28763,
        "NOCCode": "9617",
        "JobTitle": "chewing gum batch mixer"
    },
    {
        "value": 28764,
        "NOCCode": "9617",
        "JobTitle": "chewing gum production attendant"
    },
    {
        "value": 28765,
        "NOCCode": "9617",
        "JobTitle": "chicle-grinder feeder – food and beverage processing"
    },
    {
        "value": 28766,
        "NOCCode": "9617",
        "JobTitle": "chilling worker – food and beverage processing"
    },
    {
        "value": 28767,
        "NOCCode": "9617",
        "JobTitle": "chocolate packer, hand"
    },
    {
        "value": 28768,
        "NOCCode": "9617",
        "JobTitle": "chocolate processing helper"
    },
    {
        "value": 28769,
        "NOCCode": "9617",
        "JobTitle": "cigar bander"
    },
    {
        "value": 28770,
        "NOCCode": "9617",
        "JobTitle": "cigar brander"
    },
    {
        "value": 28771,
        "NOCCode": "9617",
        "JobTitle": "cigar wrapper"
    },
    {
        "value": 28772,
        "NOCCode": "9617",
        "JobTitle": "cigarette packer"
    },
    {
        "value": 28773,
        "NOCCode": "9617",
        "JobTitle": "cigar-wrapping machine tender"
    },
    {
        "value": 28774,
        "NOCCode": "9617",
        "JobTitle": "cocoa bean cleaner"
    },
    {
        "value": 28775,
        "NOCCode": "9617",
        "JobTitle": "cocoa bean press-mill helper"
    },
    {
        "value": 28776,
        "NOCCode": "9617",
        "JobTitle": "cocoa bean roaster helper"
    },
    {
        "value": 28777,
        "NOCCode": "9617",
        "JobTitle": "cocoa powder mixer"
    },
    {
        "value": 28778,
        "NOCCode": "9617",
        "JobTitle": "coffee-blending machine feeder"
    },
    {
        "value": 28779,
        "NOCCode": "9617",
        "JobTitle": "confection labourer – food and beverage processing"
    },
    {
        "value": 28780,
        "NOCCode": "9617",
        "JobTitle": "container washer, machine – food and beverage processing"
    },
    {
        "value": 28781,
        "NOCCode": "9617",
        "JobTitle": "corn syrup processing labourer"
    },
    {
        "value": 28782,
        "NOCCode": "9617",
        "JobTitle": "cream sampler – food and beverage processing"
    },
    {
        "value": 28783,
        "NOCCode": "9617",
        "JobTitle": "custom feed miller helper"
    },
    {
        "value": 28784,
        "NOCCode": "9617",
        "JobTitle": "dairy helper – food and beverage processing"
    },
    {
        "value": 28785,
        "NOCCode": "9617",
        "JobTitle": "dairy sampler – food and beverage processing"
    },
    {
        "value": 28786,
        "NOCCode": "9617",
        "JobTitle": "dough feeder – food and beverage processing"
    },
    {
        "value": 28787,
        "NOCCode": "9617",
        "JobTitle": "dough roller"
    },
    {
        "value": 28788,
        "NOCCode": "9617",
        "JobTitle": "dough weigher"
    },
    {
        "value": 28789,
        "NOCCode": "9617",
        "JobTitle": "dry curer – food and beverage processing"
    },
    {
        "value": 28790,
        "NOCCode": "9617",
        "JobTitle": "dumping machine tender – food and beverage processing"
    },
    {
        "value": 28791,
        "NOCCode": "9617",
        "JobTitle": "edible oil processing labourer"
    },
    {
        "value": 28792,
        "NOCCode": "9617",
        "JobTitle": "edible oil refiner helper"
    },
    {
        "value": 28793,
        "NOCCode": "9617",
        "JobTitle": "egg breaker – food and beverage processing"
    },
    {
        "value": 28794,
        "NOCCode": "9617",
        "JobTitle": "filler – food and beverage processing"
    },
    {
        "value": 28795,
        "NOCCode": "9617",
        "JobTitle": "filler, animal feed sacks"
    },
    {
        "value": 28796,
        "NOCCode": "9617",
        "JobTitle": "film-coating machine tender – tobacco products"
    },
    {
        "value": 28797,
        "NOCCode": "9617",
        "JobTitle": "flour mixer helper"
    },
    {
        "value": 28798,
        "NOCCode": "9617",
        "JobTitle": "flumer helper – food and beverage processing"
    },
    {
        "value": 28799,
        "NOCCode": "9617",
        "JobTitle": "food processing labourer"
    },
    {
        "value": 28800,
        "NOCCode": "9617",
        "JobTitle": "food product sampler"
    },
    {
        "value": 28801,
        "NOCCode": "9617",
        "JobTitle": "food-cooling pan tender"
    },
    {
        "value": 28802,
        "NOCCode": "9617",
        "JobTitle": "food-processing equipment cleaner"
    },
    {
        "value": 28803,
        "NOCCode": "9617",
        "JobTitle": "freezer worker – food and beverage processing"
    },
    {
        "value": 28804,
        "NOCCode": "9617",
        "JobTitle": "freezing worker – food and beverage processing"
    },
    {
        "value": 28805,
        "NOCCode": "9617",
        "JobTitle": "frozen food packer"
    },
    {
        "value": 28806,
        "NOCCode": "9617",
        "JobTitle": "frozen meat cutter helper"
    },
    {
        "value": 28807,
        "NOCCode": "9617",
        "JobTitle": "fruit and vegetable cleaner – food and beverage processing"
    },
    {
        "value": 28808,
        "NOCCode": "9617",
        "JobTitle": "fruit peeler – food and beverage processing"
    },
    {
        "value": 28809,
        "NOCCode": "9617",
        "JobTitle": "fruit preparer – food and beverage processing"
    },
    {
        "value": 28810,
        "NOCCode": "9617",
        "JobTitle": "fruit splitter"
    },
    {
        "value": 28811,
        "NOCCode": "9617",
        "JobTitle": "gambreler – food and beverage processing"
    },
    {
        "value": 28812,
        "NOCCode": "9617",
        "JobTitle": "gelatin stock cooker attendant"
    },
    {
        "value": 28813,
        "NOCCode": "9617",
        "JobTitle": "glucose transferrer – food and beverage processing"
    },
    {
        "value": 28814,
        "NOCCode": "9617",
        "JobTitle": "gluten settler – food and beverage processing"
    },
    {
        "value": 28815,
        "NOCCode": "9617",
        "JobTitle": "grain cleaner helper"
    },
    {
        "value": 28816,
        "NOCCode": "9617",
        "JobTitle": "hogshead filler – tobacco products"
    },
    {
        "value": 28817,
        "NOCCode": "9617",
        "JobTitle": "hoof remover – food and beverage processing"
    },
    {
        "value": 28818,
        "NOCCode": "9617",
        "JobTitle": "ice cream worker – food and beverage processing"
    },
    {
        "value": 28819,
        "NOCCode": "9617",
        "JobTitle": "icing dipper – food and beverage processing"
    },
    {
        "value": 28820,
        "NOCCode": "9617",
        "JobTitle": "industrial butcher helper"
    },
    {
        "value": 28821,
        "NOCCode": "9617",
        "JobTitle": "juice blender"
    },
    {
        "value": 28822,
        "NOCCode": "9617",
        "JobTitle": "label coder – food and beverage processing"
    },
    {
        "value": 28823,
        "NOCCode": "9617",
        "JobTitle": "labourer – food and beverage processing"
    },
    {
        "value": 28824,
        "NOCCode": "9617",
        "JobTitle": "labourer, meat-packing plant"
    },
    {
        "value": 28825,
        "NOCCode": "9617",
        "JobTitle": "labourer, syrup-processing plant"
    },
    {
        "value": 28826,
        "NOCCode": "9617",
        "JobTitle": "labourer, tobacco processing"
    },
    {
        "value": 28827,
        "NOCCode": "9617",
        "JobTitle": "labourer, yeast-processing plant"
    },
    {
        "value": 28828,
        "NOCCode": "9617",
        "JobTitle": "lard bleacher – food and beverage processing"
    },
    {
        "value": 28829,
        "NOCCode": "9617",
        "JobTitle": "linking machine tender – food and beverage processing"
    },
    {
        "value": 28830,
        "NOCCode": "9617",
        "JobTitle": "liquor gallery tender helper – food and beverage processing"
    },
    {
        "value": 28831,
        "NOCCode": "9617",
        "JobTitle": "machine cigar wrapper"
    },
    {
        "value": 28832,
        "NOCCode": "9617",
        "JobTitle": "machine tobacco packer"
    },
    {
        "value": 28833,
        "NOCCode": "9617",
        "JobTitle": "machine wrapper, cigars"
    },
    {
        "value": 28834,
        "NOCCode": "9617",
        "JobTitle": "meat dryer"
    },
    {
        "value": 28835,
        "NOCCode": "9617",
        "JobTitle": "meat loaf trimmer"
    },
    {
        "value": 28836,
        "NOCCode": "9617",
        "JobTitle": "meat mould sealer"
    },
    {
        "value": 28837,
        "NOCCode": "9617",
        "JobTitle": "meat mould stripper"
    },
    {
        "value": 28838,
        "NOCCode": "9617",
        "JobTitle": "meat packager"
    },
    {
        "value": 28839,
        "NOCCode": "9617",
        "JobTitle": "meat packer – food and beverage processing"
    },
    {
        "value": 28840,
        "NOCCode": "9617",
        "JobTitle": "meat press tender"
    },
    {
        "value": 28841,
        "NOCCode": "9617",
        "JobTitle": "meat vacuum wrapper"
    },
    {
        "value": 28842,
        "NOCCode": "9617",
        "JobTitle": "meat washer"
    },
    {
        "value": 28843,
        "NOCCode": "9617",
        "JobTitle": "meat wrapper"
    },
    {
        "value": 28844,
        "NOCCode": "9617",
        "JobTitle": "meat-packing plant labourer"
    },
    {
        "value": 28845,
        "NOCCode": "9617",
        "JobTitle": "meat-rolling machine tender"
    },
    {
        "value": 28846,
        "NOCCode": "9617",
        "JobTitle": "milk powder grinder"
    },
    {
        "value": 28847,
        "NOCCode": "9617",
        "JobTitle": "milk sampler"
    },
    {
        "value": 28848,
        "NOCCode": "9617",
        "JobTitle": "neck skewerer – food and beverage processing"
    },
    {
        "value": 28849,
        "NOCCode": "9617",
        "JobTitle": "neutralizer, corn starch"
    },
    {
        "value": 28850,
        "NOCCode": "9617",
        "JobTitle": "noodle paste mixer tender"
    },
    {
        "value": 28851,
        "NOCCode": "9617",
        "JobTitle": "nut sorter – food and beverage processing"
    },
    {
        "value": 28852,
        "NOCCode": "9617",
        "JobTitle": "nut-chopping machine feeder"
    },
    {
        "value": 28853,
        "NOCCode": "9617",
        "JobTitle": "packager, bakery products"
    },
    {
        "value": 28854,
        "NOCCode": "9617",
        "JobTitle": "packager, poultry"
    },
    {
        "value": 28855,
        "NOCCode": "9617",
        "JobTitle": "packer, liquid"
    },
    {
        "value": 28856,
        "NOCCode": "9617",
        "JobTitle": "packer, tobacco"
    },
    {
        "value": 28857,
        "NOCCode": "9617",
        "JobTitle": "pastry cook helper"
    },
    {
        "value": 28858,
        "NOCCode": "9617",
        "JobTitle": "pastry wrapper"
    },
    {
        "value": 28859,
        "NOCCode": "9617",
        "JobTitle": "pellet mill worker – food and beverage processing"
    },
    {
        "value": 28860,
        "NOCCode": "9617",
        "JobTitle": "popcorn maker – food and beverage processing"
    },
    {
        "value": 28861,
        "NOCCode": "9617",
        "JobTitle": "potato chip sorter"
    },
    {
        "value": 28862,
        "NOCCode": "9617",
        "JobTitle": "potato-peeling machine tender"
    },
    {
        "value": 28863,
        "NOCCode": "9617",
        "JobTitle": "poultry cooler worker – food and beverage processing"
    },
    {
        "value": 28864,
        "NOCCode": "9617",
        "JobTitle": "poultry packager"
    },
    {
        "value": 28865,
        "NOCCode": "9617",
        "JobTitle": "production helper"
    },
    {
        "value": 28866,
        "NOCCode": "9617",
        "JobTitle": "production helper – food and beverage processing"
    },
    {
        "value": 28867,
        "NOCCode": "9617",
        "JobTitle": "production labourer – food processing"
    },
    {
        "value": 28868,
        "NOCCode": "9617",
        "JobTitle": "salt packer"
    },
    {
        "value": 28869,
        "NOCCode": "9617",
        "JobTitle": "sampler – food and beverage processing"
    },
    {
        "value": 28870,
        "NOCCode": "9617",
        "JobTitle": "sampler, food products"
    },
    {
        "value": 28871,
        "NOCCode": "9617",
        "JobTitle": "sausage casing curer"
    },
    {
        "value": 28872,
        "NOCCode": "9617",
        "JobTitle": "sausage linker – food and beverage processing"
    },
    {
        "value": 28873,
        "NOCCode": "9617",
        "JobTitle": "sausage tier – food and beverage processing"
    },
    {
        "value": 28874,
        "NOCCode": "9617",
        "JobTitle": "sausage wrapper – food and beverage processing"
    },
    {
        "value": 28875,
        "NOCCode": "9617",
        "JobTitle": "seasoning mixer – food and beverage processing"
    },
    {
        "value": 28876,
        "NOCCode": "9617",
        "JobTitle": "shrouder – food and beverage processing"
    },
    {
        "value": 28877,
        "NOCCode": "9617",
        "JobTitle": "singeing machine tender"
    },
    {
        "value": 28878,
        "NOCCode": "9617",
        "JobTitle": "skinning machine feeder – food and beverage processing"
    },
    {
        "value": 28879,
        "NOCCode": "9617",
        "JobTitle": "smoked meat preparer – food and beverage processing"
    },
    {
        "value": 28880,
        "NOCCode": "9617",
        "JobTitle": "snuff-packing machine tender"
    },
    {
        "value": 28881,
        "NOCCode": "9617",
        "JobTitle": "soft drink mixer"
    },
    {
        "value": 28882,
        "NOCCode": "9617",
        "JobTitle": "sorting machine feeder – food and beverage processing"
    },
    {
        "value": 28883,
        "NOCCode": "9617",
        "JobTitle": "starch centrifuge tender"
    },
    {
        "value": 28884,
        "NOCCode": "9617",
        "JobTitle": "starch factory labourer – food and beverage processing"
    },
    {
        "value": 28885,
        "NOCCode": "9617",
        "JobTitle": "stunner and shackler – meat packing"
    },
    {
        "value": 28886,
        "NOCCode": "9617",
        "JobTitle": "sugar boiler helper"
    },
    {
        "value": 28887,
        "NOCCode": "9617",
        "JobTitle": "sugar grinder"
    },
    {
        "value": 28888,
        "NOCCode": "9617",
        "JobTitle": "tank attendant – food and beverage processing"
    },
    {
        "value": 28889,
        "NOCCode": "9617",
        "JobTitle": "tare assessor – food and beverage processing"
    },
    {
        "value": 28890,
        "NOCCode": "9617",
        "JobTitle": "tea blender"
    },
    {
        "value": 28891,
        "NOCCode": "9617",
        "JobTitle": "ticket checker – tobacco processing"
    },
    {
        "value": 28892,
        "NOCCode": "9617",
        "JobTitle": "tobacco baler"
    },
    {
        "value": 28893,
        "NOCCode": "9617",
        "JobTitle": "tobacco hogshead liner"
    },
    {
        "value": 28894,
        "NOCCode": "9617",
        "JobTitle": "tobacco packer"
    },
    {
        "value": 28895,
        "NOCCode": "9617",
        "JobTitle": "tobacco packer, machine"
    },
    {
        "value": 28896,
        "NOCCode": "9617",
        "JobTitle": "tobacco processing labourer"
    },
    {
        "value": 28897,
        "NOCCode": "9617",
        "JobTitle": "tobacco weigher"
    },
    {
        "value": 28898,
        "NOCCode": "9617",
        "JobTitle": "tobacco-packing machine tender"
    },
    {
        "value": 28899,
        "NOCCode": "9617",
        "JobTitle": "tub filler – food and beverage processing"
    },
    {
        "value": 28900,
        "NOCCode": "9617",
        "JobTitle": "tumbler tender – food and beverage processing"
    },
    {
        "value": 28901,
        "NOCCode": "9617",
        "JobTitle": "vinegar maker helper"
    },
    {
        "value": 28902,
        "NOCCode": "9617",
        "JobTitle": "washing and waxing machine tender – food and beverage processing"
    },
    {
        "value": 28903,
        "NOCCode": "9617",
        "JobTitle": "yeast processing labourer"
    },
    {
        "value": 28904,
        "NOCCode": "9617",
        "JobTitle": "yeast sampler – food and beverage processing"
    },
    {
        "value": 28905,
        "NOCCode": "9618",
        "JobTitle": "briner, fish – fish processing"
    },
    {
        "value": 28906,
        "NOCCode": "9618",
        "JobTitle": "cannery labourer"
    },
    {
        "value": 28907,
        "NOCCode": "9618",
        "JobTitle": "cannery labourer – fish processing"
    },
    {
        "value": 28908,
        "NOCCode": "9618",
        "JobTitle": "cooler operator – fish processing"
    },
    {
        "value": 28909,
        "NOCCode": "9618",
        "JobTitle": "fish bin worker – fish processing"
    },
    {
        "value": 28910,
        "NOCCode": "9618",
        "JobTitle": "fish briner – fish processing"
    },
    {
        "value": 28911,
        "NOCCode": "9618",
        "JobTitle": "fish culler"
    },
    {
        "value": 28912,
        "NOCCode": "9618",
        "JobTitle": "fish curing worker"
    },
    {
        "value": 28913,
        "NOCCode": "9618",
        "JobTitle": "fish dipper – fish processing"
    },
    {
        "value": 28914,
        "NOCCode": "9618",
        "JobTitle": "fish flaker – fish processing"
    },
    {
        "value": 28915,
        "NOCCode": "9618",
        "JobTitle": "fish freezing worker – fish processing"
    },
    {
        "value": 28916,
        "NOCCode": "9618",
        "JobTitle": "fish hopper filling worker"
    },
    {
        "value": 28917,
        "NOCCode": "9618",
        "JobTitle": "fish icer – fish processing"
    },
    {
        "value": 28918,
        "NOCCode": "9618",
        "JobTitle": "fish packer – fish processing"
    },
    {
        "value": 28919,
        "NOCCode": "9618",
        "JobTitle": "fish pickler – fish processing"
    },
    {
        "value": 28920,
        "NOCCode": "9618",
        "JobTitle": "fish plant labourer"
    },
    {
        "value": 28921,
        "NOCCode": "9618",
        "JobTitle": "fish processing labourer"
    },
    {
        "value": 28922,
        "NOCCode": "9618",
        "JobTitle": "fish salter – fish processing"
    },
    {
        "value": 28923,
        "NOCCode": "9618",
        "JobTitle": "fish sorter"
    },
    {
        "value": 28924,
        "NOCCode": "9618",
        "JobTitle": "fish washer – fish processing"
    },
    {
        "value": 28925,
        "NOCCode": "9618",
        "JobTitle": "fish weigher"
    },
    {
        "value": 28926,
        "NOCCode": "9618",
        "JobTitle": "fish-processing plant labourer"
    },
    {
        "value": 28927,
        "NOCCode": "9618",
        "JobTitle": "handler, fish-processing plant"
    },
    {
        "value": 28928,
        "NOCCode": "9618",
        "JobTitle": "labourer – shellfish processing"
    },
    {
        "value": 28929,
        "NOCCode": "9618",
        "JobTitle": "labourer, cannery – fish processing"
    },
    {
        "value": 28930,
        "NOCCode": "9618",
        "JobTitle": "labourer, fish-processing plant"
    },
    {
        "value": 28931,
        "NOCCode": "9618",
        "JobTitle": "plant labourer – fish processing"
    },
    {
        "value": 28932,
        "NOCCode": "9618",
        "JobTitle": "shellfish checker – fish processing"
    },
    {
        "value": 28933,
        "NOCCode": "9618",
        "JobTitle": "shellfish labourer"
    },
    {
        "value": 28934,
        "NOCCode": "9618",
        "JobTitle": "shellfish packer – fish processing"
    },
    {
        "value": 28935,
        "NOCCode": "9618",
        "JobTitle": "shellfish processing labourer"
    },
    {
        "value": 28936,
        "NOCCode": "9619",
        "JobTitle": "abrasive sheets inspector"
    },
    {
        "value": 28937,
        "NOCCode": "9619",
        "JobTitle": "acid bottler"
    },
    {
        "value": 28938,
        "NOCCode": "9619",
        "JobTitle": "acid-bottling machine tender"
    },
    {
        "value": 28939,
        "NOCCode": "9619",
        "JobTitle": "aircraft parts packager"
    },
    {
        "value": 28940,
        "NOCCode": "9619",
        "JobTitle": "aircraft parts wrapper"
    },
    {
        "value": 28941,
        "NOCCode": "9619",
        "JobTitle": "ampoule filler"
    },
    {
        "value": 28942,
        "NOCCode": "9619",
        "JobTitle": "ampoule inspector – pharmaceuticals and toiletries manufacturing"
    },
    {
        "value": 28943,
        "NOCCode": "9619",
        "JobTitle": "antichecking steel bander"
    },
    {
        "value": 28944,
        "NOCCode": "9619",
        "JobTitle": "assembly line general labourer"
    },
    {
        "value": 28945,
        "NOCCode": "9619",
        "JobTitle": "back padder – furniture manufacturing"
    },
    {
        "value": 28946,
        "NOCCode": "9619",
        "JobTitle": "bag liner – packaging"
    },
    {
        "value": 28947,
        "NOCCode": "9619",
        "JobTitle": "bagger, garments – garment manufacturing"
    },
    {
        "value": 28948,
        "NOCCode": "9619",
        "JobTitle": "bagging machine feeder"
    },
    {
        "value": 28949,
        "NOCCode": "9619",
        "JobTitle": "baler tender"
    },
    {
        "value": 28950,
        "NOCCode": "9619",
        "JobTitle": "baler tender – packaging"
    },
    {
        "value": 28951,
        "NOCCode": "9619",
        "JobTitle": "baler, cloth – textiles"
    },
    {
        "value": 28952,
        "NOCCode": "9619",
        "JobTitle": "balerworker"
    },
    {
        "value": 28953,
        "NOCCode": "9619",
        "JobTitle": "baling press tender – printing"
    },
    {
        "value": 28954,
        "NOCCode": "9619",
        "JobTitle": "ball weigher – toy manufacturing"
    },
    {
        "value": 28955,
        "NOCCode": "9619",
        "JobTitle": "band nailer – wood products"
    },
    {
        "value": 28956,
        "NOCCode": "9619",
        "JobTitle": "bander, crates"
    },
    {
        "value": 28957,
        "NOCCode": "9619",
        "JobTitle": "bander, paper goods"
    },
    {
        "value": 28958,
        "NOCCode": "9619",
        "JobTitle": "bandoleer straightener-stamper"
    },
    {
        "value": 28959,
        "NOCCode": "9619",
        "JobTitle": "barrel charrer"
    },
    {
        "value": 28960,
        "NOCCode": "9619",
        "JobTitle": "barrel liner, hand"
    },
    {
        "value": 28961,
        "NOCCode": "9619",
        "JobTitle": "barrel stave leveller – wood products manufacturing"
    },
    {
        "value": 28962,
        "NOCCode": "9619",
        "JobTitle": "barrel straightener"
    },
    {
        "value": 28963,
        "NOCCode": "9619",
        "JobTitle": "baseball glove shaper"
    },
    {
        "value": 28964,
        "NOCCode": "9619",
        "JobTitle": "battery acid dumper"
    },
    {
        "value": 28965,
        "NOCCode": "9619",
        "JobTitle": "battery plate offbearer"
    },
    {
        "value": 28966,
        "NOCCode": "9619",
        "JobTitle": "belt edge stainer"
    },
    {
        "value": 28967,
        "NOCCode": "9619",
        "JobTitle": "belt liner"
    },
    {
        "value": 28968,
        "NOCCode": "9619",
        "JobTitle": "belt maker, leather"
    },
    {
        "value": 28969,
        "NOCCode": "9619",
        "JobTitle": "bench worker – garment manufacturing"
    },
    {
        "value": 28970,
        "NOCCode": "9619",
        "JobTitle": "beveller – shoe manufacturing"
    },
    {
        "value": 28971,
        "NOCCode": "9619",
        "JobTitle": "binder helper – printing"
    },
    {
        "value": 28972,
        "NOCCode": "9619",
        "JobTitle": "bindery helper"
    },
    {
        "value": 28973,
        "NOCCode": "9619",
        "JobTitle": "bindery labourer"
    },
    {
        "value": 28974,
        "NOCCode": "9619",
        "JobTitle": "bindery machine feeder – printing"
    },
    {
        "value": 28975,
        "NOCCode": "9619",
        "JobTitle": "bisque cleaner – clay products"
    },
    {
        "value": 28976,
        "NOCCode": "9619",
        "JobTitle": "blackboard eraser maker"
    },
    {
        "value": 28977,
        "NOCCode": "9619",
        "JobTitle": "blister packer"
    },
    {
        "value": 28978,
        "NOCCode": "9619",
        "JobTitle": "blocker, lenses"
    },
    {
        "value": 28979,
        "NOCCode": "9619",
        "JobTitle": "blueprint trimmer-wrapper – printing"
    },
    {
        "value": 28980,
        "NOCCode": "9619",
        "JobTitle": "blueprinter helper – printing"
    },
    {
        "value": 28981,
        "NOCCode": "9619",
        "JobTitle": "boat assembly helper"
    },
    {
        "value": 28982,
        "NOCCode": "9619",
        "JobTitle": "boner, garments and hosiery"
    },
    {
        "value": 28983,
        "NOCCode": "9619",
        "JobTitle": "boot and shoe matcher"
    },
    {
        "value": 28984,
        "NOCCode": "9619",
        "JobTitle": "boot and shoe perforator"
    },
    {
        "value": 28985,
        "NOCCode": "9619",
        "JobTitle": "bottle sorter"
    },
    {
        "value": 28986,
        "NOCCode": "9619",
        "JobTitle": "bottom wheeler – shoe manufacturing"
    },
    {
        "value": 28987,
        "NOCCode": "9619",
        "JobTitle": "box factory labourer"
    },
    {
        "value": 28988,
        "NOCCode": "9619",
        "JobTitle": "box labeller"
    },
    {
        "value": 28989,
        "NOCCode": "9619",
        "JobTitle": "box marker – manufacturing"
    },
    {
        "value": 28990,
        "NOCCode": "9619",
        "JobTitle": "box packer"
    },
    {
        "value": 28991,
        "NOCCode": "9619",
        "JobTitle": "box sealer"
    },
    {
        "value": 28992,
        "NOCCode": "9619",
        "JobTitle": "box-blank machine feeder"
    },
    {
        "value": 28993,
        "NOCCode": "9619",
        "JobTitle": "broom cleaner and cutter"
    },
    {
        "value": 28994,
        "NOCCode": "9619",
        "JobTitle": "broom trimmer"
    },
    {
        "value": 28995,
        "NOCCode": "9619",
        "JobTitle": "buckle maker"
    },
    {
        "value": 28996,
        "NOCCode": "9619",
        "JobTitle": "buffer, shoe parts"
    },
    {
        "value": 28997,
        "NOCCode": "9619",
        "JobTitle": "bundle wrapper"
    },
    {
        "value": 28998,
        "NOCCode": "9619",
        "JobTitle": "bundler – manufacturing"
    },
    {
        "value": 28999,
        "NOCCode": "9619",
        "JobTitle": "bushing tender"
    },
    {
        "value": 29000,
        "NOCCode": "9619",
        "JobTitle": "button assorter"
    },
    {
        "value": 29001,
        "NOCCode": "9619",
        "JobTitle": "button marker"
    },
    {
        "value": 29002,
        "NOCCode": "9619",
        "JobTitle": "button-sorting machine feeder"
    },
    {
        "value": 29003,
        "NOCCode": "9619",
        "JobTitle": "cable insulator helper"
    },
    {
        "value": 29004,
        "NOCCode": "9619",
        "JobTitle": "candle manufacturing labourer"
    },
    {
        "value": 29005,
        "NOCCode": "9619",
        "JobTitle": "candle-wrapping machine tender"
    },
    {
        "value": 29006,
        "NOCCode": "9619",
        "JobTitle": "carbon-rod-inserting machine feeder"
    },
    {
        "value": 29007,
        "NOCCode": "9619",
        "JobTitle": "carton filler"
    },
    {
        "value": 29008,
        "NOCCode": "9619",
        "JobTitle": "carton labeller"
    },
    {
        "value": 29009,
        "NOCCode": "9619",
        "JobTitle": "carton marker"
    },
    {
        "value": 29010,
        "NOCCode": "9619",
        "JobTitle": "carton stamper"
    },
    {
        "value": 29011,
        "NOCCode": "9619",
        "JobTitle": "carton wrapper"
    },
    {
        "value": 29012,
        "NOCCode": "9619",
        "JobTitle": "case filler"
    },
    {
        "value": 29013,
        "NOCCode": "9619",
        "JobTitle": "case liner"
    },
    {
        "value": 29014,
        "NOCCode": "9619",
        "JobTitle": "case marker"
    },
    {
        "value": 29015,
        "NOCCode": "9619",
        "JobTitle": "case packer"
    },
    {
        "value": 29016,
        "NOCCode": "9619",
        "JobTitle": "casket liner"
    },
    {
        "value": 29017,
        "NOCCode": "9619",
        "JobTitle": "casket trimmer"
    },
    {
        "value": 29018,
        "NOCCode": "9619",
        "JobTitle": "cement applicator – shoe manufacturing"
    },
    {
        "value": 29019,
        "NOCCode": "9619",
        "JobTitle": "cementer, hand – shoe manufacturing"
    },
    {
        "value": 29020,
        "NOCCode": "9619",
        "JobTitle": "cementer, knife parts – processing and manufacturing"
    },
    {
        "value": 29021,
        "NOCCode": "9619",
        "JobTitle": "chair sander"
    },
    {
        "value": 29022,
        "NOCCode": "9619",
        "JobTitle": "chair trimmer"
    },
    {
        "value": 29023,
        "NOCCode": "9619",
        "JobTitle": "channel closer – shoe manufacturing"
    },
    {
        "value": 29024,
        "NOCCode": "9619",
        "JobTitle": "clamp remover – wood products manufacturing"
    },
    {
        "value": 29025,
        "NOCCode": "9619",
        "JobTitle": "cleaner and finisher, upholstery"
    },
    {
        "value": 29026,
        "NOCCode": "9619",
        "JobTitle": "cleaner, ink fountains – printing"
    },
    {
        "value": 29027,
        "NOCCode": "9619",
        "JobTitle": "cleaner, porcelain enamel products"
    },
    {
        "value": 29028,
        "NOCCode": "9619",
        "JobTitle": "cleaner, processing equipment"
    },
    {
        "value": 29029,
        "NOCCode": "9619",
        "JobTitle": "cleaner, spray booths"
    },
    {
        "value": 29030,
        "NOCCode": "9619",
        "JobTitle": "clip-loading machine feeder"
    },
    {
        "value": 29031,
        "NOCCode": "9619",
        "JobTitle": "cloth baler – textiles"
    },
    {
        "value": 29032,
        "NOCCode": "9619",
        "JobTitle": "cloth cutter, hand"
    },
    {
        "value": 29033,
        "NOCCode": "9619",
        "JobTitle": "cloth layer – garment manufacturing"
    },
    {
        "value": 29034,
        "NOCCode": "9619",
        "JobTitle": "clothing plant labourer"
    },
    {
        "value": 29035,
        "NOCCode": "9619",
        "JobTitle": "collator tender – printing"
    },
    {
        "value": 29036,
        "NOCCode": "9619",
        "JobTitle": "collator-inserter tender – printing"
    },
    {
        "value": 29037,
        "NOCCode": "9619",
        "JobTitle": "conveyor washer tender"
    },
    {
        "value": 29038,
        "NOCCode": "9619",
        "JobTitle": "cooperage labourer"
    },
    {
        "value": 29039,
        "NOCCode": "9619",
        "JobTitle": "core mounter"
    },
    {
        "value": 29040,
        "NOCCode": "9619",
        "JobTitle": "core pipe extractor"
    },
    {
        "value": 29041,
        "NOCCode": "9619",
        "JobTitle": "cotton roll machine tender"
    },
    {
        "value": 29042,
        "NOCCode": "9619",
        "JobTitle": "coverer, furniture panels – furniture assembly"
    },
    {
        "value": 29043,
        "NOCCode": "9619",
        "JobTitle": "coverer, luggage"
    },
    {
        "value": 29044,
        "NOCCode": "9619",
        "JobTitle": "coverer, slip seats – furniture assembly"
    },
    {
        "value": 29045,
        "NOCCode": "9619",
        "JobTitle": "crate bander"
    },
    {
        "value": 29046,
        "NOCCode": "9619",
        "JobTitle": "crate liner"
    },
    {
        "value": 29047,
        "NOCCode": "9619",
        "JobTitle": "crate marker"
    },
    {
        "value": 29048,
        "NOCCode": "9619",
        "JobTitle": "crate packer"
    },
    {
        "value": 29049,
        "NOCCode": "9619",
        "JobTitle": "crater – manufacturing"
    },
    {
        "value": 29050,
        "NOCCode": "9619",
        "JobTitle": "crater, furniture"
    },
    {
        "value": 29051,
        "NOCCode": "9619",
        "JobTitle": "crayon cutter"
    },
    {
        "value": 29052,
        "NOCCode": "9619",
        "JobTitle": "crayon moulder"
    },
    {
        "value": 29053,
        "NOCCode": "9619",
        "JobTitle": "creping machine operator helper"
    },
    {
        "value": 29054,
        "NOCCode": "9619",
        "JobTitle": "cushion cementer – shoe manufacturing"
    },
    {
        "value": 29055,
        "NOCCode": "9619",
        "JobTitle": "cushion filler – furniture assembly"
    },
    {
        "value": 29056,
        "NOCCode": "9619",
        "JobTitle": "cushion stuffer – furniture assembly"
    },
    {
        "value": 29057,
        "NOCCode": "9619",
        "JobTitle": "cut-out and marking machine tender – shoe manufacturing"
    },
    {
        "value": 29058,
        "NOCCode": "9619",
        "JobTitle": "cutter, films – film processing"
    },
    {
        "value": 29059,
        "NOCCode": "9619",
        "JobTitle": "cutter, padding – furniture assembly"
    },
    {
        "value": 29060,
        "NOCCode": "9619",
        "JobTitle": "cutter, tiles"
    },
    {
        "value": 29061,
        "NOCCode": "9619",
        "JobTitle": "deburrer – clock and watch manufacturing"
    },
    {
        "value": 29062,
        "NOCCode": "9619",
        "JobTitle": "dipper and dryer, paper cones"
    },
    {
        "value": 29063,
        "NOCCode": "9619",
        "JobTitle": "dipper, shoe parts"
    },
    {
        "value": 29064,
        "NOCCode": "9619",
        "JobTitle": "dipping labourer"
    },
    {
        "value": 29065,
        "NOCCode": "9619",
        "JobTitle": "door sander"
    },
    {
        "value": 29066,
        "NOCCode": "9619",
        "JobTitle": "drapery making labourer"
    },
    {
        "value": 29067,
        "NOCCode": "9619",
        "JobTitle": "drapery pleater, hand"
    },
    {
        "value": 29068,
        "NOCCode": "9619",
        "JobTitle": "drawstring inserter – garment manufacturing"
    },
    {
        "value": 29069,
        "NOCCode": "9619",
        "JobTitle": "dress finisher"
    },
    {
        "value": 29070,
        "NOCCode": "9619",
        "JobTitle": "dry cell remover – electrical equipment manufacturing"
    },
    {
        "value": 29071,
        "NOCCode": "9619",
        "JobTitle": "electric motor tester helper"
    },
    {
        "value": 29072,
        "NOCCode": "9619",
        "JobTitle": "electric motor testing helper"
    },
    {
        "value": 29073,
        "NOCCode": "9619",
        "JobTitle": "electrical insulator tester"
    },
    {
        "value": 29074,
        "NOCCode": "9619",
        "JobTitle": "electrical products labourer"
    },
    {
        "value": 29075,
        "NOCCode": "9619",
        "JobTitle": "elemental worker – packaging"
    },
    {
        "value": 29076,
        "NOCCode": "9619",
        "JobTitle": "emblem-fusing machine operator – garment manufacturing"
    },
    {
        "value": 29077,
        "NOCCode": "9619",
        "JobTitle": "embroidered fabric connecting thread cutter tender"
    },
    {
        "value": 29078,
        "NOCCode": "9619",
        "JobTitle": "embroidery frame mounter"
    },
    {
        "value": 29079,
        "NOCCode": "9619",
        "JobTitle": "embroidery machine charger"
    },
    {
        "value": 29080,
        "NOCCode": "9619",
        "JobTitle": "etcher helper – printing"
    },
    {
        "value": 29081,
        "NOCCode": "9619",
        "JobTitle": "eyeglass frame trimmer"
    },
    {
        "value": 29082,
        "NOCCode": "9619",
        "JobTitle": "eyeglass pad cementer"
    },
    {
        "value": 29083,
        "NOCCode": "9619",
        "JobTitle": "factory helper"
    },
    {
        "value": 29084,
        "NOCCode": "9619",
        "JobTitle": "factory labourer – manufacturing"
    },
    {
        "value": 29085,
        "NOCCode": "9619",
        "JobTitle": "factory labourer, garments"
    },
    {
        "value": 29086,
        "NOCCode": "9619",
        "JobTitle": "feather washer and dryer"
    },
    {
        "value": 29087,
        "NOCCode": "9619",
        "JobTitle": "feeder – printing"
    },
    {
        "value": 29088,
        "NOCCode": "9619",
        "JobTitle": "feeder, folding machine – printing"
    },
    {
        "value": 29089,
        "NOCCode": "9619",
        "JobTitle": "feeder, lithographic offset press"
    },
    {
        "value": 29090,
        "NOCCode": "9619",
        "JobTitle": "feeder, machine"
    },
    {
        "value": 29091,
        "NOCCode": "9619",
        "JobTitle": "feeder, pencil-tipping machine"
    },
    {
        "value": 29092,
        "NOCCode": "9619",
        "JobTitle": "feeder, pressing machine – garment manufacturing"
    },
    {
        "value": 29093,
        "NOCCode": "9619",
        "JobTitle": "feeder, printing press"
    },
    {
        "value": 29094,
        "NOCCode": "9619",
        "JobTitle": "feeder, screen printing machine"
    },
    {
        "value": 29095,
        "NOCCode": "9619",
        "JobTitle": "feeder, tubulating machine – glass products manufacturing"
    },
    {
        "value": 29096,
        "NOCCode": "9619",
        "JobTitle": "feeder, vacuum bottle exhaust machine"
    },
    {
        "value": 29097,
        "NOCCode": "9619",
        "JobTitle": "feeder, wood products machine"
    },
    {
        "value": 29098,
        "NOCCode": "9619",
        "JobTitle": "feeder, woodworking machine"
    },
    {
        "value": 29099,
        "NOCCode": "9619",
        "JobTitle": "filler, sporting goods"
    },
    {
        "value": 29100,
        "NOCCode": "9619",
        "JobTitle": "filler, stamps"
    },
    {
        "value": 29101,
        "NOCCode": "9619",
        "JobTitle": "film cutter – film processing"
    },
    {
        "value": 29102,
        "NOCCode": "9619",
        "JobTitle": "film mounter – film processing"
    },
    {
        "value": 29103,
        "NOCCode": "9619",
        "JobTitle": "film numberer – film processing"
    },
    {
        "value": 29104,
        "NOCCode": "9619",
        "JobTitle": "final assembler, garment manufacturing"
    },
    {
        "value": 29105,
        "NOCCode": "9619",
        "JobTitle": "finisher-sander – furniture manufacturing"
    },
    {
        "value": 29106,
        "NOCCode": "9619",
        "JobTitle": "finisher-trimmer – footwear manufacturing"
    },
    {
        "value": 29107,
        "NOCCode": "9619",
        "JobTitle": "flocker – garment manufacturing"
    },
    {
        "value": 29108,
        "NOCCode": "9619",
        "JobTitle": "floor attendant – bindery"
    },
    {
        "value": 29109,
        "NOCCode": "9619",
        "JobTitle": "floor attendant – printing"
    },
    {
        "value": 29110,
        "NOCCode": "9619",
        "JobTitle": "floor tile edge grinder"
    },
    {
        "value": 29111,
        "NOCCode": "9619",
        "JobTitle": "folder, garments – garment manufacturing"
    },
    {
        "value": 29112,
        "NOCCode": "9619",
        "JobTitle": "folder, parachutes"
    },
    {
        "value": 29113,
        "NOCCode": "9619",
        "JobTitle": "folderman/woman – printing"
    },
    {
        "value": 29114,
        "NOCCode": "9619",
        "JobTitle": "folding machine feeder – printing"
    },
    {
        "value": 29115,
        "NOCCode": "9619",
        "JobTitle": "footwear manufacturing labourer"
    },
    {
        "value": 29116,
        "NOCCode": "9619",
        "JobTitle": "former, shoe parts"
    },
    {
        "value": 29117,
        "NOCCode": "9619",
        "JobTitle": "forwarder – printing"
    },
    {
        "value": 29118,
        "NOCCode": "9619",
        "JobTitle": "furniture crater"
    },
    {
        "value": 29119,
        "NOCCode": "9619",
        "JobTitle": "furniture manufacturing labourer"
    },
    {
        "value": 29120,
        "NOCCode": "9619",
        "JobTitle": "furniture packer"
    },
    {
        "value": 29121,
        "NOCCode": "9619",
        "JobTitle": "furniture panel coverer – furniture assembly"
    },
    {
        "value": 29122,
        "NOCCode": "9619",
        "JobTitle": "garment bagger – garment manufacturing"
    },
    {
        "value": 29123,
        "NOCCode": "9619",
        "JobTitle": "garment factory labourer"
    },
    {
        "value": 29124,
        "NOCCode": "9619",
        "JobTitle": "garment folder"
    },
    {
        "value": 29125,
        "NOCCode": "9619",
        "JobTitle": "garment fringer – garment manufacturing"
    },
    {
        "value": 29126,
        "NOCCode": "9619",
        "JobTitle": "garment manufacturing final assembler"
    },
    {
        "value": 29127,
        "NOCCode": "9619",
        "JobTitle": "garment mender – garment manufacturing"
    },
    {
        "value": 29128,
        "NOCCode": "9619",
        "JobTitle": "garment notcher – garment manufacturing"
    },
    {
        "value": 29129,
        "NOCCode": "9619",
        "JobTitle": "garment packer – garment manufacturing"
    },
    {
        "value": 29130,
        "NOCCode": "9619",
        "JobTitle": "garment parts sorter – garment manufacturing"
    },
    {
        "value": 29131,
        "NOCCode": "9619",
        "JobTitle": "garment plant labourer"
    },
    {
        "value": 29132,
        "NOCCode": "9619",
        "JobTitle": "garment ripper – garment manufacturing"
    },
    {
        "value": 29133,
        "NOCCode": "9619",
        "JobTitle": "garment sorter – garment manufacturing"
    },
    {
        "value": 29134,
        "NOCCode": "9619",
        "JobTitle": "garment steamer – garment manufacturing"
    },
    {
        "value": 29135,
        "NOCCode": "9619",
        "JobTitle": "garment waterproofer – garment manufacturing"
    },
    {
        "value": 29136,
        "NOCCode": "9619",
        "JobTitle": "gathering machine feeder – printing"
    },
    {
        "value": 29137,
        "NOCCode": "9619",
        "JobTitle": "general helper – small electrical appliance assembly"
    },
    {
        "value": 29138,
        "NOCCode": "9619",
        "JobTitle": "general labourer – electrical equipment assembly"
    },
    {
        "value": 29139,
        "NOCCode": "9619",
        "JobTitle": "general labourer – light-fixture assembly"
    },
    {
        "value": 29140,
        "NOCCode": "9619",
        "JobTitle": "general labourer – manufacturing"
    },
    {
        "value": 29141,
        "NOCCode": "9619",
        "JobTitle": "general labourer, assembly line"
    },
    {
        "value": 29142,
        "NOCCode": "9619",
        "JobTitle": "glassfiber mat roller-packer"
    },
    {
        "value": 29143,
        "NOCCode": "9619",
        "JobTitle": "glove liner"
    },
    {
        "value": 29144,
        "NOCCode": "9619",
        "JobTitle": "glove pairer"
    },
    {
        "value": 29145,
        "NOCCode": "9619",
        "JobTitle": "glove turner, hand"
    },
    {
        "value": 29146,
        "NOCCode": "9619",
        "JobTitle": "golf club weigher"
    },
    {
        "value": 29147,
        "NOCCode": "9619",
        "JobTitle": "groover and turner – shoe manufacturing"
    },
    {
        "value": 29148,
        "NOCCode": "9619",
        "JobTitle": "hand cutter, cloth"
    },
    {
        "value": 29149,
        "NOCCode": "9619",
        "JobTitle": "hand inserter – printing"
    },
    {
        "value": 29150,
        "NOCCode": "9619",
        "JobTitle": "hand paper pad gluer"
    },
    {
        "value": 29151,
        "NOCCode": "9619",
        "JobTitle": "hand sander – furniture manufacturing"
    },
    {
        "value": 29152,
        "NOCCode": "9619",
        "JobTitle": "hand trimmer – garment manufacturing"
    },
    {
        "value": 29153,
        "NOCCode": "9619",
        "JobTitle": "hand trimmer – shoe manufacturing"
    },
    {
        "value": 29154,
        "NOCCode": "9619",
        "JobTitle": "handbag framer"
    },
    {
        "value": 29155,
        "NOCCode": "9619",
        "JobTitle": "handle mounter"
    },
    {
        "value": 29156,
        "NOCCode": "9619",
        "JobTitle": "hardener – optical instruments manufacturing"
    },
    {
        "value": 29157,
        "NOCCode": "9619",
        "JobTitle": "harness rigger, parachutes"
    },
    {
        "value": 29158,
        "NOCCode": "9619",
        "JobTitle": "hat and cap parts bundler"
    },
    {
        "value": 29159,
        "NOCCode": "9619",
        "JobTitle": "hat and cap perforator"
    },
    {
        "value": 29160,
        "NOCCode": "9619",
        "JobTitle": "hat brusher"
    },
    {
        "value": 29161,
        "NOCCode": "9619",
        "JobTitle": "hat liner"
    },
    {
        "value": 29162,
        "NOCCode": "9619",
        "JobTitle": "hat sizer"
    },
    {
        "value": 29163,
        "NOCCode": "9619",
        "JobTitle": "hat sorter"
    },
    {
        "value": 29164,
        "NOCCode": "9619",
        "JobTitle": "hat sweatband flanger"
    },
    {
        "value": 29165,
        "NOCCode": "9619",
        "JobTitle": "hat treater"
    },
    {
        "value": 29166,
        "NOCCode": "9619",
        "JobTitle": "heat-seal press pad assembler – furniture assembly"
    },
    {
        "value": 29167,
        "NOCCode": "9619",
        "JobTitle": "heddle examiner"
    },
    {
        "value": 29168,
        "NOCCode": "9619",
        "JobTitle": "heel compressor tender – shoe manufacturing"
    },
    {
        "value": 29169,
        "NOCCode": "9619",
        "JobTitle": "heel reducer – shoe manufacturing"
    },
    {
        "value": 29170,
        "NOCCode": "9619",
        "JobTitle": "heel scorer – shoe manufacturing"
    },
    {
        "value": 29171,
        "NOCCode": "9619",
        "JobTitle": "heel shaper – shoe manufacturing"
    },
    {
        "value": 29172,
        "NOCCode": "9619",
        "JobTitle": "helper – printing"
    },
    {
        "value": 29173,
        "NOCCode": "9619",
        "JobTitle": "helper, electric motor testing"
    },
    {
        "value": 29174,
        "NOCCode": "9619",
        "JobTitle": "helper, etcher – printing"
    },
    {
        "value": 29175,
        "NOCCode": "9619",
        "JobTitle": "helper, factory"
    },
    {
        "value": 29176,
        "NOCCode": "9619",
        "JobTitle": "helper, portable diamond drill and saw operator"
    },
    {
        "value": 29177,
        "NOCCode": "9619",
        "JobTitle": "helper, press – printing"
    },
    {
        "value": 29178,
        "NOCCode": "9619",
        "JobTitle": "helper, production painter"
    },
    {
        "value": 29179,
        "NOCCode": "9619",
        "JobTitle": "helper, protective signalling installer – manufacturing"
    },
    {
        "value": 29180,
        "NOCCode": "9619",
        "JobTitle": "helper, upholstery"
    },
    {
        "value": 29181,
        "NOCCode": "9619",
        "JobTitle": "helper, woodworking shop"
    },
    {
        "value": 29182,
        "NOCCode": "9619",
        "JobTitle": "hide and pelt processing labourer"
    },
    {
        "value": 29183,
        "NOCCode": "9619",
        "JobTitle": "incinerator plant tender"
    },
    {
        "value": 29184,
        "NOCCode": "9619",
        "JobTitle": "industrial washing machine tender"
    },
    {
        "value": 29185,
        "NOCCode": "9619",
        "JobTitle": "ink fountain cleaner – printing"
    },
    {
        "value": 29186,
        "NOCCode": "9619",
        "JobTitle": "inserter – printing"
    },
    {
        "value": 29187,
        "NOCCode": "9619",
        "JobTitle": "inserter, pail handles"
    },
    {
        "value": 29188,
        "NOCCode": "9619",
        "JobTitle": "inserter, springs – furniture manufacturing"
    },
    {
        "value": 29189,
        "NOCCode": "9619",
        "JobTitle": "insole filler – footwear manufacturing"
    },
    {
        "value": 29190,
        "NOCCode": "9619",
        "JobTitle": "installer helper, protective signalling – manufacturing"
    },
    {
        "value": 29191,
        "NOCCode": "9619",
        "JobTitle": "insulator tester"
    },
    {
        "value": 29192,
        "NOCCode": "9619",
        "JobTitle": "jewellery pickler and dipper"
    },
    {
        "value": 29193,
        "NOCCode": "9619",
        "JobTitle": "jogger – printing"
    },
    {
        "value": 29194,
        "NOCCode": "9619",
        "JobTitle": "label brander"
    },
    {
        "value": 29195,
        "NOCCode": "9619",
        "JobTitle": "label heat tacker"
    },
    {
        "value": 29196,
        "NOCCode": "9619",
        "JobTitle": "labourer – packaging"
    },
    {
        "value": 29197,
        "NOCCode": "9619",
        "JobTitle": "labourer – packaging company"
    },
    {
        "value": 29198,
        "NOCCode": "9619",
        "JobTitle": "labourer – printing"
    },
    {
        "value": 29199,
        "NOCCode": "9619",
        "JobTitle": "labourer – shoe manufacturing"
    },
    {
        "value": 29200,
        "NOCCode": "9619",
        "JobTitle": "labourer – tannery"
    },
    {
        "value": 29201,
        "NOCCode": "9619",
        "JobTitle": "labourer, bindery"
    },
    {
        "value": 29202,
        "NOCCode": "9619",
        "JobTitle": "labourer, boat assembly"
    },
    {
        "value": 29203,
        "NOCCode": "9619",
        "JobTitle": "labourer, box factory"
    },
    {
        "value": 29204,
        "NOCCode": "9619",
        "JobTitle": "labourer, candle manufacturing"
    },
    {
        "value": 29205,
        "NOCCode": "9619",
        "JobTitle": "labourer, cooperage"
    },
    {
        "value": 29206,
        "NOCCode": "9619",
        "JobTitle": "labourer, dipping"
    },
    {
        "value": 29207,
        "NOCCode": "9619",
        "JobTitle": "labourer, electrical appliance manufacturing"
    },
    {
        "value": 29208,
        "NOCCode": "9619",
        "JobTitle": "labourer, electrical products manufacturing"
    },
    {
        "value": 29209,
        "NOCCode": "9619",
        "JobTitle": "labourer, factory – manufacturing"
    },
    {
        "value": 29210,
        "NOCCode": "9619",
        "JobTitle": "labourer, footwear manufacturing"
    },
    {
        "value": 29211,
        "NOCCode": "9619",
        "JobTitle": "labourer, furniture manufacturing"
    },
    {
        "value": 29212,
        "NOCCode": "9619",
        "JobTitle": "labourer, furrier shop"
    },
    {
        "value": 29213,
        "NOCCode": "9619",
        "JobTitle": "labourer, garment factory"
    },
    {
        "value": 29214,
        "NOCCode": "9619",
        "JobTitle": "labourer, garment plant"
    },
    {
        "value": 29215,
        "NOCCode": "9619",
        "JobTitle": "labourer, general – electrical equipment assembly"
    },
    {
        "value": 29216,
        "NOCCode": "9619",
        "JobTitle": "labourer, general – manufacturing"
    },
    {
        "value": 29217,
        "NOCCode": "9619",
        "JobTitle": "labourer, hide and pelt processing"
    },
    {
        "value": 29218,
        "NOCCode": "9619",
        "JobTitle": "labourer, leather processing"
    },
    {
        "value": 29219,
        "NOCCode": "9619",
        "JobTitle": "labourer, packaging"
    },
    {
        "value": 29220,
        "NOCCode": "9619",
        "JobTitle": "labourer, packaging company"
    },
    {
        "value": 29221,
        "NOCCode": "9619",
        "JobTitle": "labourer, padding"
    },
    {
        "value": 29222,
        "NOCCode": "9619",
        "JobTitle": "labourer, shipyard"
    },
    {
        "value": 29223,
        "NOCCode": "9619",
        "JobTitle": "labourer, shoe manufacturing"
    },
    {
        "value": 29224,
        "NOCCode": "9619",
        "JobTitle": "lacer – footwear manufacturing"
    },
    {
        "value": 29225,
        "NOCCode": "9619",
        "JobTitle": "lacer – sporting goods manufacturing"
    },
    {
        "value": 29226,
        "NOCCode": "9619",
        "JobTitle": "last ironer – shoe manufacturing"
    },
    {
        "value": 29227,
        "NOCCode": "9619",
        "JobTitle": "layer-up – garment manufacturing"
    },
    {
        "value": 29228,
        "NOCCode": "9619",
        "JobTitle": "lay-up worker – garment manufacturing"
    },
    {
        "value": 29229,
        "NOCCode": "9619",
        "JobTitle": "leather belt maker"
    },
    {
        "value": 29230,
        "NOCCode": "9619",
        "JobTitle": "leather coater"
    },
    {
        "value": 29231,
        "NOCCode": "9619",
        "JobTitle": "leather glove worker"
    },
    {
        "value": 29232,
        "NOCCode": "9619",
        "JobTitle": "leather processing labourer"
    },
    {
        "value": 29233,
        "NOCCode": "9619",
        "JobTitle": "lens blank marker"
    },
    {
        "value": 29234,
        "NOCCode": "9619",
        "JobTitle": "lens block cleaner"
    },
    {
        "value": 29235,
        "NOCCode": "9619",
        "JobTitle": "lens blocker"
    },
    {
        "value": 29236,
        "NOCCode": "9619",
        "JobTitle": "lens hardener"
    },
    {
        "value": 29237,
        "NOCCode": "9619",
        "JobTitle": "level vial setter"
    },
    {
        "value": 29238,
        "NOCCode": "9619",
        "JobTitle": "liner, crates"
    },
    {
        "value": 29239,
        "NOCCode": "9619",
        "JobTitle": "liner, gloves"
    },
    {
        "value": 29240,
        "NOCCode": "9619",
        "JobTitle": "lining inserter, skates"
    },
    {
        "value": 29241,
        "NOCCode": "9619",
        "JobTitle": "link cutter – garment manufacturing"
    },
    {
        "value": 29242,
        "NOCCode": "9619",
        "JobTitle": "lithographic offset press feeder"
    },
    {
        "value": 29243,
        "NOCCode": "9619",
        "JobTitle": "lithographic press feeder, sheet metal"
    },
    {
        "value": 29244,
        "NOCCode": "9619",
        "JobTitle": "luggage handle maker"
    },
    {
        "value": 29245,
        "NOCCode": "9619",
        "JobTitle": "machine feeder"
    },
    {
        "value": 29246,
        "NOCCode": "9619",
        "JobTitle": "marker, cartons"
    },
    {
        "value": 29247,
        "NOCCode": "9619",
        "JobTitle": "marker, crates"
    },
    {
        "value": 29248,
        "NOCCode": "9619",
        "JobTitle": "marker, pockets – garment manufacturing"
    },
    {
        "value": 29249,
        "NOCCode": "9619",
        "JobTitle": "marker, products"
    },
    {
        "value": 29250,
        "NOCCode": "9619",
        "JobTitle": "marker, shoe parts"
    },
    {
        "value": 29251,
        "NOCCode": "9619",
        "JobTitle": "masker"
    },
    {
        "value": 29252,
        "NOCCode": "9619",
        "JobTitle": "mechanical tester, insulators"
    },
    {
        "value": 29253,
        "NOCCode": "9619",
        "JobTitle": "mender, garment – garment manufacturing"
    },
    {
        "value": 29254,
        "NOCCode": "9619",
        "JobTitle": "mixer tender, brush materials"
    },
    {
        "value": 29255,
        "NOCCode": "9619",
        "JobTitle": "mounter, solid tires – toy manufacturing"
    },
    {
        "value": 29256,
        "NOCCode": "9619",
        "JobTitle": "nail cutter – shoe manufacturing"
    },
    {
        "value": 29257,
        "NOCCode": "9619",
        "JobTitle": "offbearer – printing"
    },
    {
        "value": 29258,
        "NOCCode": "9619",
        "JobTitle": "oil reclaimer"
    },
    {
        "value": 29259,
        "NOCCode": "9619",
        "JobTitle": "optical element cleaner"
    },
    {
        "value": 29260,
        "NOCCode": "9619",
        "JobTitle": "ornamental stone setter – jewellery manufacturing"
    },
    {
        "value": 29261,
        "NOCCode": "9619",
        "JobTitle": "packager – manufacturing"
    },
    {
        "value": 29262,
        "NOCCode": "9619",
        "JobTitle": "packager, machine"
    },
    {
        "value": 29263,
        "NOCCode": "9619",
        "JobTitle": "packager, seeds"
    },
    {
        "value": 29264,
        "NOCCode": "9619",
        "JobTitle": "packaging company labourer"
    },
    {
        "value": 29265,
        "NOCCode": "9619",
        "JobTitle": "packaging line worker"
    },
    {
        "value": 29266,
        "NOCCode": "9619",
        "JobTitle": "packer – manufacturing"
    },
    {
        "value": 29267,
        "NOCCode": "9619",
        "JobTitle": "packer, boxes"
    },
    {
        "value": 29268,
        "NOCCode": "9619",
        "JobTitle": "packer, cases"
    },
    {
        "value": 29269,
        "NOCCode": "9619",
        "JobTitle": "packer, furniture"
    },
    {
        "value": 29270,
        "NOCCode": "9619",
        "JobTitle": "packer, garments – garment manufacturing"
    },
    {
        "value": 29271,
        "NOCCode": "9619",
        "JobTitle": "padding cutter – furniture assembly"
    },
    {
        "value": 29272,
        "NOCCode": "9619",
        "JobTitle": "padding labourer"
    },
    {
        "value": 29273,
        "NOCCode": "9619",
        "JobTitle": "pail handle inserter"
    },
    {
        "value": 29274,
        "NOCCode": "9619",
        "JobTitle": "paint roller winder"
    },
    {
        "value": 29275,
        "NOCCode": "9619",
        "JobTitle": "paint stripper"
    },
    {
        "value": 29276,
        "NOCCode": "9619",
        "JobTitle": "painter helper, production"
    },
    {
        "value": 29277,
        "NOCCode": "9619",
        "JobTitle": "painter helper, shipyard"
    },
    {
        "value": 29278,
        "NOCCode": "9619",
        "JobTitle": "paper cone dipper and dryer"
    },
    {
        "value": 29279,
        "NOCCode": "9619",
        "JobTitle": "paper feeder, press – printing"
    },
    {
        "value": 29280,
        "NOCCode": "9619",
        "JobTitle": "paper sheet counter – printing"
    },
    {
        "value": 29281,
        "NOCCode": "9619",
        "JobTitle": "parachute folder"
    },
    {
        "value": 29282,
        "NOCCode": "9619",
        "JobTitle": "parachute preparer"
    },
    {
        "value": 29283,
        "NOCCode": "9619",
        "JobTitle": "paster, screen printing"
    },
    {
        "value": 29284,
        "NOCCode": "9619",
        "JobTitle": "pencil blank presser"
    },
    {
        "value": 29285,
        "NOCCode": "9619",
        "JobTitle": "pencil-tipping machine feeder"
    },
    {
        "value": 29286,
        "NOCCode": "9619",
        "JobTitle": "pickler and dipper, jewellery"
    },
    {
        "value": 29287,
        "NOCCode": "9619",
        "JobTitle": "piler – printing"
    },
    {
        "value": 29288,
        "NOCCode": "9619",
        "JobTitle": "pipe grader"
    },
    {
        "value": 29289,
        "NOCCode": "9619",
        "JobTitle": "plastic printer helper"
    },
    {
        "value": 29290,
        "NOCCode": "9619",
        "JobTitle": "plastic-packing machine tender"
    },
    {
        "value": 29291,
        "NOCCode": "9619",
        "JobTitle": "plastics masker"
    },
    {
        "value": 29292,
        "NOCCode": "9619",
        "JobTitle": "pocket marker – garment manufacturing"
    },
    {
        "value": 29293,
        "NOCCode": "9619",
        "JobTitle": "pocket-folding machine feeder-loader – printing"
    },
    {
        "value": 29294,
        "NOCCode": "9619",
        "JobTitle": "polisher, shoe manufacturing"
    },
    {
        "value": 29295,
        "NOCCode": "9619",
        "JobTitle": "porcelain products cleaner"
    },
    {
        "value": 29296,
        "NOCCode": "9619",
        "JobTitle": "preparer, parachutes"
    },
    {
        "value": 29297,
        "NOCCode": "9619",
        "JobTitle": "press assistant, reel replacer"
    },
    {
        "value": 29298,
        "NOCCode": "9619",
        "JobTitle": "press helper – printing"
    },
    {
        "value": 29299,
        "NOCCode": "9619",
        "JobTitle": "press paper feeder – printing"
    },
    {
        "value": 29300,
        "NOCCode": "9619",
        "JobTitle": "presser, pencil blanks"
    },
    {
        "value": 29301,
        "NOCCode": "9619",
        "JobTitle": "pressing machine feeder – garment manufacturing"
    },
    {
        "value": 29302,
        "NOCCode": "9619",
        "JobTitle": "pressroom floor attendant – printing"
    },
    {
        "value": 29303,
        "NOCCode": "9619",
        "JobTitle": "printer helper, wallpapers"
    },
    {
        "value": 29304,
        "NOCCode": "9619",
        "JobTitle": "printing press feeder"
    },
    {
        "value": 29305,
        "NOCCode": "9619",
        "JobTitle": "processing equipment cleaner"
    },
    {
        "value": 29306,
        "NOCCode": "9619",
        "JobTitle": "product marker"
    },
    {
        "value": 29307,
        "NOCCode": "9619",
        "JobTitle": "production painter helper"
    },
    {
        "value": 29308,
        "NOCCode": "9619",
        "JobTitle": "production weigher"
    },
    {
        "value": 29309,
        "NOCCode": "9619",
        "JobTitle": "putty applicator – furniture and fixtures manufacturing"
    },
    {
        "value": 29310,
        "NOCCode": "9619",
        "JobTitle": "refrigerator crater"
    },
    {
        "value": 29311,
        "NOCCode": "9619",
        "JobTitle": "relaster – shoe manufacturing"
    },
    {
        "value": 29312,
        "NOCCode": "9619",
        "JobTitle": "rock splitter"
    },
    {
        "value": 29313,
        "NOCCode": "9619",
        "JobTitle": "roller-packer, glassfiber mats"
    },
    {
        "value": 29314,
        "NOCCode": "9619",
        "JobTitle": "sample clerk – fabric products"
    },
    {
        "value": 29315,
        "NOCCode": "9619",
        "JobTitle": "sample room worker"
    },
    {
        "value": 29316,
        "NOCCode": "9619",
        "JobTitle": "sander, doors"
    },
    {
        "value": 29317,
        "NOCCode": "9619",
        "JobTitle": "sander, hand – furniture manufacturing"
    },
    {
        "value": 29318,
        "NOCCode": "9619",
        "JobTitle": "screen printing machine feeder"
    },
    {
        "value": 29319,
        "NOCCode": "9619",
        "JobTitle": "screen printing paster"
    },
    {
        "value": 29320,
        "NOCCode": "9619",
        "JobTitle": "seed packager"
    },
    {
        "value": 29321,
        "NOCCode": "9619",
        "JobTitle": "setter, level vials"
    },
    {
        "value": 29322,
        "NOCCode": "9619",
        "JobTitle": "shank cementer – shoe manufacturing"
    },
    {
        "value": 29323,
        "NOCCode": "9619",
        "JobTitle": "shell mould bonder"
    },
    {
        "value": 29324,
        "NOCCode": "9619",
        "JobTitle": "shipyard labourer"
    },
    {
        "value": 29325,
        "NOCCode": "9619",
        "JobTitle": "shipyard painter helper"
    },
    {
        "value": 29326,
        "NOCCode": "9619",
        "JobTitle": "shoe parts dipper"
    },
    {
        "value": 29327,
        "NOCCode": "9619",
        "JobTitle": "shoe parts former"
    },
    {
        "value": 29328,
        "NOCCode": "9619",
        "JobTitle": "shoe parts marker"
    },
    {
        "value": 29329,
        "NOCCode": "9619",
        "JobTitle": "shoe repairer helper, factory"
    },
    {
        "value": 29330,
        "NOCCode": "9619",
        "JobTitle": "silvering machine feeder"
    },
    {
        "value": 29331,
        "NOCCode": "9619",
        "JobTitle": "slip seat coverer – furniture assembly"
    },
    {
        "value": 29332,
        "NOCCode": "9619",
        "JobTitle": "smoothing machine tender – shoe manufacturing"
    },
    {
        "value": 29333,
        "NOCCode": "9619",
        "JobTitle": "sock boarder"
    },
    {
        "value": 29334,
        "NOCCode": "9619",
        "JobTitle": "sole edge stainer-finisher – shoe manufacturing"
    },
    {
        "value": 29335,
        "NOCCode": "9619",
        "JobTitle": "sole-flexing machine tender"
    },
    {
        "value": 29336,
        "NOCCode": "9619",
        "JobTitle": "sorter, garments – garment manufacturing"
    },
    {
        "value": 29337,
        "NOCCode": "9619",
        "JobTitle": "sorter, recyclable materials"
    },
    {
        "value": 29338,
        "NOCCode": "9619",
        "JobTitle": "sorter, shoe parts stock"
    },
    {
        "value": 29339,
        "NOCCode": "9619",
        "JobTitle": "sorter, upholstery parts"
    },
    {
        "value": 29340,
        "NOCCode": "9619",
        "JobTitle": "spike installer, golf shoes"
    },
    {
        "value": 29341,
        "NOCCode": "9619",
        "JobTitle": "splitting machine tender – shoe manufacturing"
    },
    {
        "value": 29342,
        "NOCCode": "9619",
        "JobTitle": "sporting goods filler"
    },
    {
        "value": 29343,
        "NOCCode": "9619",
        "JobTitle": "spray booth cleaner"
    },
    {
        "value": 29344,
        "NOCCode": "9619",
        "JobTitle": "spray painter helper"
    },
    {
        "value": 29345,
        "NOCCode": "9619",
        "JobTitle": "sprayer, toes and heels – shoe manufacturing"
    },
    {
        "value": 29346,
        "NOCCode": "9619",
        "JobTitle": "spreader, machine – garment manufacturing"
    },
    {
        "value": 29347,
        "NOCCode": "9619",
        "JobTitle": "spring inserter – furniture manufacturing"
    },
    {
        "value": 29348,
        "NOCCode": "9619",
        "JobTitle": "springer – furniture assembly"
    },
    {
        "value": 29349,
        "NOCCode": "9619",
        "JobTitle": "stacker – printing"
    },
    {
        "value": 29350,
        "NOCCode": "9619",
        "JobTitle": "stainer-finisher, sole edges – shoe manufacturing"
    },
    {
        "value": 29351,
        "NOCCode": "9619",
        "JobTitle": "stamp filler"
    },
    {
        "value": 29352,
        "NOCCode": "9619",
        "JobTitle": "stamper and labeller"
    },
    {
        "value": 29353,
        "NOCCode": "9619",
        "JobTitle": "steam cabinet attendant – garment manufacturing"
    },
    {
        "value": 29354,
        "NOCCode": "9619",
        "JobTitle": "stitch separator and simulator – shoe manufacturing"
    },
    {
        "value": 29355,
        "NOCCode": "9619",
        "JobTitle": "storage worker – ice processing"
    },
    {
        "value": 29356,
        "NOCCode": "9619",
        "JobTitle": "stripper, paints"
    },
    {
        "value": 29357,
        "NOCCode": "9619",
        "JobTitle": "tender, baler – packaging"
    },
    {
        "value": 29358,
        "NOCCode": "9619",
        "JobTitle": "tender, cotton roll machine"
    },
    {
        "value": 29359,
        "NOCCode": "9619",
        "JobTitle": "tender, incinerator plant"
    },
    {
        "value": 29360,
        "NOCCode": "9619",
        "JobTitle": "tender, smoothing machine – shoe manufacturing"
    },
    {
        "value": 29361,
        "NOCCode": "9619",
        "JobTitle": "tender, tying machine"
    },
    {
        "value": 29362,
        "NOCCode": "9619",
        "JobTitle": "thermostat capillary sealer"
    },
    {
        "value": 29363,
        "NOCCode": "9619",
        "JobTitle": "thread laster – shoe manufacturing"
    },
    {
        "value": 29364,
        "NOCCode": "9619",
        "JobTitle": "thread trimmer – garment manufacturing"
    },
    {
        "value": 29365,
        "NOCCode": "9619",
        "JobTitle": "tie maker"
    },
    {
        "value": 29366,
        "NOCCode": "9619",
        "JobTitle": "tile cutter"
    },
    {
        "value": 29367,
        "NOCCode": "9619",
        "JobTitle": "toe and heel sprayer – shoe manufacturing"
    },
    {
        "value": 29368,
        "NOCCode": "9619",
        "JobTitle": "touch-up cleaner – furniture finishing and refinishing"
    },
    {
        "value": 29369,
        "NOCCode": "9619",
        "JobTitle": "trimmer, brooms"
    },
    {
        "value": 29370,
        "NOCCode": "9619",
        "JobTitle": "trimmer, chairs"
    },
    {
        "value": 29371,
        "NOCCode": "9619",
        "JobTitle": "trimmer, thread – garment manufacturing"
    },
    {
        "value": 29372,
        "NOCCode": "9619",
        "JobTitle": "trimmer, upholstery"
    },
    {
        "value": 29373,
        "NOCCode": "9619",
        "JobTitle": "tubulating machine feeder – glass products manufacturing"
    },
    {
        "value": 29374,
        "NOCCode": "9619",
        "JobTitle": "turner – garment manufacturing"
    },
    {
        "value": 29375,
        "NOCCode": "9619",
        "JobTitle": "tying machine tender"
    },
    {
        "value": 29376,
        "NOCCode": "9619",
        "JobTitle": "upholsterer helper"
    },
    {
        "value": 29377,
        "NOCCode": "9619",
        "JobTitle": "upholstery cleaner and finisher"
    },
    {
        "value": 29378,
        "NOCCode": "9619",
        "JobTitle": "upholstery trimmer"
    },
    {
        "value": 29379,
        "NOCCode": "9619",
        "JobTitle": "vacuum bottle exhaust machine feeder"
    },
    {
        "value": 29380,
        "NOCCode": "9619",
        "JobTitle": "wallpaper printer helper"
    },
    {
        "value": 29381,
        "NOCCode": "9619",
        "JobTitle": "waterproofer, garments – garment manufacturing"
    },
    {
        "value": 29382,
        "NOCCode": "9619",
        "JobTitle": "waxer, explosives manufacturing"
    },
    {
        "value": 29383,
        "NOCCode": "9619",
        "JobTitle": "wedger, machine"
    },
    {
        "value": 29384,
        "NOCCode": "9619",
        "JobTitle": "weigher, production"
    },
    {
        "value": 29385,
        "NOCCode": "9619",
        "JobTitle": "weigher-counter"
    },
    {
        "value": 29386,
        "NOCCode": "9619",
        "JobTitle": "weigher-manufacturing"
    },
    {
        "value": 29387,
        "NOCCode": "9619",
        "JobTitle": "welt butter and tacker – shoe manufacturing"
    },
    {
        "value": 29388,
        "NOCCode": "9619",
        "JobTitle": "winder, paint rollers"
    },
    {
        "value": 29389,
        "NOCCode": "9619",
        "JobTitle": "wire bender, hand"
    },
    {
        "value": 29390,
        "NOCCode": "9619",
        "JobTitle": "wire border assembler – furniture assembly"
    },
    {
        "value": 29391,
        "NOCCode": "9619",
        "JobTitle": "wood products finisher – furniture manufacturing"
    },
    {
        "value": 29392,
        "NOCCode": "9619",
        "JobTitle": "wood products machine feeder"
    },
    {
        "value": 29393,
        "NOCCode": "9619",
        "JobTitle": "wooden box bander"
    },
    {
        "value": 29394,
        "NOCCode": "9619",
        "JobTitle": "wooden coat hanger shaper feeder"
    },
    {
        "value": 29395,
        "NOCCode": "9619",
        "JobTitle": "woodworking machine feeder"
    },
    {
        "value": 29396,
        "NOCCode": "9619",
        "JobTitle": "woodworking shop helper"
    },
    {
        "value": 29397,
        "NOCCode": "9619",
        "JobTitle": "wool puller – hide and pelt processing"
    },
    {
        "value": 29398,
        "NOCCode": "9619",
        "JobTitle": "wrapper – manufacturing"
    },
    {
        "value": 29399,
        "NOCCode": "9619",
        "JobTitle": "wrapper, bundles"
    },
    {
        "value": 29400,
        "NOCCode": "9619",
        "JobTitle": "wrinkle remover – shoe manufacturing"
    },
    {
        "value": 29401,
        "NOCCode": "9619",
        "JobTitle": "zipper repairer"
    }
]