export const JOB_TITLES1 = [
    {
        "value": 1,
        "NOCCode": "0011",
        "JobTitle": "Cabinet minister"
    },
    {
        "value": 2,
        "NOCCode": "0011",
        "JobTitle": "City councillor"
    },
    {
        "value": 3,
        "NOCCode": "0011",
        "JobTitle": "First Nations band chief"
    },
    {
        "value": 4,
        "NOCCode": "0011",
        "JobTitle": "Governor general"
    },
    {
        "value": 5,
        "NOCCode": "0011",
        "JobTitle": "Lieutenant-governor"
    },
    {
        "value": 6,
        "NOCCode": "0011",
        "JobTitle": "Mayor"
    },
    {
        "value": 7,
        "NOCCode": "0011",
        "JobTitle": "Member of Legislative Assembly (MLA)"
    },
    {
        "value": 8,
        "NOCCode": "0011",
        "JobTitle": "Member of Parliament (MP)"
    },
    {
        "value": 9,
        "NOCCode": "0011",
        "JobTitle": "Member of the National Assembly (MNA)"
    },
    {
        "value": 10,
        "NOCCode": "0011",
        "JobTitle": "Member of the Provincial Parliament (MPP)"
    },
    {
        "value": 11,
        "NOCCode": "0011",
        "JobTitle": "Premier"
    },
    {
        "value": 12,
        "NOCCode": "0011",
        "JobTitle": "Prime minister"
    },
    {
        "value": 13,
        "NOCCode": "0011",
        "JobTitle": "School board trustee"
    },
    {
        "value": 14,
        "NOCCode": "0011",
        "JobTitle": "Senator"
    },
    {
        "value": 15,
        "NOCCode": "0012",
        "JobTitle": "Assistant deputy minister"
    },
    {
        "value": 16,
        "NOCCode": "0012",
        "JobTitle": "Chief administrative officer - regional municipality"
    },
    {
        "value": 17,
        "NOCCode": "0012",
        "JobTitle": "Chief statistician - government services"
    },
    {
        "value": 18,
        "NOCCode": "0012",
        "JobTitle": "City administrator"
    },
    {
        "value": 19,
        "NOCCode": "0012",
        "JobTitle": "Deputy minister"
    },
    {
        "value": 20,
        "NOCCode": "0012",
        "JobTitle": "Director general - government services"
    },
    {
        "value": 21,
        "NOCCode": "0012",
        "JobTitle": "Executive director - government services"
    },
    {
        "value": 22,
        "NOCCode": "0012",
        "JobTitle": "High commissioner"
    },
    {
        "value": 23,
        "NOCCode": "0012",
        "JobTitle": "Human Rights Commission chairperson"
    },
    {
        "value": 24,
        "NOCCode": "0012",
        "JobTitle": "County clerk"
    },
    {
        "value": 25,
        "NOCCode": "0013",
        "JobTitle": "Advertising agency president"
    },
    {
        "value": 26,
        "NOCCode": "0013",
        "JobTitle": "Bank president"
    },
    {
        "value": 27,
        "NOCCode": "0013",
        "JobTitle": "Chief executive officer (CEO) - telephone company"
    },
    {
        "value": 28,
        "NOCCode": "0013",
        "JobTitle": "Chief financial officer (CFO) - advertising agency"
    },
    {
        "value": 29,
        "NOCCode": "0013",
        "JobTitle": "Chief operating officer - engineering firm"
    },
    {
        "value": 30,
        "NOCCode": "0013",
        "JobTitle": "Computing services company president"
    },
    {
        "value": 31,
        "NOCCode": "0013",
        "JobTitle": "Credit union executive director"
    },
    {
        "value": 32,
        "NOCCode": "0013",
        "JobTitle": "Executive vice-president - real estate agency"
    },
    {
        "value": 33,
        "NOCCode": "0013",
        "JobTitle": "General manager - real estate management company"
    },
    {
        "value": 34,
        "NOCCode": "0013",
        "JobTitle": "Human resources vice-president - bank"
    },
    {
        "value": 35,
        "NOCCode": "0013",
        "JobTitle": "Marketing vice-president - clerical staff services"
    },
    {
        "value": 36,
        "NOCCode": "0013",
        "JobTitle": "Operations vice-president - satellite communication services"
    },
    {
        "value": 37,
        "NOCCode": "0013",
        "JobTitle": "President and chief executive officer - financial, communications and other business services"
    },
    {
        "value": 38,
        "NOCCode": "0013",
        "JobTitle": "Trust company regional vice-president"
    },
    {
        "value": 39,
        "NOCCode": "0014",
        "JobTitle": "Arts and culture association chairperson"
    },
    {
        "value": 40,
        "NOCCode": "0014",
        "JobTitle": "Automobile association executive director"
    },
    {
        "value": 41,
        "NOCCode": "0014",
        "JobTitle": "Business association president"
    },
    {
        "value": 42,
        "NOCCode": "0014",
        "JobTitle": "Educational institution chief financial officer (CFO)"
    },
    {
        "value": 43,
        "NOCCode": "0014",
        "JobTitle": "Health services institution executive director"
    },
    {
        "value": 44,
        "NOCCode": "0014",
        "JobTitle": "Labour organization president"
    },
    {
        "value": 45,
        "NOCCode": "0014",
        "JobTitle": "Membership organization general manager"
    },
    {
        "value": 46,
        "NOCCode": "0014",
        "JobTitle": "Music guild president"
    },
    {
        "value": 47,
        "NOCCode": "0014",
        "JobTitle": "Professional association executive director"
    },
    {
        "value": 48,
        "NOCCode": "0014",
        "JobTitle": "Scientific association chairperson"
    },
    {
        "value": 49,
        "NOCCode": "0014",
        "JobTitle": "Social services institution corporate controller"
    },
    {
        "value": 50,
        "NOCCode": "0014",
        "JobTitle": "Voluntary organization executive directo"
    },
    {
        "value": 51,
        "NOCCode": "0015",
        "JobTitle": "Cleaning service general manager"
    },
    {
        "value": 52,
        "NOCCode": "0015",
        "JobTitle": "Department store president"
    },
    {
        "value": 53,
        "NOCCode": "0015",
        "JobTitle": "Engineering vice-president - television broadcasting services"
    },
    {
        "value": 54,
        "NOCCode": "0015",
        "JobTitle": "Finance vice-president - food wholesaling"
    },
    {
        "value": 55,
        "NOCCode": "0015",
        "JobTitle": "Hotel chain regional vice-president"
    },
    {
        "value": 56,
        "NOCCode": "0015",
        "JobTitle": "Marketing vice-president - grocery store chain"
    },
    {
        "value": 57,
        "NOCCode": "0015",
        "JobTitle": "Operations vice-president - radio broadcasting services"
    },
    {
        "value": 58,
        "NOCCode": "0015",
        "JobTitle": "Professional sports club chief financial officer (CFO)"
    },
    {
        "value": 59,
        "NOCCode": "0015",
        "JobTitle": "Restaurant chain corporate controller"
    },
    {
        "value": 60,
        "NOCCode": "0015",
        "JobTitle": "Travel agency chief executive officer (CEO"
    },
    {
        "value": 61,
        "NOCCode": "0016",
        "JobTitle": "Chief executive officer (CEO) - manufacturing company"
    },
    {
        "value": 62,
        "NOCCode": "0016",
        "JobTitle": "Chief financial officer (CFO) - urban transit system"
    },
    {
        "value": 63,
        "NOCCode": "0016",
        "JobTitle": "Executive vice-president - railway"
    },
    {
        "value": 64,
        "NOCCode": "0016",
        "JobTitle": "Finance vice-president - mining company"
    },
    {
        "value": 65,
        "NOCCode": "0016",
        "JobTitle": "Logging company corporate controller"
    },
    {
        "value": 66,
        "NOCCode": "0016",
        "JobTitle": "Marketing vice-president - airline"
    },
    {
        "value": 67,
        "NOCCode": "0016",
        "JobTitle": "Operations vice-president - electric power company"
    },
    {
        "value": 68,
        "NOCCode": "0016",
        "JobTitle": "Petroleum production company regional vice-president"
    },
    {
        "value": 69,
        "NOCCode": "0016",
        "JobTitle": "Publishing house general manager"
    },
    {
        "value": 70,
        "NOCCode": "0016",
        "JobTitle": "Residential construction company president"
    },
    {
        "value": 71,
        "NOCCode": "0016",
        "JobTitle": "Trucking company general manager"
    },
    {
        "value": 72,
        "NOCCode": "0111",
        "JobTitle": "Controller - financial services"
    },
    {
        "value": 73,
        "NOCCode": "0111",
        "JobTitle": "Director - financial services"
    },
    {
        "value": 74,
        "NOCCode": "0111",
        "JobTitle": "Director of accounting"
    },
    {
        "value": 75,
        "NOCCode": "0111",
        "JobTitle": "Finance director"
    },
    {
        "value": 76,
        "NOCCode": "0111",
        "JobTitle": "Financial administrator"
    },
    {
        "value": 77,
        "NOCCode": "0111",
        "JobTitle": "Financial control manager"
    },
    {
        "value": 78,
        "NOCCode": "0111",
        "JobTitle": "Financial planning and analysis manager"
    },
    {
        "value": 79,
        "NOCCode": "0111",
        "JobTitle": "Internal audit services manager"
    },
    {
        "value": 80,
        "NOCCode": "0111",
        "JobTitle": "Treasurer"
    },
    {
        "value": 81,
        "NOCCode": "0111",
        "JobTitle": "Corporate risk department manager"
    },
    {
        "value": 82,
        "NOCCode": "0111",
        "JobTitle": "Pension plans administrator"
    },
    {
        "value": 83,
        "NOCCode": "0111",
        "JobTitle": "Pension services manager"
    },
    {
        "value": 84,
        "NOCCode": "0112",
        "JobTitle": "Employer-employee relations manager"
    },
    {
        "value": 85,
        "NOCCode": "0112",
        "JobTitle": "Human resources administrator"
    },
    {
        "value": 86,
        "NOCCode": "0112",
        "JobTitle": "Human resources manager"
    },
    {
        "value": 87,
        "NOCCode": "0112",
        "JobTitle": "Industrial relations manager"
    },
    {
        "value": 88,
        "NOCCode": "0112",
        "JobTitle": "Occupational health and safety manager"
    },
    {
        "value": 89,
        "NOCCode": "0112",
        "JobTitle": "Pay and benefits manager"
    },
    {
        "value": 90,
        "NOCCode": "0112",
        "JobTitle": "Personnel director"
    },
    {
        "value": 91,
        "NOCCode": "0112",
        "JobTitle": "Personnel services manager"
    },
    {
        "value": 92,
        "NOCCode": "0112",
        "JobTitle": "Personnel training and development manager"
    },
    {
        "value": 93,
        "NOCCode": "0112",
        "JobTitle": "Recruiting manager"
    },
    {
        "value": 94,
        "NOCCode": "0112",
        "JobTitle": "Staff relations manager"
    },
    {
        "value": 95,
        "NOCCode": "0113",
        "JobTitle": "Contract manager"
    },
    {
        "value": 96,
        "NOCCode": "0113",
        "JobTitle": "Food purchasing manager"
    },
    {
        "value": 97,
        "NOCCode": "0113",
        "JobTitle": "Material manager"
    },
    {
        "value": 98,
        "NOCCode": "0113",
        "JobTitle": "Procurement director"
    },
    {
        "value": 99,
        "NOCCode": "0113",
        "JobTitle": "Purchasing contracts manager"
    },
    {
        "value": 100,
        "NOCCode": "0113",
        "JobTitle": "Purchasing director"
    },
    {
        "value": 101,
        "NOCCode": "0113",
        "JobTitle": "Supply chain logistics manager"
    },
    {
        "value": 102,
        "NOCCode": "0114",
        "JobTitle": "Administrative services chief"
    },
    {
        "value": 103,
        "NOCCode": "0114",
        "JobTitle": "Administrative services manager"
    },
    {
        "value": 104,
        "NOCCode": "0114",
        "JobTitle": "Business manager - non-profit organization"
    },
    {
        "value": 105,
        "NOCCode": "0114",
        "JobTitle": "Inventory control manager"
    },
    {
        "value": 106,
        "NOCCode": "0114",
        "JobTitle": "Records administrator"
    },
    {
        "value": 107,
        "NOCCode": "0114",
        "JobTitle": "Regional administrative manager"
    },
    {
        "value": 108,
        "NOCCode": "0114",
        "JobTitle": "Security manager"
    },
    {
        "value": 109,
        "NOCCode": "0114",
        "JobTitle": "Support services manager"
    },
    {
        "value": 110,
        "NOCCode": "0121",
        "JobTitle": "Bond sales manager"
    },
    {
        "value": 111,
        "NOCCode": "0121",
        "JobTitle": "Brokerage manager - investments"
    },
    {
        "value": 112,
        "NOCCode": "0121",
        "JobTitle": "Commodities trading manager"
    },
    {
        "value": 113,
        "NOCCode": "0121",
        "JobTitle": "Financial brokerage manager"
    },
    {
        "value": 114,
        "NOCCode": "0121",
        "JobTitle": "Insurance claims service manager"
    },
    {
        "value": 115,
        "NOCCode": "0121",
        "JobTitle": "Insurance manager"
    },
    {
        "value": 116,
        "NOCCode": "0121",
        "JobTitle": "Investment manager - financial brokerage"
    },
    {
        "value": 117,
        "NOCCode": "0121",
        "JobTitle": "Mortgage broker manager"
    },
    {
        "value": 118,
        "NOCCode": "0121",
        "JobTitle": "Real estate service manager"
    },
    {
        "value": 119,
        "NOCCode": "0121",
        "JobTitle": "Securities sales director"
    },
    {
        "value": 120,
        "NOCCode": "0121",
        "JobTitle": "Trading floor manager"
    },
    {
        "value": 121,
        "NOCCode": "0121",
        "JobTitle": "Real estate service developer"
    },
    {
        "value": 122,
        "NOCCode": "0122",
        "JobTitle": "Assistant operations manager - banking, credit and investment"
    },
    {
        "value": 123,
        "NOCCode": "0122",
        "JobTitle": "Bank manager"
    },
    {
        "value": 124,
        "NOCCode": "0122",
        "JobTitle": "Banking operations manager"
    },
    {
        "value": 125,
        "NOCCode": "0122",
        "JobTitle": "Collection centre manager"
    },
    {
        "value": 126,
        "NOCCode": "0122",
        "JobTitle": "Commercial banking manager"
    },
    {
        "value": 127,
        "NOCCode": "0122",
        "JobTitle": "Corporate banking centre manager"
    },
    {
        "value": 128,
        "NOCCode": "0122",
        "JobTitle": "Corporate services manager - banking, credit and investment"
    },
    {
        "value": 129,
        "NOCCode": "0122",
        "JobTitle": "Credit card centre manager"
    },
    {
        "value": 130,
        "NOCCode": "0122",
        "JobTitle": "Credit card company operations manager"
    },
    {
        "value": 131,
        "NOCCode": "0122",
        "JobTitle": "Credit manager"
    },
    {
        "value": 132,
        "NOCCode": "0122",
        "JobTitle": "Credit union manager"
    },
    {
        "value": 133,
        "NOCCode": "0122",
        "JobTitle": "Mortgage and consumer credit manager"
    },
    {
        "value": 134,
        "NOCCode": "0122",
        "JobTitle": "Personal services manager - banking, credit and investment"
    },
    {
        "value": 135,
        "NOCCode": "0122",
        "JobTitle": "Regional collection manager"
    },
    {
        "value": 136,
        "NOCCode": "0122",
        "JobTitle": "Trust company manager"
    },
    {
        "value": 137,
        "NOCCode": "0124",
        "JobTitle": "Advertising director"
    },
    {
        "value": 138,
        "NOCCode": "0124",
        "JobTitle": "Advertising manager"
    },
    {
        "value": 139,
        "NOCCode": "0124",
        "JobTitle": "Electronic business (e-business) manager"
    },
    {
        "value": 140,
        "NOCCode": "0124",
        "JobTitle": "Fundraising campaign manager"
    },
    {
        "value": 141,
        "NOCCode": "0124",
        "JobTitle": "Internet communications manager"
    },
    {
        "value": 142,
        "NOCCode": "0124",
        "JobTitle": "Marketing manager"
    },
    {
        "value": 143,
        "NOCCode": "0124",
        "JobTitle": "Media relations director"
    },
    {
        "value": 144,
        "NOCCode": "0124",
        "JobTitle": "Promotions manager"
    },
    {
        "value": 145,
        "NOCCode": "0124",
        "JobTitle": "Public relations director"
    },
    {
        "value": 146,
        "NOCCode": "0124",
        "JobTitle": "Sales and marketing manager"
    },
    {
        "value": 147,
        "NOCCode": "0124",
        "JobTitle": "Web communications manager"
    },
    {
        "value": 148,
        "NOCCode": "0124",
        "JobTitle": "Web marketing manager"
    },
    {
        "value": 149,
        "NOCCode": "0125",
        "JobTitle": "Accounting firm manager"
    },
    {
        "value": 150,
        "NOCCode": "0125",
        "JobTitle": "Contact centre manager"
    },
    {
        "value": 151,
        "NOCCode": "0125",
        "JobTitle": "Employment agency manager"
    },
    {
        "value": 152,
        "NOCCode": "0125",
        "JobTitle": "Energy asset joint venture manager"
    },
    {
        "value": 153,
        "NOCCode": "0125",
        "JobTitle": "Legal firm manager"
    },
    {
        "value": 154,
        "NOCCode": "0125",
        "JobTitle": "Management consulting service manager"
    },
    {
        "value": 155,
        "NOCCode": "0125",
        "JobTitle": "Market research service manager"
    },
    {
        "value": 156,
        "NOCCode": "0125",
        "JobTitle": "Nursing registry manager"
    },
    {
        "value": 157,
        "NOCCode": "0125",
        "JobTitle": "Payroll service manager"
    },
    {
        "value": 158,
        "NOCCode": "0125",
        "JobTitle": "Personnel agency manager"
    },
    {
        "value": 159,
        "NOCCode": "0125",
        "JobTitle": "Professional services manager - business services"
    },
    {
        "value": 160,
        "NOCCode": "0125",
        "JobTitle": "Security service manager"
    },
    {
        "value": 161,
        "NOCCode": "0131",
        "JobTitle": "Microwave facilities manager - telecommunications"
    },
    {
        "value": 162,
        "NOCCode": "0131",
        "JobTitle": "Network installation manager - telecommunications"
    },
    {
        "value": 163,
        "NOCCode": "0131",
        "JobTitle": "Network operations manager - telecommunications"
    },
    {
        "value": 164,
        "NOCCode": "0131",
        "JobTitle": "Network services manager - telecommunications"
    },
    {
        "value": 165,
        "NOCCode": "0131",
        "JobTitle": "Operations and transmission services manager - telecommunications"
    },
    {
        "value": 166,
        "NOCCode": "0131",
        "JobTitle": "Regional manager - telecommunications system"
    },
    {
        "value": 167,
        "NOCCode": "0131",
        "JobTitle": "Switching systems director - telecommunications"
    },
    {
        "value": 168,
        "NOCCode": "0131",
        "JobTitle": "Telecommunications facilities manager"
    },
    {
        "value": 169,
        "NOCCode": "0131",
        "JobTitle": "Telecommunications installation manager"
    },
    {
        "value": 170,
        "NOCCode": "0131",
        "JobTitle": "Telecommunications manager"
    },
    {
        "value": 171,
        "NOCCode": "0131",
        "JobTitle": "Telecommunications services manager"
    },
    {
        "value": 172,
        "NOCCode": "0131",
        "JobTitle": "Telephone company district manager"
    },
    {
        "value": 173,
        "NOCCode": "0132",
        "JobTitle": "Collection and delivery operations manager - postal service"
    },
    {
        "value": 174,
        "NOCCode": "0132",
        "JobTitle": "Mail operations manager"
    },
    {
        "value": 175,
        "NOCCode": "0132",
        "JobTitle": "Mail processing plant manager"
    },
    {
        "value": 176,
        "NOCCode": "0132",
        "JobTitle": "Messenger service manager"
    },
    {
        "value": 177,
        "NOCCode": "0132",
        "JobTitle": "Parcel processing plant manager"
    },
    {
        "value": 178,
        "NOCCode": "0132",
        "JobTitle": "Post office manager"
    },
    {
        "value": 179,
        "NOCCode": "0132",
        "JobTitle": "Postal station superintendent"
    },
    {
        "value": 180,
        "NOCCode": "0132",
        "JobTitle": "Production control manager - postal service"
    },
    {
        "value": 181,
        "NOCCode": "0211",
        "JobTitle": "Electrical engineering service manager"
    },
    {
        "value": 182,
        "NOCCode": "0211",
        "JobTitle": "Engineering department manager"
    },
    {
        "value": 183,
        "NOCCode": "0211",
        "JobTitle": "Engineering manager"
    },
    {
        "value": 184,
        "NOCCode": "0211",
        "JobTitle": "Engineering research and development director"
    },
    {
        "value": 185,
        "NOCCode": "0211",
        "JobTitle": "Production engineering manager"
    },
    {
        "value": 186,
        "NOCCode": "0211",
        "JobTitle": "Telecommunications engineering service manager"
    },
    {
        "value": 187,
        "NOCCode": "0212",
        "JobTitle": "Agricultural chemistry branch director"
    },
    {
        "value": 188,
        "NOCCode": "0212",
        "JobTitle": "Architectural manager"
    },
    {
        "value": 189,
        "NOCCode": "0212",
        "JobTitle": "Chief actuary"
    },
    {
        "value": 190,
        "NOCCode": "0212",
        "JobTitle": "Director of research - forestry"
    },
    {
        "value": 191,
        "NOCCode": "0212",
        "JobTitle": "Director of research - manufacturing"
    },
    {
        "value": 192,
        "NOCCode": "0212",
        "JobTitle": "Director of research - mining"
    },
    {
        "value": 193,
        "NOCCode": "0212",
        "JobTitle": "Landscape architecture manager"
    },
    {
        "value": 194,
        "NOCCode": "0212",
        "JobTitle": "Life sciences program manager"
    },
    {
        "value": 195,
        "NOCCode": "0212",
        "JobTitle": "Petroleum geology department manager"
    },
    {
        "value": 196,
        "NOCCode": "0212",
        "JobTitle": "Scientific research department manager"
    },
    {
        "value": 197,
        "NOCCode": "0212",
        "JobTitle": "Statistical service manager"
    },
    {
        "value": 198,
        "NOCCode": "0213",
        "JobTitle": "Computer systems manager"
    },
    {
        "value": 199,
        "NOCCode": "0213",
        "JobTitle": "Data centre manager"
    },
    {
        "value": 200,
        "NOCCode": "0213",
        "JobTitle": "Data processing and systems analysis manager"
    },
    {
        "value": 201,
        "NOCCode": "0213",
        "JobTitle": "Data processing director"
    },
    {
        "value": 202,
        "NOCCode": "0213",
        "JobTitle": "Electronic data processing (EDP) manager"
    },
    {
        "value": 203,
        "NOCCode": "0213",
        "JobTitle": "Information systems manager"
    },
    {
        "value": 204,
        "NOCCode": "0213",
        "JobTitle": "Management information system (MIS) manager"
    },
    {
        "value": 205,
        "NOCCode": "0213",
        "JobTitle": "Software development manager"
    },
    {
        "value": 206,
        "NOCCode": "0213",
        "JobTitle": "Software engineering manager"
    },
    {
        "value": 207,
        "NOCCode": "0213",
        "JobTitle": "Systems development manager"
    },
    {
        "value": 208,
        "NOCCode": "0311",
        "JobTitle": "Chief of anesthesia"
    },
    {
        "value": 209,
        "NOCCode": "0311",
        "JobTitle": "Chief of emergency medicine"
    },
    {
        "value": 210,
        "NOCCode": "0311",
        "JobTitle": "Chief of medical staff"
    },
    {
        "value": 211,
        "NOCCode": "0311",
        "JobTitle": "Director of clinical medicine"
    },
    {
        "value": 212,
        "NOCCode": "0311",
        "JobTitle": "Director of dietetics"
    },
    {
        "value": 213,
        "NOCCode": "0311",
        "JobTitle": "Director of laboratory medicine"
    },
    {
        "value": 214,
        "NOCCode": "0311",
        "JobTitle": "Director of nursing - medical services"
    },
    {
        "value": 215,
        "NOCCode": "0311",
        "JobTitle": "Director of occupational therapy"
    },
    {
        "value": 216,
        "NOCCode": "0311",
        "JobTitle": "Director of physiotherapy"
    },
    {
        "value": 217,
        "NOCCode": "0311",
        "JobTitle": "Director of surgery"
    },
    {
        "value": 218,
        "NOCCode": "0311",
        "JobTitle": "Home care services director - medical services"
    },
    {
        "value": 219,
        "NOCCode": "0311",
        "JobTitle": "Medical clinic director"
    },
    {
        "value": 220,
        "NOCCode": "0311",
        "JobTitle": "Mental health residential care program manager"
    },
    {
        "value": 221,
        "NOCCode": "0311",
        "JobTitle": "Rehabilitation services director"
    },
    {
        "value": 222,
        "NOCCode": "0411",
        "JobTitle": "City medical officer of health - government services"
    },
    {
        "value": 223,
        "NOCCode": "0411",
        "JobTitle": "Health information and promotion director - government services"
    },
    {
        "value": 224,
        "NOCCode": "0411",
        "JobTitle": "Health program operations manager - government services"
    },
    {
        "value": 225,
        "NOCCode": "0411",
        "JobTitle": "Housing policy and development director - government services"
    },
    {
        "value": 226,
        "NOCCode": "0411",
        "JobTitle": "Immigration and settlement director - government services"
    },
    {
        "value": 227,
        "NOCCode": "0411",
        "JobTitle": "Social services director - government services"
    },
    {
        "value": 228,
        "NOCCode": "0411",
        "JobTitle": "Social services planning manager - government services"
    },
    {
        "value": 229,
        "NOCCode": "0412",
        "JobTitle": "Border services manager"
    },
    {
        "value": 230,
        "NOCCode": "0412",
        "JobTitle": "Business development director - government services"
    },
    {
        "value": 231,
        "NOCCode": "0412",
        "JobTitle": "Customs manager - government services"
    },
    {
        "value": 232,
        "NOCCode": "0412",
        "JobTitle": "Economic analysis chief - government services"
    },
    {
        "value": 233,
        "NOCCode": "0412",
        "JobTitle": "Economic development director - government services"
    },
    {
        "value": 234,
        "NOCCode": "0412",
        "JobTitle": "Economic policy analysis director - government services"
    },
    {
        "value": 235,
        "NOCCode": "0412",
        "JobTitle": "Energy market analysis director - government services"
    },
    {
        "value": 236,
        "NOCCode": "0412",
        "JobTitle": "Excise tax programs director - government services"
    },
    {
        "value": 237,
        "NOCCode": "0412",
        "JobTitle": "Farm products marketing director - government services"
    },
    {
        "value": 238,
        "NOCCode": "0412",
        "JobTitle": "Forest resource analysis chief - government services"
    },
    {
        "value": 239,
        "NOCCode": "0412",
        "JobTitle": "Grain market analysis manager - government services"
    },
    {
        "value": 240,
        "NOCCode": "0412",
        "JobTitle": "International trade policy manager - government services"
    },
    {
        "value": 241,
        "NOCCode": "0412",
        "JobTitle": "Revenue programs manager - government services"
    },
    {
        "value": 242,
        "NOCCode": "0412",
        "JobTitle": "Tax policy research manager - government services"
    },
    {
        "value": 243,
        "NOCCode": "0412",
        "JobTitle": "Taxation manager"
    },
    {
        "value": 244,
        "NOCCode": "0412",
        "JobTitle": "Tourism development manager - government services"
    },
    {
        "value": 245,
        "NOCCode": "0412",
        "JobTitle": "Trade development director"
    },
    {
        "value": 246,
        "NOCCode": "0413",
        "JobTitle": "Education curriculum development director"
    },
    {
        "value": 247,
        "NOCCode": "0413",
        "JobTitle": "Education director"
    },
    {
        "value": 248,
        "NOCCode": "0413",
        "JobTitle": "Education policy analysis and research director"
    },
    {
        "value": 249,
        "NOCCode": "0413",
        "JobTitle": "Education program administration manager"
    },
    {
        "value": 250,
        "NOCCode": "0413",
        "JobTitle": "School inspection director - public administration"
    },
    {
        "value": 251,
        "NOCCode": "0414",
        "JobTitle": "Clerk of the committee - Legislative Assembly"
    },
    {
        "value": 252,
        "NOCCode": "0414",
        "JobTitle": "Elections planning director"
    },
    {
        "value": 253,
        "NOCCode": "0414",
        "JobTitle": "Federal-provincial relations director"
    },
    {
        "value": 254,
        "NOCCode": "0414",
        "JobTitle": "Intergovernmental affairs director"
    },
    {
        "value": 255,
        "NOCCode": "0421",
        "JobTitle": "Aviation school manager"
    },
    {
        "value": 256,
        "NOCCode": "0421",
        "JobTitle": "Business school manager"
    },
    {
        "value": 257,
        "NOCCode": "0421",
        "JobTitle": "Community college dean"
    },
    {
        "value": 258,
        "NOCCode": "0421",
        "JobTitle": "Community college registrar"
    },
    {
        "value": 259,
        "NOCCode": "0421",
        "JobTitle": "Dean - university or college"
    },
    {
        "value": 260,
        "NOCCode": "0421",
        "JobTitle": "Dean of adult education"
    },
    {
        "value": 261,
        "NOCCode": "0421",
        "JobTitle": "Faculty of science dean"
    },
    {
        "value": 262,
        "NOCCode": "0421",
        "JobTitle": "School of nursing dean"
    },
    {
        "value": 263,
        "NOCCode": "0421",
        "JobTitle": "School of technology dean"
    },
    {
        "value": 264,
        "NOCCode": "0421",
        "JobTitle": "Student activities dean"
    },
    {
        "value": 265,
        "NOCCode": "0421",
        "JobTitle": "Trade school manager"
    },
    {
        "value": 266,
        "NOCCode": "0421",
        "JobTitle": "University registrar"
    },
    {
        "value": 267,
        "NOCCode": "0422",
        "JobTitle": "Board of education administrator"
    },
    {
        "value": 268,
        "NOCCode": "0422",
        "JobTitle": "Chief superintendent - schools"
    },
    {
        "value": 269,
        "NOCCode": "0422",
        "JobTitle": "Director of education - correctional institution"
    },
    {
        "value": 270,
        "NOCCode": "0422",
        "JobTitle": "Director of school for the hearing impaired"
    },
    {
        "value": 271,
        "NOCCode": "0422",
        "JobTitle": "District school superintendent"
    },
    {
        "value": 272,
        "NOCCode": "0422",
        "JobTitle": "Private school headmaster/mistress"
    },
    {
        "value": 273,
        "NOCCode": "0422",
        "JobTitle": "School principal"
    },
    {
        "value": 274,
        "NOCCode": "0422",
        "JobTitle": "School vice-principal"
    },
    {
        "value": 275,
        "NOCCode": "0422",
        "JobTitle": "Secondary school principal"
    },
    {
        "value": 276,
        "NOCCode": "0422",
        "JobTitle": "Superintendent of elementary education"
    },
    {
        "value": 277,
        "NOCCode": "0422",
        "JobTitle": "Superintendent of secondary schools"
    },
    {
        "value": 278,
        "NOCCode": "0422",
        "JobTitle": "Superintendent of special education"
    },
    {
        "value": 279,
        "NOCCode": "0423",
        "JobTitle": "Association director"
    },
    {
        "value": 280,
        "NOCCode": "0423",
        "JobTitle": "Child welfare services administrator"
    },
    {
        "value": 281,
        "NOCCode": "0423",
        "JobTitle": "Children's aid society director"
    },
    {
        "value": 282,
        "NOCCode": "0423",
        "JobTitle": "Community centre director"
    },
    {
        "value": 283,
        "NOCCode": "0423",
        "JobTitle": "Correctional institution director"
    },
    {
        "value": 284,
        "NOCCode": "0423",
        "JobTitle": "Correctional treatment and training director"
    },
    {
        "value": 285,
        "NOCCode": "0423",
        "JobTitle": "Detention centre director"
    },
    {
        "value": 286,
        "NOCCode": "0423",
        "JobTitle": "Environmental group director"
    },
    {
        "value": 287,
        "NOCCode": "0423",
        "JobTitle": "Family services area manager"
    },
    {
        "value": 288,
        "NOCCode": "0423",
        "JobTitle": "Income maintenance director"
    },
    {
        "value": 289,
        "NOCCode": "0423",
        "JobTitle": "Labour organization manager"
    },
    {
        "value": 290,
        "NOCCode": "0423",
        "JobTitle": "Membership services manager"
    },
    {
        "value": 291,
        "NOCCode": "0423",
        "JobTitle": "Political organization manager"
    },
    {
        "value": 292,
        "NOCCode": "0423",
        "JobTitle": "Prison warden"
    },
    {
        "value": 293,
        "NOCCode": "0423",
        "JobTitle": "Social assistance director"
    },
    {
        "value": 294,
        "NOCCode": "0423",
        "JobTitle": "Social services regional administrator"
    },
    {
        "value": 295,
        "NOCCode": "0423",
        "JobTitle": "Social work director"
    },
    {
        "value": 296,
        "NOCCode": "0423",
        "JobTitle": "Trade association manager"
    },
    {
        "value": 297,
        "NOCCode": "0423",
        "JobTitle": "Volunteer services director"
    },
    {
        "value": 298,
        "NOCCode": "0431",
        "JobTitle": "Chief superintendent"
    },
    {
        "value": 299,
        "NOCCode": "0431",
        "JobTitle": "Deputy police chief"
    },
    {
        "value": 300,
        "NOCCode": "0431",
        "JobTitle": "Harbour police chief"
    },
    {
        "value": 301,
        "NOCCode": "0431",
        "JobTitle": "Police chief"
    },
    {
        "value": 302,
        "NOCCode": "0431",
        "JobTitle": "Police lieutenant"
    },
    {
        "value": 303,
        "NOCCode": "0431",
        "JobTitle": "Police superintendent"
    },
    {
        "value": 304,
        "NOCCode": "0431",
        "JobTitle": "Railway police chief"
    },
    {
        "value": 305,
        "NOCCode": "0431",
        "JobTitle": "Royal Canadian Mounted Police (RCMP) commissioner"
    },
    {
        "value": 306,
        "NOCCode": "0431",
        "JobTitle": "Staff inspector"
    },
    {
        "value": 307,
        "NOCCode": "0432",
        "JobTitle": "Deputy fire chief"
    },
    {
        "value": 308,
        "NOCCode": "0432",
        "JobTitle": "District fire chief"
    },
    {
        "value": 309,
        "NOCCode": "0432",
        "JobTitle": "Fire chief"
    },
    {
        "value": 310,
        "NOCCode": "0433",
        "JobTitle": "Admiral"
    },
    {
        "value": 311,
        "NOCCode": "0433",
        "JobTitle": "Artillery officer"
    },
    {
        "value": 312,
        "NOCCode": "0433",
        "JobTitle": "Brigadier general"
    },
    {
        "value": 313,
        "NOCCode": "0433",
        "JobTitle": "Colonel"
    },
    {
        "value": 314,
        "NOCCode": "0433",
        "JobTitle": "Commodore"
    },
    {
        "value": 315,
        "NOCCode": "0433",
        "JobTitle": "Lieutenant commander"
    },
    {
        "value": 316,
        "NOCCode": "0433",
        "JobTitle": "Major"
    },
    {
        "value": 317,
        "NOCCode": "0433",
        "JobTitle": "Naval cadet"
    },
    {
        "value": 318,
        "NOCCode": "0433",
        "JobTitle": "Officer cadet"
    },
    {
        "value": 319,
        "NOCCode": "0433",
        "JobTitle": "Search and rescue captain"
    },
    {
        "value": 320,
        "NOCCode": "0511",
        "JobTitle": "Archives director"
    },
    {
        "value": 321,
        "NOCCode": "0511",
        "JobTitle": "Art gallery manager"
    },
    {
        "value": 322,
        "NOCCode": "0511",
        "JobTitle": "Assistant director of archives"
    },
    {
        "value": 323,
        "NOCCode": "0511",
        "JobTitle": "Chief librarian"
    },
    {
        "value": 324,
        "NOCCode": "0511",
        "JobTitle": "Library director"
    },
    {
        "value": 325,
        "NOCCode": "0511",
        "JobTitle": "Museum administrator"
    },
    {
        "value": 326,
        "NOCCode": "0511",
        "JobTitle": "Museum executive director"
    },
    {
        "value": 327,
        "NOCCode": "0512",
        "JobTitle": "Ballet company director"
    },
    {
        "value": 328,
        "NOCCode": "0512",
        "JobTitle": "Book publishing manager"
    },
    {
        "value": 329,
        "NOCCode": "0512",
        "JobTitle": "Broadcasting manager"
    },
    {
        "value": 330,
        "NOCCode": "0512",
        "JobTitle": "Broadcasting station manager"
    },
    {
        "value": 331,
        "NOCCode": "0512",
        "JobTitle": "Copyright manager - publishing"
    },
    {
        "value": 332,
        "NOCCode": "0512",
        "JobTitle": "Dance company director"
    },
    {
        "value": 333,
        "NOCCode": "0512",
        "JobTitle": "Editor-in-chief"
    },
    {
        "value": 334,
        "NOCCode": "0512",
        "JobTitle": "Managing editor"
    },
    {
        "value": 335,
        "NOCCode": "0512",
        "JobTitle": "Opera company director"
    },
    {
        "value": 336,
        "NOCCode": "0512",
        "JobTitle": "Publishing manager"
    },
    {
        "value": 337,
        "NOCCode": "0512",
        "JobTitle": "Radio programming manager"
    },
    {
        "value": 338,
        "NOCCode": "0512",
        "JobTitle": "Radio station manager"
    },
    {
        "value": 339,
        "NOCCode": "0512",
        "JobTitle": "Television station manager"
    },
    {
        "value": 340,
        "NOCCode": "0512",
        "JobTitle": "Theatre company manager"
    },
    {
        "value": 341,
        "NOCCode": "0512",
        "JobTitle": "Video production company manager"
    },
    {
        "value": 342,
        "NOCCode": "0513",
        "JobTitle": "Director of recreation"
    },
    {
        "value": 343,
        "NOCCode": "0513",
        "JobTitle": "Professional football team manager"
    },
    {
        "value": 344,
        "NOCCode": "0513",
        "JobTitle": "Professional hockey team manager"
    },
    {
        "value": 345,
        "NOCCode": "0513",
        "JobTitle": "Professional track and field team manager"
    },
    {
        "value": 346,
        "NOCCode": "0513",
        "JobTitle": "Sports administration director - government"
    },
    {
        "value": 347,
        "NOCCode": "0513",
        "JobTitle": "Sports association director"
    },
    {
        "value": 348,
        "NOCCode": "0513",
        "JobTitle": "Sports federation director"
    },
    {
        "value": 349,
        "NOCCode": "0513",
        "JobTitle": "Sports program director"
    },
    {
        "value": 350,
        "NOCCode": "0513",
        "JobTitle": "Young Men's Christian Association (YMCA) programs director"
    },
    {
        "value": 351,
        "NOCCode": "0601",
        "JobTitle": "Corporate sales manager"
    },
    {
        "value": 352,
        "NOCCode": "0601",
        "JobTitle": "National accounts manager - sales"
    },
    {
        "value": 353,
        "NOCCode": "0601",
        "JobTitle": "Regional sales manager"
    },
    {
        "value": 354,
        "NOCCode": "0601",
        "JobTitle": "Trade expansion manager"
    },
    {
        "value": 355,
        "NOCCode": "0621",
        "JobTitle": "Antique dealer"
    },
    {
        "value": 356,
        "NOCCode": "0621",
        "JobTitle": "Assistant manager - retail"
    },
    {
        "value": 357,
        "NOCCode": "0621",
        "JobTitle": "Automobile repair shop manager"
    },
    {
        "value": 358,
        "NOCCode": "0621",
        "JobTitle": "Bookstore manager"
    },
    {
        "value": 359,
        "NOCCode": "0621",
        "JobTitle": "Car dealership manager"
    },
    {
        "value": 360,
        "NOCCode": "0621",
        "JobTitle": "Cattle dealer"
    },
    {
        "value": 361,
        "NOCCode": "0621",
        "JobTitle": "Clothing store manager"
    },
    {
        "value": 362,
        "NOCCode": "0621",
        "JobTitle": "Department store manager"
    },
    {
        "value": 363,
        "NOCCode": "0621",
        "JobTitle": "Pleasure boat dealer"
    },
    {
        "value": 364,
        "NOCCode": "0621",
        "JobTitle": "Sporting goods store manager"
    },
    {
        "value": 365,
        "NOCCode": "0621",
        "JobTitle": "Store manager - retail"
    },
    {
        "value": 366,
        "NOCCode": "0621",
        "JobTitle": "Supermarket manager"
    },
    {
        "value": 367,
        "NOCCode": "0621",
        "JobTitle": "Variety store manager"
    },
    {
        "value": 368,
        "NOCCode": "0631",
        "JobTitle": "Banquet manager"
    },
    {
        "value": 369,
        "NOCCode": "0631",
        "JobTitle": "Bar manager"
    },
    {
        "value": 370,
        "NOCCode": "0631",
        "JobTitle": "Cafeteria manager"
    },
    {
        "value": 371,
        "NOCCode": "0631",
        "JobTitle": "Catering service manager"
    },
    {
        "value": 372,
        "NOCCode": "0631",
        "JobTitle": "Dining room manager"
    },
    {
        "value": 373,
        "NOCCode": "0631",
        "JobTitle": "Food services manager"
    },
    {
        "value": 374,
        "NOCCode": "0631",
        "JobTitle": "Hotel food and beverage service manager"
    },
    {
        "value": 375,
        "NOCCode": "0631",
        "JobTitle": "Restaurant assistant manager"
    },
    {
        "value": 376,
        "NOCCode": "0631",
        "JobTitle": "Restaurant manager"
    },
    {
        "value": 377,
        "NOCCode": "0631",
        "JobTitle": "Restaurateur - food services"
    },
    {
        "value": 378,
        "NOCCode": "0632",
        "JobTitle": "Bed and breakfast operator"
    },
    {
        "value": 379,
        "NOCCode": "0632",
        "JobTitle": "Front desk manager - accommodation services"
    },
    {
        "value": 380,
        "NOCCode": "0632",
        "JobTitle": "Guest-house operator"
    },
    {
        "value": 381,
        "NOCCode": "0632",
        "JobTitle": "Hotel assistant manager"
    },
    {
        "value": 382,
        "NOCCode": "0632",
        "JobTitle": "Hotel director"
    },
    {
        "value": 383,
        "NOCCode": "0632",
        "JobTitle": "Hotel manager"
    },
    {
        "value": 384,
        "NOCCode": "0632",
        "JobTitle": "Motel manager"
    },
    {
        "value": 385,
        "NOCCode": "0632",
        "JobTitle": "Reservations manager"
    },
    {
        "value": 386,
        "NOCCode": "0632",
        "JobTitle": "Seasonal resort manager"
    },
    {
        "value": 387,
        "NOCCode": "0632",
        "JobTitle": "Ski resort manager"
    },
    {
        "value": 388,
        "NOCCode": "0632",
        "JobTitle": "Tourist home operator"
    },
    {
        "value": 389,
        "NOCCode": "0651",
        "JobTitle": "Barber shop manager"
    },
    {
        "value": 390,
        "NOCCode": "0651",
        "JobTitle": "Car wash manager"
    },
    {
        "value": 391,
        "NOCCode": "0651",
        "JobTitle": "Cooking school manager"
    },
    {
        "value": 392,
        "NOCCode": "0651",
        "JobTitle": "Driving school manager"
    },
    {
        "value": 393,
        "NOCCode": "0651",
        "JobTitle": "Dry cleaning service manager"
    },
    {
        "value": 394,
        "NOCCode": "0651",
        "JobTitle": "Hairdressing salon manager"
    },
    {
        "value": 395,
        "NOCCode": "0651",
        "JobTitle": "Pest control service manager"
    },
    {
        "value": 396,
        "NOCCode": "0651",
        "JobTitle": "Residential cleaning service manager"
    },
    {
        "value": 397,
        "NOCCode": "0651",
        "JobTitle": "Septic tank service manager"
    },
    {
        "value": 398,
        "NOCCode": "0651",
        "JobTitle": "Spa manager"
    },
    {
        "value": 399,
        "NOCCode": "0651",
        "JobTitle": "Window washing service manager"
    },
    {
        "value": 400,
        "NOCCode": "0711",
        "JobTitle": "Commercial construction manager"
    },
    {
        "value": 401,
        "NOCCode": "0711",
        "JobTitle": "Construction manager"
    },
    {
        "value": 402,
        "NOCCode": "0711",
        "JobTitle": "Construction project manager"
    },
    {
        "value": 403,
        "NOCCode": "0711",
        "JobTitle": "Construction superintendent"
    },
    {
        "value": 404,
        "NOCCode": "0711",
        "JobTitle": "General contractor"
    },
    {
        "value": 405,
        "NOCCode": "0711",
        "JobTitle": "Housing construction manager"
    },
    {
        "value": 406,
        "NOCCode": "0711",
        "JobTitle": "Industrial construction manager"
    },
    {
        "value": 407,
        "NOCCode": "0711",
        "JobTitle": "Pipeline construction manager"
    },
    {
        "value": 408,
        "NOCCode": "0711",
        "JobTitle": "Residential construction manager"
    },
    {
        "value": 409,
        "NOCCode": "0712",
        "JobTitle": "Home builder"
    },
    {
        "value": 410,
        "NOCCode": "0712",
        "JobTitle": "Home renovation contractor"
    },
    {
        "value": 411,
        "NOCCode": "0712",
        "JobTitle": "Home renovator"
    },
    {
        "value": 412,
        "NOCCode": "0712",
        "JobTitle": "Renovation contractor"
    },
    {
        "value": 413,
        "NOCCode": "0712",
        "JobTitle": "Residential homes contractor"
    },
    {
        "value": 414,
        "NOCCode": "0714",
        "JobTitle": "Airport manager"
    },
    {
        "value": 415,
        "NOCCode": "0714",
        "JobTitle": "Arena manager"
    },
    {
        "value": 416,
        "NOCCode": "0714",
        "JobTitle": "Campus maintenance manager"
    },
    {
        "value": 417,
        "NOCCode": "0714",
        "JobTitle": "Facilities maintenance head"
    },
    {
        "value": 418,
        "NOCCode": "0714",
        "JobTitle": "Facility operations manager"
    },
    {
        "value": 419,
        "NOCCode": "0714",
        "JobTitle": "Harbour master"
    },
    {
        "value": 420,
        "NOCCode": "0714",
        "JobTitle": "Maintenance and service superintendent"
    },
    {
        "value": 421,
        "NOCCode": "0714",
        "JobTitle": "Maintenance manager"
    },
    {
        "value": 422,
        "NOCCode": "0714",
        "JobTitle": "Maintenance support services chief"
    },
    {
        "value": 423,
        "NOCCode": "0714",
        "JobTitle": "Mechanical services superintendent"
    },
    {
        "value": 424,
        "NOCCode": "0714",
        "JobTitle": "Plant maintenance superintendent"
    },
    {
        "value": 425,
        "NOCCode": "0714",
        "JobTitle": "Recreation facility manager"
    },
    {
        "value": 426,
        "NOCCode": "0714",
        "JobTitle": "Shopping centre manager"
    },
    {
        "value": 427,
        "NOCCode": "0714",
        "JobTitle": "Warehouse manager"
    },
    {
        "value": 428,
        "NOCCode": "0731",
        "JobTitle": "Bus company manager"
    },
    {
        "value": 429,
        "NOCCode": "0731",
        "JobTitle": "Distribution manager - logistics"
    },
    {
        "value": 430,
        "NOCCode": "0731",
        "JobTitle": "Flight operations manager"
    },
    {
        "value": 431,
        "NOCCode": "0731",
        "JobTitle": "Freight forwarding manager"
    },
    {
        "value": 432,
        "NOCCode": "0731",
        "JobTitle": "Marine superintendent"
    },
    {
        "value": 433,
        "NOCCode": "0731",
        "JobTitle": "Rail operations superintendent"
    },
    {
        "value": 434,
        "NOCCode": "0731",
        "JobTitle": "Railway freight manager"
    },
    {
        "value": 435,
        "NOCCode": "0731",
        "JobTitle": "Traffic manager - transportation"
    },
    {
        "value": 436,
        "NOCCode": "0731",
        "JobTitle": "Transport department manager"
    },
    {
        "value": 437,
        "NOCCode": "0731",
        "JobTitle": "Transportation manager"
    },
    {
        "value": 438,
        "NOCCode": "0731",
        "JobTitle": "Urban transit system manager"
    },
    {
        "value": 439,
        "NOCCode": "0731",
        "JobTitle": "Cruise shipline Manager"
    },
    {
        "value": 440,
        "NOCCode": "0811",
        "JobTitle": "Director of mining"
    },
    {
        "value": 441,
        "NOCCode": "0811",
        "JobTitle": "Drilling operations manager"
    },
    {
        "value": 442,
        "NOCCode": "0811",
        "JobTitle": "Fishing operations manager"
    },
    {
        "value": 443,
        "NOCCode": "0811",
        "JobTitle": "Forestry operations manager"
    },
    {
        "value": 444,
        "NOCCode": "0811",
        "JobTitle": "Gas field operations manager"
    },
    {
        "value": 445,
        "NOCCode": "0811",
        "JobTitle": "Mine manager"
    },
    {
        "value": 446,
        "NOCCode": "0811",
        "JobTitle": "Oil well servicing manager"
    },
    {
        "value": 447,
        "NOCCode": "0811",
        "JobTitle": "Quarry manager"
    },
    {
        "value": 448,
        "NOCCode": "0811",
        "JobTitle": "Shore captain - fishing"
    },
    {
        "value": 449,
        "NOCCode": "0821",
        "JobTitle": "Apiarist"
    },
    {
        "value": 450,
        "NOCCode": "0821",
        "JobTitle": "Apple grower"
    },
    {
        "value": 451,
        "NOCCode": "0821",
        "JobTitle": "Chicken farmer"
    },
    {
        "value": 452,
        "NOCCode": "0821",
        "JobTitle": "Dairy farmer"
    },
    {
        "value": 453,
        "NOCCode": "0821",
        "JobTitle": "Domestic animal breeder"
    },
    {
        "value": 454,
        "NOCCode": "0821",
        "JobTitle": "Fruit farmer"
    },
    {
        "value": 455,
        "NOCCode": "0821",
        "JobTitle": "Hog breeder"
    },
    {
        "value": 456,
        "NOCCode": "0821",
        "JobTitle": "Horse breeder"
    },
    {
        "value": 457,
        "NOCCode": "0821",
        "JobTitle": "Maple syrup producer"
    },
    {
        "value": 458,
        "NOCCode": "0821",
        "JobTitle": "Market gardener"
    },
    {
        "value": 459,
        "NOCCode": "0821",
        "JobTitle": "Potato farmer"
    },
    {
        "value": 460,
        "NOCCode": "0821",
        "JobTitle": "Rancher"
    },
    {
        "value": 461,
        "NOCCode": "0821",
        "JobTitle": "Seed grower"
    },
    {
        "value": 462,
        "NOCCode": "0821",
        "JobTitle": "Sod farmer"
    },
    {
        "value": 463,
        "NOCCode": "0821",
        "JobTitle": "Vegetable grower"
    },
    {
        "value": 464,
        "NOCCode": "0821",
        "JobTitle": "Vineyard manager"
    },
    {
        "value": 465,
        "NOCCode": "0821",
        "JobTitle": "Viticulturist"
    },
    {
        "value": 466,
        "NOCCode": "0821",
        "JobTitle": "Wheat farmer"
    },
    {
        "value": 467,
        "NOCCode": "0822",
        "JobTitle": "Christmas tree farm operator"
    },
    {
        "value": 468,
        "NOCCode": "0822",
        "JobTitle": "Flower grower"
    },
    {
        "value": 469,
        "NOCCode": "0822",
        "JobTitle": "Greenhouse manager"
    },
    {
        "value": 470,
        "NOCCode": "0822",
        "JobTitle": "Greenhouse operator"
    },
    {
        "value": 471,
        "NOCCode": "0822",
        "JobTitle": "Nursery manager"
    },
    {
        "value": 472,
        "NOCCode": "0822",
        "JobTitle": "Nursery operator"
    },
    {
        "value": 473,
        "NOCCode": "0822",
        "JobTitle": "Plant grower - nursery"
    },
    {
        "value": 474,
        "NOCCode": "0823",
        "JobTitle": "Aquaculture manager"
    },
    {
        "value": 475,
        "NOCCode": "0823",
        "JobTitle": "Aquaculture operator"
    },
    {
        "value": 476,
        "NOCCode": "0823",
        "JobTitle": "Fish farm operator"
    },
    {
        "value": 477,
        "NOCCode": "0823",
        "JobTitle": "Fish farmer"
    },
    {
        "value": 478,
        "NOCCode": "0823",
        "JobTitle": "Fish hatchery manager"
    },
    {
        "value": 479,
        "NOCCode": "0823",
        "JobTitle": "Fish hatchery operator"
    },
    {
        "value": 480,
        "NOCCode": "0823",
        "JobTitle": "Mussel grower"
    },
    {
        "value": 481,
        "NOCCode": "0823",
        "JobTitle": "Oyster grower"
    },
    {
        "value": 482,
        "NOCCode": "0823",
        "JobTitle": "Salmon grower"
    },
    {
        "value": 483,
        "NOCCode": "0823",
        "JobTitle": "Trout farmer"
    },
    {
        "value": 484,
        "NOCCode": "0911",
        "JobTitle": "Automobile production manager"
    },
    {
        "value": 485,
        "NOCCode": "0911",
        "JobTitle": "Clothing factory manager"
    },
    {
        "value": 486,
        "NOCCode": "0911",
        "JobTitle": "Dairy plant manager"
    },
    {
        "value": 487,
        "NOCCode": "0911",
        "JobTitle": "Distillery manager"
    },
    {
        "value": 488,
        "NOCCode": "0911",
        "JobTitle": "Factory superintendent"
    },
    {
        "value": 489,
        "NOCCode": "0911",
        "JobTitle": "Foundry manager"
    },
    {
        "value": 490,
        "NOCCode": "0911",
        "JobTitle": "Manufacturing manager"
    },
    {
        "value": 491,
        "NOCCode": "0911",
        "JobTitle": "Manufacturing operations manager"
    },
    {
        "value": 492,
        "NOCCode": "0911",
        "JobTitle": "Manufacturing plant manager"
    },
    {
        "value": 493,
        "NOCCode": "0911",
        "JobTitle": "Printing plant manager"
    },
    {
        "value": 494,
        "NOCCode": "0911",
        "JobTitle": "Production manager - manufacturing"
    },
    {
        "value": 495,
        "NOCCode": "0911",
        "JobTitle": "Textile mill manager"
    },
    {
        "value": 496,
        "NOCCode": "0911",
        "JobTitle": "Tire plant manager"
    },
    {
        "value": 497,
        "NOCCode": "0911",
        "JobTitle": "Brewmaster"
    },
    {
        "value": 498,
        "NOCCode": "0912",
        "JobTitle": "Director of waste management"
    },
    {
        "value": 499,
        "NOCCode": "0912",
        "JobTitle": "Director of water pollution control"
    },
    {
        "value": 500,
        "NOCCode": "0912",
        "JobTitle": "Distribution systems director - utilities"
    },
    {
        "value": 501,
        "NOCCode": "0912",
        "JobTitle": "Electric generating plant manager"
    },
    {
        "value": 502,
        "NOCCode": "0912",
        "JobTitle": "Electric power plant manager"
    },
    {
        "value": 503,
        "NOCCode": "0912",
        "JobTitle": "Electrical power transmission operations director"
    },
    {
        "value": 504,
        "NOCCode": "0912",
        "JobTitle": "Gas supply operations manager"
    },
    {
        "value": 505,
        "NOCCode": "0912",
        "JobTitle": "Liquid waste facility manager"
    },
    {
        "value": 506,
        "NOCCode": "0912",
        "JobTitle": "Refined petroleum products distribution manager"
    },
    {
        "value": 507,
        "NOCCode": "0912",
        "JobTitle": "Sewage treatment plant manager"
    },
    {
        "value": 508,
        "NOCCode": "0912",
        "JobTitle": "Water filtration plant manager"
    },
    {
        "value": 509,
        "NOCCode": "0912",
        "JobTitle": "Water supply director"
    },
    {
        "value": 510,
        "NOCCode": "1111",
        "JobTitle": "Accountant"
    },
    {
        "value": 511,
        "NOCCode": "1111",
        "JobTitle": "Chief accountant"
    },
    {
        "value": 512,
        "NOCCode": "1111",
        "JobTitle": "Financial auditor"
    },
    {
        "value": 513,
        "NOCCode": "1111",
        "JobTitle": "Income tax expert"
    },
    {
        "value": 514,
        "NOCCode": "1111",
        "JobTitle": "Industrial accountant"
    },
    {
        "value": 515,
        "NOCCode": "1111",
        "JobTitle": "Internal auditor"
    },
    {
        "value": 516,
        "NOCCode": "1112",
        "JobTitle": "Chartered financial analyst"
    },
    {
        "value": 517,
        "NOCCode": "1112",
        "JobTitle": "Financial analyst"
    },
    {
        "value": 518,
        "NOCCode": "1112",
        "JobTitle": "Financial services research associate"
    },
    {
        "value": 519,
        "NOCCode": "1112",
        "JobTitle": "Investment analyst"
    },
    {
        "value": 520,
        "NOCCode": "1112",
        "JobTitle": "Money market analyst"
    },
    {
        "value": 521,
        "NOCCode": "1112",
        "JobTitle": "Portfolio manager"
    },
    {
        "value": 522,
        "NOCCode": "1113",
        "JobTitle": "Bond dealer"
    },
    {
        "value": 523,
        "NOCCode": "1113",
        "JobTitle": "Broker"
    },
    {
        "value": 524,
        "NOCCode": "1113",
        "JobTitle": "Commodity broker"
    },
    {
        "value": 525,
        "NOCCode": "1113",
        "JobTitle": "Discount broker"
    },
    {
        "value": 526,
        "NOCCode": "1113",
        "JobTitle": "Floor trader - commodity exchange"
    },
    {
        "value": 527,
        "NOCCode": "1113",
        "JobTitle": "Foreign exchange trader"
    },
    {
        "value": 528,
        "NOCCode": "1113",
        "JobTitle": "Investment dealer"
    },
    {
        "value": 529,
        "NOCCode": "1113",
        "JobTitle": "Mutual fund broker"
    },
    {
        "value": 530,
        "NOCCode": "1113",
        "JobTitle": "Mutual fund sales representative"
    },
    {
        "value": 531,
        "NOCCode": "1113",
        "JobTitle": "Registered investment representative"
    },
    {
        "value": 532,
        "NOCCode": "1113",
        "JobTitle": "Registered representatives supervisor"
    },
    {
        "value": 533,
        "NOCCode": "1113",
        "JobTitle": "Securities agent"
    },
    {
        "value": 534,
        "NOCCode": "1113",
        "JobTitle": "Securities sales agent"
    },
    {
        "value": 535,
        "NOCCode": "1113",
        "JobTitle": "Securities trader"
    },
    {
        "value": 536,
        "NOCCode": "1113",
        "JobTitle": "Stockbroker"
    },
    {
        "value": 537,
        "NOCCode": "1114",
        "JobTitle": "Account manager"
    },
    {
        "value": 538,
        "NOCCode": "1114",
        "JobTitle": "Credit adjudicator"
    },
    {
        "value": 539,
        "NOCCode": "1114",
        "JobTitle": "Credit unions examiner"
    },
    {
        "value": 540,
        "NOCCode": "1114",
        "JobTitle": "Estate and trust administrator"
    },
    {
        "value": 541,
        "NOCCode": "1114",
        "JobTitle": "Financial institutions inspector"
    },
    {
        "value": 542,
        "NOCCode": "1114",
        "JobTitle": "Financial investigator"
    },
    {
        "value": 543,
        "NOCCode": "1114",
        "JobTitle": "Financial planner"
    },
    {
        "value": 544,
        "NOCCode": "1114",
        "JobTitle": "Financial underwriter"
    },
    {
        "value": 545,
        "NOCCode": "1114",
        "JobTitle": "Mortgage broker"
    },
    {
        "value": 546,
        "NOCCode": "1114",
        "JobTitle": "Trust officer"
    },
    {
        "value": 547,
        "NOCCode": "1121",
        "JobTitle": "Classification officer - human resources"
    },
    {
        "value": 548,
        "NOCCode": "1121",
        "JobTitle": "Classification specialist"
    },
    {
        "value": 549,
        "NOCCode": "1121",
        "JobTitle": "Compensation research analyst"
    },
    {
        "value": 550,
        "NOCCode": "1121",
        "JobTitle": "Conciliator"
    },
    {
        "value": 551,
        "NOCCode": "1121",
        "JobTitle": "Employee relations officer"
    },
    {
        "value": 552,
        "NOCCode": "1121",
        "JobTitle": "Employment equity officer"
    },
    {
        "value": 553,
        "NOCCode": "1121",
        "JobTitle": "Human resources consultant"
    },
    {
        "value": 554,
        "NOCCode": "1121",
        "JobTitle": "Human resources research officer"
    },
    {
        "value": 555,
        "NOCCode": "1121",
        "JobTitle": "Job analyst"
    },
    {
        "value": 556,
        "NOCCode": "1121",
        "JobTitle": "Labour organization business agent"
    },
    {
        "value": 557,
        "NOCCode": "1121",
        "JobTitle": "Labour relations officer"
    },
    {
        "value": 558,
        "NOCCode": "1121",
        "JobTitle": "Mediator"
    },
    {
        "value": 559,
        "NOCCode": "1121",
        "JobTitle": "Union representative"
    },
    {
        "value": 560,
        "NOCCode": "1121",
        "JobTitle": "Wage analyst"
    },
    {
        "value": 561,
        "NOCCode": "1121",
        "JobTitle": "Employment adviser - human resources"
    },
    {
        "value": 562,
        "NOCCode": "1121",
        "JobTitle": "Staff training and development officer"
    },
    {
        "value": 563,
        "NOCCode": "1122",
        "JobTitle": "Business management consultant"
    },
    {
        "value": 564,
        "NOCCode": "1122",
        "JobTitle": "Business methods analyst"
    },
    {
        "value": 565,
        "NOCCode": "1122",
        "JobTitle": "Health information management consultant"
    },
    {
        "value": 566,
        "NOCCode": "1122",
        "JobTitle": "ISO consultant"
    },
    {
        "value": 567,
        "NOCCode": "1122",
        "JobTitle": "Management analyst"
    },
    {
        "value": 568,
        "NOCCode": "1122",
        "JobTitle": "Operations management senior consultant"
    },
    {
        "value": 569,
        "NOCCode": "1122",
        "JobTitle": "Organization and methods researcher"
    },
    {
        "value": 570,
        "NOCCode": "1122",
        "JobTitle": "Organizational analysis consultant"
    },
    {
        "value": 571,
        "NOCCode": "1122",
        "JobTitle": "Organizational analyst"
    },
    {
        "value": 572,
        "NOCCode": "1122",
        "JobTitle": "Records management specialist"
    },
    {
        "value": 573,
        "NOCCode": "1123",
        "JobTitle": "Advertising consultant"
    },
    {
        "value": 574,
        "NOCCode": "1123",
        "JobTitle": "Communication officer (except emergency services)"
    },
    {
        "value": 575,
        "NOCCode": "1123",
        "JobTitle": "Communications specialist"
    },
    {
        "value": 576,
        "NOCCode": "1123",
        "JobTitle": "Event marketing specialist"
    },
    {
        "value": 577,
        "NOCCode": "1123",
        "JobTitle": "Fundraising consultant"
    },
    {
        "value": 578,
        "NOCCode": "1123",
        "JobTitle": "Information officer"
    },
    {
        "value": 579,
        "NOCCode": "1123",
        "JobTitle": "Literary agent"
    },
    {
        "value": 580,
        "NOCCode": "1123",
        "JobTitle": "Media co-ordinator"
    },
    {
        "value": 581,
        "NOCCode": "1123",
        "JobTitle": "Media relations officer"
    },
    {
        "value": 582,
        "NOCCode": "1123",
        "JobTitle": "Museum educator"
    },
    {
        "value": 583,
        "NOCCode": "1123",
        "JobTitle": "Performers agent"
    },
    {
        "value": 584,
        "NOCCode": "1123",
        "JobTitle": "Press secretary"
    },
    {
        "value": 585,
        "NOCCode": "1123",
        "JobTitle": "Public affairs officer"
    },
    {
        "value": 586,
        "NOCCode": "1123",
        "JobTitle": "Public relations consultant"
    },
    {
        "value": 587,
        "NOCCode": "1123",
        "JobTitle": "Public relations officer"
    },
    {
        "value": 588,
        "NOCCode": "1123",
        "JobTitle": "Publicist"
    },
    {
        "value": 589,
        "NOCCode": "1123",
        "JobTitle": "Publicity agent"
    },
    {
        "value": 590,
        "NOCCode": "1123",
        "JobTitle": "Fundraiser"
    },
    {
        "value": 591,
        "NOCCode": "1123",
        "JobTitle": "Information consultant"
    },
    {
        "value": 592,
        "NOCCode": "1123",
        "JobTitle": "Information service co-ordinator"
    },
    {
        "value": 593,
        "NOCCode": "1211",
        "JobTitle": "Clerical supervisor"
    },
    {
        "value": 594,
        "NOCCode": "1211",
        "JobTitle": "Data entry supervisor"
    },
    {
        "value": 595,
        "NOCCode": "1211",
        "JobTitle": "Filing clerks supervisor"
    },
    {
        "value": 596,
        "NOCCode": "1211",
        "JobTitle": "Hospital admitting clerks supervisor"
    },
    {
        "value": 597,
        "NOCCode": "1211",
        "JobTitle": "Personnel clerks supervisor"
    },
    {
        "value": 598,
        "NOCCode": "1211",
        "JobTitle": "Records office supervisor"
    },
    {
        "value": 599,
        "NOCCode": "1211",
        "JobTitle": "Registry supervisor"
    },
    {
        "value": 600,
        "NOCCode": "1211",
        "JobTitle": "Switchboard operators supervisor"
    },
    {
        "value": 601,
        "NOCCode": "1211",
        "JobTitle": "Operations supervisor"
    },
    {
        "value": 602,
        "NOCCode": "1212",
        "JobTitle": "Accounts payable supervisor"
    },
    {
        "value": 603,
        "NOCCode": "1212",
        "JobTitle": "Accounts receivable supervisor"
    },
    {
        "value": 604,
        "NOCCode": "1212",
        "JobTitle": "Bank clerks supervisor"
    },
    {
        "value": 605,
        "NOCCode": "1212",
        "JobTitle": "Billing supervisor"
    },
    {
        "value": 606,
        "NOCCode": "1212",
        "JobTitle": "Bookkeeping supervisor"
    },
    {
        "value": 607,
        "NOCCode": "1212",
        "JobTitle": "Claims adjusters supervisor"
    },
    {
        "value": 608,
        "NOCCode": "1212",
        "JobTitle": "Collection supervisor"
    },
    {
        "value": 609,
        "NOCCode": "1212",
        "JobTitle": "Credit supervisor"
    },
    {
        "value": 610,
        "NOCCode": "1212",
        "JobTitle": "Payroll supervisor"
    },
    {
        "value": 611,
        "NOCCode": "1212",
        "JobTitle": "Supervisor of accounting"
    },
    {
        "value": 612,
        "NOCCode": "1213",
        "JobTitle": "Advertising clerks supervisor"
    },
    {
        "value": 613,
        "NOCCode": "1213",
        "JobTitle": "Correspondence clerks supervisor"
    },
    {
        "value": 614,
        "NOCCode": "1213",
        "JobTitle": "Library clerks supervisor"
    },
    {
        "value": 615,
        "NOCCode": "1213",
        "JobTitle": "Publication clerks supervisor"
    },
    {
        "value": 616,
        "NOCCode": "1213",
        "JobTitle": "Statistical clerks supervisor"
    },
    {
        "value": 617,
        "NOCCode": "1213",
        "JobTitle": "Survey interviewers supervisor"
    },
    {
        "value": 618,
        "NOCCode": "1214",
        "JobTitle": "Courier service supervisor"
    },
    {
        "value": 619,
        "NOCCode": "1214",
        "JobTitle": "Letter carriers supervisor"
    },
    {
        "value": 620,
        "NOCCode": "1214",
        "JobTitle": "Mail and postal clerks supervisor"
    },
    {
        "value": 621,
        "NOCCode": "1214",
        "JobTitle": "Mail room supervisor"
    },
    {
        "value": 622,
        "NOCCode": "1214",
        "JobTitle": "Messenger service supervisor"
    },
    {
        "value": 623,
        "NOCCode": "1214",
        "JobTitle": "Postal station supervisor"
    },
    {
        "value": 624,
        "NOCCode": "1214",
        "JobTitle": "Postmaster/mistress"
    },
    {
        "value": 625,
        "NOCCode": "1215",
        "JobTitle": "Dispatch logistician"
    },
    {
        "value": 626,
        "NOCCode": "1215",
        "JobTitle": "Flight crew scheduling supervisor"
    },
    {
        "value": 627,
        "NOCCode": "1215",
        "JobTitle": "Freight forwarding logistician"
    },
    {
        "value": 628,
        "NOCCode": "1215",
        "JobTitle": "Head dispatcher"
    },
    {
        "value": 629,
        "NOCCode": "1215",
        "JobTitle": "Head shipper"
    },
    {
        "value": 630,
        "NOCCode": "1215",
        "JobTitle": "Inventory control supervisor"
    },
    {
        "value": 631,
        "NOCCode": "1215",
        "JobTitle": "Logistics supervisor - transportation"
    },
    {
        "value": 632,
        "NOCCode": "1215",
        "JobTitle": "Parts service supervisor"
    },
    {
        "value": 633,
        "NOCCode": "1215",
        "JobTitle": "Production clerks supervisor"
    },
    {
        "value": 634,
        "NOCCode": "1215",
        "JobTitle": "Ramp services supervisor - airport"
    },
    {
        "value": 635,
        "NOCCode": "1215",
        "JobTitle": "Receiving supervisor"
    },
    {
        "value": 636,
        "NOCCode": "1215",
        "JobTitle": "Supply control co-ordinator"
    },
    {
        "value": 637,
        "NOCCode": "1215",
        "JobTitle": "Guide-operator"
    },
    {
        "value": 638,
        "NOCCode": "1221",
        "JobTitle": "Access to information and privacy officer"
    },
    {
        "value": 639,
        "NOCCode": "1221",
        "JobTitle": "Administrative officer"
    },
    {
        "value": 640,
        "NOCCode": "1221",
        "JobTitle": "Administrative services co-ordinator"
    },
    {
        "value": 641,
        "NOCCode": "1221",
        "JobTitle": "Forms management officer"
    },
    {
        "value": 642,
        "NOCCode": "1221",
        "JobTitle": "Office administrator"
    },
    {
        "value": 643,
        "NOCCode": "1221",
        "JobTitle": "Office manager"
    },
    {
        "value": 644,
        "NOCCode": "1221",
        "JobTitle": "Office services co-ordinator"
    },
    {
        "value": 645,
        "NOCCode": "1221",
        "JobTitle": "Planning officer"
    },
    {
        "value": 646,
        "NOCCode": "1221",
        "JobTitle": "Records analyst - access to information"
    },
    {
        "value": 647,
        "NOCCode": "1221",
        "JobTitle": "Surplus assets officer"
    },
    {
        "value": 648,
        "NOCCode": "1221",
        "JobTitle": "University admissions officer"
    },
    {
        "value": 649,
        "NOCCode": "1221",
        "JobTitle": "Communications service co-ordinator"
    },
    {
        "value": 650,
        "NOCCode": "1221",
        "JobTitle": "Exam invigilator - post-secondary institution"
    },
    {
        "value": 651,
        "NOCCode": "1221",
        "JobTitle": "Exam supervisor - post-secondary institution"
    },
    {
        "value": 652,
        "NOCCode": "1221",
        "JobTitle": "Implementation officer"
    },
    {
        "value": 653,
        "NOCCode": "1221",
        "JobTitle": "Liaison officer"
    },
    {
        "value": 654,
        "NOCCode": "1221",
        "JobTitle": "Public trustee"
    },
    {
        "value": 655,
        "NOCCode": "1221",
        "JobTitle": "Regional services chief"
    },
    {
        "value": 656,
        "NOCCode": "1221",
        "JobTitle": "Relocation commissioner"
    },
    {
        "value": 657,
        "NOCCode": "1222",
        "JobTitle": "Committee clerk"
    },
    {
        "value": 658,
        "NOCCode": "1222",
        "JobTitle": "Corporate secretary"
    },
    {
        "value": 659,
        "NOCCode": "1222",
        "JobTitle": "Executive assistant"
    },
    {
        "value": 660,
        "NOCCode": "1222",
        "JobTitle": "Legislative assistan"
    },
    {
        "value": 661,
        "NOCCode": "1222",
        "JobTitle": "Special assistant"
    },
    {
        "value": 662,
        "NOCCode": "1223",
        "JobTitle": "Human resources officer"
    },
    {
        "value": 663,
        "NOCCode": "1223",
        "JobTitle": "Personnel officer"
    },
    {
        "value": 664,
        "NOCCode": "1223",
        "JobTitle": "Recruitment specialist"
    },
    {
        "value": 665,
        "NOCCode": "1223",
        "JobTitle": "Staffing analyst"
    },
    {
        "value": 666,
        "NOCCode": "1223",
        "JobTitle": "Staffing co-ordinator"
    },
    {
        "value": 667,
        "NOCCode": "1223",
        "JobTitle": "Employment Supervisor"
    },
    {
        "value": 668,
        "NOCCode": "1224",
        "JobTitle": "Accommodation officer"
    },
    {
        "value": 669,
        "NOCCode": "1224",
        "JobTitle": "Apartment rental agent"
    },
    {
        "value": 670,
        "NOCCode": "1224",
        "JobTitle": "Housing project manager"
    },
    {
        "value": 671,
        "NOCCode": "1224",
        "JobTitle": "Property administrator"
    },
    {
        "value": 672,
        "NOCCode": "1224",
        "JobTitle": "Property leasing co-ordinator"
    },
    {
        "value": 673,
        "NOCCode": "1224",
        "JobTitle": "Property rentals manager"
    },
    {
        "value": 674,
        "NOCCode": "1224",
        "JobTitle": "Landlord/landlady"
    },
    {
        "value": 675,
        "NOCCode": "1225",
        "JobTitle": "Contract management officer"
    },
    {
        "value": 676,
        "NOCCode": "1225",
        "JobTitle": "Contracts officer"
    },
    {
        "value": 677,
        "NOCCode": "1225",
        "JobTitle": "Energy asset surface land analyst"
    },
    {
        "value": 678,
        "NOCCode": "1225",
        "JobTitle": "Furniture and furnishings purchaser"
    },
    {
        "value": 679,
        "NOCCode": "1225",
        "JobTitle": "Government supply officer"
    },
    {
        "value": 680,
        "NOCCode": "1225",
        "JobTitle": "Material management officer"
    },
    {
        "value": 681,
        "NOCCode": "1225",
        "JobTitle": "Procurement officer"
    },
    {
        "value": 682,
        "NOCCode": "1225",
        "JobTitle": "Purchasing agent"
    },
    {
        "value": 683,
        "NOCCode": "1226",
        "JobTitle": "Conference and meeting planner"
    },
    {
        "value": 684,
        "NOCCode": "1226",
        "JobTitle": "Conference planner"
    },
    {
        "value": 685,
        "NOCCode": "1226",
        "JobTitle": "Conference services officer"
    },
    {
        "value": 686,
        "NOCCode": "1226",
        "JobTitle": "Convention co-ordinator"
    },
    {
        "value": 687,
        "NOCCode": "1226",
        "JobTitle": "Event planner"
    },
    {
        "value": 688,
        "NOCCode": "1226",
        "JobTitle": "Festival organizer"
    },
    {
        "value": 689,
        "NOCCode": "1226",
        "JobTitle": "Meeting planner"
    },
    {
        "value": 690,
        "NOCCode": "1226",
        "JobTitle": "Special events organizer"
    },
    {
        "value": 691,
        "NOCCode": "1226",
        "JobTitle": "Trade show planner"
    },
    {
        "value": 692,
        "NOCCode": "1227",
        "JobTitle": "Commissioner of affidavits"
    },
    {
        "value": 693,
        "NOCCode": "1227",
        "JobTitle": "Commissioner of marriages"
    },
    {
        "value": 694,
        "NOCCode": "1227",
        "JobTitle": "Co-ordinator of court services"
    },
    {
        "value": 695,
        "NOCCode": "1227",
        "JobTitle": "Court administrator"
    },
    {
        "value": 696,
        "NOCCode": "1227",
        "JobTitle": "Court clerk supervisor"
    },
    {
        "value": 697,
        "NOCCode": "1227",
        "JobTitle": "Court officer"
    },
    {
        "value": 698,
        "NOCCode": "1227",
        "JobTitle": "Court registrar"
    },
    {
        "value": 699,
        "NOCCode": "1227",
        "JobTitle": "Court services manager"
    },
    {
        "value": 700,
        "NOCCode": "1227",
        "JobTitle": "Deputy registrar - courts"
    },
    {
        "value": 701,
        "NOCCode": "1227",
        "JobTitle": "Judicial administrator"
    },
    {
        "value": 702,
        "NOCCode": "1227",
        "JobTitle": "Judicial court administrator"
    },
    {
        "value": 703,
        "NOCCode": "1227",
        "JobTitle": "Judicial officer"
    },
    {
        "value": 704,
        "NOCCode": "1227",
        "JobTitle": "Justice of the peace"
    },
    {
        "value": 705,
        "NOCCode": "1227",
        "JobTitle": "Registrar of bankruptcy"
    },
    {
        "value": 706,
        "NOCCode": "1227",
        "JobTitle": "Supreme Court registrar"
    },
    {
        "value": 707,
        "NOCCode": "1227",
        "JobTitle": "Trial co-ordinator - courts"
    },
    {
        "value": 708,
        "NOCCode": "1227",
        "JobTitle": "Prothonotary"
    },
    {
        "value": 709,
        "NOCCode": "1228",
        "JobTitle": "Border services officer (BSO)"
    },
    {
        "value": 710,
        "NOCCode": "1228",
        "JobTitle": "Customs inspector"
    },
    {
        "value": 711,
        "NOCCode": "1228",
        "JobTitle": "Customs officer"
    },
    {
        "value": 712,
        "NOCCode": "1228",
        "JobTitle": "Employment insurance agent"
    },
    {
        "value": 713,
        "NOCCode": "1228",
        "JobTitle": "Employment insurance benefits control officer"
    },
    {
        "value": 714,
        "NOCCode": "1228",
        "JobTitle": "Immigration agent - government services"
    },
    {
        "value": 715,
        "NOCCode": "1228",
        "JobTitle": "Immigration examining officer"
    },
    {
        "value": 716,
        "NOCCode": "1228",
        "JobTitle": "Revenue officer"
    },
    {
        "value": 717,
        "NOCCode": "1228",
        "JobTitle": "Tax collection officer"
    },
    {
        "value": 718,
        "NOCCode": "1228",
        "JobTitle": "Tax enforcement officer"
    },
    {
        "value": 719,
        "NOCCode": "1228",
        "JobTitle": "Appeal Officer"
    },
    {
        "value": 720,
        "NOCCode": "1241",
        "JobTitle": "Administrative assistant"
    },
    {
        "value": 721,
        "NOCCode": "1241",
        "JobTitle": "Executive secretary (except legal and medical)"
    },
    {
        "value": 722,
        "NOCCode": "1241",
        "JobTitle": "Office administrative assistant"
    },
    {
        "value": 723,
        "NOCCode": "1241",
        "JobTitle": "Private secretary"
    },
    {
        "value": 724,
        "NOCCode": "1241",
        "JobTitle": "Secretary (except legal and medical)"
    },
    {
        "value": 725,
        "NOCCode": "1241",
        "JobTitle": "Technical secretary"
    },
    {
        "value": 726,
        "NOCCode": "1241",
        "JobTitle": "Secretary-clerk"
    },
    {
        "value": 727,
        "NOCCode": "1242",
        "JobTitle": "Administrative assistant - legal"
    },
    {
        "value": 728,
        "NOCCode": "1242",
        "JobTitle": "Corporate law legal assistant"
    },
    {
        "value": 729,
        "NOCCode": "1242",
        "JobTitle": "Legal assistant"
    },
    {
        "value": 730,
        "NOCCode": "1242",
        "JobTitle": "Legal assistant - criminal law"
    },
    {
        "value": 731,
        "NOCCode": "1242",
        "JobTitle": "Legal secretary"
    },
    {
        "value": 732,
        "NOCCode": "1242",
        "JobTitle": "Litigation legal assistant"
    },
    {
        "value": 733,
        "NOCCode": "1242",
        "JobTitle": "Litigation secretary"
    },
    {
        "value": 734,
        "NOCCode": "1242",
        "JobTitle": "Real estate secretary"
    },
    {
        "value": 735,
        "NOCCode": "1243",
        "JobTitle": "Administrative assistant - medical"
    },
    {
        "value": 736,
        "NOCCode": "1243",
        "JobTitle": "Medical secretary"
    },
    {
        "value": 737,
        "NOCCode": "1243",
        "JobTitle": "Ward secretary"
    },
    {
        "value": 738,
        "NOCCode": "1251",
        "JobTitle": "Closed captioner"
    },
    {
        "value": 739,
        "NOCCode": "1251",
        "JobTitle": "Court reporter"
    },
    {
        "value": 740,
        "NOCCode": "1251",
        "JobTitle": "Hansard reporter"
    },
    {
        "value": 741,
        "NOCCode": "1251",
        "JobTitle": "Medical transcriptionist"
    },
    {
        "value": 742,
        "NOCCode": "1251",
        "JobTitle": "Transcriptionist"
    },
    {
        "value": 743,
        "NOCCode": "1252",
        "JobTitle": "Health information management supervisor"
    },
    {
        "value": 744,
        "NOCCode": "1252",
        "JobTitle": "Health information management technician"
    },
    {
        "value": 745,
        "NOCCode": "1252",
        "JobTitle": "Health records technician"
    },
    {
        "value": 746,
        "NOCCode": "1252",
        "JobTitle": "Medical records unit supervisor"
    },
    {
        "value": 747,
        "NOCCode": "1253",
        "JobTitle": "Information management technician"
    },
    {
        "value": 748,
        "NOCCode": "1253",
        "JobTitle": "Microfilm records searcher"
    },
    {
        "value": 749,
        "NOCCode": "1253",
        "JobTitle": "Records classifier"
    },
    {
        "value": 750,
        "NOCCode": "1253",
        "JobTitle": "Records digitizing technician"
    },
    {
        "value": 751,
        "NOCCode": "1253",
        "JobTitle": "Records technician"
    },
    {
        "value": 752,
        "NOCCode": "1254",
        "JobTitle": "Research assistant - generalist (except university)"
    },
    {
        "value": 753,
        "NOCCode": "1254",
        "JobTitle": "Research support officer"
    },
    {
        "value": 754,
        "NOCCode": "1254",
        "JobTitle": "Social research assistant (except university)"
    },
    {
        "value": 755,
        "NOCCode": "1254",
        "JobTitle": "Statistical officer"
    },
    {
        "value": 756,
        "NOCCode": "1254",
        "JobTitle": "Technical research assistant (except post-secondary)"
    },
    {
        "value": 757,
        "NOCCode": "1311",
        "JobTitle": "Accounting bookkeeper"
    },
    {
        "value": 758,
        "NOCCode": "1311",
        "JobTitle": "Accounting technician"
    },
    {
        "value": 759,
        "NOCCode": "1311",
        "JobTitle": "Bookkeeper"
    },
    {
        "value": 760,
        "NOCCode": "1311",
        "JobTitle": "Finance technician"
    },
    {
        "value": 761,
        "NOCCode": "1312",
        "JobTitle": "Adjuster"
    },
    {
        "value": 762,
        "NOCCode": "1312",
        "JobTitle": "Claims examiner"
    },
    {
        "value": 763,
        "NOCCode": "1312",
        "JobTitle": "Claims representative"
    },
    {
        "value": 764,
        "NOCCode": "1312",
        "JobTitle": "Insurance adjuster"
    },
    {
        "value": 765,
        "NOCCode": "1312",
        "JobTitle": "Production examiner"
    },
    {
        "value": 766,
        "NOCCode": "1313",
        "JobTitle": "Group underwriter"
    },
    {
        "value": 767,
        "NOCCode": "1313",
        "JobTitle": "Insurance underwriter"
    },
    {
        "value": 768,
        "NOCCode": "1313",
        "JobTitle": "Liability underwriter"
    },
    {
        "value": 769,
        "NOCCode": "1313",
        "JobTitle": "Property underwriter"
    },
    {
        "value": 770,
        "NOCCode": "1313",
        "JobTitle": "Casualty insurance products analyst-designer"
    },
    {
        "value": 771,
        "NOCCode": "1313",
        "JobTitle": "Insurance rating analyst"
    },
    {
        "value": 772,
        "NOCCode": "1314",
        "JobTitle": "Accredited appraiser"
    },
    {
        "value": 773,
        "NOCCode": "1314",
        "JobTitle": "Business valuator"
    },
    {
        "value": 774,
        "NOCCode": "1314",
        "JobTitle": "Chartered business valuator (CBV)"
    },
    {
        "value": 775,
        "NOCCode": "1314",
        "JobTitle": "Property assessor"
    },
    {
        "value": 776,
        "NOCCode": "1314",
        "JobTitle": "Property valuator"
    },
    {
        "value": 777,
        "NOCCode": "1314",
        "JobTitle": "Real estate appraiser"
    },
    {
        "value": 778,
        "NOCCode": "1314",
        "JobTitle": "Real Estate Analyst"
    },
    {
        "value": 779,
        "NOCCode": "1315",
        "JobTitle": "Cargo broker"
    },
    {
        "value": 780,
        "NOCCode": "1315",
        "JobTitle": "Chartered shipbroker"
    },
    {
        "value": 781,
        "NOCCode": "1315",
        "JobTitle": "Customs broker"
    },
    {
        "value": 782,
        "NOCCode": "1315",
        "JobTitle": "Gas broker"
    },
    {
        "value": 783,
        "NOCCode": "1315",
        "JobTitle": "Licensed customs broker"
    },
    {
        "value": 784,
        "NOCCode": "1315",
        "JobTitle": "Shipbroker"
    },
    {
        "value": 785,
        "NOCCode": "1315",
        "JobTitle": "ship line agent"
    },
    {
        "value": 786,
        "NOCCode": "1411",
        "JobTitle": "Administrative clerk"
    },
    {
        "value": 787,
        "NOCCode": "1411",
        "JobTitle": "Aircraft records clerk"
    },
    {
        "value": 788,
        "NOCCode": "1411",
        "JobTitle": "File and classification clerk"
    },
    {
        "value": 789,
        "NOCCode": "1411",
        "JobTitle": "Filing clerk"
    },
    {
        "value": 790,
        "NOCCode": "1411",
        "JobTitle": "General office worker"
    },
    {
        "value": 791,
        "NOCCode": "1411",
        "JobTitle": "Medical records clerk"
    },
    {
        "value": 792,
        "NOCCode": "1411",
        "JobTitle": "Office assistant"
    },
    {
        "value": 793,
        "NOCCode": "1411",
        "JobTitle": "Office clerk"
    },
    {
        "value": 794,
        "NOCCode": "1411",
        "JobTitle": "Records filing-system clerk"
    },
    {
        "value": 795,
        "NOCCode": "1411",
        "JobTitle": "Technical records clerk"
    },
    {
        "value": 796,
        "NOCCode": "1411",
        "JobTitle": "Ward clerk - hospital"
    },
    {
        "value": 797,
        "NOCCode": "1411",
        "JobTitle": "Warranty clerk"
    },
    {
        "value": 798,
        "NOCCode": "1414",
        "JobTitle": "Answering service operator"
    },
    {
        "value": 799,
        "NOCCode": "1414",
        "JobTitle": "Appointment clerk"
    },
    {
        "value": 800,
        "NOCCode": "1414",
        "JobTitle": "Business receptionist"
    },
    {
        "value": 801,
        "NOCCode": "1414",
        "JobTitle": "Dental receptionist"
    },
    {
        "value": 802,
        "NOCCode": "1414",
        "JobTitle": "Hospital admitting clerk"
    },
    {
        "value": 803,
        "NOCCode": "1414",
        "JobTitle": "Medical receptionist"
    },
    {
        "value": 804,
        "NOCCode": "1414",
        "JobTitle": "Office reception clerk"
    },
    {
        "value": 805,
        "NOCCode": "1414",
        "JobTitle": "Receptionist"
    },
    {
        "value": 806,
        "NOCCode": "1414",
        "JobTitle": "Switchboard operator"
    },
    {
        "value": 807,
        "NOCCode": "1414",
        "JobTitle": "Telephone operator"
    },
    {
        "value": 808,
        "NOCCode": "1414",
        "JobTitle": "Telephone service assistant"
    },
    {
        "value": 809,
        "NOCCode": "1414",
        "JobTitle": "Telereceptionist"
    },
    {
        "value": 810,
        "NOCCode": "1414",
        "JobTitle": "Toll operator"
    },
    {
        "value": 811,
        "NOCCode": "1414",
        "JobTitle": "Mail and message distribution clerk"
    },
    {
        "value": 812,
        "NOCCode": "1414",
        "JobTitle": "Medical office assistant"
    },
    {
        "value": 813,
        "NOCCode": "1415",
        "JobTitle": "Classification clerk - human resources"
    },
    {
        "value": 814,
        "NOCCode": "1415",
        "JobTitle": "Employment clerk"
    },
    {
        "value": 815,
        "NOCCode": "1415",
        "JobTitle": "Human resources assistant"
    },
    {
        "value": 816,
        "NOCCode": "1415",
        "JobTitle": "Human resources clerk"
    },
    {
        "value": 817,
        "NOCCode": "1415",
        "JobTitle": "Labour relations clerk"
    },
    {
        "value": 818,
        "NOCCode": "1415",
        "JobTitle": "Personnel services clerk"
    },
    {
        "value": 819,
        "NOCCode": "1415",
        "JobTitle": "Staffing clerk"
    },
    {
        "value": 820,
        "NOCCode": "1415",
        "JobTitle": "Training clerk"
    },
    {
        "value": 821,
        "NOCCode": "1416",
        "JobTitle": "Court clerk"
    },
    {
        "value": 822,
        "NOCCode": "1416",
        "JobTitle": "Court clerk-crier"
    },
    {
        "value": 823,
        "NOCCode": "1416",
        "JobTitle": "Criminal court clerk"
    },
    {
        "value": 824,
        "NOCCode": "1416",
        "JobTitle": "Judicial clerk"
    },
    {
        "value": 825,
        "NOCCode": "1416",
        "JobTitle": "Provincial court clerk"
    },
    {
        "value": 826,
        "NOCCode": "1422",
        "JobTitle": "Data control clerk"
    },
    {
        "value": 827,
        "NOCCode": "1422",
        "JobTitle": "Data entry operator"
    },
    {
        "value": 828,
        "NOCCode": "1422",
        "JobTitle": "Data input clerk"
    },
    {
        "value": 829,
        "NOCCode": "1422",
        "JobTitle": "Data processor"
    },
    {
        "value": 830,
        "NOCCode": "1422",
        "JobTitle": "Payment entry clerk"
    },
    {
        "value": 831,
        "NOCCode": "1423",
        "JobTitle": "Braille keyboard operator"
    },
    {
        "value": 832,
        "NOCCode": "1423",
        "JobTitle": "Compositor - typesetting"
    },
    {
        "value": 833,
        "NOCCode": "1423",
        "JobTitle": "Computer typesetter"
    },
    {
        "value": 834,
        "NOCCode": "1423",
        "JobTitle": "Copy marker - typesetting"
    },
    {
        "value": 835,
        "NOCCode": "1423",
        "JobTitle": "Desktop compositor"
    },
    {
        "value": 836,
        "NOCCode": "1423",
        "JobTitle": "Desktop publishing (DTP) operator"
    },
    {
        "value": 837,
        "NOCCode": "1423",
        "JobTitle": "Input operator - typesetting"
    },
    {
        "value": 838,
        "NOCCode": "1423",
        "JobTitle": "Markup person"
    },
    {
        "value": 839,
        "NOCCode": "1423",
        "JobTitle": "Output operator - typesetting"
    },
    {
        "value": 840,
        "NOCCode": "1423",
        "JobTitle": "Photocomposition typesetter"
    },
    {
        "value": 841,
        "NOCCode": "1423",
        "JobTitle": "Photocompositor"
    },
    {
        "value": 842,
        "NOCCode": "1423",
        "JobTitle": "Phototypesetter operator"
    },
    {
        "value": 843,
        "NOCCode": "1423",
        "JobTitle": "Typographer"
    },
    {
        "value": 844,
        "NOCCode": "1423",
        "JobTitle": "Terminal operator"
    },
    {
        "value": 845,
        "NOCCode": "1431",
        "JobTitle": "Accounting clerk"
    },
    {
        "value": 846,
        "NOCCode": "1431",
        "JobTitle": "Accounts payable clerk"
    },
    {
        "value": 847,
        "NOCCode": "1431",
        "JobTitle": "Accounts receivable clerk"
    },
    {
        "value": 848,
        "NOCCode": "1431",
        "JobTitle": "Audit clerk"
    },
    {
        "value": 849,
        "NOCCode": "1431",
        "JobTitle": "Billing clerk"
    },
    {
        "value": 850,
        "NOCCode": "1431",
        "JobTitle": "Budget clerk"
    },
    {
        "value": 851,
        "NOCCode": "1431",
        "JobTitle": "Costing clerk"
    },
    {
        "value": 852,
        "NOCCode": "1431",
        "JobTitle": "Deposit clerk"
    },
    {
        "value": 853,
        "NOCCode": "1431",
        "JobTitle": "Finance clerk"
    },
    {
        "value": 854,
        "NOCCode": "1431",
        "JobTitle": "Freight-rate clerk"
    },
    {
        "value": 855,
        "NOCCode": "1431",
        "JobTitle": "Income tax return preparer"
    },
    {
        "value": 856,
        "NOCCode": "1431",
        "JobTitle": "Invoice clerk"
    },
    {
        "value": 857,
        "NOCCode": "1431",
        "JobTitle": "Ledger clerk"
    },
    {
        "value": 858,
        "NOCCode": "1431",
        "JobTitle": "Tax clerk"
    },
    {
        "value": 859,
        "NOCCode": "1431",
        "JobTitle": "Wharf clerk"
    },
    {
        "value": 860,
        "NOCCode": "1432",
        "JobTitle": "Benefits officer - payroll administration"
    },
    {
        "value": 861,
        "NOCCode": "1432",
        "JobTitle": "Pay advisor"
    },
    {
        "value": 862,
        "NOCCode": "1432",
        "JobTitle": "Pay and benefits administrator"
    },
    {
        "value": 863,
        "NOCCode": "1432",
        "JobTitle": "Pay and benefits clerk"
    },
    {
        "value": 864,
        "NOCCode": "1432",
        "JobTitle": "Pay clerk"
    },
    {
        "value": 865,
        "NOCCode": "1432",
        "JobTitle": "Payroll clerk"
    },
    {
        "value": 866,
        "NOCCode": "1432",
        "JobTitle": "Payroll officer"
    },
    {
        "value": 867,
        "NOCCode": "1432",
        "JobTitle": "Salary administration officer"
    },
    {
        "value": 868,
        "NOCCode": "1434",
        "JobTitle": "Actuarial clerk"
    },
    {
        "value": 869,
        "NOCCode": "1434",
        "JobTitle": "Bank clerk"
    },
    {
        "value": 870,
        "NOCCode": "1434",
        "JobTitle": "Credit clerk"
    },
    {
        "value": 871,
        "NOCCode": "1434",
        "JobTitle": "Dental claims clerk"
    },
    {
        "value": 872,
        "NOCCode": "1434",
        "JobTitle": "Dividend calculation clerk"
    },
    {
        "value": 873,
        "NOCCode": "1434",
        "JobTitle": "Insurance clerk - financial sector"
    },
    {
        "value": 874,
        "NOCCode": "1434",
        "JobTitle": "Insurance rater"
    },
    {
        "value": 875,
        "NOCCode": "1434",
        "JobTitle": "Ledger control clerk"
    },
    {
        "value": 876,
        "NOCCode": "1434",
        "JobTitle": "Loan clerk - financial sector"
    },
    {
        "value": 877,
        "NOCCode": "1434",
        "JobTitle": "Mortgage clerk"
    },
    {
        "value": 878,
        "NOCCode": "1434",
        "JobTitle": "Premium rater - insurance"
    },
    {
        "value": 879,
        "NOCCode": "1434",
        "JobTitle": "Real estate clerk"
    },
    {
        "value": 880,
        "NOCCode": "1434",
        "JobTitle": "Securities clerk - financial sector"
    },
    {
        "value": 881,
        "NOCCode": "1435",
        "JobTitle": "Bill collector"
    },
    {
        "value": 882,
        "NOCCode": "1435",
        "JobTitle": "Collection clerk"
    },
    {
        "value": 883,
        "NOCCode": "1435",
        "JobTitle": "Collection officer (except taxation)"
    },
    {
        "value": 884,
        "NOCCode": "1435",
        "JobTitle": "Collections investigation officer"
    },
    {
        "value": 885,
        "NOCCode": "1435",
        "JobTitle": "Collector"
    },
    {
        "value": 886,
        "NOCCode": "1435",
        "JobTitle": "Credit and collection clerk"
    },
    {
        "value": 887,
        "NOCCode": "1435",
        "JobTitle": "Locator - collection"
    },
    {
        "value": 888,
        "NOCCode": "1435",
        "JobTitle": "Skip tracer - collection"
    },
    {
        "value": 889,
        "NOCCode": "1451",
        "JobTitle": "Circulation clerk - library"
    },
    {
        "value": 890,
        "NOCCode": "1451",
        "JobTitle": "Interlibrary loan clerk"
    },
    {
        "value": 891,
        "NOCCode": "1451",
        "JobTitle": "Library assistant"
    },
    {
        "value": 892,
        "NOCCode": "1451",
        "JobTitle": "Library page"
    },
    {
        "value": 893,
        "NOCCode": "1451",
        "JobTitle": "Periodicals clerk"
    },
    {
        "value": 894,
        "NOCCode": "1451",
        "JobTitle": "Reference clerk"
    },
    {
        "value": 895,
        "NOCCode": "1451",
        "JobTitle": "Shelving clerk - library"
    },
    {
        "value": 896,
        "NOCCode": "1452",
        "JobTitle": "Advertising clerk"
    },
    {
        "value": 897,
        "NOCCode": "1452",
        "JobTitle": "Application clerk"
    },
    {
        "value": 898,
        "NOCCode": "1452",
        "JobTitle": "Authorization clerk"
    },
    {
        "value": 899,
        "NOCCode": "1452",
        "JobTitle": "By-law clerk"
    },
    {
        "value": 900,
        "NOCCode": "1452",
        "JobTitle": "Classified advertising clerk"
    },
    {
        "value": 901,
        "NOCCode": "1452",
        "JobTitle": "Contract clerk"
    },
    {
        "value": 902,
        "NOCCode": "1452",
        "JobTitle": "Correspondence clerk"
    },
    {
        "value": 903,
        "NOCCode": "1452",
        "JobTitle": "Customs clerk"
    },
    {
        "value": 904,
        "NOCCode": "1452",
        "JobTitle": "Directory compiler"
    },
    {
        "value": 905,
        "NOCCode": "1452",
        "JobTitle": "Documentation clerk"
    },
    {
        "value": 906,
        "NOCCode": "1452",
        "JobTitle": "Editorial assistant"
    },
    {
        "value": 907,
        "NOCCode": "1452",
        "JobTitle": "Licence clerk"
    },
    {
        "value": 908,
        "NOCCode": "1452",
        "JobTitle": "Passport clerk"
    },
    {
        "value": 909,
        "NOCCode": "1452",
        "JobTitle": "Proofreader"
    },
    {
        "value": 910,
        "NOCCode": "1452",
        "JobTitle": "Publication clerk"
    },
    {
        "value": 911,
        "NOCCode": "1452",
        "JobTitle": "Registration clerk"
    },
    {
        "value": 912,
        "NOCCode": "1452",
        "JobTitle": "Registry clerk"
    },
    {
        "value": 913,
        "NOCCode": "1452",
        "JobTitle": "Translation clerk"
    },
    {
        "value": 914,
        "NOCCode": "1452",
        "JobTitle": "Archives clerk"
    },
    {
        "value": 915,
        "NOCCode": "1452",
        "JobTitle": "Press clipper"
    },
    {
        "value": 916,
        "NOCCode": "1452",
        "JobTitle": "Press reader"
    },
    {
        "value": 917,
        "NOCCode": "1452",
        "JobTitle": "Reader"
    },
    {
        "value": 918,
        "NOCCode": "1452",
        "JobTitle": "Tests clerk"
    },
    {
        "value": 919,
        "NOCCode": "1454",
        "JobTitle": "Census enumerator"
    },
    {
        "value": 920,
        "NOCCode": "1454",
        "JobTitle": "Coding clerk - statistics"
    },
    {
        "value": 921,
        "NOCCode": "1454",
        "JobTitle": "Election enumerator"
    },
    {
        "value": 922,
        "NOCCode": "1454",
        "JobTitle": "Interview clerk"
    },
    {
        "value": 923,
        "NOCCode": "1454",
        "JobTitle": "Poll clerk"
    },
    {
        "value": 924,
        "NOCCode": "1454",
        "JobTitle": "Public opinion interviewer"
    },
    {
        "value": 925,
        "NOCCode": "1454",
        "JobTitle": "Statistical clerk"
    },
    {
        "value": 926,
        "NOCCode": "1454",
        "JobTitle": "Survey interviewer"
    },
    {
        "value": 927,
        "NOCCode": "1454",
        "JobTitle": "Telephone survey clerk"
    },
    {
        "value": 928,
        "NOCCode": "1454",
        "JobTitle": "Meter reader"
    },
    {
        "value": 929,
        "NOCCode": "1511",
        "JobTitle": "Bus parcel express clerk"
    },
    {
        "value": 930,
        "NOCCode": "1511",
        "JobTitle": "Express mail service clerk"
    },
    {
        "value": 931,
        "NOCCode": "1511",
        "JobTitle": "Mail clerk"
    },
    {
        "value": 932,
        "NOCCode": "1511",
        "JobTitle": "Mail room clerk"
    },
    {
        "value": 933,
        "NOCCode": "1511",
        "JobTitle": "Mail sorter"
    },
    {
        "value": 934,
        "NOCCode": "1511",
        "JobTitle": "Postal clerk"
    },
    {
        "value": 935,
        "NOCCode": "1511",
        "JobTitle": "Postal counter clerk"
    },
    {
        "value": 936,
        "NOCCode": "1511",
        "JobTitle": "Postal wicket clerk"
    },
    {
        "value": 937,
        "NOCCode": "1511",
        "JobTitle": "Railway mail clerk"
    },
    {
        "value": 938,
        "NOCCode": "1511",
        "JobTitle": "Bursting machine tender"
    },
    {
        "value": 939,
        "NOCCode": "1512",
        "JobTitle": "Letter carrier"
    },
    {
        "value": 940,
        "NOCCode": "1512",
        "JobTitle": "Postman/woman"
    },
    {
        "value": 941,
        "NOCCode": "1512",
        "JobTitle": "Rural mail carrier"
    },
    {
        "value": 942,
        "NOCCode": "1513",
        "JobTitle": "Bank messenger"
    },
    {
        "value": 943,
        "NOCCode": "1513",
        "JobTitle": "Courier"
    },
    {
        "value": 944,
        "NOCCode": "1513",
        "JobTitle": "Delivery person - courier service"
    },
    {
        "value": 945,
        "NOCCode": "1513",
        "JobTitle": "Door-to-door distributor"
    },
    {
        "value": 946,
        "NOCCode": "1513",
        "JobTitle": "Flyer distributor - courier service"
    },
    {
        "value": 947,
        "NOCCode": "1513",
        "JobTitle": "Newspaper carrier"
    },
    {
        "value": 948,
        "NOCCode": "1513",
        "JobTitle": "Runner - courier service"
    },
    {
        "value": 949,
        "NOCCode": "1521",
        "JobTitle": "Freight receiver"
    },
    {
        "value": 950,
        "NOCCode": "1521",
        "JobTitle": "Freight shipper"
    },
    {
        "value": 951,
        "NOCCode": "1521",
        "JobTitle": "Import freight clerk"
    },
    {
        "value": 952,
        "NOCCode": "1521",
        "JobTitle": "Receiver"
    },
    {
        "value": 953,
        "NOCCode": "1521",
        "JobTitle": "Shipper"
    },
    {
        "value": 954,
        "NOCCode": "1521",
        "JobTitle": "Shipper-receiver"
    },
    {
        "value": 955,
        "NOCCode": "1521",
        "JobTitle": "Shipping agent"
    },
    {
        "value": 956,
        "NOCCode": "1521",
        "JobTitle": "Shipping and receiving clerk"
    },
    {
        "value": 957,
        "NOCCode": "1521",
        "JobTitle": "Supply chain assistant"
    },
    {
        "value": 958,
        "NOCCode": "1522",
        "JobTitle": "Ammunition storekeeper"
    },
    {
        "value": 959,
        "NOCCode": "1522",
        "JobTitle": "Automotive partsperson - retail"
    },
    {
        "value": 960,
        "NOCCode": "1522",
        "JobTitle": "Material keeper"
    },
    {
        "value": 961,
        "NOCCode": "1522",
        "JobTitle": "Medical supply clerk"
    },
    {
        "value": 962,
        "NOCCode": "1522",
        "JobTitle": "Motor vehicle parts clerk"
    },
    {
        "value": 963,
        "NOCCode": "1522",
        "JobTitle": "Parts clerk"
    },
    {
        "value": 964,
        "NOCCode": "1522",
        "JobTitle": "Parts supplier"
    },
    {
        "value": 965,
        "NOCCode": "1522",
        "JobTitle": "Partsperson"
    },
    {
        "value": 966,
        "NOCCode": "1522",
        "JobTitle": "Ship storeman/woman"
    },
    {
        "value": 967,
        "NOCCode": "1522",
        "JobTitle": "Storekeeper"
    },
    {
        "value": 968,
        "NOCCode": "1522",
        "JobTitle": "Tool room attendant"
    },
    {
        "value": 969,
        "NOCCode": "1523",
        "JobTitle": "Expediter"
    },
    {
        "value": 970,
        "NOCCode": "1523",
        "JobTitle": "Production clerk"
    },
    {
        "value": 971,
        "NOCCode": "1523",
        "JobTitle": "Production co-ordinator"
    },
    {
        "value": 972,
        "NOCCode": "1523",
        "JobTitle": "Production scheduler"
    },
    {
        "value": 973,
        "NOCCode": "1523",
        "JobTitle": "Scheduling clerk"
    },
    {
        "value": 974,
        "NOCCode": "1523",
        "JobTitle": "Supply chain co-ordinator - production"
    },
    {
        "value": 975,
        "NOCCode": "1523",
        "JobTitle": "Traffic control clerk"
    },
    {
        "value": 976,
        "NOCCode": "1523",
        "JobTitle": "Material estimator"
    },
    {
        "value": 977,
        "NOCCode": "1523",
        "JobTitle": "Traffic clerk - broadcasting"
    },
    {
        "value": 978,
        "NOCCode": "1524",
        "JobTitle": "Inventory analyst"
    },
    {
        "value": 979,
        "NOCCode": "1524",
        "JobTitle": "Inventory clerk"
    },
    {
        "value": 980,
        "NOCCode": "1524",
        "JobTitle": "Inventory control clerk"
    },
    {
        "value": 981,
        "NOCCode": "1524",
        "JobTitle": "Inventory planner"
    },
    {
        "value": 982,
        "NOCCode": "1524",
        "JobTitle": "Procurement clerk"
    },
    {
        "value": 983,
        "NOCCode": "1524",
        "JobTitle": "Purchasing clerk"
    },
    {
        "value": 984,
        "NOCCode": "1524",
        "JobTitle": "Purchasing clerk assistant"
    },
    {
        "value": 985,
        "NOCCode": "1524",
        "JobTitle": "Inventory Auditors"
    },
    {
        "value": 986,
        "NOCCode": "1525",
        "JobTitle": "911 dispatcher"
    },
    {
        "value": 987,
        "NOCCode": "1525",
        "JobTitle": "Alarm system dispatcher"
    },
    {
        "value": 988,
        "NOCCode": "1525",
        "JobTitle": "Ambulance dispatcher"
    },
    {
        "value": 989,
        "NOCCode": "1525",
        "JobTitle": "Emergency vehicle dispatcher"
    },
    {
        "value": 990,
        "NOCCode": "1525",
        "JobTitle": "Inbound/outbound freight co-ordinator"
    },
    {
        "value": 991,
        "NOCCode": "1525",
        "JobTitle": "Mobile equipment dispatcher"
    },
    {
        "value": 992,
        "NOCCode": "1525",
        "JobTitle": "Radio operator"
    },
    {
        "value": 993,
        "NOCCode": "1525",
        "JobTitle": "Taxi dispatcher"
    },
    {
        "value": 994,
        "NOCCode": "1525",
        "JobTitle": "Tow truck dispatcher"
    },
    {
        "value": 995,
        "NOCCode": "1525",
        "JobTitle": "Truck dispatcher"
    },
    {
        "value": 996,
        "NOCCode": "1525",
        "JobTitle": "Utilities maintenance crew dispatcher"
    },
    {
        "value": 997,
        "NOCCode": "1526",
        "JobTitle": "Bus scheduler"
    },
    {
        "value": 998,
        "NOCCode": "1526",
        "JobTitle": "Crew scheduler - transportation"
    },
    {
        "value": 999,
        "NOCCode": "1526",
        "JobTitle": "Flight crew scheduler"
    },
    {
        "value": 1000,
        "NOCCode": "1526",
        "JobTitle": "Schedule analyst"
    },
    {
        "value": 1001,
        "NOCCode": "1526",
        "JobTitle": "Train scheduler"
    },
    {
        "value": 1002,
        "NOCCode": "1526",
        "JobTitle": "Transit scheduler"
    },
    {
        "value": 1003,
        "NOCCode": "1526",
        "JobTitle": "Transportation schedules writer"
    },
    {
        "value": 1004,
        "NOCCode": "1526",
        "JobTitle": "Truck scheduler"
    },
    {
        "value": 1005,
        "NOCCode": "2111",
        "JobTitle": "Acoustics physicist"
    },
    {
        "value": 1006,
        "NOCCode": "2111",
        "JobTitle": "Aerodynamicist"
    },
    {
        "value": 1007,
        "NOCCode": "2111",
        "JobTitle": "Aerospace research scientist"
    },
    {
        "value": 1008,
        "NOCCode": "2111",
        "JobTitle": "Astronomer"
    },
    {
        "value": 1009,
        "NOCCode": "2111",
        "JobTitle": "Astrophysicist"
    },
    {
        "value": 1010,
        "NOCCode": "2111",
        "JobTitle": "Atmospheric physicist"
    },
    {
        "value": 1011,
        "NOCCode": "2111",
        "JobTitle": "Biophysicist"
    },
    {
        "value": 1012,
        "NOCCode": "2111",
        "JobTitle": "Cosmologist"
    },
    {
        "value": 1013,
        "NOCCode": "2111",
        "JobTitle": "Electronics research scientist"
    },
    {
        "value": 1014,
        "NOCCode": "2111",
        "JobTitle": "Experimental physicist"
    },
    {
        "value": 1015,
        "NOCCode": "2111",
        "JobTitle": "Health physicist"
    },
    {
        "value": 1016,
        "NOCCode": "2111",
        "JobTitle": "Medical physicist"
    },
    {
        "value": 1017,
        "NOCCode": "2111",
        "JobTitle": "Metrologist"
    },
    {
        "value": 1018,
        "NOCCode": "2111",
        "JobTitle": "Nuclear physicist"
    },
    {
        "value": 1019,
        "NOCCode": "2111",
        "JobTitle": "Optics physicist"
    },
    {
        "value": 1020,
        "NOCCode": "2111",
        "JobTitle": "Physics research scientist"
    },
    {
        "value": 1021,
        "NOCCode": "2111",
        "JobTitle": "Plasma physicist"
    },
    {
        "value": 1022,
        "NOCCode": "2111",
        "JobTitle": "Radio astronomer"
    },
    {
        "value": 1023,
        "NOCCode": "2111",
        "JobTitle": "Remote sensing research scientist"
    },
    {
        "value": 1024,
        "NOCCode": "2111",
        "JobTitle": "Solid-state physicist"
    },
    {
        "value": 1025,
        "NOCCode": "2112",
        "JobTitle": "Agricultural chemist"
    },
    {
        "value": 1026,
        "NOCCode": "2112",
        "JobTitle": "Analytical chemist"
    },
    {
        "value": 1027,
        "NOCCode": "2112",
        "JobTitle": "Bioanalytical chemist"
    },
    {
        "value": 1028,
        "NOCCode": "2112",
        "JobTitle": "Biochemist"
    },
    {
        "value": 1029,
        "NOCCode": "2112",
        "JobTitle": "Chemist"
    },
    {
        "value": 1030,
        "NOCCode": "2112",
        "JobTitle": "Clinical chemist"
    },
    {
        "value": 1031,
        "NOCCode": "2112",
        "JobTitle": "Coatings chemist"
    },
    {
        "value": 1032,
        "NOCCode": "2112",
        "JobTitle": "Electrochemist"
    },
    {
        "value": 1033,
        "NOCCode": "2112",
        "JobTitle": "Environmental chemist"
    },
    {
        "value": 1034,
        "NOCCode": "2112",
        "JobTitle": "Food chemist"
    },
    {
        "value": 1035,
        "NOCCode": "2112",
        "JobTitle": "Inorganic chemist"
    },
    {
        "value": 1036,
        "NOCCode": "2112",
        "JobTitle": "Medicinal chemist"
    },
    {
        "value": 1037,
        "NOCCode": "2112",
        "JobTitle": "Nuclear magnetic resonance (NMR) spectroscopist"
    },
    {
        "value": 1038,
        "NOCCode": "2112",
        "JobTitle": "Oceanographic chemist"
    },
    {
        "value": 1039,
        "NOCCode": "2112",
        "JobTitle": "Organic chemist"
    },
    {
        "value": 1040,
        "NOCCode": "2112",
        "JobTitle": "Organic mass spectrometrist"
    },
    {
        "value": 1041,
        "NOCCode": "2112",
        "JobTitle": "Organometallic chemist"
    },
    {
        "value": 1042,
        "NOCCode": "2112",
        "JobTitle": "Pharmacological chemist"
    },
    {
        "value": 1043,
        "NOCCode": "2112",
        "JobTitle": "Physical chemist"
    },
    {
        "value": 1044,
        "NOCCode": "2112",
        "JobTitle": "Polymer chemist"
    },
    {
        "value": 1045,
        "NOCCode": "2112",
        "JobTitle": "Quality control chemist"
    },
    {
        "value": 1046,
        "NOCCode": "2112",
        "JobTitle": "Research chemist"
    },
    {
        "value": 1047,
        "NOCCode": "2112",
        "JobTitle": "Soil chemist"
    },
    {
        "value": 1048,
        "NOCCode": "2112",
        "JobTitle": "Textile chemist"
    },
    {
        "value": 1049,
        "NOCCode": "2112",
        "JobTitle": "Theoretical chemist"
    },
    {
        "value": 1050,
        "NOCCode": "2113",
        "JobTitle": "Development geologist"
    },
    {
        "value": 1051,
        "NOCCode": "2113",
        "JobTitle": "Environmental geologist"
    },
    {
        "value": 1052,
        "NOCCode": "2113",
        "JobTitle": "Exploration geologist"
    },
    {
        "value": 1053,
        "NOCCode": "2113",
        "JobTitle": "Geochemist"
    },
    {
        "value": 1054,
        "NOCCode": "2113",
        "JobTitle": "Geologist"
    },
    {
        "value": 1055,
        "NOCCode": "2113",
        "JobTitle": "Geophysicist"
    },
    {
        "value": 1056,
        "NOCCode": "2113",
        "JobTitle": "Glaciologist"
    },
    {
        "value": 1057,
        "NOCCode": "2113",
        "JobTitle": "Groundwater geologist"
    },
    {
        "value": 1058,
        "NOCCode": "2113",
        "JobTitle": "Hydrogeologist"
    },
    {
        "value": 1059,
        "NOCCode": "2113",
        "JobTitle": "Hydrologist"
    },
    {
        "value": 1060,
        "NOCCode": "2113",
        "JobTitle": "Mine geologist"
    },
    {
        "value": 1061,
        "NOCCode": "2113",
        "JobTitle": "Mineralogist"
    },
    {
        "value": 1062,
        "NOCCode": "2113",
        "JobTitle": "Oceanographer"
    },
    {
        "value": 1063,
        "NOCCode": "2113",
        "JobTitle": "Paleontologist"
    },
    {
        "value": 1064,
        "NOCCode": "2113",
        "JobTitle": "Petroleum geologist"
    },
    {
        "value": 1065,
        "NOCCode": "2113",
        "JobTitle": "Petrologist"
    },
    {
        "value": 1066,
        "NOCCode": "2113",
        "JobTitle": "Sedimentologist"
    },
    {
        "value": 1067,
        "NOCCode": "2113",
        "JobTitle": "Seismologist"
    },
    {
        "value": 1068,
        "NOCCode": "2113",
        "JobTitle": "Stratigrapher"
    },
    {
        "value": 1069,
        "NOCCode": "2114",
        "JobTitle": "Air quality meteorologist"
    },
    {
        "value": 1070,
        "NOCCode": "2114",
        "JobTitle": "Climatologist"
    },
    {
        "value": 1071,
        "NOCCode": "2114",
        "JobTitle": "Hydrometeorologist"
    },
    {
        "value": 1072,
        "NOCCode": "2114",
        "JobTitle": "Meteorologist"
    },
    {
        "value": 1073,
        "NOCCode": "2114",
        "JobTitle": "Operational meteorologist"
    },
    {
        "value": 1074,
        "NOCCode": "2114",
        "JobTitle": "Weather Forecaster"
    },
    {
        "value": 1075,
        "NOCCode": "2115",
        "JobTitle": "Astronaut"
    },
    {
        "value": 1076,
        "NOCCode": "2115",
        "JobTitle": "Ballistics examiner"
    },
    {
        "value": 1077,
        "NOCCode": "2115",
        "JobTitle": "Materials scientist"
    },
    {
        "value": 1078,
        "NOCCode": "2115",
        "JobTitle": "Metallurgist"
    },
    {
        "value": 1079,
        "NOCCode": "2115",
        "JobTitle": "Physical metallurgist"
    },
    {
        "value": 1080,
        "NOCCode": "2115",
        "JobTitle": "Research scientist - ceramics"
    },
    {
        "value": 1081,
        "NOCCode": "2115",
        "JobTitle": "Research scientist - composite materials"
    },
    {
        "value": 1082,
        "NOCCode": "2115",
        "JobTitle": "Soil scientist"
    },
    {
        "value": 1083,
        "NOCCode": "2121",
        "JobTitle": "Anatomist"
    },
    {
        "value": 1084,
        "NOCCode": "2121",
        "JobTitle": "Bacteriologist"
    },
    {
        "value": 1085,
        "NOCCode": "2121",
        "JobTitle": "Bioinformatician"
    },
    {
        "value": 1086,
        "NOCCode": "2121",
        "JobTitle": "Biologist"
    },
    {
        "value": 1087,
        "NOCCode": "2121",
        "JobTitle": "Botanist"
    },
    {
        "value": 1088,
        "NOCCode": "2121",
        "JobTitle": "Cell biologist"
    },
    {
        "value": 1089,
        "NOCCode": "2121",
        "JobTitle": "Ecologist"
    },
    {
        "value": 1090,
        "NOCCode": "2121",
        "JobTitle": "Embryologist"
    },
    {
        "value": 1091,
        "NOCCode": "2121",
        "JobTitle": "Geneticist"
    },
    {
        "value": 1092,
        "NOCCode": "2121",
        "JobTitle": "Histologist"
    },
    {
        "value": 1093,
        "NOCCode": "2121",
        "JobTitle": "Immunologist"
    },
    {
        "value": 1094,
        "NOCCode": "2121",
        "JobTitle": "Marine biologist"
    },
    {
        "value": 1095,
        "NOCCode": "2121",
        "JobTitle": "Microbiologist"
    },
    {
        "value": 1096,
        "NOCCode": "2121",
        "JobTitle": "Molecular biologist"
    },
    {
        "value": 1097,
        "NOCCode": "2121",
        "JobTitle": "Mycologist"
    },
    {
        "value": 1098,
        "NOCCode": "2121",
        "JobTitle": "Parasitologist"
    },
    {
        "value": 1099,
        "NOCCode": "2121",
        "JobTitle": "Pharmacologist"
    },
    {
        "value": 1100,
        "NOCCode": "2121",
        "JobTitle": "Physiologist"
    },
    {
        "value": 1101,
        "NOCCode": "2121",
        "JobTitle": "Protozoologist"
    },
    {
        "value": 1102,
        "NOCCode": "2121",
        "JobTitle": "Toxicologist"
    },
    {
        "value": 1103,
        "NOCCode": "2121",
        "JobTitle": "Virologist"
    },
    {
        "value": 1104,
        "NOCCode": "2121",
        "JobTitle": "Zoologist"
    },
    {
        "value": 1105,
        "NOCCode": "2122",
        "JobTitle": "Appraisal forester"
    },
    {
        "value": 1106,
        "NOCCode": "2122",
        "JobTitle": "Consulting forester"
    },
    {
        "value": 1107,
        "NOCCode": "2122",
        "JobTitle": "District forester"
    },
    {
        "value": 1108,
        "NOCCode": "2122",
        "JobTitle": "Forester"
    },
    {
        "value": 1109,
        "NOCCode": "2122",
        "JobTitle": "Regional inventory officer - forestry"
    },
    {
        "value": 1110,
        "NOCCode": "2122",
        "JobTitle": "Registered professional forester (RPF)"
    },
    {
        "value": 1111,
        "NOCCode": "2122",
        "JobTitle": "Forest engineer"
    },
    {
        "value": 1112,
        "NOCCode": "2122",
        "JobTitle": "Superintendent - forestry"
    },
    {
        "value": 1113,
        "NOCCode": "2123",
        "JobTitle": "Agricultural consultant"
    },
    {
        "value": 1114,
        "NOCCode": "2123",
        "JobTitle": "Agricultural extension supervisor"
    },
    {
        "value": 1115,
        "NOCCode": "2123",
        "JobTitle": "Agricultural livestock specialist"
    },
    {
        "value": 1116,
        "NOCCode": "2123",
        "JobTitle": "Agricultural representative"
    },
    {
        "value": 1117,
        "NOCCode": "2123",
        "JobTitle": "Agricultural soil and crop specialist"
    },
    {
        "value": 1118,
        "NOCCode": "2123",
        "JobTitle": "Agriculturist"
    },
    {
        "value": 1119,
        "NOCCode": "2123",
        "JobTitle": "Agrologist"
    },
    {
        "value": 1120,
        "NOCCode": "2123",
        "JobTitle": "Agronomist"
    },
    {
        "value": 1121,
        "NOCCode": "2123",
        "JobTitle": "Consulting agrologist"
    },
    {
        "value": 1122,
        "NOCCode": "2123",
        "JobTitle": "Crop specialist"
    },
    {
        "value": 1123,
        "NOCCode": "2123",
        "JobTitle": "Farm management consultant"
    },
    {
        "value": 1124,
        "NOCCode": "2123",
        "JobTitle": "Field service adviser - agriculture"
    },
    {
        "value": 1125,
        "NOCCode": "2123",
        "JobTitle": "Field service agent - agriculture"
    },
    {
        "value": 1126,
        "NOCCode": "2123",
        "JobTitle": "Growers' advisor"
    },
    {
        "value": 1127,
        "NOCCode": "2123",
        "JobTitle": "Professional agrologist (P.Ag.)"
    },
    {
        "value": 1128,
        "NOCCode": "2131",
        "JobTitle": "Bridge engineer"
    },
    {
        "value": 1129,
        "NOCCode": "2131",
        "JobTitle": "Civil engineer"
    },
    {
        "value": 1130,
        "NOCCode": "2131",
        "JobTitle": "Construction engineer"
    },
    {
        "value": 1131,
        "NOCCode": "2131",
        "JobTitle": "Construction project engineer"
    },
    {
        "value": 1132,
        "NOCCode": "2131",
        "JobTitle": "Environmental engineer"
    },
    {
        "value": 1133,
        "NOCCode": "2131",
        "JobTitle": "Geodetic engineer"
    },
    {
        "value": 1134,
        "NOCCode": "2131",
        "JobTitle": "Geomatics engineer"
    },
    {
        "value": 1135,
        "NOCCode": "2131",
        "JobTitle": "Highway engineer"
    },
    {
        "value": 1136,
        "NOCCode": "2131",
        "JobTitle": "Hydraulics engineer"
    },
    {
        "value": 1137,
        "NOCCode": "2131",
        "JobTitle": "Municipal engineer"
    },
    {
        "value": 1138,
        "NOCCode": "2131",
        "JobTitle": "Public works engineer"
    },
    {
        "value": 1139,
        "NOCCode": "2131",
        "JobTitle": "Sanitation engineer"
    },
    {
        "value": 1140,
        "NOCCode": "2131",
        "JobTitle": "Structural engineer"
    },
    {
        "value": 1141,
        "NOCCode": "2131",
        "JobTitle": "Surveying engineer"
    },
    {
        "value": 1142,
        "NOCCode": "2131",
        "JobTitle": "Traffic engineer"
    },
    {
        "value": 1143,
        "NOCCode": "2131",
        "JobTitle": "Transportation engineer"
    },
    {
        "value": 1144,
        "NOCCode": "2131",
        "JobTitle": "Water management engineer"
    },
    {
        "value": 1145,
        "NOCCode": "2131",
        "JobTitle": "Spatial information systems engineer"
    },
    {
        "value": 1146,
        "NOCCode": "2132",
        "JobTitle": "Acoustics engineer"
    },
    {
        "value": 1147,
        "NOCCode": "2132",
        "JobTitle": "Automotive engineer"
    },
    {
        "value": 1148,
        "NOCCode": "2132",
        "JobTitle": "Design engineer - mechanical"
    },
    {
        "value": 1149,
        "NOCCode": "2132",
        "JobTitle": "Energy conservation engineer"
    },
    {
        "value": 1150,
        "NOCCode": "2132",
        "JobTitle": "Fluid mechanics engineer"
    },
    {
        "value": 1151,
        "NOCCode": "2132",
        "JobTitle": "Heating, ventilation and air conditioning (HVAC) engineer"
    },
    {
        "value": 1152,
        "NOCCode": "2132",
        "JobTitle": "Mechanical engineer"
    },
    {
        "value": 1153,
        "NOCCode": "2132",
        "JobTitle": "Mechanical maintenance engineer"
    },
    {
        "value": 1154,
        "NOCCode": "2132",
        "JobTitle": "Nuclear engineer"
    },
    {
        "value": 1155,
        "NOCCode": "2132",
        "JobTitle": "Piping engineer"
    },
    {
        "value": 1156,
        "NOCCode": "2132",
        "JobTitle": "Power generation engineer"
    },
    {
        "value": 1157,
        "NOCCode": "2132",
        "JobTitle": "Refrigeration engineer"
    },
    {
        "value": 1158,
        "NOCCode": "2132",
        "JobTitle": "Robotics engineer"
    },
    {
        "value": 1159,
        "NOCCode": "2132",
        "JobTitle": "Thermal design engineer"
    },
    {
        "value": 1160,
        "NOCCode": "2132",
        "JobTitle": "Tool engineer"
    },
    {
        "value": 1161,
        "NOCCode": "2133",
        "JobTitle": "Avionics engineer"
    },
    {
        "value": 1162,
        "NOCCode": "2133",
        "JobTitle": "Control systems engineer"
    },
    {
        "value": 1163,
        "NOCCode": "2133",
        "JobTitle": "Electrical design engineer"
    },
    {
        "value": 1164,
        "NOCCode": "2133",
        "JobTitle": "Electrical distribution planning engineer"
    },
    {
        "value": 1165,
        "NOCCode": "2133",
        "JobTitle": "Electrical engineer"
    },
    {
        "value": 1166,
        "NOCCode": "2133",
        "JobTitle": "Electrical network engineer"
    },
    {
        "value": 1167,
        "NOCCode": "2133",
        "JobTitle": "Electrical process control engineer"
    },
    {
        "value": 1168,
        "NOCCode": "2133",
        "JobTitle": "Electrical systems planning engineer"
    },
    {
        "value": 1169,
        "NOCCode": "2133",
        "JobTitle": "Electronics engineer"
    },
    {
        "value": 1170,
        "NOCCode": "2133",
        "JobTitle": "Electronics test engineer"
    },
    {
        "value": 1171,
        "NOCCode": "2133",
        "JobTitle": "Instrumentation and control engineer"
    },
    {
        "value": 1172,
        "NOCCode": "2133",
        "JobTitle": "Roadway lighting design engineer"
    },
    {
        "value": 1173,
        "NOCCode": "2133",
        "JobTitle": "Television systems engineer"
    },
    {
        "value": 1174,
        "NOCCode": "2134",
        "JobTitle": "Adhesives engineer"
    },
    {
        "value": 1175,
        "NOCCode": "2134",
        "JobTitle": "Biochemical engineer"
    },
    {
        "value": 1176,
        "NOCCode": "2134",
        "JobTitle": "Biotechnical engineer"
    },
    {
        "value": 1177,
        "NOCCode": "2134",
        "JobTitle": "Chemical process control engineer"
    },
    {
        "value": 1178,
        "NOCCode": "2134",
        "JobTitle": "Chemical process engineer"
    },
    {
        "value": 1179,
        "NOCCode": "2134",
        "JobTitle": "Chemical project engineer"
    },
    {
        "value": 1180,
        "NOCCode": "2134",
        "JobTitle": "Environmental chemical engineer"
    },
    {
        "value": 1181,
        "NOCCode": "2134",
        "JobTitle": "Industrial hygiene engineer"
    },
    {
        "value": 1182,
        "NOCCode": "2134",
        "JobTitle": "Industrial waste treatment engineer"
    },
    {
        "value": 1183,
        "NOCCode": "2134",
        "JobTitle": "Liquid fuels engineer"
    },
    {
        "value": 1184,
        "NOCCode": "2134",
        "JobTitle": "Petrochemical engineer"
    },
    {
        "value": 1185,
        "NOCCode": "2134",
        "JobTitle": "Polymer engineer"
    },
    {
        "value": 1186,
        "NOCCode": "2134",
        "JobTitle": "Pulp and paper engineer"
    },
    {
        "value": 1187,
        "NOCCode": "2134",
        "JobTitle": "Refinery engineer"
    },
    {
        "value": 1188,
        "NOCCode": "2134",
        "JobTitle": "Waste treatment engineer"
    },
    {
        "value": 1189,
        "NOCCode": "2134",
        "JobTitle": "Pipeline transport engineer"
    },
    {
        "value": 1190,
        "NOCCode": "2141",
        "JobTitle": "Computer integrated manufacturing (CIM) engineer"
    },
    {
        "value": 1191,
        "NOCCode": "2141",
        "JobTitle": "Fire prevention engineer"
    },
    {
        "value": 1192,
        "NOCCode": "2141",
        "JobTitle": "Industrial engineer"
    },
    {
        "value": 1193,
        "NOCCode": "2141",
        "JobTitle": "Manufacturing engineer"
    },
    {
        "value": 1194,
        "NOCCode": "2141",
        "JobTitle": "Plant engineer"
    },
    {
        "value": 1195,
        "NOCCode": "2141",
        "JobTitle": "Production engineer"
    },
    {
        "value": 1196,
        "NOCCode": "2141",
        "JobTitle": "Quality control engineer"
    },
    {
        "value": 1197,
        "NOCCode": "2141",
        "JobTitle": "Safety engineer"
    },
    {
        "value": 1198,
        "NOCCode": "2141",
        "JobTitle": "Work measurement engineer"
    },
    {
        "value": 1199,
        "NOCCode": "2142",
        "JobTitle": "Ceramics engineer"
    },
    {
        "value": 1200,
        "NOCCode": "2142",
        "JobTitle": "Corrosion engineer"
    },
    {
        "value": 1201,
        "NOCCode": "2142",
        "JobTitle": "Electrometallurgical engineer"
    },
    {
        "value": 1202,
        "NOCCode": "2142",
        "JobTitle": "Foundry engineer"
    },
    {
        "value": 1203,
        "NOCCode": "2142",
        "JobTitle": "Hydrometallurgical engineer"
    },
    {
        "value": 1204,
        "NOCCode": "2142",
        "JobTitle": "Materials engineer"
    },
    {
        "value": 1205,
        "NOCCode": "2142",
        "JobTitle": "Metallurgical engineer"
    },
    {
        "value": 1206,
        "NOCCode": "2142",
        "JobTitle": "Physical metallurgical engineer"
    },
    {
        "value": 1207,
        "NOCCode": "2142",
        "JobTitle": "Pyrometallurgical engineer"
    },
    {
        "value": 1208,
        "NOCCode": "2142",
        "JobTitle": "Welding engineer"
    },
    {
        "value": 1209,
        "NOCCode": "2143",
        "JobTitle": "Mine design engineer"
    },
    {
        "value": 1210,
        "NOCCode": "2143",
        "JobTitle": "Mine development engineer"
    },
    {
        "value": 1211,
        "NOCCode": "2143",
        "JobTitle": "Mine layout engineer"
    },
    {
        "value": 1212,
        "NOCCode": "2143",
        "JobTitle": "Mine production engineer"
    },
    {
        "value": 1213,
        "NOCCode": "2143",
        "JobTitle": "Mine safety engineer"
    },
    {
        "value": 1214,
        "NOCCode": "2143",
        "JobTitle": "Mine ventilation engineer"
    },
    {
        "value": 1215,
        "NOCCode": "2143",
        "JobTitle": "Mineral engineer"
    },
    {
        "value": 1216,
        "NOCCode": "2143",
        "JobTitle": "Mining engineer"
    },
    {
        "value": 1217,
        "NOCCode": "2144",
        "JobTitle": "Geological engineer"
    },
    {
        "value": 1218,
        "NOCCode": "2144",
        "JobTitle": "Geophysical engineer"
    },
    {
        "value": 1219,
        "NOCCode": "2144",
        "JobTitle": "Hydrogeological engineer - engineering"
    },
    {
        "value": 1220,
        "NOCCode": "2145",
        "JobTitle": "Oil and gas drilling engineer"
    },
    {
        "value": 1221,
        "NOCCode": "2145",
        "JobTitle": "Oil and gas production engineer"
    },
    {
        "value": 1222,
        "NOCCode": "2145",
        "JobTitle": "Petroleum engineer"
    },
    {
        "value": 1223,
        "NOCCode": "2145",
        "JobTitle": "Petroleum reservoir engineer"
    },
    {
        "value": 1224,
        "NOCCode": "2145",
        "JobTitle": "Petroleum well completion engineer"
    },
    {
        "value": 1225,
        "NOCCode": "2145",
        "JobTitle": "Subsea engineer"
    },
    {
        "value": 1226,
        "NOCCode": "2146",
        "JobTitle": "Aerodynamics engineer"
    },
    {
        "value": 1227,
        "NOCCode": "2146",
        "JobTitle": "Aeronautical engineer"
    },
    {
        "value": 1228,
        "NOCCode": "2146",
        "JobTitle": "Aerospace engineer"
    },
    {
        "value": 1229,
        "NOCCode": "2146",
        "JobTitle": "Aerospace structural engineer"
    },
    {
        "value": 1230,
        "NOCCode": "2146",
        "JobTitle": "Aerospace systems engineer"
    },
    {
        "value": 1231,
        "NOCCode": "2146",
        "JobTitle": "Aerospace test engineer"
    },
    {
        "value": 1232,
        "NOCCode": "2146",
        "JobTitle": "Aircraft design engineer"
    },
    {
        "value": 1233,
        "NOCCode": "2146",
        "JobTitle": "Stress engineer - aerospace"
    },
    {
        "value": 1234,
        "NOCCode": "2147",
        "JobTitle": "Computer hardware engineer"
    },
    {
        "value": 1235,
        "NOCCode": "2147",
        "JobTitle": "Fibre-optic network designer"
    },
    {
        "value": 1236,
        "NOCCode": "2147",
        "JobTitle": "Hardware circuit board designer"
    },
    {
        "value": 1237,
        "NOCCode": "2147",
        "JobTitle": "Hardware development engineer"
    },
    {
        "value": 1238,
        "NOCCode": "2147",
        "JobTitle": "Hardware technical architect"
    },
    {
        "value": 1239,
        "NOCCode": "2147",
        "JobTitle": "Network test engineer"
    },
    {
        "value": 1240,
        "NOCCode": "2147",
        "JobTitle": "Systems designer - hardware"
    },
    {
        "value": 1241,
        "NOCCode": "2147",
        "JobTitle": "Telecommunications hardware engineer"
    },
    {
        "value": 1242,
        "NOCCode": "2147",
        "JobTitle": "Wireless communications network engineer"
    },
    {
        "value": 1243,
        "NOCCode": "2148",
        "JobTitle": "Agricultural engineer"
    },
    {
        "value": 1244,
        "NOCCode": "2148",
        "JobTitle": "Biomedical engineer"
    },
    {
        "value": 1245,
        "NOCCode": "2148",
        "JobTitle": "Bioresource engineer"
    },
    {
        "value": 1246,
        "NOCCode": "2148",
        "JobTitle": "Engineering physicist"
    },
    {
        "value": 1247,
        "NOCCode": "2148",
        "JobTitle": "Engineering scientist"
    },
    {
        "value": 1248,
        "NOCCode": "2148",
        "JobTitle": "Food processing engineer"
    },
    {
        "value": 1249,
        "NOCCode": "2148",
        "JobTitle": "Marine engineer"
    },
    {
        "value": 1250,
        "NOCCode": "2148",
        "JobTitle": "Naval architect"
    },
    {
        "value": 1251,
        "NOCCode": "2148",
        "JobTitle": "Textile engineer"
    },
    {
        "value": 1252,
        "NOCCode": "2151",
        "JobTitle": "Architect"
    },
    {
        "value": 1253,
        "NOCCode": "2151",
        "JobTitle": "Architectural standards specialist"
    },
    {
        "value": 1254,
        "NOCCode": "2151",
        "JobTitle": "Chief architect"
    },
    {
        "value": 1255,
        "NOCCode": "2151",
        "JobTitle": "Consulting architect"
    },
    {
        "value": 1256,
        "NOCCode": "2151",
        "JobTitle": "Industrial and commercial buildings architect"
    },
    {
        "value": 1257,
        "NOCCode": "2151",
        "JobTitle": "Residential architect"
    },
    {
        "value": 1258,
        "NOCCode": "2152",
        "JobTitle": "Landscape architect"
    },
    {
        "value": 1259,
        "NOCCode": "2152",
        "JobTitle": "Community and urban planner"
    },
    {
        "value": 1260,
        "NOCCode": "2152",
        "JobTitle": "Environmental planner"
    },
    {
        "value": 1261,
        "NOCCode": "2152",
        "JobTitle": "Land use planner"
    },
    {
        "value": 1262,
        "NOCCode": "2152",
        "JobTitle": "Municipal planner"
    },
    {
        "value": 1263,
        "NOCCode": "2152",
        "JobTitle": "Park planner"
    },
    {
        "value": 1264,
        "NOCCode": "2152",
        "JobTitle": "Planner"
    },
    {
        "value": 1265,
        "NOCCode": "2152",
        "JobTitle": "Recreation planner"
    },
    {
        "value": 1266,
        "NOCCode": "2152",
        "JobTitle": "Regional planner"
    },
    {
        "value": 1267,
        "NOCCode": "2152",
        "JobTitle": "Urban planner"
    },
    {
        "value": 1268,
        "NOCCode": "2154",
        "JobTitle": "Cadastral surveyor"
    },
    {
        "value": 1269,
        "NOCCode": "2154",
        "JobTitle": "Canada lands surveyor"
    },
    {
        "value": 1270,
        "NOCCode": "2154",
        "JobTitle": "City surveyor"
    },
    {
        "value": 1271,
        "NOCCode": "2154",
        "JobTitle": "Land surveyor"
    },
    {
        "value": 1272,
        "NOCCode": "2154",
        "JobTitle": "Property surveyor"
    },
    {
        "value": 1273,
        "NOCCode": "2154",
        "JobTitle": "Land survey supervisor"
    },
    {
        "value": 1274,
        "NOCCode": "2161",
        "JobTitle": "Actuary"
    },
    {
        "value": 1275,
        "NOCCode": "2161",
        "JobTitle": "Biostatistician"
    },
    {
        "value": 1276,
        "NOCCode": "2161",
        "JobTitle": "Consulting actuary"
    },
    {
        "value": 1277,
        "NOCCode": "2161",
        "JobTitle": "Demographer"
    },
    {
        "value": 1278,
        "NOCCode": "2161",
        "JobTitle": "Insurance actuary"
    },
    {
        "value": 1279,
        "NOCCode": "2161",
        "JobTitle": "Mathematician"
    },
    {
        "value": 1280,
        "NOCCode": "2161",
        "JobTitle": "Statistical analyst"
    },
    {
        "value": 1281,
        "NOCCode": "2161",
        "JobTitle": "Statistician"
    },
    {
        "value": 1282,
        "NOCCode": "2171",
        "JobTitle": "Computer systems analyst"
    },
    {
        "value": 1283,
        "NOCCode": "2171",
        "JobTitle": "Informatics consultant"
    },
    {
        "value": 1284,
        "NOCCode": "2171",
        "JobTitle": "Informatics security analyst"
    },
    {
        "value": 1285,
        "NOCCode": "2171",
        "JobTitle": "Information systems business analyst"
    },
    {
        "value": 1286,
        "NOCCode": "2171",
        "JobTitle": "Information systems quality assurance (QA) analyst"
    },
    {
        "value": 1287,
        "NOCCode": "2171",
        "JobTitle": "Information technology (IT) consultant"
    },
    {
        "value": 1288,
        "NOCCode": "2171",
        "JobTitle": "Management information systems (MIS) analyst"
    },
    {
        "value": 1289,
        "NOCCode": "2171",
        "JobTitle": "Systems auditor"
    },
    {
        "value": 1290,
        "NOCCode": "2171",
        "JobTitle": "Systems consultant"
    },
    {
        "value": 1291,
        "NOCCode": "2171",
        "JobTitle": "Systems security analyst"
    },
    {
        "value": 1292,
        "NOCCode": "2172",
        "JobTitle": "Data administrator"
    },
    {
        "value": 1293,
        "NOCCode": "2172",
        "JobTitle": "Data custodian"
    },
    {
        "value": 1294,
        "NOCCode": "2172",
        "JobTitle": "Data dictionary administrator"
    },
    {
        "value": 1295,
        "NOCCode": "2172",
        "JobTitle": "Data warehouse analyst"
    },
    {
        "value": 1296,
        "NOCCode": "2172",
        "JobTitle": "Database administrator (DBA)"
    },
    {
        "value": 1297,
        "NOCCode": "2172",
        "JobTitle": "Database analyst"
    },
    {
        "value": 1298,
        "NOCCode": "2172",
        "JobTitle": "Database architect"
    },
    {
        "value": 1299,
        "NOCCode": "2172",
        "JobTitle": "Technical architect - database"
    },
    {
        "value": 1300,
        "NOCCode": "2173",
        "JobTitle": "Application architect"
    },
    {
        "value": 1301,
        "NOCCode": "2173",
        "JobTitle": "Computer software engineer"
    },
    {
        "value": 1302,
        "NOCCode": "2173",
        "JobTitle": "Embedded software engineer"
    },
    {
        "value": 1303,
        "NOCCode": "2173",
        "JobTitle": "Software architect"
    },
    {
        "value": 1304,
        "NOCCode": "2173",
        "JobTitle": "Software design engineer"
    },
    {
        "value": 1305,
        "NOCCode": "2173",
        "JobTitle": "Software design verification engineer"
    },
    {
        "value": 1306,
        "NOCCode": "2173",
        "JobTitle": "Software designer"
    },
    {
        "value": 1307,
        "NOCCode": "2173",
        "JobTitle": "Software systems integration engineer"
    },
    {
        "value": 1308,
        "NOCCode": "2173",
        "JobTitle": "Software technical architect"
    },
    {
        "value": 1309,
        "NOCCode": "2173",
        "JobTitle": "Software testing engineer"
    },
    {
        "value": 1310,
        "NOCCode": "2173",
        "JobTitle": "Telecommunications software engineer"
    },
    {
        "value": 1311,
        "NOCCode": "2173",
        "JobTitle": "Systems architect"
    },
    {
        "value": 1312,
        "NOCCode": "2174",
        "JobTitle": "Application programmer"
    },
    {
        "value": 1313,
        "NOCCode": "2174",
        "JobTitle": "Business application programmer"
    },
    {
        "value": 1314,
        "NOCCode": "2174",
        "JobTitle": "Computer game developer"
    },
    {
        "value": 1315,
        "NOCCode": "2174",
        "JobTitle": "Computer programmer"
    },
    {
        "value": 1316,
        "NOCCode": "2174",
        "JobTitle": "Electronic business (e-business) software developer"
    },
    {
        "value": 1317,
        "NOCCode": "2174",
        "JobTitle": "Interactive media developer"
    },
    {
        "value": 1318,
        "NOCCode": "2174",
        "JobTitle": "Multimedia developer"
    },
    {
        "value": 1319,
        "NOCCode": "2174",
        "JobTitle": "Operating systems programmer"
    },
    {
        "value": 1320,
        "NOCCode": "2174",
        "JobTitle": "Programmer analyst"
    },
    {
        "value": 1321,
        "NOCCode": "2174",
        "JobTitle": "Scientific programmer"
    },
    {
        "value": 1322,
        "NOCCode": "2174",
        "JobTitle": "Software developer"
    },
    {
        "value": 1323,
        "NOCCode": "2174",
        "JobTitle": "Software programmer"
    },
    {
        "value": 1324,
        "NOCCode": "2174",
        "JobTitle": "Systems programmer"
    },
    {
        "value": 1325,
        "NOCCode": "2174",
        "JobTitle": "Web programmer"
    },
    {
        "value": 1326,
        "NOCCode": "2175",
        "JobTitle": "Electronic business (e-business) Web site developer"
    },
    {
        "value": 1327,
        "NOCCode": "2175",
        "JobTitle": "Internet site designer"
    },
    {
        "value": 1328,
        "NOCCode": "2175",
        "JobTitle": "Internet site developer"
    },
    {
        "value": 1329,
        "NOCCode": "2175",
        "JobTitle": "Intranet site designer"
    },
    {
        "value": 1330,
        "NOCCode": "2175",
        "JobTitle": "Web designer"
    },
    {
        "value": 1331,
        "NOCCode": "2175",
        "JobTitle": "Web developer"
    },
    {
        "value": 1332,
        "NOCCode": "2175",
        "JobTitle": "Web manager"
    },
    {
        "value": 1333,
        "NOCCode": "2175",
        "JobTitle": "Web site developer"
    },
    {
        "value": 1334,
        "NOCCode": "2175",
        "JobTitle": "Webmaster"
    },
    {
        "value": 1335,
        "NOCCode": "2211",
        "JobTitle": "Analytical chemistry technician"
    },
    {
        "value": 1336,
        "NOCCode": "2211",
        "JobTitle": "Biochemistry technologist"
    },
    {
        "value": 1337,
        "NOCCode": "2211",
        "JobTitle": "Chemical analyst"
    },
    {
        "value": 1338,
        "NOCCode": "2211",
        "JobTitle": "Chemical engineering technician"
    },
    {
        "value": 1339,
        "NOCCode": "2211",
        "JobTitle": "Chemical engineering technologist"
    },
    {
        "value": 1340,
        "NOCCode": "2211",
        "JobTitle": "Chemical laboratory analyst"
    },
    {
        "value": 1341,
        "NOCCode": "2211",
        "JobTitle": "Chemical research technician"
    },
    {
        "value": 1342,
        "NOCCode": "2211",
        "JobTitle": "Chemical technician"
    },
    {
        "value": 1343,
        "NOCCode": "2211",
        "JobTitle": "Chemical technologist"
    },
    {
        "value": 1344,
        "NOCCode": "2211",
        "JobTitle": "Food technologist"
    },
    {
        "value": 1345,
        "NOCCode": "2211",
        "JobTitle": "Formulation technician"
    },
    {
        "value": 1346,
        "NOCCode": "2211",
        "JobTitle": "Geochemical technician"
    },
    {
        "value": 1347,
        "NOCCode": "2211",
        "JobTitle": "Industrial hygiene technologist"
    },
    {
        "value": 1348,
        "NOCCode": "2211",
        "JobTitle": "Mass spectrometer technician"
    },
    {
        "value": 1349,
        "NOCCode": "2211",
        "JobTitle": "Master dyer - textiles"
    },
    {
        "value": 1350,
        "NOCCode": "2211",
        "JobTitle": "Paint technician"
    },
    {
        "value": 1351,
        "NOCCode": "2211",
        "JobTitle": "Pilot plant technician"
    },
    {
        "value": 1352,
        "NOCCode": "2211",
        "JobTitle": "Quality control technician - chemical processing"
    },
    {
        "value": 1353,
        "NOCCode": "2211",
        "JobTitle": "Quality control technician - food processing"
    },
    {
        "value": 1354,
        "NOCCode": "2211",
        "JobTitle": "Documents examiner - applied chemistry"
    },
    {
        "value": 1355,
        "NOCCode": "2211",
        "JobTitle": "Forensic handwriting expert"
    },
    {
        "value": 1356,
        "NOCCode": "2212",
        "JobTitle": "Assayer"
    },
    {
        "value": 1357,
        "NOCCode": "2212",
        "JobTitle": "Geological technician"
    },
    {
        "value": 1358,
        "NOCCode": "2212",
        "JobTitle": "Geophysical technologist"
    },
    {
        "value": 1359,
        "NOCCode": "2212",
        "JobTitle": "Groundwater technologist"
    },
    {
        "value": 1360,
        "NOCCode": "2212",
        "JobTitle": "Log technician"
    },
    {
        "value": 1361,
        "NOCCode": "2212",
        "JobTitle": "Marine geoscience technologist"
    },
    {
        "value": 1362,
        "NOCCode": "2212",
        "JobTitle": "Metallurgical technologist"
    },
    {
        "value": 1363,
        "NOCCode": "2212",
        "JobTitle": "Mineralogy technician"
    },
    {
        "value": 1364,
        "NOCCode": "2212",
        "JobTitle": "Mining engineering technologist"
    },
    {
        "value": 1365,
        "NOCCode": "2212",
        "JobTitle": "Mining technologist"
    },
    {
        "value": 1366,
        "NOCCode": "2212",
        "JobTitle": "Petroleum engineering technologist"
    },
    {
        "value": 1367,
        "NOCCode": "2212",
        "JobTitle": "Petroleum technician"
    },
    {
        "value": 1368,
        "NOCCode": "2212",
        "JobTitle": "Petrology technician"
    },
    {
        "value": 1369,
        "NOCCode": "2212",
        "JobTitle": "Reservoir engineering technician"
    },
    {
        "value": 1370,
        "NOCCode": "2212",
        "JobTitle": "Rock mechanics technician"
    },
    {
        "value": 1371,
        "NOCCode": "2212",
        "JobTitle": "Seismic technician"
    },
    {
        "value": 1372,
        "NOCCode": "2212",
        "JobTitle": "Welding technologist"
    },
    {
        "value": 1373,
        "NOCCode": "2212",
        "JobTitle": "Laboratory technician - metallurgy"
    },
    {
        "value": 1374,
        "NOCCode": "2221",
        "JobTitle": "Agricultural technician"
    },
    {
        "value": 1375,
        "NOCCode": "2221",
        "JobTitle": "Agricultural technologist"
    },
    {
        "value": 1376,
        "NOCCode": "2221",
        "JobTitle": "Agrology technician"
    },
    {
        "value": 1377,
        "NOCCode": "2221",
        "JobTitle": "Aquaculture technician"
    },
    {
        "value": 1378,
        "NOCCode": "2221",
        "JobTitle": "Bacteriological technician"
    },
    {
        "value": 1379,
        "NOCCode": "2221",
        "JobTitle": "Biological laboratory technologist"
    },
    {
        "value": 1380,
        "NOCCode": "2221",
        "JobTitle": "Botanical technician"
    },
    {
        "value": 1381,
        "NOCCode": "2221",
        "JobTitle": "Fish hatchery technician"
    },
    {
        "value": 1382,
        "NOCCode": "2221",
        "JobTitle": "Fisheries technician"
    },
    {
        "value": 1383,
        "NOCCode": "2221",
        "JobTitle": "Food bacteriological technician"
    },
    {
        "value": 1384,
        "NOCCode": "2221",
        "JobTitle": "Microbiology quality control technologist"
    },
    {
        "value": 1385,
        "NOCCode": "2221",
        "JobTitle": "Microbiology technologist (except medical)"
    },
    {
        "value": 1386,
        "NOCCode": "2221",
        "JobTitle": "Plant breeding technician"
    },
    {
        "value": 1387,
        "NOCCode": "2221",
        "JobTitle": "Seed technologist"
    },
    {
        "value": 1388,
        "NOCCode": "2221",
        "JobTitle": "Wildlife biology technician"
    },
    {
        "value": 1389,
        "NOCCode": "2222",
        "JobTitle": "Agricultural products inspection supervisor"
    },
    {
        "value": 1390,
        "NOCCode": "2222",
        "JobTitle": "Agricultural products inspector"
    },
    {
        "value": 1391,
        "NOCCode": "2222",
        "JobTitle": "Crop certification inspector"
    },
    {
        "value": 1392,
        "NOCCode": "2222",
        "JobTitle": "Dairy products inspector"
    },
    {
        "value": 1393,
        "NOCCode": "2222",
        "JobTitle": "Fish inspector"
    },
    {
        "value": 1394,
        "NOCCode": "2222",
        "JobTitle": "Fish products inspection supervisor"
    },
    {
        "value": 1395,
        "NOCCode": "2222",
        "JobTitle": "Fish products inspector"
    },
    {
        "value": 1396,
        "NOCCode": "2222",
        "JobTitle": "Fruit and vegetables inspector"
    },
    {
        "value": 1397,
        "NOCCode": "2222",
        "JobTitle": "Grain inspector"
    },
    {
        "value": 1398,
        "NOCCode": "2222",
        "JobTitle": "Livestock inspector"
    },
    {
        "value": 1399,
        "NOCCode": "2222",
        "JobTitle": "Meat inspector"
    },
    {
        "value": 1400,
        "NOCCode": "2222",
        "JobTitle": "Plant protection inspector"
    },
    {
        "value": 1401,
        "NOCCode": "2222",
        "JobTitle": "Poultry inspector"
    },
    {
        "value": 1402,
        "NOCCode": "2223",
        "JobTitle": "Conservation technician - forestry"
    },
    {
        "value": 1403,
        "NOCCode": "2223",
        "JobTitle": "Cruising technician - forestry"
    },
    {
        "value": 1404,
        "NOCCode": "2223",
        "JobTitle": "Enforcement officer - forestry"
    },
    {
        "value": 1405,
        "NOCCode": "2223",
        "JobTitle": "Extension ranger - forestry"
    },
    {
        "value": 1406,
        "NOCCode": "2223",
        "JobTitle": "Fire suppression officer - forestry"
    },
    {
        "value": 1407,
        "NOCCode": "2223",
        "JobTitle": "Forest fire technician"
    },
    {
        "value": 1408,
        "NOCCode": "2223",
        "JobTitle": "Forest inventory resource officer"
    },
    {
        "value": 1409,
        "NOCCode": "2223",
        "JobTitle": "Forest survey technician"
    },
    {
        "value": 1410,
        "NOCCode": "2223",
        "JobTitle": "Forest technician"
    },
    {
        "value": 1411,
        "NOCCode": "2223",
        "JobTitle": "Forestry technician"
    },
    {
        "value": 1412,
        "NOCCode": "2223",
        "JobTitle": "Forestry technologist"
    },
    {
        "value": 1413,
        "NOCCode": "2223",
        "JobTitle": "Resource technician - forestry"
    },
    {
        "value": 1414,
        "NOCCode": "2223",
        "JobTitle": "Scaler technician - logging"
    },
    {
        "value": 1415,
        "NOCCode": "2223",
        "JobTitle": "Scaling co-ordinator - logging"
    },
    {
        "value": 1416,
        "NOCCode": "2223",
        "JobTitle": "Silviculture technician"
    },
    {
        "value": 1417,
        "NOCCode": "2223",
        "JobTitle": "Range manager"
    },
    {
        "value": 1418,
        "NOCCode": "2224",
        "JobTitle": "Conservation officer"
    },
    {
        "value": 1419,
        "NOCCode": "2224",
        "JobTitle": "Fish and wildlife officer"
    },
    {
        "value": 1420,
        "NOCCode": "2224",
        "JobTitle": "Fishery officer"
    },
    {
        "value": 1421,
        "NOCCode": "2224",
        "JobTitle": "Forest ranger - wildlife management"
    },
    {
        "value": 1422,
        "NOCCode": "2224",
        "JobTitle": "Game officer"
    },
    {
        "value": 1423,
        "NOCCode": "2224",
        "JobTitle": "Game warden"
    },
    {
        "value": 1424,
        "NOCCode": "2224",
        "JobTitle": "Natural resources officer"
    },
    {
        "value": 1425,
        "NOCCode": "2224",
        "JobTitle": "Park ranger"
    },
    {
        "value": 1426,
        "NOCCode": "2225",
        "JobTitle": "Arborist"
    },
    {
        "value": 1427,
        "NOCCode": "2225",
        "JobTitle": "Golf course superintendent"
    },
    {
        "value": 1428,
        "NOCCode": "2225",
        "JobTitle": "Greenskeeper"
    },
    {
        "value": 1429,
        "NOCCode": "2225",
        "JobTitle": "Horticultural technician"
    },
    {
        "value": 1430,
        "NOCCode": "2225",
        "JobTitle": "Horticulture specialist"
    },
    {
        "value": 1431,
        "NOCCode": "2225",
        "JobTitle": "Horticulturist"
    },
    {
        "value": 1432,
        "NOCCode": "2225",
        "JobTitle": "Hydroponics technician"
    },
    {
        "value": 1433,
        "NOCCode": "2225",
        "JobTitle": "Landscape architectural technician"
    },
    {
        "value": 1434,
        "NOCCode": "2225",
        "JobTitle": "Landscape designer"
    },
    {
        "value": 1435,
        "NOCCode": "2225",
        "JobTitle": "Landscape gardener"
    },
    {
        "value": 1436,
        "NOCCode": "2225",
        "JobTitle": "Landscape technician"
    },
    {
        "value": 1437,
        "NOCCode": "2225",
        "JobTitle": "Landscaper"
    },
    {
        "value": 1438,
        "NOCCode": "2225",
        "JobTitle": "Lawn care specialist"
    },
    {
        "value": 1439,
        "NOCCode": "2225",
        "JobTitle": "Tree service technician"
    },
    {
        "value": 1440,
        "NOCCode": "2231",
        "JobTitle": "Bridge design technician"
    },
    {
        "value": 1441,
        "NOCCode": "2231",
        "JobTitle": "Building materials technician"
    },
    {
        "value": 1442,
        "NOCCode": "2231",
        "JobTitle": "Civil engineering technician"
    },
    {
        "value": 1443,
        "NOCCode": "2231",
        "JobTitle": "Civil engineering technologist"
    },
    {
        "value": 1444,
        "NOCCode": "2231",
        "JobTitle": "Construction specifications writer"
    },
    {
        "value": 1445,
        "NOCCode": "2231",
        "JobTitle": "Construction technologist"
    },
    {
        "value": 1446,
        "NOCCode": "2231",
        "JobTitle": "Foundation technologist"
    },
    {
        "value": 1447,
        "NOCCode": "2231",
        "JobTitle": "Highway technician"
    },
    {
        "value": 1448,
        "NOCCode": "2231",
        "JobTitle": "Municipal engineering assistant"
    },
    {
        "value": 1449,
        "NOCCode": "2231",
        "JobTitle": "Soil technologist - civil engineering"
    },
    {
        "value": 1450,
        "NOCCode": "2231",
        "JobTitle": "Structural design technologist"
    },
    {
        "value": 1451,
        "NOCCode": "2231",
        "JobTitle": "Structural investigator"
    },
    {
        "value": 1452,
        "NOCCode": "2232",
        "JobTitle": "Aeronautical technologist"
    },
    {
        "value": 1453,
        "NOCCode": "2232",
        "JobTitle": "Heating designer"
    },
    {
        "value": 1454,
        "NOCCode": "2232",
        "JobTitle": "Heating, ventilation and air conditioning (HVAC) technologist"
    },
    {
        "value": 1455,
        "NOCCode": "2232",
        "JobTitle": "Machine designer"
    },
    {
        "value": 1456,
        "NOCCode": "2232",
        "JobTitle": "Marine engineering technologist"
    },
    {
        "value": 1457,
        "NOCCode": "2232",
        "JobTitle": "Mechanical engineering technician"
    },
    {
        "value": 1458,
        "NOCCode": "2232",
        "JobTitle": "Mechanical engineering technologist"
    },
    {
        "value": 1459,
        "NOCCode": "2232",
        "JobTitle": "Mechanical technologist"
    },
    {
        "value": 1460,
        "NOCCode": "2232",
        "JobTitle": "Mould designer"
    },
    {
        "value": 1461,
        "NOCCode": "2232",
        "JobTitle": "Thermal station technician"
    },
    {
        "value": 1462,
        "NOCCode": "2232",
        "JobTitle": "Tool and die designer"
    },
    {
        "value": 1463,
        "NOCCode": "2232",
        "JobTitle": "Tool designer"
    },
    {
        "value": 1464,
        "NOCCode": "2233",
        "JobTitle": "Computer-assisted design/computer-assisted manufacturing (CAD/CAM) programmer"
    },
    {
        "value": 1465,
        "NOCCode": "2233",
        "JobTitle": "Industrial engineering technician"
    },
    {
        "value": 1466,
        "NOCCode": "2233",
        "JobTitle": "Industrial engineering technologist"
    },
    {
        "value": 1467,
        "NOCCode": "2233",
        "JobTitle": "Loss prevention technologist - manufacturing"
    },
    {
        "value": 1468,
        "NOCCode": "2233",
        "JobTitle": "Manufacturing technician"
    },
    {
        "value": 1469,
        "NOCCode": "2233",
        "JobTitle": "Manufacturing technologist"
    },
    {
        "value": 1470,
        "NOCCode": "2233",
        "JobTitle": "Planning technician"
    },
    {
        "value": 1471,
        "NOCCode": "2233",
        "JobTitle": "Plastics manufacturing technician"
    },
    {
        "value": 1472,
        "NOCCode": "2233",
        "JobTitle": "Pulp and paper manufacturing technologist"
    },
    {
        "value": 1473,
        "NOCCode": "2233",
        "JobTitle": "Quality assurance technologist"
    },
    {
        "value": 1474,
        "NOCCode": "2233",
        "JobTitle": "Scheduling technician - manufacturing"
    },
    {
        "value": 1475,
        "NOCCode": "2233",
        "JobTitle": "Textile technologist"
    },
    {
        "value": 1476,
        "NOCCode": "2233",
        "JobTitle": "Time study analyst"
    },
    {
        "value": 1477,
        "NOCCode": "2234",
        "JobTitle": "Chief estimator - construction"
    },
    {
        "value": 1478,
        "NOCCode": "2234",
        "JobTitle": "Construction estimator"
    },
    {
        "value": 1479,
        "NOCCode": "2234",
        "JobTitle": "Cost estimator - construction"
    },
    {
        "value": 1480,
        "NOCCode": "2234",
        "JobTitle": "Principal estimator - construction"
    },
    {
        "value": 1481,
        "NOCCode": "2234",
        "JobTitle": "Professional quantity surveyor"
    },
    {
        "value": 1482,
        "NOCCode": "2234",
        "JobTitle": "Quantity surveyor - construction"
    },
    {
        "value": 1483,
        "NOCCode": "2241",
        "JobTitle": "Communications technologist"
    },
    {
        "value": 1484,
        "NOCCode": "2241",
        "JobTitle": "Electrical engineering technician"
    },
    {
        "value": 1485,
        "NOCCode": "2241",
        "JobTitle": "Electrical engineering technologist"
    },
    {
        "value": 1486,
        "NOCCode": "2241",
        "JobTitle": "Electricity distribution network technologist"
    },
    {
        "value": 1487,
        "NOCCode": "2241",
        "JobTitle": "Electronics design technologist"
    },
    {
        "value": 1488,
        "NOCCode": "2241",
        "JobTitle": "Electronics engineering technician"
    },
    {
        "value": 1489,
        "NOCCode": "2241",
        "JobTitle": "Electronics engineering technologist"
    },
    {
        "value": 1490,
        "NOCCode": "2241",
        "JobTitle": "Electronics manufacturing technician"
    },
    {
        "value": 1491,
        "NOCCode": "2241",
        "JobTitle": "Electronics manufacturing technologist"
    },
    {
        "value": 1492,
        "NOCCode": "2241",
        "JobTitle": "Lighting technologist"
    },
    {
        "value": 1493,
        "NOCCode": "2241",
        "JobTitle": "Metering technologist"
    },
    {
        "value": 1494,
        "NOCCode": "2241",
        "JobTitle": "Microwave maintenance technician"
    },
    {
        "value": 1495,
        "NOCCode": "2241",
        "JobTitle": "Production support technician - electronics manufacturing"
    },
    {
        "value": 1496,
        "NOCCode": "2242",
        "JobTitle": "Alarm system technician"
    },
    {
        "value": 1497,
        "NOCCode": "2242",
        "JobTitle": "Audio-video service technician"
    },
    {
        "value": 1498,
        "NOCCode": "2242",
        "JobTitle": "Computer service technician"
    },
    {
        "value": 1499,
        "NOCCode": "2242",
        "JobTitle": "Electronic products field service technician"
    },
    {
        "value": 1500,
        "NOCCode": "2242",
        "JobTitle": "Electronic service technician apprentice"
    },
    {
        "value": 1501,
        "NOCCode": "2242",
        "JobTitle": "Electronic service technician supervisor"
    },
    {
        "value": 1502,
        "NOCCode": "2242",
        "JobTitle": "Office equipment service technician"
    },
    {
        "value": 1503,
        "NOCCode": "2242",
        "JobTitle": "Photocopy machine technician"
    },
    {
        "value": 1504,
        "NOCCode": "2242",
        "JobTitle": "Radio and television service technician"
    },
    {
        "value": 1505,
        "NOCCode": "2242",
        "JobTitle": "Satellite antenna servicer"
    },
    {
        "value": 1506,
        "NOCCode": "2243",
        "JobTitle": "Apprentice industrial instrument mechanic"
    },
    {
        "value": 1507,
        "NOCCode": "2243",
        "JobTitle": "Industrial instrument mechanic"
    },
    {
        "value": 1508,
        "NOCCode": "2243",
        "JobTitle": "Industrial instrument technician"
    },
    {
        "value": 1509,
        "NOCCode": "2243",
        "JobTitle": "Industrial instrumentation technician"
    },
    {
        "value": 1510,
        "NOCCode": "2243",
        "JobTitle": "Process control equipment mechanic"
    },
    {
        "value": 1511,
        "NOCCode": "2244",
        "JobTitle": "Aircraft electrical technician"
    },
    {
        "value": 1512,
        "NOCCode": "2244",
        "JobTitle": "Aircraft electrician"
    },
    {
        "value": 1513,
        "NOCCode": "2244",
        "JobTitle": "Aircraft instrument inspector"
    },
    {
        "value": 1514,
        "NOCCode": "2244",
        "JobTitle": "Aircraft instrument mechanic"
    },
    {
        "value": 1515,
        "NOCCode": "2244",
        "JobTitle": "Aircraft instrument technician"
    },
    {
        "value": 1516,
        "NOCCode": "2244",
        "JobTitle": "Aircraft maintenance engineer (AME) - avionics"
    },
    {
        "value": 1517,
        "NOCCode": "2244",
        "JobTitle": "Avionics inspector"
    },
    {
        "value": 1518,
        "NOCCode": "2244",
        "JobTitle": "Avionics maintenance technician"
    },
    {
        "value": 1519,
        "NOCCode": "2244",
        "JobTitle": "Avionics technician"
    },
    {
        "value": 1520,
        "NOCCode": "2244",
        "JobTitle": "Instrument overhaul and repair mechanic - avionics"
    },
    {
        "value": 1521,
        "NOCCode": "2251",
        "JobTitle": "architectural assistant"
    },
    {
        "value": 1522,
        "NOCCode": "2251",
        "JobTitle": "architectural design technician"
    },
    {
        "value": 1523,
        "NOCCode": "2251",
        "JobTitle": "architectural design technologist"
    },
    {
        "value": 1524,
        "NOCCode": "2251",
        "JobTitle": "architectural technician"
    },
    {
        "value": 1525,
        "NOCCode": "2251",
        "JobTitle": "architectural technologist"
    },
    {
        "value": 1526,
        "NOCCode": "2251",
        "JobTitle": "assistant, architectural"
    },
    {
        "value": 1527,
        "NOCCode": "2251",
        "JobTitle": "registered building technician"
    },
    {
        "value": 1528,
        "NOCCode": "2251",
        "JobTitle": "registered building technologist"
    },
    {
        "value": 1529,
        "NOCCode": "2251",
        "JobTitle": "residential buildings technologist"
    },
    {
        "value": 1530,
        "NOCCode": "2251",
        "JobTitle": "technician, architectural"
    },
    {
        "value": 1531,
        "NOCCode": "2251",
        "JobTitle": "technologist, architectural"
    },
    {
        "value": 1532,
        "NOCCode": "2251",
        "JobTitle": "technologist, residential buildings"
    },
    {
        "value": 1533,
        "NOCCode": "2252",
        "JobTitle": "business products designer"
    },
    {
        "value": 1534,
        "NOCCode": "2252",
        "JobTitle": "consumer products designer"
    },
    {
        "value": 1535,
        "NOCCode": "2252",
        "JobTitle": "container designer"
    },
    {
        "value": 1536,
        "NOCCode": "2252",
        "JobTitle": "designer, furniture"
    },
    {
        "value": 1537,
        "NOCCode": "2252",
        "JobTitle": "designer, industrial"
    },
    {
        "value": 1538,
        "NOCCode": "2252",
        "JobTitle": "designer, industrial products"
    },
    {
        "value": 1539,
        "NOCCode": "2252",
        "JobTitle": "ergonomic products designer"
    },
    {
        "value": 1540,
        "NOCCode": "2252",
        "JobTitle": "fixture designer"
    },
    {
        "value": 1541,
        "NOCCode": "2252",
        "JobTitle": "furniture designer"
    },
    {
        "value": 1542,
        "NOCCode": "2252",
        "JobTitle": "industrial design consultant"
    },
    {
        "value": 1543,
        "NOCCode": "2252",
        "JobTitle": "industrial designer"
    },
    {
        "value": 1544,
        "NOCCode": "2252",
        "JobTitle": "industrial products designer"
    },
    {
        "value": 1545,
        "NOCCode": "2252",
        "JobTitle": "product designer"
    },
    {
        "value": 1546,
        "NOCCode": "2252",
        "JobTitle": "toy designer – industrial design"
    },
    {
        "value": 1547,
        "NOCCode": "2253",
        "JobTitle": "aeronautical draftsperson"
    },
    {
        "value": 1548,
        "NOCCode": "2253",
        "JobTitle": "air conditioning systems draftsperson"
    },
    {
        "value": 1549,
        "NOCCode": "2253",
        "JobTitle": "architectural draftsperson"
    },
    {
        "value": 1550,
        "NOCCode": "2253",
        "JobTitle": "CAD (computer-aided design) specialist – drafting"
    },
    {
        "value": 1551,
        "NOCCode": "2253",
        "JobTitle": "CAD (computer-aided design) technologist"
    },
    {
        "value": 1552,
        "NOCCode": "2253",
        "JobTitle": "CAD (computer-assisted drafting)"
    },
    {
        "value": 1553,
        "NOCCode": "2253",
        "JobTitle": "CAD (computer-assisted drafting) draftsperson – printed circuit boards"
    },
    {
        "value": 1554,
        "NOCCode": "2253",
        "JobTitle": "CAD (computer-assisted drafting) technician"
    },
    {
        "value": 1555,
        "NOCCode": "2253",
        "JobTitle": "CAD operator"
    },
    {
        "value": 1556,
        "NOCCode": "2253",
        "JobTitle": "checker, drafting"
    },
    {
        "value": 1557,
        "NOCCode": "2253",
        "JobTitle": "chief draftsperson"
    },
    {
        "value": 1558,
        "NOCCode": "2253",
        "JobTitle": "civil draftsperson"
    },
    {
        "value": 1559,
        "NOCCode": "2253",
        "JobTitle": "commercial draftsperson"
    },
    {
        "value": 1560,
        "NOCCode": "2253",
        "JobTitle": "computer-aided design (CAD) specialist – drafting"
    },
    {
        "value": 1561,
        "NOCCode": "2253",
        "JobTitle": "computer-aided design (CAD) technologist"
    },
    {
        "value": 1562,
        "NOCCode": "2253",
        "JobTitle": "computer-aided drafting (CAD) technician"
    },
    {
        "value": 1563,
        "NOCCode": "2253",
        "JobTitle": "computer-assisted design and drafting technologist"
    },
    {
        "value": 1564,
        "NOCCode": "2253",
        "JobTitle": "computer-assisted drafting (CAD) technician"
    },
    {
        "value": 1565,
        "NOCCode": "2253",
        "JobTitle": "computer-assisted electromechanical design draftsperson"
    },
    {
        "value": 1566,
        "NOCCode": "2253",
        "JobTitle": "design and drafting technologist"
    },
    {
        "value": 1567,
        "NOCCode": "2253",
        "JobTitle": "design checker, drafting"
    },
    {
        "value": 1568,
        "NOCCode": "2253",
        "JobTitle": "design draftsperson"
    },
    {
        "value": 1569,
        "NOCCode": "2253",
        "JobTitle": "design technician, drafting"
    },
    {
        "value": 1570,
        "NOCCode": "2253",
        "JobTitle": "design technologist – drafting"
    },
    {
        "value": 1571,
        "NOCCode": "2253",
        "JobTitle": "detail draftsperson"
    },
    {
        "value": 1572,
        "NOCCode": "2253",
        "JobTitle": "detailer"
    },
    {
        "value": 1573,
        "NOCCode": "2253",
        "JobTitle": "detailer – structural steel drafter"
    },
    {
        "value": 1574,
        "NOCCode": "2253",
        "JobTitle": "drafter"
    },
    {
        "value": 1575,
        "NOCCode": "2253",
        "JobTitle": "drafting checker"
    },
    {
        "value": 1576,
        "NOCCode": "2253",
        "JobTitle": "drafting clerk"
    },
    {
        "value": 1577,
        "NOCCode": "2253",
        "JobTitle": "drafting supervisor"
    },
    {
        "value": 1578,
        "NOCCode": "2253",
        "JobTitle": "drafting technician"
    },
    {
        "value": 1579,
        "NOCCode": "2253",
        "JobTitle": "drafting technician, design"
    },
    {
        "value": 1580,
        "NOCCode": "2253",
        "JobTitle": "drafting technologist"
    },
    {
        "value": 1581,
        "NOCCode": "2253",
        "JobTitle": "drafting tracer"
    },
    {
        "value": 1582,
        "NOCCode": "2253",
        "JobTitle": "draftsman/woman"
    },
    {
        "value": 1583,
        "NOCCode": "2253",
        "JobTitle": "draftsperson"
    },
    {
        "value": 1584,
        "NOCCode": "2253",
        "JobTitle": "draftsperson – computer-assisted design"
    },
    {
        "value": 1585,
        "NOCCode": "2253",
        "JobTitle": "draftsperson hull – shipbuilding and repair"
    },
    {
        "value": 1586,
        "NOCCode": "2253",
        "JobTitle": "draftsperson, air conditioning systems"
    },
    {
        "value": 1587,
        "NOCCode": "2253",
        "JobTitle": "draftsperson, architectural"
    },
    {
        "value": 1588,
        "NOCCode": "2253",
        "JobTitle": "draftsperson, civil"
    },
    {
        "value": 1589,
        "NOCCode": "2253",
        "JobTitle": "draftsperson, electrical"
    },
    {
        "value": 1590,
        "NOCCode": "2253",
        "JobTitle": "draftsperson, process piping"
    },
    {
        "value": 1591,
        "NOCCode": "2253",
        "JobTitle": "draftspersons supervisor"
    },
    {
        "value": 1592,
        "NOCCode": "2253",
        "JobTitle": "electrical drafting supervisor"
    },
    {
        "value": 1593,
        "NOCCode": "2253",
        "JobTitle": "electrical draftsperson"
    },
    {
        "value": 1594,
        "NOCCode": "2253",
        "JobTitle": "electromechanical draftsperson"
    },
    {
        "value": 1595,
        "NOCCode": "2253",
        "JobTitle": "electronic draftsperson"
    },
    {
        "value": 1596,
        "NOCCode": "2253",
        "JobTitle": "engineering design and drafting technologist"
    },
    {
        "value": 1597,
        "NOCCode": "2253",
        "JobTitle": "engineering draftsperson"
    },
    {
        "value": 1598,
        "NOCCode": "2253",
        "JobTitle": "general draftsperson"
    },
    {
        "value": 1599,
        "NOCCode": "2253",
        "JobTitle": "geological draftsperson"
    },
    {
        "value": 1600,
        "NOCCode": "2253",
        "JobTitle": "geophysical draftsperson"
    },
    {
        "value": 1601,
        "NOCCode": "2253",
        "JobTitle": "group leader, drafting"
    },
    {
        "value": 1602,
        "NOCCode": "2253",
        "JobTitle": "heating and ventilation systems draftsperson"
    },
    {
        "value": 1603,
        "NOCCode": "2253",
        "JobTitle": "heating systems draftsperson"
    },
    {
        "value": 1604,
        "NOCCode": "2253",
        "JobTitle": "hull draftsperson – shipbuilding and repair"
    },
    {
        "value": 1605,
        "NOCCode": "2253",
        "JobTitle": "hydraulic machinery draftsperson"
    },
    {
        "value": 1606,
        "NOCCode": "2253",
        "JobTitle": "illustrator, technical – drafting"
    },
    {
        "value": 1607,
        "NOCCode": "2253",
        "JobTitle": "marine drafting supervisor"
    },
    {
        "value": 1608,
        "NOCCode": "2253",
        "JobTitle": "marine draftsperson, electrical"
    },
    {
        "value": 1609,
        "NOCCode": "2253",
        "JobTitle": "mechanical building draftsperson"
    },
    {
        "value": 1610,
        "NOCCode": "2253",
        "JobTitle": "mechanical design draftsperson"
    },
    {
        "value": 1611,
        "NOCCode": "2253",
        "JobTitle": "mechanical draftsperson"
    },
    {
        "value": 1612,
        "NOCCode": "2253",
        "JobTitle": "mine draftsperson"
    },
    {
        "value": 1613,
        "NOCCode": "2253",
        "JobTitle": "municipal draftsperson"
    },
    {
        "value": 1614,
        "NOCCode": "2253",
        "JobTitle": "optical draftsperson"
    },
    {
        "value": 1615,
        "NOCCode": "2253",
        "JobTitle": "petroleum exploration draftsperson"
    },
    {
        "value": 1616,
        "NOCCode": "2253",
        "JobTitle": "piping draftsperson"
    },
    {
        "value": 1617,
        "NOCCode": "2253",
        "JobTitle": "process piping draftsperson"
    },
    {
        "value": 1618,
        "NOCCode": "2253",
        "JobTitle": "refrigeration systems draftsperson"
    },
    {
        "value": 1619,
        "NOCCode": "2253",
        "JobTitle": "ship detail draftsperson"
    },
    {
        "value": 1620,
        "NOCCode": "2253",
        "JobTitle": "shipbuilding draftsperson"
    },
    {
        "value": 1621,
        "NOCCode": "2253",
        "JobTitle": "steel detailer – drafting"
    },
    {
        "value": 1622,
        "NOCCode": "2253",
        "JobTitle": "steel frame draftsman/woman"
    },
    {
        "value": 1623,
        "NOCCode": "2253",
        "JobTitle": "steel frame draftsperson"
    },
    {
        "value": 1624,
        "NOCCode": "2253",
        "JobTitle": "structural draftsperson"
    },
    {
        "value": 1625,
        "NOCCode": "2253",
        "JobTitle": "structural steel detailer"
    },
    {
        "value": 1626,
        "NOCCode": "2253",
        "JobTitle": "structural steel drafter-detailer"
    },
    {
        "value": 1627,
        "NOCCode": "2253",
        "JobTitle": "structural steel draftsperson"
    },
    {
        "value": 1628,
        "NOCCode": "2253",
        "JobTitle": "supervisor, drafting"
    },
    {
        "value": 1629,
        "NOCCode": "2253",
        "JobTitle": "supervisor, drafting office"
    },
    {
        "value": 1630,
        "NOCCode": "2253",
        "JobTitle": "survey draftsperson"
    },
    {
        "value": 1631,
        "NOCCode": "2253",
        "JobTitle": "technical illustrator – drafting"
    },
    {
        "value": 1632,
        "NOCCode": "2253",
        "JobTitle": "technologist, CAD (computer-aided design)"
    },
    {
        "value": 1633,
        "NOCCode": "2253",
        "JobTitle": "technologist, computer-aided design (CAD)"
    },
    {
        "value": 1634,
        "NOCCode": "2253",
        "JobTitle": "tool checker – drafting"
    },
    {
        "value": 1635,
        "NOCCode": "2253",
        "JobTitle": "tool design draftsperson"
    },
    {
        "value": 1636,
        "NOCCode": "2253",
        "JobTitle": "tracer, drafting"
    },
    {
        "value": 1637,
        "NOCCode": "2254",
        "JobTitle": "engineering survey technician"
    },
    {
        "value": 1638,
        "NOCCode": "2254",
        "JobTitle": "engineering survey technologist"
    },
    {
        "value": 1639,
        "NOCCode": "2254",
        "JobTitle": "geodetic survey technician"
    },
    {
        "value": 1640,
        "NOCCode": "2254",
        "JobTitle": "geodetic survey technologist"
    },
    {
        "value": 1641,
        "NOCCode": "2254",
        "JobTitle": "geomatics technician – land surveying"
    },
    {
        "value": 1642,
        "NOCCode": "2254",
        "JobTitle": "geomatics technologist – land surveying"
    },
    {
        "value": 1643,
        "NOCCode": "2254",
        "JobTitle": "instrument man/woman – surveying"
    },
    {
        "value": 1644,
        "NOCCode": "2254",
        "JobTitle": "land survey technician"
    },
    {
        "value": 1645,
        "NOCCode": "2254",
        "JobTitle": "land survey technologist"
    },
    {
        "value": 1646,
        "NOCCode": "2254",
        "JobTitle": "legal survey assistant"
    },
    {
        "value": 1647,
        "NOCCode": "2254",
        "JobTitle": "legal survey technician"
    },
    {
        "value": 1648,
        "NOCCode": "2254",
        "JobTitle": "legal survey technologist"
    },
    {
        "value": 1649,
        "NOCCode": "2254",
        "JobTitle": "plane table operator – surveying"
    },
    {
        "value": 1650,
        "NOCCode": "2254",
        "JobTitle": "technician, geomatics – land surveying"
    },
    {
        "value": 1651,
        "NOCCode": "2254",
        "JobTitle": "technician, land survey"
    },
    {
        "value": 1652,
        "NOCCode": "2254",
        "JobTitle": "technician, topographic survey"
    },
    {
        "value": 1653,
        "NOCCode": "2254",
        "JobTitle": "technologist, geomatics – land surveying"
    },
    {
        "value": 1654,
        "NOCCode": "2254",
        "JobTitle": "technologist, land survey"
    },
    {
        "value": 1655,
        "NOCCode": "2254",
        "JobTitle": "technologist, topographic survey"
    },
    {
        "value": 1656,
        "NOCCode": "2254",
        "JobTitle": "topographic survey technician"
    },
    {
        "value": 1657,
        "NOCCode": "2254",
        "JobTitle": "topographic survey technologist"
    },
    {
        "value": 1658,
        "NOCCode": "2254",
        "JobTitle": "transit man/woman – surveying"
    },
    {
        "value": 1659,
        "NOCCode": "2254",
        "JobTitle": "transit operator – surveying"
    },
    {
        "value": 1660,
        "NOCCode": "2255",
        "JobTitle": "aerial photograph analyst"
    },
    {
        "value": 1661,
        "NOCCode": "2255",
        "JobTitle": "aerial photograph interpreter"
    },
    {
        "value": 1662,
        "NOCCode": "2255",
        "JobTitle": "aerial photograph technician"
    },
    {
        "value": 1663,
        "NOCCode": "2255",
        "JobTitle": "aerial survey technician"
    },
    {
        "value": 1664,
        "NOCCode": "2255",
        "JobTitle": "aerial survey technologist"
    },
    {
        "value": 1665,
        "NOCCode": "2255",
        "JobTitle": "aerological observer"
    },
    {
        "value": 1666,
        "NOCCode": "2255",
        "JobTitle": "aerological technician"
    },
    {
        "value": 1667,
        "NOCCode": "2255",
        "JobTitle": "airborne data acquisition specialist"
    },
    {
        "value": 1668,
        "NOCCode": "2255",
        "JobTitle": "avalanche controller"
    },
    {
        "value": 1669,
        "NOCCode": "2255",
        "JobTitle": "avalanche observer"
    },
    {
        "value": 1670,
        "NOCCode": "2255",
        "JobTitle": "cartographer"
    },
    {
        "value": 1671,
        "NOCCode": "2255",
        "JobTitle": "cartographic compiler"
    },
    {
        "value": 1672,
        "NOCCode": "2255",
        "JobTitle": "cartographic draftsman/woman"
    },
    {
        "value": 1673,
        "NOCCode": "2255",
        "JobTitle": "cartographic technician"
    },
    {
        "value": 1674,
        "NOCCode": "2255",
        "JobTitle": "cartographic technologist"
    },
    {
        "value": 1675,
        "NOCCode": "2255",
        "JobTitle": "climate data processor"
    },
    {
        "value": 1676,
        "NOCCode": "2255",
        "JobTitle": "climate service technician"
    },
    {
        "value": 1677,
        "NOCCode": "2255",
        "JobTitle": "climatological technician"
    },
    {
        "value": 1678,
        "NOCCode": "2255",
        "JobTitle": "controller, avalanche"
    },
    {
        "value": 1679,
        "NOCCode": "2255",
        "JobTitle": "draftsman, cartographic"
    },
    {
        "value": 1680,
        "NOCCode": "2255",
        "JobTitle": "draftsman/woman, map"
    },
    {
        "value": 1681,
        "NOCCode": "2255",
        "JobTitle": "editor, maps"
    },
    {
        "value": 1682,
        "NOCCode": "2255",
        "JobTitle": "geographic information systems (GIS) technician"
    },
    {
        "value": 1683,
        "NOCCode": "2255",
        "JobTitle": "geographic information systems (GIS) technologist"
    },
    {
        "value": 1684,
        "NOCCode": "2255",
        "JobTitle": "geological information system technician"
    },
    {
        "value": 1685,
        "NOCCode": "2255",
        "JobTitle": "geological information system technologist"
    },
    {
        "value": 1686,
        "NOCCode": "2255",
        "JobTitle": "geological mapping technician"
    },
    {
        "value": 1687,
        "NOCCode": "2255",
        "JobTitle": "geomatics technician – aerial surveys"
    },
    {
        "value": 1688,
        "NOCCode": "2255",
        "JobTitle": "geomatics technician – cartography"
    },
    {
        "value": 1689,
        "NOCCode": "2255",
        "JobTitle": "geomatics technician – geographical information systems (GIS)"
    },
    {
        "value": 1690,
        "NOCCode": "2255",
        "JobTitle": "geomatics technician – photogrammetry"
    },
    {
        "value": 1691,
        "NOCCode": "2255",
        "JobTitle": "geomatics technician – remote sensing"
    },
    {
        "value": 1692,
        "NOCCode": "2255",
        "JobTitle": "geomatics technologist – aerial surveys"
    },
    {
        "value": 1693,
        "NOCCode": "2255",
        "JobTitle": "geomatics technologist – cartography"
    },
    {
        "value": 1694,
        "NOCCode": "2255",
        "JobTitle": "geomatics technologist – geographical information systems"
    },
    {
        "value": 1695,
        "NOCCode": "2255",
        "JobTitle": "geomatics technologist – photogrammetry"
    },
    {
        "value": 1696,
        "NOCCode": "2255",
        "JobTitle": "geomatics technologist – remote sensing"
    },
    {
        "value": 1697,
        "NOCCode": "2255",
        "JobTitle": "GIS (geographic information system) technician"
    },
    {
        "value": 1698,
        "NOCCode": "2255",
        "JobTitle": "GIS (geographic information systems) technologist"
    },
    {
        "value": 1699,
        "NOCCode": "2255",
        "JobTitle": "ice conditions service specialist – meteorology"
    },
    {
        "value": 1700,
        "NOCCode": "2255",
        "JobTitle": "ice observer"
    },
    {
        "value": 1701,
        "NOCCode": "2255",
        "JobTitle": "land information system (LIS) technician"
    },
    {
        "value": 1702,
        "NOCCode": "2255",
        "JobTitle": "land information system (LIS) technologist"
    },
    {
        "value": 1703,
        "NOCCode": "2255",
        "JobTitle": "LIS (land information system) technician"
    },
    {
        "value": 1704,
        "NOCCode": "2255",
        "JobTitle": "LIS (land information system) technologist"
    },
    {
        "value": 1705,
        "NOCCode": "2255",
        "JobTitle": "map draftsman/woman"
    },
    {
        "value": 1706,
        "NOCCode": "2255",
        "JobTitle": "map editor"
    },
    {
        "value": 1707,
        "NOCCode": "2255",
        "JobTitle": "mapping technician"
    },
    {
        "value": 1708,
        "NOCCode": "2255",
        "JobTitle": "meteorological aide"
    },
    {
        "value": 1709,
        "NOCCode": "2255",
        "JobTitle": "meteorological inspector"
    },
    {
        "value": 1710,
        "NOCCode": "2255",
        "JobTitle": "meteorological technician"
    },
    {
        "value": 1711,
        "NOCCode": "2255",
        "JobTitle": "observer, surface weather"
    },
    {
        "value": 1712,
        "NOCCode": "2255",
        "JobTitle": "officer-in-charge, weather station"
    },
    {
        "value": 1713,
        "NOCCode": "2255",
        "JobTitle": "operations technician, weather station"
    },
    {
        "value": 1714,
        "NOCCode": "2255",
        "JobTitle": "photo cartographer"
    },
    {
        "value": 1715,
        "NOCCode": "2255",
        "JobTitle": "photogrammetric technician"
    },
    {
        "value": 1716,
        "NOCCode": "2255",
        "JobTitle": "photogrammetric technologist"
    },
    {
        "value": 1717,
        "NOCCode": "2255",
        "JobTitle": "photogrammetrist"
    },
    {
        "value": 1718,
        "NOCCode": "2255",
        "JobTitle": "remote sensing (RS) application specialist"
    },
    {
        "value": 1719,
        "NOCCode": "2255",
        "JobTitle": "remote sensing (RS) technician"
    },
    {
        "value": 1720,
        "NOCCode": "2255",
        "JobTitle": "remote sensing (RS) technologist"
    },
    {
        "value": 1721,
        "NOCCode": "2255",
        "JobTitle": "RS (remote sensing) application specialist"
    },
    {
        "value": 1722,
        "NOCCode": "2255",
        "JobTitle": "RS (remote sensing) technician"
    },
    {
        "value": 1723,
        "NOCCode": "2255",
        "JobTitle": "RS (remote sensing) technologist"
    },
    {
        "value": 1724,
        "NOCCode": "2255",
        "JobTitle": "snow controller"
    },
    {
        "value": 1725,
        "NOCCode": "2255",
        "JobTitle": "softcopy photogrammetrist"
    },
    {
        "value": 1726,
        "NOCCode": "2255",
        "JobTitle": "specialist, remote sensing (RS) application"
    },
    {
        "value": 1727,
        "NOCCode": "2255",
        "JobTitle": "specialist, RS (remote sensing) application"
    },
    {
        "value": 1728,
        "NOCCode": "2255",
        "JobTitle": "surface weather observer"
    },
    {
        "value": 1729,
        "NOCCode": "2255",
        "JobTitle": "technician, geographic information systems (GIS)"
    },
    {
        "value": 1730,
        "NOCCode": "2255",
        "JobTitle": "technician, geological information system"
    },
    {
        "value": 1731,
        "NOCCode": "2255",
        "JobTitle": "technician, geological mapping"
    },
    {
        "value": 1732,
        "NOCCode": "2255",
        "JobTitle": "technician, geomatics – aerial surveys"
    },
    {
        "value": 1733,
        "NOCCode": "2255",
        "JobTitle": "technician, geomatics – cartography"
    },
    {
        "value": 1734,
        "NOCCode": "2255",
        "JobTitle": "technician, geomatics – geographical information systems (GIS)"
    },
    {
        "value": 1735,
        "NOCCode": "2255",
        "JobTitle": "technician, geomatics – photogrammetry"
    },
    {
        "value": 1736,
        "NOCCode": "2255",
        "JobTitle": "technician, geomatics – remote sensing"
    },
    {
        "value": 1737,
        "NOCCode": "2255",
        "JobTitle": "technician, GIS (geographic information system)"
    },
    {
        "value": 1738,
        "NOCCode": "2255",
        "JobTitle": "technician, land information system (LIS)"
    },
    {
        "value": 1739,
        "NOCCode": "2255",
        "JobTitle": "technician, LIS (land information system)"
    },
    {
        "value": 1740,
        "NOCCode": "2255",
        "JobTitle": "technician, photogrammetric"
    },
    {
        "value": 1741,
        "NOCCode": "2255",
        "JobTitle": "technician, remote sensing (RS)"
    },
    {
        "value": 1742,
        "NOCCode": "2255",
        "JobTitle": "technician, RS (remote sensing)"
    },
    {
        "value": 1743,
        "NOCCode": "2255",
        "JobTitle": "technologist, geographic information systems (GIS)"
    },
    {
        "value": 1744,
        "NOCCode": "2255",
        "JobTitle": "technologist, geological information system"
    },
    {
        "value": 1745,
        "NOCCode": "2255",
        "JobTitle": "technologist, geomatics – aerial surveys"
    },
    {
        "value": 1746,
        "NOCCode": "2255",
        "JobTitle": "technologist, geomatics – cartography"
    },
    {
        "value": 1747,
        "NOCCode": "2255",
        "JobTitle": "technologist, geomatics – geographical information systems"
    },
    {
        "value": 1748,
        "NOCCode": "2255",
        "JobTitle": "technologist, geomatics – photogrammetry"
    },
    {
        "value": 1749,
        "NOCCode": "2255",
        "JobTitle": "technologist, geomatics – remote sensing"
    },
    {
        "value": 1750,
        "NOCCode": "2255",
        "JobTitle": "technologist, GIS (geographic information systems)"
    },
    {
        "value": 1751,
        "NOCCode": "2255",
        "JobTitle": "technologist, land information system (LIS)"
    },
    {
        "value": 1752,
        "NOCCode": "2255",
        "JobTitle": "technologist, LIS (land information system)"
    },
    {
        "value": 1753,
        "NOCCode": "2255",
        "JobTitle": "technologist, photogrammetric"
    },
    {
        "value": 1754,
        "NOCCode": "2255",
        "JobTitle": "technologist, remote sensing (RS)"
    },
    {
        "value": 1755,
        "NOCCode": "2255",
        "JobTitle": "technologist, RS (remote sensing)"
    },
    {
        "value": 1756,
        "NOCCode": "2255",
        "JobTitle": "weather briefer"
    },
    {
        "value": 1757,
        "NOCCode": "2255",
        "JobTitle": "weather chart plotter"
    },
    {
        "value": 1758,
        "NOCCode": "2255",
        "JobTitle": "weather forecast presentation technician"
    },
    {
        "value": 1759,
        "NOCCode": "2261",
        "JobTitle": "acoustic emission technician"
    },
    {
        "value": 1760,
        "NOCCode": "2261",
        "JobTitle": "acoustic emission tester"
    },
    {
        "value": 1761,
        "NOCCode": "2261",
        "JobTitle": "aircraft non-destructive inspection technician"
    },
    {
        "value": 1762,
        "NOCCode": "2261",
        "JobTitle": "black light technician – non-destructive testing"
    },
    {
        "value": 1763,
        "NOCCode": "2261",
        "JobTitle": "boiler tester"
    },
    {
        "value": 1764,
        "NOCCode": "2261",
        "JobTitle": "dye penetrant technician"
    },
    {
        "value": 1765,
        "NOCCode": "2261",
        "JobTitle": "dye penetrant tester"
    },
    {
        "value": 1766,
        "NOCCode": "2261",
        "JobTitle": "eddy current technician"
    },
    {
        "value": 1767,
        "NOCCode": "2261",
        "JobTitle": "eddy current tester"
    },
    {
        "value": 1768,
        "NOCCode": "2261",
        "JobTitle": "fluorescent penetrant testing technician"
    },
    {
        "value": 1769,
        "NOCCode": "2261",
        "JobTitle": "fluoroscope tester"
    },
    {
        "value": 1770,
        "NOCCode": "2261",
        "JobTitle": "forging tester"
    },
    {
        "value": 1771,
        "NOCCode": "2261",
        "JobTitle": "hydrostatic tester"
    },
    {
        "value": 1772,
        "NOCCode": "2261",
        "JobTitle": "industrial radiographer"
    },
    {
        "value": 1773,
        "NOCCode": "2261",
        "JobTitle": "industrial radiographer – radiotelephony"
    },
    {
        "value": 1774,
        "NOCCode": "2261",
        "JobTitle": "infrared technician"
    },
    {
        "value": 1775,
        "NOCCode": "2261",
        "JobTitle": "infrared tester"
    },
    {
        "value": 1776,
        "NOCCode": "2261",
        "JobTitle": "infrared thermographer"
    },
    {
        "value": 1777,
        "NOCCode": "2261",
        "JobTitle": "infrared thermography technician"
    },
    {
        "value": 1778,
        "NOCCode": "2261",
        "JobTitle": "liquid penetrant technician – non-destructive testing"
    },
    {
        "value": 1779,
        "NOCCode": "2261",
        "JobTitle": "liquid penetrant testing technician"
    },
    {
        "value": 1780,
        "NOCCode": "2261",
        "JobTitle": "magnetic particle technician"
    },
    {
        "value": 1781,
        "NOCCode": "2261",
        "JobTitle": "magnetic particle tester"
    },
    {
        "value": 1782,
        "NOCCode": "2261",
        "JobTitle": "magnetic particle testing technician"
    },
    {
        "value": 1783,
        "NOCCode": "2261",
        "JobTitle": "maintenance technician – non-destructive testing (NDT)"
    },
    {
        "value": 1784,
        "NOCCode": "2261",
        "JobTitle": "NDT (non-destructive testing) maintenance technician"
    },
    {
        "value": 1785,
        "NOCCode": "2261",
        "JobTitle": "non-destructive examination technician"
    },
    {
        "value": 1786,
        "NOCCode": "2261",
        "JobTitle": "non-destructive examiner"
    },
    {
        "value": 1787,
        "NOCCode": "2261",
        "JobTitle": "non-destructive inspection technician"
    },
    {
        "value": 1788,
        "NOCCode": "2261",
        "JobTitle": "non-destructive inspection tester"
    },
    {
        "value": 1789,
        "NOCCode": "2261",
        "JobTitle": "non-destructive inspector"
    },
    {
        "value": 1790,
        "NOCCode": "2261",
        "JobTitle": "non-destructive test technician"
    },
    {
        "value": 1791,
        "NOCCode": "2261",
        "JobTitle": "non-destructive tester"
    },
    {
        "value": 1792,
        "NOCCode": "2261",
        "JobTitle": "non-destructive testing maintenance (NDT) technician"
    },
    {
        "value": 1793,
        "NOCCode": "2261",
        "JobTitle": "non-destructive testing trainee"
    },
    {
        "value": 1794,
        "NOCCode": "2261",
        "JobTitle": "pipe tester – non-destructive testing"
    },
    {
        "value": 1795,
        "NOCCode": "2261",
        "JobTitle": "pipeline testing technician – non-destructive testing"
    },
    {
        "value": 1796,
        "NOCCode": "2261",
        "JobTitle": "pressure tank tester"
    },
    {
        "value": 1797,
        "NOCCode": "2261",
        "JobTitle": "pressure vessel tester"
    },
    {
        "value": 1798,
        "NOCCode": "2261",
        "JobTitle": "qualified operator, non-destructive testing"
    },
    {
        "value": 1799,
        "NOCCode": "2261",
        "JobTitle": "radiographer, industrial"
    },
    {
        "value": 1800,
        "NOCCode": "2261",
        "JobTitle": "radiographic technician – non-destructive testing"
    },
    {
        "value": 1801,
        "NOCCode": "2261",
        "JobTitle": "radiographic tester"
    },
    {
        "value": 1802,
        "NOCCode": "2261",
        "JobTitle": "reflectoscope tester"
    },
    {
        "value": 1803,
        "NOCCode": "2261",
        "JobTitle": "technician, NDT (non-destructive testing) maintenance"
    },
    {
        "value": 1804,
        "NOCCode": "2261",
        "JobTitle": "technician, non-destructive examinations"
    },
    {
        "value": 1805,
        "NOCCode": "2261",
        "JobTitle": "technician, non-destructive inspection"
    },
    {
        "value": 1806,
        "NOCCode": "2261",
        "JobTitle": "tester, boiler"
    },
    {
        "value": 1807,
        "NOCCode": "2261",
        "JobTitle": "tester, non-destructive inspection"
    },
    {
        "value": 1808,
        "NOCCode": "2261",
        "JobTitle": "testing technician, fluorescent penetrant"
    },
    {
        "value": 1809,
        "NOCCode": "2261",
        "JobTitle": "testing technician, ultraviolet light"
    },
    {
        "value": 1810,
        "NOCCode": "2261",
        "JobTitle": "thermographer-tester"
    },
    {
        "value": 1811,
        "NOCCode": "2261",
        "JobTitle": "trainee, non-destructive testing"
    },
    {
        "value": 1812,
        "NOCCode": "2261",
        "JobTitle": "ultrasonic immersion tester"
    },
    {
        "value": 1813,
        "NOCCode": "2261",
        "JobTitle": "ultrasonic technician – non-destructive testing"
    },
    {
        "value": 1814,
        "NOCCode": "2261",
        "JobTitle": "ultrasonic tester"
    },
    {
        "value": 1815,
        "NOCCode": "2261",
        "JobTitle": "ultrasonic testing technician"
    },
    {
        "value": 1816,
        "NOCCode": "2261",
        "JobTitle": "ultraviolet light testing technician"
    },
    {
        "value": 1817,
        "NOCCode": "2261",
        "JobTitle": "vibration analysis technician – non-destructive testing"
    },
    {
        "value": 1818,
        "NOCCode": "2261",
        "JobTitle": "visual inspection technician – welding"
    },
    {
        "value": 1819,
        "NOCCode": "2261",
        "JobTitle": "weld tester"
    },
    {
        "value": 1820,
        "NOCCode": "2262",
        "JobTitle": "airworthiness inspector"
    },
    {
        "value": 1821,
        "NOCCode": "2262",
        "JobTitle": "ammunition safety inspector"
    },
    {
        "value": 1822,
        "NOCCode": "2262",
        "JobTitle": "boiler and machinery inspector"
    },
    {
        "value": 1823,
        "NOCCode": "2262",
        "JobTitle": "boiler inspector"
    },
    {
        "value": 1824,
        "NOCCode": "2262",
        "JobTitle": "broadcast interference inspector"
    },
    {
        "value": 1825,
        "NOCCode": "2262",
        "JobTitle": "cargo surveyor"
    },
    {
        "value": 1826,
        "NOCCode": "2262",
        "JobTitle": "consumer affairs inspector"
    },
    {
        "value": 1827,
        "NOCCode": "2262",
        "JobTitle": "crane inspector"
    },
    {
        "value": 1828,
        "NOCCode": "2262",
        "JobTitle": "electricity and gas meter inspector"
    },
    {
        "value": 1829,
        "NOCCode": "2262",
        "JobTitle": "elevator inspector"
    },
    {
        "value": 1830,
        "NOCCode": "2262",
        "JobTitle": "engineering department inspector"
    },
    {
        "value": 1831,
        "NOCCode": "2262",
        "JobTitle": "engineering department inspector – local government"
    },
    {
        "value": 1832,
        "NOCCode": "2262",
        "JobTitle": "engineering inspector"
    },
    {
        "value": 1833,
        "NOCCode": "2262",
        "JobTitle": "escalator inspector"
    },
    {
        "value": 1834,
        "NOCCode": "2262",
        "JobTitle": "flight operations inspector"
    },
    {
        "value": 1835,
        "NOCCode": "2262",
        "JobTitle": "freight car inspector"
    },
    {
        "value": 1836,
        "NOCCode": "2262",
        "JobTitle": "inspector, air carrier maintenance"
    },
    {
        "value": 1837,
        "NOCCode": "2262",
        "JobTitle": "inspector, air transport"
    },
    {
        "value": 1838,
        "NOCCode": "2262",
        "JobTitle": "inspector, electricity and gas meters"
    },
    {
        "value": 1839,
        "NOCCode": "2262",
        "JobTitle": "inspector, engineering department"
    },
    {
        "value": 1840,
        "NOCCode": "2262",
        "JobTitle": "inspector, weights and measures"
    },
    {
        "value": 1841,
        "NOCCode": "2262",
        "JobTitle": "insurance loss prevention inspector"
    },
    {
        "value": 1842,
        "NOCCode": "2262",
        "JobTitle": "lifting gear inspector"
    },
    {
        "value": 1843,
        "NOCCode": "2262",
        "JobTitle": "loss prevention inspector – insurance"
    },
    {
        "value": 1844,
        "NOCCode": "2262",
        "JobTitle": "marine cargo surveyor"
    },
    {
        "value": 1845,
        "NOCCode": "2262",
        "JobTitle": "marine damage surveyor"
    },
    {
        "value": 1846,
        "NOCCode": "2262",
        "JobTitle": "marine surveyor"
    },
    {
        "value": 1847,
        "NOCCode": "2262",
        "JobTitle": "motor transportation inspector"
    },
    {
        "value": 1848,
        "NOCCode": "2262",
        "JobTitle": "motor transportation regulations officer"
    },
    {
        "value": 1849,
        "NOCCode": "2262",
        "JobTitle": "motor vehicle defects investigator"
    },
    {
        "value": 1850,
        "NOCCode": "2262",
        "JobTitle": "motor vehicle inspector"
    },
    {
        "value": 1851,
        "NOCCode": "2262",
        "JobTitle": "pipeline equipment inspector"
    },
    {
        "value": 1852,
        "NOCCode": "2262",
        "JobTitle": "pipeline inspector"
    },
    {
        "value": 1853,
        "NOCCode": "2262",
        "JobTitle": "quality assurance officer"
    },
    {
        "value": 1854,
        "NOCCode": "2262",
        "JobTitle": "radio interference investigator"
    },
    {
        "value": 1855,
        "NOCCode": "2262",
        "JobTitle": "railway accident investigation officer"
    },
    {
        "value": 1856,
        "NOCCode": "2262",
        "JobTitle": "railway inspector"
    },
    {
        "value": 1857,
        "NOCCode": "2262",
        "JobTitle": "regulatory officer, engineering"
    },
    {
        "value": 1858,
        "NOCCode": "2262",
        "JobTitle": "road safety inspector"
    },
    {
        "value": 1859,
        "NOCCode": "2262",
        "JobTitle": "ship inspector"
    },
    {
        "value": 1860,
        "NOCCode": "2262",
        "JobTitle": "surveyor, cargo"
    },
    {
        "value": 1861,
        "NOCCode": "2262",
        "JobTitle": "surveyor, marine cargo"
    },
    {
        "value": 1862,
        "NOCCode": "2262",
        "JobTitle": "tensile strength inspector"
    },
    {
        "value": 1863,
        "NOCCode": "2263",
        "JobTitle": "beauty salon inspector"
    },
    {
        "value": 1864,
        "NOCCode": "2263",
        "JobTitle": "dangerous goods inspector – railway transport"
    },
    {
        "value": 1865,
        "NOCCode": "2263",
        "JobTitle": "dangerous waste inspector"
    },
    {
        "value": 1866,
        "NOCCode": "2263",
        "JobTitle": "enforcement officer – environmental health"
    },
    {
        "value": 1867,
        "NOCCode": "2263",
        "JobTitle": "environmental health and safety technician"
    },
    {
        "value": 1868,
        "NOCCode": "2263",
        "JobTitle": "environmental health and safety technologist"
    },
    {
        "value": 1869,
        "NOCCode": "2263",
        "JobTitle": "environmental health inspector"
    },
    {
        "value": 1870,
        "NOCCode": "2263",
        "JobTitle": "environmental health inspectors supervisor"
    },
    {
        "value": 1871,
        "NOCCode": "2263",
        "JobTitle": "environmental health officer"
    },
    {
        "value": 1872,
        "NOCCode": "2263",
        "JobTitle": "environmental health officers supervisor"
    },
    {
        "value": 1873,
        "NOCCode": "2263",
        "JobTitle": "environmental officer"
    },
    {
        "value": 1874,
        "NOCCode": "2263",
        "JobTitle": "examiner, mines"
    },
    {
        "value": 1875,
        "NOCCode": "2263",
        "JobTitle": "field supervisor – occupational health and safety"
    },
    {
        "value": 1876,
        "NOCCode": "2263",
        "JobTitle": "flood damage inspector"
    },
    {
        "value": 1877,
        "NOCCode": "2263",
        "JobTitle": "food inspector"
    },
    {
        "value": 1878,
        "NOCCode": "2263",
        "JobTitle": "hazardous waste inspector"
    },
    {
        "value": 1879,
        "NOCCode": "2263",
        "JobTitle": "hazardous waste inspector – environmental health"
    },
    {
        "value": 1880,
        "NOCCode": "2263",
        "JobTitle": "hazardous waste management technologist"
    },
    {
        "value": 1881,
        "NOCCode": "2263",
        "JobTitle": "health and safety inspector"
    },
    {
        "value": 1882,
        "NOCCode": "2263",
        "JobTitle": "health and safety officer"
    },
    {
        "value": 1883,
        "NOCCode": "2263",
        "JobTitle": "health and safety technician"
    },
    {
        "value": 1884,
        "NOCCode": "2263",
        "JobTitle": "health and safety technologist"
    },
    {
        "value": 1885,
        "NOCCode": "2263",
        "JobTitle": "health officers supervisor"
    },
    {
        "value": 1886,
        "NOCCode": "2263",
        "JobTitle": "health standards inspector"
    },
    {
        "value": 1887,
        "NOCCode": "2263",
        "JobTitle": "hotel and restaurant inspector – public health"
    },
    {
        "value": 1888,
        "NOCCode": "2263",
        "JobTitle": "industrial health officer"
    },
    {
        "value": 1889,
        "NOCCode": "2263",
        "JobTitle": "industrial hygiene inspector"
    },
    {
        "value": 1890,
        "NOCCode": "2263",
        "JobTitle": "industrial safety officer – occupational health and safety"
    },
    {
        "value": 1891,
        "NOCCode": "2263",
        "JobTitle": "industrial waste control inspector"
    },
    {
        "value": 1892,
        "NOCCode": "2263",
        "JobTitle": "inspector, dangerous waste"
    },
    {
        "value": 1893,
        "NOCCode": "2263",
        "JobTitle": "inspector, environmental health"
    },
    {
        "value": 1894,
        "NOCCode": "2263",
        "JobTitle": "inspector, flood damages"
    },
    {
        "value": 1895,
        "NOCCode": "2263",
        "JobTitle": "inspector, hazardous waste"
    },
    {
        "value": 1896,
        "NOCCode": "2263",
        "JobTitle": "inspector, hazardous waste – environmental health"
    },
    {
        "value": 1897,
        "NOCCode": "2263",
        "JobTitle": "inspector, health standards"
    },
    {
        "value": 1898,
        "NOCCode": "2263",
        "JobTitle": "inspector, hotels and restaurants – public health"
    },
    {
        "value": 1899,
        "NOCCode": "2263",
        "JobTitle": "inspector, industrial hygiene"
    },
    {
        "value": 1900,
        "NOCCode": "2263",
        "JobTitle": "inspector, industrial waste control"
    },
    {
        "value": 1901,
        "NOCCode": "2263",
        "JobTitle": "inspector, nursing homes"
    },
    {
        "value": 1902,
        "NOCCode": "2263",
        "JobTitle": "inspector, occupational health and safety"
    },
    {
        "value": 1903,
        "NOCCode": "2263",
        "JobTitle": "inspector, occupational hygiene"
    },
    {
        "value": 1904,
        "NOCCode": "2263",
        "JobTitle": "inspector, occupational safety"
    },
    {
        "value": 1905,
        "NOCCode": "2263",
        "JobTitle": "inspector, pollution control"
    },
    {
        "value": 1906,
        "NOCCode": "2263",
        "JobTitle": "inspector, public health"
    },
    {
        "value": 1907,
        "NOCCode": "2263",
        "JobTitle": "inspector, sanitary department – environmental health"
    },
    {
        "value": 1908,
        "NOCCode": "2263",
        "JobTitle": "inspector, sanitary standards"
    },
    {
        "value": 1909,
        "NOCCode": "2263",
        "JobTitle": "inspector, sewage disposal – environmental health"
    },
    {
        "value": 1910,
        "NOCCode": "2263",
        "JobTitle": "inspector, water"
    },
    {
        "value": 1911,
        "NOCCode": "2263",
        "JobTitle": "labour affairs officer – occupational health and safety"
    },
    {
        "value": 1912,
        "NOCCode": "2263",
        "JobTitle": "labour standards officer"
    },
    {
        "value": 1913,
        "NOCCode": "2263",
        "JobTitle": "labour standards officer – occupational health and safety"
    },
    {
        "value": 1914,
        "NOCCode": "2263",
        "JobTitle": "mine examiner"
    },
    {
        "value": 1915,
        "NOCCode": "2263",
        "JobTitle": "mine safety inspector"
    },
    {
        "value": 1916,
        "NOCCode": "2263",
        "JobTitle": "nursing home inspector"
    },
    {
        "value": 1917,
        "NOCCode": "2263",
        "JobTitle": "occupational health and safety officer"
    },
    {
        "value": 1918,
        "NOCCode": "2263",
        "JobTitle": "occupational health inspector"
    },
    {
        "value": 1919,
        "NOCCode": "2263",
        "JobTitle": "occupational health officer"
    },
    {
        "value": 1920,
        "NOCCode": "2263",
        "JobTitle": "occupational health supervisor"
    },
    {
        "value": 1921,
        "NOCCode": "2263",
        "JobTitle": "occupational hygiene inspector"
    },
    {
        "value": 1922,
        "NOCCode": "2263",
        "JobTitle": "occupational hygiene technician"
    },
    {
        "value": 1923,
        "NOCCode": "2263",
        "JobTitle": "occupational hygienist technologist"
    },
    {
        "value": 1924,
        "NOCCode": "2263",
        "JobTitle": "occupational safety inspector"
    },
    {
        "value": 1925,
        "NOCCode": "2263",
        "JobTitle": "occupational safety officer"
    },
    {
        "value": 1926,
        "NOCCode": "2263",
        "JobTitle": "officer, environmental health"
    },
    {
        "value": 1927,
        "NOCCode": "2263",
        "JobTitle": "officer, health and safety"
    },
    {
        "value": 1928,
        "NOCCode": "2263",
        "JobTitle": "officer, industrial health"
    },
    {
        "value": 1929,
        "NOCCode": "2263",
        "JobTitle": "officer, labour standards"
    },
    {
        "value": 1930,
        "NOCCode": "2263",
        "JobTitle": "officer, occupational health"
    },
    {
        "value": 1931,
        "NOCCode": "2263",
        "JobTitle": "officer, occupational health and safety"
    },
    {
        "value": 1932,
        "NOCCode": "2263",
        "JobTitle": "officer, occupational safety"
    },
    {
        "value": 1933,
        "NOCCode": "2263",
        "JobTitle": "officer, safety and health"
    },
    {
        "value": 1934,
        "NOCCode": "2263",
        "JobTitle": "pollution control inspector"
    },
    {
        "value": 1935,
        "NOCCode": "2263",
        "JobTitle": "public health inspector"
    },
    {
        "value": 1936,
        "NOCCode": "2263",
        "JobTitle": "public health safety inspector"
    },
    {
        "value": 1937,
        "NOCCode": "2263",
        "JobTitle": "public health unit supervisor"
    },
    {
        "value": 1938,
        "NOCCode": "2263",
        "JobTitle": "quarantine officer – public health"
    },
    {
        "value": 1939,
        "NOCCode": "2263",
        "JobTitle": "radiation monitor"
    },
    {
        "value": 1940,
        "NOCCode": "2263",
        "JobTitle": "restaurant inspector"
    },
    {
        "value": 1941,
        "NOCCode": "2263",
        "JobTitle": "restaurant inspector – public health"
    },
    {
        "value": 1942,
        "NOCCode": "2263",
        "JobTitle": "rodent control inspector"
    },
    {
        "value": 1943,
        "NOCCode": "2263",
        "JobTitle": "rodent control officer"
    },
    {
        "value": 1944,
        "NOCCode": "2263",
        "JobTitle": "safety and sanitary inspector – public and environmental health"
    },
    {
        "value": 1945,
        "NOCCode": "2263",
        "JobTitle": "safety inspector, mines"
    },
    {
        "value": 1946,
        "NOCCode": "2263",
        "JobTitle": "safety officer"
    },
    {
        "value": 1947,
        "NOCCode": "2263",
        "JobTitle": "safety practitioner"
    },
    {
        "value": 1948,
        "NOCCode": "2263",
        "JobTitle": "sanitary department inspector – environmental health"
    },
    {
        "value": 1949,
        "NOCCode": "2263",
        "JobTitle": "sanitary standards inspector"
    },
    {
        "value": 1950,
        "NOCCode": "2263",
        "JobTitle": "sewage disposal inspector – environmental health"
    },
    {
        "value": 1951,
        "NOCCode": "2263",
        "JobTitle": "supervisor, health officers"
    },
    {
        "value": 1952,
        "NOCCode": "2263",
        "JobTitle": "supervisor, occupational health"
    },
    {
        "value": 1953,
        "NOCCode": "2263",
        "JobTitle": "supervisor, public health inspectors"
    },
    {
        "value": 1954,
        "NOCCode": "2263",
        "JobTitle": "waste water treatment plant inspector"
    },
    {
        "value": 1955,
        "NOCCode": "2263",
        "JobTitle": "water inspector"
    },
    {
        "value": 1956,
        "NOCCode": "2263",
        "JobTitle": "welfare institute inspector – public health"
    },
    {
        "value": 1957,
        "NOCCode": "2264",
        "JobTitle": "boiler installation inspector"
    },
    {
        "value": 1958,
        "NOCCode": "2264",
        "JobTitle": "bridge and building inspector"
    },
    {
        "value": 1959,
        "NOCCode": "2264",
        "JobTitle": "bridge inspector"
    },
    {
        "value": 1960,
        "NOCCode": "2264",
        "JobTitle": "bridge inspector – construction and maintenance"
    },
    {
        "value": 1961,
        "NOCCode": "2264",
        "JobTitle": "building construction inspector"
    },
    {
        "value": 1962,
        "NOCCode": "2264",
        "JobTitle": "building inspector"
    },
    {
        "value": 1963,
        "NOCCode": "2264",
        "JobTitle": "building inspector – electrical wiring"
    },
    {
        "value": 1964,
        "NOCCode": "2264",
        "JobTitle": "building inspector – structure"
    },
    {
        "value": 1965,
        "NOCCode": "2264",
        "JobTitle": "building safety inspector"
    },
    {
        "value": 1966,
        "NOCCode": "2264",
        "JobTitle": "Canada Mortgage and Housing Corporation (CMHC) inspector"
    },
    {
        "value": 1967,
        "NOCCode": "2264",
        "JobTitle": "carpentry inspector"
    },
    {
        "value": 1968,
        "NOCCode": "2264",
        "JobTitle": "closed circuit TV sewer inspector"
    },
    {
        "value": 1969,
        "NOCCode": "2264",
        "JobTitle": "CMHC (Canada Mortgage and Housing Corporation) inspector"
    },
    {
        "value": 1970,
        "NOCCode": "2264",
        "JobTitle": "compliance inspector – buildings"
    },
    {
        "value": 1971,
        "NOCCode": "2264",
        "JobTitle": "construction field inspector"
    },
    {
        "value": 1972,
        "NOCCode": "2264",
        "JobTitle": "construction inspector"
    },
    {
        "value": 1973,
        "NOCCode": "2264",
        "JobTitle": "construction inspector and tester"
    },
    {
        "value": 1974,
        "NOCCode": "2264",
        "JobTitle": "construction safety officer"
    },
    {
        "value": 1975,
        "NOCCode": "2264",
        "JobTitle": "dam construction inspector"
    },
    {
        "value": 1976,
        "NOCCode": "2264",
        "JobTitle": "ditch construction inspector"
    },
    {
        "value": 1977,
        "NOCCode": "2264",
        "JobTitle": "electrical energy installation inspector"
    },
    {
        "value": 1978,
        "NOCCode": "2264",
        "JobTitle": "electrical inspector – construction"
    },
    {
        "value": 1979,
        "NOCCode": "2264",
        "JobTitle": "electrical installation inspector"
    },
    {
        "value": 1980,
        "NOCCode": "2264",
        "JobTitle": "electrical safety inspector"
    },
    {
        "value": 1981,
        "NOCCode": "2264",
        "JobTitle": "electrical wiring inspector – construction"
    },
    {
        "value": 1982,
        "NOCCode": "2264",
        "JobTitle": "flood damage inspector – construction sites"
    },
    {
        "value": 1983,
        "NOCCode": "2264",
        "JobTitle": "foreman/woman, building construction inspectors"
    },
    {
        "value": 1984,
        "NOCCode": "2264",
        "JobTitle": "foreman/woman, highway construction inspectors"
    },
    {
        "value": 1985,
        "NOCCode": "2264",
        "JobTitle": "foreman/woman, pipeline construction inspector"
    },
    {
        "value": 1986,
        "NOCCode": "2264",
        "JobTitle": "foreman/woman, pipeline inspectors"
    },
    {
        "value": 1987,
        "NOCCode": "2264",
        "JobTitle": "highway construction inspector"
    },
    {
        "value": 1988,
        "NOCCode": "2264",
        "JobTitle": "home construction inspector"
    },
    {
        "value": 1989,
        "NOCCode": "2264",
        "JobTitle": "home inspector"
    },
    {
        "value": 1990,
        "NOCCode": "2264",
        "JobTitle": "housing construction inspector"
    },
    {
        "value": 1991,
        "NOCCode": "2264",
        "JobTitle": "housing rehabilitation inspector"
    },
    {
        "value": 1992,
        "NOCCode": "2264",
        "JobTitle": "industrial construction safety inspector"
    },
    {
        "value": 1993,
        "NOCCode": "2264",
        "JobTitle": "industrial electric installation inspector"
    },
    {
        "value": 1994,
        "NOCCode": "2264",
        "JobTitle": "inspector and tester, construction"
    },
    {
        "value": 1995,
        "NOCCode": "2264",
        "JobTitle": "inspector, boiler installation"
    },
    {
        "value": 1996,
        "NOCCode": "2264",
        "JobTitle": "inspector, bridges"
    },
    {
        "value": 1997,
        "NOCCode": "2264",
        "JobTitle": "inspector, bridges – construction and maintenance"
    },
    {
        "value": 1998,
        "NOCCode": "2264",
        "JobTitle": "inspector, bridges and buildings"
    },
    {
        "value": 1999,
        "NOCCode": "2264",
        "JobTitle": "inspector, building construction"
    },
    {
        "value": 2000,
        "NOCCode": "2264",
        "JobTitle": "inspector, building safety"
    },
    {
        "value": 2001,
        "NOCCode": "2264",
        "JobTitle": "inspector, buildings"
    },
    {
        "value": 2002,
        "NOCCode": "2264",
        "JobTitle": "inspector, buildings – electrical wiring"
    },
    {
        "value": 2003,
        "NOCCode": "2264",
        "JobTitle": "inspector, buildings – structure"
    },
    {
        "value": 2004,
        "NOCCode": "2264",
        "JobTitle": "inspector, Canada Mortgage and Housing Corporation (CMHC)"
    },
    {
        "value": 2005,
        "NOCCode": "2264",
        "JobTitle": "inspector, CMHC (Canada Mortgage and Housing Corporation)"
    },
    {
        "value": 2006,
        "NOCCode": "2264",
        "JobTitle": "inspector, compliance – buildings"
    },
    {
        "value": 2007,
        "NOCCode": "2264",
        "JobTitle": "inspector, construction"
    },
    {
        "value": 2008,
        "NOCCode": "2264",
        "JobTitle": "inspector, construction fields"
    },
    {
        "value": 2009,
        "NOCCode": "2264",
        "JobTitle": "inspector, dam construction"
    },
    {
        "value": 2010,
        "NOCCode": "2264",
        "JobTitle": "inspector, ditch construction"
    },
    {
        "value": 2011,
        "NOCCode": "2264",
        "JobTitle": "inspector, electrical energy installation"
    },
    {
        "value": 2012,
        "NOCCode": "2264",
        "JobTitle": "inspector, electrical installation"
    },
    {
        "value": 2013,
        "NOCCode": "2264",
        "JobTitle": "inspector, electrical safety"
    },
    {
        "value": 2014,
        "NOCCode": "2264",
        "JobTitle": "inspector, electrical wiring – construction"
    },
    {
        "value": 2015,
        "NOCCode": "2264",
        "JobTitle": "inspector, highway construction"
    },
    {
        "value": 2016,
        "NOCCode": "2264",
        "JobTitle": "inspector, home construction"
    },
    {
        "value": 2017,
        "NOCCode": "2264",
        "JobTitle": "inspector, homes"
    },
    {
        "value": 2018,
        "NOCCode": "2264",
        "JobTitle": "inspector, housing construction"
    },
    {
        "value": 2019,
        "NOCCode": "2264",
        "JobTitle": "inspector, housing rehabilitation"
    },
    {
        "value": 2020,
        "NOCCode": "2264",
        "JobTitle": "inspector, industrial construction safety"
    },
    {
        "value": 2021,
        "NOCCode": "2264",
        "JobTitle": "inspector, industrial electric installations"
    },
    {
        "value": 2022,
        "NOCCode": "2264",
        "JobTitle": "inspector, irrigation – construction"
    },
    {
        "value": 2023,
        "NOCCode": "2264",
        "JobTitle": "inspector, maintenance – construction"
    },
    {
        "value": 2024,
        "NOCCode": "2264",
        "JobTitle": "inspector, masonry"
    },
    {
        "value": 2025,
        "NOCCode": "2264",
        "JobTitle": "inspector, mine construction"
    },
    {
        "value": 2026,
        "NOCCode": "2264",
        "JobTitle": "inspector, pipeline construction"
    },
    {
        "value": 2027,
        "NOCCode": "2264",
        "JobTitle": "inspector, plumbing"
    },
    {
        "value": 2028,
        "NOCCode": "2264",
        "JobTitle": "inspector, pre-stressed concrete"
    },
    {
        "value": 2029,
        "NOCCode": "2264",
        "JobTitle": "inspector, public works – construction"
    },
    {
        "value": 2030,
        "NOCCode": "2264",
        "JobTitle": "inspector, refinery equipment"
    },
    {
        "value": 2031,
        "NOCCode": "2264",
        "JobTitle": "inspector, reinforced concrete"
    },
    {
        "value": 2032,
        "NOCCode": "2264",
        "JobTitle": "inspector, road construction"
    },
    {
        "value": 2033,
        "NOCCode": "2264",
        "JobTitle": "inspector, road grading"
    },
    {
        "value": 2034,
        "NOCCode": "2264",
        "JobTitle": "inspector, road paving"
    },
    {
        "value": 2035,
        "NOCCode": "2264",
        "JobTitle": "inspector, sewer construction"
    },
    {
        "value": 2036,
        "NOCCode": "2264",
        "JobTitle": "inspector, structural ironwork"
    },
    {
        "value": 2037,
        "NOCCode": "2264",
        "JobTitle": "inspector, structural steel"
    },
    {
        "value": 2038,
        "NOCCode": "2264",
        "JobTitle": "inspector, tunnel construction"
    },
    {
        "value": 2039,
        "NOCCode": "2264",
        "JobTitle": "inspector, waterworks construction"
    },
    {
        "value": 2040,
        "NOCCode": "2264",
        "JobTitle": "inspector, wiring – domestic installation"
    },
    {
        "value": 2041,
        "NOCCode": "2264",
        "JobTitle": "inspector, wiring – industrial installation"
    },
    {
        "value": 2042,
        "NOCCode": "2264",
        "JobTitle": "irrigation inspector – construction"
    },
    {
        "value": 2043,
        "NOCCode": "2264",
        "JobTitle": "maintenance inspector – construction"
    },
    {
        "value": 2044,
        "NOCCode": "2264",
        "JobTitle": "masonry inspector"
    },
    {
        "value": 2045,
        "NOCCode": "2264",
        "JobTitle": "mine construction inspector"
    },
    {
        "value": 2046,
        "NOCCode": "2264",
        "JobTitle": "mine inspector, construction"
    },
    {
        "value": 2047,
        "NOCCode": "2264",
        "JobTitle": "pipeline construction inspector"
    },
    {
        "value": 2048,
        "NOCCode": "2264",
        "JobTitle": "plumbing inspector"
    },
    {
        "value": 2049,
        "NOCCode": "2264",
        "JobTitle": "pre-stressed concrete inspector"
    },
    {
        "value": 2050,
        "NOCCode": "2264",
        "JobTitle": "public works inspector – construction"
    },
    {
        "value": 2051,
        "NOCCode": "2264",
        "JobTitle": "refinery equipment inspector"
    },
    {
        "value": 2052,
        "NOCCode": "2264",
        "JobTitle": "reinforced concrete inspector"
    },
    {
        "value": 2053,
        "NOCCode": "2264",
        "JobTitle": "road construction inspector"
    },
    {
        "value": 2054,
        "NOCCode": "2264",
        "JobTitle": "road grading inspector"
    },
    {
        "value": 2055,
        "NOCCode": "2264",
        "JobTitle": "road paving inspector"
    },
    {
        "value": 2056,
        "NOCCode": "2264",
        "JobTitle": "safety officer – construction"
    },
    {
        "value": 2057,
        "NOCCode": "2264",
        "JobTitle": "sand and gravel inspector – construction"
    },
    {
        "value": 2058,
        "NOCCode": "2264",
        "JobTitle": "sewer construction inspector"
    },
    {
        "value": 2059,
        "NOCCode": "2264",
        "JobTitle": "sewer inspector, closed circuit TV"
    },
    {
        "value": 2060,
        "NOCCode": "2264",
        "JobTitle": "soil inspector – construction"
    },
    {
        "value": 2061,
        "NOCCode": "2264",
        "JobTitle": "structural ironwork inspector"
    },
    {
        "value": 2062,
        "NOCCode": "2264",
        "JobTitle": "structural steel inspector"
    },
    {
        "value": 2063,
        "NOCCode": "2264",
        "JobTitle": "supervisor, construction inspectors"
    },
    {
        "value": 2064,
        "NOCCode": "2264",
        "JobTitle": "tunnel construction inspector"
    },
    {
        "value": 2065,
        "NOCCode": "2264",
        "JobTitle": "waterworks construction inspector"
    },
    {
        "value": 2066,
        "NOCCode": "2264",
        "JobTitle": "wiring inspector – domestic installation"
    },
    {
        "value": 2067,
        "NOCCode": "2264",
        "JobTitle": "wiring inspector – industrial installation"
    },
    {
        "value": 2068,
        "NOCCode": "2271",
        "JobTitle": "aerial crop duster"
    },
    {
        "value": 2069,
        "NOCCode": "2271",
        "JobTitle": "aerial sprayer"
    },
    {
        "value": 2070,
        "NOCCode": "2271",
        "JobTitle": "aerial survey flight supervisor"
    },
    {
        "value": 2071,
        "NOCCode": "2271",
        "JobTitle": "aerial survey pilot"
    },
    {
        "value": 2072,
        "NOCCode": "2271",
        "JobTitle": "air navigator"
    },
    {
        "value": 2073,
        "NOCCode": "2271",
        "JobTitle": "air patrol pilot"
    },
    {
        "value": 2074,
        "NOCCode": "2271",
        "JobTitle": "air pilot"
    },
    {
        "value": 2075,
        "NOCCode": "2271",
        "JobTitle": "air pilots and navigators supervisor"
    },
    {
        "value": 2076,
        "NOCCode": "2271",
        "JobTitle": "airline pilot"
    },
    {
        "value": 2077,
        "NOCCode": "2271",
        "JobTitle": "airline pilot instructor"
    },
    {
        "value": 2078,
        "NOCCode": "2271",
        "JobTitle": "airline test pilot"
    },
    {
        "value": 2079,
        "NOCCode": "2271",
        "JobTitle": "airplane pilot"
    },
    {
        "value": 2080,
        "NOCCode": "2271",
        "JobTitle": "bush pilot"
    },
    {
        "value": 2081,
        "NOCCode": "2271",
        "JobTitle": "business aircraft pilot"
    },
    {
        "value": 2082,
        "NOCCode": "2271",
        "JobTitle": "captain – air transport"
    },
    {
        "value": 2083,
        "NOCCode": "2271",
        "JobTitle": "charter pilot"
    },
    {
        "value": 2084,
        "NOCCode": "2271",
        "JobTitle": "check pilot"
    },
    {
        "value": 2085,
        "NOCCode": "2271",
        "JobTitle": "chief flying instructor"
    },
    {
        "value": 2086,
        "NOCCode": "2271",
        "JobTitle": "chief pilot"
    },
    {
        "value": 2087,
        "NOCCode": "2271",
        "JobTitle": "chief pilot – air transport"
    },
    {
        "value": 2088,
        "NOCCode": "2271",
        "JobTitle": "chief pilot, helicopter"
    },
    {
        "value": 2089,
        "NOCCode": "2271",
        "JobTitle": "commercial airline pilot"
    },
    {
        "value": 2090,
        "NOCCode": "2271",
        "JobTitle": "commercial pilot"
    },
    {
        "value": 2091,
        "NOCCode": "2271",
        "JobTitle": "co-pilot"
    },
    {
        "value": 2092,
        "NOCCode": "2271",
        "JobTitle": "co-pilot – air transport"
    },
    {
        "value": 2093,
        "NOCCode": "2271",
        "JobTitle": "corporate pilot"
    },
    {
        "value": 2094,
        "NOCCode": "2271",
        "JobTitle": "crop duster"
    },
    {
        "value": 2095,
        "NOCCode": "2271",
        "JobTitle": "engineering test pilot"
    },
    {
        "value": 2096,
        "NOCCode": "2271",
        "JobTitle": "executive pilot"
    },
    {
        "value": 2097,
        "NOCCode": "2271",
        "JobTitle": "experimental test pilot"
    },
    {
        "value": 2098,
        "NOCCode": "2271",
        "JobTitle": "fire patrol pilot"
    },
    {
        "value": 2099,
        "NOCCode": "2271",
        "JobTitle": "first officer – air transport"
    },
    {
        "value": 2100,
        "NOCCode": "2271",
        "JobTitle": "flight engineer"
    },
    {
        "value": 2101,
        "NOCCode": "2271",
        "JobTitle": "flight engineer – air transport"
    },
    {
        "value": 2102,
        "NOCCode": "2271",
        "JobTitle": "flight instructor"
    },
    {
        "value": 2103,
        "NOCCode": "2271",
        "JobTitle": "flight simulator instructor"
    },
    {
        "value": 2104,
        "NOCCode": "2271",
        "JobTitle": "flying instructor"
    },
    {
        "value": 2105,
        "NOCCode": "2271",
        "JobTitle": "forest spray pilot"
    },
    {
        "value": 2106,
        "NOCCode": "2271",
        "JobTitle": "forestry patrol pilot"
    },
    {
        "value": 2107,
        "NOCCode": "2271",
        "JobTitle": "helicopter flight instructor"
    },
    {
        "value": 2108,
        "NOCCode": "2271",
        "JobTitle": "helicopter pilot"
    },
    {
        "value": 2109,
        "NOCCode": "2271",
        "JobTitle": "mapping pilot"
    },
    {
        "value": 2110,
        "NOCCode": "2271",
        "JobTitle": "navigator – air transport"
    },
    {
        "value": 2111,
        "NOCCode": "2271",
        "JobTitle": "pilot – air transport"
    },
    {
        "value": 2112,
        "NOCCode": "2271",
        "JobTitle": "pilot instructor"
    },
    {
        "value": 2113,
        "NOCCode": "2271",
        "JobTitle": "pilot, airline"
    },
    {
        "value": 2114,
        "NOCCode": "2271",
        "JobTitle": "pilot, remote sensing"
    },
    {
        "value": 2115,
        "NOCCode": "2271",
        "JobTitle": "pipeline air patrol pilot"
    },
    {
        "value": 2116,
        "NOCCode": "2271",
        "JobTitle": "power line air patrol pilot"
    },
    {
        "value": 2117,
        "NOCCode": "2271",
        "JobTitle": "relief pilot"
    },
    {
        "value": 2118,
        "NOCCode": "2271",
        "JobTitle": "remote sensing pilot"
    },
    {
        "value": 2119,
        "NOCCode": "2271",
        "JobTitle": "second officer – air transport"
    },
    {
        "value": 2120,
        "NOCCode": "2271",
        "JobTitle": "spray pilot"
    },
    {
        "value": 2121,
        "NOCCode": "2271",
        "JobTitle": "supervisor, air pilots and flight engineers"
    },
    {
        "value": 2122,
        "NOCCode": "2271",
        "JobTitle": "survey pilot"
    },
    {
        "value": 2123,
        "NOCCode": "2271",
        "JobTitle": "test pilot"
    },
    {
        "value": 2124,
        "NOCCode": "2271",
        "JobTitle": "topographic survey pilot"
    },
    {
        "value": 2125,
        "NOCCode": "2271",
        "JobTitle": "water bomber pilot"
    },
    {
        "value": 2126,
        "NOCCode": "2272",
        "JobTitle": "air traffic control assistant"
    },
    {
        "value": 2127,
        "NOCCode": "2272",
        "JobTitle": "air traffic control chief"
    },
    {
        "value": 2128,
        "NOCCode": "2272",
        "JobTitle": "air traffic control officer"
    },
    {
        "value": 2129,
        "NOCCode": "2272",
        "JobTitle": "air traffic controller (ATC)"
    },
    {
        "value": 2130,
        "NOCCode": "2272",
        "JobTitle": "air traffic controller, airport"
    },
    {
        "value": 2131,
        "NOCCode": "2272",
        "JobTitle": "air traffic controller, enroute"
    },
    {
        "value": 2132,
        "NOCCode": "2272",
        "JobTitle": "air traffic controller, terminal"
    },
    {
        "value": 2133,
        "NOCCode": "2272",
        "JobTitle": "air traffic co-ordinator"
    },
    {
        "value": 2134,
        "NOCCode": "2272",
        "JobTitle": "airline dispatcher"
    },
    {
        "value": 2135,
        "NOCCode": "2272",
        "JobTitle": "airport air traffic controller"
    },
    {
        "value": 2136,
        "NOCCode": "2272",
        "JobTitle": "assistant, air traffic control"
    },
    {
        "value": 2137,
        "NOCCode": "2272",
        "JobTitle": "ATC (air traffic controller)"
    },
    {
        "value": 2138,
        "NOCCode": "2272",
        "JobTitle": "chief air traffic controller"
    },
    {
        "value": 2139,
        "NOCCode": "2272",
        "JobTitle": "controller, air traffic"
    },
    {
        "value": 2140,
        "NOCCode": "2272",
        "JobTitle": "dispatcher, flights"
    },
    {
        "value": 2141,
        "NOCCode": "2272",
        "JobTitle": "enroute air traffic controller"
    },
    {
        "value": 2142,
        "NOCCode": "2272",
        "JobTitle": "flight dispatch assistant"
    },
    {
        "value": 2143,
        "NOCCode": "2272",
        "JobTitle": "flight dispatcher"
    },
    {
        "value": 2144,
        "NOCCode": "2272",
        "JobTitle": "flight operations officer"
    },
    {
        "value": 2145,
        "NOCCode": "2272",
        "JobTitle": "flight service specialist (FSS)"
    },
    {
        "value": 2146,
        "NOCCode": "2272",
        "JobTitle": "head air traffic controller"
    },
    {
        "value": 2147,
        "NOCCode": "2272",
        "JobTitle": "instrument flight rules air traffic controller"
    },
    {
        "value": 2148,
        "NOCCode": "2272",
        "JobTitle": "officer, air traffic control"
    },
    {
        "value": 2149,
        "NOCCode": "2272",
        "JobTitle": "terminal air traffic controller"
    },
    {
        "value": 2150,
        "NOCCode": "2272",
        "JobTitle": "tower controller – air traffic"
    },
    {
        "value": 2151,
        "NOCCode": "2272",
        "JobTitle": "visual flight rules air traffic controller"
    },
    {
        "value": 2152,
        "NOCCode": "2273",
        "JobTitle": "apprentice master – minor waters"
    },
    {
        "value": 2153,
        "NOCCode": "2273",
        "JobTitle": "captain – water transport"
    },
    {
        "value": 2154,
        "NOCCode": "2273",
        "JobTitle": "captain, ferryboat"
    },
    {
        "value": 2155,
        "NOCCode": "2273",
        "JobTitle": "captain, offshore rig"
    },
    {
        "value": 2156,
        "NOCCode": "2273",
        "JobTitle": "captain, passenger ship"
    },
    {
        "value": 2157,
        "NOCCode": "2273",
        "JobTitle": "captain, self-propelled barge"
    },
    {
        "value": 2158,
        "NOCCode": "2273",
        "JobTitle": "captain, self-propelled drilling rig"
    },
    {
        "value": 2159,
        "NOCCode": "2273",
        "JobTitle": "captain, ship"
    },
    {
        "value": 2160,
        "NOCCode": "2273",
        "JobTitle": "chief officer, Coast Guard vessel"
    },
    {
        "value": 2161,
        "NOCCode": "2273",
        "JobTitle": "chief officer, ship"
    },
    {
        "value": 2162,
        "NOCCode": "2273",
        "JobTitle": "chief officer, ship operations"
    },
    {
        "value": 2163,
        "NOCCode": "2273",
        "JobTitle": "Coast Guard vessel commanding officer"
    },
    {
        "value": 2164,
        "NOCCode": "2273",
        "JobTitle": "Coast Guard vessel second officer"
    },
    {
        "value": 2165,
        "NOCCode": "2273",
        "JobTitle": "Coast Guard vessel second watchkeeping officer"
    },
    {
        "value": 2166,
        "NOCCode": "2273",
        "JobTitle": "Coast Guard vessel third officer"
    },
    {
        "value": 2167,
        "NOCCode": "2273",
        "JobTitle": "Coast Guard vessel third watchkeeping officer"
    },
    {
        "value": 2168,
        "NOCCode": "2273",
        "JobTitle": "commanding officer – water transport"
    },
    {
        "value": 2169,
        "NOCCode": "2273",
        "JobTitle": "commanding officer, Coast Guard vessel"
    },
    {
        "value": 2170,
        "NOCCode": "2273",
        "JobTitle": "commanding officer, dredge"
    },
    {
        "value": 2171,
        "NOCCode": "2273",
        "JobTitle": "commanding officer, offshore rig"
    },
    {
        "value": 2172,
        "NOCCode": "2273",
        "JobTitle": "deck officer – cadet"
    },
    {
        "value": 2173,
        "NOCCode": "2273",
        "JobTitle": "deck officer – water transport"
    },
    {
        "value": 2174,
        "NOCCode": "2273",
        "JobTitle": "deck officer, self-propelled barge"
    },
    {
        "value": 2175,
        "NOCCode": "2273",
        "JobTitle": "deck officer, self-propelled oil rig"
    },
    {
        "value": 2176,
        "NOCCode": "2273",
        "JobTitle": "dredge captain"
    },
    {
        "value": 2177,
        "NOCCode": "2273",
        "JobTitle": "dredge commanding officer"
    },
    {
        "value": 2178,
        "NOCCode": "2273",
        "JobTitle": "dredge mate"
    },
    {
        "value": 2179,
        "NOCCode": "2273",
        "JobTitle": "ferryboat captain"
    },
    {
        "value": 2180,
        "NOCCode": "2273",
        "JobTitle": "ferryboat first mate"
    },
    {
        "value": 2181,
        "NOCCode": "2273",
        "JobTitle": "ferryboat master"
    },
    {
        "value": 2182,
        "NOCCode": "2273",
        "JobTitle": "ferryboat operator"
    },
    {
        "value": 2183,
        "NOCCode": "2273",
        "JobTitle": "first mate – inland waters"
    },
    {
        "value": 2184,
        "NOCCode": "2273",
        "JobTitle": "first mate, ferryboat"
    },
    {
        "value": 2185,
        "NOCCode": "2273",
        "JobTitle": "first mate, foreign-going"
    },
    {
        "value": 2186,
        "NOCCode": "2273",
        "JobTitle": "first mate, home trade"
    },
    {
        "value": 2187,
        "NOCCode": "2273",
        "JobTitle": "first mate, offshore drilling rig"
    },
    {
        "value": 2188,
        "NOCCode": "2273",
        "JobTitle": "first mate, self-propelled drilling rig"
    },
    {
        "value": 2189,
        "NOCCode": "2273",
        "JobTitle": "first mate, ship"
    },
    {
        "value": 2190,
        "NOCCode": "2273",
        "JobTitle": "first watchkeeping officer, Coast Guard vessel"
    },
    {
        "value": 2191,
        "NOCCode": "2273",
        "JobTitle": "foreign-going first mate"
    },
    {
        "value": 2192,
        "NOCCode": "2273",
        "JobTitle": "foreign-going second mate"
    },
    {
        "value": 2193,
        "NOCCode": "2273",
        "JobTitle": "harbour pilot"
    },
    {
        "value": 2194,
        "NOCCode": "2273",
        "JobTitle": "home trade first mate"
    },
    {
        "value": 2195,
        "NOCCode": "2273",
        "JobTitle": "home trade master"
    },
    {
        "value": 2196,
        "NOCCode": "2273",
        "JobTitle": "home trade second mate"
    },
    {
        "value": 2197,
        "NOCCode": "2273",
        "JobTitle": "hovercraft master"
    },
    {
        "value": 2198,
        "NOCCode": "2273",
        "JobTitle": "inland navigation mate"
    },
    {
        "value": 2199,
        "NOCCode": "2273",
        "JobTitle": "junior mate, ship"
    },
    {
        "value": 2200,
        "NOCCode": "2273",
        "JobTitle": "launchman/woman"
    },
    {
        "value": 2201,
        "NOCCode": "2273",
        "JobTitle": "master – inland waters"
    },
    {
        "value": 2202,
        "NOCCode": "2273",
        "JobTitle": "master – minor waters"
    },
    {
        "value": 2203,
        "NOCCode": "2273",
        "JobTitle": "master – water transport"
    },
    {
        "value": 2204,
        "NOCCode": "2273",
        "JobTitle": "master mariner"
    },
    {
        "value": 2205,
        "NOCCode": "2273",
        "JobTitle": "master, dredge"
    },
    {
        "value": 2206,
        "NOCCode": "2273",
        "JobTitle": "master, ferryboat"
    },
    {
        "value": 2207,
        "NOCCode": "2273",
        "JobTitle": "master, hovercraft"
    },
    {
        "value": 2208,
        "NOCCode": "2273",
        "JobTitle": "master, self-propelled drilling rig"
    },
    {
        "value": 2209,
        "NOCCode": "2273",
        "JobTitle": "master, ship"
    },
    {
        "value": 2210,
        "NOCCode": "2273",
        "JobTitle": "mate – water transport"
    },
    {
        "value": 2211,
        "NOCCode": "2273",
        "JobTitle": "mate, home trade"
    },
    {
        "value": 2212,
        "NOCCode": "2273",
        "JobTitle": "mate, inland navigation"
    },
    {
        "value": 2213,
        "NOCCode": "2273",
        "JobTitle": "mate, merchant navy"
    },
    {
        "value": 2214,
        "NOCCode": "2273",
        "JobTitle": "mate, self-propelled drilling rig"
    },
    {
        "value": 2215,
        "NOCCode": "2273",
        "JobTitle": "mate, ship"
    },
    {
        "value": 2216,
        "NOCCode": "2273",
        "JobTitle": "merchant navy mate"
    },
    {
        "value": 2217,
        "NOCCode": "2273",
        "JobTitle": "navigation officer – water transport"
    },
    {
        "value": 2218,
        "NOCCode": "2273",
        "JobTitle": "navigation officer, Coast Guard vessel"
    },
    {
        "value": 2219,
        "NOCCode": "2273",
        "JobTitle": "officer, deck"
    },
    {
        "value": 2220,
        "NOCCode": "2273",
        "JobTitle": "officer, deck – water transport"
    },
    {
        "value": 2221,
        "NOCCode": "2273",
        "JobTitle": "officer, navigation – water transport"
    },
    {
        "value": 2222,
        "NOCCode": "2273",
        "JobTitle": "offshore drilling rig first mate"
    },
    {
        "value": 2223,
        "NOCCode": "2273",
        "JobTitle": "offshore rig captain"
    },
    {
        "value": 2224,
        "NOCCode": "2273",
        "JobTitle": "offshore rig commanding officer"
    },
    {
        "value": 2225,
        "NOCCode": "2273",
        "JobTitle": "passenger ship captain"
    },
    {
        "value": 2226,
        "NOCCode": "2273",
        "JobTitle": "pilot – water transport"
    },
    {
        "value": 2227,
        "NOCCode": "2273",
        "JobTitle": "pilot, harbour"
    },
    {
        "value": 2228,
        "NOCCode": "2273",
        "JobTitle": "pilot, ship"
    },
    {
        "value": 2229,
        "NOCCode": "2273",
        "JobTitle": "river pilot"
    },
    {
        "value": 2230,
        "NOCCode": "2273",
        "JobTitle": "riverboat captain"
    },
    {
        "value": 2231,
        "NOCCode": "2273",
        "JobTitle": "second deck officer"
    },
    {
        "value": 2232,
        "NOCCode": "2273",
        "JobTitle": "second mate"
    },
    {
        "value": 2233,
        "NOCCode": "2273",
        "JobTitle": "second mate – inland waters"
    },
    {
        "value": 2234,
        "NOCCode": "2273",
        "JobTitle": "second mate, foreign-going"
    },
    {
        "value": 2235,
        "NOCCode": "2273",
        "JobTitle": "second mate, home trade"
    },
    {
        "value": 2236,
        "NOCCode": "2273",
        "JobTitle": "second mate, ship"
    },
    {
        "value": 2237,
        "NOCCode": "2273",
        "JobTitle": "second officer, Coast Guard vessel"
    },
    {
        "value": 2238,
        "NOCCode": "2273",
        "JobTitle": "second watchkeeping officer, Coast Guard vessel"
    },
    {
        "value": 2239,
        "NOCCode": "2273",
        "JobTitle": "self-propelled barge captain"
    },
    {
        "value": 2240,
        "NOCCode": "2273",
        "JobTitle": "self-propelled barge deck officer"
    },
    {
        "value": 2241,
        "NOCCode": "2273",
        "JobTitle": "self-propelled drilling rig captain"
    },
    {
        "value": 2242,
        "NOCCode": "2273",
        "JobTitle": "self-propelled drilling rig first mate"
    },
    {
        "value": 2243,
        "NOCCode": "2273",
        "JobTitle": "self-propelled drilling rig mate"
    },
    {
        "value": 2244,
        "NOCCode": "2273",
        "JobTitle": "self-propelled oil rig deck officer"
    },
    {
        "value": 2245,
        "NOCCode": "2273",
        "JobTitle": "ship first mate"
    },
    {
        "value": 2246,
        "NOCCode": "2273",
        "JobTitle": "ship master"
    },
    {
        "value": 2247,
        "NOCCode": "2273",
        "JobTitle": "ship pilot"
    },
    {
        "value": 2248,
        "NOCCode": "2273",
        "JobTitle": "ship watchkeeping mate"
    },
    {
        "value": 2249,
        "NOCCode": "2273",
        "JobTitle": "ship's captain"
    },
    {
        "value": 2250,
        "NOCCode": "2273",
        "JobTitle": "ship's mate"
    },
    {
        "value": 2251,
        "NOCCode": "2273",
        "JobTitle": "skipper, tugboat"
    },
    {
        "value": 2252,
        "NOCCode": "2273",
        "JobTitle": "third mate, ship"
    },
    {
        "value": 2253,
        "NOCCode": "2273",
        "JobTitle": "third officer, Coast Guard vessel"
    },
    {
        "value": 2254,
        "NOCCode": "2273",
        "JobTitle": "third watchkeeping officer, Coast Guard vessel"
    },
    {
        "value": 2255,
        "NOCCode": "2273",
        "JobTitle": "tugboat captain"
    },
    {
        "value": 2256,
        "NOCCode": "2273",
        "JobTitle": "tugboat master"
    },
    {
        "value": 2257,
        "NOCCode": "2273",
        "JobTitle": "tugboat skipper"
    },
    {
        "value": 2258,
        "NOCCode": "2273",
        "JobTitle": "watchkeeping mate, ship"
    },
    {
        "value": 2259,
        "NOCCode": "2273",
        "JobTitle": "watchkeeping officer – water transport"
    },
    {
        "value": 2260,
        "NOCCode": "2273",
        "JobTitle": "watchkeeping officer, Coast Guard vessel"
    },
    {
        "value": 2261,
        "NOCCode": "2273",
        "JobTitle": "waterman/woman"
    },
    {
        "value": 2262,
        "NOCCode": "2274",
        "JobTitle": "apprentice marine engineer officer"
    },
    {
        "value": 2263,
        "NOCCode": "2274",
        "JobTitle": "assistant engineer – water transport"
    },
    {
        "value": 2264,
        "NOCCode": "2274",
        "JobTitle": "chief engineer – water transport"
    },
    {
        "value": 2265,
        "NOCCode": "2274",
        "JobTitle": "chief engineer, Coast Guard vessel"
    },
    {
        "value": 2266,
        "NOCCode": "2274",
        "JobTitle": "chief engineer, fishing vessel"
    },
    {
        "value": 2267,
        "NOCCode": "2274",
        "JobTitle": "chief engineer, mobile platform – offshore drilling"
    },
    {
        "value": 2268,
        "NOCCode": "2274",
        "JobTitle": "chief engineer, self-propelled drilling rig"
    },
    {
        "value": 2269,
        "NOCCode": "2274",
        "JobTitle": "chief marine engineer – water transport"
    },
    {
        "value": 2270,
        "NOCCode": "2274",
        "JobTitle": "chief marine engineer, factory freezer trawler"
    },
    {
        "value": 2271,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel chief engineer"
    },
    {
        "value": 2272,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel first watchkeeping engineer"
    },
    {
        "value": 2273,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel marine engineer officer"
    },
    {
        "value": 2274,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel second engineer"
    },
    {
        "value": 2275,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel second watchkeeping engineer"
    },
    {
        "value": 2276,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel senior engineer"
    },
    {
        "value": 2277,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel third engineer"
    },
    {
        "value": 2278,
        "NOCCode": "2274",
        "JobTitle": "Coast Guard vessel third watchkeeping engineer"
    },
    {
        "value": 2279,
        "NOCCode": "2274",
        "JobTitle": "engineer officer – water transport"
    },
    {
        "value": 2280,
        "NOCCode": "2274",
        "JobTitle": "engineer officer, fishing vessel"
    },
    {
        "value": 2281,
        "NOCCode": "2274",
        "JobTitle": "engineer, tugboat"
    },
    {
        "value": 2282,
        "NOCCode": "2274",
        "JobTitle": "factory freezer trawler chief marine engineer"
    },
    {
        "value": 2283,
        "NOCCode": "2274",
        "JobTitle": "factory freezer trawler first engineer"
    },
    {
        "value": 2284,
        "NOCCode": "2274",
        "JobTitle": "factory freezer trawler second engineer"
    },
    {
        "value": 2285,
        "NOCCode": "2274",
        "JobTitle": "first engineer, factory freezer trawler"
    },
    {
        "value": 2286,
        "NOCCode": "2274",
        "JobTitle": "first engineer, self-propelled drilling rig"
    },
    {
        "value": 2287,
        "NOCCode": "2274",
        "JobTitle": "first watchkeeping engineer, Coast Guard vessel"
    },
    {
        "value": 2288,
        "NOCCode": "2274",
        "JobTitle": "fishing vessel chief engineer"
    },
    {
        "value": 2289,
        "NOCCode": "2274",
        "JobTitle": "fishing vessel engineer officer"
    },
    {
        "value": 2290,
        "NOCCode": "2274",
        "JobTitle": "fourth engineer – water transport"
    },
    {
        "value": 2291,
        "NOCCode": "2274",
        "JobTitle": "junior engineer – water transport"
    },
    {
        "value": 2292,
        "NOCCode": "2274",
        "JobTitle": "marine engineer officer"
    },
    {
        "value": 2293,
        "NOCCode": "2274",
        "JobTitle": "marine engineer officer – water transport"
    },
    {
        "value": 2294,
        "NOCCode": "2274",
        "JobTitle": "marine engineer officer, Coast Guard vessel"
    },
    {
        "value": 2295,
        "NOCCode": "2274",
        "JobTitle": "marine engineer officer, offshore drilling rig"
    },
    {
        "value": 2296,
        "NOCCode": "2274",
        "JobTitle": "marine engineer officer-cadet – except armed forces"
    },
    {
        "value": 2297,
        "NOCCode": "2274",
        "JobTitle": "marine engineer, chief – water transport"
    },
    {
        "value": 2298,
        "NOCCode": "2274",
        "JobTitle": "marine second engineer – water transport"
    },
    {
        "value": 2299,
        "NOCCode": "2274",
        "JobTitle": "marine senior engineer – water transport"
    },
    {
        "value": 2300,
        "NOCCode": "2274",
        "JobTitle": "mobile platform chief engineer – offshore drilling"
    },
    {
        "value": 2301,
        "NOCCode": "2274",
        "JobTitle": "offshore drilling rig marine engineer officer"
    },
    {
        "value": 2302,
        "NOCCode": "2274",
        "JobTitle": "second engineer"
    },
    {
        "value": 2303,
        "NOCCode": "2274",
        "JobTitle": "second engineer – water transport"
    },
    {
        "value": 2304,
        "NOCCode": "2274",
        "JobTitle": "second engineer, Coast Guard vessel"
    },
    {
        "value": 2305,
        "NOCCode": "2274",
        "JobTitle": "second engineer, factory freezer trawler"
    },
    {
        "value": 2306,
        "NOCCode": "2274",
        "JobTitle": "second engineer, marine – water transport"
    },
    {
        "value": 2307,
        "NOCCode": "2274",
        "JobTitle": "second engineer, self-propelled drilling rig"
    },
    {
        "value": 2308,
        "NOCCode": "2274",
        "JobTitle": "second watchkeeping engineer, Coast Guard vessel"
    },
    {
        "value": 2309,
        "NOCCode": "2274",
        "JobTitle": "self-propelled barge marine engineer officer"
    },
    {
        "value": 2310,
        "NOCCode": "2274",
        "JobTitle": "self-propelled drilling rig chief engineer"
    },
    {
        "value": 2311,
        "NOCCode": "2274",
        "JobTitle": "self-propelled drilling rig first engineer"
    },
    {
        "value": 2312,
        "NOCCode": "2274",
        "JobTitle": "self-propelled drilling rig marine engineer officer"
    },
    {
        "value": 2313,
        "NOCCode": "2274",
        "JobTitle": "self-propelled drilling rig second engineer"
    },
    {
        "value": 2314,
        "NOCCode": "2274",
        "JobTitle": "senior engineer, Coast Guard vessel"
    },
    {
        "value": 2315,
        "NOCCode": "2274",
        "JobTitle": "senior engineer, marine – water transport"
    },
    {
        "value": 2316,
        "NOCCode": "2274",
        "JobTitle": "ship engineer officer"
    },
    {
        "value": 2317,
        "NOCCode": "2274",
        "JobTitle": "third engineer – water transport"
    },
    {
        "value": 2318,
        "NOCCode": "2274",
        "JobTitle": "third engineer, Coast Guard vessel"
    },
    {
        "value": 2319,
        "NOCCode": "2274",
        "JobTitle": "third watchkeeping engineer, Coast Guard vessel"
    },
    {
        "value": 2320,
        "NOCCode": "2274",
        "JobTitle": "tugboat engineer"
    },
    {
        "value": 2321,
        "NOCCode": "2275",
        "JobTitle": "centralized traffic control (CTC) operator – railway traffic"
    },
    {
        "value": 2322,
        "NOCCode": "2275",
        "JobTitle": "centralized traffic controller – railway traffic"
    },
    {
        "value": 2323,
        "NOCCode": "2275",
        "JobTitle": "chief rail traffic controller"
    },
    {
        "value": 2324,
        "NOCCode": "2275",
        "JobTitle": "chief train dispatcher"
    },
    {
        "value": 2325,
        "NOCCode": "2275",
        "JobTitle": "controller, rail traffic"
    },
    {
        "value": 2326,
        "NOCCode": "2275",
        "JobTitle": "CTC (centralized traffic control) operator – railway traffic"
    },
    {
        "value": 2327,
        "NOCCode": "2275",
        "JobTitle": "dispatcher, trains"
    },
    {
        "value": 2328,
        "NOCCode": "2275",
        "JobTitle": "head train dispatcher"
    },
    {
        "value": 2329,
        "NOCCode": "2275",
        "JobTitle": "locomotive dispatcher"
    },
    {
        "value": 2330,
        "NOCCode": "2275",
        "JobTitle": "marine traffic controller"
    },
    {
        "value": 2331,
        "NOCCode": "2275",
        "JobTitle": "marine traffic regulator"
    },
    {
        "value": 2332,
        "NOCCode": "2275",
        "JobTitle": "operator, train"
    },
    {
        "value": 2333,
        "NOCCode": "2275",
        "JobTitle": "rail traffic controller"
    },
    {
        "value": 2334,
        "NOCCode": "2275",
        "JobTitle": "regulator, marine traffic"
    },
    {
        "value": 2335,
        "NOCCode": "2275",
        "JobTitle": "supervisor, train dispatchers"
    },
    {
        "value": 2336,
        "NOCCode": "2275",
        "JobTitle": "traffic checker, waterways"
    },
    {
        "value": 2337,
        "NOCCode": "2275",
        "JobTitle": "train dispatcher"
    },
    {
        "value": 2338,
        "NOCCode": "2275",
        "JobTitle": "train dispatchers supervisor"
    },
    {
        "value": 2339,
        "NOCCode": "2275",
        "JobTitle": "train operator"
    },
    {
        "value": 2340,
        "NOCCode": "2275",
        "JobTitle": "waterway traffic checker"
    },
    {
        "value": 2341,
        "NOCCode": "2281",
        "JobTitle": "computer network technician"
    },
    {
        "value": 2342,
        "NOCCode": "2281",
        "JobTitle": "computer network technicians' supervisor"
    },
    {
        "value": 2343,
        "NOCCode": "2281",
        "JobTitle": "computer operations supervisor"
    },
    {
        "value": 2344,
        "NOCCode": "2281",
        "JobTitle": "computer tape librarian"
    },
    {
        "value": 2345,
        "NOCCode": "2281",
        "JobTitle": "controller, network"
    },
    {
        "value": 2346,
        "NOCCode": "2281",
        "JobTitle": "data centre operator"
    },
    {
        "value": 2347,
        "NOCCode": "2281",
        "JobTitle": "digital computer operator"
    },
    {
        "value": 2348,
        "NOCCode": "2281",
        "JobTitle": "Internet Web site technician"
    },
    {
        "value": 2349,
        "NOCCode": "2281",
        "JobTitle": "LAN (local area network) administrator"
    },
    {
        "value": 2350,
        "NOCCode": "2281",
        "JobTitle": "LAN (local area network) manager"
    },
    {
        "value": 2351,
        "NOCCode": "2281",
        "JobTitle": "LAN (local area network) operator"
    },
    {
        "value": 2352,
        "NOCCode": "2281",
        "JobTitle": "LAN (local area network) technician"
    },
    {
        "value": 2353,
        "NOCCode": "2281",
        "JobTitle": "local area network (LAN) administrator"
    },
    {
        "value": 2354,
        "NOCCode": "2281",
        "JobTitle": "local area network (LAN) manager"
    },
    {
        "value": 2355,
        "NOCCode": "2281",
        "JobTitle": "local area network (LAN) operator"
    },
    {
        "value": 2356,
        "NOCCode": "2281",
        "JobTitle": "local area network (LAN) technician"
    },
    {
        "value": 2357,
        "NOCCode": "2281",
        "JobTitle": "master console operator, computer"
    },
    {
        "value": 2358,
        "NOCCode": "2281",
        "JobTitle": "network administrator"
    },
    {
        "value": 2359,
        "NOCCode": "2281",
        "JobTitle": "network controller"
    },
    {
        "value": 2360,
        "NOCCode": "2281",
        "JobTitle": "network operator"
    },
    {
        "value": 2361,
        "NOCCode": "2281",
        "JobTitle": "network support analyst"
    },
    {
        "value": 2362,
        "NOCCode": "2281",
        "JobTitle": "network support technician"
    },
    {
        "value": 2363,
        "NOCCode": "2281",
        "JobTitle": "operator, network"
    },
    {
        "value": 2364,
        "NOCCode": "2281",
        "JobTitle": "server administrator"
    },
    {
        "value": 2365,
        "NOCCode": "2281",
        "JobTitle": "supervisor, computer network technicians"
    },
    {
        "value": 2366,
        "NOCCode": "2281",
        "JobTitle": "supervisor, computer operations"
    },
    {
        "value": 2367,
        "NOCCode": "2281",
        "JobTitle": "system administrator"
    },
    {
        "value": 2368,
        "NOCCode": "2281",
        "JobTitle": "tape librarian, computer"
    },
    {
        "value": 2369,
        "NOCCode": "2281",
        "JobTitle": "technician, Internet Web site"
    },
    {
        "value": 2370,
        "NOCCode": "2281",
        "JobTitle": "technician, Web"
    },
    {
        "value": 2371,
        "NOCCode": "2281",
        "JobTitle": "WAN (wide area network) administrator"
    },
    {
        "value": 2372,
        "NOCCode": "2281",
        "JobTitle": "WAN (wide area network) technician"
    },
    {
        "value": 2373,
        "NOCCode": "2281",
        "JobTitle": "Web site administrator"
    },
    {
        "value": 2374,
        "NOCCode": "2281",
        "JobTitle": "Web technician"
    },
    {
        "value": 2375,
        "NOCCode": "2281",
        "JobTitle": "Wide area network (WAN) administrator"
    },
    {
        "value": 2376,
        "NOCCode": "2281",
        "JobTitle": "wide area network (WAN) technician"
    },
    {
        "value": 2377,
        "NOCCode": "2282",
        "JobTitle": "analyst, systems technical support"
    },
    {
        "value": 2378,
        "NOCCode": "2282",
        "JobTitle": "call centre agent – technical support"
    },
    {
        "value": 2379,
        "NOCCode": "2282",
        "JobTitle": "client support representative – systems"
    },
    {
        "value": 2380,
        "NOCCode": "2282",
        "JobTitle": "computer help desk representative – systems"
    },
    {
        "value": 2381,
        "NOCCode": "2282",
        "JobTitle": "computer help desk supervisor"
    },
    {
        "value": 2382,
        "NOCCode": "2282",
        "JobTitle": "hardware installation technician"
    },
    {
        "value": 2383,
        "NOCCode": "2282",
        "JobTitle": "hardware technical support analyst"
    },
    {
        "value": 2384,
        "NOCCode": "2282",
        "JobTitle": "help desk agent, technical"
    },
    {
        "value": 2385,
        "NOCCode": "2282",
        "JobTitle": "help desk analyst – systems"
    },
    {
        "value": 2386,
        "NOCCode": "2282",
        "JobTitle": "help desk supervisor, computer"
    },
    {
        "value": 2387,
        "NOCCode": "2282",
        "JobTitle": "help desk technical agent"
    },
    {
        "value": 2388,
        "NOCCode": "2282",
        "JobTitle": "help desk technician"
    },
    {
        "value": 2389,
        "NOCCode": "2282",
        "JobTitle": "installation technician, hardware"
    },
    {
        "value": 2390,
        "NOCCode": "2282",
        "JobTitle": "installation technician, software"
    },
    {
        "value": 2391,
        "NOCCode": "2282",
        "JobTitle": "PC (personal computer) support analyst"
    },
    {
        "value": 2392,
        "NOCCode": "2282",
        "JobTitle": "software installation technician"
    },
    {
        "value": 2393,
        "NOCCode": "2282",
        "JobTitle": "software technical support analyst"
    },
    {
        "value": 2394,
        "NOCCode": "2282",
        "JobTitle": "supervisor, technical support"
    },
    {
        "value": 2395,
        "NOCCode": "2282",
        "JobTitle": "systems support representative"
    },
    {
        "value": 2396,
        "NOCCode": "2282",
        "JobTitle": "systems technical support analyst"
    },
    {
        "value": 2397,
        "NOCCode": "2282",
        "JobTitle": "technical help desk agent"
    },
    {
        "value": 2398,
        "NOCCode": "2282",
        "JobTitle": "technical support analyst – systems"
    },
    {
        "value": 2399,
        "NOCCode": "2282",
        "JobTitle": "technical support supervisor"
    },
    {
        "value": 2400,
        "NOCCode": "2282",
        "JobTitle": "user support technician"
    },
    {
        "value": 2401,
        "NOCCode": "2283",
        "JobTitle": "application tester"
    },
    {
        "value": 2402,
        "NOCCode": "2283",
        "JobTitle": "application testing technician"
    },
    {
        "value": 2403,
        "NOCCode": "2283",
        "JobTitle": "software test co-ordinator"
    },
    {
        "value": 2404,
        "NOCCode": "2283",
        "JobTitle": "software tester"
    },
    {
        "value": 2405,
        "NOCCode": "2283",
        "JobTitle": "software testing technician"
    },
    {
        "value": 2406,
        "NOCCode": "2283",
        "JobTitle": "systems tester"
    },
    {
        "value": 2407,
        "NOCCode": "2283",
        "JobTitle": "systems testing technician"
    },
    {
        "value": 2408,
        "NOCCode": "2283",
        "JobTitle": "technician, application testing"
    },
    {
        "value": 2409,
        "NOCCode": "2283",
        "JobTitle": "technician, software testing"
    },
    {
        "value": 2410,
        "NOCCode": "2283",
        "JobTitle": "test coordination analyst"
    },
    {
        "value": 2411,
        "NOCCode": "2283",
        "JobTitle": "user acceptance tester"
    },
    {
        "value": 2412,
        "NOCCode": "3011",
        "JobTitle": "acute care co-ordinator – nursing"
    },
    {
        "value": 2413,
        "NOCCode": "3011",
        "JobTitle": "assistant head nurse"
    },
    {
        "value": 2414,
        "NOCCode": "3011",
        "JobTitle": "birthing room nursing supervisor"
    },
    {
        "value": 2415,
        "NOCCode": "3011",
        "JobTitle": "burn unit nursing co-ordinator"
    },
    {
        "value": 2416,
        "NOCCode": "3011",
        "JobTitle": "burn unit nursing supervisor"
    },
    {
        "value": 2417,
        "NOCCode": "3011",
        "JobTitle": "co-ordinator of nursing services"
    },
    {
        "value": 2418,
        "NOCCode": "3011",
        "JobTitle": "co-ordinator, nursing"
    },
    {
        "value": 2419,
        "NOCCode": "3011",
        "JobTitle": "co-ordinator, nursing unit"
    },
    {
        "value": 2420,
        "NOCCode": "3011",
        "JobTitle": "critical care nursing supervisor"
    },
    {
        "value": 2421,
        "NOCCode": "3011",
        "JobTitle": "delivery room nursing supervisor"
    },
    {
        "value": 2422,
        "NOCCode": "3011",
        "JobTitle": "geriatric nursing supervisor"
    },
    {
        "value": 2423,
        "NOCCode": "3011",
        "JobTitle": "head nurse"
    },
    {
        "value": 2424,
        "NOCCode": "3011",
        "JobTitle": "nursing care co-ordinator"
    },
    {
        "value": 2425,
        "NOCCode": "3011",
        "JobTitle": "nursing co-ordinator"
    },
    {
        "value": 2426,
        "NOCCode": "3011",
        "JobTitle": "nursing services co-ordinator"
    },
    {
        "value": 2427,
        "NOCCode": "3011",
        "JobTitle": "nursing supervisor"
    },
    {
        "value": 2428,
        "NOCCode": "3011",
        "JobTitle": "nursing supervisor, operating room"
    },
    {
        "value": 2429,
        "NOCCode": "3011",
        "JobTitle": "nursing unit co-ordinator"
    },
    {
        "value": 2430,
        "NOCCode": "3011",
        "JobTitle": "nursing unit supervisor"
    },
    {
        "value": 2431,
        "NOCCode": "3011",
        "JobTitle": "nursing ward supervisor"
    },
    {
        "value": 2432,
        "NOCCode": "3011",
        "JobTitle": "obstetrical nursing supervisor"
    },
    {
        "value": 2433,
        "NOCCode": "3011",
        "JobTitle": "operating room nursing supervisor"
    },
    {
        "value": 2434,
        "NOCCode": "3011",
        "JobTitle": "patient care co-ordinator – nursing"
    },
    {
        "value": 2435,
        "NOCCode": "3011",
        "JobTitle": "psychiatric nursing supervisor"
    },
    {
        "value": 2436,
        "NOCCode": "3011",
        "JobTitle": "public health nursing supervisor"
    },
    {
        "value": 2437,
        "NOCCode": "3011",
        "JobTitle": "rehabilitation nursing supervisor"
    },
    {
        "value": 2438,
        "NOCCode": "3011",
        "JobTitle": "supervisor, nursing"
    },
    {
        "value": 2439,
        "NOCCode": "3011",
        "JobTitle": "supervisor, nursing ward"
    },
    {
        "value": 2440,
        "NOCCode": "3011",
        "JobTitle": "surgical co-ordinator – nursing"
    },
    {
        "value": 2441,
        "NOCCode": "3011",
        "JobTitle": "surgical ward supervisor – nursing"
    },
    {
        "value": 2442,
        "NOCCode": "3011",
        "JobTitle": "ward supervisor – nursing"
    },
    {
        "value": 2443,
        "NOCCode": "3012",
        "JobTitle": "ambulatory care nurse"
    },
    {
        "value": 2444,
        "NOCCode": "3012",
        "JobTitle": "bedside nurse"
    },
    {
        "value": 2445,
        "NOCCode": "3012",
        "JobTitle": "burn patient care nurse"
    },
    {
        "value": 2446,
        "NOCCode": "3012",
        "JobTitle": "burn victim care nurse"
    },
    {
        "value": 2447,
        "NOCCode": "3012",
        "JobTitle": "cardiac surgery nurse"
    },
    {
        "value": 2448,
        "NOCCode": "3012",
        "JobTitle": "cardiology nurse"
    },
    {
        "value": 2449,
        "NOCCode": "3012",
        "JobTitle": "cardiology nurse technician"
    },
    {
        "value": 2450,
        "NOCCode": "3012",
        "JobTitle": "casual duty nurse"
    },
    {
        "value": 2451,
        "NOCCode": "3012",
        "JobTitle": "circulating nurse"
    },
    {
        "value": 2452,
        "NOCCode": "3012",
        "JobTitle": "clinic nurse"
    },
    {
        "value": 2453,
        "NOCCode": "3012",
        "JobTitle": "clinical nurse"
    },
    {
        "value": 2454,
        "NOCCode": "3012",
        "JobTitle": "clinical nurse specialist"
    },
    {
        "value": 2455,
        "NOCCode": "3012",
        "JobTitle": "community health nurse"
    },
    {
        "value": 2456,
        "NOCCode": "3012",
        "JobTitle": "consultant nurse"
    },
    {
        "value": 2457,
        "NOCCode": "3012",
        "JobTitle": "consultant, nursing"
    },
    {
        "value": 2458,
        "NOCCode": "3012",
        "JobTitle": "contagious disease transmission prevention nurse"
    },
    {
        "value": 2459,
        "NOCCode": "3012",
        "JobTitle": "co-ordinator, infection control – hospital"
    },
    {
        "value": 2460,
        "NOCCode": "3012",
        "JobTitle": "coronary intensive care nurse"
    },
    {
        "value": 2461,
        "NOCCode": "3012",
        "JobTitle": "coronary unit nurse"
    },
    {
        "value": 2462,
        "NOCCode": "3012",
        "JobTitle": "critical care nurse"
    },
    {
        "value": 2463,
        "NOCCode": "3012",
        "JobTitle": "dialysis nurse"
    },
    {
        "value": 2464,
        "NOCCode": "3012",
        "JobTitle": "doctor's office nurse"
    },
    {
        "value": 2465,
        "NOCCode": "3012",
        "JobTitle": "emergency care nurse"
    },
    {
        "value": 2466,
        "NOCCode": "3012",
        "JobTitle": "emergency nurse"
    },
    {
        "value": 2467,
        "NOCCode": "3012",
        "JobTitle": "first aid nurse"
    },
    {
        "value": 2468,
        "NOCCode": "3012",
        "JobTitle": "float nurse"
    },
    {
        "value": 2469,
        "NOCCode": "3012",
        "JobTitle": "general duty nurse"
    },
    {
        "value": 2470,
        "NOCCode": "3012",
        "JobTitle": "general duty nurse – psychiatry"
    },
    {
        "value": 2471,
        "NOCCode": "3012",
        "JobTitle": "geriatric care nurse"
    },
    {
        "value": 2472,
        "NOCCode": "3012",
        "JobTitle": "geriatric nurse"
    },
    {
        "value": 2473,
        "NOCCode": "3012",
        "JobTitle": "gerontology nurse"
    },
    {
        "value": 2474,
        "NOCCode": "3012",
        "JobTitle": "graduate nurse"
    },
    {
        "value": 2475,
        "NOCCode": "3012",
        "JobTitle": "graduate nurse – doctor's office"
    },
    {
        "value": 2476,
        "NOCCode": "3012",
        "JobTitle": "graduate nurse – occupational health"
    },
    {
        "value": 2477,
        "NOCCode": "3012",
        "JobTitle": "graduate nurse – physician's office"
    },
    {
        "value": 2478,
        "NOCCode": "3012",
        "JobTitle": "graduate private duty nurse"
    },
    {
        "value": 2479,
        "NOCCode": "3012",
        "JobTitle": "graduate religious nurse"
    },
    {
        "value": 2480,
        "NOCCode": "3012",
        "JobTitle": "hemodialysis nurse"
    },
    {
        "value": 2481,
        "NOCCode": "3012",
        "JobTitle": "home care neuroscience nurse"
    },
    {
        "value": 2482,
        "NOCCode": "3012",
        "JobTitle": "home care nurse"
    },
    {
        "value": 2483,
        "NOCCode": "3012",
        "JobTitle": "home care nurse, neuroscience"
    },
    {
        "value": 2484,
        "NOCCode": "3012",
        "JobTitle": "hospital hygiene nurse"
    },
    {
        "value": 2485,
        "NOCCode": "3012",
        "JobTitle": "hospital hygienist – nursing"
    },
    {
        "value": 2486,
        "NOCCode": "3012",
        "JobTitle": "hospital nurse"
    },
    {
        "value": 2487,
        "NOCCode": "3012",
        "JobTitle": "ICU (intensive care unit) nurse"
    },
    {
        "value": 2488,
        "NOCCode": "3012",
        "JobTitle": "independent practice – registered nurse"
    },
    {
        "value": 2489,
        "NOCCode": "3012",
        "JobTitle": "industrial nurse"
    },
    {
        "value": 2490,
        "NOCCode": "3012",
        "JobTitle": "infection control co-ordinator – hospital"
    },
    {
        "value": 2491,
        "NOCCode": "3012",
        "JobTitle": "infection control nurse"
    },
    {
        "value": 2492,
        "NOCCode": "3012",
        "JobTitle": "infection control officer – hospital"
    },
    {
        "value": 2493,
        "NOCCode": "3012",
        "JobTitle": "infection surveillance nurse"
    },
    {
        "value": 2494,
        "NOCCode": "3012",
        "JobTitle": "intensive care nurse"
    },
    {
        "value": 2495,
        "NOCCode": "3012",
        "JobTitle": "intensive care unit (ICU) nurse"
    },
    {
        "value": 2496,
        "NOCCode": "3012",
        "JobTitle": "liaison nurse"
    },
    {
        "value": 2497,
        "NOCCode": "3012",
        "JobTitle": "medical intensive care nurse"
    },
    {
        "value": 2498,
        "NOCCode": "3012",
        "JobTitle": "mental health nurse"
    },
    {
        "value": 2499,
        "NOCCode": "3012",
        "JobTitle": "missionary nurse"
    },
    {
        "value": 2500,
        "NOCCode": "3012",
        "JobTitle": "nephrology and kidney transplantation nurse"
    },
    {
        "value": 2501,
        "NOCCode": "3012",
        "JobTitle": "nephrology and renal transplantation nurse"
    },
    {
        "value": 2502,
        "NOCCode": "3012",
        "JobTitle": "nephrology nurse"
    },
    {
        "value": 2503,
        "NOCCode": "3012",
        "JobTitle": "neuroscience nurse"
    },
    {
        "value": 2504,
        "NOCCode": "3012",
        "JobTitle": "nurse"
    },
    {
        "value": 2505,
        "NOCCode": "3012",
        "JobTitle": "nurse – birthing room"
    },
    {
        "value": 2506,
        "NOCCode": "3012",
        "JobTitle": "nurse consultant"
    },
    {
        "value": 2507,
        "NOCCode": "3012",
        "JobTitle": "nurse counsellor"
    },
    {
        "value": 2508,
        "NOCCode": "3012",
        "JobTitle": "nurse employed at national level"
    },
    {
        "value": 2509,
        "NOCCode": "3012",
        "JobTitle": "nurse of Native ancestry"
    },
    {
        "value": 2510,
        "NOCCode": "3012",
        "JobTitle": "nurse researcher"
    },
    {
        "value": 2511,
        "NOCCode": "3012",
        "JobTitle": "nurse technician"
    },
    {
        "value": 2512,
        "NOCCode": "3012",
        "JobTitle": "nurse, contagious disease transmission prevention"
    },
    {
        "value": 2513,
        "NOCCode": "3012",
        "JobTitle": "nurse, geriatric"
    },
    {
        "value": 2514,
        "NOCCode": "3012",
        "JobTitle": "nurse, hospital hygiene"
    },
    {
        "value": 2515,
        "NOCCode": "3012",
        "JobTitle": "nursing adviser"
    },
    {
        "value": 2516,
        "NOCCode": "3012",
        "JobTitle": "nursing consultant"
    },
    {
        "value": 2517,
        "NOCCode": "3012",
        "JobTitle": "nursing counsellor"
    },
    {
        "value": 2518,
        "NOCCode": "3012",
        "JobTitle": "nursing evaluator"
    },
    {
        "value": 2519,
        "NOCCode": "3012",
        "JobTitle": "nursing researcher"
    },
    {
        "value": 2520,
        "NOCCode": "3012",
        "JobTitle": "nursing researcher – nurse"
    },
    {
        "value": 2521,
        "NOCCode": "3012",
        "JobTitle": "nursing technician"
    },
    {
        "value": 2522,
        "NOCCode": "3012",
        "JobTitle": "obstetrics nurse"
    },
    {
        "value": 2523,
        "NOCCode": "3012",
        "JobTitle": "occupational health nurse"
    },
    {
        "value": 2524,
        "NOCCode": "3012",
        "JobTitle": "occupational health nurse consultant"
    },
    {
        "value": 2525,
        "NOCCode": "3012",
        "JobTitle": "occupational medicine nurse"
    },
    {
        "value": 2526,
        "NOCCode": "3012",
        "JobTitle": "office nurse"
    },
    {
        "value": 2527,
        "NOCCode": "3012",
        "JobTitle": "officer, infection control – hospital"
    },
    {
        "value": 2528,
        "NOCCode": "3012",
        "JobTitle": "on-call nurse"
    },
    {
        "value": 2529,
        "NOCCode": "3012",
        "JobTitle": "oncology nurse"
    },
    {
        "value": 2530,
        "NOCCode": "3012",
        "JobTitle": "operating room (OR) nurse"
    },
    {
        "value": 2531,
        "NOCCode": "3012",
        "JobTitle": "OR (operating room) nurse"
    },
    {
        "value": 2532,
        "NOCCode": "3012",
        "JobTitle": "out-patient clinic nurse"
    },
    {
        "value": 2533,
        "NOCCode": "3012",
        "JobTitle": "outpost nurse"
    },
    {
        "value": 2534,
        "NOCCode": "3012",
        "JobTitle": "outreach nurse"
    },
    {
        "value": 2535,
        "NOCCode": "3012",
        "JobTitle": "palliative care nurse"
    },
    {
        "value": 2536,
        "NOCCode": "3012",
        "JobTitle": "pediatric intensive care nurse"
    },
    {
        "value": 2537,
        "NOCCode": "3012",
        "JobTitle": "pediatric nurse"
    },
    {
        "value": 2538,
        "NOCCode": "3012",
        "JobTitle": "perinatal care nurse"
    },
    {
        "value": 2539,
        "NOCCode": "3012",
        "JobTitle": "physician's office nurse"
    },
    {
        "value": 2540,
        "NOCCode": "3012",
        "JobTitle": "primary care nurse"
    },
    {
        "value": 2541,
        "NOCCode": "3012",
        "JobTitle": "primary nurse"
    },
    {
        "value": 2542,
        "NOCCode": "3012",
        "JobTitle": "private care nurse"
    },
    {
        "value": 2543,
        "NOCCode": "3012",
        "JobTitle": "private duty nurse"
    },
    {
        "value": 2544,
        "NOCCode": "3012",
        "JobTitle": "psychiatric and mental health nurse"
    },
    {
        "value": 2545,
        "NOCCode": "3012",
        "JobTitle": "psychiatric nurse"
    },
    {
        "value": 2546,
        "NOCCode": "3012",
        "JobTitle": "public and community health nurse"
    },
    {
        "value": 2547,
        "NOCCode": "3012",
        "JobTitle": "public health nurse"
    },
    {
        "value": 2548,
        "NOCCode": "3012",
        "JobTitle": "public health nurse consultant"
    },
    {
        "value": 2549,
        "NOCCode": "3012",
        "JobTitle": "recovery room nurse"
    },
    {
        "value": 2550,
        "NOCCode": "3012",
        "JobTitle": "Red Cross nurse"
    },
    {
        "value": 2551,
        "NOCCode": "3012",
        "JobTitle": "registered community health nurse"
    },
    {
        "value": 2552,
        "NOCCode": "3012",
        "JobTitle": "registered industrial nurse"
    },
    {
        "value": 2553,
        "NOCCode": "3012",
        "JobTitle": "registered nurse – call centre"
    },
    {
        "value": 2554,
        "NOCCode": "3012",
        "JobTitle": "registered nurse – contact centre"
    },
    {
        "value": 2555,
        "NOCCode": "3012",
        "JobTitle": "registered nurse – independent practice"
    },
    {
        "value": 2556,
        "NOCCode": "3012",
        "JobTitle": "registered nurse – public and community health"
    },
    {
        "value": 2557,
        "NOCCode": "3012",
        "JobTitle": "registered nurse – telehealth"
    },
    {
        "value": 2558,
        "NOCCode": "3012",
        "JobTitle": "registered nurse (R.N.)"
    },
    {
        "value": 2559,
        "NOCCode": "3012",
        "JobTitle": "registered psychiatric nurse (R.P.N.)"
    },
    {
        "value": 2560,
        "NOCCode": "3012",
        "JobTitle": "registered public health nurse"
    },
    {
        "value": 2561,
        "NOCCode": "3012",
        "JobTitle": "religious nurse"
    },
    {
        "value": 2562,
        "NOCCode": "3012",
        "JobTitle": "respiratory care nurse"
    },
    {
        "value": 2563,
        "NOCCode": "3012",
        "JobTitle": "respiratory nurse specialist"
    },
    {
        "value": 2564,
        "NOCCode": "3012",
        "JobTitle": "RN (registered nurse)"
    },
    {
        "value": 2565,
        "NOCCode": "3012",
        "JobTitle": "RPN (registered psychiatric nurse)"
    },
    {
        "value": 2566,
        "NOCCode": "3012",
        "JobTitle": "school health nurse"
    },
    {
        "value": 2567,
        "NOCCode": "3012",
        "JobTitle": "school nurse"
    },
    {
        "value": 2568,
        "NOCCode": "3012",
        "JobTitle": "scrub nurse"
    },
    {
        "value": 2569,
        "NOCCode": "3012",
        "JobTitle": "staff nurse"
    },
    {
        "value": 2570,
        "NOCCode": "3012",
        "JobTitle": "surgical intensive care nurse"
    },
    {
        "value": 2571,
        "NOCCode": "3012",
        "JobTitle": "telehealth registered nurse"
    },
    {
        "value": 2572,
        "NOCCode": "3012",
        "JobTitle": "tele-nurse"
    },
    {
        "value": 2573,
        "NOCCode": "3012",
        "JobTitle": "triage nurse"
    },
    {
        "value": 2574,
        "NOCCode": "3012",
        "JobTitle": "Victoria Order nurse"
    },
    {
        "value": 2575,
        "NOCCode": "3012",
        "JobTitle": "Victorian Order of Nurses (VON) nurse"
    },
    {
        "value": 2576,
        "NOCCode": "3012",
        "JobTitle": "visiting nurse"
    },
    {
        "value": 2577,
        "NOCCode": "3012",
        "JobTitle": "VON (Victorian Order of Nurses) nurse"
    },
    {
        "value": 2578,
        "NOCCode": "3012",
        "JobTitle": "ward nurse"
    },
    {
        "value": 2579,
        "NOCCode": "3012",
        "JobTitle": "youth centre nurse"
    },
    {
        "value": 2580,
        "NOCCode": "3111",
        "JobTitle": "acute care specialist"
    },
    {
        "value": 2581,
        "NOCCode": "3111",
        "JobTitle": "allergist"
    },
    {
        "value": 2582,
        "NOCCode": "3111",
        "JobTitle": "allergologist"
    },
    {
        "value": 2583,
        "NOCCode": "3111",
        "JobTitle": "anatomical pathologist"
    },
    {
        "value": 2584,
        "NOCCode": "3111",
        "JobTitle": "anatomopathologist"
    },
    {
        "value": 2585,
        "NOCCode": "3111",
        "JobTitle": "anesthesiologist"
    },
    {
        "value": 2586,
        "NOCCode": "3111",
        "JobTitle": "anesthetist"
    },
    {
        "value": 2587,
        "NOCCode": "3111",
        "JobTitle": "anesthetist-resuscitator"
    },
    {
        "value": 2588,
        "NOCCode": "3111",
        "JobTitle": "autopsy pathologist"
    },
    {
        "value": 2589,
        "NOCCode": "3111",
        "JobTitle": "biochemist, medical – physician"
    },
    {
        "value": 2590,
        "NOCCode": "3111",
        "JobTitle": "cardiac surgeon"
    },
    {
        "value": 2591,
        "NOCCode": "3111",
        "JobTitle": "cardiologist"
    },
    {
        "value": 2592,
        "NOCCode": "3111",
        "JobTitle": "cardiology specialist"
    },
    {
        "value": 2593,
        "NOCCode": "3111",
        "JobTitle": "cardiothoracic surgeon"
    },
    {
        "value": 2594,
        "NOCCode": "3111",
        "JobTitle": "cardiovascular and thoracic surgeon"
    },
    {
        "value": 2595,
        "NOCCode": "3111",
        "JobTitle": "cardiovascular and thoracic surgery specialist"
    },
    {
        "value": 2596,
        "NOCCode": "3111",
        "JobTitle": "cardiovascular surgeon"
    },
    {
        "value": 2597,
        "NOCCode": "3111",
        "JobTitle": "child specialist"
    },
    {
        "value": 2598,
        "NOCCode": "3111",
        "JobTitle": "clinical immunologist"
    },
    {
        "value": 2599,
        "NOCCode": "3111",
        "JobTitle": "clinical immunologist-allergist"
    },
    {
        "value": 2600,
        "NOCCode": "3111",
        "JobTitle": "clinical immunology and allergy specialist"
    },
    {
        "value": 2601,
        "NOCCode": "3111",
        "JobTitle": "clinical pathologist"
    },
    {
        "value": 2602,
        "NOCCode": "3111",
        "JobTitle": "clinical pharmacologist"
    },
    {
        "value": 2603,
        "NOCCode": "3111",
        "JobTitle": "community health physician"
    },
    {
        "value": 2604,
        "NOCCode": "3111",
        "JobTitle": "community medicine physician"
    },
    {
        "value": 2605,
        "NOCCode": "3111",
        "JobTitle": "community medicine specialist"
    },
    {
        "value": 2606,
        "NOCCode": "3111",
        "JobTitle": "cosmetic surgeon"
    },
    {
        "value": 2607,
        "NOCCode": "3111",
        "JobTitle": "critical care medicine physician"
    },
    {
        "value": 2608,
        "NOCCode": "3111",
        "JobTitle": "critical care medicine specialist"
    },
    {
        "value": 2609,
        "NOCCode": "3111",
        "JobTitle": "dermatologist"
    },
    {
        "value": 2610,
        "NOCCode": "3111",
        "JobTitle": "diagnostic radiologist"
    },
    {
        "value": 2611,
        "NOCCode": "3111",
        "JobTitle": "diagnostic radiology specialist"
    },
    {
        "value": 2612,
        "NOCCode": "3111",
        "JobTitle": "ear specialist"
    },
    {
        "value": 2613,
        "NOCCode": "3111",
        "JobTitle": "emergency medicine physician"
    },
    {
        "value": 2614,
        "NOCCode": "3111",
        "JobTitle": "emergency medicine specialist"
    },
    {
        "value": 2615,
        "NOCCode": "3111",
        "JobTitle": "emergency physician"
    },
    {
        "value": 2616,
        "NOCCode": "3111",
        "JobTitle": "emergentologist"
    },
    {
        "value": 2617,
        "NOCCode": "3111",
        "JobTitle": "endocrinologist"
    },
    {
        "value": 2618,
        "NOCCode": "3111",
        "JobTitle": "environmental medicine specialist"
    },
    {
        "value": 2619,
        "NOCCode": "3111",
        "JobTitle": "esthetic surgeon"
    },
    {
        "value": 2620,
        "NOCCode": "3111",
        "JobTitle": "eye specialist"
    },
    {
        "value": 2621,
        "NOCCode": "3111",
        "JobTitle": "forensic pathologist"
    },
    {
        "value": 2622,
        "NOCCode": "3111",
        "JobTitle": "gastroenterologist"
    },
    {
        "value": 2623,
        "NOCCode": "3111",
        "JobTitle": "general pathologist"
    },
    {
        "value": 2624,
        "NOCCode": "3111",
        "JobTitle": "general surgeon"
    },
    {
        "value": 2625,
        "NOCCode": "3111",
        "JobTitle": "geneticist – medical specialist"
    },
    {
        "value": 2626,
        "NOCCode": "3111",
        "JobTitle": "geriatric medicine specialist"
    },
    {
        "value": 2627,
        "NOCCode": "3111",
        "JobTitle": "geriatrician"
    },
    {
        "value": 2628,
        "NOCCode": "3111",
        "JobTitle": "geriatrics specialist"
    },
    {
        "value": 2629,
        "NOCCode": "3111",
        "JobTitle": "geriatrist"
    },
    {
        "value": 2630,
        "NOCCode": "3111",
        "JobTitle": "gynecologist"
    },
    {
        "value": 2631,
        "NOCCode": "3111",
        "JobTitle": "heart specialist"
    },
    {
        "value": 2632,
        "NOCCode": "3111",
        "JobTitle": "hematological-pathologist"
    },
    {
        "value": 2633,
        "NOCCode": "3111",
        "JobTitle": "hematologist"
    },
    {
        "value": 2634,
        "NOCCode": "3111",
        "JobTitle": "hematopathologist"
    },
    {
        "value": 2635,
        "NOCCode": "3111",
        "JobTitle": "hematopathology specialist"
    },
    {
        "value": 2636,
        "NOCCode": "3111",
        "JobTitle": "histopathologist – medical specialist"
    },
    {
        "value": 2637,
        "NOCCode": "3111",
        "JobTitle": "hyperbaric physician"
    },
    {
        "value": 2638,
        "NOCCode": "3111",
        "JobTitle": "immunologist and allergologist"
    },
    {
        "value": 2639,
        "NOCCode": "3111",
        "JobTitle": "infectious disease physician"
    },
    {
        "value": 2640,
        "NOCCode": "3111",
        "JobTitle": "infectious disease specialist"
    },
    {
        "value": 2641,
        "NOCCode": "3111",
        "JobTitle": "infertility specialist"
    },
    {
        "value": 2642,
        "NOCCode": "3111",
        "JobTitle": "inpatient physician"
    },
    {
        "value": 2643,
        "NOCCode": "3111",
        "JobTitle": "internal medicine specialist"
    },
    {
        "value": 2644,
        "NOCCode": "3111",
        "JobTitle": "internist"
    },
    {
        "value": 2645,
        "NOCCode": "3111",
        "JobTitle": "laryngologist"
    },
    {
        "value": 2646,
        "NOCCode": "3111",
        "JobTitle": "lung specialist"
    },
    {
        "value": 2647,
        "NOCCode": "3111",
        "JobTitle": "medical bacteriologist"
    },
    {
        "value": 2648,
        "NOCCode": "3111",
        "JobTitle": "medical biochemist – physician"
    },
    {
        "value": 2649,
        "NOCCode": "3111",
        "JobTitle": "medical examiner"
    },
    {
        "value": 2650,
        "NOCCode": "3111",
        "JobTitle": "medical geneticist"
    },
    {
        "value": 2651,
        "NOCCode": "3111",
        "JobTitle": "medical genetics specialist"
    },
    {
        "value": 2652,
        "NOCCode": "3111",
        "JobTitle": "medical microbiologist"
    },
    {
        "value": 2653,
        "NOCCode": "3111",
        "JobTitle": "medical oncologist"
    },
    {
        "value": 2654,
        "NOCCode": "3111",
        "JobTitle": "medical oncology specialist"
    },
    {
        "value": 2655,
        "NOCCode": "3111",
        "JobTitle": "medical parasitologist"
    },
    {
        "value": 2656,
        "NOCCode": "3111",
        "JobTitle": "medical pathologist"
    },
    {
        "value": 2657,
        "NOCCode": "3111",
        "JobTitle": "medical physiologist"
    },
    {
        "value": 2658,
        "NOCCode": "3111",
        "JobTitle": "medical specialist"
    },
    {
        "value": 2659,
        "NOCCode": "3111",
        "JobTitle": "neonatologist"
    },
    {
        "value": 2660,
        "NOCCode": "3111",
        "JobTitle": "nephrologist"
    },
    {
        "value": 2661,
        "NOCCode": "3111",
        "JobTitle": "nerve specialist"
    },
    {
        "value": 2662,
        "NOCCode": "3111",
        "JobTitle": "neurological surgeon"
    },
    {
        "value": 2663,
        "NOCCode": "3111",
        "JobTitle": "neurologist"
    },
    {
        "value": 2664,
        "NOCCode": "3111",
        "JobTitle": "neuropathologist"
    },
    {
        "value": 2665,
        "NOCCode": "3111",
        "JobTitle": "neuropsychiatrist"
    },
    {
        "value": 2666,
        "NOCCode": "3111",
        "JobTitle": "neurosurgeon"
    },
    {
        "value": 2667,
        "NOCCode": "3111",
        "JobTitle": "nose specialist"
    },
    {
        "value": 2668,
        "NOCCode": "3111",
        "JobTitle": "nuclear medicine physician"
    },
    {
        "value": 2669,
        "NOCCode": "3111",
        "JobTitle": "nuclear medicine specialist"
    },
    {
        "value": 2670,
        "NOCCode": "3111",
        "JobTitle": "nuclear physician"
    },
    {
        "value": 2671,
        "NOCCode": "3111",
        "JobTitle": "obstetrician"
    },
    {
        "value": 2672,
        "NOCCode": "3111",
        "JobTitle": "obstetrician-gynecologist"
    },
    {
        "value": 2673,
        "NOCCode": "3111",
        "JobTitle": "occupational health physician (OHP)"
    },
    {
        "value": 2674,
        "NOCCode": "3111",
        "JobTitle": "occupational medicine specialist"
    },
    {
        "value": 2675,
        "NOCCode": "3111",
        "JobTitle": "occupational physician"
    },
    {
        "value": 2676,
        "NOCCode": "3111",
        "JobTitle": "oculist"
    },
    {
        "value": 2677,
        "NOCCode": "3111",
        "JobTitle": "OHP (occupational health physician)"
    },
    {
        "value": 2678,
        "NOCCode": "3111",
        "JobTitle": "oncologist"
    },
    {
        "value": 2679,
        "NOCCode": "3111",
        "JobTitle": "oncologist, pediatric"
    },
    {
        "value": 2680,
        "NOCCode": "3111",
        "JobTitle": "oncologist, radiation"
    },
    {
        "value": 2681,
        "NOCCode": "3111",
        "JobTitle": "ophthalmologist"
    },
    {
        "value": 2682,
        "NOCCode": "3111",
        "JobTitle": "orthopedic surgeon"
    },
    {
        "value": 2683,
        "NOCCode": "3111",
        "JobTitle": "orthopedics specialist"
    },
    {
        "value": 2684,
        "NOCCode": "3111",
        "JobTitle": "orthopedist"
    },
    {
        "value": 2685,
        "NOCCode": "3111",
        "JobTitle": "otolaryngologist"
    },
    {
        "value": 2686,
        "NOCCode": "3111",
        "JobTitle": "otologist"
    },
    {
        "value": 2687,
        "NOCCode": "3111",
        "JobTitle": "otorhinolaryngologist"
    },
    {
        "value": 2688,
        "NOCCode": "3111",
        "JobTitle": "otorhinolaryngology specialist"
    },
    {
        "value": 2689,
        "NOCCode": "3111",
        "JobTitle": "pathologist – medical"
    },
    {
        "value": 2690,
        "NOCCode": "3111",
        "JobTitle": "pathologist, autopsy"
    },
    {
        "value": 2691,
        "NOCCode": "3111",
        "JobTitle": "pathologist-hematologist"
    },
    {
        "value": 2692,
        "NOCCode": "3111",
        "JobTitle": "pediatric oncologist"
    },
    {
        "value": 2693,
        "NOCCode": "3111",
        "JobTitle": "pediatric surgeon"
    },
    {
        "value": 2694,
        "NOCCode": "3111",
        "JobTitle": "pediatrician"
    },
    {
        "value": 2695,
        "NOCCode": "3111",
        "JobTitle": "phoniatrician"
    },
    {
        "value": 2696,
        "NOCCode": "3111",
        "JobTitle": "phoniatrist"
    },
    {
        "value": 2697,
        "NOCCode": "3111",
        "JobTitle": "physiatrist"
    },
    {
        "value": 2698,
        "NOCCode": "3111",
        "JobTitle": "physical medicine and rehabilitation specialist"
    },
    {
        "value": 2699,
        "NOCCode": "3111",
        "JobTitle": "physician, community health"
    },
    {
        "value": 2700,
        "NOCCode": "3111",
        "JobTitle": "physician, community medicine"
    },
    {
        "value": 2701,
        "NOCCode": "3111",
        "JobTitle": "physician, critical care medicine"
    },
    {
        "value": 2702,
        "NOCCode": "3111",
        "JobTitle": "physician, emergency"
    },
    {
        "value": 2703,
        "NOCCode": "3111",
        "JobTitle": "physician, infectious diseases"
    },
    {
        "value": 2704,
        "NOCCode": "3111",
        "JobTitle": "physician, nuclear medicine"
    },
    {
        "value": 2705,
        "NOCCode": "3111",
        "JobTitle": "physician, occupational health"
    },
    {
        "value": 2706,
        "NOCCode": "3111",
        "JobTitle": "physician, preventive medicine"
    },
    {
        "value": 2707,
        "NOCCode": "3111",
        "JobTitle": "physician, pulmonary medicine"
    },
    {
        "value": 2708,
        "NOCCode": "3111",
        "JobTitle": "physician, sports medicine"
    },
    {
        "value": 2709,
        "NOCCode": "3111",
        "JobTitle": "physician, tropical medicine"
    },
    {
        "value": 2710,
        "NOCCode": "3111",
        "JobTitle": "physiologist, medical"
    },
    {
        "value": 2711,
        "NOCCode": "3111",
        "JobTitle": "plastic surgeon"
    },
    {
        "value": 2712,
        "NOCCode": "3111",
        "JobTitle": "plastic surgery specialist"
    },
    {
        "value": 2713,
        "NOCCode": "3111",
        "JobTitle": "pneumologist"
    },
    {
        "value": 2714,
        "NOCCode": "3111",
        "JobTitle": "police surgeon"
    },
    {
        "value": 2715,
        "NOCCode": "3111",
        "JobTitle": "preventive medicine physician"
    },
    {
        "value": 2716,
        "NOCCode": "3111",
        "JobTitle": "preventive medicine specialist"
    },
    {
        "value": 2717,
        "NOCCode": "3111",
        "JobTitle": "proctologist"
    },
    {
        "value": 2718,
        "NOCCode": "3111",
        "JobTitle": "psychiatrist"
    },
    {
        "value": 2719,
        "NOCCode": "3111",
        "JobTitle": "psychopharmacologist"
    },
    {
        "value": 2720,
        "NOCCode": "3111",
        "JobTitle": "pulmonary disease specialist"
    },
    {
        "value": 2721,
        "NOCCode": "3111",
        "JobTitle": "pulmonary medicine physician"
    },
    {
        "value": 2722,
        "NOCCode": "3111",
        "JobTitle": "pulmonary medicine specialist"
    },
    {
        "value": 2723,
        "NOCCode": "3111",
        "JobTitle": "pulmonologist"
    },
    {
        "value": 2724,
        "NOCCode": "3111",
        "JobTitle": "radiation oncologist"
    },
    {
        "value": 2725,
        "NOCCode": "3111",
        "JobTitle": "radiologist"
    },
    {
        "value": 2726,
        "NOCCode": "3111",
        "JobTitle": "radiologist, diagnostic"
    },
    {
        "value": 2727,
        "NOCCode": "3111",
        "JobTitle": "radio-oncologist"
    },
    {
        "value": 2728,
        "NOCCode": "3111",
        "JobTitle": "radio-oncology specialist"
    },
    {
        "value": 2729,
        "NOCCode": "3111",
        "JobTitle": "rehabilitation specialist"
    },
    {
        "value": 2730,
        "NOCCode": "3111",
        "JobTitle": "respiratory disease specialist"
    },
    {
        "value": 2731,
        "NOCCode": "3111",
        "JobTitle": "respirologist"
    },
    {
        "value": 2732,
        "NOCCode": "3111",
        "JobTitle": "rheumatologist"
    },
    {
        "value": 2733,
        "NOCCode": "3111",
        "JobTitle": "rhinologist"
    },
    {
        "value": 2734,
        "NOCCode": "3111",
        "JobTitle": "skin disease specialist"
    },
    {
        "value": 2735,
        "NOCCode": "3111",
        "JobTitle": "skin specialist"
    },
    {
        "value": 2736,
        "NOCCode": "3111",
        "JobTitle": "specialist doctor"
    },
    {
        "value": 2737,
        "NOCCode": "3111",
        "JobTitle": "specialist in cardiology"
    },
    {
        "value": 2738,
        "NOCCode": "3111",
        "JobTitle": "specialist in cardiovascular and thoracic surgery"
    },
    {
        "value": 2739,
        "NOCCode": "3111",
        "JobTitle": "specialist in community medicine"
    },
    {
        "value": 2740,
        "NOCCode": "3111",
        "JobTitle": "specialist in critical care medicine"
    },
    {
        "value": 2741,
        "NOCCode": "3111",
        "JobTitle": "specialist in emergency medicine"
    },
    {
        "value": 2742,
        "NOCCode": "3111",
        "JobTitle": "specialist in environmental medicine"
    },
    {
        "value": 2743,
        "NOCCode": "3111",
        "JobTitle": "specialist in epidemiology and community medicine"
    },
    {
        "value": 2744,
        "NOCCode": "3111",
        "JobTitle": "specialist in geriatric medicine"
    },
    {
        "value": 2745,
        "NOCCode": "3111",
        "JobTitle": "specialist in hematopathology"
    },
    {
        "value": 2746,
        "NOCCode": "3111",
        "JobTitle": "specialist in infectious diseases"
    },
    {
        "value": 2747,
        "NOCCode": "3111",
        "JobTitle": "specialist in infertility"
    },
    {
        "value": 2748,
        "NOCCode": "3111",
        "JobTitle": "specialist in internal medicine"
    },
    {
        "value": 2749,
        "NOCCode": "3111",
        "JobTitle": "specialist in medical oncology"
    },
    {
        "value": 2750,
        "NOCCode": "3111",
        "JobTitle": "specialist in nuclear medicine"
    },
    {
        "value": 2751,
        "NOCCode": "3111",
        "JobTitle": "specialist in physical medicine and rehabilitation"
    },
    {
        "value": 2752,
        "NOCCode": "3111",
        "JobTitle": "specialist in plastic surgery"
    },
    {
        "value": 2753,
        "NOCCode": "3111",
        "JobTitle": "specialist in preventive medicine"
    },
    {
        "value": 2754,
        "NOCCode": "3111",
        "JobTitle": "specialist in pulmonary disease"
    },
    {
        "value": 2755,
        "NOCCode": "3111",
        "JobTitle": "specialist in spinal injuries"
    },
    {
        "value": 2756,
        "NOCCode": "3111",
        "JobTitle": "specialist in sports medicine"
    },
    {
        "value": 2757,
        "NOCCode": "3111",
        "JobTitle": "specialist in vascular surgery"
    },
    {
        "value": 2758,
        "NOCCode": "3111",
        "JobTitle": "specialist physician"
    },
    {
        "value": 2759,
        "NOCCode": "3111",
        "JobTitle": "specialist, children"
    },
    {
        "value": 2760,
        "NOCCode": "3111",
        "JobTitle": "specialist, ears"
    },
    {
        "value": 2761,
        "NOCCode": "3111",
        "JobTitle": "specialist, eyes"
    },
    {
        "value": 2762,
        "NOCCode": "3111",
        "JobTitle": "specialist, heart"
    },
    {
        "value": 2763,
        "NOCCode": "3111",
        "JobTitle": "specialist, lungs"
    },
    {
        "value": 2764,
        "NOCCode": "3111",
        "JobTitle": "specialist, nerves"
    },
    {
        "value": 2765,
        "NOCCode": "3111",
        "JobTitle": "specialist, nose"
    },
    {
        "value": 2766,
        "NOCCode": "3111",
        "JobTitle": "specialist, occupational medicine"
    },
    {
        "value": 2767,
        "NOCCode": "3111",
        "JobTitle": "specialist, rehabilitation"
    },
    {
        "value": 2768,
        "NOCCode": "3111",
        "JobTitle": "specialist, skin"
    },
    {
        "value": 2769,
        "NOCCode": "3111",
        "JobTitle": "specialist, throat"
    },
    {
        "value": 2770,
        "NOCCode": "3111",
        "JobTitle": "spinal injury specialist"
    },
    {
        "value": 2771,
        "NOCCode": "3111",
        "JobTitle": "sports medicine physician"
    },
    {
        "value": 2772,
        "NOCCode": "3111",
        "JobTitle": "sports medicine specialist"
    },
    {
        "value": 2773,
        "NOCCode": "3111",
        "JobTitle": "surgeon"
    },
    {
        "value": 2774,
        "NOCCode": "3111",
        "JobTitle": "surgical pathologist"
    },
    {
        "value": 2775,
        "NOCCode": "3111",
        "JobTitle": "surgical physician"
    },
    {
        "value": 2776,
        "NOCCode": "3111",
        "JobTitle": "surgical resident"
    },
    {
        "value": 2777,
        "NOCCode": "3111",
        "JobTitle": "thoracic surgeon"
    },
    {
        "value": 2778,
        "NOCCode": "3111",
        "JobTitle": "throat specialist"
    },
    {
        "value": 2779,
        "NOCCode": "3111",
        "JobTitle": "tropical medicine physician"
    },
    {
        "value": 2780,
        "NOCCode": "3111",
        "JobTitle": "urologist"
    },
    {
        "value": 2781,
        "NOCCode": "3111",
        "JobTitle": "vascular surgeon"
    },
    {
        "value": 2782,
        "NOCCode": "3112",
        "JobTitle": "civil aviation medical officer"
    },
    {
        "value": 2783,
        "NOCCode": "3112",
        "JobTitle": "company physician"
    },
    {
        "value": 2784,
        "NOCCode": "3112",
        "JobTitle": "doctor of medicine (MD)"
    },
    {
        "value": 2785,
        "NOCCode": "3112",
        "JobTitle": "doctor, family"
    },
    {
        "value": 2786,
        "NOCCode": "3112",
        "JobTitle": "doctor, medical"
    },
    {
        "value": 2787,
        "NOCCode": "3112",
        "JobTitle": "family doctor"
    },
    {
        "value": 2788,
        "NOCCode": "3112",
        "JobTitle": "family physician"
    },
    {
        "value": 2789,
        "NOCCode": "3112",
        "JobTitle": "family practitioner"
    },
    {
        "value": 2790,
        "NOCCode": "3112",
        "JobTitle": "general practice intern"
    },
    {
        "value": 2791,
        "NOCCode": "3112",
        "JobTitle": "general practice physician"
    },
    {
        "value": 2792,
        "NOCCode": "3112",
        "JobTitle": "general practitioner (GP)"
    },
    {
        "value": 2793,
        "NOCCode": "3112",
        "JobTitle": "GP (general practitioner)"
    },
    {
        "value": 2794,
        "NOCCode": "3112",
        "JobTitle": "industrial medical officer"
    },
    {
        "value": 2795,
        "NOCCode": "3112",
        "JobTitle": "industrial medicine physician"
    },
    {
        "value": 2796,
        "NOCCode": "3112",
        "JobTitle": "industrial physician"
    },
    {
        "value": 2797,
        "NOCCode": "3112",
        "JobTitle": "intern"
    },
    {
        "value": 2798,
        "NOCCode": "3112",
        "JobTitle": "intern, general practice"
    },
    {
        "value": 2799,
        "NOCCode": "3112",
        "JobTitle": "MD (doctor of medicine)"
    },
    {
        "value": 2800,
        "NOCCode": "3112",
        "JobTitle": "medical doctor"
    },
    {
        "value": 2801,
        "NOCCode": "3112",
        "JobTitle": "medical officer of health (MOH)"
    },
    {
        "value": 2802,
        "NOCCode": "3112",
        "JobTitle": "medical officer, civil aviation"
    },
    {
        "value": 2803,
        "NOCCode": "3112",
        "JobTitle": "missionary doctor"
    },
    {
        "value": 2804,
        "NOCCode": "3112",
        "JobTitle": "MOH (medical officer of health)"
    },
    {
        "value": 2805,
        "NOCCode": "3112",
        "JobTitle": "physician resident"
    },
    {
        "value": 2806,
        "NOCCode": "3112",
        "JobTitle": "physician, community preventive medicine"
    },
    {
        "value": 2807,
        "NOCCode": "3112",
        "JobTitle": "physician, company"
    },
    {
        "value": 2808,
        "NOCCode": "3112",
        "JobTitle": "physician, family"
    },
    {
        "value": 2809,
        "NOCCode": "3112",
        "JobTitle": "physician, general practice"
    },
    {
        "value": 2810,
        "NOCCode": "3112",
        "JobTitle": "physician, industrial"
    },
    {
        "value": 2811,
        "NOCCode": "3112",
        "JobTitle": "physician, primary care"
    },
    {
        "value": 2812,
        "NOCCode": "3112",
        "JobTitle": "physician, public health"
    },
    {
        "value": 2813,
        "NOCCode": "3112",
        "JobTitle": "practitioner, general"
    },
    {
        "value": 2814,
        "NOCCode": "3112",
        "JobTitle": "primary care physician"
    },
    {
        "value": 2815,
        "NOCCode": "3112",
        "JobTitle": "public health physician"
    },
    {
        "value": 2816,
        "NOCCode": "3112",
        "JobTitle": "resident"
    },
    {
        "value": 2817,
        "NOCCode": "3112",
        "JobTitle": "resident physician"
    },
    {
        "value": 2818,
        "NOCCode": "3112",
        "JobTitle": "resident, general practice"
    },
    {
        "value": 2819,
        "NOCCode": "3113",
        "JobTitle": "dental surgeon"
    },
    {
        "value": 2820,
        "NOCCode": "3113",
        "JobTitle": "dentist"
    },
    {
        "value": 2821,
        "NOCCode": "3113",
        "JobTitle": "dentist, public health"
    },
    {
        "value": 2822,
        "NOCCode": "3113",
        "JobTitle": "doctor of dental surgery"
    },
    {
        "value": 2823,
        "NOCCode": "3113",
        "JobTitle": "endodontist"
    },
    {
        "value": 2824,
        "NOCCode": "3113",
        "JobTitle": "general practice dentist"
    },
    {
        "value": 2825,
        "NOCCode": "3113",
        "JobTitle": "implantologist – dentistry"
    },
    {
        "value": 2826,
        "NOCCode": "3113",
        "JobTitle": "oral and dental surgeon"
    },
    {
        "value": 2827,
        "NOCCode": "3113",
        "JobTitle": "oral and maxillofacial surgeon"
    },
    {
        "value": 2828,
        "NOCCode": "3113",
        "JobTitle": "oral medicine specialist"
    },
    {
        "value": 2829,
        "NOCCode": "3113",
        "JobTitle": "oral pathologist"
    },
    {
        "value": 2830,
        "NOCCode": "3113",
        "JobTitle": "oral pathology and maxillofacial surgery specialist"
    },
    {
        "value": 2831,
        "NOCCode": "3113",
        "JobTitle": "oral radiologist"
    },
    {
        "value": 2832,
        "NOCCode": "3113",
        "JobTitle": "oral surgeon"
    },
    {
        "value": 2833,
        "NOCCode": "3113",
        "JobTitle": "orthodontist"
    },
    {
        "value": 2834,
        "NOCCode": "3113",
        "JobTitle": "pediatric dentist"
    },
    {
        "value": 2835,
        "NOCCode": "3113",
        "JobTitle": "pedodontist"
    },
    {
        "value": 2836,
        "NOCCode": "3113",
        "JobTitle": "periodontist"
    },
    {
        "value": 2837,
        "NOCCode": "3113",
        "JobTitle": "prosthodontist"
    },
    {
        "value": 2838,
        "NOCCode": "3113",
        "JobTitle": "public health dentist"
    },
    {
        "value": 2839,
        "NOCCode": "3113",
        "JobTitle": "specialist, oral medicine"
    },
    {
        "value": 2840,
        "NOCCode": "3113",
        "JobTitle": "stomatologist"
    },
    {
        "value": 2841,
        "NOCCode": "3113",
        "JobTitle": "surgeon, dental"
    },
    {
        "value": 2842,
        "NOCCode": "3113",
        "JobTitle": "surgeon, oral"
    },
    {
        "value": 2843,
        "NOCCode": "3113",
        "JobTitle": "surgeon, oral and maxillofacial"
    },
    {
        "value": 2844,
        "NOCCode": "3114",
        "JobTitle": "anatomist, veterinary"
    },
    {
        "value": 2845,
        "NOCCode": "3114",
        "JobTitle": "animal pathologist"
    },
    {
        "value": 2846,
        "NOCCode": "3114",
        "JobTitle": "avian veterinarian"
    },
    {
        "value": 2847,
        "NOCCode": "3114",
        "JobTitle": "companion animal veterinarian"
    },
    {
        "value": 2848,
        "NOCCode": "3114",
        "JobTitle": "dentist, veterinary"
    },
    {
        "value": 2849,
        "NOCCode": "3114",
        "JobTitle": "epidemiologist, veterinary"
    },
    {
        "value": 2850,
        "NOCCode": "3114",
        "JobTitle": "equine veterinarian"
    },
    {
        "value": 2851,
        "NOCCode": "3114",
        "JobTitle": "farm veterinarian"
    },
    {
        "value": 2852,
        "NOCCode": "3114",
        "JobTitle": "food animal veterinarian"
    },
    {
        "value": 2853,
        "NOCCode": "3114",
        "JobTitle": "inspector, veterinary"
    },
    {
        "value": 2854,
        "NOCCode": "3114",
        "JobTitle": "internist, veterinary"
    },
    {
        "value": 2855,
        "NOCCode": "3114",
        "JobTitle": "laboratory animal veterinarian"
    },
    {
        "value": 2856,
        "NOCCode": "3114",
        "JobTitle": "microbiologist, veterinary"
    },
    {
        "value": 2857,
        "NOCCode": "3114",
        "JobTitle": "parasitologist, veterinary"
    },
    {
        "value": 2858,
        "NOCCode": "3114",
        "JobTitle": "pathologist, animal"
    },
    {
        "value": 2859,
        "NOCCode": "3114",
        "JobTitle": "pathologist, veterinary"
    },
    {
        "value": 2860,
        "NOCCode": "3114",
        "JobTitle": "pharmacologist, veterinary"
    },
    {
        "value": 2861,
        "NOCCode": "3114",
        "JobTitle": "physiologist, veterinary"
    },
    {
        "value": 2862,
        "NOCCode": "3114",
        "JobTitle": "poultry veterinarian"
    },
    {
        "value": 2863,
        "NOCCode": "3114",
        "JobTitle": "preventive medicine veterinarian"
    },
    {
        "value": 2864,
        "NOCCode": "3114",
        "JobTitle": "radiologist, veterinary"
    },
    {
        "value": 2865,
        "NOCCode": "3114",
        "JobTitle": "small animal veterinarian"
    },
    {
        "value": 2866,
        "NOCCode": "3114",
        "JobTitle": "small animal veterinary specialist"
    },
    {
        "value": 2867,
        "NOCCode": "3114",
        "JobTitle": "surgeon, veterinary"
    },
    {
        "value": 2868,
        "NOCCode": "3114",
        "JobTitle": "veterinarian"
    },
    {
        "value": 2869,
        "NOCCode": "3114",
        "JobTitle": "veterinarian practitioner"
    },
    {
        "value": 2870,
        "NOCCode": "3114",
        "JobTitle": "veterinarian, companion animals"
    },
    {
        "value": 2871,
        "NOCCode": "3114",
        "JobTitle": "veterinarian, farm"
    },
    {
        "value": 2872,
        "NOCCode": "3114",
        "JobTitle": "veterinarian, food animals"
    },
    {
        "value": 2873,
        "NOCCode": "3114",
        "JobTitle": "veterinarian, laboratory animals"
    },
    {
        "value": 2874,
        "NOCCode": "3114",
        "JobTitle": "veterinarian, preventive medicine"
    },
    {
        "value": 2875,
        "NOCCode": "3114",
        "JobTitle": "veterinary anatomist"
    },
    {
        "value": 2876,
        "NOCCode": "3114",
        "JobTitle": "veterinary bacteriologist"
    },
    {
        "value": 2877,
        "NOCCode": "3114",
        "JobTitle": "veterinary dentist"
    },
    {
        "value": 2878,
        "NOCCode": "3114",
        "JobTitle": "veterinary epidemiologist"
    },
    {
        "value": 2879,
        "NOCCode": "3114",
        "JobTitle": "veterinary inspector"
    },
    {
        "value": 2880,
        "NOCCode": "3114",
        "JobTitle": "veterinary internist"
    },
    {
        "value": 2881,
        "NOCCode": "3114",
        "JobTitle": "veterinary laboratory diagnostician"
    },
    {
        "value": 2882,
        "NOCCode": "3114",
        "JobTitle": "veterinary microbiologist"
    },
    {
        "value": 2883,
        "NOCCode": "3114",
        "JobTitle": "veterinary parasitologist"
    },
    {
        "value": 2884,
        "NOCCode": "3114",
        "JobTitle": "veterinary pathologist"
    },
    {
        "value": 2885,
        "NOCCode": "3114",
        "JobTitle": "veterinary pharmacologist"
    },
    {
        "value": 2886,
        "NOCCode": "3114",
        "JobTitle": "veterinary physiologist"
    },
    {
        "value": 2887,
        "NOCCode": "3114",
        "JobTitle": "veterinary radiologist"
    },
    {
        "value": 2888,
        "NOCCode": "3114",
        "JobTitle": "veterinary specialist, small animals"
    },
    {
        "value": 2889,
        "NOCCode": "3114",
        "JobTitle": "veterinary surgeon"
    },
    {
        "value": 2890,
        "NOCCode": "3114",
        "JobTitle": "veterinary virologist"
    },
    {
        "value": 2891,
        "NOCCode": "3114",
        "JobTitle": "zoo veterinarian"
    },
    {
        "value": 2892,
        "NOCCode": "3121",
        "JobTitle": "doctor of optometry (OD)"
    },
    {
        "value": 2893,
        "NOCCode": "3121",
        "JobTitle": "OD (doctor of optometry)"
    },
    {
        "value": 2894,
        "NOCCode": "3121",
        "JobTitle": "optometrist"
    },
    {
        "value": 2895,
        "NOCCode": "3122",
        "JobTitle": "chiropractic doctor"
    },
    {
        "value": 2896,
        "NOCCode": "3122",
        "JobTitle": "chiropractor"
    },
    {
        "value": 2897,
        "NOCCode": "3122",
        "JobTitle": "DC (doctor of chiropractic)"
    },
    {
        "value": 2898,
        "NOCCode": "3122",
        "JobTitle": "doctor of chiropractic"
    },
    {
        "value": 2899,
        "NOCCode": "3122",
        "JobTitle": "doctor of chiropractic (DC)"
    },
    {
        "value": 2900,
        "NOCCode": "3124",
        "JobTitle": "acute care nurse practitioner"
    },
    {
        "value": 2901,
        "NOCCode": "3124",
        "JobTitle": "advanced nurse practitioner"
    },
    {
        "value": 2902,
        "NOCCode": "3124",
        "JobTitle": "advanced practice nurse"
    },
    {
        "value": 2903,
        "NOCCode": "3124",
        "JobTitle": "anesthesia assistant"
    },
    {
        "value": 2904,
        "NOCCode": "3124",
        "JobTitle": "extended class registered nurse – nurse practitioner"
    },
    {
        "value": 2905,
        "NOCCode": "3124",
        "JobTitle": "licensed midwife"
    },
    {
        "value": 2906,
        "NOCCode": "3124",
        "JobTitle": "midwife"
    },
    {
        "value": 2907,
        "NOCCode": "3124",
        "JobTitle": "nurse practioner – adult care"
    },
    {
        "value": 2908,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner"
    },
    {
        "value": 2909,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner – anesthesia"
    },
    {
        "value": 2910,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner – cardiology"
    },
    {
        "value": 2911,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner – extended class"
    },
    {
        "value": 2912,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner – pediatrics"
    },
    {
        "value": 2913,
        "NOCCode": "3124",
        "JobTitle": "nurse practitioner – primary health care"
    },
    {
        "value": 2914,
        "NOCCode": "3124",
        "JobTitle": "nurse-midwife"
    },
    {
        "value": 2915,
        "NOCCode": "3124",
        "JobTitle": "physician assistant"
    },
    {
        "value": 2916,
        "NOCCode": "3124",
        "JobTitle": "primary health care nurse practitioner"
    },
    {
        "value": 2917,
        "NOCCode": "3124",
        "JobTitle": "registered midwife (RM)"
    },
    {
        "value": 2918,
        "NOCCode": "3124",
        "JobTitle": "registered nurse – extended class (RN-EC)"
    },
    {
        "value": 2919,
        "NOCCode": "3124",
        "JobTitle": "RM (registered midwife)"
    },
    {
        "value": 2920,
        "NOCCode": "3125",
        "JobTitle": "chief orthoptist"
    },
    {
        "value": 2921,
        "NOCCode": "3125",
        "JobTitle": "chiropodist"
    },
    {
        "value": 2922,
        "NOCCode": "3125",
        "JobTitle": "clinical orthoptist"
    },
    {
        "value": 2923,
        "NOCCode": "3125",
        "JobTitle": "D.P.M. (doctor of podiatric medicine)"
    },
    {
        "value": 2924,
        "NOCCode": "3125",
        "JobTitle": "doctor of naturopathic medicine"
    },
    {
        "value": 2925,
        "NOCCode": "3125",
        "JobTitle": "doctor of naturopathy"
    },
    {
        "value": 2926,
        "NOCCode": "3125",
        "JobTitle": "doctor of osteopathic medicine"
    },
    {
        "value": 2927,
        "NOCCode": "3125",
        "JobTitle": "doctor of osteopathy"
    },
    {
        "value": 2928,
        "NOCCode": "3125",
        "JobTitle": "doctor of podiatric medicine (D.P.M.)"
    },
    {
        "value": 2929,
        "NOCCode": "3125",
        "JobTitle": "foot specialist"
    },
    {
        "value": 2930,
        "NOCCode": "3125",
        "JobTitle": "naturopath"
    },
    {
        "value": 2931,
        "NOCCode": "3125",
        "JobTitle": "naturopathic doctor (ND)"
    },
    {
        "value": 2932,
        "NOCCode": "3125",
        "JobTitle": "naturopathic physician"
    },
    {
        "value": 2933,
        "NOCCode": "3125",
        "JobTitle": "ND (naturopathic doctor)"
    },
    {
        "value": 2934,
        "NOCCode": "3125",
        "JobTitle": "orthoptist"
    },
    {
        "value": 2935,
        "NOCCode": "3125",
        "JobTitle": "osteopath"
    },
    {
        "value": 2936,
        "NOCCode": "3125",
        "JobTitle": "osteopathic doctor"
    },
    {
        "value": 2937,
        "NOCCode": "3125",
        "JobTitle": "osteopathic physician"
    },
    {
        "value": 2938,
        "NOCCode": "3125",
        "JobTitle": "podiatrist"
    },
    {
        "value": 2939,
        "NOCCode": "3125",
        "JobTitle": "teaching orthoptist"
    },
    {
        "value": 2940,
        "NOCCode": "3131",
        "JobTitle": "clinical pharmacist"
    },
    {
        "value": 2941,
        "NOCCode": "3131",
        "JobTitle": "community pharmacist"
    },
    {
        "value": 2942,
        "NOCCode": "3131",
        "JobTitle": "dispensary department supervisor – hospital"
    },
    {
        "value": 2943,
        "NOCCode": "3131",
        "JobTitle": "drug information pharmacist"
    },
    {
        "value": 2944,
        "NOCCode": "3131",
        "JobTitle": "druggist"
    },
    {
        "value": 2945,
        "NOCCode": "3131",
        "JobTitle": "health care institution pharmacist"
    },
    {
        "value": 2946,
        "NOCCode": "3131",
        "JobTitle": "hospital druggist"
    },
    {
        "value": 2947,
        "NOCCode": "3131",
        "JobTitle": "hospital pharmacist"
    },
    {
        "value": 2948,
        "NOCCode": "3131",
        "JobTitle": "industrial pharmacist"
    },
    {
        "value": 2949,
        "NOCCode": "3131",
        "JobTitle": "intern pharmacist"
    },
    {
        "value": 2950,
        "NOCCode": "3131",
        "JobTitle": "pharmacist"
    },
    {
        "value": 2951,
        "NOCCode": "3131",
        "JobTitle": "pharmacist consultant"
    },
    {
        "value": 2952,
        "NOCCode": "3131",
        "JobTitle": "registered pharmacist"
    },
    {
        "value": 2953,
        "NOCCode": "3131",
        "JobTitle": "retail pharmacist"
    },
    {
        "value": 2954,
        "NOCCode": "3132",
        "JobTitle": "administrative dietitian"
    },
    {
        "value": 2955,
        "NOCCode": "3132",
        "JobTitle": "administrative nutritionist"
    },
    {
        "value": 2956,
        "NOCCode": "3132",
        "JobTitle": "clinical dietitian"
    },
    {
        "value": 2957,
        "NOCCode": "3132",
        "JobTitle": "clinical nutritionist"
    },
    {
        "value": 2958,
        "NOCCode": "3132",
        "JobTitle": "community dietitian"
    },
    {
        "value": 2959,
        "NOCCode": "3132",
        "JobTitle": "community nutritionist"
    },
    {
        "value": 2960,
        "NOCCode": "3132",
        "JobTitle": "consultant dietitian"
    },
    {
        "value": 2961,
        "NOCCode": "3132",
        "JobTitle": "consultant nutritionist"
    },
    {
        "value": 2962,
        "NOCCode": "3132",
        "JobTitle": "dietetic consultant"
    },
    {
        "value": 2963,
        "NOCCode": "3132",
        "JobTitle": "dietician"
    },
    {
        "value": 2964,
        "NOCCode": "3132",
        "JobTitle": "dietitian"
    },
    {
        "value": 2965,
        "NOCCode": "3132",
        "JobTitle": "dietitian, administrative"
    },
    {
        "value": 2966,
        "NOCCode": "3132",
        "JobTitle": "dietitian, community"
    },
    {
        "value": 2967,
        "NOCCode": "3132",
        "JobTitle": "dietitian, consultant"
    },
    {
        "value": 2968,
        "NOCCode": "3132",
        "JobTitle": "dietitian, professional"
    },
    {
        "value": 2969,
        "NOCCode": "3132",
        "JobTitle": "dietitian, public health"
    },
    {
        "value": 2970,
        "NOCCode": "3132",
        "JobTitle": "dietitian, registered"
    },
    {
        "value": 2971,
        "NOCCode": "3132",
        "JobTitle": "dietitian, research"
    },
    {
        "value": 2972,
        "NOCCode": "3132",
        "JobTitle": "dietitian, therapeutic"
    },
    {
        "value": 2973,
        "NOCCode": "3132",
        "JobTitle": "dietitian/nutritionist, registered"
    },
    {
        "value": 2974,
        "NOCCode": "3132",
        "JobTitle": "dietitian-nutritionist"
    },
    {
        "value": 2975,
        "NOCCode": "3132",
        "JobTitle": "nutrition and dietetics researcher"
    },
    {
        "value": 2976,
        "NOCCode": "3132",
        "JobTitle": "nutrition consultant"
    },
    {
        "value": 2977,
        "NOCCode": "3132",
        "JobTitle": "nutrition researcher"
    },
    {
        "value": 2978,
        "NOCCode": "3132",
        "JobTitle": "nutrition specialist"
    },
    {
        "value": 2979,
        "NOCCode": "3132",
        "JobTitle": "nutritionist"
    },
    {
        "value": 2980,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, administrative"
    },
    {
        "value": 2981,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, community"
    },
    {
        "value": 2982,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, consultant"
    },
    {
        "value": 2983,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, public health"
    },
    {
        "value": 2984,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, registered"
    },
    {
        "value": 2985,
        "NOCCode": "3132",
        "JobTitle": "nutritionist, therapeutic"
    },
    {
        "value": 2986,
        "NOCCode": "3132",
        "JobTitle": "P.Dt. (professional dietitian)"
    },
    {
        "value": 2987,
        "NOCCode": "3132",
        "JobTitle": "private practice dietitian"
    },
    {
        "value": 2988,
        "NOCCode": "3132",
        "JobTitle": "professional dietitian (P.Dt.)"
    },
    {
        "value": 2989,
        "NOCCode": "3132",
        "JobTitle": "professional dietitian, registered"
    },
    {
        "value": 2990,
        "NOCCode": "3132",
        "JobTitle": "professional nutritionist"
    },
    {
        "value": 2991,
        "NOCCode": "3132",
        "JobTitle": "public health dietitian"
    },
    {
        "value": 2992,
        "NOCCode": "3132",
        "JobTitle": "public health nutritionist"
    },
    {
        "value": 2993,
        "NOCCode": "3132",
        "JobTitle": "RD (registered dietitian)"
    },
    {
        "value": 2994,
        "NOCCode": "3132",
        "JobTitle": "RDN (registered dietitian-nutritionist)"
    },
    {
        "value": 2995,
        "NOCCode": "3132",
        "JobTitle": "registered dietitian (RD)"
    },
    {
        "value": 2996,
        "NOCCode": "3132",
        "JobTitle": "registered dietitian-nutritionist (RDN)"
    },
    {
        "value": 2997,
        "NOCCode": "3132",
        "JobTitle": "registered nutritionist"
    },
    {
        "value": 2998,
        "NOCCode": "3132",
        "JobTitle": "registered professional dietitian"
    },
    {
        "value": 2999,
        "NOCCode": "3132",
        "JobTitle": "registered professional nutritionist"
    },
    {
        "value": 3000,
        "NOCCode": "3132",
        "JobTitle": "research dietitian"
    },
    {
        "value": 3001,
        "NOCCode": "3132",
        "JobTitle": "research nutritionist"
    },
    {
        "value": 3002,
        "NOCCode": "3132",
        "JobTitle": "therapeutic dietitian"
    },
    {
        "value": 3003,
        "NOCCode": "3132",
        "JobTitle": "therapeutic nutritionist"
    },
    {
        "value": 3004,
        "NOCCode": "3141",
        "JobTitle": "amplification audiologist"
    },
    {
        "value": 3005,
        "NOCCode": "3141",
        "JobTitle": "audiologist"
    },
    {
        "value": 3006,
        "NOCCode": "3141",
        "JobTitle": "audiologist, research"
    },
    {
        "value": 3007,
        "NOCCode": "3141",
        "JobTitle": "audiology clinician"
    },
    {
        "value": 3008,
        "NOCCode": "3141",
        "JobTitle": "audiology specialist"
    },
    {
        "value": 3009,
        "NOCCode": "3141",
        "JobTitle": "certified audiologist"
    },
    {
        "value": 3010,
        "NOCCode": "3141",
        "JobTitle": "certified hearing aid audiologist"
    },
    {
        "value": 3011,
        "NOCCode": "3141",
        "JobTitle": "clinical audiologist"
    },
    {
        "value": 3012,
        "NOCCode": "3141",
        "JobTitle": "community audiologist"
    },
    {
        "value": 3013,
        "NOCCode": "3141",
        "JobTitle": "community speech-language pathologist"
    },
    {
        "value": 3014,
        "NOCCode": "3141",
        "JobTitle": "diagnostic audiologist"
    },
    {
        "value": 3015,
        "NOCCode": "3141",
        "JobTitle": "dispensing audiologist"
    },
    {
        "value": 3016,
        "NOCCode": "3141",
        "JobTitle": "educational audiologist"
    },
    {
        "value": 3017,
        "NOCCode": "3141",
        "JobTitle": "educational speech-language pathologist"
    },
    {
        "value": 3018,
        "NOCCode": "3141",
        "JobTitle": "human communication disorders specialist"
    },
    {
        "value": 3019,
        "NOCCode": "3141",
        "JobTitle": "industrial audiologist"
    },
    {
        "value": 3020,
        "NOCCode": "3141",
        "JobTitle": "logopedist"
    },
    {
        "value": 3021,
        "NOCCode": "3141",
        "JobTitle": "pathologist, speech"
    },
    {
        "value": 3022,
        "NOCCode": "3141",
        "JobTitle": "pathologist, speech-language"
    },
    {
        "value": 3023,
        "NOCCode": "3141",
        "JobTitle": "pediatric audiologist"
    },
    {
        "value": 3024,
        "NOCCode": "3141",
        "JobTitle": "research audiologist"
    },
    {
        "value": 3025,
        "NOCCode": "3141",
        "JobTitle": "specialist, audiology"
    },
    {
        "value": 3026,
        "NOCCode": "3141",
        "JobTitle": "speech and hearing therapist"
    },
    {
        "value": 3027,
        "NOCCode": "3141",
        "JobTitle": "speech pathologist"
    },
    {
        "value": 3028,
        "NOCCode": "3141",
        "JobTitle": "speech therapist"
    },
    {
        "value": 3029,
        "NOCCode": "3141",
        "JobTitle": "speech-language clinician"
    },
    {
        "value": 3030,
        "NOCCode": "3141",
        "JobTitle": "speech-language pathologist"
    },
    {
        "value": 3031,
        "NOCCode": "3141",
        "JobTitle": "speech-language pathologist clinician"
    },
    {
        "value": 3032,
        "NOCCode": "3141",
        "JobTitle": "speech-language pathologist researcher"
    },
    {
        "value": 3033,
        "NOCCode": "3141",
        "JobTitle": "therapist, speech"
    },
    {
        "value": 3034,
        "NOCCode": "3141",
        "JobTitle": "therapist, speech and hearing"
    },
    {
        "value": 3035,
        "NOCCode": "3142",
        "JobTitle": "clinical co-ordinator, physical therapy"
    },
    {
        "value": 3036,
        "NOCCode": "3142",
        "JobTitle": "clinical co-ordinator, physiotherapy"
    },
    {
        "value": 3037,
        "NOCCode": "3142",
        "JobTitle": "clinical physical therapist"
    },
    {
        "value": 3038,
        "NOCCode": "3142",
        "JobTitle": "clinical physiotherapist"
    },
    {
        "value": 3039,
        "NOCCode": "3142",
        "JobTitle": "consultant physical therapist"
    },
    {
        "value": 3040,
        "NOCCode": "3142",
        "JobTitle": "consultant physiotherapist"
    },
    {
        "value": 3041,
        "NOCCode": "3142",
        "JobTitle": "physical rehabilitation therapist"
    },
    {
        "value": 3042,
        "NOCCode": "3142",
        "JobTitle": "physical therapist"
    },
    {
        "value": 3043,
        "NOCCode": "3142",
        "JobTitle": "physical therapist, research"
    },
    {
        "value": 3044,
        "NOCCode": "3142",
        "JobTitle": "physical therapy clinical co-ordinator"
    },
    {
        "value": 3045,
        "NOCCode": "3142",
        "JobTitle": "physical therapy clinical specialist"
    },
    {
        "value": 3046,
        "NOCCode": "3142",
        "JobTitle": "physical therapy supervisor"
    },
    {
        "value": 3047,
        "NOCCode": "3142",
        "JobTitle": "physiotherapist"
    },
    {
        "value": 3048,
        "NOCCode": "3142",
        "JobTitle": "physiotherapist, research"
    },
    {
        "value": 3049,
        "NOCCode": "3142",
        "JobTitle": "physiotherapy supervisor"
    },
    {
        "value": 3050,
        "NOCCode": "3142",
        "JobTitle": "registered physical therapist"
    },
    {
        "value": 3051,
        "NOCCode": "3142",
        "JobTitle": "registered physiotherapist"
    },
    {
        "value": 3052,
        "NOCCode": "3142",
        "JobTitle": "research physical therapist"
    },
    {
        "value": 3053,
        "NOCCode": "3142",
        "JobTitle": "research physiotherapist"
    },
    {
        "value": 3054,
        "NOCCode": "3142",
        "JobTitle": "supervisor, physical therapy"
    },
    {
        "value": 3055,
        "NOCCode": "3142",
        "JobTitle": "supervisor, physiotherapy"
    },
    {
        "value": 3056,
        "NOCCode": "3142",
        "JobTitle": "therapist, physical rehabilitation"
    },
    {
        "value": 3057,
        "NOCCode": "3143",
        "JobTitle": "clinical occupational therapist"
    },
    {
        "value": 3058,
        "NOCCode": "3143",
        "JobTitle": "clinical occupational therapy specialist"
    },
    {
        "value": 3059,
        "NOCCode": "3143",
        "JobTitle": "community occupational therapist"
    },
    {
        "value": 3060,
        "NOCCode": "3143",
        "JobTitle": "community practice occupational therapist"
    },
    {
        "value": 3061,
        "NOCCode": "3143",
        "JobTitle": "home care occupational therapist"
    },
    {
        "value": 3062,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist (OT)"
    },
    {
        "value": 3063,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist vocational evaluator"
    },
    {
        "value": 3064,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, case manager"
    },
    {
        "value": 3065,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, community"
    },
    {
        "value": 3066,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, community practice"
    },
    {
        "value": 3067,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, home care"
    },
    {
        "value": 3068,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, research"
    },
    {
        "value": 3069,
        "NOCCode": "3143",
        "JobTitle": "occupational therapist, research and development"
    },
    {
        "value": 3070,
        "NOCCode": "3143",
        "JobTitle": "occupational therapy rehabilitation consultant"
    },
    {
        "value": 3071,
        "NOCCode": "3143",
        "JobTitle": "OT (occupational therapist)"
    },
    {
        "value": 3072,
        "NOCCode": "3143",
        "JobTitle": "rehabilitation consultant, occupational therapy"
    },
    {
        "value": 3073,
        "NOCCode": "3143",
        "JobTitle": "research and development occupational therapist"
    },
    {
        "value": 3074,
        "NOCCode": "3143",
        "JobTitle": "research occupational therapist"
    },
    {
        "value": 3075,
        "NOCCode": "3143",
        "JobTitle": "specialist, clinical occupational therapy"
    },
    {
        "value": 3076,
        "NOCCode": "3143",
        "JobTitle": "vocational evaluator occupational therapist"
    },
    {
        "value": 3077,
        "NOCCode": "3144",
        "JobTitle": "analyst, movement – medical"
    },
    {
        "value": 3078,
        "NOCCode": "3144",
        "JobTitle": "art therapist"
    },
    {
        "value": 3079,
        "NOCCode": "3144",
        "JobTitle": "art therapists' supervisor"
    },
    {
        "value": 3080,
        "NOCCode": "3144",
        "JobTitle": "art therapy consultant"
    },
    {
        "value": 3081,
        "NOCCode": "3144",
        "JobTitle": "art therapy teacher (except education)"
    },
    {
        "value": 3082,
        "NOCCode": "3144",
        "JobTitle": "athletic therapist"
    },
    {
        "value": 3083,
        "NOCCode": "3144",
        "JobTitle": "athletic trainer – therapy"
    },
    {
        "value": 3084,
        "NOCCode": "3144",
        "JobTitle": "biokineticist"
    },
    {
        "value": 3085,
        "NOCCode": "3144",
        "JobTitle": "CAT (certified athletic therapist)"
    },
    {
        "value": 3086,
        "NOCCode": "3144",
        "JobTitle": "certified athletic therapist (CAT)"
    },
    {
        "value": 3087,
        "NOCCode": "3144",
        "JobTitle": "certified kinesiologist"
    },
    {
        "value": 3088,
        "NOCCode": "3144",
        "JobTitle": "consultant, art therapy"
    },
    {
        "value": 3089,
        "NOCCode": "3144",
        "JobTitle": "dance therapist"
    },
    {
        "value": 3090,
        "NOCCode": "3144",
        "JobTitle": "dance therapists' supervisor"
    },
    {
        "value": 3091,
        "NOCCode": "3144",
        "JobTitle": "dance therapy researcher"
    },
    {
        "value": 3092,
        "NOCCode": "3144",
        "JobTitle": "dance-movement therapist"
    },
    {
        "value": 3093,
        "NOCCode": "3144",
        "JobTitle": "dance-movement therapy researcher"
    },
    {
        "value": 3094,
        "NOCCode": "3144",
        "JobTitle": "drama therapist"
    },
    {
        "value": 3095,
        "NOCCode": "3144",
        "JobTitle": "drama therapists' supervisor"
    },
    {
        "value": 3096,
        "NOCCode": "3144",
        "JobTitle": "DTR (registered dance therapist)"
    },
    {
        "value": 3097,
        "NOCCode": "3144",
        "JobTitle": "exercise physiologist"
    },
    {
        "value": 3098,
        "NOCCode": "3144",
        "JobTitle": "exercise therapist"
    },
    {
        "value": 3099,
        "NOCCode": "3144",
        "JobTitle": "human kineticist"
    },
    {
        "value": 3100,
        "NOCCode": "3144",
        "JobTitle": "kinanthropologist"
    },
    {
        "value": 3101,
        "NOCCode": "3144",
        "JobTitle": "kinesiologist"
    },
    {
        "value": 3102,
        "NOCCode": "3144",
        "JobTitle": "movement analyst – medical"
    },
    {
        "value": 3103,
        "NOCCode": "3144",
        "JobTitle": "movement therapist"
    },
    {
        "value": 3104,
        "NOCCode": "3144",
        "JobTitle": "music therapist"
    },
    {
        "value": 3105,
        "NOCCode": "3144",
        "JobTitle": "music therapists' supervisor"
    },
    {
        "value": 3106,
        "NOCCode": "3144",
        "JobTitle": "music therapy researcher"
    },
    {
        "value": 3107,
        "NOCCode": "3144",
        "JobTitle": "recreational therapist"
    },
    {
        "value": 3108,
        "NOCCode": "3144",
        "JobTitle": "registered dance therapist (DTR)"
    },
    {
        "value": 3109,
        "NOCCode": "3144",
        "JobTitle": "registered kinesiologist"
    },
    {
        "value": 3110,
        "NOCCode": "3144",
        "JobTitle": "remedial gymnast"
    },
    {
        "value": 3111,
        "NOCCode": "3144",
        "JobTitle": "remedial gymnastics teacher (except education)"
    },
    {
        "value": 3112,
        "NOCCode": "3144",
        "JobTitle": "remedial gymnasts' supervisor"
    },
    {
        "value": 3113,
        "NOCCode": "3144",
        "JobTitle": "remedial therapist"
    },
    {
        "value": 3114,
        "NOCCode": "3144",
        "JobTitle": "researcher, dance therapy"
    },
    {
        "value": 3115,
        "NOCCode": "3144",
        "JobTitle": "researcher, dance-movement therapy"
    },
    {
        "value": 3116,
        "NOCCode": "3144",
        "JobTitle": "researcher, music therapy"
    },
    {
        "value": 3117,
        "NOCCode": "3144",
        "JobTitle": "spontaneous art teacher – therapy"
    },
    {
        "value": 3118,
        "NOCCode": "3144",
        "JobTitle": "supervisor, art therapists"
    },
    {
        "value": 3119,
        "NOCCode": "3144",
        "JobTitle": "supervisor, dance therapists"
    },
    {
        "value": 3120,
        "NOCCode": "3144",
        "JobTitle": "supervisor, drama therapists"
    },
    {
        "value": 3121,
        "NOCCode": "3144",
        "JobTitle": "supervisor, music therapists"
    },
    {
        "value": 3122,
        "NOCCode": "3144",
        "JobTitle": "supervisor, remedial gymnasts"
    },
    {
        "value": 3123,
        "NOCCode": "3144",
        "JobTitle": "therapeutic recreationist"
    },
    {
        "value": 3124,
        "NOCCode": "3144",
        "JobTitle": "therapist, art"
    },
    {
        "value": 3125,
        "NOCCode": "3144",
        "JobTitle": "therapist, dance"
    },
    {
        "value": 3126,
        "NOCCode": "3144",
        "JobTitle": "therapist, dance-movement"
    },
    {
        "value": 3127,
        "NOCCode": "3144",
        "JobTitle": "therapist, drama"
    },
    {
        "value": 3128,
        "NOCCode": "3144",
        "JobTitle": "therapist, movement"
    },
    {
        "value": 3129,
        "NOCCode": "3144",
        "JobTitle": "therapist, music"
    },
    {
        "value": 3130,
        "NOCCode": "3211",
        "JobTitle": "advanced registered technologist – medical laboratory"
    },
    {
        "value": 3131,
        "NOCCode": "3211",
        "JobTitle": "anatomical pathology technologist"
    },
    {
        "value": 3132,
        "NOCCode": "3211",
        "JobTitle": "autopsy technologist – medical laboratory"
    },
    {
        "value": 3133,
        "NOCCode": "3211",
        "JobTitle": "biochemistry technologist – medical laboratory"
    },
    {
        "value": 3134,
        "NOCCode": "3211",
        "JobTitle": "blood bank technologist"
    },
    {
        "value": 3135,
        "NOCCode": "3211",
        "JobTitle": "charge technologist – medical laboratory"
    },
    {
        "value": 3136,
        "NOCCode": "3211",
        "JobTitle": "clinical immunology technologist"
    },
    {
        "value": 3137,
        "NOCCode": "3211",
        "JobTitle": "clinical laboratory technologist"
    },
    {
        "value": 3138,
        "NOCCode": "3211",
        "JobTitle": "combined laboratory and x-ray technologist"
    },
    {
        "value": 3139,
        "NOCCode": "3211",
        "JobTitle": "cytogenetics technologist – medical laboratory"
    },
    {
        "value": 3140,
        "NOCCode": "3211",
        "JobTitle": "cytology technologist"
    },
    {
        "value": 3141,
        "NOCCode": "3211",
        "JobTitle": "cytotechnologist – medical laboratory"
    },
    {
        "value": 3142,
        "NOCCode": "3211",
        "JobTitle": "electron microscopy technologist – medical laboratory"
    },
    {
        "value": 3143,
        "NOCCode": "3211",
        "JobTitle": "hematology technologist – medical laboratory"
    },
    {
        "value": 3144,
        "NOCCode": "3211",
        "JobTitle": "histology technologist"
    },
    {
        "value": 3145,
        "NOCCode": "3211",
        "JobTitle": "histology technologist – medical laboratory"
    },
    {
        "value": 3146,
        "NOCCode": "3211",
        "JobTitle": "histopathology technologist"
    },
    {
        "value": 3147,
        "NOCCode": "3211",
        "JobTitle": "histotechnologist – medical laboratory"
    },
    {
        "value": 3148,
        "NOCCode": "3211",
        "JobTitle": "immunohematology technologist"
    },
    {
        "value": 3149,
        "NOCCode": "3211",
        "JobTitle": "immunohematology technologist – medical laboratory"
    },
    {
        "value": 3150,
        "NOCCode": "3211",
        "JobTitle": "immunology technologist – medical laboratory"
    },
    {
        "value": 3151,
        "NOCCode": "3211",
        "JobTitle": "in-charge technologist – autopsy services"
    },
    {
        "value": 3152,
        "NOCCode": "3211",
        "JobTitle": "medical laboratory supervisor"
    },
    {
        "value": 3153,
        "NOCCode": "3211",
        "JobTitle": "medical laboratory technical supervisor"
    },
    {
        "value": 3154,
        "NOCCode": "3211",
        "JobTitle": "medical laboratory technologist"
    },
    {
        "value": 3155,
        "NOCCode": "3211",
        "JobTitle": "medical laboratory technologist, anatomical pathology"
    },
    {
        "value": 3156,
        "NOCCode": "3211",
        "JobTitle": "medical technologist"
    },
    {
        "value": 3157,
        "NOCCode": "3211",
        "JobTitle": "medical technologist – medical laboratory"
    },
    {
        "value": 3158,
        "NOCCode": "3211",
        "JobTitle": "medical technologists supervisor"
    },
    {
        "value": 3159,
        "NOCCode": "3211",
        "JobTitle": "microbiology technologist – medical laboratory"
    },
    {
        "value": 3160,
        "NOCCode": "3211",
        "JobTitle": "registered medical technologist"
    },
    {
        "value": 3161,
        "NOCCode": "3211",
        "JobTitle": "registered technologist – medical"
    },
    {
        "value": 3162,
        "NOCCode": "3211",
        "JobTitle": "serology technologist"
    },
    {
        "value": 3163,
        "NOCCode": "3211",
        "JobTitle": "supervisor, medical laboratory"
    },
    {
        "value": 3164,
        "NOCCode": "3211",
        "JobTitle": "technical supervisor, medical laboratory"
    },
    {
        "value": 3165,
        "NOCCode": "3211",
        "JobTitle": "technologist, anatomical pathology"
    },
    {
        "value": 3166,
        "NOCCode": "3211",
        "JobTitle": "technologist, autopsy – medical laboratory"
    },
    {
        "value": 3167,
        "NOCCode": "3211",
        "JobTitle": "technologist, clinical laboratory"
    },
    {
        "value": 3168,
        "NOCCode": "3211",
        "JobTitle": "technologist, medical laboratory"
    },
    {
        "value": 3169,
        "NOCCode": "3211",
        "JobTitle": "tissue technologist – medical laboratory"
    },
    {
        "value": 3170,
        "NOCCode": "3212",
        "JobTitle": "aide, medical laboratory"
    },
    {
        "value": 3171,
        "NOCCode": "3212",
        "JobTitle": "assistant, medical laboratory"
    },
    {
        "value": 3172,
        "NOCCode": "3212",
        "JobTitle": "assistant, pathology"
    },
    {
        "value": 3173,
        "NOCCode": "3212",
        "JobTitle": "biochemical technician – medical laboratory"
    },
    {
        "value": 3174,
        "NOCCode": "3212",
        "JobTitle": "biological technician – medical laboratory"
    },
    {
        "value": 3175,
        "NOCCode": "3212",
        "JobTitle": "cancer research technician"
    },
    {
        "value": 3176,
        "NOCCode": "3212",
        "JobTitle": "clinical laboratory technician"
    },
    {
        "value": 3177,
        "NOCCode": "3212",
        "JobTitle": "combined laboratory and x-ray technician"
    },
    {
        "value": 3178,
        "NOCCode": "3212",
        "JobTitle": "community health technician – medical laboratory"
    },
    {
        "value": 3179,
        "NOCCode": "3212",
        "JobTitle": "cytology technician"
    },
    {
        "value": 3180,
        "NOCCode": "3212",
        "JobTitle": "epidemiology worker – medical laboratory"
    },
    {
        "value": 3181,
        "NOCCode": "3212",
        "JobTitle": "helper, medical laboratory"
    },
    {
        "value": 3182,
        "NOCCode": "3212",
        "JobTitle": "hematology technician – medical laboratory"
    },
    {
        "value": 3183,
        "NOCCode": "3212",
        "JobTitle": "histology technician"
    },
    {
        "value": 3184,
        "NOCCode": "3212",
        "JobTitle": "laboratory assistant – medical"
    },
    {
        "value": 3185,
        "NOCCode": "3212",
        "JobTitle": "laboratory technician – medical"
    },
    {
        "value": 3186,
        "NOCCode": "3212",
        "JobTitle": "medical laboratory aide"
    },
    {
        "value": 3187,
        "NOCCode": "3212",
        "JobTitle": "medical laboratory assistant"
    },
    {
        "value": 3188,
        "NOCCode": "3212",
        "JobTitle": "medical laboratory helper"
    },
    {
        "value": 3189,
        "NOCCode": "3212",
        "JobTitle": "medical laboratory technical assistant"
    },
    {
        "value": 3190,
        "NOCCode": "3212",
        "JobTitle": "medical laboratory technician"
    },
    {
        "value": 3191,
        "NOCCode": "3212",
        "JobTitle": "morgue attendant supervisor"
    },
    {
        "value": 3192,
        "NOCCode": "3212",
        "JobTitle": "pathology assistant"
    },
    {
        "value": 3193,
        "NOCCode": "3212",
        "JobTitle": "phlebotomist"
    },
    {
        "value": 3194,
        "NOCCode": "3212",
        "JobTitle": "phlebotomy aide"
    },
    {
        "value": 3195,
        "NOCCode": "3212",
        "JobTitle": "technical assistant – medical laboratory"
    },
    {
        "value": 3196,
        "NOCCode": "3212",
        "JobTitle": "technical assistant – pathology"
    },
    {
        "value": 3197,
        "NOCCode": "3212",
        "JobTitle": "technician, blood bank"
    },
    {
        "value": 3198,
        "NOCCode": "3213",
        "JobTitle": "(RAHT) registered animal health technologist"
    },
    {
        "value": 3199,
        "NOCCode": "3213",
        "JobTitle": "animal care technician"
    },
    {
        "value": 3200,
        "NOCCode": "3213",
        "JobTitle": "animal care technologist"
    },
    {
        "value": 3201,
        "NOCCode": "3213",
        "JobTitle": "animal health technician"
    },
    {
        "value": 3202,
        "NOCCode": "3213",
        "JobTitle": "animal health technician – agriculture"
    },
    {
        "value": 3203,
        "NOCCode": "3213",
        "JobTitle": "animal health technologist"
    },
    {
        "value": 3204,
        "NOCCode": "3213",
        "JobTitle": "animal health technologist supervisor"
    },
    {
        "value": 3205,
        "NOCCode": "3213",
        "JobTitle": "animal operating room attendant"
    },
    {
        "value": 3206,
        "NOCCode": "3213",
        "JobTitle": "attendant, animal operating room"
    },
    {
        "value": 3207,
        "NOCCode": "3213",
        "JobTitle": "laboratory animal technician"
    },
    {
        "value": 3208,
        "NOCCode": "3213",
        "JobTitle": "laboratory animal technologist"
    },
    {
        "value": 3209,
        "NOCCode": "3213",
        "JobTitle": "laboratory technician, veterinary"
    },
    {
        "value": 3210,
        "NOCCode": "3213",
        "JobTitle": "registered animal health technologist"
    },
    {
        "value": 3211,
        "NOCCode": "3213",
        "JobTitle": "registered animal health technologist (RAHT)"
    },
    {
        "value": 3212,
        "NOCCode": "3213",
        "JobTitle": "registered veterinary technician"
    },
    {
        "value": 3213,
        "NOCCode": "3213",
        "JobTitle": "registered veterinary technician (RVT)"
    },
    {
        "value": 3214,
        "NOCCode": "3213",
        "JobTitle": "technician, animal care"
    },
    {
        "value": 3215,
        "NOCCode": "3213",
        "JobTitle": "technician, animal health"
    },
    {
        "value": 3216,
        "NOCCode": "3213",
        "JobTitle": "technician, laboratory animals"
    },
    {
        "value": 3217,
        "NOCCode": "3213",
        "JobTitle": "technician, veterinary"
    },
    {
        "value": 3218,
        "NOCCode": "3213",
        "JobTitle": "technician, veterinary laboratory"
    },
    {
        "value": 3219,
        "NOCCode": "3213",
        "JobTitle": "technologist, animal care"
    },
    {
        "value": 3220,
        "NOCCode": "3213",
        "JobTitle": "technologist, animal health"
    },
    {
        "value": 3221,
        "NOCCode": "3213",
        "JobTitle": "technologist, veterinary"
    },
    {
        "value": 3222,
        "NOCCode": "3213",
        "JobTitle": "veterinarian assistant"
    },
    {
        "value": 3223,
        "NOCCode": "3213",
        "JobTitle": "veterinary laboratory technician"
    },
    {
        "value": 3224,
        "NOCCode": "3213",
        "JobTitle": "veterinary technician"
    },
    {
        "value": 3225,
        "NOCCode": "3213",
        "JobTitle": "veterinary technician supervisor"
    },
    {
        "value": 3226,
        "NOCCode": "3213",
        "JobTitle": "veterinary technologist"
    },
    {
        "value": 3227,
        "NOCCode": "3214",
        "JobTitle": "cardiopulmonary technician"
    },
    {
        "value": 3228,
        "NOCCode": "3214",
        "JobTitle": "cardiopulmonary technologist"
    },
    {
        "value": 3229,
        "NOCCode": "3214",
        "JobTitle": "cardiovascular perfusion clinical instructor"
    },
    {
        "value": 3230,
        "NOCCode": "3214",
        "JobTitle": "cardiovascular perfusion instructor"
    },
    {
        "value": 3231,
        "NOCCode": "3214",
        "JobTitle": "cardiovascular perfusion supervisor"
    },
    {
        "value": 3232,
        "NOCCode": "3214",
        "JobTitle": "cardiovascular perfusion technologist"
    },
    {
        "value": 3233,
        "NOCCode": "3214",
        "JobTitle": "cardiovascular perfusionist"
    },
    {
        "value": 3234,
        "NOCCode": "3214",
        "JobTitle": "CCP (certified clinical perfusionist)"
    },
    {
        "value": 3235,
        "NOCCode": "3214",
        "JobTitle": "certified clinical perfusionist (CCP)"
    },
    {
        "value": 3236,
        "NOCCode": "3214",
        "JobTitle": "chief perfusionist"
    },
    {
        "value": 3237,
        "NOCCode": "3214",
        "JobTitle": "chief respiratory technologist"
    },
    {
        "value": 3238,
        "NOCCode": "3214",
        "JobTitle": "chief, respiratory therapy"
    },
    {
        "value": 3239,
        "NOCCode": "3214",
        "JobTitle": "clinical instructor, cardiovascular perfusion"
    },
    {
        "value": 3240,
        "NOCCode": "3214",
        "JobTitle": "clinical instructor, clinical perfusion"
    },
    {
        "value": 3241,
        "NOCCode": "3214",
        "JobTitle": "clinical instructor, respiratory therapy"
    },
    {
        "value": 3242,
        "NOCCode": "3214",
        "JobTitle": "clinical perfusion clinical instructor"
    },
    {
        "value": 3243,
        "NOCCode": "3214",
        "JobTitle": "clinical perfusion supervisor"
    },
    {
        "value": 3244,
        "NOCCode": "3214",
        "JobTitle": "clinical perfusionist"
    },
    {
        "value": 3245,
        "NOCCode": "3214",
        "JobTitle": "extracorporal circulation technologist"
    },
    {
        "value": 3246,
        "NOCCode": "3214",
        "JobTitle": "extracorporal technician"
    },
    {
        "value": 3247,
        "NOCCode": "3214",
        "JobTitle": "extracorporal technologist"
    },
    {
        "value": 3248,
        "NOCCode": "3214",
        "JobTitle": "inhalation technologist"
    },
    {
        "value": 3249,
        "NOCCode": "3214",
        "JobTitle": "instructor, cardiopulmonary technology"
    },
    {
        "value": 3250,
        "NOCCode": "3214",
        "JobTitle": "instructor, cardiovascular perfusion"
    },
    {
        "value": 3251,
        "NOCCode": "3214",
        "JobTitle": "oxygen therapy technician"
    },
    {
        "value": 3252,
        "NOCCode": "3214",
        "JobTitle": "perfusion technologist"
    },
    {
        "value": 3253,
        "NOCCode": "3214",
        "JobTitle": "perfusionist"
    },
    {
        "value": 3254,
        "NOCCode": "3214",
        "JobTitle": "perfusionist, cardiovascular"
    },
    {
        "value": 3255,
        "NOCCode": "3214",
        "JobTitle": "pulmonary function technologist"
    },
    {
        "value": 3256,
        "NOCCode": "3214",
        "JobTitle": "registered respiratory care practitioner"
    },
    {
        "value": 3257,
        "NOCCode": "3214",
        "JobTitle": "registered respiratory therapist (RRT)"
    },
    {
        "value": 3258,
        "NOCCode": "3214",
        "JobTitle": "respiratory care practitioner"
    },
    {
        "value": 3259,
        "NOCCode": "3214",
        "JobTitle": "respiratory technician"
    },
    {
        "value": 3260,
        "NOCCode": "3214",
        "JobTitle": "respiratory technologist"
    },
    {
        "value": 3261,
        "NOCCode": "3214",
        "JobTitle": "respiratory therapist"
    },
    {
        "value": 3262,
        "NOCCode": "3214",
        "JobTitle": "respiratory therapy chief"
    },
    {
        "value": 3263,
        "NOCCode": "3214",
        "JobTitle": "respiratory therapy clinical instructor"
    },
    {
        "value": 3264,
        "NOCCode": "3214",
        "JobTitle": "respiratory therapy supervisor"
    },
    {
        "value": 3265,
        "NOCCode": "3214",
        "JobTitle": "RRT (registered respiratory therapist)"
    },
    {
        "value": 3266,
        "NOCCode": "3214",
        "JobTitle": "supervisor, cardiopulmonary technology"
    },
    {
        "value": 3267,
        "NOCCode": "3214",
        "JobTitle": "supervisor, cardiovascular perfusion"
    },
    {
        "value": 3268,
        "NOCCode": "3214",
        "JobTitle": "supervisor, clinical perfusion"
    },
    {
        "value": 3269,
        "NOCCode": "3214",
        "JobTitle": "supervisor, respiratory therapy"
    },
    {
        "value": 3270,
        "NOCCode": "3214",
        "JobTitle": "technologist, anesthesia"
    },
    {
        "value": 3271,
        "NOCCode": "3214",
        "JobTitle": "technologist, cardiovascular perfusion"
    },
    {
        "value": 3272,
        "NOCCode": "3214",
        "JobTitle": "technologist, extracorporal circulation"
    },
    {
        "value": 3273,
        "NOCCode": "3214",
        "JobTitle": "technologist, inhalation"
    },
    {
        "value": 3274,
        "NOCCode": "3214",
        "JobTitle": "technologist, perfusion"
    },
    {
        "value": 3275,
        "NOCCode": "3214",
        "JobTitle": "technologist, pulmonary function"
    },
    {
        "value": 3276,
        "NOCCode": "3214",
        "JobTitle": "technologist, respiratory"
    },
    {
        "value": 3277,
        "NOCCode": "3214",
        "JobTitle": "therapist, respiratory"
    },
    {
        "value": 3278,
        "NOCCode": "3215",
        "JobTitle": "brachytherapy technologist"
    },
    {
        "value": 3279,
        "NOCCode": "3215",
        "JobTitle": "chief radiographer"
    },
    {
        "value": 3280,
        "NOCCode": "3215",
        "JobTitle": "chief radiotherapy technologist"
    },
    {
        "value": 3281,
        "NOCCode": "3215",
        "JobTitle": "chief technologist, nuclear medicine"
    },
    {
        "value": 3282,
        "NOCCode": "3215",
        "JobTitle": "chief technologist, radiation therapy"
    },
    {
        "value": 3283,
        "NOCCode": "3215",
        "JobTitle": "chief technologist, radiography"
    },
    {
        "value": 3284,
        "NOCCode": "3215",
        "JobTitle": "clinical co-ordinator, nuclear medicine"
    },
    {
        "value": 3285,
        "NOCCode": "3215",
        "JobTitle": "clinical co-ordinator, radiation therapy"
    },
    {
        "value": 3286,
        "NOCCode": "3215",
        "JobTitle": "clinical co-ordinator, radiography"
    },
    {
        "value": 3287,
        "NOCCode": "3215",
        "JobTitle": "clinical instructor, nuclear medicine"
    },
    {
        "value": 3288,
        "NOCCode": "3215",
        "JobTitle": "clinical instructor, radiation therapy"
    },
    {
        "value": 3289,
        "NOCCode": "3215",
        "JobTitle": "clinical instructor, radiography"
    },
    {
        "value": 3290,
        "NOCCode": "3215",
        "JobTitle": "diagnostic medical radiation technologist"
    },
    {
        "value": 3291,
        "NOCCode": "3215",
        "JobTitle": "diagnostic radiography technologist"
    },
    {
        "value": 3292,
        "NOCCode": "3215",
        "JobTitle": "diagnostic radiological technician"
    },
    {
        "value": 3293,
        "NOCCode": "3215",
        "JobTitle": "diagnostic radiological technologist"
    },
    {
        "value": 3294,
        "NOCCode": "3215",
        "JobTitle": "diagnostic radiology technologist"
    },
    {
        "value": 3295,
        "NOCCode": "3215",
        "JobTitle": "magnetic resonance technologist"
    },
    {
        "value": 3296,
        "NOCCode": "3215",
        "JobTitle": "mammography technician"
    },
    {
        "value": 3297,
        "NOCCode": "3215",
        "JobTitle": "mammography technologist"
    },
    {
        "value": 3298,
        "NOCCode": "3215",
        "JobTitle": "medical radiation technologist"
    },
    {
        "value": 3299,
        "NOCCode": "3215",
        "JobTitle": "medical radiation technologists supervisor"
    },
    {
        "value": 3300,
        "NOCCode": "3215",
        "JobTitle": "MRI (magnetic resonance imaging) technologist"
    },
    {
        "value": 3301,
        "NOCCode": "3215",
        "JobTitle": "NMRI (nuclear magnetic resonance imaging) technologist"
    },
    {
        "value": 3302,
        "NOCCode": "3215",
        "JobTitle": "nuclear magnetic resonance imaging (NMRI) technologist"
    },
    {
        "value": 3303,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine chief technologist"
    },
    {
        "value": 3304,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine clinical instructor"
    },
    {
        "value": 3305,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine supervisor"
    },
    {
        "value": 3306,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine technical co-ordinator"
    },
    {
        "value": 3307,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine technical director"
    },
    {
        "value": 3308,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine technician"
    },
    {
        "value": 3309,
        "NOCCode": "3215",
        "JobTitle": "nuclear medicine technologist"
    },
    {
        "value": 3310,
        "NOCCode": "3215",
        "JobTitle": "oncology technologist"
    },
    {
        "value": 3311,
        "NOCCode": "3215",
        "JobTitle": "oncology technologist, radiation therapy"
    },
    {
        "value": 3312,
        "NOCCode": "3215",
        "JobTitle": "PET (positron-emission tomography) technologist"
    },
    {
        "value": 3313,
        "NOCCode": "3215",
        "JobTitle": "positron-emission tomography (PET) technologist"
    },
    {
        "value": 3314,
        "NOCCode": "3215",
        "JobTitle": "radiation oncology simulator technologist"
    },
    {
        "value": 3315,
        "NOCCode": "3215",
        "JobTitle": "radiation oncology technician"
    },
    {
        "value": 3316,
        "NOCCode": "3215",
        "JobTitle": "radiation oncology technologist"
    },
    {
        "value": 3317,
        "NOCCode": "3215",
        "JobTitle": "radiation technologist – cancer therapy"
    },
    {
        "value": 3318,
        "NOCCode": "3215",
        "JobTitle": "radiation technologist – oncology"
    },
    {
        "value": 3319,
        "NOCCode": "3215",
        "JobTitle": "radiation therapist"
    },
    {
        "value": 3320,
        "NOCCode": "3215",
        "JobTitle": "radiation therapists supervisor"
    },
    {
        "value": 3321,
        "NOCCode": "3215",
        "JobTitle": "radiation therapy chief technologist"
    },
    {
        "value": 3322,
        "NOCCode": "3215",
        "JobTitle": "radiation therapy technical co-ordinator"
    },
    {
        "value": 3323,
        "NOCCode": "3215",
        "JobTitle": "radiation therapy technologist (RTT)"
    },
    {
        "value": 3324,
        "NOCCode": "3215",
        "JobTitle": "radiographer, medical"
    },
    {
        "value": 3325,
        "NOCCode": "3215",
        "JobTitle": "radiographic technologist"
    },
    {
        "value": 3326,
        "NOCCode": "3215",
        "JobTitle": "radiography chief technologist"
    },
    {
        "value": 3327,
        "NOCCode": "3215",
        "JobTitle": "radiography clinical co-ordinator"
    },
    {
        "value": 3328,
        "NOCCode": "3215",
        "JobTitle": "radiography clinical instructor"
    },
    {
        "value": 3329,
        "NOCCode": "3215",
        "JobTitle": "radiography supervisor"
    },
    {
        "value": 3330,
        "NOCCode": "3215",
        "JobTitle": "radiography technical co-ordinator"
    },
    {
        "value": 3331,
        "NOCCode": "3215",
        "JobTitle": "radiography technical director"
    },
    {
        "value": 3332,
        "NOCCode": "3215",
        "JobTitle": "radiography technologist"
    },
    {
        "value": 3333,
        "NOCCode": "3215",
        "JobTitle": "radiography technologists supervisor"
    },
    {
        "value": 3334,
        "NOCCode": "3215",
        "JobTitle": "radioisotope technician"
    },
    {
        "value": 3335,
        "NOCCode": "3215",
        "JobTitle": "radioisotope technologist"
    },
    {
        "value": 3336,
        "NOCCode": "3215",
        "JobTitle": "radiological technician"
    },
    {
        "value": 3337,
        "NOCCode": "3215",
        "JobTitle": "radiological technologist"
    },
    {
        "value": 3338,
        "NOCCode": "3215",
        "JobTitle": "radiology technologist"
    },
    {
        "value": 3339,
        "NOCCode": "3215",
        "JobTitle": "radiotherapy technician"
    },
    {
        "value": 3340,
        "NOCCode": "3215",
        "JobTitle": "radiotherapy technologist"
    },
    {
        "value": 3341,
        "NOCCode": "3215",
        "JobTitle": "registered radiology technologist"
    },
    {
        "value": 3342,
        "NOCCode": "3215",
        "JobTitle": "registered technologist in nuclear medicine (RTNM)"
    },
    {
        "value": 3343,
        "NOCCode": "3215",
        "JobTitle": "registered technologist in radiation therapy"
    },
    {
        "value": 3344,
        "NOCCode": "3215",
        "JobTitle": "registered technologist in radiography (RTR)"
    },
    {
        "value": 3345,
        "NOCCode": "3215",
        "JobTitle": "RTNM (registered technologist in nuclear medicine)"
    },
    {
        "value": 3346,
        "NOCCode": "3215",
        "JobTitle": "RTR (registered technologist in radiography)"
    },
    {
        "value": 3347,
        "NOCCode": "3215",
        "JobTitle": "RTT (radiation therapy technologist)"
    },
    {
        "value": 3348,
        "NOCCode": "3215",
        "JobTitle": "senior radiation therapy technologist"
    },
    {
        "value": 3349,
        "NOCCode": "3215",
        "JobTitle": "supervisor, medical radiation technologists"
    },
    {
        "value": 3350,
        "NOCCode": "3215",
        "JobTitle": "supervisor, nuclear medicine"
    },
    {
        "value": 3351,
        "NOCCode": "3215",
        "JobTitle": "supervisor, nuclear medicine technologists"
    },
    {
        "value": 3352,
        "NOCCode": "3215",
        "JobTitle": "supervisor, radiation therapists"
    },
    {
        "value": 3353,
        "NOCCode": "3215",
        "JobTitle": "supervisor, radiography"
    },
    {
        "value": 3354,
        "NOCCode": "3215",
        "JobTitle": "supervisor, radiography technologists"
    },
    {
        "value": 3355,
        "NOCCode": "3215",
        "JobTitle": "technical co-ordinator, nuclear medicine"
    },
    {
        "value": 3356,
        "NOCCode": "3215",
        "JobTitle": "technical co-ordinator, radiation therapy"
    },
    {
        "value": 3357,
        "NOCCode": "3215",
        "JobTitle": "technical co-ordinator, radiography"
    },
    {
        "value": 3358,
        "NOCCode": "3215",
        "JobTitle": "technical director, nuclear medicine"
    },
    {
        "value": 3359,
        "NOCCode": "3215",
        "JobTitle": "technical director, radiography"
    },
    {
        "value": 3360,
        "NOCCode": "3215",
        "JobTitle": "technician, mammography"
    },
    {
        "value": 3361,
        "NOCCode": "3215",
        "JobTitle": "technician, nuclear medicine"
    },
    {
        "value": 3362,
        "NOCCode": "3215",
        "JobTitle": "technician, radiation oncology"
    },
    {
        "value": 3363,
        "NOCCode": "3215",
        "JobTitle": "technician, radioisotope"
    },
    {
        "value": 3364,
        "NOCCode": "3215",
        "JobTitle": "technician, radiotherapy"
    },
    {
        "value": 3365,
        "NOCCode": "3215",
        "JobTitle": "technician, X-ray (radiology)"
    },
    {
        "value": 3366,
        "NOCCode": "3215",
        "JobTitle": "technologist, brachytherapy"
    },
    {
        "value": 3367,
        "NOCCode": "3215",
        "JobTitle": "technologist, diagnostic medical radiation"
    },
    {
        "value": 3368,
        "NOCCode": "3215",
        "JobTitle": "technologist, diagnostic radiography"
    },
    {
        "value": 3369,
        "NOCCode": "3215",
        "JobTitle": "technologist, magnetic resonance"
    },
    {
        "value": 3370,
        "NOCCode": "3215",
        "JobTitle": "technologist, magnetic resonance imaging (MRI)"
    },
    {
        "value": 3371,
        "NOCCode": "3215",
        "JobTitle": "technologist, mammography"
    },
    {
        "value": 3372,
        "NOCCode": "3215",
        "JobTitle": "technologist, medical radiation"
    },
    {
        "value": 3373,
        "NOCCode": "3215",
        "JobTitle": "technologist, MRI (magnetic resonance imaging)"
    },
    {
        "value": 3374,
        "NOCCode": "3215",
        "JobTitle": "technologist, NMRI (nuclear magnetic resonance imaging)"
    },
    {
        "value": 3375,
        "NOCCode": "3215",
        "JobTitle": "technologist, nuclear magnetic resonance imaging (NMRI)"
    },
    {
        "value": 3376,
        "NOCCode": "3215",
        "JobTitle": "technologist, nuclear medicine"
    },
    {
        "value": 3377,
        "NOCCode": "3215",
        "JobTitle": "technologist, oncology"
    },
    {
        "value": 3378,
        "NOCCode": "3215",
        "JobTitle": "technologist, PET (positron-emission tomography)"
    },
    {
        "value": 3379,
        "NOCCode": "3215",
        "JobTitle": "technologist, positron-emission tomography (PET)"
    },
    {
        "value": 3380,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiation oncology"
    },
    {
        "value": 3381,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiation oncology simulator"
    },
    {
        "value": 3382,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiation therapy"
    },
    {
        "value": 3383,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiography"
    },
    {
        "value": 3384,
        "NOCCode": "3215",
        "JobTitle": "technologist, radioisotope"
    },
    {
        "value": 3385,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiology"
    },
    {
        "value": 3386,
        "NOCCode": "3215",
        "JobTitle": "technologist, radiotherapy"
    },
    {
        "value": 3387,
        "NOCCode": "3215",
        "JobTitle": "therapeutic radiological technician"
    },
    {
        "value": 3388,
        "NOCCode": "3215",
        "JobTitle": "X-ray (radiology) technician"
    },
    {
        "value": 3389,
        "NOCCode": "3215",
        "JobTitle": "X-ray machine operator – medical"
    },
    {
        "value": 3390,
        "NOCCode": "3215",
        "JobTitle": "X-ray technician"
    },
    {
        "value": 3391,
        "NOCCode": "3216",
        "JobTitle": "adult echocardiographer"
    },
    {
        "value": 3392,
        "NOCCode": "3216",
        "JobTitle": "cardiac sonographer"
    },
    {
        "value": 3393,
        "NOCCode": "3216",
        "JobTitle": "cardiac ultrasound technician"
    },
    {
        "value": 3394,
        "NOCCode": "3216",
        "JobTitle": "cardiac ultrasound technologist"
    },
    {
        "value": 3395,
        "NOCCode": "3216",
        "JobTitle": "chief diagnostic medical sonographer"
    },
    {
        "value": 3396,
        "NOCCode": "3216",
        "JobTitle": "diagnostic cardiac sonographer"
    },
    {
        "value": 3397,
        "NOCCode": "3216",
        "JobTitle": "diagnostic echography technician"
    },
    {
        "value": 3398,
        "NOCCode": "3216",
        "JobTitle": "diagnostic medical sonographer"
    },
    {
        "value": 3399,
        "NOCCode": "3216",
        "JobTitle": "diagnostic medical sonography instructor"
    },
    {
        "value": 3400,
        "NOCCode": "3216",
        "JobTitle": "diagnostic ultrasonography technician"
    },
    {
        "value": 3401,
        "NOCCode": "3216",
        "JobTitle": "diagnostic ultrasound supervisor"
    },
    {
        "value": 3402,
        "NOCCode": "3216",
        "JobTitle": "diagnostic ultrasound technician"
    },
    {
        "value": 3403,
        "NOCCode": "3216",
        "JobTitle": "diagnostic ultrasound technologist – medical"
    },
    {
        "value": 3404,
        "NOCCode": "3216",
        "JobTitle": "echocardiographer"
    },
    {
        "value": 3405,
        "NOCCode": "3216",
        "JobTitle": "echocardiography technician"
    },
    {
        "value": 3406,
        "NOCCode": "3216",
        "JobTitle": "echocardiography technologist"
    },
    {
        "value": 3407,
        "NOCCode": "3216",
        "JobTitle": "echocardiography technologist sonographer"
    },
    {
        "value": 3408,
        "NOCCode": "3216",
        "JobTitle": "echography technician"
    },
    {
        "value": 3409,
        "NOCCode": "3216",
        "JobTitle": "echography technologist"
    },
    {
        "value": 3410,
        "NOCCode": "3216",
        "JobTitle": "instructor, diagnostic medical sonography"
    },
    {
        "value": 3411,
        "NOCCode": "3216",
        "JobTitle": "medical sonographer"
    },
    {
        "value": 3412,
        "NOCCode": "3216",
        "JobTitle": "medical sonographers supervisor"
    },
    {
        "value": 3413,
        "NOCCode": "3216",
        "JobTitle": "pediatric echocardiographer"
    },
    {
        "value": 3414,
        "NOCCode": "3216",
        "JobTitle": "RDMS (registered diagnostic medical sonographer)"
    },
    {
        "value": 3415,
        "NOCCode": "3216",
        "JobTitle": "registered diagnostic cardiac sonographer"
    },
    {
        "value": 3416,
        "NOCCode": "3216",
        "JobTitle": "registered diagnostic medical sonographer (RDMS)"
    },
    {
        "value": 3417,
        "NOCCode": "3216",
        "JobTitle": "registered technologist, ultrasound"
    },
    {
        "value": 3418,
        "NOCCode": "3216",
        "JobTitle": "registered ultrasound technologist"
    },
    {
        "value": 3419,
        "NOCCode": "3216",
        "JobTitle": "sonographer – medical"
    },
    {
        "value": 3420,
        "NOCCode": "3216",
        "JobTitle": "sonography technologist"
    },
    {
        "value": 3421,
        "NOCCode": "3216",
        "JobTitle": "student medical sonographer"
    },
    {
        "value": 3422,
        "NOCCode": "3216",
        "JobTitle": "student sonographer – medical"
    },
    {
        "value": 3423,
        "NOCCode": "3216",
        "JobTitle": "supervisor, diagnostic ultrasound"
    },
    {
        "value": 3424,
        "NOCCode": "3216",
        "JobTitle": "supervisor, medical sonographers"
    },
    {
        "value": 3425,
        "NOCCode": "3216",
        "JobTitle": "technician, diagnostic echography"
    },
    {
        "value": 3426,
        "NOCCode": "3216",
        "JobTitle": "technician, diagnostic ultrasonography"
    },
    {
        "value": 3427,
        "NOCCode": "3216",
        "JobTitle": "technician, diagnostic ultrasound"
    },
    {
        "value": 3428,
        "NOCCode": "3216",
        "JobTitle": "technician, echography"
    },
    {
        "value": 3429,
        "NOCCode": "3216",
        "JobTitle": "technician, ultrasonography"
    },
    {
        "value": 3430,
        "NOCCode": "3216",
        "JobTitle": "technician, ultrasound"
    },
    {
        "value": 3431,
        "NOCCode": "3216",
        "JobTitle": "technologist, echography"
    },
    {
        "value": 3432,
        "NOCCode": "3216",
        "JobTitle": "technologist, ultrasonography"
    },
    {
        "value": 3433,
        "NOCCode": "3216",
        "JobTitle": "technologist, ultrasound"
    },
    {
        "value": 3434,
        "NOCCode": "3216",
        "JobTitle": "ultrasonographer – medical"
    },
    {
        "value": 3435,
        "NOCCode": "3216",
        "JobTitle": "ultrasonography technician"
    },
    {
        "value": 3436,
        "NOCCode": "3216",
        "JobTitle": "ultrasonography technologist"
    },
    {
        "value": 3437,
        "NOCCode": "3216",
        "JobTitle": "ultrasound supervisor"
    },
    {
        "value": 3438,
        "NOCCode": "3216",
        "JobTitle": "ultrasound technician"
    },
    {
        "value": 3439,
        "NOCCode": "3216",
        "JobTitle": "ultrasound technologist"
    },
    {
        "value": 3440,
        "NOCCode": "3216",
        "JobTitle": "vascular sonographer"
    },
    {
        "value": 3441,
        "NOCCode": "3217",
        "JobTitle": "assistant cardiology supervisor"
    },
    {
        "value": 3442,
        "NOCCode": "3217",
        "JobTitle": "assistant supervisor, cardiology"
    },
    {
        "value": 3443,
        "NOCCode": "3217",
        "JobTitle": "cardiac catheterization technician"
    },
    {
        "value": 3444,
        "NOCCode": "3217",
        "JobTitle": "cardiac stress technologist"
    },
    {
        "value": 3445,
        "NOCCode": "3217",
        "JobTitle": "cardiac technician"
    },
    {
        "value": 3446,
        "NOCCode": "3217",
        "JobTitle": "cardiology instructor"
    },
    {
        "value": 3447,
        "NOCCode": "3217",
        "JobTitle": "cardiology supervisor"
    },
    {
        "value": 3448,
        "NOCCode": "3217",
        "JobTitle": "cardiology technician"
    },
    {
        "value": 3449,
        "NOCCode": "3217",
        "JobTitle": "cardiology technologist"
    },
    {
        "value": 3450,
        "NOCCode": "3217",
        "JobTitle": "charge technologist – electrocardiography"
    },
    {
        "value": 3451,
        "NOCCode": "3217",
        "JobTitle": "chief electroencephalograph technologist"
    },
    {
        "value": 3452,
        "NOCCode": "3217",
        "JobTitle": "chief electromyography (EMG) technologist"
    },
    {
        "value": 3453,
        "NOCCode": "3217",
        "JobTitle": "chief EMG (electromyography) technologist"
    },
    {
        "value": 3454,
        "NOCCode": "3217",
        "JobTitle": "chief technologist, electroencephalograph"
    },
    {
        "value": 3455,
        "NOCCode": "3217",
        "JobTitle": "chief technologist, electromyography (EMG)"
    },
    {
        "value": 3456,
        "NOCCode": "3217",
        "JobTitle": "chief technologist, EMG (electromyography)"
    },
    {
        "value": 3457,
        "NOCCode": "3217",
        "JobTitle": "ECG (electrocardiographic) technician"
    },
    {
        "value": 3458,
        "NOCCode": "3217",
        "JobTitle": "ECG (electrocardiographic) technologist"
    },
    {
        "value": 3459,
        "NOCCode": "3217",
        "JobTitle": "echoencephalographic technologist"
    },
    {
        "value": 3460,
        "NOCCode": "3217",
        "JobTitle": "echoencephalography technician"
    },
    {
        "value": 3461,
        "NOCCode": "3217",
        "JobTitle": "EEG (electroencephalograph) chief technologist"
    },
    {
        "value": 3462,
        "NOCCode": "3217",
        "JobTitle": "EEG (electroencephalograph) technician"
    },
    {
        "value": 3463,
        "NOCCode": "3217",
        "JobTitle": "EEG (electroencephalograph) technologist"
    },
    {
        "value": 3464,
        "NOCCode": "3217",
        "JobTitle": "EEG (electroencephalography) technologist"
    },
    {
        "value": 3465,
        "NOCCode": "3217",
        "JobTitle": "electrocardiographic (ECG) technician"
    },
    {
        "value": 3466,
        "NOCCode": "3217",
        "JobTitle": "electrocardiographic (ECG) technologist"
    },
    {
        "value": 3467,
        "NOCCode": "3217",
        "JobTitle": "electrocardiography technician"
    },
    {
        "value": 3468,
        "NOCCode": "3217",
        "JobTitle": "electrocardiography technologist"
    },
    {
        "value": 3469,
        "NOCCode": "3217",
        "JobTitle": "electrocardiology technician"
    },
    {
        "value": 3470,
        "NOCCode": "3217",
        "JobTitle": "electrocardiology technologist"
    },
    {
        "value": 3471,
        "NOCCode": "3217",
        "JobTitle": "electroencephalograph (EEG) chief technologist"
    },
    {
        "value": 3472,
        "NOCCode": "3217",
        "JobTitle": "electroencephalograph (EEG) technician"
    },
    {
        "value": 3473,
        "NOCCode": "3217",
        "JobTitle": "electroencephalograph (EEG) technologist"
    },
    {
        "value": 3474,
        "NOCCode": "3217",
        "JobTitle": "electroencephalograph technologist"
    },
    {
        "value": 3475,
        "NOCCode": "3217",
        "JobTitle": "electroencephalographic instructor"
    },
    {
        "value": 3476,
        "NOCCode": "3217",
        "JobTitle": "electromyography (EMG) chief technologist"
    },
    {
        "value": 3477,
        "NOCCode": "3217",
        "JobTitle": "electromyography (EMG) technician"
    },
    {
        "value": 3478,
        "NOCCode": "3217",
        "JobTitle": "electromyography (EMG) technologist"
    },
    {
        "value": 3479,
        "NOCCode": "3217",
        "JobTitle": "electroneurodiagnostic (END) technologist"
    },
    {
        "value": 3480,
        "NOCCode": "3217",
        "JobTitle": "electroneurophysiology (ENP) technologist"
    },
    {
        "value": 3481,
        "NOCCode": "3217",
        "JobTitle": "EMG (electromyography) chief technologist"
    },
    {
        "value": 3482,
        "NOCCode": "3217",
        "JobTitle": "EMG (electromyography) technician"
    },
    {
        "value": 3483,
        "NOCCode": "3217",
        "JobTitle": "EMG (electromyography) technologist"
    },
    {
        "value": 3484,
        "NOCCode": "3217",
        "JobTitle": "END (electroneurodiagnostic) technologist"
    },
    {
        "value": 3485,
        "NOCCode": "3217",
        "JobTitle": "evoked potential (EP) technologist"
    },
    {
        "value": 3486,
        "NOCCode": "3217",
        "JobTitle": "Holter monitor technologist"
    },
    {
        "value": 3487,
        "NOCCode": "3217",
        "JobTitle": "Holter scanning technologist"
    },
    {
        "value": 3488,
        "NOCCode": "3217",
        "JobTitle": "neuroelectrophysiology technologist"
    },
    {
        "value": 3489,
        "NOCCode": "3217",
        "JobTitle": "non-registered electrocardiography technician"
    },
    {
        "value": 3490,
        "NOCCode": "3217",
        "JobTitle": "registered cardiology technologist"
    },
    {
        "value": 3491,
        "NOCCode": "3217",
        "JobTitle": "registered electrocardiography technician"
    },
    {
        "value": 3492,
        "NOCCode": "3217",
        "JobTitle": "student cardiology technologist"
    },
    {
        "value": 3493,
        "NOCCode": "3217",
        "JobTitle": "supervisor, cardiology"
    },
    {
        "value": 3494,
        "NOCCode": "3217",
        "JobTitle": "technician, cardiac catheterization"
    },
    {
        "value": 3495,
        "NOCCode": "3217",
        "JobTitle": "technician, cardiology"
    },
    {
        "value": 3496,
        "NOCCode": "3217",
        "JobTitle": "technician, echoencephalography"
    },
    {
        "value": 3497,
        "NOCCode": "3217",
        "JobTitle": "technician, electrocardiographic (ECG)"
    },
    {
        "value": 3498,
        "NOCCode": "3217",
        "JobTitle": "technician, electrocardiography"
    },
    {
        "value": 3499,
        "NOCCode": "3217",
        "JobTitle": "technician, electrocardiology"
    },
    {
        "value": 3500,
        "NOCCode": "3217",
        "JobTitle": "technician, electroencephalograph (EEG)"
    },
    {
        "value": 3501,
        "NOCCode": "3217",
        "JobTitle": "technician, electromyography (EMG)"
    },
    {
        "value": 3502,
        "NOCCode": "3217",
        "JobTitle": "technician, EMG (electromyography)"
    },
    {
        "value": 3503,
        "NOCCode": "3217",
        "JobTitle": "technologist, cardiac catheterization"
    },
    {
        "value": 3504,
        "NOCCode": "3217",
        "JobTitle": "technologist, cardiology"
    },
    {
        "value": 3505,
        "NOCCode": "3217",
        "JobTitle": "technologist, ECG (electrocardiographic)"
    },
    {
        "value": 3506,
        "NOCCode": "3217",
        "JobTitle": "technologist, echoencephalographic"
    },
    {
        "value": 3507,
        "NOCCode": "3217",
        "JobTitle": "technologist, electrocardiographic (ECG)"
    },
    {
        "value": 3508,
        "NOCCode": "3217",
        "JobTitle": "technologist, electrocardiography"
    },
    {
        "value": 3509,
        "NOCCode": "3217",
        "JobTitle": "technologist, electrocardiology"
    },
    {
        "value": 3510,
        "NOCCode": "3217",
        "JobTitle": "technologist, electroencephalograph"
    },
    {
        "value": 3511,
        "NOCCode": "3217",
        "JobTitle": "technologist, electroencephalograph (EEG)"
    },
    {
        "value": 3512,
        "NOCCode": "3217",
        "JobTitle": "technologist, electromyography (EMG)"
    },
    {
        "value": 3513,
        "NOCCode": "3217",
        "JobTitle": "technologist, electroneurodiagnostic (END)"
    },
    {
        "value": 3514,
        "NOCCode": "3217",
        "JobTitle": "technologist, EMG (electromyography)"
    },
    {
        "value": 3515,
        "NOCCode": "3217",
        "JobTitle": "technologist, END (electroneurodiagnostic)"
    },
    {
        "value": 3516,
        "NOCCode": "3217",
        "JobTitle": "technologist, evoked potential (EP)"
    },
    {
        "value": 3517,
        "NOCCode": "3217",
        "JobTitle": "technologist, vascular"
    },
    {
        "value": 3518,
        "NOCCode": "3217",
        "JobTitle": "vascular technologist"
    },
    {
        "value": 3519,
        "NOCCode": "3219",
        "JobTitle": "aeromedical instructor"
    },
    {
        "value": 3520,
        "NOCCode": "3219",
        "JobTitle": "aeromedical technician"
    },
    {
        "value": 3521,
        "NOCCode": "3219",
        "JobTitle": "aeromedicine technician"
    },
    {
        "value": 3522,
        "NOCCode": "3219",
        "JobTitle": "apprentice ocularist"
    },
    {
        "value": 3523,
        "NOCCode": "3219",
        "JobTitle": "artificial eye maker"
    },
    {
        "value": 3524,
        "NOCCode": "3219",
        "JobTitle": "artificial limb assembler"
    },
    {
        "value": 3525,
        "NOCCode": "3219",
        "JobTitle": "artificial limb finisher"
    },
    {
        "value": 3526,
        "NOCCode": "3219",
        "JobTitle": "artificial limb maker"
    },
    {
        "value": 3527,
        "NOCCode": "3219",
        "JobTitle": "assembler, artificial limbs"
    },
    {
        "value": 3528,
        "NOCCode": "3219",
        "JobTitle": "assembler, orthopedic appliances"
    },
    {
        "value": 3529,
        "NOCCode": "3219",
        "JobTitle": "aviation medicine technician"
    },
    {
        "value": 3530,
        "NOCCode": "3219",
        "JobTitle": "brace maker"
    },
    {
        "value": 3531,
        "NOCCode": "3219",
        "JobTitle": "C Ped (certified pedorthist)"
    },
    {
        "value": 3532,
        "NOCCode": "3219",
        "JobTitle": "certified orthotist (CO)"
    },
    {
        "value": 3533,
        "NOCCode": "3219",
        "JobTitle": "certified pedorthist (C Ped)"
    },
    {
        "value": 3534,
        "NOCCode": "3219",
        "JobTitle": "certified prosthetist"
    },
    {
        "value": 3535,
        "NOCCode": "3219",
        "JobTitle": "certified prosthetist-orthotist (CPO)"
    },
    {
        "value": 3536,
        "NOCCode": "3219",
        "JobTitle": "CO (certified orthotist)"
    },
    {
        "value": 3537,
        "NOCCode": "3219",
        "JobTitle": "CPO (certified prosthetist and orthotist)"
    },
    {
        "value": 3538,
        "NOCCode": "3219",
        "JobTitle": "dialysis technician"
    },
    {
        "value": 3539,
        "NOCCode": "3219",
        "JobTitle": "dialysis technologist"
    },
    {
        "value": 3540,
        "NOCCode": "3219",
        "JobTitle": "dietary technician"
    },
    {
        "value": 3541,
        "NOCCode": "3219",
        "JobTitle": "dietary technologist"
    },
    {
        "value": 3542,
        "NOCCode": "3219",
        "JobTitle": "finisher, artificial limbs"
    },
    {
        "value": 3543,
        "NOCCode": "3219",
        "JobTitle": "fitter, surgical appliances"
    },
    {
        "value": 3544,
        "NOCCode": "3219",
        "JobTitle": "food and nutrition technician – dietetics"
    },
    {
        "value": 3545,
        "NOCCode": "3219",
        "JobTitle": "hemodialysis technician"
    },
    {
        "value": 3546,
        "NOCCode": "3219",
        "JobTitle": "hemodialysis technologist"
    },
    {
        "value": 3547,
        "NOCCode": "3219",
        "JobTitle": "kidney dialysis technician"
    },
    {
        "value": 3548,
        "NOCCode": "3219",
        "JobTitle": "kidney dialysis technologist"
    },
    {
        "value": 3549,
        "NOCCode": "3219",
        "JobTitle": "ocular prosthetist"
    },
    {
        "value": 3550,
        "NOCCode": "3219",
        "JobTitle": "ocularist"
    },
    {
        "value": 3551,
        "NOCCode": "3219",
        "JobTitle": "ocularist apprentice"
    },
    {
        "value": 3552,
        "NOCCode": "3219",
        "JobTitle": "ocularist technician"
    },
    {
        "value": 3553,
        "NOCCode": "3219",
        "JobTitle": "orthopedic appliance assembler"
    },
    {
        "value": 3554,
        "NOCCode": "3219",
        "JobTitle": "orthopedic mechanic"
    },
    {
        "value": 3555,
        "NOCCode": "3219",
        "JobTitle": "orthotic corset maker"
    },
    {
        "value": 3556,
        "NOCCode": "3219",
        "JobTitle": "orthotic technician"
    },
    {
        "value": 3557,
        "NOCCode": "3219",
        "JobTitle": "orthotist"
    },
    {
        "value": 3558,
        "NOCCode": "3219",
        "JobTitle": "orthotist-prosthetist"
    },
    {
        "value": 3559,
        "NOCCode": "3219",
        "JobTitle": "pedorthist"
    },
    {
        "value": 3560,
        "NOCCode": "3219",
        "JobTitle": "pharmacy technical assistant"
    },
    {
        "value": 3561,
        "NOCCode": "3219",
        "JobTitle": "pharmacy technician"
    },
    {
        "value": 3562,
        "NOCCode": "3219",
        "JobTitle": "prosthetic aide"
    },
    {
        "value": 3563,
        "NOCCode": "3219",
        "JobTitle": "prosthetic assistant"
    },
    {
        "value": 3564,
        "NOCCode": "3219",
        "JobTitle": "prosthetic technician"
    },
    {
        "value": 3565,
        "NOCCode": "3219",
        "JobTitle": "prosthetic-orthotic technician"
    },
    {
        "value": 3566,
        "NOCCode": "3219",
        "JobTitle": "prosthetist"
    },
    {
        "value": 3567,
        "NOCCode": "3219",
        "JobTitle": "prosthetist assistant"
    },
    {
        "value": 3568,
        "NOCCode": "3219",
        "JobTitle": "prosthetist-orthotist"
    },
    {
        "value": 3569,
        "NOCCode": "3219",
        "JobTitle": "R.T.O. (registered orthotic technician)"
    },
    {
        "value": 3570,
        "NOCCode": "3219",
        "JobTitle": "R.T.P. (registered prosthetic technician)"
    },
    {
        "value": 3571,
        "NOCCode": "3219",
        "JobTitle": "R.T.P.O. (registered prosthetic and orthotic technician)"
    },
    {
        "value": 3572,
        "NOCCode": "3219",
        "JobTitle": "registered orthotic technician (R.T.O.)"
    },
    {
        "value": 3573,
        "NOCCode": "3219",
        "JobTitle": "registered prosthetic and orthotic technician (R.T.P.O.)"
    },
    {
        "value": 3574,
        "NOCCode": "3219",
        "JobTitle": "registered prosthetic technician (R.T.P.)"
    },
    {
        "value": 3575,
        "NOCCode": "3219",
        "JobTitle": "surgical appliance fitter"
    },
    {
        "value": 3576,
        "NOCCode": "3219",
        "JobTitle": "surgical fitter"
    },
    {
        "value": 3577,
        "NOCCode": "3219",
        "JobTitle": "technical assistant, pharmacy"
    },
    {
        "value": 3578,
        "NOCCode": "3219",
        "JobTitle": "technician, aeromedicine"
    },
    {
        "value": 3579,
        "NOCCode": "3219",
        "JobTitle": "technician, aviation medicine"
    },
    {
        "value": 3580,
        "NOCCode": "3219",
        "JobTitle": "technician, food and nutrition – dietetics"
    },
    {
        "value": 3581,
        "NOCCode": "3219",
        "JobTitle": "technician, orthotic"
    },
    {
        "value": 3582,
        "NOCCode": "3219",
        "JobTitle": "technician, pharmacy"
    },
    {
        "value": 3583,
        "NOCCode": "3219",
        "JobTitle": "technician, prosthetic"
    },
    {
        "value": 3584,
        "NOCCode": "3219",
        "JobTitle": "technician, prosthetic-orthotic"
    },
    {
        "value": 3585,
        "NOCCode": "3221",
        "JobTitle": "apprentice dental mechanic"
    },
    {
        "value": 3586,
        "NOCCode": "3221",
        "JobTitle": "dental mechanic"
    },
    {
        "value": 3587,
        "NOCCode": "3221",
        "JobTitle": "dental mechanic apprentice"
    },
    {
        "value": 3588,
        "NOCCode": "3221",
        "JobTitle": "denture mechanic"
    },
    {
        "value": 3589,
        "NOCCode": "3221",
        "JobTitle": "denture technician"
    },
    {
        "value": 3590,
        "NOCCode": "3221",
        "JobTitle": "denture therapist"
    },
    {
        "value": 3591,
        "NOCCode": "3221",
        "JobTitle": "denturist"
    },
    {
        "value": 3592,
        "NOCCode": "3221",
        "JobTitle": "denturologist"
    },
    {
        "value": 3593,
        "NOCCode": "3221",
        "JobTitle": "mechanic, dentures"
    },
    {
        "value": 3594,
        "NOCCode": "3221",
        "JobTitle": "student dental mechanic"
    },
    {
        "value": 3595,
        "NOCCode": "3221",
        "JobTitle": "student denturist"
    },
    {
        "value": 3596,
        "NOCCode": "3221",
        "JobTitle": "technician, dentures"
    },
    {
        "value": 3597,
        "NOCCode": "3221",
        "JobTitle": "therapist, denture"
    },
    {
        "value": 3598,
        "NOCCode": "3222",
        "JobTitle": "community dental hygienist"
    },
    {
        "value": 3599,
        "NOCCode": "3222",
        "JobTitle": "dental hygiene technician"
    },
    {
        "value": 3600,
        "NOCCode": "3222",
        "JobTitle": "dental hygienist"
    },
    {
        "value": 3601,
        "NOCCode": "3222",
        "JobTitle": "dental hygienist technician"
    },
    {
        "value": 3602,
        "NOCCode": "3222",
        "JobTitle": "dental nurse"
    },
    {
        "value": 3603,
        "NOCCode": "3222",
        "JobTitle": "dental therapist"
    },
    {
        "value": 3604,
        "NOCCode": "3222",
        "JobTitle": "expanded duty dental hygienist"
    },
    {
        "value": 3605,
        "NOCCode": "3222",
        "JobTitle": "expanded duty dental nurse"
    },
    {
        "value": 3606,
        "NOCCode": "3222",
        "JobTitle": "licensed dental therapist"
    },
    {
        "value": 3607,
        "NOCCode": "3222",
        "JobTitle": "orthodontic hygienist"
    },
    {
        "value": 3608,
        "NOCCode": "3222",
        "JobTitle": "periodontal hygienist"
    },
    {
        "value": 3609,
        "NOCCode": "3222",
        "JobTitle": "registered dental hygienist"
    },
    {
        "value": 3610,
        "NOCCode": "3222",
        "JobTitle": "registered dental therapist"
    },
    {
        "value": 3611,
        "NOCCode": "3223",
        "JobTitle": "apprentice dental prosthesis maker"
    },
    {
        "value": 3612,
        "NOCCode": "3223",
        "JobTitle": "apprentice dental technician"
    },
    {
        "value": 3613,
        "NOCCode": "3223",
        "JobTitle": "bench moulder, dentures"
    },
    {
        "value": 3614,
        "NOCCode": "3223",
        "JobTitle": "CDT (certified dental technician)"
    },
    {
        "value": 3615,
        "NOCCode": "3223",
        "JobTitle": "ceramic caster, dentures"
    },
    {
        "value": 3616,
        "NOCCode": "3223",
        "JobTitle": "ceramic denture caster"
    },
    {
        "value": 3617,
        "NOCCode": "3223",
        "JobTitle": "ceramic denture mechanic"
    },
    {
        "value": 3618,
        "NOCCode": "3223",
        "JobTitle": "ceramic denture mechanic-moulder"
    },
    {
        "value": 3619,
        "NOCCode": "3223",
        "JobTitle": "ceramic denture moulder"
    },
    {
        "value": 3620,
        "NOCCode": "3223",
        "JobTitle": "ceramic moulder, dentures"
    },
    {
        "value": 3621,
        "NOCCode": "3223",
        "JobTitle": "certified dental technician"
    },
    {
        "value": 3622,
        "NOCCode": "3223",
        "JobTitle": "crown and bridge dental technician"
    },
    {
        "value": 3623,
        "NOCCode": "3223",
        "JobTitle": "dental ceramic caster"
    },
    {
        "value": 3624,
        "NOCCode": "3223",
        "JobTitle": "dental ceramic moulder"
    },
    {
        "value": 3625,
        "NOCCode": "3223",
        "JobTitle": "dental ceramist"
    },
    {
        "value": 3626,
        "NOCCode": "3223",
        "JobTitle": "dental laboratory assistant"
    },
    {
        "value": 3627,
        "NOCCode": "3223",
        "JobTitle": "dental laboratory bench worker"
    },
    {
        "value": 3628,
        "NOCCode": "3223",
        "JobTitle": "dental laboratory technician"
    },
    {
        "value": 3629,
        "NOCCode": "3223",
        "JobTitle": "dental laboratory technician assistant"
    },
    {
        "value": 3630,
        "NOCCode": "3223",
        "JobTitle": "dental prosthesis maker"
    },
    {
        "value": 3631,
        "NOCCode": "3223",
        "JobTitle": "dental technician"
    },
    {
        "value": 3632,
        "NOCCode": "3223",
        "JobTitle": "dental technician apprentice"
    },
    {
        "value": 3633,
        "NOCCode": "3223",
        "JobTitle": "dental technician supervisor"
    },
    {
        "value": 3634,
        "NOCCode": "3223",
        "JobTitle": "dental technologist"
    },
    {
        "value": 3635,
        "NOCCode": "3223",
        "JobTitle": "denture bench moulder"
    },
    {
        "value": 3636,
        "NOCCode": "3223",
        "JobTitle": "denture finisher"
    },
    {
        "value": 3637,
        "NOCCode": "3223",
        "JobTitle": "denture framework finisher"
    },
    {
        "value": 3638,
        "NOCCode": "3223",
        "JobTitle": "denture packer"
    },
    {
        "value": 3639,
        "NOCCode": "3223",
        "JobTitle": "denture setter"
    },
    {
        "value": 3640,
        "NOCCode": "3223",
        "JobTitle": "denture set-up man/woman"
    },
    {
        "value": 3641,
        "NOCCode": "3223",
        "JobTitle": "denture trimmer-polisher"
    },
    {
        "value": 3642,
        "NOCCode": "3223",
        "JobTitle": "denture wax pattern former"
    },
    {
        "value": 3643,
        "NOCCode": "3223",
        "JobTitle": "denture wax pattern former – metal framework"
    },
    {
        "value": 3644,
        "NOCCode": "3223",
        "JobTitle": "framework finisher, dentures"
    },
    {
        "value": 3645,
        "NOCCode": "3223",
        "JobTitle": "metal dental technician"
    },
    {
        "value": 3646,
        "NOCCode": "3223",
        "JobTitle": "occlusion rim former – dentures"
    },
    {
        "value": 3647,
        "NOCCode": "3223",
        "JobTitle": "orthodontic band maker"
    },
    {
        "value": 3648,
        "NOCCode": "3223",
        "JobTitle": "orthodontic dental technician"
    },
    {
        "value": 3649,
        "NOCCode": "3223",
        "JobTitle": "orthodontic prosthesis maker"
    },
    {
        "value": 3650,
        "NOCCode": "3223",
        "JobTitle": "orthodontic technician"
    },
    {
        "value": 3651,
        "NOCCode": "3223",
        "JobTitle": "plaster moulder – dentures"
    },
    {
        "value": 3652,
        "NOCCode": "3223",
        "JobTitle": "RDT (registered dental technician)"
    },
    {
        "value": 3653,
        "NOCCode": "3223",
        "JobTitle": "RDT (registered dental technologist)"
    },
    {
        "value": 3654,
        "NOCCode": "3223",
        "JobTitle": "registered dental technician"
    },
    {
        "value": 3655,
        "NOCCode": "3223",
        "JobTitle": "registered dental technologist"
    },
    {
        "value": 3656,
        "NOCCode": "3223",
        "JobTitle": "set-up man/woman, dentures"
    },
    {
        "value": 3657,
        "NOCCode": "3223",
        "JobTitle": "supervisor, dental technician"
    },
    {
        "value": 3658,
        "NOCCode": "3223",
        "JobTitle": "technician, dental laboratory"
    },
    {
        "value": 3659,
        "NOCCode": "3231",
        "JobTitle": "apprentice optician"
    },
    {
        "value": 3660,
        "NOCCode": "3231",
        "JobTitle": "contact lens dispenser"
    },
    {
        "value": 3661,
        "NOCCode": "3231",
        "JobTitle": "contact lens fitter"
    },
    {
        "value": 3662,
        "NOCCode": "3231",
        "JobTitle": "contact lens practitioner"
    },
    {
        "value": 3663,
        "NOCCode": "3231",
        "JobTitle": "contact lens technician"
    },
    {
        "value": 3664,
        "NOCCode": "3231",
        "JobTitle": "dispenser, contact lens"
    },
    {
        "value": 3665,
        "NOCCode": "3231",
        "JobTitle": "dispenser, ophthalmic devices"
    },
    {
        "value": 3666,
        "NOCCode": "3231",
        "JobTitle": "dispenser, optical devices"
    },
    {
        "value": 3667,
        "NOCCode": "3231",
        "JobTitle": "dispensing optician"
    },
    {
        "value": 3668,
        "NOCCode": "3231",
        "JobTitle": "dispensing optician student"
    },
    {
        "value": 3669,
        "NOCCode": "3231",
        "JobTitle": "eyeglasses fitter"
    },
    {
        "value": 3670,
        "NOCCode": "3231",
        "JobTitle": "eyeglasses frame fitter"
    },
    {
        "value": 3671,
        "NOCCode": "3231",
        "JobTitle": "fitter, contact lens"
    },
    {
        "value": 3672,
        "NOCCode": "3231",
        "JobTitle": "fitter, eyeglasses"
    },
    {
        "value": 3673,
        "NOCCode": "3231",
        "JobTitle": "fitter, eyeglasses frame"
    },
    {
        "value": 3674,
        "NOCCode": "3231",
        "JobTitle": "intern optician"
    },
    {
        "value": 3675,
        "NOCCode": "3231",
        "JobTitle": "licensed contact lens practitioner"
    },
    {
        "value": 3676,
        "NOCCode": "3231",
        "JobTitle": "licensed dispensing optician"
    },
    {
        "value": 3677,
        "NOCCode": "3231",
        "JobTitle": "licensed optician"
    },
    {
        "value": 3678,
        "NOCCode": "3231",
        "JobTitle": "licensed practitioner, contact lenses"
    },
    {
        "value": 3679,
        "NOCCode": "3231",
        "JobTitle": "managing optician"
    },
    {
        "value": 3680,
        "NOCCode": "3231",
        "JobTitle": "ophthalmic device dispenser"
    },
    {
        "value": 3681,
        "NOCCode": "3231",
        "JobTitle": "ophthalmic dispenser"
    },
    {
        "value": 3682,
        "NOCCode": "3231",
        "JobTitle": "optical device dispenser"
    },
    {
        "value": 3683,
        "NOCCode": "3231",
        "JobTitle": "optical dispensing manager"
    },
    {
        "value": 3684,
        "NOCCode": "3231",
        "JobTitle": "optical frame fitter"
    },
    {
        "value": 3685,
        "NOCCode": "3231",
        "JobTitle": "optician"
    },
    {
        "value": 3686,
        "NOCCode": "3231",
        "JobTitle": "optician, dispensing"
    },
    {
        "value": 3687,
        "NOCCode": "3231",
        "JobTitle": "optician, student"
    },
    {
        "value": 3688,
        "NOCCode": "3231",
        "JobTitle": "optometric dispenser"
    },
    {
        "value": 3689,
        "NOCCode": "3231",
        "JobTitle": "practitioner, contact lenses"
    },
    {
        "value": 3690,
        "NOCCode": "3231",
        "JobTitle": "spectacle frame fitter"
    },
    {
        "value": 3691,
        "NOCCode": "3231",
        "JobTitle": "student optician"
    },
    {
        "value": 3692,
        "NOCCode": "3231",
        "JobTitle": "technician, contact lenses"
    },
    {
        "value": 3693,
        "NOCCode": "3231",
        "JobTitle": "technician, visual ortheses"
    },
    {
        "value": 3694,
        "NOCCode": "3231",
        "JobTitle": "technician, visual orthotic devices"
    },
    {
        "value": 3695,
        "NOCCode": "3231",
        "JobTitle": "visual orthesis technician"
    },
    {
        "value": 3696,
        "NOCCode": "3231",
        "JobTitle": "visual orthotic device technician"
    },
    {
        "value": 3697,
        "NOCCode": "3232",
        "JobTitle": "acupressure therapist"
    },
    {
        "value": 3698,
        "NOCCode": "3232",
        "JobTitle": "acupuncture, doctor of"
    },
    {
        "value": 3699,
        "NOCCode": "3232",
        "JobTitle": "acupuncturist"
    },
    {
        "value": 3700,
        "NOCCode": "3232",
        "JobTitle": "aromatherapist"
    },
    {
        "value": 3701,
        "NOCCode": "3232",
        "JobTitle": "auriculotherapist"
    },
    {
        "value": 3702,
        "NOCCode": "3232",
        "JobTitle": "ayurvedic practitioner"
    },
    {
        "value": 3703,
        "NOCCode": "3232",
        "JobTitle": "certified iridologist"
    },
    {
        "value": 3704,
        "NOCCode": "3232",
        "JobTitle": "certified reflexologist"
    },
    {
        "value": 3705,
        "NOCCode": "3232",
        "JobTitle": "certified rolfer"
    },
    {
        "value": 3706,
        "NOCCode": "3232",
        "JobTitle": "certified rolfing movement teacher"
    },
    {
        "value": 3707,
        "NOCCode": "3232",
        "JobTitle": "chartered herbalist"
    },
    {
        "value": 3708,
        "NOCCode": "3232",
        "JobTitle": "Chinese medicine doctor (CMD)"
    },
    {
        "value": 3709,
        "NOCCode": "3232",
        "JobTitle": "Chinese medicine practitioner"
    },
    {
        "value": 3710,
        "NOCCode": "3232",
        "JobTitle": "clinical hypnotherapist"
    },
    {
        "value": 3711,
        "NOCCode": "3232",
        "JobTitle": "CMD (Chinese medicine doctor)"
    },
    {
        "value": 3712,
        "NOCCode": "3232",
        "JobTitle": "doctor of acupuncture"
    },
    {
        "value": 3713,
        "NOCCode": "3232",
        "JobTitle": "doctor of Chinese medicine"
    },
    {
        "value": 3714,
        "NOCCode": "3232",
        "JobTitle": "doctor of homeopathic therapeutics"
    },
    {
        "value": 3715,
        "NOCCode": "3232",
        "JobTitle": "doctor of homeopathy"
    },
    {
        "value": 3716,
        "NOCCode": "3232",
        "JobTitle": "doctor of Oriental medicine"
    },
    {
        "value": 3717,
        "NOCCode": "3232",
        "JobTitle": "herb practitioner"
    },
    {
        "value": 3718,
        "NOCCode": "3232",
        "JobTitle": "herbal practitioner"
    },
    {
        "value": 3719,
        "NOCCode": "3232",
        "JobTitle": "herbal specialist"
    },
    {
        "value": 3720,
        "NOCCode": "3232",
        "JobTitle": "herbalist"
    },
    {
        "value": 3721,
        "NOCCode": "3232",
        "JobTitle": "herbologist"
    },
    {
        "value": 3722,
        "NOCCode": "3232",
        "JobTitle": "herbology practitioner"
    },
    {
        "value": 3723,
        "NOCCode": "3232",
        "JobTitle": "herborist"
    },
    {
        "value": 3724,
        "NOCCode": "3232",
        "JobTitle": "holistic practitioner"
    },
    {
        "value": 3725,
        "NOCCode": "3232",
        "JobTitle": "homeopath"
    },
    {
        "value": 3726,
        "NOCCode": "3232",
        "JobTitle": "homeopathic physician"
    },
    {
        "value": 3727,
        "NOCCode": "3232",
        "JobTitle": "homeopathic practitioner"
    },
    {
        "value": 3728,
        "NOCCode": "3232",
        "JobTitle": "homeopathist"
    },
    {
        "value": 3729,
        "NOCCode": "3232",
        "JobTitle": "hypnotherapist"
    },
    {
        "value": 3730,
        "NOCCode": "3232",
        "JobTitle": "iridologist"
    },
    {
        "value": 3731,
        "NOCCode": "3232",
        "JobTitle": "medical Qi gong pactitioner"
    },
    {
        "value": 3732,
        "NOCCode": "3232",
        "JobTitle": "Oriental medical practitioner"
    },
    {
        "value": 3733,
        "NOCCode": "3232",
        "JobTitle": "osteopathic manual practitioner"
    },
    {
        "value": 3734,
        "NOCCode": "3232",
        "JobTitle": "physician, homeopathic"
    },
    {
        "value": 3735,
        "NOCCode": "3232",
        "JobTitle": "practitioner, Chinese medicine"
    },
    {
        "value": 3736,
        "NOCCode": "3232",
        "JobTitle": "practitioner, herb"
    },
    {
        "value": 3737,
        "NOCCode": "3232",
        "JobTitle": "practitioner, herbal"
    },
    {
        "value": 3738,
        "NOCCode": "3232",
        "JobTitle": "practitioner, herbology"
    },
    {
        "value": 3739,
        "NOCCode": "3232",
        "JobTitle": "practitioner, holistic"
    },
    {
        "value": 3740,
        "NOCCode": "3232",
        "JobTitle": "practitioner, homeopathic"
    },
    {
        "value": 3741,
        "NOCCode": "3232",
        "JobTitle": "reflexologist"
    },
    {
        "value": 3742,
        "NOCCode": "3232",
        "JobTitle": "registered acupuncture practitioner"
    },
    {
        "value": 3743,
        "NOCCode": "3232",
        "JobTitle": "remedial herbalist"
    },
    {
        "value": 3744,
        "NOCCode": "3232",
        "JobTitle": "remedial herbologist"
    },
    {
        "value": 3745,
        "NOCCode": "3232",
        "JobTitle": "rolfer"
    },
    {
        "value": 3746,
        "NOCCode": "3232",
        "JobTitle": "rolfing movement teacher"
    },
    {
        "value": 3747,
        "NOCCode": "3232",
        "JobTitle": "shaman"
    },
    {
        "value": 3748,
        "NOCCode": "3232",
        "JobTitle": "shiatsu therapist"
    },
    {
        "value": 3749,
        "NOCCode": "3232",
        "JobTitle": "teacher, rolfing movement"
    },
    {
        "value": 3750,
        "NOCCode": "3232",
        "JobTitle": "therapist, shiatsu"
    },
    {
        "value": 3751,
        "NOCCode": "3232",
        "JobTitle": "traditional Chinese medicine practitioner"
    },
    {
        "value": 3752,
        "NOCCode": "3233",
        "JobTitle": "certified nursing assistant (CNA)"
    },
    {
        "value": 3753,
        "NOCCode": "3233",
        "JobTitle": "CNA (certified nursing assistant)"
    },
    {
        "value": 3754,
        "NOCCode": "3233",
        "JobTitle": "graduate nursing assistant"
    },
    {
        "value": 3755,
        "NOCCode": "3233",
        "JobTitle": "L.P.N. (licensed practical nurse)"
    },
    {
        "value": 3756,
        "NOCCode": "3233",
        "JobTitle": "licensed nursing assistant"
    },
    {
        "value": 3757,
        "NOCCode": "3233",
        "JobTitle": "licensed practical nurse (L.P.N.)"
    },
    {
        "value": 3758,
        "NOCCode": "3233",
        "JobTitle": "nursing assistant (registered – Québec)"
    },
    {
        "value": 3759,
        "NOCCode": "3233",
        "JobTitle": "operating room technician"
    },
    {
        "value": 3760,
        "NOCCode": "3233",
        "JobTitle": "operating room technician – nursing"
    },
    {
        "value": 3761,
        "NOCCode": "3233",
        "JobTitle": "R.N.A. (registered nursing assistant)"
    },
    {
        "value": 3762,
        "NOCCode": "3233",
        "JobTitle": "R.P.N. (registered practical nurse)"
    },
    {
        "value": 3763,
        "NOCCode": "3233",
        "JobTitle": "registered nursing assistant (R.N.A.)"
    },
    {
        "value": 3764,
        "NOCCode": "3233",
        "JobTitle": "registered practical nurse (R.P.N.)"
    },
    {
        "value": 3765,
        "NOCCode": "3233",
        "JobTitle": "surgical assistant, nursing"
    },
    {
        "value": 3766,
        "NOCCode": "3233",
        "JobTitle": "surgical nursing assistant"
    },
    {
        "value": 3767,
        "NOCCode": "3233",
        "JobTitle": "surgical technician – nursing"
    },
    {
        "value": 3768,
        "NOCCode": "3233",
        "JobTitle": "technician, operating room – nursing"
    },
    {
        "value": 3769,
        "NOCCode": "3233",
        "JobTitle": "technician, surgical – nursing"
    },
    {
        "value": 3770,
        "NOCCode": "3234",
        "JobTitle": "advanced care paramedic"
    },
    {
        "value": 3771,
        "NOCCode": "3234",
        "JobTitle": "advanced emergency medical assistant"
    },
    {
        "value": 3772,
        "NOCCode": "3234",
        "JobTitle": "advanced life support attendant"
    },
    {
        "value": 3773,
        "NOCCode": "3234",
        "JobTitle": "advanced life support co-ordinator"
    },
    {
        "value": 3774,
        "NOCCode": "3234",
        "JobTitle": "advanced life support paramedic"
    },
    {
        "value": 3775,
        "NOCCode": "3234",
        "JobTitle": "ambulance attendant"
    },
    {
        "value": 3776,
        "NOCCode": "3234",
        "JobTitle": "ambulance attendant trainee"
    },
    {
        "value": 3777,
        "NOCCode": "3234",
        "JobTitle": "ambulance attendants supervisor"
    },
    {
        "value": 3778,
        "NOCCode": "3234",
        "JobTitle": "ambulance driver"
    },
    {
        "value": 3779,
        "NOCCode": "3234",
        "JobTitle": "ambulance driver-attendant"
    },
    {
        "value": 3780,
        "NOCCode": "3234",
        "JobTitle": "ambulance services field supervisor"
    },
    {
        "value": 3781,
        "NOCCode": "3234",
        "JobTitle": "ambulance services unit chief"
    },
    {
        "value": 3782,
        "NOCCode": "3234",
        "JobTitle": "ambulance technician"
    },
    {
        "value": 3783,
        "NOCCode": "3234",
        "JobTitle": "assistant paramedic"
    },
    {
        "value": 3784,
        "NOCCode": "3234",
        "JobTitle": "attendant, advanced life support"
    },
    {
        "value": 3785,
        "NOCCode": "3234",
        "JobTitle": "attendant, ambulance"
    },
    {
        "value": 3786,
        "NOCCode": "3234",
        "JobTitle": "attendant, infant transport"
    },
    {
        "value": 3787,
        "NOCCode": "3234",
        "JobTitle": "chief ambulance attendant"
    },
    {
        "value": 3788,
        "NOCCode": "3234",
        "JobTitle": "critical care paramedic"
    },
    {
        "value": 3789,
        "NOCCode": "3234",
        "JobTitle": "drilling rig medic"
    },
    {
        "value": 3790,
        "NOCCode": "3234",
        "JobTitle": "drilling rig medical technician"
    },
    {
        "value": 3791,
        "NOCCode": "3234",
        "JobTitle": "drilling rig medical warden"
    },
    {
        "value": 3792,
        "NOCCode": "3234",
        "JobTitle": "drilling rig medical worker"
    },
    {
        "value": 3793,
        "NOCCode": "3234",
        "JobTitle": "driver, ambulance"
    },
    {
        "value": 3794,
        "NOCCode": "3234",
        "JobTitle": "EMA (emergency medical attendant)"
    },
    {
        "value": 3795,
        "NOCCode": "3234",
        "JobTitle": "emergency medical care technician, newborns"
    },
    {
        "value": 3796,
        "NOCCode": "3234",
        "JobTitle": "emergency medical technician – paramedic/advanced care paramedic (EMT – P/ACP)"
    },
    {
        "value": 3797,
        "NOCCode": "3234",
        "JobTitle": "emergency medical technician – primary care paramedic (EMT – PCP)"
    },
    {
        "value": 3798,
        "NOCCode": "3234",
        "JobTitle": "emergency medical technician (EMT)"
    },
    {
        "value": 3799,
        "NOCCode": "3234",
        "JobTitle": "emergency medical technician, paramedic"
    },
    {
        "value": 3800,
        "NOCCode": "3234",
        "JobTitle": "emergency medical technologist – paramedic (EMT-P)"
    },
    {
        "value": 3801,
        "NOCCode": "3234",
        "JobTitle": "emergency paramedic"
    },
    {
        "value": 3802,
        "NOCCode": "3234",
        "JobTitle": "EMT (emergency medical technician)"
    },
    {
        "value": 3803,
        "NOCCode": "3234",
        "JobTitle": "EMT-P (emergency medical technologist – paramedic)"
    },
    {
        "value": 3804,
        "NOCCode": "3234",
        "JobTitle": "field supervisor, ambulance services"
    },
    {
        "value": 3805,
        "NOCCode": "3234",
        "JobTitle": "infant transport attendant"
    },
    {
        "value": 3806,
        "NOCCode": "3234",
        "JobTitle": "intermediate care paramedic"
    },
    {
        "value": 3807,
        "NOCCode": "3234",
        "JobTitle": "medic, drilling rig"
    },
    {
        "value": 3808,
        "NOCCode": "3234",
        "JobTitle": "medical assistant (paramedic)"
    },
    {
        "value": 3809,
        "NOCCode": "3234",
        "JobTitle": "medical care technician, newborns"
    },
    {
        "value": 3810,
        "NOCCode": "3234",
        "JobTitle": "medical technician"
    },
    {
        "value": 3811,
        "NOCCode": "3234",
        "JobTitle": "medical technician, drilling rig"
    },
    {
        "value": 3812,
        "NOCCode": "3234",
        "JobTitle": "medical warden, drilling rig"
    },
    {
        "value": 3813,
        "NOCCode": "3234",
        "JobTitle": "newborn emergency medical care technician"
    },
    {
        "value": 3814,
        "NOCCode": "3234",
        "JobTitle": "newborn medical care technician"
    },
    {
        "value": 3815,
        "NOCCode": "3234",
        "JobTitle": "paramedic"
    },
    {
        "value": 3816,
        "NOCCode": "3234",
        "JobTitle": "paramedic worker"
    },
    {
        "value": 3817,
        "NOCCode": "3234",
        "JobTitle": "primary care paramedic"
    },
    {
        "value": 3818,
        "NOCCode": "3234",
        "JobTitle": "registered emergency paramedic"
    },
    {
        "value": 3819,
        "NOCCode": "3234",
        "JobTitle": "supervisor ambulance attendants"
    },
    {
        "value": 3820,
        "NOCCode": "3234",
        "JobTitle": "supervisor, ambulance services"
    },
    {
        "value": 3821,
        "NOCCode": "3234",
        "JobTitle": "technician, ambulance"
    },
    {
        "value": 3822,
        "NOCCode": "3234",
        "JobTitle": "unit chief, ambulance services"
    },
    {
        "value": 3823,
        "NOCCode": "3236",
        "JobTitle": "massage therapist (MT)"
    },
    {
        "value": 3824,
        "NOCCode": "3236",
        "JobTitle": "masso kinesitherapist"
    },
    {
        "value": 3825,
        "NOCCode": "3236",
        "JobTitle": "massotherapist"
    },
    {
        "value": 3826,
        "NOCCode": "3236",
        "JobTitle": "MT (massage therapist)"
    },
    {
        "value": 3827,
        "NOCCode": "3236",
        "JobTitle": "myotherapist"
    },
    {
        "value": 3828,
        "NOCCode": "3236",
        "JobTitle": "orthotherapist"
    },
    {
        "value": 3829,
        "NOCCode": "3236",
        "JobTitle": "registered massage practitioner"
    },
    {
        "value": 3830,
        "NOCCode": "3236",
        "JobTitle": "registered massage therapist (RMT)"
    },
    {
        "value": 3831,
        "NOCCode": "3236",
        "JobTitle": "RMT (registered massage therapist)"
    },
    {
        "value": 3832,
        "NOCCode": "3236",
        "JobTitle": "Thai massage therapist"
    },
    {
        "value": 3833,
        "NOCCode": "3236",
        "JobTitle": "therapist, massage"
    },
    {
        "value": 3834,
        "NOCCode": "3237",
        "JobTitle": "aide, physical therapy"
    },
    {
        "value": 3835,
        "NOCCode": "3237",
        "JobTitle": "aide, speech"
    },
    {
        "value": 3836,
        "NOCCode": "3237",
        "JobTitle": "aide, speech therapy"
    },
    {
        "value": 3837,
        "NOCCode": "3237",
        "JobTitle": "assistant, audiometric"
    },
    {
        "value": 3838,
        "NOCCode": "3237",
        "JobTitle": "assistant, communication disorders"
    },
    {
        "value": 3839,
        "NOCCode": "3237",
        "JobTitle": "assistant, hearing aid"
    },
    {
        "value": 3840,
        "NOCCode": "3237",
        "JobTitle": "assistant, occupational therapist"
    },
    {
        "value": 3841,
        "NOCCode": "3237",
        "JobTitle": "assistant, occupational therapy"
    },
    {
        "value": 3842,
        "NOCCode": "3237",
        "JobTitle": "assistant, ophthalmic"
    },
    {
        "value": 3843,
        "NOCCode": "3237",
        "JobTitle": "assistant, speech language"
    },
    {
        "value": 3844,
        "NOCCode": "3237",
        "JobTitle": "assistant, speech therapy"
    },
    {
        "value": 3845,
        "NOCCode": "3237",
        "JobTitle": "assistive listening device technician"
    },
    {
        "value": 3846,
        "NOCCode": "3237",
        "JobTitle": "audiological technician"
    },
    {
        "value": 3847,
        "NOCCode": "3237",
        "JobTitle": "audiology technician"
    },
    {
        "value": 3848,
        "NOCCode": "3237",
        "JobTitle": "audiometric aide"
    },
    {
        "value": 3849,
        "NOCCode": "3237",
        "JobTitle": "audiometric assistant"
    },
    {
        "value": 3850,
        "NOCCode": "3237",
        "JobTitle": "audiometric technician"
    },
    {
        "value": 3851,
        "NOCCode": "3237",
        "JobTitle": "audiometric technician – public health"
    },
    {
        "value": 3852,
        "NOCCode": "3237",
        "JobTitle": "audiometrician"
    },
    {
        "value": 3853,
        "NOCCode": "3237",
        "JobTitle": "audiometrist"
    },
    {
        "value": 3854,
        "NOCCode": "3237",
        "JobTitle": "audiometrist aide"
    },
    {
        "value": 3855,
        "NOCCode": "3237",
        "JobTitle": "communication aide – medical"
    },
    {
        "value": 3856,
        "NOCCode": "3237",
        "JobTitle": "communication assistant – medical"
    },
    {
        "value": 3857,
        "NOCCode": "3237",
        "JobTitle": "communication disorders assistant"
    },
    {
        "value": 3858,
        "NOCCode": "3237",
        "JobTitle": "communicative disorders assistant"
    },
    {
        "value": 3859,
        "NOCCode": "3237",
        "JobTitle": "communicative disorders instructor"
    },
    {
        "value": 3860,
        "NOCCode": "3237",
        "JobTitle": "hearing aid assistant"
    },
    {
        "value": 3861,
        "NOCCode": "3237",
        "JobTitle": "hearing aid consultant"
    },
    {
        "value": 3862,
        "NOCCode": "3237",
        "JobTitle": "hearing aid dealer"
    },
    {
        "value": 3863,
        "NOCCode": "3237",
        "JobTitle": "hearing aid dealer and consultant"
    },
    {
        "value": 3864,
        "NOCCode": "3237",
        "JobTitle": "hearing aid practitioner"
    },
    {
        "value": 3865,
        "NOCCode": "3237",
        "JobTitle": "hearing aid specialist"
    },
    {
        "value": 3866,
        "NOCCode": "3237",
        "JobTitle": "hearing aid technician"
    },
    {
        "value": 3867,
        "NOCCode": "3237",
        "JobTitle": "hearing assessment technician"
    },
    {
        "value": 3868,
        "NOCCode": "3237",
        "JobTitle": "hearing disorder technician"
    },
    {
        "value": 3869,
        "NOCCode": "3237",
        "JobTitle": "hearing instrument dispenser"
    },
    {
        "value": 3870,
        "NOCCode": "3237",
        "JobTitle": "hearing instrument practitioner"
    },
    {
        "value": 3871,
        "NOCCode": "3237",
        "JobTitle": "hearing instrument practitioner assistant"
    },
    {
        "value": 3872,
        "NOCCode": "3237",
        "JobTitle": "hearing instrument specialist"
    },
    {
        "value": 3873,
        "NOCCode": "3237",
        "JobTitle": "industrial audiometric technician"
    },
    {
        "value": 3874,
        "NOCCode": "3237",
        "JobTitle": "instructor, communication disorders"
    },
    {
        "value": 3875,
        "NOCCode": "3237",
        "JobTitle": "kinesitherapist"
    },
    {
        "value": 3876,
        "NOCCode": "3237",
        "JobTitle": "licensed hearing instrument specialist"
    },
    {
        "value": 3877,
        "NOCCode": "3237",
        "JobTitle": "manual arts therapist"
    },
    {
        "value": 3878,
        "NOCCode": "3237",
        "JobTitle": "occupational therapist assistant"
    },
    {
        "value": 3879,
        "NOCCode": "3237",
        "JobTitle": "occupational therapy assistant"
    },
    {
        "value": 3880,
        "NOCCode": "3237",
        "JobTitle": "occupational therapy technician"
    },
    {
        "value": 3881,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic assistant"
    },
    {
        "value": 3882,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic medical assistant"
    },
    {
        "value": 3883,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic medical technician"
    },
    {
        "value": 3884,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic medical technologist"
    },
    {
        "value": 3885,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic technician"
    },
    {
        "value": 3886,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic technician (except retail)"
    },
    {
        "value": 3887,
        "NOCCode": "3237",
        "JobTitle": "ophthalmic technologist"
    },
    {
        "value": 3888,
        "NOCCode": "3237",
        "JobTitle": "ophthalmologist assistant"
    },
    {
        "value": 3889,
        "NOCCode": "3237",
        "JobTitle": "physical rehabilitation technician"
    },
    {
        "value": 3890,
        "NOCCode": "3237",
        "JobTitle": "physical therapist assistant (PTA)"
    },
    {
        "value": 3891,
        "NOCCode": "3237",
        "JobTitle": "physical therapy aide"
    },
    {
        "value": 3892,
        "NOCCode": "3237",
        "JobTitle": "physical therapy technician"
    },
    {
        "value": 3893,
        "NOCCode": "3237",
        "JobTitle": "physiotherapist assistant (PTA)"
    },
    {
        "value": 3894,
        "NOCCode": "3237",
        "JobTitle": "physiotherapy aide"
    },
    {
        "value": 3895,
        "NOCCode": "3237",
        "JobTitle": "physiotherapy assistant"
    },
    {
        "value": 3896,
        "NOCCode": "3237",
        "JobTitle": "physiotherapy technician"
    },
    {
        "value": 3897,
        "NOCCode": "3237",
        "JobTitle": "PTA (physical therapist assistant)"
    },
    {
        "value": 3898,
        "NOCCode": "3237",
        "JobTitle": "speech aide"
    },
    {
        "value": 3899,
        "NOCCode": "3237",
        "JobTitle": "speech correctionist – medical"
    },
    {
        "value": 3900,
        "NOCCode": "3237",
        "JobTitle": "speech language assistant"
    },
    {
        "value": 3901,
        "NOCCode": "3237",
        "JobTitle": "speech technician"
    },
    {
        "value": 3902,
        "NOCCode": "3237",
        "JobTitle": "speech therapist aide"
    },
    {
        "value": 3903,
        "NOCCode": "3237",
        "JobTitle": "speech therapist assistant"
    },
    {
        "value": 3904,
        "NOCCode": "3237",
        "JobTitle": "speech therapy aide"
    },
    {
        "value": 3905,
        "NOCCode": "3237",
        "JobTitle": "speech therapy assistant"
    },
    {
        "value": 3906,
        "NOCCode": "3237",
        "JobTitle": "speech-language pathology assistant"
    },
    {
        "value": 3907,
        "NOCCode": "3237",
        "JobTitle": "technician, assistive listening devices"
    },
    {
        "value": 3908,
        "NOCCode": "3237",
        "JobTitle": "technician, audiology"
    },
    {
        "value": 3909,
        "NOCCode": "3237",
        "JobTitle": "technician, audiometric"
    },
    {
        "value": 3910,
        "NOCCode": "3237",
        "JobTitle": "technician, hearing aids"
    },
    {
        "value": 3911,
        "NOCCode": "3237",
        "JobTitle": "technician, hearing assessment"
    },
    {
        "value": 3912,
        "NOCCode": "3237",
        "JobTitle": "technician, hearing disorders"
    },
    {
        "value": 3913,
        "NOCCode": "3237",
        "JobTitle": "technician, occupational therapy"
    },
    {
        "value": 3914,
        "NOCCode": "3237",
        "JobTitle": "technician, ophthalmic (except retail)"
    },
    {
        "value": 3915,
        "NOCCode": "3237",
        "JobTitle": "technician, physical rehabilitation"
    },
    {
        "value": 3916,
        "NOCCode": "3237",
        "JobTitle": "technician, physiotherapy"
    },
    {
        "value": 3917,
        "NOCCode": "3237",
        "JobTitle": "technician, speech"
    },
    {
        "value": 3918,
        "NOCCode": "3237",
        "JobTitle": "technologist, ophthalmic"
    },
    {
        "value": 3919,
        "NOCCode": "3411",
        "JobTitle": "certified dental assistant"
    },
    {
        "value": 3920,
        "NOCCode": "3411",
        "JobTitle": "certified intra-oral dental assistant"
    },
    {
        "value": 3921,
        "NOCCode": "3411",
        "JobTitle": "chair-side assistant – dental"
    },
    {
        "value": 3922,
        "NOCCode": "3411",
        "JobTitle": "dental assistant"
    },
    {
        "value": 3923,
        "NOCCode": "3411",
        "JobTitle": "dental clinical assistant"
    },
    {
        "value": 3924,
        "NOCCode": "3411",
        "JobTitle": "intra-oral dental assistant"
    },
    {
        "value": 3925,
        "NOCCode": "3411",
        "JobTitle": "licensed dental assistant"
    },
    {
        "value": 3926,
        "NOCCode": "3411",
        "JobTitle": "preventive dental assistant"
    },
    {
        "value": 3927,
        "NOCCode": "3411",
        "JobTitle": "registered dental assistant"
    },
    {
        "value": 3928,
        "NOCCode": "3413",
        "JobTitle": "(EMCA) emergency medical care attendant"
    },
    {
        "value": 3929,
        "NOCCode": "3413",
        "JobTitle": "aide, emergency room"
    },
    {
        "value": 3930,
        "NOCCode": "3413",
        "JobTitle": "aide, health care"
    },
    {
        "value": 3931,
        "NOCCode": "3413",
        "JobTitle": "aide, hospital"
    },
    {
        "value": 3932,
        "NOCCode": "3413",
        "JobTitle": "aide, hospital ward"
    },
    {
        "value": 3933,
        "NOCCode": "3413",
        "JobTitle": "aide, medical"
    },
    {
        "value": 3934,
        "NOCCode": "3413",
        "JobTitle": "aide, neuropsychiatric"
    },
    {
        "value": 3935,
        "NOCCode": "3413",
        "JobTitle": "aide, nursery – hospital"
    },
    {
        "value": 3936,
        "NOCCode": "3413",
        "JobTitle": "aide, operating room"
    },
    {
        "value": 3937,
        "NOCCode": "3413",
        "JobTitle": "aide, patient care"
    },
    {
        "value": 3938,
        "NOCCode": "3413",
        "JobTitle": "aide, psychiatric"
    },
    {
        "value": 3939,
        "NOCCode": "3413",
        "JobTitle": "aide, special care – nursing"
    },
    {
        "value": 3940,
        "NOCCode": "3413",
        "JobTitle": "aide, surgical"
    },
    {
        "value": 3941,
        "NOCCode": "3413",
        "JobTitle": "anesthetic attendant – hospital"
    },
    {
        "value": 3942,
        "NOCCode": "3413",
        "JobTitle": "assistant, emergency medical care"
    },
    {
        "value": 3943,
        "NOCCode": "3413",
        "JobTitle": "attendant, hospital"
    },
    {
        "value": 3944,
        "NOCCode": "3413",
        "JobTitle": "attendant, nursing"
    },
    {
        "value": 3945,
        "NOCCode": "3413",
        "JobTitle": "attendant, nursing home"
    },
    {
        "value": 3946,
        "NOCCode": "3413",
        "JobTitle": "attendant, personal care – medical"
    },
    {
        "value": 3947,
        "NOCCode": "3413",
        "JobTitle": "attendant, special care facility"
    },
    {
        "value": 3948,
        "NOCCode": "3413",
        "JobTitle": "continuing care assistant"
    },
    {
        "value": 3949,
        "NOCCode": "3413",
        "JobTitle": "emergency medical care assistant"
    },
    {
        "value": 3950,
        "NOCCode": "3413",
        "JobTitle": "emergency room aide"
    },
    {
        "value": 3951,
        "NOCCode": "3413",
        "JobTitle": "environmental assistant – medical"
    },
    {
        "value": 3952,
        "NOCCode": "3413",
        "JobTitle": "environmental support person – medical"
    },
    {
        "value": 3953,
        "NOCCode": "3413",
        "JobTitle": "first aid attendant – emergency"
    },
    {
        "value": 3954,
        "NOCCode": "3413",
        "JobTitle": "geriatric aide"
    },
    {
        "value": 3955,
        "NOCCode": "3413",
        "JobTitle": "geriatric health care attendant"
    },
    {
        "value": 3956,
        "NOCCode": "3413",
        "JobTitle": "health care aide"
    },
    {
        "value": 3957,
        "NOCCode": "3413",
        "JobTitle": "health care assistant"
    },
    {
        "value": 3958,
        "NOCCode": "3413",
        "JobTitle": "hospice worker"
    },
    {
        "value": 3959,
        "NOCCode": "3413",
        "JobTitle": "hospital aide"
    },
    {
        "value": 3960,
        "NOCCode": "3413",
        "JobTitle": "hospital attendant"
    },
    {
        "value": 3961,
        "NOCCode": "3413",
        "JobTitle": "hospital orderly"
    },
    {
        "value": 3962,
        "NOCCode": "3413",
        "JobTitle": "hospital porter"
    },
    {
        "value": 3963,
        "NOCCode": "3413",
        "JobTitle": "hospital ward aide"
    },
    {
        "value": 3964,
        "NOCCode": "3413",
        "JobTitle": "hostel service aide – medical"
    },
    {
        "value": 3965,
        "NOCCode": "3413",
        "JobTitle": "institutional aide"
    },
    {
        "value": 3966,
        "NOCCode": "3413",
        "JobTitle": "long term care aide"
    },
    {
        "value": 3967,
        "NOCCode": "3413",
        "JobTitle": "medical aide"
    },
    {
        "value": 3968,
        "NOCCode": "3413",
        "JobTitle": "medical orderly"
    },
    {
        "value": 3969,
        "NOCCode": "3413",
        "JobTitle": "neuropsychiatric aide"
    },
    {
        "value": 3970,
        "NOCCode": "3413",
        "JobTitle": "nurse aide"
    },
    {
        "value": 3971,
        "NOCCode": "3413",
        "JobTitle": "nursery aide – hospital"
    },
    {
        "value": 3972,
        "NOCCode": "3413",
        "JobTitle": "nursing assistant (non-registered)"
    },
    {
        "value": 3973,
        "NOCCode": "3413",
        "JobTitle": "nursing attendant"
    },
    {
        "value": 3974,
        "NOCCode": "3413",
        "JobTitle": "nursing home attendant"
    },
    {
        "value": 3975,
        "NOCCode": "3413",
        "JobTitle": "nursing orderly"
    },
    {
        "value": 3976,
        "NOCCode": "3413",
        "JobTitle": "nursing services aide"
    },
    {
        "value": 3977,
        "NOCCode": "3413",
        "JobTitle": "operating room aide"
    },
    {
        "value": 3978,
        "NOCCode": "3413",
        "JobTitle": "orderly"
    },
    {
        "value": 3979,
        "NOCCode": "3413",
        "JobTitle": "patient care aide"
    },
    {
        "value": 3980,
        "NOCCode": "3413",
        "JobTitle": "patient care assistant"
    },
    {
        "value": 3981,
        "NOCCode": "3413",
        "JobTitle": "patient care associate"
    },
    {
        "value": 3982,
        "NOCCode": "3413",
        "JobTitle": "patient service aide"
    },
    {
        "value": 3983,
        "NOCCode": "3413",
        "JobTitle": "patient service assistant"
    },
    {
        "value": 3984,
        "NOCCode": "3413",
        "JobTitle": "patient service associate"
    },
    {
        "value": 3985,
        "NOCCode": "3413",
        "JobTitle": "patient service attendant"
    },
    {
        "value": 3986,
        "NOCCode": "3413",
        "JobTitle": "patient service worker"
    },
    {
        "value": 3987,
        "NOCCode": "3413",
        "JobTitle": "personal care aide – medical"
    },
    {
        "value": 3988,
        "NOCCode": "3413",
        "JobTitle": "personal care attendant – medical"
    },
    {
        "value": 3989,
        "NOCCode": "3413",
        "JobTitle": "personal care provider – medical"
    },
    {
        "value": 3990,
        "NOCCode": "3413",
        "JobTitle": "personal support aide – medical"
    },
    {
        "value": 3991,
        "NOCCode": "3413",
        "JobTitle": "psychiatric aide"
    },
    {
        "value": 3992,
        "NOCCode": "3413",
        "JobTitle": "registered care aide"
    },
    {
        "value": 3993,
        "NOCCode": "3413",
        "JobTitle": "resident care aide – medical"
    },
    {
        "value": 3994,
        "NOCCode": "3413",
        "JobTitle": "resident companion – medical"
    },
    {
        "value": 3995,
        "NOCCode": "3413",
        "JobTitle": "respite care aide"
    },
    {
        "value": 3996,
        "NOCCode": "3413",
        "JobTitle": "service assistant – medical"
    },
    {
        "value": 3997,
        "NOCCode": "3413",
        "JobTitle": "service associate – medical"
    },
    {
        "value": 3998,
        "NOCCode": "3413",
        "JobTitle": "service attendant – medical"
    },
    {
        "value": 3999,
        "NOCCode": "3413",
        "JobTitle": "special care aide – nursing"
    },
    {
        "value": 4000,
        "NOCCode": "3413",
        "JobTitle": "special care facility attendant"
    },
    {
        "value": 4001,
        "NOCCode": "3413",
        "JobTitle": "special care worker – medical"
    },
    {
        "value": 4002,
        "NOCCode": "3413",
        "JobTitle": "support services assistant – medical"
    },
    {
        "value": 4003,
        "NOCCode": "3413",
        "JobTitle": "surgical aide"
    },
    {
        "value": 4004,
        "NOCCode": "3413",
        "JobTitle": "unit aide – medical"
    },
    {
        "value": 4005,
        "NOCCode": "3413",
        "JobTitle": "unit assistant – medical"
    },
    {
        "value": 4006,
        "NOCCode": "3413",
        "JobTitle": "unit support technician – medical"
    },
    {
        "value": 4007,
        "NOCCode": "3413",
        "JobTitle": "ward aide – medical"
    },
    {
        "value": 4008,
        "NOCCode": "3414",
        "JobTitle": "activationist – health support services"
    },
    {
        "value": 4009,
        "NOCCode": "3414",
        "JobTitle": "activities co-ordinator – health support services"
    },
    {
        "value": 4010,
        "NOCCode": "3414",
        "JobTitle": "activity aide – health support services"
    },
    {
        "value": 4011,
        "NOCCode": "3414",
        "JobTitle": "activity leader – seniors"
    },
    {
        "value": 4012,
        "NOCCode": "3414",
        "JobTitle": "activity worker – health support services"
    },
    {
        "value": 4013,
        "NOCCode": "3414",
        "JobTitle": "acupuncture assistant"
    },
    {
        "value": 4014,
        "NOCCode": "3414",
        "JobTitle": "acupuncture attendant"
    },
    {
        "value": 4015,
        "NOCCode": "3414",
        "JobTitle": "adjuvant"
    },
    {
        "value": 4016,
        "NOCCode": "3414",
        "JobTitle": "aide, chiropractic"
    },
    {
        "value": 4017,
        "NOCCode": "3414",
        "JobTitle": "aide, occupational therapy"
    },
    {
        "value": 4018,
        "NOCCode": "3414",
        "JobTitle": "aide, pharmacy"
    },
    {
        "value": 4019,
        "NOCCode": "3414",
        "JobTitle": "aide, supply, processing and distribution – medical"
    },
    {
        "value": 4020,
        "NOCCode": "3414",
        "JobTitle": "antireflection (AR) coating technician, ophthalmic goods"
    },
    {
        "value": 4021,
        "NOCCode": "3414",
        "JobTitle": "AR (antireflection) coating technician, ophthalmic goods"
    },
    {
        "value": 4022,
        "NOCCode": "3414",
        "JobTitle": "assistant, acupuncture"
    },
    {
        "value": 4023,
        "NOCCode": "3414",
        "JobTitle": "assistant, autopsies"
    },
    {
        "value": 4024,
        "NOCCode": "3414",
        "JobTitle": "assistant, blood donor clinic"
    },
    {
        "value": 4025,
        "NOCCode": "3414",
        "JobTitle": "assistant, chiropractic health"
    },
    {
        "value": 4026,
        "NOCCode": "3414",
        "JobTitle": "assistant, clinic – medical"
    },
    {
        "value": 4027,
        "NOCCode": "3414",
        "JobTitle": "assistant, herbal medicine"
    },
    {
        "value": 4028,
        "NOCCode": "3414",
        "JobTitle": "assistant, orthopedic"
    },
    {
        "value": 4029,
        "NOCCode": "3414",
        "JobTitle": "assistant, phytotherapy"
    },
    {
        "value": 4030,
        "NOCCode": "3414",
        "JobTitle": "attendant, blood sampling"
    },
    {
        "value": 4031,
        "NOCCode": "3414",
        "JobTitle": "attendant, fracture room"
    },
    {
        "value": 4032,
        "NOCCode": "3414",
        "JobTitle": "attendant, morgue"
    },
    {
        "value": 4033,
        "NOCCode": "3414",
        "JobTitle": "attendant, physiotherapy"
    },
    {
        "value": 4034,
        "NOCCode": "3414",
        "JobTitle": "attendant, plaster room"
    },
    {
        "value": 4035,
        "NOCCode": "3414",
        "JobTitle": "attendant, post-mortem"
    },
    {
        "value": 4036,
        "NOCCode": "3414",
        "JobTitle": "autopsy assistant"
    },
    {
        "value": 4037,
        "NOCCode": "3414",
        "JobTitle": "autopsy attendant"
    },
    {
        "value": 4038,
        "NOCCode": "3414",
        "JobTitle": "autopsy technician"
    },
    {
        "value": 4039,
        "NOCCode": "3414",
        "JobTitle": "bench worker, ophthalmic goods"
    },
    {
        "value": 4040,
        "NOCCode": "3414",
        "JobTitle": "blood donor clinic assistant"
    },
    {
        "value": 4041,
        "NOCCode": "3414",
        "JobTitle": "blood donor clinic attendant"
    },
    {
        "value": 4042,
        "NOCCode": "3414",
        "JobTitle": "blood donor clinic helper"
    },
    {
        "value": 4043,
        "NOCCode": "3414",
        "JobTitle": "blood sampling attendant"
    },
    {
        "value": 4044,
        "NOCCode": "3414",
        "JobTitle": "cast room technician"
    },
    {
        "value": 4045,
        "NOCCode": "3414",
        "JobTitle": "central service room worker – hospital"
    },
    {
        "value": 4046,
        "NOCCode": "3414",
        "JobTitle": "central supply aide"
    },
    {
        "value": 4047,
        "NOCCode": "3414",
        "JobTitle": "central supply aide – medical"
    },
    {
        "value": 4048,
        "NOCCode": "3414",
        "JobTitle": "central supply room supervisor – hospital"
    },
    {
        "value": 4049,
        "NOCCode": "3414",
        "JobTitle": "central supply room technician – hospital"
    },
    {
        "value": 4050,
        "NOCCode": "3414",
        "JobTitle": "chiropractic aide"
    },
    {
        "value": 4051,
        "NOCCode": "3414",
        "JobTitle": "chiropractic assistant"
    },
    {
        "value": 4052,
        "NOCCode": "3414",
        "JobTitle": "chiropractic health assistant"
    },
    {
        "value": 4053,
        "NOCCode": "3414",
        "JobTitle": "chiropractic office assistant"
    },
    {
        "value": 4054,
        "NOCCode": "3414",
        "JobTitle": "chiropractor aide"
    },
    {
        "value": 4055,
        "NOCCode": "3414",
        "JobTitle": "clinic assistant – medical"
    },
    {
        "value": 4056,
        "NOCCode": "3414",
        "JobTitle": "clinical laboratory helper"
    },
    {
        "value": 4057,
        "NOCCode": "3414",
        "JobTitle": "dispensary assistant"
    },
    {
        "value": 4058,
        "NOCCode": "3414",
        "JobTitle": "dispensing assistant, drugstore"
    },
    {
        "value": 4059,
        "NOCCode": "3414",
        "JobTitle": "drugstore dispensing assistant"
    },
    {
        "value": 4060,
        "NOCCode": "3414",
        "JobTitle": "drugstore side room attendant"
    },
    {
        "value": 4061,
        "NOCCode": "3414",
        "JobTitle": "edge grinder, ophthalmic lenses"
    },
    {
        "value": 4062,
        "NOCCode": "3414",
        "JobTitle": "emergency room attendant"
    },
    {
        "value": 4063,
        "NOCCode": "3414",
        "JobTitle": "emergency-entry attendant – hospital"
    },
    {
        "value": 4064,
        "NOCCode": "3414",
        "JobTitle": "fracture room attendant"
    },
    {
        "value": 4065,
        "NOCCode": "3414",
        "JobTitle": "grinder, ophthalmic lenses"
    },
    {
        "value": 4066,
        "NOCCode": "3414",
        "JobTitle": "health aide"
    },
    {
        "value": 4067,
        "NOCCode": "3414",
        "JobTitle": "helper, blood donor clinic"
    },
    {
        "value": 4068,
        "NOCCode": "3414",
        "JobTitle": "helper, occupational therapy"
    },
    {
        "value": 4069,
        "NOCCode": "3414",
        "JobTitle": "helper, physiotherapy"
    },
    {
        "value": 4070,
        "NOCCode": "3414",
        "JobTitle": "herbal medicine assistant"
    },
    {
        "value": 4071,
        "NOCCode": "3414",
        "JobTitle": "inspector, optical goods"
    },
    {
        "value": 4072,
        "NOCCode": "3414",
        "JobTitle": "inspector, optical lenses"
    },
    {
        "value": 4073,
        "NOCCode": "3414",
        "JobTitle": "instrument sterilizer – medical"
    },
    {
        "value": 4074,
        "NOCCode": "3414",
        "JobTitle": "laboratory technician, ophthalmic – retail"
    },
    {
        "value": 4075,
        "NOCCode": "3414",
        "JobTitle": "laboratory technician, optical – retail"
    },
    {
        "value": 4076,
        "NOCCode": "3414",
        "JobTitle": "lens coating technician, ophthalmic goods"
    },
    {
        "value": 4077,
        "NOCCode": "3414",
        "JobTitle": "lens cutter, ophthalmic goods"
    },
    {
        "value": 4078,
        "NOCCode": "3414",
        "JobTitle": "lens grinder, ophthalmic"
    },
    {
        "value": 4079,
        "NOCCode": "3414",
        "JobTitle": "lens grinder-polisher setter"
    },
    {
        "value": 4080,
        "NOCCode": "3414",
        "JobTitle": "lens marker – health support services"
    },
    {
        "value": 4081,
        "NOCCode": "3414",
        "JobTitle": "lens marker – ophthalmic"
    },
    {
        "value": 4082,
        "NOCCode": "3414",
        "JobTitle": "lens picker, optical and ophthalmic goods"
    },
    {
        "value": 4083,
        "NOCCode": "3414",
        "JobTitle": "lens tinter, ophthalmic goods"
    },
    {
        "value": 4084,
        "NOCCode": "3414",
        "JobTitle": "marker, ophthalmic lenses"
    },
    {
        "value": 4085,
        "NOCCode": "3414",
        "JobTitle": "mechanic, optical lab – retail"
    },
    {
        "value": 4086,
        "NOCCode": "3414",
        "JobTitle": "medical clinic assistant"
    },
    {
        "value": 4087,
        "NOCCode": "3414",
        "JobTitle": "medical instrument sterilizer"
    },
    {
        "value": 4088,
        "NOCCode": "3414",
        "JobTitle": "medical material handler"
    },
    {
        "value": 4089,
        "NOCCode": "3414",
        "JobTitle": "morgue attendant"
    },
    {
        "value": 4090,
        "NOCCode": "3414",
        "JobTitle": "morgue technician"
    },
    {
        "value": 4091,
        "NOCCode": "3414",
        "JobTitle": "occupational therapy aide"
    },
    {
        "value": 4092,
        "NOCCode": "3414",
        "JobTitle": "occupational therapy helper"
    },
    {
        "value": 4093,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic goods bench worker"
    },
    {
        "value": 4094,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lab technician – retail"
    },
    {
        "value": 4095,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic laboratory technician – retail"
    },
    {
        "value": 4096,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens bench worker"
    },
    {
        "value": 4097,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens cutter"
    },
    {
        "value": 4098,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens edge grinder"
    },
    {
        "value": 4099,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens grinder and polisher"
    },
    {
        "value": 4100,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens grinder-polisher setter"
    },
    {
        "value": 4101,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens inspector"
    },
    {
        "value": 4102,
        "NOCCode": "3414",
        "JobTitle": "ophthalmic lens marker"
    },
    {
        "value": 4103,
        "NOCCode": "3414",
        "JobTitle": "optical goods inspector"
    },
    {
        "value": 4104,
        "NOCCode": "3414",
        "JobTitle": "optical lab mechanic – retail"
    },
    {
        "value": 4105,
        "NOCCode": "3414",
        "JobTitle": "optical laboratory assistant"
    },
    {
        "value": 4106,
        "NOCCode": "3414",
        "JobTitle": "optical laboratory technician"
    },
    {
        "value": 4107,
        "NOCCode": "3414",
        "JobTitle": "optical laboratory technician – retail"
    },
    {
        "value": 4108,
        "NOCCode": "3414",
        "JobTitle": "optical lens grinder and polisher"
    },
    {
        "value": 4109,
        "NOCCode": "3414",
        "JobTitle": "optical lens inspector"
    },
    {
        "value": 4110,
        "NOCCode": "3414",
        "JobTitle": "optical mechanic"
    },
    {
        "value": 4111,
        "NOCCode": "3414",
        "JobTitle": "optical technician"
    },
    {
        "value": 4112,
        "NOCCode": "3414",
        "JobTitle": "optometric technician"
    },
    {
        "value": 4113,
        "NOCCode": "3414",
        "JobTitle": "optometrist assistant"
    },
    {
        "value": 4114,
        "NOCCode": "3414",
        "JobTitle": "orthopedic assistant"
    },
    {
        "value": 4115,
        "NOCCode": "3414",
        "JobTitle": "orthopedic physician assistant"
    },
    {
        "value": 4116,
        "NOCCode": "3414",
        "JobTitle": "orthopedic supervisor"
    },
    {
        "value": 4117,
        "NOCCode": "3414",
        "JobTitle": "orthopedic technician"
    },
    {
        "value": 4118,
        "NOCCode": "3414",
        "JobTitle": "orthopedic technologist"
    },
    {
        "value": 4119,
        "NOCCode": "3414",
        "JobTitle": "orthopedist aide"
    },
    {
        "value": 4120,
        "NOCCode": "3414",
        "JobTitle": "pharmacy aide"
    },
    {
        "value": 4121,
        "NOCCode": "3414",
        "JobTitle": "pharmacy side room attendant"
    },
    {
        "value": 4122,
        "NOCCode": "3414",
        "JobTitle": "pharmacy supply assistant"
    },
    {
        "value": 4123,
        "NOCCode": "3414",
        "JobTitle": "physical therapy attendant"
    },
    {
        "value": 4124,
        "NOCCode": "3414",
        "JobTitle": "physiotherapy attendant"
    },
    {
        "value": 4125,
        "NOCCode": "3414",
        "JobTitle": "physiotherapy helper"
    },
    {
        "value": 4126,
        "NOCCode": "3414",
        "JobTitle": "phytotherapy assistant"
    },
    {
        "value": 4127,
        "NOCCode": "3414",
        "JobTitle": "plaster room aide"
    },
    {
        "value": 4128,
        "NOCCode": "3414",
        "JobTitle": "plaster room attendant"
    },
    {
        "value": 4129,
        "NOCCode": "3414",
        "JobTitle": "polishing pad mounter"
    },
    {
        "value": 4130,
        "NOCCode": "3414",
        "JobTitle": "post-mortem attendant"
    },
    {
        "value": 4131,
        "NOCCode": "3414",
        "JobTitle": "radiology aide"
    },
    {
        "value": 4132,
        "NOCCode": "3414",
        "JobTitle": "recreation aide – health services"
    },
    {
        "value": 4133,
        "NOCCode": "3414",
        "JobTitle": "recreation therapist aide"
    },
    {
        "value": 4134,
        "NOCCode": "3414",
        "JobTitle": "recreation therapy assistant"
    },
    {
        "value": 4135,
        "NOCCode": "3414",
        "JobTitle": "recreational therapy aide"
    },
    {
        "value": 4136,
        "NOCCode": "3414",
        "JobTitle": "registered central service technician – medical"
    },
    {
        "value": 4137,
        "NOCCode": "3414",
        "JobTitle": "registered orthopedic technologist"
    },
    {
        "value": 4138,
        "NOCCode": "3414",
        "JobTitle": "rehabilitation aide"
    },
    {
        "value": 4139,
        "NOCCode": "3414",
        "JobTitle": "rehabilitation assistant"
    },
    {
        "value": 4140,
        "NOCCode": "3414",
        "JobTitle": "senior adjuvant"
    },
    {
        "value": 4141,
        "NOCCode": "3414",
        "JobTitle": "sterile processing technician"
    },
    {
        "value": 4142,
        "NOCCode": "3414",
        "JobTitle": "sterile processing worker"
    },
    {
        "value": 4143,
        "NOCCode": "3414",
        "JobTitle": "sterile supply room attendant"
    },
    {
        "value": 4144,
        "NOCCode": "3414",
        "JobTitle": "sterilization attendant"
    },
    {
        "value": 4145,
        "NOCCode": "3414",
        "JobTitle": "sterilization processing attendant"
    },
    {
        "value": 4146,
        "NOCCode": "3414",
        "JobTitle": "supervisor, central supply room – hospital"
    },
    {
        "value": 4147,
        "NOCCode": "3414",
        "JobTitle": "supply, processing and distribution aide – medical"
    },
    {
        "value": 4148,
        "NOCCode": "3414",
        "JobTitle": "surgical assistant"
    },
    {
        "value": 4149,
        "NOCCode": "3414",
        "JobTitle": "surgical assistant – non-nursing"
    },
    {
        "value": 4150,
        "NOCCode": "3414",
        "JobTitle": "surgical technician – non-nursing"
    },
    {
        "value": 4151,
        "NOCCode": "3414",
        "JobTitle": "technician, autopsy"
    },
    {
        "value": 4152,
        "NOCCode": "3414",
        "JobTitle": "technician, cast room"
    },
    {
        "value": 4153,
        "NOCCode": "3414",
        "JobTitle": "technician, central supply room – hospital"
    },
    {
        "value": 4154,
        "NOCCode": "3414",
        "JobTitle": "technician, morgue"
    },
    {
        "value": 4155,
        "NOCCode": "3414",
        "JobTitle": "technician, ophthalmic goods antireflection (AR) coating"
    },
    {
        "value": 4156,
        "NOCCode": "3414",
        "JobTitle": "technician, ophthalmic lab – retail"
    },
    {
        "value": 4157,
        "NOCCode": "3414",
        "JobTitle": "technician, optical laboratory"
    },
    {
        "value": 4158,
        "NOCCode": "3414",
        "JobTitle": "technician, optical laboratory – retail"
    },
    {
        "value": 4159,
        "NOCCode": "3414",
        "JobTitle": "technician, orthopedic"
    },
    {
        "value": 4160,
        "NOCCode": "3414",
        "JobTitle": "technologist, orthopedic"
    },
    {
        "value": 4161,
        "NOCCode": "3414",
        "JobTitle": "therapist assistant – medical"
    },
    {
        "value": 4162,
        "NOCCode": "3414",
        "JobTitle": "therapy aide – medical"
    },
    {
        "value": 4163,
        "NOCCode": "3414",
        "JobTitle": "therapy assistant – medical"
    },
    {
        "value": 4164,
        "NOCCode": "4011",
        "JobTitle": "agriculture professor – university"
    },
    {
        "value": 4165,
        "NOCCode": "4011",
        "JobTitle": "anatomy professor – university"
    },
    {
        "value": 4166,
        "NOCCode": "4011",
        "JobTitle": "anthropology professor – university"
    },
    {
        "value": 4167,
        "NOCCode": "4011",
        "JobTitle": "archaeology professor – university"
    },
    {
        "value": 4168,
        "NOCCode": "4011",
        "JobTitle": "Asian studies professor – university"
    },
    {
        "value": 4169,
        "NOCCode": "4011",
        "JobTitle": "assistant professor – university"
    },
    {
        "value": 4170,
        "NOCCode": "4011",
        "JobTitle": "assistant professor, botany – university"
    },
    {
        "value": 4171,
        "NOCCode": "4011",
        "JobTitle": "assistant professor, history – university"
    },
    {
        "value": 4172,
        "NOCCode": "4011",
        "JobTitle": "associate professor – university"
    },
    {
        "value": 4173,
        "NOCCode": "4011",
        "JobTitle": "associate professor, linguistics"
    },
    {
        "value": 4174,
        "NOCCode": "4011",
        "JobTitle": "astronomy professor – university"
    },
    {
        "value": 4175,
        "NOCCode": "4011",
        "JobTitle": "bacteriology professor – university"
    },
    {
        "value": 4176,
        "NOCCode": "4011",
        "JobTitle": "biochemistry professor – university"
    },
    {
        "value": 4177,
        "NOCCode": "4011",
        "JobTitle": "biology professor – university"
    },
    {
        "value": 4178,
        "NOCCode": "4011",
        "JobTitle": "biomechanics professor – university"
    },
    {
        "value": 4179,
        "NOCCode": "4011",
        "JobTitle": "botany professor – university"
    },
    {
        "value": 4180,
        "NOCCode": "4011",
        "JobTitle": "business administration professor – university"
    },
    {
        "value": 4181,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, food sciences department – university"
    },
    {
        "value": 4182,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, geography department – university"
    },
    {
        "value": 4183,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, linguistics department – university"
    },
    {
        "value": 4184,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, physics department – university"
    },
    {
        "value": 4185,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, theology department – university"
    },
    {
        "value": 4186,
        "NOCCode": "4011",
        "JobTitle": "chairman/woman, visual arts department – university"
    },
    {
        "value": 4187,
        "NOCCode": "4011",
        "JobTitle": "chairperson, food sciences department – university"
    },
    {
        "value": 4188,
        "NOCCode": "4011",
        "JobTitle": "chairperson, geography department – university"
    },
    {
        "value": 4189,
        "NOCCode": "4011",
        "JobTitle": "chairperson, linguistics department – university"
    },
    {
        "value": 4190,
        "NOCCode": "4011",
        "JobTitle": "chairperson, physics department – university"
    },
    {
        "value": 4191,
        "NOCCode": "4011",
        "JobTitle": "chairperson, theology department – university"
    },
    {
        "value": 4192,
        "NOCCode": "4011",
        "JobTitle": "chairperson, visual arts department – university"
    },
    {
        "value": 4193,
        "NOCCode": "4011",
        "JobTitle": "chemical engineering professor – university"
    },
    {
        "value": 4194,
        "NOCCode": "4011",
        "JobTitle": "chemistry professor – university"
    },
    {
        "value": 4195,
        "NOCCode": "4011",
        "JobTitle": "civil engineering professor – university"
    },
    {
        "value": 4196,
        "NOCCode": "4011",
        "JobTitle": "classics professor – university"
    },
    {
        "value": 4197,
        "NOCCode": "4011",
        "JobTitle": "computer science professor – university"
    },
    {
        "value": 4198,
        "NOCCode": "4011",
        "JobTitle": "criminology professor – university"
    },
    {
        "value": 4199,
        "NOCCode": "4011",
        "JobTitle": "dentistry professor – university"
    },
    {
        "value": 4200,
        "NOCCode": "4011",
        "JobTitle": "department chairman/woman – university"
    },
    {
        "value": 4201,
        "NOCCode": "4011",
        "JobTitle": "department chairperson – university"
    },
    {
        "value": 4202,
        "NOCCode": "4011",
        "JobTitle": "department head – university"
    },
    {
        "value": 4203,
        "NOCCode": "4011",
        "JobTitle": "department head, food sciences – university"
    },
    {
        "value": 4204,
        "NOCCode": "4011",
        "JobTitle": "department head, geography – university"
    },
    {
        "value": 4205,
        "NOCCode": "4011",
        "JobTitle": "department head, linguistics – university"
    },
    {
        "value": 4206,
        "NOCCode": "4011",
        "JobTitle": "department head, physics – university"
    },
    {
        "value": 4207,
        "NOCCode": "4011",
        "JobTitle": "department head, theology – university"
    },
    {
        "value": 4208,
        "NOCCode": "4011",
        "JobTitle": "department head, visual arts – university"
    },
    {
        "value": 4209,
        "NOCCode": "4011",
        "JobTitle": "drama professor – university"
    },
    {
        "value": 4210,
        "NOCCode": "4011",
        "JobTitle": "economics professor – university"
    },
    {
        "value": 4211,
        "NOCCode": "4011",
        "JobTitle": "education professor – university"
    },
    {
        "value": 4212,
        "NOCCode": "4011",
        "JobTitle": "electrical engineering professor – university"
    },
    {
        "value": 4213,
        "NOCCode": "4011",
        "JobTitle": "engineering and architecture professor – university"
    },
    {
        "value": 4214,
        "NOCCode": "4011",
        "JobTitle": "engineering professor – university"
    },
    {
        "value": 4215,
        "NOCCode": "4011",
        "JobTitle": "English professor – university"
    },
    {
        "value": 4216,
        "NOCCode": "4011",
        "JobTitle": "fine arts professor – university"
    },
    {
        "value": 4217,
        "NOCCode": "4011",
        "JobTitle": "food science professor – university"
    },
    {
        "value": 4218,
        "NOCCode": "4011",
        "JobTitle": "forestry professor – university"
    },
    {
        "value": 4219,
        "NOCCode": "4011",
        "JobTitle": "French language professor – university"
    },
    {
        "value": 4220,
        "NOCCode": "4011",
        "JobTitle": "full professor – university"
    },
    {
        "value": 4221,
        "NOCCode": "4011",
        "JobTitle": "geography professor – university"
    },
    {
        "value": 4222,
        "NOCCode": "4011",
        "JobTitle": "geology professor – university"
    },
    {
        "value": 4223,
        "NOCCode": "4011",
        "JobTitle": "geophysics professor – university"
    },
    {
        "value": 4224,
        "NOCCode": "4011",
        "JobTitle": "history professor – university"
    },
    {
        "value": 4225,
        "NOCCode": "4011",
        "JobTitle": "humanities professor – university"
    },
    {
        "value": 4226,
        "NOCCode": "4011",
        "JobTitle": "hygiene professor – university"
    },
    {
        "value": 4227,
        "NOCCode": "4011",
        "JobTitle": "industrial engineering professor – university"
    },
    {
        "value": 4228,
        "NOCCode": "4011",
        "JobTitle": "journalism professor – university"
    },
    {
        "value": 4229,
        "NOCCode": "4011",
        "JobTitle": "laboratory technology professor – university"
    },
    {
        "value": 4230,
        "NOCCode": "4011",
        "JobTitle": "language professor – university"
    },
    {
        "value": 4231,
        "NOCCode": "4011",
        "JobTitle": "law professor – university"
    },
    {
        "value": 4232,
        "NOCCode": "4011",
        "JobTitle": "lecturer – university"
    },
    {
        "value": 4233,
        "NOCCode": "4011",
        "JobTitle": "lecturer, university"
    },
    {
        "value": 4234,
        "NOCCode": "4011",
        "JobTitle": "library science professor – university"
    },
    {
        "value": 4235,
        "NOCCode": "4011",
        "JobTitle": "life sciences professor – university"
    },
    {
        "value": 4236,
        "NOCCode": "4011",
        "JobTitle": "linguistics professor – university"
    },
    {
        "value": 4237,
        "NOCCode": "4011",
        "JobTitle": "literature professor – university"
    },
    {
        "value": 4238,
        "NOCCode": "4011",
        "JobTitle": "mathematics professor – university"
    },
    {
        "value": 4239,
        "NOCCode": "4011",
        "JobTitle": "mechanical engineering professor – university"
    },
    {
        "value": 4240,
        "NOCCode": "4011",
        "JobTitle": "medical sciences professor – university"
    },
    {
        "value": 4241,
        "NOCCode": "4011",
        "JobTitle": "medicine professor – university"
    },
    {
        "value": 4242,
        "NOCCode": "4011",
        "JobTitle": "metallurgical engineering professor – university"
    },
    {
        "value": 4243,
        "NOCCode": "4011",
        "JobTitle": "metallurgy professor – university"
    },
    {
        "value": 4244,
        "NOCCode": "4011",
        "JobTitle": "meteorology professor – university"
    },
    {
        "value": 4245,
        "NOCCode": "4011",
        "JobTitle": "music professor – university"
    },
    {
        "value": 4246,
        "NOCCode": "4011",
        "JobTitle": "natural sciences professor – university"
    },
    {
        "value": 4247,
        "NOCCode": "4011",
        "JobTitle": "nursing professor – university"
    },
    {
        "value": 4248,
        "NOCCode": "4011",
        "JobTitle": "oceanography professor – university"
    },
    {
        "value": 4249,
        "NOCCode": "4011",
        "JobTitle": "pharmacy professor – university"
    },
    {
        "value": 4250,
        "NOCCode": "4011",
        "JobTitle": "philosophy professor – university"
    },
    {
        "value": 4251,
        "NOCCode": "4011",
        "JobTitle": "physical sciences professor – university"
    },
    {
        "value": 4252,
        "NOCCode": "4011",
        "JobTitle": "physics professor – university"
    },
    {
        "value": 4253,
        "NOCCode": "4011",
        "JobTitle": "physiology professor – university"
    },
    {
        "value": 4254,
        "NOCCode": "4011",
        "JobTitle": "political science professor – university"
    },
    {
        "value": 4255,
        "NOCCode": "4011",
        "JobTitle": "professor emeritus – university"
    },
    {
        "value": 4256,
        "NOCCode": "4011",
        "JobTitle": "professor of computer science – university"
    },
    {
        "value": 4257,
        "NOCCode": "4011",
        "JobTitle": "professor of medicine – university"
    },
    {
        "value": 4258,
        "NOCCode": "4011",
        "JobTitle": "professor, university"
    },
    {
        "value": 4259,
        "NOCCode": "4011",
        "JobTitle": "psychiatry professor – university"
    },
    {
        "value": 4260,
        "NOCCode": "4011",
        "JobTitle": "psychology professor – university"
    },
    {
        "value": 4261,
        "NOCCode": "4011",
        "JobTitle": "public administration professor – university"
    },
    {
        "value": 4262,
        "NOCCode": "4011",
        "JobTitle": "public health professor – university"
    },
    {
        "value": 4263,
        "NOCCode": "4011",
        "JobTitle": "recreology professor – university"
    },
    {
        "value": 4264,
        "NOCCode": "4011",
        "JobTitle": "religion professor – university"
    },
    {
        "value": 4265,
        "NOCCode": "4011",
        "JobTitle": "research professor – university"
    },
    {
        "value": 4266,
        "NOCCode": "4011",
        "JobTitle": "sculpture professor – university"
    },
    {
        "value": 4267,
        "NOCCode": "4011",
        "JobTitle": "social sciences professor – university"
    },
    {
        "value": 4268,
        "NOCCode": "4011",
        "JobTitle": "social work professor – university"
    },
    {
        "value": 4269,
        "NOCCode": "4011",
        "JobTitle": "sociology professor – university"
    },
    {
        "value": 4270,
        "NOCCode": "4011",
        "JobTitle": "surgery professor – university"
    },
    {
        "value": 4271,
        "NOCCode": "4011",
        "JobTitle": "university department head"
    },
    {
        "value": 4272,
        "NOCCode": "4011",
        "JobTitle": "university instructor, engineering"
    },
    {
        "value": 4273,
        "NOCCode": "4011",
        "JobTitle": "university lecturer"
    },
    {
        "value": 4274,
        "NOCCode": "4011",
        "JobTitle": "university professor"
    },
    {
        "value": 4275,
        "NOCCode": "4011",
        "JobTitle": "university professor, engineering"
    },
    {
        "value": 4276,
        "NOCCode": "4011",
        "JobTitle": "university professor, English"
    },
    {
        "value": 4277,
        "NOCCode": "4011",
        "JobTitle": "urban planning professor – university"
    },
    {
        "value": 4278,
        "NOCCode": "4011",
        "JobTitle": "veterinary medicine professor – university"
    },
    {
        "value": 4279,
        "NOCCode": "4011",
        "JobTitle": "visiting professor – university"
    },
    {
        "value": 4280,
        "NOCCode": "4011",
        "JobTitle": "visiting scholar – university"
    },
    {
        "value": 4281,
        "NOCCode": "4011",
        "JobTitle": "zoology professor – university"
    },
    {
        "value": 4282,
        "NOCCode": "4012",
        "JobTitle": "college laboratory assistant"
    },
    {
        "value": 4283,
        "NOCCode": "4012",
        "JobTitle": "college teaching assistant"
    },
    {
        "value": 4284,
        "NOCCode": "4012",
        "JobTitle": "demonstrator, laboratory – post-secondary teaching assistance"
    },
    {
        "value": 4285,
        "NOCCode": "4012",
        "JobTitle": "discussion group leader – post-secondary teaching assistant"
    },
    {
        "value": 4286,
        "NOCCode": "4012",
        "JobTitle": "graduate assistant – university"
    },
    {
        "value": 4287,
        "NOCCode": "4012",
        "JobTitle": "laboratory assistant, college"
    },
    {
        "value": 4288,
        "NOCCode": "4012",
        "JobTitle": "laboratory assistant, university"
    },
    {
        "value": 4289,
        "NOCCode": "4012",
        "JobTitle": "laboratory demonstrator – post-secondary teaching assistance"
    },
    {
        "value": 4290,
        "NOCCode": "4012",
        "JobTitle": "laboratory supervisor – post-secondary teaching assistant"
    },
    {
        "value": 4291,
        "NOCCode": "4012",
        "JobTitle": "marker – post-secondary teaching assistance"
    },
    {
        "value": 4292,
        "NOCCode": "4012",
        "JobTitle": "post-secondary research assistant"
    },
    {
        "value": 4293,
        "NOCCode": "4012",
        "JobTitle": "post-secondary teaching assistant"
    },
    {
        "value": 4294,
        "NOCCode": "4012",
        "JobTitle": "research assistant – post-secondary"
    },
    {
        "value": 4295,
        "NOCCode": "4012",
        "JobTitle": "research assistant, university"
    },
    {
        "value": 4296,
        "NOCCode": "4012",
        "JobTitle": "teaching assistant – post-secondary"
    },
    {
        "value": 4297,
        "NOCCode": "4012",
        "JobTitle": "teaching assistant – university"
    },
    {
        "value": 4298,
        "NOCCode": "4012",
        "JobTitle": "teaching assistant, college"
    },
    {
        "value": 4299,
        "NOCCode": "4012",
        "JobTitle": "teaching assistant, university"
    },
    {
        "value": 4300,
        "NOCCode": "4012",
        "JobTitle": "university laboratory assistant"
    },
    {
        "value": 4301,
        "NOCCode": "4012",
        "JobTitle": "university teaching assistant"
    },
    {
        "value": 4302,
        "NOCCode": "4021",
        "JobTitle": "academic subjects teacher – college level"
    },
    {
        "value": 4303,
        "NOCCode": "4021",
        "JobTitle": "accounting teacher – college level"
    },
    {
        "value": 4304,
        "NOCCode": "4021",
        "JobTitle": "administration teacher"
    },
    {
        "value": 4305,
        "NOCCode": "4021",
        "JobTitle": "advertising art teacher"
    },
    {
        "value": 4306,
        "NOCCode": "4021",
        "JobTitle": "agricultural college teacher"
    },
    {
        "value": 4307,
        "NOCCode": "4021",
        "JobTitle": "agricultural school teacher"
    },
    {
        "value": 4308,
        "NOCCode": "4021",
        "JobTitle": "airline customer-service instructor"
    },
    {
        "value": 4309,
        "NOCCode": "4021",
        "JobTitle": "apparel design teacher"
    },
    {
        "value": 4310,
        "NOCCode": "4021",
        "JobTitle": "appliance servicing teacher – vocational institute"
    },
    {
        "value": 4311,
        "NOCCode": "4021",
        "JobTitle": "applied arts teacher – college level"
    },
    {
        "value": 4312,
        "NOCCode": "4021",
        "JobTitle": "architectural technology instructor"
    },
    {
        "value": 4313,
        "NOCCode": "4021",
        "JobTitle": "automotive repair instructor – community college"
    },
    {
        "value": 4314,
        "NOCCode": "4021",
        "JobTitle": "Bible college teacher"
    },
    {
        "value": 4315,
        "NOCCode": "4021",
        "JobTitle": "Bible school teacher"
    },
    {
        "value": 4316,
        "NOCCode": "4021",
        "JobTitle": "bookkeeping teacher"
    },
    {
        "value": 4317,
        "NOCCode": "4021",
        "JobTitle": "business administration teacher"
    },
    {
        "value": 4318,
        "NOCCode": "4021",
        "JobTitle": "business administration teacher – college level"
    },
    {
        "value": 4319,
        "NOCCode": "4021",
        "JobTitle": "business college instructor"
    },
    {
        "value": 4320,
        "NOCCode": "4021",
        "JobTitle": "business college teacher"
    },
    {
        "value": 4321,
        "NOCCode": "4021",
        "JobTitle": "business law teacher"
    },
    {
        "value": 4322,
        "NOCCode": "4021",
        "JobTitle": "business subjects teacher – vocational institute"
    },
    {
        "value": 4323,
        "NOCCode": "4021",
        "JobTitle": "CEGEP (general and vocational college) teacher"
    },
    {
        "value": 4324,
        "NOCCode": "4021",
        "JobTitle": "CEGEP teacher"
    },
    {
        "value": 4325,
        "NOCCode": "4021",
        "JobTitle": "chemical technology teacher"
    },
    {
        "value": 4326,
        "NOCCode": "4021",
        "JobTitle": "chemistry teacher – agricultural school"
    },
    {
        "value": 4327,
        "NOCCode": "4021",
        "JobTitle": "child-care service teacher"
    },
    {
        "value": 4328,
        "NOCCode": "4021",
        "JobTitle": "child-care service teacher – college level"
    },
    {
        "value": 4329,
        "NOCCode": "4021",
        "JobTitle": "clothing design teacher"
    },
    {
        "value": 4330,
        "NOCCode": "4021",
        "JobTitle": "college instructor"
    },
    {
        "value": 4331,
        "NOCCode": "4021",
        "JobTitle": "college lecturer"
    },
    {
        "value": 4332,
        "NOCCode": "4021",
        "JobTitle": "college of applied arts and technology instructor"
    },
    {
        "value": 4333,
        "NOCCode": "4021",
        "JobTitle": "college of applied arts and technology teacher"
    },
    {
        "value": 4334,
        "NOCCode": "4021",
        "JobTitle": "college teacher"
    },
    {
        "value": 4335,
        "NOCCode": "4021",
        "JobTitle": "commercial art instructor"
    },
    {
        "value": 4336,
        "NOCCode": "4021",
        "JobTitle": "commercial art teacher"
    },
    {
        "value": 4337,
        "NOCCode": "4021",
        "JobTitle": "commercial subjects teacher – vocational institute"
    },
    {
        "value": 4338,
        "NOCCode": "4021",
        "JobTitle": "commercial vehicle driver instructor"
    },
    {
        "value": 4339,
        "NOCCode": "4021",
        "JobTitle": "communications teacher – college level"
    },
    {
        "value": 4340,
        "NOCCode": "4021",
        "JobTitle": "community college instructor"
    },
    {
        "value": 4341,
        "NOCCode": "4021",
        "JobTitle": "community college teacher"
    },
    {
        "value": 4342,
        "NOCCode": "4021",
        "JobTitle": "community planning teacher – college level"
    },
    {
        "value": 4343,
        "NOCCode": "4021",
        "JobTitle": "community-based trainer"
    },
    {
        "value": 4344,
        "NOCCode": "4021",
        "JobTitle": "company trainer"
    },
    {
        "value": 4345,
        "NOCCode": "4021",
        "JobTitle": "computer programming instructor – college level"
    },
    {
        "value": 4346,
        "NOCCode": "4021",
        "JobTitle": "computer science teacher – college level"
    },
    {
        "value": 4347,
        "NOCCode": "4021",
        "JobTitle": "computer trainer"
    },
    {
        "value": 4348,
        "NOCCode": "4021",
        "JobTitle": "computer training instructor"
    },
    {
        "value": 4349,
        "NOCCode": "4021",
        "JobTitle": "computer training representative"
    },
    {
        "value": 4350,
        "NOCCode": "4021",
        "JobTitle": "computer-assisted drafting (CAD) instructor"
    },
    {
        "value": 4351,
        "NOCCode": "4021",
        "JobTitle": "computer-based trainer"
    },
    {
        "value": 4352,
        "NOCCode": "4021",
        "JobTitle": "correspondence school instructor"
    },
    {
        "value": 4353,
        "NOCCode": "4021",
        "JobTitle": "correspondence school tutor"
    },
    {
        "value": 4354,
        "NOCCode": "4021",
        "JobTitle": "correspondence teacher – college level"
    },
    {
        "value": 4355,
        "NOCCode": "4021",
        "JobTitle": "court reporting instructor"
    },
    {
        "value": 4356,
        "NOCCode": "4021",
        "JobTitle": "data-processing teacher – college level"
    },
    {
        "value": 4357,
        "NOCCode": "4021",
        "JobTitle": "denominational institute teacher"
    },
    {
        "value": 4358,
        "NOCCode": "4021",
        "JobTitle": "dental assistant program teacher – community college"
    },
    {
        "value": 4359,
        "NOCCode": "4021",
        "JobTitle": "dental hygienist program teacher"
    },
    {
        "value": 4360,
        "NOCCode": "4021",
        "JobTitle": "department chairman/woman – college"
    },
    {
        "value": 4361,
        "NOCCode": "4021",
        "JobTitle": "department chairman/woman – community college"
    },
    {
        "value": 4362,
        "NOCCode": "4021",
        "JobTitle": "department chairperson – college"
    },
    {
        "value": 4363,
        "NOCCode": "4021",
        "JobTitle": "department chairperson – community college"
    },
    {
        "value": 4364,
        "NOCCode": "4021",
        "JobTitle": "department head – CEGEP"
    },
    {
        "value": 4365,
        "NOCCode": "4021",
        "JobTitle": "department head – college"
    },
    {
        "value": 4366,
        "NOCCode": "4021",
        "JobTitle": "department head – community college"
    },
    {
        "value": 4367,
        "NOCCode": "4021",
        "JobTitle": "department head – institute of technology"
    },
    {
        "value": 4368,
        "NOCCode": "4021",
        "JobTitle": "department head – private training institute"
    },
    {
        "value": 4369,
        "NOCCode": "4021",
        "JobTitle": "department head – technical institute"
    },
    {
        "value": 4370,
        "NOCCode": "4021",
        "JobTitle": "department head – vocational institute"
    },
    {
        "value": 4371,
        "NOCCode": "4021",
        "JobTitle": "direct sales instructor"
    },
    {
        "value": 4372,
        "NOCCode": "4021",
        "JobTitle": "drafting instructor"
    },
    {
        "value": 4373,
        "NOCCode": "4021",
        "JobTitle": "drafting instructor – college level"
    },
    {
        "value": 4374,
        "NOCCode": "4021",
        "JobTitle": "drafting teacher"
    },
    {
        "value": 4375,
        "NOCCode": "4021",
        "JobTitle": "drafting teacher – college level"
    },
    {
        "value": 4376,
        "NOCCode": "4021",
        "JobTitle": "driving instructor, commercial vehicles"
    },
    {
        "value": 4377,
        "NOCCode": "4021",
        "JobTitle": "early childhood education instructor – college level"
    },
    {
        "value": 4378,
        "NOCCode": "4021",
        "JobTitle": "early childhood education teacher – college level"
    },
    {
        "value": 4379,
        "NOCCode": "4021",
        "JobTitle": "economics teacher – college level"
    },
    {
        "value": 4380,
        "NOCCode": "4021",
        "JobTitle": "electrolysis instructor – vocational institute"
    },
    {
        "value": 4381,
        "NOCCode": "4021",
        "JobTitle": "electromechanical technology teacher – college level"
    },
    {
        "value": 4382,
        "NOCCode": "4021",
        "JobTitle": "electronic technology teacher – college level"
    },
    {
        "value": 4383,
        "NOCCode": "4021",
        "JobTitle": "elocution teacher – non-medical"
    },
    {
        "value": 4384,
        "NOCCode": "4021",
        "JobTitle": "embalming teacher"
    },
    {
        "value": 4385,
        "NOCCode": "4021",
        "JobTitle": "English as a second language teacher (ESL) – college level"
    },
    {
        "value": 4386,
        "NOCCode": "4021",
        "JobTitle": "English teacher – business college"
    },
    {
        "value": 4387,
        "NOCCode": "4021",
        "JobTitle": "English teacher – college level"
    },
    {
        "value": 4388,
        "NOCCode": "4021",
        "JobTitle": "ESL (English as a second language) teacher – college level"
    },
    {
        "value": 4389,
        "NOCCode": "4021",
        "JobTitle": "fashion design teacher – CEGEP"
    },
    {
        "value": 4390,
        "NOCCode": "4021",
        "JobTitle": "fashion teacher – college level"
    },
    {
        "value": 4391,
        "NOCCode": "4021",
        "JobTitle": "fine arts teacher – college level"
    },
    {
        "value": 4392,
        "NOCCode": "4021",
        "JobTitle": "firefighters instructor"
    },
    {
        "value": 4393,
        "NOCCode": "4021",
        "JobTitle": "firefighting instructor"
    },
    {
        "value": 4394,
        "NOCCode": "4021",
        "JobTitle": "flight attendants instructor"
    },
    {
        "value": 4395,
        "NOCCode": "4021",
        "JobTitle": "French as a second language teacher (except elementary, high school or university)"
    },
    {
        "value": 4396,
        "NOCCode": "4021",
        "JobTitle": "gliding instructor"
    },
    {
        "value": 4397,
        "NOCCode": "4021",
        "JobTitle": "graphic arts teacher"
    },
    {
        "value": 4398,
        "NOCCode": "4021",
        "JobTitle": "graphic design instructor"
    },
    {
        "value": 4399,
        "NOCCode": "4021",
        "JobTitle": "ground school instructor"
    },
    {
        "value": 4400,
        "NOCCode": "4021",
        "JobTitle": "hairdressing instructor – vocational institute"
    },
    {
        "value": 4401,
        "NOCCode": "4021",
        "JobTitle": "hairdressing teacher – community college"
    },
    {
        "value": 4402,
        "NOCCode": "4021",
        "JobTitle": "health and safety instructor"
    },
    {
        "value": 4403,
        "NOCCode": "4021",
        "JobTitle": "health technology teacher"
    },
    {
        "value": 4404,
        "NOCCode": "4021",
        "JobTitle": "heavy equipment operators instructor – community college"
    },
    {
        "value": 4405,
        "NOCCode": "4021",
        "JobTitle": "history teacher – CEGEP"
    },
    {
        "value": 4406,
        "NOCCode": "4021",
        "JobTitle": "horticulture and landscaping instructor – school of horticulture"
    },
    {
        "value": 4407,
        "NOCCode": "4021",
        "JobTitle": "hotel management teacher – college"
    },
    {
        "value": 4408,
        "NOCCode": "4021",
        "JobTitle": "industrial instrumentation teacher"
    },
    {
        "value": 4409,
        "NOCCode": "4021",
        "JobTitle": "industrial safety instructor"
    },
    {
        "value": 4410,
        "NOCCode": "4021",
        "JobTitle": "Industry-based instructor"
    },
    {
        "value": 4411,
        "NOCCode": "4021",
        "JobTitle": "industry-based trainer"
    },
    {
        "value": 4412,
        "NOCCode": "4021",
        "JobTitle": "institute of technology teacher"
    },
    {
        "value": 4413,
        "NOCCode": "4021",
        "JobTitle": "instructor – business college"
    },
    {
        "value": 4414,
        "NOCCode": "4021",
        "JobTitle": "instructor – commercial college"
    },
    {
        "value": 4415,
        "NOCCode": "4021",
        "JobTitle": "instructor – company"
    },
    {
        "value": 4416,
        "NOCCode": "4021",
        "JobTitle": "instructor – industry"
    },
    {
        "value": 4417,
        "NOCCode": "4021",
        "JobTitle": "instructor – language school"
    },
    {
        "value": 4418,
        "NOCCode": "4021",
        "JobTitle": "instructor – private training institute"
    },
    {
        "value": 4419,
        "NOCCode": "4021",
        "JobTitle": "instructor – technical school"
    },
    {
        "value": 4420,
        "NOCCode": "4021",
        "JobTitle": "instructor – technology institute"
    },
    {
        "value": 4421,
        "NOCCode": "4021",
        "JobTitle": "instructor – vocational school"
    },
    {
        "value": 4422,
        "NOCCode": "4021",
        "JobTitle": "instructor, college"
    },
    {
        "value": 4423,
        "NOCCode": "4021",
        "JobTitle": "instructor, college of applied arts and technology"
    },
    {
        "value": 4424,
        "NOCCode": "4021",
        "JobTitle": "instructor, community college"
    },
    {
        "value": 4425,
        "NOCCode": "4021",
        "JobTitle": "instructor, correspondence school"
    },
    {
        "value": 4426,
        "NOCCode": "4021",
        "JobTitle": "instructor, firefighters"
    },
    {
        "value": 4427,
        "NOCCode": "4021",
        "JobTitle": "instructor, firefighting"
    },
    {
        "value": 4428,
        "NOCCode": "4021",
        "JobTitle": "instructor, flight attendants"
    },
    {
        "value": 4429,
        "NOCCode": "4021",
        "JobTitle": "instructor, ground school"
    },
    {
        "value": 4430,
        "NOCCode": "4021",
        "JobTitle": "instructor, health and safety"
    },
    {
        "value": 4431,
        "NOCCode": "4021",
        "JobTitle": "instructor, heavy equipment operators – community college"
    },
    {
        "value": 4432,
        "NOCCode": "4021",
        "JobTitle": "instructor, industrial safety"
    },
    {
        "value": 4433,
        "NOCCode": "4021",
        "JobTitle": "instructor, police"
    },
    {
        "value": 4434,
        "NOCCode": "4021",
        "JobTitle": "instructor, pursers"
    },
    {
        "value": 4435,
        "NOCCode": "4021",
        "JobTitle": "instructor, technological institute"
    },
    {
        "value": 4436,
        "NOCCode": "4021",
        "JobTitle": "interior design teacher"
    },
    {
        "value": 4437,
        "NOCCode": "4021",
        "JobTitle": "journalism teacher – college level"
    },
    {
        "value": 4438,
        "NOCCode": "4021",
        "JobTitle": "landscaping instructor"
    },
    {
        "value": 4439,
        "NOCCode": "4021",
        "JobTitle": "language instructor – language school"
    },
    {
        "value": 4440,
        "NOCCode": "4021",
        "JobTitle": "language laboratory teacher – college level"
    },
    {
        "value": 4441,
        "NOCCode": "4021",
        "JobTitle": "language school instructor"
    },
    {
        "value": 4442,
        "NOCCode": "4021",
        "JobTitle": "law enforcement teacher"
    },
    {
        "value": 4443,
        "NOCCode": "4021",
        "JobTitle": "law teacher – police college"
    },
    {
        "value": 4444,
        "NOCCode": "4021",
        "JobTitle": "leader, management seminar"
    },
    {
        "value": 4445,
        "NOCCode": "4021",
        "JobTitle": "lecturer – college"
    },
    {
        "value": 4446,
        "NOCCode": "4021",
        "JobTitle": "lecturer, college"
    },
    {
        "value": 4447,
        "NOCCode": "4021",
        "JobTitle": "legal secretarial program teacher – community college"
    },
    {
        "value": 4448,
        "NOCCode": "4021",
        "JobTitle": "management seminar leader"
    },
    {
        "value": 4449,
        "NOCCode": "4021",
        "JobTitle": "marketing teacher – college level"
    },
    {
        "value": 4450,
        "NOCCode": "4021",
        "JobTitle": "mathematics teacher – college level"
    },
    {
        "value": 4451,
        "NOCCode": "4021",
        "JobTitle": "mechanical technology teacher – college level"
    },
    {
        "value": 4452,
        "NOCCode": "4021",
        "JobTitle": "medical records management program teacher"
    },
    {
        "value": 4453,
        "NOCCode": "4021",
        "JobTitle": "medical technology teacher"
    },
    {
        "value": 4454,
        "NOCCode": "4021",
        "JobTitle": "mineral technology teacher – college of applied arts and technology"
    },
    {
        "value": 4455,
        "NOCCode": "4021",
        "JobTitle": "mining rescue instructor"
    },
    {
        "value": 4456,
        "NOCCode": "4021",
        "JobTitle": "modern languages teacher – college level"
    },
    {
        "value": 4457,
        "NOCCode": "4021",
        "JobTitle": "modern languages tutor – language school"
    },
    {
        "value": 4458,
        "NOCCode": "4021",
        "JobTitle": "music teacher – conservatory of music"
    },
    {
        "value": 4459,
        "NOCCode": "4021",
        "JobTitle": "nursing educator – except college"
    },
    {
        "value": 4460,
        "NOCCode": "4021",
        "JobTitle": "nursing instructor – college"
    },
    {
        "value": 4461,
        "NOCCode": "4021",
        "JobTitle": "police college teacher"
    },
    {
        "value": 4462,
        "NOCCode": "4021",
        "JobTitle": "police instructor"
    },
    {
        "value": 4463,
        "NOCCode": "4021",
        "JobTitle": "printing technology teacher – CEGEP"
    },
    {
        "value": 4464,
        "NOCCode": "4021",
        "JobTitle": "professional training instructor"
    },
    {
        "value": 4465,
        "NOCCode": "4021",
        "JobTitle": "public-speaking instructor"
    },
    {
        "value": 4466,
        "NOCCode": "4021",
        "JobTitle": "public-speaking teacher"
    },
    {
        "value": 4467,
        "NOCCode": "4021",
        "JobTitle": "pursers instructor"
    },
    {
        "value": 4468,
        "NOCCode": "4021",
        "JobTitle": "recreational leadership teacher"
    },
    {
        "value": 4469,
        "NOCCode": "4021",
        "JobTitle": "remedial teacher – college level"
    },
    {
        "value": 4470,
        "NOCCode": "4021",
        "JobTitle": "retail management teacher"
    },
    {
        "value": 4471,
        "NOCCode": "4021",
        "JobTitle": "second-language instructor – language school"
    },
    {
        "value": 4472,
        "NOCCode": "4021",
        "JobTitle": "secretarial services teacher"
    },
    {
        "value": 4473,
        "NOCCode": "4021",
        "JobTitle": "shorthand teacher – business college"
    },
    {
        "value": 4474,
        "NOCCode": "4021",
        "JobTitle": "social services teacher – college level"
    },
    {
        "value": 4475,
        "NOCCode": "4021",
        "JobTitle": "staff training officer – industry"
    },
    {
        "value": 4476,
        "NOCCode": "4021",
        "JobTitle": "teacher – business college"
    },
    {
        "value": 4477,
        "NOCCode": "4021",
        "JobTitle": "teacher – commercial college"
    },
    {
        "value": 4478,
        "NOCCode": "4021",
        "JobTitle": "teacher – institute of technology"
    },
    {
        "value": 4479,
        "NOCCode": "4021",
        "JobTitle": "teacher – seminary"
    },
    {
        "value": 4480,
        "NOCCode": "4021",
        "JobTitle": "teacher, agricultural college"
    },
    {
        "value": 4481,
        "NOCCode": "4021",
        "JobTitle": "teacher, agricultural school"
    },
    {
        "value": 4482,
        "NOCCode": "4021",
        "JobTitle": "teacher, Bible college"
    },
    {
        "value": 4483,
        "NOCCode": "4021",
        "JobTitle": "teacher, Bible school"
    },
    {
        "value": 4484,
        "NOCCode": "4021",
        "JobTitle": "teacher, CEGEP (general and vocational college)"
    },
    {
        "value": 4485,
        "NOCCode": "4021",
        "JobTitle": "teacher, college"
    },
    {
        "value": 4486,
        "NOCCode": "4021",
        "JobTitle": "teacher, college of applied arts and technology"
    },
    {
        "value": 4487,
        "NOCCode": "4021",
        "JobTitle": "teacher, community college"
    },
    {
        "value": 4488,
        "NOCCode": "4021",
        "JobTitle": "teacher, English as a second language (ESL) – college level"
    },
    {
        "value": 4489,
        "NOCCode": "4021",
        "JobTitle": "teacher, English as a second language (except elementary, high school or university)"
    },
    {
        "value": 4490,
        "NOCCode": "4021",
        "JobTitle": "teacher, French as a second language (except elementary, high school or university)"
    },
    {
        "value": 4491,
        "NOCCode": "4021",
        "JobTitle": "teacher, legal assistant program"
    },
    {
        "value": 4492,
        "NOCCode": "4021",
        "JobTitle": "teacher, police college"
    },
    {
        "value": 4493,
        "NOCCode": "4021",
        "JobTitle": "teacher, public speaking"
    },
    {
        "value": 4494,
        "NOCCode": "4021",
        "JobTitle": "teacher, technological institute"
    },
    {
        "value": 4495,
        "NOCCode": "4021",
        "JobTitle": "teacher, vocational institute"
    },
    {
        "value": 4496,
        "NOCCode": "4021",
        "JobTitle": "technical subjects instructor – vocational institute"
    },
    {
        "value": 4497,
        "NOCCode": "4021",
        "JobTitle": "technological institute instructor"
    },
    {
        "value": 4498,
        "NOCCode": "4021",
        "JobTitle": "technological institute teacher"
    },
    {
        "value": 4499,
        "NOCCode": "4021",
        "JobTitle": "technological subjects instructor – vocational institute"
    },
    {
        "value": 4500,
        "NOCCode": "4021",
        "JobTitle": "technology teacher – college level"
    },
    {
        "value": 4501,
        "NOCCode": "4021",
        "JobTitle": "tool and die making teacher – community college"
    },
    {
        "value": 4502,
        "NOCCode": "4021",
        "JobTitle": "tourism trainer"
    },
    {
        "value": 4503,
        "NOCCode": "4021",
        "JobTitle": "trade instructor – community college"
    },
    {
        "value": 4504,
        "NOCCode": "4021",
        "JobTitle": "trainee instructor"
    },
    {
        "value": 4505,
        "NOCCode": "4021",
        "JobTitle": "trainer, company"
    },
    {
        "value": 4506,
        "NOCCode": "4021",
        "JobTitle": "trainer, computer"
    },
    {
        "value": 4507,
        "NOCCode": "4021",
        "JobTitle": "training consultant – industry"
    },
    {
        "value": 4508,
        "NOCCode": "4021",
        "JobTitle": "training instructor – college level"
    },
    {
        "value": 4509,
        "NOCCode": "4021",
        "JobTitle": "training officer – company"
    },
    {
        "value": 4510,
        "NOCCode": "4021",
        "JobTitle": "training representative, computer"
    },
    {
        "value": 4511,
        "NOCCode": "4021",
        "JobTitle": "truck driver trainer – community college"
    },
    {
        "value": 4512,
        "NOCCode": "4021",
        "JobTitle": "tutor, correspondence school"
    },
    {
        "value": 4513,
        "NOCCode": "4021",
        "JobTitle": "tutor, modern languages – language school"
    },
    {
        "value": 4514,
        "NOCCode": "4021",
        "JobTitle": "typing teacher – commercial college"
    },
    {
        "value": 4515,
        "NOCCode": "4021",
        "JobTitle": "vocational institute teacher"
    },
    {
        "value": 4516,
        "NOCCode": "4021",
        "JobTitle": "vocational trainer"
    },
    {
        "value": 4517,
        "NOCCode": "4021",
        "JobTitle": "welding teacher – technical institute"
    },
    {
        "value": 4518,
        "NOCCode": "4031",
        "JobTitle": "Aboriginal school community teacher – secondary school"
    },
    {
        "value": 4519,
        "NOCCode": "4031",
        "JobTitle": "academic subjects high school teacher"
    },
    {
        "value": 4520,
        "NOCCode": "4031",
        "JobTitle": "academic subjects secondary school teacher"
    },
    {
        "value": 4521,
        "NOCCode": "4031",
        "JobTitle": "accounting teacher – secondary school"
    },
    {
        "value": 4522,
        "NOCCode": "4031",
        "JobTitle": "adult education teacher, secondary school"
    },
    {
        "value": 4523,
        "NOCCode": "4031",
        "JobTitle": "algebra teacher – secondary school"
    },
    {
        "value": 4524,
        "NOCCode": "4031",
        "JobTitle": "art teacher – secondary school"
    },
    {
        "value": 4525,
        "NOCCode": "4031",
        "JobTitle": "arts and crafts teacher – secondary school"
    },
    {
        "value": 4526,
        "NOCCode": "4031",
        "JobTitle": "biology teacher – secondary school"
    },
    {
        "value": 4527,
        "NOCCode": "4031",
        "JobTitle": "biology teacher, high school"
    },
    {
        "value": 4528,
        "NOCCode": "4031",
        "JobTitle": "business and commerce teacher – secondary school"
    },
    {
        "value": 4529,
        "NOCCode": "4031",
        "JobTitle": "business education teacher"
    },
    {
        "value": 4530,
        "NOCCode": "4031",
        "JobTitle": "business teacher – secondary school"
    },
    {
        "value": 4531,
        "NOCCode": "4031",
        "JobTitle": "calculus teacher – secondary school"
    },
    {
        "value": 4532,
        "NOCCode": "4031",
        "JobTitle": "career and life management teacher"
    },
    {
        "value": 4533,
        "NOCCode": "4031",
        "JobTitle": "career and technology studies teacher"
    },
    {
        "value": 4534,
        "NOCCode": "4031",
        "JobTitle": "career education teacher"
    },
    {
        "value": 4535,
        "NOCCode": "4031",
        "JobTitle": "career exploration teacher"
    },
    {
        "value": 4536,
        "NOCCode": "4031",
        "JobTitle": "chemistry teacher – secondary school"
    },
    {
        "value": 4537,
        "NOCCode": "4031",
        "JobTitle": "classical languages secondary school teacher"
    },
    {
        "value": 4538,
        "NOCCode": "4031",
        "JobTitle": "commerce teacher – secondary school"
    },
    {
        "value": 4539,
        "NOCCode": "4031",
        "JobTitle": "community teacher, Aboriginal school – secondary school"
    },
    {
        "value": 4540,
        "NOCCode": "4031",
        "JobTitle": "community teacher, Native school – secondary school"
    },
    {
        "value": 4541,
        "NOCCode": "4031",
        "JobTitle": "computer technology teacher – secondary school"
    },
    {
        "value": 4542,
        "NOCCode": "4031",
        "JobTitle": "cooking teacher – secondary school"
    },
    {
        "value": 4543,
        "NOCCode": "4031",
        "JobTitle": "cooperative education teacher"
    },
    {
        "value": 4544,
        "NOCCode": "4031",
        "JobTitle": "cooperative learning teacher"
    },
    {
        "value": 4545,
        "NOCCode": "4031",
        "JobTitle": "correspondence teacher – secondary school"
    },
    {
        "value": 4546,
        "NOCCode": "4031",
        "JobTitle": "data processing teacher – secondary school"
    },
    {
        "value": 4547,
        "NOCCode": "4031",
        "JobTitle": "department head, secondary school"
    },
    {
        "value": 4548,
        "NOCCode": "4031",
        "JobTitle": "distance education teacher – secondary school"
    },
    {
        "value": 4549,
        "NOCCode": "4031",
        "JobTitle": "drama teacher – secondary school"
    },
    {
        "value": 4550,
        "NOCCode": "4031",
        "JobTitle": "economics teacher – secondary school"
    },
    {
        "value": 4551,
        "NOCCode": "4031",
        "JobTitle": "English as a second language (ESL) high school teacher"
    },
    {
        "value": 4552,
        "NOCCode": "4031",
        "JobTitle": "English as a second language teacher, high school"
    },
    {
        "value": 4553,
        "NOCCode": "4031",
        "JobTitle": "English literature high school teacher"
    },
    {
        "value": 4554,
        "NOCCode": "4031",
        "JobTitle": "English teacher, secondary school"
    },
    {
        "value": 4555,
        "NOCCode": "4031",
        "JobTitle": "ESL (English as a second language) high school teacher"
    },
    {
        "value": 4556,
        "NOCCode": "4031",
        "JobTitle": "ethics teacher – secondary school"
    },
    {
        "value": 4557,
        "NOCCode": "4031",
        "JobTitle": "fine arts teacher – secondary school"
    },
    {
        "value": 4558,
        "NOCCode": "4031",
        "JobTitle": "French as a second language teacher – secondary school"
    },
    {
        "value": 4559,
        "NOCCode": "4031",
        "JobTitle": "French as a second language teacher, high school"
    },
    {
        "value": 4560,
        "NOCCode": "4031",
        "JobTitle": "French teacher – secondary school"
    },
    {
        "value": 4561,
        "NOCCode": "4031",
        "JobTitle": "geography teacher – secondary school"
    },
    {
        "value": 4562,
        "NOCCode": "4031",
        "JobTitle": "geometry teacher – secondary school"
    },
    {
        "value": 4563,
        "NOCCode": "4031",
        "JobTitle": "German teacher – secondary school"
    },
    {
        "value": 4564,
        "NOCCode": "4031",
        "JobTitle": "Greek teacher – secondary school"
    },
    {
        "value": 4565,
        "NOCCode": "4031",
        "JobTitle": "guest teacher – secondary school"
    },
    {
        "value": 4566,
        "NOCCode": "4031",
        "JobTitle": "health education teacher – secondary school"
    },
    {
        "value": 4567,
        "NOCCode": "4031",
        "JobTitle": "high school teacher"
    },
    {
        "value": 4568,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, English as a second language"
    },
    {
        "value": 4569,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, French as a second language"
    },
    {
        "value": 4570,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, outdoor activities"
    },
    {
        "value": 4571,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, physical education"
    },
    {
        "value": 4572,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, religious education"
    },
    {
        "value": 4573,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, science"
    },
    {
        "value": 4574,
        "NOCCode": "4031",
        "JobTitle": "high school teacher, special education"
    },
    {
        "value": 4575,
        "NOCCode": "4031",
        "JobTitle": "history teacher, secondary school"
    },
    {
        "value": 4576,
        "NOCCode": "4031",
        "JobTitle": "home economics teacher – secondary school"
    },
    {
        "value": 4577,
        "NOCCode": "4031",
        "JobTitle": "humanities teacher – high school"
    },
    {
        "value": 4578,
        "NOCCode": "4031",
        "JobTitle": "industrial and vocational education teacher – secondary school"
    },
    {
        "value": 4579,
        "NOCCode": "4031",
        "JobTitle": "industrial arts teacher – secondary school"
    },
    {
        "value": 4580,
        "NOCCode": "4031",
        "JobTitle": "information technology teacher – secondary school"
    },
    {
        "value": 4581,
        "NOCCode": "4031",
        "JobTitle": "Italian teacher – secondary school"
    },
    {
        "value": 4582,
        "NOCCode": "4031",
        "JobTitle": "Latin teacher – secondary school"
    },
    {
        "value": 4583,
        "NOCCode": "4031",
        "JobTitle": "librarian-teacher, high school"
    },
    {
        "value": 4584,
        "NOCCode": "4031",
        "JobTitle": "mathematics teacher – secondary school"
    },
    {
        "value": 4585,
        "NOCCode": "4031",
        "JobTitle": "modern languages teacher – secondary school"
    },
    {
        "value": 4586,
        "NOCCode": "4031",
        "JobTitle": "music teacher – secondary school"
    },
    {
        "value": 4587,
        "NOCCode": "4031",
        "JobTitle": "Native school community teacher – secondary school"
    },
    {
        "value": 4588,
        "NOCCode": "4031",
        "JobTitle": "outdoor physical education teacher – secondary school"
    },
    {
        "value": 4589,
        "NOCCode": "4031",
        "JobTitle": "physical and health education teacher – secondary school"
    },
    {
        "value": 4590,
        "NOCCode": "4031",
        "JobTitle": "physical education teacher – secondary school"
    },
    {
        "value": 4591,
        "NOCCode": "4031",
        "JobTitle": "physical science teacher – secondary school"
    },
    {
        "value": 4592,
        "NOCCode": "4031",
        "JobTitle": "physics teacher – secondary school"
    },
    {
        "value": 4593,
        "NOCCode": "4031",
        "JobTitle": "practical and applied arts teacher"
    },
    {
        "value": 4594,
        "NOCCode": "4031",
        "JobTitle": "private teacher – secondary school"
    },
    {
        "value": 4595,
        "NOCCode": "4031",
        "JobTitle": "reading clinician – secondary school"
    },
    {
        "value": 4596,
        "NOCCode": "4031",
        "JobTitle": "religious education high school teacher"
    },
    {
        "value": 4597,
        "NOCCode": "4031",
        "JobTitle": "remedial teacher – secondary school"
    },
    {
        "value": 4598,
        "NOCCode": "4031",
        "JobTitle": "remedial teacher, secondary school"
    },
    {
        "value": 4599,
        "NOCCode": "4031",
        "JobTitle": "school teacher – secondary school"
    },
    {
        "value": 4600,
        "NOCCode": "4031",
        "JobTitle": "science high school teacher"
    },
    {
        "value": 4601,
        "NOCCode": "4031",
        "JobTitle": "science teacher – secondary school"
    },
    {
        "value": 4602,
        "NOCCode": "4031",
        "JobTitle": "secondary school reading clinician"
    },
    {
        "value": 4603,
        "NOCCode": "4031",
        "JobTitle": "secondary school teacher"
    },
    {
        "value": 4604,
        "NOCCode": "4031",
        "JobTitle": "secondary school teacher, classical languages"
    },
    {
        "value": 4605,
        "NOCCode": "4031",
        "JobTitle": "secondary school teacher, physical education"
    },
    {
        "value": 4606,
        "NOCCode": "4031",
        "JobTitle": "secondary school teacher, special education"
    },
    {
        "value": 4607,
        "NOCCode": "4031",
        "JobTitle": "secondary school vocational teacher"
    },
    {
        "value": 4608,
        "NOCCode": "4031",
        "JobTitle": "sewing teacher – secondary school"
    },
    {
        "value": 4609,
        "NOCCode": "4031",
        "JobTitle": "sheet metal instructor – secondary school"
    },
    {
        "value": 4610,
        "NOCCode": "4031",
        "JobTitle": "social sciences teacher – secondary school"
    },
    {
        "value": 4611,
        "NOCCode": "4031",
        "JobTitle": "Spanish teacher – secondary school"
    },
    {
        "value": 4612,
        "NOCCode": "4031",
        "JobTitle": "special education teacher – secondary school"
    },
    {
        "value": 4613,
        "NOCCode": "4031",
        "JobTitle": "special needs teacher – secondary school"
    },
    {
        "value": 4614,
        "NOCCode": "4031",
        "JobTitle": "special programs teacher – secondary school"
    },
    {
        "value": 4615,
        "NOCCode": "4031",
        "JobTitle": "substitute high school teacher"
    },
    {
        "value": 4616,
        "NOCCode": "4031",
        "JobTitle": "substitute teacher – secondary school"
    },
    {
        "value": 4617,
        "NOCCode": "4031",
        "JobTitle": "supply high school teacher"
    },
    {
        "value": 4618,
        "NOCCode": "4031",
        "JobTitle": "supply teacher, high school"
    },
    {
        "value": 4619,
        "NOCCode": "4031",
        "JobTitle": "teacher, high school"
    },
    {
        "value": 4620,
        "NOCCode": "4031",
        "JobTitle": "teacher, secondary school"
    },
    {
        "value": 4621,
        "NOCCode": "4031",
        "JobTitle": "teacher-librarian, high school"
    },
    {
        "value": 4622,
        "NOCCode": "4031",
        "JobTitle": "technical and vocational teacher – secondary school"
    },
    {
        "value": 4623,
        "NOCCode": "4031",
        "JobTitle": "trades instructor – secondary school"
    },
    {
        "value": 4624,
        "NOCCode": "4031",
        "JobTitle": "vocational high school teacher"
    },
    {
        "value": 4625,
        "NOCCode": "4031",
        "JobTitle": "vocational secondary school teacher"
    },
    {
        "value": 4626,
        "NOCCode": "4031",
        "JobTitle": "vocational teacher – secondary school"
    },
    {
        "value": 4627,
        "NOCCode": "4031",
        "JobTitle": "vocational teacher, high school"
    },
    {
        "value": 4628,
        "NOCCode": "4032",
        "JobTitle": "art teacher – elementary school"
    },
    {
        "value": 4629,
        "NOCCode": "4032",
        "JobTitle": "cooking teacher – elementary school"
    },
    {
        "value": 4630,
        "NOCCode": "4032",
        "JobTitle": "correspondence teacher – elementary school"
    },
    {
        "value": 4631,
        "NOCCode": "4032",
        "JobTitle": "crafts teacher – elementary school"
    },
    {
        "value": 4632,
        "NOCCode": "4032",
        "JobTitle": "early childhood services teacher – elementary school"
    },
    {
        "value": 4633,
        "NOCCode": "4032",
        "JobTitle": "elementary school reading clinician"
    },
    {
        "value": 4634,
        "NOCCode": "4032",
        "JobTitle": "elementary school substitute teacher"
    },
    {
        "value": 4635,
        "NOCCode": "4032",
        "JobTitle": "elementary school supply teacher"
    },
    {
        "value": 4636,
        "NOCCode": "4032",
        "JobTitle": "elementary school teacher"
    },
    {
        "value": 4637,
        "NOCCode": "4032",
        "JobTitle": "elementary school teacher, English as a second language"
    },
    {
        "value": 4638,
        "NOCCode": "4032",
        "JobTitle": "elementary school teacher, French as a second language"
    },
    {
        "value": 4639,
        "NOCCode": "4032",
        "JobTitle": "elementary school teacher, special education"
    },
    {
        "value": 4640,
        "NOCCode": "4032",
        "JobTitle": "English teacher – elementary school"
    },
    {
        "value": 4641,
        "NOCCode": "4032",
        "JobTitle": "French immersion teacher – elementary school"
    },
    {
        "value": 4642,
        "NOCCode": "4032",
        "JobTitle": "French teacher – elementary school"
    },
    {
        "value": 4643,
        "NOCCode": "4032",
        "JobTitle": "grade school teacher"
    },
    {
        "value": 4644,
        "NOCCode": "4032",
        "JobTitle": "home economics teacher – elementary school"
    },
    {
        "value": 4645,
        "NOCCode": "4032",
        "JobTitle": "industrial arts teacher – elementary school"
    },
    {
        "value": 4646,
        "NOCCode": "4032",
        "JobTitle": "junior high school teacher"
    },
    {
        "value": 4647,
        "NOCCode": "4032",
        "JobTitle": "kindergarten teacher"
    },
    {
        "value": 4648,
        "NOCCode": "4032",
        "JobTitle": "language teacher – elementary school"
    },
    {
        "value": 4649,
        "NOCCode": "4032",
        "JobTitle": "librarian-teacher, elementary school"
    },
    {
        "value": 4650,
        "NOCCode": "4032",
        "JobTitle": "music teacher – elementary school"
    },
    {
        "value": 4651,
        "NOCCode": "4032",
        "JobTitle": "nature study teacher – elementary school"
    },
    {
        "value": 4652,
        "NOCCode": "4032",
        "JobTitle": "physical education teacher – elementary school"
    },
    {
        "value": 4653,
        "NOCCode": "4032",
        "JobTitle": "primary school teacher"
    },
    {
        "value": 4654,
        "NOCCode": "4032",
        "JobTitle": "reading clinician – elementary school"
    },
    {
        "value": 4655,
        "NOCCode": "4032",
        "JobTitle": "remedial teacher – elementary school"
    },
    {
        "value": 4656,
        "NOCCode": "4032",
        "JobTitle": "sewing teacher – elementary school"
    },
    {
        "value": 4657,
        "NOCCode": "4032",
        "JobTitle": "special education teacher – elementary school"
    },
    {
        "value": 4658,
        "NOCCode": "4032",
        "JobTitle": "special education teacher – primary school"
    },
    {
        "value": 4659,
        "NOCCode": "4032",
        "JobTitle": "special needs teacher – elementary school"
    },
    {
        "value": 4660,
        "NOCCode": "4032",
        "JobTitle": "substitute teacher – elementary school"
    },
    {
        "value": 4661,
        "NOCCode": "4032",
        "JobTitle": "supply teacher – elementary school"
    },
    {
        "value": 4662,
        "NOCCode": "4032",
        "JobTitle": "teacher – junior kindergarten"
    },
    {
        "value": 4663,
        "NOCCode": "4032",
        "JobTitle": "teacher, Aboriginal school – elementary school"
    },
    {
        "value": 4664,
        "NOCCode": "4032",
        "JobTitle": "teacher, elementary school"
    },
    {
        "value": 4665,
        "NOCCode": "4032",
        "JobTitle": "teacher, English as a second language – elementary school"
    },
    {
        "value": 4666,
        "NOCCode": "4032",
        "JobTitle": "teacher, French as a second language – elementary school"
    },
    {
        "value": 4667,
        "NOCCode": "4032",
        "JobTitle": "teacher, kindergarten"
    },
    {
        "value": 4668,
        "NOCCode": "4032",
        "JobTitle": "teacher, primary school"
    },
    {
        "value": 4669,
        "NOCCode": "4032",
        "JobTitle": "teacher-librarian, elementary school"
    },
    {
        "value": 4670,
        "NOCCode": "4032",
        "JobTitle": "visiting teacher – elementary school"
    },
    {
        "value": 4671,
        "NOCCode": "4033",
        "JobTitle": "academic adviser"
    },
    {
        "value": 4672,
        "NOCCode": "4033",
        "JobTitle": "academic adviser, First Nations studies program"
    },
    {
        "value": 4673,
        "NOCCode": "4033",
        "JobTitle": "academic counsellor"
    },
    {
        "value": 4674,
        "NOCCode": "4033",
        "JobTitle": "academic skills counsellor"
    },
    {
        "value": 4675,
        "NOCCode": "4033",
        "JobTitle": "adjustment officer, school"
    },
    {
        "value": 4676,
        "NOCCode": "4033",
        "JobTitle": "adviser, academic"
    },
    {
        "value": 4677,
        "NOCCode": "4033",
        "JobTitle": "career counsellor – education"
    },
    {
        "value": 4678,
        "NOCCode": "4033",
        "JobTitle": "college counsellor"
    },
    {
        "value": 4679,
        "NOCCode": "4033",
        "JobTitle": "co-op officer"
    },
    {
        "value": 4680,
        "NOCCode": "4033",
        "JobTitle": "counselling intern"
    },
    {
        "value": 4681,
        "NOCCode": "4033",
        "JobTitle": "counsellor for Aboriginal students"
    },
    {
        "value": 4682,
        "NOCCode": "4033",
        "JobTitle": "counsellor, school"
    },
    {
        "value": 4683,
        "NOCCode": "4033",
        "JobTitle": "director, educational guidance"
    },
    {
        "value": 4684,
        "NOCCode": "4033",
        "JobTitle": "education officer"
    },
    {
        "value": 4685,
        "NOCCode": "4033",
        "JobTitle": "educational counsellor"
    },
    {
        "value": 4686,
        "NOCCode": "4033",
        "JobTitle": "educational guidance director"
    },
    {
        "value": 4687,
        "NOCCode": "4033",
        "JobTitle": "First Nations counsellor – Education"
    },
    {
        "value": 4688,
        "NOCCode": "4033",
        "JobTitle": "First Nations studies program academic adviser"
    },
    {
        "value": 4689,
        "NOCCode": "4033",
        "JobTitle": "guidance counsellor"
    },
    {
        "value": 4690,
        "NOCCode": "4033",
        "JobTitle": "guidance service head"
    },
    {
        "value": 4691,
        "NOCCode": "4033",
        "JobTitle": "head, school guidance department"
    },
    {
        "value": 4692,
        "NOCCode": "4033",
        "JobTitle": "independent or applied studies officer"
    },
    {
        "value": 4693,
        "NOCCode": "4033",
        "JobTitle": "international student adviser"
    },
    {
        "value": 4694,
        "NOCCode": "4033",
        "JobTitle": "learning and study skills specialist"
    },
    {
        "value": 4695,
        "NOCCode": "4033",
        "JobTitle": "personal academic counsellor"
    },
    {
        "value": 4696,
        "NOCCode": "4033",
        "JobTitle": "personal counsellor – education"
    },
    {
        "value": 4697,
        "NOCCode": "4033",
        "JobTitle": "school adjustment officer"
    },
    {
        "value": 4698,
        "NOCCode": "4033",
        "JobTitle": "school counsellor"
    },
    {
        "value": 4699,
        "NOCCode": "4033",
        "JobTitle": "school guidance department head"
    },
    {
        "value": 4700,
        "NOCCode": "4033",
        "JobTitle": "student affairs and employment officer"
    },
    {
        "value": 4701,
        "NOCCode": "4033",
        "JobTitle": "student counsellor"
    },
    {
        "value": 4702,
        "NOCCode": "4033",
        "JobTitle": "student services counsellor"
    },
    {
        "value": 4703,
        "NOCCode": "4033",
        "JobTitle": "university counsellor"
    },
    {
        "value": 4704,
        "NOCCode": "4033",
        "JobTitle": "vocational counsellor – education"
    },
    {
        "value": 4705,
        "NOCCode": "4111",
        "JobTitle": "administrative judge"
    },
    {
        "value": 4706,
        "NOCCode": "4111",
        "JobTitle": "appellate court judge"
    },
    {
        "value": 4707,
        "NOCCode": "4111",
        "JobTitle": "associate chief justice"
    },
    {
        "value": 4708,
        "NOCCode": "4111",
        "JobTitle": "chief justice"
    },
    {
        "value": 4709,
        "NOCCode": "4111",
        "JobTitle": "county court judge"
    },
    {
        "value": 4710,
        "NOCCode": "4111",
        "JobTitle": "county judge"
    },
    {
        "value": 4711,
        "NOCCode": "4111",
        "JobTitle": "Court of Queen's Bench justice"
    },
    {
        "value": 4712,
        "NOCCode": "4111",
        "JobTitle": "deputy justice"
    },
    {
        "value": 4713,
        "NOCCode": "4111",
        "JobTitle": "district and surrogate court judge"
    },
    {
        "value": 4714,
        "NOCCode": "4111",
        "JobTitle": "district court judge"
    },
    {
        "value": 4715,
        "NOCCode": "4111",
        "JobTitle": "family court judge"
    },
    {
        "value": 4716,
        "NOCCode": "4111",
        "JobTitle": "federal court justice"
    },
    {
        "value": 4717,
        "NOCCode": "4111",
        "JobTitle": "federal court justice – trial division"
    },
    {
        "value": 4718,
        "NOCCode": "4111",
        "JobTitle": "Federal Court of Appeal justice"
    },
    {
        "value": 4719,
        "NOCCode": "4111",
        "JobTitle": "federal trial court justice"
    },
    {
        "value": 4720,
        "NOCCode": "4111",
        "JobTitle": "judge – law"
    },
    {
        "value": 4721,
        "NOCCode": "4111",
        "JobTitle": "justice"
    },
    {
        "value": 4722,
        "NOCCode": "4111",
        "JobTitle": "juvenile court judge"
    },
    {
        "value": 4723,
        "NOCCode": "4111",
        "JobTitle": "magistrate court judge"
    },
    {
        "value": 4724,
        "NOCCode": "4111",
        "JobTitle": "probate court judge"
    },
    {
        "value": 4725,
        "NOCCode": "4111",
        "JobTitle": "provincial court judge"
    },
    {
        "value": 4726,
        "NOCCode": "4111",
        "JobTitle": "provincial court of appeal justice"
    },
    {
        "value": 4727,
        "NOCCode": "4111",
        "JobTitle": "provincial supreme court justice"
    },
    {
        "value": 4728,
        "NOCCode": "4111",
        "JobTitle": "puisne judge"
    },
    {
        "value": 4729,
        "NOCCode": "4111",
        "JobTitle": "small claims court judge"
    },
    {
        "value": 4730,
        "NOCCode": "4111",
        "JobTitle": "superior court justice"
    },
    {
        "value": 4731,
        "NOCCode": "4111",
        "JobTitle": "Supreme Court justice"
    },
    {
        "value": 4732,
        "NOCCode": "4111",
        "JobTitle": "Supreme Court of Canada justice"
    },
    {
        "value": 4733,
        "NOCCode": "4111",
        "JobTitle": "Tax Court judge"
    },
    {
        "value": 4734,
        "NOCCode": "4111",
        "JobTitle": "trial court judge"
    },
    {
        "value": 4735,
        "NOCCode": "4111",
        "JobTitle": "youth court judge"
    },
    {
        "value": 4736,
        "NOCCode": "4112",
        "JobTitle": "administrative lawyer"
    },
    {
        "value": 4737,
        "NOCCode": "4112",
        "JobTitle": "adviser, law and corporate affairs"
    },
    {
        "value": 4738,
        "NOCCode": "4112",
        "JobTitle": "advisory counsel"
    },
    {
        "value": 4739,
        "NOCCode": "4112",
        "JobTitle": "advocate, legal"
    },
    {
        "value": 4740,
        "NOCCode": "4112",
        "JobTitle": "articling law student"
    },
    {
        "value": 4741,
        "NOCCode": "4112",
        "JobTitle": "assistant corporate counsel"
    },
    {
        "value": 4742,
        "NOCCode": "4112",
        "JobTitle": "assistant Crown attorney"
    },
    {
        "value": 4743,
        "NOCCode": "4112",
        "JobTitle": "assistant general solicitor"
    },
    {
        "value": 4744,
        "NOCCode": "4112",
        "JobTitle": "assistant legal advisor"
    },
    {
        "value": 4745,
        "NOCCode": "4112",
        "JobTitle": "assistant regional counsel"
    },
    {
        "value": 4746,
        "NOCCode": "4112",
        "JobTitle": "associate counsel"
    },
    {
        "value": 4747,
        "NOCCode": "4112",
        "JobTitle": "associate lawyer"
    },
    {
        "value": 4748,
        "NOCCode": "4112",
        "JobTitle": "associate legal advisor"
    },
    {
        "value": 4749,
        "NOCCode": "4112",
        "JobTitle": "associate legal counsel"
    },
    {
        "value": 4750,
        "NOCCode": "4112",
        "JobTitle": "attorney"
    },
    {
        "value": 4751,
        "NOCCode": "4112",
        "JobTitle": "attorney-at-law"
    },
    {
        "value": 4752,
        "NOCCode": "4112",
        "JobTitle": "attorney's assistant chief agent"
    },
    {
        "value": 4753,
        "NOCCode": "4112",
        "JobTitle": "barrister"
    },
    {
        "value": 4754,
        "NOCCode": "4112",
        "JobTitle": "barrister and solicitor"
    },
    {
        "value": 4755,
        "NOCCode": "4112",
        "JobTitle": "city attorney"
    },
    {
        "value": 4756,
        "NOCCode": "4112",
        "JobTitle": "city solicitor"
    },
    {
        "value": 4757,
        "NOCCode": "4112",
        "JobTitle": "civil lawyer"
    },
    {
        "value": 4758,
        "NOCCode": "4112",
        "JobTitle": "claim attorney"
    },
    {
        "value": 4759,
        "NOCCode": "4112",
        "JobTitle": "commercial law notary"
    },
    {
        "value": 4760,
        "NOCCode": "4112",
        "JobTitle": "commercial lawyer"
    },
    {
        "value": 4761,
        "NOCCode": "4112",
        "JobTitle": "contract counsel"
    },
    {
        "value": 4762,
        "NOCCode": "4112",
        "JobTitle": "corporate counsel"
    },
    {
        "value": 4763,
        "NOCCode": "4112",
        "JobTitle": "corporate notary (Quebec)"
    },
    {
        "value": 4764,
        "NOCCode": "4112",
        "JobTitle": "corporation lawyer"
    },
    {
        "value": 4765,
        "NOCCode": "4112",
        "JobTitle": "counsel"
    },
    {
        "value": 4766,
        "NOCCode": "4112",
        "JobTitle": "counsellor-at-law"
    },
    {
        "value": 4767,
        "NOCCode": "4112",
        "JobTitle": "counsellor-lawyer"
    },
    {
        "value": 4768,
        "NOCCode": "4112",
        "JobTitle": "county attorney"
    },
    {
        "value": 4769,
        "NOCCode": "4112",
        "JobTitle": "criminal lawyer"
    },
    {
        "value": 4770,
        "NOCCode": "4112",
        "JobTitle": "Crown attorney"
    },
    {
        "value": 4771,
        "NOCCode": "4112",
        "JobTitle": "Crown corporation counsel"
    },
    {
        "value": 4772,
        "NOCCode": "4112",
        "JobTitle": "Crown counsel"
    },
    {
        "value": 4773,
        "NOCCode": "4112",
        "JobTitle": "Crown prosecutor"
    },
    {
        "value": 4774,
        "NOCCode": "4112",
        "JobTitle": "defence counsel"
    },
    {
        "value": 4775,
        "NOCCode": "4112",
        "JobTitle": "departmental solicitor"
    },
    {
        "value": 4776,
        "NOCCode": "4112",
        "JobTitle": "deputy city solicitor"
    },
    {
        "value": 4777,
        "NOCCode": "4112",
        "JobTitle": "family and estates lawyer"
    },
    {
        "value": 4778,
        "NOCCode": "4112",
        "JobTitle": "general counsel"
    },
    {
        "value": 4779,
        "NOCCode": "4112",
        "JobTitle": "general solicitor"
    },
    {
        "value": 4780,
        "NOCCode": "4112",
        "JobTitle": "immigration lawyer"
    },
    {
        "value": 4781,
        "NOCCode": "4112",
        "JobTitle": "industrial lawyer"
    },
    {
        "value": 4782,
        "NOCCode": "4112",
        "JobTitle": "in-house legal counsel"
    },
    {
        "value": 4783,
        "NOCCode": "4112",
        "JobTitle": "insurance lawyer"
    },
    {
        "value": 4784,
        "NOCCode": "4112",
        "JobTitle": "intellectual-property lawyer"
    },
    {
        "value": 4785,
        "NOCCode": "4112",
        "JobTitle": "labour lawyer"
    },
    {
        "value": 4786,
        "NOCCode": "4112",
        "JobTitle": "law and corporate affairs adviser"
    },
    {
        "value": 4787,
        "NOCCode": "4112",
        "JobTitle": "law partner"
    },
    {
        "value": 4788,
        "NOCCode": "4112",
        "JobTitle": "lawyer"
    },
    {
        "value": 4789,
        "NOCCode": "4112",
        "JobTitle": "lawyer, legal aid"
    },
    {
        "value": 4790,
        "NOCCode": "4112",
        "JobTitle": "legal advisor"
    },
    {
        "value": 4791,
        "NOCCode": "4112",
        "JobTitle": "legal aid lawyer"
    },
    {
        "value": 4792,
        "NOCCode": "4112",
        "JobTitle": "legal attaché"
    },
    {
        "value": 4793,
        "NOCCode": "4112",
        "JobTitle": "legal counsel"
    },
    {
        "value": 4794,
        "NOCCode": "4112",
        "JobTitle": "legal officer"
    },
    {
        "value": 4795,
        "NOCCode": "4112",
        "JobTitle": "legislative adviser"
    },
    {
        "value": 4796,
        "NOCCode": "4112",
        "JobTitle": "legislative counsel"
    },
    {
        "value": 4797,
        "NOCCode": "4112",
        "JobTitle": "litigator"
    },
    {
        "value": 4798,
        "NOCCode": "4112",
        "JobTitle": "municipal solicitor"
    },
    {
        "value": 4799,
        "NOCCode": "4112",
        "JobTitle": "notary (Quebec)"
    },
    {
        "value": 4800,
        "NOCCode": "4112",
        "JobTitle": "notary, Quebec"
    },
    {
        "value": 4801,
        "NOCCode": "4112",
        "JobTitle": "patent counsel"
    },
    {
        "value": 4802,
        "NOCCode": "4112",
        "JobTitle": "patent lawyer"
    },
    {
        "value": 4803,
        "NOCCode": "4112",
        "JobTitle": "patent solicitor"
    },
    {
        "value": 4804,
        "NOCCode": "4112",
        "JobTitle": "prosecuting attorney"
    },
    {
        "value": 4805,
        "NOCCode": "4112",
        "JobTitle": "prosecutor"
    },
    {
        "value": 4806,
        "NOCCode": "4112",
        "JobTitle": "Quebec notary"
    },
    {
        "value": 4807,
        "NOCCode": "4112",
        "JobTitle": "Queen's Counsel"
    },
    {
        "value": 4808,
        "NOCCode": "4112",
        "JobTitle": "real estate lawyer"
    },
    {
        "value": 4809,
        "NOCCode": "4112",
        "JobTitle": "regional attorney"
    },
    {
        "value": 4810,
        "NOCCode": "4112",
        "JobTitle": "regional Crown prosecutor"
    },
    {
        "value": 4811,
        "NOCCode": "4112",
        "JobTitle": "review lawyer"
    },
    {
        "value": 4812,
        "NOCCode": "4112",
        "JobTitle": "solicitor"
    },
    {
        "value": 4813,
        "NOCCode": "4112",
        "JobTitle": "special prosecutor"
    },
    {
        "value": 4814,
        "NOCCode": "4112",
        "JobTitle": "staff counsel"
    },
    {
        "value": 4815,
        "NOCCode": "4112",
        "JobTitle": "staff lawyer"
    },
    {
        "value": 4816,
        "NOCCode": "4112",
        "JobTitle": "staff solicitor"
    },
    {
        "value": 4817,
        "NOCCode": "4112",
        "JobTitle": "student-at-law"
    },
    {
        "value": 4818,
        "NOCCode": "4112",
        "JobTitle": "tariff counsel"
    },
    {
        "value": 4819,
        "NOCCode": "4112",
        "JobTitle": "tax attorney"
    },
    {
        "value": 4820,
        "NOCCode": "4112",
        "JobTitle": "tax lawyer"
    },
    {
        "value": 4821,
        "NOCCode": "4112",
        "JobTitle": "title lawyer"
    },
    {
        "value": 4822,
        "NOCCode": "4112",
        "JobTitle": "trademark lawyer"
    },
    {
        "value": 4823,
        "NOCCode": "4151",
        "JobTitle": "behavioural modification psychologist"
    },
    {
        "value": 4824,
        "NOCCode": "4151",
        "JobTitle": "behavioural psychologist"
    },
    {
        "value": 4825,
        "NOCCode": "4151",
        "JobTitle": "behavioural therapist"
    },
    {
        "value": 4826,
        "NOCCode": "4151",
        "JobTitle": "behaviourist"
    },
    {
        "value": 4827,
        "NOCCode": "4151",
        "JobTitle": "biopsychologist"
    },
    {
        "value": 4828,
        "NOCCode": "4151",
        "JobTitle": "chartered psychologist"
    },
    {
        "value": 4829,
        "NOCCode": "4151",
        "JobTitle": "child psychologist"
    },
    {
        "value": 4830,
        "NOCCode": "4151",
        "JobTitle": "clinical psychologist"
    },
    {
        "value": 4831,
        "NOCCode": "4151",
        "JobTitle": "cognitive psychologist"
    },
    {
        "value": 4832,
        "NOCCode": "4151",
        "JobTitle": "community psychologist"
    },
    {
        "value": 4833,
        "NOCCode": "4151",
        "JobTitle": "counselling psychologist"
    },
    {
        "value": 4834,
        "NOCCode": "4151",
        "JobTitle": "developmental psychologist"
    },
    {
        "value": 4835,
        "NOCCode": "4151",
        "JobTitle": "educational psychologist"
    },
    {
        "value": 4836,
        "NOCCode": "4151",
        "JobTitle": "experimental psychologist"
    },
    {
        "value": 4837,
        "NOCCode": "4151",
        "JobTitle": "forensic psychologist"
    },
    {
        "value": 4838,
        "NOCCode": "4151",
        "JobTitle": "human development psychologist"
    },
    {
        "value": 4839,
        "NOCCode": "4151",
        "JobTitle": "industrial psychologist"
    },
    {
        "value": 4840,
        "NOCCode": "4151",
        "JobTitle": "intern psychologist"
    },
    {
        "value": 4841,
        "NOCCode": "4151",
        "JobTitle": "neuropsychologist"
    },
    {
        "value": 4842,
        "NOCCode": "4151",
        "JobTitle": "organizational psychologist"
    },
    {
        "value": 4843,
        "NOCCode": "4151",
        "JobTitle": "physiological psychologist"
    },
    {
        "value": 4844,
        "NOCCode": "4151",
        "JobTitle": "psychological associate"
    },
    {
        "value": 4845,
        "NOCCode": "4151",
        "JobTitle": "psychologist"
    },
    {
        "value": 4846,
        "NOCCode": "4151",
        "JobTitle": "psychologist – counselling"
    },
    {
        "value": 4847,
        "NOCCode": "4151",
        "JobTitle": "psychologist, school"
    },
    {
        "value": 4848,
        "NOCCode": "4151",
        "JobTitle": "psychotherapist"
    },
    {
        "value": 4849,
        "NOCCode": "4151",
        "JobTitle": "registered psychologist"
    },
    {
        "value": 4850,
        "NOCCode": "4151",
        "JobTitle": "research psychologist"
    },
    {
        "value": 4851,
        "NOCCode": "4151",
        "JobTitle": "school psychologist"
    },
    {
        "value": 4852,
        "NOCCode": "4151",
        "JobTitle": "social psychologist"
    },
    {
        "value": 4853,
        "NOCCode": "4151",
        "JobTitle": "sports psychologist"
    },
    {
        "value": 4854,
        "NOCCode": "4151",
        "JobTitle": "vocational psychologist"
    },
    {
        "value": 4855,
        "NOCCode": "4152",
        "JobTitle": "addiction social worker"
    },
    {
        "value": 4856,
        "NOCCode": "4152",
        "JobTitle": "case management consultant – social work"
    },
    {
        "value": 4857,
        "NOCCode": "4152",
        "JobTitle": "case management social worker"
    },
    {
        "value": 4858,
        "NOCCode": "4152",
        "JobTitle": "case work consultant – social work"
    },
    {
        "value": 4859,
        "NOCCode": "4152",
        "JobTitle": "casework supervisor – social work"
    },
    {
        "value": 4860,
        "NOCCode": "4152",
        "JobTitle": "caseworker – social work"
    },
    {
        "value": 4861,
        "NOCCode": "4152",
        "JobTitle": "certified social worker"
    },
    {
        "value": 4862,
        "NOCCode": "4152",
        "JobTitle": "child welfare social worker"
    },
    {
        "value": 4863,
        "NOCCode": "4152",
        "JobTitle": "co-ordinator of social work"
    },
    {
        "value": 4864,
        "NOCCode": "4152",
        "JobTitle": "family social worker"
    },
    {
        "value": 4865,
        "NOCCode": "4152",
        "JobTitle": "geriatric social worker"
    },
    {
        "value": 4866,
        "NOCCode": "4152",
        "JobTitle": "group social worker"
    },
    {
        "value": 4867,
        "NOCCode": "4152",
        "JobTitle": "medical social worker"
    },
    {
        "value": 4868,
        "NOCCode": "4152",
        "JobTitle": "psychiatric social worker"
    },
    {
        "value": 4869,
        "NOCCode": "4152",
        "JobTitle": "registered social worker"
    },
    {
        "value": 4870,
        "NOCCode": "4152",
        "JobTitle": "school social worker"
    },
    {
        "value": 4871,
        "NOCCode": "4152",
        "JobTitle": "social casework consultant"
    },
    {
        "value": 4872,
        "NOCCode": "4152",
        "JobTitle": "social work co-ordinator"
    },
    {
        "value": 4873,
        "NOCCode": "4152",
        "JobTitle": "social work officer"
    },
    {
        "value": 4874,
        "NOCCode": "4152",
        "JobTitle": "social work supervisor"
    },
    {
        "value": 4875,
        "NOCCode": "4152",
        "JobTitle": "social worker"
    },
    {
        "value": 4876,
        "NOCCode": "4152",
        "JobTitle": "social worker, groups"
    },
    {
        "value": 4877,
        "NOCCode": "4152",
        "JobTitle": "social workers supervisor"
    },
    {
        "value": 4878,
        "NOCCode": "4153",
        "JobTitle": "addictions counsellor"
    },
    {
        "value": 4879,
        "NOCCode": "4153",
        "JobTitle": "alcohol addiction counsellor"
    },
    {
        "value": 4880,
        "NOCCode": "4153",
        "JobTitle": "bereavement counsellor"
    },
    {
        "value": 4881,
        "NOCCode": "4153",
        "JobTitle": "child and family counsellor"
    },
    {
        "value": 4882,
        "NOCCode": "4153",
        "JobTitle": "child and youth counsellor"
    },
    {
        "value": 4883,
        "NOCCode": "4153",
        "JobTitle": "children's counsellor"
    },
    {
        "value": 4884,
        "NOCCode": "4153",
        "JobTitle": "clinical counsellor"
    },
    {
        "value": 4885,
        "NOCCode": "4153",
        "JobTitle": "community mental health counsellor"
    },
    {
        "value": 4886,
        "NOCCode": "4153",
        "JobTitle": "counselling program supervisor"
    },
    {
        "value": 4887,
        "NOCCode": "4153",
        "JobTitle": "counsellor for persons who are intellectually impaired"
    },
    {
        "value": 4888,
        "NOCCode": "4153",
        "JobTitle": "counsellor, child and youth"
    },
    {
        "value": 4889,
        "NOCCode": "4153",
        "JobTitle": "counsellor, marriage"
    },
    {
        "value": 4890,
        "NOCCode": "4153",
        "JobTitle": "counsellor, preretirement"
    },
    {
        "value": 4891,
        "NOCCode": "4153",
        "JobTitle": "counsellor, rehabilitation"
    },
    {
        "value": 4892,
        "NOCCode": "4153",
        "JobTitle": "couples therapist"
    },
    {
        "value": 4893,
        "NOCCode": "4153",
        "JobTitle": "crisis counsellor"
    },
    {
        "value": 4894,
        "NOCCode": "4153",
        "JobTitle": "drug addiction counsellor"
    },
    {
        "value": 4895,
        "NOCCode": "4153",
        "JobTitle": "eating disorder therapist"
    },
    {
        "value": 4896,
        "NOCCode": "4153",
        "JobTitle": "family counsellor"
    },
    {
        "value": 4897,
        "NOCCode": "4153",
        "JobTitle": "family planning counsellor"
    },
    {
        "value": 4898,
        "NOCCode": "4153",
        "JobTitle": "family therapist"
    },
    {
        "value": 4899,
        "NOCCode": "4153",
        "JobTitle": "family therapist (FT)"
    },
    {
        "value": 4900,
        "NOCCode": "4153",
        "JobTitle": "gambling addictions counsellor"
    },
    {
        "value": 4901,
        "NOCCode": "4153",
        "JobTitle": "gambling addictions therapist"
    },
    {
        "value": 4902,
        "NOCCode": "4153",
        "JobTitle": "genetic counsellor"
    },
    {
        "value": 4903,
        "NOCCode": "4153",
        "JobTitle": "grief counsellor"
    },
    {
        "value": 4904,
        "NOCCode": "4153",
        "JobTitle": "group home counsellor"
    },
    {
        "value": 4905,
        "NOCCode": "4153",
        "JobTitle": "human relations officer – family and children's services"
    },
    {
        "value": 4906,
        "NOCCode": "4153",
        "JobTitle": "human relations officer – family therapy"
    },
    {
        "value": 4907,
        "NOCCode": "4153",
        "JobTitle": "marital counsellor"
    },
    {
        "value": 4908,
        "NOCCode": "4153",
        "JobTitle": "marriage and family therapist"
    },
    {
        "value": 4909,
        "NOCCode": "4153",
        "JobTitle": "marriage and family therapist (MFT)"
    },
    {
        "value": 4910,
        "NOCCode": "4153",
        "JobTitle": "marriage consultant"
    },
    {
        "value": 4911,
        "NOCCode": "4153",
        "JobTitle": "marriage counsellor"
    },
    {
        "value": 4912,
        "NOCCode": "4153",
        "JobTitle": "marriage therapist"
    },
    {
        "value": 4913,
        "NOCCode": "4153",
        "JobTitle": "marriage therapist (MT)"
    },
    {
        "value": 4914,
        "NOCCode": "4153",
        "JobTitle": "mental health counsellor"
    },
    {
        "value": 4915,
        "NOCCode": "4153",
        "JobTitle": "preretirement counsellor"
    },
    {
        "value": 4916,
        "NOCCode": "4153",
        "JobTitle": "registered clinical counsellor"
    },
    {
        "value": 4917,
        "NOCCode": "4153",
        "JobTitle": "registered family therapist"
    },
    {
        "value": 4918,
        "NOCCode": "4153",
        "JobTitle": "registered marriage and family therapist"
    },
    {
        "value": 4919,
        "NOCCode": "4153",
        "JobTitle": "registered marriage therapist"
    },
    {
        "value": 4920,
        "NOCCode": "4153",
        "JobTitle": "rehabilitation counsellor"
    },
    {
        "value": 4921,
        "NOCCode": "4153",
        "JobTitle": "sex therapist"
    },
    {
        "value": 4922,
        "NOCCode": "4153",
        "JobTitle": "sexologist"
    },
    {
        "value": 4923,
        "NOCCode": "4153",
        "JobTitle": "special needs counsellor"
    },
    {
        "value": 4924,
        "NOCCode": "4153",
        "JobTitle": "supervisor, counselling programs"
    },
    {
        "value": 4925,
        "NOCCode": "4153",
        "JobTitle": "therapist, marriage"
    },
    {
        "value": 4926,
        "NOCCode": "4153",
        "JobTitle": "vocational rehabilitation consultant"
    },
    {
        "value": 4927,
        "NOCCode": "4153",
        "JobTitle": "vocational rehabilitation counsellor"
    },
    {
        "value": 4928,
        "NOCCode": "4153",
        "JobTitle": "welfare organization counsellor"
    },
    {
        "value": 4929,
        "NOCCode": "4153",
        "JobTitle": "zootherapist"
    },
    {
        "value": 4930,
        "NOCCode": "4154",
        "JobTitle": "archbishop"
    },
    {
        "value": 4931,
        "NOCCode": "4154",
        "JobTitle": "archdeacon"
    },
    {
        "value": 4932,
        "NOCCode": "4154",
        "JobTitle": "assistant minister – religion"
    },
    {
        "value": 4933,
        "NOCCode": "4154",
        "JobTitle": "bishop"
    },
    {
        "value": 4934,
        "NOCCode": "4154",
        "JobTitle": "cardinal"
    },
    {
        "value": 4935,
        "NOCCode": "4154",
        "JobTitle": "chancellor – religion"
    },
    {
        "value": 4936,
        "NOCCode": "4154",
        "JobTitle": "chaplain"
    },
    {
        "value": 4937,
        "NOCCode": "4154",
        "JobTitle": "clergyman/woman"
    },
    {
        "value": 4938,
        "NOCCode": "4154",
        "JobTitle": "curate"
    },
    {
        "value": 4939,
        "NOCCode": "4154",
        "JobTitle": "evangelist"
    },
    {
        "value": 4940,
        "NOCCode": "4154",
        "JobTitle": "granthi"
    },
    {
        "value": 4941,
        "NOCCode": "4154",
        "JobTitle": "imam"
    },
    {
        "value": 4942,
        "NOCCode": "4154",
        "JobTitle": "minister"
    },
    {
        "value": 4943,
        "NOCCode": "4154",
        "JobTitle": "minister – religion"
    },
    {
        "value": 4944,
        "NOCCode": "4154",
        "JobTitle": "moderator – religion"
    },
    {
        "value": 4945,
        "NOCCode": "4154",
        "JobTitle": "officiating clergyman/woman"
    },
    {
        "value": 4946,
        "NOCCode": "4154",
        "JobTitle": "parish priest"
    },
    {
        "value": 4947,
        "NOCCode": "4154",
        "JobTitle": "pastor"
    },
    {
        "value": 4948,
        "NOCCode": "4154",
        "JobTitle": "preacher"
    },
    {
        "value": 4949,
        "NOCCode": "4154",
        "JobTitle": "presbyter"
    },
    {
        "value": 4950,
        "NOCCode": "4154",
        "JobTitle": "priest"
    },
    {
        "value": 4951,
        "NOCCode": "4154",
        "JobTitle": "rabbi"
    },
    {
        "value": 4952,
        "NOCCode": "4154",
        "JobTitle": "reverend"
    },
    {
        "value": 4953,
        "NOCCode": "4154",
        "JobTitle": "Salvation Army commissioned officer"
    },
    {
        "value": 4954,
        "NOCCode": "4154",
        "JobTitle": "television evangelist"
    },
    {
        "value": 4955,
        "NOCCode": "4155",
        "JobTitle": "case management worker – corrections"
    },
    {
        "value": 4956,
        "NOCCode": "4155",
        "JobTitle": "case manager – corrections"
    },
    {
        "value": 4957,
        "NOCCode": "4155",
        "JobTitle": "classification counsellor – corrections"
    },
    {
        "value": 4958,
        "NOCCode": "4155",
        "JobTitle": "classification officer, correctional institution"
    },
    {
        "value": 4959,
        "NOCCode": "4155",
        "JobTitle": "clemency officer – corrections"
    },
    {
        "value": 4960,
        "NOCCode": "4155",
        "JobTitle": "community case manager officer – corrections"
    },
    {
        "value": 4961,
        "NOCCode": "4155",
        "JobTitle": "correctional centre caseworker"
    },
    {
        "value": 4962,
        "NOCCode": "4155",
        "JobTitle": "parole officer"
    },
    {
        "value": 4963,
        "NOCCode": "4155",
        "JobTitle": "parole supervisor"
    },
    {
        "value": 4964,
        "NOCCode": "4155",
        "JobTitle": "probation and parole officer"
    },
    {
        "value": 4965,
        "NOCCode": "4155",
        "JobTitle": "probation officer"
    },
    {
        "value": 4966,
        "NOCCode": "4155",
        "JobTitle": "social assistance program officer – corrections"
    },
    {
        "value": 4967,
        "NOCCode": "4156",
        "JobTitle": "Aboriginal employment officer"
    },
    {
        "value": 4968,
        "NOCCode": "4156",
        "JobTitle": "Aboriginal employment worker"
    },
    {
        "value": 4969,
        "NOCCode": "4156",
        "JobTitle": "career coach"
    },
    {
        "value": 4970,
        "NOCCode": "4156",
        "JobTitle": "career counsellor – except education"
    },
    {
        "value": 4971,
        "NOCCode": "4156",
        "JobTitle": "career development counsellor"
    },
    {
        "value": 4972,
        "NOCCode": "4156",
        "JobTitle": "career development facilitator"
    },
    {
        "value": 4973,
        "NOCCode": "4156",
        "JobTitle": "career development practitioner"
    },
    {
        "value": 4974,
        "NOCCode": "4156",
        "JobTitle": "career group facilitator"
    },
    {
        "value": 4975,
        "NOCCode": "4156",
        "JobTitle": "career resource centre co-ordinator"
    },
    {
        "value": 4976,
        "NOCCode": "4156",
        "JobTitle": "counsellor, employment"
    },
    {
        "value": 4977,
        "NOCCode": "4156",
        "JobTitle": "counsellor, employment – government services"
    },
    {
        "value": 4978,
        "NOCCode": "4156",
        "JobTitle": "counsellor, employment group"
    },
    {
        "value": 4979,
        "NOCCode": "4156",
        "JobTitle": "counsellor, job"
    },
    {
        "value": 4980,
        "NOCCode": "4156",
        "JobTitle": "counsellor, job – government services"
    },
    {
        "value": 4981,
        "NOCCode": "4156",
        "JobTitle": "counsellor, outplacement"
    },
    {
        "value": 4982,
        "NOCCode": "4156",
        "JobTitle": "counsellor, relocation"
    },
    {
        "value": 4983,
        "NOCCode": "4156",
        "JobTitle": "employment counsellor"
    },
    {
        "value": 4984,
        "NOCCode": "4156",
        "JobTitle": "employment counsellor – government services"
    },
    {
        "value": 4985,
        "NOCCode": "4156",
        "JobTitle": "employment group counsellor"
    },
    {
        "value": 4986,
        "NOCCode": "4156",
        "JobTitle": "employment outreach counsellor"
    },
    {
        "value": 4987,
        "NOCCode": "4156",
        "JobTitle": "employment outreach program co-ordinator"
    },
    {
        "value": 4988,
        "NOCCode": "4156",
        "JobTitle": "employment outreach program counsellor"
    },
    {
        "value": 4989,
        "NOCCode": "4156",
        "JobTitle": "employment services assessment officer"
    },
    {
        "value": 4990,
        "NOCCode": "4156",
        "JobTitle": "employment services officer"
    },
    {
        "value": 4991,
        "NOCCode": "4156",
        "JobTitle": "group counsellor, employment services"
    },
    {
        "value": 4992,
        "NOCCode": "4156",
        "JobTitle": "job counsellor"
    },
    {
        "value": 4993,
        "NOCCode": "4156",
        "JobTitle": "job counsellor – government services"
    },
    {
        "value": 4994,
        "NOCCode": "4156",
        "JobTitle": "job search trainer"
    },
    {
        "value": 4995,
        "NOCCode": "4156",
        "JobTitle": "labour market information consultant"
    },
    {
        "value": 4996,
        "NOCCode": "4156",
        "JobTitle": "outplacement consultant"
    },
    {
        "value": 4997,
        "NOCCode": "4156",
        "JobTitle": "outplacement counsellor"
    },
    {
        "value": 4998,
        "NOCCode": "4156",
        "JobTitle": "outreach counsellor, employment"
    },
    {
        "value": 4999,
        "NOCCode": "4156",
        "JobTitle": "outreach program co-ordinator, employment"
    },
    {
        "value": 5000,
        "NOCCode": "4156",
        "JobTitle": "outreach program counsellor, employment"
    },
    {
        "value": 5001,
        "NOCCode": "4156",
        "JobTitle": "relocation consultant"
    },
    {
        "value": 5002,
        "NOCCode": "4156",
        "JobTitle": "workforce adjustment officer"
    },
    {
        "value": 5003,
        "NOCCode": "4161",
        "JobTitle": "agricultural association analyst"
    },
    {
        "value": 5004,
        "NOCCode": "4161",
        "JobTitle": "agricultural issues lobbyist"
    },
    {
        "value": 5005,
        "NOCCode": "4161",
        "JobTitle": "analyst, agricultural association"
    },
    {
        "value": 5006,
        "NOCCode": "4161",
        "JobTitle": "certified ergonomist"
    },
    {
        "value": 5007,
        "NOCCode": "4161",
        "JobTitle": "certified industrial hygienist"
    },
    {
        "value": 5008,
        "NOCCode": "4161",
        "JobTitle": "communications policy researcher"
    },
    {
        "value": 5009,
        "NOCCode": "4161",
        "JobTitle": "consultant, natural and applied sciences"
    },
    {
        "value": 5010,
        "NOCCode": "4161",
        "JobTitle": "co-ordinator, municipal recycling program"
    },
    {
        "value": 5011,
        "NOCCode": "4161",
        "JobTitle": "co-ordinator, waste management program"
    },
    {
        "value": 5012,
        "NOCCode": "4161",
        "JobTitle": "disaster emergency response planner"
    },
    {
        "value": 5013,
        "NOCCode": "4161",
        "JobTitle": "emergency management analyst"
    },
    {
        "value": 5014,
        "NOCCode": "4161",
        "JobTitle": "emergency preparedness planner"
    },
    {
        "value": 5015,
        "NOCCode": "4161",
        "JobTitle": "energy policy analyst"
    },
    {
        "value": 5016,
        "NOCCode": "4161",
        "JobTitle": "energy program officer"
    },
    {
        "value": 5017,
        "NOCCode": "4161",
        "JobTitle": "environmental advisor (except engineer)"
    },
    {
        "value": 5018,
        "NOCCode": "4161",
        "JobTitle": "environmental consultant (except engineer)"
    },
    {
        "value": 5019,
        "NOCCode": "4161",
        "JobTitle": "environmental impact analyst"
    },
    {
        "value": 5020,
        "NOCCode": "4161",
        "JobTitle": "environmental issues lobbyist"
    },
    {
        "value": 5021,
        "NOCCode": "4161",
        "JobTitle": "environmental lobbyist"
    },
    {
        "value": 5022,
        "NOCCode": "4161",
        "JobTitle": "environmental program co-ordinator"
    },
    {
        "value": 5023,
        "NOCCode": "4161",
        "JobTitle": "environmental program development supervisor"
    },
    {
        "value": 5024,
        "NOCCode": "4161",
        "JobTitle": "environmental program manager"
    },
    {
        "value": 5025,
        "NOCCode": "4161",
        "JobTitle": "ergonomics consultant"
    },
    {
        "value": 5026,
        "NOCCode": "4161",
        "JobTitle": "ergonomics specialist"
    },
    {
        "value": 5027,
        "NOCCode": "4161",
        "JobTitle": "ergonomist"
    },
    {
        "value": 5028,
        "NOCCode": "4161",
        "JobTitle": "fisheries analyst"
    },
    {
        "value": 5029,
        "NOCCode": "4161",
        "JobTitle": "fisheries program officer"
    },
    {
        "value": 5030,
        "NOCCode": "4161",
        "JobTitle": "human factors engineer"
    },
    {
        "value": 5031,
        "NOCCode": "4161",
        "JobTitle": "human factors specialist (except engineer)"
    },
    {
        "value": 5032,
        "NOCCode": "4161",
        "JobTitle": "industrial hygienist"
    },
    {
        "value": 5033,
        "NOCCode": "4161",
        "JobTitle": "industrial waste reduction program co-ordinator"
    },
    {
        "value": 5034,
        "NOCCode": "4161",
        "JobTitle": "interface ergonomist"
    },
    {
        "value": 5035,
        "NOCCode": "4161",
        "JobTitle": "lobbyist, agricultural issues"
    },
    {
        "value": 5036,
        "NOCCode": "4161",
        "JobTitle": "lobbyist, environmental issues"
    },
    {
        "value": 5037,
        "NOCCode": "4161",
        "JobTitle": "multimedia ergonomist"
    },
    {
        "value": 5038,
        "NOCCode": "4161",
        "JobTitle": "municipal recycling program co-ordinator"
    },
    {
        "value": 5039,
        "NOCCode": "4161",
        "JobTitle": "natural and applied sciences consultant"
    },
    {
        "value": 5040,
        "NOCCode": "4161",
        "JobTitle": "natural and applied sciences policy advisor"
    },
    {
        "value": 5041,
        "NOCCode": "4161",
        "JobTitle": "natural and applied sciences policy analyst"
    },
    {
        "value": 5042,
        "NOCCode": "4161",
        "JobTitle": "natural and applied sciences program officer"
    },
    {
        "value": 5043,
        "NOCCode": "4161",
        "JobTitle": "natural and applied sciences researcher"
    },
    {
        "value": 5044,
        "NOCCode": "4161",
        "JobTitle": "natural resources planner"
    },
    {
        "value": 5045,
        "NOCCode": "4161",
        "JobTitle": "natural resources policy analyst"
    },
    {
        "value": 5046,
        "NOCCode": "4161",
        "JobTitle": "occupational hygiene officer"
    },
    {
        "value": 5047,
        "NOCCode": "4161",
        "JobTitle": "occupational hygienist"
    },
    {
        "value": 5048,
        "NOCCode": "4161",
        "JobTitle": "patent agent, registered"
    },
    {
        "value": 5049,
        "NOCCode": "4161",
        "JobTitle": "policy analyst, natural and applied sciences"
    },
    {
        "value": 5050,
        "NOCCode": "4161",
        "JobTitle": "program co-ordinator – environmental organization"
    },
    {
        "value": 5051,
        "NOCCode": "4161",
        "JobTitle": "program co-ordinator, solid waste"
    },
    {
        "value": 5052,
        "NOCCode": "4161",
        "JobTitle": "program manager, solid waste"
    },
    {
        "value": 5053,
        "NOCCode": "4161",
        "JobTitle": "program officer, energy"
    },
    {
        "value": 5054,
        "NOCCode": "4161",
        "JobTitle": "program officer, fisheries"
    },
    {
        "value": 5055,
        "NOCCode": "4161",
        "JobTitle": "program officer, natural and applied sciences"
    },
    {
        "value": 5056,
        "NOCCode": "4161",
        "JobTitle": "recycling co-ordinator – residuals management"
    },
    {
        "value": 5057,
        "NOCCode": "4161",
        "JobTitle": "recycling program co-ordinator"
    },
    {
        "value": 5058,
        "NOCCode": "4161",
        "JobTitle": "regional recycling and waste reduction program co-ordinator"
    },
    {
        "value": 5059,
        "NOCCode": "4161",
        "JobTitle": "registered occupational hygienist"
    },
    {
        "value": 5060,
        "NOCCode": "4161",
        "JobTitle": "registered patent agent"
    },
    {
        "value": 5061,
        "NOCCode": "4161",
        "JobTitle": "researcher, natural and applied sciences"
    },
    {
        "value": 5062,
        "NOCCode": "4161",
        "JobTitle": "scientific consultant"
    },
    {
        "value": 5063,
        "NOCCode": "4161",
        "JobTitle": "solid waste program co-ordinator"
    },
    {
        "value": 5064,
        "NOCCode": "4161",
        "JobTitle": "solid waste program manager"
    },
    {
        "value": 5065,
        "NOCCode": "4161",
        "JobTitle": "supervisor, environmental program development"
    },
    {
        "value": 5066,
        "NOCCode": "4161",
        "JobTitle": "technology transfer officer"
    },
    {
        "value": 5067,
        "NOCCode": "4161",
        "JobTitle": "transportation program analyst"
    },
    {
        "value": 5068,
        "NOCCode": "4161",
        "JobTitle": "transportation safety analyst"
    },
    {
        "value": 5069,
        "NOCCode": "4161",
        "JobTitle": "waste diversion consultant"
    },
    {
        "value": 5070,
        "NOCCode": "4161",
        "JobTitle": "waste diversion program co-ordinator"
    },
    {
        "value": 5071,
        "NOCCode": "4161",
        "JobTitle": "waste diversion program manager"
    },
    {
        "value": 5072,
        "NOCCode": "4161",
        "JobTitle": "waste management program co-ordinator"
    },
    {
        "value": 5073,
        "NOCCode": "4161",
        "JobTitle": "waste reduction and recycling officer"
    },
    {
        "value": 5074,
        "NOCCode": "4161",
        "JobTitle": "waste reduction education program officer"
    },
    {
        "value": 5075,
        "NOCCode": "4161",
        "JobTitle": "waste reduction program co-ordinator"
    },
    {
        "value": 5076,
        "NOCCode": "4161",
        "JobTitle": "wind energy analyst"
    },
    {
        "value": 5077,
        "NOCCode": "4161",
        "JobTitle": "work site analyst"
    },
    {
        "value": 5078,
        "NOCCode": "4161",
        "JobTitle": "work site auditor"
    },
    {
        "value": 5079,
        "NOCCode": "4162",
        "JobTitle": "agricultural economist"
    },
    {
        "value": 5080,
        "NOCCode": "4162",
        "JobTitle": "analyst, economic"
    },
    {
        "value": 5081,
        "NOCCode": "4162",
        "JobTitle": "analyst, fiscal economics"
    },
    {
        "value": 5082,
        "NOCCode": "4162",
        "JobTitle": "business economist"
    },
    {
        "value": 5083,
        "NOCCode": "4162",
        "JobTitle": "development economist"
    },
    {
        "value": 5084,
        "NOCCode": "4162",
        "JobTitle": "econometrician"
    },
    {
        "value": 5085,
        "NOCCode": "4162",
        "JobTitle": "economic advisor"
    },
    {
        "value": 5086,
        "NOCCode": "4162",
        "JobTitle": "economic analyst"
    },
    {
        "value": 5087,
        "NOCCode": "4162",
        "JobTitle": "economic consultant"
    },
    {
        "value": 5088,
        "NOCCode": "4162",
        "JobTitle": "economic policy adviser"
    },
    {
        "value": 5089,
        "NOCCode": "4162",
        "JobTitle": "economic policy analyst"
    },
    {
        "value": 5090,
        "NOCCode": "4162",
        "JobTitle": "economic policy researcher"
    },
    {
        "value": 5091,
        "NOCCode": "4162",
        "JobTitle": "economic research group supervisor"
    },
    {
        "value": 5092,
        "NOCCode": "4162",
        "JobTitle": "economist"
    },
    {
        "value": 5093,
        "NOCCode": "4162",
        "JobTitle": "economist, natural resources"
    },
    {
        "value": 5094,
        "NOCCode": "4162",
        "JobTitle": "energy economist"
    },
    {
        "value": 5095,
        "NOCCode": "4162",
        "JobTitle": "farm economist"
    },
    {
        "value": 5096,
        "NOCCode": "4162",
        "JobTitle": "financial economist"
    },
    {
        "value": 5097,
        "NOCCode": "4162",
        "JobTitle": "financial market economist"
    },
    {
        "value": 5098,
        "NOCCode": "4162",
        "JobTitle": "fiscal economics analyst"
    },
    {
        "value": 5099,
        "NOCCode": "4162",
        "JobTitle": "forest economist"
    },
    {
        "value": 5100,
        "NOCCode": "4162",
        "JobTitle": "forest resources economist"
    },
    {
        "value": 5101,
        "NOCCode": "4162",
        "JobTitle": "general economist"
    },
    {
        "value": 5102,
        "NOCCode": "4162",
        "JobTitle": "health economist"
    },
    {
        "value": 5103,
        "NOCCode": "4162",
        "JobTitle": "human resources economist"
    },
    {
        "value": 5104,
        "NOCCode": "4162",
        "JobTitle": "industrial economist"
    },
    {
        "value": 5105,
        "NOCCode": "4162",
        "JobTitle": "industrial relations economist"
    },
    {
        "value": 5106,
        "NOCCode": "4162",
        "JobTitle": "industrial trade economist"
    },
    {
        "value": 5107,
        "NOCCode": "4162",
        "JobTitle": "international trade economist"
    },
    {
        "value": 5108,
        "NOCCode": "4162",
        "JobTitle": "investment economist"
    },
    {
        "value": 5109,
        "NOCCode": "4162",
        "JobTitle": "labour economist"
    },
    {
        "value": 5110,
        "NOCCode": "4162",
        "JobTitle": "labour market information analyst"
    },
    {
        "value": 5111,
        "NOCCode": "4162",
        "JobTitle": "land use economist"
    },
    {
        "value": 5112,
        "NOCCode": "4162",
        "JobTitle": "mathematical economist"
    },
    {
        "value": 5113,
        "NOCCode": "4162",
        "JobTitle": "natural resources economist"
    },
    {
        "value": 5114,
        "NOCCode": "4162",
        "JobTitle": "price economist"
    },
    {
        "value": 5115,
        "NOCCode": "4162",
        "JobTitle": "regional economist"
    },
    {
        "value": 5116,
        "NOCCode": "4162",
        "JobTitle": "resource economist"
    },
    {
        "value": 5117,
        "NOCCode": "4162",
        "JobTitle": "risk management analyst"
    },
    {
        "value": 5118,
        "NOCCode": "4162",
        "JobTitle": "salary analyst – economic policy"
    },
    {
        "value": 5119,
        "NOCCode": "4162",
        "JobTitle": "social economist"
    },
    {
        "value": 5120,
        "NOCCode": "4162",
        "JobTitle": "supervisor, economic research group"
    },
    {
        "value": 5121,
        "NOCCode": "4162",
        "JobTitle": "tax economist"
    },
    {
        "value": 5122,
        "NOCCode": "4162",
        "JobTitle": "territorial resources economist"
    },
    {
        "value": 5123,
        "NOCCode": "4162",
        "JobTitle": "trade analyst"
    },
    {
        "value": 5124,
        "NOCCode": "4162",
        "JobTitle": "trade economist"
    },
    {
        "value": 5125,
        "NOCCode": "4162",
        "JobTitle": "transport economist"
    },
    {
        "value": 5126,
        "NOCCode": "4162",
        "JobTitle": "welfare economist"
    },
    {
        "value": 5127,
        "NOCCode": "4163",
        "JobTitle": "Aboriginal economic development officer"
    },
    {
        "value": 5128,
        "NOCCode": "4163",
        "JobTitle": "advertising analyst"
    },
    {
        "value": 5129,
        "NOCCode": "4163",
        "JobTitle": "agriculture and food sector development consultant"
    },
    {
        "value": 5130,
        "NOCCode": "4163",
        "JobTitle": "agriculture and food sector marketing consultant"
    },
    {
        "value": 5131,
        "NOCCode": "4163",
        "JobTitle": "agri-food sector development consultant"
    },
    {
        "value": 5132,
        "NOCCode": "4163",
        "JobTitle": "agri-food sector marketing consultant"
    },
    {
        "value": 5133,
        "NOCCode": "4163",
        "JobTitle": "analyst – market research"
    },
    {
        "value": 5134,
        "NOCCode": "4163",
        "JobTitle": "analyst, manufacturers' association"
    },
    {
        "value": 5135,
        "NOCCode": "4163",
        "JobTitle": "analyst, marketing"
    },
    {
        "value": 5136,
        "NOCCode": "4163",
        "JobTitle": "area development officer – government"
    },
    {
        "value": 5137,
        "NOCCode": "4163",
        "JobTitle": "business development officer"
    },
    {
        "value": 5138,
        "NOCCode": "4163",
        "JobTitle": "business issues lobbyist"
    },
    {
        "value": 5139,
        "NOCCode": "4163",
        "JobTitle": "commerce officer"
    },
    {
        "value": 5140,
        "NOCCode": "4163",
        "JobTitle": "commercial development officer – government"
    },
    {
        "value": 5141,
        "NOCCode": "4163",
        "JobTitle": "commercial research analyst"
    },
    {
        "value": 5142,
        "NOCCode": "4163",
        "JobTitle": "community development consultant"
    },
    {
        "value": 5143,
        "NOCCode": "4163",
        "JobTitle": "community economic development consultant"
    },
    {
        "value": 5144,
        "NOCCode": "4163",
        "JobTitle": "community economic development officer"
    },
    {
        "value": 5145,
        "NOCCode": "4163",
        "JobTitle": "consultant, marketing – market research"
    },
    {
        "value": 5146,
        "NOCCode": "4163",
        "JobTitle": "consultant, tourism – government"
    },
    {
        "value": 5147,
        "NOCCode": "4163",
        "JobTitle": "co-ordinator, tourism services – government"
    },
    {
        "value": 5148,
        "NOCCode": "4163",
        "JobTitle": "economic development consultant"
    },
    {
        "value": 5149,
        "NOCCode": "4163",
        "JobTitle": "economic development officer"
    },
    {
        "value": 5150,
        "NOCCode": "4163",
        "JobTitle": "economic development officer – Aboriginal communities"
    },
    {
        "value": 5151,
        "NOCCode": "4163",
        "JobTitle": "industrial commissioner"
    },
    {
        "value": 5152,
        "NOCCode": "4163",
        "JobTitle": "industrial development officer"
    },
    {
        "value": 5153,
        "NOCCode": "4163",
        "JobTitle": "industrial development representative"
    },
    {
        "value": 5154,
        "NOCCode": "4163",
        "JobTitle": "international development and assistance researcher"
    },
    {
        "value": 5155,
        "NOCCode": "4163",
        "JobTitle": "international marketing specialist"
    },
    {
        "value": 5156,
        "NOCCode": "4163",
        "JobTitle": "lobbyist, business issues"
    },
    {
        "value": 5157,
        "NOCCode": "4163",
        "JobTitle": "manufacturers' association analyst"
    },
    {
        "value": 5158,
        "NOCCode": "4163",
        "JobTitle": "market analyst – non-financial"
    },
    {
        "value": 5159,
        "NOCCode": "4163",
        "JobTitle": "market research analyst"
    },
    {
        "value": 5160,
        "NOCCode": "4163",
        "JobTitle": "market research specialist"
    },
    {
        "value": 5161,
        "NOCCode": "4163",
        "JobTitle": "market researcher"
    },
    {
        "value": 5162,
        "NOCCode": "4163",
        "JobTitle": "marketing analysis specialist"
    },
    {
        "value": 5163,
        "NOCCode": "4163",
        "JobTitle": "marketing analyst"
    },
    {
        "value": 5164,
        "NOCCode": "4163",
        "JobTitle": "marketing analyst – non-financial"
    },
    {
        "value": 5165,
        "NOCCode": "4163",
        "JobTitle": "marketing consultant – market research"
    },
    {
        "value": 5166,
        "NOCCode": "4163",
        "JobTitle": "marketing consultant – wholesale"
    },
    {
        "value": 5167,
        "NOCCode": "4163",
        "JobTitle": "marketing project officer"
    },
    {
        "value": 5168,
        "NOCCode": "4163",
        "JobTitle": "marketing research specialist"
    },
    {
        "value": 5169,
        "NOCCode": "4163",
        "JobTitle": "marketing researcher"
    },
    {
        "value": 5170,
        "NOCCode": "4163",
        "JobTitle": "mining industry development consultant"
    },
    {
        "value": 5171,
        "NOCCode": "4163",
        "JobTitle": "officer, commercial development – government"
    },
    {
        "value": 5172,
        "NOCCode": "4163",
        "JobTitle": "officer, economic development – government"
    },
    {
        "value": 5173,
        "NOCCode": "4163",
        "JobTitle": "officer, industrial development – government"
    },
    {
        "value": 5174,
        "NOCCode": "4163",
        "JobTitle": "officer, regional economic development agency"
    },
    {
        "value": 5175,
        "NOCCode": "4163",
        "JobTitle": "officer, tourism development – government"
    },
    {
        "value": 5176,
        "NOCCode": "4163",
        "JobTitle": "program delivery officer – business development"
    },
    {
        "value": 5177,
        "NOCCode": "4163",
        "JobTitle": "program officer – international development agency"
    },
    {
        "value": 5178,
        "NOCCode": "4163",
        "JobTitle": "project officer, marketing"
    },
    {
        "value": 5179,
        "NOCCode": "4163",
        "JobTitle": "regional development analyst"
    },
    {
        "value": 5180,
        "NOCCode": "4163",
        "JobTitle": "regional development officer"
    },
    {
        "value": 5181,
        "NOCCode": "4163",
        "JobTitle": "regional economic development agency officer"
    },
    {
        "value": 5182,
        "NOCCode": "4163",
        "JobTitle": "regional industrial development officer"
    },
    {
        "value": 5183,
        "NOCCode": "4163",
        "JobTitle": "regional officer, industrial development"
    },
    {
        "value": 5184,
        "NOCCode": "4163",
        "JobTitle": "researcher, market"
    },
    {
        "value": 5185,
        "NOCCode": "4163",
        "JobTitle": "researcher, marketing"
    },
    {
        "value": 5186,
        "NOCCode": "4163",
        "JobTitle": "rural development officer"
    },
    {
        "value": 5187,
        "NOCCode": "4163",
        "JobTitle": "small business counsellor"
    },
    {
        "value": 5188,
        "NOCCode": "4163",
        "JobTitle": "tourism consultant – government"
    },
    {
        "value": 5189,
        "NOCCode": "4163",
        "JobTitle": "tourism development officer"
    },
    {
        "value": 5190,
        "NOCCode": "4163",
        "JobTitle": "tourism development officer – government"
    },
    {
        "value": 5191,
        "NOCCode": "4163",
        "JobTitle": "tourism industry consultant"
    },
    {
        "value": 5192,
        "NOCCode": "4163",
        "JobTitle": "tourism industry specialist"
    },
    {
        "value": 5193,
        "NOCCode": "4163",
        "JobTitle": "tourism services co-ordinator – government"
    },
    {
        "value": 5194,
        "NOCCode": "4163",
        "JobTitle": "trade promotion officer"
    },
    {
        "value": 5195,
        "NOCCode": "4163",
        "JobTitle": "visitor interpretation services co-ordinator – government"
    },
    {
        "value": 5196,
        "NOCCode": "4164",
        "JobTitle": "Aboriginal affairs officer"
    },
    {
        "value": 5197,
        "NOCCode": "4164",
        "JobTitle": "Aboriginal employment policy officer"
    },
    {
        "value": 5198,
        "NOCCode": "4164",
        "JobTitle": "Aboriginal issues lobbyist"
    },
    {
        "value": 5199,
        "NOCCode": "4164",
        "JobTitle": "adoption project officer"
    },
    {
        "value": 5200,
        "NOCCode": "4164",
        "JobTitle": "adviser for the integration of persons with disabilities"
    },
    {
        "value": 5201,
        "NOCCode": "4164",
        "JobTitle": "adviser, public assistance"
    },
    {
        "value": 5202,
        "NOCCode": "4164",
        "JobTitle": "affirmation action advisor"
    },
    {
        "value": 5203,
        "NOCCode": "4164",
        "JobTitle": "analyst, occupational supply"
    },
    {
        "value": 5204,
        "NOCCode": "4164",
        "JobTitle": "analyst, social policies"
    },
    {
        "value": 5205,
        "NOCCode": "4164",
        "JobTitle": "child care licensing officer"
    },
    {
        "value": 5206,
        "NOCCode": "4164",
        "JobTitle": "child welfare policy analyst"
    },
    {
        "value": 5207,
        "NOCCode": "4164",
        "JobTitle": "child-care programs planning officer"
    },
    {
        "value": 5208,
        "NOCCode": "4164",
        "JobTitle": "child-care services liaison officer"
    },
    {
        "value": 5209,
        "NOCCode": "4164",
        "JobTitle": "citizenship officer"
    },
    {
        "value": 5210,
        "NOCCode": "4164",
        "JobTitle": "community organizer"
    },
    {
        "value": 5211,
        "NOCCode": "4164",
        "JobTitle": "community social development officer"
    },
    {
        "value": 5212,
        "NOCCode": "4164",
        "JobTitle": "consultant, social policy"
    },
    {
        "value": 5213,
        "NOCCode": "4164",
        "JobTitle": "consultant, social services"
    },
    {
        "value": 5214,
        "NOCCode": "4164",
        "JobTitle": "consultant, social services planning"
    },
    {
        "value": 5215,
        "NOCCode": "4164",
        "JobTitle": "consumer advisor"
    },
    {
        "value": 5216,
        "NOCCode": "4164",
        "JobTitle": "co-ordinator, public rights"
    },
    {
        "value": 5217,
        "NOCCode": "4164",
        "JobTitle": "co-ordinator, women's shelter program"
    },
    {
        "value": 5218,
        "NOCCode": "4164",
        "JobTitle": "correction rehabilitation program co-ordinator"
    },
    {
        "value": 5219,
        "NOCCode": "4164",
        "JobTitle": "correctional service policy consultant"
    },
    {
        "value": 5220,
        "NOCCode": "4164",
        "JobTitle": "employment equity adviser – social policy"
    },
    {
        "value": 5221,
        "NOCCode": "4164",
        "JobTitle": "employment equity consultant"
    },
    {
        "value": 5222,
        "NOCCode": "4164",
        "JobTitle": "employment equity policy analyst"
    },
    {
        "value": 5223,
        "NOCCode": "4164",
        "JobTitle": "employment initiatives co-ordinator"
    },
    {
        "value": 5224,
        "NOCCode": "4164",
        "JobTitle": "employment insurance policy analysis officer"
    },
    {
        "value": 5225,
        "NOCCode": "4164",
        "JobTitle": "employment insurance policy analyst"
    },
    {
        "value": 5226,
        "NOCCode": "4164",
        "JobTitle": "employment insurance services adviser"
    },
    {
        "value": 5227,
        "NOCCode": "4164",
        "JobTitle": "employment standards specialist"
    },
    {
        "value": 5228,
        "NOCCode": "4164",
        "JobTitle": "evaluation officer, job creation and employment services"
    },
    {
        "value": 5229,
        "NOCCode": "4164",
        "JobTitle": "experimental home economist"
    },
    {
        "value": 5230,
        "NOCCode": "4164",
        "JobTitle": "extension home economist"
    },
    {
        "value": 5231,
        "NOCCode": "4164",
        "JobTitle": "family violence prevention program adviser"
    },
    {
        "value": 5232,
        "NOCCode": "4164",
        "JobTitle": "field representative – housing"
    },
    {
        "value": 5233,
        "NOCCode": "4164",
        "JobTitle": "food consultant – home economics"
    },
    {
        "value": 5234,
        "NOCCode": "4164",
        "JobTitle": "home economics consultant"
    },
    {
        "value": 5235,
        "NOCCode": "4164",
        "JobTitle": "home economist"
    },
    {
        "value": 5236,
        "NOCCode": "4164",
        "JobTitle": "home economist – consumer association"
    },
    {
        "value": 5237,
        "NOCCode": "4164",
        "JobTitle": "housing officer, public housing"
    },
    {
        "value": 5238,
        "NOCCode": "4164",
        "JobTitle": "housing policy analyst"
    },
    {
        "value": 5239,
        "NOCCode": "4164",
        "JobTitle": "Human Rights Commission officer"
    },
    {
        "value": 5240,
        "NOCCode": "4164",
        "JobTitle": "Human Rights Commission officers supervisor"
    },
    {
        "value": 5241,
        "NOCCode": "4164",
        "JobTitle": "human rights officer"
    },
    {
        "value": 5242,
        "NOCCode": "4164",
        "JobTitle": "human rights officer – government services"
    },
    {
        "value": 5243,
        "NOCCode": "4164",
        "JobTitle": "human rights specialist"
    },
    {
        "value": 5244,
        "NOCCode": "4164",
        "JobTitle": "immigrant settlement liaison officer"
    },
    {
        "value": 5245,
        "NOCCode": "4164",
        "JobTitle": "immigration and demographic analysis officer"
    },
    {
        "value": 5246,
        "NOCCode": "4164",
        "JobTitle": "immigration consultant"
    },
    {
        "value": 5247,
        "NOCCode": "4164",
        "JobTitle": "immigration policy advisor"
    },
    {
        "value": 5248,
        "NOCCode": "4164",
        "JobTitle": "immigration policy consultant – non-government"
    },
    {
        "value": 5249,
        "NOCCode": "4164",
        "JobTitle": "immigration settlement practitioner"
    },
    {
        "value": 5250,
        "NOCCode": "4164",
        "JobTitle": "income maintenance program officer"
    },
    {
        "value": 5251,
        "NOCCode": "4164",
        "JobTitle": "international aid and development project officer"
    },
    {
        "value": 5252,
        "NOCCode": "4164",
        "JobTitle": "international aid and development researcher"
    },
    {
        "value": 5253,
        "NOCCode": "4164",
        "JobTitle": "international relations specialist"
    },
    {
        "value": 5254,
        "NOCCode": "4164",
        "JobTitle": "job creation and employment services evaluation officer"
    },
    {
        "value": 5255,
        "NOCCode": "4164",
        "JobTitle": "labour affairs officer – labour policy"
    },
    {
        "value": 5256,
        "NOCCode": "4164",
        "JobTitle": "labour market analyst"
    },
    {
        "value": 5257,
        "NOCCode": "4164",
        "JobTitle": "labour market information officer"
    },
    {
        "value": 5258,
        "NOCCode": "4164",
        "JobTitle": "labour market policy officer"
    },
    {
        "value": 5259,
        "NOCCode": "4164",
        "JobTitle": "labour mobility policy consultant"
    },
    {
        "value": 5260,
        "NOCCode": "4164",
        "JobTitle": "labour policy analyst"
    },
    {
        "value": 5261,
        "NOCCode": "4164",
        "JobTitle": "labour policy officer"
    },
    {
        "value": 5262,
        "NOCCode": "4164",
        "JobTitle": "labour standards analyst"
    },
    {
        "value": 5263,
        "NOCCode": "4164",
        "JobTitle": "liaison officer, child-care services"
    },
    {
        "value": 5264,
        "NOCCode": "4164",
        "JobTitle": "lobbyist, Aboriginal issues"
    },
    {
        "value": 5265,
        "NOCCode": "4164",
        "JobTitle": "lobbyist, Native issues"
    },
    {
        "value": 5266,
        "NOCCode": "4164",
        "JobTitle": "multiculturalism project officer"
    },
    {
        "value": 5267,
        "NOCCode": "4164",
        "JobTitle": "Native affairs officer"
    },
    {
        "value": 5268,
        "NOCCode": "4164",
        "JobTitle": "Native employment program officer"
    },
    {
        "value": 5269,
        "NOCCode": "4164",
        "JobTitle": "Native issues lobbyist"
    },
    {
        "value": 5270,
        "NOCCode": "4164",
        "JobTitle": "occupational supply analyst"
    },
    {
        "value": 5271,
        "NOCCode": "4164",
        "JobTitle": "officer, Aboriginal employment program"
    },
    {
        "value": 5272,
        "NOCCode": "4164",
        "JobTitle": "officer, employment insurance policy analysis"
    },
    {
        "value": 5273,
        "NOCCode": "4164",
        "JobTitle": "officer, human rights"
    },
    {
        "value": 5274,
        "NOCCode": "4164",
        "JobTitle": "officer, Human Rights Commission"
    },
    {
        "value": 5275,
        "NOCCode": "4164",
        "JobTitle": "officer, immigration and demographic analysis"
    },
    {
        "value": 5276,
        "NOCCode": "4164",
        "JobTitle": "officer, social programs"
    },
    {
        "value": 5277,
        "NOCCode": "4164",
        "JobTitle": "peace activist"
    },
    {
        "value": 5278,
        "NOCCode": "4164",
        "JobTitle": "peace researcher"
    },
    {
        "value": 5279,
        "NOCCode": "4164",
        "JobTitle": "planning officer, child-care programs"
    },
    {
        "value": 5280,
        "NOCCode": "4164",
        "JobTitle": "policy analyst, child welfare"
    },
    {
        "value": 5281,
        "NOCCode": "4164",
        "JobTitle": "policy analyst, housing"
    },
    {
        "value": 5282,
        "NOCCode": "4164",
        "JobTitle": "policy analyst, immigration"
    },
    {
        "value": 5283,
        "NOCCode": "4164",
        "JobTitle": "policy analyst, status of women's directorate"
    },
    {
        "value": 5284,
        "NOCCode": "4164",
        "JobTitle": "policy consultant, correction services"
    },
    {
        "value": 5285,
        "NOCCode": "4164",
        "JobTitle": "policy consultant, employment equity"
    },
    {
        "value": 5286,
        "NOCCode": "4164",
        "JobTitle": "policy consultant, labour mobility"
    },
    {
        "value": 5287,
        "NOCCode": "4164",
        "JobTitle": "policy consultant, women's employment"
    },
    {
        "value": 5288,
        "NOCCode": "4164",
        "JobTitle": "policy officer, labour"
    },
    {
        "value": 5289,
        "NOCCode": "4164",
        "JobTitle": "policy officer, visible minorities"
    },
    {
        "value": 5290,
        "NOCCode": "4164",
        "JobTitle": "political organizer"
    },
    {
        "value": 5291,
        "NOCCode": "4164",
        "JobTitle": "pollster"
    },
    {
        "value": 5292,
        "NOCCode": "4164",
        "JobTitle": "poverty researcher"
    },
    {
        "value": 5293,
        "NOCCode": "4164",
        "JobTitle": "program adviser, family violence prevention"
    },
    {
        "value": 5294,
        "NOCCode": "4164",
        "JobTitle": "program consultant, community policing"
    },
    {
        "value": 5295,
        "NOCCode": "4164",
        "JobTitle": "program officer, income maintenance"
    },
    {
        "value": 5296,
        "NOCCode": "4164",
        "JobTitle": "program officer, Native employment"
    },
    {
        "value": 5297,
        "NOCCode": "4164",
        "JobTitle": "program officer, refugee affairs"
    },
    {
        "value": 5298,
        "NOCCode": "4164",
        "JobTitle": "program planner, social services"
    },
    {
        "value": 5299,
        "NOCCode": "4164",
        "JobTitle": "project officer, international aid and development"
    },
    {
        "value": 5300,
        "NOCCode": "4164",
        "JobTitle": "project officer, multiculturalism"
    },
    {
        "value": 5301,
        "NOCCode": "4164",
        "JobTitle": "psychology assistant (except university)"
    },
    {
        "value": 5302,
        "NOCCode": "4164",
        "JobTitle": "public assistance adviser"
    },
    {
        "value": 5303,
        "NOCCode": "4164",
        "JobTitle": "public housing officer"
    },
    {
        "value": 5304,
        "NOCCode": "4164",
        "JobTitle": "public housing policy analyst"
    },
    {
        "value": 5305,
        "NOCCode": "4164",
        "JobTitle": "public opinion survey researcher"
    },
    {
        "value": 5306,
        "NOCCode": "4164",
        "JobTitle": "public rights co-ordinator"
    },
    {
        "value": 5307,
        "NOCCode": "4164",
        "JobTitle": "race relations officer"
    },
    {
        "value": 5308,
        "NOCCode": "4164",
        "JobTitle": "refugee affairs program officer"
    },
    {
        "value": 5309,
        "NOCCode": "4164",
        "JobTitle": "research home economist"
    },
    {
        "value": 5310,
        "NOCCode": "4164",
        "JobTitle": "research officer, social welfare"
    },
    {
        "value": 5311,
        "NOCCode": "4164",
        "JobTitle": "researcher, international aid and development"
    },
    {
        "value": 5312,
        "NOCCode": "4164",
        "JobTitle": "settlement counsellor"
    },
    {
        "value": 5313,
        "NOCCode": "4164",
        "JobTitle": "settlement liaison officer"
    },
    {
        "value": 5314,
        "NOCCode": "4164",
        "JobTitle": "settlement practitioner"
    },
    {
        "value": 5315,
        "NOCCode": "4164",
        "JobTitle": "social and community care licensing officer"
    },
    {
        "value": 5316,
        "NOCCode": "4164",
        "JobTitle": "social assistance advisor"
    },
    {
        "value": 5317,
        "NOCCode": "4164",
        "JobTitle": "social assistance policy analyst"
    },
    {
        "value": 5318,
        "NOCCode": "4164",
        "JobTitle": "social development officer"
    },
    {
        "value": 5319,
        "NOCCode": "4164",
        "JobTitle": "social issues researcher"
    },
    {
        "value": 5320,
        "NOCCode": "4164",
        "JobTitle": "social policy advisor"
    },
    {
        "value": 5321,
        "NOCCode": "4164",
        "JobTitle": "social policy analyst"
    },
    {
        "value": 5322,
        "NOCCode": "4164",
        "JobTitle": "social policy consultant"
    },
    {
        "value": 5323,
        "NOCCode": "4164",
        "JobTitle": "social policy planning consultant"
    },
    {
        "value": 5324,
        "NOCCode": "4164",
        "JobTitle": "social policy researcher"
    },
    {
        "value": 5325,
        "NOCCode": "4164",
        "JobTitle": "social program officer"
    },
    {
        "value": 5326,
        "NOCCode": "4164",
        "JobTitle": "social researcher"
    },
    {
        "value": 5327,
        "NOCCode": "4164",
        "JobTitle": "social science researcher"
    },
    {
        "value": 5328,
        "NOCCode": "4164",
        "JobTitle": "social service program officer"
    },
    {
        "value": 5329,
        "NOCCode": "4164",
        "JobTitle": "social services consultant"
    },
    {
        "value": 5330,
        "NOCCode": "4164",
        "JobTitle": "social services planner"
    },
    {
        "value": 5331,
        "NOCCode": "4164",
        "JobTitle": "social services planning consultant"
    },
    {
        "value": 5332,
        "NOCCode": "4164",
        "JobTitle": "social services program officers supervisor"
    },
    {
        "value": 5333,
        "NOCCode": "4164",
        "JobTitle": "social services program planner"
    },
    {
        "value": 5334,
        "NOCCode": "4164",
        "JobTitle": "social survey researcher (except statistician)"
    },
    {
        "value": 5335,
        "NOCCode": "4164",
        "JobTitle": "social survey researchers supervisor"
    },
    {
        "value": 5336,
        "NOCCode": "4164",
        "JobTitle": "social welfare advisor"
    },
    {
        "value": 5337,
        "NOCCode": "4164",
        "JobTitle": "social welfare research officer"
    },
    {
        "value": 5338,
        "NOCCode": "4164",
        "JobTitle": "socio-economic research and planning officer"
    },
    {
        "value": 5339,
        "NOCCode": "4164",
        "JobTitle": "specialist, employment standards"
    },
    {
        "value": 5340,
        "NOCCode": "4164",
        "JobTitle": "status of women's directorate policy analyst"
    },
    {
        "value": 5341,
        "NOCCode": "4164",
        "JobTitle": "supervisor, Human Rights Commission officers"
    },
    {
        "value": 5342,
        "NOCCode": "4164",
        "JobTitle": "supervisor, social services program officers"
    },
    {
        "value": 5343,
        "NOCCode": "4164",
        "JobTitle": "supervisor, social survey researchers"
    },
    {
        "value": 5344,
        "NOCCode": "4164",
        "JobTitle": "supervisor, welfare policy analysts"
    },
    {
        "value": 5345,
        "NOCCode": "4164",
        "JobTitle": "textile and clothing consultant – home economics"
    },
    {
        "value": 5346,
        "NOCCode": "4164",
        "JobTitle": "visible minorities policy officer"
    },
    {
        "value": 5347,
        "NOCCode": "4164",
        "JobTitle": "welfare policy analysts supervisor"
    },
    {
        "value": 5348,
        "NOCCode": "4164",
        "JobTitle": "women's employment policy consultant"
    },
    {
        "value": 5349,
        "NOCCode": "4164",
        "JobTitle": "women's issues lobbyist"
    },
    {
        "value": 5350,
        "NOCCode": "4164",
        "JobTitle": "women's program co-ordinator"
    },
    {
        "value": 5351,
        "NOCCode": "4164",
        "JobTitle": "women's shelter program co-ordinator"
    },
    {
        "value": 5352,
        "NOCCode": "4164",
        "JobTitle": "women's status policy consultant"
    },
    {
        "value": 5353,
        "NOCCode": "4164",
        "JobTitle": "workers' compensation officer"
    },
    {
        "value": 5354,
        "NOCCode": "4164",
        "JobTitle": "workers' compensation policy analyst"
    },
    {
        "value": 5355,
        "NOCCode": "4165",
        "JobTitle": "accreditation officer, health and social services institution"
    },
    {
        "value": 5356,
        "NOCCode": "4165",
        "JobTitle": "clinical research associate"
    },
    {
        "value": 5357,
        "NOCCode": "4165",
        "JobTitle": "clinical research program manager"
    },
    {
        "value": 5358,
        "NOCCode": "4165",
        "JobTitle": "clinical trials co-ordinator"
    },
    {
        "value": 5359,
        "NOCCode": "4165",
        "JobTitle": "consultant, drug and alcohol abuse"
    },
    {
        "value": 5360,
        "NOCCode": "4165",
        "JobTitle": "consultant, health care"
    },
    {
        "value": 5361,
        "NOCCode": "4165",
        "JobTitle": "consultant, health care planning"
    },
    {
        "value": 5362,
        "NOCCode": "4165",
        "JobTitle": "consultant, home-care programs"
    },
    {
        "value": 5363,
        "NOCCode": "4165",
        "JobTitle": "consultant, labour force planning – health sector"
    },
    {
        "value": 5364,
        "NOCCode": "4165",
        "JobTitle": "consultant, mental health programs"
    },
    {
        "value": 5365,
        "NOCCode": "4165",
        "JobTitle": "coroner"
    },
    {
        "value": 5366,
        "NOCCode": "4165",
        "JobTitle": "dental health consultant"
    },
    {
        "value": 5367,
        "NOCCode": "4165",
        "JobTitle": "health and social services institution accreditation officer"
    },
    {
        "value": 5368,
        "NOCCode": "4165",
        "JobTitle": "health and social services liaison officer"
    },
    {
        "value": 5369,
        "NOCCode": "4165",
        "JobTitle": "health care association program officer"
    },
    {
        "value": 5370,
        "NOCCode": "4165",
        "JobTitle": "health care planner"
    },
    {
        "value": 5371,
        "NOCCode": "4165",
        "JobTitle": "health care planning consultant"
    },
    {
        "value": 5372,
        "NOCCode": "4165",
        "JobTitle": "health care planning officer"
    },
    {
        "value": 5373,
        "NOCCode": "4165",
        "JobTitle": "health care program administration officer"
    },
    {
        "value": 5374,
        "NOCCode": "4165",
        "JobTitle": "health care researcher"
    },
    {
        "value": 5375,
        "NOCCode": "4165",
        "JobTitle": "health educator"
    },
    {
        "value": 5376,
        "NOCCode": "4165",
        "JobTitle": "health policy development officer"
    },
    {
        "value": 5377,
        "NOCCode": "4165",
        "JobTitle": "health program information analyst"
    },
    {
        "value": 5378,
        "NOCCode": "4165",
        "JobTitle": "health promotion program officer"
    },
    {
        "value": 5379,
        "NOCCode": "4165",
        "JobTitle": "health research officer"
    },
    {
        "value": 5380,
        "NOCCode": "4165",
        "JobTitle": "health services consultant"
    },
    {
        "value": 5381,
        "NOCCode": "4165",
        "JobTitle": "health services program consultant"
    },
    {
        "value": 5382,
        "NOCCode": "4165",
        "JobTitle": "health services research officer"
    },
    {
        "value": 5383,
        "NOCCode": "4165",
        "JobTitle": "health services researcher"
    },
    {
        "value": 5384,
        "NOCCode": "4165",
        "JobTitle": "home-care programs consultant"
    },
    {
        "value": 5385,
        "NOCCode": "4165",
        "JobTitle": "labour force planning consultant – health sector"
    },
    {
        "value": 5386,
        "NOCCode": "4165",
        "JobTitle": "liaison officer, health and social services"
    },
    {
        "value": 5387,
        "NOCCode": "4165",
        "JobTitle": "long-term care program consultant"
    },
    {
        "value": 5388,
        "NOCCode": "4165",
        "JobTitle": "nursing home guidelines development officer"
    },
    {
        "value": 5389,
        "NOCCode": "4165",
        "JobTitle": "nursing home policy development officer"
    },
    {
        "value": 5390,
        "NOCCode": "4165",
        "JobTitle": "officer, health care planning"
    },
    {
        "value": 5391,
        "NOCCode": "4165",
        "JobTitle": "officer, health policy development"
    },
    {
        "value": 5392,
        "NOCCode": "4165",
        "JobTitle": "planner, health care"
    },
    {
        "value": 5393,
        "NOCCode": "4165",
        "JobTitle": "planning consultant, health care"
    },
    {
        "value": 5394,
        "NOCCode": "4165",
        "JobTitle": "planning co-ordinator, district health council program"
    },
    {
        "value": 5395,
        "NOCCode": "4165",
        "JobTitle": "planning officer, child health care programs"
    },
    {
        "value": 5396,
        "NOCCode": "4165",
        "JobTitle": "planning officer, health care"
    },
    {
        "value": 5397,
        "NOCCode": "4165",
        "JobTitle": "policy development officer, health"
    },
    {
        "value": 5398,
        "NOCCode": "4165",
        "JobTitle": "policy development officer, nursing homes"
    },
    {
        "value": 5399,
        "NOCCode": "4165",
        "JobTitle": "prevention officer – occupational health and safety"
    },
    {
        "value": 5400,
        "NOCCode": "4165",
        "JobTitle": "program officer, health care association"
    },
    {
        "value": 5401,
        "NOCCode": "4165",
        "JobTitle": "program officer, health promotion"
    },
    {
        "value": 5402,
        "NOCCode": "4165",
        "JobTitle": "research analyst, health policy"
    },
    {
        "value": 5403,
        "NOCCode": "4165",
        "JobTitle": "research officer, health services"
    },
    {
        "value": 5404,
        "NOCCode": "4165",
        "JobTitle": "researcher, health care"
    },
    {
        "value": 5405,
        "NOCCode": "4165",
        "JobTitle": "wellness consultant"
    },
    {
        "value": 5406,
        "NOCCode": "4165",
        "JobTitle": "wellness co-ordinator"
    },
    {
        "value": 5407,
        "NOCCode": "4165",
        "JobTitle": "wellness program co-ordinator"
    },
    {
        "value": 5408,
        "NOCCode": "4166",
        "JobTitle": "adult education consultant"
    },
    {
        "value": 5409,
        "NOCCode": "4166",
        "JobTitle": "adult education program officer"
    },
    {
        "value": 5410,
        "NOCCode": "4166",
        "JobTitle": "analyst, education policies"
    },
    {
        "value": 5411,
        "NOCCode": "4166",
        "JobTitle": "andragogy specialist"
    },
    {
        "value": 5412,
        "NOCCode": "4166",
        "JobTitle": "art co-ordinator – education"
    },
    {
        "value": 5413,
        "NOCCode": "4166",
        "JobTitle": "board of education inspector"
    },
    {
        "value": 5414,
        "NOCCode": "4166",
        "JobTitle": "consultant, adult education"
    },
    {
        "value": 5415,
        "NOCCode": "4166",
        "JobTitle": "consultant, education"
    },
    {
        "value": 5416,
        "NOCCode": "4166",
        "JobTitle": "consultant, education policies"
    },
    {
        "value": 5417,
        "NOCCode": "4166",
        "JobTitle": "consultant, heritage languages education"
    },
    {
        "value": 5418,
        "NOCCode": "4166",
        "JobTitle": "consultant, school subjects"
    },
    {
        "value": 5419,
        "NOCCode": "4166",
        "JobTitle": "consultant, special education"
    },
    {
        "value": 5420,
        "NOCCode": "4166",
        "JobTitle": "consultant, teaching"
    },
    {
        "value": 5421,
        "NOCCode": "4166",
        "JobTitle": "co-ordinator, special education"
    },
    {
        "value": 5422,
        "NOCCode": "4166",
        "JobTitle": "curriculum consultant"
    },
    {
        "value": 5423,
        "NOCCode": "4166",
        "JobTitle": "curriculum developer"
    },
    {
        "value": 5424,
        "NOCCode": "4166",
        "JobTitle": "curriculum planner"
    },
    {
        "value": 5425,
        "NOCCode": "4166",
        "JobTitle": "distance education program co-ordinator"
    },
    {
        "value": 5426,
        "NOCCode": "4166",
        "JobTitle": "distance education project officer"
    },
    {
        "value": 5427,
        "NOCCode": "4166",
        "JobTitle": "education consultant"
    },
    {
        "value": 5428,
        "NOCCode": "4166",
        "JobTitle": "education outreach program co-ordinator"
    },
    {
        "value": 5429,
        "NOCCode": "4166",
        "JobTitle": "education policy advisor"
    },
    {
        "value": 5430,
        "NOCCode": "4166",
        "JobTitle": "education policy analyst"
    },
    {
        "value": 5431,
        "NOCCode": "4166",
        "JobTitle": "education policy consultant"
    },
    {
        "value": 5432,
        "NOCCode": "4166",
        "JobTitle": "education policy officer"
    },
    {
        "value": 5433,
        "NOCCode": "4166",
        "JobTitle": "education program co-ordinator"
    },
    {
        "value": 5434,
        "NOCCode": "4166",
        "JobTitle": "education program officer"
    },
    {
        "value": 5435,
        "NOCCode": "4166",
        "JobTitle": "education program planner"
    },
    {
        "value": 5436,
        "NOCCode": "4166",
        "JobTitle": "education research officer"
    },
    {
        "value": 5437,
        "NOCCode": "4166",
        "JobTitle": "education researcher"
    },
    {
        "value": 5438,
        "NOCCode": "4166",
        "JobTitle": "education science specialist"
    },
    {
        "value": 5439,
        "NOCCode": "4166",
        "JobTitle": "educational and vocational information specialist"
    },
    {
        "value": 5440,
        "NOCCode": "4166",
        "JobTitle": "heritage languages education consultant"
    },
    {
        "value": 5441,
        "NOCCode": "4166",
        "JobTitle": "inspector – education"
    },
    {
        "value": 5442,
        "NOCCode": "4166",
        "JobTitle": "language assessor – education"
    },
    {
        "value": 5443,
        "NOCCode": "4166",
        "JobTitle": "languages co-ordinator – education"
    },
    {
        "value": 5444,
        "NOCCode": "4166",
        "JobTitle": "mathematics co-ordinator – education"
    },
    {
        "value": 5445,
        "NOCCode": "4166",
        "JobTitle": "music co-ordinator – education"
    },
    {
        "value": 5446,
        "NOCCode": "4166",
        "JobTitle": "officer, adult education program"
    },
    {
        "value": 5447,
        "NOCCode": "4166",
        "JobTitle": "officer, official languages education"
    },
    {
        "value": 5448,
        "NOCCode": "4166",
        "JobTitle": "official languages education officer"
    },
    {
        "value": 5449,
        "NOCCode": "4166",
        "JobTitle": "physical education co-ordinator – education"
    },
    {
        "value": 5450,
        "NOCCode": "4166",
        "JobTitle": "policy analyst, education"
    },
    {
        "value": 5451,
        "NOCCode": "4166",
        "JobTitle": "policy consultant, education"
    },
    {
        "value": 5452,
        "NOCCode": "4166",
        "JobTitle": "policy officer, education"
    },
    {
        "value": 5453,
        "NOCCode": "4166",
        "JobTitle": "program co-ordinator – education"
    },
    {
        "value": 5454,
        "NOCCode": "4166",
        "JobTitle": "program development specialist – college"
    },
    {
        "value": 5455,
        "NOCCode": "4166",
        "JobTitle": "program officer, education"
    },
    {
        "value": 5456,
        "NOCCode": "4166",
        "JobTitle": "program planner, education"
    },
    {
        "value": 5457,
        "NOCCode": "4166",
        "JobTitle": "research officer, education"
    },
    {
        "value": 5458,
        "NOCCode": "4166",
        "JobTitle": "school adjustment specialist"
    },
    {
        "value": 5459,
        "NOCCode": "4166",
        "JobTitle": "school subjects consultant"
    },
    {
        "value": 5460,
        "NOCCode": "4166",
        "JobTitle": "science co-ordinator – education"
    },
    {
        "value": 5461,
        "NOCCode": "4166",
        "JobTitle": "special education consultant"
    },
    {
        "value": 5462,
        "NOCCode": "4166",
        "JobTitle": "specialist, andragogy"
    },
    {
        "value": 5463,
        "NOCCode": "4166",
        "JobTitle": "specialist, educational and vocational information"
    },
    {
        "value": 5464,
        "NOCCode": "4166",
        "JobTitle": "specialist, program development – college"
    },
    {
        "value": 5465,
        "NOCCode": "4166",
        "JobTitle": "specialist, school adjustment"
    },
    {
        "value": 5466,
        "NOCCode": "4166",
        "JobTitle": "subject consultant – education"
    },
    {
        "value": 5467,
        "NOCCode": "4166",
        "JobTitle": "supervisor, education policy"
    },
    {
        "value": 5468,
        "NOCCode": "4166",
        "JobTitle": "teaching consultant"
    },
    {
        "value": 5469,
        "NOCCode": "4167",
        "JobTitle": "analyst, fitness"
    },
    {
        "value": 5470,
        "NOCCode": "4167",
        "JobTitle": "analyst, fitness policy"
    },
    {
        "value": 5471,
        "NOCCode": "4167",
        "JobTitle": "analyst, leisure"
    },
    {
        "value": 5472,
        "NOCCode": "4167",
        "JobTitle": "analyst, leisure policy"
    },
    {
        "value": 5473,
        "NOCCode": "4167",
        "JobTitle": "analyst, recreation"
    },
    {
        "value": 5474,
        "NOCCode": "4167",
        "JobTitle": "analyst, recreation policy"
    },
    {
        "value": 5475,
        "NOCCode": "4167",
        "JobTitle": "analyst, sports"
    },
    {
        "value": 5476,
        "NOCCode": "4167",
        "JobTitle": "analyst, sports policy"
    },
    {
        "value": 5477,
        "NOCCode": "4167",
        "JobTitle": "aquatic program co-ordinator"
    },
    {
        "value": 5478,
        "NOCCode": "4167",
        "JobTitle": "arts and crafts programs co-ordinator"
    },
    {
        "value": 5479,
        "NOCCode": "4167",
        "JobTitle": "community recreation and leisure consultant"
    },
    {
        "value": 5480,
        "NOCCode": "4167",
        "JobTitle": "community recreation and leisure counsellor"
    },
    {
        "value": 5481,
        "NOCCode": "4167",
        "JobTitle": "community recreation co-ordinator"
    },
    {
        "value": 5482,
        "NOCCode": "4167",
        "JobTitle": "community recreational and leisure programs consultant"
    },
    {
        "value": 5483,
        "NOCCode": "4167",
        "JobTitle": "community recreational and leisure programs counsellor"
    },
    {
        "value": 5484,
        "NOCCode": "4167",
        "JobTitle": "consultant, community recreation and leisure"
    },
    {
        "value": 5485,
        "NOCCode": "4167",
        "JobTitle": "consultant, community recreational and leisure programs"
    },
    {
        "value": 5486,
        "NOCCode": "4167",
        "JobTitle": "consultant, employee fitness"
    },
    {
        "value": 5487,
        "NOCCode": "4167",
        "JobTitle": "consultant, geriatric recreational and leisure activities"
    },
    {
        "value": 5488,
        "NOCCode": "4167",
        "JobTitle": "consultant, leisure"
    },
    {
        "value": 5489,
        "NOCCode": "4167",
        "JobTitle": "consultant, leisure activities"
    },
    {
        "value": 5490,
        "NOCCode": "4167",
        "JobTitle": "consultant, recreation"
    },
    {
        "value": 5491,
        "NOCCode": "4167",
        "JobTitle": "consultant, recreational activities"
    },
    {
        "value": 5492,
        "NOCCode": "4167",
        "JobTitle": "consultant, sports"
    },
    {
        "value": 5493,
        "NOCCode": "4167",
        "JobTitle": "consultant, sports and leisure"
    },
    {
        "value": 5494,
        "NOCCode": "4167",
        "JobTitle": "consultant, sports and recreation"
    },
    {
        "value": 5495,
        "NOCCode": "4167",
        "JobTitle": "consultant, youth recreational and leisure activities"
    },
    {
        "value": 5496,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, aquatic programs"
    },
    {
        "value": 5497,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, arts and crafts programs"
    },
    {
        "value": 5498,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, fitness"
    },
    {
        "value": 5499,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, geriatric activities"
    },
    {
        "value": 5500,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, geriatric recreational and leisure activities"
    },
    {
        "value": 5501,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, leisure"
    },
    {
        "value": 5502,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, leisure activities programs"
    },
    {
        "value": 5503,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, leisure programs"
    },
    {
        "value": 5504,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, leisure services"
    },
    {
        "value": 5505,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, nautical activities"
    },
    {
        "value": 5506,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, nautical programs"
    },
    {
        "value": 5507,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, pool activities"
    },
    {
        "value": 5508,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, recreation"
    },
    {
        "value": 5509,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, recreation services"
    },
    {
        "value": 5510,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, recreational activities"
    },
    {
        "value": 5511,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, recreational activities programs"
    },
    {
        "value": 5512,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, recreational programs"
    },
    {
        "value": 5513,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, sports"
    },
    {
        "value": 5514,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, sports activities"
    },
    {
        "value": 5515,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, sports programs"
    },
    {
        "value": 5516,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, sports programs – sports and recreation"
    },
    {
        "value": 5517,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, teen club programs"
    },
    {
        "value": 5518,
        "NOCCode": "4167",
        "JobTitle": "co-ordinator, youth recreational and leisure activities program"
    },
    {
        "value": 5519,
        "NOCCode": "4167",
        "JobTitle": "counsellor, community recreation and leisure"
    },
    {
        "value": 5520,
        "NOCCode": "4167",
        "JobTitle": "counsellor, community recreational and leisure programs"
    },
    {
        "value": 5521,
        "NOCCode": "4167",
        "JobTitle": "counsellor, employee fitness"
    },
    {
        "value": 5522,
        "NOCCode": "4167",
        "JobTitle": "counsellor, geriatric recreational and leisure activities"
    },
    {
        "value": 5523,
        "NOCCode": "4167",
        "JobTitle": "counsellor, leisure"
    },
    {
        "value": 5524,
        "NOCCode": "4167",
        "JobTitle": "counsellor, leisure activities"
    },
    {
        "value": 5525,
        "NOCCode": "4167",
        "JobTitle": "counsellor, recreation"
    },
    {
        "value": 5526,
        "NOCCode": "4167",
        "JobTitle": "counsellor, recreational activities"
    },
    {
        "value": 5527,
        "NOCCode": "4167",
        "JobTitle": "counsellor, sports"
    },
    {
        "value": 5528,
        "NOCCode": "4167",
        "JobTitle": "counsellor, sports and leisure"
    },
    {
        "value": 5529,
        "NOCCode": "4167",
        "JobTitle": "counsellor, sports and recreation"
    },
    {
        "value": 5530,
        "NOCCode": "4167",
        "JobTitle": "counsellor, youth recreational and leisure activities"
    },
    {
        "value": 5531,
        "NOCCode": "4167",
        "JobTitle": "employee fitness consultant"
    },
    {
        "value": 5532,
        "NOCCode": "4167",
        "JobTitle": "employee fitness counsellor"
    },
    {
        "value": 5533,
        "NOCCode": "4167",
        "JobTitle": "fitness analyst"
    },
    {
        "value": 5534,
        "NOCCode": "4167",
        "JobTitle": "fitness policy analyst"
    },
    {
        "value": 5535,
        "NOCCode": "4167",
        "JobTitle": "fitness program consultant"
    },
    {
        "value": 5536,
        "NOCCode": "4167",
        "JobTitle": "fitness programs supervisor"
    },
    {
        "value": 5537,
        "NOCCode": "4167",
        "JobTitle": "fitness, recreation or sports policy analyst"
    },
    {
        "value": 5538,
        "NOCCode": "4167",
        "JobTitle": "geriatric activities co-ordinator"
    },
    {
        "value": 5539,
        "NOCCode": "4167",
        "JobTitle": "geriatric recreational and leisure activities consultant"
    },
    {
        "value": 5540,
        "NOCCode": "4167",
        "JobTitle": "geriatric recreational and leisure activities co-ordinator"
    },
    {
        "value": 5541,
        "NOCCode": "4167",
        "JobTitle": "geriatric recreational and leisure activities counsellor"
    },
    {
        "value": 5542,
        "NOCCode": "4167",
        "JobTitle": "leisure activities consultant"
    },
    {
        "value": 5543,
        "NOCCode": "4167",
        "JobTitle": "leisure activities counsellor"
    },
    {
        "value": 5544,
        "NOCCode": "4167",
        "JobTitle": "leisure activities program co-ordinator"
    },
    {
        "value": 5545,
        "NOCCode": "4167",
        "JobTitle": "leisure analyst"
    },
    {
        "value": 5546,
        "NOCCode": "4167",
        "JobTitle": "leisure consultant"
    },
    {
        "value": 5547,
        "NOCCode": "4167",
        "JobTitle": "leisure co-ordinator"
    },
    {
        "value": 5548,
        "NOCCode": "4167",
        "JobTitle": "leisure counsellor"
    },
    {
        "value": 5549,
        "NOCCode": "4167",
        "JobTitle": "leisure policy analyst"
    },
    {
        "value": 5550,
        "NOCCode": "4167",
        "JobTitle": "leisure program co-ordinator"
    },
    {
        "value": 5551,
        "NOCCode": "4167",
        "JobTitle": "leisure services co-ordinator"
    },
    {
        "value": 5552,
        "NOCCode": "4167",
        "JobTitle": "nautical activities co-ordinator"
    },
    {
        "value": 5553,
        "NOCCode": "4167",
        "JobTitle": "nautical programs co-ordinator"
    },
    {
        "value": 5554,
        "NOCCode": "4167",
        "JobTitle": "officer, sports projects"
    },
    {
        "value": 5555,
        "NOCCode": "4167",
        "JobTitle": "policy analyst, recreation, sports or fitness"
    },
    {
        "value": 5556,
        "NOCCode": "4167",
        "JobTitle": "pool activities co-ordinator"
    },
    {
        "value": 5557,
        "NOCCode": "4167",
        "JobTitle": "program co-ordinator, arts and crafts"
    },
    {
        "value": 5558,
        "NOCCode": "4167",
        "JobTitle": "program co-ordinator, leisure activities"
    },
    {
        "value": 5559,
        "NOCCode": "4167",
        "JobTitle": "program co-ordinator, recreation"
    },
    {
        "value": 5560,
        "NOCCode": "4167",
        "JobTitle": "program co-ordinator, recreational activities"
    },
    {
        "value": 5561,
        "NOCCode": "4167",
        "JobTitle": "program co-ordinator, teen clubs"
    },
    {
        "value": 5562,
        "NOCCode": "4167",
        "JobTitle": "program supervisor – sports and leisure"
    },
    {
        "value": 5563,
        "NOCCode": "4167",
        "JobTitle": "program supervisor, fitness"
    },
    {
        "value": 5564,
        "NOCCode": "4167",
        "JobTitle": "program supervisor, recreation and leisure"
    },
    {
        "value": 5565,
        "NOCCode": "4167",
        "JobTitle": "program supervisor, sports"
    },
    {
        "value": 5566,
        "NOCCode": "4167",
        "JobTitle": "program supervisor, sports, recreation and leisure"
    },
    {
        "value": 5567,
        "NOCCode": "4167",
        "JobTitle": "recreation analyst"
    },
    {
        "value": 5568,
        "NOCCode": "4167",
        "JobTitle": "recreation and leisure programs supervisor"
    },
    {
        "value": 5569,
        "NOCCode": "4167",
        "JobTitle": "recreation and leisure supervisor"
    },
    {
        "value": 5570,
        "NOCCode": "4167",
        "JobTitle": "recreation consultant"
    },
    {
        "value": 5571,
        "NOCCode": "4167",
        "JobTitle": "recreation co-ordinator"
    },
    {
        "value": 5572,
        "NOCCode": "4167",
        "JobTitle": "recreation counsellor"
    },
    {
        "value": 5573,
        "NOCCode": "4167",
        "JobTitle": "recreation policy analyst"
    },
    {
        "value": 5574,
        "NOCCode": "4167",
        "JobTitle": "recreation programmer"
    },
    {
        "value": 5575,
        "NOCCode": "4167",
        "JobTitle": "recreation services co-ordinator"
    },
    {
        "value": 5576,
        "NOCCode": "4167",
        "JobTitle": "recreation supervisor"
    },
    {
        "value": 5577,
        "NOCCode": "4167",
        "JobTitle": "recreation, sports or fitness policy analyst"
    },
    {
        "value": 5578,
        "NOCCode": "4167",
        "JobTitle": "recreational activities consultant"
    },
    {
        "value": 5579,
        "NOCCode": "4167",
        "JobTitle": "recreational activities co-ordinator"
    },
    {
        "value": 5580,
        "NOCCode": "4167",
        "JobTitle": "recreational activities counsellor"
    },
    {
        "value": 5581,
        "NOCCode": "4167",
        "JobTitle": "recreational activities program co-ordinator"
    },
    {
        "value": 5582,
        "NOCCode": "4167",
        "JobTitle": "recreational programs co-ordinator"
    },
    {
        "value": 5583,
        "NOCCode": "4167",
        "JobTitle": "recreationist – geriatrics"
    },
    {
        "value": 5584,
        "NOCCode": "4167",
        "JobTitle": "recreologist"
    },
    {
        "value": 5585,
        "NOCCode": "4167",
        "JobTitle": "sports activities co-ordinator"
    },
    {
        "value": 5586,
        "NOCCode": "4167",
        "JobTitle": "sports analyst"
    },
    {
        "value": 5587,
        "NOCCode": "4167",
        "JobTitle": "sports and leisure consultant"
    },
    {
        "value": 5588,
        "NOCCode": "4167",
        "JobTitle": "sports and leisure counsellor"
    },
    {
        "value": 5589,
        "NOCCode": "4167",
        "JobTitle": "sports and recreation consultant"
    },
    {
        "value": 5590,
        "NOCCode": "4167",
        "JobTitle": "sports and recreation counsellor"
    },
    {
        "value": 5591,
        "NOCCode": "4167",
        "JobTitle": "sports consultant"
    },
    {
        "value": 5592,
        "NOCCode": "4167",
        "JobTitle": "sports co-ordinator"
    },
    {
        "value": 5593,
        "NOCCode": "4167",
        "JobTitle": "sports counsellor"
    },
    {
        "value": 5594,
        "NOCCode": "4167",
        "JobTitle": "sports policy analyst"
    },
    {
        "value": 5595,
        "NOCCode": "4167",
        "JobTitle": "sports program co-ordinator – sports and recreation"
    },
    {
        "value": 5596,
        "NOCCode": "4167",
        "JobTitle": "sports program supervisor"
    },
    {
        "value": 5597,
        "NOCCode": "4167",
        "JobTitle": "sports programs co-ordinator"
    },
    {
        "value": 5598,
        "NOCCode": "4167",
        "JobTitle": "sports projects officer"
    },
    {
        "value": 5599,
        "NOCCode": "4167",
        "JobTitle": "sports supervisor"
    },
    {
        "value": 5600,
        "NOCCode": "4167",
        "JobTitle": "sports, fitness or recreation policy analyst"
    },
    {
        "value": 5601,
        "NOCCode": "4167",
        "JobTitle": "sports, recreation and leisure supervisor"
    },
    {
        "value": 5602,
        "NOCCode": "4167",
        "JobTitle": "sports, recreational and leisure programs supervisor"
    },
    {
        "value": 5603,
        "NOCCode": "4167",
        "JobTitle": "supervisor, fitness programs"
    },
    {
        "value": 5604,
        "NOCCode": "4167",
        "JobTitle": "supervisor, programs – sports and leisure"
    },
    {
        "value": 5605,
        "NOCCode": "4167",
        "JobTitle": "supervisor, recreation and leisure"
    },
    {
        "value": 5606,
        "NOCCode": "4167",
        "JobTitle": "supervisor, recreational and leisure programs"
    },
    {
        "value": 5607,
        "NOCCode": "4167",
        "JobTitle": "supervisor, sports"
    },
    {
        "value": 5608,
        "NOCCode": "4167",
        "JobTitle": "supervisor, sports programs"
    },
    {
        "value": 5609,
        "NOCCode": "4167",
        "JobTitle": "supervisor, sports, recreation and leisure"
    },
    {
        "value": 5610,
        "NOCCode": "4167",
        "JobTitle": "supervisor, sports, recreational and leisure programs"
    },
    {
        "value": 5611,
        "NOCCode": "4167",
        "JobTitle": "teen club programs co-ordinator"
    },
    {
        "value": 5612,
        "NOCCode": "4167",
        "JobTitle": "youth recreational and leisure activities consultant"
    },
    {
        "value": 5613,
        "NOCCode": "4167",
        "JobTitle": "youth recreational and leisure activities counsellor"
    },
    {
        "value": 5614,
        "NOCCode": "4167",
        "JobTitle": "youth recreational and leisure activities program co-ordinator"
    },
    {
        "value": 5615,
        "NOCCode": "4168",
        "JobTitle": "Aboriginal land claims liaison officer"
    },
    {
        "value": 5616,
        "NOCCode": "4168",
        "JobTitle": "attaché"
    },
    {
        "value": 5617,
        "NOCCode": "4168",
        "JobTitle": "board member – government services"
    },
    {
        "value": 5618,
        "NOCCode": "4168",
        "JobTitle": "deputy returning officer"
    },
    {
        "value": 5619,
        "NOCCode": "4168",
        "JobTitle": "diplomat"
    },
    {
        "value": 5620,
        "NOCCode": "4168",
        "JobTitle": "election commission officer"
    },
    {
        "value": 5621,
        "NOCCode": "4168",
        "JobTitle": "elections officer"
    },
    {
        "value": 5622,
        "NOCCode": "4168",
        "JobTitle": "extraparliamentary commission officer"
    },
    {
        "value": 5623,
        "NOCCode": "4168",
        "JobTitle": "federal and intergovernmental affairs research officer – government services"
    },
    {
        "value": 5624,
        "NOCCode": "4168",
        "JobTitle": "federal-provincial relations officer"
    },
    {
        "value": 5625,
        "NOCCode": "4168",
        "JobTitle": "first secretary, foreign service"
    },
    {
        "value": 5626,
        "NOCCode": "4168",
        "JobTitle": "foreign service officer"
    },
    {
        "value": 5627,
        "NOCCode": "4168",
        "JobTitle": "government official"
    },
    {
        "value": 5628,
        "NOCCode": "4168",
        "JobTitle": "health authority board member"
    },
    {
        "value": 5629,
        "NOCCode": "4168",
        "JobTitle": "House of Commons committee officer"
    },
    {
        "value": 5630,
        "NOCCode": "4168",
        "JobTitle": "House of Commons officer"
    },
    {
        "value": 5631,
        "NOCCode": "4168",
        "JobTitle": "intergovernmental affairs officer"
    },
    {
        "value": 5632,
        "NOCCode": "4168",
        "JobTitle": "legislative committee officer"
    },
    {
        "value": 5633,
        "NOCCode": "4168",
        "JobTitle": "liaison officer, Native land claims"
    },
    {
        "value": 5634,
        "NOCCode": "4168",
        "JobTitle": "Native land claims liaison officer"
    },
    {
        "value": 5635,
        "NOCCode": "4168",
        "JobTitle": "officer, election commission"
    },
    {
        "value": 5636,
        "NOCCode": "4168",
        "JobTitle": "officer, federal-provincial relations"
    },
    {
        "value": 5637,
        "NOCCode": "4168",
        "JobTitle": "officer, foreign service"
    },
    {
        "value": 5638,
        "NOCCode": "4168",
        "JobTitle": "officer, House of Commons"
    },
    {
        "value": 5639,
        "NOCCode": "4168",
        "JobTitle": "officer, House of Commons committee"
    },
    {
        "value": 5640,
        "NOCCode": "4168",
        "JobTitle": "officer, intergovernmental affairs"
    },
    {
        "value": 5641,
        "NOCCode": "4168",
        "JobTitle": "officer, labour relations commission"
    },
    {
        "value": 5642,
        "NOCCode": "4168",
        "JobTitle": "officer, legislative committee"
    },
    {
        "value": 5643,
        "NOCCode": "4168",
        "JobTitle": "officer, legislative council"
    },
    {
        "value": 5644,
        "NOCCode": "4168",
        "JobTitle": "officer, office of the Speaker"
    },
    {
        "value": 5645,
        "NOCCode": "4168",
        "JobTitle": "officer, Office of the Speaker of the House of Commons"
    },
    {
        "value": 5646,
        "NOCCode": "4168",
        "JobTitle": "officer, official languages commission"
    },
    {
        "value": 5647,
        "NOCCode": "4168",
        "JobTitle": "officer, Premier's Office (provincial government)"
    },
    {
        "value": 5648,
        "NOCCode": "4168",
        "JobTitle": "officer, Prime Minister's Office (federal government)"
    },
    {
        "value": 5649,
        "NOCCode": "4168",
        "JobTitle": "officer, Privy Council Office"
    },
    {
        "value": 5650,
        "NOCCode": "4168",
        "JobTitle": "officer, protocol – government services"
    },
    {
        "value": 5651,
        "NOCCode": "4168",
        "JobTitle": "officer, public inquiries"
    },
    {
        "value": 5652,
        "NOCCode": "4168",
        "JobTitle": "officer, regional committee – government services"
    },
    {
        "value": 5653,
        "NOCCode": "4168",
        "JobTitle": "officer, regional council"
    },
    {
        "value": 5654,
        "NOCCode": "4168",
        "JobTitle": "officer, royal commission"
    },
    {
        "value": 5655,
        "NOCCode": "4168",
        "JobTitle": "officer, Senate committee"
    },
    {
        "value": 5656,
        "NOCCode": "4168",
        "JobTitle": "officer, standing committee"
    },
    {
        "value": 5657,
        "NOCCode": "4168",
        "JobTitle": "officer, standing or select committee"
    },
    {
        "value": 5658,
        "NOCCode": "4168",
        "JobTitle": "officer, task force"
    },
    {
        "value": 5659,
        "NOCCode": "4168",
        "JobTitle": "officer, territorial council"
    },
    {
        "value": 5660,
        "NOCCode": "4168",
        "JobTitle": "officer, tribunal"
    },
    {
        "value": 5661,
        "NOCCode": "4168",
        "JobTitle": "official languages promotion officer"
    },
    {
        "value": 5662,
        "NOCCode": "4168",
        "JobTitle": "parole board member"
    },
    {
        "value": 5663,
        "NOCCode": "4168",
        "JobTitle": "protocol officer"
    },
    {
        "value": 5664,
        "NOCCode": "4168",
        "JobTitle": "refugee board member"
    },
    {
        "value": 5665,
        "NOCCode": "4168",
        "JobTitle": "research officer – federal and intergovernmental affairs"
    },
    {
        "value": 5666,
        "NOCCode": "4168",
        "JobTitle": "returning officer"
    },
    {
        "value": 5667,
        "NOCCode": "4168",
        "JobTitle": "royal commission officer"
    },
    {
        "value": 5668,
        "NOCCode": "4168",
        "JobTitle": "second secretary, foreign service"
    },
    {
        "value": 5669,
        "NOCCode": "4168",
        "JobTitle": "select committee officer"
    },
    {
        "value": 5670,
        "NOCCode": "4168",
        "JobTitle": "Senate committee officer"
    },
    {
        "value": 5671,
        "NOCCode": "4168",
        "JobTitle": "standing committee officer"
    },
    {
        "value": 5672,
        "NOCCode": "4168",
        "JobTitle": "task force officer"
    },
    {
        "value": 5673,
        "NOCCode": "4168",
        "JobTitle": "territorial council officer"
    },
    {
        "value": 5674,
        "NOCCode": "4168",
        "JobTitle": "third secretary, foreign service"
    },
    {
        "value": 5675,
        "NOCCode": "4168",
        "JobTitle": "tribunal chairperson"
    },
    {
        "value": 5676,
        "NOCCode": "4168",
        "JobTitle": "tribunal member"
    },
    {
        "value": 5677,
        "NOCCode": "4168",
        "JobTitle": "tribunal officer"
    },
    {
        "value": 5678,
        "NOCCode": "4168",
        "JobTitle": "tribunal officer – government services"
    },
    {
        "value": 5679,
        "NOCCode": "4169",
        "JobTitle": "analyst, handwriting"
    },
    {
        "value": 5680,
        "NOCCode": "4169",
        "JobTitle": "anthropologist"
    },
    {
        "value": 5681,
        "NOCCode": "4169",
        "JobTitle": "applied anthropologist"
    },
    {
        "value": 5682,
        "NOCCode": "4169",
        "JobTitle": "applied geographer"
    },
    {
        "value": 5683,
        "NOCCode": "4169",
        "JobTitle": "applied linguist"
    },
    {
        "value": 5684,
        "NOCCode": "4169",
        "JobTitle": "archaeological consultant"
    },
    {
        "value": 5685,
        "NOCCode": "4169",
        "JobTitle": "archaeological field worker"
    },
    {
        "value": 5686,
        "NOCCode": "4169",
        "JobTitle": "archaeologist"
    },
    {
        "value": 5687,
        "NOCCode": "4169",
        "JobTitle": "biogeographer"
    },
    {
        "value": 5688,
        "NOCCode": "4169",
        "JobTitle": "biological anthropologist"
    },
    {
        "value": 5689,
        "NOCCode": "4169",
        "JobTitle": "cultural anthropologist"
    },
    {
        "value": 5690,
        "NOCCode": "4169",
        "JobTitle": "cultural geographer"
    },
    {
        "value": 5691,
        "NOCCode": "4169",
        "JobTitle": "diplomatic historian"
    },
    {
        "value": 5692,
        "NOCCode": "4169",
        "JobTitle": "dramatic arts historian"
    },
    {
        "value": 5693,
        "NOCCode": "4169",
        "JobTitle": "economic geographer"
    },
    {
        "value": 5694,
        "NOCCode": "4169",
        "JobTitle": "economic historian"
    },
    {
        "value": 5695,
        "NOCCode": "4169",
        "JobTitle": "educational sociologist"
    },
    {
        "value": 5696,
        "NOCCode": "4169",
        "JobTitle": "ethnographer"
    },
    {
        "value": 5697,
        "NOCCode": "4169",
        "JobTitle": "ethnologist"
    },
    {
        "value": 5698,
        "NOCCode": "4169",
        "JobTitle": "etymologist"
    },
    {
        "value": 5699,
        "NOCCode": "4169",
        "JobTitle": "geographer"
    },
    {
        "value": 5700,
        "NOCCode": "4169",
        "JobTitle": "gerontologist"
    },
    {
        "value": 5701,
        "NOCCode": "4169",
        "JobTitle": "graphoanalyst"
    },
    {
        "value": 5702,
        "NOCCode": "4169",
        "JobTitle": "graphologist"
    },
    {
        "value": 5703,
        "NOCCode": "4169",
        "JobTitle": "handwriting analyst"
    },
    {
        "value": 5704,
        "NOCCode": "4169",
        "JobTitle": "handwriting expert"
    },
    {
        "value": 5705,
        "NOCCode": "4169",
        "JobTitle": "historian"
    },
    {
        "value": 5706,
        "NOCCode": "4169",
        "JobTitle": "historical geographer"
    },
    {
        "value": 5707,
        "NOCCode": "4169",
        "JobTitle": "industrial geographer"
    },
    {
        "value": 5708,
        "NOCCode": "4169",
        "JobTitle": "industrial sociologist"
    },
    {
        "value": 5709,
        "NOCCode": "4169",
        "JobTitle": "linguist"
    },
    {
        "value": 5710,
        "NOCCode": "4169",
        "JobTitle": "linguistic anthropologist"
    },
    {
        "value": 5711,
        "NOCCode": "4169",
        "JobTitle": "medical sociologist"
    },
    {
        "value": 5712,
        "NOCCode": "4169",
        "JobTitle": "military historian"
    },
    {
        "value": 5713,
        "NOCCode": "4169",
        "JobTitle": "penologist"
    },
    {
        "value": 5714,
        "NOCCode": "4169",
        "JobTitle": "philologist"
    },
    {
        "value": 5715,
        "NOCCode": "4169",
        "JobTitle": "philosopher"
    },
    {
        "value": 5716,
        "NOCCode": "4169",
        "JobTitle": "physical anthropologist"
    },
    {
        "value": 5717,
        "NOCCode": "4169",
        "JobTitle": "physical geographer"
    },
    {
        "value": 5718,
        "NOCCode": "4169",
        "JobTitle": "political geographer"
    },
    {
        "value": 5719,
        "NOCCode": "4169",
        "JobTitle": "political historian"
    },
    {
        "value": 5720,
        "NOCCode": "4169",
        "JobTitle": "political scientist"
    },
    {
        "value": 5721,
        "NOCCode": "4169",
        "JobTitle": "politicist"
    },
    {
        "value": 5722,
        "NOCCode": "4169",
        "JobTitle": "psycholinguist"
    },
    {
        "value": 5723,
        "NOCCode": "4169",
        "JobTitle": "psychometrician"
    },
    {
        "value": 5724,
        "NOCCode": "4169",
        "JobTitle": "psychometrist"
    },
    {
        "value": 5725,
        "NOCCode": "4169",
        "JobTitle": "quantitative historian"
    },
    {
        "value": 5726,
        "NOCCode": "4169",
        "JobTitle": "research anthropologist"
    },
    {
        "value": 5727,
        "NOCCode": "4169",
        "JobTitle": "research archaeologist"
    },
    {
        "value": 5728,
        "NOCCode": "4169",
        "JobTitle": "rural sociologist"
    },
    {
        "value": 5729,
        "NOCCode": "4169",
        "JobTitle": "scientific expert, handwriting"
    },
    {
        "value": 5730,
        "NOCCode": "4169",
        "JobTitle": "scientific handwriting expert"
    },
    {
        "value": 5731,
        "NOCCode": "4169",
        "JobTitle": "social and cultural anthropologist"
    },
    {
        "value": 5732,
        "NOCCode": "4169",
        "JobTitle": "social anthropologist"
    },
    {
        "value": 5733,
        "NOCCode": "4169",
        "JobTitle": "social ecologist"
    },
    {
        "value": 5734,
        "NOCCode": "4169",
        "JobTitle": "social historian"
    },
    {
        "value": 5735,
        "NOCCode": "4169",
        "JobTitle": "social scientist"
    },
    {
        "value": 5736,
        "NOCCode": "4169",
        "JobTitle": "sociolinguist"
    },
    {
        "value": 5737,
        "NOCCode": "4169",
        "JobTitle": "sociologist"
    },
    {
        "value": 5738,
        "NOCCode": "4169",
        "JobTitle": "theatre historian"
    },
    {
        "value": 5739,
        "NOCCode": "4169",
        "JobTitle": "urban geographer"
    },
    {
        "value": 5740,
        "NOCCode": "4169",
        "JobTitle": "urban sociologist"
    },
    {
        "value": 5741,
        "NOCCode": "4211",
        "JobTitle": "abstractor"
    },
    {
        "value": 5742,
        "NOCCode": "4211",
        "JobTitle": "brief writer – law"
    },
    {
        "value": 5743,
        "NOCCode": "4211",
        "JobTitle": "British Columbia notary public"
    },
    {
        "value": 5744,
        "NOCCode": "4211",
        "JobTitle": "clerk, law"
    },
    {
        "value": 5745,
        "NOCCode": "4211",
        "JobTitle": "collection paralegal"
    },
    {
        "value": 5746,
        "NOCCode": "4211",
        "JobTitle": "commercial law clerk"
    },
    {
        "value": 5747,
        "NOCCode": "4211",
        "JobTitle": "commercial law paralegal"
    },
    {
        "value": 5748,
        "NOCCode": "4211",
        "JobTitle": "contract clerk – law"
    },
    {
        "value": 5749,
        "NOCCode": "4211",
        "JobTitle": "conveyance clerk"
    },
    {
        "value": 5750,
        "NOCCode": "4211",
        "JobTitle": "copyright agent"
    },
    {
        "value": 5751,
        "NOCCode": "4211",
        "JobTitle": "corporate law and litigation clerk"
    },
    {
        "value": 5752,
        "NOCCode": "4211",
        "JobTitle": "corporate law clerk"
    },
    {
        "value": 5753,
        "NOCCode": "4211",
        "JobTitle": "corporate paralegal"
    },
    {
        "value": 5754,
        "NOCCode": "4211",
        "JobTitle": "corporate securities law clerk"
    },
    {
        "value": 5755,
        "NOCCode": "4211",
        "JobTitle": "corporation paralegal"
    },
    {
        "value": 5756,
        "NOCCode": "4211",
        "JobTitle": "court and tribunal agent"
    },
    {
        "value": 5757,
        "NOCCode": "4211",
        "JobTitle": "criminal law clerk"
    },
    {
        "value": 5758,
        "NOCCode": "4211",
        "JobTitle": "criminal law paralegal"
    },
    {
        "value": 5759,
        "NOCCode": "4211",
        "JobTitle": "family law clerk"
    },
    {
        "value": 5760,
        "NOCCode": "4211",
        "JobTitle": "family law paralegal"
    },
    {
        "value": 5761,
        "NOCCode": "4211",
        "JobTitle": "foreclosure clerk"
    },
    {
        "value": 5762,
        "NOCCode": "4211",
        "JobTitle": "foreclosure paralegal"
    },
    {
        "value": 5763,
        "NOCCode": "4211",
        "JobTitle": "independent paralegal"
    },
    {
        "value": 5764,
        "NOCCode": "4211",
        "JobTitle": "insurance law clerk"
    },
    {
        "value": 5765,
        "NOCCode": "4211",
        "JobTitle": "insurance paralegal"
    },
    {
        "value": 5766,
        "NOCCode": "4211",
        "JobTitle": "labour law clerk"
    },
    {
        "value": 5767,
        "NOCCode": "4211",
        "JobTitle": "labour law paralegal"
    },
    {
        "value": 5768,
        "NOCCode": "4211",
        "JobTitle": "land titles clerk"
    },
    {
        "value": 5769,
        "NOCCode": "4211",
        "JobTitle": "land titles examiner"
    },
    {
        "value": 5770,
        "NOCCode": "4211",
        "JobTitle": "law clerk to judge"
    },
    {
        "value": 5771,
        "NOCCode": "4211",
        "JobTitle": "lay notary public"
    },
    {
        "value": 5772,
        "NOCCode": "4211",
        "JobTitle": "lease and title clerk"
    },
    {
        "value": 5773,
        "NOCCode": "4211",
        "JobTitle": "legal researcher"
    },
    {
        "value": 5774,
        "NOCCode": "4211",
        "JobTitle": "legal technician"
    },
    {
        "value": 5775,
        "NOCCode": "4211",
        "JobTitle": "litigation law clerk"
    },
    {
        "value": 5776,
        "NOCCode": "4211",
        "JobTitle": "litigation paralegal"
    },
    {
        "value": 5777,
        "NOCCode": "4211",
        "JobTitle": "mortgage and real estate paralegal"
    },
    {
        "value": 5778,
        "NOCCode": "4211",
        "JobTitle": "notary public"
    },
    {
        "value": 5779,
        "NOCCode": "4211",
        "JobTitle": "notary public (British Columbia)"
    },
    {
        "value": 5780,
        "NOCCode": "4211",
        "JobTitle": "notary public (outside Quebec)"
    },
    {
        "value": 5781,
        "NOCCode": "4211",
        "JobTitle": "notary public clerk"
    },
    {
        "value": 5782,
        "NOCCode": "4211",
        "JobTitle": "notary public, lay"
    },
    {
        "value": 5783,
        "NOCCode": "4211",
        "JobTitle": "oil and gas property paralegal"
    },
    {
        "value": 5784,
        "NOCCode": "4211",
        "JobTitle": "oil and gas rights law clerk"
    },
    {
        "value": 5785,
        "NOCCode": "4211",
        "JobTitle": "oil and gas rights paralegal"
    },
    {
        "value": 5786,
        "NOCCode": "4211",
        "JobTitle": "paralegal"
    },
    {
        "value": 5787,
        "NOCCode": "4211",
        "JobTitle": "property paralegal"
    },
    {
        "value": 5788,
        "NOCCode": "4211",
        "JobTitle": "real estate law clerk"
    },
    {
        "value": 5789,
        "NOCCode": "4211",
        "JobTitle": "real estate paralegal"
    },
    {
        "value": 5790,
        "NOCCode": "4211",
        "JobTitle": "registered trademark agent"
    },
    {
        "value": 5791,
        "NOCCode": "4211",
        "JobTitle": "researcher, legal"
    },
    {
        "value": 5792,
        "NOCCode": "4211",
        "JobTitle": "tax law clerk"
    },
    {
        "value": 5793,
        "NOCCode": "4211",
        "JobTitle": "tax paralegal"
    },
    {
        "value": 5794,
        "NOCCode": "4211",
        "JobTitle": "title and lease clerk"
    },
    {
        "value": 5795,
        "NOCCode": "4211",
        "JobTitle": "title examiner"
    },
    {
        "value": 5796,
        "NOCCode": "4211",
        "JobTitle": "title searcher"
    },
    {
        "value": 5797,
        "NOCCode": "4211",
        "JobTitle": "trademark agent"
    },
    {
        "value": 5798,
        "NOCCode": "4211",
        "JobTitle": "transfer-of-title clerk"
    },
    {
        "value": 5799,
        "NOCCode": "4211",
        "JobTitle": "wills and estates law clerk"
    },
    {
        "value": 5800,
        "NOCCode": "4211",
        "JobTitle": "wills and estates paralegal"
    },
    {
        "value": 5801,
        "NOCCode": "4212",
        "JobTitle": "Aboriginal centre co-ordinator"
    },
    {
        "value": 5802,
        "NOCCode": "4212",
        "JobTitle": "Aboriginal outreach officer – social services"
    },
    {
        "value": 5803,
        "NOCCode": "4212",
        "JobTitle": "Aboriginal outreach worker"
    },
    {
        "value": 5804,
        "NOCCode": "4212",
        "JobTitle": "addictions worker"
    },
    {
        "value": 5805,
        "NOCCode": "4212",
        "JobTitle": "addictions worker – compulsive gambling"
    },
    {
        "value": 5806,
        "NOCCode": "4212",
        "JobTitle": "assistant, social services"
    },
    {
        "value": 5807,
        "NOCCode": "4212",
        "JobTitle": "case aide – social services"
    },
    {
        "value": 5808,
        "NOCCode": "4212",
        "JobTitle": "certified return-to-work co-ordinator – disability management"
    },
    {
        "value": 5809,
        "NOCCode": "4212",
        "JobTitle": "certified return-to-work facilitator – disability management"
    },
    {
        "value": 5810,
        "NOCCode": "4212",
        "JobTitle": "child and youth worker"
    },
    {
        "value": 5811,
        "NOCCode": "4212",
        "JobTitle": "child welfare worker"
    },
    {
        "value": 5812,
        "NOCCode": "4212",
        "JobTitle": "child-care worker (except day care)"
    },
    {
        "value": 5813,
        "NOCCode": "4212",
        "JobTitle": "coach, life skills"
    },
    {
        "value": 5814,
        "NOCCode": "4212",
        "JobTitle": "community and social services worker"
    },
    {
        "value": 5815,
        "NOCCode": "4212",
        "JobTitle": "community centre co-ordinator"
    },
    {
        "value": 5816,
        "NOCCode": "4212",
        "JobTitle": "community centre worker"
    },
    {
        "value": 5817,
        "NOCCode": "4212",
        "JobTitle": "community counsellor – social services"
    },
    {
        "value": 5818,
        "NOCCode": "4212",
        "JobTitle": "community development worker"
    },
    {
        "value": 5819,
        "NOCCode": "4212",
        "JobTitle": "community liaison worker"
    },
    {
        "value": 5820,
        "NOCCode": "4212",
        "JobTitle": "community mental health worker"
    },
    {
        "value": 5821,
        "NOCCode": "4212",
        "JobTitle": "community organization worker"
    },
    {
        "value": 5822,
        "NOCCode": "4212",
        "JobTitle": "community rehabilitation worker"
    },
    {
        "value": 5823,
        "NOCCode": "4212",
        "JobTitle": "community service worker"
    },
    {
        "value": 5824,
        "NOCCode": "4212",
        "JobTitle": "community services officer – social services"
    },
    {
        "value": 5825,
        "NOCCode": "4212",
        "JobTitle": "community worker"
    },
    {
        "value": 5826,
        "NOCCode": "4212",
        "JobTitle": "co-ordinator, community centre"
    },
    {
        "value": 5827,
        "NOCCode": "4212",
        "JobTitle": "co-ordinator, Native centre"
    },
    {
        "value": 5828,
        "NOCCode": "4212",
        "JobTitle": "co-ordinator, youth development"
    },
    {
        "value": 5829,
        "NOCCode": "4212",
        "JobTitle": "crisis intervention worker"
    },
    {
        "value": 5830,
        "NOCCode": "4212",
        "JobTitle": "detention home worker"
    },
    {
        "value": 5831,
        "NOCCode": "4212",
        "JobTitle": "development service worker"
    },
    {
        "value": 5832,
        "NOCCode": "4212",
        "JobTitle": "developmental service worker"
    },
    {
        "value": 5833,
        "NOCCode": "4212",
        "JobTitle": "disability management worker"
    },
    {
        "value": 5834,
        "NOCCode": "4212",
        "JobTitle": "drop-in centre worker"
    },
    {
        "value": 5835,
        "NOCCode": "4212",
        "JobTitle": "drug addiction worker"
    },
    {
        "value": 5836,
        "NOCCode": "4212",
        "JobTitle": "eligibility co-ordinator – social assistance"
    },
    {
        "value": 5837,
        "NOCCode": "4212",
        "JobTitle": "eligibility co-ordinator – welfare"
    },
    {
        "value": 5838,
        "NOCCode": "4212",
        "JobTitle": "exceptional-child-care worker (except day care)"
    },
    {
        "value": 5839,
        "NOCCode": "4212",
        "JobTitle": "family service worker"
    },
    {
        "value": 5840,
        "NOCCode": "4212",
        "JobTitle": "field officer, veterans' affairs"
    },
    {
        "value": 5841,
        "NOCCode": "4212",
        "JobTitle": "financial assistance officer – social assistance"
    },
    {
        "value": 5842,
        "NOCCode": "4212",
        "JobTitle": "financial assistance worker – social services"
    },
    {
        "value": 5843,
        "NOCCode": "4212",
        "JobTitle": "group home operator"
    },
    {
        "value": 5844,
        "NOCCode": "4212",
        "JobTitle": "group home worker"
    },
    {
        "value": 5845,
        "NOCCode": "4212",
        "JobTitle": "halfway house supervisor"
    },
    {
        "value": 5846,
        "NOCCode": "4212",
        "JobTitle": "halfway house worker"
    },
    {
        "value": 5847,
        "NOCCode": "4212",
        "JobTitle": "help centre supervisor – social services"
    },
    {
        "value": 5848,
        "NOCCode": "4212",
        "JobTitle": "hostel co-ordinator"
    },
    {
        "value": 5849,
        "NOCCode": "4212",
        "JobTitle": "hostel outreach worker"
    },
    {
        "value": 5850,
        "NOCCode": "4212",
        "JobTitle": "income maintenance officer – social services"
    },
    {
        "value": 5851,
        "NOCCode": "4212",
        "JobTitle": "independent living instructor"
    },
    {
        "value": 5852,
        "NOCCode": "4212",
        "JobTitle": "instructor, life skills"
    },
    {
        "value": 5853,
        "NOCCode": "4212",
        "JobTitle": "intellectual disabilities worker"
    },
    {
        "value": 5854,
        "NOCCode": "4212",
        "JobTitle": "Itinerant support service worker"
    },
    {
        "value": 5855,
        "NOCCode": "4212",
        "JobTitle": "life skills coach"
    },
    {
        "value": 5856,
        "NOCCode": "4212",
        "JobTitle": "life skills instructor"
    },
    {
        "value": 5857,
        "NOCCode": "4212",
        "JobTitle": "meals-on-wheels worker"
    },
    {
        "value": 5858,
        "NOCCode": "4212",
        "JobTitle": "mental health proctor"
    },
    {
        "value": 5859,
        "NOCCode": "4212",
        "JobTitle": "mental health support worker"
    },
    {
        "value": 5860,
        "NOCCode": "4212",
        "JobTitle": "mental health worker"
    },
    {
        "value": 5861,
        "NOCCode": "4212",
        "JobTitle": "Native centre co-ordinator"
    },
    {
        "value": 5862,
        "NOCCode": "4212",
        "JobTitle": "Native community worker"
    },
    {
        "value": 5863,
        "NOCCode": "4212",
        "JobTitle": "Native outreach officer – social services"
    },
    {
        "value": 5864,
        "NOCCode": "4212",
        "JobTitle": "Native outreach worker"
    },
    {
        "value": 5865,
        "NOCCode": "4212",
        "JobTitle": "neighbourhood worker – social services"
    },
    {
        "value": 5866,
        "NOCCode": "4212",
        "JobTitle": "officer, Native outreach – social services"
    },
    {
        "value": 5867,
        "NOCCode": "4212",
        "JobTitle": "peer support worker"
    },
    {
        "value": 5868,
        "NOCCode": "4212",
        "JobTitle": "personal skills development instructor – social services"
    },
    {
        "value": 5869,
        "NOCCode": "4212",
        "JobTitle": "proctor, mental health"
    },
    {
        "value": 5870,
        "NOCCode": "4212",
        "JobTitle": "psychological assistant"
    },
    {
        "value": 5871,
        "NOCCode": "4212",
        "JobTitle": "registered social service worker"
    },
    {
        "value": 5872,
        "NOCCode": "4212",
        "JobTitle": "rehabilitation officer – social services"
    },
    {
        "value": 5873,
        "NOCCode": "4212",
        "JobTitle": "rehabilitation service worker"
    },
    {
        "value": 5874,
        "NOCCode": "4212",
        "JobTitle": "rehabilitation worker – social services"
    },
    {
        "value": 5875,
        "NOCCode": "4212",
        "JobTitle": "residence co-ordinator – group home"
    },
    {
        "value": 5876,
        "NOCCode": "4212",
        "JobTitle": "return-to-work co-ordinator – disability management"
    },
    {
        "value": 5877,
        "NOCCode": "4212",
        "JobTitle": "return-to-work facilitator – disability management"
    },
    {
        "value": 5878,
        "NOCCode": "4212",
        "JobTitle": "settlement worker – community services"
    },
    {
        "value": 5879,
        "NOCCode": "4212",
        "JobTitle": "shelter supervisor – social services"
    },
    {
        "value": 5880,
        "NOCCode": "4212",
        "JobTitle": "shelter workshop supervisor"
    },
    {
        "value": 5881,
        "NOCCode": "4212",
        "JobTitle": "social aid officer"
    },
    {
        "value": 5882,
        "NOCCode": "4212",
        "JobTitle": "social animator"
    },
    {
        "value": 5883,
        "NOCCode": "4212",
        "JobTitle": "social assistance officer"
    },
    {
        "value": 5884,
        "NOCCode": "4212",
        "JobTitle": "social rehabilitation officer"
    },
    {
        "value": 5885,
        "NOCCode": "4212",
        "JobTitle": "social rehabilitation technician"
    },
    {
        "value": 5886,
        "NOCCode": "4212",
        "JobTitle": "social rehabilitation worker"
    },
    {
        "value": 5887,
        "NOCCode": "4212",
        "JobTitle": "social services assistant"
    },
    {
        "value": 5888,
        "NOCCode": "4212",
        "JobTitle": "social services volunteer co-ordinator"
    },
    {
        "value": 5889,
        "NOCCode": "4212",
        "JobTitle": "social services worker"
    },
    {
        "value": 5890,
        "NOCCode": "4212",
        "JobTitle": "social welfare officer"
    },
    {
        "value": 5891,
        "NOCCode": "4212",
        "JobTitle": "special education technician – social and community services"
    },
    {
        "value": 5892,
        "NOCCode": "4212",
        "JobTitle": "street outreach worker"
    },
    {
        "value": 5893,
        "NOCCode": "4212",
        "JobTitle": "streetworker – social services"
    },
    {
        "value": 5894,
        "NOCCode": "4212",
        "JobTitle": "substance abuse worker"
    },
    {
        "value": 5895,
        "NOCCode": "4212",
        "JobTitle": "supervised access worker"
    },
    {
        "value": 5896,
        "NOCCode": "4212",
        "JobTitle": "supervisor, halfway house"
    },
    {
        "value": 5897,
        "NOCCode": "4212",
        "JobTitle": "supervisor, women's centre – social services"
    },
    {
        "value": 5898,
        "NOCCode": "4212",
        "JobTitle": "supervisor, women's shelter – social services"
    },
    {
        "value": 5899,
        "NOCCode": "4212",
        "JobTitle": "transition home worker – social services"
    },
    {
        "value": 5900,
        "NOCCode": "4212",
        "JobTitle": "transition house worker – social services"
    },
    {
        "value": 5901,
        "NOCCode": "4212",
        "JobTitle": "veteran services officer"
    },
    {
        "value": 5902,
        "NOCCode": "4212",
        "JobTitle": "veterans' affairs field officer"
    },
    {
        "value": 5903,
        "NOCCode": "4212",
        "JobTitle": "volunteer co-ordinator, social services"
    },
    {
        "value": 5904,
        "NOCCode": "4212",
        "JobTitle": "welfare and compensation officer"
    },
    {
        "value": 5905,
        "NOCCode": "4212",
        "JobTitle": "welfare organization supervisor"
    },
    {
        "value": 5906,
        "NOCCode": "4212",
        "JobTitle": "welfare worker"
    },
    {
        "value": 5907,
        "NOCCode": "4212",
        "JobTitle": "women's centre co-ordinator – social services"
    },
    {
        "value": 5908,
        "NOCCode": "4212",
        "JobTitle": "women's centre program supervisor – social services"
    },
    {
        "value": 5909,
        "NOCCode": "4212",
        "JobTitle": "women's shelter supervisor"
    },
    {
        "value": 5910,
        "NOCCode": "4212",
        "JobTitle": "youth development co-ordinator"
    },
    {
        "value": 5911,
        "NOCCode": "4212",
        "JobTitle": "youth hostel co-ordinator"
    },
    {
        "value": 5912,
        "NOCCode": "4212",
        "JobTitle": "youth services worker"
    },
    {
        "value": 5913,
        "NOCCode": "4212",
        "JobTitle": "youth worker"
    },
    {
        "value": 5914,
        "NOCCode": "4212",
        "JobTitle": "youth worker – social services"
    },
    {
        "value": 5915,
        "NOCCode": "4214",
        "JobTitle": "aide, daycare"
    },
    {
        "value": 5916,
        "NOCCode": "4214",
        "JobTitle": "aide, nursery school"
    },
    {
        "value": 5917,
        "NOCCode": "4214",
        "JobTitle": "assistant ECE"
    },
    {
        "value": 5918,
        "NOCCode": "4214",
        "JobTitle": "baby-care worker"
    },
    {
        "value": 5919,
        "NOCCode": "4214",
        "JobTitle": "baby-care worker assistant"
    },
    {
        "value": 5920,
        "NOCCode": "4214",
        "JobTitle": "baby-care workers supervisor"
    },
    {
        "value": 5921,
        "NOCCode": "4214",
        "JobTitle": "child care worker – daycare"
    },
    {
        "value": 5922,
        "NOCCode": "4214",
        "JobTitle": "child care worker assistant"
    },
    {
        "value": 5923,
        "NOCCode": "4214",
        "JobTitle": "child care worker, daycare"
    },
    {
        "value": 5924,
        "NOCCode": "4214",
        "JobTitle": "co-op daycare co-ordinator"
    },
    {
        "value": 5925,
        "NOCCode": "4214",
        "JobTitle": "co-ordinator, co-op daycare"
    },
    {
        "value": 5926,
        "NOCCode": "4214",
        "JobTitle": "co-ordinator, daycare"
    },
    {
        "value": 5927,
        "NOCCode": "4214",
        "JobTitle": "daycare aide"
    },
    {
        "value": 5928,
        "NOCCode": "4214",
        "JobTitle": "daycare attendant"
    },
    {
        "value": 5929,
        "NOCCode": "4214",
        "JobTitle": "daycare co-ordinator"
    },
    {
        "value": 5930,
        "NOCCode": "4214",
        "JobTitle": "daycare helper"
    },
    {
        "value": 5931,
        "NOCCode": "4214",
        "JobTitle": "daycare supervisor"
    },
    {
        "value": 5932,
        "NOCCode": "4214",
        "JobTitle": "daycare worker"
    },
    {
        "value": 5933,
        "NOCCode": "4214",
        "JobTitle": "daycare worker assistant"
    },
    {
        "value": 5934,
        "NOCCode": "4214",
        "JobTitle": "early childhood assistant"
    },
    {
        "value": 5935,
        "NOCCode": "4214",
        "JobTitle": "early childhood education worker"
    },
    {
        "value": 5936,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator – junior kindergarten"
    },
    {
        "value": 5937,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator – kindergarten"
    },
    {
        "value": 5938,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator – preschool"
    },
    {
        "value": 5939,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator (E.C.E.)"
    },
    {
        "value": 5940,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator assistant – junior kindergarten"
    },
    {
        "value": 5941,
        "NOCCode": "4214",
        "JobTitle": "early childhood educator assistant – kindergarten"
    },
    {
        "value": 5942,
        "NOCCode": "4214",
        "JobTitle": "early childhood program staff assistant"
    },
    {
        "value": 5943,
        "NOCCode": "4214",
        "JobTitle": "early childhood program supervisor"
    },
    {
        "value": 5944,
        "NOCCode": "4214",
        "JobTitle": "early childhood supervisor"
    },
    {
        "value": 5945,
        "NOCCode": "4214",
        "JobTitle": "ECE assistant"
    },
    {
        "value": 5946,
        "NOCCode": "4214",
        "JobTitle": "helper, daycare"
    },
    {
        "value": 5947,
        "NOCCode": "4214",
        "JobTitle": "helper, nursery school"
    },
    {
        "value": 5948,
        "NOCCode": "4214",
        "JobTitle": "infant daycare workers supervisor"
    },
    {
        "value": 5949,
        "NOCCode": "4214",
        "JobTitle": "infants' daycare worker"
    },
    {
        "value": 5950,
        "NOCCode": "4214",
        "JobTitle": "nursery school aide"
    },
    {
        "value": 5951,
        "NOCCode": "4214",
        "JobTitle": "nursery school helper"
    },
    {
        "value": 5952,
        "NOCCode": "4214",
        "JobTitle": "preschool helper"
    },
    {
        "value": 5953,
        "NOCCode": "4214",
        "JobTitle": "preschool supervisor"
    },
    {
        "value": 5954,
        "NOCCode": "4214",
        "JobTitle": "teacher, daycare"
    },
    {
        "value": 5955,
        "NOCCode": "4215",
        "JobTitle": "braille instructor"
    },
    {
        "value": 5956,
        "NOCCode": "4215",
        "JobTitle": "braille teacher"
    },
    {
        "value": 5957,
        "NOCCode": "4215",
        "JobTitle": "finger-spelling teacher"
    },
    {
        "value": 5958,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with a mobility impairment"
    },
    {
        "value": 5959,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons who are blind"
    },
    {
        "value": 5960,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons who are deaf"
    },
    {
        "value": 5961,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons who are hard of hearing"
    },
    {
        "value": 5962,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with a learning disability"
    },
    {
        "value": 5963,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with a visual impairment"
    },
    {
        "value": 5964,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with developmental disabilities"
    },
    {
        "value": 5965,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with disabilities"
    },
    {
        "value": 5966,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with intellectual disabilities"
    },
    {
        "value": 5967,
        "NOCCode": "4215",
        "JobTitle": "instructor of persons with special needs"
    },
    {
        "value": 5968,
        "NOCCode": "4215",
        "JobTitle": "instructor, braille"
    },
    {
        "value": 5969,
        "NOCCode": "4215",
        "JobTitle": "instructor, orientation and mobility"
    },
    {
        "value": 5970,
        "NOCCode": "4215",
        "JobTitle": "instructor, orientation and mobility of persons who are blind"
    },
    {
        "value": 5971,
        "NOCCode": "4215",
        "JobTitle": "instructor, sign language"
    },
    {
        "value": 5972,
        "NOCCode": "4215",
        "JobTitle": "instructor, special programs for persons with disabilities"
    },
    {
        "value": 5973,
        "NOCCode": "4215",
        "JobTitle": "job coach for persons with disabilities"
    },
    {
        "value": 5974,
        "NOCCode": "4215",
        "JobTitle": "lip-reading instructor"
    },
    {
        "value": 5975,
        "NOCCode": "4215",
        "JobTitle": "lip-reading teacher"
    },
    {
        "value": 5976,
        "NOCCode": "4215",
        "JobTitle": "orientation and mobility instructor"
    },
    {
        "value": 5977,
        "NOCCode": "4215",
        "JobTitle": "orientation and mobility instructor for persons with a visual impairment"
    },
    {
        "value": 5978,
        "NOCCode": "4215",
        "JobTitle": "orientation and mobility teacher for persons who are blind"
    },
    {
        "value": 5979,
        "NOCCode": "4215",
        "JobTitle": "orientation and mobility teacher of persons with a visual impairment"
    },
    {
        "value": 5980,
        "NOCCode": "4215",
        "JobTitle": "rehabilitation instructor for persons who are blind"
    },
    {
        "value": 5981,
        "NOCCode": "4215",
        "JobTitle": "rehabilitation instructor of persons with a visual impairment"
    },
    {
        "value": 5982,
        "NOCCode": "4215",
        "JobTitle": "rehabilitation teacher"
    },
    {
        "value": 5983,
        "NOCCode": "4215",
        "JobTitle": "rehabilitation teacher for persons who are blind"
    },
    {
        "value": 5984,
        "NOCCode": "4215",
        "JobTitle": "rehabilitation teacher of persons with a visual impairment"
    },
    {
        "value": 5985,
        "NOCCode": "4215",
        "JobTitle": "sign language instructor"
    },
    {
        "value": 5986,
        "NOCCode": "4215",
        "JobTitle": "sign language teacher"
    },
    {
        "value": 5987,
        "NOCCode": "4215",
        "JobTitle": "special education technician – persons with disabilities"
    },
    {
        "value": 5988,
        "NOCCode": "4215",
        "JobTitle": "teacher for persons with autism"
    },
    {
        "value": 5989,
        "NOCCode": "4215",
        "JobTitle": "teacher for persons with intellectual disabilities"
    },
    {
        "value": 5990,
        "NOCCode": "4215",
        "JobTitle": "teacher of braille"
    },
    {
        "value": 5991,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons who are blind"
    },
    {
        "value": 5992,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons who are deaf"
    },
    {
        "value": 5993,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons who are hard of hearing"
    },
    {
        "value": 5994,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons who are hearing impaired"
    },
    {
        "value": 5995,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons with a mobility impairment"
    },
    {
        "value": 5996,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons with a visual impairment"
    },
    {
        "value": 5997,
        "NOCCode": "4215",
        "JobTitle": "teacher of persons with developmental disabilities"
    },
    {
        "value": 5998,
        "NOCCode": "4215",
        "JobTitle": "teacher, finger spelling"
    },
    {
        "value": 5999,
        "NOCCode": "4215",
        "JobTitle": "teacher, lip reading"
    },
    {
        "value": 6000,
        "NOCCode": "4215",
        "JobTitle": "teacher, rehabilitation"
    },
    {
        "value": 6001,
        "NOCCode": "4215",
        "JobTitle": "teacher, sign language"
    },
    {
        "value": 6002,
        "NOCCode": "4216",
        "JobTitle": "auto-driving instructor"
    },
    {
        "value": 6003,
        "NOCCode": "4216",
        "JobTitle": "beauty culture school instructor"
    },
    {
        "value": 6004,
        "NOCCode": "4216",
        "JobTitle": "child safety educator"
    },
    {
        "value": 6005,
        "NOCCode": "4216",
        "JobTitle": "driver's licence examiner"
    },
    {
        "value": 6006,
        "NOCCode": "4216",
        "JobTitle": "driving examiner"
    },
    {
        "value": 6007,
        "NOCCode": "4216",
        "JobTitle": "driving instructor"
    },
    {
        "value": 6008,
        "NOCCode": "4216",
        "JobTitle": "driving school instructor"
    },
    {
        "value": 6009,
        "NOCCode": "4216",
        "JobTitle": "first aid instructor"
    },
    {
        "value": 6010,
        "NOCCode": "4216",
        "JobTitle": "gourmet cooking instructor – non-vocational"
    },
    {
        "value": 6011,
        "NOCCode": "4216",
        "JobTitle": "instructor, auto-driving"
    },
    {
        "value": 6012,
        "NOCCode": "4216",
        "JobTitle": "instructor, beauty culture school"
    },
    {
        "value": 6013,
        "NOCCode": "4216",
        "JobTitle": "instructor, first aid"
    },
    {
        "value": 6014,
        "NOCCode": "4216",
        "JobTitle": "instructor, gourmet cooking – non-vocational"
    },
    {
        "value": 6015,
        "NOCCode": "4216",
        "JobTitle": "instructor, modelling and finishing school"
    },
    {
        "value": 6016,
        "NOCCode": "4216",
        "JobTitle": "instructor, motorcycle driving"
    },
    {
        "value": 6017,
        "NOCCode": "4216",
        "JobTitle": "instructor, sailing"
    },
    {
        "value": 6018,
        "NOCCode": "4216",
        "JobTitle": "instructor, self-improvement course"
    },
    {
        "value": 6019,
        "NOCCode": "4216",
        "JobTitle": "instructor, sewing – non-vocational"
    },
    {
        "value": 6020,
        "NOCCode": "4216",
        "JobTitle": "instructor, small boat navigation"
    },
    {
        "value": 6021,
        "NOCCode": "4216",
        "JobTitle": "motorcycle driving instructor"
    },
    {
        "value": 6022,
        "NOCCode": "4216",
        "JobTitle": "sailing instructor"
    },
    {
        "value": 6023,
        "NOCCode": "4216",
        "JobTitle": "self-improvement course instructor"
    },
    {
        "value": 6024,
        "NOCCode": "4216",
        "JobTitle": "sewing instructor – non-vocational"
    },
    {
        "value": 6025,
        "NOCCode": "4216",
        "JobTitle": "sewing teacher – non-vocational"
    },
    {
        "value": 6026,
        "NOCCode": "4216",
        "JobTitle": "small-boat navigation instructor"
    },
    {
        "value": 6027,
        "NOCCode": "4216",
        "JobTitle": "tutor – elementary school subjects"
    },
    {
        "value": 6028,
        "NOCCode": "4216",
        "JobTitle": "tutor – secondary school subjects"
    },
    {
        "value": 6029,
        "NOCCode": "4217",
        "JobTitle": "animator – spiritual guidance"
    },
    {
        "value": 6030,
        "NOCCode": "4217",
        "JobTitle": "brother/sister – religion"
    },
    {
        "value": 6031,
        "NOCCode": "4217",
        "JobTitle": "Christian Science nurse – non-medical"
    },
    {
        "value": 6032,
        "NOCCode": "4217",
        "JobTitle": "Christian science practitioner"
    },
    {
        "value": 6033,
        "NOCCode": "4217",
        "JobTitle": "Christian science worker"
    },
    {
        "value": 6034,
        "NOCCode": "4217",
        "JobTitle": "church worker"
    },
    {
        "value": 6035,
        "NOCCode": "4217",
        "JobTitle": "deacon"
    },
    {
        "value": 6036,
        "NOCCode": "4217",
        "JobTitle": "deaconess"
    },
    {
        "value": 6037,
        "NOCCode": "4217",
        "JobTitle": "exegete"
    },
    {
        "value": 6038,
        "NOCCode": "4217",
        "JobTitle": "faith healer"
    },
    {
        "value": 6039,
        "NOCCode": "4217",
        "JobTitle": "gospel worker"
    },
    {
        "value": 6040,
        "NOCCode": "4217",
        "JobTitle": "hazzan"
    },
    {
        "value": 6041,
        "NOCCode": "4217",
        "JobTitle": "healer, faith"
    },
    {
        "value": 6042,
        "NOCCode": "4217",
        "JobTitle": "home mission worker – religion"
    },
    {
        "value": 6043,
        "NOCCode": "4217",
        "JobTitle": "missionary"
    },
    {
        "value": 6044,
        "NOCCode": "4217",
        "JobTitle": "mohel"
    },
    {
        "value": 6045,
        "NOCCode": "4217",
        "JobTitle": "monk"
    },
    {
        "value": 6046,
        "NOCCode": "4217",
        "JobTitle": "mother superior/religious superior"
    },
    {
        "value": 6047,
        "NOCCode": "4217",
        "JobTitle": "novice – religion"
    },
    {
        "value": 6048,
        "NOCCode": "4217",
        "JobTitle": "nun"
    },
    {
        "value": 6049,
        "NOCCode": "4217",
        "JobTitle": "panthee"
    },
    {
        "value": 6050,
        "NOCCode": "4217",
        "JobTitle": "pastoral animator"
    },
    {
        "value": 6051,
        "NOCCode": "4217",
        "JobTitle": "postulant"
    },
    {
        "value": 6052,
        "NOCCode": "4217",
        "JobTitle": "ragi"
    },
    {
        "value": 6053,
        "NOCCode": "4217",
        "JobTitle": "religious education worker"
    },
    {
        "value": 6054,
        "NOCCode": "4217",
        "JobTitle": "religious superior – religious community"
    },
    {
        "value": 6055,
        "NOCCode": "4217",
        "JobTitle": "religious superior/mother superior"
    },
    {
        "value": 6056,
        "NOCCode": "4217",
        "JobTitle": "Salvation Army captain"
    },
    {
        "value": 6057,
        "NOCCode": "4217",
        "JobTitle": "Salvation Army field worker"
    },
    {
        "value": 6058,
        "NOCCode": "4217",
        "JobTitle": "Salvation Army worker"
    },
    {
        "value": 6059,
        "NOCCode": "4217",
        "JobTitle": "sister/brother – religion"
    },
    {
        "value": 6060,
        "NOCCode": "4311",
        "JobTitle": "bodyguard – police"
    },
    {
        "value": 6061,
        "NOCCode": "4311",
        "JobTitle": "cadet, police"
    },
    {
        "value": 6062,
        "NOCCode": "4311",
        "JobTitle": "Canadian Security Intelligence Service (CSIS) investigator"
    },
    {
        "value": 6063,
        "NOCCode": "4311",
        "JobTitle": "canine-team member – police"
    },
    {
        "value": 6064,
        "NOCCode": "4311",
        "JobTitle": "community police officer"
    },
    {
        "value": 6065,
        "NOCCode": "4311",
        "JobTitle": "community relations officer – police"
    },
    {
        "value": 6066,
        "NOCCode": "4311",
        "JobTitle": "community services officer – police"
    },
    {
        "value": 6067,
        "NOCCode": "4311",
        "JobTitle": "constable"
    },
    {
        "value": 6068,
        "NOCCode": "4311",
        "JobTitle": "counterfeit and forgery section detective"
    },
    {
        "value": 6069,
        "NOCCode": "4311",
        "JobTitle": "crime prevention constable"
    },
    {
        "value": 6070,
        "NOCCode": "4311",
        "JobTitle": "CSIS (Canadian Security Intelligence Service) investigator"
    },
    {
        "value": 6071,
        "NOCCode": "4311",
        "JobTitle": "desk officer – police"
    },
    {
        "value": 6072,
        "NOCCode": "4311",
        "JobTitle": "detective – police"
    },
    {
        "value": 6073,
        "NOCCode": "4311",
        "JobTitle": "detective, counterfeit and forgery section"
    },
    {
        "value": 6074,
        "NOCCode": "4311",
        "JobTitle": "detective, narcotics squad"
    },
    {
        "value": 6075,
        "NOCCode": "4311",
        "JobTitle": "detective, vice squad"
    },
    {
        "value": 6076,
        "NOCCode": "4311",
        "JobTitle": "divisional diving supervisor – police"
    },
    {
        "value": 6077,
        "NOCCode": "4311",
        "JobTitle": "dog master – police"
    },
    {
        "value": 6078,
        "NOCCode": "4311",
        "JobTitle": "drug investigator – police"
    },
    {
        "value": 6079,
        "NOCCode": "4311",
        "JobTitle": "duty officer, headquarters – police"
    },
    {
        "value": 6080,
        "NOCCode": "4311",
        "JobTitle": "duty officer, station – police"
    },
    {
        "value": 6081,
        "NOCCode": "4311",
        "JobTitle": "education relations officer – policing"
    },
    {
        "value": 6082,
        "NOCCode": "4311",
        "JobTitle": "ethnic relations officer – police"
    },
    {
        "value": 6083,
        "NOCCode": "4311",
        "JobTitle": "evidence technician – police"
    },
    {
        "value": 6084,
        "NOCCode": "4311",
        "JobTitle": "harbour police officer"
    },
    {
        "value": 6085,
        "NOCCode": "4311",
        "JobTitle": "harbour police sergeant"
    },
    {
        "value": 6086,
        "NOCCode": "4311",
        "JobTitle": "harbour policeman/woman"
    },
    {
        "value": 6087,
        "NOCCode": "4311",
        "JobTitle": "headquarters duty officer – police"
    },
    {
        "value": 6088,
        "NOCCode": "4311",
        "JobTitle": "highway patrol officer"
    },
    {
        "value": 6089,
        "NOCCode": "4311",
        "JobTitle": "highway patrolman/woman"
    },
    {
        "value": 6090,
        "NOCCode": "4311",
        "JobTitle": "identification officer – police"
    },
    {
        "value": 6091,
        "NOCCode": "4311",
        "JobTitle": "investigator – police"
    },
    {
        "value": 6092,
        "NOCCode": "4311",
        "JobTitle": "investigator, railway – police"
    },
    {
        "value": 6093,
        "NOCCode": "4311",
        "JobTitle": "morality officer – police"
    },
    {
        "value": 6094,
        "NOCCode": "4311",
        "JobTitle": "motorcycle patrolman/woman – police"
    },
    {
        "value": 6095,
        "NOCCode": "4311",
        "JobTitle": "mounted police constable"
    },
    {
        "value": 6096,
        "NOCCode": "4311",
        "JobTitle": "mounted policeman/woman"
    },
    {
        "value": 6097,
        "NOCCode": "4311",
        "JobTitle": "narcotics squad detective"
    },
    {
        "value": 6098,
        "NOCCode": "4311",
        "JobTitle": "officer, community relations – police"
    },
    {
        "value": 6099,
        "NOCCode": "4311",
        "JobTitle": "officer, recruitment and training – police"
    },
    {
        "value": 6100,
        "NOCCode": "4311",
        "JobTitle": "patrol officer – police"
    },
    {
        "value": 6101,
        "NOCCode": "4311",
        "JobTitle": "patrol officer, traffic"
    },
    {
        "value": 6102,
        "NOCCode": "4311",
        "JobTitle": "peace officer"
    },
    {
        "value": 6103,
        "NOCCode": "4311",
        "JobTitle": "plain-clothes officer"
    },
    {
        "value": 6104,
        "NOCCode": "4311",
        "JobTitle": "police cadet"
    },
    {
        "value": 6105,
        "NOCCode": "4311",
        "JobTitle": "police constable"
    },
    {
        "value": 6106,
        "NOCCode": "4311",
        "JobTitle": "police constable, railway"
    },
    {
        "value": 6107,
        "NOCCode": "4311",
        "JobTitle": "police corporal"
    },
    {
        "value": 6108,
        "NOCCode": "4311",
        "JobTitle": "police diver – salvage"
    },
    {
        "value": 6109,
        "NOCCode": "4311",
        "JobTitle": "police investigator"
    },
    {
        "value": 6110,
        "NOCCode": "4311",
        "JobTitle": "police officer"
    },
    {
        "value": 6111,
        "NOCCode": "4311",
        "JobTitle": "police sergeant"
    },
    {
        "value": 6112,
        "NOCCode": "4311",
        "JobTitle": "policeman/woman"
    },
    {
        "value": 6113,
        "NOCCode": "4311",
        "JobTitle": "railway guard – police"
    },
    {
        "value": 6114,
        "NOCCode": "4311",
        "JobTitle": "railway investigator – police"
    },
    {
        "value": 6115,
        "NOCCode": "4311",
        "JobTitle": "railway police constable"
    },
    {
        "value": 6116,
        "NOCCode": "4311",
        "JobTitle": "railway police officer"
    },
    {
        "value": 6117,
        "NOCCode": "4311",
        "JobTitle": "railway special agent – police"
    },
    {
        "value": 6118,
        "NOCCode": "4311",
        "JobTitle": "RCMP (Royal Canadian Mounted Police) officer"
    },
    {
        "value": 6119,
        "NOCCode": "4311",
        "JobTitle": "RCMP officer"
    },
    {
        "value": 6120,
        "NOCCode": "4311",
        "JobTitle": "recruitment and training officer – police"
    },
    {
        "value": 6121,
        "NOCCode": "4311",
        "JobTitle": "Royal Canadian Mounted Police (RCMP) officer"
    },
    {
        "value": 6122,
        "NOCCode": "4311",
        "JobTitle": "safety patrol officer – police"
    },
    {
        "value": 6123,
        "NOCCode": "4311",
        "JobTitle": "school liaison officer – police"
    },
    {
        "value": 6124,
        "NOCCode": "4311",
        "JobTitle": "school safety officer – police"
    },
    {
        "value": 6125,
        "NOCCode": "4311",
        "JobTitle": "scuba diver – police"
    },
    {
        "value": 6126,
        "NOCCode": "4311",
        "JobTitle": "scuba-diving specialist – police"
    },
    {
        "value": 6127,
        "NOCCode": "4311",
        "JobTitle": "secret service officer"
    },
    {
        "value": 6128,
        "NOCCode": "4311",
        "JobTitle": "security constable – police"
    },
    {
        "value": 6129,
        "NOCCode": "4311",
        "JobTitle": "security officer – police"
    },
    {
        "value": 6130,
        "NOCCode": "4311",
        "JobTitle": "staff sergeant – police"
    },
    {
        "value": 6131,
        "NOCCode": "4311",
        "JobTitle": "station duty officer – police"
    },
    {
        "value": 6132,
        "NOCCode": "4311",
        "JobTitle": "station officer – police"
    },
    {
        "value": 6133,
        "NOCCode": "4311",
        "JobTitle": "traffic patrol officer"
    },
    {
        "value": 6134,
        "NOCCode": "4311",
        "JobTitle": "vice squad detective"
    },
    {
        "value": 6135,
        "NOCCode": "4312",
        "JobTitle": "airport firefighter"
    },
    {
        "value": 6136,
        "NOCCode": "4312",
        "JobTitle": "apprentice, firefighter"
    },
    {
        "value": 6137,
        "NOCCode": "4312",
        "JobTitle": "communication service firefighter"
    },
    {
        "value": 6138,
        "NOCCode": "4312",
        "JobTitle": "crash firefighter"
    },
    {
        "value": 6139,
        "NOCCode": "4312",
        "JobTitle": "fire captain"
    },
    {
        "value": 6140,
        "NOCCode": "4312",
        "JobTitle": "fire department platoon chief"
    },
    {
        "value": 6141,
        "NOCCode": "4312",
        "JobTitle": "fire inspector"
    },
    {
        "value": 6142,
        "NOCCode": "4312",
        "JobTitle": "fire lieutenant"
    },
    {
        "value": 6143,
        "NOCCode": "4312",
        "JobTitle": "fire prevention officer"
    },
    {
        "value": 6144,
        "NOCCode": "4312",
        "JobTitle": "fire safety inspection firefighter"
    },
    {
        "value": 6145,
        "NOCCode": "4312",
        "JobTitle": "firefighter"
    },
    {
        "value": 6146,
        "NOCCode": "4312",
        "JobTitle": "firefighter apprentice"
    },
    {
        "value": 6147,
        "NOCCode": "4312",
        "JobTitle": "firefighter, communication service"
    },
    {
        "value": 6148,
        "NOCCode": "4312",
        "JobTitle": "firefighter, fire safety inspection"
    },
    {
        "value": 6149,
        "NOCCode": "4312",
        "JobTitle": "firefighter, industrial complex"
    },
    {
        "value": 6150,
        "NOCCode": "4312",
        "JobTitle": "firefighter, shipboard"
    },
    {
        "value": 6151,
        "NOCCode": "4312",
        "JobTitle": "firefighter, structural"
    },
    {
        "value": 6152,
        "NOCCode": "4312",
        "JobTitle": "firefighter, training"
    },
    {
        "value": 6153,
        "NOCCode": "4312",
        "JobTitle": "firefighter-inspector"
    },
    {
        "value": 6154,
        "NOCCode": "4312",
        "JobTitle": "industrial firefighter"
    },
    {
        "value": 6155,
        "NOCCode": "4312",
        "JobTitle": "lieutenant, firefighters"
    },
    {
        "value": 6156,
        "NOCCode": "4312",
        "JobTitle": "platoon chief, fire department"
    },
    {
        "value": 6157,
        "NOCCode": "4313",
        "JobTitle": "able seaman – armed forces"
    },
    {
        "value": 6158,
        "NOCCode": "4313",
        "JobTitle": "armour defence gunner"
    },
    {
        "value": 6159,
        "NOCCode": "4313",
        "JobTitle": "artillery soldier"
    },
    {
        "value": 6160,
        "NOCCode": "4313",
        "JobTitle": "assault pioneer"
    },
    {
        "value": 6161,
        "NOCCode": "4313",
        "JobTitle": "chief petty officer first class"
    },
    {
        "value": 6162,
        "NOCCode": "4313",
        "JobTitle": "chief petty officer second class"
    },
    {
        "value": 6163,
        "NOCCode": "4313",
        "JobTitle": "chief warrant officer"
    },
    {
        "value": 6164,
        "NOCCode": "4313",
        "JobTitle": "corporal"
    },
    {
        "value": 6165,
        "NOCCode": "4313",
        "JobTitle": "infanteer"
    },
    {
        "value": 6166,
        "NOCCode": "4313",
        "JobTitle": "infantry soldier"
    },
    {
        "value": 6167,
        "NOCCode": "4313",
        "JobTitle": "leading seaman"
    },
    {
        "value": 6168,
        "NOCCode": "4313",
        "JobTitle": "master corporal"
    },
    {
        "value": 6169,
        "NOCCode": "4313",
        "JobTitle": "master seaman"
    },
    {
        "value": 6170,
        "NOCCode": "4313",
        "JobTitle": "master warrant officer"
    },
    {
        "value": 6171,
        "NOCCode": "4313",
        "JobTitle": "mortarman/woman"
    },
    {
        "value": 6172,
        "NOCCode": "4313",
        "JobTitle": "naval acoustics operator"
    },
    {
        "value": 6173,
        "NOCCode": "4313",
        "JobTitle": "naval electronics technician – armed forces"
    },
    {
        "value": 6174,
        "NOCCode": "4313",
        "JobTitle": "ordinary seaman – armed forces"
    },
    {
        "value": 6175,
        "NOCCode": "4313",
        "JobTitle": "petty officer first class"
    },
    {
        "value": 6176,
        "NOCCode": "4313",
        "JobTitle": "petty officer second class"
    },
    {
        "value": 6177,
        "NOCCode": "4313",
        "JobTitle": "private"
    },
    {
        "value": 6178,
        "NOCCode": "4313",
        "JobTitle": "private recruit"
    },
    {
        "value": 6179,
        "NOCCode": "4313",
        "JobTitle": "reconnaissance patrolman"
    },
    {
        "value": 6180,
        "NOCCode": "4313",
        "JobTitle": "sergeant"
    },
    {
        "value": 6181,
        "NOCCode": "4313",
        "JobTitle": "trooper"
    },
    {
        "value": 6182,
        "NOCCode": "4313",
        "JobTitle": "warrant officer"
    },
    {
        "value": 6183,
        "NOCCode": "4411",
        "JobTitle": "au pair"
    },
    {
        "value": 6184,
        "NOCCode": "4411",
        "JobTitle": "au pair girl"
    },
    {
        "value": 6185,
        "NOCCode": "4411",
        "JobTitle": "babysitter"
    },
    {
        "value": 6186,
        "NOCCode": "4411",
        "JobTitle": "caregiver, babysitting"
    },
    {
        "value": 6187,
        "NOCCode": "4411",
        "JobTitle": "child care provider – private home"
    },
    {
        "value": 6188,
        "NOCCode": "4411",
        "JobTitle": "child caregiver – private home"
    },
    {
        "value": 6189,
        "NOCCode": "4411",
        "JobTitle": "child minder"
    },
    {
        "value": 6190,
        "NOCCode": "4411",
        "JobTitle": "children's guardian"
    },
    {
        "value": 6191,
        "NOCCode": "4411",
        "JobTitle": "family child care provider"
    },
    {
        "value": 6192,
        "NOCCode": "4411",
        "JobTitle": "foster father"
    },
    {
        "value": 6193,
        "NOCCode": "4411",
        "JobTitle": "foster mother"
    },
    {
        "value": 6194,
        "NOCCode": "4411",
        "JobTitle": "governess – child care"
    },
    {
        "value": 6195,
        "NOCCode": "4411",
        "JobTitle": "home child care provider"
    },
    {
        "value": 6196,
        "NOCCode": "4411",
        "JobTitle": "kinship care provider"
    },
    {
        "value": 6197,
        "NOCCode": "4411",
        "JobTitle": "kinship caregiver"
    },
    {
        "value": 6198,
        "NOCCode": "4411",
        "JobTitle": "live-in caregiver, child care"
    },
    {
        "value": 6199,
        "NOCCode": "4411",
        "JobTitle": "live-in nanny"
    },
    {
        "value": 6200,
        "NOCCode": "4411",
        "JobTitle": "mother's helper"
    },
    {
        "value": 6201,
        "NOCCode": "4411",
        "JobTitle": "nanny"
    },
    {
        "value": 6202,
        "NOCCode": "4411",
        "JobTitle": "parent, foster"
    },
    {
        "value": 6203,
        "NOCCode": "4411",
        "JobTitle": "parent's helper"
    },
    {
        "value": 6204,
        "NOCCode": "4412",
        "JobTitle": "attendant for persons with a visual impairment – home care"
    },
    {
        "value": 6205,
        "NOCCode": "4412",
        "JobTitle": "attendant for persons with disabilities – home care"
    },
    {
        "value": 6206,
        "NOCCode": "4412",
        "JobTitle": "client care attendant – home care"
    },
    {
        "value": 6207,
        "NOCCode": "4412",
        "JobTitle": "companion"
    },
    {
        "value": 6208,
        "NOCCode": "4412",
        "JobTitle": "domestic housekeeper"
    },
    {
        "value": 6209,
        "NOCCode": "4412",
        "JobTitle": "family caregiver"
    },
    {
        "value": 6210,
        "NOCCode": "4412",
        "JobTitle": "family worker – home care"
    },
    {
        "value": 6211,
        "NOCCode": "4412",
        "JobTitle": "home health care worker"
    },
    {
        "value": 6212,
        "NOCCode": "4412",
        "JobTitle": "home support worker"
    },
    {
        "value": 6213,
        "NOCCode": "4412",
        "JobTitle": "home-care worker"
    },
    {
        "value": 6214,
        "NOCCode": "4412",
        "JobTitle": "homemaker"
    },
    {
        "value": 6215,
        "NOCCode": "4412",
        "JobTitle": "housekeeper"
    },
    {
        "value": 6216,
        "NOCCode": "4412",
        "JobTitle": "housekeeper, live-in"
    },
    {
        "value": 6217,
        "NOCCode": "4412",
        "JobTitle": "live-in caregiver – persons with disabilities"
    },
    {
        "value": 6218,
        "NOCCode": "4412",
        "JobTitle": "live-in caregiver – seniors"
    },
    {
        "value": 6219,
        "NOCCode": "4412",
        "JobTitle": "personal aide – home support"
    },
    {
        "value": 6220,
        "NOCCode": "4412",
        "JobTitle": "personal assistant – home care"
    },
    {
        "value": 6221,
        "NOCCode": "4412",
        "JobTitle": "personal care aide – home care"
    },
    {
        "value": 6222,
        "NOCCode": "4412",
        "JobTitle": "personal care attendant – home care"
    },
    {
        "value": 6223,
        "NOCCode": "4412",
        "JobTitle": "personal care provider – home care"
    },
    {
        "value": 6224,
        "NOCCode": "4412",
        "JobTitle": "personal support aide – home aide"
    },
    {
        "value": 6225,
        "NOCCode": "4412",
        "JobTitle": "personal support worker – home support"
    },
    {
        "value": 6226,
        "NOCCode": "4412",
        "JobTitle": "respite worker – home support"
    },
    {
        "value": 6227,
        "NOCCode": "4412",
        "JobTitle": "teaching homemaker"
    },
    {
        "value": 6228,
        "NOCCode": "4412",
        "JobTitle": "visiting homemaker"
    },
    {
        "value": 6229,
        "NOCCode": "4413",
        "JobTitle": "aide, remedial education"
    },
    {
        "value": 6230,
        "NOCCode": "4413",
        "JobTitle": "aide, teacher's"
    },
    {
        "value": 6231,
        "NOCCode": "4413",
        "JobTitle": "assistant, educational"
    },
    {
        "value": 6232,
        "NOCCode": "4413",
        "JobTitle": "assistant, secondary school teacher's"
    },
    {
        "value": 6233,
        "NOCCode": "4413",
        "JobTitle": "assistant, special education"
    },
    {
        "value": 6234,
        "NOCCode": "4413",
        "JobTitle": "assistant, teacher's"
    },
    {
        "value": 6235,
        "NOCCode": "4413",
        "JobTitle": "attendant, child care – elementary school"
    },
    {
        "value": 6236,
        "NOCCode": "4413",
        "JobTitle": "child care attendant – elementary school"
    },
    {
        "value": 6237,
        "NOCCode": "4413",
        "JobTitle": "classroom assistant – primary and secondary school"
    },
    {
        "value": 6238,
        "NOCCode": "4413",
        "JobTitle": "educational assistant"
    },
    {
        "value": 6239,
        "NOCCode": "4413",
        "JobTitle": "educational resources assistant"
    },
    {
        "value": 6240,
        "NOCCode": "4413",
        "JobTitle": "elementary school teacher's aide"
    },
    {
        "value": 6241,
        "NOCCode": "4413",
        "JobTitle": "elementary school teacher's assistant"
    },
    {
        "value": 6242,
        "NOCCode": "4413",
        "JobTitle": "helper, teacher's"
    },
    {
        "value": 6243,
        "NOCCode": "4413",
        "JobTitle": "homework assistant"
    },
    {
        "value": 6244,
        "NOCCode": "4413",
        "JobTitle": "instructional assistant – primary and secondary school"
    },
    {
        "value": 6245,
        "NOCCode": "4413",
        "JobTitle": "lunchroom supervisor – school"
    },
    {
        "value": 6246,
        "NOCCode": "4413",
        "JobTitle": "lunchtime supervisor – primary and secondary school"
    },
    {
        "value": 6247,
        "NOCCode": "4413",
        "JobTitle": "paraeducator"
    },
    {
        "value": 6248,
        "NOCCode": "4413",
        "JobTitle": "paraprofessional educator"
    },
    {
        "value": 6249,
        "NOCCode": "4413",
        "JobTitle": "program assistant, education"
    },
    {
        "value": 6250,
        "NOCCode": "4413",
        "JobTitle": "remedial education aide"
    },
    {
        "value": 6251,
        "NOCCode": "4413",
        "JobTitle": "school lunchroom supervisor"
    },
    {
        "value": 6252,
        "NOCCode": "4413",
        "JobTitle": "secondary school teacher's aide"
    },
    {
        "value": 6253,
        "NOCCode": "4413",
        "JobTitle": "secondary school teacher's assistant"
    },
    {
        "value": 6254,
        "NOCCode": "4413",
        "JobTitle": "special education assistant"
    },
    {
        "value": 6255,
        "NOCCode": "4413",
        "JobTitle": "special needs educational assistant"
    },
    {
        "value": 6256,
        "NOCCode": "4413",
        "JobTitle": "special programs aide – education"
    },
    {
        "value": 6257,
        "NOCCode": "4413",
        "JobTitle": "student monitor"
    },
    {
        "value": 6258,
        "NOCCode": "4413",
        "JobTitle": "student support assistant"
    },
    {
        "value": 6259,
        "NOCCode": "4413",
        "JobTitle": "teacher's aide"
    },
    {
        "value": 6260,
        "NOCCode": "4413",
        "JobTitle": "teacher's assistant"
    },
    {
        "value": 6261,
        "NOCCode": "4413",
        "JobTitle": "teacher's helper"
    },
    {
        "value": 6262,
        "NOCCode": "4413",
        "JobTitle": "teaching staff assistant, primary and secondary school"
    },
    {
        "value": 6263,
        "NOCCode": "4421",
        "JobTitle": "automobile repossessor"
    },
    {
        "value": 6264,
        "NOCCode": "4421",
        "JobTitle": "bailiff"
    },
    {
        "value": 6265,
        "NOCCode": "4421",
        "JobTitle": "car repossessor"
    },
    {
        "value": 6266,
        "NOCCode": "4421",
        "JobTitle": "chief deputy sheriff"
    },
    {
        "value": 6267,
        "NOCCode": "4421",
        "JobTitle": "chief sheriff"
    },
    {
        "value": 6268,
        "NOCCode": "4421",
        "JobTitle": "court bailiff"
    },
    {
        "value": 6269,
        "NOCCode": "4421",
        "JobTitle": "court enforcement officer"
    },
    {
        "value": 6270,
        "NOCCode": "4421",
        "JobTitle": "deputy sheriff"
    },
    {
        "value": 6271,
        "NOCCode": "4421",
        "JobTitle": "district sheriff"
    },
    {
        "value": 6272,
        "NOCCode": "4421",
        "JobTitle": "private bailiff"
    },
    {
        "value": 6273,
        "NOCCode": "4421",
        "JobTitle": "process server"
    },
    {
        "value": 6274,
        "NOCCode": "4421",
        "JobTitle": "sheriff"
    },
    {
        "value": 6275,
        "NOCCode": "4421",
        "JobTitle": "sheriff's bailiff"
    },
    {
        "value": 6276,
        "NOCCode": "4421",
        "JobTitle": "sheriff's officer"
    },
    {
        "value": 6277,
        "NOCCode": "4421",
        "JobTitle": "sub-sheriff"
    },
    {
        "value": 6278,
        "NOCCode": "4421",
        "JobTitle": "writ server – bailiff"
    },
    {
        "value": 6279,
        "NOCCode": "4422",
        "JobTitle": "convict guard"
    },
    {
        "value": 6280,
        "NOCCode": "4422",
        "JobTitle": "correctional facility guard"
    },
    {
        "value": 6281,
        "NOCCode": "4422",
        "JobTitle": "correctional institution guard"
    },
    {
        "value": 6282,
        "NOCCode": "4422",
        "JobTitle": "correctional institution head matron"
    },
    {
        "value": 6283,
        "NOCCode": "4422",
        "JobTitle": "correctional officer"
    },
    {
        "value": 6284,
        "NOCCode": "4422",
        "JobTitle": "correctional officers supervisor"
    },
    {
        "value": 6285,
        "NOCCode": "4422",
        "JobTitle": "correctional service officer"
    },
    {
        "value": 6286,
        "NOCCode": "4422",
        "JobTitle": "correctional services unit supervisor"
    },
    {
        "value": 6287,
        "NOCCode": "4422",
        "JobTitle": "corrections officer"
    },
    {
        "value": 6288,
        "NOCCode": "4422",
        "JobTitle": "detention guard"
    },
    {
        "value": 6289,
        "NOCCode": "4422",
        "JobTitle": "guard, correctional facility"
    },
    {
        "value": 6290,
        "NOCCode": "4422",
        "JobTitle": "guard, correctional institution"
    },
    {
        "value": 6291,
        "NOCCode": "4422",
        "JobTitle": "head correctional officer"
    },
    {
        "value": 6292,
        "NOCCode": "4422",
        "JobTitle": "head matron – correctional service"
    },
    {
        "value": 6293,
        "NOCCode": "4422",
        "JobTitle": "institution guard – correctional service"
    },
    {
        "value": 6294,
        "NOCCode": "4422",
        "JobTitle": "jail guard"
    },
    {
        "value": 6295,
        "NOCCode": "4422",
        "JobTitle": "jailer"
    },
    {
        "value": 6296,
        "NOCCode": "4422",
        "JobTitle": "matron – correctional service"
    },
    {
        "value": 6297,
        "NOCCode": "4422",
        "JobTitle": "penal institution guard"
    },
    {
        "value": 6298,
        "NOCCode": "4422",
        "JobTitle": "penitentiary guard"
    },
    {
        "value": 6299,
        "NOCCode": "4422",
        "JobTitle": "prison guard"
    },
    {
        "value": 6300,
        "NOCCode": "4422",
        "JobTitle": "prison officer"
    },
    {
        "value": 6301,
        "NOCCode": "4422",
        "JobTitle": "reformatory guard"
    },
    {
        "value": 6302,
        "NOCCode": "4422",
        "JobTitle": "supervisor, correctional officers"
    },
    {
        "value": 6303,
        "NOCCode": "4423",
        "JobTitle": "animal control inspector"
    },
    {
        "value": 6304,
        "NOCCode": "4423",
        "JobTitle": "animal control officer"
    },
    {
        "value": 6305,
        "NOCCode": "4423",
        "JobTitle": "animal control supervisor"
    },
    {
        "value": 6306,
        "NOCCode": "4423",
        "JobTitle": "by-law enforcement officer"
    },
    {
        "value": 6307,
        "NOCCode": "4423",
        "JobTitle": "by-law enforcement officers supervisor"
    },
    {
        "value": 6308,
        "NOCCode": "4423",
        "JobTitle": "commercial transport inspector"
    },
    {
        "value": 6309,
        "NOCCode": "4423",
        "JobTitle": "commercial vehicle enforcement officer"
    },
    {
        "value": 6310,
        "NOCCode": "4423",
        "JobTitle": "development control officer"
    },
    {
        "value": 6311,
        "NOCCode": "4423",
        "JobTitle": "dog catcher"
    },
    {
        "value": 6312,
        "NOCCode": "4423",
        "JobTitle": "dog warden"
    },
    {
        "value": 6313,
        "NOCCode": "4423",
        "JobTitle": "garbage collection inspector"
    },
    {
        "value": 6314,
        "NOCCode": "4423",
        "JobTitle": "highway scale operator"
    },
    {
        "value": 6315,
        "NOCCode": "4423",
        "JobTitle": "Humane Society inspector"
    },
    {
        "value": 6316,
        "NOCCode": "4423",
        "JobTitle": "Humane Society officer"
    },
    {
        "value": 6317,
        "NOCCode": "4423",
        "JobTitle": "inspector – by-law enforcement"
    },
    {
        "value": 6318,
        "NOCCode": "4423",
        "JobTitle": "inspector – municipal law enforcement"
    },
    {
        "value": 6319,
        "NOCCode": "4423",
        "JobTitle": "licence inspector"
    },
    {
        "value": 6320,
        "NOCCode": "4423",
        "JobTitle": "liquor board licence inspector"
    },
    {
        "value": 6321,
        "NOCCode": "4423",
        "JobTitle": "liquor licence inspector"
    },
    {
        "value": 6322,
        "NOCCode": "4423",
        "JobTitle": "lottery supervisor – government services"
    },
    {
        "value": 6323,
        "NOCCode": "4423",
        "JobTitle": "municipal law enforcement officer"
    },
    {
        "value": 6324,
        "NOCCode": "4423",
        "JobTitle": "municipal law enforcement officers supervisor"
    },
    {
        "value": 6325,
        "NOCCode": "4423",
        "JobTitle": "officer, by-law enforcement"
    },
    {
        "value": 6326,
        "NOCCode": "4423",
        "JobTitle": "officer, municipal law enforcement"
    },
    {
        "value": 6327,
        "NOCCode": "4423",
        "JobTitle": "parking control officer"
    },
    {
        "value": 6328,
        "NOCCode": "4423",
        "JobTitle": "pound officer"
    },
    {
        "value": 6329,
        "NOCCode": "4423",
        "JobTitle": "property standards inspector"
    },
    {
        "value": 6330,
        "NOCCode": "4423",
        "JobTitle": "property use inspector"
    },
    {
        "value": 6331,
        "NOCCode": "4423",
        "JobTitle": "right of way inspector"
    },
    {
        "value": 6332,
        "NOCCode": "4423",
        "JobTitle": "road transport inspector"
    },
    {
        "value": 6333,
        "NOCCode": "4423",
        "JobTitle": "service station inspector"
    },
    {
        "value": 6334,
        "NOCCode": "4423",
        "JobTitle": "sign inspector"
    },
    {
        "value": 6335,
        "NOCCode": "4423",
        "JobTitle": "subdivision control officer"
    },
    {
        "value": 6336,
        "NOCCode": "4423",
        "JobTitle": "supervisor, animal control"
    },
    {
        "value": 6337,
        "NOCCode": "4423",
        "JobTitle": "supervisor, by-law enforcement officers"
    },
    {
        "value": 6338,
        "NOCCode": "4423",
        "JobTitle": "supervisor, municipal law enforcement officers"
    },
    {
        "value": 6339,
        "NOCCode": "4423",
        "JobTitle": "taxi inspector"
    },
    {
        "value": 6340,
        "NOCCode": "4423",
        "JobTitle": "traffic supervisor – by-law enforcement"
    },
    {
        "value": 6341,
        "NOCCode": "4423",
        "JobTitle": "transport compliance officer"
    },
    {
        "value": 6342,
        "NOCCode": "4423",
        "JobTitle": "transportation enforcement officer"
    },
    {
        "value": 6343,
        "NOCCode": "4423",
        "JobTitle": "weigh station operator – by-law enforcement"
    },
    {
        "value": 6344,
        "NOCCode": "4423",
        "JobTitle": "zoning enforcement officer"
    },
    {
        "value": 6345,
        "NOCCode": "4423",
        "JobTitle": "zoning inspector"
    },
    {
        "value": 6346,
        "NOCCode": "5111",
        "JobTitle": "art librarian"
    },
    {
        "value": 6347,
        "NOCCode": "5111",
        "JobTitle": "associate librarian"
    },
    {
        "value": 6348,
        "NOCCode": "5111",
        "JobTitle": "automation librarian"
    },
    {
        "value": 6349,
        "NOCCode": "5111",
        "JobTitle": "bibliographer"
    },
    {
        "value": 6350,
        "NOCCode": "5111",
        "JobTitle": "bookmobile librarian"
    },
    {
        "value": 6351,
        "NOCCode": "5111",
        "JobTitle": "cataloguer – library"
    },
    {
        "value": 6352,
        "NOCCode": "5111",
        "JobTitle": "children's services librarian"
    },
    {
        "value": 6353,
        "NOCCode": "5111",
        "JobTitle": "classifier – library"
    },
    {
        "value": 6354,
        "NOCCode": "5111",
        "JobTitle": "computer search librarian"
    },
    {
        "value": 6355,
        "NOCCode": "5111",
        "JobTitle": "cybrarian"
    },
    {
        "value": 6356,
        "NOCCode": "5111",
        "JobTitle": "database librarian"
    },
    {
        "value": 6357,
        "NOCCode": "5111",
        "JobTitle": "documentation librarian"
    },
    {
        "value": 6358,
        "NOCCode": "5111",
        "JobTitle": "health librarian"
    },
    {
        "value": 6359,
        "NOCCode": "5111",
        "JobTitle": "iconographer"
    },
    {
        "value": 6360,
        "NOCCode": "5111",
        "JobTitle": "information and reference librarian"
    },
    {
        "value": 6361,
        "NOCCode": "5111",
        "JobTitle": "information services librarian"
    },
    {
        "value": 6362,
        "NOCCode": "5111",
        "JobTitle": "law librarian"
    },
    {
        "value": 6363,
        "NOCCode": "5111",
        "JobTitle": "liaison librarian"
    },
    {
        "value": 6364,
        "NOCCode": "5111",
        "JobTitle": "librarian"
    },
    {
        "value": 6365,
        "NOCCode": "5111",
        "JobTitle": "library consultant"
    },
    {
        "value": 6366,
        "NOCCode": "5111",
        "JobTitle": "library co-ordinator"
    },
    {
        "value": 6367,
        "NOCCode": "5111",
        "JobTitle": "library supervisor"
    },
    {
        "value": 6368,
        "NOCCode": "5111",
        "JobTitle": "medical librarian"
    },
    {
        "value": 6369,
        "NOCCode": "5111",
        "JobTitle": "multimedia librarian"
    },
    {
        "value": 6370,
        "NOCCode": "5111",
        "JobTitle": "music librarian"
    },
    {
        "value": 6371,
        "NOCCode": "5111",
        "JobTitle": "music librarian – broadcasting"
    },
    {
        "value": 6372,
        "NOCCode": "5111",
        "JobTitle": "periodicals librarian"
    },
    {
        "value": 6373,
        "NOCCode": "5111",
        "JobTitle": "reference librarian"
    },
    {
        "value": 6374,
        "NOCCode": "5111",
        "JobTitle": "resource sharing and processing librarian"
    },
    {
        "value": 6375,
        "NOCCode": "5111",
        "JobTitle": "scientific librarian"
    },
    {
        "value": 6376,
        "NOCCode": "5111",
        "JobTitle": "supervisor, library"
    },
    {
        "value": 6377,
        "NOCCode": "5111",
        "JobTitle": "technical services librarian"
    },
    {
        "value": 6378,
        "NOCCode": "5111",
        "JobTitle": "unit head librarian"
    },
    {
        "value": 6379,
        "NOCCode": "5112",
        "JobTitle": "archaeology curator"
    },
    {
        "value": 6380,
        "NOCCode": "5112",
        "JobTitle": "archaeology museum curator"
    },
    {
        "value": 6381,
        "NOCCode": "5112",
        "JobTitle": "art gallery conservator"
    },
    {
        "value": 6382,
        "NOCCode": "5112",
        "JobTitle": "art gallery curator"
    },
    {
        "value": 6383,
        "NOCCode": "5112",
        "JobTitle": "art object curator"
    },
    {
        "value": 6384,
        "NOCCode": "5112",
        "JobTitle": "artifact conservator"
    },
    {
        "value": 6385,
        "NOCCode": "5112",
        "JobTitle": "assistant curator"
    },
    {
        "value": 6386,
        "NOCCode": "5112",
        "JobTitle": "assistant museum curator"
    },
    {
        "value": 6387,
        "NOCCode": "5112",
        "JobTitle": "aviation and space museum curator"
    },
    {
        "value": 6388,
        "NOCCode": "5112",
        "JobTitle": "ceramics and glass conservator"
    },
    {
        "value": 6389,
        "NOCCode": "5112",
        "JobTitle": "conservator – art objects"
    },
    {
        "value": 6390,
        "NOCCode": "5112",
        "JobTitle": "conservator – museum"
    },
    {
        "value": 6391,
        "NOCCode": "5112",
        "JobTitle": "conservator, art gallery"
    },
    {
        "value": 6392,
        "NOCCode": "5112",
        "JobTitle": "contemporary art curator"
    },
    {
        "value": 6393,
        "NOCCode": "5112",
        "JobTitle": "contemporary art museum curator"
    },
    {
        "value": 6394,
        "NOCCode": "5112",
        "JobTitle": "curator"
    },
    {
        "value": 6395,
        "NOCCode": "5112",
        "JobTitle": "ethnology curator"
    },
    {
        "value": 6396,
        "NOCCode": "5112",
        "JobTitle": "ethnology museum curator"
    },
    {
        "value": 6397,
        "NOCCode": "5112",
        "JobTitle": "fine art and polychrome conservator"
    },
    {
        "value": 6398,
        "NOCCode": "5112",
        "JobTitle": "fossil conservator"
    },
    {
        "value": 6399,
        "NOCCode": "5112",
        "JobTitle": "fossil curator"
    },
    {
        "value": 6400,
        "NOCCode": "5112",
        "JobTitle": "furniture and wooden objects conservator"
    },
    {
        "value": 6401,
        "NOCCode": "5112",
        "JobTitle": "herbarium curator"
    },
    {
        "value": 6402,
        "NOCCode": "5112",
        "JobTitle": "historical artifact conservator"
    },
    {
        "value": 6403,
        "NOCCode": "5112",
        "JobTitle": "insectarium conservator"
    },
    {
        "value": 6404,
        "NOCCode": "5112",
        "JobTitle": "insectarium curator"
    },
    {
        "value": 6405,
        "NOCCode": "5112",
        "JobTitle": "invertebrate zoology curator"
    },
    {
        "value": 6406,
        "NOCCode": "5112",
        "JobTitle": "invertebrate zoology museum curator"
    },
    {
        "value": 6407,
        "NOCCode": "5112",
        "JobTitle": "library curator"
    },
    {
        "value": 6408,
        "NOCCode": "5112",
        "JobTitle": "metals conservator"
    },
    {
        "value": 6409,
        "NOCCode": "5112",
        "JobTitle": "museologist"
    },
    {
        "value": 6410,
        "NOCCode": "5112",
        "JobTitle": "museum curator"
    },
    {
        "value": 6411,
        "NOCCode": "5112",
        "JobTitle": "museum objects conservator"
    },
    {
        "value": 6412,
        "NOCCode": "5112",
        "JobTitle": "natural history museum curator"
    },
    {
        "value": 6413,
        "NOCCode": "5112",
        "JobTitle": "natural history specimen conservator"
    },
    {
        "value": 6414,
        "NOCCode": "5112",
        "JobTitle": "natural sciences curator"
    },
    {
        "value": 6415,
        "NOCCode": "5112",
        "JobTitle": "natural sciences museum curator"
    },
    {
        "value": 6416,
        "NOCCode": "5112",
        "JobTitle": "ornithology curator"
    },
    {
        "value": 6417,
        "NOCCode": "5112",
        "JobTitle": "ornithology museum curator"
    },
    {
        "value": 6418,
        "NOCCode": "5112",
        "JobTitle": "paintings conservator"
    },
    {
        "value": 6419,
        "NOCCode": "5112",
        "JobTitle": "paper conservator"
    },
    {
        "value": 6420,
        "NOCCode": "5112",
        "JobTitle": "photograph conservator"
    },
    {
        "value": 6421,
        "NOCCode": "5112",
        "JobTitle": "photography museum curator"
    },
    {
        "value": 6422,
        "NOCCode": "5112",
        "JobTitle": "science and technology curator"
    },
    {
        "value": 6423,
        "NOCCode": "5112",
        "JobTitle": "science and technology museum curator"
    },
    {
        "value": 6424,
        "NOCCode": "5112",
        "JobTitle": "sculpture conservator"
    },
    {
        "value": 6425,
        "NOCCode": "5112",
        "JobTitle": "textile conservator"
    },
    {
        "value": 6426,
        "NOCCode": "5112",
        "JobTitle": "vertebrate zoology curator"
    },
    {
        "value": 6427,
        "NOCCode": "5112",
        "JobTitle": "vertebrate zoology museum curator"
    },
    {
        "value": 6428,
        "NOCCode": "5113",
        "JobTitle": "archivist"
    },
    {
        "value": 6429,
        "NOCCode": "5113",
        "JobTitle": "archivist, assistant"
    },
    {
        "value": 6430,
        "NOCCode": "5113",
        "JobTitle": "archivist, fine arts"
    },
    {
        "value": 6431,
        "NOCCode": "5113",
        "JobTitle": "archivist, videos"
    },
    {
        "value": 6432,
        "NOCCode": "5113",
        "JobTitle": "collections archivist"
    },
    {
        "value": 6433,
        "NOCCode": "5113",
        "JobTitle": "corporate archivist"
    },
    {
        "value": 6434,
        "NOCCode": "5113",
        "JobTitle": "fine arts archivist"
    },
    {
        "value": 6435,
        "NOCCode": "5113",
        "JobTitle": "historical archivist"
    },
    {
        "value": 6436,
        "NOCCode": "5113",
        "JobTitle": "multimedia archivist"
    },
    {
        "value": 6437,
        "NOCCode": "5113",
        "JobTitle": "photo archivist"
    },
    {
        "value": 6438,
        "NOCCode": "5113",
        "JobTitle": "research archivist"
    },
    {
        "value": 6439,
        "NOCCode": "5113",
        "JobTitle": "video archivist"
    },
    {
        "value": 6440,
        "NOCCode": "5121",
        "JobTitle": "ad writer"
    },
    {
        "value": 6441,
        "NOCCode": "5121",
        "JobTitle": "advertising copywriter"
    },
    {
        "value": 6442,
        "NOCCode": "5121",
        "JobTitle": "advertising writer"
    },
    {
        "value": 6443,
        "NOCCode": "5121",
        "JobTitle": "author"
    },
    {
        "value": 6444,
        "NOCCode": "5121",
        "JobTitle": "biographer"
    },
    {
        "value": 6445,
        "NOCCode": "5121",
        "JobTitle": "copywriter"
    },
    {
        "value": 6446,
        "NOCCode": "5121",
        "JobTitle": "crossword puzzle maker"
    },
    {
        "value": 6447,
        "NOCCode": "5121",
        "JobTitle": "designer and script writer"
    },
    {
        "value": 6448,
        "NOCCode": "5121",
        "JobTitle": "dialogue writer"
    },
    {
        "value": 6449,
        "NOCCode": "5121",
        "JobTitle": "dubbing dialogue writer"
    },
    {
        "value": 6450,
        "NOCCode": "5121",
        "JobTitle": "essayist"
    },
    {
        "value": 6451,
        "NOCCode": "5121",
        "JobTitle": "feature writer"
    },
    {
        "value": 6452,
        "NOCCode": "5121",
        "JobTitle": "fiction writer"
    },
    {
        "value": 6453,
        "NOCCode": "5121",
        "JobTitle": "game, CD-ROM or Web critic"
    },
    {
        "value": 6454,
        "NOCCode": "5121",
        "JobTitle": "ghost writer"
    },
    {
        "value": 6455,
        "NOCCode": "5121",
        "JobTitle": "handbook writer"
    },
    {
        "value": 6456,
        "NOCCode": "5121",
        "JobTitle": "help file developer"
    },
    {
        "value": 6457,
        "NOCCode": "5121",
        "JobTitle": "humorist-author"
    },
    {
        "value": 6458,
        "NOCCode": "5121",
        "JobTitle": "interactive media writer"
    },
    {
        "value": 6459,
        "NOCCode": "5121",
        "JobTitle": "lexicographer"
    },
    {
        "value": 6460,
        "NOCCode": "5121",
        "JobTitle": "literary writer"
    },
    {
        "value": 6461,
        "NOCCode": "5121",
        "JobTitle": "manual writer"
    },
    {
        "value": 6462,
        "NOCCode": "5121",
        "JobTitle": "medical writer"
    },
    {
        "value": 6463,
        "NOCCode": "5121",
        "JobTitle": "multimedia author"
    },
    {
        "value": 6464,
        "NOCCode": "5121",
        "JobTitle": "multimedia script writer"
    },
    {
        "value": 6465,
        "NOCCode": "5121",
        "JobTitle": "multimedia writer"
    },
    {
        "value": 6466,
        "NOCCode": "5121",
        "JobTitle": "new media writer"
    },
    {
        "value": 6467,
        "NOCCode": "5121",
        "JobTitle": "novelist"
    },
    {
        "value": 6468,
        "NOCCode": "5121",
        "JobTitle": "on-line reference developer"
    },
    {
        "value": 6469,
        "NOCCode": "5121",
        "JobTitle": "playwright"
    },
    {
        "value": 6470,
        "NOCCode": "5121",
        "JobTitle": "poet"
    },
    {
        "value": 6471,
        "NOCCode": "5121",
        "JobTitle": "programmed-instruction writer"
    },
    {
        "value": 6472,
        "NOCCode": "5121",
        "JobTitle": "publicity writer"
    },
    {
        "value": 6473,
        "NOCCode": "5121",
        "JobTitle": "radio writer"
    },
    {
        "value": 6474,
        "NOCCode": "5121",
        "JobTitle": "rewriter"
    },
    {
        "value": 6475,
        "NOCCode": "5121",
        "JobTitle": "scientific writer"
    },
    {
        "value": 6476,
        "NOCCode": "5121",
        "JobTitle": "screen writer"
    },
    {
        "value": 6477,
        "NOCCode": "5121",
        "JobTitle": "script and dialogue writer"
    },
    {
        "value": 6478,
        "NOCCode": "5121",
        "JobTitle": "script writer"
    },
    {
        "value": 6479,
        "NOCCode": "5121",
        "JobTitle": "self-instruction manual writer"
    },
    {
        "value": 6480,
        "NOCCode": "5121",
        "JobTitle": "serial story writer"
    },
    {
        "value": 6481,
        "NOCCode": "5121",
        "JobTitle": "serial writer"
    },
    {
        "value": 6482,
        "NOCCode": "5121",
        "JobTitle": "short story writer"
    },
    {
        "value": 6483,
        "NOCCode": "5121",
        "JobTitle": "specifications writer"
    },
    {
        "value": 6484,
        "NOCCode": "5121",
        "JobTitle": "specifications writer (except construction)"
    },
    {
        "value": 6485,
        "NOCCode": "5121",
        "JobTitle": "speech writer"
    },
    {
        "value": 6486,
        "NOCCode": "5121",
        "JobTitle": "sports writer – novels and books"
    },
    {
        "value": 6487,
        "NOCCode": "5121",
        "JobTitle": "standards writer"
    },
    {
        "value": 6488,
        "NOCCode": "5121",
        "JobTitle": "storyboarder"
    },
    {
        "value": 6489,
        "NOCCode": "5121",
        "JobTitle": "support materials writer"
    },
    {
        "value": 6490,
        "NOCCode": "5121",
        "JobTitle": "technical writer"
    },
    {
        "value": 6491,
        "NOCCode": "5121",
        "JobTitle": "technical writer – aeronautics"
    },
    {
        "value": 6492,
        "NOCCode": "5121",
        "JobTitle": "technical writer – chemical processing"
    },
    {
        "value": 6493,
        "NOCCode": "5121",
        "JobTitle": "technical writer – electronics equipment"
    },
    {
        "value": 6494,
        "NOCCode": "5121",
        "JobTitle": "technical writer – pharmaceutical industry"
    },
    {
        "value": 6495,
        "NOCCode": "5121",
        "JobTitle": "teleplay writer, screenwriter"
    },
    {
        "value": 6496,
        "NOCCode": "5121",
        "JobTitle": "television writer"
    },
    {
        "value": 6497,
        "NOCCode": "5121",
        "JobTitle": "writer"
    },
    {
        "value": 6498,
        "NOCCode": "5121",
        "JobTitle": "writer, advertising"
    },
    {
        "value": 6499,
        "NOCCode": "5121",
        "JobTitle": "writer, fiction"
    },
    {
        "value": 6500,
        "NOCCode": "5121",
        "JobTitle": "writer, handbooks"
    },
    {
        "value": 6501,
        "NOCCode": "5121",
        "JobTitle": "writer, interactive media"
    },
    {
        "value": 6502,
        "NOCCode": "5121",
        "JobTitle": "writer, multimedia"
    },
    {
        "value": 6503,
        "NOCCode": "5121",
        "JobTitle": "writer, new media"
    },
    {
        "value": 6504,
        "NOCCode": "5121",
        "JobTitle": "writer, self-instruction manuals"
    },
    {
        "value": 6505,
        "NOCCode": "5121",
        "JobTitle": "writer, short stories"
    },
    {
        "value": 6506,
        "NOCCode": "5121",
        "JobTitle": "writer, speeches"
    },
    {
        "value": 6507,
        "NOCCode": "5122",
        "JobTitle": "advertising editor"
    },
    {
        "value": 6508,
        "NOCCode": "5122",
        "JobTitle": "art editor"
    },
    {
        "value": 6509,
        "NOCCode": "5122",
        "JobTitle": "assistant editor"
    },
    {
        "value": 6510,
        "NOCCode": "5122",
        "JobTitle": "associate editor"
    },
    {
        "value": 6511,
        "NOCCode": "5122",
        "JobTitle": "bilingual editor"
    },
    {
        "value": 6512,
        "NOCCode": "5122",
        "JobTitle": "book editor"
    },
    {
        "value": 6513,
        "NOCCode": "5122",
        "JobTitle": "business editor"
    },
    {
        "value": 6514,
        "NOCCode": "5122",
        "JobTitle": "caption editor"
    },
    {
        "value": 6515,
        "NOCCode": "5122",
        "JobTitle": "city editor"
    },
    {
        "value": 6516,
        "NOCCode": "5122",
        "JobTitle": "contributing editor"
    },
    {
        "value": 6517,
        "NOCCode": "5122",
        "JobTitle": "control room editor – broadcasting"
    },
    {
        "value": 6518,
        "NOCCode": "5122",
        "JobTitle": "copy chief"
    },
    {
        "value": 6519,
        "NOCCode": "5122",
        "JobTitle": "copy editor"
    },
    {
        "value": 6520,
        "NOCCode": "5122",
        "JobTitle": "copy preparer"
    },
    {
        "value": 6521,
        "NOCCode": "5122",
        "JobTitle": "dictionary editor"
    },
    {
        "value": 6522,
        "NOCCode": "5122",
        "JobTitle": "editor"
    },
    {
        "value": 6523,
        "NOCCode": "5122",
        "JobTitle": "editor, advertising"
    },
    {
        "value": 6524,
        "NOCCode": "5122",
        "JobTitle": "editor, blog"
    },
    {
        "value": 6525,
        "NOCCode": "5122",
        "JobTitle": "editor, book"
    },
    {
        "value": 6526,
        "NOCCode": "5122",
        "JobTitle": "editor, business"
    },
    {
        "value": 6527,
        "NOCCode": "5122",
        "JobTitle": "editor, control room – broadcasting"
    },
    {
        "value": 6528,
        "NOCCode": "5122",
        "JobTitle": "editor, dictionary"
    },
    {
        "value": 6529,
        "NOCCode": "5122",
        "JobTitle": "editor, editorial page"
    },
    {
        "value": 6530,
        "NOCCode": "5122",
        "JobTitle": "editor, journal"
    },
    {
        "value": 6531,
        "NOCCode": "5122",
        "JobTitle": "editor, magazine"
    },
    {
        "value": 6532,
        "NOCCode": "5122",
        "JobTitle": "editor, manuscripts"
    },
    {
        "value": 6533,
        "NOCCode": "5122",
        "JobTitle": "editor, newspaper"
    },
    {
        "value": 6534,
        "NOCCode": "5122",
        "JobTitle": "editor, picture"
    },
    {
        "value": 6535,
        "NOCCode": "5122",
        "JobTitle": "editor, Web site"
    },
    {
        "value": 6536,
        "NOCCode": "5122",
        "JobTitle": "editorial consultant"
    },
    {
        "value": 6537,
        "NOCCode": "5122",
        "JobTitle": "editorial page editor"
    },
    {
        "value": 6538,
        "NOCCode": "5122",
        "JobTitle": "English editor"
    },
    {
        "value": 6539,
        "NOCCode": "5122",
        "JobTitle": "feature editor"
    },
    {
        "value": 6540,
        "NOCCode": "5122",
        "JobTitle": "financial editor"
    },
    {
        "value": 6541,
        "NOCCode": "5122",
        "JobTitle": "financial news editor"
    },
    {
        "value": 6542,
        "NOCCode": "5122",
        "JobTitle": "foreign news broadcasting editor"
    },
    {
        "value": 6543,
        "NOCCode": "5122",
        "JobTitle": "foreign news service editor"
    },
    {
        "value": 6544,
        "NOCCode": "5122",
        "JobTitle": "French editor"
    },
    {
        "value": 6545,
        "NOCCode": "5122",
        "JobTitle": "information editor"
    },
    {
        "value": 6546,
        "NOCCode": "5122",
        "JobTitle": "journal editor"
    },
    {
        "value": 6547,
        "NOCCode": "5122",
        "JobTitle": "line editor"
    },
    {
        "value": 6548,
        "NOCCode": "5122",
        "JobTitle": "line-up editor"
    },
    {
        "value": 6549,
        "NOCCode": "5122",
        "JobTitle": "literary editor"
    },
    {
        "value": 6550,
        "NOCCode": "5122",
        "JobTitle": "local news editor"
    },
    {
        "value": 6551,
        "NOCCode": "5122",
        "JobTitle": "magazine editor"
    },
    {
        "value": 6552,
        "NOCCode": "5122",
        "JobTitle": "manuscript editor"
    },
    {
        "value": 6553,
        "NOCCode": "5122",
        "JobTitle": "medical editor"
    },
    {
        "value": 6554,
        "NOCCode": "5122",
        "JobTitle": "news desk editor"
    },
    {
        "value": 6555,
        "NOCCode": "5122",
        "JobTitle": "news editor"
    },
    {
        "value": 6556,
        "NOCCode": "5122",
        "JobTitle": "news editor – broadcasting"
    },
    {
        "value": 6557,
        "NOCCode": "5122",
        "JobTitle": "news service editor"
    },
    {
        "value": 6558,
        "NOCCode": "5122",
        "JobTitle": "newspaper editor"
    },
    {
        "value": 6559,
        "NOCCode": "5122",
        "JobTitle": "production editor"
    },
    {
        "value": 6560,
        "NOCCode": "5122",
        "JobTitle": "publications editor"
    },
    {
        "value": 6561,
        "NOCCode": "5122",
        "JobTitle": "scientific editor"
    },
    {
        "value": 6562,
        "NOCCode": "5122",
        "JobTitle": "script editor"
    },
    {
        "value": 6563,
        "NOCCode": "5122",
        "JobTitle": "senior editor"
    },
    {
        "value": 6564,
        "NOCCode": "5122",
        "JobTitle": "society editor"
    },
    {
        "value": 6565,
        "NOCCode": "5122",
        "JobTitle": "special features editor"
    },
    {
        "value": 6566,
        "NOCCode": "5122",
        "JobTitle": "sport desk editor"
    },
    {
        "value": 6567,
        "NOCCode": "5122",
        "JobTitle": "sports editor"
    },
    {
        "value": 6568,
        "NOCCode": "5122",
        "JobTitle": "sub-editor"
    },
    {
        "value": 6569,
        "NOCCode": "5122",
        "JobTitle": "technical editor"
    },
    {
        "value": 6570,
        "NOCCode": "5122",
        "JobTitle": "telecommunications editor"
    },
    {
        "value": 6571,
        "NOCCode": "5122",
        "JobTitle": "writer-editor"
    },
    {
        "value": 6572,
        "NOCCode": "5123",
        "JobTitle": "anchorman/woman"
    },
    {
        "value": 6573,
        "NOCCode": "5123",
        "JobTitle": "anchorperson, television news"
    },
    {
        "value": 6574,
        "NOCCode": "5123",
        "JobTitle": "art critic"
    },
    {
        "value": 6575,
        "NOCCode": "5123",
        "JobTitle": "book critic"
    },
    {
        "value": 6576,
        "NOCCode": "5123",
        "JobTitle": "book reviewer"
    },
    {
        "value": 6577,
        "NOCCode": "5123",
        "JobTitle": "broadcast journalist"
    },
    {
        "value": 6578,
        "NOCCode": "5123",
        "JobTitle": "chronicler"
    },
    {
        "value": 6579,
        "NOCCode": "5123",
        "JobTitle": "columnist"
    },
    {
        "value": 6580,
        "NOCCode": "5123",
        "JobTitle": "columnist, entertainment"
    },
    {
        "value": 6581,
        "NOCCode": "5123",
        "JobTitle": "columnist, fashion"
    },
    {
        "value": 6582,
        "NOCCode": "5123",
        "JobTitle": "columnist, society"
    },
    {
        "value": 6583,
        "NOCCode": "5123",
        "JobTitle": "columnist, sports"
    },
    {
        "value": 6584,
        "NOCCode": "5123",
        "JobTitle": "commentator"
    },
    {
        "value": 6585,
        "NOCCode": "5123",
        "JobTitle": "commentator, radio"
    },
    {
        "value": 6586,
        "NOCCode": "5123",
        "JobTitle": "correspondent"
    },
    {
        "value": 6587,
        "NOCCode": "5123",
        "JobTitle": "correspondent, newspaper"
    },
    {
        "value": 6588,
        "NOCCode": "5123",
        "JobTitle": "critic"
    },
    {
        "value": 6589,
        "NOCCode": "5123",
        "JobTitle": "culinary journalist"
    },
    {
        "value": 6590,
        "NOCCode": "5123",
        "JobTitle": "cyberjournalist"
    },
    {
        "value": 6591,
        "NOCCode": "5123",
        "JobTitle": "cyberreporter"
    },
    {
        "value": 6592,
        "NOCCode": "5123",
        "JobTitle": "drama critic"
    },
    {
        "value": 6593,
        "NOCCode": "5123",
        "JobTitle": "editorial writer, newspaper"
    },
    {
        "value": 6594,
        "NOCCode": "5123",
        "JobTitle": "entertainment columnist"
    },
    {
        "value": 6595,
        "NOCCode": "5123",
        "JobTitle": "fashion columnist"
    },
    {
        "value": 6596,
        "NOCCode": "5123",
        "JobTitle": "feature reporter"
    },
    {
        "value": 6597,
        "NOCCode": "5123",
        "JobTitle": "field reporter"
    },
    {
        "value": 6598,
        "NOCCode": "5123",
        "JobTitle": "film critic"
    },
    {
        "value": 6599,
        "NOCCode": "5123",
        "JobTitle": "financial reporter"
    },
    {
        "value": 6600,
        "NOCCode": "5123",
        "JobTitle": "foreign affairs reporter"
    },
    {
        "value": 6601,
        "NOCCode": "5123",
        "JobTitle": "foreign correspondent"
    },
    {
        "value": 6602,
        "NOCCode": "5123",
        "JobTitle": "foreign news correspondent"
    },
    {
        "value": 6603,
        "NOCCode": "5123",
        "JobTitle": "headline writer"
    },
    {
        "value": 6604,
        "NOCCode": "5123",
        "JobTitle": "investigative reporter"
    },
    {
        "value": 6605,
        "NOCCode": "5123",
        "JobTitle": "journalist"
    },
    {
        "value": 6606,
        "NOCCode": "5123",
        "JobTitle": "law reporter"
    },
    {
        "value": 6607,
        "NOCCode": "5123",
        "JobTitle": "literary critic"
    },
    {
        "value": 6608,
        "NOCCode": "5123",
        "JobTitle": "movie critic"
    },
    {
        "value": 6609,
        "NOCCode": "5123",
        "JobTitle": "multimedia information trader"
    },
    {
        "value": 6610,
        "NOCCode": "5123",
        "JobTitle": "music critic"
    },
    {
        "value": 6611,
        "NOCCode": "5123",
        "JobTitle": "music reviewer"
    },
    {
        "value": 6612,
        "NOCCode": "5123",
        "JobTitle": "netsurfer"
    },
    {
        "value": 6613,
        "NOCCode": "5123",
        "JobTitle": "network reporter"
    },
    {
        "value": 6614,
        "NOCCode": "5123",
        "JobTitle": "news analyst"
    },
    {
        "value": 6615,
        "NOCCode": "5123",
        "JobTitle": "news analyst – broadcasting"
    },
    {
        "value": 6616,
        "NOCCode": "5123",
        "JobTitle": "news commentator"
    },
    {
        "value": 6617,
        "NOCCode": "5123",
        "JobTitle": "news commentator – broadcasting"
    },
    {
        "value": 6618,
        "NOCCode": "5123",
        "JobTitle": "news correspondent"
    },
    {
        "value": 6619,
        "NOCCode": "5123",
        "JobTitle": "news reporter"
    },
    {
        "value": 6620,
        "NOCCode": "5123",
        "JobTitle": "news writer – international broadcasting"
    },
    {
        "value": 6621,
        "NOCCode": "5123",
        "JobTitle": "newscast correspondent"
    },
    {
        "value": 6622,
        "NOCCode": "5123",
        "JobTitle": "newspaper correspondent"
    },
    {
        "value": 6623,
        "NOCCode": "5123",
        "JobTitle": "newspaper critic"
    },
    {
        "value": 6624,
        "NOCCode": "5123",
        "JobTitle": "newspaper editorial writer"
    },
    {
        "value": 6625,
        "NOCCode": "5123",
        "JobTitle": "newspaper reporter"
    },
    {
        "value": 6626,
        "NOCCode": "5123",
        "JobTitle": "newspaper writer"
    },
    {
        "value": 6627,
        "NOCCode": "5123",
        "JobTitle": "on-line chronicler"
    },
    {
        "value": 6628,
        "NOCCode": "5123",
        "JobTitle": "on-line columnist"
    },
    {
        "value": 6629,
        "NOCCode": "5123",
        "JobTitle": "political analyst – radio or television"
    },
    {
        "value": 6630,
        "NOCCode": "5123",
        "JobTitle": "political columnist"
    },
    {
        "value": 6631,
        "NOCCode": "5123",
        "JobTitle": "political reporter"
    },
    {
        "value": 6632,
        "NOCCode": "5123",
        "JobTitle": "press reporter"
    },
    {
        "value": 6633,
        "NOCCode": "5123",
        "JobTitle": "program researcher – radio or television"
    },
    {
        "value": 6634,
        "NOCCode": "5123",
        "JobTitle": "radio commentator"
    },
    {
        "value": 6635,
        "NOCCode": "5123",
        "JobTitle": "radio researcher"
    },
    {
        "value": 6636,
        "NOCCode": "5123",
        "JobTitle": "reporter"
    },
    {
        "value": 6637,
        "NOCCode": "5123",
        "JobTitle": "reporter, foreign affairs"
    },
    {
        "value": 6638,
        "NOCCode": "5123",
        "JobTitle": "reporter, newspaper"
    },
    {
        "value": 6639,
        "NOCCode": "5123",
        "JobTitle": "reporter, press"
    },
    {
        "value": 6640,
        "NOCCode": "5123",
        "JobTitle": "reporter, society"
    },
    {
        "value": 6641,
        "NOCCode": "5123",
        "JobTitle": "researcher, radio"
    },
    {
        "value": 6642,
        "NOCCode": "5123",
        "JobTitle": "researcher, television"
    },
    {
        "value": 6643,
        "NOCCode": "5123",
        "JobTitle": "society columnist"
    },
    {
        "value": 6644,
        "NOCCode": "5123",
        "JobTitle": "society journalist"
    },
    {
        "value": 6645,
        "NOCCode": "5123",
        "JobTitle": "society reporter"
    },
    {
        "value": 6646,
        "NOCCode": "5123",
        "JobTitle": "sports columnist"
    },
    {
        "value": 6647,
        "NOCCode": "5123",
        "JobTitle": "sports reporter"
    },
    {
        "value": 6648,
        "NOCCode": "5123",
        "JobTitle": "sports writer – journalism"
    },
    {
        "value": 6649,
        "NOCCode": "5123",
        "JobTitle": "staff writer – newspaper"
    },
    {
        "value": 6650,
        "NOCCode": "5123",
        "JobTitle": "stock market reporter"
    },
    {
        "value": 6651,
        "NOCCode": "5123",
        "JobTitle": "television news anchorperson"
    },
    {
        "value": 6652,
        "NOCCode": "5123",
        "JobTitle": "television researcher"
    },
    {
        "value": 6653,
        "NOCCode": "5123",
        "JobTitle": "theatre critic"
    },
    {
        "value": 6654,
        "NOCCode": "5125",
        "JobTitle": "American Sign Language (ASL) interpreter"
    },
    {
        "value": 6655,
        "NOCCode": "5125",
        "JobTitle": "ASL (American Sign Language) interpreter"
    },
    {
        "value": 6656,
        "NOCCode": "5125",
        "JobTitle": "C. Int. (certified interpreter)"
    },
    {
        "value": 6657,
        "NOCCode": "5125",
        "JobTitle": "C. Term. (certified terminologist)"
    },
    {
        "value": 6658,
        "NOCCode": "5125",
        "JobTitle": "C. Tr. (certified translator)"
    },
    {
        "value": 6659,
        "NOCCode": "5125",
        "JobTitle": "certified interpreter (C.Int.)"
    },
    {
        "value": 6660,
        "NOCCode": "5125",
        "JobTitle": "certified terminologist (C.Term.)"
    },
    {
        "value": 6661,
        "NOCCode": "5125",
        "JobTitle": "certified translator (C.Tr.)"
    },
    {
        "value": 6662,
        "NOCCode": "5125",
        "JobTitle": "community interpreter"
    },
    {
        "value": 6663,
        "NOCCode": "5125",
        "JobTitle": "conference interpreter"
    },
    {
        "value": 6664,
        "NOCCode": "5125",
        "JobTitle": "consecutive interpreter"
    },
    {
        "value": 6665,
        "NOCCode": "5125",
        "JobTitle": "court interpreter"
    },
    {
        "value": 6666,
        "NOCCode": "5125",
        "JobTitle": "cultural or international adaptation specialist"
    },
    {
        "value": 6667,
        "NOCCode": "5125",
        "JobTitle": "deaf interpreter"
    },
    {
        "value": 6668,
        "NOCCode": "5125",
        "JobTitle": "educational interpreter"
    },
    {
        "value": 6669,
        "NOCCode": "5125",
        "JobTitle": "elbow interpreter"
    },
    {
        "value": 6670,
        "NOCCode": "5125",
        "JobTitle": "escort interpreter"
    },
    {
        "value": 6671,
        "NOCCode": "5125",
        "JobTitle": "foreign broadcast translator"
    },
    {
        "value": 6672,
        "NOCCode": "5125",
        "JobTitle": "foreign news translator"
    },
    {
        "value": 6673,
        "NOCCode": "5125",
        "JobTitle": "interpreter"
    },
    {
        "value": 6674,
        "NOCCode": "5125",
        "JobTitle": "interpreter for persons who are deaf"
    },
    {
        "value": 6675,
        "NOCCode": "5125",
        "JobTitle": "interpreter, sign language"
    },
    {
        "value": 6676,
        "NOCCode": "5125",
        "JobTitle": "jurilinguist translator"
    },
    {
        "value": 6677,
        "NOCCode": "5125",
        "JobTitle": "legal terminologist"
    },
    {
        "value": 6678,
        "NOCCode": "5125",
        "JobTitle": "legal translator"
    },
    {
        "value": 6679,
        "NOCCode": "5125",
        "JobTitle": "literary translator"
    },
    {
        "value": 6680,
        "NOCCode": "5125",
        "JobTitle": "localiser"
    },
    {
        "value": 6681,
        "NOCCode": "5125",
        "JobTitle": "LSQ (Quebec Sign Language) interpreter"
    },
    {
        "value": 6682,
        "NOCCode": "5125",
        "JobTitle": "medical terminologist"
    },
    {
        "value": 6683,
        "NOCCode": "5125",
        "JobTitle": "medical translator"
    },
    {
        "value": 6684,
        "NOCCode": "5125",
        "JobTitle": "oral interpreter"
    },
    {
        "value": 6685,
        "NOCCode": "5125",
        "JobTitle": "platform interpreter, sign language"
    },
    {
        "value": 6686,
        "NOCCode": "5125",
        "JobTitle": "Quebec Sign Language (LSQ) interpreter"
    },
    {
        "value": 6687,
        "NOCCode": "5125",
        "JobTitle": "relay interpreter"
    },
    {
        "value": 6688,
        "NOCCode": "5125",
        "JobTitle": "reviser – translation"
    },
    {
        "value": 6689,
        "NOCCode": "5125",
        "JobTitle": "scientific translator"
    },
    {
        "value": 6690,
        "NOCCode": "5125",
        "JobTitle": "sign language interpreter"
    },
    {
        "value": 6691,
        "NOCCode": "5125",
        "JobTitle": "sign language platform interpreter"
    },
    {
        "value": 6692,
        "NOCCode": "5125",
        "JobTitle": "simultaneous interpreter"
    },
    {
        "value": 6693,
        "NOCCode": "5125",
        "JobTitle": "tactile interpreter"
    },
    {
        "value": 6694,
        "NOCCode": "5125",
        "JobTitle": "technical translator"
    },
    {
        "value": 6695,
        "NOCCode": "5125",
        "JobTitle": "terminologist"
    },
    {
        "value": 6696,
        "NOCCode": "5125",
        "JobTitle": "terminology advisor"
    },
    {
        "value": 6697,
        "NOCCode": "5125",
        "JobTitle": "translator"
    },
    {
        "value": 6698,
        "NOCCode": "5125",
        "JobTitle": "translator adaptor"
    },
    {
        "value": 6699,
        "NOCCode": "5125",
        "JobTitle": "translator, foreign broadcasts"
    },
    {
        "value": 6700,
        "NOCCode": "5125",
        "JobTitle": "translator, foreign news"
    },
    {
        "value": 6701,
        "NOCCode": "5125",
        "JobTitle": "translator-reviser"
    },
    {
        "value": 6702,
        "NOCCode": "5125",
        "JobTitle": "visual language interpreter"
    },
    {
        "value": 6703,
        "NOCCode": "5131",
        "JobTitle": "art director – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6704,
        "NOCCode": "5131",
        "JobTitle": "artistic director – motion picture"
    },
    {
        "value": 6705,
        "NOCCode": "5131",
        "JobTitle": "artistic director, performing arts"
    },
    {
        "value": 6706,
        "NOCCode": "5131",
        "JobTitle": "assistant director"
    },
    {
        "value": 6707,
        "NOCCode": "5131",
        "JobTitle": "assistant director – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6708,
        "NOCCode": "5131",
        "JobTitle": "audiovisual (AV) producer"
    },
    {
        "value": 6709,
        "NOCCode": "5131",
        "JobTitle": "broadcasting director"
    },
    {
        "value": 6710,
        "NOCCode": "5131",
        "JobTitle": "broadcasting producer"
    },
    {
        "value": 6711,
        "NOCCode": "5131",
        "JobTitle": "broadcasting sports director"
    },
    {
        "value": 6712,
        "NOCCode": "5131",
        "JobTitle": "casting director"
    },
    {
        "value": 6713,
        "NOCCode": "5131",
        "JobTitle": "choreographer"
    },
    {
        "value": 6714,
        "NOCCode": "5131",
        "JobTitle": "cinematographer"
    },
    {
        "value": 6715,
        "NOCCode": "5131",
        "JobTitle": "cyber-choreographer"
    },
    {
        "value": 6716,
        "NOCCode": "5131",
        "JobTitle": "director – motion picture"
    },
    {
        "value": 6717,
        "NOCCode": "5131",
        "JobTitle": "director – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6718,
        "NOCCode": "5131",
        "JobTitle": "director of photography"
    },
    {
        "value": 6719,
        "NOCCode": "5131",
        "JobTitle": "director, broadcasting"
    },
    {
        "value": 6720,
        "NOCCode": "5131",
        "JobTitle": "director, motion picture"
    },
    {
        "value": 6721,
        "NOCCode": "5131",
        "JobTitle": "director, radio"
    },
    {
        "value": 6722,
        "NOCCode": "5131",
        "JobTitle": "director, stage – performing arts"
    },
    {
        "value": 6723,
        "NOCCode": "5131",
        "JobTitle": "director, television"
    },
    {
        "value": 6724,
        "NOCCode": "5131",
        "JobTitle": "director, videos"
    },
    {
        "value": 6725,
        "NOCCode": "5131",
        "JobTitle": "editor, films"
    },
    {
        "value": 6726,
        "NOCCode": "5131",
        "JobTitle": "film director"
    },
    {
        "value": 6727,
        "NOCCode": "5131",
        "JobTitle": "film producer"
    },
    {
        "value": 6728,
        "NOCCode": "5131",
        "JobTitle": "filmmaker"
    },
    {
        "value": 6729,
        "NOCCode": "5131",
        "JobTitle": "floor director – performing arts"
    },
    {
        "value": 6730,
        "NOCCode": "5131",
        "JobTitle": "motion picture director"
    },
    {
        "value": 6731,
        "NOCCode": "5131",
        "JobTitle": "motion picture producer"
    },
    {
        "value": 6732,
        "NOCCode": "5131",
        "JobTitle": "motion picture production director"
    },
    {
        "value": 6733,
        "NOCCode": "5131",
        "JobTitle": "movie director"
    },
    {
        "value": 6734,
        "NOCCode": "5131",
        "JobTitle": "movie producer"
    },
    {
        "value": 6735,
        "NOCCode": "5131",
        "JobTitle": "multimedia audio producer"
    },
    {
        "value": 6736,
        "NOCCode": "5131",
        "JobTitle": "performing arts artistic director"
    },
    {
        "value": 6737,
        "NOCCode": "5131",
        "JobTitle": "producer – performing arts"
    },
    {
        "value": 6738,
        "NOCCode": "5131",
        "JobTitle": "producer, broadcasting"
    },
    {
        "value": 6739,
        "NOCCode": "5131",
        "JobTitle": "producer, motion picture"
    },
    {
        "value": 6740,
        "NOCCode": "5131",
        "JobTitle": "producer, stage"
    },
    {
        "value": 6741,
        "NOCCode": "5131",
        "JobTitle": "producer-director"
    },
    {
        "value": 6742,
        "NOCCode": "5131",
        "JobTitle": "production designer – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6743,
        "NOCCode": "5131",
        "JobTitle": "production director – motion picture"
    },
    {
        "value": 6744,
        "NOCCode": "5131",
        "JobTitle": "program director – broadcasting"
    },
    {
        "value": 6745,
        "NOCCode": "5131",
        "JobTitle": "radio director"
    },
    {
        "value": 6746,
        "NOCCode": "5131",
        "JobTitle": "radio producer"
    },
    {
        "value": 6747,
        "NOCCode": "5131",
        "JobTitle": "record producer"
    },
    {
        "value": 6748,
        "NOCCode": "5131",
        "JobTitle": "recording director"
    },
    {
        "value": 6749,
        "NOCCode": "5131",
        "JobTitle": "stage director – performing arts"
    },
    {
        "value": 6750,
        "NOCCode": "5131",
        "JobTitle": "stage producer"
    },
    {
        "value": 6751,
        "NOCCode": "5131",
        "JobTitle": "technical director – broadcasting"
    },
    {
        "value": 6752,
        "NOCCode": "5131",
        "JobTitle": "technical director – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6753,
        "NOCCode": "5131",
        "JobTitle": "technical producer"
    },
    {
        "value": 6754,
        "NOCCode": "5131",
        "JobTitle": "technical producer – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 6755,
        "NOCCode": "5131",
        "JobTitle": "television director"
    },
    {
        "value": 6756,
        "NOCCode": "5131",
        "JobTitle": "television director-producer"
    },
    {
        "value": 6757,
        "NOCCode": "5131",
        "JobTitle": "television producer"
    },
    {
        "value": 6758,
        "NOCCode": "5131",
        "JobTitle": "video director"
    },
    {
        "value": 6759,
        "NOCCode": "5131",
        "JobTitle": "video maker"
    },
    {
        "value": 6760,
        "NOCCode": "5131",
        "JobTitle": "video producer"
    },
    {
        "value": 6761,
        "NOCCode": "5132",
        "JobTitle": "arranger"
    },
    {
        "value": 6762,
        "NOCCode": "5132",
        "JobTitle": "arranger, music"
    },
    {
        "value": 6763,
        "NOCCode": "5132",
        "JobTitle": "artistic director, orchestra"
    },
    {
        "value": 6764,
        "NOCCode": "5132",
        "JobTitle": "author-composer-performer"
    },
    {
        "value": 6765,
        "NOCCode": "5132",
        "JobTitle": "band conductor"
    },
    {
        "value": 6766,
        "NOCCode": "5132",
        "JobTitle": "band director"
    },
    {
        "value": 6767,
        "NOCCode": "5132",
        "JobTitle": "bandmaster"
    },
    {
        "value": 6768,
        "NOCCode": "5132",
        "JobTitle": "chansonnier"
    },
    {
        "value": 6769,
        "NOCCode": "5132",
        "JobTitle": "choir director"
    },
    {
        "value": 6770,
        "NOCCode": "5132",
        "JobTitle": "choir leader"
    },
    {
        "value": 6771,
        "NOCCode": "5132",
        "JobTitle": "choirmaster"
    },
    {
        "value": 6772,
        "NOCCode": "5132",
        "JobTitle": "choral conductor"
    },
    {
        "value": 6773,
        "NOCCode": "5132",
        "JobTitle": "choral director"
    },
    {
        "value": 6774,
        "NOCCode": "5132",
        "JobTitle": "chorus master"
    },
    {
        "value": 6775,
        "NOCCode": "5132",
        "JobTitle": "composer"
    },
    {
        "value": 6776,
        "NOCCode": "5132",
        "JobTitle": "conductor"
    },
    {
        "value": 6777,
        "NOCCode": "5132",
        "JobTitle": "conductor, band"
    },
    {
        "value": 6778,
        "NOCCode": "5132",
        "JobTitle": "conductor, choral"
    },
    {
        "value": 6779,
        "NOCCode": "5132",
        "JobTitle": "conductor, orchestra"
    },
    {
        "value": 6780,
        "NOCCode": "5132",
        "JobTitle": "copyist – music"
    },
    {
        "value": 6781,
        "NOCCode": "5132",
        "JobTitle": "director, band"
    },
    {
        "value": 6782,
        "NOCCode": "5132",
        "JobTitle": "director, choir"
    },
    {
        "value": 6783,
        "NOCCode": "5132",
        "JobTitle": "director, choral"
    },
    {
        "value": 6784,
        "NOCCode": "5132",
        "JobTitle": "director, music"
    },
    {
        "value": 6785,
        "NOCCode": "5132",
        "JobTitle": "leader, choir"
    },
    {
        "value": 6786,
        "NOCCode": "5132",
        "JobTitle": "maestro"
    },
    {
        "value": 6787,
        "NOCCode": "5132",
        "JobTitle": "master, chorus"
    },
    {
        "value": 6788,
        "NOCCode": "5132",
        "JobTitle": "motion picture musical director"
    },
    {
        "value": 6789,
        "NOCCode": "5132",
        "JobTitle": "music adapter"
    },
    {
        "value": 6790,
        "NOCCode": "5132",
        "JobTitle": "music arranger"
    },
    {
        "value": 6791,
        "NOCCode": "5132",
        "JobTitle": "music copyist"
    },
    {
        "value": 6792,
        "NOCCode": "5132",
        "JobTitle": "music director"
    },
    {
        "value": 6793,
        "NOCCode": "5132",
        "JobTitle": "music writer"
    },
    {
        "value": 6794,
        "NOCCode": "5132",
        "JobTitle": "musical director, motion picture"
    },
    {
        "value": 6795,
        "NOCCode": "5132",
        "JobTitle": "musical director, stage"
    },
    {
        "value": 6796,
        "NOCCode": "5132",
        "JobTitle": "musical director, television"
    },
    {
        "value": 6797,
        "NOCCode": "5132",
        "JobTitle": "orchestra artistic director"
    },
    {
        "value": 6798,
        "NOCCode": "5132",
        "JobTitle": "orchestral conductor"
    },
    {
        "value": 6799,
        "NOCCode": "5132",
        "JobTitle": "orchestrator"
    },
    {
        "value": 6800,
        "NOCCode": "5132",
        "JobTitle": "orchestrator-arranger"
    },
    {
        "value": 6801,
        "NOCCode": "5132",
        "JobTitle": "singer-songwriter-composer"
    },
    {
        "value": 6802,
        "NOCCode": "5132",
        "JobTitle": "songwriter"
    },
    {
        "value": 6803,
        "NOCCode": "5132",
        "JobTitle": "stage musical director"
    },
    {
        "value": 6804,
        "NOCCode": "5132",
        "JobTitle": "television musical director"
    },
    {
        "value": 6805,
        "NOCCode": "5132",
        "JobTitle": "writer, music"
    },
    {
        "value": 6806,
        "NOCCode": "5132",
        "JobTitle": "writer, songs"
    },
    {
        "value": 6807,
        "NOCCode": "5132",
        "JobTitle": "writer-composer-performer"
    },
    {
        "value": 6808,
        "NOCCode": "5133",
        "JobTitle": "accompanist"
    },
    {
        "value": 6809,
        "NOCCode": "5133",
        "JobTitle": "accordion teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6810,
        "NOCCode": "5133",
        "JobTitle": "alto"
    },
    {
        "value": 6811,
        "NOCCode": "5133",
        "JobTitle": "back-up musician"
    },
    {
        "value": 6812,
        "NOCCode": "5133",
        "JobTitle": "baritone"
    },
    {
        "value": 6813,
        "NOCCode": "5133",
        "JobTitle": "bass"
    },
    {
        "value": 6814,
        "NOCCode": "5133",
        "JobTitle": "bass drum teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6815,
        "NOCCode": "5133",
        "JobTitle": "bass player"
    },
    {
        "value": 6816,
        "NOCCode": "5133",
        "JobTitle": "bassoon teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6817,
        "NOCCode": "5133",
        "JobTitle": "blues singer"
    },
    {
        "value": 6818,
        "NOCCode": "5133",
        "JobTitle": "bugler"
    },
    {
        "value": 6819,
        "NOCCode": "5133",
        "JobTitle": "carillonneur"
    },
    {
        "value": 6820,
        "NOCCode": "5133",
        "JobTitle": "cellist"
    },
    {
        "value": 6821,
        "NOCCode": "5133",
        "JobTitle": "cello teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6822,
        "NOCCode": "5133",
        "JobTitle": "chorister"
    },
    {
        "value": 6823,
        "NOCCode": "5133",
        "JobTitle": "church organist"
    },
    {
        "value": 6824,
        "NOCCode": "5133",
        "JobTitle": "concert singer"
    },
    {
        "value": 6825,
        "NOCCode": "5133",
        "JobTitle": "cornetist"
    },
    {
        "value": 6826,
        "NOCCode": "5133",
        "JobTitle": "drummer"
    },
    {
        "value": 6827,
        "NOCCode": "5133",
        "JobTitle": "flutist"
    },
    {
        "value": 6828,
        "NOCCode": "5133",
        "JobTitle": "folk singer"
    },
    {
        "value": 6829,
        "NOCCode": "5133",
        "JobTitle": "gospel singer"
    },
    {
        "value": 6830,
        "NOCCode": "5133",
        "JobTitle": "guitar player"
    },
    {
        "value": 6831,
        "NOCCode": "5133",
        "JobTitle": "harpist"
    },
    {
        "value": 6832,
        "NOCCode": "5133",
        "JobTitle": "horn player"
    },
    {
        "value": 6833,
        "NOCCode": "5133",
        "JobTitle": "horn teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6834,
        "NOCCode": "5133",
        "JobTitle": "instrumental music teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6835,
        "NOCCode": "5133",
        "JobTitle": "instrumental musician"
    },
    {
        "value": 6836,
        "NOCCode": "5133",
        "JobTitle": "instrumentalist"
    },
    {
        "value": 6837,
        "NOCCode": "5133",
        "JobTitle": "keyboardist"
    },
    {
        "value": 6838,
        "NOCCode": "5133",
        "JobTitle": "lied singer"
    },
    {
        "value": 6839,
        "NOCCode": "5133",
        "JobTitle": "mezzo-soprano"
    },
    {
        "value": 6840,
        "NOCCode": "5133",
        "JobTitle": "music teacher – musicians"
    },
    {
        "value": 6841,
        "NOCCode": "5133",
        "JobTitle": "music teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6842,
        "NOCCode": "5133",
        "JobTitle": "musical interpreter"
    },
    {
        "value": 6843,
        "NOCCode": "5133",
        "JobTitle": "musician"
    },
    {
        "value": 6844,
        "NOCCode": "5133",
        "JobTitle": "opera singer"
    },
    {
        "value": 6845,
        "NOCCode": "5133",
        "JobTitle": "organ teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6846,
        "NOCCode": "5133",
        "JobTitle": "organist"
    },
    {
        "value": 6847,
        "NOCCode": "5133",
        "JobTitle": "percussionist"
    },
    {
        "value": 6848,
        "NOCCode": "5133",
        "JobTitle": "pianist"
    },
    {
        "value": 6849,
        "NOCCode": "5133",
        "JobTitle": "piano teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6850,
        "NOCCode": "5133",
        "JobTitle": "pop singer"
    },
    {
        "value": 6851,
        "NOCCode": "5133",
        "JobTitle": "recital singer"
    },
    {
        "value": 6852,
        "NOCCode": "5133",
        "JobTitle": "recording artist"
    },
    {
        "value": 6853,
        "NOCCode": "5133",
        "JobTitle": "rehearsal musician"
    },
    {
        "value": 6854,
        "NOCCode": "5133",
        "JobTitle": "rock singer"
    },
    {
        "value": 6855,
        "NOCCode": "5133",
        "JobTitle": "session musician"
    },
    {
        "value": 6856,
        "NOCCode": "5133",
        "JobTitle": "singer"
    },
    {
        "value": 6857,
        "NOCCode": "5133",
        "JobTitle": "soloist"
    },
    {
        "value": 6858,
        "NOCCode": "5133",
        "JobTitle": "soprano"
    },
    {
        "value": 6859,
        "NOCCode": "5133",
        "JobTitle": "street musician"
    },
    {
        "value": 6860,
        "NOCCode": "5133",
        "JobTitle": "teacher, music – private, conservatory or studio lessons"
    },
    {
        "value": 6861,
        "NOCCode": "5133",
        "JobTitle": "tenor"
    },
    {
        "value": 6862,
        "NOCCode": "5133",
        "JobTitle": "trombonist"
    },
    {
        "value": 6863,
        "NOCCode": "5133",
        "JobTitle": "trumpeter"
    },
    {
        "value": 6864,
        "NOCCode": "5133",
        "JobTitle": "tuba player"
    },
    {
        "value": 6865,
        "NOCCode": "5133",
        "JobTitle": "viola teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6866,
        "NOCCode": "5133",
        "JobTitle": "violin teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6867,
        "NOCCode": "5133",
        "JobTitle": "violinist"
    },
    {
        "value": 6868,
        "NOCCode": "5133",
        "JobTitle": "vocal music teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6869,
        "NOCCode": "5133",
        "JobTitle": "vocal soloist"
    },
    {
        "value": 6870,
        "NOCCode": "5133",
        "JobTitle": "vocalist"
    },
    {
        "value": 6871,
        "NOCCode": "5133",
        "JobTitle": "voice teacher – music"
    },
    {
        "value": 6872,
        "NOCCode": "5133",
        "JobTitle": "voice teacher – private, conservatory or studio lessons"
    },
    {
        "value": 6873,
        "NOCCode": "5134",
        "JobTitle": "ballerina"
    },
    {
        "value": 6874,
        "NOCCode": "5134",
        "JobTitle": "ballet dancer"
    },
    {
        "value": 6875,
        "NOCCode": "5134",
        "JobTitle": "ballet master"
    },
    {
        "value": 6876,
        "NOCCode": "5134",
        "JobTitle": "ballet teacher"
    },
    {
        "value": 6877,
        "NOCCode": "5134",
        "JobTitle": "ballroom dancing teacher"
    },
    {
        "value": 6878,
        "NOCCode": "5134",
        "JobTitle": "baton twirling instructor"
    },
    {
        "value": 6879,
        "NOCCode": "5134",
        "JobTitle": "belly dancer"
    },
    {
        "value": 6880,
        "NOCCode": "5134",
        "JobTitle": "chorus dancer"
    },
    {
        "value": 6881,
        "NOCCode": "5134",
        "JobTitle": "classical dance instructor"
    },
    {
        "value": 6882,
        "NOCCode": "5134",
        "JobTitle": "classical dancer"
    },
    {
        "value": 6883,
        "NOCCode": "5134",
        "JobTitle": "dance instructor"
    },
    {
        "value": 6884,
        "NOCCode": "5134",
        "JobTitle": "dance notator"
    },
    {
        "value": 6885,
        "NOCCode": "5134",
        "JobTitle": "dance teacher"
    },
    {
        "value": 6886,
        "NOCCode": "5134",
        "JobTitle": "dancer"
    },
    {
        "value": 6887,
        "NOCCode": "5134",
        "JobTitle": "dancing instructor"
    },
    {
        "value": 6888,
        "NOCCode": "5134",
        "JobTitle": "folk dance teacher"
    },
    {
        "value": 6889,
        "NOCCode": "5134",
        "JobTitle": "folk dancer"
    },
    {
        "value": 6890,
        "NOCCode": "5134",
        "JobTitle": "folkloric dance teacher"
    },
    {
        "value": 6891,
        "NOCCode": "5134",
        "JobTitle": "folkloric dancer"
    },
    {
        "value": 6892,
        "NOCCode": "5134",
        "JobTitle": "group dance teacher"
    },
    {
        "value": 6893,
        "NOCCode": "5134",
        "JobTitle": "group dancer"
    },
    {
        "value": 6894,
        "NOCCode": "5134",
        "JobTitle": "interpretative dancer"
    },
    {
        "value": 6895,
        "NOCCode": "5134",
        "JobTitle": "interpretive dancer"
    },
    {
        "value": 6896,
        "NOCCode": "5134",
        "JobTitle": "line dance teacher"
    },
    {
        "value": 6897,
        "NOCCode": "5134",
        "JobTitle": "line dancer"
    },
    {
        "value": 6898,
        "NOCCode": "5134",
        "JobTitle": "tap dance teacher"
    },
    {
        "value": 6899,
        "NOCCode": "5134",
        "JobTitle": "tap dancer"
    },
    {
        "value": 6900,
        "NOCCode": "5134",
        "JobTitle": "teacher, ballet"
    },
    {
        "value": 6901,
        "NOCCode": "5134",
        "JobTitle": "teacher, ballroom dancing"
    },
    {
        "value": 6902,
        "NOCCode": "5135",
        "JobTitle": "acting teacher – private or studio"
    },
    {
        "value": 6903,
        "NOCCode": "5135",
        "JobTitle": "actor/actress"
    },
    {
        "value": 6904,
        "NOCCode": "5135",
        "JobTitle": "comedian"
    },
    {
        "value": 6905,
        "NOCCode": "5135",
        "JobTitle": "comic"
    },
    {
        "value": 6906,
        "NOCCode": "5135",
        "JobTitle": "diction coach"
    },
    {
        "value": 6907,
        "NOCCode": "5135",
        "JobTitle": "drama actor"
    },
    {
        "value": 6908,
        "NOCCode": "5135",
        "JobTitle": "drama coach"
    },
    {
        "value": 6909,
        "NOCCode": "5135",
        "JobTitle": "drama teacher – private or studio"
    },
    {
        "value": 6910,
        "NOCCode": "5135",
        "JobTitle": "dramatic arts teacher – private or studio"
    },
    {
        "value": 6911,
        "NOCCode": "5135",
        "JobTitle": "dramatic reader"
    },
    {
        "value": 6912,
        "NOCCode": "5135",
        "JobTitle": "extra, performing arts"
    },
    {
        "value": 6913,
        "NOCCode": "5135",
        "JobTitle": "film dubber"
    },
    {
        "value": 6914,
        "NOCCode": "5135",
        "JobTitle": "humorist – performing arts"
    },
    {
        "value": 6915,
        "NOCCode": "5135",
        "JobTitle": "improviser"
    },
    {
        "value": 6916,
        "NOCCode": "5135",
        "JobTitle": "mime"
    },
    {
        "value": 6917,
        "NOCCode": "5135",
        "JobTitle": "movie actor"
    },
    {
        "value": 6918,
        "NOCCode": "5135",
        "JobTitle": "narrator"
    },
    {
        "value": 6919,
        "NOCCode": "5135",
        "JobTitle": "reader – performing arts"
    },
    {
        "value": 6920,
        "NOCCode": "5135",
        "JobTitle": "stand-up comedian"
    },
    {
        "value": 6921,
        "NOCCode": "5135",
        "JobTitle": "story teller"
    },
    {
        "value": 6922,
        "NOCCode": "5135",
        "JobTitle": "straight man"
    },
    {
        "value": 6923,
        "NOCCode": "5135",
        "JobTitle": "voice coach"
    },
    {
        "value": 6924,
        "NOCCode": "5135",
        "JobTitle": "voice-over actor"
    },
    {
        "value": 6925,
        "NOCCode": "5136",
        "JobTitle": "animal art painter"
    },
    {
        "value": 6926,
        "NOCCode": "5136",
        "JobTitle": "animal art sculptor"
    },
    {
        "value": 6927,
        "NOCCode": "5136",
        "JobTitle": "art instructor"
    },
    {
        "value": 6928,
        "NOCCode": "5136",
        "JobTitle": "art instructor (except primary, secondary, post-secondary education)"
    },
    {
        "value": 6929,
        "NOCCode": "5136",
        "JobTitle": "art teacher (except primary, secondary and post-secondary education)"
    },
    {
        "value": 6930,
        "NOCCode": "5136",
        "JobTitle": "artist"
    },
    {
        "value": 6931,
        "NOCCode": "5136",
        "JobTitle": "artist, plastic arts"
    },
    {
        "value": 6932,
        "NOCCode": "5136",
        "JobTitle": "artist, visual arts"
    },
    {
        "value": 6933,
        "NOCCode": "5136",
        "JobTitle": "artistic painter"
    },
    {
        "value": 6934,
        "NOCCode": "5136",
        "JobTitle": "artistic printmaker"
    },
    {
        "value": 6935,
        "NOCCode": "5136",
        "JobTitle": "charcoal artist"
    },
    {
        "value": 6936,
        "NOCCode": "5136",
        "JobTitle": "clay modeller"
    },
    {
        "value": 6937,
        "NOCCode": "5136",
        "JobTitle": "decorations painter – visual arts"
    },
    {
        "value": 6938,
        "NOCCode": "5136",
        "JobTitle": "etcher – visual arts"
    },
    {
        "value": 6939,
        "NOCCode": "5136",
        "JobTitle": "ice sculptor"
    },
    {
        "value": 6940,
        "NOCCode": "5136",
        "JobTitle": "ink sketcher"
    },
    {
        "value": 6941,
        "NOCCode": "5136",
        "JobTitle": "Inuit art sculptor"
    },
    {
        "value": 6942,
        "NOCCode": "5136",
        "JobTitle": "landscape artist, murals"
    },
    {
        "value": 6943,
        "NOCCode": "5136",
        "JobTitle": "landscape painter"
    },
    {
        "value": 6944,
        "NOCCode": "5136",
        "JobTitle": "lithographic artist"
    },
    {
        "value": 6945,
        "NOCCode": "5136",
        "JobTitle": "maritime art painter"
    },
    {
        "value": 6946,
        "NOCCode": "5136",
        "JobTitle": "mural landscape artist"
    },
    {
        "value": 6947,
        "NOCCode": "5136",
        "JobTitle": "mural painter"
    },
    {
        "value": 6948,
        "NOCCode": "5136",
        "JobTitle": "oil painter"
    },
    {
        "value": 6949,
        "NOCCode": "5136",
        "JobTitle": "painter – visual arts"
    },
    {
        "value": 6950,
        "NOCCode": "5136",
        "JobTitle": "plastic arts artist"
    },
    {
        "value": 6951,
        "NOCCode": "5136",
        "JobTitle": "portrait painter"
    },
    {
        "value": 6952,
        "NOCCode": "5136",
        "JobTitle": "printmaker – visual arts"
    },
    {
        "value": 6953,
        "NOCCode": "5136",
        "JobTitle": "printmaking teacher"
    },
    {
        "value": 6954,
        "NOCCode": "5136",
        "JobTitle": "scenery painter"
    },
    {
        "value": 6955,
        "NOCCode": "5136",
        "JobTitle": "scenic painter – visual arts"
    },
    {
        "value": 6956,
        "NOCCode": "5136",
        "JobTitle": "sculptor"
    },
    {
        "value": 6957,
        "NOCCode": "5136",
        "JobTitle": "sculptor, metal"
    },
    {
        "value": 6958,
        "NOCCode": "5136",
        "JobTitle": "sculptor, monuments"
    },
    {
        "value": 6959,
        "NOCCode": "5136",
        "JobTitle": "sculptor, wood"
    },
    {
        "value": 6960,
        "NOCCode": "5136",
        "JobTitle": "sculptor-modeller"
    },
    {
        "value": 6961,
        "NOCCode": "5136",
        "JobTitle": "sculpture teacher (except primary, secondary, post-secondary education)"
    },
    {
        "value": 6962,
        "NOCCode": "5136",
        "JobTitle": "sculpturing teacher (except primary, secondary, post-secondary education)"
    },
    {
        "value": 6963,
        "NOCCode": "5136",
        "JobTitle": "silhouette artist"
    },
    {
        "value": 6964,
        "NOCCode": "5136",
        "JobTitle": "silkscreen artist"
    },
    {
        "value": 6965,
        "NOCCode": "5136",
        "JobTitle": "teacher, painting"
    },
    {
        "value": 6966,
        "NOCCode": "5136",
        "JobTitle": "teacher, printmaking"
    },
    {
        "value": 6967,
        "NOCCode": "5136",
        "JobTitle": "teacher, sculpturing (except primary, secondary, post-secondary education)"
    },
    {
        "value": 6968,
        "NOCCode": "5136",
        "JobTitle": "video artist"
    },
    {
        "value": 6969,
        "NOCCode": "5136",
        "JobTitle": "visual arts teacher (except primary, secondary, post-secondary education)"
    },
    {
        "value": 6970,
        "NOCCode": "5136",
        "JobTitle": "watercolourist"
    },
    {
        "value": 6971,
        "NOCCode": "5136",
        "JobTitle": "wood block artist"
    },
    {
        "value": 6972,
        "NOCCode": "5136",
        "JobTitle": "wood sculptor"
    },
    {
        "value": 6973,
        "NOCCode": "5211",
        "JobTitle": "archival assistant"
    },
    {
        "value": 6974,
        "NOCCode": "5211",
        "JobTitle": "archival management technician"
    },
    {
        "value": 6975,
        "NOCCode": "5211",
        "JobTitle": "archive technician"
    },
    {
        "value": 6976,
        "NOCCode": "5211",
        "JobTitle": "assistant music librarian"
    },
    {
        "value": 6977,
        "NOCCode": "5211",
        "JobTitle": "attendant, archive"
    },
    {
        "value": 6978,
        "NOCCode": "5211",
        "JobTitle": "cataloguing technician"
    },
    {
        "value": 6979,
        "NOCCode": "5211",
        "JobTitle": "high school librarian"
    },
    {
        "value": 6980,
        "NOCCode": "5211",
        "JobTitle": "library technician"
    },
    {
        "value": 6981,
        "NOCCode": "5211",
        "JobTitle": "school librarian – secondary school"
    },
    {
        "value": 6982,
        "NOCCode": "5211",
        "JobTitle": "technical indexer – library"
    },
    {
        "value": 6983,
        "NOCCode": "5211",
        "JobTitle": "technician, library"
    },
    {
        "value": 6984,
        "NOCCode": "5212",
        "JobTitle": "aircraft restoration technician – museum"
    },
    {
        "value": 6985,
        "NOCCode": "5212",
        "JobTitle": "aquarium interpreter"
    },
    {
        "value": 6986,
        "NOCCode": "5212",
        "JobTitle": "archaeological technician"
    },
    {
        "value": 6987,
        "NOCCode": "5212",
        "JobTitle": "art gallery preparator"
    },
    {
        "value": 6988,
        "NOCCode": "5212",
        "JobTitle": "art gallery registrar"
    },
    {
        "value": 6989,
        "NOCCode": "5212",
        "JobTitle": "art objects preparator"
    },
    {
        "value": 6990,
        "NOCCode": "5212",
        "JobTitle": "art restoration technician"
    },
    {
        "value": 6991,
        "NOCCode": "5212",
        "JobTitle": "assistant registrar – museum"
    },
    {
        "value": 6992,
        "NOCCode": "5212",
        "JobTitle": "botanical garden interpreter"
    },
    {
        "value": 6993,
        "NOCCode": "5212",
        "JobTitle": "cataloguer – museum"
    },
    {
        "value": 6994,
        "NOCCode": "5212",
        "JobTitle": "cataloguer, museum objects"
    },
    {
        "value": 6995,
        "NOCCode": "5212",
        "JobTitle": "chief display officer – museum"
    },
    {
        "value": 6996,
        "NOCCode": "5212",
        "JobTitle": "chief exhibit officer – museum"
    },
    {
        "value": 6997,
        "NOCCode": "5212",
        "JobTitle": "chief preparator"
    },
    {
        "value": 6998,
        "NOCCode": "5212",
        "JobTitle": "chief preparator – museums and art galleries"
    },
    {
        "value": 6999,
        "NOCCode": "5212",
        "JobTitle": "communications equipment restoration technician – museum"
    },
    {
        "value": 7000,
        "NOCCode": "5212",
        "JobTitle": "community museum technician"
    },
    {
        "value": 7001,
        "NOCCode": "5212",
        "JobTitle": "conservation and restoration technician"
    },
    {
        "value": 7002,
        "NOCCode": "5212",
        "JobTitle": "conservation area interpreter"
    },
    {
        "value": 7003,
        "NOCCode": "5212",
        "JobTitle": "conservation technician – museums and art galleries"
    },
    {
        "value": 7004,
        "NOCCode": "5212",
        "JobTitle": "curatorial assistant"
    },
    {
        "value": 7005,
        "NOCCode": "5212",
        "JobTitle": "dinosaur museum interpreter"
    },
    {
        "value": 7006,
        "NOCCode": "5212",
        "JobTitle": "diorama maker – museums and art galleries"
    },
    {
        "value": 7007,
        "NOCCode": "5212",
        "JobTitle": "diorama technician"
    },
    {
        "value": 7008,
        "NOCCode": "5212",
        "JobTitle": "display officer – museums and art galleries"
    },
    {
        "value": 7009,
        "NOCCode": "5212",
        "JobTitle": "document restoration technician"
    },
    {
        "value": 7010,
        "NOCCode": "5212",
        "JobTitle": "ecological interpreter"
    },
    {
        "value": 7011,
        "NOCCode": "5212",
        "JobTitle": "exhibit officer – museum"
    },
    {
        "value": 7012,
        "NOCCode": "5212",
        "JobTitle": "exhibit preparator"
    },
    {
        "value": 7013,
        "NOCCode": "5212",
        "JobTitle": "exhibit technician"
    },
    {
        "value": 7014,
        "NOCCode": "5212",
        "JobTitle": "fossil recovery and reparation technician"
    },
    {
        "value": 7015,
        "NOCCode": "5212",
        "JobTitle": "frame fitter – museums and art galleries"
    },
    {
        "value": 7016,
        "NOCCode": "5212",
        "JobTitle": "framer – museum and art gallery"
    },
    {
        "value": 7017,
        "NOCCode": "5212",
        "JobTitle": "framing technician"
    },
    {
        "value": 7018,
        "NOCCode": "5212",
        "JobTitle": "head preparator – museums and art galleries"
    },
    {
        "value": 7019,
        "NOCCode": "5212",
        "JobTitle": "heritage interpreter"
    },
    {
        "value": 7020,
        "NOCCode": "5212",
        "JobTitle": "historic site interpreter"
    },
    {
        "value": 7021,
        "NOCCode": "5212",
        "JobTitle": "historical site technician"
    },
    {
        "value": 7022,
        "NOCCode": "5212",
        "JobTitle": "historical village supervisor"
    },
    {
        "value": 7023,
        "NOCCode": "5212",
        "JobTitle": "historical village technician"
    },
    {
        "value": 7024,
        "NOCCode": "5212",
        "JobTitle": "history museum interpreter"
    },
    {
        "value": 7025,
        "NOCCode": "5212",
        "JobTitle": "insectarium interpreter"
    },
    {
        "value": 7026,
        "NOCCode": "5212",
        "JobTitle": "interpreter, historic site"
    },
    {
        "value": 7027,
        "NOCCode": "5212",
        "JobTitle": "interpreter, museum"
    },
    {
        "value": 7028,
        "NOCCode": "5212",
        "JobTitle": "interpretive guide – museum"
    },
    {
        "value": 7029,
        "NOCCode": "5212",
        "JobTitle": "model maker-preparator"
    },
    {
        "value": 7030,
        "NOCCode": "5212",
        "JobTitle": "museology technician"
    },
    {
        "value": 7031,
        "NOCCode": "5212",
        "JobTitle": "museum cataloguer"
    },
    {
        "value": 7032,
        "NOCCode": "5212",
        "JobTitle": "museum exhibit officer"
    },
    {
        "value": 7033,
        "NOCCode": "5212",
        "JobTitle": "museum extension officer"
    },
    {
        "value": 7034,
        "NOCCode": "5212",
        "JobTitle": "museum objects cataloguer"
    },
    {
        "value": 7035,
        "NOCCode": "5212",
        "JobTitle": "museum objects preparator"
    },
    {
        "value": 7036,
        "NOCCode": "5212",
        "JobTitle": "museum preparator"
    },
    {
        "value": 7037,
        "NOCCode": "5212",
        "JobTitle": "museum registrar"
    },
    {
        "value": 7038,
        "NOCCode": "5212",
        "JobTitle": "museum technician"
    },
    {
        "value": 7039,
        "NOCCode": "5212",
        "JobTitle": "museum technician – earth's history and paleontology"
    },
    {
        "value": 7040,
        "NOCCode": "5212",
        "JobTitle": "natural habitat interpreter"
    },
    {
        "value": 7041,
        "NOCCode": "5212",
        "JobTitle": "nature interpreter"
    },
    {
        "value": 7042,
        "NOCCode": "5212",
        "JobTitle": "ornithology centre interpreter"
    },
    {
        "value": 7043,
        "NOCCode": "5212",
        "JobTitle": "paintings restoration technician"
    },
    {
        "value": 7044,
        "NOCCode": "5212",
        "JobTitle": "park interpreter"
    },
    {
        "value": 7045,
        "NOCCode": "5212",
        "JobTitle": "picture framer – museum and art gallery"
    },
    {
        "value": 7046,
        "NOCCode": "5212",
        "JobTitle": "planetarium interpreter"
    },
    {
        "value": 7047,
        "NOCCode": "5212",
        "JobTitle": "planetarium technician"
    },
    {
        "value": 7048,
        "NOCCode": "5212",
        "JobTitle": "preparator"
    },
    {
        "value": 7049,
        "NOCCode": "5212",
        "JobTitle": "railway equipment restoration technician – museum"
    },
    {
        "value": 7050,
        "NOCCode": "5212",
        "JobTitle": "recording technician – museum"
    },
    {
        "value": 7051,
        "NOCCode": "5212",
        "JobTitle": "regional museum technician"
    },
    {
        "value": 7052,
        "NOCCode": "5212",
        "JobTitle": "registrar, museum"
    },
    {
        "value": 7053,
        "NOCCode": "5212",
        "JobTitle": "restoration technician – museum"
    },
    {
        "value": 7054,
        "NOCCode": "5212",
        "JobTitle": "road transport equipment restoration technician – museum"
    },
    {
        "value": 7055,
        "NOCCode": "5212",
        "JobTitle": "taxidermist"
    },
    {
        "value": 7056,
        "NOCCode": "5212",
        "JobTitle": "taxidermy technician"
    },
    {
        "value": 7057,
        "NOCCode": "5212",
        "JobTitle": "technical officer – museum"
    },
    {
        "value": 7058,
        "NOCCode": "5212",
        "JobTitle": "technician, conservation – museums and art galleries"
    },
    {
        "value": 7059,
        "NOCCode": "5212",
        "JobTitle": "technician, museum"
    },
    {
        "value": 7060,
        "NOCCode": "5212",
        "JobTitle": "technician, restoration – museum"
    },
    {
        "value": 7061,
        "NOCCode": "5212",
        "JobTitle": "wilderness interpreter"
    },
    {
        "value": 7062,
        "NOCCode": "5221",
        "JobTitle": "aerial photographer"
    },
    {
        "value": 7063,
        "NOCCode": "5221",
        "JobTitle": "chief photographer"
    },
    {
        "value": 7064,
        "NOCCode": "5221",
        "JobTitle": "commercial photographer"
    },
    {
        "value": 7065,
        "NOCCode": "5221",
        "JobTitle": "evidence photographer – forensic"
    },
    {
        "value": 7066,
        "NOCCode": "5221",
        "JobTitle": "fashion photographer"
    },
    {
        "value": 7067,
        "NOCCode": "5221",
        "JobTitle": "finish photographer"
    },
    {
        "value": 7068,
        "NOCCode": "5221",
        "JobTitle": "forensic photographer"
    },
    {
        "value": 7069,
        "NOCCode": "5221",
        "JobTitle": "industrial photographer"
    },
    {
        "value": 7070,
        "NOCCode": "5221",
        "JobTitle": "medical photographer"
    },
    {
        "value": 7071,
        "NOCCode": "5221",
        "JobTitle": "multimedia photographer"
    },
    {
        "value": 7072,
        "NOCCode": "5221",
        "JobTitle": "news photographer"
    },
    {
        "value": 7073,
        "NOCCode": "5221",
        "JobTitle": "photographer"
    },
    {
        "value": 7074,
        "NOCCode": "5221",
        "JobTitle": "photography supervisor"
    },
    {
        "value": 7075,
        "NOCCode": "5221",
        "JobTitle": "photomicrographer"
    },
    {
        "value": 7076,
        "NOCCode": "5221",
        "JobTitle": "portrait photographer"
    },
    {
        "value": 7077,
        "NOCCode": "5221",
        "JobTitle": "racetrack finish photographer"
    },
    {
        "value": 7078,
        "NOCCode": "5221",
        "JobTitle": "retoucher – photography"
    },
    {
        "value": 7079,
        "NOCCode": "5221",
        "JobTitle": "scientific photographer"
    },
    {
        "value": 7080,
        "NOCCode": "5221",
        "JobTitle": "still photographer"
    },
    {
        "value": 7081,
        "NOCCode": "5221",
        "JobTitle": "street photographer"
    },
    {
        "value": 7082,
        "NOCCode": "5221",
        "JobTitle": "supervisor, photography"
    },
    {
        "value": 7083,
        "NOCCode": "5221",
        "JobTitle": "wildlife photographer"
    },
    {
        "value": 7084,
        "NOCCode": "5222",
        "JobTitle": "assistant camera operator"
    },
    {
        "value": 7085,
        "NOCCode": "5222",
        "JobTitle": "camera operator"
    },
    {
        "value": 7086,
        "NOCCode": "5222",
        "JobTitle": "camera operator, motion picture"
    },
    {
        "value": 7087,
        "NOCCode": "5222",
        "JobTitle": "camera operator, video"
    },
    {
        "value": 7088,
        "NOCCode": "5222",
        "JobTitle": "dance videographer"
    },
    {
        "value": 7089,
        "NOCCode": "5222",
        "JobTitle": "EFP (electronic field production) camera operator"
    },
    {
        "value": 7090,
        "NOCCode": "5222",
        "JobTitle": "electronic field production (EFP) camera operator"
    },
    {
        "value": 7091,
        "NOCCode": "5222",
        "JobTitle": "electronic news gathering (ENG) camera operator"
    },
    {
        "value": 7092,
        "NOCCode": "5222",
        "JobTitle": "ENG (electronic news gathering) camera operator"
    },
    {
        "value": 7093,
        "NOCCode": "5222",
        "JobTitle": "film camera operator"
    },
    {
        "value": 7094,
        "NOCCode": "5222",
        "JobTitle": "film cameraman/woman"
    },
    {
        "value": 7095,
        "NOCCode": "5222",
        "JobTitle": "motion picture camera operator"
    },
    {
        "value": 7096,
        "NOCCode": "5222",
        "JobTitle": "news camera operator"
    },
    {
        "value": 7097,
        "NOCCode": "5222",
        "JobTitle": "senior motion picture camera operator"
    },
    {
        "value": 7098,
        "NOCCode": "5222",
        "JobTitle": "studio camera operator"
    },
    {
        "value": 7099,
        "NOCCode": "5222",
        "JobTitle": "television camera operator"
    },
    {
        "value": 7100,
        "NOCCode": "5222",
        "JobTitle": "television news camera operator"
    },
    {
        "value": 7101,
        "NOCCode": "5222",
        "JobTitle": "video camera operator"
    },
    {
        "value": 7102,
        "NOCCode": "5222",
        "JobTitle": "videographer"
    },
    {
        "value": 7103,
        "NOCCode": "5223",
        "JobTitle": "airbrush artist"
    },
    {
        "value": 7104,
        "NOCCode": "5223",
        "JobTitle": "animated cartoon inker"
    },
    {
        "value": 7105,
        "NOCCode": "5223",
        "JobTitle": "animated cartoon painter"
    },
    {
        "value": 7106,
        "NOCCode": "5223",
        "JobTitle": "animated cartoon technician"
    },
    {
        "value": 7107,
        "NOCCode": "5223",
        "JobTitle": "animated cartoons inker and painter"
    },
    {
        "value": 7108,
        "NOCCode": "5223",
        "JobTitle": "animation inker"
    },
    {
        "value": 7109,
        "NOCCode": "5223",
        "JobTitle": "animation painter"
    },
    {
        "value": 7110,
        "NOCCode": "5223",
        "JobTitle": "apprentice sign painter"
    },
    {
        "value": 7111,
        "NOCCode": "5223",
        "JobTitle": "art work assembler"
    },
    {
        "value": 7112,
        "NOCCode": "5223",
        "JobTitle": "artist, airbrush"
    },
    {
        "value": 7113,
        "NOCCode": "5223",
        "JobTitle": "artist, cartoon background"
    },
    {
        "value": 7114,
        "NOCCode": "5223",
        "JobTitle": "artist, paste-up"
    },
    {
        "value": 7115,
        "NOCCode": "5223",
        "JobTitle": "assembler, art work"
    },
    {
        "value": 7116,
        "NOCCode": "5223",
        "JobTitle": "assembler, graphic arts"
    },
    {
        "value": 7117,
        "NOCCode": "5223",
        "JobTitle": "cartoon background artist"
    },
    {
        "value": 7118,
        "NOCCode": "5223",
        "JobTitle": "colourist, photography"
    },
    {
        "value": 7119,
        "NOCCode": "5223",
        "JobTitle": "computer graphics design technician"
    },
    {
        "value": 7120,
        "NOCCode": "5223",
        "JobTitle": "computer graphics operator"
    },
    {
        "value": 7121,
        "NOCCode": "5223",
        "JobTitle": "computer graphics technician"
    },
    {
        "value": 7122,
        "NOCCode": "5223",
        "JobTitle": "copy stylist, graphic arts"
    },
    {
        "value": 7123,
        "NOCCode": "5223",
        "JobTitle": "cutter, silkscreen stencils"
    },
    {
        "value": 7124,
        "NOCCode": "5223",
        "JobTitle": "design assistant – advertising production"
    },
    {
        "value": 7125,
        "NOCCode": "5223",
        "JobTitle": "designer, poster layouts"
    },
    {
        "value": 7126,
        "NOCCode": "5223",
        "JobTitle": "detailer, sign layouts"
    },
    {
        "value": 7127,
        "NOCCode": "5223",
        "JobTitle": "digital graphic design technician"
    },
    {
        "value": 7128,
        "NOCCode": "5223",
        "JobTitle": "graphic arts assembler"
    },
    {
        "value": 7129,
        "NOCCode": "5223",
        "JobTitle": "graphic arts copy stylist"
    },
    {
        "value": 7130,
        "NOCCode": "5223",
        "JobTitle": "graphic arts renderer"
    },
    {
        "value": 7131,
        "NOCCode": "5223",
        "JobTitle": "graphic arts technician"
    },
    {
        "value": 7132,
        "NOCCode": "5223",
        "JobTitle": "graphics technician"
    },
    {
        "value": 7133,
        "NOCCode": "5223",
        "JobTitle": "hand painter – graphic arts"
    },
    {
        "value": 7134,
        "NOCCode": "5223",
        "JobTitle": "inker and painter, animated cartoons"
    },
    {
        "value": 7135,
        "NOCCode": "5223",
        "JobTitle": "inker, animated cartoons"
    },
    {
        "value": 7136,
        "NOCCode": "5223",
        "JobTitle": "letterer, photo processes – graphic arts"
    },
    {
        "value": 7137,
        "NOCCode": "5223",
        "JobTitle": "lettering artist"
    },
    {
        "value": 7138,
        "NOCCode": "5223",
        "JobTitle": "mannequin artist"
    },
    {
        "value": 7139,
        "NOCCode": "5223",
        "JobTitle": "multimedia graphic design technician"
    },
    {
        "value": 7140,
        "NOCCode": "5223",
        "JobTitle": "multimedia graphics technician"
    },
    {
        "value": 7141,
        "NOCCode": "5223",
        "JobTitle": "operator, animation camera"
    },
    {
        "value": 7142,
        "NOCCode": "5223",
        "JobTitle": "painter, animated cartoons"
    },
    {
        "value": 7143,
        "NOCCode": "5223",
        "JobTitle": "painter, animation"
    },
    {
        "value": 7144,
        "NOCCode": "5223",
        "JobTitle": "painter, hand – graphic arts"
    },
    {
        "value": 7145,
        "NOCCode": "5223",
        "JobTitle": "painter, posters"
    },
    {
        "value": 7146,
        "NOCCode": "5223",
        "JobTitle": "painter, signs"
    },
    {
        "value": 7147,
        "NOCCode": "5223",
        "JobTitle": "painter, traffic signs"
    },
    {
        "value": 7148,
        "NOCCode": "5223",
        "JobTitle": "paste-up artist"
    },
    {
        "value": 7149,
        "NOCCode": "5223",
        "JobTitle": "photo process letterer – graphic arts"
    },
    {
        "value": 7150,
        "NOCCode": "5223",
        "JobTitle": "photo stencil maker"
    },
    {
        "value": 7151,
        "NOCCode": "5223",
        "JobTitle": "photograph retoucher"
    },
    {
        "value": 7152,
        "NOCCode": "5223",
        "JobTitle": "photographic airbrush artist"
    },
    {
        "value": 7153,
        "NOCCode": "5223",
        "JobTitle": "photographic colourist"
    },
    {
        "value": 7154,
        "NOCCode": "5223",
        "JobTitle": "photography colourist"
    },
    {
        "value": 7155,
        "NOCCode": "5223",
        "JobTitle": "poster layout designer"
    },
    {
        "value": 7156,
        "NOCCode": "5223",
        "JobTitle": "renderer, graphic arts"
    },
    {
        "value": 7157,
        "NOCCode": "5223",
        "JobTitle": "rendering specialist"
    },
    {
        "value": 7158,
        "NOCCode": "5223",
        "JobTitle": "screen stencil cutter – graphic arts"
    },
    {
        "value": 7159,
        "NOCCode": "5223",
        "JobTitle": "sign layout detailer"
    },
    {
        "value": 7160,
        "NOCCode": "5223",
        "JobTitle": "sign painter"
    },
    {
        "value": 7161,
        "NOCCode": "5223",
        "JobTitle": "sign writer"
    },
    {
        "value": 7162,
        "NOCCode": "5223",
        "JobTitle": "silkscreen stencil cutter"
    },
    {
        "value": 7163,
        "NOCCode": "5223",
        "JobTitle": "silkscreen technician"
    },
    {
        "value": 7164,
        "NOCCode": "5223",
        "JobTitle": "stencil marker"
    },
    {
        "value": 7165,
        "NOCCode": "5223",
        "JobTitle": "stencil marker – graphic arts"
    },
    {
        "value": 7166,
        "NOCCode": "5223",
        "JobTitle": "technician, graphic arts"
    },
    {
        "value": 7167,
        "NOCCode": "5223",
        "JobTitle": "technician, silkscreen"
    },
    {
        "value": 7168,
        "NOCCode": "5223",
        "JobTitle": "traffic sign painter"
    },
    {
        "value": 7169,
        "NOCCode": "5223",
        "JobTitle": "video game artist"
    },
    {
        "value": 7170,
        "NOCCode": "5223",
        "JobTitle": "video game designer"
    },
    {
        "value": 7171,
        "NOCCode": "5223",
        "JobTitle": "vinyl lettering artist"
    },
    {
        "value": 7172,
        "NOCCode": "5223",
        "JobTitle": "writer, signs"
    },
    {
        "value": 7173,
        "NOCCode": "5224",
        "JobTitle": "broadcast field technician"
    },
    {
        "value": 7174,
        "NOCCode": "5224",
        "JobTitle": "broadcast maintenance technician"
    },
    {
        "value": 7175,
        "NOCCode": "5224",
        "JobTitle": "broadcast technician"
    },
    {
        "value": 7176,
        "NOCCode": "5224",
        "JobTitle": "broadcast transmitter operator"
    },
    {
        "value": 7177,
        "NOCCode": "5224",
        "JobTitle": "broadcasting control operator"
    },
    {
        "value": 7178,
        "NOCCode": "5224",
        "JobTitle": "broadcasting engineer"
    },
    {
        "value": 7179,
        "NOCCode": "5224",
        "JobTitle": "broadcasting switcher"
    },
    {
        "value": 7180,
        "NOCCode": "5224",
        "JobTitle": "broadcasting technician"
    },
    {
        "value": 7181,
        "NOCCode": "5224",
        "JobTitle": "broadcasting transmitter operator"
    },
    {
        "value": 7182,
        "NOCCode": "5224",
        "JobTitle": "control operator, broadcasting"
    },
    {
        "value": 7183,
        "NOCCode": "5224",
        "JobTitle": "control operator, transmission studio"
    },
    {
        "value": 7184,
        "NOCCode": "5224",
        "JobTitle": "control operator, video transmission studio"
    },
    {
        "value": 7185,
        "NOCCode": "5224",
        "JobTitle": "control room technician – broadcasting"
    },
    {
        "value": 7186,
        "NOCCode": "5224",
        "JobTitle": "master control room (MCR) equipment operator – broadcasting"
    },
    {
        "value": 7187,
        "NOCCode": "5224",
        "JobTitle": "master control room (MCR) technician – broadcasting"
    },
    {
        "value": 7188,
        "NOCCode": "5224",
        "JobTitle": "MCR (master control room) equipment operator"
    },
    {
        "value": 7189,
        "NOCCode": "5224",
        "JobTitle": "MCR (master control room) technician – broadcasting"
    },
    {
        "value": 7190,
        "NOCCode": "5224",
        "JobTitle": "mobile broadcasting equipment installer"
    },
    {
        "value": 7191,
        "NOCCode": "5224",
        "JobTitle": "mobile broadcasting equipment operator"
    },
    {
        "value": 7192,
        "NOCCode": "5224",
        "JobTitle": "mobile radio broadcasting supervisor"
    },
    {
        "value": 7193,
        "NOCCode": "5224",
        "JobTitle": "operator, broadcast transmitter"
    },
    {
        "value": 7194,
        "NOCCode": "5224",
        "JobTitle": "operator, broadcasting control"
    },
    {
        "value": 7195,
        "NOCCode": "5224",
        "JobTitle": "operator, broadcasting transmitter"
    },
    {
        "value": 7196,
        "NOCCode": "5224",
        "JobTitle": "operator, master control room (MCR) equipment – broadcasting"
    },
    {
        "value": 7197,
        "NOCCode": "5224",
        "JobTitle": "operator, mobile broadcasting equipment"
    },
    {
        "value": 7198,
        "NOCCode": "5224",
        "JobTitle": "operator, transmission control studio"
    },
    {
        "value": 7199,
        "NOCCode": "5224",
        "JobTitle": "operator, video transmission control studio"
    },
    {
        "value": 7200,
        "NOCCode": "5224",
        "JobTitle": "production switcher"
    },
    {
        "value": 7201,
        "NOCCode": "5224",
        "JobTitle": "regional transmitter technician"
    },
    {
        "value": 7202,
        "NOCCode": "5224",
        "JobTitle": "short-wave receiving station technician"
    },
    {
        "value": 7203,
        "NOCCode": "5224",
        "JobTitle": "supervisor, mobile radio broadcasting"
    },
    {
        "value": 7204,
        "NOCCode": "5224",
        "JobTitle": "supervisor, radio broadcasting equipment operators"
    },
    {
        "value": 7205,
        "NOCCode": "5224",
        "JobTitle": "supervisor, television broadcasting equipment operators"
    },
    {
        "value": 7206,
        "NOCCode": "5224",
        "JobTitle": "switcher, broadcasting"
    },
    {
        "value": 7207,
        "NOCCode": "5224",
        "JobTitle": "switcher, production"
    },
    {
        "value": 7208,
        "NOCCode": "5224",
        "JobTitle": "technician, regional transmitter"
    },
    {
        "value": 7209,
        "NOCCode": "5224",
        "JobTitle": "technician, short-wave receiving station"
    },
    {
        "value": 7210,
        "NOCCode": "5224",
        "JobTitle": "television switcher"
    },
    {
        "value": 7211,
        "NOCCode": "5224",
        "JobTitle": "transmission operator"
    },
    {
        "value": 7212,
        "NOCCode": "5224",
        "JobTitle": "transmission studio control operator"
    },
    {
        "value": 7213,
        "NOCCode": "5224",
        "JobTitle": "transmitter operator"
    },
    {
        "value": 7214,
        "NOCCode": "5224",
        "JobTitle": "transmitting operator"
    },
    {
        "value": 7215,
        "NOCCode": "5224",
        "JobTitle": "video control operator"
    },
    {
        "value": 7216,
        "NOCCode": "5224",
        "JobTitle": "video transmission operator"
    },
    {
        "value": 7217,
        "NOCCode": "5224",
        "JobTitle": "video transmission studio control operator"
    },
    {
        "value": 7218,
        "NOCCode": "5225",
        "JobTitle": "assistant sound editor"
    },
    {
        "value": 7219,
        "NOCCode": "5225",
        "JobTitle": "audio operator"
    },
    {
        "value": 7220,
        "NOCCode": "5225",
        "JobTitle": "audio operator assistant"
    },
    {
        "value": 7221,
        "NOCCode": "5225",
        "JobTitle": "audio recording studio operator"
    },
    {
        "value": 7222,
        "NOCCode": "5225",
        "JobTitle": "audio technician"
    },
    {
        "value": 7223,
        "NOCCode": "5225",
        "JobTitle": "audiovisual (AV) technician"
    },
    {
        "value": 7224,
        "NOCCode": "5225",
        "JobTitle": "AV (audiovisual) technician"
    },
    {
        "value": 7225,
        "NOCCode": "5225",
        "JobTitle": "colour timer – video"
    },
    {
        "value": 7226,
        "NOCCode": "5225",
        "JobTitle": "digital effects specialist"
    },
    {
        "value": 7227,
        "NOCCode": "5225",
        "JobTitle": "dubbing machine operator"
    },
    {
        "value": 7228,
        "NOCCode": "5225",
        "JobTitle": "editor, sound"
    },
    {
        "value": 7229,
        "NOCCode": "5225",
        "JobTitle": "editor, videotape"
    },
    {
        "value": 7230,
        "NOCCode": "5225",
        "JobTitle": "electronic video graphics operator"
    },
    {
        "value": 7231,
        "NOCCode": "5225",
        "JobTitle": "ENG (electronic news gathering) editor"
    },
    {
        "value": 7232,
        "NOCCode": "5225",
        "JobTitle": "film technician"
    },
    {
        "value": 7233,
        "NOCCode": "5225",
        "JobTitle": "film technician – motion picture"
    },
    {
        "value": 7234,
        "NOCCode": "5225",
        "JobTitle": "master record cutter"
    },
    {
        "value": 7235,
        "NOCCode": "5225",
        "JobTitle": "mixer, music or sound"
    },
    {
        "value": 7236,
        "NOCCode": "5225",
        "JobTitle": "multimedia sound technician"
    },
    {
        "value": 7237,
        "NOCCode": "5225",
        "JobTitle": "multimedia sound-effects supervisor"
    },
    {
        "value": 7238,
        "NOCCode": "5225",
        "JobTitle": "music editor – recording studio"
    },
    {
        "value": 7239,
        "NOCCode": "5225",
        "JobTitle": "music mixer"
    },
    {
        "value": 7240,
        "NOCCode": "5225",
        "JobTitle": "music or sound mixer"
    },
    {
        "value": 7241,
        "NOCCode": "5225",
        "JobTitle": "operator, P.A. (public address)"
    },
    {
        "value": 7242,
        "NOCCode": "5225",
        "JobTitle": "operator, P.A. (public address) systems"
    },
    {
        "value": 7243,
        "NOCCode": "5225",
        "JobTitle": "operator, public address (P.A.)"
    },
    {
        "value": 7244,
        "NOCCode": "5225",
        "JobTitle": "operator, public address (P.A.) systems"
    },
    {
        "value": 7245,
        "NOCCode": "5225",
        "JobTitle": "P.A. (public address) operator"
    },
    {
        "value": 7246,
        "NOCCode": "5225",
        "JobTitle": "P.A. (public address) system operator"
    },
    {
        "value": 7247,
        "NOCCode": "5225",
        "JobTitle": "playback operator"
    },
    {
        "value": 7248,
        "NOCCode": "5225",
        "JobTitle": "postproduction technician"
    },
    {
        "value": 7249,
        "NOCCode": "5225",
        "JobTitle": "production sound recordist"
    },
    {
        "value": 7250,
        "NOCCode": "5225",
        "JobTitle": "public address (P.A.) operator"
    },
    {
        "value": 7251,
        "NOCCode": "5225",
        "JobTitle": "radio recorder"
    },
    {
        "value": 7252,
        "NOCCode": "5225",
        "JobTitle": "recording engineer"
    },
    {
        "value": 7253,
        "NOCCode": "5225",
        "JobTitle": "recording studio technician"
    },
    {
        "value": 7254,
        "NOCCode": "5225",
        "JobTitle": "re-recording mixer"
    },
    {
        "value": 7255,
        "NOCCode": "5225",
        "JobTitle": "sound cutter"
    },
    {
        "value": 7256,
        "NOCCode": "5225",
        "JobTitle": "sound editor"
    },
    {
        "value": 7257,
        "NOCCode": "5225",
        "JobTitle": "sound effects editor"
    },
    {
        "value": 7258,
        "NOCCode": "5225",
        "JobTitle": "sound effects specialist"
    },
    {
        "value": 7259,
        "NOCCode": "5225",
        "JobTitle": "sound engineer (except professional engineers)"
    },
    {
        "value": 7260,
        "NOCCode": "5225",
        "JobTitle": "sound engineer assistant"
    },
    {
        "value": 7261,
        "NOCCode": "5225",
        "JobTitle": "sound keyer"
    },
    {
        "value": 7262,
        "NOCCode": "5225",
        "JobTitle": "sound librarian – audio-recording"
    },
    {
        "value": 7263,
        "NOCCode": "5225",
        "JobTitle": "sound mix engineer"
    },
    {
        "value": 7264,
        "NOCCode": "5225",
        "JobTitle": "sound mixer"
    },
    {
        "value": 7265,
        "NOCCode": "5225",
        "JobTitle": "sound operator"
    },
    {
        "value": 7266,
        "NOCCode": "5225",
        "JobTitle": "sound recorder"
    },
    {
        "value": 7267,
        "NOCCode": "5225",
        "JobTitle": "sound recording engineer"
    },
    {
        "value": 7268,
        "NOCCode": "5225",
        "JobTitle": "sound recording supervisor"
    },
    {
        "value": 7269,
        "NOCCode": "5225",
        "JobTitle": "sound recordist"
    },
    {
        "value": 7270,
        "NOCCode": "5225",
        "JobTitle": "sound technician"
    },
    {
        "value": 7271,
        "NOCCode": "5225",
        "JobTitle": "sound-recording and video-recording reproduction equipment operator"
    },
    {
        "value": 7272,
        "NOCCode": "5225",
        "JobTitle": "sound-recording equipment operator"
    },
    {
        "value": 7273,
        "NOCCode": "5225",
        "JobTitle": "stereo tape editor"
    },
    {
        "value": 7274,
        "NOCCode": "5225",
        "JobTitle": "studio operator, audio recording"
    },
    {
        "value": 7275,
        "NOCCode": "5225",
        "JobTitle": "supervisor, sound recording"
    },
    {
        "value": 7276,
        "NOCCode": "5225",
        "JobTitle": "video and sound recorder"
    },
    {
        "value": 7277,
        "NOCCode": "5225",
        "JobTitle": "video recorder"
    },
    {
        "value": 7278,
        "NOCCode": "5225",
        "JobTitle": "video recording technician"
    },
    {
        "value": 7279,
        "NOCCode": "5225",
        "JobTitle": "video sound mixer"
    },
    {
        "value": 7280,
        "NOCCode": "5225",
        "JobTitle": "video technician"
    },
    {
        "value": 7281,
        "NOCCode": "5225",
        "JobTitle": "video-editing equipment operator"
    },
    {
        "value": 7282,
        "NOCCode": "5225",
        "JobTitle": "video-recording equipment operator"
    },
    {
        "value": 7283,
        "NOCCode": "5225",
        "JobTitle": "videotape editor"
    },
    {
        "value": 7284,
        "NOCCode": "5225",
        "JobTitle": "videotape postproduction editor"
    },
    {
        "value": 7285,
        "NOCCode": "5225",
        "JobTitle": "videotape recording (VTR) editor"
    },
    {
        "value": 7286,
        "NOCCode": "5225",
        "JobTitle": "videotape recording (VTR) operator"
    },
    {
        "value": 7287,
        "NOCCode": "5225",
        "JobTitle": "videotape technician"
    },
    {
        "value": 7288,
        "NOCCode": "5225",
        "JobTitle": "VTR (videotape recording) editor"
    },
    {
        "value": 7289,
        "NOCCode": "5225",
        "JobTitle": "VTR (videotape recording) operator"
    },
    {
        "value": 7290,
        "NOCCode": "5226",
        "JobTitle": "animal wrangler – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7291,
        "NOCCode": "5226",
        "JobTitle": "broadcasting co-ordinator"
    },
    {
        "value": 7292,
        "NOCCode": "5226",
        "JobTitle": "chief lighting technician"
    },
    {
        "value": 7293,
        "NOCCode": "5226",
        "JobTitle": "chief stage electrician"
    },
    {
        "value": 7294,
        "NOCCode": "5226",
        "JobTitle": "chief technician, lighting effects"
    },
    {
        "value": 7295,
        "NOCCode": "5226",
        "JobTitle": "costume maker"
    },
    {
        "value": 7296,
        "NOCCode": "5226",
        "JobTitle": "costume supervisor"
    },
    {
        "value": 7297,
        "NOCCode": "5226",
        "JobTitle": "costumer"
    },
    {
        "value": 7298,
        "NOCCode": "5226",
        "JobTitle": "costumer – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7299,
        "NOCCode": "5226",
        "JobTitle": "costumes co-ordinator"
    },
    {
        "value": 7300,
        "NOCCode": "5226",
        "JobTitle": "costumier"
    },
    {
        "value": 7301,
        "NOCCode": "5226",
        "JobTitle": "electrician, set"
    },
    {
        "value": 7302,
        "NOCCode": "5226",
        "JobTitle": "electrician, stage"
    },
    {
        "value": 7303,
        "NOCCode": "5226",
        "JobTitle": "exterior set manager"
    },
    {
        "value": 7304,
        "NOCCode": "5226",
        "JobTitle": "exterior shooting director"
    },
    {
        "value": 7305,
        "NOCCode": "5226",
        "JobTitle": "extra wrangler – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7306,
        "NOCCode": "5226",
        "JobTitle": "film location manager"
    },
    {
        "value": 7307,
        "NOCCode": "5226",
        "JobTitle": "first grip"
    },
    {
        "value": 7308,
        "NOCCode": "5226",
        "JobTitle": "floor director – broadcasting"
    },
    {
        "value": 7309,
        "NOCCode": "5226",
        "JobTitle": "floor manager – broadcasting"
    },
    {
        "value": 7310,
        "NOCCode": "5226",
        "JobTitle": "gaffer"
    },
    {
        "value": 7311,
        "NOCCode": "5226",
        "JobTitle": "head grip"
    },
    {
        "value": 7312,
        "NOCCode": "5226",
        "JobTitle": "head of wardrobe"
    },
    {
        "value": 7313,
        "NOCCode": "5226",
        "JobTitle": "key dresser"
    },
    {
        "value": 7314,
        "NOCCode": "5226",
        "JobTitle": "key grip"
    },
    {
        "value": 7315,
        "NOCCode": "5226",
        "JobTitle": "lighting effects chief technician"
    },
    {
        "value": 7316,
        "NOCCode": "5226",
        "JobTitle": "lighting technician"
    },
    {
        "value": 7317,
        "NOCCode": "5226",
        "JobTitle": "location manager – motion pictures and broadcasting"
    },
    {
        "value": 7318,
        "NOCCode": "5226",
        "JobTitle": "make-up artist – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7319,
        "NOCCode": "5226",
        "JobTitle": "make-up artist and hairdresser – performing arts"
    },
    {
        "value": 7320,
        "NOCCode": "5226",
        "JobTitle": "make-up artist and wig maker"
    },
    {
        "value": 7321,
        "NOCCode": "5226",
        "JobTitle": "motion picture facilities supervisor"
    },
    {
        "value": 7322,
        "NOCCode": "5226",
        "JobTitle": "music program planner"
    },
    {
        "value": 7323,
        "NOCCode": "5226",
        "JobTitle": "music program planner, radio"
    },
    {
        "value": 7324,
        "NOCCode": "5226",
        "JobTitle": "optical effects co-ordinator"
    },
    {
        "value": 7325,
        "NOCCode": "5226",
        "JobTitle": "presentation co-ordinator – broadcasting"
    },
    {
        "value": 7326,
        "NOCCode": "5226",
        "JobTitle": "production co-ordinator – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7327,
        "NOCCode": "5226",
        "JobTitle": "production co-ordinator – radiobroadcasting"
    },
    {
        "value": 7328,
        "NOCCode": "5226",
        "JobTitle": "production technician – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7329,
        "NOCCode": "5226",
        "JobTitle": "program co-ordinator – broadcasting"
    },
    {
        "value": 7330,
        "NOCCode": "5226",
        "JobTitle": "program planner, music"
    },
    {
        "value": 7331,
        "NOCCode": "5226",
        "JobTitle": "program planner, radio"
    },
    {
        "value": 7332,
        "NOCCode": "5226",
        "JobTitle": "programming manager"
    },
    {
        "value": 7333,
        "NOCCode": "5226",
        "JobTitle": "properties custodian – stage productions or motion pictures"
    },
    {
        "value": 7334,
        "NOCCode": "5226",
        "JobTitle": "property master – broadcasting"
    },
    {
        "value": 7335,
        "NOCCode": "5226",
        "JobTitle": "property master – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7336,
        "NOCCode": "5226",
        "JobTitle": "radio music program planner"
    },
    {
        "value": 7337,
        "NOCCode": "5226",
        "JobTitle": "radio program planner"
    },
    {
        "value": 7338,
        "NOCCode": "5226",
        "JobTitle": "remote broadcasting co-ordinator"
    },
    {
        "value": 7339,
        "NOCCode": "5226",
        "JobTitle": "rigging gaffer"
    },
    {
        "value": 7340,
        "NOCCode": "5226",
        "JobTitle": "script supervisor"
    },
    {
        "value": 7341,
        "NOCCode": "5226",
        "JobTitle": "set electrician"
    },
    {
        "value": 7342,
        "NOCCode": "5226",
        "JobTitle": "settings shop foreman/woman"
    },
    {
        "value": 7343,
        "NOCCode": "5226",
        "JobTitle": "special effects technician"
    },
    {
        "value": 7344,
        "NOCCode": "5226",
        "JobTitle": "sports program co-ordinator – broadcasting"
    },
    {
        "value": 7345,
        "NOCCode": "5226",
        "JobTitle": "stage assistant director"
    },
    {
        "value": 7346,
        "NOCCode": "5226",
        "JobTitle": "stage manager"
    },
    {
        "value": 7347,
        "NOCCode": "5226",
        "JobTitle": "stage technician"
    },
    {
        "value": 7348,
        "NOCCode": "5226",
        "JobTitle": "staging supervisor"
    },
    {
        "value": 7349,
        "NOCCode": "5226",
        "JobTitle": "studio administrator"
    },
    {
        "value": 7350,
        "NOCCode": "5226",
        "JobTitle": "studio electrician"
    },
    {
        "value": 7351,
        "NOCCode": "5226",
        "JobTitle": "studio manager"
    },
    {
        "value": 7352,
        "NOCCode": "5226",
        "JobTitle": "studio manager – broadcasting"
    },
    {
        "value": 7353,
        "NOCCode": "5226",
        "JobTitle": "stunt co-ordinator"
    },
    {
        "value": 7354,
        "NOCCode": "5226",
        "JobTitle": "telecasting technician"
    },
    {
        "value": 7355,
        "NOCCode": "5226",
        "JobTitle": "theatre technician"
    },
    {
        "value": 7356,
        "NOCCode": "5226",
        "JobTitle": "unit manager – motion pictures and broadcasting"
    },
    {
        "value": 7357,
        "NOCCode": "5226",
        "JobTitle": "wardrobe co-ordinator"
    },
    {
        "value": 7358,
        "NOCCode": "5226",
        "JobTitle": "wardrobe master/mistress"
    },
    {
        "value": 7359,
        "NOCCode": "5226",
        "JobTitle": "wardrobe supervisor"
    },
    {
        "value": 7360,
        "NOCCode": "5226",
        "JobTitle": "wig hairdresser – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7361,
        "NOCCode": "5227",
        "JobTitle": "artistic rigger"
    },
    {
        "value": 7362,
        "NOCCode": "5227",
        "JobTitle": "audiovisual (AV) assistant – motion pictures and broadcasting"
    },
    {
        "value": 7363,
        "NOCCode": "5227",
        "JobTitle": "AV (audiovisual) assistant"
    },
    {
        "value": 7364,
        "NOCCode": "5227",
        "JobTitle": "best boy"
    },
    {
        "value": 7365,
        "NOCCode": "5227",
        "JobTitle": "boom grip"
    },
    {
        "value": 7366,
        "NOCCode": "5227",
        "JobTitle": "boom microphone operator"
    },
    {
        "value": 7367,
        "NOCCode": "5227",
        "JobTitle": "boom operator"
    },
    {
        "value": 7368,
        "NOCCode": "5227",
        "JobTitle": "camera crane operator"
    },
    {
        "value": 7369,
        "NOCCode": "5227",
        "JobTitle": "circus rigger"
    },
    {
        "value": 7370,
        "NOCCode": "5227",
        "JobTitle": "continuity person – motion pictures and broadcasting"
    },
    {
        "value": 7371,
        "NOCCode": "5227",
        "JobTitle": "dolly operator – motion pictures and broadcasting"
    },
    {
        "value": 7372,
        "NOCCode": "5227",
        "JobTitle": "dolly pusher – motion pictures and broadcasting"
    },
    {
        "value": 7373,
        "NOCCode": "5227",
        "JobTitle": "dresser – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7374,
        "NOCCode": "5227",
        "JobTitle": "duplicate-film examiner – motion pictures and broadcasting"
    },
    {
        "value": 7375,
        "NOCCode": "5227",
        "JobTitle": "entertainment stage rigger"
    },
    {
        "value": 7376,
        "NOCCode": "5227",
        "JobTitle": "examiner, duplicate film – motion pictures and broadcasting"
    },
    {
        "value": 7377,
        "NOCCode": "5227",
        "JobTitle": "examiner, motion pictures"
    },
    {
        "value": 7378,
        "NOCCode": "5227",
        "JobTitle": "film projectionist"
    },
    {
        "value": 7379,
        "NOCCode": "5227",
        "JobTitle": "film quality inspector"
    },
    {
        "value": 7380,
        "NOCCode": "5227",
        "JobTitle": "fireworks handler"
    },
    {
        "value": 7381,
        "NOCCode": "5227",
        "JobTitle": "flyman/woman"
    },
    {
        "value": 7382,
        "NOCCode": "5227",
        "JobTitle": "focus puller"
    },
    {
        "value": 7383,
        "NOCCode": "5227",
        "JobTitle": "grip"
    },
    {
        "value": 7384,
        "NOCCode": "5227",
        "JobTitle": "handler, fireworks"
    },
    {
        "value": 7385,
        "NOCCode": "5227",
        "JobTitle": "high rigger, theatre"
    },
    {
        "value": 7386,
        "NOCCode": "5227",
        "JobTitle": "inspector, film quality"
    },
    {
        "value": 7387,
        "NOCCode": "5227",
        "JobTitle": "lighting assistant"
    },
    {
        "value": 7388,
        "NOCCode": "5227",
        "JobTitle": "motion picture examiner"
    },
    {
        "value": 7389,
        "NOCCode": "5227",
        "JobTitle": "movie theatre projectionist"
    },
    {
        "value": 7390,
        "NOCCode": "5227",
        "JobTitle": "operator, boom microphone"
    },
    {
        "value": 7391,
        "NOCCode": "5227",
        "JobTitle": "production assistant"
    },
    {
        "value": 7392,
        "NOCCode": "5227",
        "JobTitle": "production assistant – motion pictures and broadcasting"
    },
    {
        "value": 7393,
        "NOCCode": "5227",
        "JobTitle": "projectionist, motion pictures"
    },
    {
        "value": 7394,
        "NOCCode": "5227",
        "JobTitle": "prompter"
    },
    {
        "value": 7395,
        "NOCCode": "5227",
        "JobTitle": "property person"
    },
    {
        "value": 7396,
        "NOCCode": "5227",
        "JobTitle": "props handler"
    },
    {
        "value": 7397,
        "NOCCode": "5227",
        "JobTitle": "props person"
    },
    {
        "value": 7398,
        "NOCCode": "5227",
        "JobTitle": "recording assistant – recording studio"
    },
    {
        "value": 7399,
        "NOCCode": "5227",
        "JobTitle": "rigger – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7400,
        "NOCCode": "5227",
        "JobTitle": "scene shifter"
    },
    {
        "value": 7401,
        "NOCCode": "5227",
        "JobTitle": "scenic artist – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7402,
        "NOCCode": "5227",
        "JobTitle": "scenic constructor"
    },
    {
        "value": 7403,
        "NOCCode": "5227",
        "JobTitle": "scenic constructor – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7404,
        "NOCCode": "5227",
        "JobTitle": "scenic painter – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7405,
        "NOCCode": "5227",
        "JobTitle": "script assistant"
    },
    {
        "value": 7406,
        "NOCCode": "5227",
        "JobTitle": "script person – motion pictures and broadcasting"
    },
    {
        "value": 7407,
        "NOCCode": "5227",
        "JobTitle": "searchlight operator"
    },
    {
        "value": 7408,
        "NOCCode": "5227",
        "JobTitle": "second costumes assistant"
    },
    {
        "value": 7409,
        "NOCCode": "5227",
        "JobTitle": "set builder"
    },
    {
        "value": 7410,
        "NOCCode": "5227",
        "JobTitle": "set dresser"
    },
    {
        "value": 7411,
        "NOCCode": "5227",
        "JobTitle": "special effects assistant"
    },
    {
        "value": 7412,
        "NOCCode": "5227",
        "JobTitle": "spotlight operator"
    },
    {
        "value": 7413,
        "NOCCode": "5227",
        "JobTitle": "stage best boy"
    },
    {
        "value": 7414,
        "NOCCode": "5227",
        "JobTitle": "stage production worker"
    },
    {
        "value": 7415,
        "NOCCode": "5227",
        "JobTitle": "stage property person"
    },
    {
        "value": 7416,
        "NOCCode": "5227",
        "JobTitle": "stagehand"
    },
    {
        "value": 7417,
        "NOCCode": "5227",
        "JobTitle": "staging assistant"
    },
    {
        "value": 7418,
        "NOCCode": "5227",
        "JobTitle": "technical assistant – audiovisual"
    },
    {
        "value": 7419,
        "NOCCode": "5227",
        "JobTitle": "telecine operator"
    },
    {
        "value": 7420,
        "NOCCode": "5227",
        "JobTitle": "teleprompt operator"
    },
    {
        "value": 7421,
        "NOCCode": "5227",
        "JobTitle": "theatre high rigger"
    },
    {
        "value": 7422,
        "NOCCode": "5227",
        "JobTitle": "wardrobe assistant"
    },
    {
        "value": 7423,
        "NOCCode": "5227",
        "JobTitle": "wardrobe attendant"
    },
    {
        "value": 7424,
        "NOCCode": "5227",
        "JobTitle": "wig assistant – motion pictures, broadcasting and performing arts"
    },
    {
        "value": 7425,
        "NOCCode": "5231",
        "JobTitle": "announcer"
    },
    {
        "value": 7426,
        "NOCCode": "5231",
        "JobTitle": "announcer, P.A. (public address) system"
    },
    {
        "value": 7427,
        "NOCCode": "5231",
        "JobTitle": "announcer, public address (P.A.) system"
    },
    {
        "value": 7428,
        "NOCCode": "5231",
        "JobTitle": "announcer-producer, international service"
    },
    {
        "value": 7429,
        "NOCCode": "5231",
        "JobTitle": "broadcaster"
    },
    {
        "value": 7430,
        "NOCCode": "5231",
        "JobTitle": "circus ringmaster"
    },
    {
        "value": 7431,
        "NOCCode": "5231",
        "JobTitle": "commentator, fashion show"
    },
    {
        "value": 7432,
        "NOCCode": "5231",
        "JobTitle": "commercial announcer"
    },
    {
        "value": 7433,
        "NOCCode": "5231",
        "JobTitle": "disc jockey – broadcast"
    },
    {
        "value": 7434,
        "NOCCode": "5231",
        "JobTitle": "DJ (disc jockey) – broadcast"
    },
    {
        "value": 7435,
        "NOCCode": "5231",
        "JobTitle": "fashion show commentator"
    },
    {
        "value": 7436,
        "NOCCode": "5231",
        "JobTitle": "freelance announcer"
    },
    {
        "value": 7437,
        "NOCCode": "5231",
        "JobTitle": "game show host/hostess"
    },
    {
        "value": 7438,
        "NOCCode": "5231",
        "JobTitle": "host/hostess – television or radio"
    },
    {
        "value": 7439,
        "NOCCode": "5231",
        "JobTitle": "host/hostess, game show"
    },
    {
        "value": 7440,
        "NOCCode": "5231",
        "JobTitle": "host/hostess, talk show"
    },
    {
        "value": 7441,
        "NOCCode": "5231",
        "JobTitle": "international service announcer-producer"
    },
    {
        "value": 7442,
        "NOCCode": "5231",
        "JobTitle": "MC (master of ceremonies)"
    },
    {
        "value": 7443,
        "NOCCode": "5231",
        "JobTitle": "mobile disc jockey"
    },
    {
        "value": 7444,
        "NOCCode": "5231",
        "JobTitle": "moderator – broadcasting"
    },
    {
        "value": 7445,
        "NOCCode": "5231",
        "JobTitle": "news reader"
    },
    {
        "value": 7446,
        "NOCCode": "5231",
        "JobTitle": "newscaster"
    },
    {
        "value": 7447,
        "NOCCode": "5231",
        "JobTitle": "P.A. (public address) system announcer"
    },
    {
        "value": 7448,
        "NOCCode": "5231",
        "JobTitle": "quiz master – radio and television"
    },
    {
        "value": 7449,
        "NOCCode": "5231",
        "JobTitle": "radio announcer"
    },
    {
        "value": 7450,
        "NOCCode": "5231",
        "JobTitle": "radio host/hostess"
    },
    {
        "value": 7451,
        "NOCCode": "5231",
        "JobTitle": "reporter, traffic"
    },
    {
        "value": 7452,
        "NOCCode": "5231",
        "JobTitle": "reporter, weather"
    },
    {
        "value": 7453,
        "NOCCode": "5231",
        "JobTitle": "sports announcer"
    },
    {
        "value": 7454,
        "NOCCode": "5231",
        "JobTitle": "sports commentator"
    },
    {
        "value": 7455,
        "NOCCode": "5231",
        "JobTitle": "sportscaster"
    },
    {
        "value": 7456,
        "NOCCode": "5231",
        "JobTitle": "staff announcer"
    },
    {
        "value": 7457,
        "NOCCode": "5231",
        "JobTitle": "talk show host/hostess"
    },
    {
        "value": 7458,
        "NOCCode": "5231",
        "JobTitle": "television host/hostess"
    },
    {
        "value": 7459,
        "NOCCode": "5231",
        "JobTitle": "television newscaster"
    },
    {
        "value": 7460,
        "NOCCode": "5231",
        "JobTitle": "video jockey (VJ) – television broadcast"
    },
    {
        "value": 7461,
        "NOCCode": "5231",
        "JobTitle": "VJ (video jockey) – television broadcast"
    },
    {
        "value": 7462,
        "NOCCode": "5232",
        "JobTitle": "acrobat"
    },
    {
        "value": 7463,
        "NOCCode": "5232",
        "JobTitle": "aerial acrobat – entertainment"
    },
    {
        "value": 7464,
        "NOCCode": "5232",
        "JobTitle": "art and photography model"
    },
    {
        "value": 7465,
        "NOCCode": "5232",
        "JobTitle": "busker"
    },
    {
        "value": 7466,
        "NOCCode": "5232",
        "JobTitle": "circus artist"
    },
    {
        "value": 7467,
        "NOCCode": "5232",
        "JobTitle": "circus performer"
    },
    {
        "value": 7468,
        "NOCCode": "5232",
        "JobTitle": "clown"
    },
    {
        "value": 7469,
        "NOCCode": "5232",
        "JobTitle": "contortionist"
    },
    {
        "value": 7470,
        "NOCCode": "5232",
        "JobTitle": "DJ (deejay) – turntablist"
    },
    {
        "value": 7471,
        "NOCCode": "5232",
        "JobTitle": "entertainer"
    },
    {
        "value": 7472,
        "NOCCode": "5232",
        "JobTitle": "equilibrist"
    },
    {
        "value": 7473,
        "NOCCode": "5232",
        "JobTitle": "erotic dancer"
    },
    {
        "value": 7474,
        "NOCCode": "5232",
        "JobTitle": "exotic dancer"
    },
    {
        "value": 7475,
        "NOCCode": "5232",
        "JobTitle": "face painter"
    },
    {
        "value": 7476,
        "NOCCode": "5232",
        "JobTitle": "fashion model"
    },
    {
        "value": 7477,
        "NOCCode": "5232",
        "JobTitle": "high-wire walker"
    },
    {
        "value": 7478,
        "NOCCode": "5232",
        "JobTitle": "hypnotist"
    },
    {
        "value": 7479,
        "NOCCode": "5232",
        "JobTitle": "illusionist"
    },
    {
        "value": 7480,
        "NOCCode": "5232",
        "JobTitle": "impersonator"
    },
    {
        "value": 7481,
        "NOCCode": "5232",
        "JobTitle": "juggler"
    },
    {
        "value": 7482,
        "NOCCode": "5232",
        "JobTitle": "look-alike"
    },
    {
        "value": 7483,
        "NOCCode": "5232",
        "JobTitle": "magician"
    },
    {
        "value": 7484,
        "NOCCode": "5232",
        "JobTitle": "marionette handler"
    },
    {
        "value": 7485,
        "NOCCode": "5232",
        "JobTitle": "mascot"
    },
    {
        "value": 7486,
        "NOCCode": "5232",
        "JobTitle": "mimic"
    },
    {
        "value": 7487,
        "NOCCode": "5232",
        "JobTitle": "model"
    },
    {
        "value": 7488,
        "NOCCode": "5232",
        "JobTitle": "nude dancer"
    },
    {
        "value": 7489,
        "NOCCode": "5232",
        "JobTitle": "prestidigitator"
    },
    {
        "value": 7490,
        "NOCCode": "5232",
        "JobTitle": "puppeteer"
    },
    {
        "value": 7491,
        "NOCCode": "5232",
        "JobTitle": "rodeo driver"
    },
    {
        "value": 7492,
        "NOCCode": "5232",
        "JobTitle": "rodeo horse rider"
    },
    {
        "value": 7493,
        "NOCCode": "5232",
        "JobTitle": "Santa Claus/Mrs. Claus"
    },
    {
        "value": 7494,
        "NOCCode": "5232",
        "JobTitle": "show horse rider"
    },
    {
        "value": 7495,
        "NOCCode": "5232",
        "JobTitle": "slack-rope performer – entertainment"
    },
    {
        "value": 7496,
        "NOCCode": "5232",
        "JobTitle": "sleight-of-hand artist"
    },
    {
        "value": 7497,
        "NOCCode": "5232",
        "JobTitle": "street entertainer"
    },
    {
        "value": 7498,
        "NOCCode": "5232",
        "JobTitle": "striptease dancer"
    },
    {
        "value": 7499,
        "NOCCode": "5232",
        "JobTitle": "stunt performer"
    },
    {
        "value": 7500,
        "NOCCode": "5232",
        "JobTitle": "stunt rider"
    },
    {
        "value": 7501,
        "NOCCode": "5232",
        "JobTitle": "stuntman/woman"
    },
    {
        "value": 7502,
        "NOCCode": "5232",
        "JobTitle": "table dancer"
    },
    {
        "value": 7503,
        "NOCCode": "5232",
        "JobTitle": "thrill performer"
    },
    {
        "value": 7504,
        "NOCCode": "5232",
        "JobTitle": "trapeze artist"
    },
    {
        "value": 7505,
        "NOCCode": "5232",
        "JobTitle": "turntablist"
    },
    {
        "value": 7506,
        "NOCCode": "5232",
        "JobTitle": "veejay (VJ) – video performance artist"
    },
    {
        "value": 7507,
        "NOCCode": "5232",
        "JobTitle": "ventriloquist"
    },
    {
        "value": 7508,
        "NOCCode": "5232",
        "JobTitle": "VJ (veejay) – video performance artist"
    },
    {
        "value": 7509,
        "NOCCode": "5241",
        "JobTitle": "2D animation artist"
    },
    {
        "value": 7510,
        "NOCCode": "5241",
        "JobTitle": "2D animator"
    },
    {
        "value": 7511,
        "NOCCode": "5241",
        "JobTitle": "3D animation artist"
    },
    {
        "value": 7512,
        "NOCCode": "5241",
        "JobTitle": "3D animator"
    },
    {
        "value": 7513,
        "NOCCode": "5241",
        "JobTitle": "advertising art director"
    },
    {
        "value": 7514,
        "NOCCode": "5241",
        "JobTitle": "advertising art supervisor"
    },
    {
        "value": 7515,
        "NOCCode": "5241",
        "JobTitle": "advertising artist"
    },
    {
        "value": 7516,
        "NOCCode": "5241",
        "JobTitle": "advertising designer"
    },
    {
        "value": 7517,
        "NOCCode": "5241",
        "JobTitle": "advertising illustrator"
    },
    {
        "value": 7518,
        "NOCCode": "5241",
        "JobTitle": "advertising layout designer"
    },
    {
        "value": 7519,
        "NOCCode": "5241",
        "JobTitle": "animated cartoon artist"
    },
    {
        "value": 7520,
        "NOCCode": "5241",
        "JobTitle": "animated cartoon artist – visual arts"
    },
    {
        "value": 7521,
        "NOCCode": "5241",
        "JobTitle": "animated cartoon colourist"
    },
    {
        "value": 7522,
        "NOCCode": "5241",
        "JobTitle": "animation artist"
    },
    {
        "value": 7523,
        "NOCCode": "5241",
        "JobTitle": "animation layout designer"
    },
    {
        "value": 7524,
        "NOCCode": "5241",
        "JobTitle": "animator – animated films"
    },
    {
        "value": 7525,
        "NOCCode": "5241",
        "JobTitle": "animator, graphic design and illustration"
    },
    {
        "value": 7526,
        "NOCCode": "5241",
        "JobTitle": "art layout designer"
    },
    {
        "value": 7527,
        "NOCCode": "5241",
        "JobTitle": "artist, storyboard"
    },
    {
        "value": 7528,
        "NOCCode": "5241",
        "JobTitle": "artistic illustrator"
    },
    {
        "value": 7529,
        "NOCCode": "5241",
        "JobTitle": "assistant animator – animated films"
    },
    {
        "value": 7530,
        "NOCCode": "5241",
        "JobTitle": "background artist"
    },
    {
        "value": 7531,
        "NOCCode": "5241",
        "JobTitle": "bank note designer"
    },
    {
        "value": 7532,
        "NOCCode": "5241",
        "JobTitle": "biological illustrator"
    },
    {
        "value": 7533,
        "NOCCode": "5241",
        "JobTitle": "business forms designer"
    },
    {
        "value": 7534,
        "NOCCode": "5241",
        "JobTitle": "calligrapher"
    },
    {
        "value": 7535,
        "NOCCode": "5241",
        "JobTitle": "calligraphic artist"
    },
    {
        "value": 7536,
        "NOCCode": "5241",
        "JobTitle": "caricaturist"
    },
    {
        "value": 7537,
        "NOCCode": "5241",
        "JobTitle": "cartoon film artist"
    },
    {
        "value": 7538,
        "NOCCode": "5241",
        "JobTitle": "cartoonist"
    },
    {
        "value": 7539,
        "NOCCode": "5241",
        "JobTitle": "cartoonist sketch"
    },
    {
        "value": 7540,
        "NOCCode": "5241",
        "JobTitle": "catalogue illustrator"
    },
    {
        "value": 7541,
        "NOCCode": "5241",
        "JobTitle": "cell animator"
    },
    {
        "value": 7542,
        "NOCCode": "5241",
        "JobTitle": "character animator"
    },
    {
        "value": 7543,
        "NOCCode": "5241",
        "JobTitle": "chief, advertising illustrator"
    },
    {
        "value": 7544,
        "NOCCode": "5241",
        "JobTitle": "colour artist – cartoons"
    },
    {
        "value": 7545,
        "NOCCode": "5241",
        "JobTitle": "colourist – cartoons"
    },
    {
        "value": 7546,
        "NOCCode": "5241",
        "JobTitle": "commercial artist"
    },
    {
        "value": 7547,
        "NOCCode": "5241",
        "JobTitle": "commercial design artist"
    },
    {
        "value": 7548,
        "NOCCode": "5241",
        "JobTitle": "commercial designer"
    },
    {
        "value": 7549,
        "NOCCode": "5241",
        "JobTitle": "communication designer"
    },
    {
        "value": 7550,
        "NOCCode": "5241",
        "JobTitle": "computer animator"
    },
    {
        "value": 7551,
        "NOCCode": "5241",
        "JobTitle": "computer graphics specialist"
    },
    {
        "value": 7552,
        "NOCCode": "5241",
        "JobTitle": "content director"
    },
    {
        "value": 7553,
        "NOCCode": "5241",
        "JobTitle": "content strategist"
    },
    {
        "value": 7554,
        "NOCCode": "5241",
        "JobTitle": "cover designer"
    },
    {
        "value": 7555,
        "NOCCode": "5241",
        "JobTitle": "cover page illustrator"
    },
    {
        "value": 7556,
        "NOCCode": "5241",
        "JobTitle": "cybergraphic designer"
    },
    {
        "value": 7557,
        "NOCCode": "5241",
        "JobTitle": "designer, form layouts"
    },
    {
        "value": 7558,
        "NOCCode": "5241",
        "JobTitle": "digital animator – artist"
    },
    {
        "value": 7559,
        "NOCCode": "5241",
        "JobTitle": "editorial cartoonist"
    },
    {
        "value": 7560,
        "NOCCode": "5241",
        "JobTitle": "electronic games designer"
    },
    {
        "value": 7561,
        "NOCCode": "5241",
        "JobTitle": "fashion illustrator"
    },
    {
        "value": 7562,
        "NOCCode": "5241",
        "JobTitle": "flash designer"
    },
    {
        "value": 7563,
        "NOCCode": "5241",
        "JobTitle": "form layout designer"
    },
    {
        "value": 7564,
        "NOCCode": "5241",
        "JobTitle": "forms designer"
    },
    {
        "value": 7565,
        "NOCCode": "5241",
        "JobTitle": "graphic artist"
    },
    {
        "value": 7566,
        "NOCCode": "5241",
        "JobTitle": "graphic arts room supervisor"
    },
    {
        "value": 7567,
        "NOCCode": "5241",
        "JobTitle": "graphic design and illustration animator"
    },
    {
        "value": 7568,
        "NOCCode": "5241",
        "JobTitle": "graphic designer"
    },
    {
        "value": 7569,
        "NOCCode": "5241",
        "JobTitle": "graphic designer – multimedia"
    },
    {
        "value": 7570,
        "NOCCode": "5241",
        "JobTitle": "graphic designer – multimedia, interactive or new media"
    },
    {
        "value": 7571,
        "NOCCode": "5241",
        "JobTitle": "graphic designer and layout artist"
    },
    {
        "value": 7572,
        "NOCCode": "5241",
        "JobTitle": "graphic designer, Web"
    },
    {
        "value": 7573,
        "NOCCode": "5241",
        "JobTitle": "illustrator"
    },
    {
        "value": 7574,
        "NOCCode": "5241",
        "JobTitle": "illustrator and graphic designer"
    },
    {
        "value": 7575,
        "NOCCode": "5241",
        "JobTitle": "interactive media designer"
    },
    {
        "value": 7576,
        "NOCCode": "5241",
        "JobTitle": "Internet graphic designer"
    },
    {
        "value": 7577,
        "NOCCode": "5241",
        "JobTitle": "layout artist"
    },
    {
        "value": 7578,
        "NOCCode": "5241",
        "JobTitle": "layout designer"
    },
    {
        "value": 7579,
        "NOCCode": "5241",
        "JobTitle": "layout designer, animation"
    },
    {
        "value": 7580,
        "NOCCode": "5241",
        "JobTitle": "litho artist"
    },
    {
        "value": 7581,
        "NOCCode": "5241",
        "JobTitle": "medical illustrator"
    },
    {
        "value": 7582,
        "NOCCode": "5241",
        "JobTitle": "multimedia design specialist"
    },
    {
        "value": 7583,
        "NOCCode": "5241",
        "JobTitle": "multimedia designer"
    },
    {
        "value": 7584,
        "NOCCode": "5241",
        "JobTitle": "multimedia illustrator"
    },
    {
        "value": 7585,
        "NOCCode": "5241",
        "JobTitle": "multimedia instructional designer and scriptor"
    },
    {
        "value": 7586,
        "NOCCode": "5241",
        "JobTitle": "multimedia products designer"
    },
    {
        "value": 7587,
        "NOCCode": "5241",
        "JobTitle": "multimedia products designer-ideaman/woman"
    },
    {
        "value": 7588,
        "NOCCode": "5241",
        "JobTitle": "new media graphics designer"
    },
    {
        "value": 7589,
        "NOCCode": "5241",
        "JobTitle": "newspaper illustrator"
    },
    {
        "value": 7590,
        "NOCCode": "5241",
        "JobTitle": "package designer"
    },
    {
        "value": 7591,
        "NOCCode": "5241",
        "JobTitle": "page designer"
    },
    {
        "value": 7592,
        "NOCCode": "5241",
        "JobTitle": "paper securities designer"
    },
    {
        "value": 7593,
        "NOCCode": "5241",
        "JobTitle": "pictographist"
    },
    {
        "value": 7594,
        "NOCCode": "5241",
        "JobTitle": "political caricaturist"
    },
    {
        "value": 7595,
        "NOCCode": "5241",
        "JobTitle": "political cartoonist"
    },
    {
        "value": 7596,
        "NOCCode": "5241",
        "JobTitle": "poster artist"
    },
    {
        "value": 7597,
        "NOCCode": "5241",
        "JobTitle": "registered graphic designer (RGD)"
    },
    {
        "value": 7598,
        "NOCCode": "5241",
        "JobTitle": "RGD (registered graphic designer)"
    },
    {
        "value": 7599,
        "NOCCode": "5241",
        "JobTitle": "scientific illustrator"
    },
    {
        "value": 7600,
        "NOCCode": "5241",
        "JobTitle": "sign designer"
    },
    {
        "value": 7601,
        "NOCCode": "5241",
        "JobTitle": "sports cartoonist"
    },
    {
        "value": 7602,
        "NOCCode": "5241",
        "JobTitle": "storyboard artist"
    },
    {
        "value": 7603,
        "NOCCode": "5241",
        "JobTitle": "supervisor, advertising art"
    },
    {
        "value": 7604,
        "NOCCode": "5241",
        "JobTitle": "supervisor, graphic arts room"
    },
    {
        "value": 7605,
        "NOCCode": "5241",
        "JobTitle": "title artist"
    },
    {
        "value": 7606,
        "NOCCode": "5241",
        "JobTitle": "user experience designer"
    },
    {
        "value": 7607,
        "NOCCode": "5241",
        "JobTitle": "wallpaper colourist"
    },
    {
        "value": 7608,
        "NOCCode": "5241",
        "JobTitle": "wallpaper designer"
    },
    {
        "value": 7609,
        "NOCCode": "5241",
        "JobTitle": "Web designer – graphic design"
    },
    {
        "value": 7610,
        "NOCCode": "5241",
        "JobTitle": "Web graphic designer"
    },
    {
        "value": 7611,
        "NOCCode": "5241",
        "JobTitle": "Web page designer"
    },
    {
        "value": 7612,
        "NOCCode": "5242",
        "JobTitle": "aircraft interior designer"
    },
    {
        "value": 7613,
        "NOCCode": "5242",
        "JobTitle": "building space planner"
    },
    {
        "value": 7614,
        "NOCCode": "5242",
        "JobTitle": "business aircraft interior designer"
    },
    {
        "value": 7615,
        "NOCCode": "5242",
        "JobTitle": "certified interior designer"
    },
    {
        "value": 7616,
        "NOCCode": "5242",
        "JobTitle": "colour consultant – home decorating"
    },
    {
        "value": 7617,
        "NOCCode": "5242",
        "JobTitle": "decorating consultant"
    },
    {
        "value": 7618,
        "NOCCode": "5242",
        "JobTitle": "designer – interior design"
    },
    {
        "value": 7619,
        "NOCCode": "5242",
        "JobTitle": "green design consultant – interior design"
    },
    {
        "value": 7620,
        "NOCCode": "5242",
        "JobTitle": "home decorating consultant"
    },
    {
        "value": 7621,
        "NOCCode": "5242",
        "JobTitle": "home decorator"
    },
    {
        "value": 7622,
        "NOCCode": "5242",
        "JobTitle": "home organizer"
    },
    {
        "value": 7623,
        "NOCCode": "5242",
        "JobTitle": "home stager"
    },
    {
        "value": 7624,
        "NOCCode": "5242",
        "JobTitle": "interior decorating consultant"
    },
    {
        "value": 7625,
        "NOCCode": "5242",
        "JobTitle": "interior decorator"
    },
    {
        "value": 7626,
        "NOCCode": "5242",
        "JobTitle": "interior design consultant"
    },
    {
        "value": 7627,
        "NOCCode": "5242",
        "JobTitle": "interior design technician"
    },
    {
        "value": 7628,
        "NOCCode": "5242",
        "JobTitle": "interior designer"
    },
    {
        "value": 7629,
        "NOCCode": "5242",
        "JobTitle": "interior designer assistant"
    },
    {
        "value": 7630,
        "NOCCode": "5242",
        "JobTitle": "interior space designer"
    },
    {
        "value": 7631,
        "NOCCode": "5242",
        "JobTitle": "kitchen designer"
    },
    {
        "value": 7632,
        "NOCCode": "5242",
        "JobTitle": "licensed interior designer"
    },
    {
        "value": 7633,
        "NOCCode": "5242",
        "JobTitle": "office space planner"
    },
    {
        "value": 7634,
        "NOCCode": "5242",
        "JobTitle": "project manager, interior design"
    },
    {
        "value": 7635,
        "NOCCode": "5242",
        "JobTitle": "registered interior designer"
    },
    {
        "value": 7636,
        "NOCCode": "5242",
        "JobTitle": "retail space planner"
    },
    {
        "value": 7637,
        "NOCCode": "5243",
        "JobTitle": "assistant costume designer"
    },
    {
        "value": 7638,
        "NOCCode": "5243",
        "JobTitle": "clothing designer"
    },
    {
        "value": 7639,
        "NOCCode": "5243",
        "JobTitle": "costume designer"
    },
    {
        "value": 7640,
        "NOCCode": "5243",
        "JobTitle": "costume designer – exhibits and theatre"
    },
    {
        "value": 7641,
        "NOCCode": "5243",
        "JobTitle": "couturier – haute couture"
    },
    {
        "value": 7642,
        "NOCCode": "5243",
        "JobTitle": "crest designer"
    },
    {
        "value": 7643,
        "NOCCode": "5243",
        "JobTitle": "designer – theatre"
    },
    {
        "value": 7644,
        "NOCCode": "5243",
        "JobTitle": "designer, clothing"
    },
    {
        "value": 7645,
        "NOCCode": "5243",
        "JobTitle": "designer, displays"
    },
    {
        "value": 7646,
        "NOCCode": "5243",
        "JobTitle": "designer, exhibits"
    },
    {
        "value": 7647,
        "NOCCode": "5243",
        "JobTitle": "designer, museum"
    },
    {
        "value": 7648,
        "NOCCode": "5243",
        "JobTitle": "designer, textile"
    },
    {
        "value": 7649,
        "NOCCode": "5243",
        "JobTitle": "display design supervisor"
    },
    {
        "value": 7650,
        "NOCCode": "5243",
        "JobTitle": "display designer"
    },
    {
        "value": 7651,
        "NOCCode": "5243",
        "JobTitle": "display designer – museums and art galleries"
    },
    {
        "value": 7652,
        "NOCCode": "5243",
        "JobTitle": "display designers supervisor"
    },
    {
        "value": 7653,
        "NOCCode": "5243",
        "JobTitle": "embroidery designer"
    },
    {
        "value": 7654,
        "NOCCode": "5243",
        "JobTitle": "exhibit and display designer"
    },
    {
        "value": 7655,
        "NOCCode": "5243",
        "JobTitle": "exhibit designer"
    },
    {
        "value": 7656,
        "NOCCode": "5243",
        "JobTitle": "exhibit designers supervisor"
    },
    {
        "value": 7657,
        "NOCCode": "5243",
        "JobTitle": "exhibition designer – museums and art galleries"
    },
    {
        "value": 7658,
        "NOCCode": "5243",
        "JobTitle": "fabric designer"
    },
    {
        "value": 7659,
        "NOCCode": "5243",
        "JobTitle": "fashion designer"
    },
    {
        "value": 7660,
        "NOCCode": "5243",
        "JobTitle": "fashion goods co-ordinator"
    },
    {
        "value": 7661,
        "NOCCode": "5243",
        "JobTitle": "fashion goods exhibit co-ordinator"
    },
    {
        "value": 7662,
        "NOCCode": "5243",
        "JobTitle": "fashion wear designer"
    },
    {
        "value": 7663,
        "NOCCode": "5243",
        "JobTitle": "fishing lure designer"
    },
    {
        "value": 7664,
        "NOCCode": "5243",
        "JobTitle": "flower designer"
    },
    {
        "value": 7665,
        "NOCCode": "5243",
        "JobTitle": "food stylist"
    },
    {
        "value": 7666,
        "NOCCode": "5243",
        "JobTitle": "fur designer"
    },
    {
        "value": 7667,
        "NOCCode": "5243",
        "JobTitle": "garment designer"
    },
    {
        "value": 7668,
        "NOCCode": "5243",
        "JobTitle": "glove designer"
    },
    {
        "value": 7669,
        "NOCCode": "5243",
        "JobTitle": "handbag designer"
    },
    {
        "value": 7670,
        "NOCCode": "5243",
        "JobTitle": "hat designer"
    },
    {
        "value": 7671,
        "NOCCode": "5243",
        "JobTitle": "heraldist"
    },
    {
        "value": 7672,
        "NOCCode": "5243",
        "JobTitle": "jacket designer"
    },
    {
        "value": 7673,
        "NOCCode": "5243",
        "JobTitle": "jewellery designer"
    },
    {
        "value": 7674,
        "NOCCode": "5243",
        "JobTitle": "lighting designer"
    },
    {
        "value": 7675,
        "NOCCode": "5243",
        "JobTitle": "lighting designer – exhibits"
    },
    {
        "value": 7676,
        "NOCCode": "5243",
        "JobTitle": "luggage designer"
    },
    {
        "value": 7677,
        "NOCCode": "5243",
        "JobTitle": "luggage stylist"
    },
    {
        "value": 7678,
        "NOCCode": "5243",
        "JobTitle": "memorial designer"
    },
    {
        "value": 7679,
        "NOCCode": "5243",
        "JobTitle": "museum designer"
    },
    {
        "value": 7680,
        "NOCCode": "5243",
        "JobTitle": "museum exhibit designer"
    },
    {
        "value": 7681,
        "NOCCode": "5243",
        "JobTitle": "novelties stylist"
    },
    {
        "value": 7682,
        "NOCCode": "5243",
        "JobTitle": "pottery designer"
    },
    {
        "value": 7683,
        "NOCCode": "5243",
        "JobTitle": "rug designer"
    },
    {
        "value": 7684,
        "NOCCode": "5243",
        "JobTitle": "scene designer"
    },
    {
        "value": 7685,
        "NOCCode": "5243",
        "JobTitle": "scene designer assistant"
    },
    {
        "value": 7686,
        "NOCCode": "5243",
        "JobTitle": "set decorator – theatre and motion pictures"
    },
    {
        "value": 7687,
        "NOCCode": "5243",
        "JobTitle": "set designer – theatre"
    },
    {
        "value": 7688,
        "NOCCode": "5243",
        "JobTitle": "shoe designer"
    },
    {
        "value": 7689,
        "NOCCode": "5243",
        "JobTitle": "show and demonstration designer"
    },
    {
        "value": 7690,
        "NOCCode": "5243",
        "JobTitle": "sound designer"
    },
    {
        "value": 7691,
        "NOCCode": "5243",
        "JobTitle": "stage scenery designer"
    },
    {
        "value": 7692,
        "NOCCode": "5243",
        "JobTitle": "store display designer"
    },
    {
        "value": 7693,
        "NOCCode": "5243",
        "JobTitle": "supervisor, display design"
    },
    {
        "value": 7694,
        "NOCCode": "5243",
        "JobTitle": "swimming pool designer"
    },
    {
        "value": 7695,
        "NOCCode": "5243",
        "JobTitle": "textile designer"
    },
    {
        "value": 7696,
        "NOCCode": "5243",
        "JobTitle": "theatre designer"
    },
    {
        "value": 7697,
        "NOCCode": "5243",
        "JobTitle": "tile designer"
    },
    {
        "value": 7698,
        "NOCCode": "5243",
        "JobTitle": "toy designer – arts and crafts"
    },
    {
        "value": 7699,
        "NOCCode": "5243",
        "JobTitle": "trophy designer"
    },
    {
        "value": 7700,
        "NOCCode": "5243",
        "JobTitle": "visual display stylist"
    },
    {
        "value": 7701,
        "NOCCode": "5243",
        "JobTitle": "window display designer"
    },
    {
        "value": 7702,
        "NOCCode": "5243",
        "JobTitle": "women's fashion designer"
    },
    {
        "value": 7703,
        "NOCCode": "5244",
        "JobTitle": "Aboriginal art carver"
    },
    {
        "value": 7704,
        "NOCCode": "5244",
        "JobTitle": "Aboriginal craftwork instructor"
    },
    {
        "value": 7705,
        "NOCCode": "5244",
        "JobTitle": "artificial flower maker, hand"
    },
    {
        "value": 7706,
        "NOCCode": "5244",
        "JobTitle": "artificial flower presser"
    },
    {
        "value": 7707,
        "NOCCode": "5244",
        "JobTitle": "artisan"
    },
    {
        "value": 7708,
        "NOCCode": "5244",
        "JobTitle": "artistic bouquet designer"
    },
    {
        "value": 7709,
        "NOCCode": "5244",
        "JobTitle": "artistic floral arranger"
    },
    {
        "value": 7710,
        "NOCCode": "5244",
        "JobTitle": "artistic floral bouquet designer"
    },
    {
        "value": 7711,
        "NOCCode": "5244",
        "JobTitle": "artistic floral designer"
    },
    {
        "value": 7712,
        "NOCCode": "5244",
        "JobTitle": "arts and crafts teacher (except education)"
    },
    {
        "value": 7713,
        "NOCCode": "5244",
        "JobTitle": "bag maker – arts and crafts"
    },
    {
        "value": 7714,
        "NOCCode": "5244",
        "JobTitle": "balalaika maker"
    },
    {
        "value": 7715,
        "NOCCode": "5244",
        "JobTitle": "basket weaver"
    },
    {
        "value": 7716,
        "NOCCode": "5244",
        "JobTitle": "batik artisan"
    },
    {
        "value": 7717,
        "NOCCode": "5244",
        "JobTitle": "beadworker, hand"
    },
    {
        "value": 7718,
        "NOCCode": "5244",
        "JobTitle": "bellows maker"
    },
    {
        "value": 7719,
        "NOCCode": "5244",
        "JobTitle": "bookbinder, hand"
    },
    {
        "value": 7720,
        "NOCCode": "5244",
        "JobTitle": "candle maker, hand"
    },
    {
        "value": 7721,
        "NOCCode": "5244",
        "JobTitle": "caner, custom furniture"
    },
    {
        "value": 7722,
        "NOCCode": "5244",
        "JobTitle": "canoe builder – arts and crafts"
    },
    {
        "value": 7723,
        "NOCCode": "5244",
        "JobTitle": "canoe repairer – arts and crafts"
    },
    {
        "value": 7724,
        "NOCCode": "5244",
        "JobTitle": "carver"
    },
    {
        "value": 7725,
        "NOCCode": "5244",
        "JobTitle": "carver, Native art"
    },
    {
        "value": 7726,
        "NOCCode": "5244",
        "JobTitle": "carver, totem poles"
    },
    {
        "value": 7727,
        "NOCCode": "5244",
        "JobTitle": "case maker, hand"
    },
    {
        "value": 7728,
        "NOCCode": "5244",
        "JobTitle": "cello maker"
    },
    {
        "value": 7729,
        "NOCCode": "5244",
        "JobTitle": "ceramic artist"
    },
    {
        "value": 7730,
        "NOCCode": "5244",
        "JobTitle": "ceramic potter"
    },
    {
        "value": 7731,
        "NOCCode": "5244",
        "JobTitle": "ceramics teacher (except education)"
    },
    {
        "value": 7732,
        "NOCCode": "5244",
        "JobTitle": "ceramist"
    },
    {
        "value": 7733,
        "NOCCode": "5244",
        "JobTitle": "copperplate engraver – artisan"
    },
    {
        "value": 7734,
        "NOCCode": "5244",
        "JobTitle": "craft instructor (except education)"
    },
    {
        "value": 7735,
        "NOCCode": "5244",
        "JobTitle": "craft weaver"
    },
    {
        "value": 7736,
        "NOCCode": "5244",
        "JobTitle": "craftsman/woman"
    },
    {
        "value": 7737,
        "NOCCode": "5244",
        "JobTitle": "craftsperson"
    },
    {
        "value": 7738,
        "NOCCode": "5244",
        "JobTitle": "crocheter, hand"
    },
    {
        "value": 7739,
        "NOCCode": "5244",
        "JobTitle": "custom furniture caner"
    },
    {
        "value": 7740,
        "NOCCode": "5244",
        "JobTitle": "decorative engraver"
    },
    {
        "value": 7741,
        "NOCCode": "5244",
        "JobTitle": "dollmaker, hand"
    },
    {
        "value": 7742,
        "NOCCode": "5244",
        "JobTitle": "drum maker – artisan"
    },
    {
        "value": 7743,
        "NOCCode": "5244",
        "JobTitle": "embroiderer, hand"
    },
    {
        "value": 7744,
        "NOCCode": "5244",
        "JobTitle": "enameller – arts and crafts"
    },
    {
        "value": 7745,
        "NOCCode": "5244",
        "JobTitle": "enameller, hand"
    },
    {
        "value": 7746,
        "NOCCode": "5244",
        "JobTitle": "enamellist – arts and crafts"
    },
    {
        "value": 7747,
        "NOCCode": "5244",
        "JobTitle": "engraver, hand"
    },
    {
        "value": 7748,
        "NOCCode": "5244",
        "JobTitle": "engraver, metal"
    },
    {
        "value": 7749,
        "NOCCode": "5244",
        "JobTitle": "engraver, pantograph – arts and crafts"
    },
    {
        "value": 7750,
        "NOCCode": "5244",
        "JobTitle": "fabric artisan"
    },
    {
        "value": 7751,
        "NOCCode": "5244",
        "JobTitle": "fabric artist"
    },
    {
        "value": 7752,
        "NOCCode": "5244",
        "JobTitle": "floral arrangements designer"
    },
    {
        "value": 7753,
        "NOCCode": "5244",
        "JobTitle": "floral designer"
    },
    {
        "value": 7754,
        "NOCCode": "5244",
        "JobTitle": "glass blower"
    },
    {
        "value": 7755,
        "NOCCode": "5244",
        "JobTitle": "glass engraver"
    },
    {
        "value": 7756,
        "NOCCode": "5244",
        "JobTitle": "glass novelty maker"
    },
    {
        "value": 7757,
        "NOCCode": "5244",
        "JobTitle": "glass painter"
    },
    {
        "value": 7758,
        "NOCCode": "5244",
        "JobTitle": "glassworker, hand"
    },
    {
        "value": 7759,
        "NOCCode": "5244",
        "JobTitle": "goldsmith"
    },
    {
        "value": 7760,
        "NOCCode": "5244",
        "JobTitle": "guitar maker"
    },
    {
        "value": 7761,
        "NOCCode": "5244",
        "JobTitle": "hand beadworker"
    },
    {
        "value": 7762,
        "NOCCode": "5244",
        "JobTitle": "hand bookbinder"
    },
    {
        "value": 7763,
        "NOCCode": "5244",
        "JobTitle": "hand candle maker"
    },
    {
        "value": 7764,
        "NOCCode": "5244",
        "JobTitle": "hand carver, stones"
    },
    {
        "value": 7765,
        "NOCCode": "5244",
        "JobTitle": "hand carver, woodenware"
    },
    {
        "value": 7766,
        "NOCCode": "5244",
        "JobTitle": "hand case maker"
    },
    {
        "value": 7767,
        "NOCCode": "5244",
        "JobTitle": "hand crocheter"
    },
    {
        "value": 7768,
        "NOCCode": "5244",
        "JobTitle": "hand dollmaker"
    },
    {
        "value": 7769,
        "NOCCode": "5244",
        "JobTitle": "hand embroiderer"
    },
    {
        "value": 7770,
        "NOCCode": "5244",
        "JobTitle": "hand enameller"
    },
    {
        "value": 7771,
        "NOCCode": "5244",
        "JobTitle": "hand engraver"
    },
    {
        "value": 7772,
        "NOCCode": "5244",
        "JobTitle": "hand glassworker"
    },
    {
        "value": 7773,
        "NOCCode": "5244",
        "JobTitle": "hand knitter"
    },
    {
        "value": 7774,
        "NOCCode": "5244",
        "JobTitle": "hand leather worker"
    },
    {
        "value": 7775,
        "NOCCode": "5244",
        "JobTitle": "hand metalworker"
    },
    {
        "value": 7776,
        "NOCCode": "5244",
        "JobTitle": "hand needleworker"
    },
    {
        "value": 7777,
        "NOCCode": "5244",
        "JobTitle": "hand papermaker"
    },
    {
        "value": 7778,
        "NOCCode": "5244",
        "JobTitle": "hand quilter"
    },
    {
        "value": 7779,
        "NOCCode": "5244",
        "JobTitle": "hand rug maker"
    },
    {
        "value": 7780,
        "NOCCode": "5244",
        "JobTitle": "hand silk flower maker"
    },
    {
        "value": 7781,
        "NOCCode": "5244",
        "JobTitle": "hand spinner"
    },
    {
        "value": 7782,
        "NOCCode": "5244",
        "JobTitle": "hand stone carver"
    },
    {
        "value": 7783,
        "NOCCode": "5244",
        "JobTitle": "hand weaver"
    },
    {
        "value": 7784,
        "NOCCode": "5244",
        "JobTitle": "hand woodworker"
    },
    {
        "value": 7785,
        "NOCCode": "5244",
        "JobTitle": "harpsichord builder"
    },
    {
        "value": 7786,
        "NOCCode": "5244",
        "JobTitle": "instructor, crafts (except education)"
    },
    {
        "value": 7787,
        "NOCCode": "5244",
        "JobTitle": "jewellery artisan, hand"
    },
    {
        "value": 7788,
        "NOCCode": "5244",
        "JobTitle": "jewellery engraver"
    },
    {
        "value": 7789,
        "NOCCode": "5244",
        "JobTitle": "knitter, hand"
    },
    {
        "value": 7790,
        "NOCCode": "5244",
        "JobTitle": "lace weaver – arts and crafts"
    },
    {
        "value": 7791,
        "NOCCode": "5244",
        "JobTitle": "leather worker"
    },
    {
        "value": 7792,
        "NOCCode": "5244",
        "JobTitle": "leather worker, hand"
    },
    {
        "value": 7793,
        "NOCCode": "5244",
        "JobTitle": "machine engraver – arts and crafts"
    },
    {
        "value": 7794,
        "NOCCode": "5244",
        "JobTitle": "mandolin maker"
    },
    {
        "value": 7795,
        "NOCCode": "5244",
        "JobTitle": "master glass blower"
    },
    {
        "value": 7796,
        "NOCCode": "5244",
        "JobTitle": "master glassmaker"
    },
    {
        "value": 7797,
        "NOCCode": "5244",
        "JobTitle": "metal arts worker"
    },
    {
        "value": 7798,
        "NOCCode": "5244",
        "JobTitle": "metal engraver"
    },
    {
        "value": 7799,
        "NOCCode": "5244",
        "JobTitle": "metalworker, hand"
    },
    {
        "value": 7800,
        "NOCCode": "5244",
        "JobTitle": "musical instrument maker – artisan"
    },
    {
        "value": 7801,
        "NOCCode": "5244",
        "JobTitle": "nameplate engraver"
    },
    {
        "value": 7802,
        "NOCCode": "5244",
        "JobTitle": "Native art carver"
    },
    {
        "value": 7803,
        "NOCCode": "5244",
        "JobTitle": "Native craftwork instructor"
    },
    {
        "value": 7804,
        "NOCCode": "5244",
        "JobTitle": "needleworker, hand"
    },
    {
        "value": 7805,
        "NOCCode": "5244",
        "JobTitle": "panflute maker"
    },
    {
        "value": 7806,
        "NOCCode": "5244",
        "JobTitle": "pantograph engraver – arts and crafts"
    },
    {
        "value": 7807,
        "NOCCode": "5244",
        "JobTitle": "papermaker, hand"
    },
    {
        "value": 7808,
        "NOCCode": "5244",
        "JobTitle": "pipe organ builder"
    },
    {
        "value": 7809,
        "NOCCode": "5244",
        "JobTitle": "poker-work artisan"
    },
    {
        "value": 7810,
        "NOCCode": "5244",
        "JobTitle": "potter"
    },
    {
        "value": 7811,
        "NOCCode": "5244",
        "JobTitle": "presser, artificial flowers"
    },
    {
        "value": 7812,
        "NOCCode": "5244",
        "JobTitle": "pyrographer"
    },
    {
        "value": 7813,
        "NOCCode": "5244",
        "JobTitle": "quilter, hand"
    },
    {
        "value": 7814,
        "NOCCode": "5244",
        "JobTitle": "reproduction woodcarver"
    },
    {
        "value": 7815,
        "NOCCode": "5244",
        "JobTitle": "rug maker, hand"
    },
    {
        "value": 7816,
        "NOCCode": "5244",
        "JobTitle": "rughooker"
    },
    {
        "value": 7817,
        "NOCCode": "5244",
        "JobTitle": "scientific glass apparatus blower"
    },
    {
        "value": 7818,
        "NOCCode": "5244",
        "JobTitle": "screen printing artisan"
    },
    {
        "value": 7819,
        "NOCCode": "5244",
        "JobTitle": "silk flower maker, hand"
    },
    {
        "value": 7820,
        "NOCCode": "5244",
        "JobTitle": "silversmith"
    },
    {
        "value": 7821,
        "NOCCode": "5244",
        "JobTitle": "silverware engraver"
    },
    {
        "value": 7822,
        "NOCCode": "5244",
        "JobTitle": "spinner, hand"
    },
    {
        "value": 7823,
        "NOCCode": "5244",
        "JobTitle": "stained glass artist"
    },
    {
        "value": 7824,
        "NOCCode": "5244",
        "JobTitle": "stained glass designer"
    },
    {
        "value": 7825,
        "NOCCode": "5244",
        "JobTitle": "stained glass painter"
    },
    {
        "value": 7826,
        "NOCCode": "5244",
        "JobTitle": "steel engraver – arts and crafts"
    },
    {
        "value": 7827,
        "NOCCode": "5244",
        "JobTitle": "stone carver, hand"
    },
    {
        "value": 7828,
        "NOCCode": "5244",
        "JobTitle": "stringed instrument maker"
    },
    {
        "value": 7829,
        "NOCCode": "5244",
        "JobTitle": "teacher, ceramics (except education)"
    },
    {
        "value": 7830,
        "NOCCode": "5244",
        "JobTitle": "tepee maker"
    },
    {
        "value": 7831,
        "NOCCode": "5244",
        "JobTitle": "tie-dye artisan"
    },
    {
        "value": 7832,
        "NOCCode": "5244",
        "JobTitle": "tole painter"
    },
    {
        "value": 7833,
        "NOCCode": "5244",
        "JobTitle": "totem pole carver"
    },
    {
        "value": 7834,
        "NOCCode": "5244",
        "JobTitle": "violin maker"
    },
    {
        "value": 7835,
        "NOCCode": "5244",
        "JobTitle": "weaver – arts and crafts"
    },
    {
        "value": 7836,
        "NOCCode": "5244",
        "JobTitle": "weaver, hand"
    },
    {
        "value": 7837,
        "NOCCode": "5244",
        "JobTitle": "weaving instructor (except education)"
    },
    {
        "value": 7838,
        "NOCCode": "5244",
        "JobTitle": "woodcarver"
    },
    {
        "value": 7839,
        "NOCCode": "5244",
        "JobTitle": "woodcarver, reproductions"
    },
    {
        "value": 7840,
        "NOCCode": "5244",
        "JobTitle": "woodenware carver, hand"
    },
    {
        "value": 7841,
        "NOCCode": "5244",
        "JobTitle": "woodworker, hand"
    },
    {
        "value": 7842,
        "NOCCode": "5244",
        "JobTitle": "wreath maker"
    },
    {
        "value": 7843,
        "NOCCode": "5245",
        "JobTitle": "canvas goods maker"
    },
    {
        "value": 7844,
        "NOCCode": "5245",
        "JobTitle": "canvas goods pattern designer and patternmaker"
    },
    {
        "value": 7845,
        "NOCCode": "5245",
        "JobTitle": "canvas layer-out – textile products"
    },
    {
        "value": 7846,
        "NOCCode": "5245",
        "JobTitle": "canvas pattern designer"
    },
    {
        "value": 7847,
        "NOCCode": "5245",
        "JobTitle": "canvas patternmaker"
    },
    {
        "value": 7848,
        "NOCCode": "5245",
        "JobTitle": "cartoon embroidery enlarger"
    },
    {
        "value": 7849,
        "NOCCode": "5245",
        "JobTitle": "digitizer operator – textile, leather and fur products"
    },
    {
        "value": 7850,
        "NOCCode": "5245",
        "JobTitle": "doper – fabric products"
    },
    {
        "value": 7851,
        "NOCCode": "5245",
        "JobTitle": "doper and marker"
    },
    {
        "value": 7852,
        "NOCCode": "5245",
        "JobTitle": "dress patternmaker"
    },
    {
        "value": 7853,
        "NOCCode": "5245",
        "JobTitle": "embroidery patternmaker"
    },
    {
        "value": 7854,
        "NOCCode": "5245",
        "JobTitle": "fur garment patternmaker"
    },
    {
        "value": 7855,
        "NOCCode": "5245",
        "JobTitle": "fur patternmaker"
    },
    {
        "value": 7856,
        "NOCCode": "5245",
        "JobTitle": "fur products patternmaker"
    },
    {
        "value": 7857,
        "NOCCode": "5245",
        "JobTitle": "garment patternmaker"
    },
    {
        "value": 7858,
        "NOCCode": "5245",
        "JobTitle": "last-pattern grader"
    },
    {
        "value": 7859,
        "NOCCode": "5245",
        "JobTitle": "layer-out and patternmaker"
    },
    {
        "value": 7860,
        "NOCCode": "5245",
        "JobTitle": "lay-out and patternmaker"
    },
    {
        "value": 7861,
        "NOCCode": "5245",
        "JobTitle": "leather products patternmaker"
    },
    {
        "value": 7862,
        "NOCCode": "5245",
        "JobTitle": "men's and women's wear patternmaker"
    },
    {
        "value": 7863,
        "NOCCode": "5245",
        "JobTitle": "modifier and lay-out marker"
    },
    {
        "value": 7864,
        "NOCCode": "5245",
        "JobTitle": "modifier and marker"
    },
    {
        "value": 7865,
        "NOCCode": "5245",
        "JobTitle": "pattern designer and patternmaker – textile, leather and fur products"
    },
    {
        "value": 7866,
        "NOCCode": "5245",
        "JobTitle": "pattern designer and patternmaker, canvas goods"
    },
    {
        "value": 7867,
        "NOCCode": "5245",
        "JobTitle": "pattern grader"
    },
    {
        "value": 7868,
        "NOCCode": "5245",
        "JobTitle": "pattern marker"
    },
    {
        "value": 7869,
        "NOCCode": "5245",
        "JobTitle": "pattern marker – fabric products"
    },
    {
        "value": 7870,
        "NOCCode": "5245",
        "JobTitle": "pattern modifier"
    },
    {
        "value": 7871,
        "NOCCode": "5245",
        "JobTitle": "pattern modifier – textile, leather and fur products"
    },
    {
        "value": 7872,
        "NOCCode": "5245",
        "JobTitle": "patternmaker – computer assisted"
    },
    {
        "value": 7873,
        "NOCCode": "5245",
        "JobTitle": "patternmaker – textile products"
    },
    {
        "value": 7874,
        "NOCCode": "5245",
        "JobTitle": "patternmaker – textile, leather and fur products"
    },
    {
        "value": 7875,
        "NOCCode": "5245",
        "JobTitle": "patternmaker, shoes"
    },
    {
        "value": 7876,
        "NOCCode": "5245",
        "JobTitle": "sail lay-out and patternmaker"
    },
    {
        "value": 7877,
        "NOCCode": "5245",
        "JobTitle": "sail maker"
    },
    {
        "value": 7878,
        "NOCCode": "5245",
        "JobTitle": "shoe patternmaker"
    },
    {
        "value": 7879,
        "NOCCode": "5245",
        "JobTitle": "shoe-last patternmaker"
    },
    {
        "value": 7880,
        "NOCCode": "5245",
        "JobTitle": "textile identification mark remover"
    },
    {
        "value": 7881,
        "NOCCode": "5245",
        "JobTitle": "textile mark remover"
    },
    {
        "value": 7882,
        "NOCCode": "5245",
        "JobTitle": "textile marker-down"
    },
    {
        "value": 7883,
        "NOCCode": "5245",
        "JobTitle": "textile products patternmaker"
    },
    {
        "value": 7884,
        "NOCCode": "5245",
        "JobTitle": "tracer-patternmaker"
    },
    {
        "value": 7885,
        "NOCCode": "5251",
        "JobTitle": "amateur athlete"
    },
    {
        "value": 7886,
        "NOCCode": "5251",
        "JobTitle": "archer"
    },
    {
        "value": 7887,
        "NOCCode": "5251",
        "JobTitle": "athlete"
    },
    {
        "value": 7888,
        "NOCCode": "5251",
        "JobTitle": "automobile racer"
    },
    {
        "value": 7889,
        "NOCCode": "5251",
        "JobTitle": "badminton player"
    },
    {
        "value": 7890,
        "NOCCode": "5251",
        "JobTitle": "baseball player"
    },
    {
        "value": 7891,
        "NOCCode": "5251",
        "JobTitle": "basketball player"
    },
    {
        "value": 7892,
        "NOCCode": "5251",
        "JobTitle": "billiard player"
    },
    {
        "value": 7893,
        "NOCCode": "5251",
        "JobTitle": "bowler"
    },
    {
        "value": 7894,
        "NOCCode": "5251",
        "JobTitle": "boxer"
    },
    {
        "value": 7895,
        "NOCCode": "5251",
        "JobTitle": "canoeist"
    },
    {
        "value": 7896,
        "NOCCode": "5251",
        "JobTitle": "chess player"
    },
    {
        "value": 7897,
        "NOCCode": "5251",
        "JobTitle": "chuckwagon driver"
    },
    {
        "value": 7898,
        "NOCCode": "5251",
        "JobTitle": "chuckwagon racer – stampede"
    },
    {
        "value": 7899,
        "NOCCode": "5251",
        "JobTitle": "competitor – chess"
    },
    {
        "value": 7900,
        "NOCCode": "5251",
        "JobTitle": "coxswain"
    },
    {
        "value": 7901,
        "NOCCode": "5251",
        "JobTitle": "curler"
    },
    {
        "value": 7902,
        "NOCCode": "5251",
        "JobTitle": "cyclist"
    },
    {
        "value": 7903,
        "NOCCode": "5251",
        "JobTitle": "demolition driver"
    },
    {
        "value": 7904,
        "NOCCode": "5251",
        "JobTitle": "discus thrower"
    },
    {
        "value": 7905,
        "NOCCode": "5251",
        "JobTitle": "diver, olympic sport"
    },
    {
        "value": 7906,
        "NOCCode": "5251",
        "JobTitle": "driver, chuckwagon"
    },
    {
        "value": 7907,
        "NOCCode": "5251",
        "JobTitle": "driver, demolition"
    },
    {
        "value": 7908,
        "NOCCode": "5251",
        "JobTitle": "driver, harness race"
    },
    {
        "value": 7909,
        "NOCCode": "5251",
        "JobTitle": "driver, harness racing"
    },
    {
        "value": 7910,
        "NOCCode": "5251",
        "JobTitle": "driver, race car"
    },
    {
        "value": 7911,
        "NOCCode": "5251",
        "JobTitle": "driver, sulky"
    },
    {
        "value": 7912,
        "NOCCode": "5251",
        "JobTitle": "fencer"
    },
    {
        "value": 7913,
        "NOCCode": "5251",
        "JobTitle": "figure skater"
    },
    {
        "value": 7914,
        "NOCCode": "5251",
        "JobTitle": "football player"
    },
    {
        "value": 7915,
        "NOCCode": "5251",
        "JobTitle": "footballer"
    },
    {
        "value": 7916,
        "NOCCode": "5251",
        "JobTitle": "golf player"
    },
    {
        "value": 7917,
        "NOCCode": "5251",
        "JobTitle": "golfer"
    },
    {
        "value": 7918,
        "NOCCode": "5251",
        "JobTitle": "gymnast"
    },
    {
        "value": 7919,
        "NOCCode": "5251",
        "JobTitle": "hammer thrower"
    },
    {
        "value": 7920,
        "NOCCode": "5251",
        "JobTitle": "handball player"
    },
    {
        "value": 7921,
        "NOCCode": "5251",
        "JobTitle": "harness race driver"
    },
    {
        "value": 7922,
        "NOCCode": "5251",
        "JobTitle": "harness racing driver"
    },
    {
        "value": 7923,
        "NOCCode": "5251",
        "JobTitle": "helmsman – sports"
    },
    {
        "value": 7924,
        "NOCCode": "5251",
        "JobTitle": "hockey player"
    },
    {
        "value": 7925,
        "NOCCode": "5251",
        "JobTitle": "javelin thrower"
    },
    {
        "value": 7926,
        "NOCCode": "5251",
        "JobTitle": "jockey"
    },
    {
        "value": 7927,
        "NOCCode": "5251",
        "JobTitle": "judoka"
    },
    {
        "value": 7928,
        "NOCCode": "5251",
        "JobTitle": "jumper"
    },
    {
        "value": 7929,
        "NOCCode": "5251",
        "JobTitle": "pentathlete"
    },
    {
        "value": 7930,
        "NOCCode": "5251",
        "JobTitle": "performer, rodeo"
    },
    {
        "value": 7931,
        "NOCCode": "5251",
        "JobTitle": "performer, stampede"
    },
    {
        "value": 7932,
        "NOCCode": "5251",
        "JobTitle": "ping-pong player"
    },
    {
        "value": 7933,
        "NOCCode": "5251",
        "JobTitle": "poker player"
    },
    {
        "value": 7934,
        "NOCCode": "5251",
        "JobTitle": "prize fighter"
    },
    {
        "value": 7935,
        "NOCCode": "5251",
        "JobTitle": "professional athlete"
    },
    {
        "value": 7936,
        "NOCCode": "5251",
        "JobTitle": "professional boxer"
    },
    {
        "value": 7937,
        "NOCCode": "5251",
        "JobTitle": "professional pool player"
    },
    {
        "value": 7938,
        "NOCCode": "5251",
        "JobTitle": "professional sportsman/woman"
    },
    {
        "value": 7939,
        "NOCCode": "5251",
        "JobTitle": "race car driver"
    },
    {
        "value": 7940,
        "NOCCode": "5251",
        "JobTitle": "racer, automobile"
    },
    {
        "value": 7941,
        "NOCCode": "5251",
        "JobTitle": "racer, stampede"
    },
    {
        "value": 7942,
        "NOCCode": "5251",
        "JobTitle": "racer, stock car"
    },
    {
        "value": 7943,
        "NOCCode": "5251",
        "JobTitle": "rider"
    },
    {
        "value": 7944,
        "NOCCode": "5251",
        "JobTitle": "rodeo performer"
    },
    {
        "value": 7945,
        "NOCCode": "5251",
        "JobTitle": "rower"
    },
    {
        "value": 7946,
        "NOCCode": "5251",
        "JobTitle": "sailboarder"
    },
    {
        "value": 7947,
        "NOCCode": "5251",
        "JobTitle": "short-distance runner"
    },
    {
        "value": 7948,
        "NOCCode": "5251",
        "JobTitle": "shot put"
    },
    {
        "value": 7949,
        "NOCCode": "5251",
        "JobTitle": "skater"
    },
    {
        "value": 7950,
        "NOCCode": "5251",
        "JobTitle": "skier"
    },
    {
        "value": 7951,
        "NOCCode": "5251",
        "JobTitle": "snowboarder"
    },
    {
        "value": 7952,
        "NOCCode": "5251",
        "JobTitle": "soccer player"
    },
    {
        "value": 7953,
        "NOCCode": "5251",
        "JobTitle": "sprinter"
    },
    {
        "value": 7954,
        "NOCCode": "5251",
        "JobTitle": "stampede performer"
    },
    {
        "value": 7955,
        "NOCCode": "5251",
        "JobTitle": "stampede racer"
    },
    {
        "value": 7956,
        "NOCCode": "5251",
        "JobTitle": "standardbred pacer"
    },
    {
        "value": 7957,
        "NOCCode": "5251",
        "JobTitle": "stock car racer"
    },
    {
        "value": 7958,
        "NOCCode": "5251",
        "JobTitle": "sulky driver"
    },
    {
        "value": 7959,
        "NOCCode": "5251",
        "JobTitle": "swimmer"
    },
    {
        "value": 7960,
        "NOCCode": "5251",
        "JobTitle": "table tennis player"
    },
    {
        "value": 7961,
        "NOCCode": "5251",
        "JobTitle": "tennis player"
    },
    {
        "value": 7962,
        "NOCCode": "5251",
        "JobTitle": "track athlete"
    },
    {
        "value": 7963,
        "NOCCode": "5251",
        "JobTitle": "volleyball player"
    },
    {
        "value": 7964,
        "NOCCode": "5251",
        "JobTitle": "water-polo player"
    },
    {
        "value": 7965,
        "NOCCode": "5251",
        "JobTitle": "weight-lifter"
    },
    {
        "value": 7966,
        "NOCCode": "5251",
        "JobTitle": "wrestler"
    },
    {
        "value": 7967,
        "NOCCode": "5252",
        "JobTitle": "amateur athletics team coach"
    },
    {
        "value": 7968,
        "NOCCode": "5252",
        "JobTitle": "amateur sports team coach"
    },
    {
        "value": 7969,
        "NOCCode": "5252",
        "JobTitle": "amateur track and field team coach"
    },
    {
        "value": 7970,
        "NOCCode": "5252",
        "JobTitle": "athletic boxing trainer"
    },
    {
        "value": 7971,
        "NOCCode": "5252",
        "JobTitle": "athletics coach"
    },
    {
        "value": 7972,
        "NOCCode": "5252",
        "JobTitle": "athletics team coach"
    },
    {
        "value": 7973,
        "NOCCode": "5252",
        "JobTitle": "baseball coach"
    },
    {
        "value": 7974,
        "NOCCode": "5252",
        "JobTitle": "baseball manager-coach"
    },
    {
        "value": 7975,
        "NOCCode": "5252",
        "JobTitle": "baseball scout"
    },
    {
        "value": 7976,
        "NOCCode": "5252",
        "JobTitle": "baseball team coach"
    },
    {
        "value": 7977,
        "NOCCode": "5252",
        "JobTitle": "basketball scout"
    },
    {
        "value": 7978,
        "NOCCode": "5252",
        "JobTitle": "basketball team coach"
    },
    {
        "value": 7979,
        "NOCCode": "5252",
        "JobTitle": "bobsled coach"
    },
    {
        "value": 7980,
        "NOCCode": "5252",
        "JobTitle": "bobsleigh coach"
    },
    {
        "value": 7981,
        "NOCCode": "5252",
        "JobTitle": "boxing trainer"
    },
    {
        "value": 7982,
        "NOCCode": "5252",
        "JobTitle": "coach"
    },
    {
        "value": 7983,
        "NOCCode": "5252",
        "JobTitle": "coach, amateur athletics team"
    },
    {
        "value": 7984,
        "NOCCode": "5252",
        "JobTitle": "coach, amateur sports team"
    },
    {
        "value": 7985,
        "NOCCode": "5252",
        "JobTitle": "coach, amateur track and field team"
    },
    {
        "value": 7986,
        "NOCCode": "5252",
        "JobTitle": "coach, athletics"
    },
    {
        "value": 7987,
        "NOCCode": "5252",
        "JobTitle": "coach, athletics team"
    },
    {
        "value": 7988,
        "NOCCode": "5252",
        "JobTitle": "coach, baseball"
    },
    {
        "value": 7989,
        "NOCCode": "5252",
        "JobTitle": "coach, baseball team"
    },
    {
        "value": 7990,
        "NOCCode": "5252",
        "JobTitle": "coach, basketball team"
    },
    {
        "value": 7991,
        "NOCCode": "5252",
        "JobTitle": "coach, bobsled"
    },
    {
        "value": 7992,
        "NOCCode": "5252",
        "JobTitle": "coach, bobsleigh"
    },
    {
        "value": 7993,
        "NOCCode": "5252",
        "JobTitle": "coach, figure skating"
    },
    {
        "value": 7994,
        "NOCCode": "5252",
        "JobTitle": "coach, football"
    },
    {
        "value": 7995,
        "NOCCode": "5252",
        "JobTitle": "coach, football team"
    },
    {
        "value": 7996,
        "NOCCode": "5252",
        "JobTitle": "coach, gymnastics"
    },
    {
        "value": 7997,
        "NOCCode": "5252",
        "JobTitle": "coach, hockey"
    },
    {
        "value": 7998,
        "NOCCode": "5252",
        "JobTitle": "coach, hockey team"
    },
    {
        "value": 7999,
        "NOCCode": "5252",
        "JobTitle": "coach, junior hockey"
    },
    {
        "value": 8000,
        "NOCCode": "5252",
        "JobTitle": "coach, lacrosse"
    },
    {
        "value": 8001,
        "NOCCode": "5252",
        "JobTitle": "coach, lacrosse team"
    },
    {
        "value": 8002,
        "NOCCode": "5252",
        "JobTitle": "coach, luge"
    },
    {
        "value": 8003,
        "NOCCode": "5252",
        "JobTitle": "coach, national team"
    },
    {
        "value": 8004,
        "NOCCode": "5252",
        "JobTitle": "coach, professional sports team"
    },
    {
        "value": 8005,
        "NOCCode": "5252",
        "JobTitle": "coach, professional team"
    },
    {
        "value": 8006,
        "NOCCode": "5252",
        "JobTitle": "coach, provincial team"
    },
    {
        "value": 8007,
        "NOCCode": "5252",
        "JobTitle": "coach, ski"
    },
    {
        "value": 8008,
        "NOCCode": "5252",
        "JobTitle": "coach, sports"
    },
    {
        "value": 8009,
        "NOCCode": "5252",
        "JobTitle": "coach, swim"
    },
    {
        "value": 8010,
        "NOCCode": "5252",
        "JobTitle": "coach, swimming"
    },
    {
        "value": 8011,
        "NOCCode": "5252",
        "JobTitle": "coach, team of professional athletes"
    },
    {
        "value": 8012,
        "NOCCode": "5252",
        "JobTitle": "coach, tennis"
    },
    {
        "value": 8013,
        "NOCCode": "5252",
        "JobTitle": "coach, track and field"
    },
    {
        "value": 8014,
        "NOCCode": "5252",
        "JobTitle": "coach, track and field athletes"
    },
    {
        "value": 8015,
        "NOCCode": "5252",
        "JobTitle": "coach, track and field team"
    },
    {
        "value": 8016,
        "NOCCode": "5252",
        "JobTitle": "coach, volleyball"
    },
    {
        "value": 8017,
        "NOCCode": "5252",
        "JobTitle": "cycling coach"
    },
    {
        "value": 8018,
        "NOCCode": "5252",
        "JobTitle": "figure skating coach"
    },
    {
        "value": 8019,
        "NOCCode": "5252",
        "JobTitle": "football coach"
    },
    {
        "value": 8020,
        "NOCCode": "5252",
        "JobTitle": "football scout"
    },
    {
        "value": 8021,
        "NOCCode": "5252",
        "JobTitle": "football team coach"
    },
    {
        "value": 8022,
        "NOCCode": "5252",
        "JobTitle": "gymnastics coach"
    },
    {
        "value": 8023,
        "NOCCode": "5252",
        "JobTitle": "head coach"
    },
    {
        "value": 8024,
        "NOCCode": "5252",
        "JobTitle": "hockey coach"
    },
    {
        "value": 8025,
        "NOCCode": "5252",
        "JobTitle": "hockey scout"
    },
    {
        "value": 8026,
        "NOCCode": "5252",
        "JobTitle": "hockey team coach"
    },
    {
        "value": 8027,
        "NOCCode": "5252",
        "JobTitle": "instructor, luge"
    },
    {
        "value": 8028,
        "NOCCode": "5252",
        "JobTitle": "junior hockey coach"
    },
    {
        "value": 8029,
        "NOCCode": "5252",
        "JobTitle": "kickboxing trainer"
    },
    {
        "value": 8030,
        "NOCCode": "5252",
        "JobTitle": "lacrosse coach"
    },
    {
        "value": 8031,
        "NOCCode": "5252",
        "JobTitle": "lacrosse scout"
    },
    {
        "value": 8032,
        "NOCCode": "5252",
        "JobTitle": "lacrosse team coach"
    },
    {
        "value": 8033,
        "NOCCode": "5252",
        "JobTitle": "luge coach"
    },
    {
        "value": 8034,
        "NOCCode": "5252",
        "JobTitle": "luge instructor"
    },
    {
        "value": 8035,
        "NOCCode": "5252",
        "JobTitle": "manager-coach, baseball"
    },
    {
        "value": 8036,
        "NOCCode": "5252",
        "JobTitle": "national team coach"
    },
    {
        "value": 8037,
        "NOCCode": "5252",
        "JobTitle": "professional sports scout"
    },
    {
        "value": 8038,
        "NOCCode": "5252",
        "JobTitle": "professional sports team coach"
    },
    {
        "value": 8039,
        "NOCCode": "5252",
        "JobTitle": "professional team coach"
    },
    {
        "value": 8040,
        "NOCCode": "5252",
        "JobTitle": "provincial team coach"
    },
    {
        "value": 8041,
        "NOCCode": "5252",
        "JobTitle": "scout, baseball"
    },
    {
        "value": 8042,
        "NOCCode": "5252",
        "JobTitle": "scout, basketball"
    },
    {
        "value": 8043,
        "NOCCode": "5252",
        "JobTitle": "scout, football"
    },
    {
        "value": 8044,
        "NOCCode": "5252",
        "JobTitle": "scout, hockey"
    },
    {
        "value": 8045,
        "NOCCode": "5252",
        "JobTitle": "scout, lacrosse"
    },
    {
        "value": 8046,
        "NOCCode": "5252",
        "JobTitle": "scout, professional sports"
    },
    {
        "value": 8047,
        "NOCCode": "5252",
        "JobTitle": "scout, sports"
    },
    {
        "value": 8048,
        "NOCCode": "5252",
        "JobTitle": "ski coach"
    },
    {
        "value": 8049,
        "NOCCode": "5252",
        "JobTitle": "soccer coach"
    },
    {
        "value": 8050,
        "NOCCode": "5252",
        "JobTitle": "sports coach"
    },
    {
        "value": 8051,
        "NOCCode": "5252",
        "JobTitle": "sports scout"
    },
    {
        "value": 8052,
        "NOCCode": "5252",
        "JobTitle": "swim coach"
    },
    {
        "value": 8053,
        "NOCCode": "5252",
        "JobTitle": "swimming coach"
    },
    {
        "value": 8054,
        "NOCCode": "5252",
        "JobTitle": "team coach, professional athletes"
    },
    {
        "value": 8055,
        "NOCCode": "5252",
        "JobTitle": "tennis coach"
    },
    {
        "value": 8056,
        "NOCCode": "5252",
        "JobTitle": "track and field athletes coach"
    },
    {
        "value": 8057,
        "NOCCode": "5252",
        "JobTitle": "track and field coach"
    },
    {
        "value": 8058,
        "NOCCode": "5252",
        "JobTitle": "track and field team coach"
    },
    {
        "value": 8059,
        "NOCCode": "5252",
        "JobTitle": "trainer, athletic boxing"
    },
    {
        "value": 8060,
        "NOCCode": "5252",
        "JobTitle": "trainer, boxing"
    },
    {
        "value": 8061,
        "NOCCode": "5252",
        "JobTitle": "trainer, kickboxing"
    },
    {
        "value": 8062,
        "NOCCode": "5252",
        "JobTitle": "volleyball coach"
    },
    {
        "value": 8063,
        "NOCCode": "5252",
        "JobTitle": "water-polo coach"
    },
    {
        "value": 8064,
        "NOCCode": "5253",
        "JobTitle": "athletics judge"
    },
    {
        "value": 8065,
        "NOCCode": "5253",
        "JobTitle": "clerk of scales – racetrack"
    },
    {
        "value": 8066,
        "NOCCode": "5253",
        "JobTitle": "clerk of the course – harness racing"
    },
    {
        "value": 8067,
        "NOCCode": "5253",
        "JobTitle": "clerk of the course – horseback racing"
    },
    {
        "value": 8068,
        "NOCCode": "5253",
        "JobTitle": "clerk of the course – racetrack"
    },
    {
        "value": 8069,
        "NOCCode": "5253",
        "JobTitle": "clocker, racetrack"
    },
    {
        "value": 8070,
        "NOCCode": "5253",
        "JobTitle": "commission steward – horse racing"
    },
    {
        "value": 8071,
        "NOCCode": "5253",
        "JobTitle": "competitive sports judge"
    },
    {
        "value": 8072,
        "NOCCode": "5253",
        "JobTitle": "curling judge"
    },
    {
        "value": 8073,
        "NOCCode": "5253",
        "JobTitle": "figure skating evaluator"
    },
    {
        "value": 8074,
        "NOCCode": "5253",
        "JobTitle": "figure skating judge"
    },
    {
        "value": 8075,
        "NOCCode": "5253",
        "JobTitle": "goal judge"
    },
    {
        "value": 8076,
        "NOCCode": "5253",
        "JobTitle": "harness racing starter"
    },
    {
        "value": 8077,
        "NOCCode": "5253",
        "JobTitle": "horse identifier"
    },
    {
        "value": 8078,
        "NOCCode": "5253",
        "JobTitle": "horse plating inspector – racetrack"
    },
    {
        "value": 8079,
        "NOCCode": "5253",
        "JobTitle": "horse race timer"
    },
    {
        "value": 8080,
        "NOCCode": "5253",
        "JobTitle": "horse racing starter"
    },
    {
        "value": 8081,
        "NOCCode": "5253",
        "JobTitle": "horseshoe inspector"
    },
    {
        "value": 8082,
        "NOCCode": "5253",
        "JobTitle": "identifier, horses"
    },
    {
        "value": 8083,
        "NOCCode": "5253",
        "JobTitle": "inspector, horse plating – racetrack"
    },
    {
        "value": 8084,
        "NOCCode": "5253",
        "JobTitle": "inspector, horseshoes"
    },
    {
        "value": 8085,
        "NOCCode": "5253",
        "JobTitle": "judge, athletics"
    },
    {
        "value": 8086,
        "NOCCode": "5253",
        "JobTitle": "judge, competitive sports"
    },
    {
        "value": 8087,
        "NOCCode": "5253",
        "JobTitle": "judge, figure skating"
    },
    {
        "value": 8088,
        "NOCCode": "5253",
        "JobTitle": "judge, goals"
    },
    {
        "value": 8089,
        "NOCCode": "5253",
        "JobTitle": "judge, paddock – racetrack"
    },
    {
        "value": 8090,
        "NOCCode": "5253",
        "JobTitle": "judge, patrol – racetrack"
    },
    {
        "value": 8091,
        "NOCCode": "5253",
        "JobTitle": "judge, racehorses – racetrack"
    },
    {
        "value": 8092,
        "NOCCode": "5253",
        "JobTitle": "judge, sport competitions"
    },
    {
        "value": 8093,
        "NOCCode": "5253",
        "JobTitle": "judge, sports"
    },
    {
        "value": 8094,
        "NOCCode": "5253",
        "JobTitle": "judge, standardbreds – racetrack"
    },
    {
        "value": 8095,
        "NOCCode": "5253",
        "JobTitle": "judge, track"
    },
    {
        "value": 8096,
        "NOCCode": "5253",
        "JobTitle": "judge, track and field"
    },
    {
        "value": 8097,
        "NOCCode": "5253",
        "JobTitle": "linesman/woman, sports"
    },
    {
        "value": 8098,
        "NOCCode": "5253",
        "JobTitle": "operator, scoreboard"
    },
    {
        "value": 8099,
        "NOCCode": "5253",
        "JobTitle": "paddock blacksmith – racetrack"
    },
    {
        "value": 8100,
        "NOCCode": "5253",
        "JobTitle": "paddock horseshoer – racetrack"
    },
    {
        "value": 8101,
        "NOCCode": "5253",
        "JobTitle": "paddock judge – racetrack"
    },
    {
        "value": 8102,
        "NOCCode": "5253",
        "JobTitle": "paddock plater – racetrack"
    },
    {
        "value": 8103,
        "NOCCode": "5253",
        "JobTitle": "patrol judge – racetrack"
    },
    {
        "value": 8104,
        "NOCCode": "5253",
        "JobTitle": "placing judge – racetrack"
    },
    {
        "value": 8105,
        "NOCCode": "5253",
        "JobTitle": "presiding steward – racetrack"
    },
    {
        "value": 8106,
        "NOCCode": "5253",
        "JobTitle": "racehorse judge – racetrack"
    },
    {
        "value": 8107,
        "NOCCode": "5253",
        "JobTitle": "racetrack clocker"
    },
    {
        "value": 8108,
        "NOCCode": "5253",
        "JobTitle": "racetrack steward"
    },
    {
        "value": 8109,
        "NOCCode": "5253",
        "JobTitle": "racetrack timer"
    },
    {
        "value": 8110,
        "NOCCode": "5253",
        "JobTitle": "racing secretary and handicapper"
    },
    {
        "value": 8111,
        "NOCCode": "5253",
        "JobTitle": "referee"
    },
    {
        "value": 8112,
        "NOCCode": "5253",
        "JobTitle": "referee, sports"
    },
    {
        "value": 8113,
        "NOCCode": "5253",
        "JobTitle": "scoreboard operator"
    },
    {
        "value": 8114,
        "NOCCode": "5253",
        "JobTitle": "scorekeeper"
    },
    {
        "value": 8115,
        "NOCCode": "5253",
        "JobTitle": "sports competition judge"
    },
    {
        "value": 8116,
        "NOCCode": "5253",
        "JobTitle": "sports judge"
    },
    {
        "value": 8117,
        "NOCCode": "5253",
        "JobTitle": "sports linesman/woman"
    },
    {
        "value": 8118,
        "NOCCode": "5253",
        "JobTitle": "sports official"
    },
    {
        "value": 8119,
        "NOCCode": "5253",
        "JobTitle": "sports referee"
    },
    {
        "value": 8120,
        "NOCCode": "5253",
        "JobTitle": "standardbred judge – racetrack"
    },
    {
        "value": 8121,
        "NOCCode": "5253",
        "JobTitle": "starter, harness racing"
    },
    {
        "value": 8122,
        "NOCCode": "5253",
        "JobTitle": "starter, horse racing"
    },
    {
        "value": 8123,
        "NOCCode": "5253",
        "JobTitle": "steward, presiding – racetrack"
    },
    {
        "value": 8124,
        "NOCCode": "5253",
        "JobTitle": "steward, racetrack"
    },
    {
        "value": 8125,
        "NOCCode": "5253",
        "JobTitle": "timer, racetrack"
    },
    {
        "value": 8126,
        "NOCCode": "5253",
        "JobTitle": "track and field judge"
    },
    {
        "value": 8127,
        "NOCCode": "5253",
        "JobTitle": "track judge"
    },
    {
        "value": 8128,
        "NOCCode": "5253",
        "JobTitle": "umpire"
    },
    {
        "value": 8129,
        "NOCCode": "5254",
        "JobTitle": "activities leader – seniors"
    },
    {
        "value": 8130,
        "NOCCode": "5254",
        "JobTitle": "aerobics instructor"
    },
    {
        "value": 8131,
        "NOCCode": "5254",
        "JobTitle": "arts and crafts instructor"
    },
    {
        "value": 8132,
        "NOCCode": "5254",
        "JobTitle": "arts and crafts leader"
    },
    {
        "value": 8133,
        "NOCCode": "5254",
        "JobTitle": "arts and crafts leader – sports and recreation"
    },
    {
        "value": 8134,
        "NOCCode": "5254",
        "JobTitle": "arts and leisure leader"
    },
    {
        "value": 8135,
        "NOCCode": "5254",
        "JobTitle": "arts and recreation leader"
    },
    {
        "value": 8136,
        "NOCCode": "5254",
        "JobTitle": "athletics instructor"
    },
    {
        "value": 8137,
        "NOCCode": "5254",
        "JobTitle": "beach lifeguard"
    },
    {
        "value": 8138,
        "NOCCode": "5254",
        "JobTitle": "bowling instructor"
    },
    {
        "value": 8139,
        "NOCCode": "5254",
        "JobTitle": "bridge instructor"
    },
    {
        "value": 8140,
        "NOCCode": "5254",
        "JobTitle": "bridge teacher"
    },
    {
        "value": 8141,
        "NOCCode": "5254",
        "JobTitle": "callisthenics instructor"
    },
    {
        "value": 8142,
        "NOCCode": "5254",
        "JobTitle": "camp co-ordinator"
    },
    {
        "value": 8143,
        "NOCCode": "5254",
        "JobTitle": "camp counsellor"
    },
    {
        "value": 8144,
        "NOCCode": "5254",
        "JobTitle": "camp leader"
    },
    {
        "value": 8145,
        "NOCCode": "5254",
        "JobTitle": "certified fitness apraiser"
    },
    {
        "value": 8146,
        "NOCCode": "5254",
        "JobTitle": "certified personal trainer"
    },
    {
        "value": 8147,
        "NOCCode": "5254",
        "JobTitle": "chess instructor"
    },
    {
        "value": 8148,
        "NOCCode": "5254",
        "JobTitle": "co-ordinator, camp – sports and leisure"
    },
    {
        "value": 8149,
        "NOCCode": "5254",
        "JobTitle": "co-ordinator, pool – recreation"
    },
    {
        "value": 8150,
        "NOCCode": "5254",
        "JobTitle": "counsellor, camp"
    },
    {
        "value": 8151,
        "NOCCode": "5254",
        "JobTitle": "counsellor, day camp – recreation"
    },
    {
        "value": 8152,
        "NOCCode": "5254",
        "JobTitle": "cultural activities leader"
    },
    {
        "value": 8153,
        "NOCCode": "5254",
        "JobTitle": "day camp counsellor – recreation"
    },
    {
        "value": 8154,
        "NOCCode": "5254",
        "JobTitle": "day camp leader"
    },
    {
        "value": 8155,
        "NOCCode": "5254",
        "JobTitle": "dressage instructor"
    },
    {
        "value": 8156,
        "NOCCode": "5254",
        "JobTitle": "fencing instructor"
    },
    {
        "value": 8157,
        "NOCCode": "5254",
        "JobTitle": "fishing seminar leader"
    },
    {
        "value": 8158,
        "NOCCode": "5254",
        "JobTitle": "fitness appraiser"
    },
    {
        "value": 8159,
        "NOCCode": "5254",
        "JobTitle": "fitness instructor"
    },
    {
        "value": 8160,
        "NOCCode": "5254",
        "JobTitle": "fitness leader"
    },
    {
        "value": 8161,
        "NOCCode": "5254",
        "JobTitle": "fitness leader – aerobic fitness"
    },
    {
        "value": 8162,
        "NOCCode": "5254",
        "JobTitle": "fitness leader – aquatic fitness"
    },
    {
        "value": 8163,
        "NOCCode": "5254",
        "JobTitle": "fitness leader – fitness for older adults"
    },
    {
        "value": 8164,
        "NOCCode": "5254",
        "JobTitle": "fitness leader – strength and conditioning"
    },
    {
        "value": 8165,
        "NOCCode": "5254",
        "JobTitle": "fitness supervisor"
    },
    {
        "value": 8166,
        "NOCCode": "5254",
        "JobTitle": "golf instructor"
    },
    {
        "value": 8167,
        "NOCCode": "5254",
        "JobTitle": "gymnastics instructor"
    },
    {
        "value": 8168,
        "NOCCode": "5254",
        "JobTitle": "gymnastics teacher"
    },
    {
        "value": 8169,
        "NOCCode": "5254",
        "JobTitle": "horseback riding instructor"
    },
    {
        "value": 8170,
        "NOCCode": "5254",
        "JobTitle": "ice-skating instructor"
    },
    {
        "value": 8171,
        "NOCCode": "5254",
        "JobTitle": "inline skating instructor"
    },
    {
        "value": 8172,
        "NOCCode": "5254",
        "JobTitle": "instructor, aerobics"
    },
    {
        "value": 8173,
        "NOCCode": "5254",
        "JobTitle": "instructor, arts and crafts"
    },
    {
        "value": 8174,
        "NOCCode": "5254",
        "JobTitle": "instructor, athletics"
    },
    {
        "value": 8175,
        "NOCCode": "5254",
        "JobTitle": "instructor, bowling"
    },
    {
        "value": 8176,
        "NOCCode": "5254",
        "JobTitle": "instructor, bridge"
    },
    {
        "value": 8177,
        "NOCCode": "5254",
        "JobTitle": "instructor, callisthenics"
    },
    {
        "value": 8178,
        "NOCCode": "5254",
        "JobTitle": "instructor, dressage"
    },
    {
        "value": 8179,
        "NOCCode": "5254",
        "JobTitle": "instructor, fencing"
    },
    {
        "value": 8180,
        "NOCCode": "5254",
        "JobTitle": "instructor, fitness"
    },
    {
        "value": 8181,
        "NOCCode": "5254",
        "JobTitle": "instructor, golf"
    },
    {
        "value": 8182,
        "NOCCode": "5254",
        "JobTitle": "instructor, gymnastics"
    },
    {
        "value": 8183,
        "NOCCode": "5254",
        "JobTitle": "instructor, horseback riding"
    },
    {
        "value": 8184,
        "NOCCode": "5254",
        "JobTitle": "instructor, ice-skating"
    },
    {
        "value": 8185,
        "NOCCode": "5254",
        "JobTitle": "instructor, judo"
    },
    {
        "value": 8186,
        "NOCCode": "5254",
        "JobTitle": "instructor, jujitsu"
    },
    {
        "value": 8187,
        "NOCCode": "5254",
        "JobTitle": "instructor, karate"
    },
    {
        "value": 8188,
        "NOCCode": "5254",
        "JobTitle": "instructor, martial arts"
    },
    {
        "value": 8189,
        "NOCCode": "5254",
        "JobTitle": "instructor, physical training"
    },
    {
        "value": 8190,
        "NOCCode": "5254",
        "JobTitle": "instructor, recreation"
    },
    {
        "value": 8191,
        "NOCCode": "5254",
        "JobTitle": "instructor, riding"
    },
    {
        "value": 8192,
        "NOCCode": "5254",
        "JobTitle": "instructor, rollerblading"
    },
    {
        "value": 8193,
        "NOCCode": "5254",
        "JobTitle": "instructor, roller-skating"
    },
    {
        "value": 8194,
        "NOCCode": "5254",
        "JobTitle": "instructor, ski"
    },
    {
        "value": 8195,
        "NOCCode": "5254",
        "JobTitle": "instructor, ski – sports and recreation"
    },
    {
        "value": 8196,
        "NOCCode": "5254",
        "JobTitle": "instructor, sports"
    },
    {
        "value": 8197,
        "NOCCode": "5254",
        "JobTitle": "instructor, swimming – recreation"
    },
    {
        "value": 8198,
        "NOCCode": "5254",
        "JobTitle": "instructor, swimming – sports"
    },
    {
        "value": 8199,
        "NOCCode": "5254",
        "JobTitle": "instructor, tai-chi"
    },
    {
        "value": 8200,
        "NOCCode": "5254",
        "JobTitle": "instructor, tennis – recreation"
    },
    {
        "value": 8201,
        "NOCCode": "5254",
        "JobTitle": "instructor, tennis – sports"
    },
    {
        "value": 8202,
        "NOCCode": "5254",
        "JobTitle": "instructor, track and field"
    },
    {
        "value": 8203,
        "NOCCode": "5254",
        "JobTitle": "instructor, weightlifting"
    },
    {
        "value": 8204,
        "NOCCode": "5254",
        "JobTitle": "instructor, yoga"
    },
    {
        "value": 8205,
        "NOCCode": "5254",
        "JobTitle": "judo instructor"
    },
    {
        "value": 8206,
        "NOCCode": "5254",
        "JobTitle": "jujitsu instructor"
    },
    {
        "value": 8207,
        "NOCCode": "5254",
        "JobTitle": "karate instructor"
    },
    {
        "value": 8208,
        "NOCCode": "5254",
        "JobTitle": "leisure program leader"
    },
    {
        "value": 8209,
        "NOCCode": "5254",
        "JobTitle": "life enrichment co-ordinator – recreation and fitness"
    },
    {
        "value": 8210,
        "NOCCode": "5254",
        "JobTitle": "lifeguard, beach"
    },
    {
        "value": 8211,
        "NOCCode": "5254",
        "JobTitle": "lifeguard, pool"
    },
    {
        "value": 8212,
        "NOCCode": "5254",
        "JobTitle": "martial arts instructor"
    },
    {
        "value": 8213,
        "NOCCode": "5254",
        "JobTitle": "master, riding"
    },
    {
        "value": 8214,
        "NOCCode": "5254",
        "JobTitle": "patroller, ski"
    },
    {
        "value": 8215,
        "NOCCode": "5254",
        "JobTitle": "personal trainer"
    },
    {
        "value": 8216,
        "NOCCode": "5254",
        "JobTitle": "physical training instructor"
    },
    {
        "value": 8217,
        "NOCCode": "5254",
        "JobTitle": "physical training leader"
    },
    {
        "value": 8218,
        "NOCCode": "5254",
        "JobTitle": "pilates instructor"
    },
    {
        "value": 8219,
        "NOCCode": "5254",
        "JobTitle": "playground leader"
    },
    {
        "value": 8220,
        "NOCCode": "5254",
        "JobTitle": "playground worker"
    },
    {
        "value": 8221,
        "NOCCode": "5254",
        "JobTitle": "pool co-ordinator – recreation"
    },
    {
        "value": 8222,
        "NOCCode": "5254",
        "JobTitle": "pool lifeguard"
    },
    {
        "value": 8223,
        "NOCCode": "5254",
        "JobTitle": "professional golf instructor"
    },
    {
        "value": 8224,
        "NOCCode": "5254",
        "JobTitle": "professional instructor, golf"
    },
    {
        "value": 8225,
        "NOCCode": "5254",
        "JobTitle": "professional instructor, tennis"
    },
    {
        "value": 8226,
        "NOCCode": "5254",
        "JobTitle": "professional tennis instructor"
    },
    {
        "value": 8227,
        "NOCCode": "5254",
        "JobTitle": "recreation instructor"
    },
    {
        "value": 8228,
        "NOCCode": "5254",
        "JobTitle": "recreation leader"
    },
    {
        "value": 8229,
        "NOCCode": "5254",
        "JobTitle": "recreation program leader"
    },
    {
        "value": 8230,
        "NOCCode": "5254",
        "JobTitle": "recreation technician"
    },
    {
        "value": 8231,
        "NOCCode": "5254",
        "JobTitle": "riding instructor"
    },
    {
        "value": 8232,
        "NOCCode": "5254",
        "JobTitle": "riding master"
    },
    {
        "value": 8233,
        "NOCCode": "5254",
        "JobTitle": "rollerblading instructor"
    },
    {
        "value": 8234,
        "NOCCode": "5254",
        "JobTitle": "roller-skating instructor"
    },
    {
        "value": 8235,
        "NOCCode": "5254",
        "JobTitle": "skateboarding instructor"
    },
    {
        "value": 8236,
        "NOCCode": "5254",
        "JobTitle": "skating instructor, ice"
    },
    {
        "value": 8237,
        "NOCCode": "5254",
        "JobTitle": "ski instructor"
    },
    {
        "value": 8238,
        "NOCCode": "5254",
        "JobTitle": "ski instructor – sports and recreation"
    },
    {
        "value": 8239,
        "NOCCode": "5254",
        "JobTitle": "ski patrol"
    },
    {
        "value": 8240,
        "NOCCode": "5254",
        "JobTitle": "snowboarding instructor"
    },
    {
        "value": 8241,
        "NOCCode": "5254",
        "JobTitle": "sports and leisure leader"
    },
    {
        "value": 8242,
        "NOCCode": "5254",
        "JobTitle": "sports and recreation leader"
    },
    {
        "value": 8243,
        "NOCCode": "5254",
        "JobTitle": "sports instructor"
    },
    {
        "value": 8244,
        "NOCCode": "5254",
        "JobTitle": "sports leader"
    },
    {
        "value": 8245,
        "NOCCode": "5254",
        "JobTitle": "supervisor, fitness"
    },
    {
        "value": 8246,
        "NOCCode": "5254",
        "JobTitle": "swimming instructor – recreation"
    },
    {
        "value": 8247,
        "NOCCode": "5254",
        "JobTitle": "swimming instructor – sports"
    },
    {
        "value": 8248,
        "NOCCode": "5254",
        "JobTitle": "tai-chi instructor"
    },
    {
        "value": 8249,
        "NOCCode": "5254",
        "JobTitle": "teacher, bridge"
    },
    {
        "value": 8250,
        "NOCCode": "5254",
        "JobTitle": "teacher, gymnastics"
    },
    {
        "value": 8251,
        "NOCCode": "5254",
        "JobTitle": "tennis instructor – recreation"
    },
    {
        "value": 8252,
        "NOCCode": "5254",
        "JobTitle": "tennis instructor – sports"
    },
    {
        "value": 8253,
        "NOCCode": "5254",
        "JobTitle": "track and field instructor"
    },
    {
        "value": 8254,
        "NOCCode": "5254",
        "JobTitle": "vacation camp counsellor"
    },
    {
        "value": 8255,
        "NOCCode": "5254",
        "JobTitle": "vacation camp leader"
    },
    {
        "value": 8256,
        "NOCCode": "5254",
        "JobTitle": "weightlifting instructor"
    },
    {
        "value": 8257,
        "NOCCode": "5254",
        "JobTitle": "windsurfing instructor"
    },
    {
        "value": 8258,
        "NOCCode": "5254",
        "JobTitle": "yoga instructor"
    },
    {
        "value": 8259,
        "NOCCode": "6211",
        "JobTitle": "bakery supervisor – supermarket"
    },
    {
        "value": 8260,
        "NOCCode": "6211",
        "JobTitle": "cashier supervisor – retail"
    },
    {
        "value": 8261,
        "NOCCode": "6211",
        "JobTitle": "chief canvasser – retail"
    },
    {
        "value": 8262,
        "NOCCode": "6211",
        "JobTitle": "customer service supervisor – retail"
    },
    {
        "value": 8263,
        "NOCCode": "6211",
        "JobTitle": "delicatessen supervisor – food store"
    },
    {
        "value": 8264,
        "NOCCode": "6211",
        "JobTitle": "delivery person supervisor"
    },
    {
        "value": 8265,
        "NOCCode": "6211",
        "JobTitle": "department head, retail store"
    },
    {
        "value": 8266,
        "NOCCode": "6211",
        "JobTitle": "department store supervisor"
    },
    {
        "value": 8267,
        "NOCCode": "6211",
        "JobTitle": "department supervisor – retail"
    },
    {
        "value": 8268,
        "NOCCode": "6211",
        "JobTitle": "door-to-door sales supervisor"
    },
    {
        "value": 8269,
        "NOCCode": "6211",
        "JobTitle": "food store supervisor"
    },
    {
        "value": 8270,
        "NOCCode": "6211",
        "JobTitle": "grocery clerk supervisor"
    },
    {
        "value": 8271,
        "NOCCode": "6211",
        "JobTitle": "head cashier"
    },
    {
        "value": 8272,
        "NOCCode": "6211",
        "JobTitle": "liquor store supervisor"
    },
    {
        "value": 8273,
        "NOCCode": "6211",
        "JobTitle": "meat department supervisor"
    },
    {
        "value": 8274,
        "NOCCode": "6211",
        "JobTitle": "newspaper delivery persons supervisor"
    },
    {
        "value": 8275,
        "NOCCode": "6211",
        "JobTitle": "newspaper sales supervisor"
    },
    {
        "value": 8276,
        "NOCCode": "6211",
        "JobTitle": "newspaper salesperson supervisor"
    },
    {
        "value": 8277,
        "NOCCode": "6211",
        "JobTitle": "night crew leader – retail store"
    },
    {
        "value": 8278,
        "NOCCode": "6211",
        "JobTitle": "price marker supervisor – retail"
    },
    {
        "value": 8279,
        "NOCCode": "6211",
        "JobTitle": "produce department supervisor"
    },
    {
        "value": 8280,
        "NOCCode": "6211",
        "JobTitle": "rental service supervisor"
    },
    {
        "value": 8281,
        "NOCCode": "6211",
        "JobTitle": "retail store department head"
    },
    {
        "value": 8282,
        "NOCCode": "6211",
        "JobTitle": "retail store supervisor"
    },
    {
        "value": 8283,
        "NOCCode": "6211",
        "JobTitle": "route supervisor, retail sales"
    },
    {
        "value": 8284,
        "NOCCode": "6211",
        "JobTitle": "sales clerk supervisor"
    },
    {
        "value": 8285,
        "NOCCode": "6211",
        "JobTitle": "sales supervisor"
    },
    {
        "value": 8286,
        "NOCCode": "6211",
        "JobTitle": "sales supervisor – retail"
    },
    {
        "value": 8287,
        "NOCCode": "6211",
        "JobTitle": "stock clerks supervisor – retail"
    },
    {
        "value": 8288,
        "NOCCode": "6211",
        "JobTitle": "stock supervisor – retail"
    },
    {
        "value": 8289,
        "NOCCode": "6211",
        "JobTitle": "subscription sales team leader"
    },
    {
        "value": 8290,
        "NOCCode": "6211",
        "JobTitle": "supermarket department supervisor"
    },
    {
        "value": 8291,
        "NOCCode": "6211",
        "JobTitle": "supervisor – retail"
    },
    {
        "value": 8292,
        "NOCCode": "6211",
        "JobTitle": "supervisor, cashiers – retail"
    },
    {
        "value": 8293,
        "NOCCode": "6211",
        "JobTitle": "supervisor, delivery persons"
    },
    {
        "value": 8294,
        "NOCCode": "6211",
        "JobTitle": "supervisor, door-to-door salespersons – retail"
    },
    {
        "value": 8295,
        "NOCCode": "6211",
        "JobTitle": "supervisor, price markers – retail"
    },
    {
        "value": 8296,
        "NOCCode": "6211",
        "JobTitle": "supervisor, sales clerks"
    },
    {
        "value": 8297,
        "NOCCode": "6211",
        "JobTitle": "supervisor, stock clerks – retail"
    },
    {
        "value": 8298,
        "NOCCode": "6211",
        "JobTitle": "supervisor, telemarketers"
    },
    {
        "value": 8299,
        "NOCCode": "6211",
        "JobTitle": "supervisor, telephone sales clerks"
    },
    {
        "value": 8300,
        "NOCCode": "6211",
        "JobTitle": "team leader, subscription sales"
    },
    {
        "value": 8301,
        "NOCCode": "6211",
        "JobTitle": "telemarketer supervisor"
    },
    {
        "value": 8302,
        "NOCCode": "6211",
        "JobTitle": "telemarketing services supervisor"
    },
    {
        "value": 8303,
        "NOCCode": "6211",
        "JobTitle": "telemarketing supervisor"
    },
    {
        "value": 8304,
        "NOCCode": "6211",
        "JobTitle": "telephone sales clerk supervisor"
    },
    {
        "value": 8305,
        "NOCCode": "6221",
        "JobTitle": "agent, country grain elevator"
    },
    {
        "value": 8306,
        "NOCCode": "6221",
        "JobTitle": "agent, primary grain elevator"
    },
    {
        "value": 8307,
        "NOCCode": "6221",
        "JobTitle": "agricultural equipment and supplies technical salesperson"
    },
    {
        "value": 8308,
        "NOCCode": "6221",
        "JobTitle": "aircraft sales representative"
    },
    {
        "value": 8309,
        "NOCCode": "6221",
        "JobTitle": "aircraft technical sales specialist"
    },
    {
        "value": 8310,
        "NOCCode": "6221",
        "JobTitle": "alternate heating energy consultant"
    },
    {
        "value": 8311,
        "NOCCode": "6221",
        "JobTitle": "alternative heating energy consultant – wholesale"
    },
    {
        "value": 8312,
        "NOCCode": "6221",
        "JobTitle": "applications sales engineer"
    },
    {
        "value": 8313,
        "NOCCode": "6221",
        "JobTitle": "assistant grain elevator manager"
    },
    {
        "value": 8314,
        "NOCCode": "6221",
        "JobTitle": "broadcasting equipment salesperson"
    },
    {
        "value": 8315,
        "NOCCode": "6221",
        "JobTitle": "business agent, computers"
    },
    {
        "value": 8316,
        "NOCCode": "6221",
        "JobTitle": "chemicals sales representative"
    },
    {
        "value": 8317,
        "NOCCode": "6221",
        "JobTitle": "commercial and industrial equipment and supplies sales representative – technical wholesale"
    },
    {
        "value": 8318,
        "NOCCode": "6221",
        "JobTitle": "communication equipment sales representative"
    },
    {
        "value": 8319,
        "NOCCode": "6221",
        "JobTitle": "computer applications sales engineer"
    },
    {
        "value": 8320,
        "NOCCode": "6221",
        "JobTitle": "computer equipment sales specialist"
    },
    {
        "value": 8321,
        "NOCCode": "6221",
        "JobTitle": "computer software sales representative"
    },
    {
        "value": 8322,
        "NOCCode": "6221",
        "JobTitle": "computer system sales representative"
    },
    {
        "value": 8323,
        "NOCCode": "6221",
        "JobTitle": "construction equipment sales representative"
    },
    {
        "value": 8324,
        "NOCCode": "6221",
        "JobTitle": "construction material sales representative"
    },
    {
        "value": 8325,
        "NOCCode": "6221",
        "JobTitle": "country grain elevator agent"
    },
    {
        "value": 8326,
        "NOCCode": "6221",
        "JobTitle": "country grain elevator manager"
    },
    {
        "value": 8327,
        "NOCCode": "6221",
        "JobTitle": "country grain elevator operator"
    },
    {
        "value": 8328,
        "NOCCode": "6221",
        "JobTitle": "customer support representative – wholesale"
    },
    {
        "value": 8329,
        "NOCCode": "6221",
        "JobTitle": "dental equipment and supplies salesperson"
    },
    {
        "value": 8330,
        "NOCCode": "6221",
        "JobTitle": "diagnostic instrument sales representative"
    },
    {
        "value": 8331,
        "NOCCode": "6221",
        "JobTitle": "district manager, grain elevator"
    },
    {
        "value": 8332,
        "NOCCode": "6221",
        "JobTitle": "EDP (electronic data-processing) hardware and services sales representative"
    },
    {
        "value": 8333,
        "NOCCode": "6221",
        "JobTitle": "electrical equipment sales representative"
    },
    {
        "value": 8334,
        "NOCCode": "6221",
        "JobTitle": "electrical industrial equipment sales representative"
    },
    {
        "value": 8335,
        "NOCCode": "6221",
        "JobTitle": "electricity sales representative"
    },
    {
        "value": 8336,
        "NOCCode": "6221",
        "JobTitle": "electronic data-processing (EDP) hardware and services sales representative"
    },
    {
        "value": 8337,
        "NOCCode": "6221",
        "JobTitle": "electronic equipment salesperson – wholesale"
    },
    {
        "value": 8338,
        "NOCCode": "6221",
        "JobTitle": "engineering equipment salesperson"
    },
    {
        "value": 8339,
        "NOCCode": "6221",
        "JobTitle": "engineering supplies salesperson"
    },
    {
        "value": 8340,
        "NOCCode": "6221",
        "JobTitle": "export company technical sales specialist"
    },
    {
        "value": 8341,
        "NOCCode": "6221",
        "JobTitle": "farm equipment salesperson"
    },
    {
        "value": 8342,
        "NOCCode": "6221",
        "JobTitle": "farm machinery salesperson"
    },
    {
        "value": 8343,
        "NOCCode": "6221",
        "JobTitle": "forestry equipment sales representative"
    },
    {
        "value": 8344,
        "NOCCode": "6221",
        "JobTitle": "forestry machinery sales representative"
    },
    {
        "value": 8345,
        "NOCCode": "6221",
        "JobTitle": "grain elevator agent"
    },
    {
        "value": 8346,
        "NOCCode": "6221",
        "JobTitle": "grain elevator district manager"
    },
    {
        "value": 8347,
        "NOCCode": "6221",
        "JobTitle": "grain elevator manager"
    },
    {
        "value": 8348,
        "NOCCode": "6221",
        "JobTitle": "graphic arts equipment sales representative"
    },
    {
        "value": 8349,
        "NOCCode": "6221",
        "JobTitle": "heavy equipment sales representative"
    },
    {
        "value": 8350,
        "NOCCode": "6221",
        "JobTitle": "heavy equipment salesperson"
    },
    {
        "value": 8351,
        "NOCCode": "6221",
        "JobTitle": "heavy truck sales representative"
    },
    {
        "value": 8352,
        "NOCCode": "6221",
        "JobTitle": "heavy truck salesperson"
    },
    {
        "value": 8353,
        "NOCCode": "6221",
        "JobTitle": "hospital equipment sales representative"
    },
    {
        "value": 8354,
        "NOCCode": "6221",
        "JobTitle": "hospital equipment salesperson"
    },
    {
        "value": 8355,
        "NOCCode": "6221",
        "JobTitle": "hydro-electricity sales representative"
    },
    {
        "value": 8356,
        "NOCCode": "6221",
        "JobTitle": "import company technical sales specialist"
    },
    {
        "value": 8357,
        "NOCCode": "6221",
        "JobTitle": "industrial equipment sales engineer"
    },
    {
        "value": 8358,
        "NOCCode": "6221",
        "JobTitle": "industrial machinery sales representative"
    },
    {
        "value": 8359,
        "NOCCode": "6221",
        "JobTitle": "industrial supplies sales representative"
    },
    {
        "value": 8360,
        "NOCCode": "6221",
        "JobTitle": "industrial supplies salesperson"
    },
    {
        "value": 8361,
        "NOCCode": "6221",
        "JobTitle": "light, heat and power sales engineer"
    },
    {
        "value": 8362,
        "NOCCode": "6221",
        "JobTitle": "lighting, heating and power sales representative"
    },
    {
        "value": 8363,
        "NOCCode": "6221",
        "JobTitle": "lighting, heating and power sales specialist"
    },
    {
        "value": 8364,
        "NOCCode": "6221",
        "JobTitle": "logging equipment salesperson"
    },
    {
        "value": 8365,
        "NOCCode": "6221",
        "JobTitle": "lubricating equipment salesperson"
    },
    {
        "value": 8366,
        "NOCCode": "6221",
        "JobTitle": "machinery salesperson"
    },
    {
        "value": 8367,
        "NOCCode": "6221",
        "JobTitle": "manager, grain elevator"
    },
    {
        "value": 8368,
        "NOCCode": "6221",
        "JobTitle": "manufacturer's sales representative"
    },
    {
        "value": 8369,
        "NOCCode": "6221",
        "JobTitle": "material-handling equipment salesperson"
    },
    {
        "value": 8370,
        "NOCCode": "6221",
        "JobTitle": "medical equipment and supplies salesperson"
    },
    {
        "value": 8371,
        "NOCCode": "6221",
        "JobTitle": "medical instruments sales agent"
    },
    {
        "value": 8372,
        "NOCCode": "6221",
        "JobTitle": "metal products salesperson"
    },
    {
        "value": 8373,
        "NOCCode": "6221",
        "JobTitle": "milking machine salesperson"
    },
    {
        "value": 8374,
        "NOCCode": "6221",
        "JobTitle": "mining equipment sales engineer"
    },
    {
        "value": 8375,
        "NOCCode": "6221",
        "JobTitle": "mining equipment sales representative"
    },
    {
        "value": 8376,
        "NOCCode": "6221",
        "JobTitle": "natural gas sales representative"
    },
    {
        "value": 8377,
        "NOCCode": "6221",
        "JobTitle": "office equipment salesperson – wholesale"
    },
    {
        "value": 8378,
        "NOCCode": "6221",
        "JobTitle": "oil refinery equipment sales representative"
    },
    {
        "value": 8379,
        "NOCCode": "6221",
        "JobTitle": "oil well services sales engineer"
    },
    {
        "value": 8380,
        "NOCCode": "6221",
        "JobTitle": "pharmaceutical sales representative"
    },
    {
        "value": 8381,
        "NOCCode": "6221",
        "JobTitle": "pharmaceutical salesperson"
    },
    {
        "value": 8382,
        "NOCCode": "6221",
        "JobTitle": "plumbing and heating services sales representative"
    },
    {
        "value": 8383,
        "NOCCode": "6221",
        "JobTitle": "primary grain elevator agent"
    },
    {
        "value": 8384,
        "NOCCode": "6221",
        "JobTitle": "primary grain elevator manager"
    },
    {
        "value": 8385,
        "NOCCode": "6221",
        "JobTitle": "primary grain elevator operator"
    },
    {
        "value": 8386,
        "NOCCode": "6221",
        "JobTitle": "printing machinery sales representative"
    },
    {
        "value": 8387,
        "NOCCode": "6221",
        "JobTitle": "printing management technician"
    },
    {
        "value": 8388,
        "NOCCode": "6221",
        "JobTitle": "printing supplies salesperson"
    },
    {
        "value": 8389,
        "NOCCode": "6221",
        "JobTitle": "railway equipment and supplies technical sales specialist"
    },
    {
        "value": 8390,
        "NOCCode": "6221",
        "JobTitle": "railway equipment and supplies technical salesperson"
    },
    {
        "value": 8391,
        "NOCCode": "6221",
        "JobTitle": "residential energy sales adviser"
    },
    {
        "value": 8392,
        "NOCCode": "6221",
        "JobTitle": "safety equipment salesperson"
    },
    {
        "value": 8393,
        "NOCCode": "6221",
        "JobTitle": "sale executive, technical sales"
    },
    {
        "value": 8394,
        "NOCCode": "6221",
        "JobTitle": "sales broker – wholesale"
    },
    {
        "value": 8395,
        "NOCCode": "6221",
        "JobTitle": "sales engineer, technical support"
    },
    {
        "value": 8396,
        "NOCCode": "6221",
        "JobTitle": "sales representative, construction materials"
    },
    {
        "value": 8397,
        "NOCCode": "6221",
        "JobTitle": "sales representative, lighting, heating and power"
    },
    {
        "value": 8398,
        "NOCCode": "6221",
        "JobTitle": "sales representative, technical office equipment"
    },
    {
        "value": 8399,
        "NOCCode": "6221",
        "JobTitle": "scientific instrument sales representative"
    },
    {
        "value": 8400,
        "NOCCode": "6221",
        "JobTitle": "software sales representative"
    },
    {
        "value": 8401,
        "NOCCode": "6221",
        "JobTitle": "solid fuel heating sales consultant"
    },
    {
        "value": 8402,
        "NOCCode": "6221",
        "JobTitle": "steel product sales representative"
    },
    {
        "value": 8403,
        "NOCCode": "6221",
        "JobTitle": "supervisor, technical salespersons"
    },
    {
        "value": 8404,
        "NOCCode": "6221",
        "JobTitle": "surgical supply salesperson"
    },
    {
        "value": 8405,
        "NOCCode": "6221",
        "JobTitle": "systems software support sales representative"
    },
    {
        "value": 8406,
        "NOCCode": "6221",
        "JobTitle": "technical business equipment salesperson – wholesale"
    },
    {
        "value": 8407,
        "NOCCode": "6221",
        "JobTitle": "technical office equipment sales representative"
    },
    {
        "value": 8408,
        "NOCCode": "6221",
        "JobTitle": "technical sales consultant"
    },
    {
        "value": 8409,
        "NOCCode": "6221",
        "JobTitle": "technical sales executive"
    },
    {
        "value": 8410,
        "NOCCode": "6221",
        "JobTitle": "technical sales representative"
    },
    {
        "value": 8411,
        "NOCCode": "6221",
        "JobTitle": "technical sales representative – wholesale"
    },
    {
        "value": 8412,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist"
    },
    {
        "value": 8413,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, aircraft"
    },
    {
        "value": 8414,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, chemicals"
    },
    {
        "value": 8415,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, exports"
    },
    {
        "value": 8416,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, imports"
    },
    {
        "value": 8417,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, metals"
    },
    {
        "value": 8418,
        "NOCCode": "6221",
        "JobTitle": "technical sales specialist, railway equipment and supplies"
    },
    {
        "value": 8419,
        "NOCCode": "6221",
        "JobTitle": "technical sales supervisor"
    },
    {
        "value": 8420,
        "NOCCode": "6221",
        "JobTitle": "technical salesperson – wholesale"
    },
    {
        "value": 8421,
        "NOCCode": "6221",
        "JobTitle": "technical salesperson, telecommunications"
    },
    {
        "value": 8422,
        "NOCCode": "6221",
        "JobTitle": "technical service sales consultant"
    },
    {
        "value": 8423,
        "NOCCode": "6221",
        "JobTitle": "technical support specialist – wholesale trade"
    },
    {
        "value": 8424,
        "NOCCode": "6221",
        "JobTitle": "telecommunications sales representative"
    },
    {
        "value": 8425,
        "NOCCode": "6221",
        "JobTitle": "telecommunications salesperson"
    },
    {
        "value": 8426,
        "NOCCode": "6221",
        "JobTitle": "textile machinery salesperson"
    },
    {
        "value": 8427,
        "NOCCode": "6221",
        "JobTitle": "topographical equipment salesperson"
    },
    {
        "value": 8428,
        "NOCCode": "6221",
        "JobTitle": "ultrasonic equipment salesperson"
    },
    {
        "value": 8429,
        "NOCCode": "6221",
        "JobTitle": "veterinary equipment and supplies salesperson"
    },
    {
        "value": 8430,
        "NOCCode": "6221",
        "JobTitle": "welding equipment salesperson"
    },
    {
        "value": 8431,
        "NOCCode": "6221",
        "JobTitle": "X-ray equipment salesperson"
    },
    {
        "value": 8432,
        "NOCCode": "6222",
        "JobTitle": "appliance buyer"
    },
    {
        "value": 8433,
        "NOCCode": "6222",
        "JobTitle": "assistant buyer"
    },
    {
        "value": 8434,
        "NOCCode": "6222",
        "JobTitle": "buyer – retail"
    },
    {
        "value": 8435,
        "NOCCode": "6222",
        "JobTitle": "buyer – wholesale"
    },
    {
        "value": 8436,
        "NOCCode": "6222",
        "JobTitle": "cattle buyer"
    },
    {
        "value": 8437,
        "NOCCode": "6222",
        "JobTitle": "chief buyer"
    },
    {
        "value": 8438,
        "NOCCode": "6222",
        "JobTitle": "chief buyer – retail or wholesale"
    },
    {
        "value": 8439,
        "NOCCode": "6222",
        "JobTitle": "clothing buyer"
    },
    {
        "value": 8440,
        "NOCCode": "6222",
        "JobTitle": "food buyer"
    },
    {
        "value": 8441,
        "NOCCode": "6222",
        "JobTitle": "fruit buyer"
    },
    {
        "value": 8442,
        "NOCCode": "6222",
        "JobTitle": "fur buyer"
    },
    {
        "value": 8443,
        "NOCCode": "6222",
        "JobTitle": "hardware buyer"
    },
    {
        "value": 8444,
        "NOCCode": "6222",
        "JobTitle": "hog buyer"
    },
    {
        "value": 8445,
        "NOCCode": "6222",
        "JobTitle": "livestock buyer"
    },
    {
        "value": 8446,
        "NOCCode": "6222",
        "JobTitle": "log buyer"
    },
    {
        "value": 8447,
        "NOCCode": "6222",
        "JobTitle": "lumber buyer"
    },
    {
        "value": 8448,
        "NOCCode": "6222",
        "JobTitle": "meat buyer"
    },
    {
        "value": 8449,
        "NOCCode": "6222",
        "JobTitle": "perishable food buyer"
    },
    {
        "value": 8450,
        "NOCCode": "6222",
        "JobTitle": "pharmaceuticals buyer"
    },
    {
        "value": 8451,
        "NOCCode": "6222",
        "JobTitle": "produce buyer"
    },
    {
        "value": 8452,
        "NOCCode": "6222",
        "JobTitle": "retail buyer"
    },
    {
        "value": 8453,
        "NOCCode": "6222",
        "JobTitle": "retail merchandiser"
    },
    {
        "value": 8454,
        "NOCCode": "6222",
        "JobTitle": "salvage buyer"
    },
    {
        "value": 8455,
        "NOCCode": "6222",
        "JobTitle": "scrap buyer"
    },
    {
        "value": 8456,
        "NOCCode": "6222",
        "JobTitle": "senior buyer – retail or wholesale"
    },
    {
        "value": 8457,
        "NOCCode": "6222",
        "JobTitle": "specialty food buyer"
    },
    {
        "value": 8458,
        "NOCCode": "6222",
        "JobTitle": "supervisor, buyers"
    },
    {
        "value": 8459,
        "NOCCode": "6222",
        "JobTitle": "taster and buyer, beverages"
    },
    {
        "value": 8460,
        "NOCCode": "6222",
        "JobTitle": "timber buyer"
    },
    {
        "value": 8461,
        "NOCCode": "6222",
        "JobTitle": "tobacco buyer"
    },
    {
        "value": 8462,
        "NOCCode": "6222",
        "JobTitle": "wholesale buyer"
    },
    {
        "value": 8463,
        "NOCCode": "6231",
        "JobTitle": "automobile insurance agent"
    },
    {
        "value": 8464,
        "NOCCode": "6231",
        "JobTitle": "automobile insurance salesman/woman"
    },
    {
        "value": 8465,
        "NOCCode": "6231",
        "JobTitle": "broker, insurance"
    },
    {
        "value": 8466,
        "NOCCode": "6231",
        "JobTitle": "district supervisor, insurance office"
    },
    {
        "value": 8467,
        "NOCCode": "6231",
        "JobTitle": "field agent – insurance"
    },
    {
        "value": 8468,
        "NOCCode": "6231",
        "JobTitle": "fire insurance agent"
    },
    {
        "value": 8469,
        "NOCCode": "6231",
        "JobTitle": "fire insurance salesman/woman"
    },
    {
        "value": 8470,
        "NOCCode": "6231",
        "JobTitle": "general insurance representative"
    },
    {
        "value": 8471,
        "NOCCode": "6231",
        "JobTitle": "group insurance representative"
    },
    {
        "value": 8472,
        "NOCCode": "6231",
        "JobTitle": "insurance agent"
    },
    {
        "value": 8473,
        "NOCCode": "6231",
        "JobTitle": "insurance broker"
    },
    {
        "value": 8474,
        "NOCCode": "6231",
        "JobTitle": "insurance sales agent"
    },
    {
        "value": 8475,
        "NOCCode": "6231",
        "JobTitle": "insurance sales representative"
    },
    {
        "value": 8476,
        "NOCCode": "6231",
        "JobTitle": "insurance sales supervisor"
    },
    {
        "value": 8477,
        "NOCCode": "6231",
        "JobTitle": "insurance salesman/woman"
    },
    {
        "value": 8478,
        "NOCCode": "6231",
        "JobTitle": "life insurance agent"
    },
    {
        "value": 8479,
        "NOCCode": "6231",
        "JobTitle": "life insurance representative"
    },
    {
        "value": 8480,
        "NOCCode": "6231",
        "JobTitle": "life insurance salesman/woman"
    },
    {
        "value": 8481,
        "NOCCode": "6231",
        "JobTitle": "sales agent, insurance"
    },
    {
        "value": 8482,
        "NOCCode": "6231",
        "JobTitle": "salesman/woman, insurance"
    },
    {
        "value": 8483,
        "NOCCode": "6231",
        "JobTitle": "supervisor, insurance agents"
    },
    {
        "value": 8484,
        "NOCCode": "6232",
        "JobTitle": "agent, real estate"
    },
    {
        "value": 8485,
        "NOCCode": "6232",
        "JobTitle": "associate broker – real estate"
    },
    {
        "value": 8486,
        "NOCCode": "6232",
        "JobTitle": "commercial real estate agent"
    },
    {
        "value": 8487,
        "NOCCode": "6232",
        "JobTitle": "commercial real estate salesperson"
    },
    {
        "value": 8488,
        "NOCCode": "6232",
        "JobTitle": "new home salesperson"
    },
    {
        "value": 8489,
        "NOCCode": "6232",
        "JobTitle": "real estate agency representative"
    },
    {
        "value": 8490,
        "NOCCode": "6232",
        "JobTitle": "real estate agent"
    },
    {
        "value": 8491,
        "NOCCode": "6232",
        "JobTitle": "real estate dealer"
    },
    {
        "value": 8492,
        "NOCCode": "6232",
        "JobTitle": "real estate sales consultant"
    },
    {
        "value": 8493,
        "NOCCode": "6232",
        "JobTitle": "real estate sales representative"
    },
    {
        "value": 8494,
        "NOCCode": "6232",
        "JobTitle": "real estate sales supervisor"
    },
    {
        "value": 8495,
        "NOCCode": "6232",
        "JobTitle": "real estate salesperson"
    },
    {
        "value": 8496,
        "NOCCode": "6232",
        "JobTitle": "residential real estate agent"
    },
    {
        "value": 8497,
        "NOCCode": "6232",
        "JobTitle": "residential real estate salesperson"
    },
    {
        "value": 8498,
        "NOCCode": "6232",
        "JobTitle": "sales representative, real estate"
    },
    {
        "value": 8499,
        "NOCCode": "6232",
        "JobTitle": "salesperson, new homes"
    },
    {
        "value": 8500,
        "NOCCode": "6232",
        "JobTitle": "salesperson, real estate"
    },
    {
        "value": 8501,
        "NOCCode": "6232",
        "JobTitle": "supervisor, real estate agents"
    },
    {
        "value": 8502,
        "NOCCode": "6235",
        "JobTitle": "adviser, loans"
    },
    {
        "value": 8503,
        "NOCCode": "6235",
        "JobTitle": "agent, loans"
    },
    {
        "value": 8504,
        "NOCCode": "6235",
        "JobTitle": "analyst, credit"
    },
    {
        "value": 8505,
        "NOCCode": "6235",
        "JobTitle": "analyst, loans"
    },
    {
        "value": 8506,
        "NOCCode": "6235",
        "JobTitle": "banking accounts officer"
    },
    {
        "value": 8507,
        "NOCCode": "6235",
        "JobTitle": "commercial banking accounts officer"
    },
    {
        "value": 8508,
        "NOCCode": "6235",
        "JobTitle": "consumer credit officer"
    },
    {
        "value": 8509,
        "NOCCode": "6235",
        "JobTitle": "consumer loans officer"
    },
    {
        "value": 8510,
        "NOCCode": "6235",
        "JobTitle": "counsellor, loans"
    },
    {
        "value": 8511,
        "NOCCode": "6235",
        "JobTitle": "credit analyst"
    },
    {
        "value": 8512,
        "NOCCode": "6235",
        "JobTitle": "credit investigation officer"
    },
    {
        "value": 8513,
        "NOCCode": "6235",
        "JobTitle": "credit investigator"
    },
    {
        "value": 8514,
        "NOCCode": "6235",
        "JobTitle": "credit officer"
    },
    {
        "value": 8515,
        "NOCCode": "6235",
        "JobTitle": "financial sales and service officer"
    },
    {
        "value": 8516,
        "NOCCode": "6235",
        "JobTitle": "financial sales and service supervisor"
    },
    {
        "value": 8517,
        "NOCCode": "6235",
        "JobTitle": "financial services officer"
    },
    {
        "value": 8518,
        "NOCCode": "6235",
        "JobTitle": "financial services representative"
    },
    {
        "value": 8519,
        "NOCCode": "6235",
        "JobTitle": "lending services officer"
    },
    {
        "value": 8520,
        "NOCCode": "6235",
        "JobTitle": "lending services trainee"
    },
    {
        "value": 8521,
        "NOCCode": "6235",
        "JobTitle": "loan inspector"
    },
    {
        "value": 8522,
        "NOCCode": "6235",
        "JobTitle": "loan officer"
    },
    {
        "value": 8523,
        "NOCCode": "6235",
        "JobTitle": "loans adviser"
    },
    {
        "value": 8524,
        "NOCCode": "6235",
        "JobTitle": "loans agent"
    },
    {
        "value": 8525,
        "NOCCode": "6235",
        "JobTitle": "loans analyst"
    },
    {
        "value": 8526,
        "NOCCode": "6235",
        "JobTitle": "loans counsellor"
    },
    {
        "value": 8527,
        "NOCCode": "6235",
        "JobTitle": "loans supervisor"
    },
    {
        "value": 8528,
        "NOCCode": "6235",
        "JobTitle": "merchant services specialist – financial"
    },
    {
        "value": 8529,
        "NOCCode": "6235",
        "JobTitle": "mortgage consultant"
    },
    {
        "value": 8530,
        "NOCCode": "6235",
        "JobTitle": "mortgage loans consultant"
    },
    {
        "value": 8531,
        "NOCCode": "6235",
        "JobTitle": "mortgage loans officer"
    },
    {
        "value": 8532,
        "NOCCode": "6235",
        "JobTitle": "mortgage officer"
    },
    {
        "value": 8533,
        "NOCCode": "6235",
        "JobTitle": "officer, banking accounts"
    },
    {
        "value": 8534,
        "NOCCode": "6235",
        "JobTitle": "officer, credit"
    },
    {
        "value": 8535,
        "NOCCode": "6235",
        "JobTitle": "officer, loans"
    },
    {
        "value": 8536,
        "NOCCode": "6235",
        "JobTitle": "officer, mortgage loans"
    },
    {
        "value": 8537,
        "NOCCode": "6235",
        "JobTitle": "personal banker"
    },
    {
        "value": 8538,
        "NOCCode": "6235",
        "JobTitle": "personal banking accounts officer"
    },
    {
        "value": 8539,
        "NOCCode": "6235",
        "JobTitle": "personal banking representative"
    },
    {
        "value": 8540,
        "NOCCode": "6235",
        "JobTitle": "personal financial services associate"
    },
    {
        "value": 8541,
        "NOCCode": "6235",
        "JobTitle": "personal financial services officer"
    },
    {
        "value": 8542,
        "NOCCode": "6235",
        "JobTitle": "personal loans officer"
    },
    {
        "value": 8543,
        "NOCCode": "6235",
        "JobTitle": "sales associate – personal banking"
    },
    {
        "value": 8544,
        "NOCCode": "6235",
        "JobTitle": "sales officer – financial"
    },
    {
        "value": 8545,
        "NOCCode": "6235",
        "JobTitle": "senior loans officer"
    },
    {
        "value": 8546,
        "NOCCode": "6235",
        "JobTitle": "student loans officer"
    },
    {
        "value": 8547,
        "NOCCode": "6311",
        "JobTitle": "cafeteria supervisor"
    },
    {
        "value": 8548,
        "NOCCode": "6311",
        "JobTitle": "canteen supervisor"
    },
    {
        "value": 8549,
        "NOCCode": "6311",
        "JobTitle": "catering supervisor"
    },
    {
        "value": 8550,
        "NOCCode": "6311",
        "JobTitle": "food assembly supervisor"
    },
    {
        "value": 8551,
        "NOCCode": "6311",
        "JobTitle": "food service supervisor"
    },
    {
        "value": 8552,
        "NOCCode": "6311",
        "JobTitle": "food service supervisor, hospital"
    },
    {
        "value": 8553,
        "NOCCode": "6311",
        "JobTitle": "hospital food service supervisor"
    },
    {
        "value": 8554,
        "NOCCode": "6311",
        "JobTitle": "railway dining car food service supervisor"
    },
    {
        "value": 8555,
        "NOCCode": "6311",
        "JobTitle": "shift manager, fast food restaurant"
    },
    {
        "value": 8556,
        "NOCCode": "6311",
        "JobTitle": "supervisor, food concession"
    },
    {
        "value": 8557,
        "NOCCode": "6311",
        "JobTitle": "supervisor, food services"
    },
    {
        "value": 8558,
        "NOCCode": "6311",
        "JobTitle": "swing manager, fast food restaurant"
    },
    {
        "value": 8559,
        "NOCCode": "6311",
        "JobTitle": "unit supervisor – food services"
    },
    {
        "value": 8560,
        "NOCCode": "6312",
        "JobTitle": "assistant executive housekeeper"
    },
    {
        "value": 8561,
        "NOCCode": "6312",
        "JobTitle": "assistant housekeeping manager"
    },
    {
        "value": 8562,
        "NOCCode": "6312",
        "JobTitle": "executive housekeeper"
    },
    {
        "value": 8563,
        "NOCCode": "6312",
        "JobTitle": "hospital executive housekeeper"
    },
    {
        "value": 8564,
        "NOCCode": "6312",
        "JobTitle": "hotel executive housekeeper"
    },
    {
        "value": 8565,
        "NOCCode": "6312",
        "JobTitle": "housekeeping director"
    },
    {
        "value": 8566,
        "NOCCode": "6312",
        "JobTitle": "housekeeping manager"
    },
    {
        "value": 8567,
        "NOCCode": "6313",
        "JobTitle": "airline sales and service agents supervisor"
    },
    {
        "value": 8568,
        "NOCCode": "6313",
        "JobTitle": "assistant passenger service co-ordinator – railway"
    },
    {
        "value": 8569,
        "NOCCode": "6313",
        "JobTitle": "baggage handler supervisor"
    },
    {
        "value": 8570,
        "NOCCode": "6313",
        "JobTitle": "baggage porter supervisor"
    },
    {
        "value": 8571,
        "NOCCode": "6313",
        "JobTitle": "bell captain"
    },
    {
        "value": 8572,
        "NOCCode": "6313",
        "JobTitle": "casino pit boss"
    },
    {
        "value": 8573,
        "NOCCode": "6313",
        "JobTitle": "casino section head"
    },
    {
        "value": 8574,
        "NOCCode": "6313",
        "JobTitle": "casino shift manager"
    },
    {
        "value": 8575,
        "NOCCode": "6313",
        "JobTitle": "dealers supervisor, casino"
    },
    {
        "value": 8576,
        "NOCCode": "6313",
        "JobTitle": "gaming tables supervisor"
    },
    {
        "value": 8577,
        "NOCCode": "6313",
        "JobTitle": "head baggage porter"
    },
    {
        "value": 8578,
        "NOCCode": "6313",
        "JobTitle": "head, hotel clerks"
    },
    {
        "value": 8579,
        "NOCCode": "6313",
        "JobTitle": "hotel clerk supervisor"
    },
    {
        "value": 8580,
        "NOCCode": "6313",
        "JobTitle": "hotel managing supervisor"
    },
    {
        "value": 8581,
        "NOCCode": "6313",
        "JobTitle": "lodging house managing supervisor"
    },
    {
        "value": 8582,
        "NOCCode": "6313",
        "JobTitle": "motel managing supervisor"
    },
    {
        "value": 8583,
        "NOCCode": "6313",
        "JobTitle": "night clerk supervisor – hotel"
    },
    {
        "value": 8584,
        "NOCCode": "6313",
        "JobTitle": "night supervisor – hotel"
    },
    {
        "value": 8585,
        "NOCCode": "6313",
        "JobTitle": "passenger service co-ordinator – railway"
    },
    {
        "value": 8586,
        "NOCCode": "6313",
        "JobTitle": "pit boss gaming"
    },
    {
        "value": 8587,
        "NOCCode": "6313",
        "JobTitle": "pit boss, casino"
    },
    {
        "value": 8588,
        "NOCCode": "6313",
        "JobTitle": "reservations supervisor"
    },
    {
        "value": 8589,
        "NOCCode": "6313",
        "JobTitle": "reservations supervisor – airline"
    },
    {
        "value": 8590,
        "NOCCode": "6313",
        "JobTitle": "reservations supervisor – hotel"
    },
    {
        "value": 8591,
        "NOCCode": "6313",
        "JobTitle": "reservations supervisor – travel agency"
    },
    {
        "value": 8592,
        "NOCCode": "6313",
        "JobTitle": "section head, casino"
    },
    {
        "value": 8593,
        "NOCCode": "6313",
        "JobTitle": "section head, slot machines"
    },
    {
        "value": 8594,
        "NOCCode": "6313",
        "JobTitle": "shift manager, casino"
    },
    {
        "value": 8595,
        "NOCCode": "6313",
        "JobTitle": "slot machines section head"
    },
    {
        "value": 8596,
        "NOCCode": "6313",
        "JobTitle": "slot supervisor"
    },
    {
        "value": 8597,
        "NOCCode": "6313",
        "JobTitle": "supervisor, airline sales and service agents"
    },
    {
        "value": 8598,
        "NOCCode": "6313",
        "JobTitle": "supervisor, baggage porters"
    },
    {
        "value": 8599,
        "NOCCode": "6313",
        "JobTitle": "supervisor, gaming tables"
    },
    {
        "value": 8600,
        "NOCCode": "6313",
        "JobTitle": "supervisor, hotel clerks"
    },
    {
        "value": 8601,
        "NOCCode": "6313",
        "JobTitle": "supervisor, night clerks – hotel"
    },
    {
        "value": 8602,
        "NOCCode": "6313",
        "JobTitle": "supervisor, railway station clerks"
    },
    {
        "value": 8603,
        "NOCCode": "6313",
        "JobTitle": "supervisor, reservations – hotel"
    },
    {
        "value": 8604,
        "NOCCode": "6313",
        "JobTitle": "supervisor, tour guides"
    },
    {
        "value": 8605,
        "NOCCode": "6313",
        "JobTitle": "supervisor, travel clerks"
    },
    {
        "value": 8606,
        "NOCCode": "6313",
        "JobTitle": "tour guide supervisor"
    },
    {
        "value": 8607,
        "NOCCode": "6313",
        "JobTitle": "travel clerk supervisor"
    },
    {
        "value": 8608,
        "NOCCode": "6314",
        "JobTitle": "accounts supervisor"
    },
    {
        "value": 8609,
        "NOCCode": "6314",
        "JobTitle": "call centre supervisor"
    },
    {
        "value": 8610,
        "NOCCode": "6314",
        "JobTitle": "contact centre supervisor"
    },
    {
        "value": 8611,
        "NOCCode": "6314",
        "JobTitle": "current accounts supervisor"
    },
    {
        "value": 8612,
        "NOCCode": "6314",
        "JobTitle": "customer accounts supervisor"
    },
    {
        "value": 8613,
        "NOCCode": "6314",
        "JobTitle": "customer service clerks supervisor"
    },
    {
        "value": 8614,
        "NOCCode": "6314",
        "JobTitle": "customer service representatives supervisor – financial services"
    },
    {
        "value": 8615,
        "NOCCode": "6314",
        "JobTitle": "customer service supervisor (except financial services)"
    },
    {
        "value": 8616,
        "NOCCode": "6314",
        "JobTitle": "information clerks supervisor"
    },
    {
        "value": 8617,
        "NOCCode": "6314",
        "JobTitle": "safety deposit box supervisor"
    },
    {
        "value": 8618,
        "NOCCode": "6314",
        "JobTitle": "savings accounts supervisor"
    },
    {
        "value": 8619,
        "NOCCode": "6314",
        "JobTitle": "savings and credit service supervisor"
    },
    {
        "value": 8620,
        "NOCCode": "6314",
        "JobTitle": "savings supervisor"
    },
    {
        "value": 8621,
        "NOCCode": "6314",
        "JobTitle": "supervisor, accounts"
    },
    {
        "value": 8622,
        "NOCCode": "6314",
        "JobTitle": "supervisor, call centre agents"
    },
    {
        "value": 8623,
        "NOCCode": "6314",
        "JobTitle": "supervisor, customer service clerks"
    },
    {
        "value": 8624,
        "NOCCode": "6314",
        "JobTitle": "supervisor, customer service representatives"
    },
    {
        "value": 8625,
        "NOCCode": "6314",
        "JobTitle": "supervisor, customer service representatives – financial services"
    },
    {
        "value": 8626,
        "NOCCode": "6314",
        "JobTitle": "supervisor, information clerks"
    },
    {
        "value": 8627,
        "NOCCode": "6314",
        "JobTitle": "supervisor, tellers"
    },
    {
        "value": 8628,
        "NOCCode": "6314",
        "JobTitle": "tellers supervisor"
    },
    {
        "value": 8629,
        "NOCCode": "6315",
        "JobTitle": "building cleaning and maintenance supervisor"
    },
    {
        "value": 8630,
        "NOCCode": "6315",
        "JobTitle": "building cleaning supervisor"
    },
    {
        "value": 8631,
        "NOCCode": "6315",
        "JobTitle": "carpet cleaning supervisor"
    },
    {
        "value": 8632,
        "NOCCode": "6315",
        "JobTitle": "carwash supervisor"
    },
    {
        "value": 8633,
        "NOCCode": "6315",
        "JobTitle": "cleaning and maintenance supervisor"
    },
    {
        "value": 8634,
        "NOCCode": "6315",
        "JobTitle": "cleaning supervisor"
    },
    {
        "value": 8635,
        "NOCCode": "6315",
        "JobTitle": "custodian supervisor – cleaning services"
    },
    {
        "value": 8636,
        "NOCCode": "6315",
        "JobTitle": "head caretaker"
    },
    {
        "value": 8637,
        "NOCCode": "6315",
        "JobTitle": "head cleaner"
    },
    {
        "value": 8638,
        "NOCCode": "6315",
        "JobTitle": "head cleaning man/woman"
    },
    {
        "value": 8639,
        "NOCCode": "6315",
        "JobTitle": "head custodian"
    },
    {
        "value": 8640,
        "NOCCode": "6315",
        "JobTitle": "head custodian – cleaning services"
    },
    {
        "value": 8641,
        "NOCCode": "6315",
        "JobTitle": "head janitor"
    },
    {
        "value": 8642,
        "NOCCode": "6315",
        "JobTitle": "housekeeping room attendant supervisor"
    },
    {
        "value": 8643,
        "NOCCode": "6315",
        "JobTitle": "housekeeping supervisor"
    },
    {
        "value": 8644,
        "NOCCode": "6315",
        "JobTitle": "industrial plant cleaning supervisor"
    },
    {
        "value": 8645,
        "NOCCode": "6315",
        "JobTitle": "supervisor, carwash"
    },
    {
        "value": 8646,
        "NOCCode": "6315",
        "JobTitle": "supervisor, chambermaids"
    },
    {
        "value": 8647,
        "NOCCode": "6315",
        "JobTitle": "supervisor, cleaners"
    },
    {
        "value": 8648,
        "NOCCode": "6315",
        "JobTitle": "supervisor, cleaning and maintenance"
    },
    {
        "value": 8649,
        "NOCCode": "6315",
        "JobTitle": "supervisor, janitors"
    },
    {
        "value": 8650,
        "NOCCode": "6315",
        "JobTitle": "supervisor, window washers"
    },
    {
        "value": 8651,
        "NOCCode": "6315",
        "JobTitle": "window washing supervisor"
    },
    {
        "value": 8652,
        "NOCCode": "6316",
        "JobTitle": "apparel-service and furnishings-service workers supervisor"
    },
    {
        "value": 8653,
        "NOCCode": "6316",
        "JobTitle": "billiard parlour supervisor"
    },
    {
        "value": 8654,
        "NOCCode": "6316",
        "JobTitle": "bingo games supervisor"
    },
    {
        "value": 8655,
        "NOCCode": "6316",
        "JobTitle": "campground supervisor"
    },
    {
        "value": 8656,
        "NOCCode": "6316",
        "JobTitle": "chief security guard"
    },
    {
        "value": 8657,
        "NOCCode": "6316",
        "JobTitle": "commercial security officers supervisor"
    },
    {
        "value": 8658,
        "NOCCode": "6316",
        "JobTitle": "dry cleaner supervisor"
    },
    {
        "value": 8659,
        "NOCCode": "6316",
        "JobTitle": "dry cleaning supervisor"
    },
    {
        "value": 8660,
        "NOCCode": "6316",
        "JobTitle": "foreman/forewoman, laundry and dry cleaning workers and press operators"
    },
    {
        "value": 8661,
        "NOCCode": "6316",
        "JobTitle": "foreman/woman – laundry and dry cleaning"
    },
    {
        "value": 8662,
        "NOCCode": "6316",
        "JobTitle": "foreman/woman, launderers, dry cleaners and press operators"
    },
    {
        "value": 8663,
        "NOCCode": "6316",
        "JobTitle": "fur cleaning supervisor"
    },
    {
        "value": 8664,
        "NOCCode": "6316",
        "JobTitle": "ice rink supervisor"
    },
    {
        "value": 8665,
        "NOCCode": "6316",
        "JobTitle": "launderers supervisor"
    },
    {
        "value": 8666,
        "NOCCode": "6316",
        "JobTitle": "launderers, dry cleaners and press operators foreman/woman"
    },
    {
        "value": 8667,
        "NOCCode": "6316",
        "JobTitle": "laundry and dry cleaning foreman/woman"
    },
    {
        "value": 8668,
        "NOCCode": "6316",
        "JobTitle": "laundry and dry cleaning workers and press operators foreman/woman"
    },
    {
        "value": 8669,
        "NOCCode": "6316",
        "JobTitle": "laundry production supervisor"
    },
    {
        "value": 8670,
        "NOCCode": "6316",
        "JobTitle": "laundry supervisor"
    },
    {
        "value": 8671,
        "NOCCode": "6316",
        "JobTitle": "lift operation supervisor"
    },
    {
        "value": 8672,
        "NOCCode": "6316",
        "JobTitle": "movie theatre attendants supervisor"
    },
    {
        "value": 8673,
        "NOCCode": "6316",
        "JobTitle": "park supervisor"
    },
    {
        "value": 8674,
        "NOCCode": "6316",
        "JobTitle": "parking lot supervisor"
    },
    {
        "value": 8675,
        "NOCCode": "6316",
        "JobTitle": "pool parlour supervisor"
    },
    {
        "value": 8676,
        "NOCCode": "6316",
        "JobTitle": "production supervisor, dry cleaning"
    },
    {
        "value": 8677,
        "NOCCode": "6316",
        "JobTitle": "racetrack betting supervisor"
    },
    {
        "value": 8678,
        "NOCCode": "6316",
        "JobTitle": "racetrack clerk supervisor"
    },
    {
        "value": 8679,
        "NOCCode": "6316",
        "JobTitle": "rink supervisor"
    },
    {
        "value": 8680,
        "NOCCode": "6316",
        "JobTitle": "rug cleaning foreman/woman – dry cleaning"
    },
    {
        "value": 8681,
        "NOCCode": "6316",
        "JobTitle": "security guard supervisor"
    },
    {
        "value": 8682,
        "NOCCode": "6316",
        "JobTitle": "ski area supervisor"
    },
    {
        "value": 8683,
        "NOCCode": "6316",
        "JobTitle": "ski lift attendant supervisor"
    },
    {
        "value": 8684,
        "NOCCode": "6316",
        "JobTitle": "ski lift supervisor"
    },
    {
        "value": 8685,
        "NOCCode": "6316",
        "JobTitle": "supervisor, apparel and furnishings service workers"
    },
    {
        "value": 8686,
        "NOCCode": "6316",
        "JobTitle": "supervisor, athletic club attendants"
    },
    {
        "value": 8687,
        "NOCCode": "6316",
        "JobTitle": "supervisor, billiard parlour"
    },
    {
        "value": 8688,
        "NOCCode": "6316",
        "JobTitle": "supervisor, clubhouse attendants"
    },
    {
        "value": 8689,
        "NOCCode": "6316",
        "JobTitle": "supervisor, commercial security officers"
    },
    {
        "value": 8690,
        "NOCCode": "6316",
        "JobTitle": "supervisor, commissionaires"
    },
    {
        "value": 8691,
        "NOCCode": "6316",
        "JobTitle": "supervisor, dry cleaners"
    },
    {
        "value": 8692,
        "NOCCode": "6316",
        "JobTitle": "supervisor, dry cleaning"
    },
    {
        "value": 8693,
        "NOCCode": "6316",
        "JobTitle": "supervisor, ice rink"
    },
    {
        "value": 8694,
        "NOCCode": "6316",
        "JobTitle": "supervisor, launderers"
    },
    {
        "value": 8695,
        "NOCCode": "6316",
        "JobTitle": "supervisor, laundry"
    },
    {
        "value": 8696,
        "NOCCode": "6316",
        "JobTitle": "supervisor, lift operations"
    },
    {
        "value": 8697,
        "NOCCode": "6316",
        "JobTitle": "supervisor, park"
    },
    {
        "value": 8698,
        "NOCCode": "6316",
        "JobTitle": "supervisor, parking lot"
    },
    {
        "value": 8699,
        "NOCCode": "6316",
        "JobTitle": "supervisor, pool parlour"
    },
    {
        "value": 8700,
        "NOCCode": "6316",
        "JobTitle": "supervisor, rink"
    },
    {
        "value": 8701,
        "NOCCode": "6316",
        "JobTitle": "supervisor, security guards"
    },
    {
        "value": 8702,
        "NOCCode": "6316",
        "JobTitle": "supervisor, ski lift attendants"
    },
    {
        "value": 8703,
        "NOCCode": "6316",
        "JobTitle": "supervisor, theatre attendants"
    },
    {
        "value": 8704,
        "NOCCode": "6316",
        "JobTitle": "supervisor, ticket clerks"
    },
    {
        "value": 8705,
        "NOCCode": "6316",
        "JobTitle": "supervisor, ticket sellers"
    },
    {
        "value": 8706,
        "NOCCode": "6316",
        "JobTitle": "supervisor, ticket takers"
    },
    {
        "value": 8707,
        "NOCCode": "6316",
        "JobTitle": "supervisor, toll bridge attendants"
    },
    {
        "value": 8708,
        "NOCCode": "6316",
        "JobTitle": "ticket clerk supervisor"
    },
    {
        "value": 8709,
        "NOCCode": "6316",
        "JobTitle": "ticket seller supervisor"
    },
    {
        "value": 8710,
        "NOCCode": "6316",
        "JobTitle": "ticket taker supervisor"
    },
    {
        "value": 8711,
        "NOCCode": "6316",
        "JobTitle": "toll bridge attendants supervisor"
    },
    {
        "value": 8712,
        "NOCCode": "6316",
        "JobTitle": "trailer park supervisor"
    },
    {
        "value": 8713,
        "NOCCode": "6321",
        "JobTitle": "assistant chef"
    },
    {
        "value": 8714,
        "NOCCode": "6321",
        "JobTitle": "banquet chef"
    },
    {
        "value": 8715,
        "NOCCode": "6321",
        "JobTitle": "chef"
    },
    {
        "value": 8716,
        "NOCCode": "6321",
        "JobTitle": "chef de cuisine"
    },
    {
        "value": 8717,
        "NOCCode": "6321",
        "JobTitle": "chef de partie"
    },
    {
        "value": 8718,
        "NOCCode": "6321",
        "JobTitle": "chef pâtissier"
    },
    {
        "value": 8719,
        "NOCCode": "6321",
        "JobTitle": "cold foods chef"
    },
    {
        "value": 8720,
        "NOCCode": "6321",
        "JobTitle": "corporate chef"
    },
    {
        "value": 8721,
        "NOCCode": "6321",
        "JobTitle": "entremetier"
    },
    {
        "value": 8722,
        "NOCCode": "6321",
        "JobTitle": "executive chef"
    },
    {
        "value": 8723,
        "NOCCode": "6321",
        "JobTitle": "executive sous-chef"
    },
    {
        "value": 8724,
        "NOCCode": "6321",
        "JobTitle": "first sous-chef"
    },
    {
        "value": 8725,
        "NOCCode": "6321",
        "JobTitle": "garde-manger chef"
    },
    {
        "value": 8726,
        "NOCCode": "6321",
        "JobTitle": "head chef"
    },
    {
        "value": 8727,
        "NOCCode": "6321",
        "JobTitle": "head rotisseur"
    },
    {
        "value": 8728,
        "NOCCode": "6321",
        "JobTitle": "master chef"
    },
    {
        "value": 8729,
        "NOCCode": "6321",
        "JobTitle": "meat chef"
    },
    {
        "value": 8730,
        "NOCCode": "6321",
        "JobTitle": "meat, poultry and fish chef"
    },
    {
        "value": 8731,
        "NOCCode": "6321",
        "JobTitle": "pasta chef"
    },
    {
        "value": 8732,
        "NOCCode": "6321",
        "JobTitle": "pastry chef"
    },
    {
        "value": 8733,
        "NOCCode": "6321",
        "JobTitle": "rotisserie chef"
    },
    {
        "value": 8734,
        "NOCCode": "6321",
        "JobTitle": "saucier"
    },
    {
        "value": 8735,
        "NOCCode": "6321",
        "JobTitle": "second chef"
    },
    {
        "value": 8736,
        "NOCCode": "6321",
        "JobTitle": "sous-chef"
    },
    {
        "value": 8737,
        "NOCCode": "6321",
        "JobTitle": "specialist chef"
    },
    {
        "value": 8738,
        "NOCCode": "6321",
        "JobTitle": "specialty foods chef"
    },
    {
        "value": 8739,
        "NOCCode": "6321",
        "JobTitle": "supervising chef"
    },
    {
        "value": 8740,
        "NOCCode": "6321",
        "JobTitle": "sushi chef"
    },
    {
        "value": 8741,
        "NOCCode": "6321",
        "JobTitle": "working sous-chef"
    },
    {
        "value": 8742,
        "NOCCode": "6322",
        "JobTitle": "apprentice cook"
    },
    {
        "value": 8743,
        "NOCCode": "6322",
        "JobTitle": "assistant cook"
    },
    {
        "value": 8744,
        "NOCCode": "6322",
        "JobTitle": "banquet cook"
    },
    {
        "value": 8745,
        "NOCCode": "6322",
        "JobTitle": "breakfast cook"
    },
    {
        "value": 8746,
        "NOCCode": "6322",
        "JobTitle": "broiler cook"
    },
    {
        "value": 8747,
        "NOCCode": "6322",
        "JobTitle": "cafeteria cook"
    },
    {
        "value": 8748,
        "NOCCode": "6322",
        "JobTitle": "camp cook"
    },
    {
        "value": 8749,
        "NOCCode": "6322",
        "JobTitle": "caterer cook"
    },
    {
        "value": 8750,
        "NOCCode": "6322",
        "JobTitle": "construction camp cook"
    },
    {
        "value": 8751,
        "NOCCode": "6322",
        "JobTitle": "cook"
    },
    {
        "value": 8752,
        "NOCCode": "6322",
        "JobTitle": "cook, apprentice"
    },
    {
        "value": 8753,
        "NOCCode": "6322",
        "JobTitle": "cook, camp"
    },
    {
        "value": 8754,
        "NOCCode": "6322",
        "JobTitle": "cook, domestic"
    },
    {
        "value": 8755,
        "NOCCode": "6322",
        "JobTitle": "cook, ethnic foods"
    },
    {
        "value": 8756,
        "NOCCode": "6322",
        "JobTitle": "cook, first"
    },
    {
        "value": 8757,
        "NOCCode": "6322",
        "JobTitle": "cook, fishing and merchant vessel"
    },
    {
        "value": 8758,
        "NOCCode": "6322",
        "JobTitle": "cook, institution"
    },
    {
        "value": 8759,
        "NOCCode": "6322",
        "JobTitle": "cook, kosher foods"
    },
    {
        "value": 8760,
        "NOCCode": "6322",
        "JobTitle": "cook, second"
    },
    {
        "value": 8761,
        "NOCCode": "6322",
        "JobTitle": "cook, small establishment"
    },
    {
        "value": 8762,
        "NOCCode": "6322",
        "JobTitle": "cook, third"
    },
    {
        "value": 8763,
        "NOCCode": "6322",
        "JobTitle": "diet kitchen cook"
    },
    {
        "value": 8764,
        "NOCCode": "6322",
        "JobTitle": "dietary cook"
    },
    {
        "value": 8765,
        "NOCCode": "6322",
        "JobTitle": "domestic cook"
    },
    {
        "value": 8766,
        "NOCCode": "6322",
        "JobTitle": "ethnic food cook"
    },
    {
        "value": 8767,
        "NOCCode": "6322",
        "JobTitle": "first cook"
    },
    {
        "value": 8768,
        "NOCCode": "6322",
        "JobTitle": "grill cook"
    },
    {
        "value": 8769,
        "NOCCode": "6322",
        "JobTitle": "hospital cook"
    },
    {
        "value": 8770,
        "NOCCode": "6322",
        "JobTitle": "institutional cook"
    },
    {
        "value": 8771,
        "NOCCode": "6322",
        "JobTitle": "journeyman/woman cook"
    },
    {
        "value": 8772,
        "NOCCode": "6322",
        "JobTitle": "kosher foods cook"
    },
    {
        "value": 8773,
        "NOCCode": "6322",
        "JobTitle": "licensed cook"
    },
    {
        "value": 8774,
        "NOCCode": "6322",
        "JobTitle": "line cook"
    },
    {
        "value": 8775,
        "NOCCode": "6322",
        "JobTitle": "logging camp cook"
    },
    {
        "value": 8776,
        "NOCCode": "6322",
        "JobTitle": "mess cook"
    },
    {
        "value": 8777,
        "NOCCode": "6322",
        "JobTitle": "pastry cook"
    },
    {
        "value": 8778,
        "NOCCode": "6322",
        "JobTitle": "pizza cook"
    },
    {
        "value": 8779,
        "NOCCode": "6322",
        "JobTitle": "railway cook"
    },
    {
        "value": 8780,
        "NOCCode": "6322",
        "JobTitle": "restaurant cook"
    },
    {
        "value": 8781,
        "NOCCode": "6322",
        "JobTitle": "second cook"
    },
    {
        "value": 8782,
        "NOCCode": "6322",
        "JobTitle": "ship's cook"
    },
    {
        "value": 8783,
        "NOCCode": "6322",
        "JobTitle": "short order cook"
    },
    {
        "value": 8784,
        "NOCCode": "6322",
        "JobTitle": "special diet cook"
    },
    {
        "value": 8785,
        "NOCCode": "6322",
        "JobTitle": "special orders cook, hospital"
    },
    {
        "value": 8786,
        "NOCCode": "6322",
        "JobTitle": "therapeutic diet cook"
    },
    {
        "value": 8787,
        "NOCCode": "6322",
        "JobTitle": "third cook"
    },
    {
        "value": 8788,
        "NOCCode": "6331",
        "JobTitle": "apprentice butcher – retail or wholesale"
    },
    {
        "value": 8789,
        "NOCCode": "6331",
        "JobTitle": "butcher – butcher shop"
    },
    {
        "value": 8790,
        "NOCCode": "6331",
        "JobTitle": "butcher – delicatessen"
    },
    {
        "value": 8791,
        "NOCCode": "6331",
        "JobTitle": "butcher – retail or wholesale"
    },
    {
        "value": 8792,
        "NOCCode": "6331",
        "JobTitle": "butcher – wholesale"
    },
    {
        "value": 8793,
        "NOCCode": "6331",
        "JobTitle": "butcher apprentice"
    },
    {
        "value": 8794,
        "NOCCode": "6331",
        "JobTitle": "butcher, retail"
    },
    {
        "value": 8795,
        "NOCCode": "6331",
        "JobTitle": "fishmonger – retail"
    },
    {
        "value": 8796,
        "NOCCode": "6331",
        "JobTitle": "fishmonger – wholesale"
    },
    {
        "value": 8797,
        "NOCCode": "6331",
        "JobTitle": "grocery store butcher"
    },
    {
        "value": 8798,
        "NOCCode": "6331",
        "JobTitle": "head butcher – retail"
    },
    {
        "value": 8799,
        "NOCCode": "6331",
        "JobTitle": "head butcher – retail or wholesale"
    },
    {
        "value": 8800,
        "NOCCode": "6331",
        "JobTitle": "head butcher – wholesale"
    },
    {
        "value": 8801,
        "NOCCode": "6331",
        "JobTitle": "meat cutter – retail or wholesale"
    },
    {
        "value": 8802,
        "NOCCode": "6331",
        "JobTitle": "meat portion cutter – retail or wholesale"
    },
    {
        "value": 8803,
        "NOCCode": "6331",
        "JobTitle": "pork cutter – retail or wholesale"
    },
    {
        "value": 8804,
        "NOCCode": "6331",
        "JobTitle": "retail butcher"
    },
    {
        "value": 8805,
        "NOCCode": "6331",
        "JobTitle": "retail meat cutter"
    },
    {
        "value": 8806,
        "NOCCode": "6331",
        "JobTitle": "supermarket butcher"
    },
    {
        "value": 8807,
        "NOCCode": "6331",
        "JobTitle": "supermarket meat cutter"
    },
    {
        "value": 8808,
        "NOCCode": "6332",
        "JobTitle": "apprentice baker – retail"
    },
    {
        "value": 8809,
        "NOCCode": "6332",
        "JobTitle": "bagel baker"
    },
    {
        "value": 8810,
        "NOCCode": "6332",
        "JobTitle": "bagel maker"
    },
    {
        "value": 8811,
        "NOCCode": "6332",
        "JobTitle": "baker"
    },
    {
        "value": 8812,
        "NOCCode": "6332",
        "JobTitle": "baker – retail"
    },
    {
        "value": 8813,
        "NOCCode": "6332",
        "JobTitle": "baker apprentice"
    },
    {
        "value": 8814,
        "NOCCode": "6332",
        "JobTitle": "baker, pâtissier"
    },
    {
        "value": 8815,
        "NOCCode": "6332",
        "JobTitle": "baker, specialty foods"
    },
    {
        "value": 8816,
        "NOCCode": "6332",
        "JobTitle": "baker, specialty foods – retail"
    },
    {
        "value": 8817,
        "NOCCode": "6332",
        "JobTitle": "bakery bench hand"
    },
    {
        "value": 8818,
        "NOCCode": "6332",
        "JobTitle": "bakery supervisor"
    },
    {
        "value": 8819,
        "NOCCode": "6332",
        "JobTitle": "bakery supervisor – retail"
    },
    {
        "value": 8820,
        "NOCCode": "6332",
        "JobTitle": "bench hand, bakery"
    },
    {
        "value": 8821,
        "NOCCode": "6332",
        "JobTitle": "biscuit baker"
    },
    {
        "value": 8822,
        "NOCCode": "6332",
        "JobTitle": "bread baker"
    },
    {
        "value": 8823,
        "NOCCode": "6332",
        "JobTitle": "bread baker – retail"
    },
    {
        "value": 8824,
        "NOCCode": "6332",
        "JobTitle": "cake and pastry decorator – retail"
    },
    {
        "value": 8825,
        "NOCCode": "6332",
        "JobTitle": "cake baker – retail"
    },
    {
        "value": 8826,
        "NOCCode": "6332",
        "JobTitle": "cake decorator"
    },
    {
        "value": 8827,
        "NOCCode": "6332",
        "JobTitle": "cake decorator – retail"
    },
    {
        "value": 8828,
        "NOCCode": "6332",
        "JobTitle": "cake icer"
    },
    {
        "value": 8829,
        "NOCCode": "6332",
        "JobTitle": "donut baker"
    },
    {
        "value": 8830,
        "NOCCode": "6332",
        "JobTitle": "donut maker"
    },
    {
        "value": 8831,
        "NOCCode": "6332",
        "JobTitle": "French pastry baker – retail"
    },
    {
        "value": 8832,
        "NOCCode": "6332",
        "JobTitle": "grocery store baker"
    },
    {
        "value": 8833,
        "NOCCode": "6332",
        "JobTitle": "hand icer – bakery"
    },
    {
        "value": 8834,
        "NOCCode": "6332",
        "JobTitle": "head baker"
    },
    {
        "value": 8835,
        "NOCCode": "6332",
        "JobTitle": "head baker – retail"
    },
    {
        "value": 8836,
        "NOCCode": "6332",
        "JobTitle": "hospital baker"
    },
    {
        "value": 8837,
        "NOCCode": "6332",
        "JobTitle": "hotel baker"
    },
    {
        "value": 8838,
        "NOCCode": "6332",
        "JobTitle": "icer, hand – bakery"
    },
    {
        "value": 8839,
        "NOCCode": "6332",
        "JobTitle": "junior baker"
    },
    {
        "value": 8840,
        "NOCCode": "6332",
        "JobTitle": "muffin baker"
    },
    {
        "value": 8841,
        "NOCCode": "6332",
        "JobTitle": "muffin maker"
    },
    {
        "value": 8842,
        "NOCCode": "6332",
        "JobTitle": "pastry decorator"
    },
    {
        "value": 8843,
        "NOCCode": "6332",
        "JobTitle": "pastry decorator – retail"
    },
    {
        "value": 8844,
        "NOCCode": "6332",
        "JobTitle": "pastry maker"
    },
    {
        "value": 8845,
        "NOCCode": "6332",
        "JobTitle": "pâtissier, baker"
    },
    {
        "value": 8846,
        "NOCCode": "6332",
        "JobTitle": "specialty foods baker"
    },
    {
        "value": 8847,
        "NOCCode": "6332",
        "JobTitle": "specialty foods baker – retail"
    },
    {
        "value": 8848,
        "NOCCode": "6332",
        "JobTitle": "wedding cake decorator"
    },
    {
        "value": 8849,
        "NOCCode": "6341",
        "JobTitle": "apprentice, barber"
    },
    {
        "value": 8850,
        "NOCCode": "6341",
        "JobTitle": "apprentice, hairdresser"
    },
    {
        "value": 8851,
        "NOCCode": "6341",
        "JobTitle": "apprentice, hairstylist"
    },
    {
        "value": 8852,
        "NOCCode": "6341",
        "JobTitle": "barber"
    },
    {
        "value": 8853,
        "NOCCode": "6341",
        "JobTitle": "barber apprentice"
    },
    {
        "value": 8854,
        "NOCCode": "6341",
        "JobTitle": "beauty salon operator"
    },
    {
        "value": 8855,
        "NOCCode": "6341",
        "JobTitle": "hair colour technician"
    },
    {
        "value": 8856,
        "NOCCode": "6341",
        "JobTitle": "hair dyer"
    },
    {
        "value": 8857,
        "NOCCode": "6341",
        "JobTitle": "hair-care specialist"
    },
    {
        "value": 8858,
        "NOCCode": "6341",
        "JobTitle": "hairdresser"
    },
    {
        "value": 8859,
        "NOCCode": "6341",
        "JobTitle": "hairdresser apprentice"
    },
    {
        "value": 8860,
        "NOCCode": "6341",
        "JobTitle": "hairstyling specialist"
    },
    {
        "value": 8861,
        "NOCCode": "6341",
        "JobTitle": "hairstylist"
    },
    {
        "value": 8862,
        "NOCCode": "6341",
        "JobTitle": "hairstylist apprentice"
    },
    {
        "value": 8863,
        "NOCCode": "6341",
        "JobTitle": "men's hairdresser"
    },
    {
        "value": 8864,
        "NOCCode": "6341",
        "JobTitle": "stylist, hair"
    },
    {
        "value": 8865,
        "NOCCode": "6341",
        "JobTitle": "wig stylist"
    },
    {
        "value": 8866,
        "NOCCode": "6342",
        "JobTitle": "alterationist, garments"
    },
    {
        "value": 8867,
        "NOCCode": "6342",
        "JobTitle": "alterations dressmaker"
    },
    {
        "value": 8868,
        "NOCCode": "6342",
        "JobTitle": "alterations seamstress"
    },
    {
        "value": 8869,
        "NOCCode": "6342",
        "JobTitle": "custom fur joiner"
    },
    {
        "value": 8870,
        "NOCCode": "6342",
        "JobTitle": "custom furrier"
    },
    {
        "value": 8871,
        "NOCCode": "6342",
        "JobTitle": "custom sewer, women's garments"
    },
    {
        "value": 8872,
        "NOCCode": "6342",
        "JobTitle": "custom tailor"
    },
    {
        "value": 8873,
        "NOCCode": "6342",
        "JobTitle": "dressmaker"
    },
    {
        "value": 8874,
        "NOCCode": "6342",
        "JobTitle": "fur remodeller"
    },
    {
        "value": 8875,
        "NOCCode": "6342",
        "JobTitle": "fur repair estimator"
    },
    {
        "value": 8876,
        "NOCCode": "6342",
        "JobTitle": "fur repairman/woman"
    },
    {
        "value": 8877,
        "NOCCode": "6342",
        "JobTitle": "fur tailor"
    },
    {
        "value": 8878,
        "NOCCode": "6342",
        "JobTitle": "furrier"
    },
    {
        "value": 8879,
        "NOCCode": "6342",
        "JobTitle": "garment fitter"
    },
    {
        "value": 8880,
        "NOCCode": "6342",
        "JobTitle": "garment repair seamstress"
    },
    {
        "value": 8881,
        "NOCCode": "6342",
        "JobTitle": "master tailor"
    },
    {
        "value": 8882,
        "NOCCode": "6342",
        "JobTitle": "milliner"
    },
    {
        "value": 8883,
        "NOCCode": "6342",
        "JobTitle": "millinery maker"
    },
    {
        "value": 8884,
        "NOCCode": "6342",
        "JobTitle": "repair seamstress, garments"
    },
    {
        "value": 8885,
        "NOCCode": "6342",
        "JobTitle": "sample maker, garments"
    },
    {
        "value": 8886,
        "NOCCode": "6342",
        "JobTitle": "seamstress"
    },
    {
        "value": 8887,
        "NOCCode": "6342",
        "JobTitle": "shop tailor"
    },
    {
        "value": 8888,
        "NOCCode": "6342",
        "JobTitle": "supervisor, tailors"
    },
    {
        "value": 8889,
        "NOCCode": "6342",
        "JobTitle": "tailor"
    },
    {
        "value": 8890,
        "NOCCode": "6342",
        "JobTitle": "tailor, made-to-measure garments"
    },
    {
        "value": 8891,
        "NOCCode": "6342",
        "JobTitle": "tailor, men's garment alterations"
    },
    {
        "value": 8892,
        "NOCCode": "6342",
        "JobTitle": "tailor, ready-to-wear garments"
    },
    {
        "value": 8893,
        "NOCCode": "6342",
        "JobTitle": "women's garment alterations seamstress"
    },
    {
        "value": 8894,
        "NOCCode": "6342",
        "JobTitle": "women's garment custom seamstress"
    },
    {
        "value": 8895,
        "NOCCode": "6342",
        "JobTitle": "women's hat maker – milliner"
    },
    {
        "value": 8896,
        "NOCCode": "6343",
        "JobTitle": "boot repairer"
    },
    {
        "value": 8897,
        "NOCCode": "6343",
        "JobTitle": "bootmaker"
    },
    {
        "value": 8898,
        "NOCCode": "6343",
        "JobTitle": "cobbler"
    },
    {
        "value": 8899,
        "NOCCode": "6343",
        "JobTitle": "custom shoemaker"
    },
    {
        "value": 8900,
        "NOCCode": "6343",
        "JobTitle": "orthopedic shoe repairman/woman"
    },
    {
        "value": 8901,
        "NOCCode": "6343",
        "JobTitle": "orthopedic shoemaker"
    },
    {
        "value": 8902,
        "NOCCode": "6343",
        "JobTitle": "shoe dyer"
    },
    {
        "value": 8903,
        "NOCCode": "6343",
        "JobTitle": "shoe mender – retail"
    },
    {
        "value": 8904,
        "NOCCode": "6343",
        "JobTitle": "shoe repairer"
    },
    {
        "value": 8905,
        "NOCCode": "6343",
        "JobTitle": "shoe repairer – retail"
    },
    {
        "value": 8906,
        "NOCCode": "6343",
        "JobTitle": "shoemaker"
    },
    {
        "value": 8907,
        "NOCCode": "6344",
        "JobTitle": "appraiser, jewellery"
    },
    {
        "value": 8908,
        "NOCCode": "6344",
        "JobTitle": "apprentice jeweller"
    },
    {
        "value": 8909,
        "NOCCode": "6344",
        "JobTitle": "bench moulder – jewellery"
    },
    {
        "value": 8910,
        "NOCCode": "6344",
        "JobTitle": "brass or bronze chaser"
    },
    {
        "value": 8911,
        "NOCCode": "6344",
        "JobTitle": "chaser, jewellery"
    },
    {
        "value": 8912,
        "NOCCode": "6344",
        "JobTitle": "clock repairer"
    },
    {
        "value": 8913,
        "NOCCode": "6344",
        "JobTitle": "clock repairman/woman"
    },
    {
        "value": 8914,
        "NOCCode": "6344",
        "JobTitle": "custom bracelet maker"
    },
    {
        "value": 8915,
        "NOCCode": "6344",
        "JobTitle": "custom brooch maker"
    },
    {
        "value": 8916,
        "NOCCode": "6344",
        "JobTitle": "custom jewellery mounter"
    },
    {
        "value": 8917,
        "NOCCode": "6344",
        "JobTitle": "custom locket maker"
    },
    {
        "value": 8918,
        "NOCCode": "6344",
        "JobTitle": "cutter, designs – jewellery"
    },
    {
        "value": 8919,
        "NOCCode": "6344",
        "JobTitle": "design cutter – jewellery"
    },
    {
        "value": 8920,
        "NOCCode": "6344",
        "JobTitle": "diamond bruter"
    },
    {
        "value": 8921,
        "NOCCode": "6344",
        "JobTitle": "diamond cutter"
    },
    {
        "value": 8922,
        "NOCCode": "6344",
        "JobTitle": "diamond expert"
    },
    {
        "value": 8923,
        "NOCCode": "6344",
        "JobTitle": "diamond grader – jewellery manufacturing"
    },
    {
        "value": 8924,
        "NOCCode": "6344",
        "JobTitle": "diamond marker"
    },
    {
        "value": 8925,
        "NOCCode": "6344",
        "JobTitle": "diamond polisher"
    },
    {
        "value": 8926,
        "NOCCode": "6344",
        "JobTitle": "diamond sawyer"
    },
    {
        "value": 8927,
        "NOCCode": "6344",
        "JobTitle": "diamond selector – jewellery manufacturing"
    },
    {
        "value": 8928,
        "NOCCode": "6344",
        "JobTitle": "diamond setter"
    },
    {
        "value": 8929,
        "NOCCode": "6344",
        "JobTitle": "gem cutter"
    },
    {
        "value": 8930,
        "NOCCode": "6344",
        "JobTitle": "gem expert"
    },
    {
        "value": 8931,
        "NOCCode": "6344",
        "JobTitle": "gem setter"
    },
    {
        "value": 8932,
        "NOCCode": "6344",
        "JobTitle": "hammersmith, jewellery"
    },
    {
        "value": 8933,
        "NOCCode": "6344",
        "JobTitle": "jewel setter"
    },
    {
        "value": 8934,
        "NOCCode": "6344",
        "JobTitle": "jeweller"
    },
    {
        "value": 8935,
        "NOCCode": "6344",
        "JobTitle": "jewellery appraiser"
    },
    {
        "value": 8936,
        "NOCCode": "6344",
        "JobTitle": "jewellery chaser"
    },
    {
        "value": 8937,
        "NOCCode": "6344",
        "JobTitle": "jewellery hammersmith"
    },
    {
        "value": 8938,
        "NOCCode": "6344",
        "JobTitle": "jewellery model maker"
    },
    {
        "value": 8939,
        "NOCCode": "6344",
        "JobTitle": "jewellery moulder"
    },
    {
        "value": 8940,
        "NOCCode": "6344",
        "JobTitle": "jewellery repairer"
    },
    {
        "value": 8941,
        "NOCCode": "6344",
        "JobTitle": "jewellery repairman/woman"
    },
    {
        "value": 8942,
        "NOCCode": "6344",
        "JobTitle": "jewellery sample maker"
    },
    {
        "value": 8943,
        "NOCCode": "6344",
        "JobTitle": "lapidary"
    },
    {
        "value": 8944,
        "NOCCode": "6344",
        "JobTitle": "marker, diamonds"
    },
    {
        "value": 8945,
        "NOCCode": "6344",
        "JobTitle": "model maker, jewellery"
    },
    {
        "value": 8946,
        "NOCCode": "6344",
        "JobTitle": "pearl cutter"
    },
    {
        "value": 8947,
        "NOCCode": "6344",
        "JobTitle": "pearl setter"
    },
    {
        "value": 8948,
        "NOCCode": "6344",
        "JobTitle": "plater – jewellery manufacturing"
    },
    {
        "value": 8949,
        "NOCCode": "6344",
        "JobTitle": "precious stone setter"
    },
    {
        "value": 8950,
        "NOCCode": "6344",
        "JobTitle": "sample maker, jewellery"
    },
    {
        "value": 8951,
        "NOCCode": "6344",
        "JobTitle": "sawyer, diamonds"
    },
    {
        "value": 8952,
        "NOCCode": "6344",
        "JobTitle": "setter – jewellery manufacturing"
    },
    {
        "value": 8953,
        "NOCCode": "6344",
        "JobTitle": "silver chaser"
    },
    {
        "value": 8954,
        "NOCCode": "6344",
        "JobTitle": "stonecutter – jewellery"
    },
    {
        "value": 8955,
        "NOCCode": "6344",
        "JobTitle": "stonesetter – jewellery"
    },
    {
        "value": 8956,
        "NOCCode": "6344",
        "JobTitle": "watch repairer"
    },
    {
        "value": 8957,
        "NOCCode": "6344",
        "JobTitle": "watch repairer apprentice"
    },
    {
        "value": 8958,
        "NOCCode": "6344",
        "JobTitle": "watch repairman/woman"
    },
    {
        "value": 8959,
        "NOCCode": "6344",
        "JobTitle": "watchmaker"
    },
    {
        "value": 8960,
        "NOCCode": "6345",
        "JobTitle": "aircraft custom upholsterer"
    },
    {
        "value": 8961,
        "NOCCode": "6345",
        "JobTitle": "aircraft furnishing technician"
    },
    {
        "value": 8962,
        "NOCCode": "6345",
        "JobTitle": "aircraft interior technician"
    },
    {
        "value": 8963,
        "NOCCode": "6345",
        "JobTitle": "aircraft patternmaker and upholsterer"
    },
    {
        "value": 8964,
        "NOCCode": "6345",
        "JobTitle": "aircraft upholsterer"
    },
    {
        "value": 8965,
        "NOCCode": "6345",
        "JobTitle": "automobile upholsterer"
    },
    {
        "value": 8966,
        "NOCCode": "6345",
        "JobTitle": "automotive upholsterer"
    },
    {
        "value": 8967,
        "NOCCode": "6345",
        "JobTitle": "casket upholsterer"
    },
    {
        "value": 8968,
        "NOCCode": "6345",
        "JobTitle": "chair upholsterer"
    },
    {
        "value": 8969,
        "NOCCode": "6345",
        "JobTitle": "custom upholsterer"
    },
    {
        "value": 8970,
        "NOCCode": "6345",
        "JobTitle": "furniture upholsterer"
    },
    {
        "value": 8971,
        "NOCCode": "6345",
        "JobTitle": "industrial upholsterer"
    },
    {
        "value": 8972,
        "NOCCode": "6345",
        "JobTitle": "patternmaker and upholsterer, aircraft"
    },
    {
        "value": 8973,
        "NOCCode": "6345",
        "JobTitle": "patternmaker-upholsterer"
    },
    {
        "value": 8974,
        "NOCCode": "6345",
        "JobTitle": "preparation upholsterer"
    },
    {
        "value": 8975,
        "NOCCode": "6345",
        "JobTitle": "production upholsterer"
    },
    {
        "value": 8976,
        "NOCCode": "6345",
        "JobTitle": "railway car upholsterer"
    },
    {
        "value": 8977,
        "NOCCode": "6345",
        "JobTitle": "repairer, upholstery"
    },
    {
        "value": 8978,
        "NOCCode": "6345",
        "JobTitle": "re-upholsterer"
    },
    {
        "value": 8979,
        "NOCCode": "6345",
        "JobTitle": "upholsterer"
    },
    {
        "value": 8980,
        "NOCCode": "6345",
        "JobTitle": "upholsterer, aircraft"
    },
    {
        "value": 8981,
        "NOCCode": "6345",
        "JobTitle": "upholsterer, railway car"
    },
    {
        "value": 8982,
        "NOCCode": "6345",
        "JobTitle": "upholstery mechanic"
    },
    {
        "value": 8983,
        "NOCCode": "6345",
        "JobTitle": "upholstery repairer"
    },
    {
        "value": 8984,
        "NOCCode": "6345",
        "JobTitle": "vehicle upholstery repairer"
    },
    {
        "value": 8985,
        "NOCCode": "6346",
        "JobTitle": "apprentice embalmer"
    },
    {
        "value": 8986,
        "NOCCode": "6346",
        "JobTitle": "assistant funeral director"
    },
    {
        "value": 8987,
        "NOCCode": "6346",
        "JobTitle": "director, funeral"
    },
    {
        "value": 8988,
        "NOCCode": "6346",
        "JobTitle": "embalmer"
    },
    {
        "value": 8989,
        "NOCCode": "6346",
        "JobTitle": "embalmer apprentice"
    },
    {
        "value": 8990,
        "NOCCode": "6346",
        "JobTitle": "funeral director"
    },
    {
        "value": 8991,
        "NOCCode": "6346",
        "JobTitle": "funeral director, assistant"
    },
    {
        "value": 8992,
        "NOCCode": "6346",
        "JobTitle": "funeral home director"
    },
    {
        "value": 8993,
        "NOCCode": "6346",
        "JobTitle": "funeral services director"
    },
    {
        "value": 8994,
        "NOCCode": "6346",
        "JobTitle": "mortician"
    },
    {
        "value": 8995,
        "NOCCode": "6346",
        "JobTitle": "undertaker"
    },
    {
        "value": 8996,
        "NOCCode": "6346",
        "JobTitle": "undertaker assistant"
    },
    {
        "value": 8997,
        "NOCCode": "6411",
        "JobTitle": "advertising agency broker"
    },
    {
        "value": 8998,
        "NOCCode": "6411",
        "JobTitle": "advertising agent"
    },
    {
        "value": 8999,
        "NOCCode": "6411",
        "JobTitle": "advertising space representative – print media"
    },
    {
        "value": 9000,
        "NOCCode": "6411",
        "JobTitle": "advertising time sales representative"
    },
    {
        "value": 9001,
        "NOCCode": "6411",
        "JobTitle": "alarm system sales representative"
    },
    {
        "value": 9002,
        "NOCCode": "6411",
        "JobTitle": "alcoholic beverage sales representative – wholesale"
    },
    {
        "value": 9003,
        "NOCCode": "6411",
        "JobTitle": "aluminum products sales representative – wholesale"
    },
    {
        "value": 9004,
        "NOCCode": "6411",
        "JobTitle": "auctioneer"
    },
    {
        "value": 9005,
        "NOCCode": "6411",
        "JobTitle": "automotive parts sales representative – wholesale (non-technical)"
    },
    {
        "value": 9006,
        "NOCCode": "6411",
        "JobTitle": "automotive parts salesperson – wholesale"
    },
    {
        "value": 9007,
        "NOCCode": "6411",
        "JobTitle": "beer sales representative – wholesale"
    },
    {
        "value": 9008,
        "NOCCode": "6411",
        "JobTitle": "booking agent – wholesale"
    },
    {
        "value": 9009,
        "NOCCode": "6411",
        "JobTitle": "broker, advertising agency"
    },
    {
        "value": 9010,
        "NOCCode": "6411",
        "JobTitle": "broker, livestock"
    },
    {
        "value": 9011,
        "NOCCode": "6411",
        "JobTitle": "building maintenance services sales representative"
    },
    {
        "value": 9012,
        "NOCCode": "6411",
        "JobTitle": "business equipment sales representative – wholesale"
    },
    {
        "value": 9013,
        "NOCCode": "6411",
        "JobTitle": "business forms sales representative"
    },
    {
        "value": 9014,
        "NOCCode": "6411",
        "JobTitle": "business services sales representative"
    },
    {
        "value": 9015,
        "NOCCode": "6411",
        "JobTitle": "canvas product sales representative"
    },
    {
        "value": 9016,
        "NOCCode": "6411",
        "JobTitle": "cartage and storage salesperson"
    },
    {
        "value": 9017,
        "NOCCode": "6411",
        "JobTitle": "catalogue agent – wholesale"
    },
    {
        "value": 9018,
        "NOCCode": "6411",
        "JobTitle": "classified ad salesperson"
    },
    {
        "value": 9019,
        "NOCCode": "6411",
        "JobTitle": "cleaning services sales representative"
    },
    {
        "value": 9020,
        "NOCCode": "6411",
        "JobTitle": "collection agency sales representative"
    },
    {
        "value": 9021,
        "NOCCode": "6411",
        "JobTitle": "commercial agent – wholesale"
    },
    {
        "value": 9022,
        "NOCCode": "6411",
        "JobTitle": "commercial and industrial equipment and supplies sales representative – wholesale (non-technical)"
    },
    {
        "value": 9023,
        "NOCCode": "6411",
        "JobTitle": "commercial salesperson – wholesale"
    },
    {
        "value": 9024,
        "NOCCode": "6411",
        "JobTitle": "commercial traveller – wholesale"
    },
    {
        "value": 9025,
        "NOCCode": "6411",
        "JobTitle": "commission agent, livestock"
    },
    {
        "value": 9026,
        "NOCCode": "6411",
        "JobTitle": "computer services sales representative"
    },
    {
        "value": 9027,
        "NOCCode": "6411",
        "JobTitle": "concrete products salesperson – wholesale"
    },
    {
        "value": 9028,
        "NOCCode": "6411",
        "JobTitle": "consumer sales representative"
    },
    {
        "value": 9029,
        "NOCCode": "6411",
        "JobTitle": "contact person, recordings"
    },
    {
        "value": 9030,
        "NOCCode": "6411",
        "JobTitle": "courier services sales representative"
    },
    {
        "value": 9031,
        "NOCCode": "6411",
        "JobTitle": "credit bureau sales representative"
    },
    {
        "value": 9032,
        "NOCCode": "6411",
        "JobTitle": "delivery services sales agent"
    },
    {
        "value": 9033,
        "NOCCode": "6411",
        "JobTitle": "delivery services sales representative"
    },
    {
        "value": 9034,
        "NOCCode": "6411",
        "JobTitle": "design sales representative – wholesale"
    },
    {
        "value": 9035,
        "NOCCode": "6411",
        "JobTitle": "display advertising sales representative"
    },
    {
        "value": 9036,
        "NOCCode": "6411",
        "JobTitle": "district sales representative – wholesale"
    },
    {
        "value": 9037,
        "NOCCode": "6411",
        "JobTitle": "export company sales representative"
    },
    {
        "value": 9038,
        "NOCCode": "6411",
        "JobTitle": "export sales representative"
    },
    {
        "value": 9039,
        "NOCCode": "6411",
        "JobTitle": "exporter"
    },
    {
        "value": 9040,
        "NOCCode": "6411",
        "JobTitle": "feed product sales representative"
    },
    {
        "value": 9041,
        "NOCCode": "6411",
        "JobTitle": "field salesperson – wholesale"
    },
    {
        "value": 9042,
        "NOCCode": "6411",
        "JobTitle": "financial services sales representative"
    },
    {
        "value": 9043,
        "NOCCode": "6411",
        "JobTitle": "financial services salesperson"
    },
    {
        "value": 9044,
        "NOCCode": "6411",
        "JobTitle": "fleet sales representative"
    },
    {
        "value": 9045,
        "NOCCode": "6411",
        "JobTitle": "food products sales representative"
    },
    {
        "value": 9046,
        "NOCCode": "6411",
        "JobTitle": "food services sales representative"
    },
    {
        "value": 9047,
        "NOCCode": "6411",
        "JobTitle": "freight forwarding services sales representative"
    },
    {
        "value": 9048,
        "NOCCode": "6411",
        "JobTitle": "freight sales agent"
    },
    {
        "value": 9049,
        "NOCCode": "6411",
        "JobTitle": "freight services sales representative"
    },
    {
        "value": 9050,
        "NOCCode": "6411",
        "JobTitle": "freight traffic agent"
    },
    {
        "value": 9051,
        "NOCCode": "6411",
        "JobTitle": "fur trader"
    },
    {
        "value": 9052,
        "NOCCode": "6411",
        "JobTitle": "grain broker – wholesale"
    },
    {
        "value": 9053,
        "NOCCode": "6411",
        "JobTitle": "grain dealer"
    },
    {
        "value": 9054,
        "NOCCode": "6411",
        "JobTitle": "grain merchandiser"
    },
    {
        "value": 9055,
        "NOCCode": "6411",
        "JobTitle": "graphic arts sales representative"
    },
    {
        "value": 9056,
        "NOCCode": "6411",
        "JobTitle": "graphic design sales representative"
    },
    {
        "value": 9057,
        "NOCCode": "6411",
        "JobTitle": "group sales representative, travel"
    },
    {
        "value": 9058,
        "NOCCode": "6411",
        "JobTitle": "group travel sales representative"
    },
    {
        "value": 9059,
        "NOCCode": "6411",
        "JobTitle": "hotel accommodations sales executive"
    },
    {
        "value": 9060,
        "NOCCode": "6411",
        "JobTitle": "hotel services sales representative"
    },
    {
        "value": 9061,
        "NOCCode": "6411",
        "JobTitle": "hotel supply sales representative"
    },
    {
        "value": 9062,
        "NOCCode": "6411",
        "JobTitle": "import company sales representative"
    },
    {
        "value": 9063,
        "NOCCode": "6411",
        "JobTitle": "import sales representative"
    },
    {
        "value": 9064,
        "NOCCode": "6411",
        "JobTitle": "importer"
    },
    {
        "value": 9065,
        "NOCCode": "6411",
        "JobTitle": "import-export tradesperson – wholesale"
    },
    {
        "value": 9066,
        "NOCCode": "6411",
        "JobTitle": "industrial tool sales representative"
    },
    {
        "value": 9067,
        "NOCCode": "6411",
        "JobTitle": "institutional food services sales representative"
    },
    {
        "value": 9068,
        "NOCCode": "6411",
        "JobTitle": "insulation salesperson – wholesale"
    },
    {
        "value": 9069,
        "NOCCode": "6411",
        "JobTitle": "janitorial services sales representative"
    },
    {
        "value": 9070,
        "NOCCode": "6411",
        "JobTitle": "laundry services sales representative"
    },
    {
        "value": 9071,
        "NOCCode": "6411",
        "JobTitle": "leasing agent, signs and displays"
    },
    {
        "value": 9072,
        "NOCCode": "6411",
        "JobTitle": "leasing services sales representative"
    },
    {
        "value": 9073,
        "NOCCode": "6411",
        "JobTitle": "lighting fixture sales representative – wholesale (non-technical)"
    },
    {
        "value": 9074,
        "NOCCode": "6411",
        "JobTitle": "liquor sales representative"
    },
    {
        "value": 9075,
        "NOCCode": "6411",
        "JobTitle": "liquor sales representative – wholesale"
    },
    {
        "value": 9076,
        "NOCCode": "6411",
        "JobTitle": "livestock broker"
    },
    {
        "value": 9077,
        "NOCCode": "6411",
        "JobTitle": "livestock commission agent"
    },
    {
        "value": 9078,
        "NOCCode": "6411",
        "JobTitle": "livestock sales representative – wholesale"
    },
    {
        "value": 9079,
        "NOCCode": "6411",
        "JobTitle": "lumber sales representative – wholesale"
    },
    {
        "value": 9080,
        "NOCCode": "6411",
        "JobTitle": "magazine sales representative"
    },
    {
        "value": 9081,
        "NOCCode": "6411",
        "JobTitle": "magazine sales representative – wholesale"
    },
    {
        "value": 9082,
        "NOCCode": "6411",
        "JobTitle": "manufacturer's agent – wholesale (non-technical)"
    },
    {
        "value": 9083,
        "NOCCode": "6411",
        "JobTitle": "manufacturer's representative – wholesale (non-technical)"
    },
    {
        "value": 9084,
        "NOCCode": "6411",
        "JobTitle": "media time sales representative"
    },
    {
        "value": 9085,
        "NOCCode": "6411",
        "JobTitle": "membership sales representative"
    },
    {
        "value": 9086,
        "NOCCode": "6411",
        "JobTitle": "motor vehicle and equipment sales representative – wholesale (non-technical)"
    },
    {
        "value": 9087,
        "NOCCode": "6411",
        "JobTitle": "non-technical sales specialist – wholesale"
    },
    {
        "value": 9088,
        "NOCCode": "6411",
        "JobTitle": "novelty products salesperson – wholesale"
    },
    {
        "value": 9089,
        "NOCCode": "6411",
        "JobTitle": "novelty sales representative – wholesale"
    },
    {
        "value": 9090,
        "NOCCode": "6411",
        "JobTitle": "ocean and lake container transportation sales representative"
    },
    {
        "value": 9091,
        "NOCCode": "6411",
        "JobTitle": "office equipment sales representative – wholesale (non-technical)"
    },
    {
        "value": 9092,
        "NOCCode": "6411",
        "JobTitle": "office furniture sales representative – wholesale"
    },
    {
        "value": 9093,
        "NOCCode": "6411",
        "JobTitle": "oil distributor"
    },
    {
        "value": 9094,
        "NOCCode": "6411",
        "JobTitle": "paper industry sales consultant"
    },
    {
        "value": 9095,
        "NOCCode": "6411",
        "JobTitle": "periodicals sales representative"
    },
    {
        "value": 9096,
        "NOCCode": "6411",
        "JobTitle": "periodicals sales representative – wholesale"
    },
    {
        "value": 9097,
        "NOCCode": "6411",
        "JobTitle": "petroleum product sales representative – wholesale"
    },
    {
        "value": 9098,
        "NOCCode": "6411",
        "JobTitle": "photographic services sales representative"
    },
    {
        "value": 9099,
        "NOCCode": "6411",
        "JobTitle": "plastic product sales representative – wholesale"
    },
    {
        "value": 9100,
        "NOCCode": "6411",
        "JobTitle": "postal services commercial sales representative"
    },
    {
        "value": 9101,
        "NOCCode": "6411",
        "JobTitle": "printing services sales representative"
    },
    {
        "value": 9102,
        "NOCCode": "6411",
        "JobTitle": "publication distributor – wholesale"
    },
    {
        "value": 9103,
        "NOCCode": "6411",
        "JobTitle": "publication sales representative – wholesale"
    },
    {
        "value": 9104,
        "NOCCode": "6411",
        "JobTitle": "publisher's sales representative – wholesale"
    },
    {
        "value": 9105,
        "NOCCode": "6411",
        "JobTitle": "pulp and paper product sales representative"
    },
    {
        "value": 9106,
        "NOCCode": "6411",
        "JobTitle": "pump and compressor sales representative – wholesale"
    },
    {
        "value": 9107,
        "NOCCode": "6411",
        "JobTitle": "purveyor, wholesale"
    },
    {
        "value": 9108,
        "NOCCode": "6411",
        "JobTitle": "radio advertising time sales representative"
    },
    {
        "value": 9109,
        "NOCCode": "6411",
        "JobTitle": "recordings contact person"
    },
    {
        "value": 9110,
        "NOCCode": "6411",
        "JobTitle": "recycling sales representative"
    },
    {
        "value": 9111,
        "NOCCode": "6411",
        "JobTitle": "regional sales representative – wholesale"
    },
    {
        "value": 9112,
        "NOCCode": "6411",
        "JobTitle": "reservation agent – wholesale"
    },
    {
        "value": 9113,
        "NOCCode": "6411",
        "JobTitle": "restaurant equipment sales representative"
    },
    {
        "value": 9114,
        "NOCCode": "6411",
        "JobTitle": "rubber product sales representative – wholesale"
    },
    {
        "value": 9115,
        "NOCCode": "6411",
        "JobTitle": "sales agent – wholesale (non-technical)"
    },
    {
        "value": 9116,
        "NOCCode": "6411",
        "JobTitle": "sales consultant – wholesale (non-technical)"
    },
    {
        "value": 9117,
        "NOCCode": "6411",
        "JobTitle": "sales co-ordinator – wholesale (non-technical)"
    },
    {
        "value": 9118,
        "NOCCode": "6411",
        "JobTitle": "sales executive – wholesale (non-technical)"
    },
    {
        "value": 9119,
        "NOCCode": "6411",
        "JobTitle": "sales representative – wholesale (non-technical)"
    },
    {
        "value": 9120,
        "NOCCode": "6411",
        "JobTitle": "sales representative, alcoholic beverages – wholesale"
    },
    {
        "value": 9121,
        "NOCCode": "6411",
        "JobTitle": "sales representative, business services"
    },
    {
        "value": 9122,
        "NOCCode": "6411",
        "JobTitle": "sales representative, canvas products"
    },
    {
        "value": 9123,
        "NOCCode": "6411",
        "JobTitle": "sales representative, cleaning services"
    },
    {
        "value": 9124,
        "NOCCode": "6411",
        "JobTitle": "sales representative, collection agency"
    },
    {
        "value": 9125,
        "NOCCode": "6411",
        "JobTitle": "sales representative, commercial and industrial equipment and supplies"
    },
    {
        "value": 9126,
        "NOCCode": "6411",
        "JobTitle": "sales representative, computer services"
    },
    {
        "value": 9127,
        "NOCCode": "6411",
        "JobTitle": "sales representative, courier services"
    },
    {
        "value": 9128,
        "NOCCode": "6411",
        "JobTitle": "sales representative, delivery services"
    },
    {
        "value": 9129,
        "NOCCode": "6411",
        "JobTitle": "sales representative, design – wholesale"
    },
    {
        "value": 9130,
        "NOCCode": "6411",
        "JobTitle": "sales representative, export"
    },
    {
        "value": 9131,
        "NOCCode": "6411",
        "JobTitle": "sales representative, financial services"
    },
    {
        "value": 9132,
        "NOCCode": "6411",
        "JobTitle": "sales representative, food products"
    },
    {
        "value": 9133,
        "NOCCode": "6411",
        "JobTitle": "sales representative, freight forwarding services"
    },
    {
        "value": 9134,
        "NOCCode": "6411",
        "JobTitle": "sales representative, hotel services"
    },
    {
        "value": 9135,
        "NOCCode": "6411",
        "JobTitle": "sales representative, hotel supplies"
    },
    {
        "value": 9136,
        "NOCCode": "6411",
        "JobTitle": "sales representative, import"
    },
    {
        "value": 9137,
        "NOCCode": "6411",
        "JobTitle": "sales representative, janitorial services"
    },
    {
        "value": 9138,
        "NOCCode": "6411",
        "JobTitle": "sales representative, livestock – wholesale"
    },
    {
        "value": 9139,
        "NOCCode": "6411",
        "JobTitle": "sales representative, motor vehicles and equipment – wholesale (non-technical)"
    },
    {
        "value": 9140,
        "NOCCode": "6411",
        "JobTitle": "sales representative, plastic products – wholesale"
    },
    {
        "value": 9141,
        "NOCCode": "6411",
        "JobTitle": "sales representative, printing services"
    },
    {
        "value": 9142,
        "NOCCode": "6411",
        "JobTitle": "sales representative, publications – wholesale"
    },
    {
        "value": 9143,
        "NOCCode": "6411",
        "JobTitle": "sales representative, pulp and paper products"
    },
    {
        "value": 9144,
        "NOCCode": "6411",
        "JobTitle": "sales representative, radio advertising time"
    },
    {
        "value": 9145,
        "NOCCode": "6411",
        "JobTitle": "sales representative, rubber products – wholesale"
    },
    {
        "value": 9146,
        "NOCCode": "6411",
        "JobTitle": "sales representative, security services – wholesale"
    },
    {
        "value": 9147,
        "NOCCode": "6411",
        "JobTitle": "sales representative, sign and display"
    },
    {
        "value": 9148,
        "NOCCode": "6411",
        "JobTitle": "sales representative, television advertising time"
    },
    {
        "value": 9149,
        "NOCCode": "6411",
        "JobTitle": "sales representative, textbooks"
    },
    {
        "value": 9150,
        "NOCCode": "6411",
        "JobTitle": "sales representative, tobacco products – wholesale"
    },
    {
        "value": 9151,
        "NOCCode": "6411",
        "JobTitle": "sales representative, vending machine"
    },
    {
        "value": 9152,
        "NOCCode": "6411",
        "JobTitle": "sales representative, vending machine services"
    },
    {
        "value": 9153,
        "NOCCode": "6411",
        "JobTitle": "salesperson – wholesale (non-technical)"
    },
    {
        "value": 9154,
        "NOCCode": "6411",
        "JobTitle": "salesperson, classified ads"
    },
    {
        "value": 9155,
        "NOCCode": "6411",
        "JobTitle": "salesperson, novelty products – wholesale"
    },
    {
        "value": 9156,
        "NOCCode": "6411",
        "JobTitle": "salesperson, security systems"
    },
    {
        "value": 9157,
        "NOCCode": "6411",
        "JobTitle": "security services sales consultant"
    },
    {
        "value": 9158,
        "NOCCode": "6411",
        "JobTitle": "security services sales consultant – wholesale"
    },
    {
        "value": 9159,
        "NOCCode": "6411",
        "JobTitle": "security services sales representative"
    },
    {
        "value": 9160,
        "NOCCode": "6411",
        "JobTitle": "security services sales representative – wholesale"
    },
    {
        "value": 9161,
        "NOCCode": "6411",
        "JobTitle": "security system salesperson"
    },
    {
        "value": 9162,
        "NOCCode": "6411",
        "JobTitle": "shipping sales representative"
    },
    {
        "value": 9163,
        "NOCCode": "6411",
        "JobTitle": "sign and display leasing agent"
    },
    {
        "value": 9164,
        "NOCCode": "6411",
        "JobTitle": "sign and display sales representative"
    },
    {
        "value": 9165,
        "NOCCode": "6411",
        "JobTitle": "storage services sales representative"
    },
    {
        "value": 9166,
        "NOCCode": "6411",
        "JobTitle": "supervisor, wholesale trade representatives"
    },
    {
        "value": 9167,
        "NOCCode": "6411",
        "JobTitle": "supplier – wholesale"
    },
    {
        "value": 9168,
        "NOCCode": "6411",
        "JobTitle": "telephone equipment sales representative – wholesale (non-technical)"
    },
    {
        "value": 9169,
        "NOCCode": "6411",
        "JobTitle": "television advertising time sales representative"
    },
    {
        "value": 9170,
        "NOCCode": "6411",
        "JobTitle": "television advertising time salesperson"
    },
    {
        "value": 9171,
        "NOCCode": "6411",
        "JobTitle": "textbook sales representative"
    },
    {
        "value": 9172,
        "NOCCode": "6411",
        "JobTitle": "tobacco product sales representative – wholesale"
    },
    {
        "value": 9173,
        "NOCCode": "6411",
        "JobTitle": "tour operator – wholesale"
    },
    {
        "value": 9174,
        "NOCCode": "6411",
        "JobTitle": "tour package sales representative – wholesale"
    },
    {
        "value": 9175,
        "NOCCode": "6411",
        "JobTitle": "transfer company agent"
    },
    {
        "value": 9176,
        "NOCCode": "6411",
        "JobTitle": "travelling salesperson – wholesale"
    },
    {
        "value": 9177,
        "NOCCode": "6411",
        "JobTitle": "truck and trailer sales representative – wholesale (non-technical)"
    },
    {
        "value": 9178,
        "NOCCode": "6411",
        "JobTitle": "vending machine sales representative"
    },
    {
        "value": 9179,
        "NOCCode": "6411",
        "JobTitle": "vending machine services sales representative"
    },
    {
        "value": 9180,
        "NOCCode": "6411",
        "JobTitle": "wholesale purveyor"
    },
    {
        "value": 9181,
        "NOCCode": "6411",
        "JobTitle": "wholesale trade representative"
    },
    {
        "value": 9182,
        "NOCCode": "6411",
        "JobTitle": "wine sales representative – wholesale"
    },
    {
        "value": 9183,
        "NOCCode": "6421",
        "JobTitle": "aluminum products salesperson – retail"
    },
    {
        "value": 9184,
        "NOCCode": "6421",
        "JobTitle": "aluminum siding salesperson – retail"
    },
    {
        "value": 9185,
        "NOCCode": "6421",
        "JobTitle": "appliance salesperson – retail"
    },
    {
        "value": 9186,
        "NOCCode": "6421",
        "JobTitle": "art sales consultant – retail"
    },
    {
        "value": 9187,
        "NOCCode": "6421",
        "JobTitle": "art salesperson"
    },
    {
        "value": 9188,
        "NOCCode": "6421",
        "JobTitle": "audio equipment consultant – retail"
    },
    {
        "value": 9189,
        "NOCCode": "6421",
        "JobTitle": "audio equipment salesperson"
    },
    {
        "value": 9190,
        "NOCCode": "6421",
        "JobTitle": "audio equipment salesperson – retail"
    },
    {
        "value": 9191,
        "NOCCode": "6421",
        "JobTitle": "automobile accessories salesperson – retail"
    },
    {
        "value": 9192,
        "NOCCode": "6421",
        "JobTitle": "automobile leasing agent"
    },
    {
        "value": 9193,
        "NOCCode": "6421",
        "JobTitle": "automobile leasing representative"
    },
    {
        "value": 9194,
        "NOCCode": "6421",
        "JobTitle": "automobile sales representative – retail"
    },
    {
        "value": 9195,
        "NOCCode": "6421",
        "JobTitle": "automobile salesperson"
    },
    {
        "value": 9196,
        "NOCCode": "6421",
        "JobTitle": "automobile-rental counter representative"
    },
    {
        "value": 9197,
        "NOCCode": "6421",
        "JobTitle": "bakery clerk"
    },
    {
        "value": 9198,
        "NOCCode": "6421",
        "JobTitle": "bakery counter clerk"
    },
    {
        "value": 9199,
        "NOCCode": "6421",
        "JobTitle": "bakery goods sales clerk"
    },
    {
        "value": 9200,
        "NOCCode": "6421",
        "JobTitle": "beer store sales clerk"
    },
    {
        "value": 9201,
        "NOCCode": "6421",
        "JobTitle": "bicycle salesperson – retail"
    },
    {
        "value": 9202,
        "NOCCode": "6421",
        "JobTitle": "boat and marine equipment salesperson – retail"
    },
    {
        "value": 9203,
        "NOCCode": "6421",
        "JobTitle": "book salesperson – retail"
    },
    {
        "value": 9204,
        "NOCCode": "6421",
        "JobTitle": "book store clerk – retail"
    },
    {
        "value": 9205,
        "NOCCode": "6421",
        "JobTitle": "bouquet salesperson"
    },
    {
        "value": 9206,
        "NOCCode": "6421",
        "JobTitle": "bridal sales consultant – retail"
    },
    {
        "value": 9207,
        "NOCCode": "6421",
        "JobTitle": "building supplies salesperson – retail"
    },
    {
        "value": 9208,
        "NOCCode": "6421",
        "JobTitle": "butcher shop clerk"
    },
    {
        "value": 9209,
        "NOCCode": "6421",
        "JobTitle": "cable television service salesperson"
    },
    {
        "value": 9210,
        "NOCCode": "6421",
        "JobTitle": "cable-TV and pay-TV sales representative"
    },
    {
        "value": 9211,
        "NOCCode": "6421",
        "JobTitle": "camera equipment salesperson – retail"
    },
    {
        "value": 9212,
        "NOCCode": "6421",
        "JobTitle": "camera salesperson – retail"
    },
    {
        "value": 9213,
        "NOCCode": "6421",
        "JobTitle": "car leasing representative"
    },
    {
        "value": 9214,
        "NOCCode": "6421",
        "JobTitle": "car rental agent"
    },
    {
        "value": 9215,
        "NOCCode": "6421",
        "JobTitle": "car rental clerk"
    },
    {
        "value": 9216,
        "NOCCode": "6421",
        "JobTitle": "car sales representative – retail"
    },
    {
        "value": 9217,
        "NOCCode": "6421",
        "JobTitle": "car salesperson – retail"
    },
    {
        "value": 9218,
        "NOCCode": "6421",
        "JobTitle": "card shop clerk"
    },
    {
        "value": 9219,
        "NOCCode": "6421",
        "JobTitle": "car-rental counter representative"
    },
    {
        "value": 9220,
        "NOCCode": "6421",
        "JobTitle": "children's clothing sales clerk"
    },
    {
        "value": 9221,
        "NOCCode": "6421",
        "JobTitle": "children's wear sales clerk"
    },
    {
        "value": 9222,
        "NOCCode": "6421",
        "JobTitle": "children's wear salesperson – retail"
    },
    {
        "value": 9223,
        "NOCCode": "6421",
        "JobTitle": "china and crystal salesperson – retail"
    },
    {
        "value": 9224,
        "NOCCode": "6421",
        "JobTitle": "clerk, bakery counter"
    },
    {
        "value": 9225,
        "NOCCode": "6421",
        "JobTitle": "clerk, deli counter"
    },
    {
        "value": 9226,
        "NOCCode": "6421",
        "JobTitle": "clerk, meat counter"
    },
    {
        "value": 9227,
        "NOCCode": "6421",
        "JobTitle": "clerk, retail sales"
    },
    {
        "value": 9228,
        "NOCCode": "6421",
        "JobTitle": "clock shop clerk"
    },
    {
        "value": 9229,
        "NOCCode": "6421",
        "JobTitle": "clothing salesperson"
    },
    {
        "value": 9230,
        "NOCCode": "6421",
        "JobTitle": "clothing salesperson – retail"
    },
    {
        "value": 9231,
        "NOCCode": "6421",
        "JobTitle": "commissioned salesperson – retail"
    },
    {
        "value": 9232,
        "NOCCode": "6421",
        "JobTitle": "compact disc sales clerk"
    },
    {
        "value": 9233,
        "NOCCode": "6421",
        "JobTitle": "computer salesperson – retail"
    },
    {
        "value": 9234,
        "NOCCode": "6421",
        "JobTitle": "confectionery sales clerk"
    },
    {
        "value": 9235,
        "NOCCode": "6421",
        "JobTitle": "convenience store clerk"
    },
    {
        "value": 9236,
        "NOCCode": "6421",
        "JobTitle": "corner store clerk"
    },
    {
        "value": 9237,
        "NOCCode": "6421",
        "JobTitle": "cosmetics salesperson – retail"
    },
    {
        "value": 9238,
        "NOCCode": "6421",
        "JobTitle": "counter clerk – retail"
    },
    {
        "value": 9239,
        "NOCCode": "6421",
        "JobTitle": "counter clerk, bakery"
    },
    {
        "value": 9240,
        "NOCCode": "6421",
        "JobTitle": "counter clerk, deli"
    },
    {
        "value": 9241,
        "NOCCode": "6421",
        "JobTitle": "counter clerk, meats"
    },
    {
        "value": 9242,
        "NOCCode": "6421",
        "JobTitle": "counter representative, car rental"
    },
    {
        "value": 9243,
        "NOCCode": "6421",
        "JobTitle": "custom drapery salesperson – retail"
    },
    {
        "value": 9244,
        "NOCCode": "6421",
        "JobTitle": "customer service sales clerk"
    },
    {
        "value": 9245,
        "NOCCode": "6421",
        "JobTitle": "deli cook – supermarket"
    },
    {
        "value": 9246,
        "NOCCode": "6421",
        "JobTitle": "deli counter clerk – food store"
    },
    {
        "value": 9247,
        "NOCCode": "6421",
        "JobTitle": "delicatessen clerk – retail"
    },
    {
        "value": 9248,
        "NOCCode": "6421",
        "JobTitle": "delicatessen meat salesperson – retail"
    },
    {
        "value": 9249,
        "NOCCode": "6421",
        "JobTitle": "department store clerk"
    },
    {
        "value": 9250,
        "NOCCode": "6421",
        "JobTitle": "drapery and upholstery salesperson – retail"
    },
    {
        "value": 9251,
        "NOCCode": "6421",
        "JobTitle": "drugstore clerk"
    },
    {
        "value": 9252,
        "NOCCode": "6421",
        "JobTitle": "education courses salesperson"
    },
    {
        "value": 9253,
        "NOCCode": "6421",
        "JobTitle": "electronics salesperson – retail"
    },
    {
        "value": 9254,
        "NOCCode": "6421",
        "JobTitle": "exercise equipment consultant – retail"
    },
    {
        "value": 9255,
        "NOCCode": "6421",
        "JobTitle": "fabric salesperson – retail"
    },
    {
        "value": 9256,
        "NOCCode": "6421",
        "JobTitle": "film counter clerk"
    },
    {
        "value": 9257,
        "NOCCode": "6421",
        "JobTitle": "film rental clerk"
    },
    {
        "value": 9258,
        "NOCCode": "6421",
        "JobTitle": "fish market salesperson"
    },
    {
        "value": 9259,
        "NOCCode": "6421",
        "JobTitle": "fish shop salesperson"
    },
    {
        "value": 9260,
        "NOCCode": "6421",
        "JobTitle": "floor coverings salesperson – retail"
    },
    {
        "value": 9261,
        "NOCCode": "6421",
        "JobTitle": "floral arranger – retail"
    },
    {
        "value": 9262,
        "NOCCode": "6421",
        "JobTitle": "florist – retail"
    },
    {
        "value": 9263,
        "NOCCode": "6421",
        "JobTitle": "florist salesperson"
    },
    {
        "value": 9264,
        "NOCCode": "6421",
        "JobTitle": "florist shop salesperson"
    },
    {
        "value": 9265,
        "NOCCode": "6421",
        "JobTitle": "food preparer – grocery store"
    },
    {
        "value": 9266,
        "NOCCode": "6421",
        "JobTitle": "food store clerk – retail"
    },
    {
        "value": 9267,
        "NOCCode": "6421",
        "JobTitle": "footwear salesperson – retail"
    },
    {
        "value": 9268,
        "NOCCode": "6421",
        "JobTitle": "framing sales consultant – retail"
    },
    {
        "value": 9269,
        "NOCCode": "6421",
        "JobTitle": "fruit seller"
    },
    {
        "value": 9270,
        "NOCCode": "6421",
        "JobTitle": "fur salesperson – retail"
    },
    {
        "value": 9271,
        "NOCCode": "6421",
        "JobTitle": "furniture and appliances salesperson – retail"
    },
    {
        "value": 9272,
        "NOCCode": "6421",
        "JobTitle": "furniture sales consultant – retail"
    },
    {
        "value": 9273,
        "NOCCode": "6421",
        "JobTitle": "furniture salesperson"
    },
    {
        "value": 9274,
        "NOCCode": "6421",
        "JobTitle": "furniture salesperson – retail"
    },
    {
        "value": 9275,
        "NOCCode": "6421",
        "JobTitle": "garden supplies sales clerk"
    },
    {
        "value": 9276,
        "NOCCode": "6421",
        "JobTitle": "garments and other textile products sales representative – retail"
    },
    {
        "value": 9277,
        "NOCCode": "6421",
        "JobTitle": "gift shop clerk"
    },
    {
        "value": 9278,
        "NOCCode": "6421",
        "JobTitle": "greeter, sales"
    },
    {
        "value": 9279,
        "NOCCode": "6421",
        "JobTitle": "greeting cards sales clerk"
    },
    {
        "value": 9280,
        "NOCCode": "6421",
        "JobTitle": "hardware store clerk"
    },
    {
        "value": 9281,
        "NOCCode": "6421",
        "JobTitle": "hardware store clerk – retail"
    },
    {
        "value": 9282,
        "NOCCode": "6421",
        "JobTitle": "hearing aid sales consultant – retail"
    },
    {
        "value": 9283,
        "NOCCode": "6421",
        "JobTitle": "hearing aid salesperson – retail"
    },
    {
        "value": 9284,
        "NOCCode": "6421",
        "JobTitle": "hospital television rental attendant"
    },
    {
        "value": 9285,
        "NOCCode": "6421",
        "JobTitle": "house trailer salesperson – retail"
    },
    {
        "value": 9286,
        "NOCCode": "6421",
        "JobTitle": "household appliance salesperson – retail"
    },
    {
        "value": 9287,
        "NOCCode": "6421",
        "JobTitle": "housewares salesperson – retail"
    },
    {
        "value": 9288,
        "NOCCode": "6421",
        "JobTitle": "hunting guns salesperson – retail"
    },
    {
        "value": 9289,
        "NOCCode": "6421",
        "JobTitle": "jewellery salesperson"
    },
    {
        "value": 9290,
        "NOCCode": "6421",
        "JobTitle": "jewellery salesperson – retail"
    },
    {
        "value": 9291,
        "NOCCode": "6421",
        "JobTitle": "kiosk sales clerk"
    },
    {
        "value": 9292,
        "NOCCode": "6421",
        "JobTitle": "lawn and garden supplies salesperson – retail"
    },
    {
        "value": 9293,
        "NOCCode": "6421",
        "JobTitle": "leasing agent, automobiles"
    },
    {
        "value": 9294,
        "NOCCode": "6421",
        "JobTitle": "leasing clerk, storage facility"
    },
    {
        "value": 9295,
        "NOCCode": "6421",
        "JobTitle": "leasing representative, automobiles"
    },
    {
        "value": 9296,
        "NOCCode": "6421",
        "JobTitle": "leasing representative, motor vehicles – retail"
    },
    {
        "value": 9297,
        "NOCCode": "6421",
        "JobTitle": "leather goods salesperson – retail"
    },
    {
        "value": 9298,
        "NOCCode": "6421",
        "JobTitle": "lighting appliance salesperson – retail"
    },
    {
        "value": 9299,
        "NOCCode": "6421",
        "JobTitle": "lighting salesperson – retail"
    },
    {
        "value": 9300,
        "NOCCode": "6421",
        "JobTitle": "liquor store sales clerk"
    },
    {
        "value": 9301,
        "NOCCode": "6421",
        "JobTitle": "lottery kiosk clerk"
    },
    {
        "value": 9302,
        "NOCCode": "6421",
        "JobTitle": "lottery sales representative – retail"
    },
    {
        "value": 9303,
        "NOCCode": "6421",
        "JobTitle": "magazine salesperson – retail"
    },
    {
        "value": 9304,
        "NOCCode": "6421",
        "JobTitle": "magazine shop clerk"
    },
    {
        "value": 9305,
        "NOCCode": "6421",
        "JobTitle": "meat clerk, supermarket"
    },
    {
        "value": 9306,
        "NOCCode": "6421",
        "JobTitle": "meat counter clerk"
    },
    {
        "value": 9307,
        "NOCCode": "6421",
        "JobTitle": "memorial salesperson – retail"
    },
    {
        "value": 9308,
        "NOCCode": "6421",
        "JobTitle": "men's clothing sales clerk"
    },
    {
        "value": 9309,
        "NOCCode": "6421",
        "JobTitle": "men's clothing salesperson – retail"
    },
    {
        "value": 9310,
        "NOCCode": "6421",
        "JobTitle": "men's wear sales clerk"
    },
    {
        "value": 9311,
        "NOCCode": "6421",
        "JobTitle": "mobile home salesperson – retail"
    },
    {
        "value": 9312,
        "NOCCode": "6421",
        "JobTitle": "motor vehicle leasing representative – retail"
    },
    {
        "value": 9313,
        "NOCCode": "6421",
        "JobTitle": "motor vehicle salesperson – retail"
    },
    {
        "value": 9314,
        "NOCCode": "6421",
        "JobTitle": "motorcycle salesperson – retail"
    },
    {
        "value": 9315,
        "NOCCode": "6421",
        "JobTitle": "musical instruments and supplies salesperson – retail"
    },
    {
        "value": 9316,
        "NOCCode": "6421",
        "JobTitle": "newsstand clerk"
    },
    {
        "value": 9317,
        "NOCCode": "6421",
        "JobTitle": "office equipment salesperson – retail"
    },
    {
        "value": 9318,
        "NOCCode": "6421",
        "JobTitle": "orthopedic shoes salesperson – retail"
    },
    {
        "value": 9319,
        "NOCCode": "6421",
        "JobTitle": "paint salesperson – retail"
    },
    {
        "value": 9320,
        "NOCCode": "6421",
        "JobTitle": "pastry sales clerk"
    },
    {
        "value": 9321,
        "NOCCode": "6421",
        "JobTitle": "personal computer salesperson – retail"
    },
    {
        "value": 9322,
        "NOCCode": "6421",
        "JobTitle": "pet store salesperson"
    },
    {
        "value": 9323,
        "NOCCode": "6421",
        "JobTitle": "pharmacy clerk"
    },
    {
        "value": 9324,
        "NOCCode": "6421",
        "JobTitle": "photographic equipment and supplies salesperson – retail"
    },
    {
        "value": 9325,
        "NOCCode": "6421",
        "JobTitle": "photographic studio clerk"
    },
    {
        "value": 9326,
        "NOCCode": "6421",
        "JobTitle": "picture framing consultant – retail"
    },
    {
        "value": 9327,
        "NOCCode": "6421",
        "JobTitle": "record shop sales clerk"
    },
    {
        "value": 9328,
        "NOCCode": "6421",
        "JobTitle": "record shop salesperson"
    },
    {
        "value": 9329,
        "NOCCode": "6421",
        "JobTitle": "record store sales clerk"
    },
    {
        "value": 9330,
        "NOCCode": "6421",
        "JobTitle": "record store salesperson"
    },
    {
        "value": 9331,
        "NOCCode": "6421",
        "JobTitle": "recreational equipment rental-shop attendant"
    },
    {
        "value": 9332,
        "NOCCode": "6421",
        "JobTitle": "recreational equipment salesperson – retail"
    },
    {
        "value": 9333,
        "NOCCode": "6421",
        "JobTitle": "rental agent – retail"
    },
    {
        "value": 9334,
        "NOCCode": "6421",
        "JobTitle": "rental clerk – retail"
    },
    {
        "value": 9335,
        "NOCCode": "6421",
        "JobTitle": "rental clerk, storage space"
    },
    {
        "value": 9336,
        "NOCCode": "6421",
        "JobTitle": "rental counter representative"
    },
    {
        "value": 9337,
        "NOCCode": "6421",
        "JobTitle": "rental-shop attendant, sporting goods"
    },
    {
        "value": 9338,
        "NOCCode": "6421",
        "JobTitle": "representative, car-rental counter"
    },
    {
        "value": 9339,
        "NOCCode": "6421",
        "JobTitle": "retail sales associate"
    },
    {
        "value": 9340,
        "NOCCode": "6421",
        "JobTitle": "retail sales clerk"
    },
    {
        "value": 9341,
        "NOCCode": "6421",
        "JobTitle": "retail sales representative"
    },
    {
        "value": 9342,
        "NOCCode": "6421",
        "JobTitle": "retail salesperson"
    },
    {
        "value": 9343,
        "NOCCode": "6421",
        "JobTitle": "sales and rental clerk"
    },
    {
        "value": 9344,
        "NOCCode": "6421",
        "JobTitle": "sales assistant"
    },
    {
        "value": 9345,
        "NOCCode": "6421",
        "JobTitle": "sales associate"
    },
    {
        "value": 9346,
        "NOCCode": "6421",
        "JobTitle": "sales associate – retail"
    },
    {
        "value": 9347,
        "NOCCode": "6421",
        "JobTitle": "sales clerk"
    },
    {
        "value": 9348,
        "NOCCode": "6421",
        "JobTitle": "sales consultant – retail"
    },
    {
        "value": 9349,
        "NOCCode": "6421",
        "JobTitle": "sales greeter"
    },
    {
        "value": 9350,
        "NOCCode": "6421",
        "JobTitle": "sales order clerk"
    },
    {
        "value": 9351,
        "NOCCode": "6421",
        "JobTitle": "sales promotion clerk"
    },
    {
        "value": 9352,
        "NOCCode": "6421",
        "JobTitle": "sales representative – retail"
    },
    {
        "value": 9353,
        "NOCCode": "6421",
        "JobTitle": "sales representative, automobiles – retail"
    },
    {
        "value": 9354,
        "NOCCode": "6421",
        "JobTitle": "sales representative, cable television service"
    },
    {
        "value": 9355,
        "NOCCode": "6421",
        "JobTitle": "sales representative, car – retail"
    },
    {
        "value": 9356,
        "NOCCode": "6421",
        "JobTitle": "sales representative, garments and other textile products – retail"
    },
    {
        "value": 9357,
        "NOCCode": "6421",
        "JobTitle": "salesperson – retail"
    },
    {
        "value": 9358,
        "NOCCode": "6421",
        "JobTitle": "salesperson, aluminum products – retail"
    },
    {
        "value": 9359,
        "NOCCode": "6421",
        "JobTitle": "salesperson, automobile accessories – retail"
    },
    {
        "value": 9360,
        "NOCCode": "6421",
        "JobTitle": "salesperson, boats and marine equipment – retail"
    },
    {
        "value": 9361,
        "NOCCode": "6421",
        "JobTitle": "salesperson, books – retail"
    },
    {
        "value": 9362,
        "NOCCode": "6421",
        "JobTitle": "salesperson, building supplies – retail"
    },
    {
        "value": 9363,
        "NOCCode": "6421",
        "JobTitle": "salesperson, china and crystal – retail"
    },
    {
        "value": 9364,
        "NOCCode": "6421",
        "JobTitle": "salesperson, cosmetics – retail"
    },
    {
        "value": 9365,
        "NOCCode": "6421",
        "JobTitle": "salesperson, drapery and upholstery – retail"
    },
    {
        "value": 9366,
        "NOCCode": "6421",
        "JobTitle": "salesperson, education courses"
    },
    {
        "value": 9367,
        "NOCCode": "6421",
        "JobTitle": "salesperson, fish shop"
    },
    {
        "value": 9368,
        "NOCCode": "6421",
        "JobTitle": "salesperson, floor coverings – retail"
    },
    {
        "value": 9369,
        "NOCCode": "6421",
        "JobTitle": "salesperson, florist shop"
    },
    {
        "value": 9370,
        "NOCCode": "6421",
        "JobTitle": "salesperson, footwear – retail"
    },
    {
        "value": 9371,
        "NOCCode": "6421",
        "JobTitle": "salesperson, furniture and appliances – retail"
    },
    {
        "value": 9372,
        "NOCCode": "6421",
        "JobTitle": "salesperson, hardware – retail"
    },
    {
        "value": 9373,
        "NOCCode": "6421",
        "JobTitle": "salesperson, hearing aids – retail"
    },
    {
        "value": 9374,
        "NOCCode": "6421",
        "JobTitle": "salesperson, hobby shop"
    },
    {
        "value": 9375,
        "NOCCode": "6421",
        "JobTitle": "salesperson, house trailers – retail"
    },
    {
        "value": 9376,
        "NOCCode": "6421",
        "JobTitle": "salesperson, housewares – retail"
    },
    {
        "value": 9377,
        "NOCCode": "6421",
        "JobTitle": "salesperson, jewellery – retail"
    },
    {
        "value": 9378,
        "NOCCode": "6421",
        "JobTitle": "salesperson, lighting appliances – retail"
    },
    {
        "value": 9379,
        "NOCCode": "6421",
        "JobTitle": "salesperson, memorial – retail"
    },
    {
        "value": 9380,
        "NOCCode": "6421",
        "JobTitle": "salesperson, motor vehicles – retail"
    },
    {
        "value": 9381,
        "NOCCode": "6421",
        "JobTitle": "salesperson, musical instruments and supplies – retail"
    },
    {
        "value": 9382,
        "NOCCode": "6421",
        "JobTitle": "salesperson, orthopedic shoes – retail"
    },
    {
        "value": 9383,
        "NOCCode": "6421",
        "JobTitle": "salesperson, photographic equipment and supplies – retail"
    },
    {
        "value": 9384,
        "NOCCode": "6421",
        "JobTitle": "salesperson, sewing machines – retail"
    },
    {
        "value": 9385,
        "NOCCode": "6421",
        "JobTitle": "salesperson, sporting goods – retail"
    },
    {
        "value": 9386,
        "NOCCode": "6421",
        "JobTitle": "salesperson, tires – retail"
    },
    {
        "value": 9387,
        "NOCCode": "6421",
        "JobTitle": "salesperson, toy store"
    },
    {
        "value": 9388,
        "NOCCode": "6421",
        "JobTitle": "salesperson, training courses"
    },
    {
        "value": 9389,
        "NOCCode": "6421",
        "JobTitle": "salesperson, used cars – retail"
    },
    {
        "value": 9390,
        "NOCCode": "6421",
        "JobTitle": "salesperson, video equipment – retail"
    },
    {
        "value": 9391,
        "NOCCode": "6421",
        "JobTitle": "salesperson, wearing apparel – retail"
    },
    {
        "value": 9392,
        "NOCCode": "6421",
        "JobTitle": "salesperson, yard goods – retail"
    },
    {
        "value": 9393,
        "NOCCode": "6421",
        "JobTitle": "scrap metal salesperson"
    },
    {
        "value": 9394,
        "NOCCode": "6421",
        "JobTitle": "seafood counter clerk"
    },
    {
        "value": 9395,
        "NOCCode": "6421",
        "JobTitle": "seafood sales clerk"
    },
    {
        "value": 9396,
        "NOCCode": "6421",
        "JobTitle": "seafood sales clerk – retail"
    },
    {
        "value": 9397,
        "NOCCode": "6421",
        "JobTitle": "sewing machine salesperson – retail"
    },
    {
        "value": 9398,
        "NOCCode": "6421",
        "JobTitle": "shoe salesperson – retail"
    },
    {
        "value": 9399,
        "NOCCode": "6421",
        "JobTitle": "shop clerk"
    },
    {
        "value": 9400,
        "NOCCode": "6421",
        "JobTitle": "silverware salesperson – retail"
    },
    {
        "value": 9401,
        "NOCCode": "6421",
        "JobTitle": "skate shop attendant"
    },
    {
        "value": 9402,
        "NOCCode": "6421",
        "JobTitle": "ski shop retail clerk"
    },
    {
        "value": 9403,
        "NOCCode": "6421",
        "JobTitle": "smoke shop clerk"
    },
    {
        "value": 9404,
        "NOCCode": "6421",
        "JobTitle": "sporting goods rental-shop attendant"
    },
    {
        "value": 9405,
        "NOCCode": "6421",
        "JobTitle": "sporting goods salesperson – retail"
    },
    {
        "value": 9406,
        "NOCCode": "6421",
        "JobTitle": "storage facility rental clerk"
    },
    {
        "value": 9407,
        "NOCCode": "6421",
        "JobTitle": "store clerk"
    },
    {
        "value": 9408,
        "NOCCode": "6421",
        "JobTitle": "telephone equipment salesperson – retail"
    },
    {
        "value": 9409,
        "NOCCode": "6421",
        "JobTitle": "television cable service salesperson"
    },
    {
        "value": 9410,
        "NOCCode": "6421",
        "JobTitle": "television salesperson – retail"
    },
    {
        "value": 9411,
        "NOCCode": "6421",
        "JobTitle": "tire salesperson – retail"
    },
    {
        "value": 9412,
        "NOCCode": "6421",
        "JobTitle": "tobacco product clerk"
    },
    {
        "value": 9413,
        "NOCCode": "6421",
        "JobTitle": "tobacco shop clerk"
    },
    {
        "value": 9414,
        "NOCCode": "6421",
        "JobTitle": "tool and equipment rental clerk"
    },
    {
        "value": 9415,
        "NOCCode": "6421",
        "JobTitle": "toy store salesperson"
    },
    {
        "value": 9416,
        "NOCCode": "6421",
        "JobTitle": "training course salesperson"
    },
    {
        "value": 9417,
        "NOCCode": "6421",
        "JobTitle": "travelling salesperson – retail"
    },
    {
        "value": 9418,
        "NOCCode": "6421",
        "JobTitle": "truck leasing representative"
    },
    {
        "value": 9419,
        "NOCCode": "6421",
        "JobTitle": "truck rental clerk"
    },
    {
        "value": 9420,
        "NOCCode": "6421",
        "JobTitle": "truck salesperson"
    },
    {
        "value": 9421,
        "NOCCode": "6421",
        "JobTitle": "upholstery and furniture repair salesperson"
    },
    {
        "value": 9422,
        "NOCCode": "6421",
        "JobTitle": "used-car salesperson – retail"
    },
    {
        "value": 9423,
        "NOCCode": "6421",
        "JobTitle": "video equipment salesperson – retail"
    },
    {
        "value": 9424,
        "NOCCode": "6421",
        "JobTitle": "video rental clerk"
    },
    {
        "value": 9425,
        "NOCCode": "6421",
        "JobTitle": "wallpaper salesperson – retail"
    },
    {
        "value": 9426,
        "NOCCode": "6421",
        "JobTitle": "watch sales clerk"
    },
    {
        "value": 9427,
        "NOCCode": "6421",
        "JobTitle": "watch salesperson – retail"
    },
    {
        "value": 9428,
        "NOCCode": "6421",
        "JobTitle": "wearing apparel salesperson – retail"
    },
    {
        "value": 9429,
        "NOCCode": "6421",
        "JobTitle": "wine store sales clerk"
    },
    {
        "value": 9430,
        "NOCCode": "6421",
        "JobTitle": "women's clothing sales clerk"
    },
    {
        "value": 9431,
        "NOCCode": "6421",
        "JobTitle": "women's wear sales clerk"
    },
    {
        "value": 9432,
        "NOCCode": "6421",
        "JobTitle": "yard goods salesperson – retail"
    },
    {
        "value": 9433,
        "NOCCode": "6511",
        "JobTitle": "chief host/hostess – food services"
    },
    {
        "value": 9434,
        "NOCCode": "6511",
        "JobTitle": "dining room host/hostess"
    },
    {
        "value": 9435,
        "NOCCode": "6511",
        "JobTitle": "host/hostess – restaurant or cocktail lounge"
    },
    {
        "value": 9436,
        "NOCCode": "6511",
        "JobTitle": "host/hostess, dining room"
    },
    {
        "value": 9437,
        "NOCCode": "6511",
        "JobTitle": "host/hostess, food service"
    },
    {
        "value": 9438,
        "NOCCode": "6511",
        "JobTitle": "host/hostess, restaurant"
    },
    {
        "value": 9439,
        "NOCCode": "6511",
        "JobTitle": "maître d'"
    },
    {
        "value": 9440,
        "NOCCode": "6511",
        "JobTitle": "maître d'hôtel"
    },
    {
        "value": 9441,
        "NOCCode": "6511",
        "JobTitle": "restaurant host/hostess"
    },
    {
        "value": 9442,
        "NOCCode": "6512",
        "JobTitle": "attendant, bar"
    },
    {
        "value": 9443,
        "NOCCode": "6512",
        "JobTitle": "bar attendant"
    },
    {
        "value": 9444,
        "NOCCode": "6512",
        "JobTitle": "bar steward"
    },
    {
        "value": 9445,
        "NOCCode": "6512",
        "JobTitle": "barkeeper"
    },
    {
        "value": 9446,
        "NOCCode": "6512",
        "JobTitle": "barmaid"
    },
    {
        "value": 9447,
        "NOCCode": "6512",
        "JobTitle": "barman/barwoman"
    },
    {
        "value": 9448,
        "NOCCode": "6512",
        "JobTitle": "barman/woman, beer"
    },
    {
        "value": 9449,
        "NOCCode": "6512",
        "JobTitle": "bartender"
    },
    {
        "value": 9450,
        "NOCCode": "6512",
        "JobTitle": "bartender, tap"
    },
    {
        "value": 9451,
        "NOCCode": "6512",
        "JobTitle": "bartenders supervisor"
    },
    {
        "value": 9452,
        "NOCCode": "6512",
        "JobTitle": "beer barman/woman"
    },
    {
        "value": 9453,
        "NOCCode": "6512",
        "JobTitle": "head barmaid"
    },
    {
        "value": 9454,
        "NOCCode": "6512",
        "JobTitle": "head barman/barwoman"
    },
    {
        "value": 9455,
        "NOCCode": "6512",
        "JobTitle": "head bartender"
    },
    {
        "value": 9456,
        "NOCCode": "6512",
        "JobTitle": "lounge supervisor-bartender"
    },
    {
        "value": 9457,
        "NOCCode": "6512",
        "JobTitle": "managing bartender"
    },
    {
        "value": 9458,
        "NOCCode": "6512",
        "JobTitle": "service bartender"
    },
    {
        "value": 9459,
        "NOCCode": "6512",
        "JobTitle": "supervisor, bartenders"
    },
    {
        "value": 9460,
        "NOCCode": "6512",
        "JobTitle": "supervisor-bartender, lounge"
    },
    {
        "value": 9461,
        "NOCCode": "6512",
        "JobTitle": "tapman/woman"
    },
    {
        "value": 9462,
        "NOCCode": "6522",
        "JobTitle": "aircraft flight attendant"
    },
    {
        "value": 9463,
        "NOCCode": "6522",
        "JobTitle": "attendant, commissary – air transport"
    },
    {
        "value": 9464,
        "NOCCode": "6522",
        "JobTitle": "attendant, flight"
    },
    {
        "value": 9465,
        "NOCCode": "6522",
        "JobTitle": "chief steward, ship"
    },
    {
        "value": 9466,
        "NOCCode": "6522",
        "JobTitle": "commissary attendant – air transport"
    },
    {
        "value": 9467,
        "NOCCode": "6522",
        "JobTitle": "CSD (customer service director) – water transportation"
    },
    {
        "value": 9468,
        "NOCCode": "6522",
        "JobTitle": "customer service director – water transportation"
    },
    {
        "value": 9469,
        "NOCCode": "6522",
        "JobTitle": "customer service director (CSD) – water transportation"
    },
    {
        "value": 9470,
        "NOCCode": "6522",
        "JobTitle": "director, flight service"
    },
    {
        "value": 9471,
        "NOCCode": "6522",
        "JobTitle": "director, passenger service – water transportation"
    },
    {
        "value": 9472,
        "NOCCode": "6522",
        "JobTitle": "flight attendant"
    },
    {
        "value": 9473,
        "NOCCode": "6522",
        "JobTitle": "flight purser"
    },
    {
        "value": 9474,
        "NOCCode": "6522",
        "JobTitle": "flight service director"
    },
    {
        "value": 9475,
        "NOCCode": "6522",
        "JobTitle": "in-charge flight attendant"
    },
    {
        "value": 9476,
        "NOCCode": "6522",
        "JobTitle": "in-flight service manager"
    },
    {
        "value": 9477,
        "NOCCode": "6522",
        "JobTitle": "manager, in-flight service"
    },
    {
        "value": 9478,
        "NOCCode": "6522",
        "JobTitle": "passenger service director – water transportation"
    },
    {
        "value": 9479,
        "NOCCode": "6522",
        "JobTitle": "purser – air transportation"
    },
    {
        "value": 9480,
        "NOCCode": "6522",
        "JobTitle": "purser – water transport"
    },
    {
        "value": 9481,
        "NOCCode": "6522",
        "JobTitle": "purser, airline"
    },
    {
        "value": 9482,
        "NOCCode": "6522",
        "JobTitle": "ship purser"
    },
    {
        "value": 9483,
        "NOCCode": "6522",
        "JobTitle": "ship supply officer"
    },
    {
        "value": 9484,
        "NOCCode": "6522",
        "JobTitle": "supply officer, ship"
    },
    {
        "value": 9485,
        "NOCCode": "6523",
        "JobTitle": "agent ticket and information – airline"
    },
    {
        "value": 9486,
        "NOCCode": "6523",
        "JobTitle": "agent, cargo – airline"
    },
    {
        "value": 9487,
        "NOCCode": "6523",
        "JobTitle": "agent, check-in – airline"
    },
    {
        "value": 9488,
        "NOCCode": "6523",
        "JobTitle": "agent, commissary – airline"
    },
    {
        "value": 9489,
        "NOCCode": "6523",
        "JobTitle": "agent, counter services – airline"
    },
    {
        "value": 9490,
        "NOCCode": "6523",
        "JobTitle": "agent, customer sales – airline"
    },
    {
        "value": 9491,
        "NOCCode": "6523",
        "JobTitle": "agent, customer service – airline"
    },
    {
        "value": 9492,
        "NOCCode": "6523",
        "JobTitle": "agent, load control – airline"
    },
    {
        "value": 9493,
        "NOCCode": "6523",
        "JobTitle": "agent, passengers – airline"
    },
    {
        "value": 9494,
        "NOCCode": "6523",
        "JobTitle": "agent, reservations – airline"
    },
    {
        "value": 9495,
        "NOCCode": "6523",
        "JobTitle": "agent, station – airline"
    },
    {
        "value": 9496,
        "NOCCode": "6523",
        "JobTitle": "agent, station services – airline"
    },
    {
        "value": 9497,
        "NOCCode": "6523",
        "JobTitle": "agent, tickets – airline"
    },
    {
        "value": 9498,
        "NOCCode": "6523",
        "JobTitle": "airline baggage agent"
    },
    {
        "value": 9499,
        "NOCCode": "6523",
        "JobTitle": "airline cargo agent"
    },
    {
        "value": 9500,
        "NOCCode": "6523",
        "JobTitle": "airline customer service representative"
    },
    {
        "value": 9501,
        "NOCCode": "6523",
        "JobTitle": "airline sales and service agent"
    },
    {
        "value": 9502,
        "NOCCode": "6523",
        "JobTitle": "baggage agent – airline"
    },
    {
        "value": 9503,
        "NOCCode": "6523",
        "JobTitle": "baggage tracer, airline"
    },
    {
        "value": 9504,
        "NOCCode": "6523",
        "JobTitle": "baggage tracing agent – airline"
    },
    {
        "value": 9505,
        "NOCCode": "6523",
        "JobTitle": "cargo agent – airline"
    },
    {
        "value": 9506,
        "NOCCode": "6523",
        "JobTitle": "cargo customer service representative – airline"
    },
    {
        "value": 9507,
        "NOCCode": "6523",
        "JobTitle": "certified load control agent – airline"
    },
    {
        "value": 9508,
        "NOCCode": "6523",
        "JobTitle": "certified load planner – airline"
    },
    {
        "value": 9509,
        "NOCCode": "6523",
        "JobTitle": "check-in agent – airline"
    },
    {
        "value": 9510,
        "NOCCode": "6523",
        "JobTitle": "clerk, reservations – airline"
    },
    {
        "value": 9511,
        "NOCCode": "6523",
        "JobTitle": "client service representative – airline"
    },
    {
        "value": 9512,
        "NOCCode": "6523",
        "JobTitle": "commissary agent – airline"
    },
    {
        "value": 9513,
        "NOCCode": "6523",
        "JobTitle": "counter services agent, airline"
    },
    {
        "value": 9514,
        "NOCCode": "6523",
        "JobTitle": "customer sales agent – airline"
    },
    {
        "value": 9515,
        "NOCCode": "6523",
        "JobTitle": "customer service agent, airline"
    },
    {
        "value": 9516,
        "NOCCode": "6523",
        "JobTitle": "customer service representative – airline"
    },
    {
        "value": 9517,
        "NOCCode": "6523",
        "JobTitle": "flight agent"
    },
    {
        "value": 9518,
        "NOCCode": "6523",
        "JobTitle": "load agent – airline"
    },
    {
        "value": 9519,
        "NOCCode": "6523",
        "JobTitle": "load control agent – airline"
    },
    {
        "value": 9520,
        "NOCCode": "6523",
        "JobTitle": "load planner, airline"
    },
    {
        "value": 9521,
        "NOCCode": "6523",
        "JobTitle": "passenger agent, airline"
    },
    {
        "value": 9522,
        "NOCCode": "6523",
        "JobTitle": "passenger sales agent – airline"
    },
    {
        "value": 9523,
        "NOCCode": "6523",
        "JobTitle": "passenger sales representative – airline"
    },
    {
        "value": 9524,
        "NOCCode": "6523",
        "JobTitle": "passenger service representative"
    },
    {
        "value": 9525,
        "NOCCode": "6523",
        "JobTitle": "passenger service representative – airline"
    },
    {
        "value": 9526,
        "NOCCode": "6523",
        "JobTitle": "representative, client service – airline"
    },
    {
        "value": 9527,
        "NOCCode": "6523",
        "JobTitle": "representative, customer service – airline"
    },
    {
        "value": 9528,
        "NOCCode": "6523",
        "JobTitle": "reservation agent – airline"
    },
    {
        "value": 9529,
        "NOCCode": "6523",
        "JobTitle": "reservation agent, airline"
    },
    {
        "value": 9530,
        "NOCCode": "6523",
        "JobTitle": "reservation clerk – airline"
    },
    {
        "value": 9531,
        "NOCCode": "6523",
        "JobTitle": "reservation sales agent – airline"
    },
    {
        "value": 9532,
        "NOCCode": "6523",
        "JobTitle": "sales agent, passengers – airline"
    },
    {
        "value": 9533,
        "NOCCode": "6523",
        "JobTitle": "station agent, airline"
    },
    {
        "value": 9534,
        "NOCCode": "6523",
        "JobTitle": "station services agent – airline"
    },
    {
        "value": 9535,
        "NOCCode": "6523",
        "JobTitle": "ticket agent, airline"
    },
    {
        "value": 9536,
        "NOCCode": "6523",
        "JobTitle": "ticket and information agent – airline"
    },
    {
        "value": 9537,
        "NOCCode": "6523",
        "JobTitle": "tracer, baggage – airline"
    },
    {
        "value": 9538,
        "NOCCode": "6524",
        "JobTitle": "agent, counter sales – railway"
    },
    {
        "value": 9539,
        "NOCCode": "6524",
        "JobTitle": "agent, freight – railway"
    },
    {
        "value": 9540,
        "NOCCode": "6524",
        "JobTitle": "agent, passengers – railway"
    },
    {
        "value": 9541,
        "NOCCode": "6524",
        "JobTitle": "agent, passengers (except airline)"
    },
    {
        "value": 9542,
        "NOCCode": "6524",
        "JobTitle": "agent, railway express"
    },
    {
        "value": 9543,
        "NOCCode": "6524",
        "JobTitle": "agent, reservations – railway"
    },
    {
        "value": 9544,
        "NOCCode": "6524",
        "JobTitle": "agent, ship cargo"
    },
    {
        "value": 9545,
        "NOCCode": "6524",
        "JobTitle": "agent, tickets – bus line"
    },
    {
        "value": 9546,
        "NOCCode": "6524",
        "JobTitle": "agent, tickets – cruise line"
    },
    {
        "value": 9547,
        "NOCCode": "6524",
        "JobTitle": "agent, tickets – transit station"
    },
    {
        "value": 9548,
        "NOCCode": "6524",
        "JobTitle": "baggage agent – railway"
    },
    {
        "value": 9549,
        "NOCCode": "6524",
        "JobTitle": "baggage agent (except airline)"
    },
    {
        "value": 9550,
        "NOCCode": "6524",
        "JobTitle": "baggage clerk (except airline)"
    },
    {
        "value": 9551,
        "NOCCode": "6524",
        "JobTitle": "bookings clerk – railway"
    },
    {
        "value": 9552,
        "NOCCode": "6524",
        "JobTitle": "bookings clerk – wholesale travel"
    },
    {
        "value": 9553,
        "NOCCode": "6524",
        "JobTitle": "bookings clerk (except airline)"
    },
    {
        "value": 9554,
        "NOCCode": "6524",
        "JobTitle": "bus ticket agent"
    },
    {
        "value": 9555,
        "NOCCode": "6524",
        "JobTitle": "cargo customer service representative – bus line"
    },
    {
        "value": 9556,
        "NOCCode": "6524",
        "JobTitle": "cargo customer service representative – marine shipping"
    },
    {
        "value": 9557,
        "NOCCode": "6524",
        "JobTitle": "cargo customer service representative – railway"
    },
    {
        "value": 9558,
        "NOCCode": "6524",
        "JobTitle": "check-in agent (except airline)"
    },
    {
        "value": 9559,
        "NOCCode": "6524",
        "JobTitle": "clerk, bookings – railway"
    },
    {
        "value": 9560,
        "NOCCode": "6524",
        "JobTitle": "clerk, bookings – wholesale travel"
    },
    {
        "value": 9561,
        "NOCCode": "6524",
        "JobTitle": "clerk, bookings (except airline)"
    },
    {
        "value": 9562,
        "NOCCode": "6524",
        "JobTitle": "clerk, reservations – cruise line"
    },
    {
        "value": 9563,
        "NOCCode": "6524",
        "JobTitle": "clerk, reservations – railway"
    },
    {
        "value": 9564,
        "NOCCode": "6524",
        "JobTitle": "clerk, reservations – wholesale travel"
    },
    {
        "value": 9565,
        "NOCCode": "6524",
        "JobTitle": "clerk, reservations (except airline)"
    },
    {
        "value": 9566,
        "NOCCode": "6524",
        "JobTitle": "clerk, stowage"
    },
    {
        "value": 9567,
        "NOCCode": "6524",
        "JobTitle": "client service representative – bus line"
    },
    {
        "value": 9568,
        "NOCCode": "6524",
        "JobTitle": "counter clerk (except airline)"
    },
    {
        "value": 9569,
        "NOCCode": "6524",
        "JobTitle": "counter sales agent – railway"
    },
    {
        "value": 9570,
        "NOCCode": "6524",
        "JobTitle": "counter sales agent (except airline)"
    },
    {
        "value": 9571,
        "NOCCode": "6524",
        "JobTitle": "counter service agent (except airline)"
    },
    {
        "value": 9572,
        "NOCCode": "6524",
        "JobTitle": "counter service agent, railway"
    },
    {
        "value": 9573,
        "NOCCode": "6524",
        "JobTitle": "customer sales and service agent (except airline)"
    },
    {
        "value": 9574,
        "NOCCode": "6524",
        "JobTitle": "customer service agent – bus line"
    },
    {
        "value": 9575,
        "NOCCode": "6524",
        "JobTitle": "customer service representative – bus line"
    },
    {
        "value": 9576,
        "NOCCode": "6524",
        "JobTitle": "freight agent – railway"
    },
    {
        "value": 9577,
        "NOCCode": "6524",
        "JobTitle": "freight agent (except airline)"
    },
    {
        "value": 9578,
        "NOCCode": "6524",
        "JobTitle": "luggage checker (except airline)"
    },
    {
        "value": 9579,
        "NOCCode": "6524",
        "JobTitle": "passenger agent – railway"
    },
    {
        "value": 9580,
        "NOCCode": "6524",
        "JobTitle": "passenger agent (except airline)"
    },
    {
        "value": 9581,
        "NOCCode": "6524",
        "JobTitle": "railway express agent"
    },
    {
        "value": 9582,
        "NOCCode": "6524",
        "JobTitle": "railway passenger agent"
    },
    {
        "value": 9583,
        "NOCCode": "6524",
        "JobTitle": "reservations agent – cruise line"
    },
    {
        "value": 9584,
        "NOCCode": "6524",
        "JobTitle": "reservations agent – railway"
    },
    {
        "value": 9585,
        "NOCCode": "6524",
        "JobTitle": "reservations agent (except airline)"
    },
    {
        "value": 9586,
        "NOCCode": "6524",
        "JobTitle": "reservations clerk – cruise line"
    },
    {
        "value": 9587,
        "NOCCode": "6524",
        "JobTitle": "reservations clerk – wholesale travel"
    },
    {
        "value": 9588,
        "NOCCode": "6524",
        "JobTitle": "reservations clerk (except airline)"
    },
    {
        "value": 9589,
        "NOCCode": "6524",
        "JobTitle": "reservations clerk, railway"
    },
    {
        "value": 9590,
        "NOCCode": "6524",
        "JobTitle": "sales agent – railway"
    },
    {
        "value": 9591,
        "NOCCode": "6524",
        "JobTitle": "sales agent, counter – railway"
    },
    {
        "value": 9592,
        "NOCCode": "6524",
        "JobTitle": "sales agent, counter (except airline)"
    },
    {
        "value": 9593,
        "NOCCode": "6524",
        "JobTitle": "ship cargo agent"
    },
    {
        "value": 9594,
        "NOCCode": "6524",
        "JobTitle": "station agent – railway"
    },
    {
        "value": 9595,
        "NOCCode": "6524",
        "JobTitle": "stowage clerk"
    },
    {
        "value": 9596,
        "NOCCode": "6524",
        "JobTitle": "ticket agent – bus line"
    },
    {
        "value": 9597,
        "NOCCode": "6524",
        "JobTitle": "ticket agent – cruise line"
    },
    {
        "value": 9598,
        "NOCCode": "6524",
        "JobTitle": "ticket agent – transit station"
    },
    {
        "value": 9599,
        "NOCCode": "6524",
        "JobTitle": "ticket agent (except airline)"
    },
    {
        "value": 9600,
        "NOCCode": "6524",
        "JobTitle": "ticket and customer service clerk (except airline)"
    },
    {
        "value": 9601,
        "NOCCode": "6524",
        "JobTitle": "ticket and information clerk (except airline)"
    },
    {
        "value": 9602,
        "NOCCode": "6524",
        "JobTitle": "ticket clerk – railway"
    },
    {
        "value": 9603,
        "NOCCode": "6524",
        "JobTitle": "ticket clerk (except airline)"
    },
    {
        "value": 9604,
        "NOCCode": "6524",
        "JobTitle": "transit station ticket agent"
    },
    {
        "value": 9605,
        "NOCCode": "6524",
        "JobTitle": "transportation agent (except airline)"
    },
    {
        "value": 9606,
        "NOCCode": "6525",
        "JobTitle": "clerk, front desk – hotel"
    },
    {
        "value": 9607,
        "NOCCode": "6525",
        "JobTitle": "clerk, front office – hotel"
    },
    {
        "value": 9608,
        "NOCCode": "6525",
        "JobTitle": "clerk, hotel"
    },
    {
        "value": 9609,
        "NOCCode": "6525",
        "JobTitle": "clerk, hotel front desk"
    },
    {
        "value": 9610,
        "NOCCode": "6525",
        "JobTitle": "clerk, night – hotel"
    },
    {
        "value": 9611,
        "NOCCode": "6525",
        "JobTitle": "clerk, reservations – hotel"
    },
    {
        "value": 9612,
        "NOCCode": "6525",
        "JobTitle": "clerk, room – hotel"
    },
    {
        "value": 9613,
        "NOCCode": "6525",
        "JobTitle": "day clerk – hotel"
    },
    {
        "value": 9614,
        "NOCCode": "6525",
        "JobTitle": "desk clerk, hotel"
    },
    {
        "value": 9615,
        "NOCCode": "6525",
        "JobTitle": "front desk agent"
    },
    {
        "value": 9616,
        "NOCCode": "6525",
        "JobTitle": "front desk clerk, hotel"
    },
    {
        "value": 9617,
        "NOCCode": "6525",
        "JobTitle": "front desk hotel clerk"
    },
    {
        "value": 9618,
        "NOCCode": "6525",
        "JobTitle": "front office clerk, hotel"
    },
    {
        "value": 9619,
        "NOCCode": "6525",
        "JobTitle": "front office hotel clerk"
    },
    {
        "value": 9620,
        "NOCCode": "6525",
        "JobTitle": "guest service agent"
    },
    {
        "value": 9621,
        "NOCCode": "6525",
        "JobTitle": "guest service representative"
    },
    {
        "value": 9622,
        "NOCCode": "6525",
        "JobTitle": "guest service representative – hotel"
    },
    {
        "value": 9623,
        "NOCCode": "6525",
        "JobTitle": "guest services agent – hotel"
    },
    {
        "value": 9624,
        "NOCCode": "6525",
        "JobTitle": "hotel clerk"
    },
    {
        "value": 9625,
        "NOCCode": "6525",
        "JobTitle": "hotel clerk, front office"
    },
    {
        "value": 9626,
        "NOCCode": "6525",
        "JobTitle": "hotel concierge"
    },
    {
        "value": 9627,
        "NOCCode": "6525",
        "JobTitle": "hotel front desk clerk"
    },
    {
        "value": 9628,
        "NOCCode": "6525",
        "JobTitle": "hotel front office clerk"
    },
    {
        "value": 9629,
        "NOCCode": "6525",
        "JobTitle": "hotel night auditor"
    },
    {
        "value": 9630,
        "NOCCode": "6525",
        "JobTitle": "hotel receptionist"
    },
    {
        "value": 9631,
        "NOCCode": "6525",
        "JobTitle": "night auditor – hotel"
    },
    {
        "value": 9632,
        "NOCCode": "6525",
        "JobTitle": "night clerk"
    },
    {
        "value": 9633,
        "NOCCode": "6525",
        "JobTitle": "night clerk – hotel"
    },
    {
        "value": 9634,
        "NOCCode": "6525",
        "JobTitle": "reception clerk – hotel"
    },
    {
        "value": 9635,
        "NOCCode": "6525",
        "JobTitle": "receptionist, hotel"
    },
    {
        "value": 9636,
        "NOCCode": "6525",
        "JobTitle": "reservations clerk – hotel"
    },
    {
        "value": 9637,
        "NOCCode": "6525",
        "JobTitle": "room clerk"
    },
    {
        "value": 9638,
        "NOCCode": "6525",
        "JobTitle": "room clerk – hotel"
    },
    {
        "value": 9639,
        "NOCCode": "6525",
        "JobTitle": "room service clerk – hotel"
    },
    {
        "value": 9640,
        "NOCCode": "6525",
        "JobTitle": "service agent, guests – hotel"
    },
    {
        "value": 9641,
        "NOCCode": "6525",
        "JobTitle": "superintendent of services – hotel"
    },
    {
        "value": 9642,
        "NOCCode": "6531",
        "JobTitle": "adventure travel site interpreter"
    },
    {
        "value": 9643,
        "NOCCode": "6531",
        "JobTitle": "bicycle tour guide"
    },
    {
        "value": 9644,
        "NOCCode": "6531",
        "JobTitle": "boat tour guide"
    },
    {
        "value": 9645,
        "NOCCode": "6531",
        "JobTitle": "bus tour guide"
    },
    {
        "value": 9646,
        "NOCCode": "6531",
        "JobTitle": "calèche driver"
    },
    {
        "value": 9647,
        "NOCCode": "6531",
        "JobTitle": "calèche operator"
    },
    {
        "value": 9648,
        "NOCCode": "6531",
        "JobTitle": "escort, tour"
    },
    {
        "value": 9649,
        "NOCCode": "6531",
        "JobTitle": "escort, travel"
    },
    {
        "value": 9650,
        "NOCCode": "6531",
        "JobTitle": "establishment guide"
    },
    {
        "value": 9651,
        "NOCCode": "6531",
        "JobTitle": "factory tour guide"
    },
    {
        "value": 9652,
        "NOCCode": "6531",
        "JobTitle": "guide, establishment"
    },
    {
        "value": 9653,
        "NOCCode": "6531",
        "JobTitle": "guide, horse-drawn vehicle sightseeing tours"
    },
    {
        "value": 9654,
        "NOCCode": "6531",
        "JobTitle": "guide, sightseeing"
    },
    {
        "value": 9655,
        "NOCCode": "6531",
        "JobTitle": "guide, travel"
    },
    {
        "value": 9656,
        "NOCCode": "6531",
        "JobTitle": "horse-drawn vehicle sightseeing tour guide"
    },
    {
        "value": 9657,
        "NOCCode": "6531",
        "JobTitle": "host/hostess, ski resort"
    },
    {
        "value": 9658,
        "NOCCode": "6531",
        "JobTitle": "industrial site interpreter"
    },
    {
        "value": 9659,
        "NOCCode": "6531",
        "JobTitle": "interpreter, adventure site"
    },
    {
        "value": 9660,
        "NOCCode": "6531",
        "JobTitle": "interpreter, industrial site"
    },
    {
        "value": 9661,
        "NOCCode": "6531",
        "JobTitle": "local tour guide"
    },
    {
        "value": 9662,
        "NOCCode": "6531",
        "JobTitle": "mine guide"
    },
    {
        "value": 9663,
        "NOCCode": "6531",
        "JobTitle": "plant tour guide"
    },
    {
        "value": 9664,
        "NOCCode": "6531",
        "JobTitle": "sightseeing guide"
    },
    {
        "value": 9665,
        "NOCCode": "6531",
        "JobTitle": "sightseeing tour guide, horse-drawn vehicles"
    },
    {
        "value": 9666,
        "NOCCode": "6531",
        "JobTitle": "site interpreter, adventure travel"
    },
    {
        "value": 9667,
        "NOCCode": "6531",
        "JobTitle": "ski resort host/hostess"
    },
    {
        "value": 9668,
        "NOCCode": "6531",
        "JobTitle": "tour director"
    },
    {
        "value": 9669,
        "NOCCode": "6531",
        "JobTitle": "tour escort"
    },
    {
        "value": 9670,
        "NOCCode": "6531",
        "JobTitle": "tour guide"
    },
    {
        "value": 9671,
        "NOCCode": "6531",
        "JobTitle": "tourist guide"
    },
    {
        "value": 9672,
        "NOCCode": "6531",
        "JobTitle": "travel escort"
    },
    {
        "value": 9673,
        "NOCCode": "6531",
        "JobTitle": "travel guide"
    },
    {
        "value": 9674,
        "NOCCode": "6531",
        "JobTitle": "walking tour guide"
    },
    {
        "value": 9675,
        "NOCCode": "6532",
        "JobTitle": "airship pilot"
    },
    {
        "value": 9676,
        "NOCCode": "6532",
        "JobTitle": "alpine guide"
    },
    {
        "value": 9677,
        "NOCCode": "6532",
        "JobTitle": "balloon pilot"
    },
    {
        "value": 9678,
        "NOCCode": "6532",
        "JobTitle": "canoeing guide"
    },
    {
        "value": 9679,
        "NOCCode": "6532",
        "JobTitle": "dirigible pilot"
    },
    {
        "value": 9680,
        "NOCCode": "6532",
        "JobTitle": "dogsledding guide"
    },
    {
        "value": 9681,
        "NOCCode": "6532",
        "JobTitle": "dude wrangler"
    },
    {
        "value": 9682,
        "NOCCode": "6532",
        "JobTitle": "fishing guide"
    },
    {
        "value": 9683,
        "NOCCode": "6532",
        "JobTitle": "freshwater angling guide"
    },
    {
        "value": 9684,
        "NOCCode": "6532",
        "JobTitle": "guide, hunting and fishing"
    },
    {
        "value": 9685,
        "NOCCode": "6532",
        "JobTitle": "guide, mountain climbing"
    },
    {
        "value": 9686,
        "NOCCode": "6532",
        "JobTitle": "guide, outdoor"
    },
    {
        "value": 9687,
        "NOCCode": "6532",
        "JobTitle": "guide, outfitting"
    },
    {
        "value": 9688,
        "NOCCode": "6532",
        "JobTitle": "guide, rafting"
    },
    {
        "value": 9689,
        "NOCCode": "6532",
        "JobTitle": "guide, whitewater rafting"
    },
    {
        "value": 9690,
        "NOCCode": "6532",
        "JobTitle": "hiking guide"
    },
    {
        "value": 9691,
        "NOCCode": "6532",
        "JobTitle": "horseback riding guide"
    },
    {
        "value": 9692,
        "NOCCode": "6532",
        "JobTitle": "hot air balloonist"
    },
    {
        "value": 9693,
        "NOCCode": "6532",
        "JobTitle": "hunting and fishing guide"
    },
    {
        "value": 9694,
        "NOCCode": "6532",
        "JobTitle": "hunting and fishing outfitter"
    },
    {
        "value": 9695,
        "NOCCode": "6532",
        "JobTitle": "hunting guide"
    },
    {
        "value": 9696,
        "NOCCode": "6532",
        "JobTitle": "mountain climbing guide"
    },
    {
        "value": 9697,
        "NOCCode": "6532",
        "JobTitle": "mountain guide"
    },
    {
        "value": 9698,
        "NOCCode": "6532",
        "JobTitle": "mountaineering guide"
    },
    {
        "value": 9699,
        "NOCCode": "6532",
        "JobTitle": "outdoor guide"
    },
    {
        "value": 9700,
        "NOCCode": "6532",
        "JobTitle": "outfitter"
    },
    {
        "value": 9701,
        "NOCCode": "6532",
        "JobTitle": "outfitting guide"
    },
    {
        "value": 9702,
        "NOCCode": "6532",
        "JobTitle": "rafting guide"
    },
    {
        "value": 9703,
        "NOCCode": "6532",
        "JobTitle": "rock climbing guide"
    },
    {
        "value": 9704,
        "NOCCode": "6532",
        "JobTitle": "snowmobile operations guide"
    },
    {
        "value": 9705,
        "NOCCode": "6532",
        "JobTitle": "trapping guide"
    },
    {
        "value": 9706,
        "NOCCode": "6532",
        "JobTitle": "whitewater rafting guide"
    },
    {
        "value": 9707,
        "NOCCode": "6532",
        "JobTitle": "winter camping guide"
    },
    {
        "value": 9708,
        "NOCCode": "6532",
        "JobTitle": "wrangler, dude ranch"
    },
    {
        "value": 9709,
        "NOCCode": "6533",
        "JobTitle": "baccarat croupier"
    },
    {
        "value": 9710,
        "NOCCode": "6533",
        "JobTitle": "blackjack croupier"
    },
    {
        "value": 9711,
        "NOCCode": "6533",
        "JobTitle": "casino dealer"
    },
    {
        "value": 9712,
        "NOCCode": "6533",
        "JobTitle": "casino runner"
    },
    {
        "value": 9713,
        "NOCCode": "6533",
        "JobTitle": "casino slot machine attendant"
    },
    {
        "value": 9714,
        "NOCCode": "6533",
        "JobTitle": "counting attendant – casino"
    },
    {
        "value": 9715,
        "NOCCode": "6533",
        "JobTitle": "croupier"
    },
    {
        "value": 9716,
        "NOCCode": "6533",
        "JobTitle": "croupier – casino"
    },
    {
        "value": 9717,
        "NOCCode": "6533",
        "JobTitle": "croupier, baccarat"
    },
    {
        "value": 9718,
        "NOCCode": "6533",
        "JobTitle": "croupier, blackjack"
    },
    {
        "value": 9719,
        "NOCCode": "6533",
        "JobTitle": "croupier, pai gow"
    },
    {
        "value": 9720,
        "NOCCode": "6533",
        "JobTitle": "croupier, pai gow domino"
    },
    {
        "value": 9721,
        "NOCCode": "6533",
        "JobTitle": "croupier, pai gow poker"
    },
    {
        "value": 9722,
        "NOCCode": "6533",
        "JobTitle": "croupier, poker"
    },
    {
        "value": 9723,
        "NOCCode": "6533",
        "JobTitle": "croupier, roulette"
    },
    {
        "value": 9724,
        "NOCCode": "6533",
        "JobTitle": "croupier, sic bo"
    },
    {
        "value": 9725,
        "NOCCode": "6533",
        "JobTitle": "croupier, super pan 9"
    },
    {
        "value": 9726,
        "NOCCode": "6533",
        "JobTitle": "croupier, twenty-one"
    },
    {
        "value": 9727,
        "NOCCode": "6533",
        "JobTitle": "dealer, casino"
    },
    {
        "value": 9728,
        "NOCCode": "6533",
        "JobTitle": "gambling table operator"
    },
    {
        "value": 9729,
        "NOCCode": "6533",
        "JobTitle": "gaming table dealer"
    },
    {
        "value": 9730,
        "NOCCode": "6533",
        "JobTitle": "gaming table operator – casino"
    },
    {
        "value": 9731,
        "NOCCode": "6533",
        "JobTitle": "keno dealer"
    },
    {
        "value": 9732,
        "NOCCode": "6533",
        "JobTitle": "keno runner, casino"
    },
    {
        "value": 9733,
        "NOCCode": "6533",
        "JobTitle": "keno writer"
    },
    {
        "value": 9734,
        "NOCCode": "6533",
        "JobTitle": "operator, gambling table"
    },
    {
        "value": 9735,
        "NOCCode": "6533",
        "JobTitle": "pai gow croupier"
    },
    {
        "value": 9736,
        "NOCCode": "6533",
        "JobTitle": "pai gow domino croupier"
    },
    {
        "value": 9737,
        "NOCCode": "6533",
        "JobTitle": "pai gow poker croupier"
    },
    {
        "value": 9738,
        "NOCCode": "6533",
        "JobTitle": "poker croupier"
    },
    {
        "value": 9739,
        "NOCCode": "6533",
        "JobTitle": "roulette croupier"
    },
    {
        "value": 9740,
        "NOCCode": "6533",
        "JobTitle": "runner, casino"
    },
    {
        "value": 9741,
        "NOCCode": "6533",
        "JobTitle": "sic bo croupier"
    },
    {
        "value": 9742,
        "NOCCode": "6533",
        "JobTitle": "slot machine attendant, casino"
    },
    {
        "value": 9743,
        "NOCCode": "6533",
        "JobTitle": "super pan 9 croupier"
    },
    {
        "value": 9744,
        "NOCCode": "6541",
        "JobTitle": "air courier guard"
    },
    {
        "value": 9745,
        "NOCCode": "6541",
        "JobTitle": "airport security guard"
    },
    {
        "value": 9746,
        "NOCCode": "6541",
        "JobTitle": "alarm investigator"
    },
    {
        "value": 9747,
        "NOCCode": "6541",
        "JobTitle": "alarm signal investigator"
    },
    {
        "value": 9748,
        "NOCCode": "6541",
        "JobTitle": "alarm signal specialist"
    },
    {
        "value": 9749,
        "NOCCode": "6541",
        "JobTitle": "armoured car driver"
    },
    {
        "value": 9750,
        "NOCCode": "6541",
        "JobTitle": "armoured car guard"
    },
    {
        "value": 9751,
        "NOCCode": "6541",
        "JobTitle": "ATM (automatic teller machine) guard"
    },
    {
        "value": 9752,
        "NOCCode": "6541",
        "JobTitle": "attendant, gate – security"
    },
    {
        "value": 9753,
        "NOCCode": "6541",
        "JobTitle": "automatic teller machine (ATM) guard"
    },
    {
        "value": 9754,
        "NOCCode": "6541",
        "JobTitle": "bodyguard (except police)"
    },
    {
        "value": 9755,
        "NOCCode": "6541",
        "JobTitle": "bouncer"
    },
    {
        "value": 9756,
        "NOCCode": "6541",
        "JobTitle": "bouncer – security"
    },
    {
        "value": 9757,
        "NOCCode": "6541",
        "JobTitle": "business establishment investigator – security"
    },
    {
        "value": 9758,
        "NOCCode": "6541",
        "JobTitle": "cabin baggage inspector"
    },
    {
        "value": 9759,
        "NOCCode": "6541",
        "JobTitle": "carry-on baggage inspector"
    },
    {
        "value": 9760,
        "NOCCode": "6541",
        "JobTitle": "commissionaire"
    },
    {
        "value": 9761,
        "NOCCode": "6541",
        "JobTitle": "commissionaire – security"
    },
    {
        "value": 9762,
        "NOCCode": "6541",
        "JobTitle": "convoy guard"
    },
    {
        "value": 9763,
        "NOCCode": "6541",
        "JobTitle": "corporate security officer"
    },
    {
        "value": 9764,
        "NOCCode": "6541",
        "JobTitle": "crossing guard"
    },
    {
        "value": 9765,
        "NOCCode": "6541",
        "JobTitle": "detective, private"
    },
    {
        "value": 9766,
        "NOCCode": "6541",
        "JobTitle": "floorman/woman, retail store"
    },
    {
        "value": 9767,
        "NOCCode": "6541",
        "JobTitle": "floorwalker, retail store"
    },
    {
        "value": 9768,
        "NOCCode": "6541",
        "JobTitle": "gate attendant – security"
    },
    {
        "value": 9769,
        "NOCCode": "6541",
        "JobTitle": "gateman/woman"
    },
    {
        "value": 9770,
        "NOCCode": "6541",
        "JobTitle": "guard, armoured car"
    },
    {
        "value": 9771,
        "NOCCode": "6541",
        "JobTitle": "guard, security"
    },
    {
        "value": 9772,
        "NOCCode": "6541",
        "JobTitle": "hand baggage inspector"
    },
    {
        "value": 9773,
        "NOCCode": "6541",
        "JobTitle": "hand luggage inspector"
    },
    {
        "value": 9774,
        "NOCCode": "6541",
        "JobTitle": "house detective"
    },
    {
        "value": 9775,
        "NOCCode": "6541",
        "JobTitle": "investigations officer – postal service"
    },
    {
        "value": 9776,
        "NOCCode": "6541",
        "JobTitle": "investigator, business establishment"
    },
    {
        "value": 9777,
        "NOCCode": "6541",
        "JobTitle": "investigator, private"
    },
    {
        "value": 9778,
        "NOCCode": "6541",
        "JobTitle": "lie detector examiner"
    },
    {
        "value": 9779,
        "NOCCode": "6541",
        "JobTitle": "loss prevention officer – retail"
    },
    {
        "value": 9780,
        "NOCCode": "6541",
        "JobTitle": "loss prevention officers supervisor"
    },
    {
        "value": 9781,
        "NOCCode": "6541",
        "JobTitle": "mystery shopper"
    },
    {
        "value": 9782,
        "NOCCode": "6541",
        "JobTitle": "night guard"
    },
    {
        "value": 9783,
        "NOCCode": "6541",
        "JobTitle": "night watchman/woman"
    },
    {
        "value": 9784,
        "NOCCode": "6541",
        "JobTitle": "officer, corporate security"
    },
    {
        "value": 9785,
        "NOCCode": "6541",
        "JobTitle": "officer, investigations – postal service"
    },
    {
        "value": 9786,
        "NOCCode": "6541",
        "JobTitle": "officer, loss prevention – retail"
    },
    {
        "value": 9787,
        "NOCCode": "6541",
        "JobTitle": "officer, postal inspection"
    },
    {
        "value": 9788,
        "NOCCode": "6541",
        "JobTitle": "officer, security analysis – postal service"
    },
    {
        "value": 9789,
        "NOCCode": "6541",
        "JobTitle": "patrol officer – security"
    },
    {
        "value": 9790,
        "NOCCode": "6541",
        "JobTitle": "patrolman/woman – security"
    },
    {
        "value": 9791,
        "NOCCode": "6541",
        "JobTitle": "plant gatekeeper"
    },
    {
        "value": 9792,
        "NOCCode": "6541",
        "JobTitle": "plant guard"
    },
    {
        "value": 9793,
        "NOCCode": "6541",
        "JobTitle": "polygraph examiner"
    },
    {
        "value": 9794,
        "NOCCode": "6541",
        "JobTitle": "polygraph operator"
    },
    {
        "value": 9795,
        "NOCCode": "6541",
        "JobTitle": "polygraphist"
    },
    {
        "value": 9796,
        "NOCCode": "6541",
        "JobTitle": "postal inspection officer"
    },
    {
        "value": 9797,
        "NOCCode": "6541",
        "JobTitle": "postal service inspector"
    },
    {
        "value": 9798,
        "NOCCode": "6541",
        "JobTitle": "preboarding security guard"
    },
    {
        "value": 9799,
        "NOCCode": "6541",
        "JobTitle": "private detective"
    },
    {
        "value": 9800,
        "NOCCode": "6541",
        "JobTitle": "private investigator"
    },
    {
        "value": 9801,
        "NOCCode": "6541",
        "JobTitle": "private investigators supervisor"
    },
    {
        "value": 9802,
        "NOCCode": "6541",
        "JobTitle": "private police agents and investigators supervisor"
    },
    {
        "value": 9803,
        "NOCCode": "6541",
        "JobTitle": "private policemen/women and investigators supervisor"
    },
    {
        "value": 9804,
        "NOCCode": "6541",
        "JobTitle": "private security officer"
    },
    {
        "value": 9805,
        "NOCCode": "6541",
        "JobTitle": "residential alarm system specialist"
    },
    {
        "value": 9806,
        "NOCCode": "6541",
        "JobTitle": "retail investigator"
    },
    {
        "value": 9807,
        "NOCCode": "6541",
        "JobTitle": "retail loss investigator"
    },
    {
        "value": 9808,
        "NOCCode": "6541",
        "JobTitle": "retail loss prevention officer"
    },
    {
        "value": 9809,
        "NOCCode": "6541",
        "JobTitle": "retail store floorman/woman"
    },
    {
        "value": 9810,
        "NOCCode": "6541",
        "JobTitle": "retail store floorwalker"
    },
    {
        "value": 9811,
        "NOCCode": "6541",
        "JobTitle": "school crossing guard"
    },
    {
        "value": 9812,
        "NOCCode": "6541",
        "JobTitle": "security alarm system consultant"
    },
    {
        "value": 9813,
        "NOCCode": "6541",
        "JobTitle": "security analysis officer – postal service"
    },
    {
        "value": 9814,
        "NOCCode": "6541",
        "JobTitle": "security and investigations officer"
    },
    {
        "value": 9815,
        "NOCCode": "6541",
        "JobTitle": "security consultant (except computer systems)"
    },
    {
        "value": 9816,
        "NOCCode": "6541",
        "JobTitle": "security guard"
    },
    {
        "value": 9817,
        "NOCCode": "6541",
        "JobTitle": "security guard – explosives"
    },
    {
        "value": 9818,
        "NOCCode": "6541",
        "JobTitle": "security investigator"
    },
    {
        "value": 9819,
        "NOCCode": "6541",
        "JobTitle": "security officer"
    },
    {
        "value": 9820,
        "NOCCode": "6541",
        "JobTitle": "security officer, private"
    },
    {
        "value": 9821,
        "NOCCode": "6541",
        "JobTitle": "security specialist (except computer systems)"
    },
    {
        "value": 9822,
        "NOCCode": "6541",
        "JobTitle": "shopping investigator – security"
    },
    {
        "value": 9823,
        "NOCCode": "6541",
        "JobTitle": "store detective"
    },
    {
        "value": 9824,
        "NOCCode": "6541",
        "JobTitle": "supervisor, loss prevention officers"
    },
    {
        "value": 9825,
        "NOCCode": "6541",
        "JobTitle": "supervisor, private investigators"
    },
    {
        "value": 9826,
        "NOCCode": "6541",
        "JobTitle": "supervisor, private police agents and investigators"
    },
    {
        "value": 9827,
        "NOCCode": "6541",
        "JobTitle": "supervisor, private policemen/women and investigators"
    },
    {
        "value": 9828,
        "NOCCode": "6541",
        "JobTitle": "theft prevention officer"
    },
    {
        "value": 9829,
        "NOCCode": "6541",
        "JobTitle": "vault custodian"
    },
    {
        "value": 9830,
        "NOCCode": "6551",
        "JobTitle": "accounts information service clerk – financial institution"
    },
    {
        "value": 9831,
        "NOCCode": "6551",
        "JobTitle": "bank customer service officer"
    },
    {
        "value": 9832,
        "NOCCode": "6551",
        "JobTitle": "bank teller"
    },
    {
        "value": 9833,
        "NOCCode": "6551",
        "JobTitle": "credit union teller"
    },
    {
        "value": 9834,
        "NOCCode": "6551",
        "JobTitle": "current account representative – financial services"
    },
    {
        "value": 9835,
        "NOCCode": "6551",
        "JobTitle": "customer service agent – financial institution"
    },
    {
        "value": 9836,
        "NOCCode": "6551",
        "JobTitle": "customer service officer, bank"
    },
    {
        "value": 9837,
        "NOCCode": "6551",
        "JobTitle": "customer service representative – financial services"
    },
    {
        "value": 9838,
        "NOCCode": "6551",
        "JobTitle": "financial customer service representative"
    },
    {
        "value": 9839,
        "NOCCode": "6551",
        "JobTitle": "foreign exchange teller – financial services"
    },
    {
        "value": 9840,
        "NOCCode": "6551",
        "JobTitle": "head teller – financial services"
    },
    {
        "value": 9841,
        "NOCCode": "6551",
        "JobTitle": "proof teller – bank"
    },
    {
        "value": 9842,
        "NOCCode": "6551",
        "JobTitle": "savings account teller – financial services"
    },
    {
        "value": 9843,
        "NOCCode": "6551",
        "JobTitle": "senior teller – financial services"
    },
    {
        "value": 9844,
        "NOCCode": "6551",
        "JobTitle": "teller – financial services"
    },
    {
        "value": 9845,
        "NOCCode": "6551",
        "JobTitle": "teller trainee"
    },
    {
        "value": 9846,
        "NOCCode": "6551",
        "JobTitle": "trust company teller"
    },
    {
        "value": 9847,
        "NOCCode": "6552",
        "JobTitle": "accounts information clerk"
    },
    {
        "value": 9848,
        "NOCCode": "6552",
        "JobTitle": "adjustment clerk"
    },
    {
        "value": 9849,
        "NOCCode": "6552",
        "JobTitle": "attendant, tourist booth"
    },
    {
        "value": 9850,
        "NOCCode": "6552",
        "JobTitle": "attendant, tourist information office"
    },
    {
        "value": 9851,
        "NOCCode": "6552",
        "JobTitle": "automobile dealerships service representative"
    },
    {
        "value": 9852,
        "NOCCode": "6552",
        "JobTitle": "bill complaints investigator"
    },
    {
        "value": 9853,
        "NOCCode": "6552",
        "JobTitle": "bus information clerk"
    },
    {
        "value": 9854,
        "NOCCode": "6552",
        "JobTitle": "bus schedule information clerk"
    },
    {
        "value": 9855,
        "NOCCode": "6552",
        "JobTitle": "bus service information clerk"
    },
    {
        "value": 9856,
        "NOCCode": "6552",
        "JobTitle": "business office service representative – telecommunications"
    },
    {
        "value": 9857,
        "NOCCode": "6552",
        "JobTitle": "call centre agent – customer service"
    },
    {
        "value": 9858,
        "NOCCode": "6552",
        "JobTitle": "claim clerk, lost or damaged goods"
    },
    {
        "value": 9859,
        "NOCCode": "6552",
        "JobTitle": "clerk, customer service"
    },
    {
        "value": 9860,
        "NOCCode": "6552",
        "JobTitle": "clerk, maintenance service"
    },
    {
        "value": 9861,
        "NOCCode": "6552",
        "JobTitle": "client service clerk"
    },
    {
        "value": 9862,
        "NOCCode": "6552",
        "JobTitle": "compensation agent"
    },
    {
        "value": 9863,
        "NOCCode": "6552",
        "JobTitle": "complaints adjuster"
    },
    {
        "value": 9864,
        "NOCCode": "6552",
        "JobTitle": "complaints clerk – customer service"
    },
    {
        "value": 9865,
        "NOCCode": "6552",
        "JobTitle": "complaints clerk – telephone system"
    },
    {
        "value": 9866,
        "NOCCode": "6552",
        "JobTitle": "complaints specialist – customer service"
    },
    {
        "value": 9867,
        "NOCCode": "6552",
        "JobTitle": "contact centre agent – customer service"
    },
    {
        "value": 9868,
        "NOCCode": "6552",
        "JobTitle": "counter enquiries clerk"
    },
    {
        "value": 9869,
        "NOCCode": "6552",
        "JobTitle": "courtesy desk clerk"
    },
    {
        "value": 9870,
        "NOCCode": "6552",
        "JobTitle": "customer information service clerk – insurance"
    },
    {
        "value": 9871,
        "NOCCode": "6552",
        "JobTitle": "customer information service representative – retail"
    },
    {
        "value": 9872,
        "NOCCode": "6552",
        "JobTitle": "customer relations clerk"
    },
    {
        "value": 9873,
        "NOCCode": "6552",
        "JobTitle": "customer satisfaction representative"
    },
    {
        "value": 9874,
        "NOCCode": "6552",
        "JobTitle": "customer service adviser"
    },
    {
        "value": 9875,
        "NOCCode": "6552",
        "JobTitle": "customer service agent"
    },
    {
        "value": 9876,
        "NOCCode": "6552",
        "JobTitle": "customer service assistant"
    },
    {
        "value": 9877,
        "NOCCode": "6552",
        "JobTitle": "customer service clerk"
    },
    {
        "value": 9878,
        "NOCCode": "6552",
        "JobTitle": "customer service monitor – telephone system"
    },
    {
        "value": 9879,
        "NOCCode": "6552",
        "JobTitle": "customer service operator – telephone system"
    },
    {
        "value": 9880,
        "NOCCode": "6552",
        "JobTitle": "customer service representative – call centre"
    },
    {
        "value": 9881,
        "NOCCode": "6552",
        "JobTitle": "customer service representative – insurance"
    },
    {
        "value": 9882,
        "NOCCode": "6552",
        "JobTitle": "department store information clerk"
    },
    {
        "value": 9883,
        "NOCCode": "6552",
        "JobTitle": "enquiries clerk"
    },
    {
        "value": 9884,
        "NOCCode": "6552",
        "JobTitle": "express and freight information clerk"
    },
    {
        "value": 9885,
        "NOCCode": "6552",
        "JobTitle": "hospital information clerk"
    },
    {
        "value": 9886,
        "NOCCode": "6552",
        "JobTitle": "hotel information clerk – contact centre"
    },
    {
        "value": 9887,
        "NOCCode": "6552",
        "JobTitle": "inbound customer service representative – contact centre"
    },
    {
        "value": 9888,
        "NOCCode": "6552",
        "JobTitle": "information clerk – customer service"
    },
    {
        "value": 9889,
        "NOCCode": "6552",
        "JobTitle": "investigator, bill complaints"
    },
    {
        "value": 9890,
        "NOCCode": "6552",
        "JobTitle": "kiosk information clerk"
    },
    {
        "value": 9891,
        "NOCCode": "6552",
        "JobTitle": "lost claims clerk"
    },
    {
        "value": 9892,
        "NOCCode": "6552",
        "JobTitle": "lost or damaged goods claim clerk"
    },
    {
        "value": 9893,
        "NOCCode": "6552",
        "JobTitle": "lost-and-found clerk"
    },
    {
        "value": 9894,
        "NOCCode": "6552",
        "JobTitle": "lost-and-found desk clerk"
    },
    {
        "value": 9895,
        "NOCCode": "6552",
        "JobTitle": "maintenance service clerk"
    },
    {
        "value": 9896,
        "NOCCode": "6552",
        "JobTitle": "merchandise exchange clerk"
    },
    {
        "value": 9897,
        "NOCCode": "6552",
        "JobTitle": "order clerk – customer services"
    },
    {
        "value": 9898,
        "NOCCode": "6552",
        "JobTitle": "order desk agent"
    },
    {
        "value": 9899,
        "NOCCode": "6552",
        "JobTitle": "outbound customer service representative – contact centre"
    },
    {
        "value": 9900,
        "NOCCode": "6552",
        "JobTitle": "price information clerk"
    },
    {
        "value": 9901,
        "NOCCode": "6552",
        "JobTitle": "public relations clerk"
    },
    {
        "value": 9902,
        "NOCCode": "6552",
        "JobTitle": "road transport terminal attendant"
    },
    {
        "value": 9903,
        "NOCCode": "6552",
        "JobTitle": "route supply and return clerk"
    },
    {
        "value": 9904,
        "NOCCode": "6552",
        "JobTitle": "service adviser"
    },
    {
        "value": 9905,
        "NOCCode": "6552",
        "JobTitle": "service advisor – auto repair"
    },
    {
        "value": 9906,
        "NOCCode": "6552",
        "JobTitle": "service consultant – auto repair"
    },
    {
        "value": 9907,
        "NOCCode": "6552",
        "JobTitle": "service representative, business office – telecommunications"
    },
    {
        "value": 9908,
        "NOCCode": "6552",
        "JobTitle": "service writer – auto repair"
    },
    {
        "value": 9909,
        "NOCCode": "6552",
        "JobTitle": "supply and return clerk"
    },
    {
        "value": 9910,
        "NOCCode": "6552",
        "JobTitle": "telephone enquiries clerk"
    },
    {
        "value": 9911,
        "NOCCode": "6552",
        "JobTitle": "tourist booth attendant"
    },
    {
        "value": 9912,
        "NOCCode": "6552",
        "JobTitle": "tourist information clerk"
    },
    {
        "value": 9913,
        "NOCCode": "6552",
        "JobTitle": "tourist information office attendant"
    },
    {
        "value": 9914,
        "NOCCode": "6552",
        "JobTitle": "tourist information officer"
    },
    {
        "value": 9915,
        "NOCCode": "6561",
        "JobTitle": "adviser, weight loss"
    },
    {
        "value": 9916,
        "NOCCode": "6561",
        "JobTitle": "colour analyst, fashion"
    },
    {
        "value": 9917,
        "NOCCode": "6561",
        "JobTitle": "colour consultant, fashion"
    },
    {
        "value": 9918,
        "NOCCode": "6561",
        "JobTitle": "consultant, corporate image"
    },
    {
        "value": 9919,
        "NOCCode": "6561",
        "JobTitle": "consultant, etiquette"
    },
    {
        "value": 9920,
        "NOCCode": "6561",
        "JobTitle": "consultant, fashion"
    },
    {
        "value": 9921,
        "NOCCode": "6561",
        "JobTitle": "consultant, fashion and wardrobe"
    },
    {
        "value": 9922,
        "NOCCode": "6561",
        "JobTitle": "consultant, fashion colours"
    },
    {
        "value": 9923,
        "NOCCode": "6561",
        "JobTitle": "consultant, image"
    },
    {
        "value": 9924,
        "NOCCode": "6561",
        "JobTitle": "consultant, make-up"
    },
    {
        "value": 9925,
        "NOCCode": "6561",
        "JobTitle": "consultant, personal image"
    },
    {
        "value": 9926,
        "NOCCode": "6561",
        "JobTitle": "consultant, personal improvement"
    },
    {
        "value": 9927,
        "NOCCode": "6561",
        "JobTitle": "consultant, personal wardrobe"
    },
    {
        "value": 9928,
        "NOCCode": "6561",
        "JobTitle": "consultant, professional image"
    },
    {
        "value": 9929,
        "NOCCode": "6561",
        "JobTitle": "consultant, public speaking"
    },
    {
        "value": 9930,
        "NOCCode": "6561",
        "JobTitle": "consultant, wardrobe"
    },
    {
        "value": 9931,
        "NOCCode": "6561",
        "JobTitle": "consultant, wedding services"
    },
    {
        "value": 9932,
        "NOCCode": "6561",
        "JobTitle": "consultant, weight loss"
    },
    {
        "value": 9933,
        "NOCCode": "6561",
        "JobTitle": "corporate image consultant"
    },
    {
        "value": 9934,
        "NOCCode": "6561",
        "JobTitle": "counsellor, personal image"
    },
    {
        "value": 9935,
        "NOCCode": "6561",
        "JobTitle": "etiquette consultant"
    },
    {
        "value": 9936,
        "NOCCode": "6561",
        "JobTitle": "fashion and wardrobe consultant"
    },
    {
        "value": 9937,
        "NOCCode": "6561",
        "JobTitle": "fashion colour analyst"
    },
    {
        "value": 9938,
        "NOCCode": "6561",
        "JobTitle": "fashion colour consultant"
    },
    {
        "value": 9939,
        "NOCCode": "6561",
        "JobTitle": "fashion consultant"
    },
    {
        "value": 9940,
        "NOCCode": "6561",
        "JobTitle": "image consultant"
    },
    {
        "value": 9941,
        "NOCCode": "6561",
        "JobTitle": "image co-ordinator"
    },
    {
        "value": 9942,
        "NOCCode": "6561",
        "JobTitle": "make-up consultant"
    },
    {
        "value": 9943,
        "NOCCode": "6561",
        "JobTitle": "personal image consultant"
    },
    {
        "value": 9944,
        "NOCCode": "6561",
        "JobTitle": "personal image counsellor"
    },
    {
        "value": 9945,
        "NOCCode": "6561",
        "JobTitle": "personal improvement consultant"
    },
    {
        "value": 9946,
        "NOCCode": "6561",
        "JobTitle": "personal style co-ordinator"
    },
    {
        "value": 9947,
        "NOCCode": "6561",
        "JobTitle": "personal wardrobe consultant"
    },
    {
        "value": 9948,
        "NOCCode": "6561",
        "JobTitle": "professional image consultant"
    },
    {
        "value": 9949,
        "NOCCode": "6561",
        "JobTitle": "public image consultant – personal consultants"
    },
    {
        "value": 9950,
        "NOCCode": "6561",
        "JobTitle": "public speaking consultant"
    },
    {
        "value": 9951,
        "NOCCode": "6561",
        "JobTitle": "social consultant"
    },
    {
        "value": 9952,
        "NOCCode": "6561",
        "JobTitle": "wardrobe consultant"
    },
    {
        "value": 9953,
        "NOCCode": "6561",
        "JobTitle": "wedding consultant"
    },
    {
        "value": 9954,
        "NOCCode": "6561",
        "JobTitle": "wedding services consultant"
    },
    {
        "value": 9955,
        "NOCCode": "6561",
        "JobTitle": "weight loss adviser"
    },
    {
        "value": 9956,
        "NOCCode": "6561",
        "JobTitle": "weight loss consultant"
    },
    {
        "value": 9957,
        "NOCCode": "6562",
        "JobTitle": "artist, tattoo"
    },
    {
        "value": 9958,
        "NOCCode": "6562",
        "JobTitle": "beautician"
    },
    {
        "value": 9959,
        "NOCCode": "6562",
        "JobTitle": "beauty care specialist"
    },
    {
        "value": 9960,
        "NOCCode": "6562",
        "JobTitle": "beauty culture operator"
    },
    {
        "value": 9961,
        "NOCCode": "6562",
        "JobTitle": "beauty culturist"
    },
    {
        "value": 9962,
        "NOCCode": "6562",
        "JobTitle": "beauty treatment operator"
    },
    {
        "value": 9963,
        "NOCCode": "6562",
        "JobTitle": "body piercer"
    },
    {
        "value": 9964,
        "NOCCode": "6562",
        "JobTitle": "consultant, hair replacement"
    },
    {
        "value": 9965,
        "NOCCode": "6562",
        "JobTitle": "cosmetician"
    },
    {
        "value": 9966,
        "NOCCode": "6562",
        "JobTitle": "cosmetologist"
    },
    {
        "value": 9967,
        "NOCCode": "6562",
        "JobTitle": "depilator"
    },
    {
        "value": 9968,
        "NOCCode": "6562",
        "JobTitle": "depilator, wax"
    },
    {
        "value": 9969,
        "NOCCode": "6562",
        "JobTitle": "dermal therapist"
    },
    {
        "value": 9970,
        "NOCCode": "6562",
        "JobTitle": "electrologist"
    },
    {
        "value": 9971,
        "NOCCode": "6562",
        "JobTitle": "electrolysis operator"
    },
    {
        "value": 9972,
        "NOCCode": "6562",
        "JobTitle": "electrolysis technician"
    },
    {
        "value": 9973,
        "NOCCode": "6562",
        "JobTitle": "esthetician"
    },
    {
        "value": 9974,
        "NOCCode": "6562",
        "JobTitle": "facial treatment operator"
    },
    {
        "value": 9975,
        "NOCCode": "6562",
        "JobTitle": "hair removal specialist"
    },
    {
        "value": 9976,
        "NOCCode": "6562",
        "JobTitle": "hair replacement consultant"
    },
    {
        "value": 9977,
        "NOCCode": "6562",
        "JobTitle": "hair replacement technician"
    },
    {
        "value": 9978,
        "NOCCode": "6562",
        "JobTitle": "hair weaver"
    },
    {
        "value": 9979,
        "NOCCode": "6562",
        "JobTitle": "henna tattoo artist"
    },
    {
        "value": 9980,
        "NOCCode": "6562",
        "JobTitle": "manicurist"
    },
    {
        "value": 9981,
        "NOCCode": "6562",
        "JobTitle": "mehndi tattoo artist"
    },
    {
        "value": 9982,
        "NOCCode": "6562",
        "JobTitle": "nail care technician"
    },
    {
        "value": 9983,
        "NOCCode": "6562",
        "JobTitle": "nails and lashes applicator"
    },
    {
        "value": 9984,
        "NOCCode": "6562",
        "JobTitle": "operator, beauty culture"
    },
    {
        "value": 9985,
        "NOCCode": "6562",
        "JobTitle": "operator, beauty treatment"
    },
    {
        "value": 9986,
        "NOCCode": "6562",
        "JobTitle": "operator, electrolysis"
    },
    {
        "value": 9987,
        "NOCCode": "6562",
        "JobTitle": "operator, facial treatment"
    },
    {
        "value": 9988,
        "NOCCode": "6562",
        "JobTitle": "pedicurist"
    },
    {
        "value": 9989,
        "NOCCode": "6562",
        "JobTitle": "registered dermal therapist"
    },
    {
        "value": 9990,
        "NOCCode": "6562",
        "JobTitle": "scalp treatment specialist"
    },
    {
        "value": 9991,
        "NOCCode": "6562",
        "JobTitle": "skin care consultant"
    },
    {
        "value": 9992,
        "NOCCode": "6562",
        "JobTitle": "skin care specialist"
    },
    {
        "value": 9993,
        "NOCCode": "6562",
        "JobTitle": "skin care technician"
    },
    {
        "value": 9994,
        "NOCCode": "6562",
        "JobTitle": "specialist, beauty care"
    },
    {
        "value": 9995,
        "NOCCode": "6562",
        "JobTitle": "specialist, hair removal"
    },
    {
        "value": 9996,
        "NOCCode": "6562",
        "JobTitle": "specialist, scalp treatment"
    },
    {
        "value": 9997,
        "NOCCode": "6562",
        "JobTitle": "specialist, wax depilation"
    },
    {
        "value": 9998,
        "NOCCode": "6562",
        "JobTitle": "tattoo artist"
    },
    {
        "value": 9999,
        "NOCCode": "6562",
        "JobTitle": "tattooer"
    }    
]