export const CANADIAN_SCHOOLS = [
    {
      value: 1,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ABM College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary\t",
      "Campus": "Calgary"
    },
    {
      value: 2,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Absolute Aviation",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Wetaskiwin",
      "Campus": "Wetaskiwin"
    },
    {
      value: 3,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Calgary",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary\t",
      "Campus": "Calgary South"
    },
    {
      value: 4,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Calgary",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary\t",
      "Campus": "Calgary North East"
    },
    {
      value: 5,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Edmonton",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton Down Town"
    },
    {
      value: 6,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Edmonton",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton West"
    },
    {
      value: 7,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Edmonton South\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton South"
    },
    {
      value: 8,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Medicine Hat",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Medicine Hat",
      "Campus": "Medicine Hat"
    },
    {
      value: 9,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Red Deer\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Red Deer",
      "Campus": "Red Deer"
    },
    {
      value: 10,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Adventure Aviation\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Grande Prairie",
      "Campus": "Grande Prairie"
    },
    {
      value: 11,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aesthetics Pro\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 12,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta Ballet School",
      "Type": "Ballet school ",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 13,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta Bible College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 14,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Acupuncture and Traditional Chinese Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 15,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary/ Edmonton",
      "Campus": "Calgary"
    },
    {
      value: 16,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 17,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton – South"
    },
    {
      value: 18,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Fort McMurray/ Edmonton",
      "Campus": "Fort McMurray"
    },
    {
      value: 19,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Grande Prairie / Edmonton",
      "Campus": "Grande Prairie"
    },
    {
      value: 20,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Lloydminster /Edmonton",
      "Campus": "Lloydminster"
    },
    {
      value: 21,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta College of Massage Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": " Red Deer /Edmonton",
      "Campus": "Red Deer"
    },
    {
      value: 22,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alberta University of the Arts",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 23,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alison College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 24,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alpha College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 25,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ambrose University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 26,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anne’s Language House",
      "Type": "High school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 27,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Athabasca University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Athabasca",
      "Campus": "Athabasca"
    },
    {
      value: 28,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Athabasca University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Athabasca University – Edmonton"
    },
    {
      value: 29,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Bay River College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 30,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Bow Valley College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 31,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Burman University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lacombe",
      "Campus": "Lacombe"
    },
    {
      value: 32,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 33,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary City Centre"
    },
    {
      value: 34,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary South"
    },
    {
      value: 35,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton West"
    },
    {
      value: 36,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton South"
    },
    {
      value: 37,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton North"
    },
    {
      value: 38,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton City Centre"
    },
    {
      value: 39,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Red Deer",
      "Campus": "Red Deer"
    },
    {
      value: 40,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLI College of Business, Health & Technology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 41,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CPA Western School of Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 42,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Calgary College of Traditional Chinese Medicine and Acupuncture",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 43,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Calgary Flight Training Centre (CFTC)",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Olds",
      "Campus": "Olds"
    },
    {
      value: 44,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Calgary Flying Club\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 45,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambrooks College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 46,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Campbell College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 47,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Health and Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 48,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Imperial College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 49,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Osteopathic Therapy Inc\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 50,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Traditional Chinese Medicine Corp",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 51,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Southern Baptist Seminary and College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Cochrane",
      "Campus": "Cochrane"
    },
    {
      value: 52,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canford Institute of Technology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 53,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial Flight Centre, Inc.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Sturgeon County\t",
      "Campus": "Sturgeon County"
    },
    {
      value: 54,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Columbia College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 55,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Concordia Lutheran Seminary\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 56,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Concordia University of Edmonton",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 57,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cultural Connections Institute-The Learning Exchange (CCI-LEX)\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 58,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cypress College Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Medicine Hat",
      "Campus": "Cypress College – Brooks Campus"
    },
    {
      value: 59,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cypress College Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Medicine Hat",
      "Campus": "Cypress College – Taber Campus"
    },
    {
      value: 60,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Delmar College of Hair and Esthetics Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 61,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Digital School\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 62,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ERP Technical College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 63,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "East-West College of Business and Technology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 64,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Edmonton Flying Club\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Spruce Grove",
      "Campus": "Spruce Grove"
    },
    {
      value: 65,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Elevated Learning Academy Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 66,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Elevated Learning Academy Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 67,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Embody School of Pilates\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Canmore",
      "Campus": "Canmore"
    },
    {
      value: 68,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Equilibrium School\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 69,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Excel Flight Training Incorporated",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lethbridge",
      "Campus": "Lethbridge"
    },
    {
      value: 70,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "GEOS Languages Plus, Calgary\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 71,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Glenbow College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 72,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Global Village Calgary (Rocky Mountain English Center Ltd)",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 73,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Grande Prairie Regional College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Grande Prairie",
      "Campus": "Grande Prairie Campus"
    },
    {
      value: 74,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "InnoTech College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 75,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyano College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Fort McMurray",
      "Campus": "Fort McMurray"
    },
    {
      value: 76,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LR Helicopters Inc.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 77,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lakeland College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lloydminster",
      "Campus": "Lloydminster Campus"
    },
    {
      value: 78,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lakeland College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Vermillion",
      "Campus": " Vermillion Campus"
    },
    {
      value: 79,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lethbridge College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lethbridge",
      "Campus": "Lethbridge"
    },
    {
      value: 80,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MC College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 81,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MC College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 82,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MC College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Red Deer",
      "Campus": "Red Deer"
    },
    {
      value: 83,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MCG Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 84,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MCG Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Cold Lake  /Calgary",
      "Campus": "Cold Lake"
    },
    {
      value: 85,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MCG Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Red Deer /Calgary",
      "Campus": "Red Deer"
    },
    {
      value: 86,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MH Vicars School of Massage Therapy Ltd\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 87,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MH Vicars School of Massage Therapy Ltd",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 88,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MTG Healthcare Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 89,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MTG Healthcare Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": " Edmonton/ Calgary",
      "Campus": "Edmonton"
    },
    {
      value: 90,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MTG Healthcare Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Red Deer /Calgary",
      "Campus": "Red Deer"
    },
    {
      value: 91,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MaKami College Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 92,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MaKami College Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 93,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MacEwan University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "City Centre Campus"
    },
    {
      value: 94,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MacEwan University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Centre for the Arts & Communications"
    },
    {
      value: 95,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MacEwan University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Alberta College"
    },
    {
      value: 96,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maple Leaf Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 97,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMurray Aviation",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Fort McMurray",
      "Campus": "Fort McMurray"
    },
    {
      value: 98,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Medicine Hat College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Brooks",
      "Campus": "Brooks Campus"
    },
    {
      value: 99,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Medicine Hat College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Medicine Hat",
      "Campus": "Medicine Hat Campus"
    },
    {
      value: 100,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Montair Aviation\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Springbrook",
      "Campus": "Springbrook"
    },
    {
      value: 101,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Montair Aviation\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Pitt Meadows",
      "Campus": "Pitt Meadows"
    },
    {
      value: 102,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mount Royal University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 103,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAIT (Northern Alberta Institute of Technology)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary Campus"
    },
    {
      value: 104,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAIT (Northern Alberta Institute of Technology)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Main Campus"
    },
    {
      value: 105,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAIT (Northern Alberta Institute of Technology)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Patricia Campus"
    },
    {
      value: 106,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAIT (Northern Alberta Institute of Technology)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Souch Campus"
    },
    {
      value: 107,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAIT (Northern Alberta Institute of Technology)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "St. Albert",
      "Campus": "St. Albert Campus"
    },
    {
      value: 108,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NIWE Academy Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 109,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Newman Theological College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 110,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Drayton Valley",
      "Campus": "Drayton Valley Campus"
    },
    {
      value: 111,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton Downtown"
    },
    {
      value: 112,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Westmount Campus"
    },
    {
      value: 113,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Stony Plain",
      "Campus": "Stony Plain Campus"
    },
    {
      value: 114,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Westlock",
      "Campus": "Westlock Campus"
    },
    {
      value: 115,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Wetaskiwin",
      "Campus": "Wetaskiwin Campus"
    },
    {
      value: 116,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NorQuest College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Whitecourt",
      "Campus": "Whitecourt campus"
    },
    {
      value: 117,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Grouard",
      "Campus": "Grouard Campus"
    },
    {
      value: 118,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Slave Lake",
      "Campus": "Slave Lake Main Campus"
    },
    {
      value: 119,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Athabasca",
      "Campus": "Athabasca Campus"
    },
    {
      value: 120,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Fort vermilion",
      "Campus": "Fort Vermillion Campus"
    },
    {
      value: 121,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "High Level",
      "Campus": "High Level Campus"
    },
    {
      value: 122,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "High Prairie",
      "Campus": "High Prairie Campus"
    },
    {
      value: 123,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Grande Prairie",
      "Campus": "Grande Prairie Campus"
    },
    {
      value: 124,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Peace River",
      "Campus": "Peace River Campus"
    },
    {
      value: 125,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Valleyview",
      "Campus": "Valleyview Campus"
    },
    {
      value: 126,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lakes College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Webasca",
      "Campus": "Wabasca Campus"
    },
    {
      value: 127,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "OHC Calgary\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 128,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Olds College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary Campus"
    },
    {
      value: 129,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Olds College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Olds",
      "Campus": "Olds Campus"
    },
    {
      value: 130,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "PeerlessTraining Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 131,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pixel Blue College Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 132,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Portage College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lac La Biche",
      "Campus": "Lac La Biche campus"
    },
    {
      value: 133,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Prairie Bible Institute",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Three Hills",
      "Campus": "Three Hills"
    },
    {
      value: 134,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Professional Institute of Massage Therapy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 135,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Purely Inspired Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Lethbridge",
      "Campus": "Lethbridge"
    },
    {
      value: 136,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "QCom College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 137,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Red Deer College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Red Deer",
      "Campus": "Red Deer"
    },
    {
      value: 138,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Reeves College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary City Centre"
    },
    {
      value: 139,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Reeves College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary North"
    },
    {
      value: 140,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Reeves College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 141,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Reeves College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Lethbridge",
      "Campus": "Lethbridge"
    },
    {
      value: 142,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Reeves College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Lloydminster",
      "Campus": "Lloydminster"
    },
    {
      value: 143,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 144,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 145,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Rocky Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 146,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Rosewood College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 147,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sky Wings Aviation Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Penhold",
      "Campus": "Penhold"
    },
    {
      value: 148,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Solomon College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 149,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Southern Alberta Institute of Technology (SAIT Polytechnic)\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 150,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Springbank Air Training College\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 151,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Mary’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 152,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Stephen’s College (U of Alberta Campus)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 153,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stafford House Calgary\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 154,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sundance College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 155,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Super T Aviation Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Medicine Hat",
      "Campus": "Medicine Hat"
    },
    {
      value: 156,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Synergy Flight Training Inc",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Spruce Grove",
      "Campus": "Spruce Grove"
    },
    {
      value: 157,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "TLC-International Training Centre for Caregiving Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 158,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Taylor College and Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 159,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Esthetic Institute Training Center\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 160,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The King’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 161,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Royal Alberta College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 162,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Timberline Canadian Alpine Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Banff",
      "Campus": "Banff"
    },
    {
      value: 163,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Alberta",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Camrose",
      "Campus": "Augustana Campus"
    },
    {
      value: 164,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Alberta",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Main"
    },
    {
      value: 165,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Alberta",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Campus St. Jean"
    },
    {
      value: 166,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Calgary\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary"
    },
    {
      value: 167,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Lethbridge",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Calgary",
      "Campus": "Calgary Campus"
    },
    {
      value: 168,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Lethbridge",
      "Type": "University",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Lethbridge",
      "Campus": "Lethbridge"
    },
    {
      value: 169,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vanguard College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 170,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wholistic Health Training & Research Centre Ltd\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Alberta",
      "City": "Edmonton",
      "Campus": "Edmonton"
    },
    {
      value: 171,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ARV Canada College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 172,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coastal Pacific Aviation Ltd.\tAcademy of Excellence Hair Design & Aesthetics Ltd.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 173,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Excellence Hair Design & Aesthetics Ltd.",
      "Type": "?",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 174,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Abbotsford",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 175,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Abbotsford",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 176,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Langley",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 177,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 178,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 179,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Na naimo",
      "Campus": "Na naimo"
    },
    {
      value: 180,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 181,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 182,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Nanaimo",
      "Campus": " Nanaimo"
    },
    {
      value: 183,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Victoria",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 184,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College – Richmond\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 185,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College – Surrey\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 186,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College – Vancouver – Broadway",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 187,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Acadia College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 188,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Acsenda School of Management\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 189,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Adler University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 190,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Advantage English School E/J\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 191,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ajna Yoga Teacher Training\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 192,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alexander College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 193,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alexander College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 194,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alexander College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Vancouver",
      "Campus": " Vancouver"
    },
    {
      value: 195,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "All Body Laser Corp. Training Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Port Coquitlam",
      "Campus": "Port Coquitlam"
    },
    {
      value: 196,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Arbutus College of Communication Arts, Business & Technology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 197,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ashton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 198,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ashton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 199,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ashton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 200,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Automotive Training Centre\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 201,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "BC College of Optics\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 202,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Blanche Macdonald Centre\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 203,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Blue Bird Flight Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 204,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Body Glamour Institute of Beauty by Anita\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 205,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Boucher Institute of Naturopathic Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 206,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brighton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 207,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brighton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 208,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brighton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 209,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 210,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 211,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 212,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 213,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 214,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Richmond",
      "Campus": " Richmond"
    },
    {
      value: 215,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "British Columbia Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 216,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CG Masters School of 3D Animation & VFX\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 217,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CSIC e-Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 218,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Camber College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Powell River",
      "Campus": "Powell River"
    },
    {
      value: 219,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambria College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 220,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambridge College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 221,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Camosun College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 222,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Can-Quest ESL Academy",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 223,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada Aviation Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 224,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada Aviation Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 225,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada Aviation Academy\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Kamloops\t",
      "Campus": " Kamloops\t"
    },
    {
      value: 226,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada College Vancouver",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 227,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada International Royal Arts College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 228,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada International Royal Arts College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 229,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada International Royal Arts College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Vancouver",
      "Campus": " Vancouver"
    },
    {
      value: 230,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Aviation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Pitt Meadows",
      "Campus": "Pitt Meadows"
    },
    {
      value: 231,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Baptist Seminary\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 232,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Chinese School of Theology Vancouver\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 233,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 234,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of English Language",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 235,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Performing Arts",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 236,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Shiatsu Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 237,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Shiatsu Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 238,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Shiatsu Therapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 239,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Technology and Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 240,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Film and Television Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 241,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Flight Centre (CFC)\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 242,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Flight Centre (CFC)\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 243,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Flight Centre (CFC)",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Kamloops",
      "Campus": " Kamloops"
    },
    {
      value: 244,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian National Institute of Business\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 245,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian School of Natural Nutrition (Vancouver)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 246,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian School of Natural Nutrition Vancouver Island",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 247,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian School of Natural Nutrition Vancouver Island",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 248,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian School of Natural Nutrition Vancouver Island",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 249,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Sports Business Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 250,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Tourism College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 251,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Tourism College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 252,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Tourism College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 253,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capilano University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Sechelt",
      "Campus": "Sechelt"
    },
    {
      value: 254,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capilano University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 255,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capilano University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Sechelt",
      "Campus": " Sechelt"
    },
    {
      value: 256,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 257,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 258,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 259,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 260,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 261,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Career City College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 262,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Carey Theological College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 263,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Central College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster\t",
      "Campus": "New Westminster\t"
    },
    {
      value: 264,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre for Arts and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster\t",
      "Campus": "New Westminster\t"
    },
    {
      value: 265,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chartered Professional Accountants of British Columbia (CPABC)",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 266,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chinook Helicopters (1982) Ltd.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 267,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "City University of Seattle",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 268,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "City University of Seattle",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langford",
      "Campus": "Langford"
    },
    {
      value: 269,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "City University of Seattle",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 270,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Clearmind International Institute Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 271,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Clearmind International Institute Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 272,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Clearmind International Institute Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Vancouver\t",
      "Campus": " Vancouver\t"
    },
    {
      value: 273,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cloud Nine College Ltd.",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 274,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Houston",
      "Campus": "Houston"
    },
    {
      value: 275,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kitimat",
      "Campus": "Kitimat"
    },
    {
      value: 276,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Prince Rupert",
      "Campus": "Prince Rupert"
    },
    {
      value: 277,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Masset (Haida Gwaii)",
      "Campus": "Masset (Haida Gwaii)"
    },
    {
      value: 278,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Queen Charlotte",
      "Campus": "Queen Charlotte"
    },
    {
      value: 279,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Smithers",
      "Campus": "Smithers"
    },
    {
      value: 280,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Terrace",
      "Campus": "Terrace"
    },
    {
      value: 281,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Hazelton",
      "Campus": "Hazelton"
    },
    {
      value: 282,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Houston",
      "Campus": "Houston"
    },
    {
      value: 283,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kitimat",
      "Campus": "Kitimat"
    },
    {
      value: 284,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Prince Rupert",
      "Campus": "Prince Rupert"
    },
    {
      value: 285,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Masset (Haida Gwaii)",
      "Campus": "Masset (Haida Gwaii)"
    },
    {
      value: 286,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Queen Charlotte",
      "Campus": "Queen Charlotte"
    },
    {
      value: 287,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Smither",
      "Campus": "Smither"
    },
    {
      value: 288,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coast Mountain College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Terrace",
      "Campus": "Terrace"
    },
    {
      value: 289,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coastal College of British Columbia",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 290,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coastal Pacific Aviation Ltd.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 291,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CodeCore\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 292,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Éducacentre",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 293,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Éducacentre",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 294,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Éducacentre",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Prince George",
      "Campus": "Prince George"
    },
    {
      value: 295,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Éducacentre",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouve",
      "Campus": "Vancouve"
    },
    {
      value: 296,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Éducacentre",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 297,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fort St. James",
      "Campus": "Fort St. James"
    },
    {
      value: 298,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fraser Lake",
      "Campus": "Fraser Lake"
    },
    {
      value: 299,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Mackenzie",
      "Campus": "Mackenzie"
    },
    {
      value: 300,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Prince George",
      "Campus": "Prince George"
    },
    {
      value: 301,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Quesnel",
      "Campus": "Quesnel"
    },
    {
      value: 302,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vanderhoof",
      "Campus": "Vanderhoof"
    },
    {
      value: 303,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Valemount",
      "Campus": "Valemount"
    },
    {
      value: 304,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burns Lake",
      "Campus": "Burns Lake"
    },
    {
      value: 305,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Fort St. James",
      "Campus": " Fort St. James"
    },
    {
      value: 306,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fraser Lake",
      "Campus": "Fraser Lake"
    },
    {
      value: 307,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Mackenzie",
      "Campus": "Mackenzie"
    },
    {
      value: 308,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Prince George",
      "Campus": "Prince George"
    },
    {
      value: 309,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Quesnel",
      "Campus": "Quesnel"
    },
    {
      value: 310,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vanderhoof",
      "Campus": "Vanderhoof"
    },
    {
      value: 311,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of New Caledonia\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Valemount",
      "Campus": "Valemount"
    },
    {
      value: 312,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Creston",
      "Campus": "Creston"
    },
    {
      value: 313,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fernie",
      "Campus": "Fernie"
    },
    {
      value: 314,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Golden",
      "Campus": "Golden"
    },
    {
      value: 315,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Invermere",
      "Campus": "Invermere"
    },
    {
      value: 316,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kimberley",
      "Campus": "Kimberley"
    },
    {
      value: 317,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Cranbrook",
      "Campus": "Cranbrook"
    },
    {
      value: 318,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Creston",
      "Campus": "Creston"
    },
    {
      value: 319,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "FernieFernie",
      "Campus": "FernieFernie"
    },
    {
      value: 320,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Golden",
      "Campus": " Golden"
    },
    {
      value: 321,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Invermere",
      "Campus": "Invermere"
    },
    {
      value: 322,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the Rockies\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kimberley",
      "Campus": "Kimberley"
    },
    {
      value: 323,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Columbia Bible College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 324,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Columbia College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 325,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Columbia Paramedic Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 326,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Columbia Paramedic Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 327,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Coquitlam College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 328,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cornerstone International Community College of Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 329,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cornerstone International Community College of Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 330,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Corpus Christi College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 331,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Create Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 332,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Create Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 333,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "DEA Canadian College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 334,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Courtenay",
      "Campus": "Courtenay"
    },
    {
      value: 335,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Maple Ridge",
      "Campus": "Maple Ridge"
    },
    {
      value: 336,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 337,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Parksville",
      "Campus": "Parksville"
    },
    {
      value: 338,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 339,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Campbell River",
      "Campus": "Campbell River"
    },
    {
      value: 340,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Courtenay",
      "Campus": "Courtenay"
    },
    {
      value: 341,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Maple Ridge",
      "Campus": "Maple Ridge"
    },
    {
      value: 342,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 343,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Parksville",
      "Campus": " Parksville"
    },
    {
      value: 344,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Discovery Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 345,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "DiveSafe International\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Campbell River",
      "Campus": "Campbell River"
    },
    {
      value: 346,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Diving Dynamics – Home of the Commercial Diving Institute of Canada (CDI)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 347,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dominion Herbal College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 348,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Douglas College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 349,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Douglas College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 350,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Douglas College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 351,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Douglas College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " New Westminster",
      "Campus": " New Westminster"
    },
    {
      value: 352,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Douglas College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 353,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Drake Medox College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 354,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Drake Medox College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 355,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Drake Medox College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 356,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EC Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 357,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EF International Language Campus\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 358,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EF International Language Campus\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 359,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EF International Language Campus\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Victoria",
      "Campus": " Victoria"
    },
    {
      value: 360,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ELS Language Centers\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 361,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ESLI\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 362,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Emily Carr University of Art and Design\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 363,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "English Depot Education Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 364,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eton College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 365,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Excel Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Courtenay",
      "Campus": "Courtenay"
    },
    {
      value: 366,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fairleigh Dickinson University (Vancouver)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 367,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "First College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "West Kelowna",
      "Campus": "West Kelowna"
    },
    {
      value: 368,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "First College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 369,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "First College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "West Kelowna",
      "Campus": "West Kelowna"
    },
    {
      value: 370,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Focus College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 371,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Focus College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 372,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Franklin English Language College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 373,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fraser International College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 374,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "GEOS Languages Plus\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 375,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "GEOS Languages Plus\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 376,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Gastown Business College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 377,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Gateway College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 378,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Global College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 379,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Global Village Victoria\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 380,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Goh Ballet Academy\t",
      "Type": "Ballet school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 381,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Granville College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 382,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Greystone College of Business and Technology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 383,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 384,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 385,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Vancouver\t",
      "Campus": " Vancouver\t"
    },
    {
      value: 386,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Heli-College Canada Training Inc.\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 387,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Heritage Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 388,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "IH Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 389,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 390,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 391,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ISS Language and Career College of BC\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 392,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "In Focus Film School\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 393,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Infofit Educators\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 394,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Infofit Educators\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 395,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Infofit Educators",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Vancouver",
      "Campus": " Vancouver"
    },
    {
      value: 396,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Insignia College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 397,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Insignia College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 398,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Insignia College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 399,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute of Technology Development of Canada (ITD)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 400,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Interior Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 401,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Flight Centre Inc.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 402,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International House Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 403,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International House Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 404,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Language Academy of Canada (ILAC)\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 405,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Island Coastal Aviation Inc.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Pitt Meadows",
      "Campus": "Pitt Meadows"
    },
    {
      value: 406,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "JCI Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 407,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 408,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Maple Ridge",
      "Campus": "Maple Ridge"
    },
    {
      value: 409,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 410,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 411,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 412,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 413,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Maple Ridge",
      "Campus": "Maple Ridge"
    },
    {
      value: 414,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 415,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Justice Institute of British Columbia\t",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 416,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kaplan International Languages\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 417,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kelowna College of Professional Counselling\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 418,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keystone College Ltd.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Courtenay, Surrey",
      "Campus": "Courtenay, Surrey"
    },
    {
      value: 419,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kootenay Columbia College of Integrative Health Sciences\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nelson",
      "Campus": "Nelson"
    },
    {
      value: 420,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kosmetae Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 421,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kutenai Art Therapy Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nelson",
      "Campus": "Nelson"
    },
    {
      value: 422,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 423,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 424,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 425,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Cloverdale",
      "Campus": "Cloverdale"
    },
    {
      value: 426,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 427,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 428,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kwantlen Polytechnic University",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 429,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaSalle College Vancouver\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 430,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaSalle College Vancouver\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 431,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaSalle College Vancouver\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 432,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaSalle College Vancouver\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 433,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaSalle College Vancouver\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 434,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Langara College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 435,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Langley Flying School\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 436,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Language Arts Institute\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 437,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Language Studies International (LSI)\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 438,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Languages Across Borders",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 439,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Legends Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 440,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lighthouse Labs\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 441,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "London School\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 442,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lost Boys Studios - School of Visual Effects\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 443,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "M.C. College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 444,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Madlab School of Fitness\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 445,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mark Anthony Academy of Cosmetology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 446,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mennonite Brethren Biblical Seminary\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 447,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Metropolitan Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 448,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Montair",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Pitt Meadows",
      "Campus": "Pitt Meadows"
    },
    {
      value: 449,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Montessori Training Centre of British Columbia\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 450,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mountain Transport Institute Ltd. Castlegar\t",
      "Type": "Driving school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Castlegar",
      "Campus": "Castlegar"
    },
    {
      value: 451,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Image College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 452,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Link College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 453,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Link College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 454,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New York Institute of Technology",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 455,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nicola Valley Institute of Technology\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burnaby, Merritt\t",
      "Campus": "Burnaby, Merritt\t"
    },
    {
      value: 456,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nimbus School of Recording & Media\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 457,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Courtenay",
      "Campus": "Courtenay"
    },
    {
      value: 458,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Port Alberni",
      "Campus": "Port Alberni"
    },
    {
      value: 459,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Port Hardy",
      "Campus": "Port Hardy"
    },
    {
      value: 460,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Ucluelet",
      "Campus": "Ucluelet"
    },
    {
      value: 461,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Campbell River",
      "Campus": "Campbell River"
    },
    {
      value: 462,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Courtenay",
      "Campus": "Courtenay"
    },
    {
      value: 463,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Port Alberni",
      "Campus": " Port Alberni"
    },
    {
      value: 464,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Port Hardy",
      "Campus": "Port Hardy"
    },
    {
      value: 465,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North Island College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Ucluelet",
      "Campus": "Ucluelet"
    },
    {
      value: 466,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northeastern University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 467,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Dawson Creek",
      "Campus": "Dawson Creek"
    },
    {
      value: 468,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fort Nelson",
      "Campus": "Fort Nelson"
    },
    {
      value: 469,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fort St John",
      "Campus": "Fort St John"
    },
    {
      value: 470,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Tumbler Ridge",
      "Campus": "Tumbler Ridge"
    },
    {
      value: 471,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Chetwynd",
      "Campus": "Chetwynd"
    },
    {
      value: 472,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Dawson Creek",
      "Campus": " Dawson Creek"
    },
    {
      value: 473,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fort Nelson",
      "Campus": "Fort Nelson"
    },
    {
      value: 474,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Fort St John",
      "Campus": " Fort St John"
    },
    {
      value: 475,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern Lights College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Tumbler Ridge",
      "Campus": "Tumbler Ridge"
    },
    {
      value: 476,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northwest Baptist Seminary\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 477,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northwest Culinary Academy of Vancouver",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 478,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "OHC Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 479,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "OIEG Oxford International Education Group/Eurocentres Canada\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 480,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 481,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Oliver",
      "Campus": "Oliver"
    },
    {
      value: 482,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Penticton",
      "Campus": "Penticton"
    },
    {
      value: 483,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Revelstoke",
      "Campus": "Revelstoke"
    },
    {
      value: 484,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Salmon Arm",
      "Campus": "Salmon Arm"
    },
    {
      value: 485,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Summerland",
      "Campus": "Summerland"
    },
    {
      value: 486,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vernon",
      "Campus": "Vernon"
    },
    {
      value: 487,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Oliver",
      "Campus": "Oliver"
    },
    {
      value: 488,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Penticton",
      "Campus": " Penticton"
    },
    {
      value: 489,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Revelstoke",
      "Campus": " Revelstoke"
    },
    {
      value: 490,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Salmon Arm",
      "Campus": "Salmon Arm"
    },
    {
      value: 491,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Summerland",
      "Campus": "Summerland"
    },
    {
      value: 492,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan College",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vernon",
      "Campus": "Vernon"
    },
    {
      value: 493,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan Mountain Helicopters FTU Ltd.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 494,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Okanagan Valley College of Massage Therapy Ltd.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vernon",
      "Campus": "Vernon"
    },
    {
      value: 495,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Omni College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 496,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Orane International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 497,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Orca Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 498,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Orca Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 499,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Orca Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 500,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oscar International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 501,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oscar International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 502,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oscar International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 503,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oscar International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Surrey",
      "Campus": " Surrey"
    },
    {
      value: 504,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oscar International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 505,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oshio College of Acupuncture and Herbology\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 506,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Coast Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 507,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific DanceArts\t",
      "Type": "Ballet school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 508,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Design Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 509,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Flying Club\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 510,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Institute of Culinary Arts\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 511,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Life Bible College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 512,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Link College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 513,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Link College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 514,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Link College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 515,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Link College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Burnaby",
      "Campus": " Burnaby"
    },
    {
      value: 516,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Link College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 517,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Rim Aviation Academy Inc.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Pitt Meadows",
      "Campus": "Pitt Meadows"
    },
    {
      value: 518,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Rim College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 519,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pacific Rim Early Childhood Institute Inc.\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Saanichton",
      "Campus": "Saanichton"
    },
    {
      value: 520,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pearson College UWC\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 521,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Principal Air\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 522,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Professional Flight Centre\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta",
      "Campus": "Delta"
    },
    {
      value: 523,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Q College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 524,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Q College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 525,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Q College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 526,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Quantum Academy\t",
      "Type": "High school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 527,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Quest University Canada\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Squamish",
      "Campus": "Squamish"
    },
    {
      value: 528,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Regent College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 529,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Rhodes Wellness College Ltd.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 530,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Richmond International Academy\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 531,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Bridge College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 532,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Metro College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 533,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Roads University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 534,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "SAE Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 535,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "SELC Career College Vancouver\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 536,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "SELC English Language Centre Canada\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 537,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sea Land Air Flight Centre\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Delta, Richmond\t",
      "Campus": "Delta, Richmond\t"
    },
    {
      value: 538,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Castlegar",
      "Campus": "Castlegar"
    },
    {
      value: 539,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Grand Forks",
      "Campus": "Grand Forks"
    },
    {
      value: 540,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kaslo Centre",
      "Campus": "Kaslo Centre"
    },
    {
      value: 541,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nakusp",
      "Campus": "Nakusp"
    },
    {
      value: 542,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nakusp",
      "Campus": "Nakusp"
    },
    {
      value: 543,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nelson",
      "Campus": "Nelson"
    },
    {
      value: 544,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Trail",
      "Campus": "Trail"
    },
    {
      value: 545,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Grand Forks",
      "Campus": "Grand Forks"
    },
    {
      value: 546,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kaslo Centre",
      "Campus": "Kaslo Centre"
    },
    {
      value: 547,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nakusp",
      "Campus": "Nakusp"
    },
    {
      value: 548,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nelson",
      "Campus": "Nelson"
    },
    {
      value: 549,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Selkirk College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Trail",
      "Campus": " Trail"
    },
    {
      value: 550,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seminary of Christ the King\t",
      "Type": "High school, College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Mission",
      "Campus": "Mission"
    },
    {
      value: 551,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Senniyo Aesthetics International School of Canada Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 552,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Simon Fraser University (SFU)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 553,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Simon Fraser University (SFU)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 554,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Simon Fraser University (SFU)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 555,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "SkyQuest Aviation Ltd.\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 556,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 557,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 558,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 559,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 560,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 561,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Maple Ridge",
      "Campus": "Maple Ridge"
    },
    {
      value: 562,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 563,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 564,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Osoyoos",
      "Campus": "Osoyoos"
    },
    {
      value: 565,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Penticton",
      "Campus": "Penticton"
    },
    {
      value: 566,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 567,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 568,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 569,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria\t",
      "Campus": "Victoria\t"
    },
    {
      value: 570,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 571,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 572,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Kamloops",
      "Campus": " Kamloops"
    },
    {
      value: 573,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 574,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Maple Ridge",
      "Campus": " Maple Ridge"
    },
    {
      value: 575,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 576,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 577,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Osoyoos",
      "Campus": "Osoyoos"
    },
    {
      value: 578,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Penticton",
      "Campus": "Penticton"
    },
    {
      value: 579,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 580,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 581,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 582,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 583,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 584,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 585,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 586,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 587,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 588,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. George International College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 589,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Giles Vancouver",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 590,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Mark’s College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 591,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Duncan",
      "Campus": "Duncan"
    },
    {
      value: 592,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 593,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 594,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vernon",
      "Campus": "Vernon"
    },
    {
      value: 595,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 596,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 597,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stenberg College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Vernon",
      "Campus": " Vernon"
    },
    {
      value: 598,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sterling College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 599,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sterling College",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 600,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Story Institute\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 601,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Summit Pacific College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 602,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Careers\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 603,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Careers\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 604,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Language Centres\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 605,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Language Centres\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 606,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Language Centres\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Whistler",
      "Campus": " Whistler"
    },
    {
      value: 607,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Taylor Pro Training\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 608,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Cut Fashion Design Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 609,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Hair Art Academy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Langley, Surrey",
      "Campus": "Langley, Surrey"
    },
    {
      value: 610,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Institute of Holistic Nutrition\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 611,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Think Tank Training Centre\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "North Vancouver",
      "Campus": "North Vancouver"
    },
    {
      value: 612,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Thompson Rivers University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kamloops",
      "Campus": "Kamloops"
    },
    {
      value: 613,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Thompson Rivers University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Williams Lake",
      "Campus": "Williams Lake"
    },
    {
      value: 614,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Thompson Rivers University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Williams Lake",
      "Campus": "Williams Lake"
    },
    {
      value: 615,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Torchbearers Capernwray Canada Society\t",
      "Type": "Bible school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Thetis Island",
      "Campus": "Thetis Island"
    },
    {
      value: 616,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trinity Western University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Langley",
      "Campus": "Langley"
    },
    {
      value: 617,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trinity Western University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 618,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trinity Western University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 619,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tzu Chi International College of Traditional Chinese Medicine (TCICTCM)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 620,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Universal Learning Institute\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 621,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Universal Learning Institute\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 622,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University Canada West\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 623,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of British Columbia (UBC)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 624,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of British Columbia (UBC)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 625,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of British Columbia (UBC)\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 626,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Fort St. John",
      "Campus": "Fort St. John"
    },
    {
      value: 627,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Prince George",
      "Campus": "Prince George"
    },
    {
      value: 628,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Quesnel",
      "Campus": "Quesnel"
    },
    {
      value: 629,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Terrace",
      "Campus": "Terrace"
    },
    {
      value: 630,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": " Prince George",
      "Campus": " Prince George"
    },
    {
      value: 631,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Quesnel",
      "Campus": "Quesnel"
    },
    {
      value: 632,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Northern British Columbia\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Terrace",
      "Campus": "Terrace"
    },
    {
      value: 633,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Victoria\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 634,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Mission",
      "Campus": "Mission"
    },
    {
      value: 635,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Hope",
      "Campus": "Hope"
    },
    {
      value: 636,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 637,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 638,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 639,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Hope",
      "Campus": "Hope"
    },
    {
      value: 640,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of the Fraser Valley",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Mission",
      "Campus": "Mission"
    },
    {
      value: 641,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "VGC International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 642,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "VanWest College (Kelowna)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 643,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "VanWest College (Vancouver)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 644,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Academy of Dramatic Arts\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 645,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Academy of Music\t",
      "Type": "Music school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 646,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Acting School\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 647,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Animation School\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 648,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Art Therapy Institute\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 649,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Beijing College of Chinese Medicine\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 650,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 651,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 652,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 653,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 654,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 655,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 656,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 657,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 658,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 659,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 660,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Chilliwack",
      "Campus": "Chilliwack"
    },
    {
      value: 661,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Coquitlam",
      "Campus": "Coquitlam"
    },
    {
      value: 662,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Kelowna",
      "Campus": "Kelowna"
    },
    {
      value: 663,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Richmond",
      "Campus": "Richmond"
    },
    {
      value: 664,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 665,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver ",
      "Campus": "Vancouver "
    },
    {
      value: 666,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Career College (Burnaby) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 667,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Community College\t",
      "Type": "College",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 668,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Film School\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 669,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Institute of Media Arts (VanArts)\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 670,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver International College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 671,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 672,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island School of Art\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 673,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 674,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Parksville",
      "Campus": "Parksville"
    },
    {
      value: 675,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Powell River",
      "Campus": "Powell River"
    },
    {
      value: 676,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Duncan",
      "Campus": "Duncan"
    },
    {
      value: 677,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Nanaimo",
      "Campus": "Nanaimo"
    },
    {
      value: 678,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Parksville",
      "Campus": "Parksville"
    },
    {
      value: 679,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Island University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Powell River",
      "Campus": "Powell River"
    },
    {
      value: 680,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Premier College of Arts and Science\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Burnaby",
      "Campus": "Burnaby"
    },
    {
      value: 681,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Premier College of Business and Management Ltd\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 682,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver Premier College of Hotel Management\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 683,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver School of Healing Arts\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 684,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vancouver School of Theology\t",
      "Type": "University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 685,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria Academy of Ballet\t",
      "Type": "Ballet school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 686,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria Academy of Dramatic Arts\t",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 687,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria College of Art\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 688,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria Conservatory of Music\t",
      "Type": "Music school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 689,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria Flying Club\t",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 690,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wales Young Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 691,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "West Coast College of Massage Therapy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 692,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "West Coast College of Massage Therapy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 693,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "West Coast College of Massage Therapy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 694,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "West Coast Institute for Studies in Anthroposophy\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Sechelt",
      "Campus": "Sechelt"
    },
    {
      value: 695,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Westcoast Adventure College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Sooke",
      "Campus": "Sooke"
    },
    {
      value: 696,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Abbotsford",
      "Campus": "Abbotsford"
    },
    {
      value: 697,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Surrey",
      "Campus": "Surrey"
    },
    {
      value: 698,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Community College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": " Surrey",
      "Campus": " Surrey"
    },
    {
      value: 699,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Maritime Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Ladysmith",
      "Campus": "Ladysmith"
    },
    {
      value: 700,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Maritime Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Steveston",
      "Campus": "Steveston"
    },
    {
      value: 701,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Maritime Institute\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Steveston\t",
      "Campus": "Steveston\t"
    },
    {
      value: 702,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Whistler Adventure School\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Whistler",
      "Campus": "Whistler"
    },
    {
      value: 703,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Windsong College of Healing Arts\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Port Alberni",
      "Campus": "Port Alberni"
    },
    {
      value: 704,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Winston College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "New Westminster",
      "Campus": "New Westminster"
    },
    {
      value: 705,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yorkville University\t",
      "Type": "University",
      "PGWP": 1,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 706,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "iTTTi Vancouver\t",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 707,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "inlingua Vancouver dba INVO Career College\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Vancouver",
      "Campus": "Vancouver"
    },
    {
      value: 708,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "inlingua Victoria College of Languages\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "British Columbia",
      "City": "Victoria",
      "Campus": "Victoria"
    },
    {
      value: 709,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Winnipeg North (Computerized Accounting)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 710,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Assiniboine Community College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Brandon",
      "Campus": "Brandon"
    },
    {
      value: 711,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aveda Institute Winnipeg (Hairstyling)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 712,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Booth University College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 713,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brandon Flying Club",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Brandon",
      "Campus": "Brandon"
    },
    {
      value: 714,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brandon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Brandon",
      "Campus": "Brandon"
    },
    {
      value: 715,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College (Winnipeg Campus) - [Intra-Oral Dental Assistant]",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 716,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Baptist Bible College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winkler",
      "Campus": "Winkler"
    },
    {
      value: 717,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Forces School of Aerospace Studies",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 718,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Mennonite University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 719,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chartered Professional Accountants (CPA) Manitoba",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 720,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of Morden Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Morden",
      "Campus": "Morden"
    },
    {
      value: 721,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Commonwealth College Inc. (Culinary Arts Level One, Hospitality, Electrolysis, Esthetics, Nail Technician, Skin Technician)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 722,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Harv’s Air Service",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Steinbach",
      "Campus": "Steinbach"
    },
    {
      value: 723,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Heartland International English School",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 724,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Herzing College [Supply Chain Management and Logistics; Occupational Health and Safety Officer; Medical Laboratory Assistant; Hospitality and Event Management]",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 725,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Interlake Aviation Ltd",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Gimli",
      "Campus": "Gimli"
    },
    {
      value: 726,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International College of Manitoba",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 727,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Living Word Bible College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Swan River",
      "Campus": "Swan River"
    },
    {
      value: 728,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Louis Riel School Division Arts and Technology Centre",
      "Type": "High school",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 729,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "M.C. College (M.C. College Group) - [Hairstyling, Esthetics, Nail Technician]",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 730,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Manitoba Institute of Trades and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 731,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Manitoba Tourism Education College (Hospitality and Hotel Certificate)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 732,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mid-Ocean School of Media Arts (Audio in Media)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 733,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Providence University College and Theological Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Otterburne",
      "Campus": "Otterburne"
    },
    {
      value: 734,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Red River College (Red River College of Applied Arts, Science and Technology)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 735,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Red River College (Red River College of Applied Arts, Science and Technology) ! Same as 27",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 736,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College (Accounting and Payroll Administrator, Logistics and Supply Chain Management, Massage Therapy, Medical Laboratory Assistant, Pharmacy Technician)\t",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 737,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College (Massage Therapy)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 738,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College (Medical Laboratory Assistant)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 739,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Robertson College (Pharmacy Technician)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 740,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Winnipeg Ballet School",
      "Type": "Ballet school",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 741,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "School of Contemporary Dancers",
      "Type": "Dance school",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 742,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "School of Ministers (Southland Church)",
      "Type": "High school",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Steinbach",
      "Campus": "Steinbach"
    },
    {
      value: 743,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Andrew’s College (St. Andrew’s College in Winnipeg)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 744,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Steinbach Bible College (Steinbach Bible College Inc.)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Steinbach",
      "Campus": "Steinbach"
    },
    {
      value: 745,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Saint-Boniface",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 746,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University College of the North",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "The Pas",
      "Campus": "The Pas"
    },
    {
      value: 747,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Manitoba",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 748,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Winnipeg",
      "Type": "University",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 749,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Winnipeg Aviation (Winnipeg Aviation (2003) Ltd.)",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Manitoba",
      "City": "St. Andrews",
      "Campus": "St. Andrews"
    },
    {
      value: 750,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Winnipeg Global Education College Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Manitoba",
      "City": "Winnipeg",
      "Campus": "Winnipeg"
    },
    {
      value: 751,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Atlantic Business College Ltd.",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton"
    },
    {
      value: 752,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chaleur College Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Bathurst",
      "Campus": "Bathurst"
    },
    {
      value: 753,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège communautaire du Nouveau-Brunswick (CCNB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Bathurst",
      "Campus": "Campus de Bathurst"
    },
    {
      value: 754,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège communautaire du Nouveau-Brunswick (CCNB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Campbellton",
      "Campus": "Campus de Campbellton"
    },
    {
      value: 755,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège communautaire du Nouveau-Brunswick (CCNB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Dieppe",
      "Campus": "Campus de Dieppe"
    },
    {
      value: 756,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège communautaire du Nouveau-Brunswick (CCNB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Edmundston ",
      "Campus": "Campus d’Edmundston "
    },
    {
      value: 757,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège communautaire du Nouveau-Brunswick (CCNB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Shippagan",
      "Campus": "Campus de la Péninsule acadienne"
    },
    {
      value: 758,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Crandall University",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Moncton"
    },
    {
      value: 759,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "East Coast Trades College",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton"
    },
    {
      value: 760,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eastern College",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Saint John",
      "Campus": "Saint John Campus"
    },
    {
      value: 761,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eastern College ",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton Campus"
    },
    {
      value: 762,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eastern College ",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Moncton Campus"
    },
    {
      value: 763,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kingswood University",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Sussex",
      "Campus": "Sussex"
    },
    {
      value: 764,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "LaFrance School of Hair Design",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Grand Falls",
      "Campus": "Grand Falls"
    },
    {
      value: 765,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Bathurst",
      "Campus": "Collège de Technologie Forestière des Maritimes"
    },
    {
      value: 766,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Bathurst",
      "Campus": "Bathurst Campus (French campus)"
    },
    {
      value: 767,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Maritime College of Forest Technology"
    },
    {
      value: 768,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime College of Forest Technology / Collège de Technologie Forestière des Maritimes",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton Campus (English campus)"
    },
    {
      value: 769,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McKenzie College",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Moncton"
    },
    {
      value: 770,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McKenzie College Language Learning Centre",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Moncton"
    },
    {
      value: 771,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Moncton Flight College",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Dieppe",
      "Campus": "Moncton Campus"
    },
    {
      value: 772,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Moncton Flight College",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton Campus"
    },
    {
      value: 773,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mount Allison University",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Sackville",
      "Campus": "Sackville"
    },
    {
      value: 774,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mount Saint Joseph Nursing Home",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Miramichi",
      "Campus": "Miramichi"
    },
    {
      value: 775,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Bible Institute Inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Victoria Corner",
      "Campus": "Victoria Corner"
    },
    {
      value: 776,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick College of Craft and Design",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton"
    },
    {
      value: 777,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "NBCC - Fredericton"
    },
    {
      value: 778,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Miramichi",
      "Campus": "NBCC - Miramichi"
    },
    {
      value: 779,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "NBCC - Moncton"
    },
    {
      value: 780,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Saint John",
      "Campus": "NBCC – Saint John"
    },
    {
      value: 781,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "St. Andrews",
      "Campus": "NBCC - St. Andrews"
    },
    {
      value: 782,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "New Brunswick Community College (NBCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Woodstock",
      "Campus": "NBCC - Woodstock"
    },
    {
      value: 783,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northeast Christian College",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton"
    },
    {
      value: 784,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oulton College",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Moncton"
    },
    {
      value: 785,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ready Arc Training and Testing",
      "Type": "College",
      "PGWP": 0,
      "Province": "New Brunswick",
      "City": "Saint John",
      "Campus": "Saint John"
    },
    {
      value: 786,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Stephen’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "St. Stephen",
      "Campus": "St. Stephen"
    },
    {
      value: 787,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Thomas University",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "Fredericton"
    },
    {
      value: 788,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Moncton",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Edmundston",
      "Campus": "Campus d’Edmunsdston"
    },
    {
      value: 789,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Moncton",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Moncton",
      "Campus": "Campus de Moncton"
    },
    {
      value: 790,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Moncton",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Shippagan",
      "Campus": "Campus de Shippagan"
    },
    {
      value: 791,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of New Brunswick",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Fredericton",
      "Campus": "UNB – Fredericton"
    },
    {
      value: 792,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of New Brunswick",
      "Type": "University",
      "PGWP": 1,
      "Province": "New Brunswick",
      "City": "Saint John",
      "Campus": "UNB – Saint John"
    },
    {
      value: 793,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Corner Brook",
      "Campus": "Corner Brook"
    },
    {
      value: 794,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "St. John’s"
    },
    {
      value: 795,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre for Nursing Studies",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "St. John’s"
    },
    {
      value: 796,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Baie Verte",
      "Campus": "Baie Verte Campus"
    },
    {
      value: 797,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Bonavista",
      "Campus": "Bonavista Campus"
    },
    {
      value: 798,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Burin Bay Arm",
      "Campus": "Burin Campus"
    },
    {
      value: 799,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Carbonear",
      "Campus": "Carbonear Campus"
    },
    {
      value: 800,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Clarenville",
      "Campus": "Clarenville Campus"
    },
    {
      value: 801,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Conception Bay South",
      "Campus": "Seal Cove Campus"
    },
    {
      value: 802,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Cornerbrook",
      "Campus": "Cornerbrook Campus"
    },
    {
      value: 803,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Gander",
      "Campus": "Gander Campus"
    },
    {
      value: 804,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Grand Falls-Windsor",
      "Campus": "Grand Falls-Windsor Campus"
    },
    {
      value: 805,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Happy Valley-Goose Bay",
      "Campus": "Happy Valley-Goose Bay Campus"
    },
    {
      value: 806,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Labrador City",
      "Campus": "Labrador West Campus"
    },
    {
      value: 807,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Placentia",
      "Campus": "Placentia Campus"
    },
    {
      value: 808,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Port aux Basques",
      "Campus": "Port aux Basques Campus"
    },
    {
      value: 809,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. Anthony",
      "Campus": "St. Anthony Campus"
    },
    {
      value: 810,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "Prince Philip Drive Campus"
    },
    {
      value: 811,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "Ridge Road Campus"
    },
    {
      value: 812,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of the North Atlantic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Stephenville",
      "Campus": "Bay St. George Campus"
    },
    {
      value: 813,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Gander Flight Training (Exploits Valley Air Service)",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Gander",
      "Campus": "Gander"
    },
    {
      value: 814,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (Advanced Educational Systems Inc.)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "St. John’s"
    },
    {
      value: 815,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (Data Enterprises)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Burin",
      "Campus": "Burin"
    },
    {
      value: 816,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (Future First Inc.)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Gander",
      "Campus": "Gander"
    },
    {
      value: 817,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (Future First Inc.)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Grand Falls-Windsor",
      "Campus": "Grand Falls-Windsor"
    },
    {
      value: 818,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (KMD Educational Services Ltd.)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Carbonear",
      "Campus": "Carbonear"
    },
    {
      value: 819,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College (Southcoast Training)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Marystown",
      "Campus": "Marystown"
    },
    {
      value: 820,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Keyin College Western Campus",
      "Type": "College",
      "PGWP": 0,
      "Province": "Newfoundland and Labrador",
      "City": "Stephenville",
      "Campus": "Stephenville"
    },
    {
      value: 821,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Memorial University of Newfoundland",
      "Type": "University",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Corner Brook",
      "Campus": "Grenfell Campus"
    },
    {
      value: 822,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Memorial University of Newfoundland",
      "Type": "University",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "Fisheries and Marine Institute Campus"
    },
    {
      value: 823,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Memorial University of Newfoundland",
      "Type": "University",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "St. John’s Campus"
    },
    {
      value: 824,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Queen’s College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "St. John’s",
      "Campus": "St. John’s"
    },
    {
      value: 825,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Regional School of Nursing",
      "Type": "College",
      "PGWP": 1,
      "Province": "Newfoundland and Labrador",
      "City": "Corner Brook",
      "Campus": "Corner Brook"
    },
    {
      value: 826,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Cosmetology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Dartmouth",
      "Campus": "Dartmouth"
    },
    {
      value: 827,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Halifax",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 828,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Acadia University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Wolfville",
      "Campus": "Wolfville"
    },
    {
      value: 829,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Acadia University: English Language Centre",
      "Type": "University",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Wolfville",
      "Campus": "Wolfville"
    },
    {
      value: 830,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Apex Language and Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 831,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Atlantic Flight Attendant Academy",
      "Type": "Flight school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 832,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Atlantic School of Theology",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 833,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CBBC Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Halifax"
    },
    {
      value: 834,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CBBC Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Sydney"
    },
    {
      value: 835,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLLC Halifax",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Citadel Campus"
    },
    {
      value: 836,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLLC Halifax",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Duke Campus"
    },
    {
      value: 837,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CPA Atlantic",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 838,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Acupuncture and Traditional Chinese Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 839,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Massage and Hydrotherapy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 840,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cape Breton University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Sydney"
    },
    {
      value: 841,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Da Vinci Centre of Arts and Design",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Lower Sackville",
      "Campus": "Lower Sackville"
    },
    {
      value: 842,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dalhousie University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 843,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dalhousie University ESL Programs",
      "Type": "University",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 844,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "East Coast Language College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 845,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eastern College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 846,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Elevate Beauty Institute of Cosmetology",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "New Minas",
      "Campus": "New Minas"
    },
    {
      value: 847,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eurocentres, Atlantic Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Lunenburg",
      "Campus": "Lunenburg"
    },
    {
      value: 848,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Halifax Language Institute of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 849,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ICEAP Nova Scotia Inc",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Cape Breton University and New Dawn Centre for Social Innovation"
    },
    {
      value: 850,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ICT Northumberland College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 851,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Language Institute",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 852,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Island Career Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Sydney"
    },
    {
      value: 853,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Dartmouth",
      "Campus": "Dartmouth"
    },
    {
      value: 854,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime Environmental Training Institute (METI)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Sydney",
      "Campus": "Sydney"
    },
    {
      value: 855,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Medavie HealthEd",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Dartmouth",
      "Campus": "Dartmouth"
    },
    {
      value: 856,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mount Saint Vincent University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 857,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NSCAD University (Nova Scotia College of Art and Design)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 858,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nova Scotia College of Early Childhood Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 859,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nova Scotia Community College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 860,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nova Scotia Fire Fighters School",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Waverley",
      "Campus": "Waverley"
    },
    {
      value: 861,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saint Mary’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 862,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Francis Xavier University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Antigonish",
      "Campus": "Antigonish"
    },
    {
      value: 863,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Success College of Applied Arts and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Nova Scotia",
      "City": "Lower Sackville",
      "Campus": "Lower Sackville"
    },
    {
      value: 864,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Language Centre at Saint Mary’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 865,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université Sainte-Anne",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Church Point",
      "Campus": "Church Point"
    },
    {
      value: 866,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of King’s College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Nova Scotia",
      "City": "Halifax",
      "Campus": "Halifax"
    },
    {
      value: 867,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "A1-Global College of Health, Business, and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "East Campus"
    },
    {
      value: 868,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "A1-Global College of Health, Business, and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "West Campus"
    },
    {
      value: 869,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ABC Access Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 870,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ACE Acumen Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 871,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ACE Acumen Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 872,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "AIMS College Healthcare Management Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 873,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "APlus Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "Norh York"
    },
    {
      value: 874,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Académie de Dessin de Mode Richard Robinson Academy of Fashion Design",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 875,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Applied Pharmaceutical Sciences (AAPS) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York/Toronto",
      "Campus": "Toronto"
    },
    {
      value: 876,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 877,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career College – Thunder Bay",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Thunder Bay",
      "Campus": "Thunder Bay"
    },
    {
      value: 878,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Owen Sound",
      "Campus": "Owen Sound"
    },
    {
      value: 879,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College Academy of Learning Career College ",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto  (1)",
      "Campus": "Toronto/Bay/Queen  Campus "
    },
    {
      value: 880,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Belleville",
      "Campus": "Belleville"
    },
    {
      value: 881,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College Academy of Learning Career College  Albion & Islington Campus",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Etobicoke /Toronto (2)",
      "Campus": "Etobicoke/Albion/Islington"
    },
    {
      value: 882,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough/Toronto (3)",
      "Campus": "Lawrence Campus"
    },
    {
      value: 883,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career College ",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto /North York (4)",
      "Campus": "Downsview Campu"
    },
    {
      value: 884,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career Collegee Warden/Sheppard Campus",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto /Scarborough (5)",
      "Campus": "Warden/Sheppard"
    },
    {
      value: 885,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College Bloor/Danforth /Downtown East Campus",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto (6)",
      "Campus": "Bay/Bloor Campus Bloor/Danforth /Downtown East"
    },
    {
      value: 886,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning Career and Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 887,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 888,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton Campus"
    },
    {
      value: 889,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College - Kingston",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Kingston Campus"
    },
    {
      value: 890,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Adler Graduate Professional School",
      "Type": "Graduate school ",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 891,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ahmadiyya Muslim Jama’at Canada Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Maple",
      "Campus": "Maple"
    },
    {
      value: 892,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algoma University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Algoma at Brampton"
    },
    {
      value: 893,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algoma University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sault Ste. Marie",
      "Campus": "Main Campus"
    },
    {
      value: 894,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algoma University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Timmins",
      "Campus": "Algoma at Timmins"
    },
    {
      value: 895,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin Careers Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 896,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin Careers Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 897,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Algonquin College of Applied Arts and Technology"
    },
    {
      value: 898,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Pembroke",
      "Campus": "Algonquin College of Applied Arts and Technology"
    },
    {
      value: 899,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Pembroke",
      "Campus": "Pembroke Campus"
    },
    {
      value: 900,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Perth",
      "Campus": "Algonquin College of Applied Arts and Technology"
    },
    {
      value: 901,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Algonquin College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Perth",
      "Campus": "Perth Campus"
    },
    {
      value: 902,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alpha College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 903,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anderson College of Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton"
    },
    {
      value: 904,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anderson College of Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 905,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anderson College of Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 906,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anderson College of Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Downtown"
    },
    {
      value: 907,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Anderson College of Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "North York"
    },
    {
      value: 908,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Art & Technique Academy of Hairstyling and Esthetics",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa Campus"
    },
    {
      value: 909,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aveda Institute Toronto",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 910,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Avola College Of Hairstyling and Esthetics",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 911,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "BITTS International Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 912,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Bay College of Health Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 913,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Beauty Institute Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa"
    },
    {
      value: 914,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Beta College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Brampton"
    },
    {
      value: 915,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Beta College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 916,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "BizTech College of Health Sciences, Business & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 917,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brampton Flight Centre",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Caledon",
      "Campus": "Caledon"
    },
    {
      value: 918,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brampton Skin Care Academy Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 919,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brantford Aviation College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Brantford"
    },
    {
      value: 920,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brescia University College",
      "Type": "College /University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 921,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brock University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton Campus"
    },
    {
      value: 922,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Brock University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "St. Catharines",
      "Campus": "Main Campus"
    },
    {
      value: 923,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Bryan College of Applied Health & Business Sciences",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 924,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Business and Technical Training College Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 925,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "C.C.E.C. Canadian Career Education College Ltd",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Vaughan",
      "Campus": "Vaughan"
    },
    {
      value: 926,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Brampton"
    },
    {
      value: 927,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Mississauga"
    },
    {
      value: 928,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough"
    },
    {
      value: 929,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDI College of Business, Technology and Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "North York"
    },
    {
      value: 930,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CITI College of Canadian Careers",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 931,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CITI College of Canadian Careers",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 932,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CITI College of Canadian Careers/ CJ Health Care College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Etobicoke"
    },
    {
      value: 933,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CJ Health Care College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 934,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLI College of Business, Health & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 935,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLLC Inc.",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 936,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CLLC Inc.",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 937,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CMU College of Makeup Art and Design",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Lombard Street and Adelaide Street East"
    },
    {
      value: 938,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CRD College of Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 939,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CTS Canadian Career College Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "North Bay"
    },
    {
      value: 940,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CTS Canadian Career College Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Sudbury"
    },
    {
      value: 941,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CTS Canadian Career College Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Modern College of Hairstyling and Esthetics"
    },
    {
      value: 942,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambrian College of Applied Arts and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Main Campus"
    },
    {
      value: 943,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambrian College of Applied Arts and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Cambrian College of Applied Arts and Technology at Hanson International Academy"
    },
    {
      value: 944,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cambrian College of Applied Arts and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Cambrian College of Applied Arts and Technology at Hanson International Academy"
    },
    {
      value: 945,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Can-AIM Careers Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 946,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CanPacific College of Business and English Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 947,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada Christian College & School of Graduate Theological Studies",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 948,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada College of Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 949,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canada’s National Ballet School",
      "Type": "Ballet school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 950,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Academy of Dental Health & Community Sciences",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 951,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian All Care College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 952,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian All Care College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 953,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Automotive & Trucking Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Cambridge",
      "Campus": "Cambridge"
    },
    {
      value: 954,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Beauty College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Newmarket",
      "Campus": "Newmarket"
    },
    {
      value: 955,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Beauty College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Vaughan",
      "Campus": "Vaughan"
    },
    {
      value: 956,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Beauty College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 957,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 958,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 959,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 960,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Business Skills College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 961,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College for Higher Studies",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 962,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Business, Science & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Mississauga"
    },
    {
      value: 963,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Business, Science & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "North York"
    },
    {
      value: 964,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Business, Science & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Scarborough"
    },
    {
      value: 965,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Business, Science & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 966,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Educators",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 967,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Health, Science and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "Windsor"
    },
    {
      value: 968,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Homeopathic Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 969,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Homeopathic Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Victoria College"
    },
    {
      value: 970,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian College of Naturopathic Medicine",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 971,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Forces College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 972,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Management & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Malton",
      "Campus": "Malton"
    },
    {
      value: 973,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Management & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 974,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Management & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 975,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Institute of Management & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 976,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian International Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 977,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Memorial Chiropractic College (CMCC)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 978,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 979,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 980,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 981,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 982,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto East York",
      "Campus": "Toronto East York"
    },
    {
      value: 983,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Montessori Teacher Education Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Harrowsmith",
      "Campus": "Harrowsmith"
    },
    {
      value: 984,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian National Institute of Health Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 985,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Natural Therapist and Aesthetics Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 986,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Reformed Theological Seminary",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton"
    },
    {
      value: 987,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian School of Natural Nutrition",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 988,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Technical and Management College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Milton",
      "Campus": "Milton"
    },
    {
      value: 989,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Technology College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 990,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "College Drive Campus/The Education Centre"
    },
    {
      value: 991,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "Commerce Court Campus"
    },
    {
      value: 992,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "Main Campus"
    },
    {
      value: 993,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "Aviation Campus"
    },
    {
      value: 994,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College at Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga /Brampton",
      "Campus": "Brampton"
    },
    {
      value: 995,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College at Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga/ Toronto",
      "Campus": "Scarborough"
    },
    {
      value: 996,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College at Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 997,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadore College of Applied Arts and Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Parry Sound",
      "Campus": "West Parry Sound Campus"
    },
    {
      value: 998,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Capital English Solutions and Mentora College of Business & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 999,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Career College Group",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Highbury Campus"
    },
    {
      value: 1000,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Career College Group",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton Campus"
    },
    {
      value: 1001,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Career College Group",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "North York Campus"
    },
    {
      value: 1002,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Career College Group",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1003,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CareerQuest Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1004,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CareerQuest Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Mississauga"
    },
    {
      value: 1005,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Carleton University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1006,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Ashtonbee Campus"
    },
    {
      value: 1007,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Morningside Campus"
    },
    {
      value: 1008,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Progress Campus & Student Residence"
    },
    {
      value: 1009,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "The Centre for Creative Communications"
    },
    {
      value: 1010,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centennial College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Main Campus"
    },
    {
      value: 1011,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre of English Studies",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1012,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Certcana Systems Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1013,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cestar College of Business Health & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1014,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chef School",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1015,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chellsey Institute of Beauty and Health Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Burlington",
      "Campus": "Burlington"
    },
    {
      value: 1016,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Chellsey Institute of Beauty and Health Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1017,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "City College of Business, Health & Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 1018,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Clarkridge Career Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1019,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Clarkridge Career Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1020,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Barrie",
      "Campus": "Centre d’Accès Barrie"
    },
    {
      value: 1021,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Campus Principal"
    },
    {
      value: 1022,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Campus de Toronto"
    },
    {
      value: 1023,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "Centre d’Accès Windsor"
    },
    {
      value: 1024,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Alfred",
      "Campus": "Campus d’Alfred"
    },
    {
      value: 1025,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Centre d’Accès Hamilton"
    },
    {
      value: 1026,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hearst",
      "Campus": "Collège Boréal Hearst"
    },
    {
      value: 1027,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kapuskasing",
      "Campus": "Campus de Kapuskasing"
    },
    {
      value: 1028,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Centre d’Accès London"
    },
    {
      value: 1029,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "New Liskeard",
      "Campus": "Campus de Témiskaming"
    },
    {
      value: 1030,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Niagara",
      "Campus": "Centre d’Accès Niagara"
    },
    {
      value: 1031,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sturgeon Falls",
      "Campus": "Campus de Nipissing"
    },
    {
      value: 1032,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Boréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Timmins",
      "Campus": "Campus de Timmins"
    },
    {
      value: 1033,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of Traditional Chinese Medicine Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1034,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Computek College of Business Healthcare and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham Campus"
    },
    {
      value: 1035,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Computek College of Business Healthcare and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1036,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Concordia Lutheran Theological Seminary (Brock University)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "St. Catharines",
      "Campus": "St. Catharines"
    },
    {
      value: 1037,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Kitchener"
    },
    {
      value: 1038,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Guelph",
      "Campus": "Guelph Campus"
    },
    {
      value: 1039,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Brantford Campus"
    },
    {
      value: 1040,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cambridge",
      "Campus": "Cambridge Campus"
    },
    {
      value: 1041,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ingersoll",
      "Campus": "Ingersoll Campus (Ingersoll Skills Training Centre)"
    },
    {
      value: 1042,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Doon Campus"
    },
    {
      value: 1043,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Stratford",
      "Campus": "Stratford Campus"
    },
    {
      value: 1044,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conestoga College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Waterloo Campus"
    },
    {
      value: 1045,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Confederation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Thunder Bay",
      "Campus": "Main Campus"
    },
    {
      value: 1046,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Confederation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Dryden",
      "Campus": "Dryden Campus"
    },
    {
      value: 1047,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Confederation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Fort Frances",
      "Campus": "Rainy River District Campus"
    },
    {
      value: 1048,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Confederation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Geraldton",
      "Campus": "Greenstone Campus"
    },
    {
      value: 1049,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Confederation College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kenora",
      "Campus": "Lake of the Woods Campus"
    },
    {
      value: 1050,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Connect International School",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1051,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conrad Grebel University College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Affiliated with the University of Waterloo."
    },
    {
      value: 1052,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cornwall Aviation (1979) Ltd.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Summerstown",
      "Campus": "Summerstown"
    },
    {
      value: 1053,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CultureWorks English as a Second Language, Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London @ Brescia College"
    },
    {
      value: 1054,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CultureWorks English as a Second Language, Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London @ King’s College"
    },
    {
      value: 1055,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CultureWorks English as a Second Language, Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1056,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CultureWorks English as a Second Language, Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Carleton Technology"
    },
    {
      value: 1057,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CultureWorks English as a Second Language, Inc.",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Training Centre"
    },
    {
      value: 1058,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dan Robert College of Health Care",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1059,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Diamond Flight Centre London Inc.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1060,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dominican University College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1061,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dominion College of Business Health and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1062,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Dominion College of Business Health and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Brampton"
    },
    {
      value: 1063,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Don Mills Career College for Health, Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1064,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Durham College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa Campus"
    },
    {
      value: 1065,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Durham College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Pickering",
      "Campus": "Pickering Campus"
    },
    {
      value: 1066,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Durham College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Whitby",
      "Campus": "Whitby Campus"
    },
    {
      value: 1067,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Durham Flight Centre Inc.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa"
    },
    {
      value: 1068,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1069,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EF International Language Centres",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1070,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ELS Language Centers",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1071,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eden College Health Sciences, Wellness, Aesthetics, Hair",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Bolton",
      "Campus": "Bolton"
    },
    {
      value: 1072,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Edge Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Burlington",
      "Campus": "Burlington"
    },
    {
      value: 1073,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eight Branches Inc.",
      "Type": "College, University ?",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1074,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Emmanuel Bible College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Kitchener"
    },
    {
      value: 1075,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Emmanuel College of Victoria University",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1076,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "English Encounters Inc.",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Burlington",
      "Campus": "Burlington"
    },
    {
      value: 1077,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "English School of Canada Inc.",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1078,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Essential Helicopters",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "Canadore College Aviation Campus"
    },
    {
      value: 1079,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ethnos Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Durham",
      "Campus": "NTM Canada Missionary Training Centre"
    },
    {
      value: 1080,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 1081,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1082,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "North Campus"
    },
    {
      value: 1083,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oakville",
      "Campus": "West Campus"
    },
    {
      value: 1084,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College Brampton",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1085,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Evergreen College Mississauga Campus",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Evergreen College Mississauga"
    },
    {
      value: 1086,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "FaithWay Baptist College of Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ajax",
      "Campus": "Ajax"
    },
    {
      value: 1087,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Woodstock/Oxford County"
    },
    {
      value: 1088,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "St. Thomas/Elgin Campus"
    },
    {
      value: 1089,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Simcoe/Norfolk Campus"
    },
    {
      value: 1090,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Huron/Bruce Regional Sites – Clinton Centre"
    },
    {
      value: 1091,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London South Campus"
    },
    {
      value: 1092,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London Downtown Campus"
    },
    {
      value: 1093,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fanshawe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London Main Campus"
    },
    {
      value: 1094,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Flair College of Management and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Vaughan",
      "Campus": "Vaughan"
    },
    {
      value: 1095,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fleming College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cobourg",
      "Campus": "Cobourg Campus"
    },
    {
      value: 1096,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fleming College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Haliburton",
      "Campus": "Haliburton Campus"
    },
    {
      value: 1097,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fleming College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Lindsay",
      "Campus": "Frost Campus"
    },
    {
      value: 1098,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Fleming College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Peterborough",
      "Campus": "Sutherland Campus"
    },
    {
      value: 1099,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Foundation for Montessori Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1100,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Freedom College of Business Healthcare and Technology Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1101,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Futures Canadian College of Business, Health and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1102,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "GEOS Languages Plus",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1103,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "GEOS Languages Plus",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1104,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "George Brown College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Young Centre for Performing Arts"
    },
    {
      value: 1105,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "George Brown College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Waterfront Campus"
    },
    {
      value: 1106,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "George Brown College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "St. James Campus"
    },
    {
      value: 1107,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "George Brown College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Ryerson University Campus"
    },
    {
      value: 1108,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "George Brown College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Casa Loma Campus"
    },
    {
      value: 1109,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Barrie",
      "Campus": "Barrie Campus"
    },
    {
      value: 1110,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Bracebridge",
      "Campus": "Muskoka Campus"
    },
    {
      value: 1111,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Midland",
      "Campus": "Midland Campus"
    },
    {
      value: 1112,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Orangeville",
      "Campus": "Orangeville Campus"
    },
    {
      value: 1113,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Orillia",
      "Campus": "Orillia Campus"
    },
    {
      value: 1114,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Owen Sound",
      "Campus": "Owen Sound Campus"
    },
    {
      value: 1115,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Georgian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "South Georgian Bay",
      "Campus": "South Georgian Bay Campus"
    },
    {
      value: 1116,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Gestalt Institute of Toronto",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1117,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Lakes Helicopter Corp.",
      "Type": "Flight school/ private college",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Breslau",
      "Campus": "Breslau"
    },
    {
      value: 1118,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Greystone College of Business and Technology (Toronto) Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1119,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hamilton Institute for Health Personnel",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton"
    },
    {
      value: 1120,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hansa Language Centre",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Eglinton Campus"
    },
    {
      value: 1121,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hansa Language Centre",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Yonge Campus"
    },
    {
      value: 1122,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton Campus"
    },
    {
      value: 1123,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "HSL School of Languages"
    },
    {
      value: 1124,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hanson International Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 1125,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Harris Institute For The Arts",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1126,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Heartland International English School",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1127,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Heritage College & Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cambridge",
      "Campus": "Cambridge"
    },
    {
      value: 1128,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Herzing College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa Campus"
    },
    {
      value: 1129,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Herzing College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1130,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Humber College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Humber Lakeshore Campus"
    },
    {
      value: 1131,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Humber College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Humber North Campus"
    },
    {
      value: 1132,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Humber College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Orangeville",
      "Campus": "Orangeville"
    },
    {
      value: 1133,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Huntington University (Laurentian University)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Sudbury"
    },
    {
      value: 1134,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Huron University College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1135,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "IBT College Business Travel & Tourism Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1136,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1137,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Yorkville Avenue Location"
    },
    {
      value: 1138,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Yonge Street Location"
    },
    {
      value: 1139,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Main Location"
    },
    {
      value: 1140,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "College Street Location"
    },
    {
      value: 1141,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Bloor Street West Location"
    },
    {
      value: 1142,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILAC - International Language Academy of Canada",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Bloor Street East Location"
    },
    {
      value: 1143,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "St. Patrick Street"
    },
    {
      value: 1144,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "University Avenue"
    },
    {
      value: 1145,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Elizabeth Street"
    },
    {
      value: 1146,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Dundas Street West"
    },
    {
      value: 1147,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ILSC Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Bloor Street West"
    },
    {
      value: 1148,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Innovations in Business Solutions Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 1149,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Innovations in Business Solutions Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1150,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute for Advanced Judaic Studies",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1151,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute for Christian Studies",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1152,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute of Machine Tool Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1153,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute of Technical Trades Limited",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1154,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Academy of Massage Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1155,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Beauty Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1156,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Centre for English for Academic Preparation Inc",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1157,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Institute of Travel and Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1158,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "International Test Pilots School",
      "Type": "Flight school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1159,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "JRS College of Business and Health Care Inc",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1160,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Jenny Wong Beauty Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 1161,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Jyot’s Aesthetics Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1162,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "KLC College: Healthcare, Business, Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Main Campus"
    },
    {
      value: 1163,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "KLC College: Healthcare, Business, Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "East Campus"
    },
    {
      value: 1164,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "KLC College: Healthcare, Business, Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kingston/Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1165,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "KLC College: Healthcare, Business, Education",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kingston/Whitby",
      "Campus": "Whitby"
    },
    {
      value: 1166,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kaplan International English",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1167,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kikkawa College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1168,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "King’s University College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1169,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Knox College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1170,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "La Cité collégiale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Alfred",
      "Campus": "Campus d’Alfred"
    },
    {
      value: 1171,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "La Cité collégiale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hawkesbury",
      "Campus": "Campus de Hawkesbury"
    },
    {
      value: 1172,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "La Cité collégiale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Université d’Ottawa (Collaboratif)"
    },
    {
      value: 1173,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "La Cité collégiale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Campus principal"
    },
    {
      value: 1174,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "La Cité collégiale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Campus Alphonse-Desjardins"
    },
    {
      value: 1175,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lakehead University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Thunder Bay",
      "Campus": "Main Campus"
    },
    {
      value: 1176,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lakehead University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Orillia",
      "Campus": "Orillia Campus"
    },
    {
      value: 1177,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lambton College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Lambton College at Queen’s College of Business"
    },
    {
      value: 1178,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lambton College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Technology and Public Safety"
    },
    {
      value: 1179,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lambton College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sarnia",
      "Campus": "Main Campus"
    },
    {
      value: 1180,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lambton College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Lambton College at Cestar College of Business"
    },
    {
      value: 1181,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lambton College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Health and Technology"
    },
    {
      value: 1182,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Language Studies International (Canada) Ltd.",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1183,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Barrie",
      "Campus": "Barrie Campus"
    },
    {
      value: 1184,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brockville",
      "Campus": "Laurentian at St. Lawrence"
    },
    {
      value: 1185,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cornwall",
      "Campus": "Laurentian at St. Lawrence"
    },
    {
      value: 1186,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Laurentian at St. Lawrence"
    },
    {
      value: 1187,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Cambrian College (Collaborative)"
    },
    {
      value: 1188,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Collège Boréal (Collaborative)"
    },
    {
      value: 1189,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Sudbury Campus"
    },
    {
      value: 1190,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sault Ste. Marie",
      "Campus": "Sault College (Collaborative)"
    },
    {
      value: 1191,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Laurentian University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Timmins",
      "Campus": "Northern College (Collaborative)"
    },
    {
      value: 1192,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Le Cordon Bleu Ottawa Culinary Arts Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1193,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Liaison College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Kitchener"
    },
    {
      value: 1194,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Liaison College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton Campus"
    },
    {
      value: 1195,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Liaison College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton Campus"
    },
    {
      value: 1196,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Liaison College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oakville",
      "Campus": "Oakville Campus"
    },
    {
      value: 1197,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Liaison College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Downtown Toronto Campus"
    },
    {
      value: 1198,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "London Language Institute",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1199,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Loyalist College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Bancroft",
      "Campus": "Bancroft Campus"
    },
    {
      value: 1200,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Loyalist College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Belleville",
      "Campus": "Main Campus"
    },
    {
      value: 1201,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Loyalist College at Toronto Business College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Toronto Business College – Mississauga Campus"
    },
    {
      value: 1202,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MAG Canada",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Garson",
      "Campus": "Garson"
    },
    {
      value: 1203,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MLC College of Business, Technology and Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1204,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maayanos Torah",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1205,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Master’s College and Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Peterborough",
      "Campus": "Peterborough"
    },
    {
      value: 1206,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Max the Mutt College of Animation, Art & Design",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1207,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster Divinity College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton"
    },
    {
      value: 1208,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Downtown Centre"
    },
    {
      value: 1209,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "McMaster Innovation Park"
    },
    {
      value: 1210,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Main Campus"
    },
    {
      value: 1211,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "McMaster Nursing at Mohawk (Collaborative)"
    },
    {
      value: 1212,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Burlington",
      "Campus": "DeGroote School of Business"
    },
    {
      value: 1213,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "McMaster Nursing at Conestoga (Collaborative)"
    },
    {
      value: 1214,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Waterloo Regional Campus"
    },
    {
      value: 1215,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McMaster University",
      "Type": "University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "St. Catharines",
      "Campus": "Niagara Regional Campus"
    },
    {
      value: 1216,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Metalworks Institute of Sound & Music Production",
      "Type": "Music school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1217,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Metro College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1218,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Metrocare Health Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "North York",
      "Campus": "North York"
    },
    {
      value: 1219,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Miami Ad School Toronto",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1220,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mississauga Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1221,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mohawk College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Mohawk Laurier Campus"
    },
    {
      value: 1222,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mohawk College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Stoney Creek",
      "Campus": "Stoney Creek Campus (Skilled Trades and Apprenticeship Research, Resources and Training Institute)"
    },
    {
      value: 1223,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mohawk College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Fennell Campus"
    },
    {
      value: 1224,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAV Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Cornwall",
      "Campus": "Institute for Applied Health Sciences"
    },
    {
      value: 1225,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NAV Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Cornwall",
      "Campus": "Cornwall (NAV Centre Training and Conference Centre)"
    },
    {
      value: 1226,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "National Academy of Health & Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1227,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "National Academy of Health & Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton"
    },
    {
      value: 1228,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "National Academy of Health & Business",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1229,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "National Helicopters Inc",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kleinburg",
      "Campus": "Kleinburg"
    },
    {
      value: 1230,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "National Institute of Hairstyling and Advanced Aesthetics",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1231,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ner Israel Joseph (J.T.) Tanenbaum Yeshivah College of Toronto",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Thornhill",
      "Campus": "Thornhill"
    },
    {
      value: 1232,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NetPlus College of Information Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1233,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Netsoft College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1234,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "NewCastle College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Brantford"
    },
    {
      value: 1235,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Niagara College Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Virgil",
      "Campus": "Virgil Campus"
    },
    {
      value: 1236,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Niagara College Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "St. Catharines",
      "Campus": "St. Catharines Campus"
    },
    {
      value: 1237,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Niagara College Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Niagara-On-The-Lake",
      "Campus": "Niagara-On-The-Lake Campus"
    },
    {
      value: 1238,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Niagara College Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Welland",
      "Campus": "Welland Campus"
    },
    {
      value: 1239,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nipissing University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "North Bay",
      "Campus": "North Bay Campus"
    },
    {
      value: 1240,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nipissing University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Brantford Campus"
    },
    {
      value: 1241,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nipissing University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Bracebridge",
      "Campus": "Muskoka Campus"
    },
    {
      value: 1242,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North American Business College of Sciences & Language",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1243,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North American College of Information Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1244,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North American College of Information Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1245,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North American College of Pharmaceutical Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1246,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North American School of Information Technology Inc. (NASIT)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1247,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northeastern University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1248,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kirkland Lake",
      "Campus": "Kirkland Lake Campus"
    },
    {
      value: 1249,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "South Porcupine",
      "Campus": "Timmins Campus"
    },
    {
      value: 1250,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Temiskaming Shores",
      "Campus": "Haileybury Campus"
    },
    {
      value: 1251,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Northern College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Northern College at Pures College of Technology"
    },
    {
      value: 1252,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "OCAD University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Ontario College of Art & Design University"
    },
    {
      value: 1253,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "OIEG Oxford International Education Group",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1254,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario College of Business and Law",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1255,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario College of Health and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Stoney Creek",
      "Campus": "Stoney Creek"
    },
    {
      value: 1256,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1257,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario College of Traditional Chinese Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 1258,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario College of Traditional Chinese Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham Clinic"
    },
    {
      value: 1259,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario Institute of Audio Recording Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1260,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario Truck Driving School",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1261,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ontario Truck Driving School",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Niagara-on-the-Lake",
      "Campus": "Niagara-on-the-Lake"
    },
    {
      value: 1262,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ottawa School of Art / École d’art d’Ottawa",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1263,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ottawa School of Beauty Limited",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa Academy"
    },
    {
      value: 1264,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ottawa School of Beauty Limited",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "West End Academy"
    },
    {
      value: 1265,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ottawa School of Beauty Limited",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Beauty Academy London"
    },
    {
      value: 1266,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Our Lady Seat of Wisdom College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Barry’s Bay",
      "Campus": "Barry’s Bay"
    },
    {
      value: 1267,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business & Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Barrie",
      "Campus": "Barrie"
    },
    {
      value: 1268,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1269,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Toronto"
    },
    {
      value: 1270,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Mississauga"
    },
    {
      value: 1271,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Peterborough"
    },
    {
      value: 1272,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oxford College of Arts, Business and Technology Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Burlington"
    },
    {
      value: 1273,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Peak Healthcare Private College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1274,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Petrel College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1275,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pharma-Medical Science College of Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1276,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Progressive Training College of Business and Health",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1277,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Progressive Training College of Business and Health",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1278,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Protégé School",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1279,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pures College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1280,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Pures College of Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1281,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Queen's Language School",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto ",
      "Campus": "Toronto "
    },
    {
      value: 1282,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Queen’s College of Business, Technology & Public Safety",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1283,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Queen’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Main Campus"
    },
    {
      value: 1284,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Queen’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Queen’s School of Business Toronto Facility"
    },
    {
      value: 1285,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Quest Language Studies",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1286,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "RAC Digital Arts College, Recording Arts Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1287,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Randolph College for the Performing Arts",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1288,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Redeemer University College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ancaster",
      "Campus": "Ancaster"
    },
    {
      value: 1289,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Regis College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1290,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Renison University College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Waterloo"
    },
    {
      value: 1291,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Canadian College of Massage Therapy Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1292,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Canadian Institute of International Studies",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1293,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Crown College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1294,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Military College of Canada",
      "Type": "College /University/",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Kingston"
    },
    {
      value: 1295,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Rudolf Steiner Centre Toronto",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Thornhill",
      "Campus": "Thornhill"
    },
    {
      value: 1296,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ryerson University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1297,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ryerson University International College",
      "Type": "University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1298,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saint Elizabeth Health Career College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 1299,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sault College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sault Ste. Marie",
      "Campus": "Main Campus"
    },
    {
      value: 1300,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sault College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Collège Boréal d’arts appliqués et de technologie (Collaborative)"
    },
    {
      value: 1301,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sault College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Collège Boréal d’arts appliqués et de technologie (Collaborative)"
    },
    {
      value: 1302,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sault College of Applied Arts and Technology at Trios College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Trios College - Brampton Campus"
    },
    {
      value: 1303,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "School of Photographic Arts Ottawa",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1304,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham Campus"
    },
    {
      value: 1305,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Peterborough",
      "Campus": "Peterborough Campus"
    },
    {
      value: 1306,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "King City",
      "Campus": "King Campus"
    },
    {
      value: 1307,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Jane Campus"
    },
    {
      value: 1308,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Newnham Campus"
    },
    {
      value: 1309,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Seneca @ York Campus"
    },
    {
      value: 1310,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Seneca College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Yorkgate Campus"
    },
    {
      value: 1311,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sheridan College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Hazel McCallion Campus"
    },
    {
      value: 1312,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sheridan College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Davis Campus"
    },
    {
      value: 1313,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sheridan College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Oakville",
      "Campus": "Oakville - Trafalgar Campus"
    },
    {
      value: 1314,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Shiatsu School of Canada Inc",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1315,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Six Nations Polytechnic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ohsweken",
      "Campus": "Ohsweken"
    },
    {
      value: 1316,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sol Schools International",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1317,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Southern Ontario Dental College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ancaster",
      "Campus": "Ancaster"
    },
    {
      value: 1318,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Spectrum Airways",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Burlington",
      "Campus": "Burlington"
    },
    {
      value: 1319,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sprott Shaw Language College (Ontario) Corp.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1320,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Augustine’s Seminary of Toronto",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1321,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Augustine’s Seminary of Toronto",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1322,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Clair College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Chatham",
      "Campus": "Thames Campus"
    },
    {
      value: 1323,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Clair College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "St. Clair College Centre for the Arts"
    },
    {
      value: 1324,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Clair College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "Windsor Campus"
    },
    {
      value: 1325,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Clair College at Ace Acumen Academy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "St. Clair College at ACE Acumen Academy - Toronto"
    },
    {
      value: 1326,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Clair College at Ace Acumen Academy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "St. Clair College at ACE Acumen Academy - Mississauga"
    },
    {
      value: 1327,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. George International College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1328,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Jerome’s University (University of Waterloo)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Waterloo"
    },
    {
      value: 1329,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Lawrence College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brockville",
      "Campus": "Brockville"
    },
    {
      value: 1330,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Lawrence College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cornwall",
      "Campus": "Cornwall"
    },
    {
      value: 1331,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Lawrence College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kingston",
      "Campus": "Kingston"
    },
    {
      value: 1332,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Lawrence College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "St. Lawrence College at Alpha College of Business and Technology (Collaborative)"
    },
    {
      value: 1333,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Paul’s University College (University of Waterloo)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Waterloo"
    },
    {
      value: 1334,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Philip’s Seminary / The Oratory of St. Philip Neri-Toronto",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Dunn Avenue"
    },
    {
      value: 1335,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Philip’s Seminary / The Oratory of St. Philip Neri-Toronto",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "King Street West"
    },
    {
      value: 1336,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Thomas Flight Centre",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "St.Thomas",
      "Campus": "St.Thomas"
    },
    {
      value: 1337,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stafford House Toronto",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1338,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1339,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1340,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1341,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stanford International College of Business and Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1342,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Stratford Air Services Ltd.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Stratford",
      "Campus": "Stratford"
    },
    {
      value: 1343,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sunview College Business Technology Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1344,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sunview College Business Technology Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton"
    },
    {
      value: 1345,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sunview College Business Technology Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1346,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Sunview College Business Technology Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1347,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "TAHA Academy of Beauty, Business & Health",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1348,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Careers",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1349,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood International College Ltd.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1350,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tamwood Language Centres",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1351,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Aesthetics and Hairstyling College of Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Vaughan",
      "Campus": "Vaughan"
    },
    {
      value: 1352,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Audio Recording Academy (T.A.R.A)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa Campus"
    },
    {
      value: 1353,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Automotive Training Centre (Ontario) Ltd.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1354,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Automotive Training Centre (Ontario) Ltd.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto/ Cambridge ",
      "Campus": "Cambridge Campus"
    },
    {
      value: 1355,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Create Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1356,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Language Gallery",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1357,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Michener Institute for Applied Health Sciences",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1358,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Royal Conservatory of Music",
      "Type": " Music school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1359,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The School of Toronto Dance Theatre",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1360,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Thorneloe University (Laurentian University)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Sudbury"
    },
    {
      value: 1361,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Airways Inc.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Markham",
      "Campus": "Markham"
    },
    {
      value: 1362,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Art Therapy Institute",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1363,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Baptist Seminary and Bible College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1364,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1365,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto College of Dental Hygiene and Auxiliaries Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Lodestar Campus"
    },
    {
      value: 1366,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto College of Dental Hygiene and Auxiliaries Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Steeprock Campus"
    },
    {
      value: 1367,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Economical Management College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1368,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Film School",
      "Type": "University /college",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Davisville Campus"
    },
    {
      value: 1369,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Film School",
      "Type": "University /college",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Dundas Square Main Campus"
    },
    {
      value: 1370,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Film School",
      "Type": "University /college",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Dundas Square 2nd Campus"
    },
    {
      value: 1371,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Institute of Pharmaceutical Technology",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1372,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto International Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1373,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto International Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto /Scarborough",
      "Campus": "Toronto"
    },
    {
      value: 1374,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto Montessori Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Richmond Hill",
      "Campus": "Richmond Hill"
    },
    {
      value: 1375,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto School of Management",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1376,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Toronto School of Traditional Chinese Medicine",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1377,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Transitions College of Business & Career Studies",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Niagara Falls",
      "Campus": "Niagara Falls"
    },
    {
      value: 1378,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Travel College Canada",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1379,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trebas Institute",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1380,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trent University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Trent University Durham"
    },
    {
      value: 1381,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trent University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Peterborough",
      "Campus": "Main Campus"
    },
    {
      value: 1382,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trillium College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa"
    },
    {
      value: 1383,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trillium College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "St. Catharines",
      "Campus": "St. Catharines"
    },
    {
      value: 1384,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Trillium Esthetic & Hair Technology College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Richmond Hill",
      "Campus": "Richmond Hill"
    },
    {
      value: 1385,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tyndale University College & Seminary",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Ballyconnor Campus"
    },
    {
      value: 1386,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Tyndale University College & Seminary",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Bayview Campus"
    },
    {
      value: 1387,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université Saint-Paul/St. Paul University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa"
    },
    {
      value: 1388,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Hearst",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Hearst",
      "Campus": "Campus de Hearst"
    },
    {
      value: 1389,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Hearst",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kapuskasing",
      "Campus": "Campus de Kapuskasing"
    },
    {
      value: 1390,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Hearst",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Timmins",
      "Campus": "Campus de Timmins"
    },
    {
      value: 1391,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université d’Ottawa/University of Ottawa",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Algonquin College of Applied Arts and Technology (Collaborative)"
    },
    {
      value: 1392,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université d’Ottawa/University of Ottawa",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "La Cité Collégiale (Collaborative)"
    },
    {
      value: 1393,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université d’Ottawa/University of Ottawa",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Main Campus"
    },
    {
      value: 1394,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université d’Ottawa/University of Ottawa",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Pembroke",
      "Campus": "Algonquin College of Applied Arts and Technology (Collaborative)"
    },
    {
      value: 1395,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Guelph",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Guelph",
      "Campus": "Main Campus"
    },
    {
      value: 1396,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Guelph",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "University of Guelph-Humber"
    },
    {
      value: 1397,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Guelph",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Alfred",
      "Campus": "Campus d’Alfred"
    },
    {
      value: 1398,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Guelph",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kemptville",
      "Campus": "Kemptville Campus"
    },
    {
      value: 1399,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Guelph",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Ridgetown",
      "Campus": "Ridgetown Campus"
    },
    {
      value: 1400,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Ontario Institute of Technology (UOIT)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa"
    },
    {
      value: 1401,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of St. Michael’s College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1402,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Sudbury (Laurentian University)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Sudbury",
      "Campus": "Sudbury"
    },
    {
      value: 1403,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Toronto",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga"
    },
    {
      value: 1404,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Toronto",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Main Campus"
    },
    {
      value: 1405,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Toronto",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1406,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Trinity College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "University of Toronto - Hoskin Avenue"
    },
    {
      value: 1407,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Trinity College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "University of Toronto - Devonshire Place"
    },
    {
      value: 1408,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Breslau",
      "Campus": "Wellington Flight Centre Campus (Aviation)"
    },
    {
      value: 1409,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Cambridge",
      "Campus": "School of Architecture"
    },
    {
      value: 1410,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Allen Square Campus"
    },
    {
      value: 1411,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Balsillie School of International Affairs"
    },
    {
      value: 1412,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Main Campus"
    },
    {
      value: 1413,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Health Sciences Campus"
    },
    {
      value: 1414,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "44 Gaukel St"
    },
    {
      value: 1415,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "ELAS program (operated by Conestoga College)"
    },
    {
      value: 1416,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Stratford",
      "Campus": "Stratford Campus"
    },
    {
      value: 1417,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Waterloo",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Master of Taxation Site"
    },
    {
      value: 1418,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Windsor",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "Windsor"
    },
    {
      value: 1419,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Upper Madison College of Advanced Language Learning and Skill Development",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Advanced Learning Centre"
    },
    {
      value: 1420,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Victoria College in Victoria University (University of Toronto)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1421,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Waterloo Lutheran Seminary (Wilfred Laurier University)",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Waterloo"
    },
    {
      value: 1422,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Waterloo Wellington Flight Centre",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Breslau",
      "Campus": "Breslau"
    },
    {
      value: 1423,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wellsprings College of Massage Therapy & Esthetics",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Richmond Hill",
      "Campus": "Richmond Hill"
    },
    {
      value: 1424,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western Town College for ESL",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1425,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Western University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "London",
      "Campus": "The University of Western Ontario"
    },
    {
      value: 1426,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Westervelt College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Brantford"
    },
    {
      value: 1427,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Westervelt College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "Kitchener"
    },
    {
      value: 1428,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Westervelt College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London"
    },
    {
      value: 1429,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Westervelt College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Windsor / London",
      "Campus": "Windsor"
    },
    {
      value: 1430,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wilfrid Laurier University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Brantford",
      "Campus": "Brantford Campus"
    },
    {
      value: 1431,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wilfrid Laurier University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Kitchener Location"
    },
    {
      value: 1432,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wilfrid Laurier University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Location"
    },
    {
      value: 1433,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wilfrid Laurier University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Waterloo",
      "Campus": "Main Campus"
    },
    {
      value: 1434,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Willis College of Business, Technology, Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Arnprior",
      "Campus": "Arnprior Campus"
    },
    {
      value: 1435,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Willis College of Business, Technology, Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Ottawa",
      "Campus": "Ottawa Campus"
    },
    {
      value: 1436,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Willis College of Business, Technology, Healthcare",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Smiths Falls",
      "Campus": "Smiths Falls Campus"
    },
    {
      value: 1437,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Willowbank School of Restoration Arts",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Queenston",
      "Campus": "Queenston"
    },
    {
      value: 1438,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Word of Life Bible Institute",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Owen Sound",
      "Campus": "Owen Sound"
    },
    {
      value: 1439,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "World Chinese Medicine and Acupuncture College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Scarborough",
      "Campus": "Scarborough"
    },
    {
      value: 1440,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Wycliffe College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1441,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "York Construction Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto"
    },
    {
      value: 1442,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "York University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Campus Glendon"
    },
    {
      value: 1443,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "York University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Campus principal"
    },
    {
      value: 1444,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "York University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Miles S. Nadal Management Centre"
    },
    {
      value: 1445,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "York University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Osgoode Hall Law School"
    },
    {
      value: 1446,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yorkville University/Toronto Film School",
      "Type": "University",
      "PGWP": 1,
      "Province": "Ontario",
      "City": "Concord",
      "Campus": "Concord"
    },
    {
      value: 1447,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Brampton",
      "Campus": "Brampton Campus"
    },
    {
      value: 1448,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Hamilton",
      "Campus": "Hamilton Campus"
    },
    {
      value: 1449,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Kitchener",
      "Campus": "Kitchener Campus"
    },
    {
      value: 1450,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "London",
      "Campus": "London Campus"
    },
    {
      value: 1451,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Mississauga",
      "Campus": "Mississauga Campus"
    },
    {
      value: 1452,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Oshawa",
      "Campus": "Oshawa Campus"
    },
    {
      value: 1453,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Windsor",
      "Campus": "Windsor Campus"
    },
    {
      value: 1454,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc. (Scarborough)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Scarborough Campus"
    },
    {
      value: 1455,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "triOS College Business Technology Healthcare Inc. (Scarborough)",
      "Type": "College",
      "PGWP": 0,
      "Province": "Ontario",
      "City": "Toronto",
      "Campus": "Toronto Campus"
    },
    {
      value: 1456,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning – PEI",
      "Type": "College",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1457,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de l’Île",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Centre de formation Charlottetown"
    },
    {
      value: 1458,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de l’Île",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "DeBlois",
      "Campus": "Centre de formation DeBlois"
    },
    {
      value: 1459,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de l’Île",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Wellington",
      "Campus": "Main Campus"
    },
    {
      value: 1460,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Alberton",
      "Campus": "West Prince Campus"
    },
    {
      value: 1461,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Prince of Wales Campus"
    },
    {
      value: 1462,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Tourism and Culinary Centre"
    },
    {
      value: 1463,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Georgetown",
      "Campus": "Georgetown Centre"
    },
    {
      value: 1464,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Montague",
      "Campus": "Montague Centre"
    },
    {
      value: 1465,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "O’Leary",
      "Campus": "O’Leary Centre"
    },
    {
      value: 1466,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Slemon Park",
      "Campus": "Atlantic Police Academy"
    },
    {
      value: 1467,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Souris",
      "Campus": "Souris Site"
    },
    {
      value: 1468,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Summerside",
      "Campus": "East Prince Centre"
    },
    {
      value: 1469,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Summerside",
      "Campus": "Marine Training Centre"
    },
    {
      value: 1470,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Summerside",
      "Campus": "Summerside"
    },
    {
      value: 1471,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Holland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Tignish",
      "Campus": "Dalton Centre"
    },
    {
      value: 1472,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institute of Advanced Learning",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1473,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Marguerite Connolly Training and Consulting",
      "Type": "College",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1474,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maritime Christian College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1475,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Moonlight International Academy",
      "Type": "College",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Little Sands",
      "Campus": "Little Sands"
    },
    {
      value: 1476,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Red Cliff College of Applied Technology and Management",
      "Type": "College",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1477,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Study Abroad Canada Language Institute",
      "Type": "Language school",
      "PGWP": 0,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1478,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Prince Edward Island",
      "Type": "University",
      "PGWP": 1,
      "Province": "Prince Edward Island",
      "City": "Charlottetown",
      "Campus": "Charlottetown"
    },
    {
      value: 1479,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Académie de beauté Gordon-Robertson",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Beaconsfield",
      "Campus": "Beaconsfield"
    },
    {
      value: 1480,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Académie de l’entrepreneurship Québécois inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1481,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Académie des pompiers",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mirabel",
      "Campus": "Mirabel"
    },
    {
      value: 1482,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Agrinova",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Alma",
      "Campus": "Alma"
    },
    {
      value: 1483,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Air Richelieu",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longeuil",
      "Campus": "Longeuil"
    },
    {
      value: 1484,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Atelier-école Les Cèdres",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Les Cèdres",
      "Campus": "Les Cèdres"
    },
    {
      value: 1485,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aviron Québec Collège Technique",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1486,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Biopterre - Centre de développement des bioproduits",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Pocatière",
      "Campus": "La Pocatière"
    },
    {
      value: 1487,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Bishop’s University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1488,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "CDE Collège",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1489,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Campus Notre-Dame-de-Foy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Augustin-de-Desmaures",
      "Campus": "Saint-Augustin-de-Desmaures"
    },
    {
      value: 1490,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Campus d'effets visuels Inc",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1491,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cargair ltée",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1492,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Carrefour Formation Mauricie formation professionnelle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Shawinigan",
      "Campus": "Shawinigan"
    },
    {
      value: 1493,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep André-Laurendeau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1494,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Beauce-Appalaches",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Georges",
      "Campus": "Saint-Georges"
    },
    {
      value: 1495,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Édouard Montpetit",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1496,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep François-Xavier Garneau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1497,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Gérald-Godin",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1498,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep John Abbott",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Anne-de-Bellevue",
      "Campus": "Sainte-Anne-de-Bellevue"
    },
    {
      value: 1499,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Limoilou",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1500,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Lionel Groulx",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Thérèse",
      "Campus": "Sainte-Thérèse"
    },
    {
      value: 1501,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Marie-Victorin",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1502,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Montmorency",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1503,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep Saint-Jean-sur-Richelieu",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1504,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Baie-Comeau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Baie-Comeau",
      "Campus": "Baie-Comeau"
    },
    {
      value: 1505,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Bois-de-Boulogne",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1506,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Chicoutimi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1507,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Drummondville",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1508,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Granby-Haute-Yamaska",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Granby",
      "Campus": "Granby"
    },
    {
      value: 1509,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Jonquière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1510,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de La Pocatière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Pocatière",
      "Campus": "La Pocatière"
    },
    {
      value: 1511,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Lévis-Lauzon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1512,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Maisonneuve",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1513,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Matane",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Matane",
      "Campus": "Matane"
    },
    {
      value: 1514,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Rimouski",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rimouski",
      "Campus": "Rimouski"
    },
    {
      value: 1515,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Rivière-du-Loup",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rivière-du-Loup",
      "Campus": "Rivière-du-Loup"
    },
    {
      value: 1516,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Rosemont",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1517,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Saint-Jérôme",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jérôme",
      "Campus": "Saint-Jérôme"
    },
    {
      value: 1518,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Saint-Laurent",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1519,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Sainte-Foy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1520,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Sept-Îles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sept-Îles",
      "Campus": "Sept-Îles"
    },
    {
      value: 1521,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Sherbrooke",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1522,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Sorel-Tracy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sorel-Tracy",
      "Campus": "Sorel-Tracy"
    },
    {
      value: 1523,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de St-Félicien",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Félicien",
      "Campus": "Saint-Félicien"
    },
    {
      value: 1524,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de St-Hyacinthe",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hyacinthe",
      "Campus": "Saint-Hyacinthe"
    },
    {
      value: 1525,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Thetford",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Thetford Mines",
      "Campus": "Thetford Mines"
    },
    {
      value: 1526,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Trois-Rivières",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1527,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Valleyfield",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Salaberry-de-Valleyfield",
      "Campus": "Salaberry-de-Valleyfield"
    },
    {
      value: 1528,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de Victoriaville",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Victoriaville",
      "Campus": "Victoriaville"
    },
    {
      value: 1529,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de la Gaspésie et des Îles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1530,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de la Gaspésie et des Îles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gaspé",
      "Campus": "Gaspé"
    },
    {
      value: 1531,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de l’Abitibi-Témiscamingue",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rouyn-Noranda",
      "Campus": "Rouyn-Noranda"
    },
    {
      value: 1532,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep de l’Outaouais",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1533,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep du Vieux Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1534,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep d’Ahuntsic",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1535,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cégep régional de Lanaudière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Repentigny",
      "Campus": "Repentigny"
    },
    {
      value: 1536,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre Frère-Moffet",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Ville-Marie",
      "Campus": "Ville-Marie"
    },
    {
      value: 1537,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre PACC-Formation professionnelle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lasalle",
      "Campus": "Lasalle"
    },
    {
      value: 1538,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre Performance Plus",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lachute",
      "Campus": "Lachute"
    },
    {
      value: 1539,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre Polymétier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rouyn-Noranda",
      "Campus": "Rouyn-Noranda"
    },
    {
      value: 1540,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre collégial de transfert de technologie en télécommunications",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1541,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre collégial de transfert technologique en optique-photonique OPTECH",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Pocatière",
      "Campus": "La Pocatière"
    },
    {
      value: 1542,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de Formation professionnelle de Lennoxville",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1543,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de carrières Saint Pius X",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1544,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de développement des compétences Laurier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lachute",
      "Campus": "Lachute"
    },
    {
      value: 1545,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de développement des compétences Laurier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Pont-Viau"
    },
    {
      value: 1546,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de développement des compétences Laurier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Repentigny",
      "Campus": "Repentigny"
    },
    {
      value: 1547,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de développement des compétences Laurier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Eustache",
      "Campus": "Saint-Eustache"
    },
    {
      value: 1548,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de développement des composites du Québec (CDCQ)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jérôme",
      "Campus": "Saint-Jérôme"
    },
    {
      value: 1549,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation Compétence-de-la-Rive-Sud (FP)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Prairie",
      "Campus": "La Prairie"
    },
    {
      value: 1550,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation Compétences-2000",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1551,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation Harricana",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Amos",
      "Campus": "Amos"
    },
    {
      value: 1552,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation Le Chantier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1553,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation agricole",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Anselme",
      "Campus": "Saint-Anselme"
    },
    {
      value: 1554,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation agricole de Mirabel",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mirabel",
      "Campus": "Mirabel"
    },
    {
      value: 1555,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation continue Pontiac",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Shawville",
      "Campus": "Shawville"
    },
    {
      value: 1556,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation continue de Val d’Or",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Val-d’Or",
      "Campus": "Val-d’Or"
    },
    {
      value: 1557,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation des Bâtisseurs",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Marie",
      "Campus": "Sainte-Marie"
    },
    {
      value: 1558,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation des Métiers de l’Acier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1559,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation des Nouvelles-Technologies",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Thérèse",
      "Campus": "Sainte-Thérèse"
    },
    {
      value: 1560,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation des métiers de Verdun",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Verdun",
      "Campus": "Verdun"
    },
    {
      value: 1561,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation du transport routier de Saint-Jérôme",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mirabel",
      "Campus": "Mirabel"
    },
    {
      value: 1562,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation en mécanique de véhicules lourds",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1563,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation en métallurgie de Laval",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1564,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation en montage de lignes",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1565,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation en transport de Charlesbourg",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1566,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation générale aux adultes - Campus de Brome-Missisquoi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Cowansville",
      "Campus": "Cowansville"
    },
    {
      value: 1567,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation horticole de Laval",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1568,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Havre-Saint-Pierre",
      "Campus": "Havre-Saint-Pierre"
    },
    {
      value: 1569,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle (Paspébiac-Bonaventure)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Bonaventure",
      "Campus": "Bonaventure"
    },
    {
      value: 1570,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle - Campus de Brome-Missisquoi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Cowansville",
      "Campus": "Cowansville"
    },
    {
      value: 1571,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle - Édifice André-Jacob - Pavillon des Rives - Pavillon des Baies",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Baie-Comeau",
      "Campus": "Baie-Comeau"
    },
    {
      value: 1572,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle 24-Juin",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1573,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle A.-W.-Gagné",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sept-Îles",
      "Campus": "Sept-Îles"
    },
    {
      value: 1574,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle ACCESS",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Lambert",
      "Campus": "Saint-Lambert"
    },
    {
      value: 1575,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Alma",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Alma",
      "Campus": "Alma"
    },
    {
      value: 1576,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Anchor",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "New Carlisle",
      "Campus": "New Carlisle"
    },
    {
      value: 1577,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle André-Morissette",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Plessisville",
      "Campus": "Plessisville"
    },
    {
      value: 1578,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Anjou",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1579,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Antoine-de-Saint-Exupéry",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1580,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Bel-Avenir",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1581,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Bernard-Gariépy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sorel-Tracy",
      "Campus": "Sorel-Tracy"
    },
    {
      value: 1582,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle CSSMI-CSSWL",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "St-Eustache",
      "Campus": "St-Eustache"
    },
    {
      value: 1583,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Calixa-Lavallée",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1584,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Chanoine-Armand-Racicot",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1585,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Charlotte-Tassé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1586,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Châteauguay Valley",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Ormstown",
      "Campus": "Ormstown"
    },
    {
      value: 1587,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Compétences Outaouais",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1588,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Daniel-Johnson",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1589,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Gabriel-Dionne",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Côte-Nord-du-Golfe-du-Saint-Laurent",
      "Campus": "Côte-Nord-du-Golfe-du-Saint-Laurent"
    },
    {
      value: 1590,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Gabriel-Rousseau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1591,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Harrington",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Côte-Nord-du-Golfe-du-Saint-Laurent",
      "Campus": "Côte-Nord-du-Golfe-du-Saint-Laurent"
    },
    {
      value: 1592,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Jacques-Rousseau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1593,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Jonquière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1594,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Kegaska",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Côte-Nord-du-Golfe-du-Saint-Laurent",
      "Campus": "Côte-Nord-du-Golfe-du-Saint-Laurent"
    },
    {
      value: 1595,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle La Relance",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Chandler",
      "Campus": "Chandler"
    },
    {
      value: 1596,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Lac-Abitibi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Sarre",
      "Campus": "La Sarre"
    },
    {
      value: 1597,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Laurier Macdonald",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Léonard",
      "Campus": "Saint-Léonard"
    },
    {
      value: 1598,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Léonard-De Vinci",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1599,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle L’Envol",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Carleton-sur-Mer",
      "Campus": "Carleton-sur-Mer"
    },
    {
      value: 1600,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle L’Oasis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1601,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Marcel-Proulx",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1602,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Marie-Rollet",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1603,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Marie-Sarah",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Côte-Nord-du-Golfe-du-Saint-Laurent",
      "Campus": "Côte-Nord-du-Golfe-du-Saint-Laurent"
    },
    {
      value: 1604,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Maurice-Barbeau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1605,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Mecatina",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gros-Mécatina",
      "Campus": "Gros-Mécatina"
    },
    {
      value: 1606,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Monseigneur-Scheffer",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Blanc-Sablon",
      "Campus": "Blanc-Sablon"
    },
    {
      value: 1607,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Mountain Ridge",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Bonne-Espérance",
      "Campus": "Bonne-Espérance"
    },
    {
      value: 1608,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Netagamiou",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Côte-Nord-du-Golfe-du-Saint-Laurent",
      "Campus": "Côte-Nord-du-Golfe-du-Saint-Laurent"
    },
    {
      value: 1609,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Nova",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Châteauguay",
      "Campus": "Châteauguay"
    },
    {
      value: 1610,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Paul-Émile-Dufresne",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1611,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Paul-Gérin-Lajoie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Vaudreuil-Dorion",
      "Campus": "Vaudreuil-Dorion"
    },
    {
      value: 1612,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Paul-Rousseau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1613,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Pavillon-de-l’Avenir",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rivière-du-Loup",
      "Campus": "Rivière-du-Loup"
    },
    {
      value: 1614,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Pierre-Dupuy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1615,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Pontiac",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Fort-Coulonge",
      "Campus": "Fort-Coulonge"
    },
    {
      value: 1616,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Pozer",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Georges",
      "Campus": "Saint-Georges"
    },
    {
      value: 1617,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Qualitech",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1618,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Relais de la Lièvre-Seigneurie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1619,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Sabtuan (Chisasibi - Coastal)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Chisasibi",
      "Campus": "Chisasibi"
    },
    {
      value: 1620,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Sabtuan (Mistissini - Inland)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mistissini",
      "Campus": "Mistissini"
    },
    {
      value: 1621,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Augustine",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Augustin",
      "Campus": "Saint-Augustin"
    },
    {
      value: 1622,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Bernard",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Blanc-Sablon",
      "Campus": "Blanc-Sablon"
    },
    {
      value: 1623,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Joseph",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "L’Île-d’Anticosti",
      "Campus": "L’Île-d’Anticosti"
    },
    {
      value: 1624,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Lawrence",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gros-Mécatina",
      "Campus": "Gros-Mécatina"
    },
    {
      value: 1625,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Paul",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Bonne-Espérance",
      "Campus": "Bonne-Espérance"
    },
    {
      value: 1626,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Saint-Theresa",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Blanc-Sablon",
      "Campus": "Blanc-Sablon"
    },
    {
      value: 1627,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Samuel-De Champlain",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1628,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Val-d’Or",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Val-d’Or",
      "Campus": "Val-d’Or"
    },
    {
      value: 1629,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Vision 20 20",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Victoriaville",
      "Campus": "Victoriaville"
    },
    {
      value: 1630,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Vision-Avenir",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1631,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle West-Island",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Pierrefonds",
      "Campus": "Pierrefonds"
    },
    {
      value: 1632,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle Wilbrod-Bherer",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1633,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Black Lake",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Thetford Mines",
      "Campus": "Thetford Mines"
    },
    {
      value: 1634,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Charlevoix",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Malbaie",
      "Campus": "La Malbaie"
    },
    {
      value: 1635,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Coaticook (CRIFA)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Coaticook",
      "Campus": "Coaticook"
    },
    {
      value: 1636,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Fierbourg",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1637,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Forestville",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Forestville",
      "Campus": "Forestville"
    },
    {
      value: 1638,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Grosse-Île",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Grosse-Île",
      "Campus": "Grosse-Île"
    },
    {
      value: 1639,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Kuujjuaq Kajusivik",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Kuujjuaq",
      "Campus": "Kuujjuaq"
    },
    {
      value: 1640,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de La Côte-de-Gaspé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gaspé",
      "Campus": "Gaspé"
    },
    {
      value: 1641,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de La Haute-Gaspésie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Anne-des-Monts",
      "Campus": "Sainte-Anne-des-Monts"
    },
    {
      value: 1642,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Lachine",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1643,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Lévis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1644,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Limoilou",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1645,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Maniwaki",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Maniwaki",
      "Campus": "Maniwaki"
    },
    {
      value: 1646,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Matane",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Matane",
      "Campus": "Matane"
    },
    {
      value: 1647,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Memphrémagog",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Magog",
      "Campus": "Magog"
    },
    {
      value: 1648,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Mont-Joli-Mitis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mont-Joli",
      "Campus": "Mont-Joli"
    },
    {
      value: 1649,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Mont-Laurier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mont-Laurier",
      "Campus": "Mont-Laurier"
    },
    {
      value: 1650,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Neufchâtel",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1651,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Nicolet",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Nicolet",
      "Campus": "Nicolet"
    },
    {
      value: 1652,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Northern Lights",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sept-Îles",
      "Campus": "Sept-Îles"
    },
    {
      value: 1653,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Portneuf",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Donnacona",
      "Campus": "Donnacona"
    },
    {
      value: 1654,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1655,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Rimouski-Neigette",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rimouski",
      "Campus": "Rimouski"
    },
    {
      value: 1656,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Verdun",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1657,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Wakeham",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gaspé",
      "Campus": "Gaspé"
    },
    {
      value: 1658,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de Waswanipi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Waswanipi",
      "Campus": "Waswanipi"
    },
    {
      value: 1659,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de la Baie-James",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Chibougamau",
      "Campus": "Chibougamau"
    },
    {
      value: 1660,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de la Pointe-du-Lac",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Salaberry-de-Valleyfield",
      "Campus": "Salaberry-de-Valleyfield"
    },
    {
      value: 1661,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de la Vallée-de-la-Gatineau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Maniwaki",
      "Campus": "Maniwaki"
    },
    {
      value: 1662,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de l’Asbesterie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Asbestos",
      "Campus": "Asbestos"
    },
    {
      value: 1663,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de l’Envolée",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montmagny",
      "Campus": "Montmagny"
    },
    {
      value: 1664,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle de l’automobile",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Thérèse",
      "Campus": "Sainte-Thérèse"
    },
    {
      value: 1665,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Carrefours",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1666,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Îles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Les Îles-de-la-Madeleine",
      "Campus": "Les Îles-de-la-Madeleine"
    },
    {
      value: 1667,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Moissons",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Beauharnois",
      "Campus": "Beauharnois"
    },
    {
      value: 1668,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Moulins",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Terrebonne",
      "Campus": "Terrebonne"
    },
    {
      value: 1669,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Patriotes",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Julie",
      "Campus": "Sainte-Julie"
    },
    {
      value: 1670,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Riverains",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Repentigny",
      "Campus": "Repentigny"
    },
    {
      value: 1671,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Sommets / L’Horizon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Agathe-des-Monts",
      "Campus": "Sainte-Agathe-des-Monts"
    },
    {
      value: 1672,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des Sources",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1673,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle des métiers de la santé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Kirkland",
      "Campus": "Kirkland"
    },
    {
      value: 1674,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle du Fjord",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1675,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle du Fleuve-et-des-Lacs",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Témiscouata-sur-le-Lac",
      "Campus": "Témiscouata-sur-le-Lac"
    },
    {
      value: 1676,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle du Haut-Saint-François",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "East Angus",
      "Campus": "East Angus"
    },
    {
      value: 1677,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle du Nunavik Nunavimmi Pigiursavik",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Inukjuak",
      "Campus": "Inukjuak"
    },
    {
      value: 1678,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle du Pays-des-Bleuets",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Dolbeau-Mistassini",
      "Campus": "Dolbeau-Mistassini"
    },
    {
      value: 1679,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle d’Amqui",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Amqui",
      "Campus": "Amqui"
    },
    {
      value: 1680,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle d’électrolyse et d’esthétique",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1681,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle en équipement motorisé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1682,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle en foresterie de l’Est-du-Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Causapscal",
      "Campus": "Causapscal"
    },
    {
      value: 1683,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle le Granit",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lac-Mégantic",
      "Campus": "Lac-Mégantic"
    },
    {
      value: 1684,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle le Tremplin",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Thetford Mines",
      "Campus": "Thetford Mines"
    },
    {
      value: 1685,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle pour Autochtones dans les métiers de la construction",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1686,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professionnelle, l’Émergence",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Deux-Montagnes",
      "Campus": "Deux-Montagnes"
    },
    {
      value: 1687,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de formation professsionnelle Eastern Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1688,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de géomatique du Québec inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1689,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de métallurgie du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1690,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de production automatisée (CPA)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1691,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de productique intégrée du Québec inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1692,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de recherche pour l’inclusion scolaire et professionnelle des étudiants en situation de handicap",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1693,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de robotique et de vision industrielles inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1694,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de solutions technologiques en orthèses et prothèses (CSTOP)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1695,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de technologie Rosemont",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1696,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de technologie minérale et de plasturgie inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Thetford Mines",
      "Campus": "Thetford Mines"
    },
    {
      value: 1697,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre de transfert technologique en écologie industrielle, centre J-E. Simard",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sorel-Tracy",
      "Campus": "Sorel-Tracy"
    },
    {
      value: 1698,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre des Carrières Western Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1699,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre des services aux entreprises",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Alma",
      "Campus": "Alma"
    },
    {
      value: 1700,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre des technologies de l’eau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1701,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’éducation aux adultes et communautaire Huntingdon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Huntingdon",
      "Campus": "Huntingdon"
    },
    {
      value: 1702,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’éducation des adultes de l’Accore (FP)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Châteauguay",
      "Campus": "Châteauguay"
    },
    {
      value: 1703,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’éducation des adultes du Goéland (FP)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Prairie",
      "Campus": "La Prairie"
    },
    {
      value: 1704,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’éducation professionnelle de Listuguj",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Pointe-à-la-Croix",
      "Campus": "Pointe-à-la-Croix"
    },
    {
      value: 1705,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’éducation professionnelle de New Richmond",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "New Richmond",
      "Campus": "New Richmond"
    },
    {
      value: 1706,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’électrotechnologie Pearson",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lachine",
      "Campus": "Lachine"
    },
    {
      value: 1707,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’enseignement et de recherche en foresterie de Ste-Foy inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1708,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’étude en responsabilité sociale et écocitoyenneté (CÉRSÉ)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1709,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’études commerciales Shadd",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1710,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’études en procédés chimiques du Québec (CÉPROCQ)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1711,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’études professionnelles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jérôme",
      "Campus": "Saint-Jérôme"
    },
    {
      value: 1712,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’excellence en formation industrielle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Windsor",
      "Campus": "Windsor"
    },
    {
      value: 1713,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’expérimentation et de développement en forêt boréale (CEDFOB)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Baie-Comeau",
      "Campus": "Baie-Comeau"
    },
    {
      value: 1714,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’initiation à la recherche et d’aide au développement durable (CIRADD)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Carleton-sur-Mer",
      "Campus": "Carleton-sur-Mer"
    },
    {
      value: 1715,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’innovation en microélectronique du Québec (CIMEQ)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Thérèse",
      "Campus": "Sainte-Thérèse"
    },
    {
      value: 1716,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre d’innovation sociale en agriculture",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Victoriaville",
      "Campus": "Victoriaville"
    },
    {
      value: 1717,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre en imagerie numérique et médias interactifs (CIMMI)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1718,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre intégré de mécanique industrielle de la Chaudière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Georges",
      "Campus": "Saint-Georges"
    },
    {
      value: 1719,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre intégré de mécanique, de métallurgie et d’électricité (CIMME)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1720,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre l’Envol",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Témiscaming",
      "Campus": "Témiscaming"
    },
    {
      value: 1721,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre multiservice des Samares",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Joliette",
      "Campus": "Joliette"
    },
    {
      value: 1722,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre national de conduite d’engins de chantier",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1723,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre national en électrochimie et en technologies environnementales inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Shawinigan",
      "Campus": "Shawinigan"
    },
    {
      value: 1724,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre régional de formation à distance du Grand-Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1725,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre régional intégré de formation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Granby",
      "Campus": "Granby"
    },
    {
      value: 1726,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre sectoriel des plastiques",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Damien-de-Buckland",
      "Campus": "Saint-Damien-de-Buckland"
    },
    {
      value: 1727,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre technologique des résidus industriels",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rouyn-Noranda",
      "Campus": "Rouyn-Noranda"
    },
    {
      value: 1728,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Centre technologique en aérospatiale C.T.A.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hubert",
      "Campus": "Saint-Hubert"
    },
    {
      value: 1729,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Champlain Regional College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1730,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cintech agroalimentaire",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hyacinthe",
      "Campus": "Saint-Hyacinthe"
    },
    {
      value: 1731,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège André-Grasset (1973) inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1732,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège April-Fortier inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1733,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Avalon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal, Québec",
      "Campus": "Montréal"
    },
    {
      value: 1734,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Avalon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal, Québec",
      "Campus": "Québec"
    },
    {
      value: 1735,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Bart (1975)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1736,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège CDI Administration - Technologie - Santé/CDI College Business - Technology - Health Care",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1737,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège CDI Administration. Technologie. Santé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1738,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège CDI Administration. Technologie. Santé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1739,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège CDI Administration. Technologie. Santé",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1740,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Canada inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1741,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Centennale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1742,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Cumberland Inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1743,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Dawson",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1744,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Eid Air Aviation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Bromont",
      "Campus": "Bromont"
    },
    {
      value: 1745,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Ellis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Campus de Drummondville"
    },
    {
      value: 1746,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Ellis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Campus de Trois-Rivières"
    },
    {
      value: 1747,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Greystone",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1748,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Héritage",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1749,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Herzing",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1750,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Herzing / Herzing College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1751,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Inter-Dec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1752,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège International des Marcellines",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Westmount",
      "Campus": "Westmount"
    },
    {
      value: 1753,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Jean-de-Brébeuf",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1754,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Kensley",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Québec"
    },
    {
      value: 1755,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège La Cabriole",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1756,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège LaSalle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1757,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Laflèche",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1758,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège M du Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1759,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Marianopolis",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Westmount",
      "Campus": "Westmount"
    },
    {
      value: 1760,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Mérici",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1761,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Milestone",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Brossard",
      "Campus": "Brossard"
    },
    {
      value: 1762,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège MultiHexa Saguenay/Lac Saint-Jean",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1763,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège O’Sullivan de Montréal inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1764,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège O’Sullivan de Québec inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1765,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Salette inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1766,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Shawinigan",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Shawinigan",
      "Campus": "Shawinigan"
    },
    {
      value: 1767,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège St-Michel",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1768,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Stanislas inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1769,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Supérieur de Montréal (C.S.M.) inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1770,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège TAV",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1771,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Universel",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1772,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de comptabilité et de secrétariat du Québec, campus de Longueuil inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1773,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de comptabilité et de secrétariat du Québec, campus de Sherbrooke inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1774,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de comptabilité et de secrétariat du Québec, campus de Sherbrooke inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1775,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de gestion, technologie et santé Matrix",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1776,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de l’immobilier du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1777,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de photographie Marsan",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1778,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège de technologie Ascent Inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1779,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège d’Alma",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Alma",
      "Campus": "Alma"
    },
    {
      value: 1780,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège d’aéronautique",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1781,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège d’enseignement en immobilier inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1782,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège international Marie de France",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1783,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège l’Avenir de Rosemont",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1784,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège national de science et technologie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montreal",
      "Campus": "Montreal"
    },
    {
      value: 1785,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège radio télévision de Québec inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1786,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège technique de Montréal inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mont-Royal",
      "Campus": "Mont-Royal"
    },
    {
      value: 1787,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collégial international Sainte-Anne",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1788,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Concordia University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1789,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conseil de l’Éducation du Lac-Simon",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lac-Simon",
      "Campus": "Lac-Simon"
    },
    {
      value: 1790,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Gatineau",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1791,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1792,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1793,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Rimouski",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rimouski",
      "Campus": "Rimouski"
    },
    {
      value: 1794,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Saguenay",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1795,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Trois-Rivières",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1796,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Conservatoire de musique de Val-d’Or",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Val-d’Or",
      "Campus": "Val-d’Or"
    },
    {
      value: 1797,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Corporation du service de recherche et d’expertise en transformation des produits forestiers de l’Est-du-Québec.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Amqui",
      "Campus": "Amqui"
    },
    {
      value: 1798,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "ÉCOBES Recherche et transfert",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1799,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "EQMBO-Entreprises Centre d’aide technique et technologique inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Victoriaville",
      "Campus": "Victoriaville"
    },
    {
      value: 1800,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École Manikanetish du conseil des Montagnais de Sept-Îles et Maliotenam",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sept-Îles",
      "Campus": "Sept-Îles"
    },
    {
      value: 1801,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École Nationale du meuble et de l’ébénisterie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Victoriaville",
      "Campus": "Victoriaville"
    },
    {
      value: 1802,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École Pivaut Montréal Inc",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montreal",
      "Campus": "Montreal"
    },
    {
      value: 1803,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École Polymécanique de Laval",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1804,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École Polytechnique de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1805,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de cirque de Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1806,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de cirque de Verdun",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Verdun",
      "Campus": "Verdun"
    },
    {
      value: 1807,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de danse contemporaine de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1808,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de danse de Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1809,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de foresterie et de technologie du bois de Duchesnay",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Catherine-de-la-Jacques-Cartier",
      "Campus": "Sainte-Catherine-de-la-Jacques-Cartier"
    },
    {
      value: 1810,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de formation professionnelle de Châteauguay (FP)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Châteauguay",
      "Campus": "Châteauguay"
    },
    {
      value: 1811,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de management INSA",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1812,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de musique Vincent d’Indy",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1813,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de pilotage Saint-Hubert inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1814,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de sténographie judiciaire du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1815,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de technologie gazière",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Boucherville",
      "Campus": "Boucherville"
    },
    {
      value: 1816,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École de technologie supérieure",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1817,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des Hautes Études Commerciales de Montréal (HEC Montréal)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1818,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des entrepreneurs du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1819,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de la construction de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1820,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de la restauration et du tourisme de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1821,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de l’aérospatiale de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1822,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de l’équipement motorisé de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1823,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de l’horticulture de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1824,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers de l’informatique, du commerce et de l’administration de Montréal (ÉMICA)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1825,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers des Faubourgs-de-Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1826,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers du Sud-Ouest-de-Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1827,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers du meuble de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1828,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers et occupations de l’industrie de la construction de Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1829,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des métiers spécialisés Asticou",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1830,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École des pêches et de l’aquaculture du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Grande-Rivière",
      "Campus": "Grande-Rivière"
    },
    {
      value: 1831,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Barreau du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Centre de Gatineau"
    },
    {
      value: 1832,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Barreau du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1833,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Barreau du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Centre de Québec"
    },
    {
      value: 1834,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Barreau du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1835,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Routier G.C. inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1836,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du Routier G.C. inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1837,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École du show-business",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1838,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École forestière de La Tuque - Formation professionnelle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Tuque",
      "Campus": "La Tuque"
    },
    {
      value: 1839,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École hôtelière de Laval",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1840,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École hôtelière de la Capitale",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1841,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École hôtelière de la Montérégie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Longueuil",
      "Campus": "Longueuil"
    },
    {
      value: 1842,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École hôtelière des Laurentides",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Adèle",
      "Campus": "Sainte-Adèle"
    },
    {
      value: 1843,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Québec",
      "Campus": "Québec"
    },
    {
      value: 1844,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Terrebonne",
      "Campus": "Terrebonne"
    },
    {
      value: 1845,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de camionnage et équipement lourd (E.N.C.E.L.)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1846,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de cirque",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1847,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de l’humour",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1848,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale de théâtre du Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1849,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École nationale d’administration publique",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1850,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École professionnelle de Saint-Hyacinthe",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hyacinthe",
      "Campus": "Saint-Hyacinthe"
    },
    {
      value: 1851,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École professionnelle des Métiers",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1852,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École secondaire Kassinu Mamu",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mashteuiatsh",
      "Campus": "Mashteuiatsh"
    },
    {
      value: 1853,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École secondaire Mikisiw",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Obedjiwan",
      "Campus": "Obedjiwan"
    },
    {
      value: 1854,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "École supérieure de ballet du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1855,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Extra Centre de Formation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Boucherville",
      "Campus": "Boucherville"
    },
    {
      value: 1856,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Faculté de Théologie Évangélique de l’Université Acadia",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1857,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Groupe CTT inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hyacinthe",
      "Campus": "Saint-Hyacinthe"
    },
    {
      value: 1858,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Hélicraft",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hubert",
      "Campus": "Saint-Hubert"
    },
    {
      value: 1859,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "IPIQ - Institut de protection contre les incendies du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Laval",
      "Campus": "Laval"
    },
    {
      value: 1860,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Innofibre - Centre d’innovation des produits cellulosiques",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1861,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Innovation maritime",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rimouski",
      "Campus": "Rimouski"
    },
    {
      value: 1862,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut Quadrilium de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montreal",
      "Campus": "Néant"
    },
    {
      value: 1863,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut Teccart",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1864,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut Teccart (2003)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1865,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut Trebas Québec Inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1866,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de formation théologique de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1867,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de recherche sur l’intégration professionnelle des immigrants (IRIPI)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1868,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de technologie agroalimentaire",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Pocatière",
      "Campus": "Campus de La Pocatière"
    },
    {
      value: 1869,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de technologie agroalimentaire",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Hyacinthe",
      "Campus": "Campus de Saint-Hyacinthe"
    },
    {
      value: 1870,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de technologie des emballages et du génie alimentaire (ITEGA)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1871,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut de tourisme et d’hôtellerie du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1872,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut des communications graphiques et de l’imprimabilité",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1873,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut du transport avancé du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jérôme",
      "Campus": "Saint-Jérôme"
    },
    {
      value: 1874,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut d’enregistrement du Canada",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1875,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut international de logistique de Montréal (IILM)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1876,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut national de la recherche scientifique",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1877,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut national de l’image et du son (INIS)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1878,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut supérieur d’informatique I.S.I.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1879,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut technique Aviron de Montréal inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mont-Royal",
      "Campus": "Mont-Royal"
    },
    {
      value: 1880,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Institut technologique de maintenance industrielle",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sept-Îles",
      "Campus": "Sept-Îles"
    },
    {
      value: 1881,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Isart Digital Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1882,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Kanatamat Tshitipenitamunu",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Schefferville",
      "Campus": "Schefferville"
    },
    {
      value: 1883,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lachute Aviation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lachute",
      "Campus": "Lachute"
    },
    {
      value: 1884,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "L’École du routier professionnel du Québec (1996) inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1885,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "MÉCANIUM inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Georges",
      "Campus": "Saint-Georges"
    },
    {
      value: 1886,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Maison familiale rurale du Granit",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Romain",
      "Campus": "Saint-Romain"
    },
    {
      value: 1887,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McGill University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Main Campus"
    },
    {
      value: 1888,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "McGill University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sainte-Anne-de-Bellevue",
      "Campus": "Macdonald Campus"
    },
    {
      value: 1889,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Merinov (CCTT des pêches)",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gaspé",
      "Campus": "Gaspé"
    },
    {
      value: 1890,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Montreal College of Information Technology",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1891,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Musitechnic Formation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1892,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Oleotek inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Thetford Mines",
      "Campus": "Thetford Mines"
    },
    {
      value: 1893,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Ordre des comptables professionnels agréés",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1894,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Passport Hélico",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Mascouche",
      "Campus": "Mascouche"
    },
    {
      value: 1895,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Royal Military College Saint-Jean",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saint-Jean-sur-Richelieu",
      "Campus": "Saint-Jean-sur-Richelieu"
    },
    {
      value: 1896,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Rubika, école supérieure de création numérique appliquée à l’animation, au design et au jeu vidéo inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1897,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Select Aviation",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Drummondville",
      "Campus": "Drummondville"
    },
    {
      value: 1898,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Séminaire de Sherbrooke",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1899,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Solutions Novika",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "La Pocatière",
      "Campus": "La Pocatière"
    },
    {
      value: 1900,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Syn Studio",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1901,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "TechnoCentre éolien Gaspésie, les Îles",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gaspé",
      "Campus": "Gaspé"
    },
    {
      value: 1902,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Télé-université",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1903,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "TransBIOTech Centre de recherche et de transfert en biotechnologie",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Lévis",
      "Campus": "Lévis"
    },
    {
      value: 1904,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université Laval",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1905,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1906,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université de Sherbrooke",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Sherbrooke",
      "Campus": "Sherbrooke"
    },
    {
      value: 1907,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Quebec",
      "Campus": "Quebec"
    },
    {
      value: 1908,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec à Chicoutimi",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Saguenay",
      "Campus": "Saguenay"
    },
    {
      value: 1909,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec à Montréal",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1910,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec à Rimouski",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rimouski",
      "Campus": "Rimouski"
    },
    {
      value: 1911,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec à Trois-Rivières",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Trois-Rivières",
      "Campus": "Trois-Rivières"
    },
    {
      value: 1912,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec en Abitibi-Témiscamingue",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Rouyn-Noranda",
      "Campus": "Rouyn-Noranda"
    },
    {
      value: 1913,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Université du Québec en Outaouais Pavillon Alexandre-Taché",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Gatineau",
      "Campus": "Gatineau"
    },
    {
      value: 1914,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "VESTECHPRO Centre de recherche et d’innovation en habillement",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1915,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Vanier College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Quebec",
      "City": "Montréal",
      "Campus": "Montréal"
    },
    {
      value: 1916,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Fashion Design",
      "Type": "College, University",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1917,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Academy of Learning College – Saskatoon",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1918,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Briercrest College and Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Caronport",
      "Campus": "Caronport"
    },
    {
      value: 1919,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Canadian Revival Training Centre Inc.",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Prince Albert",
      "Campus": "Prince Albert"
    },
    {
      value: 1920,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Collège Mathieu",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Gravelbourg",
      "Campus": "Gravelbourg"
    },
    {
      value: 1921,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "College of Emmanuel and St Chad",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1922,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cumberland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Nipawin",
      "Campus": "Nipawin Campus"
    },
    {
      value: 1923,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cumberland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Melfort",
      "Campus": "Melfort Campus"
    },
    {
      value: 1924,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Cumberland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Tisdale",
      "Campus": "Tisdale Campus"
    },
    {
      value: 1925,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Elite Hairstyling and Esthetics Training Center",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Moose Jaw",
      "Campus": "Moose Jaw"
    },
    {
      value: 1926,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Eston College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Eston",
      "Campus": "Eston"
    },
    {
      value: 1927,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Plains College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Swift Current",
      "Campus": "Swift Current Campus"
    },
    {
      value: 1928,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Plains College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Kindersley /Swift Current",
      "Campus": "Kindersley Campus"
    },
    {
      value: 1929,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Plains College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Warman /Swift Current",
      "Campus": "Warman Campus"
    },
    {
      value: 1930,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Plains College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Maple Creek /Swift Current",
      "Campus": "Biggar Campus"
    },
    {
      value: 1931,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Great Plains College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Maple Creek /Swift Current",
      "Campus": "Maple Creek Campus"
    },
    {
      value: 1932,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Horizon College and Seminary Inc.",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1933,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Lutheran Theological Seminary",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1934,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Millar College of the Bible",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Pambrun",
      "Campus": "Pambrun"
    },
    {
      value: 1935,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Millennium Aviation",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1936,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Mitchinson Flying Service",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1937,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Nipawin Bible College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Nipawin",
      "Campus": "Nipawin"
    },
    {
      value: 1938,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "North West College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "North Battleford",
      "Campus": "North Battleford"
    },
    {
      value: 1939,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Parkland College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Yorkton",
      "Campus": "Yorkton"
    },
    {
      value: 1940,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Regina Flying Club",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Regina",
      "Campus": "Regina"
    },
    {
      value: 1941,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatchewan Polytechnic",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Moose Jaw",
      "Campus": "Moose Jaw"
    },
    {
      value: 1942,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatchewan Polytechnic",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Prince Albert",
      "Campus": "Prince Albert"
    },
    {
      value: 1943,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatchewan Polytechnic",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Regina",
      "Campus": "Regina"
    },
    {
      value: 1944,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatchewan Polytechnic",
      "Type": "College, University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1945,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatoon Business College",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1946,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Saskatoon Health Region Orthoptic Program",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1947,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Southeast College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Weyburn Estevan",
      "Campus": "Weyburn Estevan"
    },
    {
      value: 1948,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Andrew’s College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1949,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "St. Peter’s College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Muenster",
      "Campus": "Muenster"
    },
    {
      value: 1950,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "The Professional Institute of Massage Therapy Ltd",
      "Type": "College",
      "PGWP": 0,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1951,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Regina, including Campion College, First Nations University of Canada and Luther College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Regina",
      "Campus": "Regina"
    },
    {
      value: 1952,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "University of Saskatchewan, including St. Thomas More College",
      "Type": "University",
      "PGWP": 1,
      "Province": "Saskatchewan",
      "City": "Saskatoon",
      "Campus": "Saskatoon"
    },
    {
      value: 1953,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aurora College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Northwest Territories",
      "City": "Fort Smith\t",
      "Campus": "Thebacha Campus"
    },
    {
      value: 1954,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aurora College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Northwest Territories",
      "City": "Inuvik\t",
      "Campus": "Aurora Campus"
    },
    {
      value: 1955,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Aurora College",
      "Type": "College",
      "PGWP": 1,
      "Province": "Northwest Territories",
      "City": "Yellowknife\t",
      "Campus": "Yellowknife/North Slave Campus"
    },
    {
      value: 1956,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Alkan Air Ltd.",
      "Type": "Flight school",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Whitehorse",
      "Campus": "Whitehorse"
    },
    {
      value: 1957,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Carcross",
      "Campus": "Carcross Community Campus"
    },
    {
      value: 1958,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Carmacks",
      "Campus": "Carmacks Community Campus"
    },
    {
      value: 1959,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Dawson City",
      "Campus": "Dawson City Tr’odëk Hätr’unohtän Zho Campus"
    },
    {
      value: 1960,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Faro",
      "Campus": "Faro Community Campus"
    },
    {
      value: 1961,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Haines Junction",
      "Campus": "Haines Junction Community Campus"
    },
    {
      value: 1962,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Mayo",
      "Campus": "Mayo Community Campus"
    },
    {
      value: 1963,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Old Crow",
      "Campus": "Old Crow Alice Frost Community Campus"
    },
    {
      value: 1964,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Pelly Crossing",
      "Campus": "Pelly Crossing Hets’edän Kú’Learning House Campus"
    },
    {
      value: 1965,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Ross River",
      "Campus": "Ross River Dene Cho Kê’endj Campus"
    },
    {
      value: 1966,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Teslin",
      "Campus": "Teslin Community Campus"
    },
    {
      value: 1967,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Watson Lake",
      "Campus": "Watson Lake Community Campus"
    },
    {
      value: 1968,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Whitehorse",
      "Campus": "Ayamdigut Campus"
    },
    {
      value: 1969,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Whitehorse",
      "Campus": "Kwanlin Dun First Nation Kenädän Ku House of Learning"
    },
    {
      value: 1970,
      "CreatedDate": "2020-12-12 00:00:00.000000",
      "CreatedByUser": 1,
      "ModifiedDate": "2020-12-12 00:00:00.000000",
      "LastModifiedByUser": 1,
      "IsDeleted": 0,
      "Name": "Yukon University",
      "Type": "University",
      "PGWP": 1,
      "Province": "Yukon",
      "City": "Whitehorse",
      "Campus": "Whitehorse Correctional Centre Campus"
    }
]
